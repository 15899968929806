import $ from "dom7";
import expenseForm from "./expenseForm";
import about from "./about";
import html from "../pages/index.html";
import style from "../styles/expense.less";
export default [{
    name: "/expense/",
    path: "/expense/",
    // url: "./expense/pages/index.html"
    component: {
      template: html,
      style: style,
      methods: {
        doDeleteSwiper: function (e) {
          const {
            $$
          } = this;
          var page = $$('.page[data-name="expense-index"]')[0].f7Page;
          var $$expenseCheckbox = $$('.expense-checkbox input[type="checkbox"]');
          var $$aBtn = $$(e.currentTarget).css("pointer-events", "none");
          setTimeout(function () {
            $$aBtn.css("pointer-events", "");
          }, 200);
          var id = parseInt(e.currentTarget.dataset.id);
          var expenseDeleteParams = this.getExpenseDeleteParams([id]);
          page.app.request.postJSON(page.app.data.url, expenseDeleteParams, function (delete_result) {
            console.log(delete_result);
            if (delete_result.result.result == "ok") {
              $$aBtn.parent().parent().parent().remove();
              // page.app.router.navigate(router.currentRoute.url, {
              //   reloadCurrent: true,
              //   ignoreCache: true,
              // });
            }
          });
        },
        getExpenseDetailParams: function (obj) {
          var dbname = this.$app.data.dbname;
          var userid = this.$app.data.userid;
          var password = this.$app.data.password;
          var vuserid = this.$app.data.session.userid;
          return {
            jsonrpc: "2.0",
            method: "call",
            params: {
              service: "object",
              method: "execute",
              args: [
                dbname,
                userid,
                password,
                "kthrp.expense.line.interface",
                "user_expense_line_details",
                obj
              ]
            }
          };
        },
        getListExpenseParams: function (obj) {
          var dbname = this.$app.data.dbname;
          var userid = this.$app.data.userid;
          var password = this.$app.data.password;
          return {
            jsonrpc: "2.0",
            method: "call",
            params: {
              service: "object",
              method: "execute",
              args: [
                dbname,
                userid,
                password,
                "kthrp.expense.line.interface",
                "user_expense_line",
                obj
              ]
            }
          };
        },
        getExpenseFieldsOptions: function (method, obj) {
          var vuserid = this.$app.data.session.userid;
          var dbname = this.$app.data.dbname;
          var userid = this.$app.data.userid;
          var password = this.$app.data.password;
          return {
            jsonrpc: "2.0",
            method: "call",
            params: {
              service: "object",
              method: "execute",
              args: [
                dbname,
                userid,
                password,
                "kthrp.expense.line.interface",
                method,
                obj
              ]
            }
          };
        },

        getExpenseDeleteParams: function (expense_line_ids_list) {
          var dbname = this.$app.data.dbname;
          var userid = this.$app.data.userid;
          var password = this.$app.data.password;
          return {
            "jsonrpc": "2.0",
            "method": "call",
            "params": {
              "service": "object",
              "method": "execute",
              "args": [
                dbname, userid, password, "kthrp.expense.line.interface", "delete_expense_line", {
                  "expense_line_ids": expense_line_ids_list
                }
              ]
            }
          };
        },

        doBatch: function () {
          console.log("batch action");
          const {
            $$
          } = this;
          var page = $$('.page[data-name="expense-index"]')[0].f7Page;
          $$('.expense-checkbox input[type="checkbox"]').prop("checked", false); //初始化置空checkbox
          $$(".expense-list-checkbox-icon").css("margin-left", 0);
          $$(".expense-toolbar").css("opacity", 1);
          page.app.toolbar.show(".expense-toolbar");
        },
        hideToolbar: function () {
          const {
            $$
          } = this;
          var page = $$('.page[data-name="expense-index"]')[0].f7Page;
          $$(".expense-list-checkbox-icon").css("margin-left", "-80px");
          $$(".expense-toolbar").css("opacity", 0);
          page.app.toolbar.hide(".expense-toolbar");
        },
        selectAll: function () {
          const {
            $$
          } = this;
          var page = $$('.page[data-name="expense-index"]')[0].f7Page;
          $$('.expense-checkbox input[type="checkbox"]').prop("checked", true);
        },
        deleteExpense: function () {
          //删除费用项
          const {
            $$
          } = this;
          var page = $$('.page[data-name="expense-index"]')[0].f7Page;
          var $$expenseCheckbox = $$('.expense-checkbox input[type="checkbox"]');
          var expense_line_ids = [];
          $$expenseCheckbox.each(function () {
            if ($$(this).prop("checked") === true) {
              expense_line_ids.push(parseInt($$(this).data("id")));
            }
          });
          var expenseDeleteParams = this.getExpenseDeleteParams(expense_line_ids);
          page.app.request.postJSON(page.app.data.url, expenseDeleteParams, function (delete_result) {
            console.log(delete_result);
            if (delete_result.result.result == "ok") {
              $$expenseCheckbox.each(function () {
                if ($$(this).prop("checked") === true) {
                  $$(this).parent().parent().remove();
                }
              });

              // page.app.router.navigate(router.currentRoute.url, {
              //   reloadCurrent: true,
              //   ignoreCache: true,
              // });
            }
          });
        },
        toDetailExpenseForm: function (e) {
          const {
            $$
          } = this;
          var page = $$('.page[data-name="expense-index"]')[0].f7Page;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          var expenseId = parseInt(e.currentTarget.dataset.id);
          // var model = e.currentTarget.dataset.model;
          if (page.app.data.session.source == 'dingding') {
            var expenseDetailParams = this.getExpenseDetailParams({
              "expense_line_id": expenseId,
              "dingding_code": dingding_code,
            });
          } else if (page.app.data.session.source == 'feishu') {
            var expenseDetailParams = this.getExpenseDetailParams({
              "expense_line_id": expenseId,
              "feishu_userid": feishu_userid,
            });
          } else {
            var expenseDetailParams = this.getExpenseDetailParams({
              "expense_line_id": expenseId,
              "wechat_name": vuserid,
            });
          }
          // var expenseDetailParams = this.getExpenseDetailParams(expenseId);
          page.app.request.postJSON(page.app.data.url, expenseDetailParams, function (expense_detail_result) {
            console.log(expense_detail_result.result);
            // expense_detail_result.result.expense_line_details.images = [{
            //   url: "http://image.xcar.com.cn/attachments/a/day_140319/2014031916_1bd6ffb9d5553620f870beYW9d0Nxj0n.jpg",
            // }, {
            //   url: "http://image.xcar.com.cn/attachments/a/day_140319/2014031916_1bd6ffb9d5553620f870beYW9d0Nxj0n.jpg",
            // }, {
            //   url: "http://image.xcar.com.cn/attachments/a/day_140319/2014031916_1bd6ffb9d5553620f870beYW9d0Nxj0n.jpg",
            // }];
            // expense_detail_result.result.expense_line_details.attachment_ids = [{
            //   id: "123",
            //   name: "A",
            //   url: "http://192.168.1.9/uploads/-/system/user/avatar/2/avatar.png",
            // }, {
            //   id: "124",
            //   name: "B",
            //   url: "http://192.168.1.9/uploads/-/system/user/avatar/2/avatar.png",
            // }, {
            //   id: "125",
            //   name: "C",
            //   url: "http://192.168.1.9/uploads/-/system/user/avatar/2/avatar.png",
            // }];
            // expense_detail_result.result.expense_line_details.imagesList = ["http://image.xcar.com.cn/attachments/a/day_140319/2014031916_1bd6ffb9d5553620f870beYW9d0Nxj0n.jpg", "http://image.xcar.com.cn/attachments/a/day_140319/2014031916_1bd6ffb9d5553620f870beYW9d0Nxj0n.jpg", "http://image.xcar.com.cn/attachments/a/day_140319/2014031916_1bd6ffb9d5553620f870beYW9d0Nxj0n.jpg"];
            page.app.data.expense_data.form_data = expense_detail_result.result.expense_line_details;
            page.router.navigate("/expenseform/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                attachments: true,
                info: expense_detail_result.result.expense_line_details
              }
            });
          });
          // page.app.data.expense_data.form_data = {
          //   expense_requisition_item_id: 585,
          //   date_start: "2018-12-12",
          //   date_stop: "2018-12-12",
          //   price: 1.0,
          //   quantity: 1,
          //   amount: 1.0,
          //   place: "China",
          //   supplier: "example hotel",
          //   detail_place: "中国武汉湖北武大园",
          //   note: "备注123123123"
          // };
          // console.log(page.app.data.expense_data.form_data);

        }
      },
      on: {
        pageInit: function (e, page) {
          var self = this;
          if ("form_data" in page.app.data.expense_data) {
            delete page.app.data.expense_data["form_data"];
          }


          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var post_list_expense_paramrs = this.getListExpenseParams({
              "dingding_code": dingding_code,
            });
          } else if (page.app.data.session.source == 'feishu') {
            var post_list_expense_paramrs = this.getListExpenseParams({
              "feishu_userid": feishu_userid,
            });
          } else {
            var post_list_expense_paramrs = this.getListExpenseParams({
              "wechat_name": vuserid,
            });
          }

          // var post_list_expense_paramrs = this.getListExpenseParams(vuserid);
          page.app.request.postJSON(
            page.app.data.url,
            post_list_expense_paramrs,
            function (result_list) {
              console.log(result_list);
              self.$setState({
                taskList: result_list.result.expense_line,
                taskListCount: result_list.result.expense_line.length,
              });

              // var sbExpense = page.app.searchbar.create({
              //   el: '[data-name="expense-index"] .searchbar-to-do',
              //   searchContainer: '[data-name="expense-index"] .list-expense-items',
              //   searchIn: '[data-name="expense-index"] .expense-item-text',
              //   notFoudEl: '[data-name="expense-index"] .searchbar-not-found-to-do',
              //   on: {
              //     enable: function () {
              //       console.log('Searchbar enabled')
              //     }
              //   }
              // });
            }
          );

          if (page.app.data.session.source == 'dingding') {
            var post_expense_item_head = this.getExpenseFieldsOptions("get_expense_item_head", {
              "dingding_code": dingding_code,
            });
            var post_expense_place = this.getExpenseFieldsOptions("get_expense_area", {
              "dingding_code": dingding_code,
            });
            var post_expense_supplier = this.getExpenseFieldsOptions("get_expense_supplier", {
              "dingding_code": dingding_code,
            });
          } else if (page.app.data.session.source == 'feishu') {
            var post_expense_item_head = this.getExpenseFieldsOptions("get_expense_item_head", {
              "feishu_userid": feishu_userid,
            });
            var post_expense_place = this.getExpenseFieldsOptions("get_expense_area", {
              "feishu_userid": feishu_userid,
            });
            var post_expense_supplier = this.getExpenseFieldsOptions("get_expense_supplier", {
              "feishu_userid": feishu_userid,
            });
          } else {
            var post_expense_item_head = this.getExpenseFieldsOptions("get_expense_item_head", {
              "wechat_name": vuserid,
            });
            var post_expense_place = this.getExpenseFieldsOptions("get_expense_area", {
              "wechat_name": vuserid,
            });
            var post_expense_supplier = this.getExpenseFieldsOptions("get_expense_supplier", {
              "wechat_name": vuserid,
            });
          }

          // var post_expense_item_head = this.getExpenseFieldsOptions(
          //   "get_expense_item_head"
          // );
          // var post_expense_place = this.getExpenseFieldsOptions(
          //   "get_expense_area"
          // );
          // var post_expense_supplier = this.getExpenseFieldsOptions(
          //   "get_expense_supplier"
          // );

          function getExpenseItemHead() {
            var promiseExpenseItemHead = new Promise(function (resolve, reject) {
              page.app.request.postJSON(
                page.app.data.url,
                post_expense_item_head,
                function (result_expense_item_head) {
                  console.log("getExpenseItemHead");
                  resolve(result_expense_item_head);
                }
              );
            });
            return promiseExpenseItemHead;
          }

          function getExpensePlace() {
            var promiseExpensePlace = new Promise(function (resolve, reject) {
              page.app.request.postJSON(
                page.app.data.url,
                post_expense_place,
                function (result_expense_place) {
                  console.log("promiseExpensePlace");
                  resolve(result_expense_place);
                }
              );
            });
            return promiseExpensePlace;
          }

          function getExpenseSupplier() {
            var promiseExpenseSupplier = new Promise(function (resolve, reject) {
              page.app.request.postJSON(
                page.app.data.url,
                post_expense_supplier,
                function (result_expense_supplier) {
                  console.log("getExpenseSupplier");
                  resolve(result_expense_supplier);
                }
              );
            });
            return promiseExpenseSupplier;
          }

          getExpenseItemHead()
            .then(function (result) {
              console.log(result);
              self.$setState({
                expenseItemHead: result.result.expense_item_head
              });

              page.app.data.expense_data.expenseItemHead =
                result.result.expense_item_head;
              return getExpensePlace();
            })
            .then(function (result) {
              console.log(result);
              self.$setState({
                expensePlace: result.result.expense_area
              });
              page.app.data.expense_data.expensePlace =
                result.result.expense_area;
              return getExpenseSupplier();
            })
            .then(function (result) {
              console.log(result);
              self.$setState({
                expenseSupplier: result.result.expense_supplier
              });
              page.app.data.expense_data.expenseSupplier =
                result.result.expense_supplier;
            });
        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          //初始化置空 元数据中的form_data
          if ("form_data" in page.app.data.expense_data) {
            delete page.app.data.expense_data["form_data"];
          }
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
          var sbExpense = page.app.searchbar.create({
            el: '[data-name="expense-index"] .searchbar-to-do',
            searchContainer: '[data-name="expense-index"] .list-expense-items',
            searchIn: '[data-name="expense-index"] .expense-item-text',
            notFoudEl: '[data-name="expense-index"] .searchbar-not-found-to-do',
            on: {
              enable: function () {
                console.log('Searchbar enabled')
              },
              search(sb, query, previousQuery) {
                console.log(query, previousQuery);
              }
            }
          });
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
          page.app.toolbar.hide(".expense-toolbar", false);
          $(".expense-toolbar").css("opacity", 0);
          $('.expense-checkbox input[type="checkbox"]').prop("checked", false);
          $(".expense-list-checkbox-icon").css("margin-left", "-80px");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  expenseForm,
  about
];