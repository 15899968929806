import $$ from "dom7";
import parts from "../pages/parts.html";
import Template7 from "template7";

export default {
  name: "parts",
  path: "/parts/",
  component: {
    template: parts,
    methods: {
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            var args = []
              args = [
                  dbname,
                  userid,
                  password,
                  "kthrp.asset.maintenance.work.order.interface",
                  method,
                  obj
                ]
            return {
              jsonrpc: "2.0",
              method: "call",
              params: {
                service: "object",
                method: "execute",
                args: args
              }
            };
          },
        backTo(){
            const {
                $$
              } = this;       
              var page = $$('.page[data-name="parts-form"]')[0].f7Page;
              page.router.navigate('/workOrderForm/',{
                
            })
        },
        save(){
            let formData = app.form.convertToData('#partsForm');
            let unit = app.smartSelect.get('.smart-select.unit');            
            formData.product_name = app.data.asset_data.form_data.product_name
            formData.unit_name = unit.$valueEl.text()
            console.log(formData)
            formData.product_id = formData.product
            formData.unit_id = formData.unit
            formData.estimate_qty = formData.consumption
            formData.specification = $$('.specification').text()
            if(formData.product == ''){
                app.dialog.alert('请选择产品！','系统提示')
                return
            }else if(formData.unit == ''){
                app.dialog.alert('请选择单位！','系统提示')
                return
            }else if(formData.consumption == ''){
                app.dialog.alert('请填写预计消耗量！','系统提示')
                return
            }
            app.data.asset_data.form_data.supplies.push(formData) 
            $$('.back').trigger("click")
        }
    },
    on: {
      pageInit: function (e, page) {
        console.log(page.router.history)
        page.app.form.fillFromData("#partsForm", {
            product: "",
            unit: "",
            consumption: ""
          });
        // var vuserid = page.app.data.session.userid;
        // var dingding_code = page.app.data.dingding_code;
        // var feishu_userid = page.app.data.feishu_userid;
        // var repairId = page.app.data.asset_data.form_data.id
        // if (page.app.data.session.source == 'dingding') {
        //     //获取产品列表的参数
        //     var productParams = this.getParams("get_product_info", {
        //       "repair_requisition_id": repairId,
        //       "dingding_code": dingding_code
        //     });
        //   } else {
        //     var productParams = this.getParams("get_product_info", {
        //       "repair_requisition_id": repairId,
        //       "wechat_name": vuserid
        //     });
        //   }
          //获取产品列表的接口
        //   page.app.request.postJSON(page.app.data.url, productParams, (res) => {
        //     if(res.result){
        //       console.log(res)
        //       this.$setState({
        //           productList: res.result.fields_value.product.values,
        //           unitList: []
        //       })
        //     }else{
        //         page.app.dialog.alert('获取信息错误!','系统提示')
        //     }  
        //   })
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        let product = page.app.smartSelect.get('.smart-select.product');
        product.on('close',()=>{
            let formData = app.form.convertToData('#partsForm');
            let obj = this.info.productList.find(ele=>ele.product_id == formData.product)
            if(obj){
                let vuserid = page.app.data.session.userid;
                let dingding_code = page.app.data.dingding_code;
                let feishu_userid = page.app.data.feishu_userid;
                let productId = obj.product_id
                if (page.app.data.session.source == 'dingding') {
                    //获取产品列表的参数
                    var productParams = this.getParams("get_product_unit", {
                    "product_id": productId,
                    "dingding_code": dingding_code
                    });
                } else if (page.app.data.session.source == 'feishu') {
                    //获取产品列表的参数
                    var productParams = this.getParams("get_product_unit", {
                    "product_id": productId,
                    "feishu_userid": feishu_userid
                    });
                } else {
                    var productParams = this.getParams("get_product_unit", {
                    "product_id": productId,
                    "wechat_name": vuserid
                    });
                }
                //获取单位列表的接口
                page.app.request.postJSON(page.app.data.url, productParams, (res) => {
                    if(res.result){
                    console.log(res)
                    this.$setState({
                        unitList: res.result.fields_value.map(ele=>{
                            return {
                                id: ele[0],
                                name: ele[1]
                            }
                        })
                    })
                    page.app.form.fillFromData("#partsForm", {
                        unit: obj.unit.default.length>0?obj.unit.default[0]:''
                      });
                    }else{
                        page.app.dialog.alert('获取信息错误!','系统提示')
                    }  
                })
                app.data.asset_data.form_data.product_name = obj.product_name
                $$('.specification').text(obj.specification)
            }
        })
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};