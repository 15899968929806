import $ from "dom7";
import salaryQueryFormView from "./salaryQueryFormView";
import html from "../pages/index.html";
import style from "../styles/index.less";
import app from "../../app";

export default {
    name: "/salaryQueryIndex/",
    path: "/salaryQueryIndex/",
    component: {
      template: html,
      style: style,
      methods: {
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            return {
                jsonrpc: "2.0",
                method: "call",
                params: {
                service: "object",
                method: "execute",
                args: [
                    dbname,
                    userid,
                    password,
                    "kthrp.payroll.salary.slip.month.interface",
                    method,
                    obj
                ]
                }
            };
            },
        toDetailsalaryQueryForm: function (e) {
          const id = e.currentTarget.dataset.id
            app.router.navigate("/salaryQueryFormView/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  id
                }
              });
        },
        create:function(){
          const self = this
          app.router.navigate("/salaryQueryForm/", {
            reloadCurrent: false,
            ignoreCache: true,
            context: {
              info:self.requisition_fields
            }
          });
        },
        waterMark(text){
          var shuiyinDiv = document.createElement('div');
          shuiyinDiv.className='shuiyinBox';
          var style = shuiyinDiv.style;
          style.position = 'fixed';
          style.left = '-50%';
          style.top = 0;
          style.width = '200%';
          style.minHeight = '100%';
          style.height = $('.page-current ul').height() + 'px';
          style.opacity = '0.1';
          style.background = "url("+this.textBecomeImg(text,22,"gray")+")";
          style.zIndex = 9999999991;
          style.transform = "rotate(-30deg)";
          style.pointerEvents = "none";
          document.body.appendChild(shuiyinDiv);
        },
        textBecomeImg(text,fontsize,fontcolor){
            var canvas = document.createElement('canvas');
            var $buHeight = 0;
            if(fontsize <= 32){ $buHeight = 99; }
            else if(fontsize > 32 && fontsize <= 60 ){ $buHeight = 2;}
            else if(fontsize > 60 && fontsize <= 80 ){ $buHeight = 4;}
            else if(fontsize > 80 && fontsize <= 100 ){ $buHeight = 6;}
            else if(fontsize > 100 ){ $buHeight = 10;}
            canvas.height=fontsize + $buHeight ;
            canvas.padding=30;
            var context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width*2, canvas.height);
            context.textAlign = "center";
            canvas.width = 420;
            canvas.height = 80;
            context.fillStyle = fontcolor;
            context.font=fontsize+"px Arial";
            context.textBaseline = 'middle'; 
            context.fillText(text,0,fontsize/2);
            var canvasWidth = canvas.width/99;
            canvasWidth = context.measureText(text).width;
            var dataUrl = canvas.toDataURL('image/png');
            return dataUrl;
        }
      },
      on: {
        pageInit: function (e, page) {
          $('.shuiyinBox').remove()
          var self = this;
          if(this.indexListCount){
            const text = this.indexList[0]['employee_no'] + ' ' + this.indexList[0]['employee_id']
            this.waterMark(text)
          }

          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var get_kanban_salary_slip_month = this.getParams("get_kanban_salary_slip_month", {
              "dingding_code": dingding_code
            });

          } else if (page.app.data.session.source == 'feishu') {
            var get_kanban_salary_slip_month = this.getParams("get_kanban_salary_slip_month", {
              "feishu_userid": feishu_userid
            });

          } else {
            var get_kanban_salary_slip_month = this.getParams("get_kanban_salary_slip_month", {
              "wechat_name": vuserid,
            });
          }
          page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
            $(e.currentTarget)
              .parent()
              .parent()
              .prev()
              .find(".searchbar input")
              .blur();
          });
            //未处理 搜索框实例
          var sbtodo = page.app.searchbar.create({
            el: '[data-name="salaryQuery-index"] .searchbar-to-do',
            searchContainer: '[data-name="salaryQuery-index"] .list-to-do',
            searchIn: '[data-name="salaryQuery-index"] .item-text-to-do',
            notFoudEl: '[data-name="salaryQuery-index"] .searchbar-not-found-to-do',
            });
            var sbdone = page.app.searchbar.create({
              el: ".searchbar-done",
              searchContainer: ".list-done",
              searchIn: ".item-text-done",
              notFoudEl: ".searchbar-not-found-done",
            });
            $('[data-name="salaryQuery-index"] .searchbar-done').hide();
            $('[data-name="salaryQuery-index"] .search-icon-2').hide();

            $('[data-name="salaryQuery-index"] .to-do-btn').on("click", function () {
              sbdone.disable();
              $('[data-name="salaryQuery-index"] .searchbar-done,[data-name="salaryQuery-index"] .search-icon-2').hide();
              $('[data-name="salaryQuery-index"] .searchbar-to-do,[data-name="salaryQuery-index"] .search-icon-1').show();
            });
            $('[data-name="salaryQuery-index"] .done-btn').on("click", function () {
              sbtodo.disable();
              $('[data-name="salaryQuery-index"] .searchbar-done,[data-name="salaryQuery-index"] .search-icon-2').show();
              $('[data-name="salaryQuery-index"] .searchbar-to-do,[data-name="salaryQuery-index"] .search-icon-1').hide();
          });
          
          // page.app.request.postJSON(
          //   page.app.data.url,
          //   get_kanban_salary_slip_month,
          //   function (result_list) {
          //     self.$setState({
          //       indexList:result_list.result.all_salary_slip_month,
          //       indexListCount:result_list.result.all_salary_slip_month.length
          //     });

          //     page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
          //       $(e.currentTarget)
          //         .parent()
          //         .parent()
          //         .prev()
          //         .find(".searchbar input")
          //         .blur();
          //     });
          //       //未处理 搜索框实例
          //     var sbtodo = page.app.searchbar.create({
          //       el: '[data-name="salaryQuery-index"] .searchbar-to-do',
          //       searchContainer: '[data-name="salaryQuery-index"] .list-to-do',
          //       searchIn: '[data-name="salaryQuery-index"] .item-text-to-do',
          //       notFoudEl: '[data-name="salaryQuery-index"] .searchbar-not-found-to-do',
          //       });
          //       var sbdone = page.app.searchbar.create({
          //         el: ".searchbar-done",
          //         searchContainer: ".list-done",
          //         searchIn: ".item-text-done",
          //         notFoudEl: ".searchbar-not-found-done",
          //       });
          //       $('[data-name="salaryQuery-index"] .searchbar-done').hide();
          //       $('[data-name="salaryQuery-index"] .search-icon-2').hide();

          //       $('[data-name="salaryQuery-index"] .to-do-btn').on("click", function () {
          //         sbdone.disable();
          //         $('[data-name="salaryQuery-index"] .searchbar-done,[data-name="salaryQuery-index"] .search-icon-2').hide();
          //         $('[data-name="salaryQuery-index"] .searchbar-to-do,[data-name="salaryQuery-index"] .search-icon-1').show();
          //       });
          //       $('[data-name="salaryQuery-index"] .done-btn').on("click", function () {
          //         sbtodo.disable();
          //         $('[data-name="salaryQuery-index"] .searchbar-done,[data-name="salaryQuery-index"] .search-icon-2').show();
          //         $('[data-name="salaryQuery-index"] .searchbar-to-do,[data-name="salaryQuery-index"] .search-icon-1').hide();
          //     });
          //   }
          // );
          

        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  };