import $$ from "dom7";
import assetViewFormHtml from "../pages/assetViewForm.html";
import Template7 from "template7";
import * as dd from 'dingtalk-jsapi'

// import 'weui';


export default {
  name: "assetviewform",
  path: "/assetviewform/",
  component: {
    template: assetViewFormHtml,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = []
          args = [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.repair.requisition.interface",
              method,
              obj
            ]
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args
          }
        };
      },
      goScore(){
        const self = this
        const page = $$('.page[data-name="asset-view-form"]')[0].f7Page;
        page.router.navigate("/score/", {
          reloadCurrent: false,
          ignoreCache: true,
          context: {
            repair_requisition_id: self.info.id
          }
        });
      },
      previewImage: function (e) {
        const {
          $$
        } = this;
        var page = $$('.page[data-name="asset-view-form"]')[0].f7Page;
        var nowurl = e.currentTarget.dataset.src;
        if (page.app.data.session.source == 'dingding') {
          dd.ready(function () {
            dd.biz.util.previewImage({
              urls: page.app.data.asset_data.form_data.images_list, //图片地址列表
              current: nowurl, //当前显示的图片链接
              onSuccess: function (result) {
                console.log(result)
              },
              onFail: function (err) {
                console.log(err)
              }
            })
          });
        } else if (page.app.data.session.source == 'feishu') {
          window.tt.ready(function () {
            window.tt.previewImage({
              urls: page.app.data.asset_data.form_data.images_list, //图片地址列表
              current: nowurl, //当前显示的图片链接
              success: function (result) {
                console.log(result)
              },
              fail: function (err) {
                console.log(err)
              }
            })
          });
        } else {
          wx.previewImage({
            current: nowurl,
            urls: page.app.data.asset_data.form_data.images_list
          });
        }
      },
      remind:function(e){
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        var asset_maintenance_line_id = this.info.id
        
        if (app.data.session.source == 'dingding') {
            var createParams = this.getParams("action_remind", {
              "dingding_code": dingding_code,
              "asset_maintenance_line_id": asset_maintenance_line_id
            });
          } else if (app.data.session.source == 'feishu') {
            var createParams = this.getParams("action_remind", {
              "feishu_userid": feishu_userid,
              "asset_maintenance_line_id": asset_maintenance_line_id
            });
          } else {
            var createParams = this.getParams("action_remind", {
              "wechat_name": vuserid,
              "asset_maintenance_line_id": asset_maintenance_line_id
            });

          }
          app.preloader.show()
          app.request.postJSON(app.data.url, createParams ,(result) => {
            if(result.result&&result.result.state == "success"){ 
              app.preloader.hide()
              var toast = app.toast.create({
                text: "已发送催单消息!",
                position: "center",
                closeTimeout: 2000
              });
              toast.open();
            }else{
              // var toast = app.toast.create({
              //   text: result.result.message,
              //   position: "center",
              //   closeTimeout: 2000
              // });
              // toast.open();
              app.preloader.hide()
              app.dialog.alert(result.result.message,'系统提示')
            }          
          });
      } 
    },
    on: {
      pageInit: function (e, page) {
        if(this.info.attachment_ids.length){
          let images = []
          let files = []
          this.info.attachment_ids.forEach(ele=>{
            if(ele.name.indexOf('jpg')>-1 || ele.name.indexOf('png')>-1 || ele.name.indexOf('gif')>-1){
              images.push(ele)
            }else{
              files.push(ele)
            }
          })
          this.$setState({
              images:images,
              files:files  
          })
          $('.pb-standalone-dark').on('click', function (event) {
            // 照片预览器
         let myPhotoBrowserDark = app.photoBrowser.create({
           photos : images,
           theme: 'dark'
         });
         myPhotoBrowserDark.on('closed',()=>{
           myPhotoBrowserDark.destroy()
         })
           myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
         });
        }
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};