import $$ from 'dom7'
import _ from 'lodash'
import * as dd from "dingtalk-jsapi"
import Framework7 from 'framework7/framework7.esm.bundle'
import style from '../styles/home.less'
import html from '../pages/view-home.html'

function GetQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

export default [{
  name: 'home',
  path: '/home/',
  component: {
    template: html,
    style: style,
    data: () => {
      return {}
    },
    methods: {
      getListParams: function (code, vuserid, model,res) {
        var dbname = this.$app.data.dbname
        var userid = this.$app.data.userid
        var password = this.$app.data.password
        if(res){
          return {
            jsonrpc: '2.0',
            method: 'call',
            params: {
              service: 'object',
              method: 'execute',
              args: [
                dbname,
                userid,
                password,
                model,
                'mobile_workflow_info_list',
                {
                  status: 'to_be_approved',
                  url: location.href.split('#')[0],
                  ...res,
                  get_data_type: 'to_be_approved'
                }
              ]
            }
          }
        }else{
          return {
            jsonrpc: '2.0',
            method: 'call',
            params: {
              service: 'object',
              method: 'execute',
              args: [
                dbname,
                userid,
                password,
                model,
                'mobile_workflow_info_list',
                {
                  auth_code: code,
                  user_id: vuserid,
                  status: 'to_be_approved',
                  url: location.href.split('#')[0],
                  get_data_type: 'to_be_approved'
                }
              ]
            }
          }
        }
      },
      get_auth_code: function (corp_id) {
        var self = this;
        var promise = new Promise(function (resolve, reject) {
          dd.ready(function () {
            // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
            dd.runtime.permission.requestAuthCode({
              corpId: corp_id,
              onSuccess: function (result) {
                //dd string authCode
                console.log(result);
                resolve(result);
              },
              onFail: function (err) {
                reject(err);
              }
            });
          });
        });
        return promise;
      },
      getUserId(page,code){
        console.log('getUserId')
        var self = this;
        var dbname = page.app.data.client_dbname
        var userid = page.app.data.client_username
        var password = page.app.data.client_password
        var user_id = page.app.data.userid
        return new Promise(function (resolve, reject) {
          page.app.request.postJSON(
            page.app.data.client_url, {
              jsonrpc: '2.0',
              method: 'call',
              params: {
                service: 'object',
                method: 'execute',
                args: [
                  dbname,
                  userid,
                  password,
                  'kthrp.dingding.client.interface',
                  'get_user_id',
                  {
                    auth_code: code
                  }
                ]
              }
            },
            function (result) {
              if(result.result && result.result.result == 'success'){
                page.app.data.get_user_id_data = result.result.user_info
                resolve(result.result)
              }else{
                reject(result.result.error_msg)
              }
            }
          )
        });
      },
      apiAuth(page) {
        let self = this;
        let appID = GetQueryString('appid')
        if (window.tt.requestAccess && appID) {
          window.tt.requestAccess({
            // 网页应用 App ID
            appID: appID,
            scopeList: [], // contact:user.base:readonly
            success: (res) => {
              // 用户授权后返回预授权码
              const { code } = res;
              console.log("code:", code);
              app.data.code = code
              self.getAuthCode(page,code).then(function(result){
                console.log("result:", result);
                self.feishuPart(page,code, { user_id: result.user_id })
              })
            },
            fail: (error) => {
              // 需要额外根据errno判断是否为 客户端不支持requestAccess导致的失败
              const { errno, errString } = error;
              if (errno === 103) {
                // 客户端版本过低，不支持requestAccess，需要改为调用requestAuthCode
                callRequestAuthCode(page, appID);
              } else {
                // 用户拒绝授权或者授权失败
                console.log("授权失败", error);
              }
            },
          });
        } else { // JSSDK版本过低，不支持requestAccess，需要改为调用requestAuthCode
          callRequestAuthCode(page, appID);
        }
      },
      callRequestAuthCode(page, appID) {
        window.tt.requestAuthCode({
          // 网页应用 App ID
          appId: appID,
          success: (res) => {
            // 用户免登录后返回预授权码
            const { code } = res;
            console.log("授权成功");
            app.data.code = code
            self.getAuthCode(page,code).then(function(result){
              self.feishuPart(page,code, { user_id: result.user_id })
            })
          },
          fail: (error) => {
            // 免登失败，返回相应的errno和errString
            const { errno, errString } = error;
            console.log("授权失败", error);
          },
        });
      },
      getUserIdWX(page){
        var self = this;
        var dbname = page.app.data.client_dbname
        var userid = page.app.data.client_username
        var password = page.app.data.client_password
        var vuserid = page.app.data.session.userid
        return new Promise(function (resolve, reject) {
          page.app.request.postJSON(
            page.app.data.client_url, {
              jsonrpc: '2.0',
              method: 'call',
              params: {
                service: 'object',
                method: 'execute',
                args: [
                  dbname,
                  userid,
                  password,
                  'kthrp.wechat.client.interface',
                  'get_wechat_user_id',
                  {
                    user_id:vuserid,
                    url: location.href.split('#')[0],
                    'auth_code': GetQueryString('code')
                  }
                ]
              }
            },
            function (result) {
              if(result.result && result.result.result == 'success'){
                page.app.data.get_user_id_data = result.result.user_info
                resolve(result.result)
              }else{
                reject(result.result.error_msg)
              }
            }
          )
        });
      },
      getAuthCode(page,code){
        console.log('getAuthCode', page)
        var self = this;
        var dbname = page.app.data.dbname
        var userid = page.app.data.userid
        var password = page.app.data.password
        return new Promise(function (resolve, reject) {
          page.app.request.postJSON(
            page.app.data.url, {
              jsonrpc: '2.0',
              method: 'call',
              params: {
                service: 'object',
                method: 'execute',
                args: [
                  dbname,
                  userid,
                  password,
                  'kthrp.feishu.interface',
                  'get_feishu_userid',
                  {
                    auth_code: code
                  }
                ]
              }
            },
            function (result) {
              console.log("用户信息:", result);
              if(result.result && result.result.state == 'success'){
                page.app.data.get_user_id_data = {
                  ...page.app.data.get_user_id_data,
                  user_id: result.result.user_id,
                  feishu_userid: result.result.user_id
                }
                resolve(result.result)
              }else{
                reject(result.result.error_msg)
              }
            }
          )
        });
      },
      tongyong_create(e){
        var url = e.currentTarget.dataset.url
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        var mobile_user = ''
        var source = ''
        if(app.data.session.source == "dingding") {
          source = 'dingding'
          mobile_user = dingding_code
        } else if(app.data.session.source == "feishu") {
          source = 'feishu'
          mobile_user = feishu_userid
        } else {
          source = 'wechat'
          mobile_user = vuserid
        }
        if(url){
          var url_arr = url.split('?')
          location.href =`${url_arr[0]}?from_map=True&source=${source}&mobile_user=${mobile_user}&${url_arr[1]}`
        }
      },
      dingdingPart: function(page,code,res){
        var self = this
        var vuserid = page.app.data.session.userid
        var model = "kthrp.dingding.interface"
        var post_list_params = self.getListParams(code, vuserid, model,res)
        page.app.request.postJSON(page.app.data.url, post_list_params, function (result_list) {
          page.app.data.kthrp_customer_code = result_list.result.kthrp_customer_code
          page.app.data.dingding_code = result_list.result.userid
          page.app.data.wxConfig = result_list
          console.log(result_list)
          if (result_list.result.kthrp_customer_code === 'jiahui') {
            $$('.homepage-banner').addClass('jiahui-homepage-banner');
          } else {
            $$('.homepage-banner').addClass('kthrp-homepage-banner');
          }
          if (result_list.result.result == 'error') {
            // page.app.panel.get("left").destroy();
            page.app.progressbar.hide(self.progressbar)
            page.app.dialog.alert('当前企业钉钉标识未维护，请联系系统管理员。', 'KTHRP')
            return
          }

          $$('.homepage-cover').css('display', 'none')

          page.app.data.session.userid = result_list.result.userid
          result_list.result.task_list.length =
            result_list.result.task_list.length;
          result_list.result.task_list_approved.length =
            result_list.result.task_list_approved.length;
          self.$setState({
            taskList: result_list.result.task_list,
            taskListCount: result_list.result.task_list_count,
            taskListApproved: result_list.result.task_list_approved,
            taskListApprovedCount: result_list.result.task_list_approved_count,
            customerCode: result_list.result.kthrp_customer_code,
            iconDisplayDict: result_list.result.icon_display_dict,
            map_general_creates: result_list.result.map_general_creates,
            lang: page.app.data.session.lang,
          });
          if (result_list.result.kthrp_customer_code == 'kthrp') {
            $$('.kthrp_menu').css('display', 'flex')
          }

          dd.config({
            agentId: result_list.result.signature_info.agentId, // 必填，微应用ID
            corpId: result_list.result.signature_info.corpId, //必填，企业ID
            timeStamp: parseInt(result_list.result.signature_info.timeStamp), // 必填，生成签名的时间戳
            nonceStr: result_list.result.signature_info.nonceStr, // 必填，生成签名的随机串
            signature: result_list.result.signature_info.signature, // 必填，签名
            type: 0, //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
            jsApiList: [
              'biz.util.uploadImage',
            ] // 必填，需要使用的jsapi列表，注意：不要带dd。
          });

          dd.error(function (error) {
            /**
             {
                errorMessage:"错误信息",// errorMessage 信息会展示出钉钉服务端生成签名使用的参数，请和您生成签名的参数作对比，找出错误的参数
                errorCode: "错误码"
              }
            **/
            alert('dd error: ' + JSON.stringify(error));
          });

          page.app.data.session.userid = result_list.result.userid

          if (result_list.result.result === 'ok') {
            // 支持配置主页地址跳转至具体页面
            var res_id = parseInt(GetQueryString('res_id')) // 工资条详情id
            if(page.app.data.session.router){
              var inRoutes = page.app.routes.some(function(e) {
                return e.path == "/" + page.app.data.session.router + "/"
              })
              if(inRoutes){
                var context = {
                  id: res_id
                }
                page.router.navigate("/" + page.app.data.session.router + "/", {
                  // reloadCurrent: true,
                  animate: false,
                  ignoreCache: true,
                  context
                });
              }
            }
            page.app.progressbar.hide(self.progressbar)
          }
          var key = parseInt(GetQueryString('state'))
          if (key && page.app.data.flag) {
            var dbname = page.app.data.dbname
            var userid = page.app.data.userid
            var password = page.app.data.password
            var user_id = page.app.data.session.userid
            page.app.request.postJSON(
              page.app.data.url, {
                jsonrpc: '2.0',
                method: 'call',
                params: {
                  service: 'object',
                  method: 'execute',
                  args: [
                    dbname,
                    userid,
                    password,
                    'kthrp.dingding.interface',
                    'mobile_workflow_info',
                    {
                      task_id: key,
                      is_general_map: true,
                      lang: page.app.data.session.lang,
                      user_id
                    }
                  ]
                }
              },
              function (result_detail) {
                console.log(result_detail)
                var record_model = result_detail.result.info.model
                var type = result_detail.result.info.doc_type ? result_detail.result.info.doc_type : ''
                result_detail.result.info.customerCode = page.app.data.session.kthrp_customer_code
                if (result_detail.result.result == 'error') {
                  page.app.dialog.alert('当前钉钉未标识未维护，请联系系统管理员。', 'KTHRP')
                  return
                }
                //FIXME:没有语言环境
                if (result_detail.result.info.is_tongyong || result_detail.result.info.is_general_map) {
                  page.router.navigate("/tongyong/", {
                    reloadCurrent: false,
                    ignoreCache: true,
                    context: {
                      info: result_detail.result.info,
                      lang: page.app.data.session.lang
                    }
                  });
                } else {
                  var nModel = type == "" ? "/" + record_model + "/" : "/" + record_model + "." + type + "/";
                  page.router.navigate(nModel, {
                    reloadCurrent: false,
                    ignoreCache: true,
                    context: {
                      info: result_detail.result.info,
                      lang: page.app.data.session.lang
                    }
                  });
                }
                page.app.data.flag = false
              }
            )
          }
        })
      },
      feishuPart: function(page, code, res){
        let self = this
        let vuserid = page.app.data.session.userid
        let model = "kthrp.feishu.map.interface"
        let post_list_params = self.getListParams(code, vuserid, model,res)
        page.app.request.postJSON(page.app.data.url, post_list_params, function (result_list) {
          console.log(result_list)
          page.app.data.kthrp_customer_code = result_list.result.kthrp_customer_code
          page.app.data.feishu_userid = result_list.result.userid

          $$('.homepage-cover').css('display', 'none')
          if (result_list.result.kthrp_customer_code == 'kthrp') {
            $$('.kthrp_menu').css('display', 'flex')
          }

          page.app.data.session.userid = result_list.result.userid
          result_list.result.task_list.length =
            result_list.result.task_list.length;
          result_list.result.task_list_approved.length =
            result_list.result.task_list_approved.length;
          self.$setState({
            taskList: result_list.result.task_list,
            taskListCount: result_list.result.task_list_count,
            taskListApproved: result_list.result.task_list_approved,
            taskListApprovedCount: result_list.result.task_list_approved_count,
            customerCode: result_list.result.kthrp_customer_code,
            iconDisplayDict: result_list.result.icon_display_dict,
            map_general_creates: result_list.result.map_general_creates,
            lang: page.app.data.session.lang,
          });


          if (result_list.result.result === 'ok') {
            // 支持配置主页地址跳转至具体页面
            var res_id = parseInt(GetQueryString('res_id')) //工资条详情id
            if(page.app.data.session.router){
              var inRoutes = page.app.routes.some(function(e) {
                return e.path == "/" + page.app.data.session.router + "/"
              })
              if(inRoutes){
                var context = {
                  id: res_id
                }
                page.router.navigate("/" + page.app.data.session.router + "/", {
                  // reloadCurrent: true,
                  animate: false,
                  ignoreCache: true,
                  context
                });
              }
            }
            page.app.progressbar.hide(self.progressbar)
          }
          var key = parseInt(GetQueryString('state'))
          if (key && page.app.data.flag) {
            var dbname = page.app.data.dbname
            var userid = page.app.data.userid
            var password = page.app.data.password
            var user_id = page.app.data.session.userid
            page.app.request.postJSON(
              page.app.data.url, {
                jsonrpc: '2.0',
                method: 'call',
                params: {
                  service: 'object',
                  method: 'execute',
                  args: [
                    dbname,
                    userid,
                    password,
                    'kthrp.feishu.map.interface',
                    'mobile_workflow_info',
                    {
                      task_id: key,
                      feishu_userid: user_id
                    }
                  ]
                }
              },
              function (result_detail) {
                console.log(result_detail)
                var record_model = result_detail.result.info.model
                var type = result_detail.result.info.doc_type ? result_detail.result.info.doc_type : ''
                result_detail.result.info.customerCode = page.app.data.session.kthrp_customer_code
                if (result_detail.result.result == 'error') {
                  page.app.dialog.alert('当前企业飞书标识未维护，请联系系统管理员。', 'KTHRP')
                  return
                }
                //FIXME:没有语言环境
                if (result_detail.result.info.is_tongyong || result_detail.result.info.is_general_map) {
                  page.router.navigate("/tongyong/", {
                    reloadCurrent: false,
                    ignoreCache: true,
                    context: {
                      info: result_detail.result.info,
                      lang: page.app.data.session.lang
                    }
                  });
                } else {
                  var nModel = type == "" ? "/" + record_model + "/" : "/" + record_model + "." + type + "/";
                  page.router.navigate(nModel, {
                    reloadCurrent: false,
                    ignoreCache: true,
                    context: {
                      info: result_detail.result.info,
                      lang: page.app.data.session.lang
                    }
                  });
                }
                page.app.data.flag = false
              }
            )
          }
        })
      },
      wxPart: function(page,res){
        var self = this
        var code = page.app.data.session.code
        var vuserid = page.app.data.session.userid
        var model = "kthrp.wechat.map.interface"
        var post_list_params = self.getListParams(code, vuserid, model,res)
        page.app.request.postJSON(page.app.data.url, post_list_params, function (result_list) {
          page.app.data.wxConfig = result_list
          console.log(result_list)
          page.app.data.kthrp_customer_code = result_list.result.kthrp_customer_code
          if (result_list.result.kthrp_customer_code === 'jiahui') {
            $$('.homepage-banner').addClass('jiahui-homepage-banner');
          } else {
            $$('.homepage-banner').addClass('kthrp-homepage-banner');
          }
          if (result_list.result.result == 'error') {
            // page.app.panel.get("left").destroy();
            page.app.progressbar.hide(self.progressbar)
            page.app.dialog.alert('当前企业微信标识未维护，请联系系统管理员。', 'KTHRP')
            return
          }

          $$('.homepage-cover').css('display', 'none')
          if (result_list.result.kthrp_customer_code == 'kthrp') {
            $$('.kthrp_menu').css('display', 'flex')
          }
          wx.config({
            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result_list.result.signature_info.appid, // 必填，企业微信的corpID
            timestamp: parseInt(result_list.result.signature_info.timestamp), // 必填，生成签名的时间戳
            nonceStr: result_list.result.signature_info.noncestr, // 必填，生成签名的随机串
            signature: result_list.result.signature_info.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['chooseImage', 'scanQRCode', 'previewFile', 'getLocalImgData', 'uploadImage'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          })

          wx.ready(function () {
            wx.checkJsApi({
              jsApiList: ['chooseImage', 'scanQRCode', 'previewFile', 'getLocalImgData', 'uploadImage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function (ress) {
                console.log(ress)
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
              }
            })
          })
          wx.error(function (res) {
            console.log(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          })

          page.app.data.session.userid = result_list.result.userid
          result_list.result.task_list.length =
            result_list.result.task_list.length;
          result_list.result.task_list_approved.length =
            result_list.result.task_list_approved.length;
          self.$setState({
            taskList: result_list.result.task_list,
            taskListCount: result_list.result.task_list_count,
            taskListApproved: result_list.result.task_list_approved,
            taskListApprovedCount: result_list.result.task_list_approved_count,
            customerCode: result_list.result.kthrp_customer_code,
            iconDisplayDict: result_list.result.icon_display_dict,
            map_general_creates: result_list.result.map_general_creates,
            lang: page.app.data.session.lang,
          });


          if (result_list.result.result === 'ok') {
            // 支持配置主页地址跳转至具体页面
            var res_id = parseInt(GetQueryString('res_id')) //工资条详情id
            if(page.app.data.session.router){
              var inRoutes = page.app.routes.some(function(e) {
                return e.path == "/" + page.app.data.session.router + "/"
              })
              if(inRoutes){
                var context = {
                  id: res_id
                }
                page.router.navigate("/" + page.app.data.session.router + "/", {
                  // reloadCurrent: true,
                  animate: false,
                  ignoreCache: true,
                  context
                });
              }
            }
            page.app.progressbar.hide(self.progressbar)
          }
          var key = parseInt(GetQueryString('state'))
          if (key && page.app.data.flag) {
            var dbname = page.app.data.dbname
            var userid = page.app.data.userid
            var password = page.app.data.password
            var user_id = page.app.data.session.userid
            page.app.request.postJSON(
              page.app.data.url, {
                jsonrpc: '2.0',
                method: 'call',
                params: {
                  service: 'object',
                  method: 'execute',
                  args: [
                    dbname,
                    userid,
                    password,
                    'kthrp.wechat.map.interface',
                    'mobile_workflow_info',
                    {
                      task_id: key,
                      user_id
                    }
                  ]
                }
              },
              function (result_detail) {
                console.log(result_detail)
                var record_model = result_detail.result.info.model
                var type = result_detail.result.info.doc_type ? result_detail.result.info.doc_type : ''
                result_detail.result.info.customerCode = page.app.data.session.kthrp_customer_code
                if (result_detail.result.result == 'error') {
                  page.app.dialog.alert('当前企业微信标识未维护，请联系系统管理员。', 'KTHRP')
                  return
                }
                //FIXME:没有语言环境
                if (result_detail.result.info.is_tongyong || result_detail.result.info.is_general_map) {
                  page.router.navigate("/tongyong/", {
                    reloadCurrent: false,
                    ignoreCache: true,
                    context: {
                      info: result_detail.result.info,
                      lang: page.app.data.session.lang
                    }
                  });
                } else {
                  var nModel = type == "" ? "/" + record_model + "/" : "/" + record_model + "." + type + "/";
                  page.router.navigate(nModel, {
                    reloadCurrent: false,
                    ignoreCache: true,
                    context: {
                      info: result_detail.result.info,
                      lang: page.app.data.session.lang
                    }
                  });
                }
                page.app.data.flag = false
              }
            )
          }
        })
      }
    },
    on: {
      pageMounted: function (e, page) {
        //TODO
      },
      pageBeforeRemove: function (e, page) {},
      pageAfterIn: function (e, page) {},
      pageInit: function (e, page) {
        var self = this
        var progressbar = page.app.progressbar.show('.demo-determinate-container')
        self.$setState({
          progressbar: progressbar
        });
        if (localStorage.getItem("lang")) {
          page.app.data.session.lang = localStorage.getItem("lang");
        }
        page.app.request({
          async: false
        })
        page.app.request.postJSON(
          page.app.data.url, {
            jsonrpc: '2.0',
            method: 'call',
            params: {
              service: 'common',
              method: 'login',
              args: [page.app.data.dbname, page.app.data.username, page.app.data.password]
            }
          },
          function (login_result) {
            console.log(login_result)

            if (page.app.data.session.source === "dingding") {
              /* dingding start */
              page.app.data.userid = login_result.result
              var vuserid = page.app.data.session.userid
              var model = "kthrp.dingding.interface"
              self.get_auth_code(page.app.data._config.dd_config.corp_id).then(function (code) {
                
                if(page.app.data._config.send_type == 'map'){
                  self.getUserId(page,code).then(function(res){
                    self.dingdingPart(page,code,res.user_info)
                  })
                }else{
                  self.dingdingPart(page,code)
                }
              });

              /** dingding end */
            } else if (page.app.data.session.source === "feishu") {
              /* feishu start */
              const code = app.data.code
              const user_id = app.data.feishu_userid
              page.app.data.userid = login_result.result
              console.log("code", code, user_id);
              if(!user_id) {
                self.apiAuth(page)
              } else {
                self.feishuPart(page,code, { user_id })
              }
              /** feishu end */
            } else {
              /* wechat start*/
              page.app.data.userid = login_result.result

              if(page.app.data._config.send_type == 'map'){
                self.getUserIdWX(page).then(function(res){
                  self.wxPart(page,res.user_info)
                })

              }else{
                self.wxPart(page)
              }
              /* wechat end*/
            }
          }
        )
      }
    }
  }
}]