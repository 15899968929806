import $ from "dom7";
import regularizationAppForm from "./regularizationAppForm";
import regularizationAppViewForm from "./regularizationAppViewForm";
import regularizationAppFormLineDetail from "./regularizationAppFormLineDetail";
import preIndex from "./preIndex";
import about from "./about";
import html from "../pages/index.html";
import style from "../styles/index.less";
import app from "../../app";
export default [{
    name: "/regularizationapplist/",
    path: "/regularizationapplist/",
    component: {
      template: html,
      style: style,
      methods: {
        getUserObj: function () {
          if (this.$app.data.session.source == 'dingding') {
            return {
              dingding_code: this.$app.data.dingding_code
            }
          } else if (this.$app.data.session.source == 'feishu') {
            return {
              feishu_userid: this.$app.data.feishu_userid
            }
          } else {
            return {
              wechat_name: this.$app.data.session.userid
            }
          }
        },
        toDetail: function (e) {
          let self = this;
          let id = parseInt(e.currentTarget.dataset.id);
          let obj = this.getUserObj()
          obj.change_apply_id = parseInt(id)
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.employee.change.apply.interface', 'get_change_apply_details', obj), function (res) {
            console.log(res);
            self.$app.router.navigate("/regularizationappviewform/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                info: res.result.data
              }
            });
          });
        },
        toForm: function (e) {
          this.$app.router.navigate("/changepreindex/", {
            reloadCurrent: false,
            ignoreCache: true
          });
          // var self = this
          // this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.employee.change.apply.interface', 'get_other_fields', this.getUserObj()), function (res) {
          //   console.log(res);
          //   self.$app.router.navigate("/regularizationappform/", {
          //     reloadCurrent: false,
          //     ignoreCache: true,
          //     context: {
          //       info: res.result
          //     }
          //   });
          // });
        }
      },
      on: {
        pageInit: function (e, page) {
          let self = this
          const {
            $$
          } = this
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.employee.change.apply.interface', 'get_change_apply', this.getUserObj()), function (res) {
            console.log(res)        
            self.$setState({
              all_change_apply: res.result.all_change_apply,
              all_change_apply_count:res.result.all_change_apply.length,
              processing_change_apply: res.result.processing_change_apply,
              processing_change_apply_count: res.result.processing_change_apply.length,
            });
            //未处理 搜索框实例
            var sbtodo = page.app.searchbar.create({
              el: '[data-name="regularization-index"] .searchbar-to-do',
              searchContainer: '[data-name="regularization-index"] .list-to-do',
              searchIn: '[data-name="regularization-index"] .item-text-to-do',
              notFoudEl: '[data-name="regularization-index"] .searchbar-not-found-to-do',
            });
            var sbdone = page.app.searchbar.create({
              el: ".searchbar-done",
              searchContainer: ".list-done",
              searchIn: ".item-text-done",
              notFoudEl: ".searchbar-not-found-done",
            });
            $('[data-name="regularization-index"] .searchbar-done').hide();
            $('[data-name="regularization-index"] .search-icon-2').hide();

            $('[data-name="regularization-index"] .to-do-btn').on("click", function () {
              sbdone.disable();
              $('[data-name="regularization-index"] .searchbar-done,[data-name="regularization-index"] .search-icon-2').hide();
              $('[data-name="regularization-index"] .searchbar-to-do,[data-name="regularization-index"] .search-icon-1').show();
            });
            $('[data-name="regularization-index"] .done-btn').on("click", function () {
              sbtodo.disable();
              $('[data-name="regularization-index"] .searchbar-done,[data-name="regularization-index"] .search-icon-2').show();
              $('[data-name="regularization-index"] .searchbar-to-do,[data-name="regularization-index"] .search-icon-1').hide();
            });
          })
        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          if(page.app.data.kthrp_customer_code == 'huangjiali')$('.changed').hide()  //根据kthrp_customer_code判断  皇家丽隐藏异动相关的字段
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  regularizationAppForm,
  regularizationAppViewForm,
  regularizationAppFormLineDetail,
  about,
  preIndex
];