/*
 * @Author: liubaozhen
 * @Date: 2023-07-04 20:50:43
 * @LastEditTime: 2024-04-18 13:47:18
 * @LastEditors: liubaozhen
 * @Description: 
 * @FilePath: \odoo\kthrp_map\src\contractRenewalApp\routes\contractRenewalAppForm.js
 */
import $$ from "dom7";
import contractRenewalAppForm from "../pages/contractRenewalAppForm.html";
import Template7 from "template7";

export default {
  name: "contractrenewalappform",
  path: "/contractrenewalappform/",
  component: {
    template: contractRenewalAppForm,
    data: () => {
      return {};
    },
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      getFileFormData: function (files) {
        let self = this
        let formData = new FormData()
        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        if (self.$app.data.session.source == 'dingding') {
          formData.append('dingding_code', self.$app.data.dingding_code)
        } else if (self.$app.data.session.source == 'feishu') {
          formData.append('feishu_userid', self.$app.data.feishu_userid)
        } else {
          formData.append('wechat_name', self.$app.data.session.userid)
        }
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          formData.append("file", file)
        }
        return formData
      },
      submit: function (e) {
        let self = this
        let page = $$('.page[data-name="contractrenewal-form"]')[0].f7Page
        let contractrenewalFormData = self.$app.form.convertToData("#contractrenewal-form");
        let userObj = self.getUserObj()
        let post_file_url = this.$app.data.base_url + '/web/map_upload_attachment'
        let files = this.$$('#file-attach')[0].files
        if (!contractrenewalFormData.employee) {
          return self.$app.dialog.alert('续签员工必须有值!', "KTHRP");
        }
        if (!contractrenewalFormData.renewal_contract) {
          return self.$app.dialog.alert('续签合同必须有值!', "KTHRP");
        }
        if (!contractrenewalFormData.signing_type) {
          return self.$app.dialog.alert('签约类型必须有值!', "KTHRP");
        }
        if (!contractrenewalFormData.contract_type) {
          return self.$app.dialog.alert('合同类型必须有值!', "KTHRP");
        }
        if (!contractrenewalFormData.effect_date) {
          return self.$app.dialog.alert('有效期自必须有值!', "KTHRP");
        }
        if (!contractrenewalFormData.expiry_date) {
          return self.$app.dialog.alert('有效期至必须有值!', "KTHRP");
        }
        if (contractrenewalFormData.effect_date >= contractrenewalFormData.expiry_date) {
          return self.$app.dialog.alert('有效期至必须大于有效期自!', "KTHRP");
        }
        let newContractrenewalFormData = {}
        newContractrenewalFormData.employee_id = parseInt(contractrenewalFormData.employee)
        newContractrenewalFormData.renewal_contract_id = parseInt(contractrenewalFormData.renewal_contract)
        newContractrenewalFormData.contract_type_id = parseInt(contractrenewalFormData.contract_type)
        newContractrenewalFormData.selection = contractrenewalFormData.signing_type
        newContractrenewalFormData.note = contractrenewalFormData.note
        newContractrenewalFormData.effect_date = contractrenewalFormData.effect_date
        newContractrenewalFormData.expiry_date = contractrenewalFormData.expiry_date
        newContractrenewalFormData.new_contract_num = contractrenewalFormData.new_contract_num

        let obj = {
          ...newContractrenewalFormData,
          ...userObj
        }
        console.log(obj)
        self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.contract.renewal.application.interface', 'create_contract_renewal_application', obj), function (res) {
          console.log(res);
          if (res.result.state === 'ok') {
            if (files.length === 0) {
              var toast = app.toast.create({
                text: '恭喜您,操作成功',
                position: 'top',
                closeTimeout: 2000
              })
              toast.open()
              page.router.navigate('/contractrenewalapplist/', {})
            } else {
              let formData = self.getFileFormData(self.$$('#file-attach')[0].files)
              formData.append('model_name', res.result.data.model_name)
              formData.append('record_id', res.result.data.record_id)
              self.$app.request.post(post_file_url, formData, function (result) {
                console.log(result)
                if (JSON.parse(result).state === 'ok') {
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  page.router.navigate('/contractrenewalapplist/', {})
                } else {
                  var toast = app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  page.router.navigate('/contractrenewalapplist/', {})
                }
              });
            }
          } else {
            self.$app.dialog.alert(res.result.err, "KTHRP");
          }
        });
      },

    },
    on: {
      pageInit: function (e, page) {
        let self = this;
        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });
        page.app.calendar.create({
          inputEl: '#effect_date',
          dateFormat: "yyyy-mm-dd",
          openIn: 'customModal',
          header: true,
          footer: true,
          on: {}
        });
        page.app.calendar.create({
          inputEl: '#expiry_date',
          dateFormat: "yyyy-mm-dd",
          openIn: 'customModal',
          header: true,
          footer: true,
          on: {}
        });
        page.app.form.fillFromData("#contractrenewal-form", {
          employee: "",
          renewal_contract: "",
          contract_type: "",
          signing_type: "",
        });

      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
        let self = this
        page.app.smartSelect.get('.smart-select.employee').on('closed', function () {
          page.app.form.fillFromData("#contractrenewal-form", {
            renewal_contract: "",
          });
          var id = parseInt(page.app.smartSelect.get('.smart-select.employee').selectEl.value)
          let obj = self.getUserObj()
          obj.employee_id = id
          self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.contract.renewal.application.interface', 'select_employee_get_fields', obj), function (res) {
            console.log(res)
            let Template = Template7.compile(
              '<option value="" selected disabled></option>{{#each info.renewal_contract}}<option value="{{renewal_contract_id}}">{{show_name}}</option> {{/each}}'
            );
            let renewalContractHtml = Template({
              info: res.result.data
            });
            $$('#renewal_contract').html(renewalContractHtml)
            $$('.department').html(res.result.data.department.show_name)
            $$('.job').html(res.result.data.job.show_name)
            $$('.job_grade').html(res.result.data.legal_company.show_name)
          });
        });
        page.app.smartSelect.get('.smart-select.renewal_contract').on('closed', function () {
          var id = parseInt(page.app.smartSelect.get('.smart-select.renewal_contract').selectEl.value)
          let obj = self.getUserObj()
          obj.renewal_contract_id = id
          if (obj.renewal_contract_id) {
            self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.contract.renewal.application.interface', 'select_contract_get_fields', obj), function (res) {
              console.log(res)
              if (res.result) {
                $$('.start_date').html(res.result.data.start_date)
                $$('.end_date').html(res.result.data.end_date)
              }
            });
          }
        });

        page.app.smartSelect.get('.smart-select.contract_type').on('closed', function () {
          var id = parseInt(page.app.smartSelect.get('.smart-select.contract_type').selectEl.value)
          let obj = self.getUserObj()
          obj.contract_type_id = id
          if (obj.contract_type_id) {
            self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.contract.renewal.application.interface', 'select_contract_type_get_limit', obj), function (res) {
              console.log(res)
              if (res.result.need_manual_input) {
                $$('.smart-select.contract_type').parent().append('<li class="item-content item-input"><div class="item-inner"><div class="item-title item-label">新合同编号</div><div class="item-input-wrap"><input id="new_contract_num" type="text" placeholder="请输入" name="new_contract_num" /><span class="input-clear-button"></span></div></div></li>')
              } else {
                $$('#new_contract_num').parent().parent().parent().remove()
              }
            });
          }
        });
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};