import $$ from "dom7";
import workOrderForm from "../pages/workOrderForm.html";
import Template7 from "template7";
import app from "../../app";
import { retry } from "async";

// import 'weui';


export default {
  name: "workOrderForm",
  path: "/workOrderForm/",
  component: {
    template: workOrderForm,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = []
          args = [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.work.order.interface",
              method,
              obj
            ]
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args
          }
        };
      },
      //获取工程师列表的函数
      getEngineerList(page){
        let that = this
        let promise = new Promise((resolve, reject)=>{
            var vuserid = page.app.data.session.userid;
            var dingding_code = page.app.data.dingding_code;
            var feishu_userid = page.app.data.feishu_userid;
            var repairId = this.info.work_order_id
            if (page.app.data.session.source == 'dingding') {
                var paramrs = this.getParams("select_engineer", {
                    "dingding_code": dingding_code,
                    "work_order_id": repairId
                });
            } else if (page.app.data.session.source == 'feishu') {
                var paramrs = this.getParams("select_engineer", {
                    "feishu_userid": feishu_userid,
                    "work_order_id": repairId
                });
            } else {
                var paramrs = this.getParams("select_engineer", {
                    "wechat_name": vuserid,
                    "work_order_id": repairId
                });
            }
            page.app.request.postJSON(page.app.data.url, paramrs ,function (result) {
                if(result.result){
                    that.$setState({
                        engineerList: result.result.data.engineer
                    })
                    $('#engineer').val(that.info.default_engineer_id)
                    $('.tigger .item-after').text(that.info.default_engineer_name)
                    resolve(result)
                }else{
                    page.app.dialog.alert('系统错误！','系统提示')
                }          
            });
        })
        return promise
      },
      confirm(){
        let engineer = $('.tigger .item-after').text()
        if(engineer === '请选择' || engineer == ''){
          app.dialog.alert('请先选择工程师！',"温馨提示")
        }else{
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          var repairId = this.info.work_order_id
          // if(app.data.inspection_data.button_assign){  // 判断是分配还是重新分配
          //     var assign = 'confirm_assign'
          //   }else{
          //     var assign = 'confirm_reassign'
          //   }
          if (app.data.session.source == 'dingding') {
              var paramrs = this.getParams('confirm_reassign', {
                  "dingding_code": dingding_code,
                  "work_order_id": repairId,
                  "engineer_id": $('#engineer').val()?$('#engineer').val():this.info.default_engineer_id
              });
          } else if (app.data.session.source == 'feishu') {
              var paramrs = this.getParams('confirm_reassign', {
                  "feishu_userid": feishu_userid,
                  "work_order_id": repairId,
                  "engineer_id": $('#engineer').val()?$('#engineer').val():this.info.default_engineer_id
              });
          } else {
              var paramrs = this.getParams('confirm_reassign', {
                  "wechat_name": vuserid,
                  "work_order_id": repairId,
                  "engineer_id": $('#engineer').val()?$('#engineer').val():this.info.default_engineer_id
              });
          }
          app.request.postJSON(app.data.url, paramrs ,function (result) {
              if(result.result){
                  app.popup.get('.popup-about').close(false)
                  let toast = app.toast.create({
                      text: '分配成功！',
                      position: "center",
                      closeTimeout: 2000
                    });
                    toast.open();
                  app.router.back()
                }else{
                  app.dialog.alert('分配失败！','系统提示')
                }
          })
        }
      },
      //点击故障报修单号的函数
      repairNumber(){
        const {
          $$
        } = this;       
        var page = $$('.page[data-name="work-order-form"]')[0].f7Page;
        page.app.data.asset_data.form_data.showButton=false
        if(this.info.isForm){
          page.router.navigate("/workOrderViewForm/", {
            reloadCurrent: false,
            context: {
                info: page.app.data.asset_data.form_data
            }
            });
        }else{
          
        }     
      },
    add(){
      const {
        $$
      } = this;       
      var page = $$('.page[data-name="work-order-form"]')[0].f7Page;
      page.router.navigate("/parts/", {
        reloadCurrent: false,
        ignoreCache: true,
        context:{
          info: this.lists
        }
    });
    },
    submit(){
      let formData = app.form.convertToData('#workOrderForm');
      console.log(formData)
      if(this.document_type_length>1 && !formData.document_type){
        app.dialog.alert('请选择单据类型！','系统提示')
        return
      }
      if(formData.wo_overview == ''){
        app.dialog.alert('请输入工作单概述！','系统提示')
        return
      }
      if(formData.executed_by == "agency"){
        if(formData.agency == ''){
          app.dialog.alert('请选择政府机构！','系统提示')
          return
        }
        delete formData.supplier
      }else if(formData.executed_by == "original_supplier" || formData.executed_by == "supplier"){
        if(formData.supplier == ''){
          app.dialog.alert('请选择供应商！','系统提示')
          return
        }
        delete formData.agency
      }else{
        delete formData.supplier
        delete formData.agency
      }
      var vuserid = app.data.session.userid;
      var dingding_code = app.data.dingding_code;
      var feishu_userid = app.data.feishu_userid;
      var repairId = app.data.asset_data.form_data.id
      if (app.data.session.source == 'dingding') {
        //获取单据类型、政府机构等列表的参数
          var createParams = this.getParams("create_work_order", {
            "repair_requisition_id": repairId,
            "dingding_code": dingding_code,
            "type_id": formData.document_type?formData.document_type:this.document_type[0].type_id,
            "wo_overview": formData.wo_overview,
            "engineer_id": this.info.engineer.engineer_id,
            "executed_by": formData.executed_by,
            "agency_id": formData.agency?formData.agency:'',
            "supplier_id": formData.supplier?formData.supplier:'',
            "part_ids": app.data.asset_data.form_data.supplies
          });
        } else if (app.data.session.source == 'feishu') {
        //获取单据类型、政府机构等列表的参数
          var createParams = this.getParams("create_work_order", {
            "repair_requisition_id": repairId,
            "feishu_userid": feishu_userid,
            "type_id": formData.document_type?formData.document_type:this.document_type[0].type_id,
            "wo_overview": formData.wo_overview,
            "engineer_id": this.info.engineer.engineer_id,
            "executed_by": formData.executed_by,
            "agency_id": formData.agency?formData.agency:'',
            "supplier_id": formData.supplier?formData.supplier:'',
            "part_ids": app.data.asset_data.form_data.supplies
          });
        } else {
          var createParams = this.getParams("create_work_order", {
            "repair_requisition_id": repairId,
            "wechat_name": vuserid,
            "type_id": formData.document_type?formData.document_type:this.document_type[0].type_id,
            "wo_overview": formData.wo_overview,
            "engineer_id": this.info.engineer.engineer_id,
            "executed_by": formData.executed_by,
            "agency_id": formData.agency?formData.agency:'',
            "supplier_id": formData.supplier?formData.supplier:'',
            "part_ids": app.data.asset_data.form_data.supplies
          });
        }
        console.log(createParams)
        app.request.postJSON(app.data.url, createParams ,(result) => {
          if(result.result){ 
            // app.dialog.alert('创建成功！','系统提示')
            var toast = app.toast.create({
              text: "创建成功！",
              position: "center",
              closeTimeout: 2000
            });
            toast.open();
            app.router.back('/workOrder/',{
              force: true
            })
          }else{
              app.dialog.alert('系统错误！','系统提示')
          }          
        });
    }
    },
    on: {
      pageInit: function (e, page) {
        console.log(this.info)
        $$('.agencyShow').hide()
        $$('.supplierShow').hide()
        if(this.info.button_reassign){
          this.getEngineerList(page)
        } 
        if(!this.info.isForm){
          if(this.info.part.length>0){
            this.$setState({
              supplies: this.info.part.map(ele=>{
                ele.product_name = ele.product
                ele.unit_name = ele.unit
                ele.consumption = ele.estimate_qty
                return ele
              })
            })
          }  
          $$('#submit').hide()       
          $$('.data-table-footer').hide()
          return
        }
        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;
        var repairId = page.app.data.asset_data.form_data.id
        if (page.app.data.session.source == 'dingding') {
          //获取单据类型、政府机构等列表的参数
            var fieldsParams = this.getParams("get_fields_value", {
              "repair_requisition_id": repairId,
              "dingding_code": dingding_code
            });
            var productParams = this.getParams("get_product_info", {
              "repair_requisition_id": repairId,
              "dingding_code": dingding_code
            });
          } else if (page.app.data.session.source == 'feishu') {
          //获取单据类型、政府机构等列表的参数
            var fieldsParams = this.getParams("get_fields_value", {
              "repair_requisition_id": repairId,
              "feishu_userid": feishu_userid
            });
            var productParams = this.getParams("get_product_info", {
              "repair_requisition_id": repairId,
              "feishu_userid": feishu_userid
            });
          } else {
            var fieldsParams = this.getParams("get_fields_value", {
              "repair_requisition_id": repairId,
              "wechat_name": vuserid
            });
            var productParams = this.getParams("get_product_info", {
              "repair_requisition_id": repairId,
              "wechat_name": vuserid
            });
          }
          //获取单据类型、政府机构等列表
          page.app.request.postJSON(page.app.data.url, fieldsParams ,(result) => {
            if(result.result){ 
                this.$setState({
                  document_type: result.result.fields_value.document_type.values,
                  document_type_length: result.result.fields_value.document_type.values.length,
                  document_type_name: result.result.fields_value.document_type.values[0].type_name,
                  agency: result.result.fields_value.agency.values,
                  supplier: result.result.fields_value.supplier.values,
                  engineer: `[${page.app.data.asset_data.form_data.engineer.employee_number}]${page.app.data.asset_data.form_data.engineer.employee_name}`,        
                })
                if(result.result.fields_value.supplier.default.supplier_id){
                  page.app.smartSelect.get('.smart-select.supplier').$valueEl.text(result.result.fields_value.supplier.default.supplier_name)
                  page.app.form.fillFromData("#workOrderForm", {
                    supplier: result.result.fields_value.supplier.default.supplier_id
                  });
                }else if(result.result.fields_value.agency.default.agency_id){
                  page.app.smartSelect.get('.smart-select.agency').$valueEl.text(result.result.fields_value.agency.default.agency_name)
                  page.app.form.fillFromData("#workOrderForm", {
                    agency: result.result.fields_value.agency.default.agency_id
                  });
                }
                page.app.form.fillFromData("#workOrderForm", {
                  wo_overview: this.info.description,
                });
                console.log(this)
            }else{
                page.app.dialog.alert('系统错误！','系统提示')
            }          
          });
          page.app.request.postJSON(page.app.data.url, productParams, (res) => {
            if(res.result){
              console.log(res)
                  this.lists = {
                    productList: res.result.fields_value.product.values.slice(0,100),
                    unitList: []
                  }
            }else{
                page.app.dialog.alert('获取信息错误!','系统提示')
            }  
          })
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {   
        //删掉多余的路由历史
        let route = page.router.history[page.router.history.length-1]
        if(route === '/workOrderForm/')page.router.history.splice(page.router.history.length-1,1)

        console.log(page.router.history)
        let arr = page.app.data.asset_data.form_data.supplies
        if(arr.length>0){
          this.$setState({
            supplies: arr     
          })
        }
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        let smartSelect = page.app.smartSelect.get('.smart-select');
        $('.tigger').on('click', function (page) {
            smartSelect.open()
        });
        smartSelect.on('close', function () { 
            if(smartSelect.$valueEl.text()){
                $('.tigger .item-after').text(smartSelect.$valueEl.text())
            }
         });
        let smart_select_executed_by = page.app.smartSelect.get('.smart-select.executed_by')
        smart_select_executed_by.on('close',() => {
          let value = smart_select_executed_by.$valueEl.text()
          if(value == '政府机构'){
            $$('.agencyShow').show()
            $$('.supplierShow').hide()
          }else if(value == '原厂供应商' || value == '外部供应商'){
            $$('.agencyShow').hide()
            $$('.supplierShow').show()
          }else{
            $$('.agencyShow').hide()
            $$('.supplierShow').hide()
          }
        })
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};