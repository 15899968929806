import $ from "dom7";
import teamBuildingAppForm from "./teamBuildingAppForm";
import teamBuildingAppViewForm from "./teamBuildingAppViewForm";
import html from "../pages/index.html";
import style from "../styles/index.less";
export default [{
    name: "/teambuildingapplist/",
    path: "/teambuildingapplist/",
    component: {
      template: html,
      style: style,
      methods: {
        getUserObj: function () {
          if (this.$app.data.session.source == 'dingding') {
            return {
              dingding_code: this.$app.data.dingding_code
            }
          } else if (this.$app.data.session.source == 'feishu') {
            return {
              feishu_userid: this.$app.data.feishu_userid
            }
          } else {
            return {
              wechat_name: this.$app.data.session.userid
            }
          }
        },
        toDetail: function (e) {
          let self = this;
          let id = parseInt(e.currentTarget.dataset.id);
          let obj = this.getUserObj()
          obj.team_building_application_id = parseInt(id)
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.team.building.application.interface', 'get_team_building_application_details', obj), function (res) {
            console.log(res);
            self.$app.router.navigate("/teambuildingappviewform/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                attachments: true,
                info: res.result.data
              }
            });
          });
        }
      },
      on: {
        pageInit: function (e, page) {
          let self = this
          const {
            $$
          } = this
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.team.building.application.interface', 'get_user_team_building_requisition', this.getUserObj()), function (res) {
            console.log(res)
            self.$setState({
              all_team_building_requisition_list: res.result.all_team_building_requisition_list,
              all_team_building_requisition_list_count: res.result.all_team_building_requisition_list.length,
              processing_team_building_requisition_list: res.result.processing_team_building_requisition_list,
              processing_team_building_requisition_list_count: res.result.processing_team_building_requisition_list.length,
            });
            //未处理 搜索框实例
            var sbtodo = page.app.searchbar.create({
              el: '[data-name="teambuildingapp-index"] .searchbar-to-do',
              searchContainer: '[data-name="teambuildingapp-index"] .list-to-do',
              searchIn: '[data-name="teambuildingapp-index"] .item-text-to-do',
              notFoudEl: '[data-name="teambuildingapp-index"] .searchbar-not-found-to-do',
            });
            var sbdone = page.app.searchbar.create({
              el: ".searchbar-done",
              searchContainer: ".list-done",
              searchIn: ".item-text-done",
              notFoudEl: ".searchbar-not-found-done",
            });
            $('[data-name="teambuildingapp-index"] .searchbar-done').hide();
            $('[data-name="teambuildingapp-index"] .search-icon-2').hide();

            $('[data-name="teambuildingapp-index"] .to-do-btn').on("click", function () {
              sbdone.disable();
              $('[data-name="teambuildingapp-index"] .searchbar-done,[data-name="teambuildingapp-index"] .search-icon-2').hide();
              $('[data-name="teambuildingapp-index"] .searchbar-to-do,[data-name="teambuildingapp-index"] .search-icon-1').show();
            });
            $('[data-name="teambuildingapp-index"] .done-btn').on("click", function () {
              sbtodo.disable();
              $('[data-name="teambuildingapp-index"] .searchbar-done,[data-name="teambuildingapp-index"] .search-icon-2').show();
              $('[data-name="teambuildingapp-index"] .searchbar-to-do,[data-name="teambuildingapp-index"] .search-icon-1').hide();
            });
          })
        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  teamBuildingAppForm,
  teamBuildingAppViewForm
];