import $$ from "dom7";
import afterTakeBackForm from "../pages/afterTakeBackForm.html";
import leaveApplicationFormNewLineTemplate from "../pages/leaveApplicationFormNewLine.html";
import Template7 from "template7";
import * as dd from 'dingtalk-jsapi'



export default {
  name: "afterTakeBackForm",
  path: "/afterTakeBackForm/",
  component: {
    template: afterTakeBackForm,
    data: () => {
      return {
        isFabBtn: false,
        countLine: 1  
      };
    },
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      //下载文件
      downloadFile(e) {
        window.open(e.currentTarget.dataset.url);
      },
      //文件上传后的处理函数
      filechange() {
        const self = this
        Array.from(this.$$("#file-attach")[0].files).forEach((ele) => {
          if (ele.type.indexOf("image") > -1) {
            this.fileList.images.push(ele);
          } else {
            this.fileList.files.push(ele);
          }
        });
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files,
        });
        //处理图片上传后事件冲突的问题
        $(".pb-standalone-dark").off("click");
        console.log(app.data);
        $(".pb-standalone-dark").on("click", function (event) {
          self.myPhotoBrowserDark.open(
            Number(event.currentTarget.dataset.index)
          );
        });
        $(".addimg").off("click");
        $(".addimg").on("click", function (event) {
          $("#file-attach").trigger("click");
        });
        this.myPhotoBrowserDark.params.photos = this.fileList.images;
        // 读取文件的函数
        function readAndPreview(file) {
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              file.url = this.result;
              file.caption = file.name;
            },
            false
          );
          if (!file.url) reader.readAsDataURL(file);
        }
        [].forEach.call(this.fileList.images, readAndPreview);
        setTimeout(() => {
          this.fileList.images.forEach((ele, index) => {
            $("img")[index].src = ele.url;
          });
        }, 100);
        console.log(this.fileList);
      },
      // 删除上传的文件
      delFile(val,attachment_id) {
        let e = window.event;
        e.stopPropagation();
        // if (!this.delete_attachment_ids) this.delete_attachment_ids = [];
        if (e.currentTarget.dataset.id)
          this.delete_attachment_ids.push(Number(e.currentTarget.dataset.id));
        if (val == "img") {
          // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            images: this.fileList.images,
          });

          //处理图片删除后事件冲突的问题
          $(".addimg").off("click");
          $(".addimg").on("click", function (event) {
            $("#file-attach").trigger("click");
          });

          this.myPhotoBrowserDark.params.photos = this.fileList.images;
          this.fileList.images.forEach((ele, index) => {
            ele.caption = ele.name;
            $("img")[index].src = ele.url;
          });
        } else {
          // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            files: this.fileList.files,
          });
        }
      },
      // 格式化时间的函数，当少0的时候自动补0
      initTime: function(time){
        let timeArr = time.split(':')
        return timeArr.map(ele => {
          if(ele.length<2){
            ele = '0'+ele
          }
          return ele
        }).join(':')
      },
      submit: function (e) {
        let self = this
        var page = $$('.page[data-name="afterTakeBack-form"]')[0].f7Page

        if ($$('#reason').val() === '') {
          return self.$app.dialog.alert('事由必须有值!', "KTHRP");
        }
        // var leaveapplicationFormData = app.form.convertToData("#afterTakeBack-form");



        let post_file_url = this.$app.data.base_url + '/web/map_upload_attachment'
        var formData = new FormData()
        let files = this.$$('#file-attach')[0].files
        if (self.$route.context.info.attachment_required && files.length === 0) {
          return self.$app.dialog.alert('请上传附件或者图片！', "KTHRP");
        }
        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        if (self.$app.data.session.source == 'dingding') {
          formData.append('dingding_code', self.$app.data.dingding_code)
        } else if (self.$app.data.session.source == 'feishu') {
          formData.append('feishu_userid', self.$app.data.feishu_userid)
        } else {
          formData.append('wechat_name', self.$app.data.session.userid)
        }
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          formData.append("file", file)
        }
        let leaveapplicationFormData = self.getUserObj()
        leaveapplicationFormData.type_id = self.$route.context.info.type_id
        leaveapplicationFormData.leave_id = self.$route.context.info.record_id
        leaveapplicationFormData.delete_attachment_ids = self.delete_attachment_ids
        leaveapplicationFormData.reason = $$('#reason').val()
        leaveapplicationFormData.usable_day = self.$route.context.info.usable_day
        leaveapplicationFormData.usable_hours = self.$route.context.info.usable_hours
        leaveapplicationFormData.record_lines = []
        var lines = $$('.list.leaveapplication-form-new-line')
        for (var i = 0; i < lines.length; i++) {
          let lineData = {}
          lineData.line_no = i + 1
          // if (i > 0) {
            // lineData.start_date = $$(lines[i]).find('.calendar-leaveapplication-start-date').val()
            // lineData.end_date = $$(lines[i]).find('.calendar-leaveapplication-end-date').val()
            lineData.line_id = parseInt($$(lines[i]).data('line-id')) || ''
            lineData.start_date = self.info.minimum_leave_unit=='hour'? $$(lines[i]).find('.calendar-leaveapplication-start-date').val().trim()+':00' : $$(lines[i]).find('.calendar-leaveapplication-start-date').val().trim().replace('上午','am').replace('下午','pm')
            lineData.end_date = self.info.minimum_leave_unit=='hour'? $$(lines[i]).find('.calendar-leaveapplication-end-date').val().trim()+':00' : $$(lines[i]).find('.calendar-leaveapplication-end-date').val().trim().replace('上午','am').replace('下午','pm')
            // if (lineData.start_date > lineData.end_date) {
            //   return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
            // }
          // } else {
          //   lineData.start_date = $$(lines[i]).find('.calendar-leaveapplication-start-date').val()
          //   lineData.end_date = $$(lines[i]).find('.calendar-leaveapplication-end-date').val()
          //   if (lineData.start_date > lineData.end_date) {
          //     return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
          //   }
          // }
          var start =  $$(lines[i]).find('.calendar-leaveapplication-start-date').val().trim().replace(/-/g, "/")
          var end =  $$(lines[i]).find('.calendar-leaveapplication-end-date').val().trim().replace(/-/g, "/")
          if(!start){
            return self.$app.dialog.alert('休假明细第'+(i+1)+'行开始日期必须有值！', "KTHRP")
          }
          if(!end){
            return self.$app.dialog.alert('休假明细第'+(i+1)+'行结束日期必须有值！', "KTHRP")
          }
          if(self.info.minimum_leave_unit=='hour'){
            if(start && end && new Date(start)-new Date(end)>=0){
              return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
            }
          }else if(self.info.minimum_leave_unit=='day'){
            if(start && end && new Date(start)-new Date(end)>0){
              return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
            }
          }else{
            start = start.replace('上午','am').replace('下午','pm')
            end = end.replace('上午','am').replace('下午','pm')
            if(start && end && new Date(start.substring(0,10))-new Date(end.substring(0,10))>0){
              return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
            }
            if(start && end && new Date(start.substring(0,10))-new Date(end.substring(0,10))==0 && start.indexOf('pm') >-1 && end.indexOf('am')){
              return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
            }
          }
          lineData.note = $$(lines[i]).find('#note').val()
          if (lineData.end_date === " " || lineData.start_date === " ") {
            return self.$app.dialog.alert('请填写休假明细信息！', "KTHRP");
          }
          leaveapplicationFormData.record_lines.push(lineData);
        }
        console.log(leaveapplicationFormData)
        app.preloader.show();
        self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'create_leave_record', leaveapplicationFormData), function (res) {
          console.log(res);
          if (res.result.state === 'ok') {
            if (files.length === 0) {
              var toast = app.toast.create({
                text: '恭喜您,操作成功',
                position: 'top',
                closeTimeout: 2000
              })
              app.preloader.hide();
              toast.open()
              page.router.back('/leaveapplicationlist/', {
                force: true
              })
            } else {
              formData.append('model_name', res.result.data.model_name)
              formData.append('record_id', res.result.data.record_id)
              self.$app.request.post(post_file_url, formData, function (result) {
                console.log(result)
                if (JSON.parse(result).state === 'ok') {
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide();
                  toast.open()
                  page.router.back('/leaveapplicationlist/', {
                    force: true
                  })
                } else {
                  var toast = app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide();
                  toast.open()
                  page.router.back('/leaveapplicationlist/', {
                    force: true
                  })
                }
              });
            }
          } else if (res.result.state === 'warn') {
            app.preloader.hide();
            self.$app.dialog.alert(res.result.err, "KTHRP");
          } else {
            app.preloader.hide();
            self.$app.dialog.alert(res.result.err, "KTHRP");
          }
        });
      },
      getDate: function (date) {
        return date.substring(0, date.indexOf(' '))
      },
      getTime: function (date) {
        return date.substring(date.indexOf(' ') + 1, date.length)
      },
      getAllLeaveTime(){
        var all_leave_time = 0
        if(this.info.minimum_leave_unit == 'hour'){
          $('.afterTakeBack-form-list .leave_hours').each(function(){
            all_leave_time += $(this).html() * 1
          });
        }else{
          $('.afterTakeBack-form-list .leave_days').each(function(){
            all_leave_time += $(this).html() * 1
          });
        }
        $('.all_leave_time').html(all_leave_time)
      },
      addLine: function () {
        const isTakeBack = this.isTakeBack
        const {
          $$
        } = this;
        var self = this
        this.countLine++
        var page = $$('.page[data-name="afterTakeBack-form"]')[0].f7Page;
        var compiledExpenseLineDistributionTemplate = Template7.compile(
          leaveApplicationFormNewLineTemplate
        );
        var html = compiledExpenseLineDistributionTemplate({
          date_start: isTakeBack ? '' : self.getDate(self.$route.context.info.start_date),
          time_start: isTakeBack ? '' : self.getTime(self.$route.context.info.start_date),
          date_end: isTakeBack ? '' : self.getDate(self.$route.context.info.end_date),
          time_end: isTakeBack ? '' : self.getTime(self.$route.context.info.end_date),
          line: 'line' + this.countLine
        });

        $$('.page[data-name="afterTakeBack-form"] .afterTakeBack-form-list').append(html);
        $$(".delete-line-btn").on("click", function (e) {
          console.log("删除行");
          console.log($$(e.currentTarget).parent().parent());
          $$(e.currentTarget).parent().parent().remove();

          self.getAllLeaveTime()
        });
        this.initNewLineDate()

        // page.app.calendar.create({
        //   inputEl: '#line' + self.countLine + ' .calendar-leaveapplication-start-date',
        //   dateFormat: "yyyy-mm-dd",
        //   openIn: 'customModal',
        //   header: true,
        //   footer: true,
        //   on: {
        //     closed: function (e) {
        //       let curList = e.$inputEl.parent().parent().parent().parent()
        //       if (curList.find('.calendar-leaveapplication-start-date').val() != '' && curList.find('#calendar-leaveapplication-start-time').val() != '' && curList.find('.calendar-leaveapplication-start-date').val() != '' && curList.find('#calendar-leaveapplication-end-time').val() != '') {
        //         let id = self.$route.context.info.type_id
        //         let obj = self.getUserObj()
        //         obj.type_id = parseInt(id)
        //         obj.record_line = {
        //           start_date: curList.find('.calendar-leaveapplication-start-date').val() + ' ' + curList.find('#calendar-leaveapplication-start-time').val(),
        //           end_date: curList.find('.calendar-leaveapplication-end-date').val() + ' ' + curList.find('#calendar-leaveapplication-end-time').val(),
        //         }
        //         self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'update_leave_record', obj), function (res) {
        //           console.log(res);
        //           if (res.result.state === 'ok') {
        //             curList.find('.leave_days').html(res.result.data.leave_days)
        //             curList.find('.leave_hours').html(res.result.data.leave_hours)
        //           }
        //         });
        //       }
        //     }
        //   }
        // });

        // page.app.calendar.create({
        //   inputEl: '#line' + self.countLine + ' .calendar-leaveapplication-end-date',
        //   dateFormat: "yyyy-mm-dd",
        //   openIn: 'customModal',
        //   header: true,
        //   footer: true,
        //   on: {
        //     closed: function (e) {
        //       let curList = e.$inputEl.parent().parent().parent().parent()
        //       if (curList.find('.calendar-leaveapplication-start-date').val() != '' && curList.find('#calendar-leaveapplication-start-time').val() != '' && curList.find('.calendar-leaveapplication-start-date').val() != '' && curList.find('#calendar-leaveapplication-end-time').val() != '') {
        //         let id = self.$route.context.info.type_id
        //         let obj = self.getUserObj()
        //         obj.type_id = parseInt(id)
        //         obj.record_line = {
        //           start_date: curList.find('.calendar-leaveapplication-start-date').val() + ' ' + curList.find('#calendar-leaveapplication-start-time').val(),
        //           end_date: curList.find('.calendar-leaveapplication-end-date').val() + ' ' + curList.find('#calendar-leaveapplication-end-time').val(),
        //         }
        //         self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'update_leave_record', obj), function (res) {
        //           console.log(res);
        //           if (res.result.state === 'ok') {
        //             curList.find('.leave_days').html(res.result.data.leave_days)
        //             curList.find('.leave_hours').html(res.result.data.leave_hours)
        //           }
        //         });
        //       }
        //     }
        //   }
        // });


      },
      getSum: function (arr) {
        var len = arr.length;
        var sum = 0;
        for (var i = 0; i < len; i++) {
          sum += parseFloat(arr[i].amount);
        }
        return sum;
      },
      sysDate(){
        var myDate = new Date();
        var year = myDate.getFullYear();
        var month = myDate.getMonth()+1;
        var date = myDate.getDate();
        if (month < 10) {
            month = "0" + month
        }
        if (date < 10) {
            date = "0" + date
        }
        //当前系统时间
        return year+"-"+month+"-"+date;
      },
      calcTime(picker,el){
        let self = this
        let $el = $$(picker.params.input)
        let curList = $el.parent().parent().parent().parent()
        if (curList.find(el).val() != ''  && curList.find(el).val() != '' ) {
          let id = self.$route.context.info.type_id
          let obj = self.getUserObj()
          obj.type_id = parseInt(id)

          obj.record_line = {
            start_date:self.info.minimum_leave_unit=='hour'? curList.find('.calendar-leaveapplication-start-date').val().trim()+':00' : curList.find('.calendar-leaveapplication-start-date').val().trim().replace('上午','am').replace('下午','pm'),
            end_date: self.info.minimum_leave_unit=='hour'? curList.find('.calendar-leaveapplication-end-date').val().trim()+':00' : curList.find('.calendar-leaveapplication-end-date').val().trim().replace('上午','am').replace('下午','pm'),
            // minimum_leave_unit:self.info.minimum_leave_unit
          }
          // var start = curList.find('.calendar-leaveapplication-start-date').val().trim().replace(/-/g, "/")
          // var end = curList.find('.calendar-leaveapplication-end-date').val().trim().replace(/-/g, "/")
          // if(self.info.minimum_leave_unit=='hour'){
          //   if(start && end && new Date(start)-new Date(end)>=0){
          //     return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
          //   }
          // }else if(self.info.minimum_leave_unit=='day'){
          //   if(start && end && new Date(start)-new Date(end)>0){
          //     return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
          //   }
          // }else{
          //   start = start.replace('上午','am').replace('下午','pm')
          //   end = end.replace('上午','am').replace('下午','pm')
          //   if(start && end && new Date(start.substring(0,10))-new Date(end.substring(0,10))>0){
          //     return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
          //   }
          //   if(start && end && new Date(start.substring(0,10))-new Date(end.substring(0,10))==0 && start.indexOf('pm') >-1 && end.indexOf('am')){
          //     return self.$app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
          //   }
          // }
          self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'update_leave_record', obj), function (res) {
            console.log(res);
            if (res.result.state === 'ok') {
              curList.find('.leave_days').html(res.result.data.leave_days)
              curList.find('.leave_hours').html(res.result.data.leave_hours)
              
              self.getAllLeaveTime()
            }
          });
        }
      },
      initNewLineDate(){
        let self = this
        const years = this.info.enable_credit ? [this.sysDate().substring(0,4)] : undefined 
        const isTakeBack = this.isTakeBack
        const line_ids = this.info.line_ids
        const i = $('.calendar-leaveapplication-start-date').length -1
        if(this.info.minimum_leave_unit=='day'){
          
            $('.calendar-leaveapplication-start-date').eq(i).datetimePicker({
              title: "",
              years:years,
              times: function () {
                  return [];
              },
              value: self.sysDate() + ' ',
              onChange: function (picker, values, displayValues) {
                console.log('onChange');
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-start-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
            });
            $('.calendar-leaveapplication-end-date').eq(i).datetimePicker({
              title: "",
              years:years,
              times: function () {
                  return [];
              },
              value: self.sysDate() + ' ',
              onChange: function (picker, values, displayValues) {
                console.log('onChange');
              },
              onClose: function (picker) {
                console.log('onClose');
                self.calcTime(picker,'.calendar-leaveapplication-end-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
            })
          
        }else if(this.info.minimum_leave_unit=='half_day'){

            $('.calendar-leaveapplication-start-date').eq(i).datetimePicker({
              title: "",
              years:years,
              times: function () {
                  return [{
                      values: ['上午', '下午']
                  }];
              },
              value: self.sysDate() + ' 上午',
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-start-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
            });
            $('.calendar-leaveapplication-end-date').eq(i).datetimePicker({
              title: "",
              years:years,
              times: function () {
                  return [{
                      values: ['上午', '下午']
                  }];
              },
              value: self.sysDate() + ' 上午',
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-end-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
            })
        
        }else{
            $('.calendar-leaveapplication-start-date').eq(i).datetimePicker({
              title: "",
              years:years,
              value: self.info.start_date.substring(0,16),
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-start-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
            });
            $('.calendar-leaveapplication-end-date').eq(i).datetimePicker({
              title: "",
              years:years,
              value: self.info.end_date.substring(0,16),
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-end-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
      
            })
       
        }
        
      },
      initDate(){
        let self = this
        const years = this.info.enable_credit ? [this.sysDate().substring(0,4)] : undefined 
        // const isTakeBack = this.isTakeBack
        const line_ids = this.info.line_ids
        if(this.info.minimum_leave_unit=='day'){
          line_ids.forEach(function(item,i){
            $('.calendar-leaveapplication-start-date').eq(i).datetimePicker({
              title: "",
              years:years,
              times: function () {
                  return [];
              },
              value: item.start_date_str,
              onChange: function (picker, values, displayValues) {
                console.log('onChange');
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-start-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
            });
            $('.calendar-leaveapplication-end-date').eq(i).datetimePicker({
              title: "",
              years:years,
              times: function () {
                  return [];
              },
              value: item.end_date_str,
              onChange: function (picker, values, displayValues) {
                console.log('onChange');
              },
              onClose: function (picker) {
                console.log('onClose');
                self.calcTime(picker,'.calendar-leaveapplication-end-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
            })
          });

        }else if(this.info.minimum_leave_unit=='half_day'){
          line_ids.forEach(function(item,i){
            $('.calendar-leaveapplication-start-date').eq(i).datetimePicker({
              title: "",
              years:years,
              times: function () {
                  return [{
                      values: ['上午', '下午']
                  }];
              },
              value: item.start_date_str,
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-start-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
            });
            $('.calendar-leaveapplication-end-date').eq(i).datetimePicker({
              title: "",
              years:years,
              times: function () {
                  return [{
                      values: ['上午', '下午']
                  }];
              },
              value: item.end_date_str,
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-end-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
            })
          });
        }else{
          line_ids.forEach(function(item,i){
            $('.calendar-leaveapplication-start-date').eq(i).datetimePicker({
              title: "",
              years:years,
              value: item.start_date_str,
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-start-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
            });
            $('.calendar-leaveapplication-end-date').eq(i).datetimePicker({
              title: "",
              years:years,
              value: item.end_date_str,
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.calendar-leaveapplication-end-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
      
            })
          });

        }
      }
    },
    on: {
      pageInit: function (e, page) {
        let self = this;
        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });
        
        this.initDate();
        let files = [];
        let images = [];
        this.info.attachment_ids.forEach((r) => {
          if (!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(r.name) ) { 
            files.push(r)     
          }else{
            images.push(r)
          }
        });

        this.$setState({
          delete_attachment_ids:[],
          fileList:{
            files,
            images
          },
          files: files,
          images: images
        });

        images.forEach((ele,index) => {
          ele.caption = ele.name
          $('img')[index].src=ele.url
        })
        // 照片预览器
        this.myPhotoBrowserDark = app.photoBrowser.create({
          photos : images,
          theme: 'dark'
        });
        $('.pb-standalone-dark').on('click', function (event) {
          self.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        // 触发上传文件的事件
        $('.addimg').on('click',function (event) { 
          console.log('触发上传文件的事件')
            $('#file-attach').trigger('click')
        })

        $$(".delete-line-btn").on("click", function (e) {
          console.log("删除行");
          console.log($$(e.currentTarget).parent().parent());
          $$(e.currentTarget).parent().parent().remove();
        });
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};