import $$ from "dom7";
import publicapplicationViewFormHtml from "../pages/publicapplicationViewForm.html";
import Template7 from "template7";
// import 'weui';


export default {
  name: "publicapplicationviewform",
  path: "/publicapplicationviewform/",
  component: {
    template: publicapplicationViewFormHtml,
    methods: {
      // previewImage: function (e) {
      //   const {
      //     $$
      //   } = this;
      //   var page = $$('.page[data-name="asset-view-form"]')[0].f7Page;
      //   var nowurl = e.currentTarget.dataset.src;
      //   if (page.app.data.session.source == 'dingding') {
      //     dd.ready(function () {
      //       dd.biz.util.previewImage({
      //         urls: page.app.data.asset_data.form_data.images_list, //图片地址列表
      //         current: nowurl, //当前显示的图片链接
      //         onSuccess: function (result) {
      //           console.log(result)
      //         },
      //         onFail: function (err) {
      //           console.log(err)
      //         }
      //       })
      //     });
      //   } else {
      //     wx.previewImage({
      //       current: nowurl,
      //       urls: page.app.data.asset_data.form_data.images_list
      //     });
      //   }
      // }
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },

      toLineDetail: function (e) {
        let self = this;
        var id = parseInt(e.currentTarget.dataset.lineId)
        let obj = this.getUserObj()
        obj.line_id = parseInt(id)
        this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'get_user_leave_record_line_details', obj), function (res) {
          console.log(res)
          self.$app.router.navigate('/publicapplicationformlinedetail/', {
            reloadCurrent: false,
            ignoreCache: true,
            context: {
              info: res.result.data
            }
          })
        })
      },
    },
    on: {
      pageInit: function (e, page) {},
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};