import $ from "dom7";
import salaryQueryFormView from "../pages/salaryQueryFormView.html";

export default {
    name: "/salaryQueryFormView/",
    path: "/salaryQueryFormView/",
    component: {
      template: salaryQueryFormView,
      methods: {
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            return {
                jsonrpc: "2.0",
                method: "call",
                params: {
                service: "object",
                method: "execute",
                args: [
                    dbname,
                    userid,
                    password,
                    "kthrp.payroll.salary.slip.month.interface",
                    method,
                    obj
                ]
                }
            };
        },
        // 下载文件
        downloadFile(e) {
          window.open(e.currentTarget.dataset.url);
        },
        waterMark(text){
          var shuiyinDiv = document.createElement('div');
          shuiyinDiv.className='shuiyinBox';
          var style = shuiyinDiv.style;
          style.position = 'fixed';
          style.left = '-50%';
          style.top = 0;
          style.width = '200%';
          style.minHeight = '100%';
          style.height = $('.page-current ul').height() + 'px';
          style.opacity = '0.1';
          style.background = "url("+this.textBecomeImg(text,22,"gray")+")";
          style.zIndex = 9999999991;
          style.transform = "rotate(-30deg)";
          style.pointerEvents = "none";
          document.body.appendChild(shuiyinDiv);
        },
        textBecomeImg(text,fontsize,fontcolor){
            var canvas = document.createElement('canvas');
            var $buHeight = 0;
            if(fontsize <= 32){ $buHeight = 99; }
            else if(fontsize > 32 && fontsize <= 60 ){ $buHeight = 2;}
            else if(fontsize > 60 && fontsize <= 80 ){ $buHeight = 4;}
            else if(fontsize > 80 && fontsize <= 100 ){ $buHeight = 6;}
            else if(fontsize > 100 ){ $buHeight = 10;}
            canvas.height=fontsize + $buHeight ;
            canvas.padding=30;
            var context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width*2, canvas.height);
            context.textAlign = "center";
                    canvas.width = 420;
                    canvas.height = 80;
                    context.fillStyle = fontcolor;
                    context.font=fontsize+"px Arial";
                    context.textBaseline = 'middle'; 
                    context.fillText(text,0,fontsize/2);
                    var canvasWidth = canvas.width/99;
                    canvasWidth = context.measureText(text).width;
            var dataUrl = canvas.toDataURL('image/png');
            return dataUrl;
        }
      },

      on: {
        pageInit: function (e, page) {
          $('.shuiyinBox').remove()
          var self = this;
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var get_detail_salary_slip_month = this.getParams("get_detail_salary_slip_month", {
              "dingding_code": dingding_code,
              salary_slip_month_id:self.id
            });

          } else if (page.app.data.session.source == 'feishu') {
            var get_detail_salary_slip_month = this.getParams("get_detail_salary_slip_month", {
              "feishu_userid": feishu_userid,
              salary_slip_month_id:self.id
            });

          } else {
            var get_detail_salary_slip_month = this.getParams("get_detail_salary_slip_month", {
              "wechat_name": vuserid,
              salary_slip_month_id:self.id
            });
          }
          page.app.request.postJSON(
            page.app.data.url,
            get_detail_salary_slip_month,
            function (result_list){
              self.$setState({
                info:result_list.result.all_salary_slip_month
              });
              let employee_no = ''
              let employee_id = ''
              result_list.result.all_salary_slip_month.forEach(item => {
                if(item.display_name == '工号'){
                  employee_no = item.value
                }
                if(item.display_name == '姓名'){
                  employee_id = item.value
                }
              })

              $('.salaryQuery-form-view .item-content').each((index,item) => {  
                var color = $(item).data('color')
                $(item).find('.item-title').css('color',color)
                $(item).find('.item-after').css('color',color)
              })
              const text = employee_no + ' ' + employee_id
              self.waterMark(text)

              // self.fileList.images.forEach((ele,index) => {
              //   ele.caption = ele.name
              //     $('img')[index].src=ele.url
              // })
              // // 照片预览器
              // var myPhotoBrowserDark = app.photoBrowser.create({
              //   photos : self.fileList.images,
              //   theme: 'dark'
              // });
              // $('.pb-standalone-dark').on('click', function (event) {
              //   myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
              // });

            }
          );


        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");

        },
        pageAfterOut: function (e, page) {
          
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  }