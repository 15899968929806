import $$ from "dom7";
import assetsInventoryList from "../pages/list.html";
import Template7 from "template7";
import * as dd from 'dingtalk-jsapi'


export default {
  name: "assetsInventoryList",
  path: "/assetsInventoryList/",
  component: {
    template: assetsInventoryList,
    methods: {

    },
    on: {
      pageInit: function (e, page) {
          this.$setState({
              infoList:[{
                  index: '1',
                  product: '齿轮润滑剂 12ml/瓶 江苏石化厂',
                  number: '2瓶'
              },{
                index: '2',
                product: '纱布 24包/箱 北京专业工厂',
                number: '2箱'
            },{
                index: '3',
                product: '口罩 24盒/箱 北京专业工厂',
                number: '2包'
            },{
                index: '4',
                product: '卫生试管 24盒/箱 北京专业工厂',
                number: '4箱'
            }]
          })
          var searchbar = app.searchbar.create({
            el: '.searchbar',
            searchContainer: '.list',
            searchIn: '.number',
          });
        },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};