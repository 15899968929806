// import $$ from 'dom7'
import _ from "lodash";
import expenseClaimForm from '../pages/expenseClaimForm.html'
// import Template7 from 'template7'
// import 'weui'
// import weui from 'weui.js'

export default {
  name: '/expenseClaimForm/',
  path: '/expenseClaimForm/',
  component: {
    template: expenseClaimForm,
    data: () => {
      return {
        isFabBtn: false
      }
    },
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.expense.report.requisition.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      documentTypeChange(){
        this.employeeChange()
      },
      budget_source_change(){
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page
        let formData = page.app.form.convertToData("#creatEditForm");
        if(formData.budget_source == 'project'){
          this.$setState({
            showProject:true,
          })
        }else{
          this.$setState({
            showProject:false,
          })
        }
        
      },
      employeeChange:function () {
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page
        let formData = page.app.form.convertToData("#creatEditForm");

        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;

        if (app.data.session.source == "dingding") {
          //保存的参数
          var saveParams = this.getParams("get_related_borrowing_and_application", {
            dingding_code: dingding_code,
            reimbursement_employee_id: formData.reimbursement_employee_id,
            document_type_id : formData.document_type_id * 1,
          });
        } else if (app.data.session.source == "feishu") {
          //保存的参数
          var saveParams = this.getParams("get_related_borrowing_and_application", {
            feishu_userid: feishu_userid,
            reimbursement_employee_id: formData.reimbursement_employee_id,
            document_type_id : formData.document_type_id * 1,
          });
        } else {
          var saveParams = this.getParams("get_related_borrowing_and_application", {
            wechat_name: vuserid,
            reimbursement_employee_id: formData.reimbursement_employee_id,
            document_type_id : formData.document_type_id * 1,
          });
        }

        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result) {
            self.$setState({
              borrowing_list: result.result.data.borrowing_list,
              borrowing_list_count: result.result.data.borrowing_list.length,
              expense_requisition_list: result.result.data.expense_requisition_list,
              expense_requisition_list_count: result.result.data.expense_requisition_list.length,
              expense_item_list:result.result.data.expense_item_list,
              bank_info_list:result.result.data.bank_info_list
            });
          } else {
            app.dialog.alert("获取信息错误！", "系统提示");
          }
        });  
      },
      getCurrentDate: function () {
        var dd = new Date();
        var year = dd.getFullYear();
        var month = dd.getMonth() + 1;
        var day = dd.getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        return year + "-" + month + "-" + day;
      },
      // page.app.data.expense_data.expense_line_ids = [];
      // page.app.data.expense_data.loan_line_ids = [];
      // page.app.data.expense_data.bank_line_ids = [];
      to_expense_line(e){
        let self = this
        let lineId = parseInt(e.currentTarget.dataset.id)
        const {
            $$
        } = this;       
        var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page;
        let formData = page.app.form.convertToData("#creatEditForm");
        page.router.navigate("/expenseClaimLineForm/", {
            reloadCurrent: false,
            ignoreCache: true,
            context:{
              info: this.info,
              lineId:lineId,
              expense_item_list:self.expense_item_list,
              reimbursement_employee_id:formData.reimbursement_employee_id,
              detail: self.expense_line_ids[lineId]
            }
        });
      },
      to_loan_line(e){
        let self = this
        let lineId = parseInt(e.currentTarget.dataset.id)
        const {
            $$
        } = this;       
        var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page;
        page.router.navigate("/addLoanLine/", {
            reloadCurrent: false,
            ignoreCache: true,
            context:{
              info: this.borrowing_list,
              lineId:lineId,
              detail: self.loan_line_ids[lineId]
            }
        });
      },
      to_bank_line(e){
        let self = this
        let lineId = parseInt(e.currentTarget.dataset.id)
        const {
            $$
        } = this;       
        var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page;
        page.router.navigate("/addBankLine/", {
            reloadCurrent: false,
            ignoreCache: true,
            context:{
              bank_info_list: this.bank_info_list,
              lineId:lineId,
              detail: self.bank_line_ids[lineId]
            }
        });
      },
        //跳转到添加工作信息的界面
      add(){
        const self = this
            const {
                $$
            } = this;       
            var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page;
            let formData = page.app.form.convertToData("#creatEditForm");

            if(!formData.document_type_id){
              app.dialog.alert("请选择单据类型！", "系统提示");
              return;
            }

            page.router.navigate("/expenseClaimLineForm/", {
                reloadCurrent: false,
                ignoreCache: true,
                context:{
                    info: this.info,
                    expense_item_list:self.expense_item_list,
                    reimbursement_employee_id:formData.reimbursement_employee_id
                }
            });
        },
      addLoan(){
        const self = this
            const {
                $$
            } = this;       
            var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page;
            page.router.navigate("/addLoanLine/", {
                reloadCurrent: false,
                ignoreCache: true,
                context:{
                    info: this.borrowing_list
                }
            });
      },
      addBank(){
        const self = this
        const {
            $$
        } = this;       
        var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page;
        page.router.navigate("/addBankLine/", {
            reloadCurrent: false,
            ignoreCache: true,
            context:{
              bank_info_list: this.bank_info_list
            }
        });
      },
      //下载文件
      downloadFile(e) {
        window.open(e.currentTarget.dataset.url);
      },
      //文件上传后的处理函数
      filechange() {
        const self = this
        Array.from(this.$$("#file-attach")[0].files).forEach((ele) => {
          if (ele.type.indexOf("image") > -1) {
            this.fileList.images.push(ele);
          } else {
            this.fileList.files.push(ele);
          }
        });
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files,
        });
        //处理图片上传后事件冲突的问题
        $(".pb-standalone-dark").off("click");
        console.log(app.data);
        $(".pb-standalone-dark").on("click", function (event) {
          self.info.myPhotoBrowserDark.open(
            Number(event.currentTarget.dataset.index)
          );
        });
        $(".addimg").off("click");
        $(".addimg").on("click", function (event) {
          $("#file-attach").trigger("click");
        });
        self.info.myPhotoBrowserDark.params.photos = this.fileList.images;
        // 读取文件的函数
        function readAndPreview(file) {
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              file.url = this.result;
              file.caption = file.name;
            },
            false
          );
          if (!file.url) reader.readAsDataURL(file);
        }
        [].forEach.call(this.fileList.images, readAndPreview);
        setTimeout(() => {
          this.fileList.images.forEach((ele, index) => {
            $("img")[index].src = ele.url;
          });
        }, 100);
        console.log(this.fileList);
      },
      // 删除上传的文件
      delFile(val,attachment_id) {
        let e = window.event;
        e.stopPropagation();
        // if (!this.delete_attachment_ids) this.delete_attachment_ids = [];
        if (e.currentTarget.dataset.id)
          this.delete_attachment_ids.push(Number(e.currentTarget.dataset.id));
        if (val == "img") {
          // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            images: this.fileList.images,
          });

          //处理图片删除后事件冲突的问题
          $(".addimg").off("click");
          $(".addimg").on("click", function (event) {
            $("#file-attach").trigger("click");
          });

          this.info.myPhotoBrowserDark.params.photos = this.fileList.images;
          this.fileList.images.forEach((ele, index) => {
            ele.caption = ele.name;
            $("img")[index].src = ele.url;
          });
        } else {
          // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            files: this.fileList.files,
          });
        }
      },
            // 上传文件到服务器的函数
    submitFiles(val){
        let pro = new Promise((resolve,reject) => {
        var formData = new FormData()
        let post_url = this.$app.data.base_url + '/web/map_upload_attachment'
        formData.append('record_id', val.record_id)
        formData.append('model_name', val.record_model)
        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        for (var i = 0; i < this.fileList.images.length; i++) {
          var file = this.fileList.images[i];
          if(!file.attachment_id)formData.append("file", file)
        }
        for (var i = 0; i < this.fileList.files.length; i++) {
          var file = this.fileList.files[i];
          if(!file.attachment_id)formData.append("file", file)
        }
        if (this.$app.data.session.source === 'dingding') {
          formData.append('dingding_code', this.$app.data.dingding_code)
        }else if (this.$app.data.session.source === 'feishu') {
          formData.append('feishu_userid', this.$app.data.feishu_userid)
        }else{
          formData.append('wechat_name', this.$app.data.session.userid)
        }
        this.$app.request.post(post_url, formData,(result) => {
          if (JSON.parse(result).state === 'ok') {
            var toast = this.$app.toast.create({
              text: '恭喜您,操作成功',
              position: 'center',
              closeTimeout: 2000
            })
            toast.open()
            // self.$app.router.navigate('/workOrderCompleted/', {})
          } else {
            var toast = this.$app.toast.create({
              text: '很遗憾,操作失败',
              position: 'center',
              closeTimeout: 2000
            })
            toast.open()
          }
          resolve()
        })
      })
      return pro
      },
      // toAssetListView(){
      //   var self = this
      //   const {
      //     $$
      //   } = this
      //   var page = $$('.page[data-name="editForm"]')[0].f7Page
      //   page.router.navigate("/assetListView/", {
      //     reloadCurrent: false,
      //     ignoreCache: true,
      //     context: {
      //       assetListViewData: self.asset
      //     }
      //   });
      // },
      date_change(e){
        let val=e||window.event;
        if(!val)return
        if(val.currentTarget&&val.currentTarget.id.indexOf('date')>-1){
          let end = $("input[name='end_date']").val().replace(/-/g, "/")
          let start = $("input[name='start_date']").val().replace(/-/g, "/")
          if(start){
            if(end && new Date(start) - new Date(end) > 0){
              app.dialog.alert("结束时间必须大于开始时间！", "系统提示");
              $("input[name='end_date']").val('')
            }
          }else{
            app.dialog.alert("请选择开始时间！", "系统提示");
          }
        }
      },
      save() {
        const {
          $$
        } = this
        var self =this
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page
        let formData = page.app.form.convertToData("#creatEditForm");

        let expense_line_ids = page.app.data.expense_data.expense_line_ids
        let loan_line_ids = page.app.data.expense_data.loan_line_ids
        let bank_line_ids = page.app.data.expense_data.bank_line_ids

        if (expense_line_ids.length == 0) {
          app.dialog.alert("请填写报销明细行！", "系统提示");
          return;
        }

        var bank_amount = 0
        if(bank_line_ids.length > 0){
          bank_line_ids.forEach(function (e) {
            bank_amount += Number(e.amount)
          });
        }
        if (formData.budget_source == 'project' && !formData.project_id) {
          app.dialog.alert("请选择项目!", "系统提示");
          return;
        }
        if (!bank_line_ids.length && formData.unpaid_amount != 0) {
          app.dialog.alert("请添加收款人转账信息的银行账户!", "系统提示");
          return;
        }

        if (bank_amount != formData.unpaid_amount) {
          app.dialog.alert("转账信息中的金额合计应等于待付金额合计", "系统提示");
          return;
        }
        let submit_expense_line_ids = _.cloneDeep(expense_line_ids)
        let submit_loan_line_ids = _.cloneDeep(loan_line_ids)
        let submit_bank_line_ids = _.cloneDeep(bank_line_ids)
        if(submit_expense_line_ids.length > 0){
          submit_expense_line_ids.forEach(function (e) {
            console.log('expense_item_id',e.expense_item_id) 
            if(e.expense_item_id.indexOf(',') != -1){
              e.expense_item_id = e.expense_item_id.split(',')[1]
            }
            if(e.expense_category.indexOf(',') != -1){
              e.expense_category = e.expense_category.split(',')[1]
            }
            if(e.cost_center_id.indexOf(',') != -1){
              e.cost_center_id = e.cost_center_id.split(',')[1]
            }
          });
        }

        if(submit_loan_line_ids.length > 0){
          submit_loan_line_ids.forEach(function (e) {
            console.log('borrowing_id',e.borrowing_id)
            if(e.borrowing_id.indexOf(',') != -1){
              e.borrowing_id = e.borrowing_id.split(',')[1]
            }

          });
        }

        if(submit_bank_line_ids.length > 0){
          submit_bank_line_ids.forEach(function (e) {
            console.log('bank_account',e.bank_account)
            if(e.bank_account.indexOf(',') != -1){
              e.bank_account = e.bank_account.split(',')[1]
            }
            if(e.type.indexOf(',') != -1){
              e.type = e.type.split(',')[1]
            }

          });
        }

        formData.reimbursement_borrowing_list = submit_loan_line_ids
        formData.reimbursement_line_list = submit_expense_line_ids
        formData.reimbursement_transfer_list = submit_bank_line_ids
        formData.date = self.currentDate
        formData.document_type_id = formData.document_type_id * 1 
        console.log('formData',formData)

        if (app.data.session.source == "dingding") {
          //保存的参数
          var saveParams = this.getParams("create_report_requisition", {
            dingding_code: dingding_code,
            ...formData
          });
        } else if (app.data.session.source == "feishu") {
          //保存的参数
          var saveParams = this.getParams("create_report_requisition", {
            feishu_userid: feishu_userid,
            ...formData
          });
        } else {
          var saveParams = this.getParams("create_report_requisition", {
            wechat_name: vuserid,
            ...formData
          });
        }

        console.log('提交保存',saveParams,self.fileList);
        app.preloader.show();
        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result.state == 'ok') {
            console.log('456456',self.fileList)
            if(self.fileList && (!self.fileList.images.every(ele=>ele.attachment_id) || !self.fileList.files.every(ele=>ele.attachment_id))){
              console.log('123123')
              self.submitFiles({record_id: result.result.data.record_id,record_model:result.result.data.model_name}).then(()=>{
                  app.preloader.hide();
                  app.router.back('/expenseClaimIndex/',{
                    force: true
                  })
              })
            }else{
              app.preloader.hide();
              app.router.back('/expenseClaimIndex/',{
                force: true
              })
            }
          }else if(result.result.state == 'warn'){
            app.preloader.hide();
            app.dialog.alert(result.result.act_err, "系统提示");
          }else if(result.result.state == 'err'){
            app.preloader.hide();
            app.dialog.alert(result.result.act_err, "系统提示");
          }  else{
            app.preloader.hide();
            app.dialog.alert('获取信息错误！', "系统提示");
          }
        });
      },
      //文件上传后的处理函数
      filechange(){ 
        const self = this
        Array.from(this.$$('#file-attach')[0].files).forEach(ele => {
          if(ele.type.indexOf('image')>-1){
            this.fileList.images.push(ele)
          }else{
            this.fileList.files.push(ele)
          }
        })
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files
        })
        //处理图片上传后事件冲突的问题
        $('.pb-standalone-dark').off('click')
       $('.pb-standalone-dark').on('click', function (event) {
        self.info.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        $('.addimg').off('click')
        $('.addimg').on('click',function (event) { 
          $('#file-attach').trigger('click')
       })
       self.info.myPhotoBrowserDark.params.photos = this.fileList.images
        // 读取文件的函数
        function readAndPreview(file,index){
          let reader = new FileReader();
          reader.addEventListener("load", function () {
              file.url = this.result
              file.caption = file.name  
              self.fileList.images.forEach((ele) => {
                $('img')[index].src=this.result
              })       
          }, false);
          if(!file.url)reader.readAsDataURL(file)    
        }
        [].forEach.call(this.fileList.images, readAndPreview)
        // setTimeout(()=>{
        //   this.fileList.images.forEach((ele,index) => {
        //     $('img')[index].src=ele.url
        //   }) 
        // },200)
        console.log(this.fileList)
      },
      // 删除上传的文件
      delFile(val){
        let e = window.event
        e.stopPropagation()
        if(val == 'img'){ // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index),1)
          this.$setState({
            images: this.fileList.images
          })

          //处理图片删除后事件冲突的问题
          $('.addimg').off('click')
          $('.addimg').on('click',function (event) { 
            $('#file-attach').trigger('click')
         })


         this.info.myPhotoBrowserDark.params.photos = this.fileList.images
          this.fileList.images.forEach((ele,index) => {
            ele.caption = ele.name
            $('img')[index].src=ele.url
        })
        }else{  // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index),1)
          this.$setState({
            files: this.fileList.files
          })
        }    
      },
    },
    on: {
      pageInit: function (e, page) {
        // this.info.expense_line_ids = page.app.data.expense_data.expense_line_ids
        const self = this
        
        // 报销人默认为当前提交人
        var currentApplicantId = this.info.applicant_id.id
        $("#reimbursement_employee_id").val(currentApplicantId);
        // this.employeeChange()

        page.app.data.expense_data.expense_line_ids = [];
        page.app.data.expense_data.loan_line_ids = [];
        page.app.data.expense_data.bank_line_ids = [];
        this.$setState({
          images: [],
          files: [],
          fileList:{
            images: [],
            files: [],
          },
          expense_line_ids:[]
        })
        // 照片预览器
        self.info.myPhotoBrowserDark = app.photoBrowser.create({
          photos : self.fileList.images,
          theme: 'dark'
        });
        $('.pb-standalone-dark').on('click', function (event) {
          self.info.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        const getCurrentDate = self.getCurrentDate()
        this.$setState({
          document_type_list:self.info.document_type_list,
          applicant_id_name:self.info.applicant_id.name,
          borrow_type_list:self.info.borrow_type_list,
          bank_info_list:self.info.bank_info_list,
          bank_list:self.info.bank_list,
          expense_dept_list:self.info.expense_dept_list,
          bank_type_list:self.info.bank_type_list,
          currentDate:getCurrentDate
        })

        // 触发上传文件的事件
        $('.addimg').on('click',function (event) { 
            console.log('触发上传文件的事件')
            $('#file-attach').trigger('click')
        })

    
    //   this.$setState({
    //     images: [],
    //     files: [],
    //     fileList:{
    //       images: [],
    //       files: [],
    //     }
    //   })
    //   // 照片预览器
    //   page.app.data.asset_data.myPhotoBrowserDark = app.photoBrowser.create({
    //     photos : this.fileList.images,
    //     theme: 'dark'
    //   });
    //   $('.pb-standalone-dark').on('click', function (event) {
    //     page.app.data.asset_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
    //   });

    //   const getCurrentDate = this.getCurrentDate()
    //   this.$setState({
    //     document_type_list:this.info.document_type_list,
    //     applicant_id_name:this.info.applicant_id.name,
    //     borrow_type_list:this.info.borrow_type_list,
    //     bank_info_list:this.info.bank_info_list,
    //       fileList:{
    //           images:[],
    //           files:[]
    //       },
    //       currentDate:getCurrentDate
    //   })
    //   // 触发上传文件的事件
    //   $('.addimg').on('click',function (event) { 
    //       console.log('触发上传文件的事件')
    //       $('#file-attach').trigger('click')
    //   })

    //   //注册时间选择控件
    //   let start_date = new lCalendar();
    //   start_date.init({
    //     trigger: "#start_date",
    //     type: "datetime",
    //   });
    //   let end_date = new lCalendar();
    //   end_date.init({
    //     trigger: "#end_date",
    //     type: "datetime",
    //   });
    //   $("input[type='text']").bind('input propertychange',this.date_change)
    },
      pageMounted: function (e, page) {
        console.log('pageMounted')
      },
      pageReinit: function (e, page) {
        // console.log('pageReinit',page.app.data.expense_data.expense_line_ids)
        // this.info.expense_line_ids = page.app.data.expense_data.expense_line_ids
        const expense_line_ids =page.app.data.expense_data.expense_line_ids
        const loan_line_ids =page.app.data.expense_data.loan_line_ids
        const bank_line_ids =page.app.data.expense_data.bank_line_ids
        this.$setState({
          expense_line_ids:expense_line_ids,
          loan_line_ids:loan_line_ids,
          bank_line_ids:bank_line_ids
        });


        var total_amount = 0,paid_amount = 0

        if(expense_line_ids.length > 0){
          expense_line_ids.forEach(function (e) {
            console.log('reimbursable_amount',e.reimbursable_amount) 
            total_amount += Number(e.reimbursable_amount)
          });
        }

        if(loan_line_ids.length > 0){
          loan_line_ids.forEach(function (e) {
            console.log('paid_amount',e.offset_amount)
            paid_amount += Number(e.offset_amount)
          });
        }

        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-form"]')[0].f7Page
        let formData = page.app.form.convertToData("#creatEditForm");
        formData.total_amount = total_amount //报销总额
        $('#total_amount').val(parseFloat(total_amount).toFixed(2))
        
        formData.offset_amount = paid_amount //核销金额
        $('#offset_amount').val(parseFloat(paid_amount).toFixed(2))

        paid_amount = total_amount - paid_amount 
        formData.unpaid_amount = paid_amount // 待付金额
        $('#unpaid_amount').val(parseFloat(paid_amount).toFixed(2))


      },
      pageBeforeIn: function (e, page) {
        console.log('pageBeforeIn')
      },
      pageAfterIn: function (e, page) {
        console.log('pageAfterIn')
        page.app.smartSelect.get('.smart-select').on('closed', function () {
          var asset_id = page.app.smartSelect.get('.smart-select').selectEl.value;
          var assetList = page.app.data.asset_data.asset
          if (!assetList) {
            return
          }
          for (var i = 0; i < assetList.length; i++) {
            if (asset_id == assetList[i].id) {
              $$('.place').html(assetList[i].location_id);
            }
          }
        });
      },
      pageBeforeInOut: function (e, page) {
        console.log('pageBeforeInOut')
      },
      pageAfterOut: function (e, page) {
        console.log('pageAfterOut')
      },
      pageBeforeRemove: function (e, page) {
        console.log('pageBeforeRemove')
      }
    }
  }
}

// "wechat_name":"LiMing",
// "document_type_id": "59",
// "reimbursement_employee_id": "1",
// "borrow_type_id": "1",
// "date": "2020-12-31",
// "expense_dept_id": "2477",
// "budget_source": "department",
// "total_amount": "100",
// "offset_amount": "100",
// "paid_amount": "1",
// "unpaid_amount": "99",
// "note": "whf",
// "reimbursement_borrowing_list": [{"borrowing_id": "178",
//     "borrowing_amount": "1",
//     "offset_amount": "1",
//     "note": "whup"}],
// "expense_requisition_id": "47",
// "reimbursement_transfer_list": [{"bank_account": "234567899872679",
//     "amount": "332",
//     "type": "business card",
//     "bank_id": "661",
//     "account_name": "wangkwq"}],
// "reimbursement_line_list": [{
//     "line_number": "1",
//     "expense_item_id": "280",
//     "expense_category": "101",
//     "quantity": "1",
//     "price": "10",
//     "amount": "10",
//     "department_id": "2477",
//     "cost_center_id": "662",
//     "note": "111",
//     "currency_id": "8",
//     "foreign_currency_id": "8",
//     "amount_fc": "10",
//     "date_start": "2020-01-01",
//     "date_stop": "2020-12-31",
//     "place": "China",
//     "expense_standard": "23",
//     "reimbursable_amount_fc": "100",
//     "reimbursable_amount": "100"
// }]