import $ from 'dom7'
import _ from 'lodash'
import style from '../styles/home.less'
import Template7 from 'template7'

import purchaseRequisitionDetailHtml from '../pages/purchaseRequisition/purchaseRequisitionDetail.html'

import contractHtml from '../pages/contract/contractDetail.html'

import purchaseOrdersDetailHtml from '../pages/purchaseOrders/purchaseOrdersDetail.html'

import paymentRequisitionDetailHtml from '../pages/paymentRequisition/paymentRequisitionDetail.html'

import supplierInvoiceDetailHtml from '../pages/supplierInvoice/supplierInvoiceDetail.html'

import expenseRequisitionDetailHtml from '../pages/expenseRequisition/expenseRequisitionDetail.html'

import expenseReimbursementDetailHtml from '../pages/expenseReimbursement/expenseReimbursementDetail.html'

import supplierRequisitionDetailHtml from '../pages/supplierRequisition/supplierRequisitionDetail.html'

import customerRequisitionDetailHtml from '../pages/customerRequisition/customerRequisitionDetail.html'

import partnerRequisitionDetailHtml from '../pages/partnerRequisition/partnerRequisitionDetail.html'

import assetacceptanceDetailHtml from '../pages/assetacceptance/assetacceptanceDetail.html'

import assetSundryReqDetailHtml from '../pages/assetSundryReq/assetSundryReqDetail.html'

import requisitionPlatformDetailHtml from '../pages/requisitionPlatform/requisitionPlatformDetail.html'

import tongYongDetailHtml from '../pages/tongYong/tongYongDetail.html'

import lineDetailRoutes from './lineDetailRoutes'

import actionRoutes from './actionTemplateRoutes'

//获取工作流图形参数
const getWorkflowData = function (current_id,workflow_id) {
  var dbname = this.$app.data.dbname
  var userid = this.$app.data.userid
  var password = this.$app.data.password
  return {
    jsonrpc: '2.0',
    method: 'call',
    params: {
      service: 'object',
      method: 'execute',
      args: [
        dbname,
        userid,
        password,
        'kthrp.base.workflow',
        'get_diagram_info_kthrp',
        {
          current_id,
          workflow_id
        }
      ]
    }
  }
}

const init_workflow = function (result) {
  var self = this;
  var G = go.GraphObject.make;
  if (!self.is_bind) {
      var myDiagram =
          G(go.Diagram, 'myDiagramDiv',
              {
                  "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
                  initialContentAlignment: go.Spot.Center,
                  "grid.visible": false,
                  "undoManager.isEnabled": true
              });
      self.myDiagram = myDiagram;
      self.is_bind = true
  }
  self.myDiagram.nodeTemplate =
      G(go.Node, "Auto",
          {
              locationSpot: go.Spot.Center,
              desiredSize: new go.Size(120, NaN),
              minSize: new go.Size(120,42)
          },
          new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
          G(go.Shape, "RoundedRectangle",
              {
                  stroke: "#000",
                  strokeWidth: 1.5
              },
              new go.Binding("size", "size_xy"),
              new go.Binding("figure", "fig"),
              new go.Binding("fill", "color")),
          G(go.TextBlock, "default text",
              {font: "bold normal 12px sans-serif", stroke: "#000000",
              overflow: go.TextBlock.OverflowEllipsis /* the default value */,
              alignment: go.Spot.Center, textAlign: "center",
              maxLines: 3},
              new go.Binding("text", "key")));
  self.myDiagram.nodeTemplateMap.add("Terminal",
      G(go.Node, "Spot",
          {locationSpot: go.Spot.Center},
          new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
          G(go.Shape, "Ellipse",
              {
                  stroke: null,
                  width: 65,
                  height: 40,
              },
              new go.Binding("size", "size_xy"),
              new go.Binding("figure", "fig"),
              new go.Binding("fill", "color")),

          G(go.TextBlock, "default text",
              {font: "normal 20px sans-serif", stroke: "#000", margin: 6},
              new go.Binding("text", "key"))));
  self.myDiagram.linkTemplate =
      G(go.Link,  // the whole link panel
          {
              curve: go.Link.None,
              adjusting: go.Link.Stretch,
              reshapable: true,
              relinkableFrom: true,
              relinkableTo: true,
              toShortLength: 3,
              corner: 10
          },
          new go.Binding("points").makeTwoWay(),
          G(go.Shape,  // the link shape
              {strokeWidth: 1.3, stroke: "#999"}),
          G(go.Shape,  // the arrowhead
              {toArrow: "standard", stroke: "#999"}),
          G(go.TextBlock,   // the label text
              {
                  textAlign: "center",
                  font: "9pt helvetica, arial, sans-serif",
                  stroke: "#666",
                  // margin: 4,
                  editable: true,  // enable in-place editing
                  segmentOffset: new go.Point( 0 , - 10 ),
                  segmentOrientation: go.Link.OrientUpright
              },
              // editing the text automatically updates the model data
              new go.Binding("text").makeTwoWay()
          )
      );
  var myModel = G(go.GraphLinksModel);
  myModel.nodeKeyProperty = result.nodeKeyProperty;
  myModel.nodeDataArray = result.nodeDataArray;
  myModel.linkDataArray = result.linkDataArray;
  self.myDiagram.model = myModel;
  app.popup.open('.popup-workflow')
}

//获取行详情post参数
const getLineDetailParamsTongYong = function (taskId, lineId, lineType, lang) {
  var dbname = this.$app.data.dbname
  var userid = this.$app.data.userid
  var password = this.$app.data.password
  var sourceModel = ''
  if(this.$app.data.session.source === "dingding") {
    sourceModel = "kthrp.dingding.interface"
  } else if(this.$app.data.session.source === "feishu") {
    sourceModel = "kthrp.feishu.map.interface"
  } else {
    sourceModel = "kthrp.wechat.map.interface"
  }

  return {
    jsonrpc: '2.0',
    method: 'call',
    params: {
      service: 'object',
      method: 'execute',
      args: [
        dbname,
        userid,
        password,
        sourceModel,
        'mobile_workflow_info_line',
        {
          task_id: taskId,
          line_id: lineId,
          line_type: lineType,
          is_general_map: true,
          lang: lang,
        }
      ]
    }
  }
}

//获取行详情post参数
const getLineDetailParams = function (taskId, lineId, lineType) {
  var dbname = this.$app.data.dbname
  var userid = this.$app.data.userid
  var password = this.$app.data.password
  var sourceModel = ''
  if(this.$app.data.session.source === "dingding") {
    sourceModel = "kthrp.dingding.interface"
  } else if(this.$app.data.session.source === "feishu") {
    sourceModel = "kthrp.feishu.map.interface"
  } else {
    sourceModel = "kthrp.wechat.map.interface"
  }
  return {
    jsonrpc: '2.0',
    method: 'call',
    params: {
      service: 'object',
      method: 'execute',
      args: [
        dbname,
        userid,
        password,
        sourceModel,
        'mobile_workflow_info_line',
        {
          task_id: taskId,
          line_id: lineId,
          line_type: lineType,
        }
      ]
    }
  }
}
//获取操作界面post参数
const getActionParams = function (taskId, action, sourceModel) {
  var dbname = this.$app.data.dbname
  var userid = this.$app.data.userid
  var password = this.$app.data.password
  return {
    jsonrpc: '2.0',
    method: 'call',
    params: {
      service: 'object',
      method: 'execute',
      args: [
        dbname,
        userid,
        password,
        sourceModel,
        'mobile_workflow_prepare',
        {
          task_id: taskId,
          operation: action
        }
      ]
    }
  }
}
//转操作页面通用方法
const gloGoAction = function (operation, pageName) {
  console.log(operation)
  var e = window.event || arguments[0]
  const {
    $$
  } = this
  var page = $$('.page[data-name=' + pageName + ']')[0].f7Page
  var title = e.currentTarget.dataset.title
  if(page.app.data.kthrp_customer_code == 'weilai' && title == '沟通'){
    title = '决策组'
  }
  if(page.app.data.kthrp_customer_code == 'weilai' && title == '同意'){
    title = ''
  }
  var returnAfter = e.currentTarget.dataset.returnAfter || ''
  var taskId = parseInt(e.currentTarget.dataset.taskId)
  var sourceModel
  if (operation != 'reject') {
    if (page.app.data.session.source === "dingding") {
      sourceModel = "kthrp.dingding.interface"
    } else if (page.app.data.session.source === "feishu") {
      sourceModel = "kthrp.feishu.map.interface"
    } else {
      sourceModel = "kthrp.wechat.map.interface"
    }
    var actionParams = getActionParams.call(this, taskId, operation, sourceModel)
    page.app.request.postJSON(page.app.data.url, actionParams, function (aciton_data) {
      console.log(aciton_data)
      page.router.navigate('/kthrp.contract.contract/aciton/', {
        context: {
          data: aciton_data.result,
          title: title,
          operation: operation,
          returnAfter: returnAfter,
          customerCode: page.app.data.kthrp_customer_code,
          lang: page.app.data.session.lang
        }
      })
    })
  } else {
    var document_id = parseInt(e.currentTarget.dataset.id)
    var document_model = e.currentTarget.dataset.model
    page.router.navigate('/kthrp.contract.contract/aciton/', {
      context: {
        data: {
          document_id: document_id,
          task_id: taskId,
          document_model: document_model
        },
        title: title,
        operation: operation,
        returnAfter: returnAfter,
        lang: page.app.data.session.lang
      }
    })
  }
}

const getPreviewFile = function (e) {
  var nowurl = e.currentTarget.dataset.src
  var nowname = e.currentTarget.dataset.name
  var nowsize = parseInt(e.currentTarget.dataset.size)
  wx.previewFile({
    url: nowurl, // 需要预览文件的地址(必填，可以使用相对路径)
    name: nowname, // 需要预览文件的文件名(不填的话取url的最后部分)
    size: nowsize // 需要预览文件的字节大小(必填)
  })
}
const detailRoutes = [
  //通用单据
  {
    name: '/tongyong/',
    path: '/tongyong/',
    component: {
      template: tongYongDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="tongYongDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParamsTongYong.call(this, taskId, lineId, lineType, page.app.data.session.lang)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            page.router.navigate('/tongyongline/', {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                info: result_line_detail.result.info,
                lang: page.app.data.session.lang
              }
            })
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'tongYongDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {}
      }
    }
  },
  //统一申请
  {
    name: '/kthrp.jiahui.request.platform/',
    path: '/kthrp.jiahui.request.platform/',
    component: {
      template: requisitionPlatformDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="requisitionPlatformDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.jiahui.request.platform.line':
                page.router.navigate('/rplatformlinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'requisitionPlatformDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {}
      }
    }
  },
  //资产申请
  {
    name: 'kthrp.asset.sundry.request',
    path: '/kthrp.asset.sundry.request/',
    component: {
      template: assetSundryReqDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="assetSundryReqDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.asset.sundry.request.line':
                page.router.navigate('/asrlinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'assetSundryReqDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {}
      }
    }
  },
  //资产验收
  {
    name: 'kthrp.asset.acceptance',
    path: '/kthrp.asset.acceptance/',
    component: {
      template: assetacceptanceDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="assetacceptanceDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.asset.asset':
                page.router.navigate('/aalinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'assetacceptanceDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {}
      }
    }
  },
  //费用报销
  {
    name: 'kthrp.expense.report',
    path: '/kthrp.expense.report/',
    component: {
      template: expenseReimbursementDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="expenseReimbursementDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.expense.report.line':
                page.router.navigate('/expensereimbursementLinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
              case 'kthrp.expense.report.borrowing':
                page.router.navigate('/expensereimbursementoffsetdetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'expenseReimbursementDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          console.log('费用报销页面初始化')
          this.is_bind = false
        }
      }
    }
  },
  //采购申请
  {
    name: 'kthrp.purchase.requisition',
    path: '/kthrp.purchase.requisition/',
    component: {
      template: purchaseRequisitionDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="purchaseRequisitionDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.purchase.requisition.line':
                page.router.navigate('/prlinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'purchaseRequisitionDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          console.log('费用报销页面初始化')
        }
      }
    }
  },
  //采购订单
  {
    name: 'kthrp.purchase.order',
    path: '/kthrp.purchase.order/',
    component: {
      template: purchaseOrdersDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="purchaseOrdersDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.purchase.order.line':
                page.router.navigate('/polinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'purchaseOrdersDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          var self = this
          console.log('采购订单page')
        }
      }
    }
  },
  //合同
  {
    name: 'kthrp.contract.contract',
    path: '/kthrp.contract.contract/',
    component: {
      template: contractHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        //转行详情界面
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="contractDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.contract.contract.line':
                page.router.navigate('/ccobjectlinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
              case 'kthrp.contract.contract.party':
                page.router.navigate('/ccpartylinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'contractDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          console.log('合同页面初始化')
        }
      }
    }
  },
  //付款申请
  {
    name: 'kthrp.payable.payment.request',
    path: '/kthrp.payable.payment.request/',
    component: {
      template: paymentRequisitionDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="paymentRequisitionDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.payable.payment.request.invoice':
                page.router.navigate('/paymentinvoicelinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
              case 'kthrp.payable.payment.request.prepayment':
                page.router.navigate('/prepaymentlinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'paymentRequisitionDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          console.log('付款申请page')
        }
      }
    }
  },
  //供应商发票申请
  {
    name: 'kthrp.payable.invoice',
    path: '/kthrp.payable.invoice/',
    component: {
      template: supplierInvoiceDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="supplierInvoiceDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.payable.invoice.line':
                page.router.navigate('/supplierinvoicelinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'supplierInvoiceDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          console.log('供应商发票申请page')
        }
      }
    }
  },
  //费用申请
  {
    name: 'kthrp.expense.expense.requisition',
    path: '/kthrp.expense.expense.requisition/',
    component: {
      template: expenseRequisitionDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="expenseRequisitionDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.expense.expense.requisition.line':
                page.router.navigate('/expenserequisitionlinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
              case 'kthrp.expense.requisition.detailed.personnel':
                page.router.navigate('/expenserequisitiondetailedpersonneldetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'expenseRequisitionDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          var self = this
          console.log('费用申请page')
        }
      }
    }
  },
  //供应商申请单
  {
    name: 'kthrp.purchase.supplier.requisition.supplier',
    path: '/kthrp.purchase.supplier.requisition.supplier/',
    component: {
      template: supplierRequisitionDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="supplierRequisitionDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.purchase.supplier.requisition.change.record':
                page.router.navigate('/supplierchangerecordsLinedetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'supplierRequisitionDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          var self = this
          console.log('供应商申请page')
        }
      }
    }
  },
  //业务伙伴申请单
  {
    name: 'kthrp.purchase.supplier.requisition.partner',
    path: '/kthrp.purchase.supplier.requisition.partner/',
    component: {
      template: partnerRequisitionDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="partnerRequisitionDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.purchase.supplier.requisition.change.record':
                page.router.navigate('/partnerChangeRecordsLineDetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'partnerRequisitionDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          var self = this
          console.log('业务伙伴申请单page')
        }
      }
    }
  },
  //客户申请单
  {
    name: 'kthrp.purchase.supplier.requisition.customer',
    path: '/kthrp.purchase.supplier.requisition.customer/',
    component: {
      template: customerRequisitionDetailHtml,
      style: style,
      methods: {
        // previewFile: function (e) {
        //   getPreviewFile(e);
        // },
        view_pdf(e){
          app.methods.view_file(e)
        },
        get_workflow(){
          const self = this
          const params = getWorkflowData.call(this,this.info.id, this.info.workflow_id)
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res)
            if(res.result){
              init_workflow.call(self,res.result)
            }
          })
        },
        toLineDetail: function (e) {
          const {
            $$
          } = this
          var page = $$('.page[data-name="customerRequisitionDetail"]')[0].f7Page
          var taskId = parseInt(e.currentTarget.dataset.taskId)
          var lineId = parseInt(e.currentTarget.dataset.lineId)
          var lineType = e.currentTarget.dataset.lineType
          var model = e.currentTarget.dataset.model
          var line_detail_params = getLineDetailParams.call(this, taskId, lineId, lineType)
          page.app.request.postJSON(page.app.data.url, line_detail_params, function (result_line_detail) {
            console.log(result_line_detail)
            switch (model) {
              case 'kthrp.purchase.supplier.requisition.change.record':
                page.router.navigate('/customerChangeRecordsLineDetail/', {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: result_line_detail.result.info
                  }
                })
                break
            }
          })
        },
        //转操作界面
        goAction: function (operation) {
          gloGoAction.call(this, operation, 'customerRequisitionDetail')
        }
      },
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          var self = this
          console.log('客户申请单page')
        }
      }
    }
  }
]

let routes = _.concat(detailRoutes, lineDetailRoutes, actionRoutes)
export default routes