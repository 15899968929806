import $$ from "dom7";
import preIndexHtml from "../pages/preIndex.html";
import Template7 from "template7";

export default {
  name: "changepreindex",
  path: "/changepreindex/",
  component: {
    template: preIndexHtml,
    data: () => {
      return {};
    },
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      next: function (e) {
        // let self = this;
        // let page = $$('.page[data-name="expenseapp-form"]')[0].f7Page
        // let formData = page.app.form.convertToData('#expenseapp-form')
        let self = this
        let userObj = self.getUserObj()
        let changeTypeFormData = this.$app.form.convertToData('#change-type-form'); 
        let obj = {
          ...userObj,
          context_action_type:changeTypeFormData.context_action_type.split(',')[0]
        }
        this.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.employee.change.apply.interface', 'get_other_fields', obj), function (res) {
          console.log(res);
          self.$app.router.navigate("/regularizationappform/", {
            reloadCurrent: false,
            ignoreCache: true,
            context: {
              info: res.result,
              context_action_type:changeTypeFormData.context_action_type
            }
          });
        });

        // this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'document_default_get', obj), function (res) {
        //   console.log(res);
        //   self.$app.router.navigate("/changeform/", {
        //     reloadCurrent: false,
        //     ignoreCache: true,
        //     context: {
        //       info: res.result.data
        //     }
        //   });
        // });
      }
    },
    on: {
      pageInit: function (e, page) {
        // let self = this
        // const {
        //   $$
        // } = this
        // this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'get_document_type', this.getUserObj()), function (res) {
        //   console.log(res)
        //   self.$setState({
        //     leaveType: res.result.data,
        //   });
        // })
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};