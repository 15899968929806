import $$ from "dom7";
import expenseFormHtml from "../pages/expenseForm.html";
import Template7 from "template7";
import expenseLineDistributionTemplate from "../pages/expenseLineDistribution.html";
import * as dd from 'dingtalk-jsapi'



export default {
  name: "expenseform",
  path: "/expenseform/",
  component: {
    template: expenseFormHtml,
    data: () => {
      return {
        isFabBtn: false
      };
    },
    methods: {
      getExpenseItemLineParams: function (expense_item_head_id) {
        var wechatName = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        return {
          "jsonrpc": "2.0",
          "method": "2.0",
          "params": {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              "kthrp.expense.line.interface",
              "get_expense_item_line",
              {
                "wechat_name": wechatName,
                "expense_item_head_id": expense_item_head_id,
              }
            ]
          }
        };
      },
      getCreateAndWriteExpenseLineParams: function (expenseFormData) {
        var wechatName = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        return {
          "jsonrpc": "2.0",
          "method": "2.0",
          "params": {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              "kthrp.expense.line.interface",
              "create_and_write_expense_line",
              expenseFormData
            ]
          }
        };
      },
      previewImage: function (e) {
        const {
          $$
        } = this;
        var page = $$('.page[data-name="expense-form"]')[0].f7Page;
        var nowurl = e.currentTarget.dataset.src;
        if (page.app.data.session.source == 'dingding') {
          dd.ready(function () {
            dd.biz.util.previewImage({
              urls: page.app.data.asset_data.form_data.images_list, //图片地址列表
              current: nowurl, //当前显示的图片链接
              onSuccess: function (result) {
                console.log(result)
              },
              onFail: function (err) {
                console.log(err)
              }
            })
          });
        } else if (page.app.data.session.source == 'feishu') {
          window.tt.ready(function () {
            window.tt.previewImage({
              urls: page.app.data.asset_data.form_data.images_list, //图片地址列表
              current: nowurl, //当前显示的图片链接
              success: function (result) {
                console.log(result)
              },
              fail: function (err) {
                console.log(err)
              }
            })
          });
        } else {
          wx.previewImage({
            current: nowurl,
            urls: page.app.data.asset_data.form_data.images_list
          });
        }
      },
      getCurrentDate: function () {
        var dd = new Date();
        var year = dd.getFullYear();
        var month = dd.getMonth() + 1;
        var day = dd.getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        return year + "/" + month + "/" + day;
      },
      addNewForm: function () {
        const {
          $$
        } = this;
        var page = $$('.page[data-name="expense-form"]')[0].f7Page;
        var compiledExpenseLineDistributionTemplate = Template7.compile(
          expenseLineDistributionTemplate
        );
        var expense_item_head_id = parseInt($$("#expense-requisition-item").val());
        var expenseItemLineParams = this.getExpenseItemLineParams(expense_item_head_id);
        page.app.request.postJSON(
          page.app.data.url, expenseItemLineParams,
          function (result) {
            var html = compiledExpenseLineDistributionTemplate({
              expenseItemHead: result.result.expense_item_line
            });
            $$('.page[data-name="expense-form"] .expense-form-list').append(html);
            var $$form = $$('.page[data-name="expense-form"] .expense-form-list form:last-child');
            $$form.find("#expense-number").on("input", function () {
              if ($$(this).val() == "" || $$form.find("#expense-quantity").val() == "" || isNaN(Number($$(this).val()))) {
                $$form.find("#expense-amount").val($$form.find("#expense-amount").val());
              } else {
                var expense_amount_value = parseFloat($$(this).val()) * parseInt($$form.find("#expense-quantity").val());
                $$form.find("#expense-amount").val(expense_amount_value.toFixed(2));
              }
            });
            $$form.find("#expense-quantity").on("input", function () {
              if ($$form.find("#expense-quantity").val() == "" || $$form.find("#expense-number").val() == "" || isNaN(Number($$(this).val()))) {
                $$form.find("#expense-amount").val($$form.find("#expense-amount").val());
              } else {
                var expense_amount_value = parseInt($$(this).val()) * parseFloat($$form.find("#expense-number").val());
                $$form.find("#expense-amount").val(expense_amount_value.toFixed(2));
              }
            });
            $$form.find("#expense-amount").on("input", function () {
              if ($$(this).val() == "" || $$form.find("#expense-quantity").val() == "" || isNaN(Number($$(this).val()))) {
                $$form.find("#expense-number").val($$form.find("#expense-number").val());
              } else {
                var expense_price_value = parseFloat($$(this).val()) / parseInt($$form.find("#expense-quantity").val());
                $$form.find("#expense-number").val(expense_price_value.toFixed(2));
              }
            });
            $$(".delete-line-btn").on("click", function (e) {
              console.log("删除行");
              console.log($$(e.currentTarget).parent().parent());
              $$(e.currentTarget).parent().parent().remove();
            });
          });


        // $$(html).insertAfter("#expense-form");
      },
      getSum: function (arr) {
        var len = arr.length;
        var sum = 0;
        for (var i = 0; i < len; i++) {
          sum += parseFloat(arr[i].amount);
        }
        return sum;
      },
    },
    on: {
      pageInit: function (e, page) {
        var self = this;
        this.imagesData = {
          localId: [],
          serverId: []
        };

        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });

        var calendarExpenseStartDate = page.app.calendar.create({
          inputEl: "#calendar-expense-start-date",
          dateFormat: "yyyy-mm-dd",
          openIn: 'customModal',
          header: true,
          footer: true,
        });

        var calendarExpenseEndDate = page.app.calendar.create({
          inputEl: "#calendar-expense-end-date",
          dateFormat: "yyyy-mm-dd",
          openIn: 'customModal',
          header: true,
          footer: true,
        });
        //默认隐藏新增浮动按钮
        $$('.page[data-name="expense-form"] .fab-expense-form').hide();


        $$("#expense-price").on("input", function () {
          if ($$("#expense-quantity").val() == "" || isNaN(Number($(this).val()))) {
            $$("#expense-amount").val($$("#expense-amount").val());
          } else if ($$(this).val() == "") {
            $$("#expense-amount").val(0.00);
          } else {
            var expense_amount_value = parseFloat($$(this).val()) * parseInt($$("#expense-quantity").val());
            $$("#expense-amount").val(expense_amount_value.toFixed(2));
          }
        });
        $$("#expense-quantity").on("input", function () {
          if ($$("#expense-price").val() == "" || isNaN(Number($(this).val()))) {
            $$("#expense-amount").val($$("#expense-amount").val());
          } else if ($$(this).val() == "") {
            $$("#expense-amount").val(0.00);
          } else {
            var expense_amount_value = parseInt($$(this).val()) * parseFloat($$("#expense-price").val());
            $$("#expense-amount").val(expense_amount_value.toFixed(2));
          }
        });
        $$("#expense-amount").on("input", function () {
          if ($$("#expense-quantity").val() == "" || isNaN(Number($(this).val()))) {
            $$("#expense-price").val($$("#expense-price").val());
          } else if ($$(this).val() == "") {
            $$("#expense-price").val(0.00);
          } else {
            var expense_price_value = parseFloat($$(this).val()) / parseInt($$("#expense-quantity").val());
            $$("#expense-price").val(expense_price_value.toFixed(2));
          }
        });
        this.$setState({
          expenseItemHead: page.app.data.expense_data.expenseItemHead,
          expensePlace: page.app.data.expense_data.expensePlace,
          expenseSupplier: page.app.data.expense_data.expenseSupplier,
          isFabBtn: false
        });
        /**
         *若存在form data则填充expense form
         *若不存在form data则按照新建表单初始化字段value
         */
        if ("form_data" in page.app.data.expense_data) {
          //填充old attachments array
          this.imagesData.serverId = page.app.data.expense_data.form_data.attachment_ids;
          app.form.fillFromData(
            "#expense-form",
            page.app.data.expense_data.form_data
          );

          var distributionLen = page.app.data.expense_data.form_data.expense_line_distribution_ids.length;
          if (distributionLen > 0) {

            //控制新增行按钮显隐
            $$('.page[data-name="expense-form"] .fab-expense-form').show();
            var compiledExpenseLineDistributionTemplate = Template7.compile(
              expenseLineDistributionTemplate
            );

            page.app.data.expense_data.form_data.expense_requisition_item_id
            var expenseItemLineParams = this.getExpenseItemLineParams(page.app.data.expense_data.form_data.expense_requisition_item_id);
            page.app.request.postJSON(
              page.app.data.url, expenseItemLineParams,
              function (result) {
                var html = compiledExpenseLineDistributionTemplate({
                  expenseItemHead: result.result.expense_item_line
                });
                for (var i = 0; i < distributionLen; i++) {
                  $$(html).appendTo('.page[data-name="expense-form"] .expense-form-list');
                  var $$form = $$('.page[data-name="expense-form"] .expense-form-list form:last-child');
                  $$form.find("#expense-number").on("input", function (e) {
                    if ($$(this).closest("form").find("#expense-quantity").val() == "" || isNaN(Number($$(this).val()))) {
                      $$(this).closest("form").find("#expense-amount").val($$(this).closest("form").find("#expense-amount").val());
                    } else if ($$(this).val() == "") {
                      $$(this).closest("form").find("#expense-amount").val(0.00);
                    } else {
                      var expense_amount_value = parseFloat($$(this).val()) * parseInt($$(this).closest("form").find("#expense-quantity").val());
                      $$(this).closest("form").find("#expense-amount").val(expense_amount_value.toFixed(2));
                    }
                  });
                  $$form.find("#expense-quantity").on("input", function (e) {
                    if ($$(this).closest("form").find("#expense-number").val() == "" || isNaN(Number($$(this).val()))) {
                      $$(this).closest("form").find("#expense-amount").val($$(this).closest("form").find("#expense-amount").val());
                    } else if ($$(this).val() == "") {
                      $$(this).closest("form").find("#expense-amount").val(0.00);
                    } else {
                      var expense_amount_value = parseInt($$(this).val()) * parseFloat($$(this).closest("form").find("#expense-number").val());
                      $$(this).closest("form").find("#expense-amount").val(expense_amount_value.toFixed(2));
                    }
                  });
                  $$form.find("#expense-amount").on("input", function (e) {
                    if ($$(this).closest("form").find("#expense-quantity").val() == "" || isNaN(Number($$(this).val()))) {
                      $$(this).closest("form").find("#expense-number").val($$(this).closest("form").find("#expense-number").val());
                    } else if ($$(this).val() == "") {
                      $$(this).closest("form").find("#expense-number").val(0.00);
                    } else {
                      var expense_price_value = parseFloat($$(this).val()) / parseInt($$(this).closest("form").find("#expense-quantity").val());
                      $$(this).closest("form").find("#expense-number").val(expense_price_value.toFixed(2));
                    }
                  });

                }
                $$('.page[data-name="expense-form"] .expense-form-list  .expense-line-distribution-form').each(function (index, item) {
                  app.form.fillFromData(
                    $$(this),
                    page.app.data.expense_data.form_data.expense_line_distribution_ids[index]
                  );

                  $$(this).find(".smart-select .item-after").text(page.app.data.expense_data.form_data.expense_line_distribution_ids[index].expense_item_name);
                });
                $$(".delete-line-btn").on("click", function (e) {
                  console.log("删除行");
                  console.log($$(e.currentTarget).parent().parent());
                  $$(e.currentTarget).parent().parent().remove();
                });
              });
          }
        } else {
          app.form.fillFromData("#expense-form", {
            expense_requisition_item_id: "",
            date_start: "",
            date_stop: "",
            price: "",
            quantity: "",
            amount: "",
            place: "",
            supplier: "",
            detail_place: "",
            note: ""
          });
          var currentDate = this.getCurrentDate();
          calendarExpenseStartDate.setValue([currentDate]);
          calendarExpenseEndDate.setValue([currentDate]);
          /*
           *金额默认运算逻辑
           */
          page.$el.find("#expense-quantity").val("1");
        }


        if (page.app.data.session.source === 'dingding') {
          var uploadCount = 0;
          var tmplIos =
            '<li class="weui-uploader__file" style="background-image:url(#url#)"></li>',
            tmplAndroid =
            '<li class="weui-uploader__file"><img width="79" height="79" src="#url#"/></li>',
            $$gallery = $$("#gallery"),
            $$galleryImg = $$("#galleryImg"),
            $$uploaderInput = $$("#expenseUploaderInput"),
            $$uploaderFiles = $$("#expenseUploaderFiles");

          dd.ready(function () {
            $$uploaderInput.on('click', function (e) {
              console.log('upload')
              dd.biz.util.uploadImage({
                stickers: {
                  time: '',
                  dateWeather: '',
                  username: '',
                  address: ''
                },
                multiple: true,
                max: 2,
                onSuccess: function (res) {
                  console.log(res)
                  for (var i = 0; i < res.length; i++) {
                    if (page.app.device.ios) {
                      console.log('ios')
                      $$uploaderFiles.append($$(tmplIos.replace('#url#', res[i])))
                      self.imagesData.serverId.push(res[i]) // 返回图片的服务器端ID
                    } else if (page.app.device.android) {
                      console.log('android')
                      $$uploaderFiles.append($$(tmplAndroid.replace('#url#', res[i])))
                      self.imagesData.serverId.push(res[i]) // 返回图片的服务器端ID
                    }
                  }
                },
                onFail: function (err) {}
              })
            })
          })
          var liIndex;
          $$uploaderFiles.on("click", "li", function () {
            liIndex = $$(this).index();
            console.log(liIndex);
            // $$galleryImg.css("background-image", $$(this).find("img").attr("src"));
            $$galleryImg.attr("style", $$(this).attr("style"));
            $$gallery.show(100);
          });
          $$gallery.on("click", function () {
            $$gallery.hide(100);
          });
          $$(".weui-gallery__del").on("click", function () {
            console.log(liIndex);
            self.imagesData.serverId.splice(liIndex, 1);
            $$uploaderFiles
              .find("li")
              .eq(liIndex)
              .remove();
            console.log(self.imagesData.serverId);
          });

        // } else if (page.app.data.session.source === 'feishu') {
        //   var uploadCount = 0;
        //   var tmplIos =
        //     '<li class="weui-uploader__file" style="background-image:url(#url#)"></li>',
        //     tmplAndroid =
        //     '<li class="weui-uploader__file"><img width="79" height="79" src="#url#"/></li>',
        //     $$gallery = $$("#gallery"),
        //     $$galleryImg = $$("#galleryImg"),
        //     $$uploaderInput = $$("#expenseUploaderInput"),
        //     $$uploaderFiles = $$("#expenseUploaderFiles");

        //     window.tt.ready(function () {
        //       $$uploaderInput.on("click", function (e) {
        //         console.log("upload");
        //         window.tt.chooseImage({
        //           count: 1,
        //           sizeType: ["original"],
        //           sourceType: ["album", "camera"],
        //           success: function (res) {
        //             console.log(res);
        //             // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
        //             // localId 可以用于微信手机版图片显示（目前PC版不可用）
        //             var localIds = res.localIds;
        //             for (var i = 0; i < localIds.length; i++) {
        //               var localId = localIds[i];
        //               if (page.app.device.ios) {
        //                 window.tt.getLocalImgData({
        //                   localId: localId, // 图片的localID
        //                   success: function (getLocalImgData_res) {
        //                     console.log(getLocalImgData_res);
        //                     $$uploaderFiles.append(
        //                       $$(
        //                         tmplIos.replace(
        //                           "#url#",
        //                           getLocalImgData_res.localData
        //                         )
        //                       )
        //                     );
        //                     window.tt.uploadImage({
        //                       localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
        //                       isShowProgressTips: 1, // 默认为1，显示进度提示
        //                       success: function (uploadImage_res) {
        //                         self.imagesData.serverId.push(
        //                           uploadImage_res.serverId
        //                         ); // 返回图片的服务器端ID
        //                       }
        //                     });
        //                   }
        //                 });
        //               } else if (page.app.device.android) {
        //                 console.log("android");
        //                 var formatLocalId = localId;
        //                 formatLocalId = localId.substring(6, localId.length);
        //                 $$uploaderFiles.append(
        //                   $$(tmplIos.replace("#url#", localId))
        //                 );
        //                 window.tt.uploadImage({
        //                   localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
        //                   isShowProgressTips: 1, // 默认为1，显示进度提示
        //                   success: function (uploadImage_res) {
        //                     console.log(uploadImage_res);
        //                     self.imagesData.serverId.push(uploadImage_res.serverId); // 返回图片的服务器端ID
        //                   }
        //                 });
        //               }
        //             }
        //           }
        //         });
        //       });
        //     });
        //   var liIndex;
        //   $$uploaderFiles.on("click", "li", function () {
        //     liIndex = $$(this).index();
        //     console.log(liIndex);
        //     // $$galleryImg.css("background-image", $$(this).find("img").attr("src"));
        //     $$galleryImg.attr("style", $$(this).attr("style"));
        //     $$gallery.show(100);
        //   });
        //   $$gallery.on("click", function () {
        //     $$gallery.hide(100);
        //   });
        //   $$(".weui-gallery__del").on("click", function () {
        //     console.log(liIndex);
        //     self.imagesData.serverId.splice(liIndex, 1);
        //     $$uploaderFiles
        //       .find("li")
        //       .eq(liIndex)
        //       .remove();
        //     console.log(self.imagesData.serverId);
        //   });

        } else {
          var uploadCount = 0;
          var tmplIos =
            '<li class="weui-uploader__file" style="background-image:url(#url#)"></li>',
            tmplAndroid =
            '<li class="weui-uploader__file"><img width="79" height="79" src="#url#"/></li>',
            $$gallery = $$("#gallery"),
            $$galleryImg = $$("#galleryImg"),
            $$uploaderInput = $$("#expenseUploaderInput"),
            $$uploaderFiles = $$("#expenseUploaderFiles");

          wx.ready(function () {
            $$uploaderInput.on("click", function (e) {
              console.log("upload");
              wx.chooseImage({
                count: 1,
                sizeType: ["original"],
                sourceType: ["album", "camera"],
                success: function (res) {
                  console.log(res);
                  // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                  // localId 可以用于微信手机版图片显示（目前PC版不可用）
                  var localIds = res.localIds;
                  for (var i = 0; i < localIds.length; i++) {
                    var localId = localIds[i];
                    if (page.app.device.ios) {
                      wx.getLocalImgData({
                        localId: localId, // 图片的localID
                        success: function (getLocalImgData_res) {
                          console.log(getLocalImgData_res);
                          $$uploaderFiles.append(
                            $$(
                              tmplIos.replace(
                                "#url#",
                                getLocalImgData_res.localData
                              )
                            )
                          );
                          wx.uploadImage({
                            localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (uploadImage_res) {
                              self.imagesData.serverId.push(
                                uploadImage_res.serverId
                              ); // 返回图片的服务器端ID
                            }
                          });
                        }
                      });
                    } else if (page.app.device.android) {
                      console.log("android");
                      var formatLocalId = localId;
                      formatLocalId = localId.substring(6, localId.length);
                      $$uploaderFiles.append(
                        $$(tmplIos.replace("#url#", localId))
                      );
                      wx.uploadImage({
                        localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: function (uploadImage_res) {
                          console.log(uploadImage_res);
                          self.imagesData.serverId.push(uploadImage_res.serverId); // 返回图片的服务器端ID

                          // wx.downloadImage({
                          //   serverId: uploadImage_res.serverId, // 需要下载的图片的服务器端ID，由uploadImage接口获得
                          //   isShowProgressTips: 1, // 默认为1，显示进度提示
                          //   success: function(res) {
                          //     console.log(res);
                          //     $$uploaderFiles.append(
                          //       $$(
                          //         tmplAndroid.replace(
                          //           "#url#",
                          //           "file:///storage/emulated/0/DCIM/WeixinWork/temp/camera/1549958773937.jpg"
                          //         )
                          //       )
                          //     );
                          //     // var localId = res.localId; // 返回图片下载后的本地ID
                          //   }
                          // });
                        }
                      });
                    }
                  }
                }
              });
            });
          });

          var liIndex;
          $$uploaderFiles.on("click", "li", function () {
            liIndex = $$(this).index();
            console.log(liIndex);
            // $$galleryImg.css("background-image", $$(this).find("img").attr("src"));
            $$galleryImg.attr("style", $$(this).attr("style"));
            $$gallery.show(100);
          });
          $$gallery.on("click", function () {
            $$gallery.hide(100);
          });
          $$(".weui-gallery__del").on("click", function () {
            console.log(liIndex);
            self.imagesData.serverId.splice(liIndex, 1);
            $$uploaderFiles
              .find("li")
              .eq(liIndex)
              .remove();
            console.log(self.imagesData.serverId);
          });

        }

        $$("#expense-requisition-item").on("change", function (e) {
          console.log(e);
          var index = this.selectedIndex;
          var isExpenseLineDistrubution = this.options[index].getAttribute(
            "is-expense-line-distribution"
          );
          if (isExpenseLineDistrubution === "true") {
            $$('.page[data-name="expense-form"] .fab-expense-form').show();
          } else {
            $$('.page[data-name="expense-form"] .fab-expense-form').hide();
            $$(".expense-line-distribution-form").remove();
          }
        });

        /*
        expense form 保存event
        */
        $$(".expense-save").on("click", function (e) {
          $$(".expense-save").addClass("disabled");
          setTimeout(function () {
            $$(".expense-save").removeClass("disabled");
          }, 1000);
          var distributionFormSumAmount;
          if ("form_data" in page.app.data.expense_data) {
            var expenseFormData = app.form.convertToData("#expense-form");
            expenseFormData.expense_requisition_item_id = parseInt(
              expenseFormData.expense_requisition_item_id
            );
            expenseFormData.price = parseFloat(expenseFormData.price);
            expenseFormData.quantity = parseInt(expenseFormData.quantity);
            expenseFormData.amount = parseFloat(expenseFormData.amount);
            if (page.app.data.session.source == 'dingding') {
              expenseFormData.dingding_code = page.app.data.dingding_code
            } else if (page.app.data.session.source == 'feishu') {
              expenseFormData.feishu_userid = page.app.data.feishu_userid
            } else {
              expenseFormData.wechat_name = page.app.data.session.userid;
            }
            expenseFormData.expense_line_distribution_ids = [];
            expenseFormData.expense_line_id = page.app.data.expense_data.form_data.expense_line_id;
            //附件暂不做编辑功能
            expenseFormData.attachment_ids = self.imagesData.serverId;
            if ($$(".expense-line-distribution-form").length > 0) {
              distributionFormSumAmount = 0;
            }
            $$(".expense-line-distribution-form").each(function (index, value) {
              var lineObj = app.form.convertToData($$(this));
              //旧分配行赋旧id
              if (page.app.data.expense_data.form_data.expense_line_distribution_ids.length > index) {
                lineObj.expense_line_distribution_id = page.app.data.expense_data.form_data.expense_line_distribution_ids[index].expense_line_distribution_id;
              } else {
                lineObj.expense_line_id = page.app.data.expense_data.form_data.expense_line_id;
              }
              distributionFormSumAmount = distributionFormSumAmount + parseFloat(lineObj.amount);
              expenseFormData.expense_line_distribution_ids.push(lineObj);
            });

          } else {
            var expenseFormData = app.form.convertToData("#expense-form");
            expenseFormData.expense_requisition_item_id = parseInt(
              expenseFormData.expense_requisition_item_id
            );
            expenseFormData.price = parseFloat(expenseFormData.price);
            expenseFormData.quantity = parseInt(expenseFormData.quantity);
            expenseFormData.amount = parseFloat(expenseFormData.amount);
            if (page.app.data.session.source == 'dingding') {
              expenseFormData.dingding_code = page.app.data.dingding_code
            } else if (page.app.data.session.source == 'feishu') {
              expenseFormData.feishu_userid = page.app.data.feishu_userid
            } else {
              expenseFormData.wechat_name = page.app.data.session.userid;
            }
            expenseFormData.expense_line_distribution_ids = [];
            expenseFormData.expense_line_id = false;

            expenseFormData.attachment_ids = self.imagesData.serverId;

            var $$distributionForm = $$(".expense-line-distribution-form");
            var distributionFormLen = $$distributionForm.length;
            if (distributionFormLen > 0) {
              distributionFormSumAmount = 0;
              for (var i = 0; i < distributionFormLen; i++) {
                var distributionFormData = app.form.convertToData($$distributionForm[i]);
                distributionFormData.expense_item_id = parseInt(
                  distributionFormData.expense_item_id
                );
                distributionFormData.quantity = parseInt(distributionFormData.quantity);
                distributionFormData.price = parseFloat(distributionFormData.price);
                distributionFormData.amount = parseFloat(distributionFormData.amount);
                distributionFormSumAmount = distributionFormSumAmount + distributionFormData.amount;
                expenseFormData.expense_line_distribution_ids.push(distributionFormData);
              }
            }
          }
          // var isDoSave = distributionFormSumAmount ? true : false;

          function isDoSave(expenseFormAmount, distributionFormSumAmount) {
            if (typeof (distributionFormSumAmount) == "undefined") {
              return true;
            } else if (typeof (distributionFormSumAmount) != "undefined") {
              if (distributionFormSumAmount == expenseFormAmount) {
                return true;
              } else {
                return false;
              }
            }
          };

          if (!isDoSave(expenseFormData.amount, distributionFormSumAmount)) {
            page.app.dialog.alert("分配行金额合计不等于汇总金额！", "KTHRP");
          }
          //校验费用项 
          else if (!expenseFormData.expense_requisition_item_id) {
            page.app.dialog.alert("请选择费用项！", "KTHRP");
          } else {
            console.log(expenseFormData);
            var createAndWriteExpenseLineParams = self.getCreateAndWriteExpenseLineParams(expenseFormData);
            app.request.postJSON(
              page.app.data.url, createAndWriteExpenseLineParams,
              function (expense_result) {
                console.log(expense_result);
                if (!("error" in expense_result)) {
                  // page.router.back();
                  var toast = app.toast.create({
                    text: "恭喜您,操作成功",
                    position: "top",
                    closeTimeout: 2000
                  });
                  toast.open();
                  page.router.navigate("/expense/", {
                    // reloadCurrent: true,
                    // ignoreCache: true
                  });
                } else if (expense_result.error.data.message.indexOf("20104") > -1) {
                  var message = "启用费用分配的费用项目所在费用行必须填写分配数据！";
                  page.app.dialog.alert(message, "KTHRP");
                }
                // else if (expense_result.error.data.message.indexOf("20102") > 0) {
                //   var message = "分配行金额合计不等于汇总金额！";
                //   page.app.dialog.alert(message, "KTHRP");
                //   // var toast = app.toast.create({
                //   //   text: "很遗憾,操作失败",
                //   //   position: "top",
                //   //   closeTimeout: 2000
                //   // });
                //   // toast.open();
                //   // page.router.navigate("/expense/", {
                //   //   // reloadCurrent: true,
                //   //   // ignoreCache: true
                //   // });
                // } 
                else if (expense_result.error.data.message.indexOf("金额大于0") > -1) {
                  var message = "金额必须大于0！";
                  page.app.dialog.alert(message, "KTHRP");
                } else if (expense_result.error.data.message.indexOf("开始日期应该小于等于结束日期") > -1) {
                  var message = "开始日期应该小于等于结束日期！";
                  page.app.dialog.alert(message, "KTHRP");
                } else {
                  var message = "系统异常，请重新保存或联系管理员";
                  page.app.dialog.alert(message, "KTHRP");
                }
              }
            );
          }
        });
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
        //初始化置空 元数据中的form_data
        if ("form_data" in page.app.data.expense_data) {
          delete page.app.data.expense_data["form_data"];
        }
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};