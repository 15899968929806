import $$ from 'dom7'
import loanForm from '../pages/loanForm.html'
import Template7 from 'template7'
import 'weui'
import weui from 'weui.js'
import * as dd from 'dingtalk-jsapi'

export default {
  name: 'loanForm',
  path: '/loanForm/',
  component: {
    template: loanForm,
    data: () => {
      return {
        isFabBtn: false
      }
    },
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.expense.borrowing.requisition.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      getCurrentDate: function () {
        var dd = new Date();
        var year = dd.getFullYear();
        var month = dd.getMonth() + 1;
        var day = dd.getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        return year + "-" + month + "-" + day;
      },
      //下载文件
      downloadFile(e) {
        window.open(e.currentTarget.dataset.url);
      },
      //文件上传后的处理函数
      filechange() {
        Array.from(this.$$("#file-attach")[0].files).forEach((ele) => {
          if (ele.type.indexOf("image") > -1) {
            this.fileList.images.push(ele);
          } else {
            this.fileList.files.push(ele);
          }
        });
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files,
        });
        //处理图片上传后事件冲突的问题
        $(".pb-standalone-dark").off("click");
        console.log(app.data);
        $(".pb-standalone-dark").on("click", function (event) {
          app.data.asset_data.myPhotoBrowserDark.open(
            Number(event.currentTarget.dataset.index)
          );
        });
        $(".addimg").off("click");
        $(".addimg").on("click", function (event) {
          $("#file-attach").trigger("click");
        });
        app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images;
        // 读取文件的函数
        function readAndPreview(file) {
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              file.url = this.result;
              file.caption = file.name;
            },
            false
          );
          if (!file.url) reader.readAsDataURL(file);
        }
        [].forEach.call(this.fileList.images, readAndPreview);
        setTimeout(() => {
          this.fileList.images.forEach((ele, index) => {
            $("img")[index].src = ele.url;
          });
        }, 100);
        console.log(this.fileList);
      },
      // 删除上传的文件
      delFile(val,attachment_id) {
        let e = window.event;
        e.stopPropagation();
        // if (!this.delete_attachment_ids) this.delete_attachment_ids = [];
        if (e.currentTarget.dataset.id)
          this.delete_attachment_ids.push(Number(e.currentTarget.dataset.id));
        if (val == "img") {
          // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            images: this.fileList.images,
          });

          //处理图片删除后事件冲突的问题
          $(".addimg").off("click");
          $(".addimg").on("click", function (event) {
            $("#file-attach").trigger("click");
          });

          app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images;
          this.fileList.images.forEach((ele, index) => {
            ele.caption = ele.name;
            $("img")[index].src = ele.url;
          });
        } else {
          // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            files: this.fileList.files,
          });
        }
      },
            // 上传文件到服务器的函数
    submitFiles(val){
        let pro = new Promise((resolve,reject) => {
        var formData = new FormData()
        let post_url = this.$app.data.base_url + '/web/map_upload_attachment'
        formData.append('record_id', val.record_id)
        formData.append('model_name', val.record_model)
        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        for (var i = 0; i < this.fileList.images.length; i++) {
          var file = this.fileList.images[i];
          if(!file.attachment_id)formData.append("file", file)
        }
        for (var i = 0; i < this.fileList.files.length; i++) {
          var file = this.fileList.files[i];
          if(!file.attachment_id)formData.append("file", file)
        }
        if (this.$app.data.session.source === 'dingding') {
          formData.append('dingding_code', this.$app.data.dingding_code)
        }else if (this.$app.data.session.source === 'feishu') {
          formData.append('feishu_userid', this.$app.data.feishu_userid)
        }else{
          formData.append('wechat_name', this.$app.data.session.userid)
        }
        this.$app.request.post(post_url, formData,(result) => {
          if (JSON.parse(result).state === 'ok') {
            var toast = this.$app.toast.create({
              text: '恭喜您,操作成功',
              position: 'center',
              closeTimeout: 2000
            })
            toast.open()
            // self.$app.router.navigate('/workOrderCompleted/', {})
          } else {
            var toast = this.$app.toast.create({
              text: '很遗憾,操作失败',
              position: 'center',
              closeTimeout: 2000
            })
            toast.open()
          }
          resolve()
        })
      })
      return pro
      },
      // toAssetListView(){
      //   var self = this
      //   const {
      //     $$
      //   } = this
      //   var page = $$('.page[data-name="editForm"]')[0].f7Page
      //   page.router.navigate("/assetListView/", {
      //     reloadCurrent: false,
      //     ignoreCache: true,
      //     context: {
      //       assetListViewData: self.asset
      //     }
      //   });
      // },
      date_change(e){
        let val=e||window.event;
        if(!val)return
        if(val.currentTarget&&val.currentTarget.id.indexOf('date')>-1){
          let end = $("input[name='end_date']").val()
          let start = $("input[name='start_date']").val()
          if(start){
            if(end && new Date(start.replace(/-/g, "/")) - new Date(end.replace(/-/g, "/")) > 0){
              app.dialog.alert("结束时间必须大于开始时间！", "系统提示");
              $("input[name='end_date']").val('')
            }
          }else{
            app.dialog.alert("请选择开始时间！", "系统提示");
          }
        }
      },
      save() {
        var self =this
        const {
          $$
        } = this
        var page = $$('.page[data-name="loan-form"]')[0].f7Page
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        let formData = app.form.convertToData("#editForm");
        
        if (formData.loan_doc_type == "") {
          app.dialog.alert("请选择单据类型！", "系统提示");
          return;
        }else if(formData.loan_type == ''){
          app.dialog.alert("请选择借款类型！", "系统提示");
          return;
        }else if(formData.start_date == ''){
          app.dialog.alert("请选择开始日期！", "系统提示");
          return;
        }else if(formData.end_date == ''){
          app.dialog.alert("请选择结束日期！", "系统提示");
          return;
        }else if(formData.loan_amount == ''){
          app.dialog.alert("请输入借款金额", "系统提示");
          return;
        }

        if (app.data.session.source == "dingding") {
          //保存的参数
          var saveParams = this.getParams("create_borrowing_requisition", {
            dingding_code: dingding_code,
            document_type_id: formData.loan_doc_type,
            borrow_type_id: formData.loan_type,
            apply_date:self.currentDate,
            start_date: formData.start_date,
            end_date: formData.end_date,
            borrowing_amount: formData.loan_amount,
            bank_account_id: formData.bank_account,
            note:formData.loan_note
          });
        } else if (app.data.session.source == "feishu") {
          //保存的参数
          var saveParams = this.getParams("create_borrowing_requisition", {
            feishu_userid: feishu_userid,
            document_type_id: formData.loan_doc_type,
            borrow_type_id: formData.loan_type,
            apply_date:self.currentDate,
            start_date: formData.start_date,
            end_date: formData.end_date,
            borrowing_amount: formData.loan_amount,
            bank_account_id: formData.bank_account,
            note:formData.loan_note
          });
        } else {
          var saveParams = this.getParams("create_borrowing_requisition", {
            wechat_name: vuserid,
            document_type_id: formData.loan_doc_type,
            borrow_type_id: formData.loan_type,
            apply_date:self.currentDate,
            start_date: formData.start_date,
            end_date: formData.end_date,
            borrowing_amount: formData.loan_amount,
            bank_account_id: formData.bank_account,
            note:formData.loan_note
          });
        }

        console.log('提交保存',saveParams,self.fileList);
        app.preloader.show();
        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result.state == 'ok') {
            console.log(result.result, "保存工作对象成功");
            console.log('456456',self.fileList)
            if(self.fileList && (!self.fileList.images.every(ele=>ele.attachment_id) || !self.fileList.files.every(ele=>ele.attachment_id))){
              console.log('123123')
              self.submitFiles({record_id: result.result.data.record_id,record_model:result.result.data.model_name}).then(()=>{
                app.preloader.hide();
                app.router.back('/loanIndex/',{
                  force: true
                }) 

              })
            }else{
              app.preloader.hide();
              app.router.back('/loanIndex/',{
                force: true
              }) 
            }
          } else if(result.result.state == 'warn'){
            app.preloader.hide();
            app.dialog.alert(result.result.act_err, "系统提示");
          } else{
            app.preloader.hide();
            app.dialog.alert('获取信息错误！', "系统提示");
          }
        });
      },

      //文件上传后的处理函数
      filechange(){ 
        Array.from(this.$$('#file-attach')[0].files).forEach(ele => {
          if(ele.type.indexOf('image')>-1){
            this.fileList.images.push(ele)
          }else{
            this.fileList.files.push(ele)
          }
        })
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files
        })
        //处理图片上传后事件冲突的问题
        $('.pb-standalone-dark').off('click')
       $('.pb-standalone-dark').on('click', function (event) {
        app.data.asset_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        $('.addimg').off('click')
        $('.addimg').on('click',function (event) { 
          $('#file-attach').trigger('click')
       })
       app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images
        // 读取文件的函数
        let self = this
        function readAndPreview(file,index){
          let reader = new FileReader();
          reader.addEventListener("load", function () {
              file.url = this.result
              file.caption = file.name  
              self.fileList.images.forEach((ele) => {
                $('img')[index].src=this.result
              })       
          }, false);
          if(!file.url)reader.readAsDataURL(file)    
        }
        [].forEach.call(this.fileList.images, readAndPreview)
        // setTimeout(()=>{
        //   this.fileList.images.forEach((ele,index) => {
        //     $('img')[index].src=ele.url
        //   }) 
        // },200)
        console.log(this.fileList)
      },
      // 删除上传的文件
      delFile(val){
        let e = window.event
        e.stopPropagation()
        if(val == 'img'){ // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index),1)
          this.$setState({
            images: this.fileList.images
          })

          //处理图片删除后事件冲突的问题
          $('.addimg').off('click')
          $('.addimg').on('click',function (event) { 
            $('#file-attach').trigger('click')
         })


         app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images
          this.fileList.images.forEach((ele,index) => {
            ele.caption = ele.name
            $('img')[index].src=ele.url
        })
        }else{  // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index),1)
          this.$setState({
            files: this.fileList.files
          })
        }    
      },
    },
    on: {
      pageInit: function (e, page) {

      this.$setState({
        images: [],
        files: [],
        fileList:{
          images: [],
          files: [],
        }
      })
      // 照片预览器
      page.app.data.asset_data.myPhotoBrowserDark = app.photoBrowser.create({
        photos : this.fileList.images,
        theme: 'dark'
      });
      $('.pb-standalone-dark').on('click', function (event) {
        page.app.data.asset_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
      });

      const getCurrentDate = this.getCurrentDate()
      this.$setState({
        document_type_list:this.info.document_type_list,
        applicant_id_name:this.info.applicant_id.name,
        borrow_type_list:this.info.borrow_type_list,
        bank_info_list:this.info.bank_info_list,
          fileList:{
              images:[],
              files:[]
          },
          currentDate:getCurrentDate
      })
      const defaultBankItem =  this.info.bank_info_list.filter(function(e){
        return e.default == 'True'
      });
      if(defaultBankItem.length){
        $("#bank_account_id").val(defaultBankItem[0].bank_account_id)
      }
      // 触发上传文件的事件
      $('.addimg').on('click',function (event) { 
          console.log('触发上传文件的事件')
          $('#file-attach').trigger('click')
      })

      //注册时间选择控件
      let start_date = new lCalendar();
      start_date.init({
        trigger: "#start_date",
        type: "date",// date 调出日期选择 datetime 调出日期时间选择 time 调出时间选择 ym 调出年月选择,
      });
      let end_date = new lCalendar();
      end_date.init({
        trigger: "#end_date",
        type: "date",
      });
      $("input[type='text']").bind('input propertychange',this.date_change)
    },
      pageMounted: function (e, page) {
        console.log('pageMounted')
      },
      pageReinit: function (e, page) {
        console.log('pageReinit')
      },
      pageBeforeIn: function (e, page) {
        console.log('pageBeforeIn')
      },
      pageAfterIn: function (e, page) {
        console.log('pageAfterIn')
        page.app.smartSelect.get('.smart-select').on('closed', function () {
          var asset_id = page.app.smartSelect.get('.smart-select').selectEl.value;
          var assetList = page.app.data.asset_data.asset
          if (!assetList) {
            return
          }
          for (var i = 0; i < assetList.length; i++) {
            if (asset_id == assetList[i].id) {
              $$('.place').html(assetList[i].location_id);
            }
          }
        });
      },
      pageBeforeInOut: function (e, page) {
        console.log('pageBeforeInOut')
      },
      pageAfterOut: function (e, page) {
        console.log('pageAfterOut')
      },
      pageBeforeRemove: function (e, page) {
        console.log('pageBeforeRemove')
      }
    }
  }
}