// import $ from "dom7";
import salaryQueryFormView from "./salaryQueryFormView";
import salaryQueryIndex from './index'
import preIndexHtml from "../pages/preIndex.html";


export default [{
    name: "salaryQueryPreindex",
    path: "/salaryQueryPreindex/",
    component: {
      template: preIndexHtml,
      data: () => {
        return {};
      },
      methods: {
        getUserObj: function () {
          if (this.$app.data.session.source == 'dingding') {
            return {
              dingding_code: this.$app.data.dingding_code
            }
          } else if (this.$app.data.session.source == 'feishu') {
            return {
              feishu_userid: this.$app.data.feishu_userid
            }
          } else {
            return {
              wechat_name: this.$app.data.session.userid
            }
          }
        },

        monthChange: function(){
          const preindexFormData = this.$app.form.convertToData('#salaryQuery-preindex');
          if((!preindexFormData.year || preindexFormData.year == '不限') && (preindexFormData.startMonth != '不限' || preindexFormData.endMonth != '不限')){
            app.dialog.alert('仅当薪资年度有值时，可以选择开始月份和结束月份', "系统提示");
          }
        },
        next: function () {
          let self = this
          let preindexFormData = this.$app.form.convertToData('#salaryQuery-preindex');

          if((!preindexFormData.year || preindexFormData.year == '不限') && (preindexFormData.startMonth != '不限' || preindexFormData.endMonth != '不限')){
            app.dialog.alert('仅当薪资年度有值时，可以选择开始月份和结束月份', "系统提示");
            return false;
          }

          let obj = this.getUserObj()
          obj.year_code = preindexFormData.year == '不限' ? '' : preindexFormData.year
          obj.start_month_code = preindexFormData.startMonth == '不限' ? '' : preindexFormData.startMonth.length == 1 ? '0' + preindexFormData.startMonth : preindexFormData.endMonth
          obj.end_month_code = preindexFormData.endMonth == '不限' ? '' : preindexFormData.endMonth.length == 1 ? '0' + preindexFormData.endMonth : preindexFormData.endMonth
          obj.classification_code = preindexFormData.classification == '不限' ? '' : preindexFormData.classification
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.payroll.salary.slip.month.interface', 'get_kanban_salary_slip_month', obj), function (res) {
            console.log(res);
            self.$app.router.navigate("/salaryQueryIndex/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                indexList:res.result.all_salary_slip_month,
                indexListCount:res.result.all_salary_slip_month.length
              }
            });
          });
        }
      },
      on: {
        pageInit: function (e, page) {
          $('.shuiyinBox').remove()
          let self = this
          const {
            $$
          } = this

          this.$app.preloader.show();
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.payroll.salary.slip.month.interface', 'get_filter_param_list', this.getUserObj()), function (res) {
            console.log(res)
            if(res.result){
              const monthList = [1,2,3,4,5,6,7,8,9,10,11,12]
              self.$setState({
                monthList,
                all_year_code: res.result.all_year_code,
                all_classification_value: res.result.all_classification_value,
              })
              const date = new Date();
              const currentMonth = date.getMonth() + 1
              const currentYear = date.getFullYear()
    
              page.app.form.fillFromData('#salaryQuery-preindex', {
                // startMonth: currentMonth,
                year: currentYear
    
              });
              self.$app.preloader.hide();
            }
            self.$app.preloader.hide();
          })


        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  salaryQueryIndex,
  salaryQueryFormView
];