import $ from "dom7";
import receptionAppForm from "./receptionAppForm";
import receptionAppViewForm from "./receptionAppViewForm";
import receptionAppFormLineDetail from "./receptionAppFormLineDetail";
import html from "../pages/index.html";
import style from "../styles/index.less";
export default [{
    name: "/receptionapplist/",
    path: "/receptionapplist/",
    // url: "./expense/pages/index.html"
    component: {
      template: html,
      style: style,
      methods: {
        getUserObj: function () {
          if (this.$app.data.session.source == 'dingding') {
            return {
              dingding_code: this.$app.data.dingding_code
            }
          } else if (this.$app.data.session.source == 'feishu') {
            return {
              feishu_userid: this.$app.data.feishu_userid
            }
          } else {
            return {
              wechat_name: this.$app.data.session.userid
            }
          }
        },
        toDetail: function (e) {
          let self = this;
          let id = parseInt(e.currentTarget.dataset.id);
          let obj = this.getUserObj()
          obj.reception_requisition_id = parseInt(id)
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.reception.application.interface', 'get_reception_requisition_details', obj), function (res) {
            console.log(res);
            self.$app.router.navigate("/receptionappviewform/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                attachments: true,
                info: res.result.data
              }
            });
          });
        },
        toForm: function (e) {
          var self = this
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.reception.application.interface', 'get_place', this.getUserObj()), function (res) {
            console.log(res);
            self.$app.router.navigate("/receptionappform/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                info: res.result.data
              }
            });
          });
        }
      },
      on: {
        pageInit: function (e, page) {
          let self = this
          const {
            $$
          } = this
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.reception.application.interface', 'get_user_reception_requisition', this.getUserObj()), function (res) {
            console.log(res)
            self.$setState({
              all_reception_requisition: res.result.all_reception_requisition,
              all_reception_requisition_count: res.result.all_reception_requisition.length,
              processing_reception_requisition: res.result.processing_reception_requisition,
              processing_reception_requisition_count: res.result.processing_reception_requisition.length,
            });
            //未处理 搜索框实例
            var sbtodo = page.app.searchbar.create({
              el: '[data-name="receptionapp-index"] .searchbar-to-do',
              searchContainer: '[data-name="receptionapp-index"] .list-to-do',
              searchIn: '[data-name="receptionapp-index"] .item-text-to-do',
              notFoudEl: '[data-name="receptionapp-index"] .searchbar-not-found-to-do',
            });
            var sbdone = page.app.searchbar.create({
              el: ".searchbar-done",
              searchContainer: ".list-done",
              searchIn: ".item-text-done",
              notFoudEl: ".searchbar-not-found-done",
            });
            $('[data-name="receptionapp-index"] .searchbar-done').hide();
            $('[data-name="receptionapp-index"] .search-icon-2').hide();

            $('[data-name="receptionapp-index"] .to-do-btn').on("click", function () {
              sbdone.disable();
              $('[data-name="receptionapp-index"] .searchbar-done,[data-name="receptionapp-index"] .search-icon-2').hide();
              $('[data-name="receptionapp-index"] .searchbar-to-do,[data-name="receptionapp-index"] .search-icon-1').show();
            });
            $('[data-name="receptionapp-index"] .done-btn').on("click", function () {
              sbtodo.disable();
              $('[data-name="receptionapp-index"] .searchbar-done,[data-name="receptionapp-index"] .search-icon-2').show();
              $('[data-name="receptionapp-index"] .searchbar-to-do,[data-name="receptionapp-index"] .search-icon-1').hide();
            });
          })
        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  receptionAppForm,
  receptionAppViewForm,
  receptionAppFormLineDetail
];