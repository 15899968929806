import $ from "dom7";
import _ from "lodash";
import style from "../styles/home.less";
import Template7 from "template7";

import purchaseRequisitionLineDetailHtml from "../pages/purchaseRequisition/purchaseRequisitionLineDetail.html";
import contractPartyLineDetailHtml from "../pages/contract/contractPartyLineDetail.html";
import contractObjectLineDetailHtml from "../pages/contract/contractObjectLineDetail.html";
import purchaseOrdersLineDetailHtml from "../pages/purchaseOrders/purchaseOrdersLineDetail.html";
import paymentinvoicelinedetailHtml from "../pages/paymentRequisition/paymentInvoiceLineDetail.html";
import prePaymentLineDetailHtml from "../pages/paymentRequisition/prePaymentLineDetail.html";
import supplierInvoiceLineDetailHtml from "../pages/supplierInvoice/supplierInvoiceLineDetail.html";
import expenseRequisitionLineDetailHtml from "../pages/expenseRequisition/expenseRequisitionLineDetail.html";
import expenseRequisitionDetailedPersonnelDetailHtml from "../pages/expenseRequisition/expenseRequisitionDetailedPersonnelDetail.html";
import expenseReimbursementLineDetailHtml from "../pages/expenseReimbursement/expenseReimbursementLineDetail.html";
import expenseReimbursementOffsetLineDetailHtml from "../pages/expenseReimbursement/expenseReimbursementOffsetLineDetail.html";
import supplierChangeRecordsLineDetailHtml from "../pages/supplierRequisition/supplierChangeRecordsLineDetail.html";
import assetLineDetailHtml from "../pages/assetacceptance/assetLineDetail.html";
import assetSundryReqLineDetailHtml from "../pages/assetSundryReq/assetSundryReqLineDetail.html";
import customerChangeRecordsLineDetailHtml from "../pages/customerRequisition/customerChangeRecordsLineDetail.html";
import partnerChangeRecordsLineDetailHtml from "../pages/partnerRequisition/partnerChangeRecordsLineDetail.html";
import requisitionPlatformLineDetailHtml from "../pages/requisitionPlatform/requisitionPlatformLineDetail.html";
import tongYongLineDetailHtml from "../pages/tongYong/tongYongLineDetail.html";


export default [
  //通用单据行
  {
    name: "tongyongline",
    path: "/tongyongline/",
    component: {
      template: tongYongLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //统一申请-申请行明细
  {
    name: "rplatformlinedetail",
    path: "/rplatformlinedetail/",
    component: {
      template: requisitionPlatformLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //资产申请-资产明细行
  {
    name: "asrlinedetail",
    path: "/asrlinedetail/",
    component: {
      template: assetSundryReqLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //资产验收-资产明细行
  {
    name: "aalinedetail",
    path: "/aalinedetail/",
    component: {
      template: assetLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //采购申请行详情
  {
    name: "prlinedetail",
    path: "/prlinedetail/",
    component: {
      template: purchaseRequisitionLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //采购订单行详情
  {
    name: "polinedetail",
    path: "/polinedetail/",
    component: {
      template: purchaseOrdersLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //合同交易方行详情
  {
    name: "ccpartylinedetail",
    path: "/ccpartylinedetail/",
    component: {
      template: contractPartyLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //合同标的行详情
  {
    name: "ccobjectlinedetail",
    path: "/ccobjectlinedetail/",
    component: {
      template: contractObjectLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //预付款发票明细行详情
  {
    name: "paymentinvoicelinedetail",
    path: "/paymentinvoicelinedetail/",
    component: {
      template: paymentinvoicelinedetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //预付款核销行详情
  {
    name: "prepaymentlinedetail",
    path: "/prepaymentlinedetail/",
    component: {
      template: prePaymentLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //供应商发票明细行详情
  {
    name: "supplierinvoicelinedetail",
    path: "/supplierinvoicelinedetail/",
    component: {
      template: supplierInvoiceLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //费用申请明细行详情
  {
    name: "expenserequisitionlinedetail",
    path: "/expenserequisitionlinedetail/",
    component: {
      template: expenseRequisitionLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //费用明细人员行详情
  {
    name: "expenserequisitiondetailedpersonneldetail",
    path: "/expenserequisitiondetailedpersonneldetail/",
    component: {
      template: expenseRequisitionDetailedPersonnelDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //费用报销明细行详情
  {
    name: "expensereimbursementLinedetail",
    path: "/expensereimbursementLinedetail/",
    component: {
      template: expenseReimbursementLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //费用报销借款核销行详情
  {
    name: "expensereimbursementoffsetdetail",
    path: "/expensereimbursementoffsetdetail/",
    component: {
      template: expenseReimbursementOffsetLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //供应商变更记录行详情
  {
    name: "supplierchangerecordsLinedetail",
    path: "/supplierchangerecordsLinedetail/",
    component: {
      template: supplierChangeRecordsLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //客户变更记录行详情
  {
    name: "customerchangerecordslinedetail",
    path: "/customerchangerecordslinedetail/",
    component: {
      template: customerChangeRecordsLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  },
  //业务伙伴变更记录行详情
  {
    name: "partnerchangerecordslinedetail",
    path: "/partnerchangerecordslinedetail/",
    component: {
      template: partnerChangeRecordsLineDetailHtml,
      style: style,
      methods: {},
      on: {
        pageMounted: function (e, page) {
          //TODO
        },
        pageBeforeRemove: function (e, page) {
          // TODO
        },
        pageInit: function (e, page) {
          // TODO
        }
      }
    }
  }
];