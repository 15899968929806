import "../styles/main.less";

let homeView, catalogView, settingsView, expenseView;

export default {
  path: "/main/",
  componentUrl: "./main/pages/view-main.html",
  on: {
    pageMounted: function(e, page) {
      // console.log(page.name, 'page mounted');
      console.log(page.name, "createView...");
      $('.show-more-btns').on('click',() => {
        if($('.more-btns').css('display') == 'none'){
          $('.more-btns').show();
        }else{
          $('.more-btns').hide();
        }
        
      })
      if (!page.app.views.get("#view-home")) {
        homeView = page.app.views.create("#view-home", {
          url: "/home/",
          iosPageLoadDelay: 100,
          materialPageLoadDelay: 100
          // pushState:true,
          // pushStateSeparator:"",
          // pushStateRoot:"http://192.168.99.100:4000/?code=122254534543&userid=LiMing",
        });
      }
      // if (!page.app.views.get("#view-expense")) {
      //   homeView = page.app.views.create("#view-expense", {
      //     url: "/expense/",
      //     iosPageLoadDelay: 100,
      //     materialPageLoadDelay: 100
      //     // pushState:true,
      //     // pushStateSeparator:"",
      //     // pushStateRoot:"http://192.168.99.100:4000/?code=122254534543&userid=LiMing",
      //   });
      // }
      // if (!page.app.views.get('#view-catalog')) {
      //   catalogView = page.app.views.create('#view-catalog', {
      //     url: '/catalog/'
      //   });
      // }
      // if (!page.app.views.get('#view-user')) {
      //   settingsView = page.app.views.create('#view-user', {
      //     url: '/user/'
      //   });
      // }
      // if (!page.app.views.get('#view-expense')) {
      //   expenseView = page.app.views.create('#view-expense', {
      //     url: '/expense/'
      //   });
      // }
    },
    pageInit: function(e, page) {
      // console.log(page.name, 'page init');
    },
    pageBeforeIn: function(e, page) {
      // console.log(page.name, 'page before in');
    },
    pageAfterIn: function(e, page) {
      // console.log(page.name, 'page after in');
      page.app.preloader.hide();
    },
    pageBeforeOut: function(e, page) {
      // console.log(page.name, 'page before out');
    },
    pageAfterOut: function(e, page) {
      // console.log(page.name, 'page after out');
    },
    pageBeforeRemove: function(e, page) {
      // console.log(page.name, 'page before remove');
    }
  }
};
