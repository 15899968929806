import $$ from 'dom7'
import score from '../pages/score.html'
import 'weui'

export default {
  name: 'score',
  path: '/score/',
  component: {
    template: score,
    data: () => {
      return {
        score:{
          satisfaction: 0,
          attitude: 0,
          technical_merit: 0,
          response_speed: 0
        }
      }
    },
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.asset.maintenance.repair.requisition.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      do_score(e){
        const index = e.currentTarget.dataset.index*1
        const field = e.currentTarget.dataset.field
        $(`.score-item[data-field='${field}']`).each((i,item) => {
          if(i <= index){
            $(item).addClass('selected')
          }else{
            $(item).removeClass('selected')
          }
        })
        this.score[field] = String(index + 1) 
      },
      save() {
        var self =this
        const {
          $$
        } = this
        var page = $$('.page[data-name="score-form"]')[0].f7Page
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        let formData = app.form.convertToData("#scoreForm");
        
        if (!this.score.satisfaction) {
          app.dialog.alert("请给总体满意度评分！", "系统提示");
          return;
        }else if(!this.score.technical_merit){
          app.dialog.alert("请给技术水平评分！", "系统提示");
          return;
        }else if(!this.score.response_speed){
          app.dialog.alert("请给响应速度评分！", "系统提示");
          return;
        }else if(!this.score.attitude){
          app.dialog.alert("请给服务态度评分！", "系统提示");
          return;
        }

        if (app.data.session.source == "dingding") {
          //保存的参数
          var saveParams = this.getParams("action_pass", {
            dingding_code: dingding_code,
            repair_requisition_id:this.repair_requisition_id,
            ...formData,
            ...this.score
          });
        } else if (app.data.session.source == "feishu") {
          //保存的参数
          var saveParams = this.getParams("action_pass", {
            feishu_userid: feishu_userid,
            repair_requisition_id:this.repair_requisition_id,
            ...formData,
            ...this.score
          });
        } else {
          var saveParams = this.getParams("action_pass", {
            wechat_name: vuserid,
            repair_requisition_id:this.repair_requisition_id,
            ...formData,
            ...this.score
          });
        }
        
        app.preloader.show();
        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result.state == 'ok') {
            app.preloader.hide();
            app.router.back('/asset/',{
              force: true
            })
          } else{
            app.preloader.hide();
            app.dialog.alert('操作失败！', "系统提示");
          }
        });
      },

    },
    on: {
      pageInit: function (e, page) {
        this.$setState({
          socreArr: [0,1,2,3,4]
        })
      },
      pageMounted: function (e, page) {
        console.log('pageMounted')
      },
      pageReinit: function (e, page) {
        console.log('pageReinit')
      },
      pageBeforeIn: function (e, page) {
        console.log('pageBeforeIn')
      },
      pageAfterIn: function (e, page) {
        console.log('pageAfterIn')

      },
      pageBeforeInOut: function (e, page) {
        console.log('pageBeforeInOut')
      },
      pageAfterOut: function (e, page) {
        console.log('pageAfterOut')
      },
      pageBeforeRemove: function (e, page) {
        console.log('pageBeforeRemove')
      }
    }
  }
}