import $$ from "dom7";
import addInspectionWorkInfo from "../pages/addInspectionWorkInfo.html";
import Template7 from "template7";

export default {
  name: "addInspectionWorkInfo",
  path: "/addInspectionWorkInfo/",
  component: {
    template: addInspectionWorkInfo,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.asset.maintenance.work.order.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      date_change(e) {
        // let val=e||window.event;

      },
      // 删除行记录
      //   del(){
      //     if(!isNaN(this.lineindex)){
      //       if(this.ismaintain){
      //         app.data.maintainDetailView_data.work_order_complete_info_line_ids.splice(this.lineindex,1)
      //       }else if(this.isInspection){

      //       }else{
      //         app.data.workOrder_data.work_order_complete_info_line_ids.splice(this.lineindex,1)
      //       }
      //     }
      //     app.router.back()
      // },
      haltChange(e) {
        let formData = app.form.convertToData("#editForm");
        if (formData.halt && formData.halt == "是") {
          $(".showHalt").show();
        } else {
          $(".showHalt").hide();
        }
      },
      //下载文件
      downloadFile(e) {
        window.open(e.currentTarget.dataset.url);
      },
      //文件上传后的处理函数
      filechange() {
        Array.from(this.$$("#file-attach")[0].files).forEach((ele) => {
          if (ele.type.indexOf("image") > -1) {
            this.fileList.images.push(ele);
          } else {
            this.fileList.files.push(ele);
          }
        });
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files,
        });
        //处理图片上传后事件冲突的问题
        $(".pb-standalone-dark").off("click");
        console.log(app.data);
        $(".pb-standalone-dark").on("click", function (event) {
          app.data.inspectionDetailView_data.myPhotoBrowserDark.open(
            Number(event.currentTarget.dataset.index)
          );
        });
        $(".addimg").off("click");
        $(".addimg").on("click", function (event) {
          $("#file-attach").trigger("click");
        });
        app.data.inspectionDetailView_data.myPhotoBrowserDark.params.photos = this.fileList.images;
        // 读取文件的函数
        function readAndPreview(file) {
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              file.url = this.result;
              file.caption = file.name;
            },
            false
          );
          if (!file.url) reader.readAsDataURL(file);
        }
        [].forEach.call(this.fileList.images, readAndPreview);
        setTimeout(() => {
          this.fileList.images.forEach((ele, index) => {
            $("img")[index].src = ele.url;
          });
        }, 100);
        console.log(this.fileList);
      },
      // 删除上传的文件
      delFile(val,attachment_id) {
        let e = window.event;
        e.stopPropagation();
        // if (!this.delete_attachment_ids) this.delete_attachment_ids = [];
        if (e.currentTarget.dataset.id)
          this.delete_attachment_ids.push(Number(e.currentTarget.dataset.id));
        if (val == "img") {
          // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            images: this.fileList.images,
          });

          //处理图片删除后事件冲突的问题
          $(".addimg").off("click");
          $(".addimg").on("click", function (event) {
            $("#file-attach").trigger("click");
          });

          app.data.inspectionDetailView_data.myPhotoBrowserDark.params.photos = this.fileList.images;
          this.fileList.images.forEach((ele, index) => {
            ele.caption = ele.name;
            $("img")[index].src = ele.url;
          });
        } else {
          // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            files: this.fileList.files,
          });
        }
      },
      save() {
        var self =this
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        let formData = app.form.convertToData("#editForm");
        console.log(formData);
        if (formData.actual_execute_date == "") {
          app.dialog.alert("请选择计划执行时间！", "系统提示");
          return;
        }else if(formData.ri_result == ''){
          app.dialog.alert("请选择巡检结果！", "系统提示");
          return;
        }
        let object_list_ids = {
          actual_execute_date: formData.actual_execute_date,
          result_note: formData.note,
          ri_result: formData.ri_result,
          object_id: this.info.object_id,
          delete_attachment_ids:this.delete_attachment_ids
        };
        if (app.data.session.source == "dingding") {
          //保存的参数
          var saveParams = this.getParams("write_object_info", {
            work_order_id: app.data.inspectionDetailView_data.work_order_id,
            dingding_code: dingding_code,
            object_list_ids: [object_list_ids],
          });
        } else if (app.data.session.source == "feishu") {
          //保存的参数
          var saveParams = this.getParams("write_object_info", {
            work_order_id: app.data.inspectionDetailView_data.work_order_id,
            feishu_userid: feishu_userid,
            object_list_ids: [object_list_ids],
          });
        } else {
          var saveParams = this.getParams("write_object_info", {
            work_order_id: app.data.inspectionDetailView_data.work_order_id,
            wechat_name: vuserid,
            object_list_ids: [object_list_ids],
          });
        }
        console.log('提交保存',saveParams,self.fileList);
        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result) {
            console.log(result.result, "保存工作对象成功");
            console.log('456456',self.fileList)
            if(self.fileList && (!self.fileList.images.every(ele=>ele.attachment_id) || !self.fileList.files.every(ele=>ele.attachment_id))){
              console.log('123123')
              self.submitFiles({record_id: self.info.object_id,record_model:self.info.model}).then(()=>{
                $$(".back").trigger("click");
              })
            }else{
              $$(".back").trigger("click");
            }
          } else {
            app.dialog.alert("获取信息错误！", "系统提示");
          }
        });



      },
      // 上传文件到服务器的函数
    submitFiles(val){
      let pro = new Promise((resolve,reject) => {
      var formData = new FormData()
      let post_url = this.$app.data.base_url + '/web/map_upload_attachment'
      formData.append('record_id', val.record_id)
      formData.append('model_name', val.record_model)
      formData.append('db', this.$app.data.dbname)
      formData.append('id', this.$app.data.userid)
      formData.append('pwd', this.$app.data.password)
      for (var i = 0; i < this.fileList.images.length; i++) {
        var file = this.fileList.images[i];
        if(!file.attachment_id)formData.append("file", file)
      }
      for (var i = 0; i < this.fileList.files.length; i++) {
        var file = this.fileList.files[i];
        if(!file.attachment_id)formData.append("file", file)
      }
      if (this.$app.data.session.source === 'dingding') {
        formData.append('dingding_code', this.$app.data.dingding_code)
      }else if (this.$app.data.session.source === 'feishu') {
        formData.append('feishu_userid', this.$app.data.feishu_userid)
      }else{
        formData.append('wechat_name', this.$app.data.session.userid)
      }
      this.$app.request.post(post_url, formData,(result) => {
        if (JSON.parse(result).state === 'ok') {
          var toast = this.$app.toast.create({
            text: '恭喜您,操作成功',
            position: 'center',
            closeTimeout: 2000
          })
          toast.open()
          // self.$app.router.navigate('/workOrderCompleted/', {})
        } else {
          var toast = this.$app.toast.create({
            text: '很遗憾,操作失败',
            position: 'center',
            closeTimeout: 2000
          })
          toast.open()
        }
        resolve()
      })
    })
    return pro
    },
    },
    on: {
      pageInit: function (e, page) {
        this.$setState({
          info: app.data.inspectionDetailView_data.object_list_ids.filter(
            (e) => e.line_number == this.lineId
          )[0],
          ri_result_list: app.data.inspectionDetailView_data.ri_result_list,
        });
        // let formData = app.form.convertToData("#editForm");

        // if(this.info.ri_result){
        //   formData.result_note = this.info.ri_result
        // }
      
        let vuserid = page.app.data.session.userid;
        let dingding_code = page.app.data.dingding_code;
        let feishu_userid = page.app.data.feishu_userid;

        console.log("pageInit", app.data, this.info);

        this.fileList = this.info.attachment_ids
        this.$setState({
          delete_attachment_ids:[],
          work_order_complete_info_line_ids: this.info.work_order_complete_info_line_ids,
          fileList:this.info.attachment_ids,
          files: this.info.attachment_ids.files,
          images: this.info.attachment_ids.images
        })

          this.fileList.images.forEach((ele,index) => {
            ele.caption = ele.name
            $('img')[index].src=ele.url
        })
        // 照片预览器
        page.app.data.inspectionDetailView_data.myPhotoBrowserDark = app.photoBrowser.create({
          photos : this.fileList.images,
          theme: 'dark'
        });
        $('.pb-standalone-dark').on('click', function (event) {
          page.app.data.inspectionDetailView_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        // 触发上传文件的事件
        $('.addimg').on('click',function (event) { 
          console.log('触发上传文件的事件')
            $('#file-attach').trigger('click')
         })




        //注册时间选择控件
        let actual_execute_date = new lCalendar();
        actual_execute_date.init({
          trigger: "#actual_execute_date",
          type: "datetime",
        });

        $("input[type='text']").bind("input propertychange", this.date_change);
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log(this.info)
        if(this.info.ri_result){
          page.app.form.fillFromData('#editForm',{
            ri_result: this.info.ri_result
          })
        }
        if(this.info.actual_execute_date){
          page.app.form.fillFromData('#editForm',{
            actual_execute_date: this.info.actual_execute_date
          })
        }


        // if (this.isEdit) {
        //   let item = this.isEdit;

        // } else {

        // }
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      },
    },
  },
};
