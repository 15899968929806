import $$ from "dom7";
import goOutAppViewForm from "../pages/goOutAppViewForm.html";
import Template7 from "template7";


export default {
  name: "goOutappviewform",
  path: "/goOutappviewform/",
  component: {
    template: goOutAppViewForm,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.hr.outwork.application.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      date_change(){
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="goOutapp-view-form"]')[0].f7Page
        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;
        let formData = page.app.form.convertToData("#expenseForm");

        if(new Date(formData.actual_return_time) - new Date(self.info.outwork_time) < 0){
          app.dialog.alert("实际返回时间必须大于外出时间！", "系统提示");
          $("input[name='actual_return_time']").val('')

          return false
        }
        
        if (app.data.session.source == "dingding") {

          var getTimeParams = self.getParams("compute_outwork_time", {
            "dingding_code": dingding_code,
            "outwork_time":self.info.outwork_time,
            "expected_return_time":formData.actual_return_time
          });
        } else if (app.data.session.source == "feishu") {

          var getTimeParams = self.getParams("compute_outwork_time", {
            "feishu_userid": feishu_userid,
            "outwork_time":self.info.outwork_time,
            "expected_return_time":formData.actual_return_time
          });
        } else {
          var getTimeParams = self.getParams("compute_outwork_time", {
            "wechat_name": vuserid,
            "outwork_time":self.info.outwork_time,
            "expected_return_time":formData.actual_return_time
          });
        }
        
        page.app.request.postJSON(
          page.app.data.url,
          getTimeParams,
          function (result) {
          if(result.result){
            $('.duration').html(result.result.outwork_hours)
          }
        })


      },
      submit(){
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="goOutapp-view-form"]')[0].f7Page
        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;
        let formData = page.app.form.convertToData("#expenseForm");


        if (app.data.session.source == "dingding") {
          //保存的参数
          var saveParams = this.getParams("modify_actual_return_time", {
            dingding_code: dingding_code,
            "outwork_id":self.info.outwork_id,
            "actual_return_time":formData.actual_return_time
          });
        } else if (app.data.session.source == "feishu") {
          //保存的参数
          var saveParams = this.getParams("modify_actual_return_time", {
            feishu_userid: feishu_userid,
            "outwork_id":self.info.outwork_id,
            "actual_return_time":formData.actual_return_time
          });
        } else {
          var saveParams = this.getParams("modify_actual_return_time", {
            wechat_name: vuserid,
            "outwork_id":self.info.outwork_id,
            "actual_return_time":formData.actual_return_time
          });
        }

        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result) {
            var toast = app.toast.create({
              text: '恭喜您,操作成功',
              position: 'top',
              closeTimeout: 2000
            })
            toast.open()
            app.router.back('/goOutapplist/',{
              force: true
            }) 
          } else {
            var toast = app.toast.create({
              text: '很遗憾,操作失败',
              position: 'top',
              closeTimeout: 2000
            })
            toast.open()
          }
        }); 
      },
    },
    on: {
      pageInit: function (e, page) {
        //注册时间选择控件
        if(this.info.isApproval == true){
          let actual_return_time = new lCalendar();
          actual_return_time.init({
            trigger: "#actual_return_time",
            type: "datetime",
          });
          $("input[type='text']").bind('input propertychange',this.date_change)
        }
        
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};