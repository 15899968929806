import $$ from "dom7";
import addLoanLine from "../pages/addLoanLine.html";
import Template7 from "template7";
import * as dd from "dingtalk-jsapi";

export default {
  name: "addLoanLine",
  path: "/addLoanLine/",
  component: {
    template: addLoanLine,
    methods: {
      borrowing_change(){
        const {
            $$
          } = this
          var page = $$('.page[data-name="addLoanLine"]')[0].f7Page
          let formData = page.app.form.convertToData("#expenseEditForm");
          const borrowing_id = formData.borrowing_id.split(',')[1]
          const current_borrowing_item = this.info.find(function (e) {
            return e.borrowing_id == borrowing_id
          });
          formData.borrow_amount = current_borrowing_item.borrow_amount
          formData.balance_amount = current_borrowing_item.balance_amount
          // formData.offset_amount = current_borrowing_item.offset_amount
          formData.note = current_borrowing_item.note
          $("#loan_borrowing_amount").val(current_borrowing_item.borrow_amount);
          $("#balance_amount").val(current_borrowing_item.balance_amount);
          // $("#loan_offset_amount").val(current_borrowing_item.offset_amount);
          $("#loan_note").val(current_borrowing_item.note);
          console.log(formData)
      },
      save() {
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="addLoanLine"]')[0].f7Page
        let formData = page.app.form.convertToData("#expenseEditForm");
        console.log(formData);
        if (!formData.borrowing_id) {
          app.dialog.alert("请选择单据编号！", "系统提示");
          return;
        }
        if (!formData.offset_amount) {
          app.dialog.alert("请输入核销金额！", "系统提示");
          return;
        }

        if (Number(formData.offset_amount) > Number(formData.balance_amount)) {
          app.dialog.alert("核销金额不能大于剩余金额！", "系统提示");
          return;
        }
        if(!this.detail){
          app.data.expense_data.loan_line_ids.push(formData)
        }else{
          app.data.expense_data.loan_line_ids[self.lineId] = formData
        }
        $$(".back").trigger("click");
   
        
      },
    },
    on: {
      pageInit: function (e, page) { 
        const self = this
        if(this.detail){
          page.app.form.fillFromData('#expenseEditForm', {
            borrow_amount: self.detail.borrow_amount,
            borrowing_id: self.detail.borrowing_id,
            note: self.detail.note,
            offset_amount: self.detail.offset_amount
          });
          let formData = page.app.form.convertToData("#expenseEditForm");
          formData.borrowing_id = this.detail.borrowing_id
        }
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      },
    },
  },
};
