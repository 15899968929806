import $$ from "dom7";
import publicapplicationForm from "../pages/publicapplicationForm.html";
import Template7 from "template7";

export default {
  name: "publicapplicationform",
  path: "/publicapplicationform/",
  component: {
    template: publicapplicationForm,
    data: () => {
      return {
        isFabBtn: false,
        countLine: 1,
      };
    },
    methods: {
      getUserObj: function () {
        if (app.data.session.source == 'dingding') {
          return {
            dingding_code: app.data.dingding_code
          }
        } else if (app.data.session.source == 'feishu') {
          return {
            feishu_userid: app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: app.data.session.userid
          }
        }
      },
      getParams: function (method, obj) {
        var vuserid = app.data.session.userid;
        var dbname = app.data.dbname;
        var userid = app.data.userid;
        var password = app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.public.outwork.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      // 格式化时间的函数，当少0的时候自动补0
      // initTime: function(time){
      //   let timeArr = time.split(':')
      //   return timeArr.map(ele => {
      //     if(ele.length<2){
      //       ele = '0'+ele
      //     }
      //     return ele
      //   }).join(':')
      // },
      submit: function (e) {
        const self = this
        const page = $$('.page[data-name="publicapplication-form"]')[0].f7Page
        let formObj = app.form.convertToData("#publicapplication-form");
        const outwork_type_id = this.outwork_type_id
        const training_days = $('.training_days').html()
        if (!formObj.public_outwork_type) {
          return app.dialog.alert('公出类型必须有值!', "KTHRP");
        }
        if (!formObj.traffic) {
          return app.dialog.alert('交通工具必须有值!', "KTHRP");
        }
        if (!formObj.start_date) {
          return app.dialog.alert('公出开始日期必须有值!', "KTHRP");
        }
        if (!formObj.end_date) {
          return app.dialog.alert('公出结束日期必须有值!', "KTHRP");
        }
        if (!formObj.out_addr) {
          return app.dialog.alert('外出地点必须有值!', "KTHRP");
        }
        if (!formObj.item) {
          return app.dialog.alert('外出事项/会议名称必须有值!', "KTHRP");
        }
        if (!formObj.sponsor) {
          return app.dialog.alert('会议/课程负责人必须有值!', "KTHRP");
        }
        if (!formObj.period) {
          return app.dialog.alert('学时（小时）必须有值!', "KTHRP");
        }
      
        if (app.data.session.source == "dingding") {
          var params = this.getParams("create_public_outwork", {
            dingding_code: app.data.dingding_code,
            ...formObj,
            outwork_type_id,
            training_days
          });
        } else if (app.data.session.source == "feishu") {
          var params = this.getParams("create_public_outwork", {
            feishu_userid: app.data.feishu_userid,
            ...formObj,
            outwork_type_id,
            training_days
          });
        } else {
          var params = this.getParams("create_public_outwork", {
            wechat_name: app.data.session.userid,
            ...formObj,
            outwork_type_id,
            training_days
          });
        }

        const post_file_url = app.data.base_url + '/web/map_upload_attachment'
        const formData = new FormData()
        let files = this.$$('#file-attach')[0].files

        if(files.length == 0){
          // 强制控制
          if (this.info.need_file == 'forbid') {
            return app.dialog.alert('请上传附件后提交！', "KTHRP");
          }else if (this.info.need_file == 'warn') {// 提示控制
            app.dialog.confirm('未上传附件，是否继续提交？', function () {
              app.preloader.show();
              app.request.postJSON(app.data.url, params, function (res) {
                console.log(res);
                if (res.result.state === 'ok') {
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide();
                  toast.open()
                  app.router.back('/publicApplication/', {
                    force: true
                  })
                } else if (res.result.state === 'warn') {
                  app.preloader.hide();
                  app.dialog.alert(res.result.err, "KTHRP");
                } else {
                  app.preloader.hide();
                  app.dialog.alert(res.result.err, "KTHRP");
                }
              });
            });
          } else{
            app.preloader.show();
            app.request.postJSON(app.data.url, params, function (res) {
              console.log(res);
              if (res.result.state === 'ok') {
                var toast = app.toast.create({
                  text: '恭喜您,操作成功',
                  position: 'top',
                  closeTimeout: 2000
                })
                app.preloader.hide();
                toast.open()
                app.router.back('/publicApplication/', {
                  force: true
                })
              } else if (res.result.state === 'warn') {
                app.preloader.hide();
                app.dialog.alert(res.result.err, "KTHRP");
              } else {
                app.preloader.hide();
                app.dialog.alert(res.result.err, "KTHRP");
              }
            });
          }
        }else{
          formData.append('db', app.data.dbname)
          formData.append('id', app.data.userid)
          formData.append('pwd', app.data.password)
          if (app.data.session.source == 'dingding') {
            formData.append('dingding_code', app.data.dingding_code)
          } else if (app.data.session.source == 'feishu') {
            formData.append('feishu_userid', app.data.feishu_userid)
          } else {
            formData.append('wechat_name', app.data.session.userid)
          }
          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            formData.append("file", file)
          }
  
          console.log(formObj)
          app.preloader.show();
          app.request.postJSON(app.data.url, params, function (res) {
            console.log(res);
            if (res.result.state === 'ok') {
              formData.append('model_name', res.result.data.model_name)
              formData.append('record_id', res.result.data.record_id)
              app.request.post(post_file_url, formData, function (result) {
                console.log(result)
                if (JSON.parse(result).state === 'ok') {
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide();
                  toast.open()
                  app.router.back('/publicApplication/', {
                    force: true
                  })
                } else {
                  var toast = app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide();
                  toast.open()
                  app.router.back('/publicApplication/', {
                    force: true
                  })
                }
              });
            } else if (res.result.state === 'warn') {
              app.preloader.hide();
              app.dialog.alert(res.result.err, "KTHRP");
            } else {
              app.preloader.hide();
              app.dialog.alert(res.result.err, "KTHRP");
            }
          });
        }
        
      },
      getDate: function (date) {
        return date.substring(0, date.indexOf(' '))
      },
      sysDate(){
        var myDate = new Date();
        var year = myDate.getFullYear();
        var month = myDate.getMonth()+1;
        var date = myDate.getDate();
        if (month < 10) {
            month = "0" + month
        }
        if (date < 10) {
            date = "0" + date
        }
        //当前系统时间
        return year+"-"+month+"-"+date;
      },
      calcTime(){
        const self = this
        let obj = self.getUserObj()
        let start = $('.calendar-publicapplication-start-date').val()
        let end = $('.calendar-publicapplication-end-date').val()
        obj.start_time = start
        obj.end_time = end
        if(start && end){
          start = start.replace(/-/g, "/")
          end = end.replace(/-/g, "/")
          if(new Date(start.substring(0,10)) - new Date(end.substring(0,10)) > 0){
            return app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
          }
          if(new Date(start.substring(0,10)) - new Date(end.substring(0,10)) == 0 && start.indexOf('下午') > -1 && end.indexOf('上午') > -1){
            return app.dialog.alert('结束日期必须大于开始日期！', "KTHRP")
          }
          app.request.postJSON(app.data.url, app.methods.getJsonrpcParams('kthrp.public.outwork.interface', 'get_time_return', obj), function (res) {
            console.log(res);
            if (res.result) {
              $('.training_days').html(res.result)
            }
          });
        }
        

      },
      initDate(){
        let self = this
        $('.calendar-publicapplication-start-date').datetimePicker({
          title: "",
          // years:[self.sysDate().substring(0,4)],
          times: function () {
              return [{
                  values: ['上午', '下午']
              }];
          },
          value: self.sysDate() + ' 上午',
          onClose: function (picker) {
            self.calcTime(picker,'.calendar-publicapplication-start-date')
          }
        });
        $('.calendar-publicapplication-end-date').datetimePicker({
          title: "",
          // years:[self.sysDate().substring(0,4)],
          times: function () {
              return [{
                  values: ['上午', '下午']
              }];
          },
          value: self.sysDate() + ' 上午',
          onClose: function (picker) {
            self.calcTime(picker,'.calendar-publicapplication-end-date')
          },

        })
 
      }

    },
    on: {
      pageInit: function (e, page) {
        if(app.data.kthrp_customer_code == 'chanyi'){
          switch(this.outwork_type_id){
            case 1: // A类公出申请
            $('#publicapplication-tips').html(`
            <h3 style="color: #000;">温馨提示：疫情防控期间离开佛山的，请务必完成OAH18和H19流程；</h3>`)
            app.popup.open('.publicapplication-tips')
            break;
            case 2: // B类公出申请
            case 4: ///特殊B类公出申请
              $('#publicapplication-tips').html(`
              <h3 style="color: #000;">温馨提示：疫情防控期间离开佛山的，请务必完成OAH18和H19流程；</h3>
              <div>申请人需详阅该说明：</div>
              <div>1、职务级别为1级或2级，可申请天数为10天/年；</div>
              <div>2、职务级别为3级或无职务级别且专业技术职称级别为正高，可申请天数为10天/年；</div>
              <div>3、职务级别为3级或无职务级别且专业技术职称级别为副高，可申请天数为8天/年；</div>
              <div>4、职务级别为3级或无职务级别且专业技术职称级别为中级，可申请天数为6天/年；</div>
              <div>5、职务级别为3级或无职务级别且专业技术职称级别为师级或士级或无职称，可申请天数为5天/年。</div>`)
              app.popup.open('.publicapplication-tips')
              break;
            case 3: //进修申请
              $('#publicapplication-tips').html(`
              <h3 style="color: #000;">温馨提示：疫情防控期间离开佛山的，请务必完成OAH18和H19流程；</h3>
              <div>申请人需详阅该说明：</div>
              <div>1、申请进修需上传进修单位的邀请函或进修通知书。</div>
              <div>2、临床、药剂、医技系列在进修开始和结束后需到科教科办理手续。</div>
              <div>3、护理系列在进修开始和结束后需到护理部办理手续。</div>
              <div>4、申请人结束进修后需到人力资源部报备。</div>
              <div>5、提前结束进修需提交销除进修申请。</div>
              <div>6、延长进修的需重新提交新的进修申请。</div>`)
              app.popup.open('.publicapplication-tips')              
              break;
            default:
              break;
          }
        }
        let self = this;
        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });

        this.initDate()
        this.calcTime()

      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};