/*
 * GoJS v1.8.2 JavaScript Library for HTML Diagrams
 * Northwoods Software, https://www.nwoods.com/
 * GoJS and Northwoods Software are registered trademarks of Northwoods Software Corporation.
 * Copyright (C) 1998-2017 by Northwoods Software Corporation.  All Rights Reserved.
 * THIS SOFTWARE IS LICENSED.  THE LICENSE AGREEMENT IS AT: https://gojs.net/1.8.2/doc/license.html.
 */
(function(window) { var f,ba={};if(!window.document||void 0===window.document.createElement("canvas").getContext)throw window.console&&window.console.log("The HTML Canvas element is not supported in this browser,or this browser is in Compatibility mode."),Error("The HTML Canvas element is not supported in this browser,or this browser is in Compatibility mode.");if(!Object.defineProperty)throw Error("GoJS requires a newer version of JavaScript");
Function.prototype.bind||(Function.prototype.bind=function(a){function b(){return g.apply(a,e.concat(d.call(arguments)))}function c(){}var d=Array.prototype.slice,e=d.call(arguments,1),g=this;c.prototype=this.prototype;b.prototype=new c;return b});
(function(){for(var a=0,b=["ms","moz","webkit","o"],c=0;c<b.length&&!window.requestAnimationFrame;++c)window.requestAnimationFrame=window[b[c]+"RequestAnimationFrame"],window.cancelAnimationFrame=window[b[c]+"CancelAnimationFrame"]||window[b[c]+"CancelRequestAnimationFrame"];window.requestAnimationFrame||(window.requestAnimationFrame=function(b){var c=(new Date).getTime(),g=Math.max(8,16-(c-a)),h=window.setTimeout(function(){b(c+g)},g);a=c+g;return h});window.cancelAnimationFrame||(window.cancelAnimationFrame=
function(a){window.clearTimeout(a)})})();
var v={zj:!1,LA:!1,pF:!1,EI:!1,gM:!1,PF:!1,du:null,enableBoundsInfo:function(a){v.zj=!0;a&&a.ro()},disableBoundsInfo:function(a){v.zj=!1;a&&(a.cd.De(!0),a.ro())},mF:function(a,b){void 0===a&&(a=v.du);void 0===b&&(b=a.qm("").sb);var c=b.length,d=a.cd;d.fillStyle="rgba(255,255,0,.3)";for(var e=0;e<c;e++){var g=b.ea(e),h=g.Z;if(g instanceof x)v.mF(a,g.xa);else{var k=g.Ni.copy();k.tB(g.Jc);d.save();d.transform(k.m11,k.m12,k.m21,k.m22,k.dx,k.dy);d.fillRect(h.x,h.y,h.width,h.height);d.restore()}}},nF:function(a,
b){a||(a=v.du);b||(b=a.qm("").sb);var c=a.cd,d=b.length;c.fillStyle="rgba(0,0,255,.3)";for(var e=0;e<d;e++){var g=b.ea(e),h=g.Ga,k=g.Ni;g instanceof x?v.nF(a,g.xa):(c.save(),c.transform(k.m11,k.m12,k.m21,k.m22,k.dx,k.dy),c.fillRect(h.x,h.y,h.width,h.height),c.restore())}},zI:function(a,b){a||(a=v.du);b||(b=a.qm("").sb);var c=a.cd,d=b.length;c.fillStyle="rgba(0,0,255,.3)";for(var e=0;e<d;e++){var g=b.ea(e),h=g.Fa,k=g.Ni;g instanceof x?v.zI(a,g.xa):(c.save(),c.transform(k.m11,k.m12,k.m21,k.m22,k.dx,
k.dy),c.fillRect(h.x||0,h.y||0,h.width,h.height),c.restore())}},FL:function(){v.nF();v.mF()},GL:function(a){a||(a=v.du);var b=a.jd;a=a.cd;a.strokeStyle="rgba(0,255,0,.9)";a.rect(b.x,b.y,b.width,b.height);a.stroke()},oF:function(a,b){b.fillStyle="red";b.fillRect(0,0,8,8);b.lineWidth=8;b.strokeStyle="rgba(255,255,0,.6)";var c=a.vb;b.rect(c.x,c.y,c.width,c.height);b.stroke();b.fillStyle="rgba(0,255,0,.2)";b.fillRect(a.jd.x,a.jd.y,a.jd.width,a.jd.height)},AI:function(a,b){b instanceof da||(a.lineWidth=
2,a.strokeStyle="rgba(255,0,0,.5)",a.rect(b.cc.x,b.cc.y,b.cc.width,b.cc.height),a.stroke(),b instanceof z&&(a.strokeStyle=b.stroke,a.lineWidth=b.mb),null!==b.R&&b.R.type===fa&&(a.lineWidth=1,a.strokeStyle="rgba(10,200,10,.6)",b instanceof z&&(a.strokeStyle=b.stroke,a.lineWidth=b.mb)))},BI:function(a,b){a.lineWidth=1;a.strokeStyle="rgba(0,0,255,.5)";a.rect(b.Ga.x,b.Ga.y,b.Ga.width,b.Ga.height);a.stroke();void 0!==b.stroke&&(a.strokeStyle=b.stroke);void 0!==b.Sg&&(a.lineWidth=b.Sg)},KA:function(a,b,
c){a.setTransform(1,0,0,1,0,0);a.scale(b.td,b.td);a.transform(c.m11,c.m12,c.m21,c.m22,c.dx,c.dy);c=b.dc.length;for(var d=0;d<c;d++)for(var e=b.dc.ea(d),g=e.sb.length,h=0;h<g;h++){var k=e.sb.ea(h);if(void 0!==k.location&&null!==k.location){if(k.location.F()){var l=k.location.x,m=k.location.y;a.beginPath();a.strokeStyle="limegreen";a.lineWidth=2;a.moveTo(l,m+6);a.lineTo(l,m);a.lineTo(l+6,m);a.moveTo(l,m);a.lineTo(l+10,m+20);a.stroke()}l=k.position.x;m=k.position.y;a.beginPath();a.strokeStyle="red";
a.lineWidth=2;a.moveTo(l,m+6);a.lineTo(l,m);a.lineTo(l+6,m);a.moveTo(l,m);a.lineTo(l+20,m+10);a.stroke()}}a.setTransform(1,0,0,1,0,0)},HL:function(a,b,c){var d=a.length;b.fillStyle="rgba(255,0,0,.1)";for(var e=0;e<d;e++){var g,h=a[e];g=c.Jc;var k=h.x,l=h.y,m=k+h.width,n=l+h.height,p=g.m11,q=g.m12,r=g.m21,s=g.m22,u=g.dx,t=g.dy,y=k*p+l*r+u,h=k*q+l*s+t;g=m*p+l*r+u;var l=m*q+l*s+t,w=k*p+n*r+u,k=k*q+n*s+t,p=m*p+n*r+u,m=m*q+n*s+t,n=y,q=h,n=Math.min(n,g),y=Math.max(y,g),q=Math.min(q,l),h=Math.max(h,l),n=
Math.min(n,w),y=Math.max(y,w),q=Math.min(q,k),h=Math.max(h,k),n=Math.min(n,p),y=Math.max(y,p),q=Math.min(q,m),h=Math.max(h,m);g=new C(n,q,y-n,h-q);b.fillRect(g.x,g.y,g.width,g.height)}},CI:function(a,b,c,d,e){a.fillStyle="rgba(0,255,0,.2)";a.fillRect(b,c,d,e)},DI:function(a,b,c){a.save();var d=b.Jc;d.reset();1!==b.scale&&d.scale(b.scale);b=b.position;0===b.x&&0===b.y||d.translate(-b.x,-b.y);a.setTransform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);c=c.fd.o;d=c.length;for(b=0;b<d;b++){var e=c[b];a.beginPath();
a.moveTo(e.x-4,e.y);a.lineTo(e.x+4,e.y);a.moveTo(e.x,e.y-4);a.lineTo(e.x,e.y+4);a.lineWidth=2;a.strokeStyle="rgba(255,0,0,.9)";a.stroke()}a.restore()},DL:function(a){a||(a=v.du);for(a=a.bo;a.next();)D.trace(a.value.toString())},uM:function(a,b){var c=b.dp(a),d=b.Vb;d.se=b.cC(c);d.ga=c;return ga(b,d,a)},pM:function(a,b){b.Ie(a.ga)&&(b.cd.fillStyle="lime",b.cd.fillRect(a.se.x,a.se.y,1*b.scale,1*b.scale))},qM:function(a){var b=ha(a,!1,null,null);if(!b.sq){var c=a.vb,d=a.scale;a=a.cd;for(var e=0;e<=b.An;e++)for(var g=
0;g<=b.Bn;g++){var h=b.rd[e][g];if(0!==h){var k=(e*b.ge+b.$f-c.x)*d,l=(g*b.Od+b.ag-c.y)*d;0!==h&&(a.fillStyle="rgba(255, 0, 0, .2)",a.fillRect(k,l,b.ge*d,b.Od*d));999999<=h||(h%=10,a.fillStyle="blue",a.fillText(h.toString(),k,l+b.Od))}}}},dumpReferences:function(a){if(a instanceof E)for(a=a.bo;a.next();){var b=a.value;D.trace(b.toString());for(b=b.Ii;b.next();)v.dumpReferences(b.value)}else if(a instanceof F){D.trace("References for "+a);null!==a.layer&&D.trace("  "+a.layer.toString()+' LayerName: "'+
a.Mf+'"');a.to!==a&&D.trace("  SelectionObject: "+a.to.toString()+' SelectionObjectName: "'+a.Hy+'"');a.Bf!==a&&D.trace("  LocationObject: "+a.Bf.toString()+' LocationObjectName: "'+a.ly+'"');if(a.oh){for(var b="",c=a.oh.j;c.next();)b+=c.key+" ";D.trace("  Adornments: "+b)}null!==a.Ka&&D.trace("  ContainingGroup: "+a.Ka.toString());if(a instanceof G){if(0<a.Kd.count){b="";for(c=a.Kd;c.next();)b+=c.value.toString()+", ";D.trace("  Links: "+b)}null!==a.Yb&&D.trace("  LabeledLink: "+a.Yb.toString());
if(a instanceof I){D.trace("  Placeholder: "+a.placeholder);b="";for(c=a.Oc;c.next();)b+=c.value.toString()+", ";D.trace("  Members: "+b);b="";for(c=a.hn.j;c.next();)b+=c.value.toString()+", ";D.trace("  NestedGroups: "+b);D.trace("  Layout: "+a.Zb)}}else if(a instanceof J){D.trace("  Path: "+a.path);D.trace("  From: "+a.aa+" "+a.Ac+' "'+a.hg+'"');D.trace("  To: "+a.ba+" "+a.ad+' "'+a.mh+'"');b="";for(a=a.Af;a.next();)b+=a.value.toString()+", ";D.trace("  LabelNodes: "+b)}}},dumpVisualTree:function(a){if(a instanceof
E)for(a=a.bo;a.next();){var b=a.value;D.trace(b.toString());for(b=b.Ii;b.next();)v.dumpVisualTree(b.value)}else a instanceof F&&(b=" ",a.cF&&(b+="c"),a.gF&&(b+="d"),a.NF&&(b+="g"),a.qG&&(b+="m"),a.IG&&(b+="h"),a.JG&&(b+="z"),a.OG&&(b+="o"),a.el&&(b+="s"),a.eH&&(b+="t"),a.UG&&(b+="A"),a.Xx&&(b+="B"),a.gB&&(b+="L"),a.ib&&(b+="S"),a.$k&&(b+="H"),v.fD(a,1,b))},fD:function(a,b,c){for(var d="",e=0;e<b;e++)d+="  ";d+=a.toString();c&&(d+=c);c=a.name?' "'+a.name+'" ':" ";c=a.visible?c+"v":c+"!v";c=a.tg?c+
"p":c+"!p";a.Ue&&(c+="m");a.yu&&(c+="a");d+=c;if(0!==a.Sb||0!==a.column)d+=" ["+a.Sb+","+a.column+"]";d+=" "+a.Z.toString();a.Ea.F()&&(d+=" d:"+a.Ea.toString());a.Ga.F()&&(d+=" n:"+a.Ga.toString());1!==a.scale&&(d+=" s:"+a.scale);0!==a.angle&&(d+=" a:"+a.angle);null!==a.background&&(d+=" b:"+a.background.toString());null!==a.fm&&(d+=" a:"+a.background.toString());a instanceof x&&(d+=" elts:"+a.xa.count,a.isEnabled||(d+=" !ENABLED"),a.vq&&(d+=" CLIPPING"),0!==a.kH&&(d+=" top:"+a.kH),0!==a.jG&&(d+=
" left:"+a.jG),null!==a.al&&(d+=" itemArray#:"+D.cb(a.al)),a.fG&&(d+=" cat:"+a.fG),null!==a.data&&(d+=" data:"+a.data));null!==a.wd&&(d+=' portId: "'+a.wd+'"');D.trace(d);if(a instanceof x)for(a=a.elements;a.next();)v.fD(a.value,b+1,"")},JL:function(a){D.trace("DelayedReferences ("+a.Ag.count+")");for(a=a.Ag.j;a.next();){for(var b="",c=a.value.j;c.next();)b+=ia(c.value)+", ";D.trace("  "+a.key+": "+b)}},VE:function(a,b){if(!D.Qa(b)||b instanceof Element||b instanceof CanvasRenderingContext2D||b instanceof
ja||b instanceof ma)return"";var c="",d;for(d in b)if("string"!==typeof d)""===c&&(c=b+"\n"),c+="  "+d+" is not a string property\n";else if("_"!==d.charAt(0)&&!(2>=d.length)){var e=D.wb(b,d);if(null!==e&&"function"!==typeof e){if(b.hasOwnProperty(d)){var g=Object.getPrototypeOf(b);if(g&&g.Hw&&g.Hw[d])continue}else if(D.Tx(b,d))continue;""===c&&(c=b+"\n");c+='  unknown property "'+d+'" has value: '+e+" at "+a+"\n"}}return c},xx:function(a,b){if(null!==b&&void 0!==b&&"number"!==typeof b&&"string"!==
typeof b&&"boolean"!==typeof b&&"function"!==typeof b)if(void 0!==D.Jd(b)){if(!v.Uw.contains(b))if(v.Uw.add(b),v.vw.add(v.VE(a,b)),b instanceof K||b instanceof L||b instanceof oa)for(var c=b.j;c.next();)v.xx(a+"["+c.key+"]",c.value);else for(c in b){var d=D.wb(b,c);if(void 0!==d&&null!==d&&D.Qa(d)&&d!==b.Hw){if(b instanceof pa){if(d===b.rf)continue}else if(b instanceof x){if("data"===c||d===b.Pd)continue;if("itemArray"===c||d===b.cj)continue;if(b instanceof F&&d===b.Rl)continue}else if(!(b instanceof
E))if(b instanceof qa){if("archetypeGroupData"===c||d===b.ez)continue}else if(b instanceof ra){if("archetypeLinkData"===c||d===b.gz)continue;if("archetypeLabelNodeData"===c||d===b.fz)continue}else if(b instanceof ta){if("archetypeNodeData"===c||d===b.ul)continue}else if(b instanceof M){if("nodeDataArray"===c||d===b.ye)continue;if("linkDataArray"===c||d===b.ef||d===b.ji)continue;if(d===b.Gc)continue;if(d===b.Ag)continue}else if(b instanceof ua||b instanceof va||b instanceof wa)continue;v.xx(a+"."+
c,d)}}}else if(Array.isArray(b))for(c=0;c<b.length;c++)v.xx(a+"["+c+"]",b[c]);else v.vw.add(v.VE(a,b))},checkProperties:function(a){void 0===v.Uw?v.Uw=new L(Object):v.Uw.clear();v.vw=new xa;v.xx("",a);a=v.vw.toString();v.vw=null;return a}};ba.Debug=v;
var D={yd:1,bd:2,qd:4,pd:8,Yn:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("534.30")&&0<window.navigator.userAgent.indexOf("Android"),kJ:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("MSIE 9.0"),YF:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("MSIE 10.0"),ZF:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("Trident/7"),RL:void 0!==window.navigator&&0<window.navigator.userAgent.indexOf("Edge/"),Zk:void 0!==window.navigator&&void 0!==
window.navigator.platform&&0<=window.navigator.platform.toUpperCase().indexOf("MAC"),$F:void 0!==window.navigator&&void 0!==window.navigator.platform&&null!==window.navigator.platform.match(/(iPhone|iPod|iPad)/i),dF:function(a,b,c){var d=-1;return function(){var e=this,g=arguments;-1!==d&&D.clearTimeout(d);d=D.setTimeout(function(){d=-1;c||a.apply(e,g)},b);c&&!d&&a.apply(e,g)}},setTimeout:function(a,b){return window.setTimeout(a,b)},clearTimeout:function(a){window.clearTimeout(a)},createElement:function(a){return window.document.createElement(a)},
k:function(a){throw Error(a);},qa:function(a,b){var c="The object is frozen, so its properties cannot be set: "+a.toString();void 0!==b&&(c+="  to value: "+b);D.k(c)},l:function(a,b,c,d){a instanceof b||(c=D.getTypeName(c),void 0!==d&&(c+="."+d),D.jc(a,b,c))},h:function(a,b,c,d){typeof a!==b&&(c=D.getTypeName(c),void 0!==d&&(c+="."+d),D.jc(a,b,c))},p:function(a,b,c){"number"===typeof a&&isFinite(a)||(b=D.getTypeName(b),void 0!==c&&(b+="."+c),D.k(b+" must be a real number type, and not NaN or Infinity: "+
a))},Da:function(a,b,c,d){a instanceof ya&&a.Oe===b||(c=D.getTypeName(c),void 0!==d&&(c+="."+d),D.jc(a,"a constant of class "+D.vf(b),c))},$t:function(a,b){"string"===typeof a?Aa(a)||D.k('Color "'+a+'" is not a valid color string for '+b):a instanceof Ba||D.k("Value for "+b+" must be a color string or a Brush, not "+a)},jc:function(a,b,c,d){b=D.getTypeName(b);c=D.getTypeName(c);void 0!==d&&(c+="."+d);"string"===typeof a?D.k(c+" value is not an instance of "+b+': "'+a+'"'):D.k(c+" value is not an instance of "+
b+": "+a)},ua:function(a,b,c,d){c=D.getTypeName(c);void 0!==d&&(c+="."+d);D.k(c+" is not in the range "+b+": "+a)},xd:function(a){D.k(D.vf(a)+" constructor cannot take any arguments.")},Ua:function(a){D.k("Collection was modified during iteration: "+a.toString()+"\n  Perhaps you should iterate over a copy of the collection,\n  or you could collect items to be removed from the collection after the iteration.")},Yj:function(a,b){D.k("No property to set for this enum value: "+b+" on "+a.toString())},
trace:function(a){return false},Gx:{},Pn:function(a,b){!0!==D.Gx[a]&&(D.Gx[a]=!0,window.console&&window.console.log(a+" is deprecated in "+b+", see the GoJS change log for more information."))},Qa:function(a){return"object"===typeof a&&null!==a},isArray:function(a){return Array.isArray(a)||a instanceof NodeList||a instanceof HTMLCollection},mJ:function(a){return Array.isArray(a)},Zt:function(a,b,c){D.isArray(a)||D.jc(a,"Array or NodeList or HTMLCollection",b,c)},cb:function(a){return a.length},
jm:function(a){return Array.prototype.slice.call(a)},La:function(a,b){Array.isArray(a);return a[b]},SE:function(a,b,c){Array.isArray(a)?a[b]=c:D.k("Cannot replace an object in an HTMLCollection or NodeList at "+b)},gm:function(a,b){if(Array.isArray(a))return a.indexOf(b);for(var c=a.length,d=0;d<c;d++)if(a[d]===b)return d;return-1},Kh:function(a,b,c){Array.isArray(a)?b>=a.length?a.push(c):a.splice(b,0,c):D.k("Cannot insert an object into an HTMLCollection or NodeList: "+c+" at "+b)},Wg:function(a,
b){Array.isArray(a)?b>=a.length?a.pop():a.splice(b,1):D.k("Cannot remove an object from an HTMLCollection or NodeList at "+b)},Xy:[],P:function(){var a=D.Xy.pop();return void 0===a?new N:a},Fb:function(a,b){var c=D.Xy.pop();if(void 0===c)return new N(a,b);c.x=a;c.y=b;return c},A:function(a){D.Xy.push(a)},qC:[],Gm:function(){var a=D.qC.pop();return void 0===a?new Ca:a},Uk:function(a){D.qC.push(a)},Yy:[],Qf:function(){var a=D.Yy.pop();return void 0===a?new C:a},vg:function(a,b,c,d){var e=D.Yy.pop();
if(void 0===e)return new C(a,b,c,d);e.x=a;e.y=b;e.width=c;e.height=d;return e},Kb:function(a){D.Yy.push(a)},rC:[],Rf:function(){var a=D.rC.pop();return void 0===a?new Ea:a},Je:function(a){D.rC.push(a)},Zy:null,v:function(){var a=D.Zy;return null!==a?(D.Zy=null,a):new Fa},u:function(a){a.reset();D.Zy=a},pC:[],nb:function(){var a=D.pC.pop();return void 0===a?[]:a},ya:function(a){a.length=0;D.pC.push(a)},Do:Object.freeze([]),Km:1,vc:function(a){a.__gohashid=D.Km++},pq:function(a){var b=a.__gohashid;
void 0===b&&(b=D.Km++,a.__gohashid=b);return b},Jd:function(a){return a.__gohashid},ka:function(a,b){b.lz=a;ba[a]=b},Sa:function(a,b){function c(){}c.prototype=b.prototype;a.prototype=new c;a.prototype.constructor=a},Di:function(a){a.JH=!0},defineProperty:function(a,b,c,d,e){D.h(a,"function","Util.defineProperty:classfunc");D.h(b,"object","Util.defineProperty:propobj");D.h(c,"function","Util.defineProperty:getter");D.h(d,"function","Util.defineProperty:setter");for(var g in b){b=b[g];c={get:c,set:d,
enumerable:!0};if(void 0!==e)for(var h in e)c[h]=e[h];Object.defineProperty(a.prototype,g,c);e=Object.getOwnPropertyDescriptor(a.prototype,g);b&&e&&Object.defineProperty(a.prototype,b,e);break}},HK:!1,w:function(a,b,c,d){D.h(a,"function","Util.defineReadOnlyProperty:classfunc");D.h(b,"object","Util.defineReadOnlyProperty:propobj");D.h(c,"function","Util.defineReadOnlyProperty:getter");for(var e in b){var g=b[e];b={get:c,set:function(a){D.k('The property "'+g+'" is read-only and cannot be set to '+
a)},enumerable:!0};if(void 0!==d)for(var h in d)b[h]=d[h];Object.defineProperty(a.prototype,e,b);d=Object.getOwnPropertyDescriptor(a.prototype,e);g&&d&&Object.defineProperty(a.prototype,g,d);break}},ne:function(a,b){for(var c in b)b[c]=!0;a.prototype.Hw=b},getTypeName:function(a){return void 0===a?"":"string"===typeof a?a:"function"===typeof a?D.vf(a):null===a?"*":""},vf:function(a){if("function"===typeof a){if(a.lz)return a.lz;if(a.name)return a.name;var b=a.toString(),c=b.indexOf("("),b=b.substring(9,
c).trim();if(""!==b)return a.lz=b}else if(D.Qa(a)&&a.constructor)return D.vf(a.constructor);return typeof a},s:function(a,b,c){D.h(a,"function","Util.defineEnumValue:classfunc");D.h(b,"string","Util.defineEnumValue:name");D.h(c,"number","Util.defineEnumValue:num");c=new ya(a,b,c);Object.freeze(c);a[b]=c;var d=a.pv;d instanceof oa||(d=new oa("string",ya),a.pv=d);d.add(b,c);return c},wb:function(a,b){if(!a||!b)return null;var c=void 0;try{"function"===typeof b?c=b(a):"function"===typeof a.getAttribute?
(c=a.getAttribute(b),null===c&&(c=void 0)):c=a[b]}catch(d){v&&D.trace("property get error: "+d.toString())}return c},Ta:function(a,b,c){if(a&&b)try{"function"===typeof b?b(a,c):"function"===typeof a.setAttribute?a.setAttribute(b,c):a[b]=c}catch(d){v&&D.trace("property set error: "+d.toString())}},dv:function(a,b){D.h(a,"object","Setting properties requires Objects as arguments");D.h(b,"object","Setting properties requires Objects as arguments");var c=a instanceof x,d=a instanceof E,e;for(e in b){""===
e&&D.k("Setting properties requires non-empty property names");var g=a,h=e;if(c||d){var k=e.indexOf(".");if(0<k){var l=e.substring(0,k);if(c)g=a.Hd(l);else if(g=a[l],void 0===g||null===g)g=a.ob[l];D.Qa(g)?h=e.substr(k+1):D.k("Unable to find object named: "+l+" in "+a.toString()+" when trying to set property: "+e)}}if("_"!==h[0]&&!D.Tx(g,h))if(d&&"ModelChanged"===h){a.TH(b[h]);continue}else if(d&&"Changed"===h){a.Cn(b[h]);continue}else if(d&&D.Tx(a.ob,h))g=a.ob;else if(d&&Ga(a,h)){a.mx(h,b[h]);continue}else if(a instanceof
M&&"Changed"===h){a.Cn(b[h]);continue}else D.k('Trying to set undefined property "'+h+'" on object: '+g.toString());g[h]=b[e];"_"===h[0]&&g instanceof P&&g.QH(h)}},Tx:function(a,b){if(a.hasOwnProperty(b))return!0;for(var c=Object.getPrototypeOf(a);c&&c!==Function;){if(c.hasOwnProperty(b))return!0;var d=c.Hw;if(d&&d[b])return!0;c=Object.getPrototypeOf(c)}return!1},XJ:function(a){for(var b=[],c=0;256>c;c++)b[c]=c;for(var d=0,e=0,c=0;256>c;c++)d=(d+b[c]+119)%256,e=b[c],b[c]=b[d],b[d]=e;for(var d=c=0,
g="",h=0;h<a.length;h++)c=(c+1)%256,d=(d+b[c])%256,e=b[c],b[c]=b[d],b[d]=e,g+=String.fromCharCode(a.charCodeAt(h)^b[(b[c]+b[d])%256]);return g},ZI:function(a){for(var b={},c=0;256>c;c++)b["0123456789abcdef".charAt(c>>4)+"0123456789abcdef".charAt(c&15)]=String.fromCharCode(c);a.length%2&&(a="0"+a);for(var d=[],e=0,c=0;c<a.length;c+=2)d[e++]=b[a.substr(c,2)];a=d.join("");return""===a?"0":a},Xg:function(a){return D.XJ(D.ZI(a))},Im:null,adym:"7da71ca0ad381e90",vfo:"2be64efdb1",kL:"@COLOR1",lL:"@COLOR2",
eF:!1,bu:null,cu:null};
D.Im=function(){var a=window.document.createElement("canvas"),b=a.getContext("2d");b[D.Xg("7ca11abfd022028846")]=D.Xg("398c3597c01238");for(var c=["5da73c80a36755dc038e4972187c3cae51fd22","32ab5ff3b26f42dc0ed90f22432913b54ae6247590da4bb21c324ba3a84e385776","54a702f3e53909c447824c6706603faf4cfb236cdfda5de14c134ba1a95a2d4c7cc6f93c1387","74bf19bce72555874c86"],d=1;5>d;d++)b[D.Xg("7ca11abfd7330390")](D.Xg(c[d-1]),10,15*d+0);b[D.Xg("7ca11abfd022028846")]=D.Xg("39f046ebb36e4b");for(d=1;5>d;d++)b[D.Xg("7ca11abfd7330390")](D.Xg(c[d-
1]),10,15*d+0);if(4!==c.length||"5"!==c[0][0]||"7"!==c[3][0])D.s=function(a,b){var c=new ya(a,b,2);Object.freeze(c);a[b]=c;var d=a.pv;d instanceof oa||(d=new oa("string",ya),a.pv=d);d.add(b,c);return c};return a}();function ya(a,b,c){D.vc(this);this.BC=a;this.ac=b;this.NH=c}D.ka("EnumValue",ya);ya.prototype.toString=function(){return D.vf(this.BC)+"."+this.ac};D.w(ya,{Oe:"classType"},function(){return this.BC});D.w(ya,{name:"name"},function(){return this.ac});D.w(ya,{value:"value"},function(){return this.NH});
var Ha;ya.findName=Ha=function(a,b){if(void 0===b||null===b||""===b)return null;D.h(a,"function","findName:classfunc");D.h(b,"string","EnumValue.findName:name");var c=a.pv;return c instanceof oa?c.oa(b):null};function xa(){this.AC=[]}xa.prototype.toString=function(){return this.AC.join("")};xa.prototype.add=function(a){""!==a&&this.AC.push(a)};function ma(){}
function Ia(a){void 0===a&&(a=42);this.seed=a;this.Ty=48271;this.ov=2147483647;this.oC=this.ov/this.Ty;this.vH=this.ov%this.Ty;this.uH=1/this.ov;this.random()}Ia.prototype.random=function(){var a=this.seed%this.oC*this.Ty-this.seed/this.oC*this.vH;this.seed=0<a?a:a+this.ov;return this.seed*this.uH};function Ja(){}D.w(Ja,{j:"iterator"},function(){return this});Ja.prototype.reset=Ja.prototype.reset=function(){};Ja.prototype.next=Ja.prototype.next=function(){return!1};Ja.prototype.hasNext=function(){return!1};
Ja.prototype.first=Ja.prototype.first=function(){return null};Ja.prototype.any=function(){return!1};Ja.prototype.all=function(){return!0};Ja.prototype.each=function(){return this};Ja.prototype.map=function(){return this};Ja.prototype.filter=function(){return this};Ja.prototype.concat=function(a){return a.j};D.w(Ja,{count:"count"},function(){return 0});Ja.prototype.Uf=function(){};Ja.prototype.toString=function(){return"EmptyIterator"};var Ka=new Ja;function Ma(a){this.key=-1;this.value=a}
D.ne(Ma,{key:!0,value:!0});D.w(Ma,{j:"iterator"},function(){return this});Ma.prototype.reset=Ma.prototype.reset=function(){this.key=-1};Ma.prototype.next=Ma.prototype.next=function(){return-1===this.key?(this.key=0,!0):!1};Ma.prototype.hasNext=function(){return this.next()};Ma.prototype.first=Ma.prototype.first=function(){this.key=0;return this.value};Ma.prototype.any=function(a){this.key=-1;return a(this.value)};Ma.prototype.all=function(a){this.key=-1;return a(this.value)};
Ma.prototype.each=function(a){this.key=-1;a(this.value);return this};Ma.prototype.map=function(a){return new Ma(a(this.value))};Ma.prototype.filter=function(a){return a(this.value)?new Ma(this.value):Ka};Ma.prototype.concat=function(a){return new Na(this,a.j)};D.w(Ma,{count:"count"},function(){return 1});Ma.prototype.Uf=function(){this.value=null};Ma.prototype.toString=function(){return"SingletonIterator("+this.value+")"};function Na(a,b){this.Jl=a;this.Kl=b;this.qv=!1}D.ne(Na,{key:!0,value:!0});
D.w(Na,{j:"iterator"},function(){return this});Na.prototype.reset=Na.prototype.reset=function(){this.Jl.reset();this.Kl.reset();this.qv=!1};Na.prototype.next=Na.prototype.next=function(){if(!this.qv){var a=this.Jl;if(a.next())return this.key=a.key,this.value=a.value,!0;this.qv=!0}return this.qv&&(a=this.Kl,a.next())?(this.key=a.key,this.value=a.value,!0):!1};Na.prototype.hasNext=function(){return this.next()};
Na.prototype.first=Na.prototype.first=function(){this.reset();return this.next()?this.value:null};Na.prototype.any=function(a){return this.Jl.any(a)||this.Kl.any(a)?!0:!1};Na.prototype.all=function(a){return this.Jl.all(a)&&this.Kl.all(a)?!0:!1};Na.prototype.each=function(a){this.Jl.each(a);this.Kl.each(a);return this};Na.prototype.map=function(a){return new Na(this.Jl.map(a),this.Kl.map(a))};Na.prototype.filter=function(a){return new Na(this.Jl.filter(a),this.Kl.filter(a))};
Na.prototype.concat=function(a){return new Na(this,a.j)};D.w(Na,{count:"count"},function(){return this.Jl.count+this.Kl.count});Na.prototype.Uf=function(){this.value=this.key=null};Na.prototype.toString=function(){return"ConcatIterator()"};function Qa(a){this.Fc=a;this.pi=null;a.$b=null;this.Za=a.I;this.Ec=-1}D.ne(Qa,{key:!0,value:!0});D.w(Qa,{j:"iterator"},function(){return this});D.defineProperty(Qa,{Eq:"predicate"},function(){return this.pi},function(a){this.pi=a});
Qa.prototype.reset=Qa.prototype.reset=function(){var a=this.Fc;a.$b=null;this.Za=a.I;this.Ec=-1};Qa.prototype.next=Qa.prototype.next=function(){var a=this.Fc;if(a.I!==this.Za){if(0>this.key)return!1;D.Ua(a)}var a=a.o,b=a.length,c=++this.Ec,d=this.pi;if(null!==d)for(;c<b;){var e=a[c];if(d(e))return this.key=this.Ec=c,this.value=e,!0;c++}else{if(c<b)return this.key=c,this.value=a[c],!0;this.Uf()}return!1};Qa.prototype.hasNext=function(){return this.next()};
Qa.prototype.first=Qa.prototype.first=function(){var a=this.Fc;this.Za=a.I;this.Ec=0;var a=a.o,b=a.length,c=this.pi;if(null!==c){for(var d=0;d<b;){var e=a[d];if(c(e))return this.key=this.Ec=d,this.value=e;d++}return null}return 0<b?(e=a[0],this.key=0,this.value=e):null};Qa.prototype.any=function(a){var b=this.Fc;b.$b=null;var c=b.I;this.Ec=-1;for(var d=b.o,e=d.length,g=this.pi,h=0;h<e;h++){var k=d[h];if(null===g||g(k)){if(a(k))return!0;b.I!==c&&D.Ua(b)}}return!1};
Qa.prototype.all=function(a){var b=this.Fc;b.$b=null;var c=b.I;this.Ec=-1;for(var d=b.o,e=d.length,g=this.pi,h=0;h<e;h++){var k=d[h];if(null===g||g(k)){if(!a(k))return!1;b.I!==c&&D.Ua(b)}}return!0};Qa.prototype.each=function(a){var b=this.Fc;b.$b=null;var c=b.I;this.Ec=-1;for(var d=b.o,e=d.length,g=this.pi,h=0;h<e;h++){var k=d[h];if(null===g||g(k))a(k),b.I!==c&&D.Ua(b)}return this};
Qa.prototype.map=function(a){var b=this.Fc;b.$b=null;var c=b.I;this.Ec=-1;for(var d=[],e=b.o,g=e.length,h=this.pi,k=0;k<g;k++){var l=e[k];if(null===h||h(l))d.push(a(l)),b.I!==c&&D.Ua(b)}a=new K;a.o=d;a.Sc();return a.j};Qa.prototype.filter=function(a){var b=this.Fc;b.$b=null;var c=b.I;this.Ec=-1;for(var d=[],e=b.o,g=e.length,h=this.pi,k=0;k<g;k++){var l=e[k];if(null===h||h(l))a(l)&&d.push(l),b.I!==c&&D.Ua(b)}a=new K(b.ca);a.o=d;a.Sc();return a.j};
Qa.prototype.concat=function(a){this.Fc.$b=null;return new Na(this,a.j)};D.w(Qa,{count:"count"},function(){var a=this.pi;if(null!==a){for(var b=0,c=this.Fc.o,d=c.length,e=0;e<d;e++)a(c[e])&&b++;return b}return this.Fc.o.length});Qa.prototype.Uf=function(){this.key=-1;this.value=null;this.Za=-1;this.pi=null;this.Fc.$b=this};Qa.prototype.toString=function(){return"ListIterator@"+this.Ec+"/"+this.Fc.count};function Ua(a){this.Fc=a;a.dj=null;this.Za=a.I;this.Ec=a.o.length}D.ne(Ua,{key:!0,value:!0});
D.w(Ua,{j:"iterator"},function(){return this});Ua.prototype.reset=Ua.prototype.reset=function(){var a=this.Fc;a.dj=null;this.Za=a.I;this.Ec=a.o.length};Ua.prototype.next=Ua.prototype.next=function(){var a=this.Fc;if(a.I!==this.Za){if(0>this.key)return!1;D.Ua(a)}var b=--this.Ec;if(0<=b)return this.key=b,this.value=a.o[b],!0;this.Uf();return!1};Ua.prototype.hasNext=function(){return this.next()};
Ua.prototype.first=Ua.prototype.first=function(){var a=this.Fc;this.Za=a.I;var b=a.o;this.Ec=a=b.length-1;return 0<=a?(b=b[a],this.key=a,this.value=b):null};Ua.prototype.any=function(a){var b=this.Fc;b.dj=null;var c=b.I,d=b.o,e=d.length;this.Ec=e;for(e-=1;0<=e;e--){if(a(d[e]))return!0;b.I!==c&&D.Ua(b)}return!1};Ua.prototype.all=function(a){var b=this.Fc;b.dj=null;var c=b.I,d=b.o,e=d.length;this.Ec=e;for(e-=1;0<=e;e--){if(!a(d[e]))return!1;b.I!==c&&D.Ua(b)}return!0};
Ua.prototype.each=function(a){var b=this.Fc;b.dj=null;var c=b.I,d=b.o,e=d.length;this.Ec=e;for(e-=1;0<=e;e--)a(d[e]),b.I!==c&&D.Ua(b);return this};Ua.prototype.map=function(a){var b=this.Fc;b.dj=null;var c=b.I,d=[],e=b.o,g=e.length;this.Ec=g;for(g-=1;0<=g;g--)d.push(a(e[g])),b.I!==c&&D.Ua(b);a=new K;a.o=d;a.Sc();return a.j};
Ua.prototype.filter=function(a){var b=this.Fc;b.dj=null;var c=b.I,d=[],e=b.o,g=e.length;this.Ec=g;for(g-=1;0<=g;g--){var h=e[g];a(h)&&d.push(h);b.I!==c&&D.Ua(b)}a=new K(b.ca);a.o=d;a.Sc();return a.j};Ua.prototype.concat=function(a){this.Fc.dj=null;return new Na(this,a.j)};D.w(Ua,{count:"count"},function(){return this.Fc.o.length});Ua.prototype.Uf=function(){this.key=-1;this.value=null;this.Za=-1;this.Fc.dj=this};
Ua.prototype.toString=function(){return"ListIteratorBackwards("+this.Ec+"/"+this.Fc.count+")"};
function K(a){D.vc(this);this.J=!1;this.o=[];this.I=0;this.dj=this.$b=null;void 0===a||null===a?this.ca=null:"string"===typeof a?"object"===a||"string"===a||"number"===a||"boolean"===a||"function"===a?this.ca=a:D.ua(a,"the string 'object', 'number', 'string', 'boolean', or 'function'","List constructor: type"):"function"===typeof a?this.ca=a===Object?"object":a===String?"string":a===Number?"number":a===Boolean?"boolean":a===Function?"function":a:D.ua(a,"null, a primitive type name, or a class type",
"List constructor: type")}D.ka("List",K);K.prototype.qh=function(a){null!==this.ca&&("string"===typeof this.ca?typeof a===this.ca&&null!==a||D.jc(a,this.ca):a instanceof this.ca||D.jc(a,this.ca))};K.prototype.Sc=function(){var a=this.I;a++;999999999<a&&(a=0);this.I=a};K.prototype.freeze=K.prototype.freeze=function(){this.J=!0;return this};K.prototype.thaw=K.prototype.Xa=function(){this.J=!1;return this};K.prototype.toString=function(){return"List("+D.getTypeName(this.ca)+")#"+D.Jd(this)};
K.prototype.add=K.prototype.add=function(a){null!==a&&(v&&this.qh(a),this.J&&D.qa(this,a),this.o.push(a),this.Sc())};K.prototype.push=K.prototype.push=function(a){this.add(a)};K.prototype.addAll=K.prototype.Tc=function(a){if(null===a)return this;this.J&&D.qa(this);var b=this.o;if(D.isArray(a))for(var c=D.cb(a),d=0;d<c;d++){var e=D.La(a,d);v&&this.qh(e);b.push(e)}else for(a=a.j;a.next();)e=a.value,v&&this.qh(e),b.push(e);this.Sc();return this};
K.prototype.clear=K.prototype.clear=function(){this.J&&D.qa(this);this.o.length=0;this.Sc()};K.prototype.contains=K.prototype.contains=function(a){if(null===a)return!1;v&&this.qh(a);return-1!==this.o.indexOf(a)};K.prototype.has=K.prototype.has=function(a){return this.contains(a)};K.prototype.indexOf=K.prototype.indexOf=function(a){if(null===a)return-1;v&&this.qh(a);return this.o.indexOf(a)};
K.prototype.elt=K.prototype.ea=function(a){v&&D.p(a,K,"elt:i");var b=this.o;(0>a||a>=b.length)&&D.ua(a,"0 <= i < length",K,"elt:i");return b[a]};K.prototype.get=K.prototype.get=function(a){return this.ea(a)};K.prototype.setElt=K.prototype.ug=function(a,b){v&&(this.qh(b),D.p(a,K,"setElt:i"));var c=this.o;(0>a||a>=c.length)&&D.ua(a,"0 <= i < length",K,"setElt:i");this.J&&D.qa(this,a);c[a]=b};K.prototype.set=K.prototype.set=function(a,b){this.ug(a,b)};
K.prototype.first=K.prototype.first=function(){var a=this.o;return 0===a.length?null:a[0]};K.prototype.last=K.prototype.qe=function(){var a=this.o,b=a.length;return 0<b?a[b-1]:null};K.prototype.pop=K.prototype.pop=function(){this.J&&D.qa(this);var a=this.o;return 0<a.length?a.pop():null};K.prototype.any=function(a){for(var b=this.o,c=this.I,d=b.length,e=0;e<d;e++){if(a(b[e]))return!0;this.I!==c&&D.Ua(this)}return!1};
K.prototype.all=function(a){for(var b=this.o,c=this.I,d=b.length,e=0;e<d;e++){if(!a(b[e]))return!1;this.I!==c&&D.Ua(this)}return!0};K.prototype.each=function(a){for(var b=this.o,c=this.I,d=b.length,e=0;e<d;e++)a(b[e]),this.I!==c&&D.Ua(this);return this};K.prototype.map=function(a){for(var b=new K,c=[],d=this.o,e=this.I,g=d.length,h=0;h<g;h++)c.push(a(d[h])),this.I!==e&&D.Ua(this);b.o=c;b.Sc();return b};
K.prototype.filter=function(a){for(var b=new K(this.ca),c=[],d=this.o,e=this.I,g=d.length,h=0;h<g;h++){var k=d[h];a(k)&&c.push(k);this.I!==e&&D.Ua(this)}b.o=c;b.Sc();return b};K.prototype.concat=function(a){return this.copy().Tc(a)};K.prototype.insertAt=K.prototype.ae=function(a,b){v&&(this.qh(b),D.p(a,K,"insertAt:i"));0>a&&D.ua(a,">= 0",K,"insertAt:i");this.J&&D.qa(this,a);var c=this.o;a>=c.length?c.push(b):c.splice(a,0,b);this.Sc();return!0};
K.prototype.remove=K.prototype["delete"]=K.prototype.remove=function(a){if(null===a)return!1;v&&this.qh(a);this.J&&D.qa(this,a);var b=this.o;a=b.indexOf(a);if(-1===a)return!1;a===b.length-1?b.pop():b.splice(a,1);this.Sc();return!0};K.prototype.removeAt=K.prototype.od=function(a){v&&D.p(a,K,"removeAt:i");var b=this.o;(0>a||a>=b.length)&&D.ua(a,"0 <= i < length",K,"removeAt:i");this.J&&D.qa(this,a);a===b.length-1?b.pop():b.splice(a,1);this.Sc()};
K.prototype.removeRange=K.prototype.removeRange=function(a,b){v&&(D.p(a,K,"removeRange:from"),D.p(b,K,"removeRange:to"));var c=this.o,d=c.length;if(0>a)a=0;else if(a>=d)return this;if(0>b)return this;b>=d&&(b=d-1);if(a>b)return this;this.J&&D.qa(this);for(var e=a,g=b+1;g<d;)c[e++]=c[g++];c.length=d-(b-a+1);this.Sc();return this};K.prototype.copy=function(){var a=new K(this.ca),b=this.o;0<b.length&&(a.o=Array.prototype.slice.call(b));return a};
K.prototype.toArray=K.prototype.oc=function(){for(var a=this.o,b=this.count,c=Array(b),d=0;d<b;d++)c[d]=a[d];return c};K.prototype.toSet=K.prototype.jH=function(){for(var a=new L(this.ca),b=this.o,c=this.count,d=0;d<c;d++)a.add(b[d]);return a};K.prototype.sort=K.prototype.sort=function(a){v&&D.h(a,"function",K,"sort:sortfunc");this.J&&D.qa(this);this.o.sort(a);this.Sc();return this};
K.prototype.sortRange=K.prototype.Rq=function(a,b,c){var d=this.o,e=d.length;void 0===b&&(b=0);void 0===c&&(c=e);v&&(D.h(a,"function",K,"sortRange:sortfunc"),D.p(b,K,"sortRange:from"),D.p(c,K,"sortRange:to"));this.J&&D.qa(this);var g=c-b;if(1>=g)return this;(0>b||b>=e-1)&&D.ua(b,"0 <= from < length",K,"sortRange:from");if(2===g)return c=d[b],e=d[b+1],0<a(c,e)&&(d[b]=e,d[b+1]=c,this.Sc()),this;if(0===b)if(c>=e)d.sort(a);else for(g=d.slice(0,c),g.sort(a),a=0;a<c;a++)d[a]=g[a];else if(c>=e)for(g=d.slice(b),
g.sort(a),a=b;a<e;a++)d[a]=g[a-b];else for(g=d.slice(b,c),g.sort(a),a=b;a<c;a++)d[a]=g[a-b];this.Sc();return this};K.prototype.reverse=K.prototype.reverse=function(){this.J&&D.qa(this);this.o.reverse();this.Sc();return this};D.w(K,{count:"count"},function(){return this.o.length});D.w(K,{size:"size"},function(){return this.o.length});D.w(K,{length:"length"},function(){return this.o.length});D.w(K,{j:"iterator"},function(){if(0>=this.o.length)return Ka;var a=this.$b;return null!==a?(a.reset(),a):new Qa(this)});
D.w(K,{ao:"iteratorBackwards"},function(){if(0>=this.o.length)return Ka;var a=this.dj;return null!==a?(a.reset(),a):new Ua(this)});function Va(a){this.ri=a;a.$b=null;this.Za=a.I;this.bb=null}D.ne(Va,{key:!0,value:!0});D.w(Va,{j:"iterator"},function(){return this});Va.prototype.reset=Va.prototype.reset=function(){var a=this.ri;a.$b=null;this.Za=a.I;this.bb=null};
Va.prototype.next=Va.prototype.next=function(){var a=this.ri;if(a.I!==this.Za){if(null===this.key)return!1;D.Ua(a)}var b=this.bb,b=null===b?a.Ma:b.fb;if(null!==b)return this.bb=b,this.value=b.value,this.key=b.key,!0;this.Uf();return!1};Va.prototype.hasNext=function(){return this.next()};Va.prototype.first=Va.prototype.first=function(){var a=this.ri;this.Za=a.I;a=a.Ma;if(null!==a){this.bb=a;var b=a.value;this.key=a.key;return this.value=b}return null};
Va.prototype.any=function(a){var b=this.ri;b.$b=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;){if(a(d.value))return!0;b.I!==c&&D.Ua(b);d=d.fb}return!1};Va.prototype.all=function(a){var b=this.ri;b.$b=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;){if(!a(d.value))return!1;b.I!==c&&D.Ua(b);d=d.fb}return!0};Va.prototype.each=function(a){var b=this.ri;b.$b=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;)a(d.value),b.I!==c&&D.Ua(b),d=d.fb;return this};
Va.prototype.map=function(a){var b=this.ri;b.$b=null;for(var c=new K,d=b.I,e=b.Ma;null!==e;)c.add(a(e.value)),b.I!==d&&D.Ua(b),e=e.fb;return c.j};Va.prototype.filter=function(a){var b=this.ri;b.$b=null;for(var c=new K(b.ca),d=b.I,e=b.Ma;null!==e;){var g=e.value;a(g)&&c.add(g);b.I!==d&&D.Ua(b);e=e.fb}return c.j};Va.prototype.concat=function(a){this.ri.$b=null;return new Na(this,a.j)};D.w(Va,{count:"count"},function(){return this.ri.Ad});
Va.prototype.Uf=function(){this.value=this.key=null;this.Za=-1;this.ri.$b=this};Va.prototype.toString=function(){return null!==this.bb?"SetIterator@"+this.bb.value:"SetIterator"};
function L(a){D.vc(this);this.J=!1;void 0===a||null===a?this.ca=null:"string"===typeof a?"object"===a||"string"===a||"number"===a?this.ca=a:D.ua(a,"the string 'object', 'number' or 'string'","Set constructor: type"):"function"===typeof a?this.ca=a===Object?"object":a===String?"string":a===Number?"number":a:D.ua(a,"null, a primitive type name, or a class type","Set constructor: type");this.Bd={};this.Ad=0;this.$b=null;this.I=0;this.ii=this.Ma=null}D.ka("Set",L);
L.prototype.qh=function(a){null!==this.ca&&("string"===typeof this.ca?typeof a===this.ca&&null!==a||D.jc(a,this.ca):a instanceof this.ca||D.jc(a,this.ca))};L.prototype.Sc=function(){var a=this.I;a++;999999999<a&&(a=0);this.I=a};L.prototype.freeze=L.prototype.freeze=function(){this.J=!0;return this};L.prototype.thaw=L.prototype.Xa=function(){this.J=!1;return this};L.prototype.toString=function(){return"Set("+D.getTypeName(this.ca)+")#"+D.Jd(this)};
L.prototype.add=L.prototype.add=function(a){if(null===a)return!1;v&&this.qh(a);this.J&&D.qa(this,a);var b=a;D.Qa(a)&&(b=D.pq(a));return void 0===this.Bd[b]?(this.Ad++,a=new Wa(a,a),this.Bd[b]=a,b=this.ii,null===b?this.Ma=a:(a.Dp=b,b.fb=a),this.ii=a,this.Sc(),!0):!1};L.prototype.addAll=L.prototype.Tc=function(a){if(null===a)return this;this.J&&D.qa(this);if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++)this.add(D.La(a,c));else for(a=a.j;a.next();)this.add(a.value);return this};
L.prototype.contains=L.prototype.contains=function(a){if(null===a)return!1;v&&this.qh(a);var b=a;return D.Qa(a)&&(b=D.Jd(a),void 0===b)?!1:void 0!==this.Bd[b]};L.prototype.has=L.prototype.has=function(a){return this.contains(a)};L.prototype.containsAll=function(a){if(null===a)return!0;for(a=a.j;a.next();)if(!this.contains(a.value))return!1;return!0};L.prototype.containsAny=function(a){if(null===a)return!0;for(a=a.j;a.next();)if(this.contains(a.value))return!0;return!1};
L.prototype.first=L.prototype.first=function(){var a=this.Ma;return null===a?null:a.value};L.prototype.any=function(a){for(var b=this.I,c=this.Ma;null!==c;){if(a(c.value))return!0;this.I!==b&&D.Ua(this);c=c.fb}return!1};L.prototype.all=function(a){for(var b=this.I,c=this.Ma;null!==c;){if(!a(c.value))return!1;this.I!==b&&D.Ua(this);c=c.fb}return!0};L.prototype.each=function(a){for(var b=this.I,c=this.Ma;null!==c;)a(c.value),this.I!==b&&D.Ua(this),c=c.fb;return this};
L.prototype.map=function(a){for(var b=new L,c=this.I,d=this.Ma;null!==d;)b.add(a(d.value)),this.I!==c&&D.Ua(this),d=d.fb;return b};L.prototype.filter=function(a){for(var b=new L(this.ca),c=this.I,d=this.Ma;null!==d;){var e=d.value;a(e)&&b.add(e);this.I!==c&&D.Ua(this);d=d.fb}return b};L.prototype.concat=function(a){return this.copy().Tc(a)};
L.prototype.remove=L.prototype["delete"]=L.prototype.remove=function(a){if(null===a)return!1;v&&this.qh(a);this.J&&D.qa(this,a);var b=a;if(D.Qa(a)&&(b=D.Jd(a),void 0===b))return!1;a=this.Bd[b];if(void 0===a)return!1;var c=a.fb,d=a.Dp;null!==c&&(c.Dp=d);null!==d&&(d.fb=c);this.Ma===a&&(this.Ma=c);this.ii===a&&(this.ii=d);delete this.Bd[b];this.Ad--;this.Sc();return!0};
L.prototype.removeAll=L.prototype.Ay=function(a){if(null===a)return this;this.J&&D.qa(this);if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++)this.remove(D.La(a,c));else for(a=a.j;a.next();)this.remove(a.value);return this};L.prototype.retainAll=function(a){if(null===a||0===this.count)return this;this.J&&D.qa(this);var b=new L(this.ca);b.Tc(a);a=[];for(var c=this.j;c.next();){var d=c.value;b.contains(d)||a.push(d)}this.Ay(a);return this};
L.prototype.clear=L.prototype.clear=function(){this.J&&D.qa(this);this.Bd={};this.Ad=0;this.ii=this.Ma=null;this.Sc()};L.prototype.copy=function(){var a=new L(this.ca),b=this.Bd,c;for(c in b)a.add(b[c].value);return a};L.prototype.toArray=L.prototype.oc=function(){var a=Array(this.Ad),b=this.Bd,c=0,d;for(d in b)a[c]=b[d].value,c++;return a};L.prototype.toList=function(){var a=new K(this.ca),b=this.Bd,c;for(c in b)a.add(b[c].value);return a};D.w(L,{count:"count"},function(){return this.Ad});
D.w(L,{size:"size"},function(){return this.Ad});D.w(L,{j:"iterator"},function(){if(0>=this.Ad)return Ka;var a=this.$b;return null!==a?(a.reset(),a):new Va(this)});function Xa(a){this.Na=a;this.Za=a.I;this.bb=null}D.ne(Xa,{key:!0,value:!0});D.w(Xa,{j:"iterator"},function(){return this});Xa.prototype.reset=Xa.prototype.reset=function(){this.Za=this.Na.I;this.bb=null};
Xa.prototype.next=Xa.prototype.next=function(){var a=this.Na;if(a.I!==this.Za){if(null===this.key)return!1;D.Ua(a)}var b=this.bb,b=null===b?a.Ma:b.fb;if(null!==b)return this.bb=b,this.value=this.key=a=b.key,!0;this.Uf();return!1};Xa.prototype.hasNext=function(){return this.next()};Xa.prototype.first=Xa.prototype.first=function(){var a=this.Na;this.Za=a.I;a=a.Ma;return null!==a?(this.bb=a,this.value=this.key=a=a.key):null};
Xa.prototype.any=function(a){var b=this.Na,c=b.I;this.bb=null;for(var d=b.Ma;null!==d;){if(a(d.key))return!0;b.I!==c&&D.Ua(b);d=d.fb}return!1};Xa.prototype.all=function(a){var b=this.Na,c=b.I;this.bb=null;for(var d=b.Ma;null!==d;){if(!a(d.key))return!1;b.I!==c&&D.Ua(b);d=d.fb}return!0};Xa.prototype.each=function(a){var b=this.Na,c=b.I;this.bb=null;for(var d=b.Ma;null!==d;)a(d.key),b.I!==c&&D.Ua(b),d=d.fb;return this};
Xa.prototype.map=function(a){var b=this.Na,c=b.I;this.bb=null;for(var d=new K,e=b.Ma;null!==e;)d.add(a(e.key)),b.I!==c&&D.Ua(b),e=e.fb;return d.j};Xa.prototype.filter=function(a){var b=this.Na,c=b.I;this.bb=null;for(var d=new K(b.df),e=b.Ma;null!==e;){var g=e.key;a(g)&&d.add(g);b.I!==c&&D.Ua(b);e=e.fb}return d.j};Xa.prototype.concat=function(a){return new Na(this,a.j)};D.w(Xa,{count:"count"},function(){return this.Na.Ad});Xa.prototype.Uf=function(){this.value=this.key=null;this.Za=-1};
Xa.prototype.toString=function(){return null!==this.bb?"MapKeySetIterator@"+this.bb.value:"MapKeySetIterator"};function Ya(a){D.vc(this);this.J=!0;this.Na=a}D.Sa(Ya,L);Ya.prototype.freeze=function(){return this};Ya.prototype.Xa=function(){return this};Ya.prototype.toString=function(){return"MapKeySet("+this.Na.toString()+")"};Ya.prototype.add=Ya.prototype.add=function(){D.k("This Set is read-only: "+this.toString());return!1};
Ya.prototype.set=Ya.prototype.set=function(){D.k("This Set is read-only: "+this.toString());return!1};Ya.prototype.contains=Ya.prototype.contains=function(a){return this.Na.contains(a)};Ya.prototype.has=Ya.prototype.has=function(a){return this.contains(a)};Ya.prototype.remove=Ya.prototype["delete"]=Ya.prototype.remove=function(){D.k("This Set is read-only: "+this.toString());return!1};Ya.prototype.clear=Ya.prototype.clear=function(){D.k("This Set is read-only: "+this.toString())};
Ya.prototype.first=Ya.prototype.first=function(){var a=this.Na.Ma;return null!==a?a.key:null};Ya.prototype.any=function(a){for(var b=this.Na.Ma;null!==b;){if(a(b.key))return!0;b=b.fb}return!1};Ya.prototype.all=function(a){for(var b=this.Na.Ma;null!==b;){if(!a(b.key))return!1;b=b.fb}return!0};Ya.prototype.each=function(a){for(var b=this.Na.Ma;null!==b;)a(b.key),b=b.fb;return this};Ya.prototype.map=function(a){for(var b=new L,c=this.Na.Ma;null!==c;)b.add(a(c.key)),c=c.fb;return b};
Ya.prototype.filter=function(a){for(var b=new L(this.Na.df),c=this.Na.Ma;null!==c;){var d=c.key;a(d)&&b.add(d);c=c.fb}return b};Ya.prototype.concat=function(a){return this.jH().Tc(a)};Ya.prototype.copy=function(){return new Ya(this.Na)};Ya.prototype.toSet=Ya.prototype.jH=function(){var a=new L(this.Na.df),b=this.Na.Bd,c;for(c in b)a.add(b[c].key);return a};Ya.prototype.toArray=Ya.prototype.oc=function(){var a=this.Na.Bd,b=Array(this.Na.Ad),c=0,d;for(d in a)b[c]=a[d].key,c++;return b};
Ya.prototype.toList=function(){var a=new K(this.Na.df),b=this.Na.Bd,c;for(c in b)a.add(b[c].key);return a};D.w(Ya,{count:"count"},function(){return this.Na.Ad});D.w(Ya,{size:"size"},function(){return this.Na.Ad});D.w(Ya,{j:"iterator"},function(){return 0>=this.Na.Ad?Ka:new Xa(this.Na)});function Za(a){this.Na=a;a.ej=null;this.Za=a.I;this.bb=null}D.ne(Za,{key:!0,value:!0});D.w(Za,{j:"iterator"},function(){return this});
Za.prototype.reset=Za.prototype.reset=function(){var a=this.Na;a.ej=null;this.Za=a.I;this.bb=null};Za.prototype.next=Za.prototype.next=function(){var a=this.Na;if(a.I!==this.Za){if(null===this.key)return!1;D.Ua(a)}var b=this.bb,b=null===b?a.Ma:b.fb;if(null!==b)return this.bb=b,this.value=b.value,this.key=b.key,!0;this.Uf();return!1};Za.prototype.hasNext=function(){return this.next()};
Za.prototype.first=Za.prototype.first=function(){var a=this.Na;this.Za=a.I;a=a.Ma;if(null!==a){this.bb=a;var b=a.value;this.key=a.key;return this.value=b}return null};Za.prototype.any=function(a){var b=this.Na;b.ej=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;){if(a(d.value))return!0;b.I!==c&&D.Ua(b);d=d.fb}return!1};Za.prototype.all=function(a){var b=this.Na;b.ej=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;){if(!a(d.value))return!1;b.I!==c&&D.Ua(b);d=d.fb}return!0};
Za.prototype.each=function(a){var b=this.Na;b.ej=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;)a(d.value),b.I!==c&&D.Ua(b),d=d.fb;return this};Za.prototype.map=function(a){var b=this.Na;b.ej=null;var c=b.I;this.bb=null;for(var d=new K,e=b.Ma;null!==e;)d.add(a(e.value)),b.I!==c&&D.Ua(b),e=e.fb;return d.j};Za.prototype.filter=function(a){var b=this.Na;b.ej=null;var c=b.I;this.bb=null;for(var d=new K(b.df),e=b.Ma;null!==e;){var g=e.value;a(g)&&d.add(g);b.I!==c&&D.Ua(b);e=e.fb}return d.j};
Za.prototype.concat=function(a){this.Na.ej=null;return new Na(this,a.j)};D.w(Za,{count:"count"},function(){return this.Na.Ad});Za.prototype.Uf=function(){this.value=this.key=null;this.Za=-1;this.Na.ej=this};Za.prototype.toString=function(){return null!==this.bb?"MapValueSetIterator@"+this.bb.value:"MapValueSetIterator"};function Wa(a,b){this.key=a;this.value=b;this.Dp=this.fb=null}D.ne(Wa,{key:!0,value:!0});Wa.prototype.toString=function(){return"{"+this.key+":"+this.value+"}"};
function $a(a){this.Na=a;a.$b=null;this.Za=a.I;this.bb=null}D.ne($a,{key:!0,value:!0});D.w($a,{j:"iterator"},function(){return this});$a.prototype.reset=$a.prototype.reset=function(){var a=this.Na;a.$b=null;this.Za=a.I;this.bb=null};$a.prototype.next=$a.prototype.next=function(){var a=this.Na;if(a.I!==this.Za){if(null===this.key)return!1;D.Ua(a)}var b=this.bb,b=null===b?a.Ma:b.fb;if(null!==b)return this.bb=b,this.key=b.key,this.value=b.value,!0;this.Uf();return!1};$a.prototype.hasNext=function(){return this.next()};
$a.prototype.first=$a.prototype.first=function(){var a=this.Na;this.Za=a.I;a=a.Ma;return null!==a?(this.bb=a,this.key=a.key,this.value=a.value,a):null};$a.prototype.any=function(a){var b=this.Na;b.$b=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;){if(a(d))return!0;b.I!==c&&D.Ua(b);d=d.fb}return!1};$a.prototype.all=function(a){var b=this.Na;b.$b=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;){if(!a(d))return!1;b.I!==c&&D.Ua(b);d=d.fb}return!0};
$a.prototype.each=function(a){var b=this.Na;b.$b=null;var c=b.I;this.bb=null;for(var d=b.Ma;null!==d;)a(d),b.I!==c&&D.Ua(b),d=d.fb;return this};$a.prototype.map=function(a){var b=this.Na;b.$b=null;var c=b.I;this.bb=null;for(var d=new K,e=b.Ma;null!==e;)d.add(a(e)),b.I!==c&&D.Ua(b),e=e.fb;return d.j};$a.prototype.filter=function(a){var b=this.Na;b.$b=null;var c=b.I;this.bb=null;for(var d=new K,e=b.Ma;null!==e;)a(e)&&d.add(e),b.I!==c&&D.Ua(b),e=e.fb;return d.j};
$a.prototype.concat=function(a){this.Na.$b=null;return new Na(this,a.j)};D.w($a,{count:"count"},function(){return this.Na.Ad});$a.prototype.Uf=function(){this.value=this.key=null;this.Za=-1;this.Na.$b=this};$a.prototype.toString=function(){return null!==this.bb?"MapIterator@"+this.bb:"MapIterator"};
function oa(a,b){D.vc(this);this.J=!1;void 0===a||null===a?this.df=null:"string"===typeof a?"object"===a||"string"===a||"number"===a?this.df=a:D.ua(a,"the string 'object', 'number' or 'string'","Map constructor: keytype"):"function"===typeof a?this.df=a===Object?"object":a===String?"string":a===Number?"number":a:D.ua(a,"null, a primitive type name, or a class type","Map constructor: keytype");void 0===b||null===b?this.Bi=null:"string"===typeof b?"object"===b||"string"===b||"boolean"===b||"number"===
b||"function"===b?this.Bi=b:D.ua(b,"the string 'object', 'number', 'string', 'boolean', or 'function'","Map constructor: valtype"):"function"===typeof b?this.Bi=b===Object?"object":b===String?"string":b===Number?"number":b===Boolean?"boolean":b===Function?"function":b:D.ua(b,"null, a primitive type name, or a class type","Map constructor: valtype");this.Bd={};this.Ad=0;this.ej=this.$b=null;this.I=0;this.ii=this.Ma=null}D.ka("Map",oa);
function bb(a,b){null!==a.df&&("string"===typeof a.df?typeof b===a.df&&null!==b||D.jc(b,a.df):b instanceof a.df||D.jc(b,a.df))}oa.prototype.Sc=function(){var a=this.I;a++;999999999<a&&(a=0);this.I=a};oa.prototype.freeze=oa.prototype.freeze=function(){this.J=!0;return this};oa.prototype.thaw=oa.prototype.Xa=function(){this.J=!1;return this};oa.prototype.toString=function(){return"Map("+D.getTypeName(this.df)+","+D.getTypeName(this.Bi)+")#"+D.Jd(this)};
oa.prototype.add=oa.prototype.add=function(a,b){v&&(bb(this,a),null!==this.Bi&&("string"===typeof this.Bi?typeof b===this.Bi&&null!==b||D.jc(b,this.Bi):b instanceof this.Bi||D.jc(b,this.Bi)));this.J&&D.qa(this,a);var c=a;D.Qa(a)&&(c=D.pq(a));var d=this.Bd[c];if(void 0===d)return this.Ad++,d=new Wa(a,b),this.Bd[c]=d,c=this.ii,null===c?this.Ma=d:(d.Dp=c,c.fb=d),this.ii=d,this.Sc(),!0;d.value=b;return!1};oa.prototype.set=oa.prototype.set=function(a,b){return this.add(a,b)};
oa.prototype.addAll=oa.prototype.Tc=function(a){if(null===a)return this;if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++){var d=D.La(a,c);this.add(d.key,d.value)}else for(a=a.j;a.next();)this.add(a.key,a.value);return this};oa.prototype.first=oa.prototype.first=function(){return this.Ma};oa.prototype.any=function(a){for(var b=this.I,c=this.Ma;null!==c;){if(a(c))return!0;this.I!==b&&D.Ua(this);c=c.fb}return!1};
oa.prototype.all=function(a){for(var b=this.I,c=this.Ma;null!==c;){if(!a(c))return!1;this.I!==b&&D.Ua(this);c=c.fb}return!0};oa.prototype.each=function(a){for(var b=this.I,c=this.Ma;null!==c;)a(c),this.I!==b&&D.Ua(this),c=c.fb;return this};oa.prototype.map=function(a){for(var b=new oa(this.df),c=this.I,d=this.Ma;null!==d;)b.add(d.key,a(d)),this.I!==c&&D.Ua(this),d=d.fb;return b};
oa.prototype.filter=function(a){for(var b=new oa(this.df,this.Bi),c=this.I,d=this.Ma;null!==d;)a(d)&&b.add(d.key,d.value),this.I!==c&&D.Ua(this),d=d.fb;return b};oa.prototype.concat=function(a){return this.copy().Tc(a)};oa.prototype.contains=oa.prototype.contains=function(a){v&&bb(this,a);var b=a;return D.Qa(a)&&(b=D.Jd(a),void 0===b)?!1:void 0!==this.Bd[b]};oa.prototype.has=oa.prototype.has=function(a){return this.contains(a)};
oa.prototype.getValue=oa.prototype.oa=function(a){v&&bb(this,a);var b=a;if(D.Qa(a)&&(b=D.Jd(a),void 0===b))return null;a=this.Bd[b];return void 0===a?null:a.value};oa.prototype.get=oa.prototype.get=function(a){return this.oa(a)};
oa.prototype.remove=oa.prototype["delete"]=oa.prototype.remove=function(a){if(null===a)return!1;v&&bb(this,a);this.J&&D.qa(this,a);var b=a;if(D.Qa(a)&&(b=D.Jd(a),void 0===b))return!1;a=this.Bd[b];if(void 0===a)return!1;var c=a.fb,d=a.Dp;null!==c&&(c.Dp=d);null!==d&&(d.fb=c);this.Ma===a&&(this.Ma=c);this.ii===a&&(this.ii=d);delete this.Bd[b];this.Ad--;this.Sc();return!0};oa.prototype.clear=oa.prototype.clear=function(){this.J&&D.qa(this);this.Bd={};this.Ad=0;this.ii=this.Ma=null;this.Sc()};
oa.prototype.copy=function(){var a=new oa(this.df,this.Bi),b=this.Bd,c;for(c in b){var d=b[c];a.add(d.key,d.value)}return a};oa.prototype.toArray=oa.prototype.oc=function(){var a=this.Bd,b=Array(this.Ad),c=0,d;for(d in a){var e=a[d];b[c]=new Wa(e.key,e.value);c++}return b};oa.prototype.toKeySet=oa.prototype.Zj=function(){return new Ya(this)};D.w(oa,{count:"count"},function(){return this.Ad});D.w(oa,{size:"size"},function(){return this.Ad});
D.w(oa,{j:"iterator"},function(){if(0>=this.count)return Ka;var a=this.$b;return null!==a?(a.reset(),a):new $a(this)});D.w(oa,{gG:"iteratorKeys"},function(){return 0>=this.count?Ka:new Xa(this)});D.w(oa,{hG:"iteratorValues"},function(){if(0>=this.count)return Ka;var a=this.ej;return null!==a?(a.reset(),a):new Za(this)});function N(a,b){void 0===a?this.M=this.L=0:"number"===typeof a&&"number"===typeof b?(this.L=a,this.M=b):D.k("Invalid arguments to Point constructor: "+a+", "+b);this.J=!1}
D.ka("Point",N);D.Di(N);D.ne(N,{x:!0,y:!0});N.prototype.assign=function(a){this.L=a.L;this.M=a.M};N.prototype.setTo=N.prototype.n=function(a,b){v&&(D.h(a,"number",N,"setTo:x"),D.h(b,"number",N,"setTo:y"));this.L=a;this.M=b;return this};N.prototype.set=N.prototype.set=function(a){v&&D.l(a,N,N,"set:p");this.Ra();this.L=a.L;this.M=a.M;return this};N.prototype.copy=function(){var a=new N;a.L=this.L;a.M=this.M;return a};f=N.prototype;f.Oa=function(){this.J=!0;Object.freeze(this);return this};
f.V=function(){return Object.isFrozen(this)?this:this.copy().freeze()};f.freeze=function(){this.J=!0;return this};f.Xa=function(){Object.isFrozen(this)&&D.k("cannot thaw constant: "+this);this.J=!1;return this};f.Ra=function(a){if(this.J){var b="The Point is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);D.k(b)}};
N.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));return new N(c,e)}return new N};N.stringify=function(a){v&&D.l(a,N);return a.x.toString()+" "+a.y.toString()};N.prototype.toString=function(){return"Point("+this.x+","+this.y+")"};N.prototype.equals=N.prototype.O=function(a){return a instanceof N?this.L===a.x&&this.M===a.y:!1};
N.prototype.equalTo=N.prototype.Jx=function(a,b){return this.L===a&&this.M===b};N.prototype.equalsApprox=N.prototype.Uc=function(a){return Q(this.L,a.x)&&Q(this.M,a.y)};N.prototype.add=N.prototype.add=function(a){v&&D.l(a,N,N,"add:p");this.Ra();this.L+=a.x;this.M+=a.y;return this};N.prototype.subtract=N.prototype.Wj=function(a){v&&D.l(a,N,N,"subtract:p");this.Ra();this.L-=a.x;this.M-=a.y;return this};
N.prototype.offset=N.prototype.offset=function(a,b){v&&(D.p(a,N,"offset:dx"),D.p(b,N,"offset:dy"));this.Ra();this.L+=a;this.M+=b;return this};N.prototype.rotate=N.prototype.rotate=function(a){v&&D.p(a,N,"rotate:angle");this.Ra();if(0===a)return this;var b=this.L,c=this.M;if(0===b&&0===c)return this;var d=0,e=0;360<=a?a-=360:0>a&&(a+=360);90===a?(d=0,e=1):180===a?(d=-1,e=0):270===a?(d=0,e=-1):(a=a*Math.PI/180,d=Math.cos(a),e=Math.sin(a));this.L=d*b-e*c;this.M=e*b+d*c;return this};
N.prototype.scale=N.prototype.scale=function(a,b){v&&(D.p(a,N,"scale:sx"),D.p(b,N,"scale:sy"));this.L*=a;this.M*=b;return this};N.prototype.distanceSquaredPoint=N.prototype.Jf=function(a){v&&D.l(a,N,N,"distanceSquaredPoint:p");var b=a.x-this.L;a=a.y-this.M;return b*b+a*a};N.prototype.distanceSquared=N.prototype.jq=function(a,b){v&&(D.p(a,N,"distanceSquared:px"),D.p(b,N,"distanceSquared:py"));var c=a-this.L,d=b-this.M;return c*c+d*d};
N.prototype.normalize=N.prototype.normalize=function(){this.Ra();var a=this.L,b=this.M,c=Math.sqrt(a*a+b*b);0<c&&(this.L=a/c,this.M=b/c);return this};N.prototype.directionPoint=N.prototype.Xb=function(a){v&&D.l(a,N,N,"directionPoint:p");return cb(a.x-this.L,a.y-this.M)};N.prototype.direction=N.prototype.direction=function(a,b){v&&(D.p(a,N,"direction:px"),D.p(b,N,"direction:py"));return cb(a-this.L,b-this.M)};
function cb(a,b){if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;var c=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?c=0>b?c+180:180-c:0>b&&(c=360-c);return c}N.prototype.projectOntoLineSegment=function(a,b,c,d){v&&(D.p(a,N,"projectOntoLineSegment:px"),D.p(b,N,"projectOntoLineSegment:py"),D.p(c,N,"projectOntoLineSegment:qx"),D.p(d,N,"projectOntoLineSegment:qy"));fb(a,b,c,d,this.L,this.M,this);return this};
N.prototype.projectOntoLineSegmentPoint=function(a,b){v&&(D.l(a,N,N,"projectOntoLineSegmentPoint:p"),D.l(b,N,N,"projectOntoLineSegmentPoint:q"));fb(a.x,a.y,b.x,b.y,this.L,this.M,this);return this};N.prototype.snapToGrid=function(a,b,c,d){v&&(D.p(a,N,"snapToGrid:originx"),D.p(b,N,"snapToGrid:originy"),D.p(c,N,"snapToGrid:cellwidth"),D.p(d,N,"snapToGrid:cellheight"));jb(this.L,this.M,a,b,c,d,this);return this};
N.prototype.snapToGridPoint=function(a,b){v&&(D.l(a,N,N,"snapToGridPoint:p"),D.l(b,Ca,N,"snapToGridPoint:q"));jb(this.L,this.M,a.x,a.y,b.width,b.height,this);return this};N.prototype.setRectSpot=N.prototype.uo=function(a,b){v&&(D.l(a,C,N,"setRectSpot:r"),D.l(b,S,N,"setRectSpot:spot"));this.Ra();this.L=a.x+b.x*a.width+b.offsetX;this.M=a.y+b.y*a.height+b.offsetY;return this};
N.prototype.setSpot=N.prototype.fv=function(a,b,c,d,e){v&&(D.p(a,N,"setSpot:x"),D.p(b,N,"setSpot:y"),D.p(c,N,"setSpot:w"),D.p(d,N,"setSpot:h"),(0>c||0>d)&&D.k("Point.setSpot:Width and height cannot be negative"),D.l(e,S,N,"setSpot:spot"));this.Ra();this.L=a+e.x*c+e.offsetX;this.M=b+e.y*d+e.offsetY;return this};N.prototype.transform=function(a){v&&D.l(a,Ea,N,"transform:t");a.xb(this);return this};function kb(a,b){v&&D.l(b,Ea,N,"transformInverted:t");b.Fi(a);return a}var lb;
N.distanceLineSegmentSquared=lb=function(a,b,c,d,e,g){v&&(D.p(a,N,"distanceLineSegmentSquared:px"),D.p(b,N,"distanceLineSegmentSquared:py"),D.p(c,N,"distanceLineSegmentSquared:ax"),D.p(d,N,"distanceLineSegmentSquared:ay"),D.p(e,N,"distanceLineSegmentSquared:bx"),D.p(g,N,"distanceLineSegmentSquared:by"));var h=e-c,k=g-d,l=h*h+k*k;c-=a;d-=b;var m=-c*h-d*k;if(0>=m||m>=l)return h=e-a,k=g-b,Math.min(c*c+d*d,h*h+k*k);a=h*d-k*c;return a*a/l};var ob;
N.distanceSquared=ob=function(a,b,c,d){v&&(D.p(a,N,"distanceSquared:px"),D.p(b,N,"distanceSquared:py"),D.p(c,N,"distanceSquared:qx"),D.p(d,N,"distanceSquared:qy"));a=c-a;b=d-b;return a*a+b*b};var Bb;
N.direction=Bb=function(a,b,c,d){v&&(D.p(a,N,"direction:px"),D.p(b,N,"direction:py"),D.p(c,N,"direction:qx"),D.p(d,N,"direction:qy"));a=c-a;b=d-b;if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;d=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?d=0>b?d+180:180-d:0>b&&(d=360-d);return d};D.defineProperty(N,{x:"x"},function(){return this.L},function(a){this.Ra(a);v&&D.h(a,"number",N,"x");this.L=a});
D.defineProperty(N,{y:"y"},function(){return this.M},function(a){this.Ra(a);v&&D.h(a,"number",N,"y");this.M=a});N.prototype.isReal=N.prototype.F=function(){return isFinite(this.x)&&isFinite(this.y)};function Ca(a,b){void 0===a?this.Ha=this.Ia=0:"number"===typeof a&&(0<=a||isNaN(a))&&"number"===typeof b&&(0<=b||isNaN(b))?(this.Ia=a,this.Ha=b):D.k("Invalid arguments to Size constructor: "+a+", "+b);this.J=!1}D.ka("Size",Ca);D.Di(Ca);D.ne(Ca,{width:!0,height:!0});
Ca.prototype.assign=function(a){this.Ia=a.Ia;this.Ha=a.Ha};Ca.prototype.setTo=Ca.prototype.n=function(a,b){v&&(D.h(a,"number",Ca,"setTo:w"),D.h(b,"number",Ca,"setTo:h"),0>a&&D.ua(a,">= 0",Ca,"setTo:w"),0>b&&D.ua(b,">= 0",Ca,"setTo:h"));this.Ia=a;this.Ha=b;return this};Ca.prototype.set=Ca.prototype.set=function(a){v&&D.l(a,Ca,Ca,"set:s");this.Ra();this.Ia=a.Ia;this.Ha=a.Ha;return this};Ca.prototype.copy=function(){var a=new Ca;a.Ia=this.Ia;a.Ha=this.Ha;return a};f=Ca.prototype;
f.Oa=function(){this.J=!0;Object.freeze(this);return this};f.V=function(){return Object.isFrozen(this)?this:this.copy().freeze()};f.freeze=function(){this.J=!0;return this};f.Xa=function(){Object.isFrozen(this)&&D.k("cannot thaw constant: "+this);this.J=!1;return this};f.Ra=function(a){if(this.J){var b="The Size is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);D.k(b)}};
Ca.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));return new Ca(c,e)}return new Ca};Ca.stringify=function(a){v&&D.l(a,Ca);return a.width.toString()+" "+a.height.toString()};Ca.prototype.toString=function(){return"Size("+this.width+","+this.height+")"};Ca.prototype.equals=Ca.prototype.O=function(a){return a instanceof Ca?this.Ia===a.width&&this.Ha===a.height:!1};
Ca.prototype.equalTo=Ca.prototype.Jx=function(a,b){return this.Ia===a&&this.Ha===b};Ca.prototype.equalsApprox=Ca.prototype.Uc=function(a){return Q(this.Ia,a.width)&&Q(this.Ha,a.height)};D.defineProperty(Ca,{width:"width"},function(){return this.Ia},function(a){this.Ra(a);v&&D.h(a,"number",Ca,"width");0>a&&D.ua(a,">= 0",Ca,"width");this.Ia=a});
D.defineProperty(Ca,{height:"height"},function(){return this.Ha},function(a){this.Ra(a);v&&D.h(a,"number",Ca,"height");0>a&&D.ua(a,">= 0",Ca,"height");this.Ha=a});Ca.prototype.isReal=Ca.prototype.F=function(){return isFinite(this.width)&&isFinite(this.height)};
function C(a,b,c,d){void 0===a?this.Ha=this.Ia=this.M=this.L=0:a instanceof N?b instanceof N?(this.L=Math.min(a.L,b.L),this.M=Math.min(a.M,b.M),this.Ia=Math.abs(a.L-b.L),this.Ha=Math.abs(a.M-b.M)):b instanceof Ca?(this.L=a.L,this.M=a.M,this.Ia=b.Ia,this.Ha=b.Ha):D.k("Incorrect arguments supplied to Rect constructor"):"number"===typeof a&&"number"===typeof b&&"number"===typeof c&&(0<=c||isNaN(c))&&"number"===typeof d&&(0<=d||isNaN(d))?(this.L=a,this.M=b,this.Ia=c,this.Ha=d):D.k("Invalid arguments to Rect constructor: "+
a+", "+b+", "+c+", "+d);this.J=!1}D.ka("Rect",C);D.Di(C);D.ne(C,{x:!0,y:!0,width:!0,height:!0});C.prototype.assign=function(a){this.L=a.L;this.M=a.M;this.Ia=a.Ia;this.Ha=a.Ha};function Cb(a,b,c){a.Ia=b;a.Ha=c}C.prototype.setTo=C.prototype.n=function(a,b,c,d){v&&(D.h(a,"number",C,"setTo:x"),D.h(b,"number",C,"setTo:y"),D.h(c,"number",C,"setTo:w"),D.h(d,"number",C,"setTo:h"),0>c&&D.ua(c,">= 0",C,"setTo:w"),0>d&&D.ua(d,">= 0",C,"setTo:h"));this.L=a;this.M=b;this.Ia=c;this.Ha=d;return this};
C.prototype.set=C.prototype.set=function(a){v&&D.l(a,C,C,"set:r");this.Ra();this.L=a.L;this.M=a.M;this.Ia=a.Ia;this.Ha=a.Ha;return this};C.prototype.setPoint=C.prototype.ih=function(a){v&&D.l(a,N,C,"setPoint:p");this.Ra();this.L=a.L;this.M=a.M;return this};C.prototype.setSize=function(a){v&&D.l(a,Ca,C,"setSize:s");this.Ra();this.Ia=a.Ia;this.Ha=a.Ha;return this};C.prototype.copy=function(){var a=new C;a.L=this.L;a.M=this.M;a.Ia=this.Ia;a.Ha=this.Ha;return a};f=C.prototype;
f.Oa=function(){this.J=!0;Object.freeze(this);return this};f.V=function(){return Object.isFrozen(this)?this:this.copy().freeze()};f.freeze=function(){this.J=!0;return this};f.Xa=function(){Object.isFrozen(this)&&D.k("cannot thaw constant: "+this);this.J=!1;return this};f.Ra=function(a){if(this.J){var b="The Rect is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);D.k(b)}};
C.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));for(var g=0;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));for(var h=0;""===a[b];)b++;(d=a[b++])&&(h=parseFloat(d));return new C(c,e,g,h)}return new C};C.stringify=function(a){v&&D.l(a,C);return a.x.toString()+" "+a.y.toString()+" "+a.width.toString()+" "+a.height.toString()};
C.prototype.toString=function(){return"Rect("+this.x+","+this.y+","+this.width+","+this.height+")"};C.prototype.equals=C.prototype.O=function(a){return a instanceof C?this.L===a.x&&this.M===a.y&&this.Ia===a.width&&this.Ha===a.height:!1};C.prototype.equalTo=C.prototype.Jx=function(a,b,c,d){return this.L===a&&this.M===b&&this.Ia===c&&this.Ha===d};C.prototype.equalsApprox=C.prototype.Uc=function(a){return Q(this.L,a.x)&&Q(this.M,a.y)&&Q(this.Ia,a.width)&&Q(this.Ha,a.height)};
function Db(a,b){return Eb(a.L,b.x)&&Eb(a.M,b.y)&&Eb(a.Ia,b.width)&&Eb(a.Ha,b.height)}C.prototype.containsPoint=C.prototype.Pa=function(a){v&&D.l(a,N,C,"containsPoint:p");return this.L<=a.x&&this.L+this.Ia>=a.x&&this.M<=a.y&&this.M+this.Ha>=a.y};C.prototype.containsRect=C.prototype.Pk=function(a){v&&D.l(a,C,C,"containsRect:r");return this.L<=a.x&&a.x+a.width<=this.L+this.Ia&&this.M<=a.y&&a.y+a.height<=this.M+this.Ha};
C.prototype.contains=C.prototype.contains=function(a,b,c,d){v?(D.p(a,C,"contains:x"),D.p(b,C,"contains:y"),void 0===c?c=0:D.p(c,C,"contains:w"),void 0===d?d=0:D.p(d,C,"contains:h"),(0>c||0>d)&&D.k("Rect.contains:Width and height cannot be negative")):(void 0===c&&(c=0),void 0===d&&(d=0));return this.L<=a&&a+c<=this.L+this.Ia&&this.M<=b&&b+d<=this.M+this.Ha};C.prototype.reset=function(){this.Ra();this.Ha=this.Ia=this.M=this.L=0};
C.prototype.offset=C.prototype.offset=function(a,b){v&&(D.p(a,C,"offset:dx"),D.p(b,C,"offset:dy"));this.Ra();this.L+=a;this.M+=b;return this};C.prototype.inflate=C.prototype.ig=function(a,b){v&&(D.p(a,C,"inflate:w"),D.p(b,C,"inflate:h"));return Gb(this,b,a,b,a)};C.prototype.addMargin=C.prototype.nx=function(a){v&&D.l(a,Lb,C,"addMargin:m");return Gb(this,a.top,a.right,a.bottom,a.left)};
C.prototype.subtractMargin=C.prototype.tK=function(a){v&&D.l(a,Lb,C,"subtractMargin:m");return Gb(this,-a.top,-a.right,-a.bottom,-a.left)};C.prototype.grow=function(a,b,c,d){v&&(D.p(a,C,"grow:t"),D.p(b,C,"grow:r"),D.p(c,C,"grow:b"),D.p(d,C,"grow:l"));return Gb(this,a,b,c,d)};function Gb(a,b,c,d,e){a.Ra();var g=a.Ia;c+e<=-g?(a.L+=g/2,a.Ia=0):(a.L-=e,a.Ia+=c+e);c=a.Ha;b+d<=-c?(a.M+=c/2,a.Ha=0):(a.M-=b,a.Ha+=b+d);return a}
C.prototype.intersectRect=function(a){v&&D.l(a,C,C,"intersectRect:r");return Nb(this,a.x,a.y,a.width,a.height)};C.prototype.intersect=C.prototype.RF=function(a,b,c,d){v&&(D.p(a,C,"intersect:x"),D.p(b,C,"intersect:y"),D.p(c,C,"intersect:w"),D.p(d,C,"intersect:h"),(0>c||0>d)&&D.k("Rect.intersect:Width and height cannot be negative"));return Nb(this,a,b,c,d)};
function Nb(a,b,c,d,e){a.Ra();var g=Math.max(a.L,b),h=Math.max(a.M,c);b=Math.min(a.L+a.Ia,b+d);c=Math.min(a.M+a.Ha,c+e);a.L=g;a.M=h;a.Ia=Math.max(0,b-g);a.Ha=Math.max(0,c-h);return a}C.prototype.intersectsRect=C.prototype.jg=function(a){v&&D.l(a,C,C,"intersectsRect:r");return this.SF(a.x,a.y,a.width,a.height)};
C.prototype.intersects=C.prototype.SF=function(a,b,c,d){v&&(D.p(a,C,"intersects:x"),D.p(b,C,"intersects:y"),D.p(a,C,"intersects:w"),D.p(b,C,"intersects:h"),(0>c||0>d)&&D.k("Rect.intersects:Width and height cannot be negative"));var e=this.Ia,g=this.L;if(Infinity!==e&&Infinity!==c&&(e+=g,c+=a,isNaN(c)||isNaN(e)||g>c||a>e))return!1;a=this.Ha;c=this.M;return Infinity!==a&&Infinity!==d&&(a+=c,d+=b,isNaN(d)||isNaN(a)||c>d||b>a)?!1:!0};
function Ob(a,b){var c=a.Ia,d=b.width+10+10,e=a.L,g=b.x-10;if(e>d+g||g>c+e)return!1;c=a.Ha;d=b.height+10+10;e=a.M;g=b.y-10;return e>d+g||g>c+e?!1:!0}C.prototype.unionPoint=C.prototype.Mi=function(a){v&&D.l(a,N,C,"unionPoint:p");return Ub(this,a.x,a.y,0,0)};C.prototype.unionRect=C.prototype.Zh=function(a){v&&D.l(a,C,C,"unionRect:r");return Ub(this,a.L,a.M,a.Ia,a.Ha)};
C.prototype.union=C.prototype.oH=function(a,b,c,d){this.Ra();v?(D.p(a,C,"union:x"),D.p(b,C,"union:y"),void 0===c?c=0:D.p(c,C,"union:w"),void 0===d?d=0:D.p(d,C,"union:h"),(0>c||0>d)&&D.k("Rect.union:Width and height cannot be negative")):(void 0===c&&(c=0),void 0===d&&(d=0));return Ub(this,a,b,c,d)};function Ub(a,b,c,d,e){var g=Math.min(a.L,b),h=Math.min(a.M,c);b=Math.max(a.L+a.Ia,b+d);c=Math.max(a.M+a.Ha,c+e);a.L=g;a.M=h;a.Ia=b-g;a.Ha=c-h;return a}
C.prototype.setSpot=C.prototype.fv=function(a,b,c){v&&(D.p(a,C,"setSpot:x"),D.p(b,C,"setSpot:y"),D.l(c,S,C,"setSpot:spot"));this.Ra();this.L=a-c.offsetX-c.x*this.Ia;this.M=b-c.offsetY-c.y*this.Ha;return this};var Vb;
C.contains=Vb=function(a,b,c,d,e,g,h,k){v?(D.p(a,C,"contains:rx"),D.p(b,C,"contains:ry"),D.p(c,C,"contains:rw"),D.p(d,C,"contains:rh"),D.p(e,C,"contains:x"),D.p(g,C,"contains:y"),void 0===h?h=0:D.p(h,C,"contains:w"),void 0===k?k=0:D.p(k,C,"contains:h"),(0>c||0>d||0>h||0>k)&&D.k("Rect.contains:Width and height cannot be negative")):(void 0===h&&(h=0),void 0===k&&(k=0));return a<=e&&e+h<=a+c&&b<=g&&g+k<=b+d};
C.intersects=function(a,b,c,d,e,g,h,k){v&&(D.p(a,C,"intersects:rx"),D.p(b,C,"intersects:ry"),D.p(c,C,"intersects:rw"),D.p(d,C,"intersects:rh"),D.p(e,C,"intersects:x"),D.p(g,C,"intersects:y"),D.p(h,C,"intersects:w"),D.p(k,C,"intersects:h"),(0>c||0>d||0>h||0>k)&&D.k("Rect.intersects:Width and height cannot be negative"));c+=a;h+=e;if(a>h||e>c)return!1;a=d+b;k+=g;return b>k||g>a?!1:!0};D.defineProperty(C,{x:"x"},function(){return this.L},function(a){this.Ra(a);v&&D.h(a,"number",C,"x");this.L=a});
D.defineProperty(C,{y:"y"},function(){return this.M},function(a){this.Ra(a);v&&D.h(a,"number",C,"y");this.M=a});D.defineProperty(C,{width:"width"},function(){return this.Ia},function(a){this.Ra(a);v&&D.h(a,"number",C,"width");0>a&&D.ua(a,">= 0",C,"width");this.Ia=a});D.defineProperty(C,{height:"height"},function(){return this.Ha},function(a){this.Ra(a);v&&D.h(a,"number",C,"height");0>a&&D.ua(a,">= 0",C,"height");this.Ha=a});
D.defineProperty(C,{left:"left"},function(){return this.L},function(a){this.Ra(a);v&&D.h(a,"number",C,"left");this.L=a});D.defineProperty(C,{top:"top"},function(){return this.M},function(a){this.Ra(a);v&&D.h(a,"number",C,"top");this.M=a});D.defineProperty(C,{right:"right"},function(){return this.L+this.Ia},function(a){this.Ra(a);v&&D.p(a,C,"right");this.L+=a-(this.L+this.Ia)});
D.defineProperty(C,{bottom:"bottom"},function(){return this.M+this.Ha},function(a){this.Ra(a);v&&D.p(a,C,"top");this.M+=a-(this.M+this.Ha)});D.defineProperty(C,{position:"position"},function(){return new N(this.L,this.M)},function(a){this.Ra(a);v&&D.l(a,N,C,"position");this.L=a.x;this.M=a.y});D.defineProperty(C,{size:"size"},function(){return new Ca(this.Ia,this.Ha)},function(a){this.Ra(a);v&&D.l(a,Ca,C,"size");this.Ia=a.width;this.Ha=a.height});
D.defineProperty(C,{im:"center"},function(){return new N(this.L+this.Ia/2,this.M+this.Ha/2)},function(a){this.Ra(a);v&&D.l(a,N,C,"center");this.L=a.x-this.Ia/2;this.M=a.y-this.Ha/2});D.defineProperty(C,{pa:"centerX"},function(){return this.L+this.Ia/2},function(a){this.Ra(a);v&&D.p(a,C,"centerX");this.L=a-this.Ia/2});D.defineProperty(C,{va:"centerY"},function(){return this.M+this.Ha/2},function(a){this.Ra(a);v&&D.p(a,C,"centerY");this.M=a-this.Ha/2});
C.prototype.isReal=C.prototype.F=function(){return isFinite(this.x)&&isFinite(this.y)&&isFinite(this.width)&&isFinite(this.height)};C.prototype.isEmpty=function(){return 0===this.width&&0===this.height};
function Lb(a,b,c,d){void 0===a?this.wh=this.ph=this.Fh=this.Gh=0:void 0===b?this.left=this.bottom=this.right=this.top=a:void 0===c?(d=b,this.top=a,this.right=b,this.bottom=a,this.left=d):void 0!==d?(this.top=a,this.right=b,this.bottom=c,this.left=d):D.k("Invalid arguments to Margin constructor: "+a+", "+b+", "+c+", "+d);this.J=!1}D.ka("Margin",Lb);D.Di(Lb);D.ne(Lb,{top:!0,right:!0,bottom:!0,left:!0});Lb.prototype.assign=function(a){this.Gh=a.Gh;this.Fh=a.Fh;this.ph=a.ph;this.wh=a.wh};
Lb.prototype.setTo=Lb.prototype.n=function(a,b,c,d){v&&(D.h(a,"number",Lb,"setTo:t"),D.h(b,"number",Lb,"setTo:r"),D.h(c,"number",Lb,"setTo:b"),D.h(d,"number",Lb,"setTo:l"));this.Ra();this.Gh=a;this.Fh=b;this.ph=c;this.wh=d;return this};Lb.prototype.set=Lb.prototype.set=function(a){v&&D.l(a,Lb,Lb,"assign:m");this.Ra();this.Gh=a.Gh;this.Fh=a.Fh;this.ph=a.ph;this.wh=a.wh;return this};Lb.prototype.copy=function(){var a=new Lb;a.Gh=this.Gh;a.Fh=this.Fh;a.ph=this.ph;a.wh=this.wh;return a};f=Lb.prototype;
f.Oa=function(){this.J=!0;Object.freeze(this);return this};f.V=function(){return Object.isFrozen(this)?this:this.copy().freeze()};f.freeze=function(){this.J=!0;return this};f.Xa=function(){Object.isFrozen(this)&&D.k("cannot thaw constant: "+this);this.J=!1;return this};f.Ra=function(a){if(this.J){var b="The Margin is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);D.k(b)}};
Lb.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=NaN;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));if(isNaN(c))return new Lb;for(var e=NaN;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));if(isNaN(e))return new Lb(c);for(var g=NaN;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));if(isNaN(g))return new Lb(c,e);for(var h=NaN;""===a[b];)b++;(d=a[b++])&&(h=parseFloat(d));return isNaN(h)?new Lb(c,e):new Lb(c,e,g,h)}return new Lb};
Lb.stringify=function(a){v&&D.l(a,Lb);return a.top.toString()+" "+a.right.toString()+" "+a.bottom.toString()+" "+a.left.toString()};Lb.prototype.toString=function(){return"Margin("+this.top+","+this.right+","+this.bottom+","+this.left+")"};Lb.prototype.equals=Lb.prototype.O=function(a){return a instanceof Lb?this.Gh===a.top&&this.Fh===a.right&&this.ph===a.bottom&&this.wh===a.left:!1};Lb.prototype.equalTo=Lb.prototype.Jx=function(a,b,c,d){return this.Gh===a&&this.Fh===b&&this.ph===c&&this.wh===d};
Lb.prototype.equalsApprox=Lb.prototype.Uc=function(a){return Q(this.Gh,a.top)&&Q(this.Fh,a.right)&&Q(this.ph,a.bottom)&&Q(this.wh,a.left)};D.defineProperty(Lb,{top:"top"},function(){return this.Gh},function(a){this.Ra(a);v&&D.p(a,Lb,"top");this.Gh=a});D.defineProperty(Lb,{right:"right"},function(){return this.Fh},function(a){this.Ra(a);v&&D.p(a,Lb,"right");this.Fh=a});D.defineProperty(Lb,{bottom:"bottom"},function(){return this.ph},function(a){this.Ra(a);v&&D.p(a,Lb,"bottom");this.ph=a});
D.defineProperty(Lb,{left:"left"},function(){return this.wh},function(a){this.Ra(a);v&&D.p(a,Lb,"left");this.wh=a});Lb.prototype.isReal=Lb.prototype.F=function(){return isFinite(this.top)&&isFinite(this.right)&&isFinite(this.bottom)&&isFinite(this.left)};function Ea(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0}D.Di(Ea);D.ne(Ea,{m11:!0,m12:!0,m21:!0,m22:!0,dx:!0,dy:!0});
Ea.prototype.set=Ea.prototype.set=function(a){v&&D.l(a,Ea,Ea,"set:t");this.m11=a.m11;this.m12=a.m12;this.m21=a.m21;this.m22=a.m22;this.dx=a.dx;this.dy=a.dy;return this};Ea.prototype.copy=function(){var a=new Ea;a.m11=this.m11;a.m12=this.m12;a.m21=this.m21;a.m22=this.m22;a.dx=this.dx;a.dy=this.dy;return a};Ea.prototype.toString=function(){return"Transform("+this.m11+","+this.m12+","+this.m21+","+this.m22+","+this.dx+","+this.dy+")"};
Ea.prototype.equals=Ea.prototype.O=function(a){return a instanceof Ea?this.m11===a.m11&&this.m12===a.m12&&this.m21===a.m21&&this.m22===a.m22&&this.dx===a.dx&&this.dy===a.dy:!1};Ea.prototype.isIdentity=Ea.prototype.Du=function(){return 1===this.m11&&0===this.m12&&0===this.m21&&1===this.m22&&0===this.dx&&0===this.dy};Ea.prototype.reset=Ea.prototype.reset=function(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0;return this};
Ea.prototype.multiply=Ea.prototype.multiply=function(a){v&&D.l(a,Ea,Ea,"multiply:matrix");var b=this.m12*a.m11+this.m22*a.m12,c=this.m11*a.m21+this.m21*a.m22,d=this.m12*a.m21+this.m22*a.m22,e=this.m11*a.dx+this.m21*a.dy+this.dx,g=this.m12*a.dx+this.m22*a.dy+this.dy;this.m11=this.m11*a.m11+this.m21*a.m12;this.m12=b;this.m21=c;this.m22=d;this.dx=e;this.dy=g;return this};
Ea.prototype.multiplyInverted=Ea.prototype.tB=function(a){v&&D.l(a,Ea,Ea,"multiplyInverted:matrix");var b=1/(a.m11*a.m22-a.m12*a.m21),c=a.m22*b,d=-a.m12*b,e=-a.m21*b,g=a.m11*b,h=b*(a.m21*a.dy-a.m22*a.dx),k=b*(a.m12*a.dx-a.m11*a.dy);a=this.m12*c+this.m22*d;b=this.m11*e+this.m21*g;e=this.m12*e+this.m22*g;g=this.m11*h+this.m21*k+this.dx;h=this.m12*h+this.m22*k+this.dy;this.m11=this.m11*c+this.m21*d;this.m12=a;this.m21=b;this.m22=e;this.dx=g;this.dy=h;return this};
Ea.prototype.invert=Ea.prototype.Ux=function(){var a=1/(this.m11*this.m22-this.m12*this.m21),b=-this.m12*a,c=-this.m21*a,d=this.m11*a,e=a*(this.m21*this.dy-this.m22*this.dx),g=a*(this.m12*this.dx-this.m11*this.dy);this.m11=this.m22*a;this.m12=b;this.m21=c;this.m22=d;this.dx=e;this.dy=g;return this};
Ea.prototype.rotate=Ea.prototype.rotate=function(a,b,c){v&&(D.p(a,Ea,"rotate:angle"),D.p(b,Ea,"rotate:rx"),D.p(c,Ea,"rotate:ry"));360<=a?a-=360:0>a&&(a+=360);if(0===a)return this;this.translate(b,c);var d=0,e=0;90===a?(d=0,e=1):180===a?(d=-1,e=0):270===a?(d=0,e=-1):(e=a*Math.PI/180,d=Math.cos(e),e=Math.sin(e));a=this.m12*d+this.m22*e;var g=this.m11*-e+this.m21*d,h=this.m12*-e+this.m22*d;this.m11=this.m11*d+this.m21*e;this.m12=a;this.m21=g;this.m22=h;this.translate(-b,-c);return this};
Ea.prototype.translate=Ea.prototype.translate=function(a,b){v&&(D.p(a,Ea,"translate:x"),D.p(b,Ea,"translate:y"));this.dx+=this.m11*a+this.m21*b;this.dy+=this.m12*a+this.m22*b;return this};Ea.prototype.scale=Ea.prototype.scale=function(a,b){void 0===b&&(b=a);v&&(D.p(a,Ea,"translate:sx"),D.p(b,Ea,"translate:sy"));this.m11*=a;this.m12*=a;this.m21*=b;this.m22*=b;return this};
Ea.prototype.transformPoint=Ea.prototype.xb=function(a){v&&D.l(a,N,Ea,"transformPoint:p");var b=a.L,c=a.M;a.L=b*this.m11+c*this.m21+this.dx;a.M=b*this.m12+c*this.m22+this.dy;return a};Ea.prototype.invertedTransformPoint=Ea.prototype.Fi=function(a){v&&D.l(a,N,Ea,"invertedTransformPoint:p");var b=1/(this.m11*this.m22-this.m12*this.m21),c=-this.m12*b,d=this.m11*b,e=b*(this.m12*this.dx-this.m11*this.dy),g=a.L,h=a.M;a.L=g*this.m22*b+h*-this.m21*b+b*(this.m21*this.dy-this.m22*this.dx);a.M=g*c+h*d+e;return a};
Ea.prototype.transformRect=Ea.prototype.nH=function(a){v&&D.l(a,C,Ea,"transformRect:rect");var b=a.L,c=a.M,d=b+a.Ia,e=c+a.Ha,g=this.m11,h=this.m12,k=this.m21,l=this.m22,m=this.dx,n=this.dy,p=b*g+c*k+m,q=b*h+c*l+n,r=d*g+c*k+m,c=d*h+c*l+n,s=b*g+e*k+m,b=b*h+e*l+n,g=d*g+e*k+m,d=d*h+e*l+n,e=p,h=q,p=Math.min(p,r),e=Math.max(e,r),h=Math.min(h,c),q=Math.max(q,c),p=Math.min(p,s),e=Math.max(e,s),h=Math.min(h,b),q=Math.max(q,b),p=Math.min(p,g),e=Math.max(e,g),h=Math.min(h,d),q=Math.max(q,d);a.L=p;a.M=h;a.Ia=
e-p;a.Ha=q-h;return a};function S(a,b,c,d){void 0===a?this.Og=this.Ng=this.M=this.L=0:(void 0===b&&(b=0),void 0===c&&(c=0),void 0===d&&(d=0),this.x=a,this.y=b,this.offsetX=c,this.offsetY=d);this.J=!1}D.ka("Spot",S);D.Di(S);D.ne(S,{x:!0,y:!0,offsetX:!0,offsetY:!0});S.prototype.assign=function(a){this.L=a.L;this.M=a.M;this.Ng=a.Ng;this.Og=a.Og};
S.prototype.setTo=S.prototype.n=function(a,b,c,d){v&&(Wb(a,"setTo:x"),Wb(b,"setTo:y"),Zb(c,"setTo:offx"),Zb(d,"setTo:offy"));this.Ra();this.L=a;this.M=b;this.Ng=c;this.Og=d;return this};S.prototype.set=S.prototype.set=function(a){v&&D.l(a,S,S,"set:s");this.Ra();this.L=a.L;this.M=a.M;this.Ng=a.Ng;this.Og=a.Og;return this};S.prototype.copy=function(){var a=new S;a.L=this.L;a.M=this.M;a.Ng=this.Ng;a.Og=this.Og;return a};f=S.prototype;f.Oa=function(){this.J=!0;Object.freeze(this);return this};
f.V=function(){return Object.isFrozen(this)?this:this.copy().freeze()};f.freeze=function(){this.J=!0;return this};f.Xa=function(){Object.isFrozen(this)&&D.k("cannot thaw constant: "+this);this.J=!1;return this};f.Ra=function(a){if(this.J){var b="The Spot is frozen, so its properties cannot be set: "+this.toString();void 0!==a&&(b+="  to value: "+a);D.k(b)}};function bc(a,b){a.L=NaN;a.M=NaN;a.Ng=b;return a}function Wb(a,b){(isNaN(a)||1<a||0>a)&&D.ua(a,"0 <= "+b+" <= 1",S,b)}
function Zb(a,b){(isNaN(a)||Infinity===a||-Infinity===a)&&D.ua(a,"real number, not NaN or Infinity",S,b)}var cc;
S.parse=cc=function(a){if("string"===typeof a){a=a.trim();if("None"===a)return dc;if("TopLeft"===a)return ec;if("Top"===a||"TopCenter"===a||"MiddleTop"===a)return jc;if("TopRight"===a)return kc;if("Left"===a||"LeftCenter"===a||"MiddleLeft"===a)return lc;if("Center"===a)return mc;if("Right"===a||"RightCenter"===a||"MiddleRight"===a)return nc;if("BottomLeft"===a)return tc;if("Bottom"===a||"BottomCenter"===a||"MiddleBottom"===a)return uc;if("BottomRight"===a)return vc;if("TopSide"===a)return wc;if("LeftSide"===
a)return xc;if("RightSide"===a)return yc;if("BottomSide"===a)return Dc;if("TopBottomSides"===a)return Ec;if("LeftRightSides"===a)return Jc;if("TopLeftSides"===a)return Kc;if("TopRightSides"===a)return Lc;if("BottomLeftSides"===a)return Mc;if("BottomRightSides"===a)return Nc;if("NotTopSide"===a)return Oc;if("NotLeftSide"===a)return Pc;if("NotRightSide"===a)return Sc;if("NotBottomSide"===a)return Tc;if("AllSides"===a)return Uc;if("Default"===a)return Vc;a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;
var d=a[b++];void 0!==d&&0<d.length&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(e=parseFloat(d));for(var g=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(g=parseFloat(d));for(var h=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(h=parseFloat(d));return new S(c,e,g,h)}return new S};S.stringify=function(a){v&&D.l(a,S);return a.Vc()?a.x.toString()+" "+a.y.toString()+" "+a.offsetX.toString()+" "+a.offsetY.toString():a.toString()};
S.prototype.toString=function(){return this.Vc()?0===this.Ng&&0===this.Og?"Spot("+this.x+","+this.y+")":"Spot("+this.x+","+this.y+","+this.offsetX+","+this.offsetY+")":this.O(dc)?"None":this.O(ec)?"TopLeft":this.O(jc)?"Top":this.O(kc)?"TopRight":this.O(lc)?"Left":this.O(mc)?"Center":this.O(nc)?"Right":this.O(tc)?"BottomLeft":this.O(uc)?"Bottom":this.O(vc)?"BottomRight":this.O(wc)?"TopSide":this.O(xc)?"LeftSide":this.O(yc)?"RightSide":this.O(Dc)?"BottomSide":this.O(Ec)?"TopBottomSides":this.O(Jc)?
"LeftRightSides":this.O(Kc)?"TopLeftSides":this.O(Lc)?"TopRightSides":this.O(Mc)?"BottomLeftSides":this.O(Nc)?"BottomRightSides":this.O(Oc)?"NotTopSide":this.O(Pc)?"NotLeftSide":this.O(Sc)?"NotRightSide":this.O(Tc)?"NotBottomSide":this.O(Uc)?"AllSides":this.O(Vc)?"Default":"None"};S.prototype.equals=S.prototype.O=function(a){return a instanceof S?(this.L===a.x||isNaN(this.L)&&isNaN(a.x))&&(this.M===a.y||isNaN(this.M)&&isNaN(a.y))&&this.Ng===a.offsetX&&this.Og===a.offsetY:!1};
S.prototype.opposite=S.prototype.uG=function(){return new S(.5-(this.L-.5),.5-(this.M-.5),-this.Ng,-this.Og)};S.prototype.includesSide=S.prototype.Ij=function(a){if(!this.Lj())return!1;if(!a.Lj())if(a.O(Wc))a=xc;else if(a.O(Xc))a=yc;else if(a.O(Yc))a=wc;else if(a.O(Zc))a=Dc;else return!1;a=a.offsetY;return(this.Og&a)===a};D.defineProperty(S,{x:"x"},function(){return this.L},function(a){this.Ra(a);v&&Wb(a,"x");this.L=a});
D.defineProperty(S,{y:"y"},function(){return this.M},function(a){this.Ra(a);v&&Wb(a,"y");this.M=a});D.defineProperty(S,{offsetX:"offsetX"},function(){return this.Ng},function(a){this.Ra(a);v&&Zb(a,"offsetX");this.Ng=a});D.defineProperty(S,{offsetY:"offsetY"},function(){return this.Og},function(a){this.Ra(a);v&&Zb(a,"offsetY");this.Og=a});S.prototype.isSpot=S.prototype.Vc=function(){return!isNaN(this.x)&&!isNaN(this.y)};S.prototype.isNoSpot=S.prototype.ce=function(){return isNaN(this.x)||isNaN(this.y)};
S.prototype.isSide=S.prototype.Lj=function(){return isNaN(this.x)&&isNaN(this.y)&&1===this.offsetX&&0!==this.offsetY};S.prototype.isNone=S.prototype.oJ=function(){return isNaN(this.x)&&isNaN(this.y)&&0===this.offsetX&&0===this.offsetY};S.prototype.isDefault=S.prototype.kd=function(){return isNaN(this.x)&&isNaN(this.y)&&-1===this.offsetX&&0===this.offsetY};var dc;S.None=dc=bc(new S(0,0,0,0),0).Oa();var Vc;S.Default=Vc=bc(new S(0,0,-1,0),-1).Oa();var ec;S.TopLeft=ec=(new S(0,0,0,0)).Oa();var jc;
S.TopCenter=jc=(new S(.5,0,0,0)).Oa();var kc;S.TopRight=kc=(new S(1,0,0,0)).Oa();var lc;S.LeftCenter=lc=(new S(0,.5,0,0)).Oa();var mc;S.Center=mc=(new S(.5,.5,0,0)).Oa();var nc;S.RightCenter=nc=(new S(1,.5,0,0)).Oa();var tc;S.BottomLeft=tc=(new S(0,1,0,0)).Oa();var uc;S.BottomCenter=uc=(new S(.5,1,0,0)).Oa();var vc;S.BottomRight=vc=(new S(1,1,0,0)).Oa();var $c;S.MiddleTop=$c=jc;var ed;S.MiddleLeft=ed=lc;var fd;S.MiddleRight=fd=nc;var gd;S.MiddleBottom=gd=uc;var Yc;S.Top=Yc=jc;var Wc;S.Left=Wc=lc;
var Xc;S.Right=Xc=nc;var Zc;S.Bottom=Zc=uc;var wc;S.TopSide=wc=bc(new S(0,0,1,D.yd),1).Oa();var xc;S.LeftSide=xc=bc(new S(0,0,1,D.bd),1).Oa();var yc;S.RightSide=yc=bc(new S(0,0,1,D.qd),1).Oa();var Dc;S.BottomSide=Dc=bc(new S(0,0,1,D.pd),1).Oa();var Ec;S.TopBottomSides=Ec=bc(new S(0,0,1,D.yd|D.pd),1).Oa();var Jc;S.LeftRightSides=Jc=bc(new S(0,0,1,D.bd|D.qd),1).Oa();var Kc;S.TopLeftSides=Kc=bc(new S(0,0,1,D.yd|D.bd),1).Oa();var Lc;S.TopRightSides=Lc=bc(new S(0,0,1,D.yd|D.qd),1).Oa();var Mc;
S.BottomLeftSides=Mc=bc(new S(0,0,1,D.pd|D.bd),1).Oa();var Nc;S.BottomRightSides=Nc=bc(new S(0,0,1,D.pd|D.qd),1).Oa();var Oc;S.NotTopSide=Oc=bc(new S(0,0,1,D.bd|D.qd|D.pd),1).Oa();var Pc;S.NotLeftSide=Pc=bc(new S(0,0,1,D.yd|D.qd|D.pd),1).Oa();var Sc;S.NotRightSide=Sc=bc(new S(0,0,1,D.yd|D.bd|D.pd),1).Oa();var Tc;S.NotBottomSide=Tc=bc(new S(0,0,1,D.yd|D.bd|D.qd),1).Oa();var Uc;S.AllSides=Uc=bc(new S(0,0,1,D.yd|D.bd|D.qd|D.pd),1).Oa();function hd(){this.bc=[1,0,0,1,0,0]}
hd.prototype.copy=function(){var a=new hd;a.bc[0]=this.bc[0];a.bc[1]=this.bc[1];a.bc[2]=this.bc[2];a.bc[3]=this.bc[3];a.bc[4]=this.bc[4];a.bc[5]=this.bc[5];return a};hd.prototype.translate=function(a,b){this.bc[4]+=this.bc[0]*a+this.bc[2]*b;this.bc[5]+=this.bc[1]*a+this.bc[3]*b};hd.prototype.scale=function(a,b){this.bc[0]*=a;this.bc[1]*=a;this.bc[2]*=b;this.bc[3]*=b};function id(a){this.type=a;this.r2=this.y2=this.x2=this.r1=this.y1=this.x1=0;this.XE=[];this.pattern=null}
id.prototype.addColorStop=function(a,b){this.XE.push({offset:a,color:b})};
function qd(a,b,c){this.fillStyle="#000000";this.font="10px sans-serif";this.globalAlpha=1;this.lineCap="butt";this.co=0;this.lineJoin="miter";this.lineWidth=1;this.miterLimit=10;this.shadowBlur=0;this.shadowColor="rgba(0, 0, 0, 0)";this.shadowOffsetY=this.shadowOffsetX=0;this.strokeStyle="#000000";this.textAlign="start";this.bq=!1;this.Qg=this.wt=this.vt=0;this.document=b||document;this.qF=c;this.ey=null;this.path=[];this.Oh=new hd;this.stack=[];this.wf=[];this.Hx=a;this.SJ="http://www.w3.org/2000/svg";
this.width=this.Hx.width;this.height=this.Hx.height;this.Em=rd(this,"svg",{width:this.width+"px",height:this.height+"px",viewBox:"0 0 "+this.Hx.width+" "+this.Hx.height});this.Em.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns","http://www.w3.org/2000/svg");this.Em.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns:xlink","http://www.w3.org/1999/xlink");sd(this,1,0,0,1,0,0);a=D.Km++;b=rd(this,"clipPath",{id:"mainClip"+a});b.appendChild(rd(this,"rect",{x:0,y:0,width:this.width,height:this.height}));
this.Em.appendChild(b);this.wf[0].setAttributeNS(null,"clip-path","url(#mainClip"+a+")")}f=qd.prototype;f.arc=function(a,b,c,d,e,g){td(this,a,b,c,d,e,g)};f.beginPath=function(){this.path=[]};f.bezierCurveTo=function(a,b,c,d,e,g){this.path.push(["C",a,b,c,d,e,g])};f.clearRect=function(){};f.clip=function(){Dd(this,"clipPath",this.path,new hd)};f.closePath=function(){this.path.push(["z"])};f.createLinearGradient=function(a,b,c,d){var e=new id("linear");e.x1=a;e.y1=b;e.x2=c;e.y2=d;return e};
f.createPattern=function(){return null};f.createRadialGradient=function(a,b,c,d,e,g){var h=new id("radial");h.x1=a;h.y1=b;h.r1=c;h.x2=d;h.y2=e;h.r2=g;return h};
f.drawImage=function(a,b,c,d,e,g,h,k,l){var m="";a instanceof HTMLCanvasElement&&(m=a.toDataURL());a instanceof HTMLImageElement&&(m=a.src);void 0===d&&(g=b,h=c,k=d=a.naturalWidth,l=e=a.naturalHeight);d=d||0;e=e||0;g=g||0;h=h||0;k=k||0;l=l||0;m={x:0,y:0,width:a.naturalWidth,height:a.naturalHeight,href:m,preserveAspectRatio:"xMidYMid slice"};Eb(d,k)&&Eb(e,l)||(m.preserveAspectRatio="none");var n="";k/=d;l/=e;if(0!==g||0!==h)n+=" translate("+g+", "+h+")";if(1!==k||1!==l)n+=" scale("+k+", "+l+")";if(0!==
b||0!==c)n+=" translate("+-b+", "+-c+")";if(0!==b||0!==c||d!==a.naturalWidth||e!==a.naturalHeight)a="CLIP"+D.Km++,g=rd(this,"clipPath",{id:a}),g.appendChild(rd(this,"rect",{x:b,y:c,width:d,height:e})),this.Em.appendChild(g),m["clip-path"]="url(#"+a+")";Ed(this,"image",m,this.Oh,n);this.addElement("image",m)};f.fill=function(){Dd(this,"fill",this.path,this.Oh)};f.Yg=function(){this.bq?this.clip():this.fill()};f.fillRect=function(a,b,c,d){Fd(this,"fill",[a,b,c,d],this.Oh)};
f.fillText=function(a,b,c){a=[a,b,c];b=this.textAlign;"left"===b?b="start":"right"===b?b="end":"center"===b&&(b="middle");b={x:a[1],y:a[2],style:"font: "+this.font,"text-anchor":b};Ed(this,"fill",b,this.Oh);this.addElement("text",b,a[0])};f.lineTo=function(a,b){this.path.push(["L",a,b])};f.moveTo=function(a,b){this.path.push(["M",a,b])};f.quadraticCurveTo=function(a,b,c,d){this.path.push(["Q",a,b,c,d])};f.rect=function(a,b,c,d){this.path.push(["M",a,b],["L",a+c,b],["L",a+c,b+d],["L",a,b+d],["z"])};
f.restore=function(){this.Oh=this.stack.pop();this.path=this.stack.pop();var a=this.stack.pop();this.fillStyle=a.fillStyle;this.font=a.font;this.globalAlpha=a.globalAlpha;this.lineCap=a.lineCap;this.co=a.co;this.lineJoin=a.lineJoin;this.lineWidth=a.lineWidth;this.miterLimit=a.miterLimit;this.shadowBlur=a.shadowBlur;this.shadowColor=a.shadowColor;this.shadowOffsetX=a.shadowOffsetX;this.shadowOffsetY=a.shadowOffsetY;this.strokeStyle=a.strokeStyle;this.textAlign=a.textAlign};
f.save=function(){this.stack.push({fillStyle:this.fillStyle,font:this.font,globalAlpha:this.globalAlpha,lineCap:this.lineCap,co:this.co,lineJoin:this.lineJoin,lineWidth:this.lineWidth,miterLimit:this.miterLimit,shadowBlur:this.shadowBlur,shadowColor:this.shadowColor,shadowOffsetX:this.shadowOffsetX,shadowOffsetY:this.shadowOffsetY,strokeStyle:this.strokeStyle,textAlign:this.textAlign});for(var a=[],b=0;b<this.path.length;b++)a.push(this.path[b]);this.stack.push(a);this.stack.push(this.Oh.copy())};
f.setTransform=function(a,b,c,d,e,g){1===a&&0===b&&0===c&&1===d&&0===e&&0===g||sd(this,a,b,c,d,e,g)};f.scale=function(a,b){this.Oh.scale(a,b)};f.translate=function(a,b){this.Oh.translate(a,b)};f.transform=function(){};f.stroke=function(){Dd(this,"stroke",this.path,this.Oh)};f.Vj=function(){this.bq||this.stroke()};f.strokeRect=function(a,b,c,d){Fd(this,"stroke",[a,b,c,d],this.Oh)};
function rd(a,b,c,d){a=a.document.createElementNS(a.SJ,b);if(D.Qa(c))for(var e in c)a.setAttributeNS("href"===e?"http://www.w3.org/1999/xlink":"",e,c[e]);void 0!==d&&(a.textContent=d);return a}f.addElement=function(a,b,c){a=rd(this,a,b,c);0<this.wf.length?this.wf[this.wf.length-1].appendChild(a):this.Em.appendChild(a);return this.ey=a};
function Ed(a,b,c,d,e){1!==a.globalAlpha&&(c.opacity=a.globalAlpha);"fill"===b?(a.fillStyle instanceof id?c.fill=Gd(a,a.fillStyle):(/^rgba\(/.test(a.fillStyle)&&(b=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle),c.fill="rgb("+b[1]+","+b[2]+","+b[3]+")",c["fill-opacity"]=b[4]),c.fill=a.fillStyle),c.stroke="none"):"stroke"===b&&(c.fill="none",a.strokeStyle instanceof id?c.stroke=Gd(a,a.strokeStyle):(/^rgba\(/.test(a.strokeStyle)&&(b=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.strokeStyle),
c.stroke="rgb("+b[1]+","+b[2]+","+b[3]+")",c["stroke-opacity"]=b[4]),c.stroke=a.strokeStyle),c["stroke-width"]=a.lineWidth,c["stroke-linecap"]=a.lineCap,c["stroke-linejoin"]=a.lineJoin,c["stroke-miterlimit"]=a.miterLimit);a=d.bc;a="matrix("+a[0]+", "+a[1]+", "+a[2]+", "+a[3]+", "+a[4]+", "+a[5]+")";void 0!==e&&(a+=e);c.transform=a}
function Gd(a,b){var c="GRAD"+D.Km++,d;if("linear"===b.type)d=rd(a,"linearGradient",{x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,id:c,gradientUnits:"userSpaceOnUse"});else if("radial"===b.type)d=rd(a,"radialGradient",{x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,r1:b.r1,r2:b.r2,id:c});else if("pattern"===b.type){var e=b.pattern;d={width:e.width,height:e.height,id:c,patternUnits:"userSpaceOnUse"};var g="";e instanceof HTMLCanvasElement&&(g=e.toDataURL());e instanceof HTMLImageElement&&(g=e.src);e={x:0,y:0,width:e.width,height:e.height,
href:g};d=rd(a,"pattern",d);d.appendChild(rd(a,"image",e))}else throw Error("invalid gradient");for(var e=b.XE,g=e.length,h=[],k=0;k<g;k++){var l=e[k],m=l.color,l={offset:l.offset,"stop-color":m};/^rgba\(/.test(m)&&(m=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(m),l["stop-color"]="rgb("+m[1]+","+m[2]+","+m[3]+")",l["stop-opacity"]=m[4]);h.push(l)}h.sort(function(a,b){return a.offset>b.offset?1:-1});for(k=0;k<g;k++)d.appendChild(rd(a,"stop",h[k]));a.Em.appendChild(d);
return"url(#"+c+")"}function Fd(a,b,c,d){c={x:c[0],y:c[1],width:c[2],height:c[3]};Ed(a,b,c,d);a.addElement("rect",c)}
function Dd(a,b,c,d){for(var e=[],g=0;g<c.length;g++){var h=D.jm(c[g]),k=[h.shift()];if("A"===k[0])k.push(h.shift()+","+h.shift(),h.shift(),h.shift()+","+h.shift(),h.shift()+","+h.shift());else for(;h.length;)k.push(h.shift()+","+h.shift());e.push(k.join(" "))}c={d:e.join(" ")};Ed(a,b,c,d);"clipPath"===b?(b="CLIP"+D.Km++,d=rd(a,"clipPath",{id:b}),d.appendChild(rd(a,"path",c)),a.Em.appendChild(d),0<a.wf.length&&a.wf[a.wf.length-1].setAttributeNS(null,"clip-path","url(#"+b+")")):a.addElement("path",
c)}function td(a,b,c,d,e,g,h){var k=Math.abs(e-g);if(e!==g){var l=b+d*Math.cos(g);g=c+d*Math.sin(g);k>=2*Math.PI?(td(a,b,c,d,e,e+Math.PI,h),td(a,b,c,d,e+Math.PI,e+2*Math.PI,h),a.path.push(["M",l,g])):(b+=d*Math.cos(e),c+=d*Math.sin(e),k=180*k/Math.PI,e=h?0:1,h=180<=k===!!h?0:1,0!==a.path.length?a.path.push(["L",b,c]):a.path.push(["M",b,c]),a.path.push(["A",d,d,k,h,e,l,g]))}}function sd(a,b,c,d,e,g,h){var k=new hd;k.bc=[b,c,d,e,g,h];b={};Ed(a,"g",b,k);k=a.addElement("g",b);a.wf.push(k)}
f.kb=function(){if(0!==this.shadowOffsetX||0!==this.shadowOffsetY||0!==this.shadowBlur){var a="SHADOW"+D.Km++,b=this.addElement("filter",{id:a,x:"-100%",y:"-100%",width:"300%",height:"300%"},null),c,d,e,g,h;c=rd(this,"feGaussianBlur",{"in":"SourceAlpha",result:"blur",DM:this.shadowBlur/2});d=rd(this,"feFlood",{"in":"blur",result:"flood","flood-color":this.shadowColor});e=rd(this,"feComposite",{"in":"flood",in2:"blur",operator:"in",result:"comp"});g=rd(this,"feOffset",{"in":"comp",result:"offsetBlur",
dx:this.shadowOffsetX,dy:this.shadowOffsetY});h=rd(this,"feMerge",{});h.appendChild(rd(this,"feMergeNode",{"in":"offsetBlur"}));h.appendChild(rd(this,"feMergeNode",{"in":"SourceGraphic"}));b.appendChild(c);b.appendChild(d);b.appendChild(e);b.appendChild(g);b.appendChild(h);0<this.wf.length&&this.wf[this.wf.length-1].setAttributeNS(null,"filter","url(#"+a+")")}};f.TB=function(a,b,c){this.vt=a;this.wt=b;this.Qg=c};f.vo=function(){this.shadowBlur=this.shadowOffsetY=this.shadowOffsetX=0};
f.wo=function(){this.shadowOffsetX=this.vt;this.shadowOffsetY=this.wt;this.shadowBlur=this.Qg};f.NA=function(){return!1};f.IA=function(){};f.De=function(){};f.Ky=function(){};qd.prototype.rotate=function(){};function ja(a,b){this.ownerDocument=void 0===b?document:b;var c=this.ownerDocument.createElement("canvas");c.tabIndex=0;this.Wd=c;this.Qk=new Hd(c);c.ia=a;Object.seal(this)}f=ja.prototype;f.toDataURL=function(a,b){return this.Wd.toDataURL(a,b)};f.getBoundingClientRect=function(){return this.Wd.getBoundingClientRect()};
f.focus=function(){return this.Wd.focus()};f.addEventListener=function(a,b,c){this.Wd.addEventListener(a,b,c)};f.removeEventListener=function(a,b,c){this.Wd.removeEventListener(a,b,c)};D.defineProperty(ja,{width:"width"},function(){return this.Wd.width},function(a){this.Wd.width=a});D.defineProperty(ja,{height:"height"},function(){return this.Wd.height},function(a){this.Wd.height=a});D.w(ja,{style:"style"},function(){return this.Wd.style});
function Hd(a){a.getContext&&a.getContext("2d")||D.k("Browser does not support HTML Canvas Element");this.za=a.getContext("2d");this.hz=this.jz=this.iz="";this.nr=!1;this.Qg=this.wt=this.vt=0;Object.seal(this)}Hd.prototype.Ky=function(a){this.za.aB=a};D.defineProperty(Hd,{fillStyle:"fillStyle"},function(){return this.za.fillStyle},function(a){this.hz!==a&&(this.hz=this.za.fillStyle=a)});
D.defineProperty(Hd,{font:"font"},function(){return this.za.font},function(a){this.iz!==a&&(this.iz=this.za.font=a)});D.defineProperty(Hd,{globalAlpha:"globalAlpha"},function(){return this.za.globalAlpha},function(a){this.za.globalAlpha=a});D.defineProperty(Hd,{lineCap:"lineCap"},function(){return this.za.lineCap},function(a){this.za.lineCap=a});D.defineProperty(Hd,{co:"lineDashOffset"},function(){return this.za.co},function(a){this.za.co=a});
D.defineProperty(Hd,{lineJoin:"lineJoin"},function(){return this.za.lineJoin},function(a){this.za.lineJoin=a});D.defineProperty(Hd,{lineWidth:"lineWidth"},function(){return this.za.lineWidth},function(a){this.za.lineWidth=a});D.defineProperty(Hd,{miterLimit:"miterLimit"},function(){return this.za.miterLimit},function(a){this.za.miterLimit=a});D.defineProperty(Hd,{shadowBlur:"shadowBlur"},function(){return this.za.shadowBlur},function(a){this.za.shadowBlur=a});
D.defineProperty(Hd,{shadowColor:"shadowColor"},function(){return this.za.shadowColor},function(a){this.za.shadowColor=a});D.defineProperty(Hd,{shadowOffsetX:"shadowOffsetX"},function(){return this.za.shadowOffsetX},function(a){this.za.shadowOffsetX=a});D.defineProperty(Hd,{shadowOffsetY:"shadowOffsetY"},function(){return this.za.shadowOffsetY},function(a){this.za.shadowOffsetY=a});
D.defineProperty(Hd,{strokeStyle:"strokeStyle"},function(){return this.za.strokeStyle},function(a){this.jz!==a&&(this.jz=this.za.strokeStyle=a)});D.defineProperty(Hd,{textAlign:"textAlign"},function(){return this.za.textAlign},function(a){this.za.textAlign=a});D.defineProperty(Hd,{aB:"imageSmoothingEnabled"},function(){return this.za.aB},function(a){this.za.aB=a});f=Hd.prototype;f.arc=function(a,b,c,d,e,g){this.za.arc(a,b,c,d,e,g)};f.beginPath=function(){this.za.beginPath()};
f.bezierCurveTo=function(a,b,c,d,e,g){this.za.bezierCurveTo(a,b,c,d,e,g)};f.clearRect=function(a,b,c,d){this.za.clearRect(a,b,c,d)};f.clip=function(){this.za.clip()};f.closePath=function(){this.za.closePath()};f.createLinearGradient=function(a,b,c,d){return this.za.createLinearGradient(a,b,c,d)};f.createPattern=function(a,b){return this.za.createPattern(a,b)};f.createRadialGradient=function(a,b,c,d,e,g){return this.za.createRadialGradient(a,b,c,d,e,g)};
f.drawImage=function(a,b,c,d,e,g,h,k,l){void 0===d?this.za.drawImage(a,b,c):this.za.drawImage(a,b,c,d,e,g,h,k,l)};f.fill=function(){this.za.fill()};f.fillRect=function(a,b,c,d){this.za.fillRect(a,b,c,d)};f.fillText=function(a,b,c){this.za.fillText(a,b,c)};f.getImageData=function(a,b,c,d){return this.za.getImageData(a,b,c,d)};f.lineTo=function(a,b){this.za.lineTo(a,b)};f.measureText=function(a){return this.za.measureText(a)};f.moveTo=function(a,b){this.za.moveTo(a,b)};
f.quadraticCurveTo=function(a,b,c,d){this.za.quadraticCurveTo(a,b,c,d)};f.rect=function(a,b,c,d){this.za.rect(a,b,c,d)};f.restore=function(){this.za.restore()};Hd.prototype.rotate=function(a){this.za.rotate(a)};f=Hd.prototype;f.save=function(){this.za.save()};f.setTransform=function(a,b,c,d,e,g){this.za.setTransform(a,b,c,d,e,g)};f.scale=function(a,b){this.za.scale(a,b)};f.stroke=function(){this.za.stroke()};
f.transform=function(a,b,c,d,e,g){1===a&&0===b&&0===c&&1===d&&0===e&&0===g||this.za.transform(a,b,c,d,e,g)};f.translate=function(a,b){this.za.translate(a,b)};f.Yg=function(a){if(a instanceof Ba&&a.type===Id){var b=a.Mo;a=a.nz;a>b?(this.scale(b/a,1),this.translate((a-b)/2,0)):b>a&&(this.scale(1,a/b),this.translate(0,(b-a)/2));this.nr?this.clip():this.fill();a>b?(this.translate(-(a-b)/2,0),this.scale(1/(b/a),1)):b>a&&(this.translate(0,-(b-a)/2),this.scale(1,1/(a/b)))}else this.nr?this.clip():this.fill()};
f.Vj=function(){this.nr||this.stroke()};D.defineProperty(Hd,{bq:"clipInsteadOfFill"},function(){return this.nr},function(a){this.nr=a});f=Hd.prototype;f.TB=function(a,b,c){this.vt=a;this.wt=b;this.Qg=c};f.vo=function(){this.shadowBlur=this.shadowOffsetY=this.shadowOffsetX=0};f.wo=function(){this.shadowOffsetX=this.vt;this.shadowOffsetY=this.wt;this.shadowBlur=this.Qg};
f.NA=function(a,b){var c=this.za;if(void 0!==c.setLineDash)c.setLineDash(a),c.lineDashOffset=b;else if(void 0!==c.webkitLineDash)c.webkitLineDash=a,c.webkitLineDashOffset=b;else if(void 0!==c.mozDash)c.mozDash=a,c.mozDashOffset=b;else return!1;return!0};f.IA=function(){var a=this.za;void 0!==a.setLineDash?(a.setLineDash(D.Do),a.lineDashOffset=0):void 0!==a.webkitLineDash?(a.webkitLineDash=D.Do,a.webkitLineDashOffset=0):void 0!==a.mozDash&&(a.mozDash=null,a.mozDashOffset=0)};
f.De=function(a){a&&(this.iz="");this.hz=this.jz=""};
var Jd=(Math.sqrt(2)-1)/3*4,Kd=(new N(0,0)).Oa(),Ld=(new C(0,0,0,0)).Oa(),Sd=(new Lb(0,0,0,0)).Oa(),Td=(new Lb(2,2,2,2)).Oa(),Ud=(new N(6,6)).Oa(),Vd=(new N(-Infinity,-Infinity)).Oa(),Wd=(new N(Infinity,Infinity)).Oa(),Xd=(new Ca(0,0)).Oa(),Yd=(new Ca(1,1)).Oa(),Zd=(new Ca(6,6)).Oa(),$d=(new Ca(8,8)).Oa(),ae=(new Ca(10,10)).Oa(),ge=(new Ca(Infinity,Infinity)).Oa(),he=(new N(NaN,NaN)).Oa(),ie=(new Ca(NaN,NaN)).Oa(),je=(new C(NaN,NaN,NaN,NaN)).Oa(),ke=(new S(.156,.156)).Oa(),le=(new S(.844,.844)).Oa(),
me=new ma,ne=new ma,oe=null;function pe(a){if(0>=a)return 0;var b=oe;if(null===b){for(var b=[],c=0;2E3>=c;c++)b[c]=Math.sqrt(c);oe=b}return 1>a?(c=1/a,2E3>=c?1/b[c|0]:Math.sqrt(a)):2E3>=a?b[a|0]:Math.sqrt(a)}function Q(a,b){var c=a-b;return.5>c&&-.5<c}function Eb(a,b){var c=a-b;return 5E-8>c&&-5E-8<c}
function qe(a,b,c,d,e,g,h){0>=e&&(e=1E-6);var k=0,l=0,m=0,n=0;a<c?(l=a,k=c):(l=c,k=a);b<d?(n=b,m=d):(n=d,m=b);if(a===c)return n<=h&&h<=m&&a-e<=g&&g<=a+e;if(b===d)return l<=g&&g<=k&&b-e<=h&&h<=b+e;k+=e;l-=e;if(l<=g&&g<=k&&(m+=e,n-=e,n<=h&&h<=m))if(k-l>m-n)if(a-c>e||c-a>e){if(g=(d-b)/(c-a)*(g-a)+b,g-e<=h&&h<=g+e)return!0}else return!0;else if(b-d>e||d-b>e){if(h=(c-a)/(d-b)*(h-b)+a,h-e<=g&&g<=h+e)return!0}else return!0;return!1}
function ze(a,b,c,d,e,g,h,k,l,m,n,p){if(qe(a,b,h,k,p,c,d)&&qe(a,b,h,k,p,e,g))return qe(a,b,h,k,p,m,n);var q=(a+c)/2,r=(b+d)/2,s=(c+e)/2,u=(d+g)/2;e=(e+h)/2;g=(g+k)/2;d=(q+s)/2;c=(r+u)/2;var s=(s+e)/2,u=(u+g)/2,t=(d+s)/2,y=(c+u)/2;return ze(a,b,q,r,d,c,t,y,l,m,n,p)||ze(t,y,s,u,e,g,h,k,l,m,n,p)}
function Ae(a,b,c,d,e,g,h,k,l,m){if(qe(a,b,h,k,l,c,d)&&qe(a,b,h,k,l,e,g))Ub(m,a,b,0,0),Ub(m,h,k,0,0);else{var n=(a+c)/2,p=(b+d)/2,q=(c+e)/2,r=(d+g)/2;e=(e+h)/2;g=(g+k)/2;d=(n+q)/2;c=(p+r)/2;var q=(q+e)/2,r=(r+g)/2,s=(d+q)/2,u=(c+r)/2;Ae(a,b,n,p,d,c,s,u,l,m);Ae(s,u,q,r,e,g,h,k,l,m)}}
function Be(a,b,c,d,e,g,h,k,l,m){if(qe(a,b,h,k,l,c,d)&&qe(a,b,h,k,l,e,g))0===m.length&&(m.push(a),m.push(b)),m.push(h),m.push(k);else{var n=(a+c)/2,p=(b+d)/2,q=(c+e)/2,r=(d+g)/2;e=(e+h)/2;g=(g+k)/2;d=(n+q)/2;c=(p+r)/2;var q=(q+e)/2,r=(r+g)/2,s=(d+q)/2,u=(c+r)/2;Be(a,b,n,p,d,c,s,u,l,m);Be(s,u,q,r,e,g,h,k,l,m)}}
function Ce(a,b,c,d,e,g,h,k,l,m,n,p,q,r){var s=1-l;a=a*s+c*l;b=b*s+d*l;c=c*s+e*l;d=d*s+g*l;e=e*s+h*l;g=g*s+k*l;k=a*s+c*l;h=b*s+d*l;c=c*s+e*l;d=d*s+g*l;m.x=a;m.y=b;n.x=k;n.y=h;p.x=k*s+c*l;p.y=h*s+d*l;q.x=c;q.y=d;r.x=e;r.y=g}function De(a,b,c,d,e,g,h,k,l,m){if(qe(a,b,e,g,m,c,d))return qe(a,b,e,g,m,k,l);var n=(a+c)/2,p=(b+d)/2;c=(c+e)/2;d=(d+g)/2;var q=(n+c)/2,r=(p+d)/2;return De(a,b,n,p,q,r,h,k,l,m)||De(q,r,c,d,e,g,h,k,l,m)}
function Ee(a,b,c,d,e,g,h,k){if(qe(a,b,e,g,h,c,d))Ub(k,a,b,0,0),Ub(k,e,g,0,0);else{var l=(a+c)/2,m=(b+d)/2;c=(c+e)/2;d=(d+g)/2;var n=(l+c)/2,p=(m+d)/2;Ee(a,b,l,m,n,p,h,k);Ee(n,p,c,d,e,g,h,k)}}function Fe(a,b,c,d,e,g,h,k){if(qe(a,b,e,g,h,c,d))0===k.length&&(k.push(a),k.push(b)),k.push(e),k.push(g);else{var l=(a+c)/2,m=(b+d)/2;c=(c+e)/2;d=(d+g)/2;var n=(l+c)/2,p=(m+d)/2;Fe(a,b,l,m,n,p,h,k);Fe(n,p,c,d,e,g,h,k)}}
function Ge(a,b,c,d,e,g,h,k,l,m,n,p,q,r){0>=q&&(q=1E-6);if(qe(a,b,h,k,q,c,d)&&qe(a,b,h,k,q,e,g)){var s=(a-h)*(m-p)-(b-k)*(l-n);if(0===s)return!1;q=((a*k-b*h)*(l-n)-(a-h)*(l*p-m*n))/s;s=((a*k-b*h)*(m-p)-(b-k)*(l*p-m*n))/s;if((l>n?l-n:n-l)<(m>p?m-p:p-m)){if(h=l=0,b<k?(l=b,h=k):(l=k,h=b),s<l||s>h)return!1}else if(a<h?l=a:(l=h,h=a),q<l||q>h)return!1;r.x=q;r.y=s;return!0}var s=(a+c)/2,u=(b+d)/2;c=(c+e)/2;d=(d+g)/2;e=(e+h)/2;g=(g+k)/2;var t=(s+c)/2,y=(u+d)/2;c=(c+e)/2;d=(d+g)/2;var w=(t+c)/2,B=(y+d)/2,
A=(n-l)*(n-l)+(p-m)*(p-m),O=!1;Ge(a,b,s,u,t,y,w,B,l,m,n,p,q,r)&&(b=(r.x-l)*(r.x-l)+(r.y-m)*(r.y-m),b<A&&(A=b,O=!0));a=r.x;s=r.y;Ge(w,B,c,d,e,g,h,k,l,m,n,p,q,r)&&(b=(r.x-l)*(r.x-l)+(r.y-m)*(r.y-m),b<A?O=!0:(r.x=a,r.y=s));return O}
function He(a,b,c,d,e,g,h,k,l,m,n,p,q){var r=0;0>=q&&(q=1E-6);if(qe(a,b,h,k,q,c,d)&&qe(a,b,h,k,q,e,g)){q=(a-h)*(m-p)-(b-k)*(l-n);if(0===q)return r;var s=((a*k-b*h)*(l-n)-(a-h)*(l*p-m*n))/q,u=((a*k-b*h)*(m-p)-(b-k)*(l*p-m*n))/q;if(s>=n)return r;if((l>n?l-n:n-l)<(m>p?m-p:p-m)){if(a=l=0,b<k?(l=b,a=k):(l=k,a=b),u<l||u>a)return r}else if(a<h?(l=a,a=h):l=h,s<l||s>a)return r;0<q?r++:0>q&&r--}else{var s=(a+c)/2,u=(b+d)/2,t=(c+e)/2,y=(d+g)/2;e=(e+h)/2;g=(g+k)/2;d=(s+t)/2;c=(u+y)/2;var t=(t+e)/2,y=(y+g)/2,
w=(d+t)/2,B=(c+y)/2,r=r+He(a,b,s,u,d,c,w,B,l,m,n,p,q),r=r+He(w,B,t,y,e,g,h,k,l,m,n,p,q)}return r}
function fb(a,b,c,d,e,g,h){if(Eb(a,c)){var k=0;c=0;b<d?(k=b,c=d):(k=d,c=b);d=g;if(d<k)return h.x=a,h.y=k,!1;if(d>c)return h.x=a,h.y=c,!1;h.x=a;h.y=d;return!0}if(Eb(b,d)){a<c?k=a:(k=c,c=a);d=e;if(d<k)return h.x=k,h.y=b,!1;if(d>c)return h.x=c,h.y=b,!1;h.x=d;h.y=b;return!0}k=((a-e)*(a-c)+(b-g)*(b-d))/((c-a)*(c-a)+(d-b)*(d-b));if(-5E-6>k)return h.x=a,h.y=b,!1;if(1.000005<k)return h.x=c,h.y=d,!1;h.x=a+k*(c-a);h.y=b+k*(d-b);return!0}
function Ie(a,b,c,d,e,g,h,k,l){if(Q(a,c)&&Q(b,d))return l.x=a,l.y=b,!1;if(Eb(e,h)){if(Eb(a,c))return fb(a,b,c,d,e,g,l),!1;g=(d-b)/(c-a)*(e-a)+b;return fb(a,b,c,d,e,g,l)}k=(k-g)/(h-e);if(Eb(a,c)){g=k*(a-e)+g;c=h=0;b<d?(h=b,c=d):(h=d,c=b);if(g<h)return l.x=a,l.y=h,!1;if(g>c)return l.x=a,l.y=c,!1;l.x=a;l.y=g;return!0}h=(d-b)/(c-a);if(Eb(k,h))return fb(a,b,c,d,e,g,l),!1;e=(h*a-k*e+g-b)/(h-k);if(Eb(h,0)){a<c?h=a:(h=c,c=a);if(e<h)return l.x=h,l.y=b,!1;if(e>c)return l.x=c,l.y=b,!1;l.x=e;l.y=b;return!0}g=
h*(e-a)+b;return fb(a,b,c,d,e,g,l)}function Je(a,b,c,d,e,g,h,k,l){var m=1E21,n=a,p=b;if(Ie(a,b,a,d,e,g,h,k,l)){var q=(l.x-e)*(l.x-e)+(l.y-g)*(l.y-g);q<m&&(m=q,n=l.x,p=l.y)}Ie(c,b,c,d,e,g,h,k,l)&&(q=(l.x-e)*(l.x-e)+(l.y-g)*(l.y-g),q<m&&(m=q,n=l.x,p=l.y));Ie(a,b,c,b,e,g,h,k,l)&&(q=(l.x-e)*(l.x-e)+(l.y-g)*(l.y-g),q<m&&(m=q,n=l.x,p=l.y));Ie(a,d,c,d,e,g,h,k,l)&&(q=(l.x-e)*(l.x-e)+(l.y-g)*(l.y-g),q<m&&(m=q,n=l.x,p=l.y));l.x=n;l.y=p;return 1E21>m}
function Ne(a,b,c,d,e,g,h,k,l){c=a-c;var m=e-h,n=h=0;0===c||0===m?0===c?(k=(g-k)/m,h=a,n=k*h+(g-k*e)):(d=(b-d)/c,h=e,n=d*h+(b-d*a)):(d=(b-d)/c,k=(g-k)/m,a=b-d*a,h=(g-k*e-a)/(d-k),n=d*h+a);l.n(h,n);return l}
function Oe(a,b,c){var d=b.x,e=b.y,g=c.x,h=c.y,k=a.left,l=a.right,m=a.top,n=a.bottom;return d===g?(g=a=0,e<h?(a=e,g=h):(a=h,g=e),k<=d&&d<=l&&a<=n&&g>=m):e===h?(d<g?a=d:(a=g,g=d),m<=e&&e<=n&&a<=l&&g>=k):a.Pa(b)||a.Pa(c)||Pe(k,m,l,m,d,e,g,h)||Pe(l,m,l,n,d,e,g,h)||Pe(l,n,k,n,d,e,g,h)||Pe(k,n,k,m,d,e,g,h)?!0:!1}function Pe(a,b,c,d,e,g,h,k){return 0>=Qe(a,b,c,d,e,g)*Qe(a,b,c,d,h,k)&&0>=Qe(e,g,h,k,a,b)*Qe(e,g,h,k,c,d)}
function Qe(a,b,c,d,e,g){c-=a;d-=b;a=e-a;b=g-b;g=a*d-b*c;0===g&&(g=a*c+b*d,0<g&&(g=(a-c)*c+(b-d)*d,0>g&&(g=0)));return 0>g?-1:0<g?1:0}function Re(a){0>a&&(a+=360);360<=a&&(a-=360);return a}
function Se(a,b,c,d){var e=Math.PI;d||(b*=e/180,c*=e/180);d=b<c?1:-1;var g=[],h=e/2,k=b;for(b=Math.min(2*e,Math.abs(c-b));1E-5<b;){c=k+d*Math.min(b,h);var e=(c-k)/2,l=a*Math.cos(e),m=a*Math.sin(e),n=-m,p=l*l+n*n,q=p+l*l+n*m,p=4/3*(Math.sqrt(2*p*q)-q)/(l*m-n*l),m=l-p*n,l=n+p*l,n=-l,p=e+k,e=Math.cos(p),p=Math.sin(p);g.push([0+a*Math.cos(k),0+a*Math.sin(k),0+m*e-l*p,0+m*p+l*e,0+m*e-n*p,0+m*p+n*e,0+a*Math.cos(c),0+a*Math.sin(c)]);b-=Math.abs(c-k);k=c}return g}
function jb(a,b,c,d,e,g,h){c=Math.floor((a-c)/e)*e+c;d=Math.floor((b-d)/g)*g+d;var k=c;c+e-a<e/2&&(k=c+e);a=d;d+g-b<g/2&&(a=d+g);h.n(k,a)}function Te(a,b){var c=Math.max(a,b),d=Math.min(a,b),e=1,g=1;do e=c%d,c=g=d,d=e;while(0<e);return g}
function Ue(a,b,c,d){var e=0>c,g=0>d,h=0,k=h=0;a<b?(h=1,k=0):(h=0,k=1);var l=0,m=0,n=0,p=0,l=0===h?a:b,n=0===h?c:d;if(0===h?e:g)n=-n;h=k;m=0===h?a:b;p=0===h?c:d;if(0===h?e:g)p=-p;a=a=0;if(0<p)if(0<n){b=l*l;a=m*m;l*=n;c=m*p;d=-a+c;e=-a+Math.sqrt(l*l+c*c);m=d;for(g=0;9999999999>g;++g){m=.5*(d+e);if(m===d||m===e)break;k=l/(m+b);h=c/(m+a);k=k*k+h*h-1;if(0<k)d=m;else if(0>k)e=m;else break}n=b*n/(m+b)-n;p=a*p/(m+a)-p;a=Math.sqrt(n*n+p*p)}else a=Math.abs(p-m);else p=l*l-m*m,a=l*n,a<p?(p=a/p,a=m*Math.sqrt(Math.abs(1-
p*p)),n=l*p-n,a=Math.sqrt(n*n+a*a)):a=Math.abs(n-l);return a}function Ve(a){1<arguments.length&&D.k("Geometry constructor can take at most one optional argument, the Geometry type.");D.vc(this);this.J=!1;void 0===a?a=We:v&&D.Da(a,Ve,Ve,"constructor:type");this.ca=a;this.Ib=this.Cb=this.Ic=this.yc=0;this.hk=new K(Xe);this.Vv=this.hk.I;this.Fv=(new C).freeze();this.pb=!0;this.cr=this.Go=null;this.dr=NaN;this.xi=ec;this.yi=vc;this.jp=this.lp=NaN;this.Xi=rf}D.ka("Geometry",Ve);D.Di(Ve);
Ve.prototype.copy=function(){var a=new Ve;a.ca=this.ca;a.yc=this.yc;a.Ic=this.Ic;a.Cb=this.Cb;a.Ib=this.Ib;for(var b=this.hk.o,c=b.length,d=a.hk,e=0;e<c;e++){var g=b[e].copy();d.add(g)}a.Vv=this.Vv;a.Fv.assign(this.Fv);a.pb=this.pb;a.Go=this.Go;a.cr=this.cr;a.dr=this.dr;a.xi=this.xi.V();a.yi=this.yi.V();a.lp=this.lp;a.jp=this.jp;a.Xi=this.Xi;return a};var sf;Ve.Line=sf=D.s(Ve,"Line",0);var tf;Ve.Rectangle=tf=D.s(Ve,"Rectangle",1);var uf;Ve.Ellipse=uf=D.s(Ve,"Ellipse",2);var We;
Ve.Path=We=D.s(Ve,"Path",3);Ve.prototype.Oa=function(){this.freeze();Object.freeze(this);return this};Ve.prototype.freeze=function(){this.J=!0;var a=this.nc;a.freeze();for(var a=a.o,b=a.length,c=0;c<b;c++)a[c].freeze();return this};Ve.prototype.Xa=function(){Object.isFrozen(this)&&D.k("cannot thaw constant: "+this);this.J=!1;var a=this.nc;a.Xa();for(var a=a.o,b=a.length,c=0;c<b;c++)a[c].Xa();return this};
Ve.prototype.equalsApprox=Ve.prototype.Uc=function(a){if(!(a instanceof Ve))return!1;if(this.type!==a.type)return this.type===sf&&a.type===We?vf(this,a):a.type===sf&&this.type===We?vf(a,this):!1;if(this.type===We){var b=this.nc.o;a=a.nc.o;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b[d].Uc(a[d]))return!1;return!0}return Q(this.ma,a.ma)&&Q(this.ja,a.ja)&&Q(this.G,a.G)&&Q(this.H,a.H)};
function vf(a,b){if(a.type!==sf||b.type!==We)return!1;if(1===b.nc.count){var c=b.nc.ea(0);if(1===c.Eb.count&&Q(a.ma,c.ma)&&Q(a.ja,c.ja)&&(c=c.Eb.ea(0),c.type===wf&&Q(a.G,c.G)&&Q(a.H,c.H)))return!0}return!1}var xf;Ve.stringify=xf=function(a){return a.toString()};Ve.prototype.qc=function(a){a.Oe===Ve?this.type=a:D.Yj(this,a)};
Ve.prototype.toString=function(a){void 0===a&&(a=-1);switch(this.type){case sf:return 0>a?"M"+this.ma.toString()+" "+this.ja.toString()+"L"+this.G.toString()+" "+this.H.toString():"M"+this.ma.toFixed(a)+" "+this.ja.toFixed(a)+"L"+this.G.toFixed(a)+" "+this.H.toFixed(a);case tf:var b=new C(this.ma,this.ja,0,0);b.oH(this.G,this.H,0,0);return 0>a?"M"+b.x.toString()+" "+b.y.toString()+"H"+b.right.toString()+"V"+b.bottom.toString()+"H"+b.left.toString()+"z":"M"+b.x.toFixed(a)+" "+b.y.toFixed(a)+"H"+b.right.toFixed(a)+
"V"+b.bottom.toFixed(a)+"H"+b.left.toFixed(a)+"z";case uf:b=new C(this.ma,this.ja,0,0);b.oH(this.G,this.H,0,0);if(0>a){var c=b.left.toString()+" "+(b.y+b.height/2).toString(),d=b.right.toString()+" "+(b.y+b.height/2).toString();return"M"+c+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+d+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+c}c=b.left.toFixed(a)+" "+(b.y+b.height/2).toFixed(a);d=b.right.toFixed(a)+" "+(b.y+b.height/2).toFixed(a);return"M"+c+"A"+(b.width/
2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+d+"A"+(b.width/2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+c;case We:for(var b="",c=this.nc.o,d=c.length,e=0;e<d;e++){var g=c[e];0<e&&(b+=" x ");g.Bu&&(b+="F ");b+=g.toString(a)}return b;default:return this.type.toString()}};
Ve.fillPath=function(a){"string"!==typeof a&&D.jc(a,"string",Ve,"fillPath:str");a=a.split(/[Xx]/);for(var b=a.length,c="",d=0;d<b;d++)var e=a[d],c=null!==e.match(/[Ff]/)?0===d?c+e:c+("X"+(" "===e[0]?"":" ")+e):c+((0===d?"":"X ")+"F"+(" "===e[0]?"":" ")+e);return c};var yf;
Ve.parse=yf=function(a,b){function c(){return m>=u-1?!0:null!==l[m+1].match(/[A-Za-z]/)}function d(){m++;return l[m]}function e(){var a=new N(parseFloat(d()),parseFloat(d()));n===n.toLowerCase()&&(a.x=s.x+a.x,a.y=s.y+a.y);return a}function g(){return s=e()}function h(){return r=e()}function k(){return"c"!==p.toLowerCase()&&"s"!==p.toLowerCase()?s:new N(2*s.x-r.x,2*s.y-r.y)}void 0===b&&(b=!1);"string"!==typeof a&&D.jc(a,"string",Ve,"parse:str");a=a.replace(/,/gm," ");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,
"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([^\s])/gm,"$1 $2");a=a.replace(/([^\s])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,"$1 $2");a=a.replace(/([0-9])([+\-])/gm,"$1 $2");a=a.replace(/(\.[0-9]*)(\.)/gm,"$1 $2");a=a.replace(/([Aa](\s+[0-9]+){3})\s+([01])\s*([01])/gm,"$1 $3 $4 ");a=a.replace(/[\s\r\t\n]+/gm," ");a=a.replace(/^\s+|\s+$/g,"");for(var l=a.split(" "),m=-1,n="",p="",q=new N(0,0),r=new N(0,0),s=new N(0,
0),u=l.length,t=D.v(),y=!1,w=!1,B=!0,A=null;!(m>=u-1);)if(p=n,n=d(),""!==n)switch(n.toUpperCase()){case "X":B=!0;w=y=!1;break;case "M":A=g();null===t.fc||!0===B?(T(t,A.x,A.y,y,!1,!w),B=!1):t.moveTo(A.x,A.y);for(q=s;!c();)A=g(),t.lineTo(A.x,A.y);break;case "L":for(;!c();)A=g(),t.lineTo(A.x,A.y);break;case "H":for(;!c();)s=A=new N((n===n.toLowerCase()?s.x:0)+parseFloat(d()),s.y),t.lineTo(s.x,s.y);break;case "V":for(;!c();)s=A=new N(s.x,(n===n.toLowerCase()?s.y:0)+parseFloat(d())),t.lineTo(s.x,s.y);
break;case "C":for(;!c();){var O=e(),H=h(),A=g();U(t,O.x,O.y,H.x,H.y,A.x,A.y)}break;case "S":for(;!c();)O=k(),H=h(),A=g(),U(t,O.x,O.y,H.x,H.y,A.x,A.y);break;case "Q":for(;!c();)H=h(),A=g(),zf(t,H.x,H.y,A.x,A.y);break;case "T":for(;!c();)r=H=k(),A=g(),zf(t,H.x,H.y,A.x,A.y);break;case "B":for(;!c();){var A=parseFloat(d()),O=parseFloat(d()),H=parseFloat(d()),R=parseFloat(d()),aa=parseFloat(d()),V=aa,ea=!1;c()||(V=parseFloat(d()),c()||(ea=0!==parseFloat(d())));n===n.toLowerCase()&&(H+=s.x,R+=s.y);t.arcTo(A,
O,H,R,aa,V,ea)}break;case "A":for(;!c();)O=Math.abs(parseFloat(d())),H=Math.abs(parseFloat(d())),R=parseFloat(d()),aa=!!parseFloat(d()),V=!!parseFloat(d()),A=g(),Af(t,O,H,R,aa,V,A.x,A.y);break;case "Z":A=t.q.nc.o[t.q.nc.length-1];X(t);s=q;break;case "F":A="";for(O=1;l[m+O];)if(null!==l[m+O].match(/[Uu]/))O++;else if(null===l[m+O].match(/[A-Za-z]/))O++;else{A=l[m+O];break}A.match(/[Mm]/)?y=!0:Bf(t);break;case "U":A="";for(O=1;l[m+O];)if(null!==l[m+O].match(/[Ff]/))O++;else if(null===l[m+O].match(/[A-Za-z]/))O++;
else{A=l[m+O];break}A.match(/[Mm]/)?w=!0:t.kb(!1)}q=t.q;D.u(t);if(b)for(t=q.nc.j;t.next();)A=t.value,A.Bu=!0;return q};function Cf(a,b){for(var c=a.length,d=D.P(),e=0;e<c;e++){var g=a[e];d.x=g[0];d.y=g[1];b.xb(d);g[0]=d.x;g[1]=d.y;d.x=g[2];d.y=g[3];b.xb(d);g[2]=d.x;g[3]=d.y;d.x=g[4];d.y=g[5];b.xb(d);g[4]=d.x;g[5]=d.y;d.x=g[6];d.y=g[7];b.xb(d);g[6]=d.x;g[7]=d.y}D.A(d)}
Ve.prototype.Yx=function(){if(this.pb||this.Vv!==this.nc.I)return!0;for(var a=this.nc.o,b=a.length,c=0;c<b;c++)if(a[c].Yx())return!0;return!1};Ve.prototype.Qy=function(){this.pb=!1;this.cr=this.Go=null;this.dr=NaN;this.Vv=this.nc.I;for(var a=this.nc.o,b=a.length,c=0;c<b;c++)a[c].Qy()};Ve.prototype.Mh=function(){var a=this.Fv;a.Xa();isNaN(this.lp)||isNaN(this.jp)?a.n(0,0,0,0):a.n(0,0,this.lp,this.jp);Df(this,a,!1);Ub(a,0,0,0,0);a.freeze()};
Ve.prototype.computeBoundsWithoutOrigin=Ve.prototype.hI=function(){var a=new C;Df(this,a,!0);return a};
function Df(a,b,c){switch(a.type){case sf:case tf:case uf:c?b.n(a.yc,a.Ic,0,0):Ub(b,a.yc,a.Ic,0,0);Ub(b,a.Cb,a.Ib,0,0);break;case We:var d=a.nc;a=d.o;for(var d=d.length,e=0;e<d;e++){var g=a[e];c&&0===e?b.n(g.ma,g.ja,0,0):Ub(b,g.ma,g.ja,0,0);for(var h=g.Eb.o,k=h.length,l=g.ma,m=g.ja,n=0;n<k;n++){var p=h[n];switch(p.type){case wf:case Ef:l=p.G;m=p.H;Ub(b,l,m,0,0);break;case Ff:Ae(l,m,p.Cc,p.Zc,p.Uh,p.Vh,p.G,p.H,.5,b);l=p.G;m=p.H;break;case Sf:Ee(l,m,p.Cc,p.Zc,p.G,p.H,.5,b);l=p.G;m=p.H;break;case Tf:case Uf:var q=
p.type===Tf?Vf(p,g):Wf(p,g,l,m),r=q.length;if(0===r){l=p.pa;m=p.va;Ub(b,l,m,0,0);break}for(var p=null,s=0;s<r;s++)p=q[s],Ae(p[0],p[1],p[2],p[3],p[4],p[5],p[6],p[7],.5,b);null!==p&&(l=p[6],m=p[7]);break;default:D.k("Unknown Segment type: "+p.type)}}}break;default:D.k("Unknown Geometry type: "+a.type)}}Ve.prototype.normalize=Ve.prototype.normalize=function(){this.J&&D.qa(this);var a=this.hI();this.offset(-a.x,-a.y);return new N(-a.x,-a.y)};
Ve.prototype.offset=Ve.prototype.offset=function(a,b){this.J&&D.qa(this);v&&(D.p(a,Ve,"offset"),D.p(b,Ve,"offset"));this.transform(1,0,0,1,a,b);return this};Ve.prototype.scale=Ve.prototype.scale=function(a,b){this.J&&D.qa(this);v&&(D.p(a,Ve,"scale:x"),D.p(b,Ve,"scale:y"),0===a&&D.ua(a,"scale must be non-zero",Ve,"scale:x"),0===b&&D.ua(b,"scale must be non-zero",Ve,"scale:y"));this.transform(a,0,0,b,0,0);return this};
Ve.prototype.rotate=Ve.prototype.rotate=function(a,b,c){this.J&&D.qa(this);void 0===b&&(b=0);void 0===c&&(c=0);v&&(D.p(a,Ve,"rotate:angle"),D.p(b,Ve,"rotate:x"),D.p(c,Ve,"rotate:y"));var d=D.Rf();d.reset();d.rotate(a,b,c);this.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);D.Je(d);return this};
Ve.prototype.transform=Ve.prototype.transform=function(a,b,c,d,e,g){var h=0,k=0;switch(this.type){case sf:case tf:case uf:h=this.yc;k=this.Ic;this.yc=h*a+k*c+e;this.Ic=h*b+k*d+g;h=this.Cb;k=this.Ib;this.Cb=h*a+k*c+e;this.Ib=h*b+k*d+g;break;case We:for(var l=this.nc.o,m=l.length,n=0;n<m;n++){var p=l[n],h=p.ma,k=p.ja;p.ma=h*a+k*c+e;p.ja=h*b+k*d+g;for(var p=p.Eb.o,q=p.length,r=0;r<q;r++){var s=p[r];switch(s.type){case wf:case Ef:h=s.G;k=s.H;s.G=h*a+k*c+e;s.H=h*b+k*d+g;break;case Ff:h=s.Cc;k=s.Zc;s.Cc=
h*a+k*c+e;s.Zc=h*b+k*d+g;h=s.Uh;k=s.Vh;s.Uh=h*a+k*c+e;s.Vh=h*b+k*d+g;h=s.G;k=s.H;s.G=h*a+k*c+e;s.H=h*b+k*d+g;break;case Sf:h=s.Cc;k=s.Zc;s.Cc=h*a+k*c+e;s.Zc=h*b+k*d+g;h=s.G;k=s.H;s.G=h*a+k*c+e;s.H=h*b+k*d+g;break;case Tf:h=s.pa;k=s.va;s.pa=h*a+k*c+e;s.va=h*b+k*d+g;0!==b&&(h=180*Math.atan2(b,a)/Math.PI,0>h&&(h+=360),s.Me+=h);0>a&&(s.Me=180-s.Me,s.Df=-s.Df);0>d&&(s.Me=-s.Me,s.Df=-s.Df);s.radiusX*=Math.sqrt(a*a+c*c);void 0!==s.radiusY&&(s.radiusY*=Math.sqrt(b*b+d*d));break;case Uf:h=s.G;k=s.H;s.G=h*
a+k*c+e;s.H=h*b+k*d+g;0!==b&&(h=180*Math.atan2(b,a)/Math.PI,0>h&&(h+=360),s.$j+=h);0>a&&(s.$j=180-s.$j,s.xm=!s.xm);0>d&&(s.$j=-s.$j,s.xm=!s.xm);s.radiusX*=Math.sqrt(a*a+c*c);s.radiusY*=Math.sqrt(b*b+d*d);break;default:D.k("Unknown Segment type: "+s.type)}}}}this.pb=!0;return this};
Ve.prototype.Pa=function(a,b,c,d){var e=a.x,g=a.y,h=this.qb.x-20;a=a.y;for(var k=0,l=0,m=0,n=0,p=0,q=0,r=this.nc.o,s=r.length,u=0;u<s;u++){var t=r[u];if(t.Bu){if(c&&t.Pa(e,g,b))return!0;for(var y=t.Eb,l=t.ma,m=t.ja,w=l,B=m,A=y.o,O=0;O<=y.length;O++){var H,R;O!==y.length?(H=A[O],R=H.type,p=H.G,q=H.H):(R=wf,p=w,q=B);switch(R){case Ef:n=Xf(e,g,h,a,l,m,w,B);if(isNaN(n))return!0;k+=n;w=p;B=q;break;case wf:n=Xf(e,g,h,a,l,m,p,q);if(isNaN(n))return!0;k+=n;break;case Ff:n=He(l,m,H.Cc,H.Zc,H.Uh,H.Vh,p,q,h,
a,e,g,.5);k+=n;break;case Sf:n=He(l,m,(l+2*H.Cc)/3,(m+2*H.Zc)/3,(2*H.Cc+p)/3,(2*H.Zc+q)/3,p,q,h,a,e,g,.5);k+=n;break;case Tf:case Uf:R=H.type===Tf?Vf(H,t):Wf(H,t,l,m);var aa=R.length;if(0===aa){n=Xf(e,g,h,a,l,m,H.pa,H.va);if(isNaN(n))return!0;k+=n;break}for(var V=null,ea=0;ea<aa;ea++){V=R[ea];if(0===ea){n=Xf(e,g,h,a,l,m,V[0],V[1]);if(isNaN(n))return!0;k+=n}n=He(V[0],V[1],V[2],V[3],V[4],V[5],V[6],V[7],h,a,e,g,.5);k+=n}null!==V&&(p=V[6],q=V[7]);break;default:D.k("Unknown Segment type: "+H.type)}l=p;
m=q}if(0!==k)return!0;k=0}else if(t.Pa(e,g,d?b:b+2))return!0}return 0!==k};function Xf(a,b,c,d,e,g,h,k){if(qe(e,g,h,k,.05,a,b))return NaN;var l=(a-c)*(g-k);if(0===l)return 0;var m=((a*d-b*c)*(e-h)-(a-c)*(e*k-g*h))/l;b=(a*d-b*c)*(g-k)/l;if(m>=a)return 0;if((e>h?e-h:h-e)<(g>k?g-k:k-g)){if(e=a=0,g<k?(a=g,e=k):(a=k,e=g),b<a||b>e)return 0}else if(e<h?(a=e,e=h):a=h,m<a||m>e)return 0;return 0<l?1:-1}function Yf(a,b,c,d){a=a.nc.o;for(var e=a.length,g=0;g<e;g++)if(a[g].Pa(b,c,d))return!0;return!1}
Ve.prototype.getPointAlongPath=Ve.prototype.SI=function(a,b){0>a?a=0:1<a&&(a=1);void 0===b&&(b=new N);if(this.type===sf)return b.n(this.ma+a*(this.G-this.ma),this.ja+a*(this.H-this.ja)),b;for(var c=this.Px,d=this.mu,e=c.length,g=this.nu*a,h=0,k=0;k<e;k++)for(var l=d[k],m=l.length,n=0;n<m;n++){var p=l[n];if(h+p>=g)return d=(g-h)/p,c=c[k],k=c[2*n],e=c[2*n+1],b.n(k+(c[2*n+2]-k)*d,e+(c[2*n+3]-e)*d),b;h+=p}b.n(NaN,NaN);return b};
Ve.prototype.getAngleAlongPath=Ve.prototype.DF=function(a){0>a?a=0:1<a&&(a=1);var b=0;if(this.type===sf)return b=180*Math.atan2(this.H-this.ja,this.G-this.ma)/Math.PI;for(var b=this.Px,c=this.mu,d=b.length,e=this.nu*a,g=0,h=0;h<d;h++){var k=c[h],l=k.length;for(a=0;a<l;a++){var m=k[a];if(g+m>=e)return b=b[h],b=180*Math.atan2(b[2*a+3]-b[2*a+1],b[2*a+2]-b[2*a])/Math.PI;g+=m}}return NaN};
Ve.prototype.getFractionForPoint=Ve.prototype.NI=function(a){if(this.type===sf){var b=this.ma,c=this.ja,d=this.G,e=this.H;if(b!==d||c!==e){var g=a.x;a=a.y;var h=0,k=0;return b===d?(c<e?(h=c,k=e):(h=e,k=c),a<=h?h===c?0:1:a>=k?k===c?0:1:Math.abs(a-c)/(k-h)):c===e?(b<d?(h=b,k=d):(h=d,k=b),g<=h?h===b?0:1:g>=k?k===b?0:1:Math.abs(g-b)/(k-h)):((g-b)*(g-b)+(a-c)*(a-c))/((d-b)*(d-b)+(e-c)*(e-c))}}else if(this.type===tf){if(b=this.ma,c=this.ja,d=this.G,e=this.H,b!==d||c!==e){var h=d-b,k=e-c,l=2*h+2*k,g=a.x;
a=a.y;g=Math.min(Math.max(g,b),d);a=Math.min(Math.max(a,c),e);var b=Math.abs(g-b),d=Math.abs(g-d),c=Math.abs(a-c),e=Math.abs(a-e),m=Math.min(b,d,c,e);if(m===c)return g/l;if(m===d)return(h+a)/l;if(m===e)return(2*h+k-g)/l;if(m===b)return(2*h+2*k-a)/l}}else{for(var e=this.Px,h=this.mu,k=this.nu,l=D.P(),c=Infinity,b=d=0,g=e.length,n=m=0,p=0;p<g;p++)for(var q=e[p],r=h[p],s=q.length,u=0;u<s;u+=2){var t=q[u],y=q[u+1];if(0!==u){fb(m,n,t,y,a.x,a.y,l);var w=(l.x-a.x)*(l.x-a.x)+(l.y-a.y)*(l.y-a.y);w<c&&(c=w,
d=b,d+=Math.sqrt((l.x-m)*(l.x-m)+(l.y-n)*(l.y-n)));b+=r[(u-2)/2]}m=t;n=y}D.A(l);a=d/k;return 0>a?0:1<a?1:a}return 0};D.w(Ve,{Px:null},function(){Zf(this);return this.Go});
function Zf(a){if(null===a.Go){a.Yx()&&a.Qy();var b=a.Go=[],c=a.cr=[],d=[],e=[];if(a.type===sf)d.push(a.ma),d.push(a.ja),d.push(a.G),d.push(a.H),b.push(d),e.push(Math.sqrt((a.ma-a.G)*(a.ma-a.G)+(a.ja-a.H)*(a.ja-a.H))),c.push(e);else if(a.type===tf)d.push(a.ma),d.push(a.ja),d.push(a.G),d.push(a.ja),d.push(a.G),d.push(a.H),d.push(a.ma),d.push(a.H),d.push(a.ma),d.push(a.ja),b.push(d),e.push(Math.abs(a.ma-a.G)),e.push(Math.abs(a.ja-a.H)),e.push(Math.abs(a.ma-a.G)),e.push(Math.abs(a.ja-a.H)),c.push(e);
else if(a.type===uf){var g=new Xe;g.ma=a.G;g.ja=(a.ja+a.H)/2;var h=new $f(Tf);h.Me=0;h.Df=360;h.pa=(a.ma+a.G)/2;h.va=(a.ja+a.H)/2;h.radiusX=Math.abs(a.ma-a.G)/2;h.radiusY=Math.abs(a.ja-a.H)/2;g.add(h);a=Vf(h,g);e=a.length;if(0===e)d.push(h.pa),d.push(h.va);else for(var h=g.ma,g=g.ja,k=0;k<e;k++){var l=a[k];Be(h,g,l[2],l[3],l[4],l[5],l[6],l[7],.5,d);h=l[6];g=l[7]}b.push(d);c.push(ag(d))}else for(var m=a.nc.j;m.next();){var n=m.value,d=[];d.push(n.ma);d.push(n.ja);for(var h=n.ma,g=n.ja,p=h,q=g,r=n.Eb.o,
s=r.length,u=0;u<s;u++){var t=r[u];switch(t.ca){case Ef:4<=d.length&&(b.push(d),c.push(ag(d)));d=[];d.push(t.G);d.push(t.H);h=t.G;g=t.H;p=h;q=g;break;case wf:d.push(t.G);d.push(t.H);h=t.G;g=t.H;break;case Ff:Be(h,g,t.Sd,t.hf,t.Eh,t.Pg,t.Cb,t.Ib,.5,d);h=t.G;g=t.H;break;case Sf:Fe(h,g,t.Sd,t.hf,t.Cb,t.Ib,.5,d);h=t.G;g=t.H;break;case Tf:a=Vf(t,n);e=a.length;if(0===e){d.push(t.pa);d.push(t.va);h=t.pa;g=t.va;break}for(k=0;k<e;k++)l=a[k],Be(h,g,l[2],l[3],l[4],l[5],l[6],l[7],.5,d),h=l[6],g=l[7];break;case Uf:a=
Wf(t,n,h,g);e=a.length;if(0===e){d.push(t.pa);d.push(t.va);h=t.pa;g=t.va;break}for(k=0;k<e;k++)l=a[k],Be(h,g,l[2],l[3],l[4],l[5],l[6],l[7],.5,d),h=l[6],g=l[7];break;default:D.k("Segment not of valid type: "+t.ca)}t.hi&&(d.push(p),d.push(q))}4<=d.length&&(b.push(d),c.push(ag(d)))}}}D.w(Ve,{mu:null},function(){Zf(this);return this.cr});
D.w(Ve,{nu:null},function(){var a=this.dr;if(isNaN(a)){if(this.type===sf)var a=Math.abs(this.G-this.ma),b=Math.abs(this.H-this.ja),a=Math.sqrt(a*a+b*b);else if(this.type===tf)a=Math.abs(this.G-this.ma),b=Math.abs(this.H-this.ja),a=2*a+2*b;else for(var b=this.mu,c=b.length,d=a=0;d<c;d++)for(var e=b[d],g=e.length,h=0;h<g;h++)a+=e[h];this.dr=a}return a});function ag(a){for(var b=[],c=0,d=0,e=a.length,g=0;g<e;g+=2){var h=a[g],k=a[g+1];0!==g&&(c=Math.sqrt(ob(c,d,h,k)),b.push(c));c=h;d=k}return b}
D.defineProperty(Ve,{type:"type"},function(){return this.ca},function(a){this.ca!==a&&(v&&D.Da(a,Ve,Ve,"type"),this.J&&D.qa(this,a),this.ca=a,this.pb=!0)});D.defineProperty(Ve,{ma:"startX"},function(){return this.yc},function(a){this.yc!==a&&(v&&D.p(a,Ve,"startX"),this.J&&D.qa(this,a),this.yc=a,this.pb=!0)});D.defineProperty(Ve,{ja:"startY"},function(){return this.Ic},function(a){this.Ic!==a&&(v&&D.p(a,Ve,"startY"),this.J&&D.qa(this,a),this.Ic=a,this.pb=!0)});
D.defineProperty(Ve,{G:"endX"},function(){return this.Cb},function(a){this.Cb!==a&&(v&&D.p(a,Ve,"endX"),this.J&&D.qa(this,a),this.Cb=a,this.pb=!0)});D.defineProperty(Ve,{H:"endY"},function(){return this.Ib},function(a){this.Ib!==a&&(v&&D.p(a,Ve,"endY"),this.J&&D.qa(this,a),this.Ib=a,this.pb=!0)});D.defineProperty(Ve,{nc:"figures"},function(){return this.hk},function(a){this.hk!==a&&(v&&D.l(a,K,Ve,"figures"),this.J&&D.qa(this,a),this.hk=a,this.pb=!0)});
Ve.prototype.add=Ve.prototype.add=function(a){this.hk.add(a);return this};Ve.prototype.setSpots=function(a,b,c,d,e,g,h,k){this.J&&D.qa(this);this.xi=(new S(a,b,e,g)).freeze();this.yi=(new S(c,d,h,k)).freeze();return this};D.defineProperty(Ve,{C:"spot1"},function(){return this.xi},function(a){v&&D.l(a,S,Ve,"spot1");this.J&&D.qa(this,a);this.xi=a.V()});D.defineProperty(Ve,{D:"spot2"},function(){return this.yi},function(a){v&&D.l(a,S,Ve,"spot2");this.J&&D.qa(this,a);this.yi=a.V()});
D.defineProperty(Ve,{me:"defaultStretch"},function(){return this.Xi},function(a){v&&D.Da(a,P,Ve,"stretch");this.J&&D.qa(this,a);this.Xi=a});D.w(Ve,{qb:"bounds"},function(){this.Yx()&&(this.Qy(),this.Mh());return this.Fv});function Xe(a,b,c,d){D.vc(this);this.J=!1;void 0===c&&(c=!0);this.Xm=c;void 0===d&&(d=!0);this.mp=d;void 0!==a?(v&&D.p(a,Xe,"sx"),this.yc=a):this.yc=0;void 0!==b?(v&&D.p(b,Xe,"sy"),this.Ic=b):this.Ic=0;this.Hp=new K($f);this.Vw=this.Hp.I;this.pb=!0}D.ka("PathFigure",Xe);D.Di(Xe);
Xe.prototype.copy=function(){var a=new Xe;a.Xm=this.Xm;a.mp=this.mp;a.yc=this.yc;a.Ic=this.Ic;for(var b=this.Hp.o,c=b.length,d=a.Hp,e=0;e<c;e++){var g=b[e].copy();d.add(g)}a.Vw=this.Vw;a.pb=this.pb;return a};Xe.prototype.equalsApprox=Xe.prototype.Uc=function(a){if(!(a instanceof Xe&&Q(this.ma,a.ma)&&Q(this.ja,a.ja)))return!1;var b=this.Eb.o;a=a.Eb.o;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b[d].Uc(a[d]))return!1;return!0};f=Xe.prototype;
f.toString=function(a){void 0===a&&(a=-1);for(var b=0>a?"M"+this.ma.toString()+" "+this.ja.toString():"M"+this.ma.toFixed(a)+" "+this.ja.toFixed(a),c=this.Eb.o,d=c.length,e=0;e<d;e++)b+=" "+c[e].toString(a);return b};f.freeze=function(){this.J=!0;var a=this.Eb;a.freeze();for(var b=a.o,a=a.length,c=0;c<a;c++)b[c].freeze();return this};f.Xa=function(){this.J=!1;var a=this.Eb;a.Xa();for(var a=a.o,b=a.length,c=0;c<b;c++)a[c].Xa();return this};
f.Yx=function(){if(this.pb)return!0;var a=this.Eb;if(this.Vw!==a.I)return!0;for(var a=a.o,b=a.length,c=0;c<b;c++)if(a[c].pb)return!0;return!1};f.Qy=function(){this.pb=!1;var a=this.Eb;this.Vw=a.I;for(var a=a.o,b=a.length,c=0;c<b;c++){var d=a[c];d.pb=!1;d.yg=null}};D.defineProperty(Xe,{Bu:"isFilled"},function(){return this.Xm},function(a){v&&D.h(a,"boolean",Xe,"isFilled");this.J&&D.qa(this,a);this.Xm=a});
D.defineProperty(Xe,{$k:"isShadowed"},function(){return this.mp},function(a){v&&D.h(a,"boolean",Xe,"isShadowed");this.J&&D.qa(this,a);this.mp=a});D.defineProperty(Xe,{ma:"startX"},function(){return this.yc},function(a){v&&D.p(a,Xe,"startX");this.J&&D.qa(this,a);this.yc=a;this.pb=!0});D.defineProperty(Xe,{ja:"startY"},function(){return this.Ic},function(a){v&&D.p(a,Xe,"startY");this.J&&D.qa(this,a);this.Ic=a;this.pb=!0});
D.defineProperty(Xe,{Eb:"segments"},function(){return this.Hp},function(a){v&&D.l(a,K,Xe,"segments");this.J&&D.qa(this,a);this.Hp=a;this.pb=!0});Xe.prototype.add=Xe.prototype.add=function(a){this.Hp.add(a);return this};
Xe.prototype.Pa=function(a,b,c){for(var d=this.ma,e=this.ja,g=d,h=e,k=this.Eb.o,l=k.length,m=0;m<l;m++){var n=k[m];switch(n.type){case Ef:g=n.G;h=n.H;d=n.G;e=n.H;break;case wf:if(qe(d,e,n.G,n.H,c,a,b))return!0;d=n.G;e=n.H;break;case Ff:if(ze(d,e,n.Cc,n.Zc,n.Uh,n.Vh,n.G,n.H,.5,a,b,c))return!0;d=n.G;e=n.H;break;case Sf:if(De(d,e,n.Cc,n.Zc,n.G,n.H,.5,a,b,c))return!0;d=n.G;e=n.H;break;case Tf:case Uf:var p=n.type===Tf?Vf(n,this):Wf(n,this,d,e),q=p.length;if(0===q){if(qe(d,e,n.pa,n.va,c,a,b))return!0;
d=n.pa;e=n.va;break}for(var r=null,s=0;s<q;s++)if(r=p[s],0===s&&qe(d,e,r[0],r[1],c,a,b)||ze(r[0],r[1],r[2],r[3],r[4],r[5],r[6],r[7],.5,a,b,c))return!0;null!==r&&(d=r[6],e=r[7]);break;default:D.k("Unknown Segment type: "+n.type)}if(n.Vx&&(d!==g||e!==h)&&qe(d,e,g,h,c,a,b))return!0}return!1};
function $f(a,b,c,d,e,g,h,k){D.vc(this);this.J=!1;void 0===a?a=wf:v&&D.Da(a,$f,$f,"constructor:type");this.ca=a;void 0!==b?(v&&D.p(b,$f,"ex"),this.Cb=b):this.Cb=0;void 0!==c?(v&&D.p(c,$f,"ey"),this.Ib=c):this.Ib=0;void 0===d&&(d=0);void 0===e&&(e=0);void 0===g&&(g=0);void 0===h&&(h=0);a===Uf?(a=g%360,0>a&&(a+=360),this.Sd=a,this.hf=0,v&&D.p(d,$f,"x1"),this.Eh=Math.max(d,0),v&&D.p(e,$f,"y1"),this.Pg=Math.max(e,0),this.qp="boolean"===typeof h?!!h:!1,this.Ko=!!k):(v&&D.p(d,$f,"x1"),this.Sd=d,v&&D.p(e,
$f,"y1"),this.hf=e,v&&D.p(g,$f,"x2"),a===Tf&&(g=Math.max(g,0)),this.Eh=g,"number"===typeof h?(a===Tf&&(h=Math.max(h,0)),this.Pg=h):this.Pg=0,this.Ko=this.qp=!1);this.hi=!1;this.pb=!0;this.yg=null}D.ka("PathSegment",$f);D.Di($f);$f.prototype.copy=function(){var a=new $f;a.ca=this.ca;a.Cb=this.Cb;a.Ib=this.Ib;a.Sd=this.Sd;a.hf=this.hf;a.Eh=this.Eh;a.Pg=this.Pg;a.qp=this.qp;a.Ko=this.Ko;a.hi=this.hi;a.pb=this.pb;return a};
$f.prototype.equalsApprox=$f.prototype.Uc=function(a){if(!(a instanceof $f)||this.type!==a.type||this.Vx!==a.Vx)return!1;switch(this.type){case Ef:case wf:return Q(this.G,a.G)&&Q(this.H,a.H);case Ff:return Q(this.G,a.G)&&Q(this.H,a.H)&&Q(this.Cc,a.Cc)&&Q(this.Zc,a.Zc)&&Q(this.Uh,a.Uh)&&Q(this.Vh,a.Vh);case Sf:return Q(this.G,a.G)&&Q(this.H,a.H)&&Q(this.Cc,a.Cc)&&Q(this.Zc,a.Zc);case Tf:return Q(this.Me,a.Me)&&Q(this.Df,a.Df)&&Q(this.pa,a.pa)&&Q(this.va,a.va)&&Q(this.radiusX,a.radiusX)&&Q(this.radiusY,
a.radiusY);case Uf:return this.xm===a.xm&&this.Zx===a.Zx&&Q(this.$j,a.$j)&&Q(this.G,a.G)&&Q(this.H,a.H)&&Q(this.radiusX,a.radiusX)&&Q(this.radiusY,a.radiusY);default:return!1}};$f.prototype.qc=function(a){a.Oe===$f?this.type=a:D.Yj(this,a)};
$f.prototype.toString=function(a){void 0===a&&(a=-1);var b="";switch(this.type){case Ef:b=0>a?"M"+this.G.toString()+" "+this.H.toString():"M"+this.G.toFixed(a)+" "+this.H.toFixed(a);break;case wf:b=0>a?"L"+this.G.toString()+" "+this.H.toString():"L"+this.G.toFixed(a)+" "+this.H.toFixed(a);break;case Ff:b=0>a?"C"+this.Cc.toString()+" "+this.Zc.toString()+" "+this.Uh.toString()+" "+this.Vh.toString()+" "+this.G.toString()+" "+this.H.toString():"C"+this.Cc.toFixed(a)+" "+this.Zc.toFixed(a)+" "+this.Uh.toFixed(a)+
" "+this.Vh.toFixed(a)+" "+this.G.toFixed(a)+" "+this.H.toFixed(a);break;case Sf:b=0>a?"Q"+this.Cc.toString()+" "+this.Zc.toString()+" "+this.G.toString()+" "+this.H.toString():"Q"+this.Cc.toFixed(a)+" "+this.Zc.toFixed(a)+" "+this.G.toFixed(a)+" "+this.H.toFixed(a);break;case Tf:b=0>a?"B"+this.Me.toString()+" "+this.Df.toString()+" "+this.pa.toString()+" "+this.va.toString()+" "+this.radiusX:"B"+this.Me.toFixed(a)+" "+this.Df.toFixed(a)+" "+this.pa.toFixed(a)+" "+this.va.toFixed(a)+" "+this.radiusX;
break;case Uf:b=0>a?"A"+this.radiusX.toString()+" "+this.radiusY.toString()+" "+this.$j.toString()+" "+(this.Zx?1:0)+" "+(this.xm?1:0)+" "+this.G.toString()+" "+this.H.toString():"A"+this.radiusX.toFixed(a)+" "+this.radiusY.toFixed(a)+" "+this.$j.toFixed(a)+" "+(this.Zx?1:0)+" "+(this.xm?1:0)+" "+this.G.toFixed(a)+" "+this.H.toFixed(a);break;default:b=this.type.toString()}return b+(this.hi?"z":"")};var Ef;$f.Move=Ef=D.s($f,"Move",0);var wf;$f.Line=wf=D.s($f,"Line",1);var Ff;
$f.Bezier=Ff=D.s($f,"Bezier",2);var Sf;$f.QuadraticBezier=Sf=D.s($f,"QuadraticBezier",3);var Tf;$f.Arc=Tf=D.s($f,"Arc",4);var Uf;$f.SvgArc=Uf=D.s($f,"SvgArc",4);$f.prototype.freeze=function(){this.J=!0;return this};$f.prototype.Xa=function(){this.J=!1;return this};$f.prototype.close=$f.prototype.close=function(){this.hi=!0;return this};
function Vf(a,b){if(null!==a.yg&&!1===b.pb)return a.yg;var c=a.radiusX,d=a.radiusY;void 0===d&&(d=c);if(0===c||0===d)return a.yg=[],a.yg;var e=a.Sd,g=a.hf,h=Se(c<d?c:d,a.Me,a.Me+a.Df,!1);if(c!==d){var k=D.Rf();k.reset();c<d?k.scale(1,d/c):k.scale(c/d,1);Cf(h,k);D.Je(k)}c=h.length;for(d=0;d<c;d++)k=h[d],k[0]+=e,k[1]+=g,k[2]+=e,k[3]+=g,k[4]+=e,k[5]+=g,k[6]+=e,k[7]+=g;a.yg=h;return a.yg}
function Wf(a,b,c,d){function e(a,b,c,d){return(a*d<b*c?-1:1)*Math.acos((a*c+b*d)/(Math.sqrt(a*a+b*b)*Math.sqrt(c*c+d*d)))}if(null!==a.yg&&!1===b.pb)return a.yg;b=a.Eh;var g=a.Pg;if(0===b||0===g)return a.yg=[],a.yg;var h=Math.PI/180*a.Sd,k=a.qp,l=a.Ko,m=a.Cb,n=a.Ib,p=Math.cos(h),q=Math.sin(h),r=p*(c-m)/2+q*(d-n)/2,h=-q*(c-m)/2+p*(d-n)/2,s=r*r/(b*b)+h*h/(g*g);1<s&&(b*=Math.sqrt(s),g*=Math.sqrt(s));s=(k===l?-1:1)*Math.sqrt((b*b*g*g-b*b*h*h-g*g*r*r)/(b*b*h*h+g*g*r*r));isNaN(s)&&(s=0);k=s*b*h/g;s=s*-g*
r/b;isNaN(k)&&(k=0);isNaN(s)&&(s=0);c=(c+m)/2+p*k-q*s;d=(d+n)/2+q*k+p*s;n=e(1,0,(r-k)/b,(h-s)/g);p=(r-k)/b;m=(h-s)/g;r=(-r-k)/b;k=(-h-s)/g;h=e(p,m,r,k);r=(p*r+m*k)/(Math.sqrt(p*p+m*m)*Math.sqrt(r*r+k*k));-1>=r?h=Math.PI:1<=r&&(h=0);!l&&0<h&&(h-=2*Math.PI);l&&0>h&&(h+=2*Math.PI);l=b>g?1:b/g;r=b>g?g/b:1;b=Se(b>g?b:g,n,n+h,!0);g=D.Rf();g.reset();g.translate(c,d);g.rotate(a.Sd,0,0);g.scale(l,r);Cf(b,g);D.Je(g);a.yg=b;return a.yg}
D.defineProperty($f,{Vx:"isClosed"},function(){return this.hi},function(a){this.hi!==a&&(this.hi=a,this.pb=!0)});D.defineProperty($f,{type:"type"},function(){return this.ca},function(a){v&&D.Da(a,$f,$f,"type");this.J&&D.qa(this,a);this.ca=a;this.pb=!0});D.defineProperty($f,{G:"endX"},function(){return this.Cb},function(a){v&&D.p(a,$f,"endX");this.J&&D.qa(this,a);this.Cb=a;this.pb=!0});
D.defineProperty($f,{H:"endY"},function(){return this.Ib},function(a){v&&D.p(a,$f,"endY");this.J&&D.qa(this,a);this.Ib=a;this.pb=!0});D.defineProperty($f,{Cc:"point1X"},function(){return this.Sd},function(a){v&&D.p(a,$f,"point1X");this.J&&D.qa(this,a);this.Sd=a;this.pb=!0});D.defineProperty($f,{Zc:"point1Y"},function(){return this.hf},function(a){v&&D.p(a,$f,"point1Y");this.J&&D.qa(this,a);this.hf=a;this.pb=!0});
D.defineProperty($f,{Uh:"point2X"},function(){return this.Eh},function(a){v&&D.p(a,$f,"point2X");this.J&&D.qa(this,a);this.Eh=a;this.pb=!0});D.defineProperty($f,{Vh:"point2Y"},function(){return this.Pg},function(a){v&&D.p(a,$f,"point2Y");this.J&&D.qa(this,a);this.Pg=a;this.pb=!0});D.defineProperty($f,{pa:"centerX"},function(){return this.Sd},function(a){v&&D.p(a,$f,"centerX");this.J&&D.qa(this,a);this.Sd=a;this.pb=!0});
D.defineProperty($f,{va:"centerY"},function(){return this.hf},function(a){v&&D.p(a,$f,"centerY");this.J&&D.qa(this,a);this.hf=a;this.pb=!0});D.defineProperty($f,{radiusX:"radiusX"},function(){return this.Eh},function(a){v&&D.p(a,$f,"radiusX");0>a&&D.ua(a,">= zero",$f,"radiusX");this.J&&D.qa(this,a);this.Eh=a;this.pb=!0});D.defineProperty($f,{radiusY:"radiusY"},function(){return this.Pg},function(a){v&&D.p(a,$f,"radiusY");0>a&&D.ua(a,">= zero",$f,"radiusY");this.J&&D.qa(this,a);this.Pg=a;this.pb=!0});
D.defineProperty($f,{Me:"startAngle"},function(){return this.Cb},function(a){this.Cb!==a&&(this.J&&D.qa(this,a),v&&D.p(a,$f,"startAngle"),a%=360,0>a&&(a+=360),this.Cb=a,this.pb=!0)});D.defineProperty($f,{Df:"sweepAngle"},function(){return this.Ib},function(a){v&&D.p(a,$f,"sweepAngle");this.J&&D.qa(this,a);360<a&&(a=360);-360>a&&(a=-360);this.Ib=a;this.pb=!0});D.defineProperty($f,{xm:"isClockwiseArc"},function(){return this.Ko},function(a){this.J&&D.qa(this,a);this.Ko=a;this.pb=!0});
D.defineProperty($f,{Zx:"isLargeArc"},function(){return this.qp},function(a){this.J&&D.qa(this,a);this.qp=a;this.pb=!0});D.defineProperty($f,{$j:"xAxisRotation"},function(){return this.Sd},function(a){v&&D.p(a,$f,"xAxisRotation");a%=360;0>a&&(a+=360);this.J&&D.qa(this,a);this.Sd=a;this.pb=!0});
function bg(){this.ia=null;this.pA=(new N(0,0)).freeze();this.xz=(new N(0,0)).freeze();this.Cv=this.yw=0;this.Dv=1;this.mw="";this.hx=this.Rv=!1;this.Ov=this.Ev=0;this.bk=this.Zv=this.iw=!1;this.Rr=null;this.ex=0;this.Tg=this.bx=null}D.ka("InputEvent",bg);
bg.prototype.copy=function(){var a=new bg;a.ia=this.ia;a.pA.assign(this.se);a.xz.assign(this.ga);a.yw=this.yw;a.Cv=this.Cv;a.Dv=this.Dv;a.mw=this.mw;a.Rv=this.Rv;a.hx=this.hx;a.Ev=this.Ev;a.Ov=this.Ov;a.iw=this.iw;a.Zv=this.Zv;a.bk=this.bk;a.Rr=this.Rr;a.ex=this.ex;a.bx=this.bx;a.Tg=this.Tg;return a};
bg.prototype.toString=function(){var a="^";0!==this.Md&&(a+="M:"+this.Md);0!==this.button&&(a+="B:"+this.button);""!==this.key&&(a+="K:"+this.key);0!==this.Pe&&(a+="C:"+this.Pe);0!==this.om&&(a+="D:"+this.om);this.Mc&&(a+="h");this.bubbles&&(a+="b");null!==this.ga&&(a+="@"+this.ga.toString());return a};D.defineProperty(bg,{g:"diagram"},function(){return this.ia},function(a){this.ia=a});D.defineProperty(bg,{se:"viewPoint"},function(){return this.pA},function(a){D.l(a,N,bg,"viewPoint");this.pA.assign(a)});
D.defineProperty(bg,{ga:"documentPoint"},function(){return this.xz},function(a){D.l(a,N,bg,"documentPoint");this.xz.assign(a)});bg.prototype.getMultiTouchViewPoint=bg.prototype.Rx=function(a,b){var c=this.g;if(null===c)return b;cg(c,this.event,a,b);return b};bg.prototype.getMultiTouchDocumentPoint=function(a,b){var c=this.g;if(null===c)return b;cg(c,this.event,a,b);b.assign(c.dC(b));return b};D.defineProperty(bg,{Md:"modifiers"},function(){return this.yw},function(a){this.yw=a});
D.defineProperty(bg,{button:"button"},function(){return this.Cv},function(a){this.Cv=a;if(null===this.event)switch(a){case 0:this.buttons=1;break;case 1:this.buttons=4;break;case 2:this.buttons=2}});D.defineProperty(bg,{buttons:"buttons"},function(){return this.Dv},function(a){this.Dv=a});D.defineProperty(bg,{key:"key"},function(){return this.mw},function(a){this.mw=a});D.defineProperty(bg,{pm:"down"},function(){return this.Rv},function(a){this.Rv=a});
D.defineProperty(bg,{up:"up"},function(){return this.hx},function(a){this.hx=a});D.defineProperty(bg,{Pe:"clickCount"},function(){return this.Ev},function(a){this.Ev=a});D.defineProperty(bg,{om:"delta"},function(){return this.Ov},function(a){this.Ov=a});D.defineProperty(bg,{Eu:"isMultiTouch"},function(){return this.iw},function(a){this.iw=a});D.defineProperty(bg,{Mc:"handled"},function(){return this.Zv},function(a){this.Zv=a});
D.defineProperty(bg,{bubbles:"bubbles"},function(){return this.bk},function(a){this.bk=a});D.defineProperty(bg,{event:"event"},function(){return this.Rr},function(a){this.Rr=a});D.w(bg,{Mj:"isTouchEvent"},function(){var a=window.TouchEvent;return a&&this.event instanceof a?!0:(a=window.PointerEvent)&&this.event instanceof a&&"touch"===this.event.pointerType});D.w(bg,{Zk:"isMac"},function(){return D.Zk});D.defineProperty(bg,{timestamp:"timestamp"},function(){return this.ex},function(a){this.ex=a});
D.defineProperty(bg,{jh:"targetDiagram"},function(){return this.bx},function(a){this.bx=a});D.defineProperty(bg,{Ze:"targetObject"},function(){return this.Tg},function(a){this.Tg=a});D.defineProperty(bg,{control:"control"},function(){return 0!==(this.Md&1)},function(a){this.Md=a?this.Md|1:this.Md&-2});D.defineProperty(bg,{shift:"shift"},function(){return 0!==(this.Md&4)},function(a){this.Md=a?this.Md|4:this.Md&-5});
D.defineProperty(bg,{alt:"alt"},function(){return 0!==(this.Md&2)},function(a){this.Md=a?this.Md|2:this.Md&-3});D.defineProperty(bg,{Nu:"meta"},function(){return 0!==(this.Md&8)},function(a){this.Md=a?this.Md|8:this.Md&-9});D.defineProperty(bg,{left:"left"},function(){var a=this.event;return null!==a&&a instanceof MouseEvent&&("mousedown"===a.type||"mouseup"===a.type)?0===this.button:0!==(this.buttons&1)},function(a){this.buttons=a?this.buttons|1:this.buttons&-2});
D.defineProperty(bg,{right:"right"},function(){var a=this.event;return null!==a&&a instanceof MouseEvent&&("mousedown"===a.type||"mouseup"===a.type)?2===this.button:0!==(this.buttons&2)},function(a){this.buttons=a?this.buttons|2:this.buttons&-3});D.defineProperty(bg,{dM:"middle"},function(){var a=this.event;return null!==a&&a instanceof MouseEvent&&("mousedown"===a.type||"mouseup"===a.type)?1===this.button:0!==(this.buttons&4)},function(a){this.buttons=a?this.buttons|4:this.buttons&-5});
function dg(){this.ia=null;this.ac="";this.Iw=this.$w=null;this.kr=!1}D.ka("DiagramEvent",dg);dg.prototype.copy=function(){var a=new dg;a.ia=this.ia;a.ac=this.ac;a.$w=this.$w;a.Iw=this.Iw;a.kr=this.kr;return a};dg.prototype.toString=function(){var a="*"+this.name;null!==this.VB&&(a+=":"+this.VB.toString());null!==this.yB&&(a+="("+this.yB.toString()+")");return a};D.defineProperty(dg,{g:"diagram"},function(){return this.ia},function(a){this.ia=a});
D.defineProperty(dg,{name:"name"},function(){return this.ac},function(a){this.ac=a});D.defineProperty(dg,{VB:"subject"},function(){return this.$w},function(a){this.$w=a});D.defineProperty(dg,{yB:"parameter"},function(){return this.Iw},function(a){this.Iw=a});D.defineProperty(dg,{cancel:"cancel"},function(){return this.kr},function(a){this.kr!==a&&D.Pn("DiagramEvent.cancel","2.0");this.kr=a});function eg(){this.lr=fg;this.on=this.xw="";this.Ns=this.Os=this.Ts=this.Us=this.Rs=this.ia=this.xe=null}
D.ka("ChangedEvent",eg);var gg;eg.Transaction=gg=D.s(eg,"Transaction",-1);var fg;eg.Property=fg=D.s(eg,"Property",0);var rg;eg.Insert=rg=D.s(eg,"Insert",1);var sg;eg.Remove=sg=D.s(eg,"Remove",2);eg.prototype.clear=eg.prototype.clear=function(){this.Ns=this.Os=this.Ts=this.Us=this.Rs=this.ia=this.xe=null};
eg.prototype.copy=function(){var a=new eg;a.lr=this.lr;a.xw=this.xw;a.on=this.on;a.xe=this.xe;a.ia=this.ia;a.Rs=this.Rs;var b=this.Us;a.Us=D.Qa(b)&&"function"===typeof b.V?b.V():b;b=this.Ts;a.Ts=D.Qa(b)&&"function"===typeof b.V?b.V():b;b=this.Os;a.Os=D.Qa(b)&&"function"===typeof b.V?b.V():b;b=this.Ns;a.Ns=D.Qa(b)&&"function"===typeof b.V?b.V():b;return a};eg.prototype.qc=function(a){a.Oe===eg?this.Kc=a:D.Yj(this,a)};
eg.prototype.toString=function(){var a="",a=this.Kc===gg?a+"* ":this.Kc===fg?a+(null!==this.da?"!m":"!d"):a+((null!==this.da?"!m":"!d")+this.Kc);this.propertyName&&"string"===typeof this.propertyName&&(a+=" "+this.propertyName);this.Cf&&this.Cf!==this.propertyName&&(a+=" "+this.Cf);a+=": ";this.Kc===gg?null!==this.oldValue&&(a+=" "+this.oldValue):(null!==this.object&&(a+=ia(this.object)),null!==this.oldValue&&(a+="  old: "+ia(this.oldValue)),null!==this.Sj&&(a+=" "+this.Sj),null!==this.newValue&&
(a+="  new: "+ia(this.newValue)),null!==this.Qj&&(a+=" "+this.Qj));return a};eg.prototype.getValue=eg.prototype.oa=function(a){return a?this.oldValue:this.newValue};eg.prototype.getParam=function(a){return a?this.Sj:this.Qj};eg.prototype.canUndo=eg.prototype.canUndo=function(){return null!==this.da||null!==this.g?!0:!1};eg.prototype.undo=eg.prototype.undo=function(){this.canUndo()&&(null!==this.da?this.da.Gn(this,!0):null!==this.g&&this.g.Gn(this,!0))};
eg.prototype.canRedo=eg.prototype.canRedo=function(){return null!==this.da||null!==this.g?!0:!1};eg.prototype.redo=eg.prototype.redo=function(){this.canRedo()&&(null!==this.da?this.da.Gn(this,!1):null!==this.g&&this.g.Gn(this,!1))};D.defineProperty(eg,{da:"model"},function(){return this.xe},function(a){this.xe=a});D.defineProperty(eg,{g:"diagram"},function(){return this.ia},function(a){this.ia=a});
D.defineProperty(eg,{Kc:"change"},function(){return this.lr},function(a){v&&D.Da(a,eg,eg,"change");this.lr=a});D.defineProperty(eg,{Cf:"modelChange"},function(){return this.xw},function(a){v&&D.h(a,"string",eg,"modelChange");this.xw=a});D.defineProperty(eg,{propertyName:"propertyName"},function(){return this.on},function(a){v&&"string"!==typeof a&&D.h(a,"function",eg,"propertyName");this.on=a});
D.w(eg,{eG:"isTransactionFinished"},function(){return this.lr===gg&&("CommittedTransaction"===this.on||"FinishedUndo"===this.on||"FinishedRedo"===this.on)});D.defineProperty(eg,{object:"object"},function(){return this.Rs},function(a){this.Rs=a});D.defineProperty(eg,{oldValue:"oldValue"},function(){return this.Us},function(a){this.Us=a});D.defineProperty(eg,{Sj:"oldParam"},function(){return this.Ts},function(a){this.Ts=a});
D.defineProperty(eg,{newValue:"newValue"},function(){return this.Os},function(a){this.Os=a});D.defineProperty(eg,{Qj:"newParam"},function(){return this.Ns},function(a){this.Ns=a});
function M(a){1<arguments.length&&D.k("Model constructor can only take one optional argument, the Array of node data.");D.vc(this);this.Ar=this.ac="";this.bj=!1;this.Vz={};this.ye=[];this.Gc=new oa(null,Object);this.yk="key";this.Po=this.vp=null;this.rr=this.sr=!1;this.br=null;this.jn="category";this.Ag=new oa(null,L);this.uk=null;this.sj=!1;this.oA=null;this.la=new tg;void 0!==a&&(this.qg=a)}D.ka("Model",M);
M.prototype.cloneProtected=function(a){a.ac=this.ac;a.Ar=this.Ar;a.bj=this.bj;a.yk=this.yk;a.vp=this.vp;a.Po=this.Po;a.sr=this.sr;a.rr=this.rr;a.br=this.br;a.jn=this.jn};M.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};M.prototype.clear=M.prototype.clear=function(){this.ye=[];this.Gc.clear();this.Ag.clear();this.la.clear()};f=M.prototype;
f.toString=function(a){void 0===a&&(a=0);if(1<a)return this.YB();var b=(""!==this.name?this.name:"")+" Model";if(0<a){b+="\n node data:";a=this.qg;for(var c=D.cb(a),d=0;d<c;d++)var e=D.La(a,d),b=b+(" "+this.zb(e)+":"+ia(e))}return b};
f.Ao=function(){var a="";""!==this.name&&(a+=',\n  "name": '+this.quote(this.name));""!==this.mm&&(a+=',\n  "dataFormat": '+this.quote(this.mm));this.rb&&(a+=',\n  "isReadOnly": '+this.rb);"key"!==this.Rj&&"string"===typeof this.Rj&&(a+=',\n  "nodeKeyProperty": '+this.quote(this.Rj));this.EA&&(a+=',\n  "copiesArrays": true');this.DA&&(a+=',\n  "copiesArrayObjects": true');"category"!==this.oo&&"string"===typeof this.oo&&(a+=',\n  "nodeCategoryProperty": '+this.quote(this.oo));return a};
f.Wu=function(a){a.name&&(this.name=a.name);a.dataFormat&&(this.mm=a.dataFormat);a.isReadOnly&&(this.rb=a.isReadOnly);a.nodeKeyProperty&&(this.Rj=a.nodeKeyProperty);a.copiesArrays&&(this.EA=a.copiesArrays);a.copiesArrayObjects&&(this.DA=a.copiesArrayObjects);a.nodeCategoryProperty&&(this.oo=a.nodeCategoryProperty)};function ug(a){return',\n  "modelData": '+vg(a,a.bl)}function wg(a,b){var c=b.modelData;D.Qa(c)&&(a.Zu(c),a.bl=c)}
f.kC=function(){var a=this.bl,b=!1,c;for(c in a)if(!xg(c,a[c])){b=!0;break}a="";b&&(a=ug(this));return a+',\n  "nodeDataArray": '+yg(this,this.qg,!0)};f.BB=function(a){wg(this,a);a=a.nodeDataArray;D.isArray(a)&&(this.Zu(a),this.qg=a)};
function zg(a,b,c,d){if(b===c)return!0;if(typeof b!==typeof c||"function"===typeof b||"function"===typeof c)return!1;if(Array.isArray(b)&&Array.isArray(c)){if(d.oa(b)===c)return!0;d.add(b,c);if(b.length!==c.length)return!1;for(var e=0;e<b.length;e++)if(!zg(a,b[e],c[e],d))return!1;return!0}if(D.Qa(b)&&D.Qa(c)){if(d.oa(b)===c)return!0;d.add(b,c);for(e in b){var g=b[e];if(!xg(e,g)){var h=c[e];if(void 0===h||!zg(a,g,h,d))return!1}}for(var k in c)if(h=c[k],!xg(k,h)&&(g=b[k],void 0===g||!zg(a,g,h,d)))return!1;
return!0}return!1}function Ag(a,b,c){a[c]!==b[c]&&D.k("Model.computeJsonDifference: Model."+c+' is not the same in both models: "'+a[c]+'" and "'+b[c]+'"')}
f.lC=function(a){Ag(this,a,"nodeKeyProperty");this instanceof Bg&&Ag(this,a,"nodeParentKeyProperty");for(var b=new L,c=new L,d=(new L).Tc(this.Gc.gG),e=new oa,g=a.qg,h=0;h<g.length;h++){var k=g[h],l=a.zb(k);if(void 0!==l){d.remove(l);var m=this.He(l);null===m?(b.add(l),c.add(k)):zg(this,m,k,e)||c.add(k)}else this.mB(k),l=this.zb(k),b.add(l),c.add(k)}g="";zg(this,this.bl,a.bl,e)||(g+=ug(this));0<b.count&&(g+=this.az+yg(this,b.oc(),!0));0<c.count&&(g+=this.tC+yg(this,c.oc(),!0));0<d.count&&(g+=this.cz+
yg(this,d.oc(),!0));return g};M.prototype.computeJsonDifference=M.prototype.computeJSONDifference=function(a,b){D.l(a,M,M,"computeJsonDifference:newmodel");void 0===b&&(b=this.constructor===M?"go.Model":this.constructor===Y?"go.GraphLinksModel":this.constructor===Bg?"go.TreeModel":D.vf(this));return'{ "class": '+this.quote(b)+', "incremental": 1'+this.Ao()+this.lC(a)+"}"};f=M.prototype;f.az=',\n  "insertedNodeKeys": ';f.tC=',\n  "modifiedNodeData": ';f.cz=',\n  "removedNodeKeys": ';
f.jC=function(a,b){var c=this,d=!1,e=new L,g=new L,h=new L;a.fg.each(function(a){a.da===c&&("nodeDataArray"===a.Cf?a.Kc===rg?e.add(a.newValue):a.Kc===sg&&h.add(a.oldValue):c.le(a.object)?g.add(a.object):c.bl===a.object&&a.Kc===fg&&(d=!0))});var k=new L;e.each(function(a){k.add(c.zb(a));b||g.add(a)});var l=new L;h.each(function(a){l.add(c.zb(a));b&&g.add(a)});var m="";d&&(m+=ug(this));0<k.count&&(m+=(b?this.cz:this.az)+yg(this,k.oc(),!0));0<g.count&&(m+=this.tC+yg(this,g.oc(),!0));0<l.count&&(m+=(b?
this.az:this.cz)+yg(this,l.oc(),!0));return m};
f.AB=function(a){wg(this,a);var b=a.insertedNodeKeys,c=a.modifiedNodeData,d=new oa;if(D.isArray(c))for(var e=0;e<c.length;e++){var g=D.La(c,e),h=this.zb(g);void 0!==h&&null!==h&&d.set(h,g)}if(D.isArray(b))for(var e=D.cb(b),k=0;k<e;k++)g=D.La(b,k),h=this.He(g),null===h&&(h=(h=d.get(g))?h:this.copyNodeData({}),this.Ly(h,g),this.cm(h));if(D.isArray(c))for(e=D.cb(c),k=0;k<e;k++)if(g=D.La(c,k),h=this.zb(g),h=this.He(h),null!==h)for(var l in g)"__gohashid"!==l&&l!==this.Rj&&l!==this.Dq&&this.setDataProperty(h,
l,g[l]);a=a.removedNodeKeys;if(D.isArray(a))for(e=D.cb(a),k=0;k<e;k++)g=D.La(a,k),h=this.He(g),null!==h&&this.Cy(h)};
M.prototype.toIncrementalJson=M.prototype.toIncrementalJSON=function(a,b){D.l(a,eg,M,"toIncrementalJson:e");a.Kc!==gg&&D.k("Model.toIncrementalJson argument is not a Transaction ChangedEvent:"+a.toString());var c=a.object;if(!(a.eG&&c instanceof Cg))return'{ "incremental": 0 }';void 0===b&&(b=this.constructor===M?"go.Model":this.constructor===Y?"go.GraphLinksModel":this.constructor===Bg?"go.TreeModel":D.vf(this));return'{ "class": '+this.quote(b)+', "incremental": 1'+this.Ao()+this.jC(c,"FinishedUndo"===
a.propertyName)+"}"};M.prototype.toJson=M.prototype.toJSON=M.prototype.YB=function(a){void 0===a&&(a=this.constructor===M?"go.Model":this.constructor===Y?"go.GraphLinksModel":this.constructor===Bg?"go.TreeModel":D.vf(this));return'{ "class": '+this.quote(a)+this.Ao()+this.kC()+"}"};
M.prototype.applyIncrementalJson=M.prototype.applyIncrementalJSON=function(a){var b=null;if("string"===typeof a)if(window.JSON&&window.JSON.parse)try{b=window.JSON.parse(a)}catch(c){v&&D.trace("JSON.parse error: "+c.toString())}else D.trace("WARNING: no JSON.parse available");else"object"===typeof a?b=a:D.k("Unable to modify a Model from: "+a);var d=b.incremental;"number"!==typeof d&&D.k("Unable to apply non-incremental changes to Model: "+a);0!==d&&(this.Nb("applyIncrementalJson"),this.AB(b),this.hd("applyIncrementalJson"))};
M.fromJson=M.fromJSON=function(a,b){void 0===b&&(b=null);null!==b&&D.l(b,M,M,"fromJson:model");var c=null;if("string"===typeof a)if(window.JSON&&window.JSON.parse)try{c=window.JSON.parse(a)}catch(d){v&&D.trace("JSON.parse error: "+d.toString())}else D.trace("WARNING: no JSON.parse available");else"object"===typeof a?c=a:D.k("Unable to construct a Model from: "+a);if(null===b){var e;e=null;var g=c["class"];if("string"===typeof g)try{var h=null;0===g.indexOf("go.")?(g=g.substr(3),h=ba[g]):(h=ba[g],
void 0===h&&(h=window[g]));"function"===typeof h&&(e=new h)}catch(k){}null===e||e instanceof M?b=e:D.k("Unable to construct a Model of declared class: "+c["class"])}null===b&&(b=new Y);b.Wu(c);b.BB(c);return b};
M.prototype.replaceJsonObjects=M.prototype.Zu=function(a){if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++){var d=D.La(a,c);D.Qa(d)&&D.SE(a,c,this.Zu(d))}else if(D.Qa(a)){for(c in a)if(d=a[c],D.Qa(d)&&(d=this.Zu(d),a[c]=d,"points"===c&&Array.isArray(d))){for(var e=0===d.length%2,g=0;g<d.length;g++)if("number"!==typeof d[g]){e=!1;break}if(e){e=new K(N);for(g=0;g<d.length/2;g++){var h=new N(d[2*g],d[2*g+1]);e.add(h)}e.freeze();a[c]=e}}if("object"===typeof a){c=a;d=a["class"];if("NaN"===d)c=NaN;else if("Date"===
d)c=new Date(a.value);else if("go.Point"===d)c=new N(Dg(a.x),Dg(a.y));else if("go.Size"===d)c=new Ca(Dg(a.width),Dg(a.height));else if("go.Rect"===d)c=new C(Dg(a.x),Dg(a.y),Dg(a.width),Dg(a.height));else if("go.Margin"===d)c=new Lb(Dg(a.top),Dg(a.right),Dg(a.bottom),Dg(a.left));else if("go.Spot"===d)c="string"===typeof a["enum"]?cc(a["enum"]):new S(Dg(a.x),Dg(a.y),Dg(a.offsetX),Dg(a.offsetY));else if("go.Brush"===d){if(c=new Ba,c.type=Ha(Ba,a.type),"string"===typeof a.color&&(c.color=a.color),a.start instanceof
S&&(c.start=a.start),a.end instanceof S&&(c.end=a.end),"number"===typeof a.startRadius&&(c.iv=Dg(a.startRadius)),"number"===typeof a.endRadius&&(c.gu=Dg(a.endRadius)),a=a.colorStops,D.Qa(a))for(b in a)c.addColorStop(parseFloat(b),a[b])}else"go.Geometry"===d?(b=null,b="string"===typeof a.path?yf(a.path):new Ve,b.type=Ha(Ve,a.type),"number"===typeof a.startX&&(b.ma=Dg(a.startX)),"number"===typeof a.startY&&(b.ja=Dg(a.startY)),"number"===typeof a.endX&&(b.G=Dg(a.endX)),"number"===typeof a.endY&&(b.H=
Dg(a.endY)),a.spot1 instanceof S&&(b.C=a.spot1),a.spot2 instanceof S&&(b.D=a.spot2),c=b):"go.EnumValue"===d&&(b=a.classType,0===b.indexOf("go.")&&(b=b.substr(3)),c=Ha(ba[b],a.name));a=c}}return a};
M.prototype.quote=function(a){for(var b="",c=a.length,d=0;d<c;d++){var e=a[d];if('"'===e||"\\"===e)b+="\\"+e;else if("\b"===e)b+="\\b";else if("\f"===e)b+="\\f";else if("\n"===e)b+="\\n";else if("\r"===e)b+="\\r";else if("\t"===e)b+="\\t";else var g=a.charCodeAt(d),b=16>g?b+("\\u000"+a.charCodeAt(d).toString(16)):32>g?b+("\\u00"+a.charCodeAt(d).toString(16)):8232===g?b+"\\u2028":8233===g?b+"\\u2029":b+e}return'"'+b+'"'};
M.prototype.writeJsonValue=M.prototype.nv=function(a){return void 0===a?"undefined":null===a?"null":!0===a?"true":!1===a?"false":"string"===typeof a?this.quote(a):"number"===typeof a?Infinity===a?"9e9999":-Infinity===a?"-9e9999":isNaN(a)?'{"class":"NaN"}':a.toString():a instanceof Date?'{"class":"Date", "value":"'+a.toJSON()+'"}':a instanceof Number?this.nv(a.valueOf()):D.isArray(a)?yg(this,a):D.Qa(a)?vg(this,a):"function"===typeof a?"null":a.toString()};
function yg(a,b,c){void 0===c&&(c=!1);var d=D.cb(b);if(0>=d)return"[]";var e=new xa;e.add("[ ");c&&1<d&&e.add("\n");for(var g=0;g<d;g++){var h=D.La(b,g);void 0!==h&&(0<g&&(e.add(","),c&&e.add("\n")),e.add(a.nv(h)))}c&&1<d&&e.add("\n");e.add(" ]");return e.toString()}function xg(a,b){return void 0===b||"__gohashid"===a||"_"===a[0]||"function"===typeof b?!0:!1}function Eg(a){return isNaN(a)?"NaN":Infinity===a?"9e9999":-Infinity===a?"-9e9999":a}
function vg(a,b){var c=b;if(c instanceof N)b={"class":"go.Point",x:Eg(c.x),y:Eg(c.y)};else if(c instanceof Ca)b={"class":"go.Size",width:Eg(c.width),height:Eg(c.height)};else if(c instanceof C)b={"class":"go.Rect",x:Eg(c.x),y:Eg(c.y),width:Eg(c.width),height:Eg(c.height)};else if(c instanceof Lb)b={"class":"go.Margin",top:Eg(c.top),right:Eg(c.right),bottom:Eg(c.bottom),left:Eg(c.left)};else if(c instanceof S)b=c.Vc()?{"class":"go.Spot",x:Eg(c.x),y:Eg(c.y),offsetX:Eg(c.offsetX),offsetY:Eg(c.offsetY)}:
{"class":"go.Spot","enum":c.toString()};else if(c instanceof Ba){b={"class":"go.Brush",type:c.type.name};if(c.type===Fg)b.color=c.color;else if(c.type===Gg||c.type===Id)b.start=c.start,b.end=c.end,c.type===Id&&(0!==c.iv&&(b.startRadius=Eg(c.iv)),isNaN(c.gu)||(b.endRadius=Eg(c.gu)));if(null!==c.Nk){for(var d={},c=c.Nk.j;c.next();)d[c.key]=c.value;b.colorStops=d}}else if(c instanceof Ve)b={"class":"go.Geometry",type:c.type.name},0!==c.ma&&(b.startX=Eg(c.ma)),0!==c.ja&&(b.startY=Eg(c.ja)),0!==c.G&&(b.endX=
Eg(c.G)),0!==c.H&&(b.endY=Eg(c.H)),c.C.O(ec)||(b.spot1=c.C),c.D.O(vc)||(b.spot2=c.D),c.type===We&&(b.path=xf(c));else if(c instanceof ya)b={"class":"go.EnumValue",classType:D.vf(c.Oe),name:c.name};else if(c instanceof P||c instanceof E||c instanceof Yg||c instanceof M||c instanceof Zg||c instanceof qa||c instanceof $g||c instanceof ua||c instanceof tg||c instanceof Cg)return D.trace("ERROR: trying to convert a GraphObject or Diagram or Model or Tool or Layout or UndoManager into JSON text: "+c.toString()),
"{}";var d="{",c=!0,e;for(e in b){var g=D.wb(b,e);if(!xg(e,g))if(c?c=!1:d+=", ",d+='"'+e+'":',"points"===e&&g instanceof K&&g.ca===N){for(var h="[",g=g.j;g.next();){var k=g.value;1<h.length&&(h+=",");h+=a.nv(k.x);h+=",";h+=a.nv(k.y)}h+="]";d+=h}else d+=a.nv(g)}return d+"}"}function Dg(a){return"number"===typeof a?a:"NaN"===a?NaN:"9e9999"===a?Infinity:"-9e9999"===a?-Infinity:parseFloat(a)}
D.defineProperty(M,{name:"name"},function(){return this.ac},function(a){var b=this.ac;b!==a&&(D.h(a,"string",M,"name"),this.ac=a,this.i("name",b,a))});D.defineProperty(M,{mm:"dataFormat"},function(){return this.Ar},function(a){var b=this.Ar;b!==a&&(D.h(a,"string",M,"dataFormat"),this.Ar=a,this.i("dataFormat",b,a))});D.defineProperty(M,{rb:"isReadOnly"},function(){return this.bj},function(a){var b=this.bj;b!==a&&(D.h(a,"boolean",M,"isReadOnly"),this.bj=a,this.i("isReadOnly",b,a))});
D.defineProperty(M,{bl:"modelData"},function(){return this.Vz},function(a){var b=this.Vz;b!==a&&(D.h(a,"object",M,"modelData"),this.Vz=a,this.i("modelData",b,a),this.Ob(a))});M.prototype.addChangedListener=M.prototype.Cn=function(a){D.h(a,"function",M,"addChangedListener:listener");null===this.uk&&(this.uk=new K("function"));this.uk.add(a)};
M.prototype.removeChangedListener=M.prototype.Xu=function(a){D.h(a,"function",M,"removeChangedListener:listener");null!==this.uk&&(this.uk.remove(a),0===this.uk.count&&(this.uk=null))};M.prototype.wx=function(a){this.lb||this.la.OF(a);if(null!==this.uk){var b=this.uk,c=b.length;if(1===c)b=b.ea(0),b(a);else if(0!==c)for(var d=b.oc(),e=0;e<c;e++)b=d[e],b(a)}};M.prototype.raiseChangedEvent=M.prototype.nd=function(a,b,c,d,e,g,h){ah(this,"",a,b,c,d,e,g,h)};
M.prototype.raiseChanged=M.prototype.i=function(a,b,c,d,e){ah(this,"",fg,a,this,b,c,d,e)};M.prototype.raiseDataChanged=M.prototype.zB=function(a,b,c,d,e,g){ah(this,"",fg,b,a,c,d,e,g)};function ah(a,b,c,d,e,g,h,k,l){void 0===k&&(k=null);void 0===l&&(l=null);var m=new eg;m.da=a;m.Kc=c;m.Cf=b;m.propertyName=d;m.object=e;m.oldValue=g;m.Sj=k;m.newValue=h;m.Qj=l;a.wx(m)}
D.defineProperty(M,{la:"undoManager"},function(){return this.oA},function(a){var b=this.oA;b!==a&&(D.l(a,tg,M,"undoManager"),null!==b&&b.aK(this),this.oA=a,null!==a&&a.SH(this))});D.defineProperty(M,{lb:"skipsUndoManager"},function(){return this.sj},function(a){D.h(a,"boolean",M,"skipsUndoManager");this.sj=a});
M.prototype.Gn=function(a,b){if(null!==a&&a.da===this)if(a.Kc===fg){var c=a.object,d=a.propertyName,e=a.oa(b);D.Ta(c,d,e)}else a.Kc===rg?(c=a.Qj,"nodeDataArray"===a.Cf?(d=a.newValue,D.Qa(d)&&"number"===typeof c&&(e=this.zb(d),b?(D.La(this.ye,c)===d&&D.Wg(this.ye,c),void 0!==e&&this.Gc.remove(e)):(D.La(this.ye,c)!==d&&D.Kh(this.ye,c,d),void 0!==e&&this.Gc.add(e,d)))):""===a.Cf?(d=a.object,!D.isArray(d)&&a.propertyName&&(d=D.wb(a.object,a.propertyName)),D.isArray(d)&&"number"===typeof c&&(e=a.newValue,
b?D.Wg(d,c):D.Kh(d,c,e))):D.k("unknown ChangedEvent.Insert modelChange: "+a.toString())):a.Kc===sg?(c=a.Sj,"nodeDataArray"===a.Cf?(d=a.oldValue,D.Qa(d)&&"number"===typeof c&&(e=this.zb(d),b?(D.La(this.ye,c)!==d&&D.Kh(this.ye,c,d),void 0!==e&&this.Gc.add(e,d)):(D.La(this.ye,c)===d&&D.Wg(this.ye,c),void 0!==e&&this.Gc.remove(e)))):""===a.Cf?(d=a.object,!D.isArray(d)&&a.propertyName&&(d=D.wb(a.object,a.propertyName)),D.isArray(d)&&"number"===typeof c&&(e=a.oldValue,b?D.Kh(d,c,e):D.Wg(d,c))):D.k("unknown ChangedEvent.Remove modelChange: "+
a.toString())):a.Kc!==gg&&D.k("unknown ChangedEvent: "+a.toString())};M.prototype.startTransaction=M.prototype.Nb=function(a){return this.la.Nb(a)};M.prototype.commitTransaction=M.prototype.hd=function(a){return this.la.hd(a)};M.prototype.rollbackTransaction=M.prototype.Bm=function(){return this.la.Bm()};M.prototype.commit=function(a,b){var c=b;void 0===c&&(c="");var d=this.lb;null===c&&(this.lb=!0,c="");this.la.Nb(c);var e=!1;try{a(this),e=!0}finally{e?this.la.hd(c):this.la.Bm(),this.lb=d}};
M.prototype.updateTargetBindings=M.prototype.Ob=function(a,b){void 0===b&&(b="");ah(this,"SourceChanged",gg,b,a,null,null)};D.defineProperty(M,{Rj:"nodeKeyProperty"},function(){return this.yk},function(a){var b=this.yk;b!==a&&(bh(a,M,"nodeKeyProperty"),""===a&&D.k("Model.nodeKeyProperty may not be the empty string"),0<this.Gc.count&&D.k("Cannot set Model.nodeKeyProperty when there is existing node data"),this.yk=a,this.i("nodeKeyProperty",b,a))});
function bh(a,b,c){"string"!==typeof a&&"function"!==typeof a&&D.jc(a,"string or function",b,c)}M.prototype.getKeyForNodeData=M.prototype.zb=function(a){if(null!==a){var b=this.yk;if(""!==b&&(b=D.wb(a,b),void 0!==b)){if(ch(b))return b;D.k("Key value for node data "+a+" is not a number or a string: "+b)}}};
M.prototype.setKeyForNodeData=M.prototype.Ly=function(a,b){void 0!==b&&null!==b&&ch(b)||D.jc(b,"number or string",M,"setKeyForNodeData:key");if(null!==a){var c=this.yk;if(""!==c)if(this.le(a)){var d=D.wb(a,c);d!==b&&null===this.He(b)&&(D.Ta(a,c,b),this.Gc.remove(d),this.Gc.add(b,a),ah(this,"nodeKey",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c),this.Yu(d,b))}else D.Ta(a,c,b)}};
D.defineProperty(M,{XL:"makeUniqueKeyFunction"},function(){return this.vp},function(a){var b=this.vp;b!==a&&(null!==a&&D.h(a,"function",M,"makeUniqueKeyFunction"),this.vp=a,this.i("makeUniqueKeyFunction",b,a))});function ch(a){return"number"===typeof a||"string"===typeof a}M.prototype.containsNodeData=M.prototype.le=function(a){var b=this.zb(a);return void 0===b?!1:this.Gc.oa(b)===a};
M.prototype.findNodeDataForKey=M.prototype.He=function(a){null===a&&D.k("Model.findNodeDataForKey:key must not be null");return void 0!==a&&ch(a)?this.Gc.oa(a):null};
D.defineProperty(M,{qg:"nodeDataArray"},function(){return this.ye},function(a){var b=this.ye;if(b!==a){D.Zt(a,M,"nodeDataArray");this.Gc.clear();this.fC();for(var c=D.cb(a),d=0;d<c;d++){var e=D.La(a,d);if(!D.Qa(e)){D.k("Model.nodeDataArray must only contain Objects, not: "+e);return}D.pq(e)}this.ye=a;for(var g=new K(Object),d=0;d<c;d++){var e=D.La(a,d),h=this.zb(e);void 0===h?g.add(e):null!==this.Gc.oa(h)?g.add(e):this.Gc.add(h,e)}for(d=g.j;d.next();)e=d.value,this.mB(e),g=this.zb(e),void 0!==g&&
this.Gc.add(g,e);ah(this,"nodeDataArray",fg,"nodeDataArray",this,b,a);for(d=0;d<c;d++)e=D.La(a,d),this.Hq(e),this.Gq(e);this.WE();D.mJ(a)||(this.rb=!0)}});
M.prototype.makeNodeDataKeyUnique=M.prototype.mB=function(a){if(null!==a){var b=this.yk;if(""!==b){var c=this.zb(a);if(void 0===c||this.Gc.contains(c)){var d=this.vp;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.Gc.contains(c))){D.Ta(a,b,c);return}if("string"===typeof c){for(d=2;this.Gc.contains(c+d);)d++;D.Ta(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(d=-this.Gc.count-1;this.Gc.contains(d);)d--;D.Ta(a,b,d)}else D.k("Model.getKeyForNodeData returned something other than a string or a number: "+
c)}}}};M.prototype.addNodeData=M.prototype.cm=function(a){null!==a&&(D.pq(a),this.le(a)||dh(this,a,!0))};function dh(a,b,c){var d=a.zb(b);if(void 0===d||a.Gc.oa(d)!==b)a.mB(b),d=a.zb(b),void 0===d?D.k("Model.makeNodeDataKeyUnique failed on "+b+".  Data not added to Model."):(a.Gc.add(d,b),d=null,c&&(d=D.cb(a.ye),D.Kh(a.ye,d,b)),ah(a,"nodeDataArray",rg,"nodeDataArray",a,null,b,null,d),a.Hq(b),a.Gq(b))}
M.prototype.addNodeDataCollection=function(a){if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++)this.cm(D.La(a,c));else for(a=a.j;a.next();)this.cm(a.value)};M.prototype.removeNodeData=M.prototype.Cy=function(a){null!==a&&eh(this,a,!0)};function eh(a,b,c){var d=a.zb(b);void 0!==d&&a.Gc.remove(d);d=null;if(c){d=D.gm(a.ye,b);if(0>d)return;D.Wg(a.ye,d)}ah(a,"nodeDataArray",sg,"nodeDataArray",a,b,null,d,null);a.mv(b)}
M.prototype.removeNodeDataCollection=function(a){if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++)this.Cy(D.La(a,c));else for(a=a.j;a.next();)this.Cy(a.value)};f=M.prototype;f.Yu=function(a,b){var c=fh(this,a);c instanceof L&&this.Ag.add(b,c)};f.fC=function(){};f.Hq=function(){};f.Gq=function(){};f.mv=function(){};function gh(a,b,c){if(void 0!==b){var d=a.Ag.oa(b);null===d&&(d=new L(Object),a.Ag.add(b,d));d.add(c)}}
function hh(a,b,c){if(void 0!==b){var d=a.Ag.oa(b);d instanceof L&&(void 0===c||null===c?a.Ag.remove(b):(d.remove(c),0===d.count&&a.Ag.remove(b)))}}function fh(a,b){if(void 0===b)return null;var c=a.Ag.oa(b);return c instanceof L?c:null}M.prototype.clearUnresolvedReferences=M.prototype.WE=function(a){void 0===a?this.Ag.clear():this.Ag.remove(a)};
D.defineProperty(M,{oL:"copyNodeDataFunction"},function(){return this.Po},function(a){var b=this.Po;b!==a&&(null!==a&&D.h(a,"function",M,"copyNodeDataFunction"),this.Po=a,this.i("copyNodeDataFunction",b,a))});D.defineProperty(M,{EA:"copiesArrays"},function(){return this.sr},function(a){var b=this.sr;b!==a&&(null!==a&&D.h(a,"boolean",M,"copiesArrays"),this.sr=a,this.i("copiesArrays",b,a))});
D.defineProperty(M,{DA:"copiesArrayObjects"},function(){return this.rr},function(a){var b=this.rr;b!==a&&(null!==a&&D.h(a,"boolean",M,"copiesArrayObjects"),this.rr=a,this.i("copiesArrayObjects",b,a))});M.prototype.copyNodeData=function(a){if(null===a)return null;var b=null,b=this.Po,b=null!==b?b(a,this):ih(this,a,!0);D.Qa(b)&&D.vc(b);return b};
function ih(a,b,c){if(a.EA&&Array.isArray(b)){var d=[];for(c=0;c<b.length;c++){var e=ih(a,b[c],a.DA);d.push(e)}D.vc(d);return d}if(c&&D.Qa(b)){c=(c=b.constructor)?new c:{};for(d in b)if("__gohashid"!==d){var e=D.wb(b,d),g;g=e;g instanceof P||g instanceof E||g instanceof Yg||g instanceof jh||g instanceof kh||g instanceof Zg||g instanceof qa||g instanceof $g||g instanceof bg||g instanceof dg?("_"!==d[0]&&D.trace('Warning: found GraphObject or Diagram reference when copying model data on property "'+
d+'" of data object: '+b.toString()+"  \nModel data should not have any references to a Diagram or any part of a diagram, such as: "+g.toString()),g=!0):g=g instanceof M||g instanceof tg||g instanceof Cg||g instanceof eg?!0:!1;g||(e=ih(a,e,!1));D.Ta(c,d,e)}D.vc(c);return c}return b instanceof N?b.copy():b instanceof Ca?b.copy():b instanceof C?b.copy():b instanceof S?b.copy():b instanceof Lb?b.copy():b}
D.defineProperty(M,{UH:"afterCopyFunction"},function(){return this.br},function(a){var b=this.br;b!==a&&(null!==a&&D.h(a,"function",M,"afterCopyFunction"),this.br=a,this.i("afterCopyFunction",b,a))});var lh=!1;M.prototype.set=function(a,b,c){this.setDataProperty(a,b,c)};
M.prototype.set=M.prototype.setDataProperty=function(a,b,c){v&&(D.h(a,"object",M,"setDataProperty:data"),D.h(b,"string",M,"setDataProperty:propname"),""===b&&D.k("Model.setDataProperty: property name must not be an empty string when setting "+a+" to "+c));if(this.le(a))if(b===this.Rj)this.Ly(a,c);else{if(b===this.oo){this.Jy(a,c);return}}else!lh&&a instanceof P&&(lh=!0,D.trace('Model.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),D.trace("  Is that really your intent?"));var d=D.wb(a,
b);d!==c&&(D.Ta(a,b,c),this.zB(a,b,d,c))};M.prototype.addArrayItem=function(a,b){this.bB(a,-1,b)};M.prototype.insertArrayItem=M.prototype.bB=function(a,b,c){v&&(D.Zt(a,M,"insertArrayItem:arr"),D.p(b,M,"insertArrayItem:idx"));a===this.ye&&D.k("Model.insertArrayItem or Model.addArrayItem should not be called on the Model.nodeDataArray");0>b&&(b=D.cb(a));D.Kh(a,b,c);ah(this,"",rg,"",a,null,c,null,b)};
M.prototype.removeArrayItem=M.prototype.CG=function(a,b){void 0===b&&(b=-1);v&&(D.Zt(a,M,"removeArrayItem:arr"),D.p(b,M,"removeArrayItem:idx"));a===this.ye&&D.k("Model.removeArrayItem should not be called on the Model.nodeDataArray");-1===b&&(b=D.cb(a)-1);var c=D.La(a,b);D.Wg(a,b);ah(this,"",sg,"",a,c,null,b,null)};D.defineProperty(M,{oo:"nodeCategoryProperty"},function(){return this.jn},function(a){var b=this.jn;b!==a&&(bh(a,M,"nodeCategoryProperty"),this.jn=a,this.i("nodeCategoryProperty",b,a))});
M.prototype.getCategoryForNodeData=M.prototype.UA=function(a){if(null===a)return"";var b=this.jn;if(""===b)return"";b=D.wb(a,b);if(void 0===b)return"";if("string"===typeof b)return b;D.k("getCategoryForNodeData found a non-string category for "+a+": "+b);return""};
M.prototype.setCategoryForNodeData=M.prototype.Jy=function(a,b){D.h(b,"string",M,"setCategoryForNodeData:cat");if(null!==a){var c=this.jn;if(""!==c)if(this.le(a)){var d=D.wb(a,c);void 0===d&&(d="");d!==b&&(D.Ta(a,c,b),ah(this,"nodeCategory",fg,c,a,d,b))}else D.Ta(a,c,b)}};
function Y(a,b){M.call(this);2<arguments.length&&D.k("GraphLinksModel constructor can only take two optional arguments, the Array of node data and the Array of link data.");this.ef=[];this.ji=new L(Object);this.sd=new oa(null,Object);this.Nl="";this.ul=this.Oo=this.wp=null;this.ki="from";this.li="to";this.en=this.dn="";this.cn="category";this.Lg="";this.zp="isGroup";this.Bh="group";this.tr=!1;void 0!==a&&(this.qg=a);void 0!==b&&(this.ah=b)}D.Sa(Y,M);D.ka("GraphLinksModel",Y);
Y.prototype.cloneProtected=function(a){M.prototype.cloneProtected.call(this,a);a.Nl=this.Nl;a.wp=this.wp;a.Oo=this.Oo;a.ki=this.ki;a.li=this.li;a.dn=this.dn;a.en=this.en;a.cn=this.cn;a.Lg=this.Lg;a.zp=this.zp;a.Bh=this.Bh;a.tr=this.tr};Y.prototype.clear=Y.prototype.clear=function(){M.prototype.clear.call(this);this.ef=[];this.sd.clear();this.ji.clear()};f=Y.prototype;
f.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.YB();var b=(""!==this.name?this.name:"")+" GraphLinksModel";if(0<a){b+="\n node data:";a=this.qg;for(var c=D.cb(a),d=0,d=0;d<c;d++)var e=D.La(a,d),b=b+(" "+this.zb(e)+":"+ia(e));b+="\n link data:";a=this.ah;c=D.cb(a);for(d=0;d<c;d++)e=D.La(a,d),b+=" "+this.um(e)+"--\x3e"+this.vm(e)}return b};
f.Ao=function(){var a=M.prototype.Ao.call(this),b="";"category"!==this.Iu&&"string"===typeof this.Iu&&(b+=',\n  "linkCategoryProperty": '+this.quote(this.Iu));""!==this.Pj&&"string"===typeof this.Pj&&(b+=',\n  "linkKeyProperty": '+this.quote(this.Pj));"from"!==this.eo&&"string"===typeof this.eo&&(b+=',\n  "linkFromKeyProperty": '+this.quote(this.eo));"to"!==this.fo&&"string"===typeof this.fo&&(b+=',\n  "linkToKeyProperty": '+this.quote(this.fo));""!==this.Ju&&"string"===typeof this.Ju&&(b+=',\n  "linkFromPortIdProperty": '+
this.quote(this.Ju));""!==this.Lu&&"string"===typeof this.Lu&&(b+=',\n  "linkToPortIdProperty": '+this.quote(this.Lu));""!==this.Ku&&"string"===typeof this.Ku&&(b+=',\n  "linkLabelKeysProperty": '+this.quote(this.Ku));"isGroup"!==this.Dq&&"string"===typeof this.Dq&&(b+=',\n  "nodeIsGroupProperty": '+this.quote(this.Dq));"group"!==this.Su&&"string"===typeof this.Su&&(b+=',\n  "nodeGroupKeyProperty": '+this.quote(this.Su));return a+b};
f.Wu=function(a){M.prototype.Wu.call(this,a);a.linkKeyProperty&&(this.Pj=a.linkKeyProperty);a.linkFromKeyProperty&&(this.eo=a.linkFromKeyProperty);a.linkToKeyProperty&&(this.fo=a.linkToKeyProperty);a.linkFromPortIdProperty&&(this.Ju=a.linkFromPortIdProperty);a.linkToPortIdProperty&&(this.Lu=a.linkToPortIdProperty);a.linkCategoryProperty&&(this.Iu=a.linkCategoryProperty);a.linkLabelKeysProperty&&(this.Ku=a.linkLabelKeysProperty);a.nodeIsGroupProperty&&(this.Dq=a.nodeIsGroupProperty);a.nodeGroupKeyProperty&&
(this.Su=a.nodeGroupKeyProperty)};f.kC=function(){var a=M.prototype.kC.call(this),b=',\n  "linkDataArray": '+yg(this,this.ah,!0);return a+b};f.BB=function(a){M.prototype.BB.call(this,a);a=a.linkDataArray;D.isArray(a)&&(this.Zu(a),this.ah=a)};
f.lC=function(a){if(!(a instanceof Y))return D.k("Model.computeJsonDifference: newmodel must be a GraphLinksModel"),"";""===this.Pj&&D.k("GraphLinksModel.linkKeyProperty must not be an empty string for .computeJsonDifference() to succeed.");var b=M.prototype.lC.call(this,a);Ag(this,a,"linkKeyProperty");Ag(this,a,"linkFromKeyProperty");Ag(this,a,"linkToKeyProperty");Ag(this,a,"linkLabelKeysProperty");Ag(this,a,"nodeIsGroupProperty");Ag(this,a,"nodeGroupKeyProperty");for(var c=new L,d=new L,e=(new L).Tc(this.sd.gG),
g=new oa,h=a.ah,k=0;k<h.length;k++){var l=h[k],m=a.mf(l);if(void 0!==m){e.remove(m);var n=this.lq(m);null===n?(c.add(m),d.add(l)):zg(this,n,l,g)||d.add(l)}else this.my(l),m=this.mf(l),c.add(m),d.add(l)}a=b;0<c.count&&(a+=this.$y+yg(this,c.oc(),!0));0<d.count&&(a+=this.sC+yg(this,d.oc(),!0));0<e.count&&(a+=this.bz+yg(this,e.oc(),!0));return a};f.$y=',\n  "insertedLinkKeys": ';f.sC=',\n  "modifiedLinkData": ';f.bz=',\n  "removedLinkKeys": ';
f.jC=function(a,b){""===this.Pj&&D.k("GraphLinksModel.linkKeyProperty must not be an empty string for .toIncrementalJson() to succeed.");var c=M.prototype.jC.call(this,a,b),d=this,e=new L,g=new L,h=new L;a.fg.each(function(a){a.da===d&&("linkDataArray"===a.Cf?a.Kc===rg?e.add(a.newValue):a.Kc===sg&&h.add(a.oldValue):d.Nh(a.object)&&g.add(a.object))});var k=new L;e.each(function(a){k.add(d.mf(a));b||g.add(a)});var l=new L;h.each(function(a){l.add(d.mf(a));b&&g.add(a)});0<k.count&&(c+=(b?this.bz:this.$y)+
yg(this,k.oc(),!0));0<g.count&&(c+=this.sC+yg(this,g.oc(),!0));0<l.count&&(c+=(b?this.$y:this.bz)+yg(this,l.oc(),!0));return c};
f.AB=function(a){M.prototype.AB.call(this,a);var b=a.insertedLinkKeys,c=a.modifiedLinkData,d=new oa;if(D.isArray(c))for(var e=0;e<c.length;e++){var g=D.La(c,e),h=this.mf(g);void 0!==h&&null!==h&&d.set(h,g)}if(D.isArray(b))for(var e=D.cb(b),k=0;k<e;k++)g=D.La(b,k),h=this.lq(g),null===h&&(h=(h=d.get(g))?h:this.Ax({}),this.XG(h,g),this.St(h));if(D.isArray(c))for(e=D.cb(c),k=0;k<e;k++)if(g=D.La(c,k),h=this.mf(g),h=this.lq(h),null!==h)for(var l in g)"__gohashid"!==l&&l!==this.Pj&&this.setDataProperty(h,
l,g[l]);a=a.removedLinkKeys;if(D.isArray(a))for(e=D.cb(a),k=0;k<e;k++)g=D.La(a,k),h=this.lq(g),null!==h&&this.By(h)};
f.Gn=function(a,b){if(a.Kc===rg){var c=a.Qj;if("linkDataArray"===a.Cf){var d=a.newValue;if(D.Qa(d)&&"number"===typeof c){var e=this.mf(d);b?(this.ji.remove(d),D.La(this.ef,c)===d&&D.Wg(this.ef,c),void 0!==e&&this.sd.remove(e)):(this.ji.add(d),D.La(this.ef,c)!==d&&D.Kh(this.ef,c,d),void 0!==e&&this.sd.add(e,d))}return}if("linkLabelKeys"===a.Cf){d=this.Vk(a.object);D.isArray(d)&&"number"===typeof c&&(b?(c=D.gm(d,a.newValue),0<=c&&D.Wg(d,c)):0>D.gm(d,a.newValue)&&D.Kh(d,c,a.newValue));return}}else if(a.Kc===
sg){c=a.Sj;if("linkDataArray"===a.Cf){d=a.oldValue;D.Qa(d)&&"number"===typeof c&&(e=this.mf(d),b?(this.ji.add(d),D.La(this.ef,c)!==d&&D.Kh(this.ef,c,d),void 0!==e&&this.sd.add(e,d)):(this.ji.remove(d),D.La(this.ef,c)===d&&D.Wg(this.ef,c),void 0!==e&&this.sd.remove(e)));return}if("linkLabelKeys"===a.Cf){d=this.Vk(a.object);D.isArray(d)&&"number"===typeof c&&(b?0>D.gm(d,a.newValue)&&D.Kh(d,c,a.newValue):(c=D.gm(d,a.newValue),0<=c&&D.Wg(d,c)));return}}M.prototype.Gn.call(this,a,b)};
D.defineProperty(Y,{vA:"archetypeNodeData"},function(){return this.ul},function(a){var b=this.ul;b!==a&&(null!==a&&D.l(a,Object,Y,"archetypeNodeData"),this.ul=a,this.i("archetypeNodeData",b,a))});Y.prototype.ho=function(a){if(void 0!==a){var b=this.ul;if(null!==b){var c=this.He(a);null===c&&(c=this.copyNodeData(b),D.Ta(c,this.yk,a),this.cm(c))}return a}};
D.defineProperty(Y,{eo:"linkFromKeyProperty"},function(){return this.ki},function(a){var b=this.ki;b!==a&&(bh(a,Y,"linkFromKeyProperty"),this.ki=a,this.i("linkFromKeyProperty",b,a))});Y.prototype.getFromKeyForLinkData=Y.prototype.um=function(a){if(null!==a){var b=this.ki;if(""!==b&&(b=D.wb(a,b),void 0!==b)){if(ch(b))return b;D.k("FromKey value for link data "+a+" is not a number or a string: "+b)}}};
Y.prototype.setFromKeyForLinkData=Y.prototype.LB=function(a,b){null===b&&(b=void 0);void 0===b||ch(b)||D.jc(b,"number or string",Y,"setFromKeyForLinkData:key");if(null!==a){var c=this.ki;if(""!==c)if(b=this.ho(b),this.Nh(a)){var d=D.wb(a,c);d!==b&&(hh(this,d,a),D.Ta(a,c,b),null===this.He(b)&&gh(this,b,a),ah(this,"linkFromKey",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};
D.defineProperty(Y,{fo:"linkToKeyProperty"},function(){return this.li},function(a){var b=this.li;b!==a&&(bh(a,Y,"linkToKeyProperty"),this.li=a,this.i("linkToKeyProperty",b,a))});Y.prototype.getToKeyForLinkData=Y.prototype.vm=function(a){if(null!==a){var b=this.li;if(""!==b&&(b=D.wb(a,b),void 0!==b)){if(ch(b))return b;D.k("ToKey value for link data "+a+" is not a number or a string: "+b)}}};
Y.prototype.setToKeyForLinkData=Y.prototype.RB=function(a,b){null===b&&(b=void 0);void 0===b||ch(b)||D.jc(b,"number or string",Y,"setToKeyForLinkData:key");if(null!==a){var c=this.li;if(""!==c)if(b=this.ho(b),this.Nh(a)){var d=D.wb(a,c);d!==b&&(hh(this,d,a),D.Ta(a,c,b),null===this.He(b)&&gh(this,b,a),ah(this,"linkToKey",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};
D.defineProperty(Y,{Ju:"linkFromPortIdProperty"},function(){return this.dn},function(a){var b=this.dn;b!==a&&(bh(a,Y,"linkFromPortIdProperty"),a!==this.eo&&a!==this.fo||D.k("linkFromPortIdProperty name must not be the same as the GraphLinksModel.linkFromKeyProperty or linkToKeyProperty: "+a),this.dn=a,this.i("linkFromPortIdProperty",b,a))});Y.prototype.getFromPortIdForLinkData=Y.prototype.OI=function(a){if(null===a)return"";var b=this.dn;if(""===b)return"";a=D.wb(a,b);return void 0===a?"":a};
Y.prototype.setFromPortIdForLinkData=Y.prototype.MB=function(a,b){D.h(b,"string",Y,"setFromPortIdForLinkData:portname");if(null!==a){var c=this.dn;if(""!==c)if(this.Nh(a)){var d=D.wb(a,c);void 0===d&&(d="");d!==b&&(D.Ta(a,c,b),ah(this,"linkFromPortId",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};
D.defineProperty(Y,{Lu:"linkToPortIdProperty"},function(){return this.en},function(a){var b=this.en;b!==a&&(bh(a,Y,"linkToPortIdProperty"),a!==this.eo&&a!==this.fo||D.k("linkFromPortIdProperty name must not be the same as the GraphLinksModel.linkFromKeyProperty or linkToKeyProperty: "+a),this.en=a,this.i("linkToPortIdProperty",b,a))});Y.prototype.getToPortIdForLinkData=Y.prototype.TI=function(a){if(null===a)return"";var b=this.en;if(""===b)return"";a=D.wb(a,b);return void 0===a?"":a};
Y.prototype.setToPortIdForLinkData=Y.prototype.SB=function(a,b){D.h(b,"string",Y,"setToPortIdForLinkData:portname");if(null!==a){var c=this.en;if(""!==c)if(this.Nh(a)){var d=D.wb(a,c);void 0===d&&(d="");d!==b&&(D.Ta(a,c,b),ah(this,"linkToPortId",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};D.defineProperty(Y,{Ku:"linkLabelKeysProperty"},function(){return this.Lg},function(a){var b=this.Lg;b!==a&&(bh(a,Y,"linkLabelKeysProperty"),this.Lg=a,this.i("linkLabelKeysProperty",b,a))});
Y.prototype.getLabelKeysForLinkData=Y.prototype.Vk=function(a){if(null===a)return D.Do;var b=this.Lg;if(""===b)return D.Do;a=D.wb(a,b);return void 0===a?D.Do:a};
Y.prototype.setLabelKeysForLinkData=Y.prototype.YG=function(a,b){D.Zt(b,Y,"setLabelKeysForLinkData:arr");if(null!==a){var c=this.Lg;if(""!==c)if(this.Nh(a)){var d=D.wb(a,c);void 0===d&&(d=D.Do);if(d!==b){for(var e=D.cb(d),g=0;g<e;g++){var h=D.La(d,g);hh(this,h,a)}D.Ta(a,c,b);e=D.cb(b);for(g=0;g<e;g++)h=D.La(b,g),null===this.He(h)&&gh(this,h,a);ah(this,"linkLabelKeys",fg,c,a,d,b);"string"===typeof c&&this.Ob(a,c)}}else D.Ta(a,c,b)}};
Y.prototype.addLabelKeyForLinkData=Y.prototype.LE=function(a,b){if(null!==b&&void 0!==b&&(ch(b)||D.jc(b,"number or string",Y,"addLabelKeyForLinkData:key"),null!==a)){var c=this.Lg;if(""!==c){var d=D.wb(a,c);if(void 0===d)c=[],c.push(b),this.YG(a,c);else if(D.isArray(d)){var e=D.gm(d,b);0<=e||(e=D.cb(d),D.Kh(d,Infinity,b),this.Nh(a)&&(null===this.He(b)&&gh(this,b,a),ah(this,"linkLabelKeys",rg,c,a,null,b,null,e)))}else D.k(c+" property is not an Array; cannot addLabelKeyForLinkData: "+a)}}};
Y.prototype.removeLabelKeyForLinkData=Y.prototype.$J=function(a,b){if(null!==b&&void 0!==b&&(ch(b)||D.jc(b,"number or string",Y,"removeLabelKeyForLinkData:key"),null!==a)){var c=this.Lg;if(""!==c){var d=D.wb(a,c);if(D.isArray(d)){var e=D.gm(d,b);0>e||(D.Wg(d,e),this.Nh(a)&&(hh(this,b,a),ah(this,"linkLabelKeys",sg,c,a,b,null,e,null)))}else void 0!==d&&D.k(c+" property is not an Array; cannot removeLabelKeyforLinkData: "+a)}}};
D.defineProperty(Y,{ah:"linkDataArray"},function(){return this.ef},function(a){var b=this.ef;if(b!==a){D.Zt(a,Y,"linkDataArray");this.sd.clear();for(var c=D.cb(a),d=0;d<c;d++){var e=D.La(a,d);if(!D.Qa(e)){D.k("GraphLinksModel.linkDataArray must only contain Objects, not: "+e);return}D.pq(e)}this.ef=a;if(""!==this.Pj){for(var g=new K(Object),d=0;d<c;d++){var e=D.La(a,d),h=this.mf(e);void 0===h?g.add(e):null!==this.sd.oa(h)?g.add(e):this.sd.add(h,e)}for(d=g.j;d.next();)e=d.value,this.my(e),g=this.mf(e),
void 0!==g&&this.sd.add(g,e)}g=new L(Object);for(d=0;d<c;d++)e=D.La(a,d),g.add(e);this.ji=g;ah(this,"linkDataArray",fg,"linkDataArray",this,b,a);for(d=0;d<c;d++)e=D.La(a,d),mh(this,e)}});
D.defineProperty(Y,{Pj:"linkKeyProperty"},function(){return this.Nl},function(a){var b=this.Nl;if(b!==a){bh(a,Y,"linkKeyProperty");this.Nl=a;this.sd.clear();for(var c=D.cb(this.ah),d=0;d<c;d++){var e=D.La(this.ah,d),g=this.mf(e);void 0===g&&(this.my(e),g=this.mf(e));void 0!==g&&this.sd.add(g,e)}this.i("linkKeyProperty",b,a)}});
Y.prototype.getKeyForLinkData=Y.prototype.mf=function(a){if(null!==a){var b=this.Nl;if(""!==b&&(b=D.wb(a,b),void 0!==b)){if(ch(b))return b;D.k("Key value for link data "+a+" is not a number or a string: "+b)}}};
Y.prototype.setKeyForLinkData=Y.prototype.XG=function(a,b){void 0!==b&&null!==b&&ch(b)||D.jc(b,"number or string",Y,"setKeyForLinkData:key");if(null!==a){var c=this.Nl;if(""!==c)if(this.Nh(a)){var d=D.wb(a,c);d!==b&&null===this.lq(b)&&(D.Ta(a,c,b),this.sd.remove(d),this.sd.add(b,a),ah(this,"linkKey",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};
D.defineProperty(Y,{YL:"makeUniqueLinkKeyFunction"},function(){return this.wp},function(a){var b=this.wp;b!==a&&(null!==a&&D.h(a,"function",Y,"makeUniqueLinkKeyFunction"),this.wp=a,this.i("makeUniqueLinkKeyFunction",b,a))});Y.prototype.findLinkDataForKey=Y.prototype.lq=function(a){null===a&&D.k("GraphLinksModel.findLinkDataForKey:key must not be null");return void 0!==a&&ch(a)?this.sd.oa(a):null};
Y.prototype.makeLinkDataKeyUnique=Y.prototype.my=function(a){if(null!==a){var b=this.Nl;if(""!==b){var c=this.mf(a);if(void 0===c||this.sd.contains(c)){var d=this.wp;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.sd.contains(c))){D.Ta(a,b,c);return}if("string"===typeof c){for(d=2;this.sd.contains(c+d);)d++;D.Ta(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(d=-this.sd.count-1;this.sd.contains(d);)d--;D.Ta(a,b,d)}else D.k("GraphLinksModel.getKeyForLinkData returned something other than a string or a number: "+
c)}}}};Y.prototype.containsLinkData=Y.prototype.Nh=function(a){return null===a?!1:this.ji.contains(a)};Y.prototype.addLinkData=Y.prototype.St=function(a){null!==a&&(D.pq(a),this.Nh(a)||nh(this,a,!0))};
function nh(a,b,c){if(""!==a.Pj){var d=a.mf(b);if(void 0!==d&&a.sd.oa(d)===b)return;a.my(b);d=a.mf(b);if(void 0===d){D.k("GraphLinksModel.makeLinkDataKeyUnique failed on "+b+". Data not added to model.");return}a.sd.add(d,b)}a.ji.add(b);d=null;c&&(d=D.cb(a.ef),D.Kh(a.ef,d,b));ah(a,"linkDataArray",rg,"linkDataArray",a,null,b,null,d);mh(a,b)}Y.prototype.addLinkDataCollection=function(a){if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++)this.St(D.La(a,c));else for(a=a.j;a.next();)this.St(a.value)};
Y.prototype.removeLinkData=Y.prototype.By=function(a){null!==a&&oh(this,a,!0)};function oh(a,b,c){a.ji.remove(b);var d=a.mf(b);void 0!==d&&a.sd.remove(d);d=null;if(c){d=D.gm(a.ef,b);if(0>d)return;D.Wg(a.ef,d)}ah(a,"linkDataArray",sg,"linkDataArray",a,b,null,d,null);c=a.um(b);hh(a,c,b);c=a.vm(b);hh(a,c,b);d=a.Vk(b);if(D.isArray(d))for(var e=D.cb(d),g=0;g<e;g++)c=D.La(d,g),hh(a,c,b)}
Y.prototype.removeLinkDataCollection=function(a){if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++)this.By(D.La(a,c));else for(a=a.j;a.next();)this.By(a.value)};function mh(a,b){var c=a.um(b),c=a.ho(c);null===a.He(c)&&gh(a,c,b);c=a.vm(b);c=a.ho(c);null===a.He(c)&&gh(a,c,b);var d=a.Vk(b);if(D.isArray(d))for(var e=D.cb(d),g=0;g<e;g++)c=D.La(d,g),null===a.He(c)&&gh(a,c,b)}
D.defineProperty(Y,{nL:"copyLinkDataFunction"},function(){return this.Oo},function(a){var b=this.Oo;b!==a&&(null!==a&&D.h(a,"function",Y,"copyLinkDataFunction"),this.Oo=a,this.i("copyLinkDataFunction",b,a))});Y.prototype.copyLinkData=Y.prototype.Ax=function(a){if(null===a)return null;var b=null,b=this.Oo,b=null!==b?b(a,this):ih(this,a,!0);D.Qa(b)&&(D.vc(b),""!==this.ki&&D.Ta(b,this.ki,void 0),""!==this.li&&D.Ta(b,this.li,void 0),""!==this.Lg&&D.Ta(b,this.Lg,[]));return b};
D.defineProperty(Y,{Dq:"nodeIsGroupProperty"},function(){return this.zp},function(a){var b=this.zp;b!==a&&(bh(a,Y,"nodeIsGroupProperty"),this.zp=a,this.i("nodeIsGroupProperty",b,a))});Y.prototype.isGroupForNodeData=Y.prototype.fB=function(a){if(null===a)return!1;var b=this.zp;return""===b?!1:D.wb(a,b)?!0:!1};D.defineProperty(Y,{Su:"nodeGroupKeyProperty"},function(){return this.Bh},function(a){var b=this.Bh;b!==a&&(bh(a,Y,"nodeGroupKeyProperty"),this.Bh=a,this.i("nodeGroupKeyProperty",b,a))});
D.defineProperty(Y,{Kn:"copiesGroupKeyOfNodeData"},function(){return this.tr},function(a){this.tr!==a&&(D.h(a,"boolean",Y,"copiesGroupKeyOfNodeData"),this.tr=a)});Y.prototype.getGroupKeyForNodeData=Y.prototype.Tn=function(a){if(null!==a){var b=this.Bh;if(""!==b&&(b=D.wb(a,b),void 0!==b)){if(ch(b))return b;D.k("GroupKey value for node data "+a+" is not a number or a string: "+b)}}};
Y.prototype.setGroupKeyForNodeData=Y.prototype.NB=function(a,b){null===b&&(b=void 0);void 0===b||ch(b)||D.jc(b,"number or string",Y,"setGroupKeyForNodeData:key");if(null!==a){var c=this.Bh;if(""!==c)if(this.le(a)){var d=D.wb(a,c);d!==b&&(hh(this,d,a),D.Ta(a,c,b),null===this.He(b)&&gh(this,b,a),ah(this,"nodeGroupKey",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};
Y.prototype.copyNodeData=function(a){if(null===a)return null;a=M.prototype.copyNodeData.call(this,a);this.Kn||""===this.Bh||void 0===D.wb(a,this.Bh)||D.Ta(a,this.Bh,void 0);return a};
Y.prototype.setDataProperty=function(a,b,c){v&&(D.h(a,"object",Y,"setDataProperty:data"),D.h(b,"string",Y,"setDataProperty:propname"),""===b&&D.k("GraphLinksModel.setDataProperty: property name must not be an empty string when setting "+a+" to "+c));if(this.le(a))if(b===this.Rj)this.Ly(a,c);else{if(b===this.oo){this.Jy(a,c);return}if(b===this.Su){this.NB(a,c);return}b===this.Dq&&D.k("GraphLinksModel.setDataProperty: property name must not be the nodeIsGroupProperty: "+b)}else if(this.Nh(a)){if(b===
this.eo){this.LB(a,c);return}if(b===this.fo){this.RB(a,c);return}if(b===this.Ju){this.MB(a,c);return}if(b===this.Lu){this.SB(a,c);return}if(b===this.Pj){this.XG(a,c);return}if(b===this.Iu){this.WG(a,c);return}if(b===this.Ku){this.YG(a,c);return}}else!lh&&a instanceof P&&(lh=!0,D.trace('GraphLinksModel.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),D.trace("  Is that really your intent?"));var d=D.wb(a,b);d!==c&&(D.Ta(a,b,c),this.zB(a,b,d,c))};f=Y.prototype;
f.Yu=function(a,b){M.prototype.Yu.call(this,a,b);for(var c=this.Gc.j;c.next();)this.FB(c.value,a,b);for(c=this.ji.j;c.next();){var d=c.value,e=a,g=b;if(this.um(d)===e){var h=this.ki;D.Ta(d,h,g);ah(this,"linkFromKey",fg,h,d,e,g);"string"===typeof h&&this.Ob(d,h)}this.vm(d)===e&&(h=this.li,D.Ta(d,h,g),ah(this,"linkToKey",fg,h,d,e,g),"string"===typeof h&&this.Ob(d,h));h=this.Vk(d);if(D.isArray(h))for(var k=D.cb(h),l=this.Lg,m=0;m<k;m++)D.La(h,m)===e&&(D.SE(h,m,g),ah(this,"linkLabelKeys",rg,l,d,e,g,m,
m))}};f.FB=function(a,b,c){if(this.Tn(a)===b){var d=this.Bh;D.Ta(a,d,c);ah(this,"nodeGroupKey",fg,d,a,b,c);"string"===typeof d&&this.Ob(a,d)}};f.fC=function(){M.prototype.fC.call(this);for(var a=this.ah,b=D.cb(a),c=0;c<b;c++){var d=D.La(a,c);mh(this,d)}};
f.Hq=function(a){M.prototype.Hq.call(this,a);a=this.zb(a);var b=fh(this,a);if(null!==b){for(var c=new K(Object),b=b.j;b.next();){var d=b.value;if(this.le(d)){if(this.Tn(d)===a){var e=this.Bh;ah(this,"nodeGroupKey",fg,e,d,a,a);"string"===typeof e&&this.Ob(d,e);c.add(d)}}else if(this.um(d)===a&&(e=this.ki,ah(this,"linkFromKey",fg,e,d,a,a),"string"===typeof e&&this.Ob(d,e),c.add(d)),this.vm(d)===a&&(e=this.li,ah(this,"linkToKey",fg,e,d,a,a),"string"===typeof e&&this.Ob(d,e),c.add(d)),e=this.Vk(d),D.isArray(e))for(var g=
D.cb(e),h=this.Lg,k=0;k<g;k++)D.La(e,k)===a&&(ah(this,"linkLabelKeys",rg,h,d,a,a,k,k),c.add(d))}for(c=c.j;c.next();)hh(this,a,c.value)}};f.Gq=function(a){M.prototype.Gq.call(this,a);var b=this.Tn(a);null===this.He(b)&&gh(this,b,a)};f.mv=function(a){M.prototype.mv.call(this,a);var b=this.Tn(a);hh(this,b,a)};D.defineProperty(Y,{Iu:"linkCategoryProperty"},function(){return this.cn},function(a){var b=this.cn;b!==a&&(bh(a,Y,"linkCategoryProperty"),this.cn=a,this.i("linkCategoryProperty",b,a))});
Y.prototype.getCategoryForLinkData=Y.prototype.Qx=function(a){if(null===a)return"";var b=this.cn;if(""===b)return"";b=D.wb(a,b);if(void 0===b)return"";if("string"===typeof b)return b;D.k("getCategoryForLinkData found a non-string category for "+a+": "+b);return""};
Y.prototype.setCategoryForLinkData=Y.prototype.WG=function(a,b){D.h(b,"string",Y,"setCategoryForLinkData:cat");if(null!==a){var c=this.cn;if(""!==c)if(this.Nh(a)){var d=D.wb(a,c);void 0===d&&(d="");d!==b&&(D.Ta(a,c,b),ah(this,"linkCategory",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};
function Bg(a){1<arguments.length&&D.k("TreeModel constructor can only take one optional argument, the Array of node data.");M.call(this);this.Ch="parent";this.ur=!1;this.mn="parentLinkCategory";void 0!==a&&(this.qg=a)}D.Sa(Bg,M);D.ka("TreeModel",Bg);Bg.prototype.cloneProtected=function(a){M.prototype.cloneProtected.call(this,a);a.Ch=this.Ch;a.ur=this.ur;a.mn=this.mn};
Bg.prototype.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.YB();var b=(""!==this.name?this.name:"")+" TreeModel";if(0<a){b+="\n node data:";a=this.qg;for(var c=D.cb(a),d=0;d<c;d++)var e=D.La(a,d),b=b+(" "+this.zb(e)+":"+ia(e))}return b};Bg.prototype.Ao=function(){var a=M.prototype.Ao.call(this),b="";"parent"!==this.Tu&&"string"===typeof this.Tu&&(b+=',\n  "nodeParentKeyProperty": '+this.quote(this.Tu));return a+b};
Bg.prototype.Wu=function(a){M.prototype.Wu.call(this,a);a.nodeParentKeyProperty&&(this.Tu=a.nodeParentKeyProperty)};Bg.prototype.ho=function(a){return a};D.defineProperty(Bg,{Tu:"nodeParentKeyProperty"},function(){return this.Ch},function(a){var b=this.Ch;b!==a&&(bh(a,Bg,"nodeParentKeyProperty"),this.Ch=a,this.i("nodeParentKeyProperty",b,a))});
D.defineProperty(Bg,{Ln:"copiesParentKeyOfNodeData"},function(){return this.ur},function(a){this.ur!==a&&(D.h(a,"boolean",Bg,"copiesParentKeyOfNodeData"),this.ur=a)});Bg.prototype.getParentKeyForNodeData=Bg.prototype.Vn=function(a){if(null!==a){var b=this.Ch;if(""!==b&&(b=D.wb(a,b),void 0!==b)){if(ch(b))return b;D.k("ParentKey value for node data "+a+" is not a number or a string: "+b)}}};
Bg.prototype.setParentKeyForNodeData=Bg.prototype.Ji=function(a,b){null===b&&(b=void 0);void 0===b||ch(b)||D.jc(b,"number or string",Bg,"setParentKeyForNodeData:key");if(null!==a){var c=this.Ch;if(""!==c)if(b=this.ho(b),this.le(a)){var d=D.wb(a,c);d!==b&&(hh(this,d,a),D.Ta(a,c,b),null===this.He(b)&&gh(this,b,a),ah(this,"nodeParentKey",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};
D.defineProperty(Bg,{rM:"parentLinkCategoryProperty"},function(){return this.mn},function(a){var b=this.mn;b!==a&&(bh(a,Bg,"parentLinkCategoryProperty"),this.mn=a,this.i("parentLinkCategoryProperty",b,a))});Bg.prototype.getParentLinkCategoryForNodeData=Bg.prototype.RI=function(a){if(null===a)return"";var b=this.mn;if(""===b)return"";b=D.wb(a,b);if(void 0===b)return"";if("string"===typeof b)return b;D.k("getParentLinkCategoryForNodeData found a non-string category for "+a+": "+b);return""};
Bg.prototype.setParentLinkCategoryForNodeData=Bg.prototype.lK=function(a,b){D.h(b,"string",Bg,"setParentLinkCategoryForNodeData:cat");if(null!==a){var c=this.mn;if(""!==c)if(this.le(a)){var d=D.wb(a,c);void 0===d&&(d="");d!==b&&(D.Ta(a,c,b),ah(this,"parentLinkCategory",fg,c,a,d,b),"string"===typeof c&&this.Ob(a,c))}else D.Ta(a,c,b)}};
Bg.prototype.copyNodeData=function(a){if(null===a)return null;a=M.prototype.copyNodeData.call(this,a);this.Ln||""===this.Ch||void 0===D.wb(a,this.Ch)||D.Ta(a,this.Ch,void 0);return a};
Bg.prototype.setDataProperty=function(a,b,c){v&&(D.h(a,"object",Bg,"setDataProperty:data"),D.h(b,"string",Bg,"setDataProperty:propname"),""===b&&D.k("TreeModel.setDataProperty: property name must not be an empty string when setting "+a+" to "+c));if(this.le(a))if(b===this.Rj)this.Ly(a,c);else{if(b===this.oo){this.Jy(a,c);return}if(b===this.Tu){this.Ji(a,c);return}}else!lh&&a instanceof P&&(lh=!0,D.trace('TreeModel.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),D.trace("  Is that really your intent?"));
var d=D.wb(a,b);d!==c&&(D.Ta(a,b,c),this.zB(a,b,d,c))};f=Bg.prototype;f.Yu=function(a,b){M.prototype.Yu.call(this,a,b);for(var c=this.Gc.j;c.next();)this.FB(c.value,a,b)};f.FB=function(a,b,c){if(this.Vn(a)===b){var d=this.Ch;D.Ta(a,d,c);ah(this,"nodeParentKey",fg,d,a,b,c);"string"===typeof d&&this.Ob(a,d)}};
f.Hq=function(a){M.prototype.Hq.call(this,a);a=this.zb(a);var b=fh(this,a);if(null!==b){for(var c=new K(Object),b=b.j;b.next();){var d=b.value;if(this.le(d)&&this.Vn(d)===a){var e=this.Ch;ah(this,"nodeParentKey",fg,e,d,a,a);"string"===typeof e&&this.Ob(d,e);c.add(d)}}for(c=c.j;c.next();)hh(this,a,c.value)}};f.Gq=function(a){M.prototype.Gq.call(this,a);var b=this.Vn(a),b=this.ho(b);null===this.He(b)&&gh(this,b,a)};f.mv=function(a){M.prototype.mv.call(this,a);var b=this.Vn(a);hh(this,b,a)};
function ph(a,b,c){D.vc(this);this.J=!1;void 0===a?a="":D.h(a,"string",ph,"constructor:targetprop");void 0===b?b=a:D.h(b,"string",ph,"constructor:sourceprop");void 0===c?c=null:null!==c&&D.h(c,"function",ph,"constructor:conv");this.mE=-1;this.Tg=null;this.Sp=a;this.Rp=this.Gt=0;this.Xw=null;this.ls=!1;this.Jp=b;this.qr=c;this.Fs=qh;this.jr=null;this.Gz=new L}D.ka("Binding",ph);
ph.prototype.copy=function(){var a=new ph;a.Sp=this.Sp;a.Gt=this.Gt;a.Rp=this.Rp;a.Xw=this.Xw;a.ls=this.ls;a.Jp=this.Jp;a.qr=this.qr;a.Fs=this.Fs;a.jr=this.jr;return a};var qh;ph.OneWay=qh=D.s(ph,"OneWay",1);var rh;ph.TwoWay=rh=D.s(ph,"TwoWay",2);ph.parseEnum=function(a,b){D.h(a,"function",ph,"parseEnum:ctor");D.Da(b,a,ph,"parseEnum:defval");return function(c){c=Ha(a,c);return null===c?b:c}};ph.prototype.qc=function(a){a.Oe===ph?this.mode=a:D.Yj(this,a)};var ia;
ph.toString=ia=function(a){var b=a;D.Qa(a)&&(a.text?b=a.text:a.name?b=a.name:void 0!==a.key?b=a.key:void 0!==a.id?b=a.id:a.constructor===Object&&(a.Text?b=a.Text:a.Name?b=a.Name:void 0!==a.Key?b=a.Key:void 0!==a.Id?b=a.Id:void 0!==a.ID&&(b=a.ID)));return void 0===b?"undefined":null===b?"null":b.toString()};ph.prototype.toString=function(){return"Binding("+this.jv+":"+this.aH+(-1!==this.Fm?" "+this.Fm:"")+" "+this.mode.name+")"};ph.prototype.freeze=function(){this.J=!0;return this};
ph.prototype.Xa=function(){this.J=!1;return this};D.defineProperty(ph,{Fm:null},function(){return this.mE},function(a){this.J&&D.qa(this);D.h(a,"number",ph,"targetId");this.mE=a});D.defineProperty(ph,{jv:"targetProperty"},function(){return this.Sp},function(a){this.J&&D.qa(this);D.h(a,"string",ph,"targetProperty");this.Sp=a});D.defineProperty(ph,{Sq:"sourceName"},function(){return this.Xw},function(a){this.J&&D.qa(this);null!==a&&D.h(a,"string",ph,"sourceName");this.Xw=a;null!==a&&(this.ls=!1)});
D.defineProperty(ph,{ay:"isToModel"},function(){return this.ls},function(a){this.J&&D.qa(this);D.h(a,"boolean",ph,"isToModel");this.ls=a});D.defineProperty(ph,{aH:"sourceProperty"},function(){return this.Jp},function(a){this.J&&D.qa(this);D.h(a,"string",ph,"sourceProperty");this.Jp=a});D.defineProperty(ph,{kI:"converter"},function(){return this.qr},function(a){this.J&&D.qa(this);null!==a&&D.h(a,"function",ph,"converter");this.qr=a});
D.defineProperty(ph,{$H:"backConverter"},function(){return this.jr},function(a){this.J&&D.qa(this);null!==a&&D.h(a,"function",ph,"backConverter");this.jr=a});D.defineProperty(ph,{mode:"mode"},function(){return this.Fs},function(a){this.J&&D.qa(this);D.Da(a,ph,ph,"mode");this.Fs=a});ph.prototype.makeTwoWay=ph.prototype.CJ=function(a){void 0===a&&(a=null);null!==a&&D.h(a,"function",ph,"makeTwoWay");this.mode=rh;this.$H=a;return this};
ph.prototype.ofObject=ph.prototype.py=function(a){void 0===a&&(a="");v&&D.h(a,"string",ph,"ofObject:srcname");this.Sq=a;this.ay=!1;return this};ph.prototype.ofModel=function(){this.Sq=null;this.ay=!0;return this};function sh(a,b,c){a=a.Sq;var d=null;return d=null===a||""===a?b:"/"===a?c.Y:"."===a?c:".."===a?c.R:b.Hd(a)}
ph.prototype.updateTarget=ph.prototype.qH=function(a,b,c){var d=this.Jp;if(void 0===c||""===d||d===c){c=this.Sp;var e=this.qr;if(null===e&&""===c)D.trace("Binding error: target property is the empty string: "+this.toString());else{v&&"string"===typeof c&&("function"!==typeof a.setAttribute&&0<c.length&&"_"!==c[0]&&!D.Tx(a,c)?D.trace("Binding error: undefined target property: "+c+" on "+a.toString()):"name"===c&&a instanceof P&&D.trace("Binding error: cannot modify GraphObject.name on "+a.toString()));
var g=b;""!==d&&(g=D.wb(b,d));if(void 0!==g)if(null===e)""!==c&&D.Ta(a,c,g);else try{if(""!==c){var h=e(g,a);v&&void 0===h&&D.trace('Binding warning: conversion function returned undefined when setting target property "'+c+'" on '+a.toString()+", function is: "+e);D.Ta(a,c,h)}else e(g,a)}catch(k){v&&D.trace("Binding error: "+k.toString()+' setting target property "'+c+'" on '+a.toString()+" with conversion function: "+e)}}}};
ph.prototype.updateSource=ph.prototype.Oy=function(a,b,c,d){if(this.Fs===rh){var e=this.Sp;if(void 0===c||e===c){c=this.Jp;var g=this.jr,h=a;""!==e&&(h=D.wb(a,e));if(void 0!==h&&!this.Gz.contains(a))try{this.Gz.add(a);var k=null!==d?d.g:null,l=null!==k?k.da:null;if(null===g)if(""!==c)null!==l?(v&&l.Rj===c&&l.le(b)&&D.trace("Binding error: cannot have TwoWay Binding on node data key property: "+this.toString()),l.setDataProperty(b,c,h)):D.Ta(b,c,h);else{if(null!==l&&null!==d&&0<=d.Gu&&null!==d.R&&
Array.isArray(d.R.al)){var m=d.Gu,n=d.R.al;l.CG(n,m);l.bB(n,m,h)}}else try{if(""!==c){var p=g(h,b,l);null!==l?(v&&(l.Rj===c&&l.le(b)&&D.trace("Binding error: cannot have TwoWay Binding on node data key property: "+this.toString()),void 0===p&&D.trace('Binding warning: conversion function returned undefined when setting source property "'+c+'" on '+b.toString()+", function is: "+g)),l.setDataProperty(b,c,p)):D.Ta(b,c,p)}else p=g(h,b,l),void 0!==p&&null!==l&&null!==d&&0<=d.Gu&&null!==d.R&&Array.isArray(d.R.al)&&
(m=d.Gu,n=d.R.al,l.CG(n,m),l.bB(n,m,p))}catch(q){v&&D.trace("Binding error: "+q.toString()+' setting source property "'+c+'" on '+b.toString()+" with conversion function: "+g)}}finally{this.Gz.remove(a)}}}};function Cg(){this.wH=(new K(eg)).freeze();this.ac="";this.qD=!1}D.ka("Transaction",Cg);
Cg.prototype.toString=function(a){var b="Transaction: "+this.name+" "+this.fg.count.toString()+(this.zu?"":", incomplete");if(void 0!==a&&0<a){a=this.fg.count;for(var c=0;c<a;c++){var d=this.fg.ea(c);null!==d&&(b+="\n  "+d.toString())}}return b};Cg.prototype.clear=Cg.prototype.clear=function(){var a=this.fg;a.Xa();for(var b=a.count-1;0<=b;b--){var c=a.ea(b);null!==c&&c.clear()}a.clear();a.freeze()};Cg.prototype.canUndo=Cg.prototype.canUndo=function(){return this.zu};
Cg.prototype.undo=Cg.prototype.undo=function(){if(this.canUndo())for(var a=this.fg.count-1;0<=a;a--){var b=this.fg.ea(a);null!==b&&b.undo()}};Cg.prototype.canRedo=Cg.prototype.canRedo=function(){return this.zu};Cg.prototype.redo=Cg.prototype.redo=function(){if(this.canRedo())for(var a=this.fg.count,b=0;b<a;b++){var c=this.fg.ea(b);null!==c&&c.redo()}};D.w(Cg,{fg:"changes"},function(){return this.wH});D.defineProperty(Cg,{name:"name"},function(){return this.ac},function(a){this.ac=a});
D.defineProperty(Cg,{zu:"isComplete"},function(){return this.qD},function(a){this.qD=a});function tg(){this.Wz=new L(M);this.qf=!1;this.AH=(new K(Cg)).freeze();this.uh=-1;this.FD=999;this.Wf=!1;this.Nv=null;this.$l=0;this.kz=!1;v&&(this.kz=!0);this.Ah=(new K("string")).freeze();this.yp=new K("number");this.Hz=!0;this.Rz=!1}D.ka("UndoManager",tg);
tg.prototype.toString=function(a){for(var b="UndoManager "+this.Hj+"<"+this.history.count+"<="+this.mG,b=b+"[",c=this.rG.count,d=0;d<c;d++)0<d&&(b+=" "),b+=this.rG.ea(d);b+="]";if(void 0!==a&&0<a)for(c=this.history.count,d=0;d<c;d++)b+="\n "+this.history.ea(d).toString(a-1);return b};
tg.prototype.clear=tg.prototype.clear=function(){var a=this.history;a.Xa();for(var b=a.count-1;0<=b;b--){var c=a.ea(b);null!==c&&c.clear()}a.clear();this.uh=-1;a.freeze();this.Wf=!1;this.Nv=null;this.$l=0;this.Ah.Xa();this.Ah.clear();this.Ah.freeze();this.yp.clear()};tg.prototype.addModel=tg.prototype.SH=function(a){this.Wz.add(a)};tg.prototype.removeModel=tg.prototype.aK=function(a){this.Wz.remove(a)};
tg.prototype.startTransaction=tg.prototype.Nb=function(a){void 0===a&&(a="");null===a&&(a="");if(this.jb)return!1;!0===this.Hz&&(this.Hz=!1,this.$l++,this.ud("StartingFirstTransaction",a,this.Cj),0<this.$l&&this.$l--);this.isEnabled&&(this.Ah.Xa(),this.Ah.add(a),this.Ah.freeze(),null===this.Cj?this.yp.add(0):this.yp.add(this.Cj.fg.count));this.$l++;var b=1===this.Ki;b&&this.ud("StartedTransaction",a,this.Cj);return b};
tg.prototype.commitTransaction=tg.prototype.hd=function(a){void 0===a&&(a="");return th(this,!0,a)};tg.prototype.rollbackTransaction=tg.prototype.Bm=function(){return th(this,!1,"")};
function th(a,b,c){if(a.jb)return!1;a.xA&&1>a.Ki&&D.trace("Ending transaction without having started a transaction: "+c);var d=1===a.Ki;d&&b&&a.ud("CommittingTransaction",c,a.Cj);var e=0;if(0<a.Ki&&(a.$l--,a.isEnabled)){var g=a.Ah.count;0<g&&(""===c&&(c=a.Ah.ea(0)),a.Ah.Xa(),a.Ah.od(g-1),a.Ah.freeze());g=a.yp.count;0<g&&(e=a.yp.ea(g-1),a.yp.od(g-1))}g=a.Cj;if(d){if(b){a.Rz=!1;if(a.isEnabled&&null!==g){b=g;b.zu=!0;b.name=c;d=a.history;d.Xa();for(e=d.count-1;e>a.Hj;e--)g=d.ea(e),null!==g&&g.clear(),
d.od(e),a.Rz=!0;e=a.mG;0===e&&(e=1);0<e&&d.count>=e&&(g=d.ea(0),null!==g&&g.clear(),d.od(0),a.uh--);d.add(b);a.uh++;d.freeze();g=b}a.ud("CommittedTransaction",c,g)}else{a.Wf=!0;try{a.isEnabled&&null!==g&&(g.zu=!0,g.undo())}finally{a.ud("RolledBackTransaction",c,g),a.Wf=!1}null!==g&&g.clear()}a.Nv=null;return!0}if(a.isEnabled&&!b&&null!==g){a=e;c=g.fg;for(b=c.count-1;b>=a;b--)d=c.ea(b),null!==d&&d.undo(),c.Xa(),c.od(b);c.freeze()}return!1}
tg.prototype.canUndo=tg.prototype.canUndo=function(){if(!this.isEnabled||0<this.Ki||this.jb)return!1;var a=this.mH;return null!==a&&a.canUndo()?!0:!1};tg.prototype.undo=tg.prototype.undo=function(){if(this.canUndo()){var a=this.mH;try{this.ud("StartingUndo","Undo",a),this.Wf=!0,this.uh--,a.undo()}catch(b){D.trace("undo error: "+b.toString())}finally{this.Wf=!1,this.ud("FinishedUndo","Undo",a)}}};
tg.prototype.canRedo=tg.prototype.canRedo=function(){if(!this.isEnabled||0<this.Ki||this.jb)return!1;var a=this.lH;return null!==a&&a.canRedo()?!0:!1};tg.prototype.redo=tg.prototype.redo=function(){if(this.canRedo()){var a=this.lH;try{this.ud("StartingRedo","Redo",a),this.Wf=!0,this.uh++,a.redo()}catch(b){D.trace("redo error: "+b.toString())}finally{this.Wf=!1,this.ud("FinishedRedo","Redo",a)}}};
tg.prototype.ud=function(a,b,c){void 0===c&&(c=null);var d=new eg;d.Kc=gg;d.propertyName=a;d.object=c;d.oldValue=b;for(a=this.MJ;a.next();)b=a.value,d.da=b,b.wx(d)};tg.prototype.handleChanged=tg.prototype.OF=function(a){if(this.isEnabled&&!this.jb&&!this.skipsEvent(a)){var b=this.Cj;null===b&&(this.Nv=b=new Cg);var c=a.copy(),b=b.fg;b.Xa();b.add(c);b.freeze();this.xA&&0>=this.Ki&&!this.Hz&&(a=a.g,null!==a&&!1===a.$n||D.trace("Change not within a transaction: "+c.toString()))}};
tg.prototype.skipsEvent=function(a){if(null===a||0>a.Kc.value)return!0;a=a.object;if(a instanceof P){if(a=a.layer,null!==a&&a.Wc)return!0}else if(a instanceof Yg&&a.Wc)return!0;return!1};D.w(tg,{MJ:"models"},function(){return this.Wz.j});D.defineProperty(tg,{isEnabled:"isEnabled"},function(){return this.qf},function(a){this.qf=a});D.w(tg,{mH:"transactionToUndo"},function(){return 0<=this.Hj&&this.Hj<=this.history.count-1?this.history.ea(this.Hj):null});
D.w(tg,{lH:"transactionToRedo"},function(){return this.Hj<this.history.count-1?this.history.ea(this.Hj+1):null});D.w(tg,{jb:"isUndoingRedoing"},function(){return this.Wf});D.w(tg,{history:"history"},function(){return this.AH});D.defineProperty(tg,{mG:"maxHistoryLength"},function(){return this.FD},function(a){this.FD=a});D.w(tg,{Hj:"historyIndex"},function(){return this.uh});D.w(tg,{Cj:"currentTransaction"},function(){return this.Nv});D.w(tg,{Ki:"transactionLevel"},function(){return this.$l});
D.w(tg,{aG:"isInTransaction"},function(){return 0<this.$l});D.defineProperty(tg,{xA:"checksTransactionLevel"},function(){return this.kz},function(a){this.kz=a});D.w(tg,{rG:"nestedTransactionNames"},function(){return this.Ah});function qa(){0<arguments.length&&D.xd(qa);D.vc(this);this.ia=null;this.DC=!1;this.YC=this.EC=!0;this.GC=this.HC=this.ZC=this.IC=!1;this.Ql=this.ez=null;this.FE=1.05;this.VC=1;this.Sz=NaN;this.BD=null;this.sA=NaN;this.rA=Ld;this.rj=null;this.cE=0}D.ka("CommandHandler",qa);
var Nh=null,Oh="";qa.prototype.toString=function(){return"CommandHandler"};D.w(qa,{g:"diagram"},function(){return this.ia});qa.prototype.$c=function(a){v&&null!==a&&D.l(a,E,qa,"setDiagram");this.ia=a};
qa.prototype.doKeyDown=function(){var a=this.g;if(null!==a){var b=a.U,c=D.Zk?b.Nu:b.control,d=b.shift,e=b.alt,g=b.key;!c||"C"!==g&&"Insert"!==g?c&&"X"===g||d&&"Del"===g?this.canCutSelection()&&this.cutSelection():c&&"V"===g||d&&"Insert"===g?this.canPasteSelection()&&this.pasteSelection():c&&"Y"===g||e&&d&&"Backspace"===g?this.canRedo()&&this.redo():c&&"Z"===g||e&&"Backspace"===g?this.canUndo()&&this.undo():"Del"===g||"Backspace"===g?this.canDeleteSelection()&&this.deleteSelection():c&&"A"===g?this.canSelectAll()&&
this.selectAll():"Esc"===g?this.canStopCommand()&&this.stopCommand():"Up"===g?a.Be&&(c?a.scroll("pixel","up"):a.scroll("line","up")):"Down"===g?a.Be&&(c?a.scroll("pixel","down"):a.scroll("line","down")):"Left"===g?a.Ae&&(c?a.scroll("pixel","left"):a.scroll("line","left")):"Right"===g?a.Ae&&(c?a.scroll("pixel","right"):a.scroll("line","right")):"PageUp"===g?d&&a.Ae?a.scroll("page","left"):a.Be&&a.scroll("page","up"):"PageDown"===g?d&&a.Ae?a.scroll("page","right"):a.Be&&a.scroll("page","down"):"Home"===
g?c&&a.Be?a.scroll("document","up"):!c&&a.Ae&&a.scroll("document","left"):"End"===g?c&&a.Be?a.scroll("document","down"):!c&&a.Ae&&a.scroll("document","right"):" "===g?this.canScrollToPart()&&this.scrollToPart():"Subtract"===g?this.canDecreaseZoom()&&this.decreaseZoom():"Add"===g?this.canIncreaseZoom()&&this.increaseZoom():c&&"0"===g?this.canResetZoom()&&this.resetZoom():d&&"Z"===g?this.canZoomToFit()&&this.zoomToFit():c&&!d&&"G"===g?this.canGroupSelection()&&this.groupSelection():c&&d&&"G"===g?this.canUngroupSelection()&&
this.ungroupSelection():b.event&&113===b.event.which?this.canEditTextBlock()&&this.editTextBlock():b.event&&93===b.event.which?this.canShowContextMenu()&&this.showContextMenu():b.bubbles=!0:this.canCopySelection()&&this.copySelection()}};qa.prototype.doKeyUp=function(){var a=this.g;null!==a&&(a.U.bubbles=!0)};qa.prototype.stopCommand=function(){var a=this.g;if(null!==a){var b=a.hb;b instanceof Ph&&a.If&&a.yx();null!==b&&b.doCancel()}};qa.prototype.canStopCommand=function(){return!0};
qa.prototype.selectAll=function(){var a=this.g;if(null!==a){a.ra();try{a.mc="wait";a.Ja("ChangingSelection");for(var b=a.Ii;b.next();)b.value.ib=!0;for(var c=a.rg;c.next();)c.value.ib=!0;for(var d=a.links;d.next();)d.value.ib=!0}finally{a.Ja("ChangedSelection"),a.mc=""}}};qa.prototype.canSelectAll=function(){var a=this.g;return null!==a&&a.If};
qa.prototype.deleteSelection=function(){var a=this.g;if(null!==a&&!a.Ja("SelectionDeleting",a.selection))try{a.mc="wait";a.Nb("Delete");a.Ja("ChangingSelection");for(var b=new L(F),c=a.selection.j;c.next();)Qh(b,c.value,!0,this.tI?Infinity:0,this.kF,function(a){return a.canDelete()});a.EB(b,!0);a.Ja("SelectionDeleted",b)}finally{a.Ja("ChangedSelection"),a.hd("Delete"),a.mc=""}};qa.prototype.canDeleteSelection=function(){var a=this.g;return null===a||a.rb||a.Lf||!a.Dn||0===a.selection.count?!1:!0};
function Qh(a,b,c,d,e,g){void 0===g&&(g=null);if(!(a.contains(b)||null!==g&&!g(b)||b instanceof da))if(a.add(b),b instanceof G){if(c&&b instanceof I)for(var h=b.Oc;h.next();)Qh(a,h.value,c,d,e,g);if(e)for(h=b.Kd;h.next();){var k=h.value;if(!a.contains(k)){var l=k.aa,m=k.ba;null!==l&&a.contains(l)&&null!==m&&a.contains(m)?Qh(a,k,c,d,e,g):null!==l&&null!==m||Qh(a,k,c,d,e,g)}}if(1<d)for(b=b.zF();b.next();)Qh(a,b.value,c,d-1,e,g)}else if(b instanceof J)for(b=b.Af;b.next();)Qh(a,b.value,c,d,e,g)}
qa.prototype.eq=function(a,b,c){var d=new oa(F,F);for(a=a.j;a.next();)Rh(this,a.value,b,d,c);if(null!==b){c=b.da;a=!1;null!==b.ob.Fe&&(a=b.ob.Fe.Ej);for(var e=new L(J),g=new oa(J,J),h=d.j;h.next();){var k=h.value;if(k instanceof J){var l=k;a||null!==l.aa&&null!==l.ba||e.add(l)}else if(c instanceof Bg&&k instanceof G&&null!==k.data){var l=c,m=k,k=h.key,n=k.Tk();null!==n&&(n=d.oa(n),null!==n?(l.Ji(m.data,l.zb(n.data)),l=b.gg(m.data),k=k.Rn(),null!==k&&null!==l&&g.add(k,l)):l.Ji(m.data,void 0))}}0<e.count&&
b.EB(e,!1);if(0<g.count)for(c=g.j;c.next();)d.add(c.key,c.value)}if(null!==b&&null!==this.g&&(b=b.da,c=b.UH,null!==c)){var p=new oa;d.each(function(a){null!==a.key.data&&p.add(a.key.data,a.value.data)});c(p,b,this.g.da)}for(b=d.j;b.next();)b.value.Ob();return d};
function Rh(a,b,c,d,e){if(null===b||e&&!b.canCopy())return null;if(d.contains(b))return d.oa(b);var g=null,h=b.data;if(null!==h&&null!==c){var k=c.da;b instanceof J?k instanceof Y&&(h=k.Ax(h),D.Qa(h)&&(k.St(h),g=c.gg(h))):(h=k.copyNodeData(h),D.Qa(h)&&(k.cm(h),g=c.Ph(h)))}else Sh(b),g=b.copy(),null!==g&&(null!==c?c.add(g):null!==h&&null!==a.g&&a.lI&&(k=a.g.da,h=g instanceof J&&k instanceof Y?k.Ax(h):k.copyNodeData(h),D.Qa(h)&&(g.data=h)));if(!(g instanceof F))return null;g.ib=!1;g.$g=!1;d.add(b,g);
if(b instanceof G){for(h=b.Kd;h.next();){k=h.value;if(k.aa===b){var l=d.oa(k);null!==l&&(l.aa=g)}k.ba===b&&(l=d.oa(k),null!==l&&(l.ba=g))}if(b instanceof I&&g instanceof I)for(h=g,b=b.Oc;b.next();)k=Rh(a,b.value,c,d,e),k instanceof J||null===k||(k.Ka=h)}else if(b instanceof J&&g instanceof J)for(h=b.aa,null!==h&&(h=d.oa(h),null!==h&&(g.aa=h)),h=b.ba,null!==h&&(h=d.oa(h),null!==h&&(g.ba=h)),b=b.Af;b.next();)h=Rh(a,b.value,c,d,e),null!==h&&h instanceof G&&(h.Yb=g);return g}
qa.prototype.copySelection=function(){var a=this.g;if(null!==a){for(var b=new L(F),a=a.selection.j;a.next();)Qh(b,a.value,!0,this.oI?Infinity:0,this.mI,function(a){return a.canCopy()});this.copyToClipboard(b)}};qa.prototype.canCopySelection=function(){var a=this.g;return null!==a&&a.Mk&&a.tA&&0!==a.selection.count?!0:!1};qa.prototype.cutSelection=function(){this.copySelection();this.deleteSelection()};
qa.prototype.canCutSelection=function(){var a=this.g;return null!==a&&!a.rb&&!a.Lf&&a.Mk&&a.Dn&&a.tA&&0!==a.selection.count?!0:!1};qa.prototype.copyToClipboard=function(a){var b=this.g;if(null!==b){var c=null;if(null===a)Nh=null,Oh="";else{var c=b.da,d=!1,e=!1,g=null;try{if(c instanceof Bg){var h=c,d=h.Ln;h.Ln=this.bF}c instanceof Y&&(h=c,e=h.Kn,h.Kn=this.aF);g=b.eq(a,null,!0)}finally{c instanceof Bg&&(c.Ln=d),c instanceof Y&&(c.Kn=e),c=new K(F),c.Tc(g),Nh=c,Oh=b.da.mm}}b.Ja("ClipboardChanged",c)}};
qa.prototype.pasteFromClipboard=function(){var a=new L(F),b=Nh;if(null===b)return a;var c=this.g;if(null===c||Oh!==c.da.mm)return a;var d=c.da,e=!1,g=!1,h=null;try{if(d instanceof Bg){var k=d,e=k.Ln;k.Ln=this.bF}d instanceof Y&&(k=d,g=k.Kn,k.Kn=this.aF);h=c.eq(b,c,!1)}finally{for(d instanceof Bg&&(d.Ln=e),d instanceof Y&&(d.Kn=g),b=h.j;b.next();)c=b.value,d=b.key,c.location.F()||(d.location.F()?c.location=d.location:!c.position.F()&&d.position.F()&&(c.position=d.position)),a.add(c)}return a};
qa.prototype.pasteSelection=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.mc="wait";b.Nb("Paste");b.Ja("ChangingSelection");var c=this.pasteFromClipboard();0<c.count&&Th(b);for(var d=c.j;d.next();)d.value.ib=!0;b.Ja("ChangedSelection");if(null!==a){var e=b.computePartsBounds(b.selection);if(e.F()){var g=b.ob.Fe;null===g&&(g=new Uh,g.$c(b));var h=g.computeEffectiveCollection(b.selection);g.moveParts(h,new N(a.x-e.pa,a.y-e.va),!1)}}b.Ja("ClipboardPasted",c)}finally{b.hd("Paste"),b.mc=
""}};qa.prototype.canPasteSelection=function(){var a=this.g;return null===a||a.rb||a.Lf||!a.Wp||!a.tA||null===Nh||Oh!==a.da.mm?!1:!0};qa.prototype.undo=function(){var a=this.g;null!==a&&a.la.undo()};qa.prototype.canUndo=function(){var a=this.g;return null===a||a.rb||a.Lf?!1:a.OE&&a.la.canUndo()};qa.prototype.redo=function(){var a=this.g;null!==a&&a.la.redo()};qa.prototype.canRedo=function(){var a=this.g;return null===a||a.rb||a.Lf?!1:a.OE&&a.la.canRedo()};
qa.prototype.decreaseZoom=function(a){void 0===a&&(a=1/this.Sy);D.p(a,qa,"decreaseZoom:factor");var b=this.g;null!==b&&b.hm===Vh&&(a*=b.scale,a<b.Sh||a>b.Rh||(b.scale=a))};qa.prototype.canDecreaseZoom=function(a){void 0===a&&(a=1/this.Sy);D.p(a,qa,"canDecreaseZoom:factor");var b=this.g;if(null===b||b.hm!==Vh)return!1;a*=b.scale;return a<b.Sh||a>b.Rh?!1:b.vx};
qa.prototype.increaseZoom=function(a){void 0===a&&(a=this.Sy);D.p(a,qa,"increaseZoom:factor");var b=this.g;null!==b&&b.hm===Vh&&(a*=b.scale,a<b.Sh||a>b.Rh||(b.scale=a))};qa.prototype.canIncreaseZoom=function(a){void 0===a&&(a=this.Sy);D.p(a,qa,"canIncreaseZoom:factor");var b=this.g;if(null===b||b.hm!==Vh)return!1;a*=b.scale;return a<b.Sh||a>b.Rh?!1:b.vx};qa.prototype.resetZoom=function(a){void 0===a&&(a=this.Ex);D.p(a,qa,"resetZoom:newscale");var b=this.g;null===b||a<b.Sh||a>b.Rh||(b.scale=a)};
qa.prototype.canResetZoom=function(a){void 0===a&&(a=this.Ex);D.p(a,qa,"canResetZoom:newscale");var b=this.g;return null===b||a<b.Sh||a>b.Rh?!1:b.vx};qa.prototype.zoomToFit=function(){var a=this.g;if(null!==a){var b=a.scale,c=a.position;b===this.sA&&!isNaN(this.Sz)&&a.jd.O(this.rA)?(a.scale=this.Sz,a.position=this.BD,this.sA=NaN,this.rA=Ld):(this.Sz=b,this.BD=c.copy(),a.zoomToFit(),this.sA=a.scale,this.rA=a.jd.copy())}};qa.prototype.canZoomToFit=function(){var a=this.g;return null===a?!1:a.vx};
qa.prototype.scrollToPart=function(a){void 0===a&&(a=null);null!==a&&D.l(a,F,qa,"part");var b=this.g;if(null!==b){if(null===a){try{null!==this.rj&&(this.rj.next()?a=this.rj.value:this.rj=null)}catch(c){this.rj=null}null===a&&(0<b.wm.count?this.rj=b.wm.j:0<b.selection.count&&(this.rj=b.selection.j),null!==this.rj&&this.rj.next()&&(a=this.rj.value))}if(null!==a){var d=b.Wa;d.po("Scroll To Part");var e=this.iK;if(0<e){var g=Wh(this,a,[a]),h=function(){b.Nb();for(var a=g.pop();0<g.length&&a instanceof
G&&a.Bc&&(!(a instanceof I)||a.ld);)a=g.pop();0<g.length?(a instanceof F&&b.QG(a.Z),a instanceof G&&!a.Bc&&(a.Bc=!0),a instanceof I&&!a.ld&&(a.ld=!0)):(a instanceof F&&b.UE(a.Z),b.DB("LayoutCompleted",k));b.hd("Scroll To Part")},k=function(){setTimeout(h,(d.isEnabled?d.duration:0)+e)};b.mx("LayoutCompleted",k);h()}else{var l=b.position.copy();b.UE(a.Z);l.Uc(b.position)&&d.Yh()}}}};
function Wh(a,b,c){if(b.isVisible())return c;if(b instanceof da)Wh(a,b.Hf,c);else if(b instanceof J){var d=b.aa;null!==d&&Wh(a,d,c);b=b.ba;null!==b&&Wh(a,b,c)}else b instanceof G&&(d=b.Yb,null!==d&&Wh(a,d,c),d=b.Tk(),null!==d&&(d.Bc||d.Uq||c.push(d),Wh(a,d,c))),b=b.Ka,null!==b&&(b.ld||b.Ry||c.push(b),Wh(a,b,c));return c}qa.prototype.canScrollToPart=function(a){void 0===a&&(a=null);if(null!==a&&!(a instanceof F))return!1;a=this.g;return null===a||0===a.selection.count&&0===a.wm.count?!1:a.Ae&&a.Be};
qa.prototype.collapseTree=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.Nb("Collapse Tree");b.Wa.po("Collapse Tree");var c=new K(G);if(null!==a&&a.Bc)a.collapseTree(),c.add(a);else for(var d=b.selection.j;d.next();){var e=d.value;e instanceof G&&(a=e,a.Bc&&(a.collapseTree(),c.add(a)))}b.Ja("TreeCollapsed",c)}finally{b.hd("Collapse Tree")}};
qa.prototype.canCollapseTree=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.rb)return!1;if(null!==a){if(!(a instanceof G&&a.Bc))return!1;if(0<a.Ox().count)return!0}else for(a=b.selection.j;a.next();)if(b=a.value,b instanceof G&&b.Bc&&0<b.Ox().count)return!0;return!1};
qa.prototype.expandTree=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.Nb("Expand Tree");b.Wa.po("Expand Tree");var c=new K(G);if(null===a||a.Bc)for(var d=b.selection.j;d.next();){var e=d.value;e instanceof G&&(a=e,a.Bc||(a.expandTree(),c.add(a)))}else a.expandTree(),c.add(a);b.Ja("TreeExpanded",c)}finally{b.hd("Expand Tree")}};
qa.prototype.canExpandTree=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.rb)return!1;if(null!==a){if(!(a instanceof G)||a.Bc)return!1;if(0<a.Ox().count)return!0}else for(a=b.selection.j;a.next();)if(b=a.value,b instanceof G&&!b.Bc&&0<b.Ox().count)return!0;return!1};
qa.prototype.groupSelection=function(){var a=this.g;if(null!==a){var b=a.da;if(b instanceof Y){var c=this.PE;if(null!==c){var d=null;try{a.mc="wait";a.Nb("Group");a.Ja("ChangingSelection");for(var e=new K(F),g=a.selection.j;g.next();){var h=g.value;h.pe()&&h.canGroup()&&e.add(h)}for(var k=new K(F),l=e.j;l.next();){for(var m=l.value,g=!1,n=e.j;n.next();)if(m.Gi(n.value)){g=!0;break}g||k.add(m)}if(0<k.count){var p=k.first().Ka;if(null!==p)for(;null!==p;){for(var e=!1,q=k.j;q.next();)if(!q.value.Gi(p)){e=
!0;break}if(e)p=p.Ka;else break}if(c instanceof I)Sh(c),d=c.copy(),null!==d&&a.add(d);else if(b.fB(c)){var r=b.copyNodeData(c);D.Qa(r)&&(b.cm(r),d=a.Nx(r))}if(null!==d){null!==p&&this.isValidMember(p,d)&&(d.Ka=p);for(var s=k.j;s.next();){var u=s.value;this.isValidMember(d,u)&&(u.Ka=d)}a.select(d)}}a.Ja("ChangedSelection");a.Ja("SelectionGrouped",d)}finally{a.hd("Group"),a.mc=""}}}}};
qa.prototype.canGroupSelection=function(){var a=this.g;if(null===a||a.rb||a.Lf||!a.Wp||!a.px||!(a.da instanceof Y)||null===this.PE)return!1;for(a=a.selection.j;a.next();){var b=a.value;if(b.pe()&&b.canGroup())return!0}return!1};function Xh(a){var b=D.nb();for(a=a.j;a.next();){var c=a.value;c instanceof J||b.push(c)}a=new L(F);for(var c=b.length,d=0;d<c;d++){for(var e=b[d],g=!0,h=0;h<c;h++)if(e.Gi(b[h])){g=!1;break}g&&a.add(e)}D.ya(b);return a}
qa.prototype.isValidMember=function(a,b){if(null===b||a===b||b instanceof J)return!1;if(null!==a){if(a===b||a.Gi(b))return!1;var c=a.oB;if(null!==c&&!c(a,b)||null===a.data&&null!==b.data||null!==a.data&&null===b.data)return!1}c=this.oB;return null!==c?c(a,b):!0};
qa.prototype.ungroupSelection=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b){var c=b.da;if(c instanceof Y)try{b.mc="wait";b.Nb("Ungroup");b.Ja("ChangingSelection");var d=new K(I);if(null!==a)d.add(a);else for(var e=b.selection.j;e.next();){var g=e.value;g instanceof I&&(a=g,a.canUngroup()&&d.add(a))}if(0<d.count){b.yx();for(var h=d.j;h.next();){var k=h.value;k.expandSubGraph();var l=k.Ka,m=null!==l&&null!==l.data?c.zb(l.data):void 0,n=new K(F);n.Tc(k.Oc);for(var p=n.j;p.next();){var q=
p.value;q.ib=!0;if(!(q instanceof J)){var r=q.data;null!==r?c.NB(r,m):q.Ka=l}}b.remove(k)}}b.Ja("ChangedSelection");b.Ja("SelectionUngrouped",d,n)}finally{b.hd("Ungroup"),b.mc=""}}};qa.prototype.canUngroupSelection=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.rb||b.Lf||!b.Dn||!b.ux||!(b.da instanceof Y))return!1;if(null!==a){if(!(a instanceof I))return!1;if(a.canUngroup())return!0}else for(a=b.selection.j;a.next();)if(b=a.value,b instanceof I&&b.canUngroup())return!0;return!1};
qa.prototype.addTopLevelParts=function(a,b){for(var c=!0,d=Xh(a).j;d.next();){var e=d.value;null!==e.Ka&&(!b||this.isValidMember(null,e)?e.Ka=null:c=!1)}return c};
qa.prototype.collapseSubGraph=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.Nb("Collapse SubGraph");b.Wa.po("Collapse SubGraph");var c=new K(I);if(null!==a&&a.ld)a.collapseSubGraph(),c.add(a);else for(var d=b.selection.j;d.next();){var e=d.value;e instanceof I&&(a=e,a.ld&&(a.collapseSubGraph(),c.add(a)))}b.Ja("SubGraphCollapsed",c)}finally{b.hd("Collapse SubGraph")}};
qa.prototype.canCollapseSubGraph=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.rb)return!1;if(null!==a)return a instanceof I&&a.ld?!0:!1;for(a=b.selection.j;a.next();)if(b=a.value,b instanceof I&&b.ld)return!0;return!1};
qa.prototype.expandSubGraph=function(a){void 0===a&&(a=null);var b=this.g;if(null!==b)try{b.Nb("Expand SubGraph");b.Wa.po("Expand SubGraph");var c=new K(I);if(null===a||a.ld)for(var d=b.selection.j;d.next();){var e=d.value;e instanceof I&&(a=e,a.ld||(a.expandSubGraph(),c.add(a)))}else a.expandSubGraph(),c.add(a);b.Ja("SubGraphExpanded",c)}finally{b.hd("Expand SubGraph")}};
qa.prototype.canExpandSubGraph=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.rb)return!1;if(null!==a)return a instanceof I&&!a.ld?!0:!1;for(a=b.selection.j;a.next();)if(b=a.value,b instanceof I&&!b.ld)return!0;return!1};
qa.prototype.editTextBlock=function(a){void 0===a&&(a=null);null!==a&&D.l(a,pa,qa,"editTextBlock");var b=this.g;if(null!==b){var c=b.ob.WB;if(null!==c){if(null===a){a=null;for(var d=b.selection.j;d.next();){var e=d.value;if(e.canEdit()){a=e;break}}if(null===a)return;a=a.iu(function(a){return a instanceof pa&&a.MA})}null!==a&&(b.hb=null,c.lh=a,b.hb=c)}}};
qa.prototype.canEditTextBlock=function(a){void 0===a&&(a=null);var b=this.g;if(null===b||b.rb||b.Lf||!b.tx||null===b.ob.WB)return!1;if(null!==a){if(!(a instanceof pa))return!1;a=a.Y;if(null!==a&&a.canEdit())return!0}else for(b=b.selection.j;b.next();)if(a=b.value,a.canEdit()&&(a=a.iu(function(a){return a instanceof pa&&a.MA}),null!==a))return!0;return!1};
qa.prototype.showContextMenu=function(a){var b=this.g;if(null!==b){var c=b.ob.CA;if(null!==c&&(void 0===a&&(a=0<b.selection.count?b.selection.first():b),a=c.findObjectWithContextMenu(a),null!==a)){var d=new bg,e=null;a instanceof P?e=a.eb(mc):b.nJ||(e=b.vb,e=new N(e.x+e.width/2,e.y+e.height/2));null!==e&&(d.g=b,d.se=b.cC(e),d.ga=e,d.left=!1,d.right=!0,d.up=!0,b.U=d);b.hb=c;Yh(c,!1,a)}}};
qa.prototype.canShowContextMenu=function(a){var b=this.g;if(null===b)return!1;var c=b.ob.CA;if(null===c)return!1;void 0===a&&(a=0<b.selection.count?b.selection.first():b);return null===c.findObjectWithContextMenu(a)?!1:!0};D.defineProperty(qa,{lI:"copiesClipboardData"},function(){return this.DC},function(a){D.h(a,"boolean",qa,"copiesClipboardData");this.DC=a});
D.defineProperty(qa,{mI:"copiesConnectedLinks"},function(){return this.EC},function(a){D.h(a,"boolean",qa,"copiesConnectedLinks");this.EC=a});D.defineProperty(qa,{kF:"deletesConnectedLinks"},function(){return this.YC},function(a){D.h(a,"boolean",qa,"deletesConnectedLinks");this.YC=a});D.defineProperty(qa,{oI:"copiesTree"},function(){return this.IC},function(a){D.h(a,"boolean",qa,"copiesTree");this.IC=a});
D.defineProperty(qa,{tI:"deletesTree"},function(){return this.ZC},function(a){D.h(a,"boolean",qa,"deletesTree");this.ZC=a});D.defineProperty(qa,{bF:"copiesParentKey"},function(){return this.HC},function(a){D.h(a,"boolean",qa,"copiesParentKey");this.HC=a});D.defineProperty(qa,{aF:"copiesGroupKey"},function(){return this.GC},function(a){D.h(a,"boolean",qa,"copiesGroupKey");this.GC=a});
D.defineProperty(qa,{PE:"archetypeGroupData"},function(){return this.ez},function(a){null!==a&&D.l(a,Object,qa,"archetypeGroupData");var b=this.g;null!==b&&(b=b.da,b instanceof Y&&(a instanceof I||b.fB(a)||D.k("CommandHandler.archetypeGroupData must be either a Group or a data object for which GraphLinksModel.isGroupForNodeData is true: "+a)));this.ez=a});D.defineProperty(qa,{oB:"memberValidation"},function(){return this.Ql},function(a){null!==a&&D.h(a,"function",qa,"memberValidation");this.Ql=a});
D.defineProperty(qa,{Ex:"defaultScale"},function(){return this.VC},function(a){D.p(a,qa,"defaultScale");0<a||D.k("defaultScale must be larger than zero, not: "+a);this.VC=a});D.defineProperty(qa,{Sy:"zoomFactor"},function(){return this.FE},function(a){D.p(a,qa,"zoomFactor");1<a||D.k("zoomFactor must be larger than 1.0, not: "+a);this.FE=a});D.defineProperty(qa,{iK:"scrollToPartPause"},function(){return this.cE},function(a){D.p(a,qa,"scrollToPartPause");this.cE=a});
function Zg(){0<arguments.length&&D.xd(Zg);D.vc(this);this.ia=null;this.ac="";this.qf=!0;this.oD=!1;this.zE=null;this.kx=-1}D.ka("Tool",Zg);Zg.prototype.$c=function(a){v&&null!==a&&D.l(a,E,Zg,"setDiagram");this.ia=a};Zg.prototype.toString=function(){return""!==this.name?this.name+" Tool":D.vf(Object.getPrototypeOf(this))};Zg.prototype.updateAdornments=function(){};Zg.prototype.canStart=function(){return this.isEnabled};Zg.prototype.doStart=function(){};Zg.prototype.doActivate=function(){this.wa=!0};
Zg.prototype.doDeactivate=function(){this.wa=!1};Zg.prototype.doStop=function(){};Zg.prototype.doCancel=function(){this.stopTool()};Zg.prototype.stopTool=function(){var a=this.g;null!==a&&a.hb===this&&(a.hb=null,a.mc="")};Zg.prototype.doMouseDown=function(){!this.wa&&this.canStart()&&this.doActivate()};Zg.prototype.doMouseMove=function(){};Zg.prototype.doMouseUp=function(){this.stopTool()};Zg.prototype.doMouseWheel=function(){};Zg.prototype.canStartMultiTouch=function(){return!0};
Zg.prototype.standardPinchZoomStart=function(){var a=this.g;if(null!==a){var b=a.U,c=b.Rx(0,D.Fb(NaN,NaN)),d=b.Rx(1,D.Fb(NaN,NaN));if(c.F()&&d.F()&&(this.doCancel(),a.tu("hasGestureZoom"))){a.PD=a.scale;var e=d.x-c.x,g=d.y-c.y;a.hE=Math.sqrt(e*e+g*g);b.bubbles=!1}D.A(c);D.A(d)}};
Zg.prototype.standardPinchZoomMove=function(){var a=this.g;if(null!==a){var b=a.U,c=b.Rx(0,D.Fb(NaN,NaN)),d=b.Rx(1,D.Fb(NaN,NaN));if(c.F()&&d.F()&&(this.doCancel(),a.tu("hasGestureZoom"))){var e=d.x-c.x,g=d.y-c.y,g=Math.sqrt(e*e+g*g)/a.hE,e=new N((Math.min(d.x,c.x)+Math.max(d.x,c.x))/2,(Math.min(d.y,c.y)+Math.max(d.y,c.y))/2),g=a.PD*g,h=a.yb;if(g!==a.scale&&h.canResetZoom(g)){var k=a.Jm;a.Jm=e;h.resetZoom(g);a.Jm=k}b.bubbles=!1}D.A(c);D.A(d)}};
Zg.prototype.doKeyDown=function(){var a=this.g;null!==a&&"Esc"===a.U.key&&this.doCancel()};Zg.prototype.doKeyUp=function(){};Zg.prototype.startTransaction=Zg.prototype.Nb=function(a){void 0===a&&(a=this.name);this.Sf=null;var b=this.g;return null===b?!1:b.Nb(a)};Zg.prototype.stopTransaction=Zg.prototype.gl=function(){var a=this.g;return null===a?!1:null===this.Sf?a.Bm():a.hd(this.Sf)};
Zg.prototype.standardMouseSelect=function(){var a=this.g;if(null!==a&&a.If){var b=a.U,c=a.ku(b.ga,!1);if(null!==c)if(D.Zk?b.Nu:b.control){a.Ja("ChangingSelection");for(b=c;null!==b&&!b.canSelect();)b=b.Ka;null!==b&&(b.ib=!b.ib);a.Ja("ChangedSelection")}else if(b.shift){if(!c.ib){a.Ja("ChangingSelection");for(b=c;null!==b&&!b.canSelect();)b=b.Ka;null!==b&&(b.ib=!0);a.Ja("ChangedSelection")}}else{if(!c.ib){for(b=c;null!==b&&!b.canSelect();)b=b.Ka;null!==b&&a.select(b)}}else!b.left||(D.Zk?b.Nu:b.control)||
b.shift||a.yx()}};Zg.prototype.standardMouseClick=function(a,b){void 0===a&&(a=null);void 0===b&&(b=function(a){return!a.layer.Wc});var c=this.g;if(null===c)return!1;var d=c.U,e=c.Ie(d.ga,a,b);d.Ze=e;Zh(e,d,c);return d.Mc};
function Zh(a,b,c){b.Mc=!1;if(null===a||a.Au()){var d=0;b.left?d=1===b.Pe?1:2===b.Pe?2:1:b.right&&1===b.Pe&&(d=3);var e="";if(null!==a){switch(d){case 1:e="ObjectSingleClicked";break;case 2:e="ObjectDoubleClicked";break;case 3:e="ObjectContextClicked"}0!==d&&c.Ja(e,a)}else{switch(d){case 1:e="BackgroundSingleClicked";break;case 2:e="BackgroundDoubleClicked";break;case 3:e="BackgroundContextClicked"}0!==d&&c.Ja(e)}if(null!==a)for(;null!==a;){c=null;switch(d){case 1:c=a.click;break;case 2:c=a.fu?a.fu:
a.click;break;case 3:c=a.BA}if(null!==c&&(c(b,a),b.Mc))break;a=a.R}else{a=null;switch(d){case 1:a=c.click;break;case 2:a=c.fu?c.fu:c.click;break;case 3:a=c.BA}null!==a&&a(b)}}}
Zg.prototype.standardMouseOver=function(){var a=this.g;if(null!==a){var b=a.U;if(null!==b.g&&!0!==a.Wa.xc){var c=a.lb;a.lb=!0;var d=a.Hl?a.Ie(b.ga,null,null):null;b.Ze=d;var e=!1;if(d!==a.Qo){var g=a.Qo,h=g;a.Qo=d;this.doCurrentObjectChanged(g,d);for(b.Mc=!1;null!==g;){var k=g.Pu;if(null!==k){if(d===g)break;if(null!==d&&d.ym(g))break;k(b,g,d);e=!0;if(b.Mc)break}g=g.R}g=h;for(b.Mc=!1;null!==d;){k=d.Ou;if(null!==k){if(g===d)break;if(null!==g&&g.ym(d))break;k(b,d,g);e=!0;if(b.Mc)break}d=d.R}d=a.Qo}if(null!==
d){g=d;for(h="";null!==g;){h=g.cursor;if(""!==h)break;g=g.R}a.mc=h;b.Mc=!1;for(g=d;null!==g;){d=g.sB;if(null!==d&&(d(b,g),e=!0,b.Mc))break;g=g.R}}else a.mc="",d=a.sB,null!==d&&(d(b),e=!0);e&&a.Ve();a.lb=c}}};Zg.prototype.doCurrentObjectChanged=function(){};
Zg.prototype.standardMouseWheel=function(){var a=this.g;if(null!==a){var b=a.U,c=b.om;if(0!==c&&a.jd.F()){var d=a.yb,e=a.ob.Qu;if((e===$h&&!b.shift||e===ai&&b.control)&&(0<c?d.canIncreaseZoom():d.canDecreaseZoom()))e=a.Jm,a.Jm=b.se,0<c?d.increaseZoom():d.decreaseZoom(),a.Jm=e,b.bubbles=!1;else if(e===$h&&b.shift||e===ai&&!b.control){var d=a.position.copy(),e=0<c?c:-c,g=b.event.deltaX,h=b.event.deltaY;void 0===g||void 0===h||0===g&&0===h||b.shift?!b.shift&&a.Be?(g=a.cv,e=e/40*g,0<c?a.scroll("pixel",
"up",e):a.scroll("pixel","down",e)):b.shift&&a.Ae&&(g=a.bv,e=e/40*g,0<c?a.scroll("pixel","left",e):a.scroll("pixel","right",e)):(0!==g&&a.Ae&&(0<g?a.scroll("pixel","left",-g):a.scroll("pixel","right",g)),0!==h&&a.Be&&(0<h?a.scroll("pixel","up",-h):a.scroll("pixel","down",h)));a.position.O(d)||(b.bubbles=!1)}}}};Zg.prototype.standardWaitAfter=function(a,b){D.h(a,"number",Zg,"standardWaitAfter:delay");this.cancelWaitAfter();var c=this,d=b.copy();this.kx=D.setTimeout(function(){c.doWaitAfter(d)},a)};
Zg.prototype.cancelWaitAfter=function(){-1!==this.kx&&D.clearTimeout(this.kx);this.kx=-1};Zg.prototype.doWaitAfter=function(){};Zg.prototype.findToolHandleAt=function(a,b){var c=this.g;if(null===c)return null;c=c.Ie(a,function(a){for(;null!==a&&!(a.R instanceof da);)a=a.R;return a});return null===c?null:c.Y.Gd===b?c:null};
Zg.prototype.isBeyondDragSize=function(a,b){var c=this.g;if(null===c)return!1;void 0===a&&(a=c.Lc.se);void 0===b&&(b=c.U.se);var d=c.ob.xI,e=d.width,d=d.height;c.Lc.Mj&&(e+=6,d+=6);return Math.abs(b.x-a.x)>e||Math.abs(b.y-a.y)>d};D.w(Zg,{g:"diagram"},function(){return this.ia});D.defineProperty(Zg,{name:"name"},function(){return this.ac},function(a){D.h(a,"string",Zg,"name");this.ac=a});
D.defineProperty(Zg,{isEnabled:"isEnabled"},function(){return this.qf},function(a){D.h(a,"boolean",Zg,"isEnabled");this.qf=a});D.defineProperty(Zg,{wa:"isActive"},function(){return this.oD},function(a){D.h(a,"boolean",Zg,"isActive");this.oD=a});D.defineProperty(Zg,{Sf:"transactionResult"},function(){return this.zE},function(a){null!==a&&D.h(a,"string",Zg,"transactionResult");this.zE=a});
function Uh(){Zg.call(this);0<arguments.length&&D.xd(Uh);this.name="Dragging";this.FC=this.sD=!0;this.es=this.dD=!1;this.wD=!0;this.Iz=(new Ca(NaN,NaN)).freeze();this.Jz=ec;this.Kz=(new N(NaN,NaN)).freeze();this.cD=!1;this.Cw=this.Bw=this.yz=this.CC=this.bD=this.NC=this.pj=null;this.Lr=this.uD=!1;this.Mp=new N(NaN,NaN);this.Yw=new N;this.ax=!1;this.rD=!0;this.Wo=100;this.fk=[];this.zH=(new L(F)).freeze()}D.Sa(Uh,Zg);D.ka("DraggingTool",Uh);
D.defineProperty(Uh,{WF:"isCopyEnabled"},function(){return this.sD},function(a){D.h(a,"boolean",Uh,"isCopyEnabled");this.sD=a});D.defineProperty(Uh,{nI:"copiesEffectiveCollection"},function(){return this.FC},function(a){D.h(a,"boolean",Uh,"copiesEffectiveCollection");this.FC=a});D.defineProperty(Uh,{yI:"dragsTree"},function(){return this.dD},function(a){D.h(a,"boolean",Uh,"dragsTree");this.dD=a});
D.defineProperty(Uh,{Cu:"isGridSnapEnabled"},function(){return this.es},function(a){D.h(a,"boolean",Uh,"isGridSnapEnabled");this.es=a});D.defineProperty(Uh,{hJ:"isComplexRoutingRealtime"},function(){return this.rD},function(a){D.h(a,"boolean",Uh,"isComplexRoutingRealtime");this.rD=a});D.defineProperty(Uh,{jJ:"isGridSnapRealtime"},function(){return this.wD},function(a){D.h(a,"boolean",Uh,"isGridSnapRealtime");this.wD=a});
D.defineProperty(Uh,{MF:"gridSnapCellSize"},function(){return this.Iz},function(a){D.l(a,Ca,Uh,"gridSnapCellSize");this.Iz.O(a)||(this.Iz=a=a.V())});D.defineProperty(Uh,{UI:"gridSnapCellSpot"},function(){return this.Jz},function(a){D.l(a,S,Uh,"gridSnapCellSpot");this.Jz.O(a)||(this.Jz=a=a.V())});D.defineProperty(Uh,{VI:"gridSnapOrigin"},function(){return this.Kz},function(a){D.l(a,N,Uh,"gridSnapOrigin");this.Kz.O(a)||(this.Kz=a=a.V())});
D.defineProperty(Uh,{Ej:"dragsLink"},function(){return this.cD},function(a){D.h(a,"boolean",Uh,"dragsLink");this.cD=a});D.defineProperty(Uh,{Nn:"currentPart"},function(){return this.NC},function(a){null!==a&&D.l(a,F,Uh,"currentPart");this.NC=a});D.defineProperty(Uh,{lc:"copiedParts"},function(){return this.CC},function(a){this.CC=a});D.defineProperty(Uh,{hc:"draggedParts"},function(){return this.bD},function(a){this.bD=a});
D.w(Uh,{EL:"draggingParts"},function(){return null!==this.lc?this.lc.Zj():null!==this.hc?this.hc.Zj():this.zH});D.defineProperty(Uh,{vd:"draggedLink"},function(){return this.yz},function(a){null!==a&&D.l(a,J,Uh,"draggedLink");this.yz!==a&&(this.yz=a,null!==a?(this.Bw=a.Ac,this.Cw=a.ad):this.Cw=this.Bw=null)});D.defineProperty(Uh,{Wx:"isDragOutStarted"},function(){return this.uD},function(a){this.uD=a});
D.defineProperty(Uh,{fl:"startPoint"},function(){return this.Yw},function(a){D.l(a,N,Uh,"startPoint");this.Yw.O(a)||(this.Yw=a=a.V())});D.defineProperty(Uh,{fF:"delay"},function(){return this.Wo},function(a){D.h(a,"number",Uh,"delay");this.Wo=a});Uh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||a.rb&&!a.Wt||!a.em&&!a.Mk&&!a.Wt||!a.If)return!1;var b=a.U;return!b.left||a.hb!==this&&(!this.isBeyondDragSize()||b.Mj&&b.timestamp-a.Lc.timestamp<this.Wo)?!1:null!==this.findDraggablePart()};
Uh.prototype.findDraggablePart=function(){var a=this.g;if(null===a)return null;a=a.ku(a.Lc.ga,!1);if(null===a)return null;for(;null!==a&&!a.canSelect();)a=a.Ka;return null!==a&&(a.canMove()||a.canCopy())?a:null};Uh.prototype.standardMouseSelect=function(){var a=this.g;if(null!==a&&a.If){var b=a.ku(a.Lc.ga,!1);if(null!==b){for(;null!==b&&!b.canSelect();)b=b.Ka;this.Nn=b;this.Nn.ib||(a.Ja("ChangingSelection"),b=a.U,(D.Zk?b.Nu:b.control)||b.shift||Th(a),this.Nn.ib=!0,a.Ja("ChangedSelection"))}}};
Uh.prototype.doActivate=function(){var a=this.g;if(null!==a){null===this.Nn&&this.standardMouseSelect();var b=this.Nn;null!==b&&(b.canMove()||b.canCopy())&&(this.wa=!0,this.Mp.set(a.position),bi(this,a.selection),this.fk.length=0,this.hc=this.computeEffectiveCollection(a.selection),a.hv=!0,ci(this,this.hc),this.Nb("Drag"),this.fl=a.Lc.ga,a.nf=!0,a.Wt&&(this.Wx=!0,this.Lr=!1,di=this,ei=this.g,this.doSimulatedDragOut()))}};
function bi(a,b){if(a.Ej){var c=a.g;null!==c&&c.En&&(c.da instanceof Y&&1===b.count&&b.first()instanceof J?(a.vd=b.first(),a.vd.canRelinkFrom()&&a.vd.canRelinkTo()&&a.vd.au(),a.pj=c.ob.BG,null===a.pj&&(a.pj=new fi,a.pj.$c(c))):(a.vd=null,a.pj=null))}}
Uh.prototype.computeEffectiveCollection=function(a){var b=null!==this.g&&this.g.hb===this,c=new oa(F);if(null===a)return c;for(var d=a.j;d.next();)gi(this,c,d.value,b);if(null!==this.vd&&this.Ej)return c;for(d=a.j;d.next();)a=d.value,a instanceof J&&(b=a.aa,null===b||c.contains(b)?(b=a.ba,null===b||c.contains(b)||c.remove(a)):c.remove(a));return c};function pi(a,b){return void 0===b?new Ci(Kd):a.Cu?new Ci(new N(Math.round(b.x),Math.round(b.y))):new Ci(b.copy())}
function gi(a,b,c,d){if(!b.contains(c)&&(!d||c.canMove()||c.canCopy()))if(c instanceof G){b.add(c,pi(a,c.location));if(c instanceof I)for(var e=c.Oc;e.next();)gi(a,b,e.value,d);for(e=c.Kd;e.next();){var g=e.value;if(!b.contains(g)){var h=g.aa,k=g.ba;null!==h&&b.contains(h)&&null!==k&&b.contains(k)&&gi(a,b,g,d)}}if(a.yI)for(c=c.zF();c.next();)gi(a,b,c.value,d)}else if(c instanceof J)for(g=c,b.add(g,pi(a)),c=g.Af;c.next();)gi(a,b,c.value,d);else c instanceof da||b.add(c,pi(a,c.location))}
Uh.prototype.doDeactivate=function(){this.wa=!1;var a=this.g;null!==a&&Di(a);Ei(this);Fi(this,this.hc);this.hc=this.Nn=null;this.Lr=this.Wx=!1;if(0<Gi.count){for(var b=Gi.length,c=0;c<b;c++){var d=Gi.ea(c);Hi(d);Ii(d);Ei(d);null!==d.g&&Di(d.g)}Gi.clear()}Hi(this);this.Mp.n(NaN,NaN);di=ei=null;Ii(this);a.nf=!1;a.mc="";a.hv=!1;this.gl()};function Ei(a){var b=a.g;if(null!==b){var c=b.lb;b.lb=!0;Ji(a,b.U,null);b.lb=c}a.fk.length=0}
function Ki(){var a=di;Ii(a);Li(a);var b=a.g;null!==b&&a.Mp.F()&&(b.position=a.Mp);null!==b&&Di(b)}Uh.prototype.doCancel=function(){Ii(this);Li(this);var a=this.g;null!==a&&this.Mp.F()&&(a.position=this.Mp);this.stopTool()};function ci(a,b){if(null!==b){a.ax=!0;for(var c=b.j;c.next();){var d=c.key;d instanceof J&&(d.Xj=!0)}}}function Fi(a,b){if(null!==b){for(var c=b.j;c.next();){var d=c.key;d instanceof J&&(d.Xj=!1,Mi(d)&&d.ec())}a.ax=!1}}
Uh.prototype.doKeyDown=function(){var a=this.g;null!==a&&(a=a.U,null!==a&&this.wa&&("Esc"===a.key?this.doCancel():this.doMouseMove()))};Uh.prototype.doKeyUp=function(){var a=this.g;null!==a&&null!==a.U&&this.wa&&this.doMouseMove()};function Ni(a,b){for(var c=Infinity,d=Infinity,e=-Infinity,g=-Infinity,h=a.j;h.next();){var k=h.value;if(k.pe()&&k.isVisible()){var l=k.location,k=l.x,l=l.y;isNaN(k)||isNaN(l)||(k<c&&(c=k),l<d&&(d=l),k>e&&(e=k),l>g&&(g=l))}}Infinity===c?b.n(0,0,0,0):b.n(c,d,e-c,g-d)}
function Oi(a,b){if(null===a.lc){var c=a.g;if(!(null===c||b&&(c.rb||c.Lf))&&null!==a.hc){var d=c.la;d.isEnabled&&d.aG?null!==d.Cj&&0<d.Cj.fg.count&&(c.la.Bm(),c.Nb("Drag")):Li(a);c.lb=!b;c.$q=!b;a.fl=c.Lc.ga;d=a.nI?a.hc.Zj():c.selection;d=c.eq(d,c,!0);for(c=d.j;c.next();)c.value.location=c.key.location;c=D.Qf();Ni(d,c);D.Kb(c);for(var c=new oa(F),e=a.hc.j;e.next();){var g=e.key;g.pe()&&g.canCopy()&&(g=d.oa(g),null!==g&&(g.Re(),c.add(g,pi(a,g.location))))}for(d=d.j;d.next();)e=d.value,e instanceof
J&&e.canCopy()&&c.add(e,pi(a));a.lc=c;bi(a,c.Zj());null!==a.vd&&(c=a.vd,d=c.so,c.cl(a.fl.x-(d.x+d.width/2),a.fl.y-(d.y+d.height/2)))}}}function Ii(a){var b=a.g;if(null!==b){if(null!==a.lc&&(b.EB(a.lc.Zj(),!1),a.lc=null,null!==a.hc))for(var c=a.hc.j;c.next();)c.key instanceof J&&(c.value.point=new N(0,0));b.lb=!1;b.$q=!1;a.fl=b.Lc.ga}}function Hi(a){if(null!==a.vd){if(a.Ej&&null!==a.pj){var b=a.pj;null!==b.g&&(b.g.remove(b.ee),b.g.remove(b.fe))}a.vd=null;a.pj=null}}
function Pi(a,b,c){var d=a.g;if(null!==d){var e=a.fl,g=D.P();g.assign(d.U.ga);a.moveParts(b,g.Wj(e),c);D.A(g)}}
Uh.prototype.moveParts=function(a,b,c){if(null!==a&&(D.l(a,oa,Uh,"moveParts:parts"),0!==a.count)){var d=D.P(),e=D.P();e.assign(b);isNaN(e.x)&&(e.x=0);isNaN(e.y)&&(e.y=0);(b=this.ax)||ci(this,a);for(var g=new K(Qi),h=new K(Wa),k=a.j;k.next();){var l=k.key;if(l.pe()){var m=Ri(this,l,a);if(null!==m)g.add(new Qi(l,k.value,m));else if(!c||l.canMove()){m=k.value.point;d.assign(m);var n=new N,p=this.computeMove(l,d.add(e),a,n);l.location=p;k.value.ZG=n.Wj(m)}}else k.key instanceof J&&h.add(k.bb)}for(c=g.j;c.next();)g=
c.value,m=g.info.point,d.assign(m),g.Yc.location=d.add(g.WI.ZG);c=D.P();m=D.P();for(h=h.j;h.next();)if(k=h.value,g=k.key,g instanceof J)if(g.Xj)l=g.aa,n=g.ba,null!==this.vd&&this.Ej?(k=k.value.point,a.add(g,pi(this,e)),l=e.x-k.x,k=e.y-k.y,g.cl(l,k)):(null!==l&&(c.assign(l.location),p=a.oa(l),null!==p&&c.Wj(p.point)),null!==n&&(m.assign(n.location),p=a.oa(n),null!==p&&m.Wj(p.point)),null!==l&&null!==n?c.Uc(m)?(k=k.value.point,l=d,l.assign(c),l.Wj(k),a.add(g,pi(this,c)),g.cl(l.x,l.y)):(g.Xj=!1,g.ec()):
(k=k.value.point,n=null!==l?c:null!==n?m:e,a.add(g,pi(this,n)),l=n.x-k.x,k=n.y-k.y,g.cl(l,k)));else if(null===g.aa||null===g.ba)k=k.value.point,a.add(g,pi(this,e)),l=e.x-k.x,k=e.y-k.y,g.cl(l,k);D.A(d);D.A(e);D.A(c);D.A(m);b||(Si(this.g),Fi(this,a))}};function Ri(a,b,c){b=b.Ka;if(null!==b){a=Ri(a,b,c);if(null!==a)return a;a=c.oa(b);if(null!==a)return a}return null}
function Li(a){if(null!==a.hc){for(var b=a.g,c=a.hc.j;c.next();){var d=c.key;d.pe()&&(d.location=c.value.point)}for(c=a.hc.j;c.next();)if(d=c.key,d instanceof J&&d.Xj){var e=c.value.point;a.hc.add(d,pi(a));d.cl(-e.x,-e.y)}b.lg()}}
Uh.prototype.computeMove=function(a,b,c,d){void 0===d&&(d=new N);d.assign(b);if(null===a)return d;void 0===c&&(c=null);var e=b;if(this.Cu&&(this.jJ||null===c||null!==this.g&&this.g.U.up)&&(e=D.P(),c=e,c.assign(b),null!==a)){var g=this.g;if(null!==g){var h=g.Wn,k=this.MF,g=k.width,k=k.height,l=this.VI,m=l.x,l=l.y,n=this.UI;if(null!==h){var p=h.Sx;isNaN(g)&&(g=p.width);isNaN(k)&&(k=p.height);h=h.LF;isNaN(m)&&(m=h.x);isNaN(l)&&(l=h.y)}h=D.Fb(0,0);h.fv(0,0,g,k,n);jb(b.x,b.y,m+h.x,l+h.y,g,k,c);D.A(h)}}c=
null!==a.lF?a.lF(a,b,e):e;k=a.LJ;g=k.x;isNaN(g)&&(g=a.location.x);k=k.y;isNaN(k)&&(k=a.location.y);h=a.FJ;m=h.x;isNaN(m)&&(m=a.location.x);h=h.y;isNaN(h)&&(h=a.location.y);d.n(Math.max(g,Math.min(c.x,m)),Math.max(k,Math.min(c.y,h)));e!==b&&D.A(e);return d};function Ti(a,b){if(null===b)return!0;var c=b.Y;return null===c||c instanceof da||c.layer.Wc||a.hc&&a.hc.contains(c)||a.lc&&a.lc.contains(c)?!0:!1}
function Ui(a,b){var c=a.g;if(null!==c){a.Ej&&(null!==a.vd&&(a.vd.aa=null,a.vd.ba=null),Vi(a,!1));var d=Wi(c,b,null,function(b){return!Ti(a,b)}),e=c.U;e.Ze=d;var g=c.lb,h=!1;try{c.lb=!0;h=Ji(a,e,d);if(!a.wa&&null===di)return;if(null===d){var k=c.PJ;null!==k&&(k(e),h=!0)}if(!a.wa&&null===di)return;a.doDragOver(b,d);if(!a.wa&&null===di)return}finally{c.lb=g,h&&c.lg()}(c.Ae||c.Be)&&c.JA(e.se)}}
function Ji(a,b,c){var d=!1,e=a.fk.length,g=0<e?a.fk[0]:null;if(c===g)return!1;b.Mc=!1;for(var h=0;h<e;h++){var k=a.fk[h],l=k.OJ;if(null!==l&&(l(b,k,c),d=!0,b.Mc))break}a.fk.length=0;if(!a.wa&&null===di||null===c)return d;for(b.Mc=!1;null!==c;)a.fk.push(c),c=Xi(c);e=a.fk.length;for(h=0;h<e&&(k=a.fk[h],l=k.NJ,null===l||(l(b,k,g),d=!0,!b.Mc));h++);return d}function Xi(a){var b=a.R;return null!==b?b:a instanceof F&&!(a instanceof I)&&(a=a.Ka,null!==a&&a.YI)?a:null}
function Yi(a,b,c){var d=a.pj;if(null===d)return null;var e=a.g.Qn(b,d.zG,function(a){return d.findValidLinkablePort(a,c)});a=D.P();for(var g=Infinity,h=null,e=e.j;e.next();){var k=e.value;if(null!==k.Y){var l=k.eb(mc,a),l=b.Jf(l);l<g&&(h=k,g=l)}}D.A(a);return h}
function Vi(a,b){var c=a.vd;if(null!==c&&!(2>c.ta)){var d=a.g;if(null!==d&&!d.rb){var e=a.pj;if(null!==e){var g=null,h=null;null===c.aa&&(g=Yi(a,c.m(0),!1),null!==g&&(h=g.Y));var k=null,l=null;null===c.ba&&(k=Yi(a,c.m(c.ta-1),!0),null!==k&&(l=k.Y));e.isValidLink(h,g,l,k)?b?(c.fq=c.m(0),c.gq=c.m(c.ta-1),c.Xj=!1,c.aa=h,null!==g&&(c.hg=g.wd),c.ba=l,null!==k&&(c.mh=k.wd),c.Ac!==a.Bw&&d.Ja("LinkRelinked",c,a.Bw),c.ad!==a.Cw&&d.Ja("LinkRelinked",c,a.Cw)):Zi(e,h,g,l,k):Zi(e,null,null,null,null)}}}}
Uh.prototype.doDragOver=function(){};
function $i(a,b){var c=a.g;if(null!==c){a.Ej&&Vi(a,!0);Ei(a);var d=Wi(c,b,null,function(b){return!Ti(a,b)}),e=c.U;e.Ze=d;if(null!==d){e.Mc=!1;for(var g=d;null!==g;){var h=g.pB;if(null!==h&&(h(e,g),e.Mc))break;g=Xi(g)}}else g=c.pB,null!==g&&g(e);if(a.wa||null!==di){for(e=(a.lc||a.hc).j;e.next();)g=e.key,g instanceof G&&g.Kd.each(function(a){a.Xj=!1});a.doDropOnto(b,d);if(a.wa||null!==di){d=D.Qf();for(e=c.selection.j;e.next();)g=e.value,g instanceof G&&aj(c,g.getAvoidableRect(d));D.Kb(d)}}}}
function aj(a,b){var c=!1;a.vb.Pk(b)&&(c=!0);c=a.PA(b,function(a){return a.Y},function(a){return a instanceof J},!0,function(a){return a instanceof J},c);if(0!==c.count)for(c=c.j;c.next();){var d=c.value;d.Jj&&d.ec()}}Uh.prototype.doDropOnto=function(){};
Uh.prototype.doMouseMove=function(){if(this.wa){var a=this.g;null!==a&&null!==this.Nn&&null!==this.hc&&(this.mayCopy()?(a.mc="copy",Oi(this,!1),ci(this,this.lc),Pi(this,this.lc,!1),Fi(this,this.lc)):this.mayMove()?(a.mc="default",Ii(this),Pi(this,this.hc,!0)):this.mayDragOut()?(a.mc="no-drop",Oi(this,!1),Pi(this,this.lc,!1)):Ii(this),Ui(this,a.U.ga))}};
Uh.prototype.doMouseUp=function(){if(this.wa){var a=this.g;if(null!==a){var b=!1,c=this.mayCopy();c&&null!==this.lc?(Ii(this),Oi(this,!0),ci(this,this.lc),Pi(this,this.lc,!1),Fi(this,this.lc),null!==this.lc&&a.TG(this.lc.Zj())):(b=!0,Ii(this),this.mayMove()&&(Pi(this,this.hc,!0),Ui(this,a.U.ga)));this.Lr=!0;$i(this,a.U.ga);if(this.wa){this.lc=null;if(b&&null!==this.hc)for(b=this.hc.j;b.next();){var d=b.key;d instanceof G&&(d=d.Ka,null===d||null===d.placeholder||this.hc.contains(d)||d.placeholder.K())}a.Nc();
Fi(this,this.hc);this.Sf=c?"Copy":"Move";a.Ja(c?"SelectionCopied":"SelectionMoved",a.selection)}this.stopTool()}}};Uh.prototype.mayCopy=function(){if(!this.WF)return!1;var a=this.g;if(null===a||a.rb||a.Lf||!a.Wp||!a.Mk||(D.Zk?!a.U.alt:!a.U.control))return!1;for(a=a.selection.j;a.next();){var b=a.value;if(b.pe()&&b.canCopy())return!0}return null!==this.vd&&this.Ej&&this.vd.canCopy()?!0:!1};
Uh.prototype.mayDragOut=function(){if(!this.WF)return!1;var a=this.g;if(null===a||!a.Wt||!a.Mk||a.em)return!1;for(a=a.selection.j;a.next();){var b=a.value;if(b.pe()&&b.canCopy())return!0}return null!==this.vd&&this.Ej&&this.vd.canCopy()?!0:!1};Uh.prototype.mayMove=function(){var a=this.g;if(null===a||a.rb||!a.em)return!1;for(a=a.selection.j;a.next();){var b=a.value;if(b.pe()&&b.canMove())return!0}return null!==this.vd&&this.Ej&&this.vd.canMove()?!0:!1};var Gi=new K(Uh),di=null,ei=null;
Uh.prototype.getDraggingSource=function(){return di};Uh.prototype.mayDragIn=function(){var a=this.g;if(null===a||!a.NE||a.rb||a.Lf||!a.Wp)return!1;var b=di;return null===b||null===b.g||b.g.da.mm!==a.da.mm?!1:!0};Uh.prototype.doSimulatedDragEnter=function(){if(this.mayDragIn()){var a=this.g;a.Wa.Yh();bj(a);a.Wa.Yh();a=di;null!==a&&null!==a.g&&(a.g.mc="copy")}};Uh.prototype.doSimulatedDragLeave=function(){var a=di;null!==a&&a.doSimulatedDragOut();this.doCancel()};
Uh.prototype.doSimulatedDragOver=function(){var a=this.g;if(null!==a){var b=di;null!==b&&null!==b.hc&&this.mayDragIn()&&(a.mc="copy",cj(this,b.hc.Zj(),!1),Pi(this,this.lc,!1),Ui(this,a.U.ga))}};
Uh.prototype.doSimulatedDrop=function(){var a=this.g;if(null!==a){var b=di;if(null!==b){var c=b.g;b.Lr=!0;Ii(this);this.mayDragIn()&&(this.Nb("Drop"),cj(this,b.hc.Zj(),!0),Pi(this,this.lc,!1),null!==this.lc&&a.TG(this.lc.Zj()),$i(this,a.U.ga),a.Nc(),b=a.selection,null!==this.lc?this.Sf="ExternalCopy":b=new L(F),this.lc=null,a.doFocus(),a.Ja("ExternalObjectsDropped",b,c),this.gl())}}};
function cj(a,b,c){if(null===a.lc){var d=a.g;if(null!==d&&!d.rb&&!d.Lf){d.lb=!c;d.$q=!c;a.fl=d.U.ga;c=d.eq(b,d,!0);var e=D.Qf();Ni(b,e);var d=e.x+e.width/2,g=e.y+e.height/2;D.Kb(e);var e=a.Yw,h=new oa(F),k=D.P();for(b=b.j;b.next();){var l=b.value,m=c.oa(l);l.pe()&&l.canCopy()?(l=l.location,k.n(e.x-(d-l.x),e.y-(g-l.y)),m.location=k,m.Re(),h.add(m,pi(a,k))):l instanceof J&&l.canCopy()&&(m.cl(e.x-d,e.y-g),h.add(m,pi(a)))}D.A(k);a.lc=h;bi(a,h.Zj());null!==a.vd&&(c=a.vd,d=c.so,c.cl(a.fl.x-(d.x+d.width/
2),a.fl.y-(d.y+d.height/2)))}}}Uh.prototype.doSimulatedDragOut=function(){var a=this.g;null!==a&&(this.mayCopy()||this.mayMove()?a.mc="":a.mc="no-drop")};function Ci(a){this.point=a;this.ZG=Kd}D.ka("DraggingInfo",Ci);function Qi(a,b,c){this.Yc=a;this.info=b;this.WI=c}
function dj(){0<arguments.length&&D.xd(dj);Zg.call(this);this.ZD=100;this.zD=!1;var a=new J,b=new z;b.Ue=!0;b.stroke="blue";a.add(b);b=new z;b.Tq="Standard";b.fill="blue";b.stroke="blue";a.add(b);a.Mf="Tool";this.sE=a;a=new G;b=new z;b.wd="";b.Jb="Rectangle";b.fill=null;b.stroke="magenta";b.mb=2;b.Ea=Yd;a.add(b);a.el=!1;a.Mf="Tool";this.qE=a;this.rE=b;a=new G;b=new z;b.wd="";b.Jb="Rectangle";b.fill=null;b.stroke="magenta";b.mb=2;b.Ea=Yd;a.add(b);a.el=!1;a.Mf="Tool";this.tE=a;this.uE=b;this.XD=this.WD=
this.SD=this.RD=this.TD=null;this.vD=!0;this.MH=new oa(P,"boolean");this.$D=this.Ol=this.nE=null}D.Sa(dj,Zg);D.ka("LinkingBaseTool",dj);dj.prototype.doStop=function(){var a=this.g;null!==a&&Di(a);this.hh=this.gh=this.fh=this.eh=this.tc=null;this.Py.clear();this.Pf=null};D.defineProperty(dj,{zG:"portGravity"},function(){return this.ZD},function(a){D.h(a,"number",dj,"portGravity");0<=a&&(this.ZD=a)});
D.defineProperty(dj,{xq:"isUnconnectedLinkValid"},function(){return this.zD},function(a){D.h(a,"boolean",dj,"isUnconnectedLinkValid");this.zD=a});D.defineProperty(dj,{kh:"temporaryLink"},function(){return this.sE},function(a){D.l(a,J,dj,"temporaryLink");this.sE=a});D.defineProperty(dj,{ee:"temporaryFromNode"},function(){return this.qE},function(a){D.l(a,G,dj,"temporaryFromNode");this.qE=a});
D.defineProperty(dj,{xo:"temporaryFromPort"},function(){return this.rE},function(a){D.l(a,P,dj,"temporaryFromPort");this.rE=a});D.defineProperty(dj,{fe:"temporaryToNode"},function(){return this.tE},function(a){D.l(a,G,dj,"temporaryToNode");this.tE=a});D.defineProperty(dj,{yo:"temporaryToPort"},function(){return this.uE},function(a){D.l(a,P,dj,"temporaryToPort");this.uE=a});D.defineProperty(dj,{tc:"originalLink"},function(){return this.TD},function(a){null!==a&&D.l(a,J,dj,"originalLink");this.TD=a});
D.defineProperty(dj,{eh:"originalFromNode"},function(){return this.RD},function(a){null!==a&&D.l(a,G,dj,"originalFromNode");this.RD=a});D.defineProperty(dj,{fh:"originalFromPort"},function(){return this.SD},function(a){null!==a&&D.l(a,P,dj,"originalFromPort");this.SD=a});D.defineProperty(dj,{gh:"originalToNode"},function(){return this.WD},function(a){null!==a&&D.l(a,G,dj,"originalToNode");this.WD=a});
D.defineProperty(dj,{hh:"originalToPort"},function(){return this.XD},function(a){null!==a&&D.l(a,P,dj,"originalToPort");this.XD=a});D.defineProperty(dj,{be:"isForwards"},function(){return this.vD},function(a){D.h(a,"boolean",dj,"isForwards");this.vD=a});D.w(dj,{Py:"validPortsCache"},function(){return this.MH});D.defineProperty(dj,{Pf:"targetPort"},function(){return this.nE},function(a){null!==a&&D.l(a,P,dj,"targetPort");this.nE=a});
dj.prototype.copyPortProperties=function(a,b,c,d,e){if(null!==a&&null!==b&&null!==c&&null!==d){d.Ea=b.Z.size;e?(d.Hb=b.Hb,d.Hm=b.Hm):(d.Gb=b.Gb,d.sm=b.sm);c.Nf=mc;var g=D.P();c.location=b.eb(mc,g);D.A(g);d.angle=b.tm();null!==this.wy&&this.wy(a,b,c,d,e)}};dj.prototype.setNoTargetPortProperties=function(a,b,c){null!==b&&(b.Ea=Yd,b.Gb=dc,b.Hb=dc);null!==a&&null!==this.g&&(a.location=this.g.U.ga);null!==this.wy&&this.wy(null,null,a,b,c)};dj.prototype.doMouseDown=function(){this.wa&&this.doMouseMove()};
dj.prototype.doMouseMove=function(){if(this.wa){var a=this.g;if(null!==a){this.Pf=this.findTargetPort(this.be);if(null!==this.Pf&&this.Pf.Y instanceof G){var b=this.Pf.Y;this.be?this.copyPortProperties(b,this.Pf,this.fe,this.yo,!0):this.copyPortProperties(b,this.Pf,this.ee,this.xo,!1)}else this.be?this.setNoTargetPortProperties(this.fe,this.yo,!0):this.setNoTargetPortProperties(this.ee,this.xo,!1);(a.Ae||a.Be)&&a.JA(a.U.se)}}};
dj.prototype.findValidLinkablePort=function(a,b){if(null===a)return null;var c=a.Y;if(!(c instanceof G))return null;for(;null!==a;){var d=b?a.hH:a.BF;if(!0===d&&(null!==a.wd||a instanceof G)&&(b?this.isValidTo(c,a):this.isValidFrom(c,a)))return a;if(!1===d)break;a=a.R}return null};
dj.prototype.findTargetPort=function(a){var b=this.g,c=b.U.ga,d=this.zG;0>=d&&(d=.1);for(var e=this,g=b.Qn(c,d,function(b){return e.findValidLinkablePort(b,a)},null,!0),d=Infinity,b=null,g=g.j;g.next();){var h=g.value,k=h.Y;if(k instanceof G){var l=h.eb(mc,D.P()),m=c.x-l.x,n=c.y-l.y;D.A(l);l=m*m+n*n;l<d&&(m=this.Py.oa(h),null!==m?m&&(b=h,d=l):a&&this.isValidLink(this.eh,this.fh,k,h)||!a&&this.isValidLink(k,h,this.gh,this.hh)?(this.Py.add(h,!0),b=h,d=l):this.Py.add(h,!1))}}return null!==b&&(c=b.Y,
c instanceof G&&(null===c.layer||c.layer.Xt))?b:null};dj.prototype.isValidFrom=function(a,b){if(null===a||null===b)return this.xq;if(null!==this.g&&this.g.hb===this&&(null!==a.layer&&!a.layer.Xt||!0!==b.BF))return!1;var c=b.SA;if(Infinity>c){if(null!==this.tc&&a===this.eh&&b===this.fh)return!0;var d=b.wd;null===d&&(d="");if(a.Mx(d).count>=c)return!1}return!0};
dj.prototype.isValidTo=function(a,b){if(null===a||null===b)return this.xq;if(null!==this.g&&this.g.hb===this&&(null!==a.layer&&!a.layer.Xt||!0!==b.hH))return!1;var c=b.yK;if(Infinity>c){if(null!==this.tc&&a===this.gh&&b===this.hh)return!0;var d=b.wd;null===d&&(d="");if(a.Zg(d).count>=c)return!1}return!0};dj.prototype.isInSameNode=function(a,b){if(null===a||null===b)return!1;if(a===b)return!0;var c=a.Y,d=b.Y;return null!==c&&c===d};
dj.prototype.isLinked=function(a,b){if(null===a||null===b)return!1;var c=a.Y;if(!(c instanceof G))return!1;var d=a.wd;null===d&&(d="");var e=b.Y;if(!(e instanceof G))return!1;var g=b.wd;null===g&&(g="");for(e=e.Zg(g);e.next();)if(g=e.value,g.aa===c&&g.hg===d)return!0;return!1};
dj.prototype.isValidLink=function(a,b,c,d){if(!this.isValidFrom(a,b)||!this.isValidTo(c,d)||!(null===b||null===d||(b.LI&&d.xK||!this.isInSameNode(b,d))&&(b.KI&&d.wK||!this.isLinked(b,d)))||null!==this.tc&&(null!==a&&this.isLabelDependentOnLink(a,this.tc)||null!==c&&this.isLabelDependentOnLink(c,this.tc))||null!==a&&null!==c&&(null===a.data&&null!==c.data||null!==a.data&&null===c.data)||!this.isValidCycle(a,c,this.tc))return!1;if(null!==a){var e=a.jy;if(null!==e&&!e(a,b,c,d,this.tc))return!1}if(null!==
c&&(e=c.jy,null!==e&&!e(a,b,c,d,this.tc)))return!1;e=this.jy;return null!==e?e(a,b,c,d,this.tc):!0};dj.prototype.isLabelDependentOnLink=function(a,b){if(null===a)return!1;var c=a.Yb;if(null===c)return!1;if(c===b)return!0;var d=new L(G);d.add(a);return ej(this,c,b,d)};function ej(a,b,c,d){if(b===c)return!0;var e=b.aa;if(null!==e&&e.Kf&&(d.add(e),ej(a,e.Yb,c,d)))return!0;b=b.ba;return null!==b&&b.Kf&&(d.add(b),ej(a,b.Yb,c,d))?!0:!1}
dj.prototype.isValidCycle=function(a,b,c){void 0===c&&(c=null);if(null===a||null===b)return this.xq;var d=null!==this.g?this.g.EK:fj;if(d!==fj){if(d===gj){if(null!==c&&!c.Xc)return!0;for(d=b.Kd;d.next();){var e=d.value;if(e!==c&&e.Xc&&e.ba===b)return!1}return!hj(this,a,b,c,!0)}if(d===ij){if(null!==c&&!c.Xc)return!0;for(d=a.Kd;d.next();)if(e=d.value,e!==c&&e.Xc&&e.aa===a)return!1;return!hj(this,a,b,c,!0)}if(d===jj)return a===b?a=!0:(d=new L(G),d.add(b),a=kj(this,d,a,b,c)),!a;if(d===lj)return!hj(this,
a,b,c,!1);if(d===mj)return a===b?a=!0:(d=new L(G),d.add(b),a=nj(this,d,a,b,c)),!a}return!0};function hj(a,b,c,d,e){if(b===c)return!0;if(null===b||null===c)return!1;for(var g=b.Kd;g.next();){var h=g.value;if(h!==d&&(!e||h.Xc)&&h.ba===b&&(h=h.aa,h!==b&&hj(a,h,c,d,e)))return!0}return!1}function kj(a,b,c,d,e){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var g=c.Kd;g.next();){var h=g.value;if(h!==e&&h.ba===c&&(h=h.aa,h!==c&&kj(a,b,h,d,e)))return!0}return!1}
function nj(a,b,c,d,e){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var g=c.Kd;g.next();){var h=g.value;if(h!==e){var k=h.aa,h=h.ba,k=k===c?h:k;if(k!==c&&nj(a,b,k,d,e))return!0}}return!1}D.defineProperty(dj,{jy:"linkValidation"},function(){return this.Ol},function(a){null!==a&&D.h(a,"function",dj,"linkValidation");this.Ol=a});D.defineProperty(dj,{wy:"portTargeted"},function(){return this.$D},function(a){null!==a&&D.h(a,"function",dj,"portTargeted");this.$D=a});
function ra(){0<arguments.length&&D.xd(ra);dj.call(this);this.name="Linking";this.gz={};this.fz=null;this.fa=oj;this.iE=null}D.Sa(ra,dj);D.ka("LinkingTool",ra);var oj;ra.Either=oj=D.s(ra,"Either",0);var pj;ra.ForwardsOnly=pj=D.s(ra,"ForwardsOnly",0);var qj;ra.BackwardsOnly=qj=D.s(ra,"BackwardsOnly",0);D.defineProperty(ra,{WH:"archetypeLinkData"},function(){return this.gz},function(a){null!==a&&D.l(a,Object,ra,"archetypeLinkData");a instanceof P&&D.l(a,J,ra,"archetypeLinkData");this.gz=a});
D.defineProperty(ra,{QE:"archetypeLabelNodeData"},function(){return this.fz},function(a){null!==a&&D.l(a,Object,ra,"archetypeLabelNodeData");a instanceof P&&D.l(a,G,ra,"archetypeLabelNodeData");this.fz=a});D.defineProperty(ra,{direction:"direction"},function(){return this.fa},function(a){D.Da(a,ra,ra,"direction");this.fa=a});D.defineProperty(ra,{bH:"startObject"},function(){return this.iE},function(a){null!==a&&D.l(a,P,ra,"startObject");this.iE=a});
ra.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||a.rb||a.Lf||!a.Xt)return!1;var b=a.da;return(b instanceof Y||b instanceof Bg)&&a.U.left&&(a.hb===this||this.isBeyondDragSize())?null!==this.findLinkablePort():!1};
ra.prototype.findLinkablePort=function(){var a=this.g;if(null===a)return null;var b=this.bH;null===b&&(b=a.Ie(a.Lc.ga,null,null));if(null===b||!(b.Y instanceof G))return null;a=this.direction;if(a===oj||a===pj){var c=this.findValidLinkablePort(b,!1);if(null!==c)return this.be=!0,c}if(a===oj||a===qj)if(c=this.findValidLinkablePort(b,!0),null!==c)return this.be=!1,c;return null};
ra.prototype.doActivate=function(){var a=this.g;if(null!==a){var b=this.findLinkablePort();null!==b&&(this.Nb(this.name),a.nf=!0,a.mc="pointer",this.be?(null===this.fe||this.fe.location.F()||(this.fe.location=a.U.ga),this.fh=b,b=this.fh.Y,b instanceof G&&(this.eh=b),this.copyPortProperties(this.eh,this.fh,this.ee,this.xo,!1)):(null===this.ee||this.ee.location.F()||(this.ee.location=a.U.ga),this.hh=b,b=this.hh.Y,b instanceof G&&(this.gh=b),this.copyPortProperties(this.gh,this.hh,this.fe,this.yo,!0)),
a.add(this.ee),a.add(this.fe),null!==this.kh&&(null!==this.ee&&(this.kh.aa=this.ee),null!==this.fe&&(this.kh.ba=this.fe),this.kh.ec(),a.add(this.kh)),this.wa=!0)}};ra.prototype.doDeactivate=function(){this.wa=!1;var a=this.g;null!==a&&(a.remove(this.kh),a.remove(this.ee),a.remove(this.fe),a.nf=!1,a.mc="",this.gl())};ra.prototype.doStop=function(){dj.prototype.doStop.call(this);this.bH=null};
ra.prototype.doMouseUp=function(){if(this.wa){var a=this.g;if(null===a)return;var b=this.Sf=null,c=null,d=null,e=null,g=this.Pf=this.findTargetPort(this.be);if(null!==g){var h=g.Y;h instanceof G&&(this.be?(null!==this.eh&&(b=this.eh,c=this.fh),d=h,e=g):(b=h,c=g,null!==this.gh&&(d=this.gh,e=this.hh)))}else this.be?null!==this.eh&&this.xq&&(b=this.eh,c=this.fh):null!==this.gh&&this.xq&&(d=this.gh,e=this.hh);null!==b||null!==d?(h=this.insertLink(b,c,d,e),null!==h?(null===g&&(this.be?h.gq=a.U.ga:h.fq=
a.U.ga),a.If&&a.select(h),this.Sf=this.name,a.Ja("LinkDrawn",h)):(a.da.WE(),this.doNoLink(b,c,d,e))):this.be?this.doNoLink(this.eh,this.fh,null,null):this.doNoLink(null,null,this.gh,this.hh)}this.stopTool()};
ra.prototype.insertLink=function(a,b,c,d){var e=this.g;if(null===e)return null;var g=e.da;if(g instanceof Bg){var h=a;b=c;e.de||(h=c,b=a);if(null!==h&&null!==b)return g.Ji(b.data,g.zb(h.data)),b.Rn()}else if(g instanceof Y)if(h="",null!==a&&(null===b&&(b=a),h=b.wd,null===h&&(h="")),b="",null!==c&&(null===d&&(d=c),b=d.wd,null===b&&(b="")),d=this.WH,d instanceof J){if(Sh(d),g=d.copy(),null!==g)return g.aa=a,g.hg=h,g.ba=c,g.mh=b,e.add(g),a=this.QE,a instanceof G&&(Sh(a),a=a.copy(),null!==a&&(a.Yb=g,
e.add(a))),g}else if(null!==d&&(d=g.Ax(d),D.Qa(d)))return null!==a&&g.LB(d,g.zb(a.data)),g.MB(d,h),null!==c&&g.RB(d,g.zb(c.data)),g.SB(d,b),g.St(d),a=this.QE,null===a||a instanceof G||(a=g.copyNodeData(a),D.Qa(a)&&(g.cm(a),a=g.zb(a),void 0!==a&&g.LE(d,a))),g=e.gg(d);return null};ra.prototype.doNoLink=function(){};
function fi(){0<arguments.length&&D.xd(fi);dj.call(this);this.name="Relinking";var a=new z;a.Jb="Diamond";a.Ea=$d;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="pointer";a.We=0;this.iD=a;a=new z;a.Jb="Diamond";a.Ea=$d;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="pointer";a.We=-1;this.vE=a;this.pc=null;this.UD=new C}D.Sa(fi,dj);D.ka("RelinkingTool",fi);
fi.prototype.updateAdornments=function(a){if(null!==a&&a instanceof J){var b="RelinkFrom",c=null;if(a.ib&&null!==this.g&&!this.g.rb){var d=a.to;null!==d&&a.canRelinkFrom()&&a.Z.F()&&a.isVisible()&&d.Z.F()&&d.Oj()&&(c=a.kq(b),null===c&&(c=this.makeAdornment(d,!1),a.bm(b,c)))}null===c&&a.Tj(b);b="RelinkTo";c=null;a.ib&&null!==this.g&&!this.g.rb&&(d=a.to,null!==d&&a.canRelinkTo()&&a.Z.F()&&a.isVisible()&&d.Z.F()&&d.Oj()&&(c=a.kq(b),null===c&&(c=this.makeAdornment(d,!0),a.bm(b,c))));null===c&&a.Tj(b)}};
fi.prototype.makeAdornment=function(a,b){var c=new da;c.type=rj;var d=b?this.vK:this.JI;null!==d&&c.add(d.copy());c.Bb=a;return c};D.defineProperty(fi,{JI:"fromHandleArchetype"},function(){return this.iD},function(a){null!==a&&D.l(a,P,fi,"fromHandleArchetype");this.iD=a});D.defineProperty(fi,{vK:"toHandleArchetype"},function(){return this.vE},function(a){null!==a&&D.l(a,P,fi,"toHandleArchetype");this.vE=a});D.w(fi,{handle:"handle"},function(){return this.pc});
fi.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||a.rb||a.Lf||!a.En)return!1;var b=a.da;if(!(b instanceof Y||b instanceof Bg)||!a.U.left)return!1;b=this.findToolHandleAt(a.Lc.ga,"RelinkFrom");null===b&&(b=this.findToolHandleAt(a.Lc.ga,"RelinkTo"));return null!==b};
fi.prototype.doActivate=function(){var a=this.g;if(null!==a){if(null===this.tc){var b=this.findToolHandleAt(a.Lc.ga,"RelinkFrom");null===b&&(b=this.findToolHandleAt(a.Lc.ga,"RelinkTo"));if(null===b)return;var c=b.Y;if(!(c instanceof da&&c.Hf instanceof J))return;this.pc=b;this.be=null===c||"RelinkTo"===c.Gd;this.tc=c.Hf}this.Nb(this.name);a.nf=!0;a.mc="pointer";this.fh=this.tc.Ac;this.eh=this.tc.aa;this.hh=this.tc.ad;this.gh=this.tc.ba;this.UD.set(this.tc.Z);null!==this.tc&&0<this.tc.ta&&(null===
this.tc.aa&&(null!==this.xo&&(this.xo.Ea=Xd),null!==this.ee&&(this.ee.location=this.tc.m(0))),null===this.tc.ba&&(null!==this.yo&&(this.yo.Ea=Xd),null!==this.fe&&(this.fe.location=this.tc.m(this.tc.ta-1))));this.copyPortProperties(this.eh,this.fh,this.ee,this.xo,!1);this.copyPortProperties(this.gh,this.hh,this.fe,this.yo,!0);a.add(this.ee);a.add(this.fe);null!==this.kh&&(null!==this.ee&&(this.kh.aa=this.ee),null!==this.fe&&(this.kh.ba=this.fe),this.copyLinkProperties(this.tc,this.kh),this.kh.ec(),
a.add(this.kh));this.wa=!0}};fi.prototype.copyLinkProperties=function(a,b){if(null!==a&&null!==b){b.Ut=a.Ut;b.FA=a.FA;var c=a.lf;if(c===sj||c===tj)c=Mj;b.lf=c;b.Dx=a.Dx;b.Ey=a.Ey;b.Qq=a.Qq;b.Gb=a.Gb;b.sm=a.sm;b.ru=a.ru;b.su=a.su;b.Hb=a.Hb;b.Hm=a.Hm;b.kv=a.kv;b.lv=a.lv;b.points=a.points}};fi.prototype.doDeactivate=function(){this.wa=!1;var a=this.g;null!==a&&(a.remove(this.kh),a.remove(this.ee),a.remove(this.fe),a.nf=!1,a.mc="",this.gl())};
fi.prototype.doStop=function(){dj.prototype.doStop.call(this);this.pc=null};
fi.prototype.doMouseUp=function(){if(this.wa){var a=this.g;if(null===a)return;this.Sf=null;var b=this.eh,c=this.fh,d=this.gh,e=this.hh,g=this.tc;this.Pf=this.findTargetPort(this.be);if(null!==this.Pf){var h=this.Pf.Y;h instanceof G&&(this.be?(d=h,e=this.Pf):(b=h,c=this.Pf))}else this.xq?this.be?e=d=null:c=b=null:g=null;null!==g?(this.reconnectLink(g,this.be?d:b,this.be?e:c,this.be),null===this.Pf&&(this.be?g.gq=a.U.ga:g.fq=a.U.ga,g.ec()),a.If&&(g.ib=!0),this.Sf=this.name,a.Ja("LinkRelinked",g,this.be?
this.hh:this.fh)):this.doNoRelink(this.tc,this.be);Nj(this.tc,this.UD)}this.stopTool()};fi.prototype.reconnectLink=function(a,b,c,d){if(null===this.g)return!1;c=null!==c&&null!==c.wd?c.wd:"";d?(a.ba=b,a.mh=c):(a.aa=b,a.hg=c);return!0};fi.prototype.doNoRelink=function(){};function Zi(a,b,c,d,e){null!==a.g&&(null!==b?(a.copyPortProperties(b,c,a.ee,a.xo,!1),a.g.add(a.ee)):a.g.remove(a.ee),null!==d?(a.copyPortProperties(d,e,a.fe,a.yo,!0),a.g.add(a.fe)):a.g.remove(a.fe))}
function Oj(){0<arguments.length&&D.xd(Oj);Zg.call(this);this.name="LinkReshaping";var a=new z;a.Jb="Rectangle";a.Ea=Zd;a.fill="lightblue";a.stroke="dodgerblue";this.Bl=a;a=new z;a.Jb="Diamond";a.Ea=Zd;a.fill="lightblue";a.stroke="dodgerblue";this.GD=a;this.aE=3;this.dz=this.pc=null;this.Bp=new N;this.Ew=null}D.Sa(Oj,Zg);D.ka("LinkReshapingTool",Oj);var Pj;Oj.None=Pj=D.s(Oj,"None",0);var Qj;Oj.Horizontal=Qj=D.s(Oj,"Horizontal",1);var Rj;Oj.Vertical=Rj=D.s(Oj,"Vertical",2);var Sj;
Oj.All=Sj=D.s(Oj,"All",3);Oj.prototype.getReshapingBehavior=Oj.prototype.GF=function(a){return a&&a.aA?a.aA:Pj};Oj.prototype.setReshapingBehavior=Oj.prototype.ev=function(a,b){D.l(a,P,Oj,"setReshapingBehavior:obj");D.Da(b,Oj,Oj,"setReshapingBehavior:behavior");a.aA=b};
Oj.prototype.updateAdornments=function(a){if(null!==a&&a instanceof J){if(a.ib&&null!==this.g&&!this.g.rb){var b=a.path;if(null!==b&&a.canReshape()&&a.Z.F()&&a.isVisible()&&b.Z.F()&&b.Oj()){var c=a.kq(this.name);if(null===c||c.OD!==a.ta||c.EE!==a.$u)c=this.makeAdornment(b),null!==c&&(c.OD=a.ta,c.EE=a.$u,a.bm(this.name,c));if(null!==c){c.location=a.position;return}}}a.Tj(this.name)}};
Oj.prototype.makeAdornment=function(a){var b=a.Y,c=b.ta,d=b.ic,e=null;if(null!==b.points&&1<c){e=new da;e.type=rj;var c=b.lu,g=b.gy,h=d?1:0;if(b.$u&&b.lf!==Tj)for(var k=c+h;k<g-h;k++){var l=this.makeResegmentHandle(a,k);null!==l&&(l.We=k,l.JB=.5,l.SA=999,e.add(l))}for(k=c+1;k<g;k++)if(l=this.makeHandle(a,k),null!==l){l.We=k;if(k!==c)if(k===c+1&&d){var h=b.m(c),m=b.m(c+1);Q(h.x,m.x)&&Q(h.y,m.y)&&(m=b.m(c-1));Q(h.x,m.x)?(this.ev(l,Rj),l.cursor="n-resize"):Q(h.y,m.y)&&(this.ev(l,Qj),l.cursor="w-resize")}else k===
g-1&&d?(h=b.m(g-1),m=b.m(g),Q(h.x,m.x)&&Q(h.y,m.y)&&(h=b.m(g+1)),Q(h.x,m.x)?(this.ev(l,Rj),l.cursor="n-resize"):Q(h.y,m.y)&&(this.ev(l,Qj),l.cursor="w-resize")):k!==g&&(this.ev(l,Sj),l.cursor="move");e.add(l)}e.Bb=a}return e};Oj.prototype.makeHandle=function(){var a=this.vu;return null===a?null:a.copy()};D.defineProperty(Oj,{vu:"handleArchetype"},function(){return this.Bl},function(a){null!==a&&D.l(a,P,Oj,"handleArchetype");this.Bl=a});
Oj.prototype.makeResegmentHandle=function(){var a=this.KJ;return null===a?null:a.copy()};D.defineProperty(Oj,{KJ:"midHandleArchetype"},function(){return this.GD},function(a){null!==a&&D.l(a,P,Oj,"midHandleArchetype");this.GD=a});D.w(Oj,{handle:"handle"},function(){return this.pc});D.w(Oj,{Vt:"adornedLink"},function(){return this.dz});Oj.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null!==a&&!a.rb&&a.qx&&a.U.left?null!==this.findToolHandleAt(a.Lc.ga,this.name):!1};
Oj.prototype.doActivate=function(){var a=this.g;if(null!==a&&(this.pc=this.findToolHandleAt(a.Lc.ga,this.name),null!==this.pc)){var b=this.pc.Y.Hf;if(b instanceof J){this.dz=b;a.nf=!0;this.Nb(this.name);if(b.$u&&999===this.pc.SA){var c=b.points.copy(),d=this.pc.eb(mc);c.ae(this.pc.We+1,d);b.ic&&c.ae(this.pc.We+1,d);b.points=c;b.oe();this.pc=this.findToolHandleAt(a.Lc.ga,this.name);if(null===this.pc){this.doDeactivate();return}}this.Bp=b.m(this.pc.We);this.Ew=b.points.copy();this.wa=!0}}};
Oj.prototype.doDeactivate=function(){this.gl();this.dz=this.pc=null;var a=this.g;null!==a&&(a.nf=!1);this.wa=!1};Oj.prototype.doCancel=function(){var a=this.Vt;null!==a&&(a.points=this.Ew);this.stopTool()};Oj.prototype.doMouseMove=function(){var a=this.g;this.wa&&null!==a&&(a=this.computeReshape(a.U.ga),this.reshape(a))};
Oj.prototype.doMouseUp=function(){var a=this.g;if(this.wa&&null!==a){var b=this.computeReshape(a.U.ga);this.reshape(b);b=this.Vt;if(null!==b&&b.$u){var c=this.handle.We,d=b.m(c-1),e=b.m(c),g=b.m(c+1);if(b.ic){if(c>b.lu+1&&c<b.gy-1){var h=b.m(c-2);if(Math.abs(d.x-e.x)<this.Wh&&Math.abs(d.y-e.y)<this.Wh&&(Uj(this,h,d,e,g,!0)||Uj(this,h,d,e,g,!1))){var k=b.points.copy();Uj(this,h,d,e,g,!0)?(k.ug(c-2,new N(h.x,(g.y+h.y)/2)),k.ug(c+1,new N(g.x,(g.y+h.y)/2))):(k.ug(c-2,new N((g.x+h.x)/2,h.y)),k.ug(c+1,
new N((g.x+h.x)/2,g.y)));k.od(c);k.od(c-1);b.points=k;b.oe()}else h=b.m(c+2),Math.abs(e.x-g.x)<this.Wh&&Math.abs(e.y-g.y)<this.Wh&&(Uj(this,d,e,g,h,!0)||Uj(this,d,e,g,h,!1))&&(k=b.points.copy(),Uj(this,d,e,g,h,!0)?(k.ug(c-1,new N(d.x,(d.y+h.y)/2)),k.ug(c+2,new N(h.x,(d.y+h.y)/2))):(k.ug(c-1,new N((d.x+h.x)/2,d.y)),k.ug(c+2,new N((d.x+h.x)/2,h.y))),k.od(c+1),k.od(c),b.points=k,b.oe())}}else h=D.P(),fb(d.x,d.y,g.x,g.y,e.x,e.y,h)&&h.Jf(e)<this.Wh*this.Wh&&(k=b.points.copy(),k.od(c),b.points=k,b.oe()),
D.A(h)}a.Nc();this.Sf=this.name;a.Ja("LinkReshaped",this.Vt,this.Ew)}this.stopTool()};function Uj(a,b,c,d,e,g){return g?Math.abs(b.y-c.y)<a.Wh&&Math.abs(c.y-d.y)<a.Wh&&Math.abs(d.y-e.y)<a.Wh:Math.abs(b.x-c.x)<a.Wh&&Math.abs(c.x-d.x)<a.Wh&&Math.abs(d.x-e.x)<a.Wh}D.defineProperty(Oj,{Wh:"resegmentingDistance"},function(){return this.aE},function(a){D.h(a,"number",Oj,"resegmentingDistance");this.aE=a});
Oj.prototype.reshape=function(a){var b=this.Vt;b.Dm();var c=this.handle.We,d=this.GF(this.handle);if(b.ic)if(c===b.lu+1)c=b.lu+1,d===Rj?(b.ha(c,b.m(c-1).x,a.y),b.ha(c+1,b.m(c+2).x,a.y)):d===Qj&&(b.ha(c,a.x,b.m(c-1).y),b.ha(c+1,a.x,b.m(c+2).y));else if(c===b.gy-1)c=b.gy-1,d===Rj?(b.ha(c-1,b.m(c-2).x,a.y),b.ha(c,b.m(c+1).x,a.y)):d===Qj&&(b.ha(c-1,a.x,b.m(c-2).y),b.ha(c,a.x,b.m(c+1).y));else{var d=c,e=b.m(d),g=b.m(d-1),h=b.m(d+1);Q(g.x,e.x)&&Q(e.y,h.y)?(Q(g.x,b.m(d-2).x)&&!Q(g.y,b.m(d-2).y)?(b.B(d,a.x,
g.y),c++,d++):b.ha(d-1,a.x,g.y),Q(h.y,b.m(d+2).y)&&!Q(h.x,b.m(d+2).x)?b.B(d+1,h.x,a.y):b.ha(d+1,h.x,a.y)):Q(g.y,e.y)&&Q(e.x,h.x)?(Q(g.y,b.m(d-2).y)&&!Q(g.x,b.m(d-2).x)?(b.B(d,g.x,a.y),c++,d++):b.ha(d-1,g.x,a.y),Q(h.x,b.m(d+2).x)&&!Q(h.y,b.m(d+2).y)?b.B(d+1,a.x,h.y):b.ha(d+1,a.x,h.y)):Q(g.x,e.x)&&Q(e.x,h.x)?(Q(g.x,b.m(d-2).x)&&!Q(g.y,b.m(d-2).y)?(b.B(d,a.x,g.y),c++,d++):b.ha(d-1,a.x,g.y),Q(h.x,b.m(d+2).x)&&!Q(h.y,b.m(d+2).y)?b.B(d+1,a.x,h.y):b.ha(d+1,a.x,h.y)):Q(g.y,e.y)&&Q(e.y,h.y)&&(Q(g.y,b.m(d-
2).y)&&!Q(g.x,b.m(d-2).x)?(b.B(d,g.x,a.y),c++,d++):b.ha(d-1,g.x,a.y),Q(h.y,b.m(d+2).y)&&!Q(h.x,b.m(d+2).x)?b.B(d+1,h.x,a.y):b.ha(d+1,h.x,a.y));b.ha(c,a.x,a.y)}else b.ha(c,a.x,a.y),e=b.aa,g=b.Ac,null!==e&&(d=e.findVisibleNode(),null!==d&&d!==e&&(e=d,g=e.port)),1===c&&b.computeSpot(!0,g).ce()&&(d=g.eb(mc,D.P()),e=b.getLinkPointFromPoint(e,g,d,a,!0,D.P()),b.ha(0,e.x,e.y),D.A(d),D.A(e)),e=b.ba,g=b.ad,null!==e&&(d=e.findVisibleNode(),null!==d&&d!==e&&(e=d,g=e.port)),c===b.ta-2&&b.computeSpot(!1,g).ce()&&
(d=g.eb(mc,D.P()),e=b.getLinkPointFromPoint(e,g,d,a,!1,D.P()),b.ha(b.ta-1,e.x,e.y),D.A(d),D.A(e));b.Aj()};Oj.prototype.computeReshape=function(a){var b=this.Vt,c=this.handle.We;switch(this.GF(this.handle)){case Sj:return a;case Rj:return b=b.m(c),new N(b.x,a.y);case Qj:return b=b.m(c),new N(a.x,b.y);default:case Pj:return b.m(c)}};D.w(Oj,{lM:"originalPoint"},function(){return this.Bp});D.w(Oj,{mM:"originalPoints"},function(){return this.Ew});
function Vj(){0<arguments.length&&D.xd(Vj);Zg.call(this);this.name="Resizing";this.ni=(new Ca(1,1)).freeze();this.mi=(new Ca(9999,9999)).freeze();this.ck=(new Ca(NaN,NaN)).freeze();this.es=!1;this.Rc=null;var a=new z;a.Ih=mc;a.Jb="Rectangle";a.Ea=Zd;a.fill="lightblue";a.stroke="dodgerblue";a.mb=1;a.cursor="pointer";this.Bl=a;this.pc=null;this.Bp=new N;this.QD=new Ca;this.VD=new N;this.Fz=new Ca(0,0);this.Ez=new Ca(Infinity,Infinity);this.Dz=new Ca(1,1);this.ND=!0}D.Sa(Vj,Zg);D.ka("ResizingTool",Vj);
Vj.prototype.updateAdornments=function(a){if(!(null===a||a instanceof J)){if(a.ib&&null!==this.g&&!this.g.rb){var b=a.LG;if(null!==b&&a.canResize()&&a.Z.F()&&a.isVisible()&&b.Z.F()&&b.Oj()){var c=a.kq(this.name);if(null===c||c.Bb!==b)c=this.makeAdornment(b);if(null!==c){var d=b.tm();c.angle=d;var e=b.eb(c.Nf,D.P()),g=b.Fj();c.location=e;D.A(e);e=c.placeholder;if(null!==e){var b=b.Ga,h=D.Gm();h.n(b.width*g,b.height*g);e.Ea=h;D.Uk(h)}this.updateResizeHandles(c,d);a.bm(this.name,c);return}}}a.Tj(this.name)}};
Vj.prototype.makeAdornment=function(a){var b=null,b=a.Y.KG;if(null===b){b=new da;b.type=Wj;b.Nf=mc;var c=new Xj;c.Ue=!0;b.add(c);b.add(this.makeHandle(a,ec));b.add(this.makeHandle(a,kc));b.add(this.makeHandle(a,vc));b.add(this.makeHandle(a,tc));b.add(this.makeHandle(a,$c));b.add(this.makeHandle(a,fd));b.add(this.makeHandle(a,gd));b.add(this.makeHandle(a,ed))}else if(Sh(b),b=b.copy(),null===b)return null;b.Bb=a;return b};
Vj.prototype.makeHandle=function(a,b){var c=this.vu;if(null===c)return null;c=c.copy();c.alignment=b;return c};
Vj.prototype.updateResizeHandles=function(a,b){if(null!==a)if(!a.alignment.kd()&&("pointer"===a.cursor||0<a.cursor.indexOf("resize")))a:{var c=a.alignment;c.ce()&&(c=mc);var d=b;if(0>=c.x)d=0>=c.y?d+225:1<=c.y?d+135:d+180;else if(1<=c.x)0>=c.y?d+=315:1<=c.y&&(d+=45);else if(0>=c.y)d+=270;else if(1<=c.y)d+=90;else break a;0>d?d+=360:360<=d&&(d-=360);a.cursor=22.5>d?"e-resize":67.5>d?"se-resize":112.5>d?"s-resize":157.5>d?"sw-resize":202.5>d?"w-resize":247.5>d?"nw-resize":292.5>d?"n-resize":337.5>d?
"ne-resize":"e-resize"}else if(a instanceof x)for(c=a.elements;c.next();)this.updateResizeHandles(c.value,b)};D.defineProperty(Vj,{vu:"handleArchetype"},function(){return this.Bl},function(a){null!==a&&D.l(a,P,Vj,"handleArchetype");this.Bl=a});D.w(Vj,{handle:"handle"},function(){return this.pc});D.defineProperty(Vj,{Bb:"adornedObject"},function(){return this.Rc},function(a){null!==a&&D.l(a,P,Vj,"adornedObject");this.Rc=a});
Vj.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null!==a&&!a.rb&&a.Yt&&a.U.left?null!==this.findToolHandleAt(a.Lc.ga,this.name):!1};
Vj.prototype.doActivate=function(){var a=this.g;null!==a&&(this.pc=this.findToolHandleAt(a.Lc.ga,this.name),null!==this.pc&&(this.Rc=this.pc.Y.Bb,this.Bp.set(this.Bb.eb(this.handle.alignment.uG())),this.VD.set(this.Rc.Y.location),this.QD.set(this.Rc.Ea),this.Dz=this.computeCellSize(),this.Fz=this.computeMinSize(),this.Ez=this.computeMaxSize(),a.nf=!0,this.ND=a.Wa.isEnabled,a.Wa.isEnabled=!1,this.Nb(this.name),this.wa=!0))};
Vj.prototype.doDeactivate=function(){var a=this.g;null!==a&&(this.gl(),this.Rc=this.pc=null,this.wa=a.nf=!1,a.Wa.isEnabled=this.ND)};Vj.prototype.doCancel=function(){null!==this.Bb&&(this.Bb.Ea=this.vG,this.Bb.Y.location=this.TJ);this.stopTool()};Vj.prototype.doMouseMove=function(){var a=this.g;if(this.wa&&null!==a){var b=this.Fz,c=this.Ez,d=this.Dz,e=this.Bb.EF(a.U.ga,D.P()),g=this.computeReshape(),b=this.computeResize(e,this.handle.alignment,b,c,d,g);this.resize(b);a.lg();D.A(e)}};
Vj.prototype.doMouseUp=function(){var a=this.g;if(this.wa&&null!==a){var b=this.Fz,c=this.Ez,d=this.Dz,e=this.Bb.EF(a.U.ga,D.P()),g=this.computeReshape(),b=this.computeResize(e,this.handle.alignment,b,c,d,g);this.resize(b);D.A(e);a.Nc();this.Sf=this.name;a.Ja("PartResized",this.Bb,this.vG)}this.stopTool()};
Vj.prototype.resize=function(a){var b=this.g;if(null!==b){var c=this.Bb,d=c.Y;c.Ea=a.size;d.Re();a=this.Bb.eb(this.handle.alignment.uG());d instanceof I?(c=new K(F),c.add(d),b.moveParts(c,this.Bp.copy().Wj(a),!0)):d.location=d.location.copy().Wj(a).add(this.Bp)}};
Vj.prototype.computeResize=function(a,b,c,d,e,g){b.ce()&&(b=mc);var h=this.Bb.Ga,k=h.x,l=h.y,m=h.x+h.width,n=h.y+h.height,p=1;if(!g){var p=h.width,q=h.height;0>=p&&(p=1);0>=q&&(q=1);p=q/p}q=D.P();jb(a.x,a.y,k,l,e.width,e.height,q);a=h.copy();0>=b.x?0>=b.y?(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=Math.max(m-a.x,c.width),a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,n-c.height),a.height=Math.max(n-a.y,c.height),g||(b=a.height/a.width,p<b?(a.height=p*a.width,a.y=n-a.height):(a.width=
a.height/p,a.x=m-a.width))):1<=b.y?(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=Math.max(m-a.x,c.width),a.height=Math.max(Math.min(q.y-l,d.height),c.height),g||(b=a.height/a.width,p<b?a.height=p*a.width:(a.width=a.height/p,a.x=m-a.width))):(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=m-a.x,g||(a.height=p*a.width,a.y=l+.5*(n-l-a.height))):1<=b.x?0>=b.y?(a.width=Math.max(Math.min(q.x-k,d.width),c.width),a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,n-c.height),
a.height=Math.max(n-a.y,c.height),g||(b=a.height/a.width,p<b?(a.height=p*a.width,a.y=n-a.height):a.width=a.height/p)):1<=b.y?(a.width=Math.max(Math.min(q.x-k,d.width),c.width),a.height=Math.max(Math.min(q.y-l,d.height),c.height),g||(b=a.height/a.width,p<b?a.height=p*a.width:a.width=a.height/p)):(a.width=Math.max(Math.min(q.x-k,d.width),c.width),g||(a.height=p*a.width,a.y=l+.5*(n-l-a.height))):0>=b.y?(a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,n-c.height),a.height=n-a.y,g||(a.width=a.height/p,a.x=
k+.5*(m-k-a.width))):1<=b.y&&(a.height=Math.max(Math.min(q.y-l,d.height),c.height),g||(a.width=a.height/p,a.x=k+.5*(m-k-a.width)));D.A(q);return a};Vj.prototype.computeReshape=function(){var a=Yj;this.Bb instanceof z&&(a=Zj(this.Bb));return!(a===ak||a===bk||null!==this.g&&this.g.U.shift)};Vj.prototype.computeMinSize=function(){var a=this.Bb.bh.copy(),b=this.bh;!isNaN(b.width)&&b.width>a.width&&(a.width=b.width);!isNaN(b.height)&&b.height>a.height&&(a.height=b.height);return a};
Vj.prototype.computeMaxSize=function(){var a=this.Bb.of.copy(),b=this.of;!isNaN(b.width)&&b.width<a.width&&(a.width=b.width);!isNaN(b.height)&&b.height<a.height&&(a.height=b.height);return a};
Vj.prototype.computeCellSize=function(){var a=new Ca(NaN,NaN),b=this.Bb.Y;if(null!==b){var c=b.bK;!isNaN(c.width)&&0<c.width&&(a.width=c.width);!isNaN(c.height)&&0<c.height&&(a.height=c.height)}c=this.Zp;isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width);isNaN(a.height)&&!isNaN(c.height)&&0<c.height&&(a.height=c.height);b=this.g;(isNaN(a.width)||isNaN(a.height))&&b&&(c=b.ob.Fe,null!==c&&c.Cu&&(c=c.MF,isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width),isNaN(a.height)&&!isNaN(c.height)&&
0<c.height&&(a.height=c.height)),b=b.Wn,null!==b&&b.visible&&this.Cu&&(c=b.Sx,isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width),isNaN(a.height)&&!isNaN(c.height)&&0<c.height&&(a.height=c.height)));if(isNaN(a.width)||0===a.width||Infinity===a.width)a.width=1;if(isNaN(a.height)||0===a.height||Infinity===a.height)a.height=1;return a};
D.defineProperty(Vj,{bh:"minSize"},function(){return this.ni},function(a){D.l(a,Ca,Vj,"minSize");if(!this.ni.O(a)){var b=a.width;isNaN(b)&&(b=0);a=a.height;isNaN(a)&&(a=0);this.ni.n(b,a)}});D.defineProperty(Vj,{of:"maxSize"},function(){return this.mi},function(a){D.l(a,Ca,Vj,"maxSize");if(!this.mi.O(a)){var b=a.width;isNaN(b)&&(b=Infinity);a=a.height;isNaN(a)&&(a=Infinity);this.mi.n(b,a)}});
D.defineProperty(Vj,{Zp:"cellSize"},function(){return this.ck},function(a){D.l(a,Ca,Vj,"cellSize");this.ck.O(a)||this.ck.assign(a)});D.defineProperty(Vj,{Cu:"isGridSnapEnabled"},function(){return this.es},function(a){D.h(a,"boolean",Vj,"isGridSnapEnabled");this.es=a});D.w(Vj,{vG:"originalDesiredSize"},function(){return this.QD});D.w(Vj,{TJ:"originalLocation"},function(){return this.VD});
function ck(){0<arguments.length&&D.xd(ck);Zg.call(this);this.name="Rotating";this.gE=45;this.fE=2;this.Rc=null;var a=new z;a.Jb="Ellipse";a.Ea=$d;a.fill="lightblue";a.stroke="dodgerblue";a.mb=1;a.cursor="pointer";this.Bl=a;this.pc=null;this.Dw=0;this.bE=new N}D.Sa(ck,Zg);D.ka("RotatingTool",ck);
ck.prototype.updateAdornments=function(a){if(null!==a){if(a instanceof J){var b=a.GB;if(b===a||b===a.path||b.Ue)return}if(a.ib&&null!==this.g&&!this.g.rb&&(b=a.GB,null!==b&&a.canRotate()&&a.Z.F()&&a.isVisible()&&b.Z.F()&&b.Oj())){var c=a.kq(this.name);if(null===c||c.Bb!==b)c=this.makeAdornment(b);if(null!==c){c.angle=b.tm();var d=null,e=null;b===a||b===a.Bf?(d=a.Bf,e=a.Nf):(d=b,e=mc);for(var g=d.Ga,e=D.Fb(g.width*e.x+e.offsetX,g.height*e.y+e.offsetY);null!==d&&d!==b;)d.transform.xb(e),d=d.R;var d=
e.y,g=Math.max(e.x-b.Ga.width,0),h=D.P();c.location=b.eb(new S(1,0,50+g,d),h);D.A(h);D.A(e);a.bm(this.name,c);return}}a.Tj(this.name)}};ck.prototype.makeAdornment=function(a){var b=null,b=a.Y.dK;if(null===b){b=new da;b.type=dk;b.Nf=mc;var c=this.vu;null!==c&&b.add(c.copy())}else if(Sh(b),b=b.copy(),null===b)return null;b.Bb=a;return b};D.defineProperty(ck,{vu:"handleArchetype"},function(){return this.Bl},function(a){null!==a&&D.l(a,P,ck,"handleArchetype");this.Bl=a});D.w(ck,{handle:"handle"},function(){return this.pc});
D.defineProperty(ck,{Bb:"adornedObject"},function(){return this.Rc},function(a){null!==a&&D.l(a,P,ck,"adornedObject");this.Rc=a});ck.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null!==a&&!a.rb&&a.sx&&a.U.left?null!==this.findToolHandleAt(a.Lc.ga,this.name):!1};
ck.prototype.doActivate=function(){var a=this.g;if(null!==a&&(this.pc=this.findToolHandleAt(a.Lc.ga,this.name),null!==this.pc)){this.Rc=this.pc.Y.Bb;var b=this.Rc.Y,c=b.Bf;this.bE=this.Rc===b||this.Rc===c?c.eb(b.Nf):this.Rc.eb(mc);this.Dw=this.Rc.angle;a.nf=!0;a.HA=!0;this.Nb(this.name);this.wa=!0}};ck.prototype.doDeactivate=function(){var a=this.g;null!==a&&(this.gl(),this.Rc=this.pc=null,this.wa=a.nf=!1)};ck.prototype.doCancel=function(){var a=this.g;null!==a&&(a.HA=!1);this.rotate(this.Dw);this.stopTool()};
ck.prototype.doMouseMove=function(){var a=this.g;this.wa&&null!==a&&(a=this.computeRotate(a.U.ga),this.rotate(a))};ck.prototype.doMouseUp=function(){var a=this.g;if(this.wa&&null!==a){a.HA=!1;var b=this.computeRotate(a.U.ga);this.rotate(b);a.Nc();this.Sf=this.name;a.Ja("PartRotated",this.Rc,this.Dw)}this.stopTool()};ck.prototype.rotate=function(a){v&&D.p(a,ck,"rotate:newangle");null!==this.Rc&&(this.Rc.angle=a)};
ck.prototype.computeRotate=function(a){a=this.bE.Xb(a);var b=this.Rc.R;null!==b&&(a-=b.tm(),360<=a?a-=360:0>a&&(a+=360));var b=Math.min(Math.abs(this.pK),180),c=Math.min(Math.abs(this.oK),b/2);(null===this.g||!this.g.U.shift)&&0<b&&0<c&&(a%b<c?a=Math.floor(a/b)*b:a%b>b-c&&(a=(Math.floor(a/b)+1)*b));360<=a?a-=360:0>a&&(a+=360);return a};D.defineProperty(ck,{pK:"snapAngleMultiple"},function(){return this.gE},function(a){D.h(a,"number",ck,"snapAngleMultiple");this.gE=a});
D.defineProperty(ck,{oK:"snapAngleEpsilon"},function(){return this.fE},function(a){D.h(a,"number",ck,"snapAngleEpsilon");this.fE=a});D.w(ck,{kM:"originalAngle"},function(){return this.Dw});function ek(){Zg.call(this);0<arguments.length&&D.xd(ek);this.name="ClickSelecting"}D.Sa(ek,Zg);D.ka("ClickSelectingTool",ek);ek.prototype.canStart=function(){return!this.isEnabled||null===this.g||this.isBeyondDragSize()?!1:!0};
ek.prototype.doMouseUp=function(){this.wa&&(this.standardMouseSelect(),!this.standardMouseClick()&&null!==this.g&&this.g.U.Mj&&this.g.ob.doToolTip());this.stopTool()};function fk(){Zg.call(this);0<arguments.length&&D.xd(fk);this.name="Action";this.Eo=null}D.Sa(fk,Zg);D.ka("ActionTool",fk);
fk.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a)return!1;var b=a.U,c=a.Ie(b.ga,function(a){for(;null!==a.R&&!a.yu;)a=a.R;return a});if(null!==c){if(!c.yu)return!1;this.Eo=c;a.Qo=a.Ie(b.ga,null,null);return!0}return!1};fk.prototype.doMouseDown=function(){if(this.wa){var a=this.g.U,b=this.Eo;null!==b&&(a.Ze=b,null!==b.HE&&b.HE(a,b))}else this.canStart()&&this.doActivate()};
fk.prototype.doMouseMove=function(){if(this.wa){var a=this.g.U,b=this.Eo;null!==b&&(a.Ze=b,null!==b.IE&&b.IE(a,b))}};fk.prototype.doMouseUp=function(){if(this.wa){var a=this.g,b=a.U,c=this.Eo;if(null===c)return;b.Ze=c;null!==c.JE&&c.JE(b,c);this.isBeyondDragSize()||Zh(c,b,a)}this.stopTool()};fk.prototype.doCancel=function(){var a=this.g;if(null!==a){var a=a.U,b=this.Eo;if(null===b)return;a.Ze=b;null!==b.GE&&b.GE(a,b)}this.stopTool()};fk.prototype.doStop=function(){this.Eo=null};
function ta(){Zg.call(this);0<arguments.length&&D.xd(ta);this.name="ClickCreating";this.ul=null;this.tD=!0;this.hD=new N(0,0)}D.Sa(ta,Zg);D.ka("ClickCreatingTool",ta);
ta.prototype.canStart=function(){if(!this.isEnabled||null===this.vA)return!1;var a=this.g;if(null===a||a.rb||a.Lf||!a.Wp||!a.U.left||this.isBeyondDragSize())return!1;if(this.iJ){if(1===a.U.Pe&&(this.hD=a.U.se.copy()),2!==a.U.Pe||this.isBeyondDragSize(this.hD))return!1}else if(1!==a.U.Pe)return!1;return a.hb!==this&&null!==a.ku(a.U.ga,!0)?!1:!0};ta.prototype.doMouseUp=function(){var a=this.g;this.wa&&null!==a&&this.insertPart(a.U.ga);this.stopTool()};
ta.prototype.insertPart=function(a){var b=this.g;if(null===b)return null;var c=this.vA;if(null===c)return null;this.Nb(this.name);var d=null;c instanceof F?c.pe()&&(Sh(c),d=c.copy(),null!==d&&b.add(d)):null!==c&&(c=b.da.copyNodeData(c),D.Qa(c)&&(b.da.cm(c),d=b.Ph(c)));null!==d&&(d.location=a,b.If&&b.select(d));b.Nc();this.Sf=this.name;b.Ja("PartCreated",d);this.gl();return d};
D.defineProperty(ta,{vA:"archetypeNodeData"},function(){return this.ul},function(a){null!==a&&D.l(a,Object,ta,"archetypeNodeData");this.ul=a});D.defineProperty(ta,{iJ:"isDoubleClick"},function(){return this.tD},function(a){D.h(a,"boolean",ta,"isDoubleClick");this.tD=a});function gk(){this.BE=this.ED=this.Mz=this.gA=null}D.ka("HTMLInfo",gk);D.defineProperty(gk,{lB:"mainElement"},function(){return this.ED},function(a){null!==a&&D.l(a,HTMLElement,gk,"mainElement");this.ED=a});
D.defineProperty(gk,{show:"show"},function(){return this.gA},function(a){this.gA!==a&&(null!==a&&D.h(a,"function",gk,"show"),this.gA=a)});D.defineProperty(gk,{Xn:"hide"},function(){return this.Mz},function(a){this.Mz!==a&&(null!==a&&D.h(a,"function",gk,"hide"),this.Mz=a)});D.defineProperty(gk,{hC:"valueFunction"},function(){return this.BE},function(a){this.BE=a});function hk(a,b,c){this.text=a;this.YE=b;this.visible=c}
function ik(){Zg.call(this);0<arguments.length&&D.xd(ik);this.name="ContextMenu";this.MC=this.oz=this.JC=null;this.LD=new N;this.qz=this.Sm=null;var a=this;this.nA=function(){a.stopTool()};jk(this)}D.Sa(ik,Zg);D.ka("ContextMenuTool",ik);
function jk(a){var b=new gk;b.show=function(a,b,c){c.showDefaultContextMenu()};b.Xn=function(a,b){b.hideDefaultContextMenu()};a.Sm=b;a.nA=function(){a.stopTool()};if(!1===D.eF){var b=D.createElement("div"),c=D.createElement("div");b.style.cssText="top: 0px;z-index:300;position: fixed;display: none;text-align: center;left: 25%;width: 50%;background-color: #F5F5F5;padding: 16px;border: 16px solid #444;border-radius: 10px;margin-top: 10px";c.style.cssText="z-index:299;position: fixed;display: none;top: 0;left: 0;width: 100%;height: 100%;background-color: black;opacity: 0.8;";
var d=D.createElement("style");window.document.getElementsByTagName("head")[0].appendChild(d);d.sheet.insertRule(".goCXul { list-style: none; }",0);d.sheet.insertRule(".goCXli {font:700 1.5em Helvetica, Arial, sans-serif;position: relative;min-width: 60px; }",0);d.sheet.insertRule(".goCXa {color: #444;display: inline-block;padding: 4px;text-decoration: none;margin: 2px;border: 1px solid gray;border-radius: 10px; }",0);b.addEventListener("contextmenu",kk,!1);b.addEventListener("selectstart",kk,!1);
c.addEventListener("contextmenu",kk,!1);window.document.body&&(window.document.body.appendChild(b),window.document.body.appendChild(c));D.cu=b;D.bu=c;D.eF=!0}}function kk(a){a.preventDefault();return!1}ik.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null===a||this.isBeyondDragSize()||!a.U.right?!1:null!==this.Sm&&a.U.Mj||null!==this.findObjectWithContextMenu()?!0:!1};ik.prototype.doStart=function(){var a=this.g;null!==a&&this.LD.set(a.Lc.ga)};
ik.prototype.doStop=function(){this.hideContextMenu();this.Cx=null};ik.prototype.findObjectWithContextMenu=function(a){void 0===a&&(a=null);var b=this.g;if(null===b)return null;var c=b.U,d=null;a instanceof E||(d=a instanceof P?a:b.Ie(c.ga,null,function(a){return!a.layer.Wc}));if(null!==d){for(a=d;null!==a;){if(null!==a.contextMenu)return a;a=a.R}if(null!==this.Sm&&b.U.Mj)return d.Y}else if(null!==b.contextMenu)return b;return null};ik.prototype.doActivate=function(){};
ik.prototype.doMouseDown=function(){Zg.prototype.doMouseDown.call(this);null!==this.g&&this.g.ob.pf.contains(this)&&lk(this)};ik.prototype.doMouseUp=function(){lk(this)};function lk(a){var b=a.g;if(null!==b)if(a.wa){var c=a.km;if(null!==c){if(!(c instanceof gk)){var d=b.Ie(b.U.ga,null,null);null!==d&&d.ym(c)&&a.standardMouseClick(null,null)}a.stopTool();a.canStart()&&(b.hb=a,a.doMouseUp())}}else a.canStart()&&(Yh(a,!0),a.wa||a.stopTool())}
function Yh(a,b,c){void 0===c&&(c=null);b&&a.standardMouseSelect();if(!a.standardMouseClick())if(a.wa=!0,b=a.Sm,null===c&&(c=a.findObjectWithContextMenu()),null!==c){var d=c.contextMenu;null!==d?(a.Cx=c instanceof P?c:null,a.showContextMenu(d,a.Cx)):null!==b&&a.showContextMenu(b,a.Cx)}else null!==b&&a.showContextMenu(b,null)}ik.prototype.doMouseMove=function(){this.wa&&null!==this.g&&this.g.ob.doMouseMove()};
ik.prototype.showContextMenu=function(a,b){!v||a instanceof da||a instanceof gk||D.k("showContextMenu:contextMenu must be an Adornment or HTMLInfo.");null!==b&&D.l(b,P,ik,"showContextMenu:obj");var c=this.g;if(null!==c){a!==this.km&&this.hideContextMenu();if(a instanceof da){a.Mf="Tool";a.el=!1;a.scale=1/c.scale;a.Gd=this.name;null!==a.placeholder&&(a.placeholder.scale=c.scale);c.add(a);if(null!==b){var c=null,d=b.rm();null!==d&&(c=d.data);a.Bb=b;a.data=c}else a.data=c.da;a.Re();this.positionContextMenu(a,
b)}else a instanceof gk&&a.show(b,c,this);this.km=a}};ik.prototype.positionContextMenu=function(a){if(null===a.placeholder){var b=this.g;if(null!==b){var c=b.U.ga.copy(),d=a.Fa,e=b.vb;b.U.Mj&&(c.x-=d.width);c.x+d.width>e.right&&(c.x-=d.width+5);c.x<e.x&&(c.x=e.x);c.y+d.height>e.bottom&&(c.y-=d.height+5);c.y<e.y&&(c.y=e.y);a.position=c}}};
ik.prototype.hideContextMenu=function(){var a=this.g;if(null!==a){var b=this.km;null!==b&&(b instanceof da?(a.remove(b),null!==this.oz&&this.oz.Tj(b.Gd),b.data=null,b.Bb=null):b instanceof gk&&(null!==b.Xn?b.Xn(a,this):null!==b.lB&&(b.lB.style.display="none")),this.km=null,this.standardMouseOver())}};
function mk(a){if(null===a.g)return null;a=new K(hk);a.add(new hk("Copy",function(a){a.yb.copySelection()},function(a){return a.yb.canCopySelection()}));a.add(new hk("Cut",function(a){a.yb.cutSelection()},function(a){return a.yb.canCutSelection()}));a.add(new hk("Delete",function(a){a.yb.deleteSelection()},function(a){return a.yb.canDeleteSelection()}));a.add(new hk("Paste",function(a){a.yb.pasteSelection(a.U.ga)},function(a){return a.yb.canPasteSelection()}));a.add(new hk("Select All",function(a){a.yb.selectAll()},
function(a){return a.yb.canSelectAll()}));a.add(new hk("Undo",function(a){a.yb.undo()},function(a){return a.yb.canUndo()}));a.add(new hk("Redo",function(a){a.yb.redo()},function(a){return a.yb.canRedo()}));a.add(new hk("Scroll To Part",function(a){a.yb.scrollToPart()},function(a){return a.yb.canScrollToPart()}));a.add(new hk("Zoom To Fit",function(a){a.yb.zoomToFit()},function(a){return a.yb.canZoomToFit()}));a.add(new hk("Reset Zoom",function(a){a.yb.resetZoom()},function(a){return a.yb.canResetZoom()}));
a.add(new hk("Group Selection",function(a){a.yb.groupSelection()},function(a){return a.yb.canGroupSelection()}));a.add(new hk("Ungroup Selection",function(a){a.yb.ungroupSelection()},function(a){return a.yb.canUngroupSelection()}));a.add(new hk("Edit Text",function(a){a.yb.editTextBlock()},function(a){return a.yb.canEditTextBlock()}));return a}
ik.prototype.showDefaultContextMenu=function(){var a=this.g;if(null!==a){null===this.qz&&(this.qz=mk(this));D.cu.innerHTML="";D.bu.addEventListener("click",this.nA,!1);var b=this,c=D.createElement("ul");c.className="goCXul";D.cu.appendChild(c);c.innerHTML="";for(var d=this.qz.j;d.next();){var e=d.value,g=e.visible;if("function"===typeof e.YE&&("function"!==typeof g||g(a))){g=D.createElement("li");g.className="goCXli";var h=D.createElement("a");h.className="goCXa";h.href="#";h.xH=e.YE;h.addEventListener("click",
function(c){this.xH(a);b.stopTool();c.preventDefault();return!1},!1);h.textContent=e.text;g.appendChild(h);c.appendChild(g)}}D.cu.style.display="block";D.bu.style.display="block"}};ik.prototype.hideDefaultContextMenu=function(){null!==this.km&&this.km===this.Sm&&(D.cu.style.display="none",D.bu.style.display="none",D.bu.removeEventListener("click",this.nA,!1),this.km=null)};
D.defineProperty(ik,{km:"currentContextMenu"},function(){return this.JC},function(a){!v||null===a||a instanceof da||a instanceof gk||D.k("ContextMenuTool.currentContextMenu must be an Adornment or HTMLInfo.");this.JC=a;this.oz=a instanceof da?a.Hf:null});D.defineProperty(ik,{CL:"defaultTouchContextMenu"},function(){return this.Sm},function(a){!v||null===a||a instanceof da||a instanceof gk||D.k("ContextMenuTool.defaultTouchContextMenu must be an Adornment or HTMLInfo.");this.Sm=a});
D.defineProperty(ik,{Cx:"currentObject"},function(){return this.MC},function(a){null!==a&&D.l(a,P,ik,"currentObject");this.MC=a});D.w(ik,{fM:"mouseDownPoint"},function(){return this.LD});function nk(){Zg.call(this);0<arguments.length&&D.xd(nk);this.name="DragSelecting";this.Wo=175;this.yD=!1;var a=new F;a.Mf="Tool";a.el=!1;var b=new z;b.name="SHAPE";b.Jb="Rectangle";b.fill=null;b.stroke="magenta";a.add(b);this.Om=a}D.Sa(nk,Zg);D.ka("DragSelectingTool",nk);
nk.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||!a.If)return!1;var b=a.U;return!b.left||a.hb!==this&&(!this.isBeyondDragSize()||b.timestamp-a.Lc.timestamp<this.fF||null!==a.ku(b.ga,!0))?!1:!0};nk.prototype.doActivate=function(){var a=this.g;null!==a&&(this.wa=!0,a.nf=!0,a.lb=!0,a.add(this.Lh),this.doMouseMove())};nk.prototype.doDeactivate=function(){var a=this.g;null!==a&&(Di(a),a.remove(this.Lh),a.lb=!1,this.wa=a.nf=!1)};
nk.prototype.doMouseMove=function(){var a=this.g;if(null!==a&&this.wa&&null!==this.Lh){var b=this.computeBoxBounds(),c=this.Lh.Hd("SHAPE");null===c&&(c=this.Lh.Xd());c.Ea=b.size;this.Lh.position=b.position;(a.Ae||a.Be)&&a.JA(a.U.se)}};nk.prototype.doMouseUp=function(){if(this.wa){var a=this.g;a.remove(this.Lh);try{a.mc="wait",this.selectInRect(this.computeBoxBounds())}finally{a.mc=""}}this.stopTool()};
nk.prototype.computeBoxBounds=function(){var a=this.g;return null===a?new C(0,0,0,0):new C(a.Lc.ga,a.U.ga)};
nk.prototype.selectInRect=function(a){var b=this.g;if(null!==b){var c=b.U;b.Ja("ChangingSelection");a=b.Sk(a,null,function(a){return a instanceof F?a.canSelect():!1},this.qJ);if(D.Zk?c.Nu:c.control)if(c.shift)for(a=a.j;a.next();)c=a.value,c.ib&&(c.ib=!1);else for(a=a.j;a.next();)c=a.value,c.ib=!c.ib;else{if(!c.shift){for(var c=new K(F),d=b.selection.j;d.next();){var e=d.value;a.contains(e)||c.add(e)}for(c=c.j;c.next();)c.value.ib=!1}for(a=a.j;a.next();)c=a.value,c.ib||(c.ib=!0)}b.Ja("ChangedSelection")}};
D.defineProperty(nk,{fF:"delay"},function(){return this.Wo},function(a){D.h(a,"number",nk,"delay");this.Wo=a});D.defineProperty(nk,{qJ:"isPartialInclusion"},function(){return this.yD},function(a){D.h(a,"boolean",nk,"isPartialInclusion");this.yD=a});D.defineProperty(nk,{Lh:"box"},function(){return this.Om},function(a){null!==a&&D.l(a,F,nk,"box");this.Om=a});
function ok(){Zg.call(this);0<arguments.length&&D.xd(ok);this.name="Panning";this.Zz=new N;this.bk=!1;var a=this;this.kE=function(){window.document.removeEventListener("scroll",a.kE,!1);a.stopTool()}}D.Sa(ok,Zg);D.ka("PanningTool",ok);ok.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;return null===a||!a.Ae&&!a.Be||!a.U.left||a.hb!==this&&!this.isBeyondDragSize()?!1:!0};
ok.prototype.doActivate=function(){var a=this.g;null!==a&&(this.bk?(a.U.bubbles=!0,window.document.addEventListener("scroll",this.kE,!1)):(a.mc="move",a.nf=!0,this.Zz=a.position.copy()),this.wa=!0)};ok.prototype.doDeactivate=function(){var a=this.g;null!==a&&(a.mc="",this.wa=a.nf=!1)};ok.prototype.doCancel=function(){var a=this.g;null!==a&&(a.position=this.Zz,a.nf=!1);this.stopTool()};ok.prototype.doMouseMove=function(){this.move()};ok.prototype.doMouseUp=function(){this.move();this.stopTool()};
ok.prototype.move=function(){var a=this.g;if(this.wa&&a)if(this.bk)a.U.bubbles=!0;else{var b=a.position,c=a.Lc.ga,d=a.U.ga,e=b.x+c.x-d.x,c=b.y+c.y-d.y;a.Ae||(e=b.x);a.Be||(c=b.y);a.position=new N(e,c)}};D.defineProperty(ok,{bubbles:"bubbles"},function(){return this.bk},function(a){D.h(a,"boolean",ok,"bubbles");this.bk=a});D.w(ok,{nM:"originalPosition"},function(){return this.Zz});
function pk(){0<arguments.length&&D.xd(pk);Zg.call(this);this.name="TextEditing";this.lA=this.Ek=null;this.jE=qk;this.Yl=null;this.Za=rk;this.Ss=null;this.HD=1;this.dE=!0;var a=new gk;this.OC=null;this.WC=a;this.rz=null;sk(this,a)}D.ka("TextEditingTool",pk);D.Sa(pk,Zg);var tk;pk.LostFocus=tk=D.s(pk,"LostFocus",0);var uk;pk.MouseDown=uk=D.s(pk,"MouseDown",1);var vk;pk.Tab=vk=D.s(pk,"Tab",2);var wk;pk.Enter=wk=D.s(pk,"Enter",3);pk.SingleClick=D.s(pk,"SingleClick",0);var qk;
pk.SingleClickSelected=qk=D.s(pk,"SingleClickSelected",1);var xk;pk.DoubleClick=xk=D.s(pk,"DoubleClick",2);var rk;pk.StateNone=rk=D.s(pk,"StateNone",0);var yk;pk.StateActive=yk=D.s(pk,"StateActive",1);var zk;pk.StateEditing=zk=D.s(pk,"StateEditing",2);var Ak;pk.StateValidating=Ak=D.s(pk,"StateValidating",3);var Bk;pk.StateInvalid=Bk=D.s(pk,"StateInvalid",4);var Ck;pk.StateValidated=Ck=D.s(pk,"StateValidated",5);
function sk(a,b){var c=D.createElement("textarea");a.rz=c;c.addEventListener("input",function(){if(null!==a.lh){var b=a.HJ(this.value),c=this.uK;this.style.width=20+b.Fa.width*c+"px";this.style.height=10+b.Fa.height*c+"px";this.rows=b.jB}},!1);c.addEventListener("keydown",function(b){if(null!==a.lh){var c=b.which;13===c?(!1===a.lh.$x&&b.preventDefault(),a.acceptText(wk)):9===c?(a.acceptText(vk),b.preventDefault()):27===c&&(a.doCancel(),null!==a.g&&a.g.doFocus())}},!1);c.addEventListener("focus",function(){Dk(a)},
!1);c.addEventListener("blur",function(){Ek(a)},!1);b.hC=function(){return c.value};b.lB=c;b.show=function(a,b,g){if(g.state===Bk)c.style.border="3px solid red",c.focus();else{var h=a.eb(mc),k=b.position,l=b.scale,m=a.Fj()*l;m<g.pG&&(m=g.pG);var n=a.Ga.width*m+6,p=a.Ga.height*m+2,q=(h.x-k.x)*l,h=(h.y-k.y)*l;c.value=a.text;b.Dj.style.font=a.font;c.style.cssText="position: absolute;z-index: 100;font: inherit;fontSize: "+100*m+"%;lineHeight: normal;width: "+n+"px;height: "+p+"px;left: "+((q-n/2|0)-1)+
"px;top: "+((h-p/2|0)-1)+"px;text-align: "+a.textAlign+";margin: 0;padding: 1px;border: 0;outline: none;white-space: pre-wrap;overflow: hidden;";c.uK=m;b.Dj.appendChild(c);c.focus();g.Iy&&(c.select(),c.setSelectionRange(0,9999))}};b.Xn=function(a){a.Dj.removeChild(c)}}D.defineProperty(pk,{lh:"textBlock"},function(){return this.lA},function(a){null!==a&&D.l(a,pa,pk,"textBlock");this.lA=a});D.defineProperty(pk,{Bj:"currentTextEditor"},function(){return this.OC},function(a){this.OC=a});
D.defineProperty(pk,{sI:"defaultTextEditor"},function(){return this.WC},function(a){!v||a instanceof HTMLElement||a instanceof gk||D.k("TextEditingTool.defaultTextEditor must be an Element or HTMLInfo.");this.WC=a});D.defineProperty(pk,{cH:"starting"},function(){return this.jE},function(a){D.Da(a,pk,pk,"starting");this.jE=a});
pk.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||a.rb||!a.U.left||this.isBeyondDragSize())return!1;var b=a.Ie(a.U.ga);if(!(null!==b&&b instanceof pa&&b.MA&&b.Y.canEdit()))return!1;b=b.Y;return null===b||this.cH===qk&&!b.ib||this.cH===xk&&2>a.U.Pe?!1:!0};pk.prototype.doStart=function(){this.wa||null===this.lh||this.doActivate()};
pk.prototype.doActivate=function(){if(!this.wa){var a=this.g;if(null!==a){var b=this.lh;null===b&&(b=a.Ie(a.U.ga));if(null!==b&&b instanceof pa&&(this.lh=b,null!==b.Y)){this.wa=!0;this.Za=yk;var c=this.sI;null!==b.gH&&(c=b.gH);this.Ek=this.lh.copy();var d=new C(this.lh.eb(ec),this.lh.eb(vc));a.QG(d);if(c instanceof gk)c.show(b,a,this);else{c.style.position="absolute";c.style.zIndex="100";c.textEditingTool=this;if("function"===typeof c.onActivate)c.onActivate();a.Dj.appendChild(c);"function"===typeof c.focus&&
c.focus();"function"===typeof c.select&&this.Iy&&(c.select(),c.setSelectionRange(0,9999))}this.Bj=c}}}};pk.prototype.doCancel=function(){null!==this.Ss&&this.Bj instanceof HTMLElement&&(this.Bj.style.border=this.Ss,this.Ss=null);this.stopTool()};pk.prototype.doMouseUp=function(){!this.wa&&this.canStart()&&this.doActivate()};pk.prototype.doMouseDown=function(){this.wa&&this.acceptText(uk)};
pk.prototype.acceptText=function(a){switch(a){case uk:if(this.Za===Ck)this.Bj instanceof HTMLElement&&this.Bj.focus();else if(this.Za===yk||this.Za===Bk||this.Za===zk)this.Za=Ak,Fk(this);break;case tk:case wk:case vk:if(wk===a&&!0===this.lA.$x)break;if(this.Za===yk||this.Za===Bk||this.Za===zk)this.Za=Ak,Fk(this)}};
function Fk(a){var b=a.lh,c=a.g,d=a.Bj;if(null!==b&&null!==d){var e=b.text,g="";d instanceof gk?null!==d.hC&&(g=d.hC()):(g=d.value,g="function"===typeof g?g():g);a.isValidText(b,e,g)?(a.Nb(a.name),a.Za=Ck,a.Sf=a.name,b.text=g,null!==b.fH&&b.fH(b,e,g),null!==c&&c.Ja("TextEdited",b,e),a.gl(),a.stopTool(),null!==c&&c.doFocus()):(a.Za=Bk,null!==b.OA&&b.OA(a,e,g),d instanceof gk?d.show(b,c,a):(null===a.Ss&&(a.Ss=d.style.border,d.style.border="3px solid red"),"function"===typeof d.focus&&d.focus()))}}
pk.prototype.doDeactivate=function(){var a=this.g;if(null!==a){this.Za=rk;this.lh=null;if(null!==this.Bj){var b=this.Bj;if(b instanceof gk)b.Xn(a,this);else{if("function"===typeof b.onDeactivate)b.onDeactivate();null!==b&&a.Dj.removeChild(b)}}this.wa=!1}};pk.prototype.doFocus=function(){v&&D.Pn("TextEditingTool.doFocus","2.0");Dk(this)};pk.prototype.doBlur=function(){v&&D.Pn("TextEditingTool.doBlur","2.0");Ek(this)};
function Dk(a){if(null!==a.Bj){var b=a.rz;a.Za===yk&&(a.Za=zk);"function"===typeof b.select&&a.Iy&&(b.select(),b.setSelectionRange(0,9999))}}function Ek(a){if(null!==a.Bj){var b=a.rz;"function"===typeof b.focus&&b.focus();"function"===typeof b.select&&a.Iy&&(b.select(),b.setSelectionRange(0,9999))}}pk.prototype.isValidText=function(a,b,c){D.l(a,pa,pk,"isValidText:textblock");var d=this.XB;if(null!==d&&!d(a,b,c))return!1;d=a.XB;return null===d||d(a,b,c)?!0:!1};
D.defineProperty(pk,{XB:"textValidation"},function(){return this.Yl},function(a){null!==a&&D.h(a,"function",pk,"textValidation");this.Yl=a});D.defineProperty(pk,{pG:"minimumEditorScale"},function(){return this.HD},function(a){null!==a&&D.h(a,"number",pk,"minimumEditorScale");this.HD=a});D.defineProperty(pk,{Iy:"selectsTextOnActivate"},function(){return this.dE},function(a){null!==a&&D.h(a,"boolean",pk,"selectsTextOnActivate");this.dE=a});
D.defineProperty(pk,{state:"state"},function(){return this.Za},function(a){this.Za!==a&&(D.Da(a,pk,pk,"starting"),this.Za=a)});pk.prototype.measureTemporaryTextBlock=pk.prototype.HJ=function(a){var b=this.Ek;b.text=a;Gk(b,this.lh.rp,Infinity);return b};function Ph(){Zg.call(this);this.name="ToolManager";this.CH=new K(Zg);this.DH=new K(Zg);this.EH=new K(Zg);this.lD=this.mD=850;this.aD=(new Ca(2,2)).Oa();this.wE=5E3;this.MD=ai;this.jD=Hk;this.Mv=this.PC=null;this.zn=-1}D.Sa(Ph,Zg);
D.ka("ToolManager",Ph);var ai;Ph.WheelScroll=ai=D.s(Ph,"WheelScroll",0);var $h;Ph.WheelZoom=$h=D.s(Ph,"WheelZoom",1);Ph.WheelNone=D.s(Ph,"WheelNone",2);var Hk;Ph.GestureZoom=Hk=D.s(Ph,"GestureZoom",3);var Uk;Ph.GestureCancel=Uk=D.s(Ph,"GestureCancel",4);var Vk;Ph.GestureNone=Vk=D.s(Ph,"GestureNone",5);D.defineProperty(Ph,{Qu:"mouseWheelBehavior"},function(){return this.MD},function(a){D.Da(a,Ph,Ph,"mouseWheelBehavior");this.MD=a});
D.defineProperty(Ph,{oq:"gestureBehavior"},function(){return this.jD},function(a){D.Da(a,Ph,Ph,"gestureBehavior");this.jD=a});Ph.prototype.initializeStandardTools=function(){this.OH=new fk;this.BG=new fi;this.zJ=new Oj;this.NG=new Vj;this.fK=new ck;this.lG=new ra;this.Fe=new Uh;this.wI=new nk;this.UJ=new ok;this.CA=new ik;this.WB=new pk;this.bI=new ta;this.cI=new ek};
Ph.prototype.updateAdornments=function(a){var b=this.lm;if(b instanceof da&&this.Mv===a){var c=b.Bb;(null!==a?c.Y===a:null===c)?this.showToolTip(b,c):this.hideToolTip()}};
Ph.prototype.doMouseDown=function(){var a=this.g;if(null!==a){var b=a.U;b.Mj&&this.oq===Uk&&(b.bubbles=!1);if(b.Eu){this.cancelWaitAfter();if(this.oq===Vk){b.bubbles=!0;return}if(this.oq===Uk)return;if(a.hb.canStartMultiTouch()){a.hb.standardPinchZoomStart();return}}var c=a.la;c.xA&&0!==c.Ki&&D.trace("WARNING: In ToolManager.doMouseDown: UndoManager.transactionLevel is not zero");for(var c=this.pf.length,d=0;d<c;d++){var e=this.pf.ea(d);null===e.g&&e.$c(this.g);if(e.canStart()){a.doFocus();a.hb=e;
a.hb===e&&(e.wa||e.doActivate(),e.doMouseDown());return}}1===a.U.button&&(this.Qu===ai?this.Qu=$h:this.Qu===$h&&(this.Qu=ai));this.doActivate();this.standardWaitAfter(this.QF,b)}};
Ph.prototype.doMouseMove=function(){var a=this.g;if(null!==a){var b=a.U;if(b.Eu){if(this.oq===Vk){b.bubbles=!0;return}if(this.oq===Uk)return;if(a.hb.canStartMultiTouch()){a.hb.standardPinchZoomMove();return}}if(this.wa)for(var c=this.mg.length,d=0;d<c;d++){var e=this.mg.ea(d);null===e.g&&e.$c(this.g);if(e.canStart()){a.doFocus();a.hb=e;a.hb===e&&(e.wa||e.doActivate(),e.doMouseMove());return}}Wk(this,a);null===b.event||"mousemove"!==b.event.type&&b.event.cancelable||(b.bubbles=!0)}};
function Wk(a,b){a.standardMouseOver();a.isBeyondDragSize()&&a.standardWaitAfter(a.wa?a.QF:a.aJ,b.U)}Ph.prototype.doCurrentObjectChanged=function(a,b){var c=this.lm;null===c||null!==b&&c instanceof da&&(b===c||b.ym(c))||this.hideToolTip()};Ph.prototype.doWaitAfter=function(a){var b=this.g;null!==b&&b.Mb&&(this.doMouseHover(),this.wa||this.doToolTip(),a.Mj&&!b.U.Mc&&(a=a.copy(),a.button=2,a.buttons=2,b.U=a,b.Ft=!0,b.doMouseUp()))};
Ph.prototype.doMouseHover=function(){var a=this.g;if(null!==a){var b=a.U;null===b.Ze&&(b.Ze=a.Ie(b.ga,null,null));var c=b.Ze;if(null!==c)for(b.Mc=!1;null!==c;){a=this.wa?c.qB:c.rB;if(null!==a&&(a(b,c),b.Mc))break;c=c.R}else c=this.wa?a.qB:a.rB,null!==c&&c(b)}};
Ph.prototype.doToolTip=function(){var a=this.g;if(null!==a){var b=a.U;null===b.Ze&&(b.Ze=a.Ie(b.ga,null,null));b=b.Ze;if(null!==b){if(a=this.lm,!(a instanceof da)||b!==a&&!b.ym(a)){for(;null!==b;){a=b.ZB;if(null!==a){this.showToolTip(a,b);return}b=b.R}this.hideToolTip()}}else a=a.ZB,null!==a?this.showToolTip(a,null):this.hideToolTip()}};
Ph.prototype.showToolTip=function(a,b){!v||a instanceof da||a instanceof gk||D.k("showToolTip:tooltip must be an Adornment or HTMLInfo.");null!==b&&D.l(b,P,Ph,"showToolTip:obj");var c=this.g;if(null!==c){a!==this.lm&&this.hideToolTip();if(a instanceof da){a.Mf="Tool";a.el=!1;a.scale=1/c.scale;a.Gd="ToolTip";null!==a.placeholder&&(a.placeholder.scale=c.scale);c.add(a);if(null!==b){var c=null,d=b.rm();null!==d&&(c=d.data);a.Bb=b;a.data=c}else a.data=c.da;a.Re();this.positionToolTip(a,b)}else a instanceof
gk&&a!==this.lm&&a.show(b,c,this);this.lm=a;-1!==this.zn&&(D.clearTimeout(this.zn),this.zn=-1);c=this.zK;if(0<c&&Infinity!==c){var e=this;this.zn=D.setTimeout(function(){e.hideToolTip()},c)}}};Ph.prototype.positionToolTip=function(a){if(null===a.placeholder){var b=this.g;if(null!==b){var c=b.U.ga.copy(),d=a.Fa,e=b.vb;b.U.Mj&&(c.x-=d.width);c.x+d.width>e.right&&(c.x-=d.width+5);c.x<e.x&&(c.x=e.x);c.y=c.y+20+d.height>e.bottom?c.y-(d.height+5):c.y+20;c.y<e.y&&(c.y=e.y);a.position=c}}};
Ph.prototype.hideToolTip=function(){-1!==this.zn&&(D.clearTimeout(this.zn),this.zn=-1);var a=this.g;if(null!==a){var b=this.lm;null!==b&&(b instanceof da?(a.remove(b),null!==this.Mv&&this.Mv.Tj(b.Gd),b.data=null,b.Bb=null):b instanceof gk&&null!==b.Xn&&b.Xn(a,this),this.lm=null)}};
D.defineProperty(Ph,{lm:"currentToolTip"},function(){return this.PC},function(a){!v||null===a||a instanceof da||a instanceof gk||D.k("ToolManager.currentToolTip must be an Adornment or HTMLInfo.");this.PC=a;this.Mv=null!==a&&a instanceof da?a.Hf:null});
Ph.prototype.doMouseUp=function(){this.cancelWaitAfter();var a=this.g;if(null!==a){if(this.wa)for(var b=this.ng.length,c=0;c<b;c++){var d=this.ng.ea(c);null===d.g&&d.$c(this.g);if(d.canStart()){a.doFocus();a.hb=d;a.hb===d&&(d.wa||d.doActivate(),d.doMouseUp());return}}a.doFocus();this.doDeactivate()}};Ph.prototype.doMouseWheel=function(){this.standardMouseWheel()};Ph.prototype.doKeyDown=function(){var a=this.g;null!==a&&a.yb.doKeyDown()};Ph.prototype.doKeyUp=function(){var a=this.g;null!==a&&a.yb.doKeyUp()};
Ph.prototype.doCancel=function(){null!==di&&di.doCancel();Zg.prototype.doCancel.call(this)};Ph.prototype.findTool=function(a){D.h(a,"string",Ph,"findTool:name");for(var b=this.pf.length,c=0;c<b;c++){var d=this.pf.ea(c);if(d.name===a)return d}b=this.mg.length;for(c=0;c<b;c++)if(d=this.mg.ea(c),d.name===a)return d;b=this.ng.length;for(c=0;c<b;c++)if(d=this.ng.ea(c),d.name===a)return d;return null};
Ph.prototype.replaceTool=function(a,b){D.h(a,"string",Ph,"replaceTool:name");null!==b&&(D.l(b,Zg,Ph,"replaceTool:newtool"),b.g&&b.g!==this.g&&D.k("Cannot share tools between Diagrams: "+b.toString()),b.$c(this.g));for(var c=this.pf.length,d=0;d<c;d++){var e=this.pf.ea(d);if(e.name===a)return null!==b?this.pf.ug(d,b):this.pf.od(d),e}c=this.mg.length;for(d=0;d<c;d++)if(e=this.mg.ea(d),e.name===a)return null!==b?this.mg.ug(d,b):this.mg.od(d),e;c=this.ng.length;for(d=0;d<c;d++)if(e=this.ng.ea(d),e.name===
a)return null!==b?this.ng.ug(d,b):this.ng.od(d),e;return null};function Xk(a,b,c,d){D.h(b,"string",Ph,"replaceStandardTool:name");D.l(d,K,Ph,"replaceStandardTool:list");null!==c&&(D.l(c,Zg,Ph,"replaceStandardTool:newtool"),c.g&&c.g!==a.g&&D.k("Cannot share tools between Diagrams: "+c.toString()),c.name=b,c.$c(a.g));a.findTool(b)?a.replaceTool(b,c):null!==c&&d.add(c)}D.w(Ph,{pf:"mouseDownTools"},function(){return this.CH});D.w(Ph,{mg:"mouseMoveTools"},function(){return this.DH});
D.w(Ph,{ng:"mouseUpTools"},function(){return this.EH});D.defineProperty(Ph,{aJ:"hoverDelay"},function(){return this.mD},function(a){D.h(a,"number",Ph,"hoverDelay");this.mD=a});D.defineProperty(Ph,{QF:"holdDelay"},function(){return this.lD},function(a){D.h(a,"number",Ph,"holdDelay");this.lD=a});D.defineProperty(Ph,{xI:"dragSize"},function(){return this.aD},function(a){D.l(a,Ca,Ph,"dragSize");this.aD=a.V()});
D.defineProperty(Ph,{zK:"toolTipDuration"},function(){return this.wE},function(a){D.h(a,"number",Ph,"toolTipDuration");this.wE=a});D.defineProperty(Ph,{OH:"actionTool"},function(){return this.findTool("Action")},function(a){Xk(this,"Action",a,this.pf)});D.defineProperty(Ph,{BG:"relinkingTool"},function(){return this.findTool("Relinking")},function(a){Xk(this,"Relinking",a,this.pf)});
D.defineProperty(Ph,{zJ:"linkReshapingTool"},function(){return this.findTool("LinkReshaping")},function(a){Xk(this,"LinkReshaping",a,this.pf)});D.defineProperty(Ph,{NG:"resizingTool"},function(){return this.findTool("Resizing")},function(a){Xk(this,"Resizing",a,this.pf)});D.defineProperty(Ph,{fK:"rotatingTool"},function(){return this.findTool("Rotating")},function(a){Xk(this,"Rotating",a,this.pf)});
D.defineProperty(Ph,{lG:"linkingTool"},function(){return this.findTool("Linking")},function(a){Xk(this,"Linking",a,this.mg)});D.defineProperty(Ph,{Fe:"draggingTool"},function(){return this.findTool("Dragging")},function(a){Xk(this,"Dragging",a,this.mg)});D.defineProperty(Ph,{wI:"dragSelectingTool"},function(){return this.findTool("DragSelecting")},function(a){Xk(this,"DragSelecting",a,this.mg)});
D.defineProperty(Ph,{UJ:"panningTool"},function(){return this.findTool("Panning")},function(a){Xk(this,"Panning",a,this.mg)});D.defineProperty(Ph,{CA:"contextMenuTool"},function(){return this.findTool("ContextMenu")},function(a){Xk(this,"ContextMenu",a,this.ng)});D.defineProperty(Ph,{WB:"textEditingTool"},function(){return this.findTool("TextEditing")},function(a){Xk(this,"TextEditing",a,this.ng)});
D.defineProperty(Ph,{bI:"clickCreatingTool"},function(){return this.findTool("ClickCreating")},function(a){Xk(this,"ClickCreating",a,this.ng)});D.defineProperty(Ph,{cI:"clickSelectingTool"},function(){return this.findTool("ClickSelecting")},function(a){Xk(this,"ClickSelecting",a,this.ng)});
function kh(){this.QC=bl;this.Fr=this.Gr=this.ia=null;this.Mm=this.Hr=this.Ir=0;this.Ho=this.xc=this.np=this.qk=!1;this.Gl=this.qf=!0;this.Kv=this.Jv=this.LC=null;this.KC=0;this.Lv=null;this.zv=new L("string");this.Az=600;this.FH=new N(0,0);this.zC=this.yC=this.AE=!1;this.kn=new oa(P,cl)}D.ka("AnimationManager",kh);kh.prototype.$c=function(a){this.ia=a};function bl(a,b,c,d){a/=d/2;return 1>a?c/2*a*a+b:-c/2*(--a*(a-2)-1)+b}D.w(kh,{fL:"animationReasons"},function(){return this.zv});
kh.prototype.canStart=function(){return!0};kh.prototype.prepareAutomaticAnimation=kh.prototype.po=function(a){this.qf&&(this.Gl||this.ia.$n)&&(this.zv.add(a),this.canStart(a)&&(this.qk&&this.Yh(),this.xc=!0))};function dl(a){if(a.qf&&(a.zv.clear(),a.xc))if(!a.Ho)a.xc=!1;else if(0===a.Mm){var b=+new Date;a.Mm=b;requestAnimationFrame(function(){if(!1!==a.xc&&!a.qk&&a.Mm===b){var c=a.ia;c.Gj("temporaryPixelRatio")&&(c.Dk=1);el(c);a.xc=!1;c.Ja("AnimationStarting");fl(a,b)}})}}
function gl(a,b,c,d,e,g){if(a.xc&&(v&&D.l(b,P,kh,"addPropToAnimation:obj"),!("position"===c&&d.O(e)||b instanceof F&&!b.UF))){var h=a.kn;if(h.contains(b)){var h=h.oa(b),k=h.start,l=h.end;void 0===k[c]&&(k[c]=hl(d));h.Bx&&void 0!==l[c]?h.hu[c]=hl(e):(g||(h.hu[c]=hl(e)),l[c]=hl(e));g&&0===c.indexOf("position:")&&b instanceof F&&(h.hu.location=hl(b.location))}else k=new ma,l=new ma,k[c]=hl(d),l[c]=hl(e),d=l,e=k.position,e instanceof N&&!e.F()&&a.zv.contains("Expand SubGraph")&&e.assign(d.position),k=
new cl(k,l,g),g&&0===c.indexOf("position:")&&b instanceof F&&(k.hu.location=hl(b.location)),h.add(b,k);a.Ho=!0}}function hl(a){return a instanceof N?a.copy():a instanceof Ca?a.copy():a}
function fl(a,b){var c;function d(){if(!1!==g.qk&&g.Mm===b){var a=+new Date,c=a>s?m:a-r;il(g);jl(g,e,q,h,c,m);g.Jv&&g.Jv();bj(e);kl(g);a>s?ll(g):requestAnimationFrame(d)}}void 0===c&&(c=new ma);var e=a.ia;if(null!==e){a.qk=!0;var g=a,h=c.KL||a.QC,k=c.iM||null,l=c.jM||null,m=c.duration||a.Az,n=a.FH;for(c=a.kn.j;c.next();){var p=c.value.start.position;p instanceof N&&(p.F()||p.assign(n))}a.LC=h;a.Jv=k;a.Kv=l;a.KC=m;a.Lv=a.kn;var q=a.Lv;for(c=q.j;c.next();)k=c.value.end,k["position:placeholder"]&&(l=
k["position:placeholder"],n=l.eb(ec),n.x+=l.padding.left,n.y+=l.padding.top,k["position:placeholder"]=n);il(a);jl(a,e,q,h,0,m);bj(a.ia);kl(a);var r=+new Date,s=r+m;g.Mm===b&&requestAnimationFrame(function(){d()})}}function il(a){if(!a.np){var b=a.ia;a.AE=b.lb;a.yC=b.Xe;a.zC=b.hv;b.lb=!0;b.Xe=!0;b.hv=!0;a.np=!0}}function kl(a){var b=a.ia;b.lb=a.AE;b.Xe=a.yC;b.hv=a.zC;a.np=!1}
function jl(a,b,c,d,e,g){for(c=c.j;c.next();){var h=c.key,k=c.value,l=k.start,k=k.end,m;for(m in k)if(("position"!==m||!k["position:placeholder"]&&!k["position:node"])&&void 0!==ml[m])ml[m](h,l[m],k[m],d,e,g)}d=b.eB;b.eB=!0;m=a.QC;0!==a.Ir&&0!==a.Hr&&(c=a.Ir,b.Db=m(e,c,a.Hr-c,g));null!==a.Gr&&null!==a.Fr&&(c=a.Gr,a=a.Fr,b.tb=new N(m(e,c.x,a.x-c.x,g),m(e,c.y,a.y-c.y,g)));b.eB=d}
kh.prototype.stopAnimation=kh.prototype.Yh=function(){!0===this.xc&&(this.xc=!1,this.Mm=0,this.Ho&&this.ia.Ve());this.qk&&this.qf&&ll(this)};
function ll(a){a.qk=!1;a.Ho=!1;il(a);for(var b=a.ia,c=a.LC,d=a.KC,e=a.Lv.j;e.next();){var g=e.key,h=e.value,k=h.start,l=h.end,m=h.hu,n;for(n in l)if(void 0!==ml[n]){var p=n;!h.Bx||"position:node"!==p&&"position:placeholder"!==p||(p="position");ml[p](g,k[n],void 0!==m[n]?m[n]:h.Bx?k[n]:l[n],c,d,d)}h.Bx&&void 0!==m.location&&g instanceof F&&(g.location=m.location);h.zy&&g instanceof F&&g.Ld(!1)}for(c=a.ia.links;c.next();)d=c.value,null!==d.Gp&&(d.points=d.Gp,d.Gp=null);b.ky.clear();b.Dk=null;b.Nc();
b.ra();b.lg();nl(b);kl(a);a.Kv&&a.Kv();a.Mm=0;a.Lv=null;a.Kv=null;a.Jv=null;a.Gr=null;a.Fr=null;a.Ir=0;a.Hr=0;a.kn=new oa(P,cl);b.Ja("AnimationFinished");b.Ve()}
function ol(a,b,c){var d=b.Z,e=c.Z,g=null;c instanceof I&&(g=c.placeholder);null!==g?(d=g.eb(ec),d.x+=g.padding.left,d.y+=g.padding.top,gl(a,b,"position",d,b.position,!1)):gl(a,b,"position",new N(e.x+e.width/2-d.width/2,e.y+e.height/2-d.height/2),b.position,!1);gl(a,b,"scale",.01,b.scale,!1);if(b instanceof I)for(b=b.Oc;b.next();)g=b.value,g instanceof G&&ol(a,g,c)}
function pl(a,b,c){if(b.isVisible()){var d=null;c instanceof I&&(d=c.placeholder);null!==d?gl(a,b,"position:placeholder",b.position,d,!0):gl(a,b,"position:node",b.position,c,!0);gl(a,b,"scale",b.scale,.01,!0);a.xc&&(d=a.kn,d.contains(b)&&(d.oa(b).zy=!0));if(b instanceof I)for(b=b.Oc;b.next();)d=b.value,d instanceof G&&pl(a,d,c)}}function ql(a,b,c){a.xc&&(null===a.Gr&&b.F()&&null===a.Fr&&(a.Gr=b.copy()),a.Fr=c.copy(),a.Ho=!0)}
function rl(a,b,c){a.xc&&a.ia.$n&&(0===a.Ir&&0===a.Hr&&(a.Ir=b),a.Hr=c,a.Ho=!0)}D.defineProperty(kh,{isEnabled:"isEnabled"},function(){return this.qf},function(a){D.h(a,"boolean",kh,"isEnabled");this.qf=a});D.defineProperty(kh,{duration:"duration"},function(){return this.Az},function(a){D.h(a,"number",kh,"duration");1>a&&D.ua(a,">= 1",kh,"duration");this.Az=a});D.w(kh,{yf:"isAnimating"},function(){return this.qk});D.w(kh,{rJ:"isTicking"},function(){return this.np});
D.defineProperty(kh,{bG:"isInitial"},function(){return this.Gl},function(a){D.h(a,"boolean",kh,"isInitial");this.Gl=a});function cl(a,b,c){this.start=a;this.end=b;this.hu=new ma;this.Bx=c;this.zy=!1}
var ml={opacity:function(a,b,c,d,e,g){a.opacity=d(e,b,c-b,g)},position:function(a,b,c,d,e,g){e!==g?a.My(d(e,b.x,c.x-b.x,g),d(e,b.y,c.y-b.y,g)):a.position=new N(d(e,b.x,c.x-b.x,g),d(e,b.y,c.y-b.y,g))},"position:node":function(a,b,c,d,e,g){var h=a.Z,k=c.Z;c=k.x+k.width/2-h.width/2;h=k.y+k.height/2-h.height/2;e!==g?a.My(d(e,b.x,c-b.x,g),d(e,b.y,h-b.y,g)):a.position=new N(d(e,b.x,c-b.x,g),d(e,b.y,h-b.y,g))},"position:placeholder":function(a,b,c,d,e,g){e!==g?a.My(d(e,b.x,c.x-b.x,g),d(e,b.y,c.y-b.y,g)):
a.position=new N(d(e,b.x,c.x-b.x,g),d(e,b.y,c.y-b.y,g))},scale:function(a,b,c,d,e,g){a.scale=d(e,b,c-b,g)},visible:function(a,b,c,d,e,g){a.visible=e!==g?b:c}};function Yg(){0<arguments.length&&D.xd(Yg);D.vc(this);this.ia=null;this.sb=new K(F);this.ac="";this.Hc=1;this.Qz=!1;this.Tl=this.qA=this.ql=this.pl=this.ol=this.nl=this.ll=this.ml=this.kl=this.sl=this.jl=this.rl=this.il=this.hl=!0;this.Nz=!1;this.Fw=[]}D.ka("Layer",Yg);Yg.prototype.$c=function(a){this.ia=a};
Yg.prototype.toString=function(a){void 0===a&&(a=0);var b='Layer "'+this.name+'"';if(0>=a)return b;for(var c=0,d=0,e=0,g=0,h=0,k=this.sb.j;k.next();){var l=k.value;l instanceof I?e++:l instanceof G?d++:l instanceof J?g++:l instanceof da?h++:c++}k="";0<c&&(k+=c+" Parts ");0<d&&(k+=d+" Nodes ");0<e&&(k+=e+" Groups ");0<g&&(k+=g+" Links ");0<h&&(k+=h+" Adornments ");if(1<a)for(a=this.sb.j;a.next();)c=a.value,k+="\n    "+c.toString(),d=c.data,null!==d&&D.Jd(d)&&(k+=" #"+D.Jd(d)),c instanceof G?k+=" "+
ia(d):c instanceof J&&(k+=" "+ia(c.aa)+" "+ia(c.ba));return b+" "+this.sb.count+": "+k};
Yg.prototype.findObjectAt=Yg.prototype.Ie=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);if(!1===this.Tl)return null;v&&!a.F()&&D.k("findObjectAt: Point must have a real value, not: "+a.toString());var d=!1;null!==this.g&&this.g.vb.Pa(a)&&(d=!0);for(var e=D.P(),g=this.sb.o,h=g.length;h--;){var k=g[h];if((!0!==d||!1!==sl(k))&&k.isVisible()&&(e.assign(a),kb(e,k.Jh),k=k.Ie(e,b,c),null!==k&&(null!==b&&(k=b(k)),null!==k&&(null===c||c(k)))))return D.A(e),k}D.A(e);return null};
Yg.prototype.findObjectsAt=Yg.prototype.ju=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof K||d instanceof L||(d=new L(P));if(!1===this.Tl)return d;v&&!a.F()&&D.k("findObjectsAt: Point must have a real value, not: "+a.toString());var e=!1;null!==this.g&&this.g.vb.Pa(a)&&(e=!0);for(var g=D.P(),h=this.sb.o,k=h.length;k--;){var l=h[k];if((!0!==e||!1!==sl(l))&&l.isVisible()){g.assign(a);kb(g,l.Jh);var m=l;l.ju(g,b,c,d)&&(null!==b&&(m=b(m)),null===m||null!==c&&!c(m)||(d instanceof
L&&d.add(m),d instanceof K&&d.add(m)))}}D.A(g);return d};
Yg.prototype.findObjectsIn=Yg.prototype.Sk=function(a,b,c,d,e){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof K||e instanceof L||(e=new L(P));if(!1===this.Tl)return e;v&&!a.F()&&D.k("findObjectsIn: Rect must have a real value, not: "+a.toString());var g=!1;null!==this.g&&this.g.vb.Pk(a)&&(g=!0);for(var h=this.sb.o,k=h.length;k--;){var l=h[k];if((!0!==g||!1!==sl(l))&&l.isVisible()){var m=l;l.Sk(a,b,c,d,e)&&(null!==b&&(m=b(m)),null===m||null!==c&&!c(m)||(e instanceof L&&e.add(m),
e instanceof K&&e.add(m)))}}return e};Yg.prototype.PA=function(a,b,c,d,e,g,h){if(!1===this.Tl)return e;for(var k=this.sb.o,l=k.length;l--;){var m=k[l];if((!0!==h||!1!==sl(m))&&g(m)&&m.isVisible()){var n=m;m.Sk(a,b,c,d,e)&&(null!==b&&(n=b(n)),null===n||null!==c&&!c(n)||(e instanceof L&&e.add(n),e instanceof K&&e.add(n)))}}return e};
Yg.prototype.findObjectsNear=Yg.prototype.Qn=function(a,b,c,d,e,g){void 0===c&&(c=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof K||e instanceof L)g=e;e=!0}g instanceof K||g instanceof L||(g=new L(P));if(!1===this.Tl)return g;v&&!a.F()&&D.k("findObjectsNear: Point must have a real value, not: "+a.toString());var h=!1;null!==this.g&&this.g.vb.Pa(a)&&(h=!0);for(var k=D.P(),l=D.P(),m=this.sb.o,n=m.length;n--;){var p=m[n];if((!0!==h||!1!==sl(p))&&p.isVisible()){k.assign(a);
kb(k,p.Jh);l.n(a.x+b,a.y);kb(l,p.Jh);var q=p;p.Qn(k,l,c,d,e,g)&&(null!==c&&(q=c(q)),null===q||null!==d&&!d(q)||(g instanceof L&&g.add(q),g instanceof K&&g.add(q)))}}D.A(k);D.A(l);return g};f=Yg.prototype;
f.Of=function(a,b){if(this.visible){var c;c=void 0===b?a.vb:b;for(var d=this.sb.o,e=d.length,g=0;g<e;g++){var h=d[g];h.DD=g;if(!(h instanceof J&&!1===h.Tf)){if(h instanceof da){var k=h;if(null!==k.Hf)continue}if(Ob(h.Z,c))for(h.Of(!0),tl(h),h=h.ox;h.next();)k=h.value,Gk(k,Infinity,Infinity),k.rc(),k.Of(!0);else h.Of(!1),null!==h.ox&&0<h.ox.count&&tl(h)}}}};
f.Ge=function(a,b,c){if(this.visible&&0!==this.Hc&&(void 0===c&&(c=!0),c||!this.Wc)){c=this.sb.o;var d=c.length;if(0!==d){1!==this.Hc&&(a.globalAlpha=this.Hc);var e=this.Fw;e.length=0;for(var g=b.scale,h=0;h<d;h++){var k=c[h];if(sl(k)){if(k instanceof J){var l=k;l.ic&&e.push(l);if(!1===l.Tf)continue}l=k.Z;1<l.width*g||1<l.height*g?k.Ge(a,b):ul(k,a)}}a.globalAlpha=1}}};
function vl(a,b,c,d){if(a.visible&&0!==a.Hc){1!==a.Hc&&(b.globalAlpha=a.Hc);var e=a.Fw;e.length=0;var g=c.scale;a=a.sb.o;for(var h=a.length,k=d.length,l=0;l<h;l++){var m=a[l];if(sl(m)){if(m instanceof J){var n=m;n.ic&&e.push(n);if(!1===n.Tf)continue}var n=wl(m,m.Z),p;a:{p=n;for(var q=d,r=k,s=2/g,u=4/g,t=0;t<r;t++){var y=q[t];if(0!==y.width&&0!==y.height&&p.SF(y.x-s,y.y-s,y.width+u,y.height+u)){p=!0;break a}}p=!1}p&&(1<n.width*g||1<n.height*g?m.Ge(b,c):ul(m,b))}}b.globalAlpha=1}}
f.i=function(a,b,c,d,e){var g=this.g;null!==g&&g.nd(fg,a,this,b,c,d,e)};f.rq=function(a,b,c){var d=this.sb;b.nw=this;if(a>=d.count)a=d.count;else if(d.ea(a)===b)return-1;d.ae(a,b);b.wu(c);d=this.g;null!==d&&(c?d.ra():d.rq(b));xl(this,a,b);return a};
f.xf=function(a,b,c){if(!c&&b.layer!==this&&null!==b.layer)return b.layer.xf(a,b,c);var d=this.sb;if(0>a||a>=d.length){if(a=d.indexOf(b),0>a)return-1}else if(d.ea(a)!==b&&(a=d.indexOf(b),0>a))return-1;b.xu(c);d.od(a);d=this.g;null!==d&&(c?d.ra():d.xf(b));b.nw=null;return a};
function xl(a,b,c){b=yl(a,b,c);if(c instanceof I&&null!==c&&isNaN(c.Co)){if(0!==c.Oc.count){for(var d=-1,e=a.sb.o,g=e.length,h=0;h<g;h++){var k=e[h];if(k===c&&(b=h,0<=d))break;if(0>d&&k.Ka===c&&(d=h,0<=b))break}!(0>d)&&d<b&&(e=a.sb,e.od(b),e.ae(d,c))}c=c.Ka;null!==c&&xl(a,-1,c)}}
function yl(a,b,c){var d=c.Co;if(isNaN(d))return b;a=a.sb;var e=a.count;if(1>=e)return b;0>b&&(b=a.indexOf(c));if(0>b)return-1;for(var g=b-1,h=NaN;0<=g;){h=a.ea(g).Co;if(!isNaN(h))break;g--}for(var k=b+1,l=NaN;k<e;){l=a.ea(k).Co;if(!isNaN(l))break;k++}if(!isNaN(h)&&h>d)for(;;){if(-1===g||h<=d){g++;if(g===b)break;a.od(b);a.ae(g,c);return g}for(h=NaN;0<=--g&&(h=a.ea(g).Co,isNaN(h)););}else if(!isNaN(l)&&l<d)for(;;){if(k===e||l>=d){k--;if(k===b)break;a.od(b);a.ae(k,c);return k}for(l=NaN;++k<e&&(l=a.ea(k).Co,
isNaN(l)););}return b}f.clear=function(){for(var a=this.sb.oc(),b=a.length,c=0;c<b;c++)a[c].Of(!1),this.xf(-1,a[c],!1)};D.w(Yg,{Ii:"parts"},function(){return this.sb.j});D.w(Yg,{sM:"partsBackwards"},function(){return this.sb.ao});D.w(Yg,{g:"diagram"},function(){return this.ia});
D.defineProperty(Yg,{name:"name"},function(){return this.ac},function(a){D.h(a,"string",Yg,"name");var b=this.ac;if(b!==a){var c=this.g;if(null!==c)for(""===b&&D.k("Cannot rename default Layer to: "+a),c=c.bo;c.next();)c.value.name===a&&D.k("Layer.name is already present in this diagram: "+a);this.ac=a;this.i("name",b,a);for(a=this.sb.j;a.next();)a.value.Mf=this.ac}});
D.defineProperty(Yg,{opacity:"opacity"},function(){return this.Hc},function(a){var b=this.Hc;b!==a&&(D.h(a,"number",Yg,"opacity"),(0>a||1<a)&&D.ua(a,"0 <= value <= 1",Yg,"opacity"),this.Hc=a,this.i("opacity",b,a),a=this.g,null!==a&&a.ra())});D.defineProperty(Yg,{Wc:"isTemporary"},function(){return this.Qz},function(a){var b=this.Qz;b!==a&&(D.h(a,"boolean",Yg,"isTemporary"),this.Qz=a,this.i("isTemporary",b,a))});
D.defineProperty(Yg,{visible:"visible"},function(){return this.qA},function(a){var b=this.qA;if(b!==a){D.h(a,"boolean",Yg,"visible");this.qA=a;this.i("visible",b,a);for(b=this.sb.j;b.next();)b.value.Ld(a);a=this.g;null!==a&&a.ra()}});D.defineProperty(Yg,{tg:"pickable"},function(){return this.Tl},function(a){var b=this.Tl;b!==a&&(D.h(a,"boolean",Yg,"pickable"),this.Tl=a,this.i("pickable",b,a))});
D.defineProperty(Yg,{VF:"isBoundsIncluded"},function(){return this.Nz},function(a){this.Nz!==a&&(this.Nz=a,null!==this.g&&this.g.Nc())});D.defineProperty(Yg,{Mk:"allowCopy"},function(){return this.hl},function(a){var b=this.hl;b!==a&&(D.h(a,"boolean",Yg,"allowCopy"),this.hl=a,this.i("allowCopy",b,a))});D.defineProperty(Yg,{Dn:"allowDelete"},function(){return this.il},function(a){var b=this.il;b!==a&&(D.h(a,"boolean",Yg,"allowDelete"),this.il=a,this.i("allowDelete",b,a))});
D.defineProperty(Yg,{tx:"allowTextEdit"},function(){return this.rl},function(a){var b=this.rl;b!==a&&(D.h(a,"boolean",Yg,"allowTextEdit"),this.rl=a,this.i("allowTextEdit",b,a))});D.defineProperty(Yg,{px:"allowGroup"},function(){return this.jl},function(a){var b=this.jl;b!==a&&(D.h(a,"boolean",Yg,"allowGroup"),this.jl=a,this.i("allowGroup",b,a))});
D.defineProperty(Yg,{ux:"allowUngroup"},function(){return this.sl},function(a){var b=this.sl;b!==a&&(D.h(a,"boolean",Yg,"allowUngroup"),this.sl=a,this.i("allowUngroup",b,a))});D.defineProperty(Yg,{Xt:"allowLink"},function(){return this.kl},function(a){var b=this.kl;b!==a&&(D.h(a,"boolean",Yg,"allowLink"),this.kl=a,this.i("allowLink",b,a))});
D.defineProperty(Yg,{En:"allowRelink"},function(){return this.ml},function(a){var b=this.ml;b!==a&&(D.h(a,"boolean",Yg,"allowRelink"),this.ml=a,this.i("allowRelink",b,a))});D.defineProperty(Yg,{em:"allowMove"},function(){return this.ll},function(a){var b=this.ll;b!==a&&(D.h(a,"boolean",Yg,"allowMove"),this.ll=a,this.i("allowMove",b,a))});
D.defineProperty(Yg,{qx:"allowReshape"},function(){return this.nl},function(a){var b=this.nl;b!==a&&(D.h(a,"boolean",Yg,"allowReshape"),this.nl=a,this.i("allowReshape",b,a))});D.defineProperty(Yg,{Yt:"allowResize"},function(){return this.ol},function(a){var b=this.ol;b!==a&&(D.h(a,"boolean",Yg,"allowResize"),this.ol=a,this.i("allowResize",b,a))});
D.defineProperty(Yg,{sx:"allowRotate"},function(){return this.pl},function(a){var b=this.pl;b!==a&&(D.h(a,"boolean",Yg,"allowRotate"),this.pl=a,this.i("allowRotate",b,a))});D.defineProperty(Yg,{If:"allowSelect"},function(){return this.ql},function(a){var b=this.ql;b!==a&&(D.h(a,"boolean",Yg,"allowSelect"),this.ql=a,this.i("allowSelect",b,a))});
function E(a){function b(){window.document.removeEventListener("DOMContentLoaded",b,!1);zl(c)}1<arguments.length&&D.k("Diagram constructor can only take one optional argument, the DIV HTML element or its id.");D.vc(this);Al=[];this.dd=!0;this.wC=new kh;this.wC.$c(this);this.Ud=17;this.hs=!1;this.bA="default";var c=this;null!==window.document.body?zl(this):window.document.addEventListener("DOMContentLoaded",b,!1);this.dc=new K(Yg);this.Tb=this.Pb=0;this.Ak=this.Ub=this.cd=this.Mb=null;this.HG();this.kp=
null;this.GG();this.tb=(new N(NaN,NaN)).freeze();this.Db=1;this.dw=(new N(NaN,NaN)).freeze();this.ew=NaN;this.ww=1E-4;this.tw=100;this.Jc=new Ea;this.lx=(new N(NaN,NaN)).freeze();this.Wv=(new C(NaN,NaN,NaN,NaN)).freeze();this.Qw=(new Lb(0,0,0,0)).freeze();this.Rw=Bl;this.Nw=this.Lw=null;this.Nm=Vh;this.No=Vc;this.El=Vh;this.hp=Vc;this.fw=this.cw=ec;this.Ne=!0;this.cs=!1;this.Hg=new L(F);this.yl=new oa(J,C);this.Kr=!0;this.hr=250;this.Io=-1;this.Bv=(new Lb(16,16,16,16)).freeze();this.Pv=this.cg=!1;
this.bp=!0;this.$i=new bg;this.gd=new bg;this.Vb=new bg;this.yh=this.Qi=null;this.Ft=!1;this.vz=this.wz=null;Cl(this);this.Ap=new L(G);this.Zl=new L(I);this.tp=new L(J);this.sb=new L(F);this.kw=!0;this.gx=Dl;this.pD=!1;this.ix=fj;this.pz=this.sz=this.mA=null;this.Iv="";this.Er="auto";this.Ui=this.tj=this.ij=this.zw=this.jj=this.kj=this.lj=this.Ti=this.Yi=this.Ri=null;this.Xz=!1;this.YD={};this.Kw=0;this.oi=[null,null];this.mz=null;this.$q=this.uz=this.hA=this.eE=this.sj=!1;this.AD=!0;this.Pz=this.je=
!1;this.xe=null;var d=this;this.JD=function(a){if(a.da===d.da&&d.$a){d.$a=!1;try{var b=a.Kc;""===a.Cf&&b===fg&&El(d,a.object,a.propertyName)}finally{d.$a=!0}}};this.KD=function(a){Fl(d,a)};this.DE=!0;this.uh=-2;this.Vi=new oa(Object,F);this.dk=new oa(Object,J);this.Zm=new oa(Object,Array);this.Cp=new oa("string",Array);this.$z=new K(Gl);this.bj=!1;this.il=this.hl=this.rv=this.qf=!0;this.tv=this.sv=!1;this.yv=this.wv=this.ql=this.pl=this.ol=this.nl=this.ll=this.ml=this.kl=this.vv=this.sl=this.jl=this.rl=
!0;this.Hl=this.xD=!1;this.xv=this.uv=this.aw=this.$v=!0;this.Tw=this.Pw=16;this.dA=this.Ow=!1;this.ot=this.Sw=null;this.eA=this.fA=0;this.sf=(new Lb(5)).freeze();this.Ww=(new L(F)).freeze();this.uw=999999999;this.bw=(new L(F)).freeze();this.Fl=this.Wm=this.pk=!0;this.Cl=this.ok=!1;this.ve=null;this.tl=!0;this.vh=!1;this.BH=new L(J);this.nD=new L(Hl);this.Td=null;this.PD=1;this.hE=0;this.Hh={scale:1,position:new N,bounds:new C,isScroll:!1};this.CE=(new C(NaN,NaN,NaN,NaN)).freeze();this.Qv=(new C(NaN,
NaN,NaN,NaN)).freeze();this.lw=!1;this.Sv=null;this.Gw=new L(Il);Jl(this);this.pw=this.Yv=this.Aw=this.TC=this.SC=this.UC=this.tk=this.Al=this.mj=null;Kl(this);this.Cd=null;this.Xv=!1;this.Qo=null;this.ob=new Ph;this.ob.initializeStandardTools();this.hb=this.eu=this.ob;this.yb=new qa;this.da=new Y;this.sj=!0;this.Zb=new $g;this.sj=!1;this.eD=this.zz=null;this.td=1;this.Dk=null;var e=D.vfo.split(".");!0!==D.Gx.licenseKey&&"1"===e[0]&&7>parseInt(e[1],10)&&(D.trace("Warning: You have entered a license key for GoJS version 1.7 or later, but this library is version "+
D.vfo+". This license key will do nothing until you upgrade to GoJS 1.7 or later."),D.Gx.licenseKey=!0);this.Ll=1;this.an=0;this.CD=new N;this.yE=500;this.Av=new N;this.xt=null;this.Il=!1;this.preventDefault=this.yy=this.xG=this.yG=this.wG=this.lo=this.dh=this.mo=this.jo=this.ko=this.iC=this.$B=this.aC=this.bC=this.Vl=this.kt=this.Ul=this.jt=null;this.hw=!1;this.Dl=new Ll;void 0!==a&&Ml(this,a);this.dd=!1}D.ka("Diagram",E);
E.prototype.clear=E.prototype.clear=function(){var a=null;null!==this.Cd&&(a=this.Cd.Y);this.da.clear();for(var b=this.dc.length,c=0;c<b;c++)this.dc.o[c].clear();this.Hg.clear();this.yl.clear();this.Ap.clear();this.Zl.clear();this.tp.clear();this.sb.clear();this.Vi.clear();this.dk.clear();this.Zm.clear();this.Ww.Xa();this.Ww.clear();this.Ww.freeze();this.bw.Xa();this.bw.clear();this.bw.freeze();Nh=this.Qo=null;Oh="";this.Qv=(new C(NaN,NaN,NaN,NaN)).freeze();null!==a&&(this.add(a),this.sb.remove(a));
this.ra()};
E.prototype.reset=E.prototype.reset=function(){this.dd=!0;this.clear();this.dc=new K(Yg);this.HG();this.GG();this.tb=(new N(NaN,NaN)).freeze();this.Db=1;this.dw=(new N(NaN,NaN)).freeze();this.ew=NaN;this.ww=1E-4;this.tw=100;this.lx=(new N(NaN,NaN)).freeze();this.Wv=(new C(NaN,NaN,NaN,NaN)).freeze();this.Qw=(new Lb(0,0,0,0)).freeze();this.Rw=Bl;this.Nw=this.Lw=null;this.Nm=Vh;this.No=Vc;this.El=Vh;this.hp=Vc;this.fw=this.cw=ec;this.hr=250;this.Bv=(new Lb(16,16,16,16)).freeze();this.kw=!0;this.gx=Dl;
this.ix=fj;this.Er="auto";this.Ui=this.tj=this.ij=this.zw=this.jj=this.kj=this.lj=this.Ti=this.Yi=this.Ri=null;this.bj=!1;this.il=this.hl=this.rv=this.qf=!0;this.tv=this.sv=!1;this.xv=this.uv=this.aw=this.$v=this.yv=this.wv=this.ql=this.pl=this.ol=this.nl=this.ll=this.ml=this.kl=this.vv=this.sl=this.jl=this.rl=!0;this.Tw=this.Pw=16;this.sf=(new Lb(5)).freeze();this.uw=999999999;this.ve=null;this.lw=!1;Kl(this);this.Cd=null;this.ob=new Ph;this.ob.initializeStandardTools();this.hb=this.eu=this.ob;this.yb=
new qa;this.sj=!0;Jl(this);this.Zb=new $g;this.sj=!1;this.da=new Y;this.vh=!1;this.bp=!0;this.dd=this.cg=!1;this.ra();this.yh=this.Qi=null;Cl(this);this.Iv=""};
function Kl(a){a.mj=new oa("string",F);var b=new G,c=new pa;c.bind(new ph("text","",ia));b.add(c);a.UC=b;a.mj.add("",b);b=new G;c=new pa;c.stroke="brown";c.bind(new ph("text","",ia));b.add(c);a.mj.add("Comment",b);b=new G;b.el=!1;b.wA=!1;c=new z;c.Jb="Ellipse";c.fill="black";c.stroke=null;c.Ea=(new Ca(3,3)).Oa();b.add(c);a.mj.add("LinkLabel",b);a.Al=new oa("string",I);b=new I;b.Hy="GROUPPANEL";b.type=Nl;c=new pa;c.font="bold 12pt sans-serif";c.bind(new ph("text","",ia));b.add(c);c=new x(Ol);c.name=
"GROUPPANEL";var d=new z;d.Jb="Rectangle";d.fill="rgba(128,128,128,0.2)";d.stroke="black";c.add(d);d=new Xj;d.padding=(new Lb(5,5,5,5)).Oa();c.add(d);b.add(c);a.SC=b;a.Al.add("",b);a.tk=new oa("string",J);b=new J;c=new z;c.Ue=!0;b.add(c);c=new z;c.Tq="Standard";c.fill="black";c.stroke=null;c.mb=0;b.add(c);a.TC=b;a.tk.add("",b);b=new J;c=new z;c.Ue=!0;c.stroke="brown";b.add(c);a.tk.add("Comment",b);b=new da;b.type=Ol;c=new z;c.fill=null;c.stroke="dodgerblue";c.mb=3;b.add(c);c=new Xj;c.margin=(new Lb(1.5,
1.5,1.5,1.5)).Oa();b.add(c);a.Aw=b;a.Yv=b;b=new da;b.type=rj;c=new z;c.Ue=!0;c.fill=null;c.stroke="dodgerblue";c.mb=3;b.add(c);a.pw=b}
function zl(a){var b=D.createElement("p");b.style.width="100%";b.style.height="200px";b.style.boxSizing="content-box";var c=D.createElement("div");c.style.position="absolute";c.style.visibility="hidden";c.style.width="200px";c.style.height="150px";c.style.overflow="hidden";c.style.boxSizing="content-box";c.appendChild(b);window.document.body.appendChild(c);var d=b.offsetWidth;c.style.overflow="scroll";b=b.offsetWidth;d===b&&(b=c.clientWidth);window.document.body.removeChild(c);c=d-b;0!==c||D.$F||
(c=11);a.Ud=c;c=D.createElement("div");c.dir="rtl";c.style.cssText="font-size: 14px; width: 1px; height: 1px; position: absolute; top: -1000px; overflow: scroll;";c.textContent="A";window.document.body.appendChild(c);d="reverse";0<c.scrollLeft?d="default":(c.scrollLeft=1,0===c.scrollLeft&&(d="negative"));window.document.body.removeChild(c);a.bA=d}E.prototype.qc=function(a){a.Oe===E?this.hm=a:D.Yj(this,a)};
E.prototype.toString=function(a){void 0===a&&(a=0);var b="";this.Dj&&this.Dj.id&&(b=this.Dj.id);b='Diagram "'+b+'"';if(0>=a)return b;for(var c=this.dc.j;c.next();)b+="\n  "+c.value.toString(a-1);return b};E.fromDiv=function(a){var b=a;"string"===typeof a&&(b=window.document.getElementById(a));return b instanceof HTMLDivElement&&b.ia instanceof E?b.ia:null};
D.defineProperty(E,{Dj:"div"},function(){return this.Ub},function(a){null!==a&&D.l(a,HTMLDivElement,E,"div");if(this.Ub!==a){Al=[];var b=this.Ub;null!==b?(b.ia=void 0,b.innerHTML="",null!==this.Mb&&(this.Mb.removeEventListener("touchstart",this.bC,!1),this.Mb.removeEventListener("touchmove",this.aC,!1),this.Mb.removeEventListener("touchend",this.$B,!1),this.Mb.Wd.ia=null),b=this.ob,null!==b&&(b.pf.each(function(a){a.cancelWaitAfter()}),b.mg.each(function(a){a.cancelWaitAfter()}),b.ng.each(function(a){a.cancelWaitAfter()})),
b.cancelWaitAfter(),this.hb.doCancel(),this.cd=this.Mb=null,window.removeEventListener("resize",this.iC,!1),window.removeEventListener("mousemove",this.ko,!0),window.removeEventListener("mousedown",this.jo,!0),window.removeEventListener("mouseup",this.mo,!0),window.removeEventListener("mousewheel",this.dh,!0),window.removeEventListener("DOMMouseScroll",this.dh,!0),window.removeEventListener("mouseout",this.lo,!0)):this.vh=!1;this.Ub=null;if(null!==a){if(b=a.ia)b.Dj=null;Ml(this,a);this.ro()}}});
function Pl(a){var b=a.Mb;b.addEventListener("touchstart",a.bC,!1);b.addEventListener("touchmove",a.aC,!1);b.addEventListener("touchend",a.$B,!1);b.addEventListener("mousemove",a.ko,!1);b.addEventListener("mousedown",a.jo,!1);b.addEventListener("mouseup",a.mo,!1);b.addEventListener("mousewheel",a.dh,!1);b.addEventListener("DOMMouseScroll",a.dh,!1);b.addEventListener("mouseout",a.lo,!1);b.addEventListener("keydown",a.tJ,!1);b.addEventListener("keyup",a.uJ,!1);b.addEventListener("selectstart",function(a){a.preventDefault();
return!1},!1);b.addEventListener("contextmenu",function(a){a.preventDefault();return!1},!1);b.addEventListener("gesturechange",function(b){a.ob.oq===Uk&&b.preventDefault()},!1);b.addEventListener("pointerdown",a.wG,!1);b.addEventListener("pointermove",a.yG,!1);b.addEventListener("pointerleave",a.xG,!1);window.addEventListener("resize",a.iC,!1)}
E.prototype.computePixelRatio=function(){if(null!==this.Dk)return this.Dk;var a=this.cd;return(window.devicePixelRatio||1)/(a.za.webkitBackingStorePixelRatio||a.za.mozBackingStorePixelRatio||a.za.msBackingStorePixelRatio||a.za.oBackingStorePixelRatio||a.za.backingStorePixelRatio||1)};E.prototype.doMouseMove=function(){this.hb.doMouseMove()};E.prototype.doMouseDown=function(){this.hb.doMouseDown()};E.prototype.doMouseUp=function(){this.hb.doMouseUp()};E.prototype.doMouseWheel=function(){this.hb.doMouseWheel()};
E.prototype.doKeyDown=function(){this.hb.doKeyDown()};E.prototype.doKeyUp=function(){this.hb.doKeyUp()};E.prototype.doFocus=function(){this.focus()};E.prototype.focus=E.prototype.focus=function(){this.Mb&&this.Mb.focus()};
function el(a){if(null!==a.Mb){var b=a.Ub;if(0!==b.clientWidth&&0!==b.clientHeight){var c=a.Cl?a.Ud:0,d=a.ok?a.Ud:0,e=a.td;a.td=a.computePixelRatio();a.td!==e&&(a.cs=!0,a.Ve());if(b.clientWidth!==a.Pb+c||b.clientHeight!==a.Tb+d)a.Wm=!0,a.Ne=!0,b=a.Zb,null!==b&&b.by&&a.hm===Vh&&b.N(),a.je||a.Ve()}}}
function Jl(a){var b=new Yg;b.name="Background";a.Rt(b);b=new Yg;b.name="";a.Rt(b);b=new Yg;b.name="Foreground";a.Rt(b);b=new Yg;b.name="Adornment";b.Wc=!0;a.Rt(b);b=new Yg;b.name="Tool";b.Wc=!0;b.VF=!0;a.Rt(b);b=new Yg;b.name="Grid";b.If=!1;b.tg=!1;b.Wc=!0;a.RH(b,a.qm("Background"))}
function Ql(a){a.Cd=new x(Rl);a.Cd.name="GRID";var b=new z;b.Jb="LineH";b.stroke="lightgray";b.mb=.5;b.interval=1;a.Cd.add(b);b=new z;b.Jb="LineH";b.stroke="gray";b.mb=.5;b.interval=5;a.Cd.add(b);b=new z;b.Jb="LineH";b.stroke="gray";b.mb=1;b.interval=10;a.Cd.add(b);b=new z;b.Jb="LineV";b.stroke="lightgray";b.mb=.5;b.interval=1;a.Cd.add(b);b=new z;b.Jb="LineV";b.stroke="gray";b.mb=.5;b.interval=5;a.Cd.add(b);b=new z;b.Jb="LineV";b.stroke="gray";b.mb=1;b.interval=10;a.Cd.add(b);b=new F;b.add(a.Cd);
b.Mf="Grid";b.Co=0;b.Xx=!1;b.UF=!1;b.tg=!1;b.ly="GRID";a.add(b);a.sb.remove(b);a.Cd.visible=!1}function Sl(){this.ia.isEnabled?this.ia.vI(this):Tl(this.ia)}function Ul(a){this.ia.isEnabled?(this.ia.dA=!0,this.ia.fA=a.target.scrollTop,this.ia.eA=a.target.scrollLeft):Tl(this.ia)}
E.prototype.diagramScroll=E.prototype.vI=function(a){if(this.dA&&null!==this.Mb){this.Ow=!0;var b=this.jd,c=this.vb,d=this.Fy,e=b.x-d.left,g=b.y-d.top,h=b.width+d.left+d.right,k=b.height+d.top+d.bottom,l=b.right+d.right,d=b.bottom+d.bottom,m=c.x,b=c.y,n=c.width,p=c.height,q=c.right,r=c.bottom,c=this.scale,s;s=a.scrollLeft;if(this.hs)switch(this.bA){case "negative":s=s+a.scrollWidth-a.clientWidth;break;case "reverse":s=a.scrollWidth-s-a.clientWidth}var u=s;n<h||p<k?(s=D.Fb(this.position.x,this.position.y),
this.Ae&&this.eA!==u&&(s.x=u/c+e,this.eA=u),this.Be&&this.fA!==a.scrollTop&&(s.y=a.scrollTop/c+g,this.fA=a.scrollTop),this.position=s,D.A(s),this.Wm=this.Ow=!1):(s=D.P(),a.HH&&this.Ae&&(e<m&&(this.position=s.n(u+e,this.position.y)),l>q&&(this.position=s.n(-(this.Sw.scrollWidth-this.Pb)+u-this.Pb/c+l,this.position.y))),a.IH&&this.Be&&(g<b&&(this.position=s.n(this.position.x,a.scrollTop+g)),d>r&&(this.position=s.n(this.position.x,-(this.Sw.scrollHeight-this.Tb)+a.scrollTop-this.Tb/c+d))),D.A(s),Vl(this),
this.Wm=this.Ow=!1,b=this.jd,c=this.vb,l=b.right,q=c.right,d=b.bottom,r=c.bottom,e=b.x,m=c.x,g=b.y,b=c.y,n>=h&&e>=m&&l<=q&&(this.ot.style.width="1px"),p>=k&&g>=b&&d<=r&&(this.ot.style.height="1px"))}};E.prototype.computeBounds=E.prototype.Mh=function(){0<this.Hg.count&&Si(this);return Wl(this)};
function Wl(a){if(a.AF.F()){var b=a.AF.copy();b.nx(a.padding);return b}for(var c=!0,d=a.dc.o,e=d.length,g=0;g<e;g++){var h=d[g];if(h.visible&&(!h.Wc||h.VF))for(var h=h.sb.o,k=h.length,l=0;l<k;l++){var m=h[l];m.Xx&&m.isVisible()&&(m=m.Z,m.F()&&(c?(c=!1,b=m.copy()):b.Zh(m)))}}c&&(b=new C(0,0,0,0));b.nx(a.padding);return b}
E.prototype.computePartsBounds=function(a,b){void 0===b&&(b=!1);for(var c=null,d=a.j;d.next();){var e=d.value;!b&&e instanceof J||(e.Re(),null===c?c=e.Z.copy():c.Zh(e.Z))}return null===c?new C(NaN,NaN,0,0):c};
function Xl(a,b){if((b||a.vh)&&!a.dd&&null!==a.Mb&&!a.Wa.yf&&a.jd.F()){a.dd=!0;var c=a.Nm;b&&a.El!==Vh&&(c=a.El);var d=c!==Vh?Yl(a,c):a.scale,c=a.vb.copy(),e=a.Pb/d,g=a.Tb/d,h=null,k=a.Wa;k.xc&&(h=a.tb.copy());a.position.Xa();var l=a.No;b&&!l.Vc()&&a.hp.Vc()&&(l=a.hp);Zl(a,a.tb,a.jd,e,g,l,b);a.position.freeze();null!==h&&ql(k,h,a.tb);e=a.scale;a.scale=d;a.dd=!1;d=a.vb;d.Uc(c)||a.Uu(c,d,e,a.scale,!1)}}
function Yl(a,b){var c=a.yb.Ex;if(null===a.Mb)return c;a.pk&&$l(a,a.Mh());var d=a.jd;if(!d.F())return c;var e=d.width,d=d.height,g=a.Pb,h=a.Tb,k=g/e,l=h/d;return b===am?(e=Math.min(l,k),e>c&&(e=c),e<a.Sh&&(e=a.Sh),e>a.Rh&&(e=a.Rh),e):b===bm?(e=l>k?(h-a.Ud)/d:(g-a.Ud)/e,e>c&&(e=c),e<a.Sh&&(e=a.Sh),e>a.Rh&&(e=a.Rh),e):a.scale}E.prototype.zoomToFit=E.prototype.zoomToFit=function(){this.scale=Yl(this,am)};
E.prototype.zoomToRect=function(a,b){void 0===b&&(b=am);var c=a.width,d=a.height;if(!(0===c||0===d||isNaN(c)&&isNaN(d))){var e=1;if(b===am||b===bm)if(isNaN(c))e=this.vb.height*this.scale/d;else if(isNaN(d))e=this.vb.width*this.scale/c;else var e=this.Pb,g=this.Tb,e=b===bm?g/d>e/c?(g-(this.ok?this.Ud:0))/d:(e-(this.Cl?this.Ud:0))/c:Math.min(g/d,e/c);this.scale=e;this.position=new N(a.x,a.y)}};D.defineProperty(E,{eB:null},function(){return this.dd},function(a){this.dd=a});
E.prototype.alignDocument=function(a,b){this.pk&&$l(this,this.Mh());var c=this.jd,d=this.vb;this.position=new N(c.x+(a.x*c.width+a.offsetX)-(b.x*d.width-b.offsetX),c.y+(a.y*c.height+a.offsetY)-(b.y*d.height-b.offsetY))};
function Zl(a,b,c,d,e,g,h){var k=b.x,l=b.y;if(h||a.IB===Bl)g.Vc()&&(d>c.width&&(k=c.x+(g.x*c.width+g.offsetX)-(g.x*d-g.offsetX)),e>c.height&&(l=c.y+(g.y*c.height+g.offsetY)-(g.y*e-g.offsetY))),g=a.Fy,h=d-c.width,d<c.width+g.left+g.right?(k=Math.min(k+d/2,c.right+Math.max(h,g.right)-d/2),k=Math.max(k,c.left-Math.max(h,g.left)+d/2),k-=d/2):k>c.left?k=c.left:k<c.right-d&&(k=c.right-d),d=e-c.height,e<c.height+g.top+g.bottom?(l=Math.min(l+e/2,c.bottom+Math.max(d,g.bottom)-e/2),l=Math.max(l,c.top-Math.max(d,
g.top)+e/2),l-=e/2):l>c.top?l=c.top:l<c.bottom-e&&(l=c.bottom-e);b.x=isFinite(k)?k:-a.padding.left;b.y=isFinite(l)?l:-a.padding.top;null!==a.AG&&(a=a.AG(a,b),b.x=a.x,b.y=a.y)}E.prototype.findPartAt=E.prototype.ku=function(a,b){var c=b?Wi(this,a,function(a){return a.Y},function(a){return a.canSelect()}):Wi(this,a,function(a){return a.Y});return c instanceof F?c:null};
E.prototype.findObjectAt=E.prototype.Ie=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);Si(this);for(var d=this.dc.ao;d.next();){var e=d.value;if(e.visible&&(e=e.Ie(a,b,c),null!==e))return e}return null};function Wi(a,b,c,d){void 0===c&&(c=null);void 0===d&&(d=null);Si(a);for(a=a.dc.ao;a.next();){var e=a.value;if(e.visible&&!e.Wc&&(e=e.Ie(b,c,d),null!==e))return e}return null}
E.prototype.findObjectsAt=E.prototype.ju=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof K||d instanceof L||(d=new L(P));Si(this);for(var e=this.dc.ao;e.next();){var g=e.value;g.visible&&g.ju(a,b,c,d)}return d};E.prototype.findObjectsIn=E.prototype.Sk=function(a,b,c,d,e){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof K||e instanceof L||(e=new L(P));Si(this);for(var g=this.dc.ao;g.next();){var h=g.value;h.visible&&h.Sk(a,b,c,d,e)}return e};
E.prototype.PA=function(a,b,c,d,e,g){var h=new L(P);Si(this);for(var k=this.dc.ao;k.next();){var l=k.value;l.visible&&l.PA(a,b,c,d,h,e,g)}return h};E.prototype.findObjectsNear=E.prototype.Qn=function(a,b,c,d,e,g){void 0===c&&(c=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof K||e instanceof L)g=e;e=!0}g instanceof K||g instanceof L||(g=new L(P));Si(this);for(var h=this.dc.ao;h.next();){var k=h.value;k.visible&&k.Qn(a,b,c,d,e,g)}return g};
E.prototype.acceptEvent=function(a){var b=this.gd;this.gd=this.Vb;this.Vb=b;cm(this,this,a,b,a instanceof MouseEvent);return b};
function cm(a,b,c,d,e){d.g=b;d.event=c;e?dm(a,c,d):(d.se=b.Vb.se,d.ga=b.Vb.ga);a=0;c.ctrlKey&&(a+=1);c.altKey&&(a+=2);c.shiftKey&&(a+=4);c.metaKey&&(a+=8);d.Md=a;d.button=c.button;void 0===c.buttons||D.kJ||(d.buttons=c.buttons);D.Zk&&0===c.button&&c.ctrlKey&&(d.button=2);d.pm=!1;d.up=!1;d.Pe=1;d.om=0;d.Mc=!1;d.bubbles=!1;d.timestamp=Date.now();d.Eu=!1;d.jh=em(c);d.Ze=null}
function em(a){var b=a.target.ia;if(!b){var c=a.path;c||"function"!==typeof a.gI||(c=a.gI());c&&c[0]&&(b=c[0].ia)}return b?b:null}function fm(a,b,c,d,e){d.g=a;dm(a,c,d);d.Md=0;d.button=0;d.buttons=1;d.pm=!0;d.up=!1;d.Pe=1;d.om=0;d.Mc=!1;d.bubbles=!0;d.event=b;d.timestamp=Date.now();d.Eu=e;d.jh=em(b);d.Ze=null;a.$i=d.copy();di=null}
function gm(a,b,c,d,e){var g=null;d.g=a;null!==c?((g=window.document.elementFromPoint(c.clientX,c.clientY))&&g.ia?g=g.ia:(c=b instanceof TouchEvent?b.targetTouches[0]:b,g=a),d.jh=g,dm(a,c,d)):null!==a.gd?(d.ga=a.gd.ga,d.se=a.gd.se,d.jh=a.gd.jh):null!==a.$i&&(d.ga=a.$i.ga,d.se=a.$i.se,d.jh=a.$i.jh);d.Md=0;d.button=0;d.buttons=1;d.pm=!1;d.up=!1;d.Pe=1;d.om=0;d.Mc=!1;d.bubbles=!1;d.event=b;d.timestamp=Date.now();d.Eu=e;d.Ze=null}
function ga(a,b,c){if(b.bubbles)return v&&v.PF&&D.trace("NOT handled "+c.type+" "+b.toString()),!0;v&&v.PF&&D.trace("handled "+c.type+" "+a.hb.name+" "+b.toString());void 0!==c.stopPropagation&&c.stopPropagation();c.preventDefault();c.cancelBubble=!0;return!1}
E.prototype.tJ=function(a){if(!this.ia.isEnabled)return!1;var b=this.ia.Vb;cm(this.ia,this.ia,a,b,!1);b.key=String.fromCharCode(a.which);b.pm=!0;switch(a.which){case 8:b.key="Backspace";break;case 33:b.key="PageUp";break;case 34:b.key="PageDown";break;case 35:b.key="End";break;case 36:b.key="Home";break;case 37:b.key="Left";break;case 38:b.key="Up";break;case 39:b.key="Right";break;case 40:b.key="Down";break;case 45:b.key="Insert";break;case 46:b.key="Del";break;case 48:b.key="0";break;case 187:case 61:case 107:b.key=
"Add";break;case 189:case 173:case 109:b.key="Subtract";break;case 27:b.key="Esc"}this.ia.doKeyDown();return ga(this.ia,b,a)};
E.prototype.uJ=function(a){if(!this.ia.isEnabled)return!1;var b=this.ia.Vb;cm(this.ia,this.ia,a,b,!1);b.key=String.fromCharCode(a.which);b.up=!0;switch(a.which){case 8:b.key="Backspace";break;case 33:b.key="PageUp";break;case 34:b.key="PageDown";break;case 35:b.key="End";break;case 36:b.key="Home";break;case 37:b.key="Left";break;case 38:b.key="Up";break;case 39:b.key="Right";break;case 40:b.key="Down";break;case 45:b.key="Insert";break;case 46:b.key="Del"}this.ia.doKeyUp();return ga(this.ia,b,a)};
E.prototype.dp=function(a){var b=this.Mb;if(null===b)return new N(0,0);var c=this.Pb,d=this.Tb,b=b.getBoundingClientRect(),c=a.clientX-c/b.width*b.left;a=a.clientY-d/b.height*b.top;return null!==this.Jc?(a=new N(c,a),kb(a,this.Jc),a):new N(c,a)};function dm(a,b,c){var d=a.Mb,e=a.Pb,g=a.Tb,h=0,k=0;null!==d&&(d=d.getBoundingClientRect(),h=b.clientX-e/d.width*d.left,k=b.clientY-g/d.height*d.top);c.se.n(h,k);null!==a.Jc?(b=D.Fb(h,k),a.Jc.Fi(b),c.ga.assign(b),D.A(b)):c.ga.n(h,k)}
function cg(a,b,c,d){var e=null;if(void 0!==b.targetTouches){if(2>b.targetTouches.length)return;e=b.targetTouches[c]}else if(null!==a.oi[0])e=a.oi[c];else return;c=a.Mb;b=a.Pb;a=a.Tb;var g=0,h=0;null!==c&&null!==e&&(c=c.getBoundingClientRect(),g=e.clientX-b/c.width*c.left,h=e.clientY-a/c.height*c.top);d.n(g,h)}E.prototype.invalidateDocumentBounds=E.prototype.Nc=function(){this.pk||(this.pk=!0,this.Ve(!0))};function nl(a){a.je||Si(a);a.pk&&$l(a,a.Mh())}
E.prototype.redraw=E.prototype.ro=function(){this.dd||this.je||(this.ra(),hm(this),Vl(this),this.Nc(),this.lg())};E.prototype.isUpdateRequested=function(){return this.cg};E.prototype.delayInitialization=function(a){void 0===a&&(a=null);var b=this.Wa,c=b.isEnabled;b.Yh();b.isEnabled=!1;bj(this);this.vh=!1;b.isEnabled=c;null!==a&&D.setTimeout(a,1)};
E.prototype.requestUpdate=E.prototype.Ve=function(a){void 0===a&&(a=!1);if(!0!==this.cg&&!(this.dd||!1===a&&this.je)){this.cg=!0;var b=this;requestAnimationFrame(function(){b.cg&&b.lg()})}};E.prototype.maybeUpdate=E.prototype.lg=function(){if(!this.bp||this.cg)this.bp&&(this.bp=!1),bj(this)};function im(a,b){a.Wa.yf||a.dd||!a.Wm||Tl(a)||(b&&Si(a),Xl(a,!1))}
function bj(a){if(!a.je&&(a.cg=!1,null!==a.Ub)){a.je=!0;var b=a.Wa,c=a.$z;if(!b.np&&0!==c.length){for(var d=c.o,e=d.length,g=0;g<e;g++){var h=d[g];jm(h,!1);h.K()}c.clear()}c=a.nD;0<c.count&&(c.each(function(a){a.gC()}),c.clear());d=c=!1;b.yf&&(d=!0,c=a.lb,a.lb=!0);b.xc||el(a);im(a,!1);null!==a.Cd&&(a.Cd.visible&&!a.Xv&&(km(a),a.Xv=!0),!a.Cd.visible&&a.Xv&&(a.Xv=!1));Si(a);e=!1;if(!a.vh||a.tl)a.vh?nm(a,!a.Pv):(a.Nb("Initial Layout"),!1===b.isEnabled&&b.Yh(),nm(a,!1)),e=!0;a.Pv=!1;Si(a);a.hA||b.yf||
nl(a);im(a,!0);e&&(a.vh||vm(a),a.Ja("LayoutCompleted"));Si(a);e&&!a.vh&&(a.vh=!0,a.hd("Initial Layout"),a.lb||a.la.clear(),D.setTimeout(function(){a.Hi=!1},1));Km(a);dl(b);a.Ge(a.cd);d&&(a.lb=c);a.je=!1}}D.w(E,{$n:null},function(){return this.vh});
function vm(a){var b=a.dc.o;a.Of(b,b.length,a);a.El!==Vh?a.scale=Yl(a,a.El):a.Nm!==Vh?a.scale=Yl(a,a.Nm):(b=a.eJ,isFinite(b)&&0<b&&(a.scale=b));b=a.dJ;if(b.F())a.position=b;else{b=D.P();b.uo(a.jd,a.cJ);var c=a.vb,c=D.vg(0,0,c.width,c.height),d=D.P();d.uo(c,a.fJ);d.n(b.x-d.x,b.y-d.y);a.position=d;D.Kb(c);D.A(d);D.A(b);hm(a);im(a,!0);Xl(a,!0)}a.Ja("InitialLayoutCompleted");km(a)}
function Si(a){if((a.je||!a.Wa.yf)&&0!==a.Hg.count){for(var b=0;23>b;b++){var c=a.Hg.j;if(null===c||0===a.Hg.count)break;a.Hg=new L(F);a.gC(c,a.Hg);v&&22===b&&D.trace("failure to validate parts")}a.rg.each(function(a){a instanceof I&&0!==(a.Ca&65536)!==!1&&(a.Ca^=65536)})}}
E.prototype.gC=function(a,b){for(a.reset();a.next();){var c=a.value;!c.pe()||c instanceof I||(c.zm()?(Gk(c,Infinity,Infinity),c.rc()):b.add(c))}for(a.reset();a.next();)c=a.value,c instanceof I&&c.isVisible()&&Lm(this,c);for(a.reset();a.next();)c=a.value,c instanceof J&&c.isVisible()&&(c.zm()?(Gk(c,Infinity,Infinity),c.rc()):b.add(c));for(a.reset();a.next();)c=a.value,c instanceof da&&c.isVisible()&&(c.zm()?(Gk(c,Infinity,Infinity),c.rc()):b.add(c))};
function Lm(a,b){for(var c=D.nb(),d=D.nb(),e=b.Oc;e.next();){var g=e.value;g.isVisible()&&(g instanceof I?(Mm(g)||Nm(g)||Om(g))&&Lm(a,g):g instanceof J?g.aa===b||g.ba===b?d.push(g):c.push(g):(Gk(g,Infinity,Infinity),g.rc()))}for(var e=c.length,h=0;h<e;h++)g=c[h],Gk(g,Infinity,Infinity),g.rc();D.ya(c);Gk(b,Infinity,Infinity);b.rc();e=d.length;for(h=0;h<e;h++)g=d[h],Gk(g,Infinity,Infinity),g.rc();D.ya(d)}E.prototype.Of=function(a,b,c,d){var e=this.Wa;if(this.Fl||e.yf)for(e=0;e<b;e++)a[e].Of(c,d)};
E.prototype.Ge=function(a,b){void 0===b&&(b=null);null===this.Ub&&D.k("No div specified");var c=this.Mb;null===c&&D.k("No canvas specified");var d=this.Wa;if(!d.xc&&(Pm(this),"0"!==this.Ub.style.opacity)){var e=a!==this.cd,g=this.dc.o,h=g.length,k=this;this.Of(g,h,k);if(e)a.De(!0),Vl(this);else if(!this.Ne&&null===b&&!d.qk)return;var h=this.tb,l=this.Db,m=Math.round(h.x*l)/l,n=Math.round(h.y*l)/l,d=this.Jc;d.reset();1!==l&&d.scale(l);0===h.x&&0===h.y||d.translate(-m,-n);l=this.td;D.Yn?(c.width=c.width,
a.De(!0),a.scale(l,l)):(a.setTransform(1,0,0,1,0,0),a.scale(l,l),a.clearRect(0,0,this.Pb,this.Tb));a.setTransform(1,0,0,1,0,0);a.scale(l,l);a.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);v&&v.zj&&v.oF(this,a);c=null!==b?function(c){var d=b;if(c.visible&&0!==c.Hc){var e=c.sb.o,g=e.length;if(0!==g){1!==c.Hc&&(a.globalAlpha=c.Hc);c=c.Fw;c.length=0;for(var h=k.scale,l=0;l<g;l++){var m=e[l];if(sl(m)&&!d.contains(m)){if(m instanceof J){var n=m;n.ic&&c.push(n);if(!1===n.Tf)continue}n=m.Z;1<n.width*h||1<
n.height*h?m.Ge(a,k):ul(m,a)}}a.globalAlpha=1}}}:function(b){b.Ge(a,k)};Qm(this,a);h=g.length;for(m=0;m<h;m++)a.setTransform(1,0,0,1,0,0),a.scale(l,l),a.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy),c(g[m]);this.Dl?this.Dl.Im(this)&&this.Sv():this.dp=function(){return new N(0,0)};v&&(v.LA||v.zj)&&v.KA(a,this,d);e?(this.cd.De(!0),Vl(this)):this.Ne=this.Fl=!1}};
function Rm(a,b,c,d,e){null===a.Ub&&D.k("No div specified");var g=a.Mb;null===g&&D.k("No canvas specified");if(!a.Wa.xc){var h=a.cd;if(a.Ne){Pm(a);var k=a.td;D.Yn?(g.width=g.width,h.De(!0)):(h.setTransform(1,0,0,1,0,0),h.clearRect(0,0,a.Pb*k,a.Tb*k));h.Ky(!1);h.drawImage(a.zz.Wd,0<d?0:Math.round(-d),0<e?0:Math.round(-e));e=a.tb;var g=a.Db,l=Math.round(e.x*g)/g,m=Math.round(e.y*g)/g;d=a.Jc;d.reset();1!==g&&d.scale(g);0===e.x&&0===e.y||d.translate(-l,-m);h.save();h.beginPath();e=c.length;for(g=0;g<
e;g++)l=c[g],0!==l.width&&0!==l.height&&h.rect(Math.floor(l.x),Math.floor(l.y),Math.ceil(l.width),Math.ceil(l.height));h.clip();h.setTransform(1,0,0,1,0,0);h.scale(k,k);h.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);v&&v.zj&&v.oF(a,h);c=a.dc.o;e=c.length;a.Of(c,e,a);Qm(a,h);for(g=0;g<e;g++)vl(c[g],h,a,b);h.restore();h.De(!0);v&&(v.LA||v.zj)&&v.KA(h,a,d);a.Dl?a.Dl.Im(a)&&a.Sv():a.dp=function(){return new N(0,0)};a.Fl=!1;a.Ne=!1;a.yy()}}}
function Sm(a,b,c,d,e,g,h,k,l,m){null===a.Ub&&D.k("No div specified");null===a.Mb&&D.k("No canvas specified");void 0===h&&(h=null);void 0===k&&(k=null);void 0===l&&(l=!1);void 0===m&&(m=!1);Pm(a);a.cd.De(!0);Vl(a);a.Pz=!0;var n=a.Db;a.Db=e;var p=a.dc.o,q=p.length;try{var r=new C(g.x,g.y,d.width/e,d.height/e),s=r.copy();s.nx(c);km(a,s);Si(a);a.Of(p,q,a,r);var u=a.td;b.setTransform(1,0,0,1,0,0);b.scale(u,u);b.clearRect(0,0,d.width,d.height);null!==k&&""!==k&&(b.fillStyle=k,b.fillRect(0,0,d.width,d.height));
var t=D.Rf();t.reset();t.translate(c.left,c.top);t.scale(e);0===g.x&&0===g.y||t.translate(-g.x,-g.y);b.setTransform(t.m11,t.m12,t.m21,t.m22,t.dx,t.dy);D.Je(t);Qm(a,b);var y;if(null!==h){var w=new L(F),B=h.j;for(B.reset();B.next();){var A=B.value;!1===m&&"Grid"===A.layer.name||null===A||w.add(A)}y=function(c){var d=l;if(c.visible&&0!==c.Hc&&(void 0===d&&(d=!0),d||!c.Wc)){var d=c.sb.o,e=d.length;if(0!==e){1!==c.Hc&&(b.globalAlpha=c.Hc);c=c.Fw;c.length=0;for(var g=a.scale,h=0;h<e;h++){var k=d[h];if(sl(k)&&
w.contains(k)){if(k instanceof J){var m=k;m.ic&&c.push(m);if(!1===m.Tf)continue}m=k.Z;1<m.width*g||1<m.height*g?k.Ge(b,a):ul(k,b)}}b.globalAlpha=1}}}}else if(!l&&m){var O=a.Wn.Y,H=O.layer;y=function(c){c===H?O.Ge(b,a):c.Ge(b,a,l)}}else y=function(c){c.Ge(b,a,l)};for(c=0;c<q;c++)y(p[c]);a.Pz=!1;a.Dl?a.Dl.Im(a)&&a.Sv():a.dp=function(){return new N(0,0)}}finally{a.Db=n,a.cd.De(!0),Vl(a),a.Of(p,q,a),km(a)}}E.prototype.getRenderingHint=E.prototype.Gj=function(a){return this.Ak[a]};
E.prototype.setRenderingHint=E.prototype.mK=function(a,b){this.Ak[a]=b;this.ro()};E.prototype.resetRenderingHints=E.prototype.HG=function(){this.Ak=new ma;this.Ak.drawShadows=!0;this.Ak.textGreeking=!0;this.Ak.viewportOptimizations=D.$F?!1:!0;this.Ak.temporaryPixelRatio=!0;this.Ak.pictureRatioOptimization=!0};function Qm(a,b){var c=a.Ak;null!==c&&(void 0!==c.imageSmoothingEnabled&&b.Ky(!!c.imageSmoothingEnabled),c=c.defaultFont,void 0!==c&&null!==c&&(b.font=c))}
E.prototype.getInputOption=E.prototype.tu=function(a){return this.kp[a]};E.prototype.setInputOption=function(a,b){this.kp[a]=b};E.prototype.resetInputOptions=E.prototype.GG=function(){this.kp=new ma;this.kp.extraTouchArea=10;this.kp.extraTouchThreshold=10;this.kp.hasGestureZoom=!0};E.prototype.setProperties=function(a){D.dv(this,a)};function Km(a){if(0===a.la.Ki&&0!==a.yl.count){for(;0<a.yl.count;){var b=a.yl;a.yl=new oa(J,C);for(b=b.j;b.next();){var c=b.key;Nj(c,b.value);c.Te()}}a.ra()}}
E.prototype.ra=function(a){void 0===a&&(a=null);if(null===a)this.Ne=!0,this.Ve();else{var b=this.vb;null!==a&&a.F()&&b.jg(a)&&(this.Ne=!0,this.Ve())}for(b=this.Gw.j;b.next();)b.value.ra(a)};
E.prototype.TF=function(a,b){if(!0!==this.Ne){this.Ne=!0;var c=!0===this.Gj("temporaryPixelRatio");if(!0===this.Gj("viewportOptimizations")&&this.IB!==Tm&&this.Fy.Jx(0,0,0,0)&&b.width===a.width&&b.height===a.height){var d=this.scale,e=D.Qf(),g=Math.max(a.x,b.x),h=Math.max(a.y,b.y),k=Math.min(a.x+a.width,b.x+b.width),l=Math.min(a.y+a.height,b.y+b.height);e.x=g;e.y=h;e.width=Math.max(0,k-g)*d;e.height=Math.max(0,l-h)*d;if(0<e.width&&0<e.height){if(!this.je&&(this.cg=!1,null!==this.Ub)){this.je=!0;Km(this);
this.jd.F()||$l(this,this.Mh());var m=this.Mb;if(null!==m){var n=this.td,h=this.Pb*n,k=this.Tb*n,g=this.scale*n,d=Math.round(Math.round(b.x*g)-Math.round(a.x*g)),g=Math.round(Math.round(b.y*g)-Math.round(a.y*g)),l=this.zz,p=this.eD;l.width!==h&&(l.width=h);l.height!==k&&(l.height=k);p.clearRect(0,0,h,k);var l=190*this.td,q=70*this.td,r=Math.max(d,0),s=Math.max(g,0),u=Math.floor(h-r),t=Math.floor(k-s);p.Ky(!1);p.drawImage(m.Wd,r,s,u,t,0,0,u,t);this.Dl.Im(this)&&p.clearRect(0,0,l,q);var m=D.nb(),p=
D.nb(),t=Math.abs(d),u=Math.abs(g),y=0===r?0:h-t,r=D.Fb(y,0),t=D.Fb(t+y,k);p.push(new C(Math.min(r.x,t.x),Math.min(r.y,t.y),Math.abs(r.x-t.x),Math.abs(r.y-t.y)));var w=this.Jc;w.reset();w.scale(n,n);1!==this.Db&&w.scale(this.Db);n=this.tb;(0!==n.x||0!==n.y)&&isFinite(n.x)&&isFinite(n.y)&&w.translate(-n.x,-n.y);kb(r,w);kb(t,w);m.push(new C(Math.min(r.x,t.x),Math.min(r.y,t.y),Math.abs(r.x-t.x),Math.abs(r.y-t.y)));y=0===s?0:k-u;r.n(0,y);t.n(h,u+y);p.push(new C(Math.min(r.x,t.x),Math.min(r.y,t.y),Math.abs(r.x-
t.x),Math.abs(r.y-t.y)));kb(r,w);kb(t,w);m.push(new C(Math.min(r.x,t.x),Math.min(r.y,t.y),Math.abs(r.x-t.x),Math.abs(r.y-t.y)));this.Dl.Im(this)&&(h=0<d?0:-d,k=0<g?0:-g,r.n(h,k),t.n(l+h,q+k),p.push(new C(Math.min(r.x,t.x),Math.min(r.y,t.y),Math.abs(r.x-t.x),Math.abs(r.y-t.y))),kb(r,w),kb(t,w),m.push(new C(Math.min(r.x,t.x),Math.min(r.y,t.y),Math.abs(r.x-t.x),Math.abs(r.y-t.y))));D.A(r);D.A(t);im(this,!1);Rm(this,m,p,d,g);D.ya(m);D.ya(p);this.je=!1}}}else this.lg();D.Kb(e);c&&(this.Dk=1,this.lg(),
this.Dk=null,this.yy())}else c?(this.Dk=1,this.lg(),this.Dk=null,this.yy()):this.lg()}};function hm(a){!1===a.Wm&&(a.Wm=!0)}function Vl(a){!1===a.Fl&&(a.Fl=!0)}function Pm(a){!1!==a.cs&&(a.cs=!1,Um(a,a.Pb,a.Tb))}function Um(a,b,c){var d=a.Mb,e=a.td,g=b*e,e=c*e;if(d.width!==g||d.height!==e)d.width=g,d.height=e,d.style.width=b+"px",d.style.height=c+"px",a.Ne=!0,a.cd.De(!0)}
function Tl(a){var b=a.Mb;if(null===b)return!0;var c=a.Ub,d=a.Pb,e=a.Tb,g=a.CE.copy();if(!g.F())return!0;var h=!1,k=a.Cl?a.Ud:0,l=a.ok?a.Ud:0,m=c.clientWidth||d+k,c=c.clientHeight||e+l;if(m!==d+k||c!==e+l)a.Cl=!1,a.ok=!1,l=k=0,a.Pb=m,a.Tb=c,h=a.cs=!0;a.Wm=!1;var n=a.vb,p=a.jd,q=0,r=0,s=0,u=0,m=n.width,c=n.height,t=a.Fy;a.AA.Vc()?(p.width>m&&(q=t.left,r=t.right),p.height>c&&(s=t.top,u=t.bottom)):(q=t.left,r=t.right,s=t.top,u=t.bottom);var t=p.width+q+r,y=p.height+s+u,q=p.x-q,w=n.x,r=p.right+r,B=n.right+
k,s=p.y-s,A=n.y,u=p.bottom+u,n=n.bottom+l,O="1px",H="1px",p=a.scale,R=!(t<m+k),aa=!(y<c+l);a.IB===Bl&&(R||aa)&&(R&&a.ZA&&a.Ae&&(O=1,q+1<w&&(O=Math.max((w-q)*p+a.Pb,O)),r>B+1&&(O=Math.max((r-B)*p+a.Pb,O)),m+k+1<t&&(O=Math.max((t-m+k)*p+a.Pb,O)),O=O.toString()+"px"),aa&&a.$A&&a.Be&&(H=1,s+1<A&&(H=Math.max((A-s)*p+a.Tb,H)),u>n+1&&(H=Math.max((u-n)*p+a.Tb,H)),c+l+1<y&&(H=Math.max((y-c+l)*p+a.Tb,H)),H=H.toString()+"px"));var R="1px"!==O,V="1px"!==H;R&&V||!R&&!V||(aa=!(y<c+l),V&&(B-=a.Ud),R&&(n-=a.Ud),
t<m+k||!a.ZA||!a.Ae||(O=1,q+1<w&&(O=Math.max((w-q)*p+a.Pb,O)),r>B+1&&(O=Math.max((r-B)*p+a.Pb,O)),m+1<t&&(O=Math.max((t-m)*p+a.Pb,O)),O=O.toString()+"px"),R="1px"!==O,k=R!==a.ok?a.Tb-a.Ud:a.Tb,aa&&a.$A&&a.Be&&(H=1,s+1<A&&(H=Math.max((A-s)*p+k,H)),u>n+1&&(H=Math.max((u-n)*p+k,H)),c+1<y&&(H=Math.max((y-c)*p+k,H)),H=H.toString()+"px"),V="1px"!==H);if(a.Ow&&R===a.ok&&V===a.Cl)return d===a.Pb&&e===a.Tb||a.lg(),!1;R!==a.ok&&(a.Tb="1px"===O?a.Tb+a.Ud:Math.max(a.Tb-a.Ud,1),h=!0);a.ok=R;a.ot.style.width=O;
V!==a.Cl&&(a.Pb="1px"===H?a.Pb+a.Ud:Math.max(a.Pb-a.Ud,1),h=!0,a.hs&&(k=D.P(),V?(b.style.left=a.Ud+"px",a.position=k.n(a.tb.x+a.Ud/a.scale,a.tb.y)):(b.style.left="0px",a.position=k.n(a.tb.x-a.Ud/a.scale,a.tb.y)),D.A(k)));a.Cl=V;a.ot.style.height=H;b=a.Sw;k=b.scrollLeft;a.ZA&&a.Ae&&(m+1<t?k=(a.position.x-q)*p:q+1<w?k=b.scrollWidth-b.clientWidth:r>B+1&&(k=a.position.x*p));if(a.hs)switch(a.bA){case "negative":k=-(b.scrollWidth-k-b.clientWidth);break;case "reverse":k=b.scrollWidth-k-b.clientWidth}b.scrollLeft=
k;a.$A&&a.Be&&(c+1<y?b.scrollTop=(a.position.y-s)*p:s+1<A?b.scrollTop=b.scrollHeight-b.clientHeight:u>n+1&&(b.scrollTop=a.position.y*p));h&&(a.cs=!0);m=a.Pb;c=a.Tb;b.style.width=m+(a.Cl?a.Ud:0)+"px";b.style.height=c+(a.ok?a.Ud:0)+"px";a.dA=!1;return d!==m||e!==c||a.Wa.xc?(n=a.vb,a.Uu(g,n,p,a.scale,h),!1):!0}
E.prototype.add=E.prototype.add=function(a){D.l(a,F,E,"add:part");var b=a.g;if(b!==this){null!==b&&D.k("Cannot add part "+a.toString()+" to "+this.toString()+". It is already a part of "+b.toString());this.$q&&(a.Ml="Tool");var c=a.Mf,b=this.qm(c);null===b&&(b=this.qm(""));null===b&&D.k('Cannot add a Part when unable find a Layer named "'+c+'" and there is no default Layer');a.layer!==b&&(c=b.rq(99999999,a,a.g===this),0<=c&&this.nd(rg,"parts",b,null,a,null,c),b.Wc||this.Nc(),a.N(Vm),c=a.hy,null!==
c&&c(a,null,b))}};
E.prototype.rq=function(a){if(a instanceof G){if(this.Ap.add(a),a instanceof I){var b=a.Ka;null===b?this.Zl.add(a):b.hn.add(a);b=a.Zb;null!==b&&(b.g=this)}}else a instanceof J?this.tp.add(a):a instanceof da||this.sb.add(a);var c=this;Wm(a,function(a){Xm(c,a)});(a instanceof da||a instanceof I&&null!==a.Rb)&&a.K();b=a.data;null!==b&&(a instanceof da||(a instanceof J?this.dk.add(b,a):this.Vi.add(b,a)),Wm(a,function(a){Ym(c,a)}));!0!==Nm(a)&&!0!==Om(a)||this.Hg.add(a);Zm(a,!0,this);$m(a)?(a.Z.F()&&this.ra(wl(a,
a.Z)),this.Nc()):a.isVisible()&&a.Z.F()&&this.ra(wl(a,a.Z));this.Ve()};
E.prototype.xf=function(a){a.au();if(a instanceof G){if(this.Ap.remove(a),a instanceof I){var b=a.Ka;null===b?this.Zl.remove(a):b.hn.remove(a);b=a.Zb;null!==b&&(b.g=null)}}else a instanceof J?this.tp.remove(a):a instanceof da||this.sb.remove(a);var c=this;Wm(a,function(a){an(c,a)});b=a.data;null!==b&&(a instanceof da||(a instanceof J?this.dk.remove(b):this.Vi.remove(b)),Wm(a,function(a){bn(c,a)}));this.Hg.remove(a);$m(a)?(a.Z.F()&&this.ra(wl(a,a.Z)),this.Nc()):a.isVisible()&&a.Z.F()&&this.ra(wl(a,
a.Z));this.Ve()};E.prototype.remove=E.prototype.remove=function(a){D.l(a,F,E,"remove:part");cn(this,a,!0)};function cn(a,b,c){var d=b.layer;null!==d&&d.g===a&&(b.ib=!1,b.$g=!1,b.N(dn),c&&b.On(),c=d.xf(-1,b,!1),0<=c&&a.nd(sg,"parts",d,b,null,c,null),a=b.hy,null!==a&&a(b,d,null))}
E.prototype.removeParts=E.prototype.EB=function(a,b){if(D.isArray(a))for(var c=D.cb(a),d=0;d<c;d++){var e=D.La(a,d);b&&!e.canDelete()||this.remove(e)}else for(e=new L(F),e.Tc(a),c=e.j;c.next();)e=c.value,b&&!e.canDelete()||this.remove(e)};E.prototype.copyParts=E.prototype.eq=function(a,b,c){return this.yb.eq(a,b,c)};
E.prototype.moveParts=E.prototype.moveParts=function(a,b,c){D.l(b,N,E,"moveParts:offset");var d=this.ob;if(null!==d){d=d.Fe;null===d&&(d=new Uh,d.$c(this));var e=new oa(F);if(null!==a)a=a.j;else{for(a=this.Ii;a.next();)gi(d,e,a.value,c);for(a=this.rg;a.next();)gi(d,e,a.value,c);a=this.links}for(;a.next();)gi(d,e,a.value,c);d.moveParts(e,b,c)}};
function en(a,b,c){D.l(b,Yg,E,"addLayer:layer");null!==b.g&&b.g!==a&&D.k("Cannot share a Layer with another Diagram: "+b+" of "+b.g);null===c?null!==b.g&&D.k("Cannot add an existing Layer to this Diagram again: "+b):(D.l(c,Yg,E,"addLayer:existingLayer"),c.g!==a&&D.k("Existing Layer must be in this Diagram: "+c+" not in "+c.g),b===c&&D.k("Cannot move a Layer before or after itself: "+b));if(b.g!==a){b=b.name;a=a.dc;c=a.count;for(var d=0;d<c;d++)a.ea(d).name===b&&D.k("Cannot add Layer with the name '"+
b+"'; a Layer with the same name is already present in this Diagram.")}}E.prototype.addLayer=E.prototype.Rt=function(a){en(this,a,null);a.$c(this);var b=this.dc,c=b.count-1;if(!a.Wc)for(;0<=c&&b.ea(c).Wc;)c--;b.ae(c+1,a);null!==this.xe&&this.nd(rg,"layers",this,null,a,null,c+1);this.ra();this.Nc()};
E.prototype.addLayerBefore=E.prototype.RH=function(a,b){en(this,a,b);a.$c(this);var c=this.dc,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.xe&&this.nd(sg,"layers",this,a,null,d,null));for(var e=c.count,g=0;g<e;g++)if(c.ea(g)===b){c.ae(g,a);break}null!==this.xe&&this.nd(rg,"layers",this,null,a,null,g);this.ra();0>d&&this.Nc()};
E.prototype.addLayerAfter=function(a,b){en(this,a,b);a.$c(this);var c=this.dc,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.xe&&this.nd(sg,"layers",this,a,null,d,null));for(var e=c.count,g=0;g<e;g++)if(c.ea(g)===b){c.ae(g+1,a);break}null!==this.xe&&this.nd(rg,"layers",this,null,a,null,g+1);this.ra();0>d&&this.Nc()};
E.prototype.removeLayer=function(a){D.l(a,Yg,E,"removeLayer:layer");a.g!==this&&D.k("Cannot remove a Layer from another Diagram: "+a+" of "+a.g);if(""!==a.name){var b=this.dc,c=b.indexOf(a);if(b.remove(a)){for(b=a.sb.copy().j;b.next();){var d=b.value,e=d.Mf;d.Mf=e!==a.name?e:""}null!==this.xe&&this.nd(sg,"layers",this,a,null,c,null);this.ra();this.Nc()}}};E.prototype.findLayer=E.prototype.qm=function(a){for(var b=this.bo;b.next();){var c=b.value;if(c.name===a)return c}return null};
E.prototype.addModelChangedListener=E.prototype.TH=function(a){D.h(a,"function",E,"addModelChangedListener:listener");null===this.yh&&(this.yh=new K("function"));this.yh.add(a);this.da.Cn(a)};E.prototype.removeModelChangedListener=function(a){D.h(a,"function",E,"removeModelChangedListener:listener");null!==this.yh&&(this.yh.remove(a),0===this.yh.count&&(this.yh=null));this.da.Xu(a)};
E.prototype.addChangedListener=E.prototype.Cn=function(a){D.h(a,"function",E,"addChangedListener:listener");null===this.Qi&&(this.Qi=new K("function"));this.Qi.add(a)};E.prototype.removeChangedListener=E.prototype.Xu=function(a){D.h(a,"function",E,"removeChangedListener:listener");null!==this.Qi&&(this.Qi.remove(a),0===this.Qi.count&&(this.Qi=null))};
E.prototype.wx=function(a){this.lb||this.la.OF(a);a.Kc!==gg&&(this.Hi=!0);if(null!==this.Qi){var b=this.Qi,c=b.length;if(1===c)b=b.ea(0),b(a);else if(0!==c)for(var d=b.oc(),e=0;e<c;e++)b=d[e],b(a)}};E.prototype.raiseChangedEvent=E.prototype.nd=function(a,b,c,d,e,g,h){void 0===g&&(g=null);void 0===h&&(h=null);var k=new eg;k.g=this;k.Kc=a;k.propertyName=b;k.object=c;k.oldValue=d;k.Sj=g;k.newValue=e;k.Qj=h;this.wx(k)};
E.prototype.raiseChanged=E.prototype.i=function(a,b,c,d,e){this.nd(fg,a,this,b,c,d,e)};D.w(E,{Wa:"animationManager"},function(){return this.wC});D.w(E,{la:"undoManager"},function(){return this.xe.la});D.defineProperty(E,{lb:"skipsUndoManager"},function(){return this.sj},function(a){D.h(a,"boolean",E,"skipsUndoManager");this.sj=a;this.xe.sj=a});D.defineProperty(E,{HA:"delaysLayout"},function(){return this.uz},function(a){this.uz=a});
E.prototype.Gn=function(a,b){if(null!==a&&a.g===this){var c=this.Xe;try{this.Xe=!0;var d=a.Kc,e;if(d===fg){var g=a.object,h=a.propertyName,k=a.oa(b);D.Ta(g,h,k);if(g instanceof P){var l,m=g.Y;null!==m&&m.oe()}this.Hi=!0}else if(d===rg){var n=a.object,p=a.Qj,g=a.newValue;if(n instanceof x)if("number"===typeof p&&g instanceof P){l=g;var q=n;b?q.xf(p):q.ae(p,l);m=n.Y;null!==m&&m.oe()}else{if("number"===typeof p&&g instanceof jh){var r=g,q=n;b?r.Ke?q.FG(p):q.DG(p):(e=r.Ke?q.Zd(r.index):q.Yd(r.index),
e.dq(r))}}else if(n instanceof Yg){var s=!0===a.Sj;if("number"===typeof p&&g instanceof F){var m=g,u=n;b?(m.ib=!1,m.$g=!1,m.oe(),u.xf(s?p:-1,m,s)):u.rq(p,m,s)}}else if(n instanceof E){if("number"===typeof p&&g instanceof Yg){var t=g;b?this.dc.od(p):(d=t,d.$c(this),this.dc.ae(p,d))}}else D.k("unknown ChangedEvent.Insert object: "+a.toString());this.Hi=!0}else d===sg?(n=a.object,p=a.Sj,g=a.oldValue,n instanceof x?"number"===typeof p&&g instanceof P?(q=n,b?q.ae(p,g):q.xf(p)):"number"===typeof p&&g instanceof
jh&&(r=g,q=n,b?(e=r.Ke?q.Zd(r.index):q.Yd(r.index),e.dq(r)):r.Ke?q.FG(p):q.DG(p)):n instanceof Yg?(s=!0===a.Qj,"number"===typeof p&&g instanceof F&&(m=g,u=n,b?u.rq(p,m,s):(m.ib=!1,m.$g=!1,m.oe(),u.xf(s?p:-1,m,s)))):n instanceof E?"number"===typeof p&&g instanceof Yg&&(t=g,b?(d=t,d.$c(this),this.dc.ae(p,d)):this.dc.od(p)):D.k("unknown ChangedEvent.Remove object: "+a.toString()),this.Hi=!0):d!==gg&&D.k("unknown ChangedEvent: "+a.toString())}finally{this.Xe=c}}};
E.prototype.startTransaction=E.prototype.Nb=function(a){return this.la.Nb(a)};E.prototype.commitTransaction=E.prototype.hd=function(a){return this.la.hd(a)};E.prototype.rollbackTransaction=E.prototype.Bm=function(){return this.la.Bm()};E.prototype.commit=function(a,b){var c=b;void 0===c&&(c="");var d=this.lb;null===c&&(this.lb=!0,c="");this.la.Nb(c);var e=!1;try{a(this),e=!0}finally{e?this.la.hd(c):this.la.Bm(),this.lb=d}};
E.prototype.updateAllTargetBindings=E.prototype.pH=function(a){void 0===a&&(a="");for(var b=this.Ii;b.next();)b.value.Ob(a);for(b=this.rg;b.next();)b.value.Ob(a);for(b=this.links;b.next();)b.value.Ob(a)};
E.prototype.updateAllRelationshipsFromData=E.prototype.DK=function(){for(var a=this.da,b=new L,c=a.qg,d=0;d<c.length;d++){var e=c[d];b.add(e)}var g=[];this.rg.each(function(a){null===a.data||b.contains(a.data)||g.push(a.data)});this.Ii.each(function(a){null===a.data||b.contains(a.data)||g.push(a.data)});g.forEach(function(b){eh(a,b,!1)});for(d=0;d<c.length;d++){var e=c[d],h=this.Ph(e);null===h&&dh(a,e,!1)}if(a instanceof Y){for(var k=new L,c=a.ah,d=0;d<c.length;d++)e=c[d],k.add(e);var l=[];this.links.each(function(a){null===
a.data||k.contains(a.data)||l.push(a.data)});l.forEach(function(b){oh(a,b,!1)});for(d=0;d<c.length;d++)e=c[d],h=this.gg(e),null===h&&nh(a,e,!1)}for(d=this.Ii;d.next();)h=d.value,h.updateRelationshipsFromData();for(d=this.rg;d.next();)d.value.updateRelationshipsFromData();for(d=this.links;d.next();)h=d.value,h.updateRelationshipsFromData()};
function fn(a,b,c){if(a.dd||a.je)a.Db=c,c=a.Wa,c.xc&&rl(c,b,a.Db);else if(a.dd=!0,null===a.Mb)a.Db=c;else{var d=a.vb.copy(),e=a.Pb,g=a.Tb;d.width=a.Pb/b;d.height=a.Tb/b;var h=a.Jm.x,k=a.Jm.y,l=a.AA;isNaN(h)&&(l.Lj()?l.Ij(xc)?h=0:l.Ij(yc)&&(h=e-1):h=l.Vc()?l.x*(e-1):e/2);isNaN(k)&&(l.Lj()?l.Ij(wc)?k=0:l.Ij(Dc)&&(k=g-1):k=l.Vc()?l.y*(g-1):g/2);null!==a.PG&&(c=a.PG(a,c));c<a.Sh&&(c=a.Sh);c>a.Rh&&(c=a.Rh);e=D.Fb(a.tb.x+h/b-h/c,a.tb.y+k/b-k/c);a.position=e;D.A(e);a.Db=c;a.Uu(d,a.vb,b,c,!1);a.dd=!1;Xl(a,
!1);c=a.Wa;c.xc&&rl(c,b,a.Db);a.ra();hm(a)}}
E.prototype.Uu=function(a,b,c,d,e){a.O(b)||(void 0===e&&(e=!1),e||hm(this),Vl(this),d=this.Zb,null===d||!d.by||this.hm!==Vh||e||a.width===b.width&&a.height===b.height||d.N(),d=this.hb,!0===this.Hl&&d instanceof Ph&&(this.U.ga=this.dC(this.U.se),Wk(d,this)),this.dd||this.TF(a,b),km(this),this.Hh.scale=c,this.Hh.position.x=a.x,this.Hh.position.y=a.y,this.Hh.bounds.set(a),this.Hh.isScroll=e,this.Ja("ViewportBoundsChanged",this.Hh,a),this.hB&&this.links.each(function(a){a.Jj&&a.Z.jg(b)&&a.ec()}))};
function km(a,b){void 0===b&&(b=null);var c=a.Cd;if(null!==c&&c.visible){for(var d=D.Gm(),e=1,g=1,h=c.xa.o,k=h.length,l=0;l<k;l++){var m=h[l],n=m.interval;2>n||(gn(m.Jb)?g=g*n/Te(g,n):e=e*n/Te(e,n))}h=c.Sx;d.n(g*h.width,e*h.height);h=g=l=k=0;if(null!==b)k=b.width,l=b.height,g=b.x,h=b.y;else{e=D.Qf();g=a.vb;e.n(g.x,g.y,g.width,g.height);for(h=a.Gw.j;h.next();)g=h.value.vb,g.F()&&Ub(e,g.x,g.y,g.width,g.height);if(!e.F()){D.Kb(e);return}k=e.width;l=e.height;g=e.x;h=e.y;D.Kb(e)}c.width=k+2*d.width;c.height=
l+2*d.height;e=D.P();jb(g,h,0,0,d.width,d.height,e);e.offset(-d.width,-d.height);D.Uk(d);c.Y.location=e;D.A(e)}}E.prototype.clearSelection=E.prototype.yx=function(){var a=0<this.selection.count;a&&this.Ja("ChangingSelection");Th(this);a&&this.Ja("ChangedSelection")};function Th(a){a=a.selection;if(0<a.count){for(var b=a.oc(),c=b.length,d=0;d<c;d++)b[d].ib=!1;a.Xa();a.clear();a.freeze()}}
E.prototype.select=E.prototype.select=function(a){null!==a&&(D.l(a,F,E,"select:part"),a.layer.g===this&&(!a.ib||1<this.selection.count)&&(this.Ja("ChangingSelection"),Th(this),a.ib=!0,this.Ja("ChangedSelection")))};
E.prototype.selectCollection=E.prototype.TG=function(a){this.Ja("ChangingSelection");Th(this);if(D.isArray(a))for(var b=D.cb(a),c=0;c<b;c++){var d=D.La(a,c);d instanceof F||D.k("Diagram.selectCollection given something that is not a Part: "+d);d.ib=!0}else for(a=a.j;a.next();)d=a.value,d instanceof F||D.k("Diagram.selectCollection given something that is not a Part: "+d),d.ib=!0;this.Ja("ChangedSelection")};
E.prototype.clearHighlighteds=E.prototype.aI=function(){var a=this.wm;if(0<a.count){for(var b=a.oc(),c=b.length,d=0;d<c;d++)b[d].$g=!1;a.Xa();a.clear();a.freeze()}};E.prototype.highlight=function(a){null!==a&&a.layer.g===this&&(D.l(a,F,E,"highlight:part"),!a.$g||1<this.wm.count)&&(this.aI(),a.$g=!0)};
E.prototype.highlightCollection=function(a){for(var b=(new L(F)).Tc(a),c=this.wm.copy().Ay(b).j;c.next();)a=c.value,a.$g=!1;for(b=b.j;b.next();)a=b.value,a instanceof F||D.k("Diagram.highlightCollection given something that is not a Part: "+a),a.$g=!0};
E.prototype.scroll=E.prototype.scroll=function(a,b,c){void 0===c&&(c=1);var d="up"===b||"down"===b,e=0;if("pixel"===a)e=c;else if("line"===a)e=c*(d?this.cv:this.bv);else if("page"===a)a=d?this.vb.height:this.vb.width,a*=this.scale,0!==a&&(e=Math.max(a-(d?this.cv:this.bv),0),e*=c);else{if("document"===a){e=this.jd;d=this.vb;c=D.P();"up"===b?this.position=c.n(d.x,e.y):"left"===b?this.position=c.n(e.x,d.y):"down"===b?this.position=c.n(d.x,e.bottom-d.height):"right"===b&&(this.position=c.n(e.right-d.width,
d.y));D.A(c);return}D.k("scrolling unit must be 'pixel', 'line', 'page', or 'document', not: "+a)}e/=this.scale;c=this.position.copy();"up"===b?c.y=this.position.y-e:"down"===b?c.y=this.position.y+e:"left"===b?c.x=this.position.x-e:"right"===b?c.x=this.position.x+e:D.k("scrolling direction must be 'up', 'down', 'left', or 'right', not: "+b);this.position=c};E.prototype.scrollToRect=E.prototype.QG=function(a){var b=this.vb;b.Pk(a)||(a=a.im,a.x-=b.width/2,a.y-=b.height/2,this.position=a)};
E.prototype.centerRect=E.prototype.UE=function(a){var b=this.vb;a=a.im;a.x-=b.width/2;a.y-=b.height/2;this.position=a};E.prototype.transformDocToView=E.prototype.cC=function(a){var b=this.Jc;b.reset();1!==this.Db&&b.scale(this.Db);var c=this.tb;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return a.copy().transform(this.Jc)};
E.prototype.transformViewToDoc=E.prototype.dC=function(a){var b=this.Jc;b.reset();1!==this.Db&&b.scale(this.Db);var c=this.tb;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return kb(a.copy(),this.Jc)};var Vh;E.None=Vh=D.s(E,"None",0);var am;E.Uniform=am=D.s(E,"Uniform",1);var bm;E.UniformToFill=bm=D.s(E,"UniformToFill",2);var fj;E.CycleAll=fj=D.s(E,"CycleAll",10);var jj;E.CycleNotDirected=jj=D.s(E,"CycleNotDirected",11);var lj;
E.CycleNotDirectedFast=lj=D.s(E,"CycleNotDirectedFast",12);var mj;E.CycleNotUndirected=mj=D.s(E,"CycleNotUndirected",13);var gj;E.CycleDestinationTree=gj=D.s(E,"CycleDestinationTree",14);var ij;E.CycleSourceTree=ij=D.s(E,"CycleSourceTree",15);var Bl;E.DocumentScroll=Bl=D.s(E,"DocumentScroll",1);var Tm;E.InfiniteScroll=Tm=D.s(E,"InfiniteScroll",2);var Dl;E.TreeParentCollapsed=Dl=D.s(E,"TreeParentCollapsed",21);var hn;E.AllParentsCollapsed=hn=D.s(E,"AllParentsCollapsed",22);var jn;
E.AnyParentsCollapsed=jn=D.s(E,"AnyParentsCollapsed",23);D.defineProperty(E,{EK:"validCycle"},function(){return this.ix},function(a){var b=this.ix;b!==a&&(D.Da(a,E,E,"validCycle"),this.ix=a,this.i("validCycle",b,a))});D.w(E,{bo:"layers"},function(){return this.dc.j});D.defineProperty(E,{Lf:"isModelReadOnly"},function(){var a=this.xe;return null===a?!1:a.rb},function(a){var b=this.xe;null!==b&&(b.rb=a)});
D.defineProperty(E,{rb:"isReadOnly"},function(){return this.bj},function(a){var b=this.bj;b!==a&&(D.h(a,"boolean",E,"isReadOnly"),this.bj=a,this.i("isReadOnly",b,a))});D.defineProperty(E,{isEnabled:"isEnabled"},function(){return this.qf},function(a){var b=this.qf;b!==a&&(D.h(a,"boolean",E,"isEnabled"),this.qf=a,this.i("isEnabled",b,a))});
D.defineProperty(E,{tA:"allowClipboard"},function(){return this.rv},function(a){var b=this.rv;b!==a&&(D.h(a,"boolean",E,"allowClipboard"),this.rv=a,this.i("allowClipboard",b,a))});D.defineProperty(E,{Mk:"allowCopy"},function(){return this.hl},function(a){var b=this.hl;b!==a&&(D.h(a,"boolean",E,"allowCopy"),this.hl=a,this.i("allowCopy",b,a))});
D.defineProperty(E,{Dn:"allowDelete"},function(){return this.il},function(a){var b=this.il;b!==a&&(D.h(a,"boolean",E,"allowDelete"),this.il=a,this.i("allowDelete",b,a))});D.defineProperty(E,{Wt:"allowDragOut"},function(){return this.sv},function(a){var b=this.sv;b!==a&&(D.h(a,"boolean",E,"allowDragOut"),this.sv=a,this.i("allowDragOut",b,a))});
D.defineProperty(E,{NE:"allowDrop"},function(){return this.tv},function(a){var b=this.tv;b!==a&&(D.h(a,"boolean",E,"allowDrop"),this.tv=a,this.i("allowDrop",b,a))});D.defineProperty(E,{tx:"allowTextEdit"},function(){return this.rl},function(a){var b=this.rl;b!==a&&(D.h(a,"boolean",E,"allowTextEdit"),this.rl=a,this.i("allowTextEdit",b,a))});
D.defineProperty(E,{px:"allowGroup"},function(){return this.jl},function(a){var b=this.jl;b!==a&&(D.h(a,"boolean",E,"allowGroup"),this.jl=a,this.i("allowGroup",b,a))});D.defineProperty(E,{ux:"allowUngroup"},function(){return this.sl},function(a){var b=this.sl;b!==a&&(D.h(a,"boolean",E,"allowUngroup"),this.sl=a,this.i("allowUngroup",b,a))});
D.defineProperty(E,{Wp:"allowInsert"},function(){return this.vv},function(a){var b=this.vv;b!==a&&(D.h(a,"boolean",E,"allowInsert"),this.vv=a,this.i("allowInsert",b,a))});D.defineProperty(E,{Xt:"allowLink"},function(){return this.kl},function(a){var b=this.kl;b!==a&&(D.h(a,"boolean",E,"allowLink"),this.kl=a,this.i("allowLink",b,a))});
D.defineProperty(E,{En:"allowRelink"},function(){return this.ml},function(a){var b=this.ml;b!==a&&(D.h(a,"boolean",E,"allowRelink"),this.ml=a,this.i("allowRelink",b,a))});D.defineProperty(E,{em:"allowMove"},function(){return this.ll},function(a){var b=this.ll;b!==a&&(D.h(a,"boolean",E,"allowMove"),this.ll=a,this.i("allowMove",b,a))});
D.defineProperty(E,{qx:"allowReshape"},function(){return this.nl},function(a){var b=this.nl;b!==a&&(D.h(a,"boolean",E,"allowReshape"),this.nl=a,this.i("allowReshape",b,a))});D.defineProperty(E,{Yt:"allowResize"},function(){return this.ol},function(a){var b=this.ol;b!==a&&(D.h(a,"boolean",E,"allowResize"),this.ol=a,this.i("allowResize",b,a))});
D.defineProperty(E,{sx:"allowRotate"},function(){return this.pl},function(a){var b=this.pl;b!==a&&(D.h(a,"boolean",E,"allowRotate"),this.pl=a,this.i("allowRotate",b,a))});D.defineProperty(E,{If:"allowSelect"},function(){return this.ql},function(a){var b=this.ql;b!==a&&(D.h(a,"boolean",E,"allowSelect"),this.ql=a,this.i("allowSelect",b,a))});
D.defineProperty(E,{OE:"allowUndo"},function(){return this.wv},function(a){var b=this.wv;b!==a&&(D.h(a,"boolean",E,"allowUndo"),this.wv=a,this.i("allowUndo",b,a))});D.defineProperty(E,{vx:"allowZoom"},function(){return this.yv},function(a){var b=this.yv;b!==a&&(D.h(a,"boolean",E,"allowZoom"),this.yv=a,this.i("allowZoom",b,a))});
D.defineProperty(E,{$A:"hasVerticalScrollbar"},function(){return this.aw},function(a){var b=this.aw;b!==a&&(D.h(a,"boolean",E,"hasVerticalScrollbar"),this.aw=a,hm(this),this.ra(),this.i("hasVerticalScrollbar",b,a),Xl(this,!1))});D.defineProperty(E,{ZA:"hasHorizontalScrollbar"},function(){return this.$v},function(a){var b=this.$v;b!==a&&(D.h(a,"boolean",E,"hasHorizontalScrollbar"),this.$v=a,hm(this),this.ra(),this.i("hasHorizontalScrollbar",b,a),Xl(this,!1))});
D.defineProperty(E,{Ae:"allowHorizontalScroll"},function(){return this.uv},function(a){var b=this.uv;b!==a&&(D.h(a,"boolean",E,"allowHorizontalScroll"),this.uv=a,this.i("allowHorizontalScroll",b,a),Xl(this,!1))});D.defineProperty(E,{Be:"allowVerticalScroll"},function(){return this.xv},function(a){var b=this.xv;b!==a&&(D.h(a,"boolean",E,"allowVerticalScroll"),this.xv=a,this.i("allowVerticalScroll",b,a),Xl(this,!1))});
D.defineProperty(E,{bv:"scrollHorizontalLineChange"},function(){return this.Pw},function(a){var b=this.Pw;b!==a&&(D.h(a,"number",E,"scrollHorizontalLineChange"),0>a&&D.ua(a,">= 0",E,"scrollHorizontalLineChange"),this.Pw=a,this.i("scrollHorizontalLineChange",b,a))});
D.defineProperty(E,{cv:"scrollVerticalLineChange"},function(){return this.Tw},function(a){var b=this.Tw;b!==a&&(D.h(a,"number",E,"scrollVerticalLineChange"),0>a&&D.ua(a,">= 0",E,"scrollVerticalLineChange"),this.Tw=a,this.i("scrollVerticalLineChange",b,a))});D.defineProperty(E,{U:"lastInput"},function(){return this.Vb},function(a){v&&D.l(a,bg,E,"lastInput");this.Vb=a});D.defineProperty(E,{Lc:"firstInput"},function(){return this.$i},function(a){v&&D.l(a,bg,E,"firstInput");this.$i=a});
D.defineProperty(E,{mc:"currentCursor"},function(){return this.Iv},function(a){""===a&&(a=this.Er);var b=this.Iv;if(b!==a){D.h(a,"string",E,"currentCursor");var c=this.Mb,d=this.Ub;null!==c&&(this.Iv=a,c.style.cursor=a,d.style.cursor=a,c.style.cursor===b&&(c.style.cursor="-webkit-"+a,d.style.cursor="-webkit-"+a,c.style.cursor===b&&(c.style.cursor="-moz-"+a,d.style.cursor="-moz-"+a,c.style.cursor===b&&(c.style.cursor=a,d.style.cursor=a))))}});
D.defineProperty(E,{tL:"defaultCursor"},function(){return this.Er},function(a){""===a&&(a="auto");var b=this.Er;b!==a&&(D.h(a,"string",E,"defaultCursor"),this.Er=a,this.i("defaultCursor",b,a))});D.defineProperty(E,{click:"click"},function(){return this.Ri},function(a){var b=this.Ri;b!==a&&(null!==a&&D.h(a,"function",E,"click"),this.Ri=a,this.i("click",b,a))});
D.defineProperty(E,{fu:"doubleClick"},function(){return this.Yi},function(a){var b=this.Yi;b!==a&&(null!==a&&D.h(a,"function",E,"doubleClick"),this.Yi=a,this.i("doubleClick",b,a))});D.defineProperty(E,{BA:"contextClick"},function(){return this.Ti},function(a){var b=this.Ti;b!==a&&(null!==a&&D.h(a,"function",E,"contextClick"),this.Ti=a,this.i("contextClick",b,a))});
D.defineProperty(E,{sB:"mouseOver"},function(){return this.lj},function(a){var b=this.lj;b!==a&&(null!==a&&D.h(a,"function",E,"mouseOver"),this.lj=a,this.i("mouseOver",b,a))});D.defineProperty(E,{rB:"mouseHover"},function(){return this.kj},function(a){var b=this.kj;b!==a&&(null!==a&&D.h(a,"function",E,"mouseHover"),this.kj=a,this.i("mouseHover",b,a))});
D.defineProperty(E,{qB:"mouseHold"},function(){return this.jj},function(a){var b=this.jj;b!==a&&(null!==a&&D.h(a,"function",E,"mouseHold"),this.jj=a,this.i("mouseHold",b,a))});D.defineProperty(E,{PJ:"mouseDragOver"},function(){return this.zw},function(a){var b=this.zw;b!==a&&(null!==a&&D.h(a,"function",E,"mouseDragOver"),this.zw=a,this.i("mouseDragOver",b,a))});
D.defineProperty(E,{pB:"mouseDrop"},function(){return this.ij},function(a){var b=this.ij;b!==a&&(null!==a&&D.h(a,"function",E,"mouseDrop"),this.ij=a,this.i("mouseDrop",b,a))});D.defineProperty(E,{ZB:"toolTip"},function(){return this.tj},function(a){var b=this.tj;b!==a&&(!v||null===a||a instanceof da||a instanceof gk||D.k("Diagram.toolTip must be an Adornment or HTMLInfo."),this.tj=a,this.i("toolTip",b,a))});
D.defineProperty(E,{contextMenu:"contextMenu"},function(){return this.Ui},function(a){var b=this.Ui;b!==a&&(!v||a instanceof da||a instanceof gk||D.k("Diagram.contextMenu must be an Adornment or HTMLInfo."),this.Ui=a,this.i("contextMenu",b,a))});D.defineProperty(E,{yb:"commandHandler"},function(){return this.mz},function(a){var b=this.mz;b!==a&&(D.l(a,qa,E,"commandHandler"),null!==a.g&&D.k("Cannot share CommandHandlers between Diagrams: "+a.toString()),null!==b&&b.$c(null),this.mz=a,a.$c(this))});
D.defineProperty(E,{ob:"toolManager"},function(){return this.mA},function(a){var b=this.mA;b!==a&&(D.l(a,Ph,E,"toolManager"),null!==a.g&&D.k("Cannot share ToolManagers between Diagrams: "+a.toString()),null!==b&&b.$c(null),this.mA=a,a.$c(this))});D.defineProperty(E,{eu:"defaultTool"},function(){return this.sz},function(a){var b=this.sz;b!==a&&(D.l(a,Zg,E,"defaultTool"),this.sz=a,this.hb===b&&(this.hb=a))});
D.defineProperty(E,{hb:"currentTool"},function(){return this.pz},function(a){var b=this.pz;null!==b&&(b.wa&&b.doDeactivate(),b.cancelWaitAfter(),b.doStop());null===a&&(a=this.eu);null!==a&&(D.l(a,Zg,E,"currentTool"),this.pz=a,a.$c(this),a.doStart())});D.w(E,{selection:"selection"},function(){return this.Ww});
D.defineProperty(E,{GJ:"maxSelectionCount"},function(){return this.uw},function(a){var b=this.uw;if(b!==a)if(D.h(a,"number",E,"maxSelectionCount"),0<=a&&!isNaN(a)){if(this.uw=a,this.i("maxSelectionCount",b,a),!this.la.jb&&(a=this.selection.count-a,0<a)){this.Ja("ChangingSelection");for(var b=this.selection.oc(),c=0;c<a;c++)b[c].ib=!1;this.Ja("ChangedSelection")}}else D.ua(a,">= 0",E,"maxSelectionCount")});
D.defineProperty(E,{RJ:"nodeSelectionAdornmentTemplate"},function(){return this.Aw},function(a){var b=this.Aw;b!==a&&(D.l(a,da,E,"nodeSelectionAdornmentTemplate"),this.Aw=a,this.i("nodeSelectionAdornmentTemplate",b,a))});D.defineProperty(E,{XI:"groupSelectionAdornmentTemplate"},function(){return this.Yv},function(a){var b=this.Yv;b!==a&&(D.l(a,da,E,"groupSelectionAdornmentTemplate"),this.Yv=a,this.i("groupSelectionAdornmentTemplate",b,a))});
D.defineProperty(E,{AJ:"linkSelectionAdornmentTemplate"},function(){return this.pw},function(a){var b=this.pw;b!==a&&(D.l(a,da,E,"linkSelectionAdornmentTemplate"),this.pw=a,this.i("linkSelectionAdornmentTemplate",b,a))});D.w(E,{wm:"highlighteds"},function(){return this.bw});
D.defineProperty(E,{Hi:"isModified"},function(){var a=this.la;return a.isEnabled?null!==a.Cj?!0:this.hw&&this.uh!==a.Hj:this.hw},function(a){if(this.hw!==a){D.h(a,"boolean",E,"isModified");this.hw=a;var b=this.la;!a&&b.isEnabled&&(this.uh=b.Hj);a||kn(this)}});function kn(a){var b=a.Hi;a.DE!==b&&(a.DE=b,a.Ja("Modified"))}
D.defineProperty(E,{da:"model"},function(){return this.xe},function(a){var b=this.xe;if(b!==a){D.l(a,M,E,"model");this.hb.doCancel();null!==b&&b.la!==a.la&&b.la.aG&&D.k("Do not replace a Diagram.model while a transaction is in progress.");this.Wa.Yh();this.yx();this.vh=!1;this.bp=!0;this.uh=-2;this.cg=!1;var c=this.je;this.je=!0;this.Wa.po("Model");null!==b&&(null!==this.yh&&this.yh.each(function(a){b.Xu(a)}),b.Xu(this.KD),b instanceof Y&&ln(this,b.ah),ln(this,b.qg));this.xe=a;a.Cn(this.JD);mn(this,
a.qg);a instanceof Y&&nn(this,a.ah);a.Xu(this.JD);a.Cn(this.KD);null!==this.yh&&this.yh.each(function(b){a.Cn(b)});this.je=c;this.dd||this.ra();null!==b&&(a.la.isEnabled=b.la.isEnabled)}});D.defineProperty(E,{$a:null},function(){return this.AD},function(a){this.AD=a});D.w(E,{ky:null},function(){return this.BH});
function Fl(a,b){if(b.da===a.da){var c=b.Kc,d=b.propertyName;if(c===gg&&"S"===d[0])if("StartingFirstTransaction"===d)c=a.ob,c.pf.each(function(b){b.$c(a)}),c.mg.each(function(b){b.$c(a)}),c.ng.each(function(b){b.$c(a)}),a.je||a.vh||(a.Pv=!0,a.bp&&(a.cg=!0));else if("StartingUndo"===d||"StartingRedo"===d){var e=a.Wa;e.yf&&!a.lb&&e.Yh();a.Ja("ChangingSelection")}else"StartedTransaction"===d&&(e=a.Wa,e.yf&&!a.lb&&e.Yh());else if(a.$a){a.$a=!1;try{var g=b.Cf;if(""!==g)if(c===fg){if("linkFromKey"===g){var h=
b.object,k=a.gg(h);if(null!==k){var l=b.newValue,m=a.Se(l);k.aa=m}}else if("linkToKey"===g)h=b.object,k=a.gg(h),null!==k&&(l=b.newValue,m=a.Se(l),k.ba=m);else if("linkFromPortId"===g){if(h=b.object,k=a.gg(h),null!==k){var n=b.newValue;"string"===typeof n&&(k.hg=n)}}else if("linkToPortId"===g)h=b.object,k=a.gg(h),null!==k&&(n=b.newValue,"string"===typeof n&&(k.mh=n));else if("nodeGroupKey"===g){var h=b.object,p=a.Ph(h);if(null!==p){var q=b.newValue;if(void 0!==q){var r=a.Se(q);p.Ka=r instanceof I?
r:null}else p.Ka=null}}else if("linkLabelKeys"===g){if(h=b.object,k=a.gg(h),null!==k){var s=b.oldValue,u=b.newValue;if(D.isArray(s))for(var t=D.cb(s),y=0;y<t;y++){var w=D.La(s,y),m=a.Se(w);null!==m&&(m.Yb=null)}if(D.isArray(u))for(t=D.cb(u),y=0;y<t;y++)w=D.La(u,y),m=a.Se(w),null!==m&&(m.Yb=k)}}else if("nodeParentKey"===g){var B=b.object,A=a.Se(b.newValue),O=a.Nx(B);if(null!==O){var H=O.Rn();null!==H?null===A?a.remove(H):a.de?H.aa=A:H.ba=A:on(a,A,O)}}else if("parentLinkCategory"===g){var B=b.object,
O=a.Nx(B),R=b.newValue;null!==O&&"string"===typeof R&&(H=O.Rn(),null!==H&&(H.Gd=R))}else if("nodeCategory"===g){var h=b.object,aa=a.Ph(h),R=b.newValue;null!==aa&&"string"===typeof R&&(aa.Gd=R)}else if("linkCategory"===g){var h=b.object,V=a.gg(h),R=b.newValue;null!==V&&"string"===typeof R&&(V.Gd=R)}else if("nodeDataArray"===g){var ea=b.oldValue;ln(a,ea);var la=b.newValue;mn(a,la)}else"linkDataArray"===g&&(ea=b.oldValue,ln(a,ea),la=b.newValue,nn(a,la));a.Hi=!0}else c===rg?(la=b.newValue,"nodeDataArray"===
g&&D.Qa(la)?pn(a,la):"linkDataArray"===g&&D.Qa(la)?qn(a,la):"linkLabelKeys"===g&&ch(la)&&(k=a.gg(b.object),m=a.Se(la),null!==k&&null!==m&&(m.Yb=k)),a.Hi=!0):c===sg?(ea=b.oldValue,"nodeDataArray"===g&&D.Qa(ea)?rn(a,ea):"linkDataArray"===g&&D.Qa(ea)?rn(a,ea):"linkLabelKeys"===g&&ch(ea)&&(m=a.Se(ea),null!==m&&(m.Yb=null)),a.Hi=!0):c===gg&&("SourceChanged"===g?null!==b.object?El(a,b.object,b.propertyName):(a.DK(),a.pH()):"ModelDisplaced"===g&&a.Am());else if(c===fg){var Da=b.propertyName,h=b.object;if(h===
a.da){if("nodeKeyProperty"===Da||"nodeCategoryProperty"===Da||"linkFromKeyProperty"===Da||"linkToKeyProperty"===Da||"linkFromPortIdProperty"===Da||"linkToPortIdProperty"===Da||"linkLabelKeysProperty"===Da||"nodeIsGroupProperty"===Da||"nodeGroupKeyProperty"===Da||"nodeParentKeyProperty"===Da||"linkCategoryProperty"===Da)a.la.jb||a.Am()}else El(a,h,Da);a.Hi=!0}else if(c===rg||c===sg)sn(a,b),a.Hi=!0;else if(c===gg){if("FinishedUndo"===d||"FinishedRedo"===d)a.la.Wf=!0,a.Ja("ChangedSelection"),Si(a),a.la.Wf=
!1;e=a.Wa;"RolledBackTransaction"===d&&e.Yh();a.Pv=!0;a.lg();0===a.la.Ki&&dl(e);"CommittedTransaction"===d&&a.la.Rz&&(a.uh=Math.min(a.uh,a.la.Hj-1));var La=b.eG;La&&(kn(a),a.ky.clear());!a.Xz&&La&&(a.Xz=!0,D.setTimeout(function(){a.hb.standardMouseOver();a.Xz=!1},10))}}finally{a.$a=!0}}}}
function El(a,b,c){if("string"===typeof c){var d=a.Ph(b);if(null!==d)d.Ob(c),a.da instanceof Bg&&(d=a.gg(b),null!==d&&d.Ob(c));else{for(var d=null,e=a.Zm.j;e.next();){for(var g=e.value,h=0;h<g.length;h++){var k=g[h].HI(b);null!==k&&(null===d&&(d=D.nb()),d.push(k))}if(null!==d)break}if(null!==d){for(e=0;e<d.length;e++)d[e].Ob(c);D.ya(d)}}b===a.da.bl&&a.pH(c)}}D.defineProperty(E,{Xe:"skipsModelSourceBindings"},function(){return this.eE},function(a){this.eE=a});
D.defineProperty(E,{hv:null},function(){return this.hA},function(a){this.hA=a});function sn(a,b){var c=b.Kc===rg,d=c?b.Qj:b.Sj,e=c?b.newValue:b.oldValue,g=a.Zm.oa(b.object);if(Array.isArray(g))for(var h=0;h<g.length;h++){var k=g[h];if(c)tn(k,e,d);else{var l=d;if(!(0>l)){var m=l;un(k)&&m++;k.xf(m,!0);vn(k,m,l)}}}}function Ym(a,b){var c=b.cj;if(D.isArray(c)){var d=a.Zm.oa(c);if(null===d)d=[],d.push(b),a.Zm.add(c,d);else{for(c=0;c<d.length;c++)if(d[c]===b)return;d.push(b)}}}
function bn(a,b){var c=b.cj;if(D.isArray(c)){var d=a.Zm.oa(c);if(null!==d)for(var e=0;e<d.length;e++)if(d[e]===b){d.splice(e,1);0===d.length&&a.Zm.remove(c);break}}}function Xm(a,b){for(var c=b.xa.o,d=c.length,e=0;e<d;e++){var g=c[e];g instanceof Gl&&wn(a,g)}}
function wn(a,b){var c=b.element;if(null!==c&&c instanceof HTMLImageElement){var d=b.bf;null!==d&&(d.ep instanceof Event&&null!==b.cf&&b.cf(b,d.ep),!0===d.fs&&(null!==b.zi&&b.zi(b,d.cA),null!==b.g&&b.g.$z.add(b)));c=c.src;d=a.Cp.oa(c);if(null===d)d=[],d.push(b),a.Cp.add(c,d);else{for(c=0;c<d.length;c++)if(d[c]===b)return;d.push(b)}}}function an(a,b){for(var c=b.xa.o,d=c.length,e=0;e<d;e++){var g=c[e];g instanceof Gl&&xn(a,g)}}
function xn(a,b){var c=b.element;if(null!==c&&c instanceof HTMLImageElement){var c=c.src,d=a.Cp.oa(c);if(null!==d)for(var e=0;e<d.length;e++)if(d[e]===b){d.splice(e,1);0===d.length&&a.Cp.remove(c);break}}}
E.prototype.rebuildParts=E.prototype.Am=function(){for(var a=this.wB.j;a.next();){var b=a.value,c=a.key;(!b.pe()||b instanceof I)&&D.k('Invalid node template in Diagram.nodeTemplateMap: template for "'+c+'" must be a Node or a simple Part, not a Group or Link: '+b)}for(a=this.YA.j;a.next();)b=a.value,c=a.key,b instanceof I||D.k('Invalid group template in Diagram.groupTemplateMap: template for "'+c+'" must be a Group, not a normal Node or Link: '+b);for(a=this.kB.j;a.next();)b=a.value,c=a.key,b instanceof
J||D.k('Invalid link template in Diagram.linkTemplateMap: template for "'+c+'" must be a Link, not a normal Node or simple Part: '+b);a=D.nb();for(b=this.selection.j;b.next();)(c=b.value.data)&&a.push(c);for(var b=D.nb(),d=this.wm.j;d.next();)(c=d.value.data)&&b.push(c);c=D.nb();for(d=this.rg.j;d.next();){var e=d.value;null!==e.data&&(c.push(e.data),c.push(e.location))}for(d=this.links.j;d.next();)e=d.value,null!==e.data&&(c.push(e.data),c.push(e.location));for(d=this.Ii.j;d.next();)e=d.value,null!==
e.data&&(c.push(e.data),c.push(e.location));d=this.da;d instanceof Y&&ln(this,d.ah);ln(this,d.qg);mn(this,d.qg);d instanceof Y&&nn(this,d.ah);for(d=0;d<a.length;d++)e=this.Ph(a[d]),null!==e&&(e.ib=!0);for(d=0;d<b.length;d++)e=this.Ph(b[d]),null!==e&&(e.$g=!0);for(d=0;d<c.length;d+=2)e=this.Ph(c[d]),null!==e&&(e.location=c[d+1]);D.ya(a);D.ya(b);D.ya(c)};
function mn(a,b){if(null!==b){for(var c=a.da,d=c instanceof Y,e=D.cb(b),g=0;g<e;g++){var h=D.La(b,g);c.le(h)?pn(a,h,!1):d&&qn(a,h)}if(d||c instanceof Bg){for(g=0;g<e;g++)h=D.La(b,g),c.le(h)&&yn(a,h);if(d)for(c=a.links;c.next();)zn(c.value)}An(a,!1)}}function pn(a,b,c){if(void 0!==b&&null!==b&&!a.la.jb&&!a.Vi.contains(b)){void 0===c&&(c=!0);var d=a.UA(b),e=Bn(a,b,d);if(null!==e&&(Sh(e),e=e.copy(),null!==e)){var g=a.Xe;a.Xe=!0;e.Pi=d;e.Pd=b;a.add(e);e.Pd=null;e.data=b;c&&yn(a,b);a.Xe=g}}}
E.prototype.UA=function(a){return this.da.UA(a)};var Cn=!1,Dn=!1;function Bn(a,b,c){var d=!1,e=a.da;e instanceof Y&&(d=e.fB(b));d?(b=a.YA.oa(c),null===b&&(b=a.YA.oa(""),null===b&&(Dn||(Dn=!0,D.trace('No Group template found for category "'+c+'"'),D.trace("  Using default group template")),b=a.SC))):(b=a.wB.oa(c),null===b&&(b=a.wB.oa(""),null===b&&(Cn||(Cn=!0,D.trace('No Node template found for category "'+c+'"'),D.trace("  Using default node template")),b=a.UC)));return b}
function yn(a,b){var c=a.da;if(c instanceof Y||c instanceof Bg){var d=c.zb(b);if(void 0!==d){var e=fh(c,d),g=a.Ph(b);if(null!==e&&null!==g){for(e=e.j;e.next();){var h=e.value;if(c instanceof Y){var k=c;if(k.le(h)){if(g instanceof I&&k.Tn(h)===d){var l=g,h=a.Ph(h);null!==h&&(h.Ka=l)}}else{var m=a.gg(h);if(null!==m&&g instanceof G&&(l=g,k.um(h)===d&&(m.aa=l),k.vm(h)===d&&(m.ba=l),h=k.Vk(h),D.isArray(h)))for(k=0;k<D.cb(h);k++)if(D.La(h,k)===d){l.Yb=m;break}}}else c instanceof Bg&&(m=c,m.le(h)&&g instanceof
G&&(l=g,m.Vn(h)===d&&(h=a.Nx(h),on(a,l,h))))}hh(c,d)}c instanceof Y?(c=c.Tn(b),void 0!==c&&(c=a.Se(c),c instanceof I&&(g.Ka=c))):c instanceof Bg&&(c=c.Vn(b),void 0!==c&&g instanceof G&&(l=g,g=a.Se(c),on(a,g,l)))}}}
function on(a,b,c){if(null!==b&&null!==c){var d=a.ob.lG,e=b,g=c;if(a.de)for(b=g.Kd;b.next();){if(b.value.ba===g)return}else for(e=c,g=b,b=e.Kd;b.next();)if(b.value.aa===e)return;if(null===d||!hj(d,e,g,null,!0))if(d=a.Qx(c.data),b=En(a,d),null!==b&&(Sh(b),b=b.copy(),null!==b)){var h=a.Xe;a.Xe=!0;b.Pi=d;b.Pd=c.data;b.aa=e;b.ba=g;a.add(b);b.Pd=null;b.data=c.data;a.Xe=h}}}function nn(a,b){if(null!==b){for(var c=D.cb(b),d=0;d<c;d++){var e=D.La(b,d);qn(a,e)}An(a,!1)}}
function qn(a,b){if(void 0!==b&&null!==b&&!a.la.jb&&!a.dk.contains(b)){var c=a.Qx(b),d=En(a,c);if(null!==d&&(Sh(d),d=d.copy(),null!==d)){var e=a.Xe;a.Xe=!0;d.Pi=c;d.Pd=b;var c=a.da,g=c.OI(b);""!==g&&(d.hg=g);g=c.um(b);void 0!==g&&(g=a.Se(g),g instanceof G&&(d.aa=g));g=c.TI(b);""!==g&&(d.mh=g);g=c.vm(b);void 0!==g&&(g=a.Se(g),g instanceof G&&(d.ba=g));c=c.Vk(b);if(D.isArray(c))for(var g=D.cb(c),h=0;h<g;h++){var k=D.La(c,h),k=a.Se(k);null!==k&&(k.Yb=d)}a.add(d);d.Pd=null;d.data=b;a.Xe=e}}}
E.prototype.Qx=function(a){var b=this.da,c="";b instanceof Y?c=b.Qx(a):b instanceof Bg&&(c=b.RI(a));return c};var Fn=!1;function En(a,b){var c=a.kB.oa(b);null===c&&(c=a.kB.oa(""),null===c&&(Fn||(Fn=!0,D.trace('No Link template found for category "'+b+'"'),D.trace("  Using default link template")),c=a.TC));return c}function ln(a,b){for(var c=D.cb(b),d=0;d<c;d++){var e=D.La(b,d);rn(a,e)}}
function rn(a,b){if(void 0!==b&&null!==b){var c=a.Ph(b);if(null!==c){cn(a,c,!1);var d=a.da;if(d instanceof Y&&c instanceof G){var e=d.zb(c.data);if(void 0!==e){for(var g=c.Kd;g.next();)gh(d,e,g.value.data);c.Kf&&(g=c.Yb,null!==g&&gh(d,e,g.data));if(c instanceof I)for(c=c.Oc;c.next();)g=c.value.data,d.le(g)&&gh(d,e,g)}}else if(d instanceof Bg&&c instanceof G){g=a.gg(c.data);if(null!==g){g.ib=!1;g.$g=!1;var h=g.layer;if(null!==h){var k=h.xf(-1,g,!1);0<=k&&a.nd(sg,"parts",h,g,null,k,null);k=g.hy;null!==
k&&k(g,h,null)}}g=a.de;for(c=c.Kd;c.next();)h=c.value,h=(g?h.ba:h.aa).data,d.le(h)&&gh(d,e,h)}}}}E.prototype.findPartForKey=E.prototype.II=function(a){if(null===a||void 0===a)return null;var b=this.da.He(a);return null!==b?this.Vi.oa(b):this.da instanceof Y&&(b=this.da.lq(a),null!==b)?this.dk.oa(b):null};E.prototype.findNodeForKey=E.prototype.Se=function(a){if(null===a||void 0===a)return null;a=this.da.He(a);if(null===a)return null;a=this.Vi.oa(a);return a instanceof G?a:null};
E.prototype.findPartForData=E.prototype.Ph=function(a){if(null===a)return null;var b=this.Vi.oa(a);return null!==b?b:b=this.dk.oa(a)};E.prototype.findNodeForData=E.prototype.Nx=function(a){if(null===a)return null;a=this.Vi.oa(a);return a instanceof G?a:null};E.prototype.findLinkForData=E.prototype.gg=function(a){return null===a?null:this.dk.oa(a)};
E.prototype.findNodesByExample=function(a){for(var b=new L(G),c=this.Ap.j;c.next();){var d=c.value,e=d.data;if(null!==e)for(var g=0;g<arguments.length;g++){var h=arguments[g];if(D.Qa(h)&&Gn(this,e,h)){b.add(d);break}}}return b.j};E.prototype.findLinksByExample=function(a){for(var b=new L(J),c=this.tp.j;c.next();){var d=c.value,e=d.data;if(null!==e)for(var g=0;g<arguments.length;g++){var h=arguments[g];if(D.Qa(h)&&Gn(this,e,h)){b.add(d);break}}}return b.j};
function Gn(a,b,c){for(var d in c){var e=b[d],g=c[d];if(D.isArray(g)){if(!D.isArray(e)||e.length<g.length)return!1;for(var h=0;h<e.length;h++){var k=e[h],l=g[h];if(void 0!==l&&!Hn(a,k,l))return!1}}else if(!Hn(a,e,g))return!1}return!0}function Hn(a,b,c){if("function"===typeof c){if(!c(b))return!1}else if(c instanceof RegExp){if(!b||!c.test(b.toString()))return!1}else if(D.Qa(b)&&D.Qa(c)){if(!Gn(a,b,c))return!1}else if(b!==c)return!1;return!0}
D.defineProperty(E,{hM:"nodeTemplate"},function(){return this.mj.oa("")},function(a){var b=this.mj.oa("");b!==a&&(D.l(a,F,E,"nodeTemplate"),this.mj.add("",a),this.i("nodeTemplate",b,a),this.la.jb||this.Am())});D.defineProperty(E,{wB:"nodeTemplateMap"},function(){return this.mj},function(a){var b=this.mj;b!==a&&(D.l(a,oa,E,"nodeTemplateMap"),this.mj=a,this.i("nodeTemplateMap",b,a),this.la.jb||this.Am())});
D.defineProperty(E,{ML:"groupTemplate"},function(){return this.Al.oa("")},function(a){var b=this.Al.oa("");b!==a&&(D.l(a,I,E,"groupTemplate"),this.Al.add("",a),this.i("groupTemplate",b,a),this.la.jb||this.Am())});D.defineProperty(E,{YA:"groupTemplateMap"},function(){return this.Al},function(a){var b=this.Al;b!==a&&(D.l(a,oa,E,"groupTemplateMap"),this.Al=a,this.i("groupTemplateMap",b,a),this.la.jb||this.Am())});
D.defineProperty(E,{WL:"linkTemplate"},function(){return this.tk.oa("")},function(a){var b=this.tk.oa("");b!==a&&(D.l(a,J,E,"linkTemplate"),this.tk.add("",a),this.i("linkTemplate",b,a),this.la.jb||this.Am())});D.defineProperty(E,{kB:"linkTemplateMap"},function(){return this.tk},function(a){var b=this.tk;b!==a&&(D.l(a,oa,E,"linkTemplateMap"),this.tk=a,this.i("linkTemplateMap",b,a),this.la.jb||this.Am())});D.defineProperty(E,{nJ:null},function(){return this.Hl},function(a){this.Hl=a});
D.defineProperty(E,{nf:"isMouseCaptured"},function(){return this.xD},function(a){var b=this.Mb;null!==b&&(a?(this.U.bubbles=!1,b.removeEventListener("mousemove",this.ko,!1),b.removeEventListener("mousedown",this.jo,!1),b.removeEventListener("mouseup",this.mo,!1),b.removeEventListener("mousewheel",this.dh,!1),b.removeEventListener("DOMMouseScroll",this.dh,!1),b.removeEventListener("mouseout",this.lo,!1),window.addEventListener("mousemove",this.ko,!0),window.addEventListener("mousedown",this.jo,!0),
window.addEventListener("mouseup",this.mo,!0),window.addEventListener("mousewheel",this.dh,!0),window.addEventListener("DOMMouseScroll",this.dh,!0),window.addEventListener("mouseout",this.lo,!0),window.addEventListener("selectstart",this.preventDefault,!1)):(window.removeEventListener("mousemove",this.ko,!0),window.removeEventListener("mousedown",this.jo,!0),window.removeEventListener("mouseup",this.mo,!0),window.removeEventListener("mousewheel",this.dh,!0),window.removeEventListener("DOMMouseScroll",
this.dh,!0),window.removeEventListener("mouseout",this.lo,!0),window.removeEventListener("selectstart",this.preventDefault,!1),b.addEventListener("mousemove",this.ko,!1),b.addEventListener("mousedown",this.jo,!1),b.addEventListener("mouseup",this.mo,!1),b.addEventListener("mousewheel",this.dh,!1),b.addEventListener("DOMMouseScroll",this.dh,!1),b.addEventListener("mouseout",this.lo,!1)),this.xD=a)});
D.defineProperty(E,{position:"position"},function(){return this.tb},function(a){var b=this.tb;if(!b.O(a)){D.l(a,N,E,"position");var c=this.vb.copy();a=a.copy();if(!this.dd&&null!==this.Mb){this.dd=!0;var d=this.scale;Zl(this,a,this.jd,this.Pb/d,this.Tb/d,this.No,!1);this.dd=!1}this.tb=a.V();a=this.Wa;a.xc&&ql(a,b,this.tb);this.dd||this.Uu(c,this.vb,this.Db,this.Db,!1)}});
D.defineProperty(E,{dJ:"initialPosition"},function(){return this.dw},function(a){this.dw.O(a)||(D.l(a,N,E,"initialPosition"),this.dw=a.V())});D.defineProperty(E,{eJ:"initialScale"},function(){return this.ew},function(a){this.ew!==a&&(D.h(a,"number",E,"initialScale"),this.ew=a)});
D.defineProperty(E,{Wn:"grid"},function(){null===this.Cd&&Ql(this);return this.Cd},function(a){var b=this.Cd;if(b!==a){null===b&&(Ql(this),b=this.Cd);D.l(a,x,E,"grid");a.type!==Rl&&D.k("Diagram.grid must be a Panel of type Panel.Grid");var c=b.R;null!==c&&c.remove(b);this.Cd=a;a.name="GRID";null!==c&&c.add(a);km(this);this.ra();this.i("grid",b,a)}});
D.w(E,{vb:"viewportBounds"},function(){var a=this.CE;if(null===this.Mb)return a;var b=this.tb,c=this.Db;a.n(b.x,b.y,Math.max(this.Pb,0)/c,Math.max(this.Tb,0)/c);return a});D.defineProperty(E,{AF:"fixedBounds"},function(){return this.Wv},function(a){var b=this.Wv;b.O(a)||(D.l(a,C,E,"fixedBounds"),(v&&Infinity===a.width||-Infinity===a.width||Infinity===a.height||-Infinity===a.height)&&D.k("fixedBounds width/height must not be Infinity"),this.Wv=a=a.V(),this.Nc(),this.i("fixedBounds",b,a))});
D.defineProperty(E,{Fy:"scrollMargin"},function(){return this.Qw},function(a){"number"===typeof a?a=new Lb(a):D.l(a,Lb,E,"scrollMargin");var b=this.Qw;b.O(a)||(this.Qw=a=a.V(),this.i("scrollMargin",b,a),this.ro())});D.defineProperty(E,{IB:"scrollMode"},function(){return this.Rw},function(a){var b=this.Rw;b!==a&&(D.Da(a,E,E,"scrollMode"),this.Rw=a,a===Bl&&Xl(this,!1),this.i("scrollMode",b,a))});
D.defineProperty(E,{AG:"positionComputation"},function(){return this.Lw},function(a){var b=this.Lw;b!==a&&(null!==a&&D.h(a,"function",E,"positionComputation"),this.Lw=a,Xl(this,!1),this.i("positionComputation",b,a))});D.defineProperty(E,{PG:"scaleComputation"},function(){return this.Nw},function(a){var b=this.Nw;b!==a&&(null!==a&&D.h(a,"function",E,"scaleComputation"),this.Nw=a,fn(this,this.scale,this.scale),this.i("scaleComputation",b,a))});D.w(E,{jd:"documentBounds"},function(){return this.Qv});
function $l(a,b){a.pk=!1;var c=a.Qv;c.O(b)||(b=b.V(),a.Qv=b,Xl(a,!1),a.Ja("DocumentBoundsChanged",null,c.copy()),hm(a))}D.defineProperty(E,{hB:"isVirtualized"},function(){return this.lw},function(a){var b=this.lw;b!==a&&(D.h(a,"boolean",E,"isVirtualized"),this.lw=a,this.i("isVirtualized",b,a))});D.defineProperty(E,{scale:"scale"},function(){return this.Db},function(a){var b=this.Db;D.p(a,E,"scale");b!==a&&fn(this,b,a)});
D.defineProperty(E,{hm:"autoScale"},function(){return this.Nm},function(a){var b=this.Nm;b!==a&&(D.Da(a,E,E,"autoScale"),this.Nm=a,this.i("autoScale",b,a),a!==Vh&&Xl(this,!1))});D.defineProperty(E,{PL:"initialAutoScale"},function(){return this.El},function(a){var b=this.El;b!==a&&(D.Da(a,E,E,"initialAutoScale"),this.El=a,this.i("initialAutoScale",b,a))});
D.defineProperty(E,{fJ:"initialViewportSpot"},function(){return this.fw},function(a){var b=this.fw;b!==a&&(D.l(a,S,E,"initialViewportSpot"),a.Vc()||D.k("initialViewportSpot must be a specific Spot: "+a),this.fw=a,this.i("initialViewportSpot",b,a))});D.defineProperty(E,{cJ:"initialDocumentSpot"},function(){return this.cw},function(a){var b=this.cw;b!==a&&(D.l(a,S,E,"initialDocumentSpot"),a.Vc()||D.k("initialViewportSpot must be a specific Spot: "+a),this.cw=a,this.i("initialDocumentSpot",b,a))});
D.defineProperty(E,{Sh:"minScale"},function(){return this.ww},function(a){D.p(a,E,"minScale");var b=this.ww;b!==a&&(0<a?(this.ww=a,this.i("minScale",b,a),a>this.scale&&(this.scale=a)):D.ua(a,"> 0",E,"minScale"))});D.defineProperty(E,{Rh:"maxScale"},function(){return this.tw},function(a){D.p(a,E,"maxScale");var b=this.tw;b!==a&&(0<a?(this.tw=a,this.i("maxScale",b,a),a<this.scale&&(this.scale=a)):D.ua(a,"> 0",E,"maxScale"))});
D.defineProperty(E,{Jm:"zoomPoint"},function(){return this.lx},function(a){this.lx.O(a)||(D.l(a,N,E,"zoomPoint"),this.lx=a=a.V())});D.defineProperty(E,{AA:"contentAlignment"},function(){return this.No},function(a){var b=this.No;b.O(a)||(D.l(a,S,E,"contentAlignment"),this.No=a=a.V(),this.i("contentAlignment",b,a),Xl(this,!1))});
D.defineProperty(E,{QL:"initialContentAlignment"},function(){return this.hp},function(a){var b=this.hp;b.O(a)||(D.l(a,S,E,"initialContentAlignment"),this.hp=a=a.V(),this.i("initialContentAlignment",b,a))});D.defineProperty(E,{padding:"padding"},function(){return this.sf},function(a){"number"===typeof a?a=new Lb(a):D.l(a,Lb,E,"padding");var b=this.sf;b.O(a)||(this.sf=a=a.V(),this.Nc(),this.i("padding",b,a))});D.w(E,{rg:"nodes"},function(){return this.Ap.j});D.w(E,{links:"links"},function(){return this.tp.j});
D.w(E,{Ii:"parts"},function(){return this.sb.j});E.prototype.findTopLevelNodesAndLinks=function(){for(var a=new L(F),b=this.Ap.j;b.next();){var c=b.value;c.wq&&a.add(c)}for(b=this.tp.j;b.next();)c=b.value,c.wq&&a.add(c);return a.j};E.prototype.findTopLevelGroups=function(){return this.Zl.j};
D.defineProperty(E,{Zb:"layout"},function(){return this.ve},function(a){var b=this.ve;b!==a&&(D.l(a,$g,E,"layout"),null!==b&&(b.g=null,b.group=null),this.ve=a,a.g=this,a.group=null,this.tl=!0,this.i("layout",b,a),this.Ve())});E.prototype.layoutDiagram=function(a){Si(this);a&&An(this,!0);nm(this,!1)};function An(a,b){for(var c=a.Zl.j;c.next();)In(a,c.value,b);null!==a.Zb&&(b?a.Zb.zf=!1:a.Zb.N())}
function In(a,b,c){if(null!==b){for(var d=b.hn.j;d.next();)In(a,d.value,c);null!==b.Zb&&(c?b.Zb.zf=!1:b.Zb.N())}}function nm(a,b){if(a.tl&&!a.uz){var c=a.$a;a.$a=!0;try{var d=a.la.Ki;0===d&&a.Nb("Layout");var e=a.Wa;0!==d||e.yf||e.xc||b||e.po("Layout");a.tl=!1;for(var g=a.Zl.j;g.next();)Jn(a,g.value,b,d);var h=a.Zb;h.zf||(!b||h.dG||0===d?(h.doLayout(a),Si(a),h.zf=!0):a.tl=!0)}finally{0===d&&a.hd("Layout"),a.tl=!h.zf,a.$a=c}}}
function Jn(a,b,c,d){if(null!==b){for(var e=b.hn.j;e.next();)Jn(a,e.value,c,d);e=b.Zb;null===e||e.zf||(!c||e.dG||0===d?(b.no=!b.location.F(),e.doLayout(b),b.N(Kn),Lm(a,b),e.zf=!0):a.tl=!0)}}D.defineProperty(E,{de:"isTreePathToChildren"},function(){return this.kw},function(a){var b=this.kw;if(b!==a&&(D.h(a,"boolean",E,"isTreePathToChildren"),this.kw=a,this.i("isTreePathToChildren",b,a),!this.la.jb))for(a=this.rg;a.next();)Ln(a.value)});
E.prototype.findTreeRoots=function(){for(var a=new K(G),b=this.rg;b.next();){var c=b.value;c.wq&&null===c.Rn()&&a.add(c)}return a.j};D.defineProperty(E,{eC:"treeCollapsePolicy"},function(){return this.gx},function(a){var b=this.gx;b!==a&&(a!==Dl&&a!==hn&&a!==jn&&D.k("Unknown Diagram.treeCollapsePolicy: "+a),this.gx=a,this.i("treeCollapsePolicy",b,a))});D.defineProperty(E,{Qh:null},function(){return this.pD},function(a){this.pD=a});
function Cl(a){function b(a){var b=a.toLowerCase(),h=new K("function");c.add(a,h);c.add(b,h);d.add(a,a);d.add(b,a)}var c=new oa("string",K),d=new oa("string","string");b("AnimationStarting");b("AnimationFinished");b("BackgroundSingleClicked");b("BackgroundDoubleClicked");b("BackgroundContextClicked");b("ClipboardChanged");b("ClipboardPasted");b("DocumentBoundsChanged");b("ExternalObjectsDropped");b("InitialLayoutCompleted");b("LayoutCompleted");b("LinkDrawn");b("LinkRelinked");b("LinkReshaped");b("Modified");
b("ObjectSingleClicked");b("ObjectDoubleClicked");b("ObjectContextClicked");b("PartCreated");b("PartResized");b("PartRotated");b("SelectionMoved");b("SelectionCopied");b("SelectionDeleting");b("SelectionDeleted");b("SelectionGrouped");b("SelectionUngrouped");b("ChangingSelection");b("ChangedSelection");b("SubGraphCollapsed");b("SubGraphExpanded");b("TextEdited");b("TreeCollapsed");b("TreeExpanded");b("ViewportBoundsChanged");a.wz=c;a.vz=d}
function Ga(a,b){var c=a.vz.oa(b);return null!==c?c:a.vz.oa(b.toLowerCase())}function Mn(a,b){var c=a.wz.oa(b);if(null!==c)return c;c=a.wz.oa(b.toLowerCase());if(null!==c)return c;D.k("Unknown DiagramEvent name: "+b);return null}E.prototype.addDiagramListener=E.prototype.mx=function(a,b){D.h(a,"string",E,"addDiagramListener:name");D.h(b,"function",E,"addDiagramListener:listener");var c=Mn(this,a);null!==c&&c.add(b)};
E.prototype.removeDiagramListener=E.prototype.DB=function(a,b){D.h(a,"string",E,"removeDiagramListener:name");D.h(b,"function",E,"addDiagramListener:listener");var c=Mn(this,a);null!==c&&c.remove(b)};
E.prototype.raiseDiagramEvent=E.prototype.Ja=function(a,b,c){v&&D.h(a,"string",E,"raiseDiagramEvent:name");var d=Mn(this,a),e=new dg;e.g=this;a=Ga(this,a);null!==a&&(e.name=a);void 0!==b&&(e.VB=b);void 0!==c&&(e.yB=c);b=d.length;if(1===b)d=d.ea(0),d(e);else if(0!==b)for(c=d.oc(),a=0;a<b;a++)d=c[a],d(e);return e.cancel};E.prototype.isUnoccupied=E.prototype.yq=function(a,b){void 0===b&&(b=null);return ha(this,!1,null,b).yq(a.x,a.y,a.width,a.height)};
E.prototype.computeOccupiedArea=function(){return this.hB?this.vb.copy():this.pk?Wl(this):this.jd.copy()};
function ha(a,b,c,d){null===a.Td&&(a.Td=new Nn);if(a.Td.sq||a.Td.group!==c||a.Td.UB!==d){if(null===c){b=a.computeOccupiedArea();b.ig(100,100);a.Td.initialize(b);b=D.Qf();for(var e=a.rg;e.next();){var g=e.value,h=g.layer;null!==h&&h.visible&&!h.Wc&&On(a,g,d,b)}}else for(c.Z.F()||c.Re(),b=c.Z.copy(),b.ig(20,20),a.Td.initialize(b),b=D.Qf(),e=c.Oc;e.next();)g=e.value,g instanceof G&&On(a,g,d,b);D.Kb(b);a.Td.group=c;a.Td.UB=d;a.Td.sq=!1}else b&&Pn(a.Td);return a.Td}
function On(a,b,c,d){if(b!==c)if(b.isVisible()&&b.wA&&!b.Kf){c=b.getAvoidableRect(d);d=a.Td.$p;b=a.Td.Yp;for(var e=c.x+c.width,g=c.y+c.height,h=c.x;h<e;h+=d){for(var k=c.y;k<g;k+=b)Qn(a.Td,h,k);Qn(a.Td,h,g)}for(k=c.y;k<g;k+=b)Qn(a.Td,e,k);Qn(a.Td,e,g)}else if(b instanceof I)for(b=b.Oc;b.next();)e=b.value,e instanceof G&&On(a,e,c,d)}E.invalidatePositionArray=E.prototype.dB=function(a){null!==this.Td&&!this.Td.sq&&(void 0===a&&(a=null),null===a||a.wA&&!a.Kf)&&(this.Td.sq=!0)};
E.prototype.simulatedMouseMove=function(a,b,c){if(null!==di){var d=di.g;c instanceof E||(c=null);var e=ei;c!==e&&(null!==e&&e!==d&&null!==e.ob.Fe&&(Di(e),di.Wx=!1,e.ob.Fe.doSimulatedDragLeave()),ei=c,null!==c&&c!==d&&null!==c.ob.Fe&&(Ki(),e=c.ob.Fe,Gi.contains(e)||Gi.add(e),c.ob.Fe.doSimulatedDragEnter()));if(null===c||c===d||!c.NE||c.rb||!c.Wp)return!1;d=c.ob.Fe;null!==d&&(null!==a?b=c.dp(a):null===b&&(b=new N),c.Vb.ga=b,c.Vb.pm=!1,c.Vb.up=!1,d.doSimulatedDragOver());return!0}return!1};
E.prototype.simulatedMouseUp=function(a,b,c,d){if(null!==di){null===d&&(d=b);b=ei;var e=di.g;if(d!==b){if(null!==b&&b!==e&&null!==b.ob.Fe)return Di(b),di.Wx=!1,b.ob.Fe.doSimulatedDragLeave(),!1;ei=d;null!==d&&null!==d.ob.Fe&&(Ki(),b=d.ob.Fe,Gi.contains(b)||Gi.add(b),d.ob.Fe.doSimulatedDragEnter())}if(null===d)return di.doCancel(),!0;if(d!==this)return null!==a&&(c=d.dp(a)),d.Vb.ga=c,d.Vb.pm=!1,d.Vb.up=!0,a=d.ob.Fe,null!==a&&a.doSimulatedDrop(),a=di,null!==a&&(d=a.mayCopy(),a.Sf=d?"Copy":"Move",a.stopTool()),
!0}return!1};D.defineProperty(E,{iL:"autoScrollInterval"},function(){return this.hr},function(a){var b=this.hr;D.p(a,E,"scale");b!==a&&(this.hr=a,this.i("autoScrollInterval",b,a))});D.defineProperty(E,{TE:"autoScrollRegion"},function(){return this.Bv},function(a){"number"===typeof a?a=new Lb(a):D.l(a,Lb,E,"autoScrollRegion");var b=this.Bv;b.O(a)||(this.Bv=a=a.V(),this.Nc(),this.i("autoScrollRegion",b,a))});
E.prototype.doAutoScroll=E.prototype.JA=function(a){this.Av.assign(a);Rn(this,this.Av).Uc(this.position)?Di(this):Sn(this)};function Sn(a){-1===a.Io&&(a.Io=D.setTimeout(function(){if(-1!==a.Io){Di(a);var b=a.U.event;if(null!==b){var c=Rn(a,a.Av);c.Uc(a.position)||(a.position=c,a.U.ga=a.dC(a.Av),c=em(b),a.simulatedMouseMove(b,null,c)||a.doMouseMove(),a.pk=!0,$l(a,a.Mh()),a.Ne=!0,a.lg(),Sn(a))}}},a.hr))}function Di(a){-1!==a.Io&&(D.clearTimeout(a.Io),a.Io=-1)}
function Rn(a,b){var c=a.position,d=a.TE;if(0>=d.top&&0>=d.left&&0>=d.right&&0>=d.bottom)return c;var e=a.vb,g=a.scale,e=D.vg(0,0,e.width*g,e.height*g),h=D.Fb(0,0);if(b.x>=e.x&&b.x<e.x+d.left){var k=Math.max(a.bv,1),k=k|0;h.x-=k;b.x<e.x+d.left/2&&(h.x-=k);b.x<e.x+d.left/4&&(h.x-=4*k)}else b.x<=e.x+e.width&&b.x>e.x+e.width-d.right&&(k=Math.max(a.bv,1),k|=0,h.x+=k,b.x>e.x+e.width-d.right/2&&(h.x+=k),b.x>e.x+e.width-d.right/4&&(h.x+=4*k));b.y>=e.y&&b.y<e.y+d.top?(k=Math.max(a.cv,1),k|=0,h.y-=k,b.y<e.y+
d.top/2&&(h.y-=k),b.y<e.y+d.top/4&&(h.y-=4*k)):b.y<=e.y+e.height&&b.y>e.y+e.height-d.bottom&&(k=Math.max(a.cv,1),k|=0,h.y+=k,b.y>e.y+e.height-d.bottom/2&&(h.y+=k),b.y>e.y+e.height-d.bottom/4&&(h.y+=4*k));h.Uc(Kd)||(c=new N(c.x+h.x/g,c.y+h.y/g));D.Kb(e);D.A(h);return c}E.prototype.makeSvg=E.prototype.makeSVG=function(a){void 0===a&&(a=new ma);a.context="svg";a=Tn(this,a);return null!==a?a.Em:null};
E.prototype.makeImage=function(a){void 0===a&&(a=new ma);var b=(a.document||document).createElement("img");b.src=this.BJ(a);return b};
E.prototype.makeImageData=E.prototype.BJ=function(a){void 0===a&&(a=new ma);var b=Tn(this,a);if(null!==b){var c=a.returnType,c=void 0===c?"string":c.toLowerCase();switch(c){case "imagedata":return b.Qk.getImageData(0,0,b.width,b.height);case "blob":b=b.Wd;c=a.callback;if("function"!==typeof c){D.k('Error: Diagram.makeImageData called with "returnType: toBlob", but no "callback" function property defined.');break}if("function"===typeof b.toBlob)return b.toBlob(c,a.type,a.details),"toBlob";if("function"===
typeof b.msToBlob)return c(b.msToBlob()),"msToBlob";c(null);break;default:return b.toDataURL(a.type,a.details)}}return""};var Un=!1;
function Tn(a,b){a.Wa.Yh();a.lg();if(null===a.Mb)return null;"object"!==typeof b&&D.k("properties argument must be an Object.");var c=!1,d=b.size||null,e=b.scale||null;void 0!==b.scale&&isNaN(b.scale)&&(e="NaN");var g=b.maxSize;void 0===b.maxSize&&(c=!0,g="svg"===b.context?new Ca(Infinity,Infinity):new Ca(2E3,2E3));var h=b.position||null,k=b.parts||null,l=void 0===b.padding?1:b.padding,m=b.background||null,n=b.omitTemporary;void 0===n&&(n=!0);var p=b.document||document,q=b.elementFinished||null,r=
b.showTemporary;void 0===r&&(r=!n);n=b.showGrid;void 0===n&&(n=r);null!==d&&isNaN(d.width)&&isNaN(d.height)&&(d=null);"number"===typeof l?l=new Lb(l):l instanceof Lb||(l=new Lb(0));l.left=Math.max(l.left,0);l.right=Math.max(l.right,0);l.top=Math.max(l.top,0);l.bottom=Math.max(l.bottom,0);a.cd.De(!0);var s=new ja(null,p),u=s.Qk;if(!(d||e||k||h)){s.width=a.Pb+Math.ceil(l.left+l.right);s.height=a.Tb+Math.ceil(l.top+l.bottom);if("svg"===b.context)return u=new qd(s.Wd,p,q),Sm(a,u,l,new Ca(s.width,s.height),
a.Db,a.tb,k,m,r,n),u;a.Kr=!1;Sm(a,u,l,new Ca(s.width,s.height),a.Db,a.tb,k,m,r,n);a.Kr=!0;return s}var t=a.yb.Ex,y=new N(0,0),w=a.jd.copy();w.tK(a.padding);if(r)for(var B=!0,B=a.dc.o,A=B.length,O=0;O<A;O++){var H=B[O];if(H.visible&&H.Wc)for(var H=H.sb.o,R=H.length,aa=0;aa<R;aa++){var V=H[aa];V.Xx&&V.isVisible()&&(V=V.Z,V.F()&&w.Zh(V))}}y.x=w.x;y.y=w.y;if(null!==k){var ea,B=!0,A=k.j;for(A.reset();A.next();)V=A.value,V instanceof F&&(H=V.layer,null!==H&&!H.visible||null!==H&&!r&&H.Wc||!V.isVisible()||
(V=V.Z,V.F()&&(B?(B=!1,ea=V.copy()):ea.Zh(V))));B&&(ea=new C(0,0,0,0));w.width=ea.width;w.height=ea.height;y.x=ea.x;y.y=ea.y}null!==h&&h.F()&&(y=h,e||(e=t));B=V=0;null!==l&&(V=l.left+l.right,B=l.top+l.bottom);O=A=0;null!==d&&(A=d.width,O=d.height,isFinite(A)&&(A=Math.max(0,A-V)),isFinite(O)&&(O=Math.max(0,O-B)));ea=h=0;null!==d&&null!==e?("NaN"===e&&(e=t),d.F()?(h=A,ea=O):isNaN(O)?(h=A,ea=w.height*e):(h=w.width*e,ea=O)):null!==d?d.F()?(e=Math.min(A/w.width,O/w.height),h=A,ea=O):isNaN(O)?(e=A/w.width,
h=A,ea=w.height*e):(e=O/w.height,h=w.width*e,ea=O):null!==e?"NaN"===e&&g.F()?(e=Math.min((g.width-V)/w.width,(g.height-B)/w.height),e>t?(e=t,h=w.width,ea=w.height):(h=g.width,ea=g.height)):(h=w.width*e,ea=w.height*e):(e=t,h=w.width,ea=w.height);null!==l?(h+=V,ea+=B):l=new Lb(0);null!==g&&(d=g.width,g=g.height,"svg"!==b.context&&c&&!Un&&(h>d||ea>g)&&(D.trace("Diagram.makeImage(data): Diagram width or height is larger than the default max size. ("+Math.ceil(h)+"x"+Math.ceil(ea)+" vs 2000x2000) Consider increasing the max size."),
Un=!0),isNaN(d)&&(d=2E3),isNaN(g)&&(g=2E3),isFinite(d)&&(h=Math.min(h,d)),isFinite(g)&&(ea=Math.min(ea,g)));s.width=Math.ceil(h);s.height=Math.ceil(ea);if("svg"===b.context)return u=new qd(s.Wd,p,q),Sm(a,u,l,new Ca(Math.ceil(h),Math.ceil(ea)),e,y,k,m,r,n),u;a.Kr=!1;Sm(a,u,l,new Ca(Math.ceil(h),Math.ceil(ea)),e,y,k,m,r,n);a.Kr=!0;return s}E.inherit=function(a,b){D.h(a,"function",E,"inherit");D.h(b,"function",E,"inherit");b.JH&&D.k("Cannot inherit from "+D.vf(b));D.Sa(a,b)};
function Ll(){this.lE=null;this.LH="63ad05bbe23a1786468a4c741b6d2";this.gk=this.LH===this._tk?!0:null}
Ll.prototype.Im=function(a){a.cd.setTransform(a.td,0,0,a.td,0,0);if(null===this.gk){var b="f",c='https://gojs.net/';a=D.Xg;this.gk=!0;if(window[a("7da7")]&&window[a("7da7")][a("76a115b6ed251eaf4692")]){var d=window[a("7da7")][a("76a115b6ed251eaf4692")],d=a(d).split(a("39e9"));if(!(6>d.length)){var e=a(d[1]).split(".");if("7da71ca0"===d[4]){var g=a(D[a("6cae19")]).split(".");if(e[0]>g[0]||e[0]===g[0]&&e[1]>=g[1]){e=c[a("76ad18b4f73e")];for(g=c[a("73a612b6fb191d")](a("35e7"))+
2;g<e;g++)b+=c[g];c=b[a("73a612b6fb191d")](a(d[2]));0>c&&a(d[2])!==a("7da71ca0ad381e90")&&(c=b[a("73a612b6fb191d")](a("76a715b2ef3e149757")));0>c&&(c=b[a("73a612b6fb191d")](a("76a715b2ef3e149757")));if(this.gk=!(0<=c&&c<b[a("73a612b6fb191d")](a("35"))))if(b=a(d[2]),"#"===b[0]){c=window.document[a("79ba13b2f7333e8846865a7d00")]("div");for(d=d[0].replace(/[A-Za-z]/g,"");4>d.length;)d+="9";d=d.substr(d.length-4);e=""+["gsh","gsf"][parseInt(d.substr(0,1),10)%2];e+=["Header","Background","Display","Feedback"][parseInt(d.substr(0,
1),10)%4];c[a("79a417a0f0181a8946")]=e;window.document[a("78a712aa")]?(window.document[a("78a712aa")][a("7bb806b6ed32388c4a875b")](c),d=window[a("7dad0290ec3b0b91578e5b40007031bf")](c)[a("7dad0283f1390b81519f4645156528bf")](a("78a704b7e62456904c9b12701b6532a8")),window.document[a("78a712aa")][a("68ad1bbcf533388c4a875b")](c),d&&-1!==d.indexOf(parseInt(b[1]+b[2],16))&&-1!==d.indexOf(parseInt(b[3]+b[4],16))&&(this.gk=!1)):(this.gk=null,this.gk=!1)}}}}}else{e=c[a("76ad18b4f73e")];for(g=c[a("73a612b6fb191d")](a("35e7"))+
2;g<e;g++)b+=c[g];c=b[a("73a612b6fb191d")](a("7da71ca0ad381e90"));this.gk=!(0<=c&&c<b[a("73a612b6fb191d")](a("35")))}}return 0<this.gk&&this!==this.lE?!0:!1};Ll.prototype.t=function(){this.lE=null};
function Ml(a,b){void 0!==b&&null!==b||D.k("Diagram setup requires an argument DIV.");null!==a.Ub&&D.k("Diagram has already completed setup.");"string"===typeof b?a.Ub=window.document.getElementById(b):b instanceof HTMLDivElement?a.Ub=b:D.k("No DIV or DIV id supplied: "+b);null===a.Ub&&D.k("Invalid DIV id; could not get element with id: "+b);void 0!==a.Ub.ia&&D.k("Invalid div id; div already has a Diagram associated with it.");"static"===window.getComputedStyle(a.Ub,null).position&&(a.Ub.style.position=
"relative");a.Ub.style["-webkit-tap-highlight-color"]="rgba(255, 255, 255, 0)";a.Ub.style["-ms-touch-action"]="none";a.Ub.innerHTML="";a.Ub.ia=a;var c=new ja(a);c.Wd.innerHTML="This text is displayed if your browser does not support the Canvas HTML element.";void 0!==c.style&&(c.style.position="absolute",c.style.top="0px",c.style.left="0px","rtl"===window.getComputedStyle(a.Ub,null).getPropertyValue("direction")&&(a.hs=!0),c.style.zIndex="2",c.style.LM="none",c.style.webkitUserSelect="none",c.style.MozUserSelect=
"none");a.Pb=a.Ub.clientWidth||1;a.Tb=a.Ub.clientHeight||1;a.Mb=c;a.cd=c.Qk;var d=a.cd;a.td=a.computePixelRatio();Um(a,a.Pb,a.Tb);a.Sv=d[D.Xg("7eba17a4ca3b1a8346")][D.Xg("78a118b7")](d,D.Im,4,4);a.Ub.insertBefore(c.Wd,a.Ub.firstChild);c=new ja(null);c.width=1;c.height=1;a.zz=c;a.eD=c.Qk;var c=D.createElement("div"),e=D.createElement("div");c.style.position="absolute";c.style.overflow="auto";c.style.width=a.Pb+"px";c.style.height=a.Tb+"px";c.style.zIndex="1";e.style.position="absolute";e.style.width=
"1px";e.style.height="1px";a.Ub.appendChild(c);c.appendChild(e);c.onscroll=Sl;c.onmousedown=Ul;c.ontouchstart=Ul;c.ia=a;c.HH=!0;c.IH=!0;a.Sw=c;a.ot=e;a.yy=D.dF(function(){a.Dk=null;a.ra()},300,!1);a.iC=D.dF(function(){el(a)},250,!1);a.preventDefault=function(a){a.preventDefault();return!1};a.ko=function(b){if(a.isEnabled){a.Hl=!0;var c=a.gd;D.Yn&&c.Mj?(b.preventDefault(),b.simulated=!0,a.xt=b):(a.gd=a.Vb,a.Vb=c,cm(a,a,b,c,!0),a.simulatedMouseMove(b,null,b.target.ia)||(a.doMouseMove(),a.hb.isBeyondDragSize()&&
(a.Ll=0),ga(a,c,b)))}};a.jo=function(b){if(a.isEnabled){a.Hl=!0;var c=a.gd;if(D.Yn&&null!==a.xt)a.xt=b,b.preventDefault();else if(D.Yn&&400>b.timeStamp-a.an)b.preventDefault();else if(a.Il)b.preventDefault();else{a.gd=a.Vb;a.Vb=c;cm(a,a,b,c,!0);c.pm=!0;c.Pe=b.detail;if(D.YF||D.ZF)b.timeStamp-a.an<a.yE&&!a.hb.isBeyondDragSize()?a.Ll++:a.Ll=1,a.an=b.timeStamp,c.Pe=a.Ll;a.$i=c;!0===c.Rr.simulated?(b.preventDefault(),b.simulated=!0):(di=null,a.doMouseDown(),a.$i=a.$i.copy(),1===b.button?b.preventDefault():
ga(a,c,b))}}};a.mo=function(b){if(a.isEnabled)if(a.Il&&2===b.button)b.preventDefault();else if(a.Il&&0===b.button&&(a.Il=!1),a.Ft)b.preventDefault();else{a.Hl=!0;var c=a.gd;if(D.Yn){if(400>b.timeStamp-a.an){b.preventDefault();return}a.an=b.timeStamp}if(D.Yn&&null!==a.xt)a.xt=null,b.preventDefault();else{a.gd=a.Vb;a.Vb=c;cm(a,a,b,c,!0);c.up=!0;c.Pe=b.detail;if(D.YF||D.ZF)c.Pe=a.Ll;c.bubbles=b.bubbles;c.jh=em(b);a.simulatedMouseUp(b,null,new N,c.jh)||(a.doMouseUp(),Di(a),ga(a,c,b))}}};a.dh=function(b){if(a.isEnabled){var c=
a.gd;a.gd=a.Vb;a.Vb=c;cm(a,a,b,c,!0);c.bubbles=!0;c.om=void 0!==b.wheelDelta?b.wheelDelta:-40*b.detail;a.doMouseWheel();ga(a,c,b)}};a.lo=function(){if(a.isEnabled){a.Hl=!1;var b=a.hb;b.cancelWaitAfter();b.standardMouseOver()}};a.bC=function(b){if(a.isEnabled){a.Ft=!1;a.Il=!0;var c=a.gd;a.gd=a.Vb;a.Vb=c;fm(a,b,b.targetTouches[0],c,1<b.touches.length);a.doMouseDown();ga(a,c,b)}};a.aC=function(b){if(a.isEnabled){var c=a.gd;a.gd=a.Vb;a.Vb=c;var d=null;0<b.targetTouches.length?d=b.targetTouches[0]:0<b.changedTouches.length&&
(d=b.changedTouches[0]);gm(a,b,d,c,1<b.touches.length);a.simulatedMouseMove(d?d:b,null,c.jh)||a.doMouseMove();ga(a,c,b)}};a.$B=function(b){if(a.isEnabled)if(a.Ft)b.preventDefault();else{var c=a.gd;a.gd=a.Vb;a.Vb=c;if(!(1<b.touches.length)){var d=null,e=null;0<b.targetTouches.length?e=b.targetTouches[0]:0<b.changedTouches.length&&(e=b.changedTouches[0]);c.g=a;c.Pe=1;if(null!==e){d=window.document.elementFromPoint(e.clientX,e.clientY);null!==d&&d.ia instanceof E&&d.ia!==a&&dm(d.ia,e,c);dm(a,e,c);var m=
e.screenX,n=e.screenY,p=a.CD;b.timeStamp-a.an<a.yE&&!(25<Math.abs(p.x-m)||25<Math.abs(p.y-n))?a.Ll++:a.Ll=1;c.Pe=a.Ll;a.an=b.timeStamp;a.CD.n(m,n)}c.Md=0;c.button=0;c.buttons=1;c.pm=!1;c.up=!0;c.om=0;c.Mc=!1;c.bubbles=!1;c.event=b;c.timestamp=Date.now();c.jh=null===d?em(b):d.ia?d.ia:null;c.Ze=null;a.simulatedMouseUp(e?e:b,null,new N,c.jh)||a.doMouseUp();ga(a,c,b);a.Il=!1}}};a.wG=function(b){if("touch"===b.pointerType){var c=a.YD;void 0===c[b.pointerId]&&(a.Kw++,c[b.pointerId]=b);a.oi[0]=null;a.oi[1]=
null;for(var d in c)if(null===a.oi[0])a.oi[0]=c[d];else if(null===a.oi[1]){a.oi[1]=c[d];break}a.isEnabled&&(a.Ft=!1,a.Il=!0,b=a.oi[0],d=a.gd,a.gd=a.Vb,a.Vb=d,fm(a,b,b,d,1<a.Kw),a.doMouseDown(),ga(a,d,b))}};a.yG=function(b){if("touch"===b.pointerType&&!(2>a.Kw)){var c=a.oi;c[0].pointerId===b.pointerId&&(c[0]=b);c[1].pointerId===b.pointerId&&(c[1]=b);a.isEnabled&&c[1]!==b&&(c=a.gd,a.gd=a.Vb,a.Vb=c,gm(a,b,b,c,!0),a.simulatedMouseMove(b,null,c.jh)||(a.doMouseMove(),ga(a,c,b)))}};a.xG=function(b){if("touch"===
b.pointerType){var c=a.YD;void 0!==c[b.pointerId]&&(a.Kw--,delete c[b.pointerId],c=a.oi,null!==c[0]&&c[0].pointerId===b.pointerId&&(c[0]=null),null!==c[1]&&c[1].pointerId===b.pointerId&&(c[1]=null))}};d.De(!0);Pl(a)}function Vn(a){1<arguments.length&&D.k("Palette constructor can only take one optional argument, the DIV HTML element or its id.");E.call(this,a);this.Wt=!0;this.em=!1;this.rb=!0;this.AA=jc;this.Zb=new Wn}D.Sa(Vn,E);D.ka("Palette",Vn);
function Il(a){1<arguments.length&&D.k("Overview constructor can only take one optional argument, the DIV HTML element or its id.");E.call(this,a);this.Wa.isEnabled=!1;this.dd=!0;this.zk=null;this.Tv=!0;this.mK("drawShadows",!1);var b=new F,c=new z;c.stroke="magenta";c.mb=2;c.fill="transparent";c.name="BOXSHAPE";b.el=!0;b.Hy="BOXSHAPE";b.ly="BOXSHAPE";b.MG="BOXSHAPE";b.cursor="move";b.add(c);this.Om=b;c=new da;c.type=Wj;c.Nf=mc;var d=new Xj;d.Ue=!0;c.add(d);d=new z;d.Ih=mc;d.Jb="Rectangle";d.Ea=new Ca(64,
64);d.cursor="se-resize";d.alignment=vc;c.add(d);b.KG=c;this.Dn=this.Mk=!1;this.If=this.Yt=!0;this.TE=new Lb(0,0,0,0);this.jA=new ja(null);this.KH=this.jA.Qk;this.ob.Fe=new Xn;this.ob.NG=new Yn;var e=this;this.click=function(){var a=e.zk;if(null!==a){var b=a.vb,c=e.U.ga;a.position=new N(c.x-b.width/2,c.y-b.height/2)}};this.tG=function(){Zn(e)};this.sG=function(){null!==e.zk&&(e.Nc(),e.ra())};this.hm=am;this.dd=!1}D.Sa(Il,E);D.ka("Overview",Il);
function $n(a){a.dd||a.je||!1!==a.cg||(a.cg=!0,requestAnimationFrame(function(){if(a.cg&&!a.je&&(a.cg=!1,null!==a.Ub)){a.je=!0;Si(a);a.jd.F()||$l(a,a.Mh());null===a.Ub&&D.k("No div specified");null===a.Mb&&D.k("No canvas specified");if(a.Ne){var b=a.zk;if(null!==b&&!b.Wa.yf&&!b.Wa.xc){var b=a.cd,c=a.jA;b.setTransform(1,0,0,1,0,0);b.clearRect(0,0,a.Mb.width,a.Mb.height);b.drawImage(c.Wd,0,0);c=a.Jc;c.reset();1!==a.scale&&c.scale(a.scale);0===a.position.x&&0===a.position.y||c.translate(-a.position.x,
-a.position.y);b.scale(a.td,a.td);b.transform(c.m11,c.m12,c.m21,c.m22,c.dx,c.dy);for(var c=a.dc.o,d=c.length,e=0;e<d;e++)c[e].Ge(b,a);a.Fl=!1;a.Ne=!1}}a.je=!1}}))}Il.prototype.computePixelRatio=function(){return 1};
Il.prototype.Ge=function(){null===this.Ub&&D.k("No div specified");null===this.Mb&&D.k("No canvas specified");if(this.Ne){var a=this.zk;if(null!==a&&!a.Wa.yf){Pm(this);var b=a.Wn;(null!==b&&b.visible&&isNaN(b.width)||isNaN(b.height))&&km(a);var c=this.Mb,b=this.cd,d=this.jA,e=this.KH;d.width=c.width;d.height=c.height;b.De(!0);b.setTransform(1,0,0,1,0,0);b.clearRect(0,0,c.width,c.height);var g=this.Jc;g.reset();1!==this.scale&&g.scale(this.scale);0===this.position.x&&0===this.position.y||g.translate(-this.position.x,
-this.position.y);b.scale(this.td,this.td);b.transform(g.m11,g.m12,g.m21,g.m22,g.dx,g.dy);for(var h=this.Tv,k=this.vb,l=a.dc.o,m=l.length,a=0;a<m;a++){var n=l[a],p=b,q=k,r=h;if(n.visible&&0!==n.Hc&&(void 0===r&&(r=!0),r||!n.Wc)){1!==n.Hc&&(p.globalAlpha=n.Hc);for(var r=this.scale,n=n.sb.o,s=n.length,u=0;u<s;u++){var t=n[u],y=t.Z;y.jg(q)&&(1<y.width*r||1<y.height*r?t.Ge(p,this):ul(t,p))}p.globalAlpha=1}}e.drawImage(c.Wd,0,0);v&&v.zj&&(e.fillStyle="red",e.fillRect(0,d.height/2,d.width,4));c=this.dc.o;
d=c.length;for(a=0;a<d;a++)c[a].Ge(b,this);v&&(v.LA||v.zj)&&v.KA(b,this,g);this.Ne=this.Fl=!1}}};
D.defineProperty(Il,{xB:"observed"},function(){return this.zk},function(a){var b=this.zk;null!==a&&D.l(a,E,Il,"observed");a instanceof Il&&D.k("Overview.observed Diagram may not be an Overview itself: "+a);b!==a&&(null!==b&&(this.remove(this.Lh),b.DB("ViewportBoundsChanged",this.tG),b.DB("DocumentBoundsChanged",this.sG),b.Gw.remove(this)),this.zk=a,null!==a&&(a.mx("ViewportBoundsChanged",this.tG),a.mx("DocumentBoundsChanged",this.sG),a.Gw.add(this),this.add(this.Lh),Zn(this)),this.Nc(),this.i("observed",
b,a))});D.defineProperty(Il,{Lh:"box"},function(){return this.Om},function(a){var b=this.Om;b!==a&&(this.Om=a,this.remove(b),this.add(this.Om),Zn(this),this.i("box",b,a))});D.defineProperty(Il,{IL:"drawsTemporaryLayers"},function(){return this.Tv},function(a){this.Tv!==a&&(this.Tv=a,this.ro())});
function Zn(a){var b=a.Lh;if(null!==b){var c=a.zk;if(null!==c){a.Ne=!0;var c=c.vb,d=b.to,e=D.Gm();e.n(c.width,c.height);d.Ea=e;D.Uk(e);a=2/a.scale;d instanceof z&&(d.mb=a);b.location=new N(c.x-a/2,c.y-a/2)}}}Il.prototype.Mh=function(){var a=this.zk;return null===a?Ld:a.jd};Il.prototype.TF=function(){!0!==this.Ne&&(this.Ne=!0,$n(this))};
Il.prototype.Uu=function(a,b,c,d,e){this.dd||(Vl(this),this.ra(),hm(this),this.Nc(),Zn(this),this.Hh.scale=c,this.Hh.position.x=a.x,this.Hh.position.y=a.y,this.Hh.bounds.set(a),this.Hh.isScroll=e,this.Ja("ViewportBoundsChanged",this.Hh,a))};function Xn(){Uh.call(this);this.Sl=null}D.Sa(Xn,Uh);
Xn.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.g;if(null===a||!a.em||!a.If)return!1;var b=a.xB;if(null===b)return!1;if(null===this.findDraggablePart()){var c=b.vb;this.Sl=new N(c.width/2,c.height/2);a=a.Lc.ga;b.position=new N(a.x-this.Sl.x,a.y-this.Sl.y)}return!0};Xn.prototype.doActivate=function(){this.Sl=null;Uh.prototype.doActivate.call(this)};
Xn.prototype.moveParts=function(){var a=this.g,b=a.xB;if(null!==b){var c=a.Lh;if(null!==c){if(null===this.Sl){var d=a.Lc.ga,c=c.location;this.Sl=new N(d.x-c.x,d.y-c.y)}a=a.U.ga;b.position=new N(a.x-this.Sl.x,a.y-this.Sl.y)}}};function Yn(){Vj.call(this)}D.Sa(Yn,Vj);Yn.prototype.resize=function(a){var b=this.g.xB;if(null!==b){var c=b.vb.copy();b.position=a.position;(c.width!==a.width||c.height!==a.height)&&0<a.width&&0<a.height&&(b.scale=Math.min(c.width/a.width,c.height/a.height))}};
function ao(){this.pg=this.md=this.Nd=this.Pc=0}
function Ba(a){1<arguments.length&&D.k("Brush constructor can take at most one optional argument, the Brush type.");D.vc(this);this.J=!1;void 0===a?(this.ca=Fg,this.Lo="black"):"string"===typeof a?(this.ca=Fg,v&&!Aa(a)&&D.k('Color "'+a+'" is not a valid color string for Brush constructor'),this.Lo=a):(v&&D.Da(a,Ba,Ba,"constructor:type"),this.ca=a,this.Lo="black");var b=this.ca;b===Gg?(this.Lp=jc,this.Zo=uc):this.Zo=b===Id?this.Lp=mc:this.Lp=dc;this.Zw=0;this.Uv=NaN;this.th=this.Jw=this.sh=null;this.nz=
this.Mo=0}D.ka("Brush",Ba);var Fg;Ba.Solid=Fg=D.s(Ba,"Solid",0);var Gg;Ba.Linear=Gg=D.s(Ba,"Linear",1);var Id;Ba.Radial=Id=D.s(Ba,"Radial",2);var bo;Ba.Pattern=bo=D.s(Ba,"Pattern",4);var co;Ba.Lab=co=D.s(Ba,"Lab",5);var eo;Ba.HSL=eo=D.s(Ba,"HSL",6);Ba.prototype.copy=function(){var a=new Ba;a.ca=this.ca;a.Lo=this.Lo;a.Lp=this.Lp.V();a.Zo=this.Zo.V();a.Zw=this.Zw;a.Uv=this.Uv;null!==this.sh&&(a.sh=this.sh.copy());a.Jw=this.Jw;return a};f=Ba.prototype;
f.Oa=function(){this.freeze();Object.freeze(this);return this};f.freeze=function(){this.J=!0;null!==this.sh&&this.sh.freeze();return this};f.Xa=function(){Object.isFrozen(this)&&D.k("cannot thaw constant: "+this);this.J=!1;null!==this.sh&&this.sh.Xa();return this};f.qc=function(a){a.Oe===Ba?this.type=a:D.Yj(this,a)};
f.toString=function(){var a="Brush(";if(this.type===Fg)a+=this.color;else if(a=this.type===Gg?a+"Linear ":this.type===Id?a+"Radial ":this.type===bo?a+"Pattern ":a+"(unknown) ",a+=this.start+" "+this.end,null!==this.Nk)for(var b=this.Nk.j;b.next();)a+=" "+b.key+":"+b.value;return a+")"};
Ba.prototype.addColorStop=Ba.prototype.addColorStop=function(a,b){this.J&&D.qa(this);("number"!==typeof a||!isFinite(a)||1<a||0>a)&&D.ua(a,"0 <= loc <= 1",Ba,"addColorStop:loc");D.h(b,"string",Ba,"addColorStop:color");v&&!Aa(b)&&D.k('Color "'+b+'" is not a valid color string for Brush.addColorStop');null===this.sh&&(this.sh=new oa("number","string"));this.sh.add(a,b);this.ca===Fg&&(this.type=Gg);this.th=null};
D.defineProperty(Ba,{type:"type"},function(){return this.ca},function(a){this.J&&D.qa(this,a);D.Da(a,Ba,Ba,"type");this.ca=a;this.start.ce()&&(a===Gg?this.start=jc:a===Id&&(this.start=mc));this.end.ce()&&(a===Gg?this.end=uc:a===Id&&(this.end=mc));this.th=null});D.defineProperty(Ba,{color:"color"},function(){return this.Lo},function(a){this.J&&D.qa(this,a);v&&!Aa(a)&&D.k('Color "'+a+'" is not a valid color string for Brush.color');this.Lo=a;this.th=null});
D.defineProperty(Ba,{start:"start"},function(){return this.Lp},function(a){this.J&&D.qa(this,a);D.l(a,S,Ba,"start");this.Lp=a.V();this.th=null});D.defineProperty(Ba,{end:"end"},function(){return this.Zo},function(a){this.J&&D.qa(this,a);D.l(a,S,Ba,"end");this.Zo=a.V();this.th=null});D.defineProperty(Ba,{iv:"startRadius"},function(){return this.Zw},function(a){this.J&&D.qa(this,a);D.p(a,Ba,"startRadius");0>a&&D.ua(a,">= zero",Ba,"startRadius");this.Zw=a;this.th=null});
D.defineProperty(Ba,{gu:"endRadius"},function(){return this.Uv},function(a){this.J&&D.qa(this,a);D.p(a,Ba,"endRadius");0>a&&D.ua(a,">= zero",Ba,"endRadius");this.Uv=a;this.th=null});D.defineProperty(Ba,{Nk:"colorStops"},function(){return this.sh},function(a){this.J&&D.qa(this,a);v&&D.l(a,oa,Ba,"colorStops");this.sh=a;this.th=null});D.defineProperty(Ba,{pattern:"pattern"},function(){return this.Jw},function(a){this.J&&D.qa(this,a);this.Jw=a;this.th=null});
Ba.randomColor=function(a,b){void 0===a&&(a=128);v&&(D.p(a,Ba,"randomColor:min"),(0>a||255<a)&&D.ua(a,"0 <= min <= 255",Ba,"randomColor:min"));void 0===b&&(b=Math.max(a,255));v&&(D.p(b,Ba,"randomColor:max"),(b<a||255<b)&&D.ua(b,"min <= max <= 255",Ba,"randomColor:max"));var c=Math.abs(b-a),d=Math.floor(a+Math.random()*c).toString(16),e=Math.floor(a+Math.random()*c).toString(16),c=Math.floor(a+Math.random()*c).toString(16);2>d.length&&(d="0"+d);2>e.length&&(e="0"+e);2>c.length&&(c="0"+c);return"#"+
d+e+c};var fo=(new ja(null)).Qk,Aa;Ba.isValidColor=Aa=function(a){if("black"===a)return!0;if(""===a)return!1;v&&D.h(a,"string",Ba,"isValidColor");fo.fillStyle="#000000";var b=fo.fillStyle;fo.fillStyle=a;if(fo.fillStyle!==b)return!0;fo.fillStyle="#FFFFFF";b=fo.fillStyle;fo.fillStyle=a;return fo.fillStyle!==b};var ho=new ao,io=new ao,jo=new ao,ko=new ao;Ba.lighten=function(a){return lo(a)};
Ba.prototype.lightenBy=function(a,b){this.J&&D.qa(this);var c=void 0===a||"number"!==typeof a?.2:a,d=void 0===b?co:b;if(this.type===Fg)mo(this.color),this.color=no(c,d);else if((this.type===Gg||this.type===Id)&&null!==this.Nk)for(var e=this.Nk.j;e.next();)mo(e.value),this.addColorStop(e.key,no(c,d));return this};var lo;Ba.lightenBy=lo=function(a,b,c){b=void 0===b||"number"!==typeof b?.2:b;c=void 0===c?co:c;mo(a);return no(b,c)};Ba.darken=function(a){return oo(a)};
Ba.prototype.darkenBy=function(a,b){this.J&&D.qa(this);var c=void 0===a||"number"!==typeof a?.2:a,d=void 0===b?co:b;if(this.type===Fg)mo(this.color),this.color=no(-c,d);else if((this.type===Gg||this.type===Id)&&null!==this.Nk)for(var e=this.Nk.j;e.next();)mo(e.value),this.addColorStop(e.key,no(-c,d));return this};var oo;Ba.darkenBy=oo=function(a,b,c){b=void 0===b||"number"!==typeof b?.2:b;c=void 0===c?co:c;mo(a);return no(-b,c)};
function no(a,b){switch(b){case co:var c=100*po(ho.Pc),d=100*po(ho.Nd),e=100*po(ho.md);jo.Pc=.4124564*c+.3575761*d+.1804375*e;jo.Nd=.2126729*c+.7151522*d+.072175*e;jo.md=.0193339*c+.119192*d+.9503041*e;jo.pg=ho.pg;c=qo(jo.Pc/ro[0]);d=qo(jo.Nd/ro[1]);e=qo(jo.md/ro[2]);ko.Pc=116*d-16;ko.Nd=500*(c-d);ko.md=200*(d-e);ko.pg=jo.pg;ko.Pc=Math.min(100,Math.max(0,ko.Pc+100*a));c=(ko.Pc+16)/116;d=c-ko.md/200;jo.Pc=ro[0]*so(ko.Nd/500+c);jo.Nd=ro[1]*(ko.Pc>to*uo?Math.pow(c,3):ko.Pc/to);jo.md=ro[2]*so(d);jo.pg=
ko.pg;c=-.969266*jo.Pc+1.8760108*jo.Nd+.041556*jo.md;d=.0556434*jo.Pc+-.2040259*jo.Nd+1.0572252*jo.md;ho.Pc=255*vo((3.2404542*jo.Pc+-1.5371385*jo.Nd+-.4985314*jo.md)/100);ho.Nd=255*vo(c/100);ho.md=255*vo(d/100);ho.pg=jo.pg;ho.Pc=Math.round(ho.Pc);255<ho.Pc?ho.Pc=255:0>ho.Pc&&(ho.Pc=0);ho.Nd=Math.round(ho.Nd);255<ho.Nd?ho.Nd=255:0>ho.Nd&&(ho.Nd=0);ho.md=Math.round(ho.md);255<ho.md?ho.md=255:0>ho.md&&(ho.md=0);return"rgba("+ho.Pc+", "+ho.Nd+", "+ho.md+", "+ho.pg+")";case eo:var e=ho.Pc/255,g=ho.Nd/
255,h=ho.md/255,k=Math.max(e,g,h),d=Math.min(e,g,h),l=k-d,d=(k+d)/2;if(0===l)c=e=0;else{switch(k){case e:c=(g-h)/l%6;break;case g:c=(h-e)/l+2;break;case h:c=(e-g)/l+4}c*=60;0>c&&(c+=360);e=l/(1-Math.abs(2*d-1))}io.Pc=Math.round(c);io.Nd=Math.round(100*e);io.md=Math.round(100*d);io.pg=ho.pg;io.md=Math.min(100,Math.max(0,io.md+100*a));return"hsla("+io.Pc+", "+io.Nd+"%, "+io.md+"%, "+io.pg+")";default:return D.k("Unknown color space: "+b),"rgba(0, 0, 0, 1)"}}
function mo(a){fo.clearRect(0,0,1,1);fo.fillStyle="#000000";var b=fo.fillStyle;fo.fillStyle=a;fo.fillStyle!==b?(fo.fillRect(0,0,1,1),a=fo.getImageData(0,0,1,1).data,ho.Pc=a[0],ho.Nd=a[1],ho.md=a[2],ho.pg=a[3]/255):(fo.fillStyle="#FFFFFF",b=fo.fillStyle,fo.fillStyle=a,fo.fillStyle===b&&v&&D.k('Color "'+a+'" is not a valid color string for RGBA color conversion'),ho.Pc=0,ho.Nd=0,ho.md=0,ho.pg=1)}function po(a){a/=255;return.04045>=a?a/12.92:Math.pow((a+.055)/1.055,2.4)}
function vo(a){return.0031308>=a?12.92*a:1.055*Math.pow(a,1/2.4)-.055}var uo=216/24389,to=24389/27,ro=[95.047,100,108.883];function qo(a){return a>uo?Math.pow(a,1/3):(to*a+16)/116}function so(a){var b=a*a*a;return b>uo?b:(116*a-16)/to}
function P(){D.vc(this);this.T=4225027;this.Hc=1;this.Dh=null;this.ac="";this.kc=this.Lb=null;this.tb=(new N(NaN,NaN)).freeze();this.af=ie;this.ni=Xd;this.mi=ge;this.Jc=new Ea;this.Ni=new Ea;this.gj=new Ea;this.Db=this.Xo=1;this.wg=0;this.Rg=wo;this.fn=Sd;this.Dd=(new C(NaN,NaN,NaN,NaN)).freeze();this.cc=(new C(NaN,NaN,NaN,NaN)).freeze();this.ed=(new C(0,0,NaN,NaN)).freeze();this.$=this.Zs=this.$s=null;this.Lm=this.te=Vc;this.lt=0;this.qj=1;this.or=0;this.Si=1;this.Bt=null;this.pt=-Infinity;this.rn=
0;this.sn=Kd;this.tn=Mj;this.xr="";this.Dc=this.na=null;this.Jo=-1;this.xn=this.zg=this.xl=this.Kp=null;this.aA=Pj;this.nn=null}D.Di(P);D.ka("GraphObject",P);
P.prototype.cloneProtected=function(a){a.T=this.T|6144;a.Hc=this.Hc;a.ac=this.ac;a.Lb=this.Lb;a.kc=this.kc;a.tb.assign(this.tb);a.af=this.af.V();a.ni=this.ni.V();a.mi=this.mi.V();a.gj=this.gj.copy();a.Db=this.Db;a.wg=this.wg;a.Rg=this.Rg;a.fn=this.fn.V();a.Dd.assign(this.Dd);a.cc.assign(this.cc);a.ed.assign(this.ed);a.Zs=this.Zs;null!==this.$&&(a.$=this.$.copy());a.te=this.te.V();a.Lm=this.Lm.V();a.lt=this.lt;a.qj=this.qj;a.or=this.or;a.Si=this.Si;a.Bt=this.Bt;a.pt=this.pt;a.rn=this.rn;a.sn=this.sn.V();
a.tn=this.tn;a.xr=this.xr;null!==this.na&&(a.na=this.na.copy());a.Dc=this.Dc;a.Jo=this.Jo;null!==this.xl&&(a.xl=D.jm(this.xl));null!==this.zg&&(a.zg=this.zg.copy());a.xn=this.xn};P.prototype.addCopyProperty=P.prototype.QH=function(a){var b=this.xl;if(D.isArray(b))for(var c=0;c<b.length;c++){if(b[c]===a)return}else this.xl=b=[];b.push(a)};P.prototype.Ei=function(a){a.$s=null;a.nn=null;a.K()};
P.prototype.clone=function(){var a=new this.constructor;this.cloneProtected(a);if(null!==this.xl)for(var b=0;b<this.xl.length;b++){var c=this.xl[b];a[c]=this[c]}return a};P.prototype.copy=function(){return this.clone()};P.prototype.qc=function(a){a.Oe===J?0===a.name.indexOf("Orient")?this.Lq=a:D.k("Unknown Link enum value for GraphObject.segmentOrientation property: "+a):a.Oe===P?this.stretch=a:D.Yj(this,a)};P.prototype.toString=function(){return D.vf(Object.getPrototypeOf(this))+"#"+D.Jd(this)};
var Yj;P.None=Yj=D.s(P,"None",0);var wo;P.Default=wo=D.s(P,"Default",0);var xo;P.Vertical=xo=D.s(P,"Vertical",4);var yo;P.Horizontal=yo=D.s(P,"Horizontal",5);var rf;P.Fill=rf=D.s(P,"Fill",3);var ak;P.Uniform=ak=D.s(P,"Uniform",1);var bk;P.UniformToFill=bk=D.s(P,"UniformToFill",2);var zo;P.FlipVertical=zo=D.s(P,"FlipVertical",1);var Ao;P.FlipHorizontal=Ao=D.s(P,"FlipHorizontal",2);var Bo;P.FlipBoth=Bo=D.s(P,"FlipBoth",3);function Co(a){null===a.na&&(a.na=new Do)}
P.prototype.$d=function(){if(null===this.$){var a=new Eo;a.mk=dc;a.Ik=dc;a.kk=10;a.Gk=10;a.jk=Fo;a.Fk=Fo;a.lk=0;a.Hk=0;this.$=a}};function Go(a,b,c,d,e,g,h){var k=.001,l=g.length;a.moveTo(b,c);d-=b;k=e-c;0===d&&(d=.001);e=k/d;for(var m=Math.sqrt(d*d+k*k),n=0,p=!0,q=0===h?!1:!0;.1<=m;){if(q){k=g[n++%l];for(k-=h;0>k;)k+=g[n++%l],p=!p;q=!1}else k=g[n++%l];k>m&&(k=m);var r=Math.sqrt(k*k/(1+e*e));0>d&&(r=-r);b+=r;c+=e*r;p?a.lineTo(b,c):a.moveTo(b,c);m-=k;p=!p}}
P.prototype.raiseChangedEvent=P.prototype.nd=function(a,b,c,d,e,g,h){var k=this.Y;if(null!==k&&(k.qo(a,b,c,d,e,g,h),Ho(this)&&c===this&&a===fg&&Io(this,k,b),c===k&&0!==(k.T&16777216)&&null!==k.data))for(a=this.xa.o,c=a.length,d=0;d<c;d++)e=a[d],e instanceof x&&Wm(e,function(a){null!==a.data&&0!==(a.T&16777216)&&a.Ob(b)})};
function Io(a,b,c){var d=a.rm();if(null!==d)for(var e=a.Dc.j;e.next();){var g=e.value,h=null;if(null!==g.Sq){h=sh(g,d,a);if(null===h)continue;g.Oy(a,h,c,null)}else if(g.ay){var k=b.g;null===k||k.Xe||g.Oy(a,k.da.bl,c,d)}else{var l=d.data;if(null===l)continue;k=b.g;null===k||k.Xe||g.Oy(a,l,c,d)}h===a&&(k=d.Lx(g.Fm),null!==k&&g.qH(k,h,c))}}P.prototype.Lx=function(a){return this.Jo===a?this:null};P.prototype.raiseChanged=P.prototype.i=function(a,b,c){this.nd(fg,a,this,b,c)};
function Jo(a,b,c,d,e){var g=a.Dd,h=a.gj;h.reset();Ko(a,h,b,c,d,e);a.gj=h;g.x=b;g.y=c;g.width=d;g.height=e;h.Du()||h.nH(g)}function Lo(a,b,c,d){if(!1===a.tg)return!1;d.multiply(a.transform);return c?a.jg(b,d):a.Jn(b,d)}
P.prototype.yF=function(a,b,c){if(!1===this.tg)return!1;var d=this.Ga;b=a.Jf(b);var e=!1;c&&(e=lb(a.x,a.y,0,0,0,d.height)<b||lb(a.x,a.y,0,d.height,d.width,d.height)<b||lb(a.x,a.y,d.width,d.height,d.width,0)<b||lb(a.x,a.y,d.width,0,0,0)<b);c||(e=lb(a.x,a.y,0,0,0,d.height)<b&&lb(a.x,a.y,0,d.height,d.width,d.height)<b&&lb(a.x,a.y,d.width,d.height,d.width,0)<b&&lb(a.x,a.y,d.width,0,0,0)<b);return e};P.prototype.nh=function(){return!0};
P.prototype.containsPoint=P.prototype.Pa=function(a){v&&D.l(a,N,P,"containsPoint:p");var b=D.P();b.assign(a);this.transform.xb(b);var c=this.Z;if(!c.F())return D.A(b),!1;var d=this.g;if(null!==d&&d.Il){var e=d.tu("extraTouchThreshold"),g=d.tu("extraTouchArea"),h=g/2,k=this.Ga,d=this.Fj()*d.scale,l=1/d;if(k.width*d<e&&k.height*d<e)return a=Vb(c.x-h*l,c.y-h*l,c.width+g*l,c.height+g*l,b.x,b.y),D.A(b),a}if(this instanceof da||this instanceof z?Vb(c.x-5,c.y-5,c.width+10,c.height+10,b.x,b.y):c.Pa(b))return e=
!1,e=this.zg&&!this.zg.Pa(b)?!1:null!==this.kc&&c.Pa(b)?!0:null!==this.Lb&&this.ed.Pa(a)?!0:this.Ok(a),D.A(b),e;D.A(b);return!1};P.prototype.Ok=function(a){var b=this.Ga;return Vb(0,0,b.width,b.height,a.x,a.y)};
P.prototype.containsRect=P.prototype.Pk=function(a){v&&D.l(a,C,P,"containsRect:r");if(0===this.angle)return this.Z.Pk(a);var b=this.Ga,b=D.vg(0,0,b.width,b.height),c=this.transform,d=!1,e=D.Fb(a.x,a.y);b.Pa(c.Fi(e))&&(e.n(a.x,a.bottom),b.Pa(c.Fi(e))&&(e.n(a.right,a.bottom),b.Pa(c.Fi(e))&&(e.n(a.right,a.y),b.Pa(c.Fi(e))&&(d=!0))));D.A(e);D.Kb(b);return d};
P.prototype.containedInRect=P.prototype.Jn=function(a,b){v&&D.l(a,C,P,"containedInRect:r");if(void 0===b)return a.Pk(this.Z);var c=this.Ga,d=!1,e=D.Fb(0,0);a.Pa(b.xb(e))&&(e.n(0,c.height),a.Pa(b.xb(e))&&(e.n(c.width,c.height),a.Pa(b.xb(e))&&(e.n(c.width,0),a.Pa(b.xb(e))&&(d=!0))));D.A(e);return d};
P.prototype.intersectsRect=P.prototype.jg=function(a,b){v&&D.l(a,C,P,"intersectsRect:r");if(void 0===b&&(b=this.transform,0===this.angle))return a.jg(this.Z);var c=this.Ga,d=b,e=D.Fb(0,0),g=D.Fb(0,c.height),h=D.Fb(c.width,c.height),k=D.Fb(c.width,0),l=!1;if(a.Pa(d.xb(e))||a.Pa(d.xb(g))||a.Pa(d.xb(h))||a.Pa(d.xb(k)))l=!0;else{var c=D.vg(0,0,c.width,c.height),m=D.Fb(a.x,a.y);c.Pa(d.Fi(m))?l=!0:(m.n(a.x,a.bottom),c.Pa(d.Fi(m))?l=!0:(m.n(a.right,a.bottom),c.Pa(d.Fi(m))?l=!0:(m.n(a.right,a.y),c.Pa(d.Fi(m))&&
(l=!0))));D.A(m);D.Kb(c);!l&&(Oe(a,e,g)||Oe(a,g,h)||Oe(a,h,k)||Oe(a,k,e))&&(l=!0)}D.A(e);D.A(g);D.A(h);D.A(k);return l};P.prototype.getDocumentPoint=P.prototype.eb=function(a,b){void 0===b&&(b=new N);if(a instanceof S){a.ce()&&D.k("getDocumentPoint:s Spot must be specific: "+a.toString());var c=this.Ga;b.n(a.x*c.width+a.offsetX,a.y*c.height+a.offsetY)}else b.set(a);this.Jh.xb(b);return b};
P.prototype.getDocumentAngle=P.prototype.tm=function(){var a;a=this.Jh;1===a.m11&&0===a.m12?a=0:(a=180*Math.atan2(a.m12,a.m11)/Math.PI,0>a&&(a+=360));return a};P.prototype.getDocumentScale=P.prototype.Fj=function(){if(0!==(this.T&4096)===!1)return this.Xo;var a=this.Db;return null!==this.R?a*this.R.Fj():a};P.prototype.getLocalPoint=P.prototype.EF=function(a,b){void 0===b&&(b=new N);b.assign(a);this.Jh.Fi(b);return b};
P.prototype.getNearestIntersectionPoint=P.prototype.FF=function(a,b,c){return this.Un(a.x,a.y,b.x,b.y,c)};f=P.prototype;f.Un=function(a,b,c,d,e){var g=this.transform,h=1/(g.m11*g.m22-g.m12*g.m21),k=g.m22*h,l=-g.m12*h,m=-g.m21*h,n=g.m11*h,p=h*(g.m21*g.dy-g.m22*g.dx),q=h*(g.m12*g.dx-g.m11*g.dy);if(null!==this.fm)return g=this.Z,Je(g.left,g.top,g.right,g.bottom,a,b,c,d,e);h=a*k+b*m+p;a=a*l+b*n+q;b=c*k+d*m+p;c=c*l+d*n+q;e.n(0,0);d=this.Ga;c=Je(0,0,d.width,d.height,h,a,b,c,e);e.transform(g);return c};
function Gk(a,b,c,d,e){if(!1!==Mm(a)){var g=a.margin,h=g.right+g.left,g=g.top+g.bottom;b=Math.max(b-h,0);c=Math.max(c-g,0);e=e||0;d=Math.max((d||0)-h,0);e=Math.max(e-g,0);var h=a.angle,g=0,g=a.Ea,k=0;a instanceof z&&(k=a.mb);90===h||270===h?(b=isFinite(g.height)?g.height+k:b,c=isFinite(g.width)?g.width+k:c):(b=isFinite(g.width)?g.width+k:b,c=isFinite(g.height)?g.height+k:c);var g=d||0,k=e||0,l=a instanceof x;switch(Mo(a,!0)){case Yj:k=g=0;l&&(c=b=Infinity);break;case rf:isFinite(b)&&b>d&&(g=b);isFinite(c)&&
c>e&&(k=c);break;case yo:isFinite(b)&&b>d&&(g=b);k=0;l&&(c=Infinity);break;case xo:isFinite(c)&&c>e&&(k=c),g=0,l&&(b=Infinity)}var l=a.of,m=a.bh;g>l.width&&m.width<l.width&&(g=l.width);k>l.height&&m.height<l.height&&(k=l.height);d=Math.max(g/a.scale,m.width);e=Math.max(k/a.scale,m.height);l.width<d&&(d=Math.min(m.width,d));l.height<e&&(e=Math.min(m.height,e));b=Math.min(l.width,b);c=Math.min(l.height,c);b=Math.max(d,b);c=Math.max(e,c);if(90===h||270===h)g=b,b=c,c=g,g=d,d=e,e=g;a.Dd.Xa();a.io(b,c,
d,e);a.Dd.freeze();a.Dd.F()||D.k("Non-real measuredBounds has been set. Object "+a+", measuredBounds: "+a.Dd.toString());jm(a,!1)}}f.io=function(){};f.Kj=function(){return!1};
f.rc=function(a,b,c,d,e){this.aj();var g=D.Qf();g.assign(this.cc);this.cc.Xa();if(!1===Nm(this)){var h=this.cc;h.x=a;h.y=b;h.width=c;h.height=d}else this.yj(a,b,c,d);this.cc.freeze();this.zg=void 0===e?null:e;c=!1;void 0!==e?c=!0:null!==this.R&&(e=this.R.ed,d=this.Fa,null!==this.fm&&(d=this.cc),c=b+d.height,d=a+d.width,c=!(0<=a+.05&&d<=e.width+.05&&0<=b+.05&&c<=e.height+.05),this instanceof pa&&(a=this.ed,this.sw>a.height||this.rf.ff>a.width))&&(c=!0);this.T=c?this.T|256:this.T&-257;this.cc.F()||
D.k("Non-real actualBounds has been set. Object "+this+", actualBounds: "+this.cc.toString());this.qy(g,this.cc);No(this,!1);D.Kb(g)};f.yj=function(){};
function Oo(a,b,c,d,e){var g=a.Z;g.x=b;g.y=c;g.width=d;g.height=e;if(!a.Ea.F()){g=a.Dd;c=a.margin;b=c.right+c.left;var h=c.top+c.bottom;c=g.width+b;g=g.height+h;d+=b;e+=h;b=Mo(a,!0);c===d&&g===e&&(b=Yj);switch(b){case Yj:if(c>d||g>e)jm(a,!0),Gk(a,c>d?d:c,g>e?e:g);break;case rf:jm(a,!0);Gk(a,d,e,0,0);break;case yo:jm(a,!0);Gk(a,d,g,0,0);break;case xo:jm(a,!0),Gk(a,c,e,0,0)}}}
f.qy=function(a,b){var c=this.Y;null!==c&&null!==c.g&&(c.to!==this&&c.LG!==this&&c.GB!==this||Po(c,!0),this.ra(),Db(a,b)||(c.Yk(),this.Qs(c)))};f.Qs=function(a){null!==this.wd&&(Po(a,!0),a instanceof G&&Qo(a,this))};D.defineProperty(P,{Pq:"shadowVisible"},function(){return this.xn},function(a){var b=this.xn;b!==a&&(v&&null!==a&&D.h(a,"boolean",P,"shadowVisible"),this.xn=a,this.ra(),this.i("shadowVisible",b,a))});
P.prototype.Ge=function(a,b){if(this.visible){var c=this.cc;if(0!==c.width&&0!==c.height&&!isNaN(c.x)&&!isNaN(c.y)){var d=this.opacity,e=1;if(1!==d){if(0===d)return;e=a.globalAlpha;a.globalAlpha=e*d}if(a instanceof qd)a:{if(this.visible){var g=null,h=a.ey;if(this instanceof x&&(this.type===Ro||this.type===So))To(this,a,b);else{var k=this.cc;if(0!==k.width&&0!==k.height&&!isNaN(k.x)&&!isNaN(k.y)){var l=this.transform,m=this.R;0!==(this.T&4096)===!0&&Uo(this);var c=0!==(this.T&256),n=!1;this instanceof
pa&&(a.font=this.font);if(c){n=m.nh()?m.Ga:m.Z;if(null!==this.zg)var p=this.zg,q=p.x,r=p.y,s=p.width,p=p.height;else q=Math.max(k.x,n.x),r=Math.max(k.y,n.y),s=Math.min(k.right,n.right)-q,p=Math.min(k.bottom,n.bottom)-r;if(q>k.width+k.x||k.x>n.width+n.x||r>k.height+k.y||k.y>n.height+n.y)break a;n=!0;sd(a,1,0,0,1,0,0);a.save();a.beginPath();a.rect(q,r,s,p);a.clip()}if(this.Kj()){var u=this;if(!u.isVisible())break a}a.Oh.bc=[1,0,0,1,0,0];this instanceof pa&&1<this.jB&&sd(a,1,0,0,1,0,0);q=!1;this.Kj()&&
this.$k&&b.Gj("drawShadows")&&(r=this.wn,a.TB(r.x*b.scale*b.td,r.y*b.scale*b.td,u.Qg),a.wo(),a.shadowColor=u.vn);u=!1;this.Y&&b.Gj("drawShadows")&&(u=this.Y.$k);!0===this.Pq?(a.wo(),!1===q&&u&&(sd(a,1,0,0,1,0,0),a.kb(),q=!0)):!1===this.Pq&&a.vo();null!==this.kc&&(Vo(this,a,this.kc,!0,!0),!1===q&&u&&(sd(a,1,0,0,1,0,0),a.kb(),q=!0),this.kc instanceof Ba&&this.kc.type===Id?(a.beginPath(),a.rect(k.x,k.y,k.width,k.height),a.Yg(this.kc)):a.fillRect(k.x,k.y,k.width,k.height));this instanceof x?sd(a,l.m11,
l.m12,l.m21,l.m22,l.dx,l.dy):a.Oh.bc=[l.m11,l.m12,l.m21,l.m22,l.dx,l.dy];null!==this.Lb&&(!1===q&&u&&(sd(a,1,0,0,1,0,0),a.kb(),q=!0),s=this.Ga,l=k=0,r=s.width,s=s.height,p=0,this instanceof z&&(s=this.ab.qb,k=s.x,l=s.y,r=s.width,s=s.height,p=this.Sg),Vo(this,a,this.Lb,!0,!1),this.Lb instanceof Ba&&this.Lb.type===Id?(a.beginPath(),a.rect(k-p/2,l-p/2,r+p,s+p),a.Yg(this.Lb)):a.fillRect(k-p/2,l-p/2,r+p,s+p));u&&(null!==this.Lb||null!==this.kc||null!==m&&0!==(m.T&512)||null!==m&&(m.type===Ol||m.type===
Wj)&&m.Xd()!==this)?(Wo(this,!0),null===this.Pq&&a.vo()):Wo(this,!1);this.Rk(a,b);u&&0!==(this.T&512)===!0&&a.wo();this.Kj()&&u&&a.vo();c&&(a.restore(),n&&a.wf.pop());this instanceof x&&(g=a.wf.pop());!0===q&&a.wf.pop();this instanceof pa&&1<this.jB&&(g=a.wf.pop());null!==a.qF&&(null===g&&(h===a.ey?(sd(a,1,0,0,1,0,0),g=a.wf.pop()):g=a.ey),a.qF(this,g))}}}}else{if(this instanceof x&&(this.type===Ro||this.type===So)){To(this,a,b);1!==d&&(a.globalAlpha=e);return}this instanceof J&&this.Mu(!1);v&&v.zj&&
v.AI(a,this);g=this.transform;h=this.R;0!==(this.T&4096)===!0&&Uo(this);m=0!==(this.T&256);a.bq&&(m=!1);this instanceof pa&&(a.font=this.font);if(m){v&&v.pF&&D.trace("clip"+this.toString());n=h.nh()?h.Ga:h.Z;null!==this.zg?(q=this.zg,k=q.x,l=q.y,u=q.width,q=q.height):(k=Math.max(c.x,n.x),l=Math.max(c.y,n.y),u=Math.min(c.right,n.right)-k,q=Math.min(c.bottom,n.bottom)-l);if(k>c.width+c.x||c.x>n.width+n.x||l>c.height+c.y||c.y>n.height+n.y){1!==d&&(a.globalAlpha=e);return}v&&v.pF&&v.CI(a,k,l,u,q);a.save();
a.beginPath();a.rect(k,l,u,q);a.clip()}if(this.Kj()){if(!this.isVisible()){1!==d&&(a.globalAlpha=e);return}this.$k&&b.Gj("drawShadows")&&(n=this.wn,a.TB(n.x*b.scale*b.td,n.y*b.scale*b.td,this.Qg),a.wo(),a.shadowColor=this.vn)}n=!1;this.Y&&b.Gj("drawShadows")&&(n=this.Y.$k);!0===this.Pq?a.wo():!1===this.Pq&&a.vo();null!==this.kc&&(Vo(this,a,this.kc,!0,!0),this.kc instanceof Ba&&this.kc.type===Id?(a.beginPath(),a.rect(c.x,c.y,c.width,c.height),a.Yg(this.kc)):a.fillRect(c.x,c.y,c.width,c.height));g.Du()||
a.transform(g.m11,g.m12,g.m21,g.m22,g.dx,g.dy);null!==this.Lb&&(u=this.Ga,k=c=0,l=u.width,u=u.height,q=0,this instanceof z&&(u=this.ab.qb,c=u.x,k=u.y,l=u.width,u=u.height,q=this.Sg),Vo(this,a,this.Lb,!0,!1),this.Lb instanceof Ba&&this.Lb.type===Id?(a.beginPath(),a.rect(c-q/2,k-q/2,l+q,u+q),a.Yg(this.Lb)):a.fillRect(c-q/2,k-q/2,l+q,u+q));v&&v.zj&&v.BI(a,this);n&&(null!==this.Lb||null!==this.kc||null!==h&&0!==(h.T&512)||null!==h&&(h.type===Ol||h.type===Wj)&&h.Xd()!==this)?(Wo(this,!0),null===this.Pq&&
a.vo()):Wo(this,!1);this.Rk(a,b);n&&0!==(this.T&512)===!0&&a.wo();this.Kj()&&n&&a.vo();m?(a.restore(),this instanceof x?a.De(!0):a.De(!1)):g.Du()||(h=1/(g.m11*g.m22-g.m12*g.m21),a.transform(g.m22*h,-g.m12*h,-g.m21*h,g.m11*h,h*(g.m21*g.dy-g.m22*g.dx),h*(g.m12*g.dx-g.m11*g.dy)))}1!==d&&(a.globalAlpha=e)}}};
function To(a,b,c){var d=a.cc;0===d.width||0===d.height||isNaN(d.x)||isNaN(d.y)||(null!==a.kc&&(Vo(a,b,a.kc,!0,!0),a.kc instanceof Ba&&a.kc.type===Id?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),b.Yg(a.kc)):b.fillRect(d.x,d.y,d.width,d.height)),null!==a.Lb&&(Vo(a,b,a.Lb,!0,!1),a.Lb instanceof Ba&&a.Lb.type===Id?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),b.Yg(a.Lb)):b.fillRect(d.x,d.y,d.width,d.height)),a.Rk(b,c))}P.prototype.Rk=function(){};
function Vo(a,b,c,d,e){if(null!==c){var g=1,h=1;if("string"===typeof c)d?b.fillStyle=c:b.strokeStyle=c;else if(c.type===Fg)d?b.fillStyle=c.color:b.strokeStyle=c.color;else{var k,h=a.Ga,g=h.width,h=h.height;if(e)var l=a.Z,g=l.width,h=l.height;var m=b instanceof Hd;if(m&&c.th&&(c.type===bo||c.Mo===g&&c.nz===h))k=c.th;else{var n=l=0,p=0,q=0,r=0,s=0,s=r=0;e&&(l=a.Z,g=l.width,h=l.height,r=l.x,s=l.y);l=c.start.x*g+c.start.offsetX;n=c.start.y*h+c.start.offsetY;p=c.end.x*g+c.end.offsetX;q=c.end.y*h+c.end.offsetY;
l+=r;p+=r;n+=s;q+=s;if(c.type===Gg)k=b.createLinearGradient(l,n,p,q);else if(c.type===Id)s=isNaN(c.gu)?Math.max(g,h)/2:c.gu,isNaN(c.iv)?(r=0,s=Math.max(g,h)/2):r=c.iv,k=b.createRadialGradient(l,n,r,p,q,s);else if(c.type===bo)try{k=b.createPattern(c.pattern,"repeat")}catch(u){k=null}else D.jc(c.type,"Brush type");if(c.type!==bo&&(e=c.Nk,null!==e))for(e=e.j;e.next();)k.addColorStop(e.key,e.value);if(m&&(c.th=k,null!==k&&(c.Mo=g,c.nz=h),null===k&&c.type===bo&&-1!==c.Mo)){c.Mo=-1;var t=a.g;null!==t&&
-1===c.Mo&&D.setTimeout(function(){t.ro()},600)}}d?b.fillStyle=k:b.strokeStyle=k}}}P.prototype.isContainedBy=P.prototype.ym=function(a){if(a instanceof x)a:{if(this!==a&&null!==a)for(var b=this.R;null!==b;){if(b===a){a=!0;break a}b=b.R}a=!1}else a=!1;return a};P.prototype.isVisibleObject=P.prototype.Oj=function(){if(!this.visible)return!1;var a=this.R;return null!==a?a.Oj():!0};
P.prototype.isEnabledObject=P.prototype.Au=function(){for(var a=this instanceof x?this:this.R;null!==a&&a.isEnabled;)a=a.R;return null===a};D.defineProperty(P,{rF:"enabledChanged"},function(){return null!==this.na?this.na.Pr:null},function(a){Co(this);var b=this.na.Pr;b!==a&&(null!==a&&D.h(a,"function",P,"enabledChanged"),this.na.Pr=a,this.i("enabledChanged",b,a))});
function Uo(a){if(0!==(a.T&2048)===!0){var b=a.Jc;b.reset();if(!a.cc.F()||!a.Dd.F()){Xo(a,!1);return}b.translate(a.cc.x,a.cc.y);b.translate(-a.Fa.x,-a.Fa.y);var c=a.Ga;Ko(a,b,c.x,c.y,c.width,c.height);Xo(a,!1);Yo(a,!0)}0!==(a.T&4096)===!0&&(b=a.R,null===b?(a.Ni.set(a.Jc),a.Xo=a.scale,Yo(a,!1)):null!==b.Jh&&(c=a.Ni,c.reset(),b.nh()?c.multiply(b.Ni):null!==b.R&&c.multiply(b.R.Ni),c.multiply(a.Jc),a.Xo=a.scale*b.Xo,Yo(a,!1)))}
function Ko(a,b,c,d,e,g){1!==a.scale&&b.scale(a.scale);if(0!==a.angle){var h=mc;a.Kj()&&a.Nf.Vc()&&(h=a.Nf);var k=D.P();if(a instanceof F&&a.Bf!==a)for(c=a.Bf,d=c.Ga,k.fv(d.x,d.y,d.width,d.height,h),c.gj.xb(k),k.offset(-c.Fa.x,-c.Fa.y),h=c.R;null!==h&&h!==a;)h.gj.xb(k),k.offset(-h.Fa.x,-h.Fa.y),h=h.R;else k.fv(c,d,e,g,h);b.rotate(a.angle,k.x,k.y);D.A(k)}}f=P.prototype;f.K=function(a){void 0===a&&(a=!1);if(!0!==Mm(this)){jm(this,!0);No(this,!0);var b=this.R;null===b||a||b.K()}};
f.uq=function(){!0!==Mm(this)&&(jm(this,!0),No(this,!0))};function Zo(a){if(!1===Nm(a)){var b=a.R;null!==b?b.K():a.Kj()&&(b=a.g,null!==b&&(b.Hg.add(a),a instanceof G&&a.kg(),b.Ve()));No(a,!0)}}f.aj=function(){0!==(this.T&2048)===!1&&(Xo(this,!0),Yo(this,!0))};f.cB=function(){Yo(this,!0)};f.ra=function(){var a=this.Y;null!==a&&a.ra()};
function Mo(a,b){var c=a.stretch,d=a.R;if(null!==d&&d.ca===fa)return $o(a,d.Zd(a.Sb),d.Yd(a.column),b);if(null!==d&&d.ca===Ol&&d.Xd()===a)return ap(a,rf,b);if(c===wo){if(null!==d){if(d.ca===Wj&&d.Xd()===a)return ap(a,rf,b);c=d.me;return c===wo?ap(a,Yj,b):ap(a,c,b)}return ap(a,Yj,b)}return ap(a,c,b)}
function $o(a,b,c,d){var e=a.stretch;if(e!==wo)return ap(a,e,d);var g=e=null;switch(b.stretch){case xo:g=!0;break;case rf:g=!0}switch(c.stretch){case yo:e=!0;break;case rf:e=!0}b=a.R.me;null===e&&(e=b===yo||b===rf);null===g&&(g=b===xo||b===rf);return!0===e&&!0===g?ap(a,rf,d):!0===e?ap(a,yo,d):!0===g?ap(a,xo,d):ap(a,Yj,d)}
function ap(a,b,c){if(c)return b;if(b===Yj)return Yj;c=a.Ea;if(c.F())return Yj;a=a.angle;if(!isNaN(c.width))if(90!==a&&270!==a){if(b===yo)return Yj;if(b===rf)return xo}else{if(b===xo)return Yj;if(b===rf)return yo}if(!isNaN(c.height))if(90!==a&&270!==a){if(b===xo)return Yj;if(b===rf)return yo}else{if(b===yo)return Yj;if(b===rf)return xo}return b}
D.defineProperty(P,{Lq:"segmentOrientation"},function(){return this.tn},function(a){var b=this.tn;b!==a&&(v&&D.Da(a,J,P,"segmentOrientation"),this.tn=a,this.K(),this.i("segmentOrientation",b,a),a===Mj&&(this.angle=0))});D.defineProperty(P,{We:"segmentIndex"},function(){return this.pt},function(a){v&&D.h(a,"number",P,"segmentIndex");a=Math.round(a);var b=this.pt;b!==a&&(this.pt=a,this.K(),this.i("segmentIndex",b,a))});
D.defineProperty(P,{JB:"segmentFraction"},function(){return this.rn},function(a){v&&D.h(a,"number",P,"segmentFraction");isNaN(a)?a=0:0>a?a=0:1<a&&(a=1);var b=this.rn;b!==a&&(this.rn=a,this.K(),this.i("segmentFraction",b,a))});D.defineProperty(P,{KB:"segmentOffset"},function(){return this.sn},function(a){var b=this.sn;b.O(a)||(v&&D.l(a,N,P,"segmentOffset"),this.sn=a=a.V(),this.K(),this.i("segmentOffset",b,a))});
D.defineProperty(P,{stretch:"stretch"},function(){return this.Rg},function(a){var b=this.Rg;b!==a&&(v&&D.Da(a,P,P,"stretch"),this.Rg=a,this.K(),this.i("stretch",b,a))});D.defineProperty(P,{name:"name"},function(){return this.ac},function(a){var b=this.ac;b!==a&&(v&&D.h(a,"string",P,"name"),this.ac=a,null!==this.Y&&(this.Y.Rl=null),this.i("name",b,a))});
D.defineProperty(P,{opacity:"opacity"},function(){return this.Hc},function(a){var b=this.Hc;b!==a&&(D.h(a,"number",P,"opacity"),(0>a||1<a)&&D.ua(a,"0 <= value <= 1",P,"opacity"),this.Hc=a,this.i("opacity",b,a),a=this.g,b=this.Y,null!==a&&null!==b&&a.ra(wl(b,b.Z)))});
D.defineProperty(P,{visible:"visible"},function(){return 0!==(this.T&1)},function(a){var b=0!==(this.T&1);b!==a&&(v&&D.h(a,"boolean",P,"visible"),this.T^=1,this.i("visible",b,a),b=this.R,null!==b?b.K():this.Kj()&&this.Ld(a),this.ra(),bp(this))});D.defineProperty(P,{tg:"pickable"},function(){return 0!==(this.T&2)},function(a){var b=0!==(this.T&2);b!==a&&(v&&D.h(a,"boolean",P,"pickable"),this.T^=2,this.i("pickable",b,a))});
D.defineProperty(P,{KI:"fromLinkableDuplicates"},function(){return 0!==(this.T&4)},function(a){var b=0!==(this.T&4);b!==a&&(v&&D.h(a,"boolean",P,"fromLinkableDuplicates"),this.T^=4,this.i("fromLinkableDuplicates",b,a))});D.defineProperty(P,{LI:"fromLinkableSelfNode"},function(){return 0!==(this.T&8)},function(a){var b=0!==(this.T&8);b!==a&&(v&&D.h(a,"boolean",P,"fromLinkableSelfNode"),this.T^=8,this.i("fromLinkableSelfNode",b,a))});
D.defineProperty(P,{wK:"toLinkableDuplicates"},function(){return 0!==(this.T&16)},function(a){var b=0!==(this.T&16);b!==a&&(v&&D.h(a,"boolean",P,"toLinkableDuplicates"),this.T^=16,this.i("toLinkableDuplicates",b,a))});D.defineProperty(P,{xK:"toLinkableSelfNode"},function(){return 0!==(this.T&32)},function(a){var b=0!==(this.T&32);b!==a&&(v&&D.h(a,"boolean",P,"toLinkableSelfNode"),this.T^=32,this.i("toLinkableSelfNode",b,a))});
D.defineProperty(P,{Ue:"isPanelMain"},function(){return 0!==(this.T&64)},function(a){var b=0!==(this.T&64);b!==a&&(v&&D.h(a,"boolean",P,"isPanelMain"),this.T^=64,this.K(),this.i("isPanelMain",b,a))});D.defineProperty(P,{yu:"isActionable"},function(){return 0!==(this.T&128)},function(a){var b=0!==(this.T&128);b!==a&&(v&&D.h(a,"boolean",P,"isActionable"),this.T^=128,this.i("isActionable",b,a))});
D.defineProperty(P,{fm:"areaBackground"},function(){return this.kc},function(a){var b=this.kc;b!==a&&(v&&null!==a&&D.$t(a,"GraphObject.areaBackground"),a instanceof Ba&&a.freeze(),this.kc=a,this.ra(),this.i("areaBackground",b,a))});D.defineProperty(P,{background:"background"},function(){return this.Lb},function(a){var b=this.Lb;b!==a&&(v&&null!==a&&D.$t(a,"GraphObject.background"),a instanceof Ba&&a.freeze(),this.Lb=a,this.ra(),this.i("background",b,a))});function Wo(a,b){a.T=b?a.T|512:a.T&-513}
function Ho(a){return 0!==(a.T&1024)}function cp(a,b){a.T=b?a.T|1024:a.T&-1025}function Xo(a,b){a.T=b?a.T|2048:a.T&-2049}function Yo(a,b){a.T=b?a.T|4096:a.T&-4097}function Mm(a){return 0!==(a.T&8192)}function jm(a,b){a.T=b?a.T|8192:a.T&-8193}function Nm(a){return 0!==(a.T&16384)}function No(a,b){a.T=b?a.T|16384:a.T&-16385}D.w(P,{Y:"part"},function(){if(this.Kj())return this;if(null!==this.nn)return this.nn;var a;for(a=this.R;a;){if(a instanceof F)return this.nn=a;a=a.R}return null});
D.w(P,{R:"panel"},function(){return this.Dh});P.prototype.Cm=function(a){this.Dh=a};D.w(P,{layer:"layer"},function(){var a=this.Y;return null!==a?a.layer:null},{configurable:!0});D.w(P,{g:"diagram"},function(){var a=this.Y;return null!==a?a.g:null},{configurable:!0});
D.defineProperty(P,{position:"position"},function(){return this.tb},function(a){v&&D.l(a,N,P,"position");var b=a.x,c=a.y,d=this.tb,e=d.x,g=d.y;(e===b||isNaN(e)&&isNaN(b))&&(g===c||isNaN(g)&&isNaN(c))?this.QB():(a=a.V(),this.PB(a,d)&&this.i("position",d,a))});P.prototype.QB=function(){};P.prototype.PB=function(a){this.tb=a;Zo(this);this.aj();return!0};P.prototype.My=function(a,b){this.tb.n(a,b);this.aj()};D.w(P,{Z:"actualBounds"},function(){return this.cc});
D.defineProperty(P,{scale:"scale"},function(){return this.Db},function(a){var b=this.Db;b!==a&&(v&&D.p(a,P,"scale"),0>=a&&D.k("GraphObject.scale for "+this+" must be greater than zero, not: "+a),this.Db=a,this.aj(),this.K(),this.i("scale",b,a))});D.defineProperty(P,{angle:"angle"},function(){return this.wg},function(a){var b=this.wg;b!==a&&(v&&D.p(a,P,"angle"),a%=360,0>a&&(a+=360),b!==a&&(this.wg=a,bp(this),this.K(),this.aj(),this.i("angle",b,a)))});
D.defineProperty(P,{Ea:"desiredSize"},function(){return this.af},function(a){v&&D.l(a,Ca,P,"desiredSize");var b=a.width,c=a.height,d=this.af,e=d.width,g=d.height;(e===b||isNaN(e)&&isNaN(b))&&(g===c||isNaN(g)&&isNaN(c))||(this.af=a=a.V(),this.K(),this instanceof z&&this.Te(),this.i("desiredSize",d,a),Ho(this)&&(a=this.Y,null!==a&&(Io(this,a,"width"),Io(this,a,"height"))))});
D.defineProperty(P,{width:"width"},function(){return this.af.width},function(a){var b=this.af.width;b===a||isNaN(b)&&isNaN(a)||(v&&D.h(a,"number",P,"width"),b=this.af,this.af=a=(new Ca(a,this.af.height)).freeze(),this.K(),this instanceof z&&this.Te(),this.i("desiredSize",b,a),Ho(this)&&(a=this.Y,null!==a&&Io(this,a,"width")))});
D.defineProperty(P,{height:"height"},function(){return this.af.height},function(a){var b=this.af.height;b===a||isNaN(b)&&isNaN(a)||(v&&D.h(a,"number",P,"height"),b=this.af,this.af=a=(new Ca(this.af.width,a)).freeze(),this.K(),this instanceof z&&this.Te(),this.i("desiredSize",b,a),Ho(this)&&(a=this.Y,null!==a&&Io(this,a,"height")))});
D.defineProperty(P,{bh:"minSize"},function(){return this.ni},function(a){var b=this.ni;b.O(a)||(v&&D.l(a,Ca,P,"minSize"),a=a.copy(),isNaN(a.width)&&(a.width=0),isNaN(a.height)&&(a.height=0),a.freeze(),this.ni=a,this.K(),this.i("minSize",b,a))});D.defineProperty(P,{of:"maxSize"},function(){return this.mi},function(a){var b=this.mi;b.O(a)||(v&&D.l(a,Ca,P,"maxSize"),a=a.copy(),isNaN(a.width)&&(a.width=Infinity),isNaN(a.height)&&(a.height=Infinity),a.freeze(),this.mi=a,this.K(),this.i("maxSize",b,a))});
D.w(P,{Fa:"measuredBounds"},function(){return this.Dd});D.w(P,{Ga:"naturalBounds"},function(){return this.ed},{configurable:!0});D.defineProperty(P,{margin:"margin"},function(){return this.fn},function(a){"number"===typeof a?a=new Lb(a):v&&D.l(a,Lb,P,"margin");var b=this.fn;b.O(a)||(this.fn=a=a.V(),this.K(),this.i("margin",b,a))});D.w(P,{transform:null},function(){0!==(this.T&2048)===!0&&Uo(this);return this.Jc});D.w(P,{Jh:null},function(){0!==(this.T&4096)===!0&&Uo(this);return this.Ni});
D.defineProperty(P,{alignment:"alignment"},function(){return this.te},function(a){var b=this.te;b.O(a)||(v&&D.l(a,S,P,"alignment"),a.ce()&&!a.kd()&&D.k("GraphObject.alignment for "+this+" must be a real Spot or Spot.Default, not: "+a),this.te=a=a.V(),Zo(this),this.i("alignment",b,a))});D.defineProperty(P,{column:"column"},function(){return this.or},function(a){v&&D.p(a,P,"column");a=Math.round(a);var b=this.or;b!==a&&(0>a&&D.ua(a,">= 0",P,"column"),this.or=a,this.K(),this.i("column",b,a))});
D.defineProperty(P,{fI:"columnSpan"},function(){return this.Si},function(a){v&&D.h(a,"number",P,"columnSpan");a=Math.round(a);var b=this.Si;b!==a&&(1>a&&D.ua(a,">= 1",P,"columnSpan"),this.Si=a,this.K(),this.i("columnSpan",b,a))});D.defineProperty(P,{Sb:"row"},function(){return this.lt},function(a){v&&D.p(a,P,"row");a=Math.round(a);var b=this.lt;b!==a&&(0>a&&D.ua(a,">= 0",P,"row"),this.lt=a,this.K(),this.i("row",b,a))});
D.defineProperty(P,{rowSpan:"rowSpan"},function(){return this.qj},function(a){v&&D.h(a,"number",P,"rowSpan");a=Math.round(a);var b=this.qj;b!==a&&(1>a&&D.ua(a,">= 1",P,"rowSpan"),this.qj=a,this.K(),this.i("rowSpan",b,a))});D.defineProperty(P,{Ny:"spanAllocation"},function(){return this.Bt},function(a){var b=this.Bt;b!==a&&(null!==a&&D.h(a,"function",P,"spanAllocation"),this.Bt=a,this.K(),this.i("spanAllocation",b,a))});
D.defineProperty(P,{Ih:"alignmentFocus"},function(){return this.Lm},function(a){var b=this.Lm;b.O(a)||(v&&D.l(a,S,P,"alignmentFocus"),!a.ce()||a.kd()||a.O(dc)&&this instanceof G||D.k("GraphObject.alignmentFocus must be a real Spot or Spot.Default, not: "+a),this.Lm=a=a.V(),this.K(),this.i("alignmentFocus",b,a))});
D.defineProperty(P,{wd:"portId"},function(){return this.Zs},function(a){var b=this.Zs;if(b!==a){v&&null!==a&&D.h(a,"string",P,"portId");var c=this.Y;null===c||c instanceof G||(D.k("Cannot set portID on a Link: "+a),c=null);null!==b&&null!==c&&dp(c,this);this.Zs=a;if(null!==a&&c){c.Xk=!0;null===c.ze&&ep(c);var d=this.wd;null!==d&&c.ze.add(d,this)}this.i("portId",b,a)}});function fp(a){var b=a.Y;if(b instanceof G&&(null!==a.wd||a===b.port)){var c=b.g;null===c||c.la.jb||Qo(b,a)}}
function bp(a){var b=a.g;null===b||b.la.jb||(a instanceof x?a instanceof G?a.kg():gp(a,a,function(a){fp(a)}):fp(a))}D.defineProperty(P,{Hb:"toSpot"},function(){return null!==this.$?this.$.Ik:dc},function(a){this.$d();var b=this.$.Ik;b.O(a)||(v&&D.l(a,S,P,"toSpot"),a=a.V(),this.$.Ik=a,this.i("toSpot",b,a),fp(this))});
D.defineProperty(P,{Hm:"toEndSegmentLength"},function(){return null!==this.$?this.$.Gk:10},function(a){this.$d();var b=this.$.Gk;b!==a&&(v&&D.h(a,"number",P,"toEndSegmentLength"),0>a&&D.ua(a,">= 0",P,"toEndSegmentLength"),this.$.Gk=a,this.i("toEndSegmentLength",b,a),fp(this))});
D.defineProperty(P,{kv:"toEndSegmentDirection"},function(){return null!==this.$?this.$.Fk:Fo},function(a){this.$d();var b=this.$.Fk;b!==a&&(D.Pn("GraphObject.toEndSegmentDirection","2.0"),v&&D.Da(a,G,P,"toEndSegmentDirection"),this.$.Fk=a,this.i("toEndSegmentDirection",b,a),fp(this))});D.defineProperty(P,{lv:"toShortLength"},function(){return null!==this.$?this.$.Hk:0},function(a){this.$d();var b=this.$.Hk;b!==a&&(v&&D.h(a,"number",P,"toShortLength"),this.$.Hk=a,this.i("toShortLength",b,a),fp(this))});
D.defineProperty(P,{hH:"toLinkable"},function(){return null!==this.$?this.$.Jt:null},function(a){this.$d();var b=this.$.Jt;b!==a&&(v&&null!==a&&D.h(a,"boolean",P,"toLinkable"),this.$.Jt=a,this.i("toLinkable",b,a))});D.defineProperty(P,{yK:"toMaxLinks"},function(){return null!==this.$?this.$.Kt:Infinity},function(a){this.$d();var b=this.$.Kt;b!==a&&(v&&D.h(a,"number",P,"toMaxLinks"),0>a&&D.ua(a,">= 0",P,"toMaxLinks"),this.$.Kt=a,this.i("toMaxLinks",b,a))});
D.defineProperty(P,{Gb:"fromSpot"},function(){return null!==this.$?this.$.mk:dc},function(a){this.$d();var b=this.$.mk;b.O(a)||(v&&D.l(a,S,P,"fromSpot"),a=a.V(),this.$.mk=a,this.i("fromSpot",b,a),fp(this))});D.defineProperty(P,{sm:"fromEndSegmentLength"},function(){return null!==this.$?this.$.kk:10},function(a){this.$d();var b=this.$.kk;b!==a&&(v&&D.h(a,"number",P,"fromEndSegmentLength"),0>a&&D.ua(a,">= 0",P,"fromEndSegmentLength"),this.$.kk=a,this.i("fromEndSegmentLength",b,a),fp(this))});
D.defineProperty(P,{ru:"fromEndSegmentDirection"},function(){return null!==this.$?this.$.jk:Fo},function(a){this.$d();var b=this.$.jk;b!==a&&(D.Pn("GraphObject.fromEndSegmentDirection","2.0"),v&&D.Da(a,G,P,"fromEndSegmentDirection"),this.$.jk=a,this.i("fromEndSegmentDirection",b,a),fp(this))});
D.defineProperty(P,{su:"fromShortLength"},function(){return null!==this.$?this.$.lk:0},function(a){this.$d();var b=this.$.lk;b!==a&&(v&&D.h(a,"number",P,"fromShortLength"),this.$.lk=a,this.i("fromShortLength",b,a),fp(this))});D.defineProperty(P,{BF:"fromLinkable"},function(){return null!==this.$?this.$.Sr:null},function(a){this.$d();var b=this.$.Sr;b!==a&&(v&&null!==a&&D.h(a,"boolean",P,"fromLinkable"),this.$.Sr=a,this.i("fromLinkable",b,a))});
D.defineProperty(P,{SA:"fromMaxLinks"},function(){return null!==this.$?this.$.Tr:Infinity},function(a){this.$d();var b=this.$.Tr;b!==a&&(v&&D.h(a,"number",P,"fromMaxLinks"),0>a&&D.ua(a,">= 0",P,"fromMaxLinks"),this.$.Tr=a,this.i("fromMaxLinks",b,a))});D.defineProperty(P,{cursor:"cursor"},function(){return this.xr},function(a){var b=this.xr;b!==a&&(D.h(a,"string",P,"cursor"),this.xr=a,this.i("cursor",b,a))});
D.defineProperty(P,{click:"click"},function(){return null!==this.na?this.na.Ri:null},function(a){Co(this);var b=this.na.Ri;b!==a&&(null!==a&&D.h(a,"function",P,"click"),this.na.Ri=a,this.i("click",b,a))});D.defineProperty(P,{fu:"doubleClick"},function(){return null!==this.na?this.na.Yi:null},function(a){Co(this);var b=this.na.Yi;b!==a&&(null!==a&&D.h(a,"function",P,"doubleClick"),this.na.Yi=a,this.i("doubleClick",b,a))});
D.defineProperty(P,{BA:"contextClick"},function(){return null!==this.na?this.na.Ti:null},function(a){Co(this);var b=this.na.Ti;b!==a&&(null!==a&&D.h(a,"function",P,"contextClick"),this.na.Ti=a,this.i("contextClick",b,a))});D.defineProperty(P,{Ou:"mouseEnter"},function(){return null!==this.na?this.na.Is:null},function(a){Co(this);var b=this.na.Is;b!==a&&(null!==a&&D.h(a,"function",P,"mouseEnter"),this.na.Is=a,this.i("mouseEnter",b,a))});
D.defineProperty(P,{Pu:"mouseLeave"},function(){return null!==this.na?this.na.Js:null},function(a){Co(this);var b=this.na.Js;b!==a&&(null!==a&&D.h(a,"function",P,"mouseLeave"),this.na.Js=a,this.i("mouseLeave",b,a))});D.defineProperty(P,{sB:"mouseOver"},function(){return null!==this.na?this.na.lj:null},function(a){Co(this);var b=this.na.lj;b!==a&&(null!==a&&D.h(a,"function",P,"mouseOver"),this.na.lj=a,this.i("mouseOver",b,a))});
D.defineProperty(P,{rB:"mouseHover"},function(){return null!==this.na?this.na.kj:null},function(a){Co(this);var b=this.na.kj;b!==a&&(null!==a&&D.h(a,"function",P,"mouseHover"),this.na.kj=a,this.i("mouseHover",b,a))});D.defineProperty(P,{qB:"mouseHold"},function(){return null!==this.na?this.na.jj:null},function(a){Co(this);var b=this.na.jj;b!==a&&(null!==a&&D.h(a,"function",P,"mouseHold"),this.na.jj=a,this.i("mouseHold",b,a))});
D.defineProperty(P,{NJ:"mouseDragEnter"},function(){return null!==this.na?this.na.Gs:null},function(a){Co(this);var b=this.na.Gs;b!==a&&(null!==a&&D.h(a,"function",P,"mouseDragEnter"),this.na.Gs=a,this.i("mouseDragEnter",b,a))});D.defineProperty(P,{OJ:"mouseDragLeave"},function(){return null!==this.na?this.na.Hs:null},function(a){Co(this);var b=this.na.Hs;b!==a&&(null!==a&&D.h(a,"function",P,"mouseDragLeave"),this.na.Hs=a,this.i("mouseDragLeave",b,a))});
D.defineProperty(P,{pB:"mouseDrop"},function(){return null!==this.na?this.na.ij:null},function(a){Co(this);var b=this.na.ij;b!==a&&(null!==a&&D.h(a,"function",P,"mouseDrop"),this.na.ij=a,this.i("mouseDrop",b,a))});D.defineProperty(P,{HE:"actionDown"},function(){return null!==this.na?this.na.Xq:null},function(a){Co(this);var b=this.na.Xq;b!==a&&(null!==a&&D.h(a,"function",P,"actionDown"),this.na.Xq=a,this.i("actionDown",b,a))});
D.defineProperty(P,{IE:"actionMove"},function(){return null!==this.na?this.na.Yq:null},function(a){Co(this);var b=this.na.Yq;b!==a&&(null!==a&&D.h(a,"function",P,"actionMove"),this.na.Yq=a,this.i("actionMove",b,a))});D.defineProperty(P,{JE:"actionUp"},function(){return null!==this.na?this.na.Zq:null},function(a){Co(this);var b=this.na.Zq;b!==a&&(null!==a&&D.h(a,"function",P,"actionUp"),this.na.Zq=a,this.i("actionUp",b,a))});
D.defineProperty(P,{GE:"actionCancel"},function(){return null!==this.na?this.na.Wq:null},function(a){Co(this);var b=this.na.Wq;b!==a&&(null!==a&&D.h(a,"function",P,"actionCancel"),this.na.Wq=a,this.i("actionCancel",b,a))});D.defineProperty(P,{ZB:"toolTip"},function(){return null!==this.na?this.na.tj:null},function(a){Co(this);var b=this.na.tj;b!==a&&(!v||null===a||a instanceof da||a instanceof gk||D.k("GraphObject.toolTip must be an Adornment or HTMLInfo."),this.na.tj=a,this.i("toolTip",b,a))});
D.defineProperty(P,{contextMenu:"contextMenu"},function(){return null!==this.na?this.na.Ui:null},function(a){Co(this);var b=this.na.Ui;b!==a&&(!v||a instanceof da||a instanceof gk||D.k("GraphObject.contextMenu must be an Adornment or HTMLInfo."),this.na.Ui=a,this.i("contextMenu",b,a))});P.prototype.bind=P.prototype.bind=function(a){a.Tg=this;var b=this.rm();null!==b&&hp(b)&&D.k("Cannot add a Binding to a template that has already been copied: "+a);null===this.Dc&&(this.Dc=new K(ph));this.Dc.add(a)};
P.prototype.findTemplateBinder=P.prototype.rm=function(){for(var a=this instanceof x?this:this.R;null!==a;){if(null!==a.vl)return a;a=a.R}return null};P.prototype.setProperties=function(a){D.dv(this,a)};var ip;
P.make=ip=function(a,b){var c=arguments,d=null,e=null;if("function"===typeof a)e=a;else if("string"===typeof a){var g=jp.oa(a);"function"===typeof g?(c=D.jm(arguments),d=g(c),D.Qa(d)||D.k('GraphObject.make invoked object builder "'+a+'", but it did not return an Object')):e=ba[a]}null===d&&(void 0!==e&&null!==e&&e.constructor||D.k("GraphObject.make requires a class function or GoJS class name or name of an object builder, not: "+a),d=new e);g=1;if(d instanceof E&&1<c.length){var h=d,e=c[1];if("string"===
typeof e||e instanceof HTMLDivElement)Ml(h,e),g++}for(;g<c.length;g++)e=c[g],void 0===e?D.k("Undefined value at argument "+g+" for object being constructed by GraphObject.make: "+d):kp(d,e);return d};
function kp(a,b){if("string"===typeof b)if(a instanceof pa)a.text=b;else if(a instanceof z)a.Jb=b;else if(a instanceof Gl)a.source=b;else if(a instanceof x){var c=Ha(x,b);null!==c?a.type=c:D.k("Unknown Panel type as an argument to GraphObject.make: "+b)}else a instanceof Ba?(c=Ha(Ba,b),null!==c?a.type=c:D.k("Unknown Brush type as an argument to GraphObject.make: "+b)):a instanceof Ve?(c=Ha(Ve,b),null!==c?a.type=c:D.k("Unknown Geometry type as an argument to GraphObject.make: "+b)):a instanceof $f?
(c=Ha($f,b),null!==c?a.type=c:D.k("Unknown PathSegment type as an argument to GraphObject.make: "+b)):D.k("Unable to use a string as an argument to GraphObject.make: "+b);else if(b instanceof P)c=b,a instanceof x||D.k("A GraphObject can only be added to a Panel, not to: "+a),a.add(c);else if(b instanceof jh){var d=b,c=a,e;d.Ke&&c.getRowDefinition?e=c.getRowDefinition(d.index):!d.Ke&&c.getColumnDefinition?e=c.getColumnDefinition(d.index):D.k("A RowColumnDefinition can only be added to a Panel, not to: "+
a);e.dq(d)}else if(b instanceof ya)"function"===typeof a.qc?a.qc(b):D.Yj(a,b);else if(b instanceof ph)a instanceof P?a.bind(b):a instanceof jh?a.bind(b):D.k("A Binding can only be applied to a GraphObject or RowColumnDefinition, not to: "+a);else if(b instanceof Xe)a instanceof Ve?a.nc.add(b):D.k("A PathFigure can only be added to a Geometry, not to: "+a);else if(b instanceof $f)a instanceof Xe?a.Eb.add(b):D.k("A PathSegment can only be added to a PathFigure, not to: "+a);else if(b instanceof $g)a instanceof
E?a.Zb=b:a instanceof I?a.Zb=b:D.k("A Layout can only be assigned to a Diagram or a Group, not to: "+a);else if(Array.isArray(b))for(c=0;c<b.length;c++)kp(a,b[c]);else if("object"===typeof b&&null!==b)if(a instanceof Ba){e=new ma;for(c in b)d=parseFloat(c),isNaN(d)?e[c]=b[c]:a.addColorStop(d,b[c]);D.dv(a,e)}else if(a instanceof jh){void 0!==b.row?(e=b.row,(void 0===e||null===e||Infinity===e||isNaN(e)||0>e)&&D.k("Must specify non-negative integer row for RowColumnDefinition "+b+", not: "+e),a.Ke=!0,
a.index=e):void 0!==b.column&&(e=b.column,(void 0===e||null===e||Infinity===e||isNaN(e)||0>e)&&D.k("Must specify non-negative integer column for RowColumnDefinition "+b+", not: "+e),a.Ke=!1,a.index=e);e=new ma;for(c in b)"row"!==c&&"column"!==c&&(e[c]=b[c]);D.dv(a,e)}else D.dv(a,b);else D.k('Unknown initializer "'+b+'" for object being constructed by GraphObject.make: '+a)}var jp=new oa("string","function");
P.getBuilders=function(){var a=new oa("string","function"),b;for(b in jp)if(b!==b.toLowerCase()){var c=jp.oa(b);"function"===typeof c&&a.add(b,c)}a.freeze();return a};var lp;P.defineBuilder=lp=function(a,b){D.h(a,"string",P,"defineBuilder:name");D.h(b,"function",P,"defineBuilder:func");var c=a.toLowerCase();""!==a&&"none"!==c&&a!==c||D.k("Shape.defineFigureGenerator name must not be empty or None or all-lower-case: "+a);jp.add(a,b)};var mp;
P.takeBuilderArgument=mp=function(a,b,c){void 0===c&&(c=null);var d=a[1];if("function"===typeof c?c(d):"string"===typeof d)return a.splice(1,1),d;if(void 0===b)throw Error("no "+("function"===typeof c?"satisfactory":"string")+" argument for GraphObject builder "+a[0]);return b};
lp("Button",function(){var a=new Ba(Gg);a.addColorStop(0,"white");a.addColorStop(1,"lightgray");var b=new Ba(Gg);b.addColorStop(0,"white");b.addColorStop(1,"dodgerblue");a=ip(x,Ol,{yu:!0,rF:function(a,b){var e=a.Hd("ButtonBorder");null!==e&&(e.fill=b?a._buttonFillNormal:a._buttonFillDisabled)},_buttonFillNormal:a,_buttonStrokeNormal:"gray",_buttonFillOver:b,_buttonStrokeOver:"blue",_buttonFillDisabled:"darkgray"},ip(z,{name:"ButtonBorder",Jb:"Rectangle",C:new S(0,0,2.761423749153968,2.761423749153968),
D:new S(1,1,-2.761423749153968,-2.761423749153968),fill:a,stroke:"gray"}));a.Ou=function(a,b){if(b.Au()){var e=b.Hd("ButtonBorder");if(e instanceof z){var g=b._buttonFillOver;b._buttonFillNormal=e.fill;e.fill=g;g=b._buttonStrokeOver;b._buttonStrokeNormal=e.stroke;e.stroke=g}}};a.Pu=function(a,b){if(b.Au()){var e=b.Hd("ButtonBorder");e instanceof z&&(e.fill=b._buttonFillNormal,e.stroke=b._buttonStrokeNormal)}};return a});
lp("TreeExpanderButton",function(){var a=ip("Button",{_treeExpandedFigure:"MinusLine",_treeCollapsedFigure:"PlusLine"},ip(z,{name:"ButtonIcon",Jb:"MinusLine",Ea:Zd},(new ph("figure","isTreeExpanded",function(a,c){var d=c.R;return a?d._treeExpandedFigure:d._treeCollapsedFigure})).py()),{visible:!1},(new ph("visible","isTreeLeaf",function(a){return!a})).py());a.click=function(a,c){var d=c.Y;d instanceof da&&(d=d.Hf);if(d instanceof G){var e=d.g;if(null!==e){e=e.yb;if(d.Bc){if(!e.canCollapseTree(d))return}else if(!e.canExpandTree(d))return;
a.Mc=!0;d.Bc?e.collapseTree(d):e.expandTree(d)}}};return a});
lp("SubGraphExpanderButton",function(){var a=ip("Button",{_subGraphExpandedFigure:"MinusLine",_subGraphCollapsedFigure:"PlusLine"},ip(z,{name:"ButtonIcon",Jb:"MinusLine",Ea:Zd},(new ph("figure","isSubGraphExpanded",function(a,c){var d=c.R;return a?d._subGraphExpandedFigure:d._subGraphCollapsedFigure})).py()));a.click=function(a,c){var d=c.Y;d instanceof da&&(d=d.Hf);if(d instanceof I){var e=d.g;if(null!==e){e=e.yb;if(d.ld){if(!e.canCollapseSubGraph(d))return}else if(!e.canExpandSubGraph(d))return;a.Mc=
!0;d.ld?e.collapseSubGraph(d):e.expandSubGraph(d)}}};return a});lp("ContextMenuButton",function(){var a=ip("Button");a.stretch=yo;var b=a.Hd("ButtonBorder");b instanceof z&&(b.Jb="Rectangle",b.C=new S(0,0,2,3),b.D=new S(1,1,-2,-2));return a});
lp("PanelExpanderButton",function(a){var b=mp(a,"COLLAPSIBLE"),c=ip("Button",{_buttonExpandedFigure:"TriangleUp",_buttonCollapsedFigure:"TriangleDown"},ip(z,"TriangleUp",{name:"ButtonIcon",Ea:new Ca(6,4)},(new ph("figure","visible",function(a){return a?c._buttonExpandedFigure:c._buttonCollapsedFigure})).py(b)));a=c.Hd("ButtonBorder");a instanceof z&&(a.stroke=null,a.fill="transparent");c.click=function(a,c){var g=c.g;if(null!==g&&!g.rb){var h=c.rm();null===h&&(h=c.Y);null!==h&&(h=h.Hd(b),null!==h&&
(g.Nb("Collapse/Expand Panel"),h.visible=!h.visible,g.hd("Collapse/Expand Panel")))}};return c});
lp("CheckBoxButton",function(a){var b=mp(a);a=ip("Button",{"ButtonBorder.fill":"white","ButtonBorder.stroke":"gray",width:14,height:14},ip(z,{name:"ButtonIcon",MI:"M0 4 L3 9 9 0",mb:2,stretch:rf,TA:ak,visible:!1},""!==b?(new ph("visible",b)).CJ():[]));a.click=function(a,d){var e=a.g;if(!(null===e||e.rb||""!==b&&e.da.rb)){a.Mc=!0;var g=d.Hd("ButtonIcon");e.Nb("checkbox");g.visible=!g.visible;"function"===typeof d._doClick&&d._doClick(a,d);e.hd("checkbox")}};return a});
lp("CheckBox",function(a){a=mp(a);a=ip("CheckBoxButton",a,{name:"Button",margin:new Lb(0,1,0,0)});var b=ip(x,"Horizontal",a,{yu:!0,margin:1,_buttonFillNormal:a._buttonFillNormal,_buttonStrokeNormal:a._buttonStrokeNormal,_buttonFillOver:a._buttonFillOver,_buttonStrokeOver:a._buttonStrokeOver,_buttonFillDisabled:a._buttonFillDisabled,Ou:a.Ou,Pu:a.Pu,click:a.click,_buttonClick:a.click});a.Ou=null;a.Pu=null;a.click=null;return b});
function Do(){this.Pr=this.Ui=this.tj=this.Wq=this.Zq=this.Yq=this.Xq=this.ij=this.Hs=this.Gs=this.jj=this.kj=this.lj=this.Js=this.Is=this.Ti=this.Yi=this.Ri=null}Do.prototype.copy=function(){var a=new Do;a.Ri=this.Ri;a.Yi=this.Yi;a.Ti=this.Ti;a.Is=this.Is;a.Js=this.Js;a.lj=this.lj;a.kj=this.kj;a.jj=this.jj;a.Gs=this.Gs;a.Hs=this.Hs;a.ij=this.ij;a.Xq=this.Xq;a.Yq=this.Yq;a.Zq=this.Zq;a.Wq=this.Wq;a.tj=this.tj;a.Ui=this.Ui;a.Pr=this.Pr;return a};
function x(a){P.call(this);void 0===a?this.ca=dk:(D.Da(a,x,x,"type"),this.ca=a);this.xa=new K(P);this.sf=Sd;this.ca===Rl&&(this.Zn=!0);this.Br=Vc;this.Xi=wo;this.ca===fa&&np(this);this.Up=ak;this.$r=ae;this.as=Kd;this.Xr=0;this.Wr=100;this.Zr=10;this.Yr=0;this.vl=this.Pd=this.nk=this.Tm=this.Vm=null;this.os=NaN;this.Jg=this.cj=null;this.op="category";this.Ig=null;this.vj=new C(NaN,NaN,NaN,NaN);this.Ek=this.mt=this.Wl=null;this.ak=""}D.Sa(x,P);D.Di(x);D.ka("Panel",x);
function np(a){a.ek=Sd;a.ei=1;a.Wi=null;a.Rm=null;a.di=1;a.ci=null;a.Qm=null;a.ke=[];a.he=[];a.qn=op;a.Pm=op;a.uj=0;a.fj=0}
x.prototype.cloneProtected=function(a){P.prototype.cloneProtected.call(this,a);a.ca=this.ca;a.sf=this.sf.V();a.Br=this.Br.V();a.Xi=this.Xi;if(a.ca===fa){a.ek=this.ek.V();a.ei=this.ei;a.Wi=this.Wi;a.Rm=this.Rm;a.di=this.di;a.ci=this.ci;a.Qm=this.Qm;var b=[];if(0<this.ke.length)for(var c=this.ke,d=c.length,e=0;e<d;e++)if(void 0!==c[e]){var g=c[e].copy();g.Cm(a);b[e]=g}a.ke=b;b=[];if(0<this.he.length)for(c=this.he,d=c.length,e=0;e<d;e++)void 0!==c[e]&&(g=c[e].copy(),g.Cm(a),b[e]=g);a.he=b;a.qn=this.qn;
a.Pm=this.Pm;a.uj=this.uj;a.fj=this.fj}a.Up=this.Up;a.$r=this.$r.V();a.as=this.as.V();a.Xr=this.Xr;a.Wr=this.Wr;a.Zr=this.Zr;a.Yr=this.Yr;a.Vm=this.Vm;a.nk=this.nk;a.Pd=this.Pd;a.vl=this.vl;a.os=this.os;a.cj=this.cj;a.Jg=this.Jg;a.op=this.op;a.vj.assign(this.vj);a.ak=this.ak;null!==this.mt&&(a.mt=this.mt)};x.prototype.Ei=function(a){P.prototype.Ei.call(this,a);a.xa=this.xa;for(var b=a.xa.o,c=b.length,d=0;d<c;d++)b[d].Dh=a;a.Wl=null};
x.prototype.copy=function(){var a=P.prototype.copy.call(this);if(null!==a){for(var b=this.xa.o,c=b.length,d=0;d<c;d++){var e=b[d].copy(),g=a;e.Cm(g);e.nn=null;var h=g.xa,k=h.count;h.ae(k,e);h=g.Y;if(null!==h){h.Rl=null;null!==e.wd&&h instanceof G&&(h.Xk=!0);var l=g.g;null!==l&&l.la.jb||h.nd(rg,"elements",g,null,e,null,k)}}return a}return null};x.prototype.qc=function(a){a.Oe===x?this.type=a:P.prototype.qc.call(this,a)};x.prototype.toString=function(){return"Panel("+this.type+")#"+D.Jd(this)};var dk;
x.Position=dk=D.s(x,"Position",0);x.Horizontal=D.s(x,"Horizontal",1);var Nl;x.Vertical=Nl=D.s(x,"Vertical",2);var Wj;x.Spot=Wj=D.s(x,"Spot",3);var Ol;x.Auto=Ol=D.s(x,"Auto",4);var fa;x.Table=fa=D.s(x,"Table",5);x.Viewbox=D.s(x,"Viewbox",6);var Ro;x.TableRow=Ro=D.s(x,"TableRow",7);var So;x.TableColumn=So=D.s(x,"TableColumn",8);var rj;x.Link=rj=D.s(x,"Link",9);var Rl;x.Grid=Rl=D.s(x,"Grid",10);var pp;x.Graduated=pp=D.s(x,"Graduated",11);
D.defineProperty(x,{type:"type"},function(){return this.ca},function(a){var b=this.ca;b!==a&&(v&&D.Da(a,x,x,"type"),b!==Ro&&b!==So||D.k("Cannot change Panel.type when it is already a TableRow or a TableColumn: "+a),this.ca=a,this.ca===Rl?this.Zn=!0:this.ca===fa&&np(this),this.K(),this.i("type",b,a))});D.w(x,{elements:"elements"},function(){return this.xa.j});D.w(x,{Ga:"naturalBounds"},function(){return this.ed});
D.defineProperty(x,{padding:"padding"},function(){return this.sf},function(a){"number"===typeof a?(0>a&&D.ua(a,">= 0",x,"padding"),a=new Lb(a)):(D.l(a,Lb,x,"padding"),0>a.left&&D.ua(a.left,">= 0",x,"padding:value.left"),0>a.right&&D.ua(a.right,">= 0",x,"padding:value.right"),0>a.top&&D.ua(a.top,">= 0",x,"padding:value.top"),0>a.bottom&&D.ua(a.bottom,">= 0",x,"padding:value.bottom"));var b=this.sf;b.O(a)||(this.sf=a=a.V(),this.K(),this.i("padding",b,a))});
D.defineProperty(x,{nm:"defaultAlignment"},function(){return this.Br},function(a){var b=this.Br;b.O(a)||(v&&D.l(a,S,x,"defaultAlignment"),this.Br=a=a.V(),this.K(),this.i("defaultAlignment",b,a))});D.defineProperty(x,{me:"defaultStretch"},function(){return this.Xi},function(a){var b=this.Xi;b!==a&&(D.Da(a,P,x,"defaultStretch"),this.Xi=a,this.K(),this.i("defaultStretch",b,a))});
D.defineProperty(x,{zL:"defaultSeparatorPadding"},function(){return void 0===this.ek?Sd:this.ek},function(a){if(void 0!==this.ek){"number"===typeof a?a=new Lb(a):v&&D.l(a,Lb,x,"defaultSeparatorPadding");var b=this.ek;b.O(a)||(this.ek=a=a.V(),this.K(),this.i("defaultSeparatorPadding",b,a))}});
D.defineProperty(x,{xL:"defaultRowSeparatorStroke"},function(){return void 0===this.Wi?null:this.Wi},function(a){var b=this.Wi;b!==a&&(null===a||"string"===typeof a||a instanceof Ba)&&(a instanceof Ba&&a.freeze(),this.Wi=a,this.ra(),this.i("defaultRowSeparatorStroke",b,a))});
D.defineProperty(x,{yL:"defaultRowSeparatorStrokeWidth"},function(){return void 0===this.ei?1:this.ei},function(a){if(void 0!==this.ei){var b=this.ei;b!==a&&isFinite(a)&&0<=a&&(this.ei=a,this.K(),this.i("defaultRowSeparatorStrokeWidth",b,a))}});
D.defineProperty(x,{wL:"defaultRowSeparatorDashArray"},function(){return void 0===this.Rm?null:this.Rm},function(a){if(void 0!==this.Rm){var b=this.Rm;if(b!==a){null===a||Array.isArray(a)||D.jc(a,"Array",x,"defaultRowSeparatorDashArray:value");if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var g=a[e];"number"===typeof g&&0<=g&&isFinite(g)||D.k("defaultRowSeparatorDashArray value "+g+" at index "+e+" must be a positive number or zero.");d+=g}if(0===d){if(null===b)return;a=null}}this.Rm=a;this.ra();
this.i("defaultRowSeparatorDashArray",b,a)}}});D.defineProperty(x,{rL:"defaultColumnSeparatorStroke"},function(){return void 0===this.ci?null:this.ci},function(a){if(void 0!==this.ci){var b=this.ci;b!==a&&(null===a||"string"===typeof a||a instanceof Ba)&&(a instanceof Ba&&a.freeze(),this.ci=a,this.ra(),this.i("defaultColumnSeparatorStroke",b,a))}});
D.defineProperty(x,{sL:"defaultColumnSeparatorStrokeWidth"},function(){return void 0===this.di?1:this.di},function(a){if(void 0!==this.di){var b=this.di;b!==a&&isFinite(a)&&0<=a&&(this.di=a,this.K(),this.i("defaultColumnSeparatorStrokeWidth",b,a))}});
D.defineProperty(x,{qL:"defaultColumnSeparatorDashArray"},function(){return void 0===this.Qm?null:this.Qm},function(a){if(void 0!==this.Qm){var b=this.Qm;if(b!==a){null===a||Array.isArray(a)||D.jc(a,"Array",x,"defaultColumnSeparatorDashArray:value");if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var g=a[e];"number"===typeof g&&0<=g&&isFinite(g)||D.k("defaultColumnSeparatorDashArray value "+g+" at index "+e+" must be a positive number or zero.");d+=g}if(0===d){if(null===b)return;a=null}}this.Qm=
a;this.ra();this.i("defaultColumnSeparatorDashArray",b,a)}}});D.defineProperty(x,{NM:"viewboxStretch"},function(){return this.Up},function(a){var b=this.Up;b!==a&&(D.Da(a,P,x,"viewboxStretch"),this.Up=a,this.K(),this.i("viewboxStretch",b,a))});
D.defineProperty(x,{Sx:"gridCellSize"},function(){return this.$r},function(a){var b=this.$r;if(!b.O(a)){D.l(a,Ca,x,"gridCellSize");a.F()&&0!==a.width&&0!==a.height||D.k("Invalid Panel.gridCellSize: "+a);this.$r=a.V();var c=this.g;null!==c&&this===c.Wn&&km(c);this.ra();this.i("gridCellSize",b,a)}});
D.defineProperty(x,{LF:"gridOrigin"},function(){return this.as},function(a){var b=this.as;if(!b.O(a)){D.l(a,N,x,"gridOrigin");a.F()||D.k("Invalid Panel.gridOrigin: "+a);this.as=a.V();var c=this.g;null!==c&&this===c.Wn&&km(c);this.ra();this.i("gridOrigin",b,a)}});D.defineProperty(x,{Wk:"graduatedMin"},function(){return this.Xr},function(a){D.p(a,x,"graduatedMin");var b=this.Xr;b!==a&&(this.Xr=a,this.K(),this.i("graduatedMin",b,a),Ho(this)&&(a=this.Y,null!==a&&Io(this,a,"graduatedRange")))});
D.defineProperty(x,{WA:"graduatedMax"},function(){return this.Wr},function(a){D.p(a,x,"graduatedMax");var b=this.Wr;b!==a&&(this.Wr=a,this.K(),this.i("graduatedMax",b,a),Ho(this)&&(a=this.Y,null!==a&&Io(this,a,"graduatedRange")))});D.w(x,{uu:"graduatedRange"},function(){return this.WA-this.Wk});D.defineProperty(x,{XA:"graduatedTickUnit"},function(){return this.Zr},function(a){D.p(a,x,"graduatedTickUnit");var b=this.Zr;b!==a&&0<a&&(this.Zr=a,this.K(),this.i("graduatedTickUnit",b,a))});
D.defineProperty(x,{KF:"graduatedTickBase"},function(){return this.Yr},function(a){D.p(a,x,"graduatedTickBase");var b=this.Yr;b!==a&&(this.Yr=a,this.K(),this.i("graduatedTickBase",b,a))});f=x.prototype;f.Qs=function(a){P.prototype.Qs.call(this,a);for(var b=this.xa.o,c=b.length,d=0;d<c;d++)b[d].Qs(a)};
f.Rk=function(a,b){if(this.ca===Rl){var c=this.Fj()*b.scale;0>=c&&(c=1);var d=this.Sx,e=d.width,d=d.height,g=this.Ga,h=g.width,g=g.height,k=Math.ceil(h/e),l=Math.ceil(g/d),m=this.LF;a.save();a.beginPath();a.rect(0,0,h,g);a.clip();for(var n=[],p=this.xa.o,q=p.length,r=0;r<q;r++){var s=p[r],u=[];n.push(u);if(s.visible)for(var s=gn(s.Jb),t=r+1;t<q;t++){var y=p[t];y.visible&&gn(y.Jb)===s&&(y=y.interval,2<=y&&u.push(y))}}p=this.xa.o;q=p.length;for(r=0;r<q;r++){var w=p[r];if(w.visible&&(u=w.interval,!(2>
e*u*c))){s=w.opacity;t=1;if(1!==s){if(0===s)continue;t=a.globalAlpha;a.globalAlpha=t*s}var y=n[r],B=!1,A=!0,O=w.dH;null!==O&&(B=!0,A=a.NA(O,w.Gf));if("LineV"===w.Jb&&null!==w.stroke){a.lineWidth=w.mb;Vo(this,a,w.stroke,!1,!1);a.beginPath();for(var H=Math.floor(-m.x/e),R=H;R<=H+k;R++){var aa=R*e+m.x;0<=aa&&aa<=h&&qp(R,u,y)&&(B&&!A?Go(a,aa,0,aa,g,O,w.Gf):(a.moveTo(aa,0),a.lineTo(aa,g)))}a.stroke()}else if("LineH"===w.Jb&&null!==w.stroke){a.lineWidth=w.mb;Vo(this,a,w.stroke,!1,!1);a.beginPath();for(R=
H=Math.floor(-m.y/d);R<=H+l;R++)aa=R*d+m.y,0<=aa&&aa<=g&&qp(R,u,y)&&(B&&!A?Go(a,0,aa,h,aa,O,w.Gf):(a.moveTo(0,aa),a.lineTo(h,aa)));a.stroke()}else if("BarV"===w.Jb&&null!==w.fill)for(Vo(this,a,w.fill,!0,!1),w=w.width,isNaN(w)&&(w=e),R=H=Math.floor(-m.x/e);R<=H+k;R++)aa=R*e+m.x,0<=aa&&aa<=h&&qp(R,u,y)&&a.fillRect(aa,0,w,g);else if("BarH"===w.Jb&&null!==w.fill)for(Vo(this,a,w.fill,!0,!1),w=w.height,isNaN(w)&&(w=d),R=H=Math.floor(-m.y/d);R<=H+l;R++)aa=R*d+m.y,0<=aa&&aa<=g&&qp(R,u,y)&&a.fillRect(0,aa,
h,w);B&&a.IA();1!==s&&(a.globalAlpha=t)}}a.restore();a.De(!1)}else if(this.ca===pp){e=this.Ga;c=e.width;e=e.height;a.save();a.beginPath();a.rect(-1,-1,c+1,e+1);a.clip();c=this.Xd();c.Ge(a,b);e=this.Fj()*b.scale;0>=e&&(e=1);d=c.Z;h=this.xa.o;g=this.Vm;k=h.length;for(l=0;l<k;l++)if(q=h[l],m=g[l],n=m.length,q.visible&&q!==c&&0!==m.length)if(q instanceof z){if(!(2>this.XA*q.interval*c.uf.nu/this.uu*e))for(r=q.Fa,u=q.mb*q.scale,s=q.Ih,s.ce()&&(s=jc),p=0;p<n;p++)t=q,y=m[p][0],B=d,H=m[p][1],R=r,w=u,A=s,
O=t.Jc,O.reset(),O.translate(y.x+B.x,y.y+B.y),O.rotate(H+t.angle,0,0),O.translate(-R.width*A.x+A.offsetX+w/2,-R.height*A.y+A.offsetY+w/2),O.scale(t.scale,t.scale),Xo(t,!1),t.Ni.set(t.Jc),t.Xo=t.scale,Yo(t,!1),q.Ge(a,b),q.Jc.reset()}else if(q instanceof pa)for(null===this.Ek&&(this.Ek=new pa),r=this.Ek,rp(q,r),p=0;p<n;p++)y=m[p],3<y.length&&(s=y[6],r.Vd=y[2],r.wg=y[3],r.we=y[4],r.rf=y[5],r.ed=y[8],r.rc(s.x,s.y,s.width,s.height),q=r,u=d,t=y[7],y=y[8],B=q.Jc,B.reset(),B.translate(s.x+u.x,s.y+u.y),B.translate(-t.x,
-t.y),Ko(q,B,y.x,y.y,y.width,y.height),Xo(q,!1),q.Ni.set(q.Jc),q.Xo=q.scale,Yo(q,!1),r.Ge(a,b));a.restore();a.De(!0)}else{this.ca===fa&&(a.lineCap="butt",sp(this,a,!0,this.ke,!0),sp(this,a,!1,this.he,!0),Qp(this,a,!0,this.ke),Qp(this,a,!1,this.he),sp(this,a,!0,this.ke,!1),sp(this,a,!1,this.he,!1));if(c=this.vq)a.save(),v&&this.type!==Wj&&D.trace("Warning: Panel.isClipping set on non-Spot Panel: "+this.toString());e=this.Xd();d=this.xa.o;h=d.length;for(g=0;g<h;g++)k=d[g],c&&k===e&&(a.bq=!0),k.Ge(a,
b),c&&k===e&&(a.bq=!1);c&&(a.restore(),a.De(!0));v&&v.EI&&this instanceof J&&v.DI(a,b,this)}};
function rp(a,b){b.T=a.T|6144;b.Hc=a.Hc;b.Lb=a.Lb;b.kc=a.kc;b.af=a.af.V();b.ni=a.ni.V();b.mi=a.mi.V();b.gj=a.gj.copy();b.Db=a.Db;b.wg=a.wg;b.Rg=a.Rg;b.fn=a.fn.V();b.te=a.te.V();b.Lm=a.Lm.V();b.rn=a.rn;b.sn=a.sn.V();b.tn=a.tn;null!==a.zg&&(b.zg=a.zg.copy());b.xn=a.xn;a instanceof pa&&(b.Vd=a.Vd,b.zc=a.zc,b.Cg=a.Cg,b.Xl=a.Xl,b.Bg=a.Bg,b.am=a.am,b.Ym=a.Ym,b.sk=a.sk,b.rk=a.rk,b.nj=a.nj,b.Kk=a.Kk,b.rf.ik=null,b.vi=a.vi,b.wi=a.wi,b.xh=a.xh,b.rp=a.rp,b.Gg=a.Gg,b.Fg=a.Fg,b.Eg=a.Eg,b.Um=a.Um)}
function Qp(a,b,c,d){for(var e=d.length,g=a.Z,h=c?a.Zd(0):a.Yd(0),k=0;k<e;k++){var l=d[k];if(void 0!==l&&l!==h&&0!==l.gb){if(c){if(l.position>g.height)continue}else if(l.position>g.width)continue;var m=l.Nq;isNaN(m)&&(m=c?a.ei:a.di);var n=l.Mq;null===n&&(n=c?a.Wi:a.ci);0!==m&&null!==n&&(Vo(a,b,n,!1,!1),b.beginPath(),n=l.position+m,c?n>g.height&&(m-=n-g.height):n>g.width&&(m-=n-g.width),l=l.position+m/2,b.lineWidth=m,n=a.padding,c?(l+=n.top,m=n.left,n=g.width-n.right,b.moveTo(m,l),b.lineTo(n,l)):(l+=
n.left,m=n.top,n=g.height-n.bottom,b.moveTo(l,m),b.lineTo(l,n)),b.stroke())}}}
function sp(a,b,c,d,e){for(var g=d.length,h=a.Z,k=0;k<g;k++){var l=d[k];if(void 0!==l&&null!==l.background&&l.GA!==e&&0!==l.gb){var m=c?h.height:h.width;if(!(l.position>m)){var n=l.kf(),p=l.Nq;isNaN(p)&&(p=c?a.ei:a.di);var q=l.Mq;null===q&&(q=c?a.Wi:a.ci);null===q&&(p=0);n-=p;p=l.position+p;n+=l.gb;p+n>m&&(n=m-p);0>=n||(m=a.padding,Vo(a,b,l.background,!0,!1),c?b.fillRect(m.left,p+m.top,h.width-(m.left+m.right),n):b.fillRect(p+m.left,m.top,n,h.height-(m.top+m.bottom)))}}}}
function qp(a,b,c){if(0!==a%b)return!1;b=c.length;for(var d=0;d<b;d++)if(0===a%c[d])return!1;return!0}function gn(a){return"LineV"===a||"BarV"===a}
f.Un=function(a,b,c,d,e){var g=this.nh(),h=this.transform,k=1/(h.m11*h.m22-h.m12*h.m21),l=h.m22*k,m=-h.m12*k,n=-h.m21*k,p=h.m11*k,q=k*(h.m21*h.dy-h.m22*h.dx),r=k*(h.m12*h.dx-h.m11*h.dy);if(null!==this.fm)return h=this.Z,Je(h.left,h.top,h.right,h.bottom,a,b,c,d,e);if(null!==this.background)g=a*l+b*n+q,k=a*m+b*p+r,a=c*l+d*n+q,l=c*m+d*p+r,e.n(0,0),c=this.Ga,c=Je(0,0,c.width,c.height,g,k,a,l,e),e.transform(h);else{g||(l=1,n=m=0,p=1,r=q=0);k=a*l+b*n+q;a=a*m+b*p+r;l=c*l+d*n+q;d=c*m+d*p+r;e.n(l,d);m=(l-
k)*(l-k)+(d-a)*(d-a);c=!1;p=this.xa.o;r=p.length;n=D.P();q=null;b=Infinity;var s=null;this.vq&&(s=D.P(),q=this.Xd(),(c=q.Un(k,a,l,d,s))&&(b=(k-s.x)*(k-s.x)+(a-s.y)*(a-s.y)));for(var u=0;u<r;u++){var t=p[u];t.visible&&t!==q&&t.Un(k,a,l,d,n)&&(c=!0,t=(k-n.x)*(k-n.x)+(a-n.y)*(a-n.y),t<m&&(m=t,e.set(n)))}this.vq&&(b>m&&e.set(s),D.A(s));D.A(n);g&&e.transform(h)}return c};
f.K=function(a){P.prototype.K.call(this,a);a=null;if(this.ca===Ol||this.ca===rj)a=this.Xd();for(var b=this.xa.o,c=b.length,d=0;d<c;d++){var e=b[d];(e===a||e.Ue)&&e.K(!0);if(!e.Ea.F()){var g=Mo(e,!1);(e instanceof Xj||e instanceof x||e instanceof pa||g!==Yj)&&e.K(!0)}}};f.uq=function(){if(!1===Mm(this)){jm(this,!0);No(this,!0);for(var a=this.xa.o,b=a.length,c=0;c<b;c++)a[c].uq()}};f.aj=function(){if(0!==(this.T&2048)===!1){Xo(this,!0);Yo(this,!0);for(var a=this.xa.o,b=a.length,c=0;c<b;c++)a[c].cB()}};
f.cB=function(){Yo(this,!0);for(var a=this.xa.o,b=a.length,c=0;c<b;c++)a[c].cB()};
f.io=function(a,b,c,d){var e=this.vj;e.width=0;e.height=0;var g=this.Ea,h=this.bh;void 0===c&&(c=h.width,d=h.height);c=Math.max(c,h.width);d=Math.max(d,h.height);var k=this.of;isNaN(g.width)||(a=Math.min(g.width,k.width));isNaN(g.height)||(b=Math.min(g.height,k.height));a=Math.max(c,a);b=Math.max(d,b);var l=this.padding;a=Math.max(a-l.left-l.right,0);b=Math.max(b-l.top-l.bottom,0);var m=this.xa.o;if(0!==m.length){var n=this.ca.ac;switch(n){case "Position":var p=a,q=b,r=c,s=d,u=m.length;e.x=0;e.y=
0;e.width=0;e.height=0;for(var t=Rp(this),y=0;y<u;y++){var w=m[y];if(w.visible||w===t){var B=w.margin,A=B.right+B.left,O=B.top+B.bottom;Gk(w,p,q,r,s);var H=w.Fa,R=Math.max(H.width+A,0),aa=Math.max(H.height+O,0),V=w.position.x,ea=w.position.y;isFinite(V)||(V=0);isFinite(ea)||(ea=0);if(w instanceof z){var la=w;if(la.XF)var Da=la.mb/2,V=V-Da,ea=ea-Da}Ub(e,V,ea,R,aa)}}break;case "Vertical":for(var La=a,gb=c,za=m.length,W=D.nb(),wb=Rp(this),Pb=0;Pb<za;Pb++){var Sa=m[Pb];if(Sa.visible||Sa===wb){var Qc=
Mo(Sa,!1);if(Qc!==Yj&&Qc!==xo)W.push(Sa);else{var Ra=Sa.margin,hg=Ra.right+Ra.left,ig=Ra.top+Ra.bottom;Gk(Sa,La,Infinity,gb,0);var ud=Sa.Fa,vd=Math.max(ud.width+hg,0),Rc=Math.max(ud.height+ig,0);e.width=Math.max(e.width,vd);e.height+=Rc}}}var uh=W.length;if(0!==uh){this.Ea.width?La=Math.min(this.Ea.width,this.of.width):0!==e.width&&(La=Math.min(e.width,this.of.width));for(Pb=0;Pb<uh;Pb++)if(Sa=W[Pb],Sa.visible||Sa===wb)Ra=Sa.margin,hg=Ra.right+Ra.left,ig=Ra.top+Ra.bottom,Gk(Sa,La,Infinity,gb,0),ud=
Sa.Fa,vd=Math.max(ud.width+hg,0),Rc=Math.max(ud.height+ig,0),e.width=Math.max(e.width,vd),e.height+=Rc;D.ya(W)}break;case "Horizontal":for(var Ye=b,hb=d,ub=m.length,sa=D.nb(),na=Rp(this),ab=0;ab<ub;ab++){var Oa=m[ab];if(Oa.visible||Oa===na){var pb=Mo(Oa,!1);if(pb!==Yj&&pb!==yo)sa.push(Oa);else{var Md=Oa.margin,Gf=Md.right+Md.left,re=Md.top+Md.bottom;Gk(Oa,Infinity,Ye,0,hb);var be=Oa.Fa,jd=Math.max(be.width+Gf,0),vh=Math.max(be.height+re,0);e.width+=jd;e.height=Math.max(e.height,vh)}}}var Hf=sa.length;
if(0!==Hf){this.Ea.height?Ye=Math.min(this.Ea.height,this.of.height):0!==e.height&&(Ye=Math.min(e.height,this.of.height));for(ab=0;ab<Hf;ab++)if(Oa=sa[ab],Oa.visible||Oa===na)Md=Oa.margin,Gf=Md.right+Md.left,re=Md.top+Md.bottom,Gk(Oa,Infinity,Ye,0,hb),be=Oa.Fa,jd=Math.max(be.width+Gf,0),vh=Math.max(be.height+re,0),e.width+=jd,e.height=Math.max(e.height,vh);D.ya(sa)}break;case "Spot":a:{var Hg=a,Hb=b,oc=c,wd=d,wh=m.length,qb=this.Xd(),Pa=qb.margin,Qb=0,If=0,Ig=Pa.right+Pa.left,xh=Pa.top+Pa.bottom;
Gk(qb,Hg,Hb,oc,wd);for(var ib=qb.Fa,zc=ib.width,$b=ib.height,se=Math.max(zc+Ig,0),Ac=Math.max($b+xh,0),Bc=this.vq,vb=D.vg(-Pa.left,-Pa.top,se,Ac),pc=!0,xb=Rp(this),Fc=0;Fc<wh;Fc++){var db=m[Fc];if(db!==qb&&(db.visible||db===xb)){Pa=db.margin;Qb=Pa.right+Pa.left;If=Pa.top+Pa.bottom;Gk(db,Hg,Hb,0,0);var ib=db.Fa,se=Math.max(ib.width+Qb,0),Ac=Math.max(ib.height+If,0),Ta=db.alignment;Ta.kd()&&(Ta=this.nm);Ta.Vc()||(Ta=mc);var Mb=db.Ih;Mb.kd()&&(Mb=mc);var xd=null;db instanceof x&&""!==db.ak&&(db.rc(0,
0,ib.width,ib.height),xd=db.Hd(db.ak),xd===db&&(xd=null));var jg,Ze;if(null!==xd){for(var $e=xd.Ga,uj=xd.margin,Rb=D.Fb(Mb.x*$e.width+Mb.offsetX-uj.left,Mb.y*$e.height+Mb.offsetY-uj.top);xd!==db;)xd.transform.xb(Rb),xd=xd.R;jg=Ta.x*zc+Ta.offsetX-Rb.x;Ze=Ta.y*$b+Ta.offsetY-Rb.y;D.A(Rb)}else jg=Ta.x*zc+Ta.offsetX-(Mb.x*ib.width-Mb.offsetX)-Pa.left,Ze=Ta.y*$b+Ta.offsetY-(Mb.y*ib.height-Mb.offsetY)-Pa.top;pc?(pc=!1,e.x=jg,e.y=Ze,e.width=se,e.height=Ac):Ub(e,jg,Ze,se,Ac)}}pc?e.assign(vb):Bc?e.RF(vb.x,
vb.y,vb.width,vb.height):Ub(e,vb.x,vb.y,vb.width,vb.height);D.Kb(vb);var qc=qb.stretch;qc===wo&&(qc=Mo(qb,!1));switch(qc){case Yj:break a;case rf:if(!isFinite(Hg)&&!isFinite(Hb))break a;break;case yo:if(!isFinite(Hg))break a;break;case xo:if(!isFinite(Hb))break a}ib=qb.Fa;zc=ib.width;$b=ib.height;se=Math.max(zc+Ig,0);Ac=Math.max($b+xh,0);Pa=qb.margin;vb=D.vg(-Pa.left,-Pa.top,se,Ac);for(Fc=0;Fc<wh;Fc++)db=m[Fc],db===qb||!db.visible&&db!==xb||(Pa=db.margin,Qb=Pa.right+Pa.left,If=Pa.top+Pa.bottom,ib=
db.Fa,se=Math.max(ib.width+Qb,0),Ac=Math.max(ib.height+If,0),Ta=db.alignment,Ta.kd()&&(Ta=this.nm),Ta.Vc()||(Ta=mc),Mb=db.Ih,Mb.kd()&&(Mb=mc),pc?(pc=!1,e.x=Ta.x*zc+Ta.offsetX-(Mb.x*ib.width-Mb.offsetX)-Pa.left,e.y=Ta.y*$b+Ta.offsetY-(Mb.y*ib.height-Mb.offsetY)-Pa.top,e.width=se,e.height=Ac):Ub(e,Ta.x*zc+Ta.offsetX-(Mb.x*ib.width-Mb.offsetX)-Pa.left,Ta.y*$b+Ta.offsetY-(Mb.y*ib.height-Mb.offsetY)-Pa.top,se,Ac));pc?e.assign(vb):Bc?e.RF(vb.x,vb.y,vb.width,vb.height):Ub(e,vb.x,vb.y,vb.width,vb.height);
D.Kb(vb)}break;case "Auto":var fc=a,kd=b,mb=c,Sb=d,te=m.length,yb=this.Xd(),ce=yb.margin,yh=fc,zh=kd,Jg=ce.right+ce.left,ld=ce.top+ce.bottom;Gk(yb,fc,kd,mb,Sb);var Nd=yb.Fa,gc=0,zb=null;yb instanceof z&&(zb=yb,gc=zb.mb*zb.scale);var yd=Math.max(Nd.width+Jg,0),nb=Math.max(Nd.height+ld,0),hc=Sp(yb),hi=hc.x*yd+hc.offsetX,ad=hc.y*nb+hc.offsetY,Ib=Tp(yb),ue=Ib.x*yd+Ib.offsetX,af=Ib.y*nb+Ib.offsetY;isFinite(fc)&&(yh=Math.max(Math.abs(hi-ue)-gc,0));isFinite(kd)&&(zh=Math.max(Math.abs(ad-af)-gc,0));var bd=
D.Gm();bd.n(0,0);for(var Jf=Rp(this),Kf=0;Kf<te;Kf++){var Ah=m[Kf];if(Ah!==yb&&(Ah.visible||Ah===Jf)){var ce=Ah.margin,Ik=ce.right+ce.left,Ab=ce.top+ce.bottom;Gk(Ah,yh,zh,0,0);Nd=Ah.Fa;yd=Math.max(Nd.width+Ik,0);nb=Math.max(Nd.height+Ab,0);bd.n(Math.max(yd,bd.width),Math.max(nb,bd.height))}}if(1===te)e.width=yd,e.height=nb,D.Uk(bd);else{var hc=Sp(yb),Ib=Tp(yb),de=0,md=0;Ib.x!==hc.x&&Ib.y!==hc.y&&(de=bd.width/Math.abs(Ib.x-hc.x),md=bd.height/Math.abs(Ib.y-hc.y));D.Uk(bd);gc=0;null!==zb&&(gc=zb.mb*
zb.scale,Zj(zb)===ak&&(de=md=Math.max(de,md)));var de=de+(Math.abs(hc.offsetX)+Math.abs(Ib.offsetX)+gc),md=md+(Math.abs(hc.offsetY)+Math.abs(Ib.offsetY)+gc),Ke=yb.stretch;Ke===wo&&(Ke=Mo(yb,!1));switch(Ke){case Yj:Sb=mb=0;break;case rf:isFinite(fc)&&(de=fc);isFinite(kd)&&(md=kd);break;case yo:isFinite(fc)&&(de=fc);Sb=0;break;case xo:mb=0,isFinite(kd)&&(md=kd)}yb.uq();Gk(yb,de,md,mb,Sb);e.width=yb.Fa.width+Jg;e.height=yb.Fa.height+ld}break;case "Table":for(var ii=a,ji=b,vj=c,wj=d,Jb=m.length,Bh=D.nb(),
Ch=D.nb(),ka=0;ka<Jb;ka++){var ca=m[ka],ic=ca instanceof x?ca:null;if(null===ic||ic.type!==Ro&&ic.type!==So||!ca.visible)Bh.push(ca);else{v&&(ic.Ea.F()&&D.k(ic.toString()+" TableRow/TableColumn Panels cannot set a desiredSize: "+ic.Ea.toString()),ic.bh.O(Xd)||D.k(ic.toString()+" TableRow/TableColumn Panels cannot set a minSize: "+ic.bh.toString()),ic.of.O(ge)||D.k(ic.toString()+" TableRow/TableColumn Panels cannot set a maxSize: "+ic.of.toString()));Ch.push(ic);for(var kg=ic.xa.o,ki=kg.length,Kb=
0;Kb<ki;Kb++){var li=kg[Kb];ic.type===Ro?li.Sb=ca.Sb:ic.type===So&&(li.column=ca.column);Bh.push(li)}}}Jb=Bh.length;0===Jb&&(this.Zd(0),this.Yd(0));for(var Xb=[],ka=0;ka<Jb;ka++)ca=Bh[ka],jm(ca,!0),No(ca,!0),Xb[ca.Sb]||(Xb[ca.Sb]=[]),Xb[ca.Sb][ca.column]||(Xb[ca.Sb][ca.column]=[]),Xb[ca.Sb][ca.column].push(ca);D.ya(Bh);for(var bf=D.nb(),Le=D.nb(),cf=D.nb(),ve={count:0},we={count:0},cd=ii,Od=ji,Dh=this.ke,Jb=Dh.length,ka=0;ka<Jb;ka++){var Z=Dh[ka];void 0!==Z&&(Z.gb=0)}Dh=this.he;Jb=Dh.length;for(ka=
0;ka<Jb;ka++)Z=Dh[ka],void 0!==Z&&(Z.gb=0);for(var nd=Xb.length,lg=0,ka=0;ka<nd;ka++)Xb[ka]&&(lg=Math.max(lg,Xb[ka].length));for(var mi=Math.min(this.uj,nd-1),Eh=Math.min(this.fj,lg-1),rc=0,nd=Xb.length,Fh=Rp(this),ka=0;ka<nd;ka++)if(Xb[ka])for(var lg=Xb[ka].length,rb=this.Zd(ka),Kb=rb.gb=0;Kb<lg;Kb++)if(Xb[ka][Kb]){var sb=this.Yd(Kb);void 0===bf[Kb]&&(sb.gb=0,bf[Kb]=!0);for(var lm=Xb[ka][Kb],df=lm.length,Jk=0;Jk<df;Jk++)if(ca=lm[Jk],ca.visible||ca===Fh){var ni=1<ca.qj||1<ca.Si;ni&&(ka<mi||Kb<Eh||
Le.push(ca));var Cc=ca.margin,Lf=Cc.right+Cc.left,mg=Cc.top+Cc.bottom,Tb=$o(ca,rb,sb,!1),ef=ca.Ea,Kk=!isNaN(ef.height),mm=!isNaN(ef.width)&&Kk;ni||Tb===Yj||mm||ka<mi||Kb<Eh||(void 0!==ve[Kb]||Tb!==rf&&Tb!==yo||(ve[Kb]=-1,ve.count++),void 0!==we[ka]||Tb!==rf&&Tb!==xo||(we[ka]=-1,we.count++),cf.push(ca));Gk(ca,Infinity,Infinity,0,0);if(!(ka<mi||Kb<Eh)){var Mf=ca.Fa,ff=Math.max(Mf.width+Lf,0),gf=Math.max(Mf.height+mg,0);if(1===ca.qj&&(Tb===Yj||Tb===yo)){var Z=this.Zd(ka),sc=Z.kf(),rc=Math.max(gf-Z.gb,
0);rc+sc>Od&&(rc=Math.max(Od-sc,0));var ng=0===Z.gb;Z.gb+=rc;Od=Math.max(Od-(rc+(ng?sc:0)),0)}1!==ca.Si||Tb!==Yj&&Tb!==xo||(Z=this.Yd(Kb),sc=Z.kf(),rc=Math.max(ff-Z.gb,0),rc+sc>cd&&(rc=Math.max(cd-sc,0)),ng=0===Z.gb,Z.gb+=rc,cd=Math.max(cd-(rc+(ng?sc:0)),0));ni&&ca.uq()}}}D.ya(bf);for(var Gc=0,Fb=0,Jb=this.cq,ka=0;ka<Jb;ka++){var oi=this.he[ka];void 0!==oi&&(Gc+=oi.Ya,0!==oi.Ya&&(Gc+=oi.kf()))}Jb=this.Kq;for(ka=0;ka<Jb;ka++){var Kg=this.ke[ka];void 0!==Kg&&(Fb+=Kg.Ya,0!==Kg.Ya&&(Fb+=Kg.kf()))}for(var cd=
Math.max(ii-Gc,0),xj=Od=Math.max(ji-Fb,0),tp=cd,Jb=cf.length,ka=0;ka<Jb;ka++){var ca=cf[ka],rb=this.Zd(ca.Sb),sb=this.Yd(ca.column),Lg=ca.Fa,Cc=ca.margin,Lf=Cc.right+Cc.left,mg=Cc.top+Cc.bottom;ve[ca.column]=0===sb.gb&&void 0!==ve[ca.column]?Math.max(Lg.width+Lf,ve[ca.column]):null;we[ca.Sb]=0===rb.gb&&void 0!==we[ca.Sb]?Math.max(Lg.height+mg,we[ca.Sb]):null}var Nf=0,Mg=0,Ng;for(Ng in we)"count"!==Ng&&(Nf+=we[Ng]);for(Ng in ve)"count"!==Ng&&(Mg+=ve[Ng]);for(var tb=D.Gm(),ka=0;ka<Jb;ka++)if(ca=cf[ka],
ca.visible||ca===Fh){var rb=this.Zd(ca.Sb),sb=this.Yd(ca.column),ac=0;isFinite(sb.width)?ac=sb.width:(ac=isFinite(cd)&&null!==ve[ca.column]?0===Mg?sb.gb+cd:ve[ca.column]/Mg*tp:null!==ve[ca.column]?cd:sb.gb||cd,ac=Math.max(0,ac-sb.kf()));var Hc=0;isFinite(rb.height)?Hc=rb.height:(Hc=isFinite(Od)&&null!==we[ca.Sb]?0===Nf?rb.gb+Od:we[ca.Sb]/Nf*xj:null!==we[ca.Sb]?Od:rb.gb||Od,Hc=Math.max(0,Hc-rb.kf()));tb.n(Math.max(sb.Th,Math.min(ac,sb.re)),Math.max(rb.Th,Math.min(Hc,rb.re)));Tb=$o(ca,rb,sb,!1);switch(Tb){case yo:tb.height=
Math.max(tb.height,rb.gb+Od);break;case xo:tb.width=Math.max(tb.width,sb.gb+cd)}Cc=ca.margin;Lf=Cc.right+Cc.left;mg=Cc.top+Cc.bottom;ca.uq();Gk(ca,tb.width,tb.height,sb.Th,rb.Th);Mf=ca.Fa;ff=Math.max(Mf.width+Lf,0);gf=Math.max(Mf.height+mg,0);isFinite(cd)&&(ff=Math.min(ff,tb.width));isFinite(Od)&&(gf=Math.min(gf,tb.height));var hf=0,hf=rb.gb;rb.gb=Math.max(rb.gb,gf);rc=rb.gb-hf;Od=Math.max(Od-rc,0);hf=sb.gb;sb.gb=Math.max(sb.gb,ff);rc=sb.gb-hf;cd=Math.max(cd-rc,0)}D.ya(cf);var Pd=D.Gm(),Jb=Le.length;
if(0!==Jb)for(var zd=D.nb(),jf=D.nb(),ka=0;ka<nd;ka++)if(Xb[ka])for(lg=Xb[ka].length,rb=this.Zd(ka),zd[ka]=rb.gb,Kb=0;Kb<lg;Kb++)Xb[ka][Kb]&&(sb=this.Yd(Kb),jf[Kb]=sb.gb);for(ka=0;ka<Jb;ka++)if(ca=Le[ka],ca.visible||ca===Fh){rb=this.Zd(ca.Sb);sb=this.Yd(ca.column);tb.n(Math.max(sb.Th,Math.min(ii,sb.re)),Math.max(rb.Th,Math.min(ji,rb.re)));Tb=$o(ca,rb,sb,!1);switch(Tb){case rf:0!==jf[sb.index]&&(tb.width=Math.min(tb.width,jf[sb.index]));0!==zd[rb.index]&&(tb.height=Math.min(tb.height,zd[rb.index]));
break;case yo:0!==jf[sb.index]&&(tb.width=Math.min(tb.width,jf[sb.index]));break;case xo:0!==zd[rb.index]&&(tb.height=Math.min(tb.height,zd[rb.index]))}isFinite(sb.width)&&(tb.width=sb.width);isFinite(rb.height)&&(tb.height=rb.height);Pd.n(0,0);for(var eb=1;eb<ca.qj&&!(ca.Sb+eb>=this.Kq);eb++)Z=this.Zd(ca.Sb+eb),rc=Tb===rf||Tb===xo?Math.max(Z.Th,0===zd[ca.Sb+eb]?Z.re:Math.min(zd[ca.Sb+eb],Z.re)):Math.max(Z.Th,isNaN(Z.tf)?Z.re:Math.min(Z.tf,Z.re)),Pd.height+=rc;for(eb=1;eb<ca.Si&&!(ca.column+eb>=this.cq);eb++)Z=
this.Yd(ca.column+eb),rc=Tb===rf||Tb===yo?Math.max(Z.Th,0===jf[ca.column+eb]?Z.re:Math.min(jf[ca.column+eb],Z.re)):Math.max(Z.Th,isNaN(Z.tf)?Z.re:Math.min(Z.tf,Z.re)),Pd.width+=rc;tb.width+=Pd.width;tb.height+=Pd.height;Cc=ca.margin;Lf=Cc.right+Cc.left;mg=Cc.top+Cc.bottom;Gk(ca,tb.width,tb.height,vj,wj);for(var Mf=ca.Fa,ff=Math.max(Mf.width+Lf,0),gf=Math.max(Mf.height+mg,0),kf=0,eb=0;eb<ca.qj&&!(ca.Sb+eb>=this.Kq);eb++)Z=this.Zd(ca.Sb+eb),kf+=Z.total||0;if(kf<gf){var od=gf-kf,Og=gf-kf;if(null!==ca.Ny)for(var lf=
ca.Ny,eb=0;eb<ca.qj&&!(0>=od)&&!(ca.Sb+eb>=this.Kq);eb++){var Z=this.Zd(ca.Sb+eb),Ic=Z.Ya||0,Of=lf(ca,Z,Og);v&&"number"!==typeof Of&&D.k(ca+" spanAllocation does not return a number: "+Of);Z.gb=Math.min(Z.re,Ic+Of);Z.Ya!==Ic&&(od-=Z.Ya-Ic)}for(;0<od;){Ic=Z.Ya||0;isNaN(Z.height)&&Z.re>Ic&&(Z.gb=Math.min(Z.re,Ic+od),Z.Ya!==Ic&&(od-=Z.Ya-Ic));if(0===Z.index)break;Z=this.Zd(Z.index-1)}}for(var Me=0,eb=0;eb<ca.Si&&!(ca.column+eb>=this.cq);eb++)Z=this.Yd(ca.column+eb),Me+=Z.total||0;if(Me<ff){od=ff-Me;
Og=ff-Me;if(null!==ca.Ny)for(lf=ca.Ny,eb=0;eb<ca.Si&&!(0>=od)&&!(ca.column+eb>=this.cq);eb++)Z=this.Yd(ca.column+eb),Ic=Z.Ya||0,Of=lf(ca,Z,Og),v&&"number"!==typeof Of&&D.k(ca+" spanAllocation does not return a number: "+Of),Z.gb=Math.min(Z.re,Ic+Of),Z.Ya!==Ic&&(od-=Z.Ya-Ic);for(;0<od;){Ic=Z.Ya||0;isNaN(Z.width)&&Z.re>Ic&&(Z.gb=Math.min(Z.re,Ic+od),Z.Ya!==Ic&&(od-=Z.Ya-Ic));if(0===Z.index)break;Z=this.Yd(Z.index-1)}}}D.ya(Le);D.Uk(Pd);D.Uk(tb);void 0!==zd&&D.ya(zd);void 0!==jf&&D.ya(jf);for(var Pg=
0,Qg=0,Tb=Mo(this,!0),yj=this.Ea,Lk=this.of,xe=Fb=Gc=0,dd=0,Jb=this.cq,ka=0;ka<Jb;ka++)void 0!==this.he[ka]&&(Z=this.Yd(ka),isFinite(Z.width)?(xe+=Z.width,xe+=Z.kf()):Up(Z)===Vp?(xe+=Z.Ya,xe+=Z.kf()):0!==Z.Ya&&(Gc+=Z.Ya,Gc+=Z.kf()));var Pg=isFinite(yj.width)?Math.min(yj.width,Lk.width):Tb!==Yj&&isFinite(ii)?ii:Gc,Pg=Math.max(Pg,this.bh.width),Pg=Math.max(Pg-xe,0),Mk=Math.max(Pg/Gc,1);isFinite(Mk)||(Mk=1);for(ka=0;ka<Jb;ka++)void 0!==this.he[ka]&&(Z=this.Yd(ka),isFinite(Z.width)||Up(Z)===Vp||(Z.gb=
Z.Ya*Mk),Z.position=e.width,0!==Z.Ya&&(e.width+=Z.Ya,e.width+=Z.kf()));Jb=this.Kq;for(ka=0;ka<Jb;ka++)void 0!==this.ke[ka]&&(Z=this.Zd(ka),isFinite(Z.height)?(dd+=Z.height,dd+=Z.kf()):Up(Z)===Vp?(dd+=Z.Ya,dd+=Z.kf()):0!==Z.Ya&&(Fb+=Z.Ya,0!==Z.Ya&&(Fb+=Z.kf())));var Qg=isFinite(yj.height)?Math.min(yj.height,Lk.height):Tb!==Yj&&isFinite(ji)?ji:Fb,Qg=Math.max(Qg,this.bh.height),Qg=Math.max(Qg-dd,0),Gh=Math.max(Qg/Fb,1);isFinite(Gh)||(Gh=1);for(ka=0;ka<Jb;ka++)void 0!==this.ke[ka]&&(Z=this.Zd(ka),isFinite(Z.height)||
Up(Z)===Vp||(Z.gb=Z.Ya*Gh),Z.position=e.height,0!==Z.Ya&&(e.height+=Z.Ya,0!==Z.Ya&&(e.height+=Z.kf())));Jb=Ch.length;for(ka=0;ka<Jb;ka++){var pd=Ch[ka];pd.type===Ro?(ac=e.width,Z=this.Zd(pd.Sb),Hc=Z.gb):(Z=this.Yd(pd.column),ac=Z.gb,Hc=e.height);pd.Dd.n(0,0,ac,Hc);jm(pd,!1);Xb[pd.Sb]||(Xb[pd.Sb]=[]);Xb[pd.Sb][pd.column]||(Xb[pd.Sb][pd.column]=[]);Xb[pd.Sb][pd.column].push(pd)}D.ya(Ch);this.mt=Xb;break;case "Viewbox":var zj=a,om=b,rt=c,st=d;1<m.length&&D.k("Viewbox Panel cannot contain more than one GraphObject.");
var mf=m[0];mf.Db=1;mf.uq();Gk(mf,Infinity,Infinity,rt,st);var Nk=mf.Fa,pm=mf.margin,tt=pm.right+pm.left,ut=pm.top+pm.bottom;if(isFinite(zj)||isFinite(om)){var iw=mf.scale,qm=Nk.width,rm=Nk.height,vt=Math.max(zj-tt,0),wt=Math.max(om-ut,0),Ok=1;this.Up===ak?0!==qm&&0!==rm&&(Ok=Math.min(vt/qm,wt/rm)):0!==qm&&0!==rm&&(Ok=Math.max(vt/qm,wt/rm));0===Ok&&(Ok=1E-4);mf.Db*=Ok;iw!==mf.scale&&(jm(mf,!0),Gk(mf,Infinity,Infinity,rt,st))}Nk=mf.Fa;e.width=isFinite(zj)?zj:Math.max(Nk.width+tt,0);e.height=isFinite(om)?
om:Math.max(Nk.height+ut,0);break;case "Link":var xt=m.length;if(this instanceof da||this instanceof J){var qi=null;this instanceof J&&(qi=this);this instanceof da&&(qi=this.Hf);if(qi instanceof J){var Pk=qi;if(0===xt){var sm=this.Ga;Cb(sm,0,0);var ye=this.Fa;ye.n(0,0,0,0)}else{var tm=this instanceof da?null:qi.path,Rg=qi.so,nf=this.vj;nf.assign(Rg);nf.x=0;nf.y=0;var Hh=Pk.points,ri=qi.ta;this.Mu(!1);var yt=Rg.width,zt=Rg.height;this.hj.n(Rg.x,Rg.y);null===this.rh&&(this.rh=new K(C));this.rh.clear();
null!==tm&&(Wp(tm,yt,zt,this),ye=tm.Fa,nf.Zh(ye),this.rh.add(ye));for(var Sg=D.Rf(),Qk=D.P(),Tg=D.P(),up=0;up<xt;up++){var Yb=m[up];if(Yb!==tm)if(Yb.Ue&&Yb instanceof z)Wp(Yb,yt,zt,this),ye=Yb.Fa,nf.Zh(ye),this.rh.add(ye);else if(2>ri)Gk(Yb,Infinity,Infinity),ye=Yb.Fa,nf.Zh(ye),this.rh.add(ye);else{var Ad=Yb.We,Bt=Yb.JB,vp=Yb.Ih;vp.ce()&&(vp=mc);var og=Yb.Lq,jw=Yb.KB,Rk=0,Sk=0,um=0;if(Ad<-ri||Ad>=ri){var Ct=Pk.oG,Pf=Pk.nG;og!==Mj&&(um=Pk.computeAngle(Yb,og,Pf),Yb.wg=um);Rk=Ct.x-Rg.x;Sk=Ct.y-Rg.y}else{var Qd,
of;if(0<=Ad)Qd=Hh.ea(Ad),of=Ad<ri-1?Hh.ea(Ad+1):Qd;else{var si=ri+Ad;Qd=Hh.ea(si);of=0<si?Hh.ea(si-1):Qd}if(Qd.Uc(of)){var ti,ui;0<=Ad?(ti=0<Ad?Hh.ea(Ad-1):Qd,ui=Ad<ri-2?Hh.ea(Ad+2):of):(ti=si<ri-1?Hh.ea(si+1):Qd,ui=1<si?Hh.ea(si-2):of);var Dt=ti.Jf(Qd),Et=of.Jf(ui),Pf=Dt>Et+10?0<=Ad?ti.Xb(Qd):Qd.Xb(ti):Et>Dt+10?0<=Ad?of.Xb(ui):ui.Xb(of):0<=Ad?ti.Xb(ui):ui.Xb(ti)}else Pf=0<=Ad?Qd.Xb(of):of.Xb(Qd);og!==Mj&&(um=Pk.computeAngle(Yb,og,Pf),Yb.wg=um);Rk=Qd.x+(of.x-Qd.x)*Bt-Rg.x;Sk=Qd.y+(of.y-Qd.y)*Bt-Rg.y}Gk(Yb,
Infinity,Infinity);var ye=Yb.Fa,sm=Yb.Ga,Tk=0;Yb instanceof z&&(Tk=Yb.mb);var vi=sm.width+Tk,Aj=sm.height+Tk;Sg.reset();Sg.translate(-ye.x,-ye.y);Sg.scale(Yb.scale,Yb.scale);Sg.rotate(og===Mj?Yb.angle:Pf,vi/2,Aj/2);og!==Xp&&og!==Yp||Sg.rotate(90,vi/2,Aj/2);og!==Zp&&og!==$p||Sg.rotate(-90,vi/2,Aj/2);og===aq&&(45<Pf&&135>Pf||225<Pf&&315>Pf)&&Sg.rotate(-Pf,vi/2,Aj/2);var Bj=new C(0,0,vi,Aj);Qk.uo(Bj,vp);Sg.xb(Qk);var kw=-Qk.x+Tk/2*Yb.scale,lw=-Qk.y+Tk/2*Yb.scale;Tg.assign(jw);isNaN(Tg.x)&&(Tg.x=0<=Ad?
vi/2+3:-(vi/2+3));isNaN(Tg.y)&&(Tg.y=-(Aj/2+3));Tg.rotate(Pf);Rk+=Tg.x;Sk+=Tg.y;Bj.set(ye);Bj.x=Rk+kw;Bj.y=Sk+lw;this.rh.add(Bj);nf.Zh(Bj)}}if(this instanceof J)for(var Ft=this.Af;Ft.next();)Gk(Ft.value,Infinity,Infinity);this.vj=nf;var wp=this.hj;wp.n(wp.x+nf.x,wp.y+nf.y);Cb(e,nf.width||0,nf.height||0);D.Je(Sg);D.A(Qk);D.A(Tg)}}}break;case "Grid":break;case "Graduated":var mw=a,nw=b,ow=c,pw=d,Ug=this.Xd();this.Tm=[];var Cj=Ug.margin,qw=Cj.right+Cj.left,rw=Cj.top+Cj.bottom;Gk(Ug,mw,nw,ow,pw);var Gt=
Ug.Fa,sw=Gt.height,tw=Math.max(Gt.width+qw,0),uw=Math.max(sw+rw,0),Ht=new C(-Cj.left,-Cj.top,tw,uw);this.Tm.push(Ht);e.assign(Ht);for(var Yk=Ug.uf,It=Ug.mb,xp=Yk.Px,Dj=Yk.mu,wi=Yk.nu,vw=xp.length,yp=0,zp=0,Ej=D.nb(),Ap=0;Ap<vw;Ap++){for(var Bp=xp[Ap],Jt=[],zp=yp=0,ww=Bp.length,Fj=0;Fj<ww;Fj+=2){var Kt=Bp[Fj],Lt=Bp[Fj+1];if(0!==Fj){var Bd=180*Math.atan2(Lt-zp,Kt-yp)/Math.PI;0>Bd&&(Bd+=360);Jt.push(Bd)}yp=Kt;zp=Lt}Ej.push(Jt)}var Mt;if(null===this.nk){for(var Nt=[],Cp=this.xa.o,Ot=Cp.length,Dp=0;Dp<
Ot;Dp++){var Zk=Cp[Dp],Pt=[];Nt.push(Pt);if(Zk.visible)for(var xw=Zk.interval,Ep=0;Ep<Ot;Ep++){var $k=Cp[Ep];if($k.visible&&Zk!==$k&&!(Zk instanceof z&&!($k instanceof z)||Zk instanceof pa&&!($k instanceof pa))){var Qt=$k.interval;Qt>xw&&Pt.push(Qt)}}}this.nk=Nt}Mt=this.nk;var Rt=this.xa.o,yw=Rt.length,Ih=0,St=0,Tt=wi;this.Vm=[];for(var wm=[],xm=0;xm<yw;xm++){var Jh=Rt[xm],wm=[];if(Jh.visible&&Jh!==Ug){var Ut=Jh.interval,Vt=this.XA;if(!(2>Vt*Ut*wi/this.uu)){var ee=this.KF,Wt=Mt[xm],xi=Dj[0][0],Qf=
0,Cd=0,St=wi*Jh.JF-1E-4,Tt=wi*Jh.HF+1E-4,Gj=Vt*Ut;if(ee<this.Wk)var yi=(this.Wk-ee)/Gj,yi=0===yi%1?yi:Math.floor(yi+1),ee=ee+yi*Gj;else ee>this.Wk+Gj&&(yi=Math.floor((ee-this.Wk)/Gj),ee-=yi*Gj);for(;ee<=this.WA;){var Fp;a:{for(var zw=Wt.length,Gp=0;Gp<zw;Gp++)if(Eb((ee-this.KF)%(Wt[Gp]*this.XA),0)){Fp=!1;break a}Fp=!0}if(Fp&&(Ih=(ee-this.Wk)*wi/this.uu,Ih>wi&&(Ih=wi),St<=Ih&&Ih<=Tt)){for(var Bd=Ej[Qf][Cd],Hj=Dj[Qf][Cd];Qf<Dj.length;){for(;Ih>xi&&Cd<Dj[Qf].length-1;)Cd++,Bd=Ej[Qf][Cd],Hj=Dj[Qf][Cd],
xi+=Hj;if(Ih<=xi)break;Qf++;Cd=0;Bd=Ej[Qf][Cd];Hj=Dj[Qf][Cd];xi+=Hj}var Rd=xp[Qf],Xt=Rd[2*Cd],Yt=Rd[2*Cd+1],ym=(Ih-(xi-Hj))/Hj,Hp=new N(Xt+(Rd[2*Cd+2]-Xt)*ym+It/2-Yk.qb.x,Yt+(Rd[2*Cd+3]-Yt)*ym+It/2-Yk.qb.y);Hp.scale(Ug.scale,Ug.scale);var pg=Bd,Ij=Ej[Qf];1E-4>ym?0<Cd?pg=Ij[Cd-1]:Eb(Rd[0],Rd[Rd.length-2])&&Eb(Rd[1],Rd[Rd.length-1])&&(pg=Ij[Ij.length-1]):.9999<ym&&(Cd+1<Ij.length?pg=Ij[Cd+1]:Eb(Rd[0],Rd[Rd.length-2])&&Eb(Rd[1],Rd[Rd.length-1])&&(pg=Ij[0]));Bd!==pg&&(180<Math.abs(Bd-pg)&&(Bd<pg?Bd+=
360:pg+=360),Bd=(Bd+pg)/2%360);if(Jh instanceof pa){var zm="",zm=null!==Jh.IF?Jh.IF(ee):(+ee.toFixed(2)).toString();v&&D.h(zm,"string");""!==zm&&wm.push([Hp,Bd,zm])}else wm.push([Hp,Bd])}ee+=Gj}}}this.Vm.push(wm)}D.ya(Ej);for(var Aw=this.Vm,Bw=m.length,Am=0;Am<Bw;Am++){var zi=m[Am],Ip=Aw[Am];if(zi.visible&&zi!==Ug&&0!==Ip.length){if(zi instanceof z){var Kh=zi,Zt=Ip,Cw=e,Jp=Kh.Ih;Jp.ce()&&(Jp=jc);var Dw=Kh.angle;Kh.wg=0;Gk(Kh,Infinity,Infinity);Kh.wg=Dw;var $t=Kh.Fa,Kp=$t.width,Lp=$t.height,au=D.vg(0,
0,Kp,Lp),fe=D.P();fe.uo(au,Jp);D.Kb(au);for(var Bm=-fe.x,Cm=-fe.y,Ai=new C,Ew=Zt.length,Dm=0;Dm<Ew;Dm++)for(var Mp=Zt[Dm],bu=Mp[0].x,cu=Mp[0].y,du=Mp[1],Em=0;4>Em;Em++){switch(Em){case 0:fe.n(Bm,Cm);break;case 1:fe.n(Bm+Kp,Cm);break;case 2:fe.n(Bm,Cm+Lp);break;case 3:fe.n(Bm+Kp,Cm+Lp)}fe.rotate(du+Kh.angle);fe.offset(bu,cu);0===Dm&&0===Em?Ai.n(fe.x,fe.y,0,0):Ai.Mi(fe);fe.offset(-bu,-cu);fe.rotate(-du-Kh.angle)}D.A(fe);this.Tm.push(Ai);Ub(Cw,Ai.x,Ai.y,Ai.width,Ai.height)}else if(zi instanceof pa){var Fm=
zi,eu=Ip,Fw=e;null===this.Ek&&(this.Ek=new pa);var Rf=this.Ek;rp(Fm,Rf);var Np=Fm.Ih;Np.ce()&&(Np=jc);for(var Lh=Fm.Lq,Gw=Fm.KB,Bi=null,Gm=0,Hm=0,Vg=0,Op=0,Hw=eu.length,Im=0;Im<Hw;Im++){var qg=eu[Im],Gm=qg[0].x,Hm=qg[0].y,Vg=qg[1];Lh!==Mj&&(Op=J.computeAngle(Lh,Vg),Rf.wg=Op);Rf.text=qg[2];Gk(Rf,Infinity,Infinity);var Mh=Rf.Fa,Jj=Rf.Ga,Kj=Jj.width,Lj=Jj.height,Wg=D.Rf();Wg.reset();Wg.translate(-Mh.x,-Mh.y);Wg.scale(Rf.scale,Rf.scale);Wg.rotate(Lh===Mj?Rf.angle:Vg,Kj/2,Lj/2);Lh!==Xp&&Lh!==Yp||Wg.rotate(90,
Kj/2,Lj/2);Lh!==Zp&&Lh!==$p||Wg.rotate(-90,Kj/2,Lj/2);Lh===aq&&(45<Vg&&135>Vg||225<Vg&&315>Vg)&&Wg.rotate(-Vg,Kj/2,Lj/2);var fu=D.vg(0,0,Kj,Lj),al=D.P();al.uo(fu,Np);Wg.xb(al);var Iw=-al.x,Jw=-al.y,Xg=D.P();Xg.assign(Gw);isNaN(Xg.x)&&(Xg.x=Kj/2+3);isNaN(Xg.y)&&(Xg.y=-(Lj/2+3));Xg.rotate(Vg);var Gm=Gm+(Xg.x+Iw),Hm=Hm+(Xg.y+Jw),Pp=new C(Gm,Hm,Mh.width,Mh.height),Kw=new C(Mh.x,Mh.y,Mh.width,Mh.height),Lw=new C(Jj.x,Jj.y,Jj.width,Jj.height),gu=new bq;gu.dq(Rf.rf);qg.push(Op);qg.push(Rf.we);qg.push(gu);
qg.push(Pp);qg.push(Kw);qg.push(Lw);0===Im?Bi=Pp.copy():Bi.Zh(Pp);D.A(Xg);D.A(al);D.Kb(fu);D.Je(Wg)}this.Tm.push(Bi);Ub(Fw,Bi.x,Bi.y,Bi.width,Bi.height)}jm(zi,!1)}}break;case "TableRow":case "TableColumn":D.k(this.toString()+" is not an element of a Table Panel. TableRow and TableColumn Panels can only be elements of a Table Panel.");break;default:D.k("Unknown panel type: "+n)}}var pf=e.width,qf=e.height,Jm=this.padding,Mw=Jm.top+Jm.bottom,pf=pf+(Jm.left+Jm.right),qf=qf+Mw;isFinite(g.width)&&(pf=
g.width);isFinite(g.height)&&(qf=g.height);pf=Math.min(k.width,pf);qf=Math.min(k.height,qf);pf=Math.max(h.width,pf);qf=Math.max(h.height,qf);pf=Math.max(c,pf);qf=Math.max(d,qf);e.width=pf;e.height=qf;Cb(this.ed,pf,qf);Jo(this,0,0,pf,qf)};x.prototype.findMainElement=x.prototype.Xd=function(){if(null===this.Wl){var a=this.xa.o,b=a.length;if(0===b)return null;for(var c=0;c<b;c++){var d=a[c];if(!0===d.Ue)return this.Wl=d}this.Wl=a[0]}return this.Wl};function Rp(a){return null!==a.Y?a.Y.Bf:null}
x.prototype.yj=function(a,b,c,d){var e=this.vj,g=this.xa.o,h=D.vg(0,0,0,0);if(0===g.length){var k=this.Z;k.x=a;k.y=b;k.width=c;k.height=d}else{if(!this.Ea.F()){var l=Mo(this,!0),m=this.Dd,n=m.width,p=m.height,q=this.margin,r=q.left+q.right,s=q.top+q.bottom;n===c&&p===d&&(l=Yj);switch(l){case Yj:if(n>c||p>d)this.K(),Gk(this,n>c?c:n,p>d?d:p);break;case rf:this.K(!0);Gk(this,c+r,d+s,0,0);break;case yo:this.K(!0);Gk(this,c+r,p+s,0,0);break;case xo:this.K(!0),Gk(this,n+r,d+s,0,0)}}k=this.Z;k.x=a;k.y=b;
k.width=c;k.height=d;var u=this.ca.ac;switch(u){case "Position":for(var t=g.length,y=e.x-this.padding.left,w=e.y-this.padding.top,B=0;B<t;B++){var A=g[B],O=A.Fa,H=A.margin,R=A.position.x,aa=A.position.y;h.x=isNaN(R)?-y:R-y;h.y=isNaN(aa)?-w:aa-w;if(A instanceof z){var V=A;if(V.XF){var ea=V.mb/2;h.x-=ea;h.y-=ea}}h.x+=H.left;h.y+=H.top;h.width=O.width;h.height=O.height;A.visible&&A.rc(h.x,h.y,h.width,h.height)}break;case "Vertical":for(var la=g.length,Da=this.padding.left,La=this.cG,gb=La?e.height:this.padding.top,
za=0;za<la;za++){var W=Da,wb=g[za];if(wb.visible){var Pb=wb.Fa,Sa=wb.margin,Qc=Sa.left+Sa.right,Ra=Da+this.padding.right,hg=Pb.width,ig=Mo(wb,!1);if(isNaN(wb.Ea.width)&&ig===rf||ig===yo)hg=Math.max(e.width-Qc-Ra,0);var ud=hg+Qc+Ra,vd=wb.alignment;vd.kd()&&(vd=this.nm);vd.Vc()||(vd=mc);La&&(gb-=Pb.height+Sa.bottom+Sa.top);wb.rc(W+vd.offsetX+Sa.left+(e.width*vd.x-ud*vd.x),gb+vd.offsetY+Sa.top,hg,Pb.height);La||(gb+=Pb.height+Sa.bottom+Sa.top)}}break;case "Horizontal":for(var Rc=g.length,uh=this.padding.top,
Ye=this.cG,hb=Ye?e.width:this.padding.left,ub=0;ub<Rc;ub++){var sa=uh,na=g[ub];if(na.visible){var ab=na.Fa,Oa=na.margin,pb=Oa.top+Oa.bottom,Md=uh+this.padding.bottom,Gf=ab.height,re=Mo(na,!1);if(isNaN(na.Ea.height)&&re===rf||re===xo)Gf=Math.max(e.height-pb-Md,0);var be=Gf+pb+Md,jd=na.alignment;jd.kd()&&(jd=this.nm);jd.Vc()||(jd=mc);Ye&&(hb-=ab.width+Oa.left+Oa.right);na.rc(hb+jd.offsetX+Oa.left,sa+jd.offsetY+Oa.top+(e.height*jd.y-be*jd.y),ab.width,Gf);Ye||(hb+=ab.width+Oa.left+Oa.right)}}break;case "Spot":var vh=
g.length,Hf=this.Xd(),Hg=Hf.Fa,Hb=Hg.width,oc=Hg.height,wd=this.padding,wh=wd.left,qb=wd.top;h.x=wh-e.x;h.y=qb-e.y;Hf.rc(h.x,h.y,Hb,oc);for(var Pa=0;Pa<vh;Pa++){var Qb=g[Pa];if(Qb!==Hf){var If=Qb.Fa,Ig=If.width,xh=If.height,ib=Qb.alignment;ib.kd()&&(ib=this.nm);ib.Vc()||(ib=mc);var zc=Qb.Ih;zc.kd()&&(zc=mc);var $b=null;Qb instanceof x&&""!==Qb.ak&&($b=Qb.Hd(Qb.ak),$b===Qb&&($b=null));if(null!==$b){for(var se=$b.Ga,Ac=D.Fb(zc.x*se.width+zc.offsetX,zc.y*se.height+zc.offsetY);$b!==Qb;)$b.transform.xb(Ac),
$b=$b.R;h.x=ib.x*Hb+ib.offsetX-Ac.x;h.y=ib.y*oc+ib.offsetY-Ac.y;D.A(Ac)}else h.x=ib.x*Hb+ib.offsetX-(zc.x*Ig-zc.offsetX),h.y=ib.y*oc+ib.offsetY-(zc.y*xh-zc.offsetY);h.x-=e.x;h.y-=e.y;Qb.visible&&Qb.rc(wh+h.x,qb+h.y,Ig,xh)}}break;case "Auto":var Bc=g.length,vb=this.Xd(),pc=vb.Fa,xb=D.Qf();xb.n(0,0,1,1);var Fc=vb.margin,db=Fc.left,Ta=Fc.top,Mb=this.padding,xd=Mb.left,jg=Mb.top;h.x=db;h.y=Ta;h.width=pc.width;h.height=pc.height;vb.rc(xd+h.x,jg+h.y,h.width,h.height);var Ze=Sp(vb),$e=Tp(vb),uj=0+Ze.y*pc.height+
Ze.offsetY,Rb=0+$e.x*pc.width+$e.offsetX,qc=0+$e.y*pc.height+$e.offsetY;xb.x=0+Ze.x*pc.width+Ze.offsetX;xb.y=uj;Ub(xb,Rb,qc,0,0);xb.x+=db+xd;xb.y+=Ta+jg;for(var fc=0;fc<Bc;fc++){var kd=g[fc];if(kd!==vb){var mb=kd.Fa,Fc=kd.margin,Sb=Math.max(mb.width+Fc.right+Fc.left,0),te=Math.max(mb.height+Fc.top+Fc.bottom,0),yb=kd.alignment;yb.kd()&&(yb=this.nm);yb.Vc()||(yb=mc);h.x=xb.width*yb.x+yb.offsetX-Sb*yb.x+Fc.left+xb.x;h.y=xb.height*yb.y+yb.offsetY-te*yb.y+Fc.top+xb.y;h.width=xb.width;h.height=xb.height;
kd.visible&&(Vb(xb.x,xb.y,xb.width,xb.height,h.x,h.y,mb.width,mb.height)?kd.rc(h.x,h.y,mb.width,mb.height):kd.rc(h.x,h.y,mb.width,mb.height,new C(xb.x,xb.y,xb.width,xb.height)))}}D.Kb(xb);break;case "Table":for(var ce=g.length,yh=this.padding,zh=yh.left,Jg=yh.top,ld=this.mt,Nd=0,gc=0,zb=ld.length,yd=0,nb=0;nb<zb;nb++)ld[nb]&&(yd=Math.max(yd,ld[nb].length));for(var hc=Math.min(this.uj,zb-1);hc!==zb&&(void 0===this.ke[hc]||0===this.ke[hc].Ya);)hc++;for(var hc=Math.min(hc,zb-1),hi=-this.ke[hc].tb,ad=
Math.min(this.fj,yd-1);ad!==yd&&(void 0===this.he[ad]||0===this.he[ad].Ya);)ad++;for(var ad=Math.min(ad,yd-1),Ib=-this.he[ad].tb,ue=D.Gm(),nb=0;nb<zb;nb++)if(ld[nb]){var yd=ld[nb].length,af=this.Zd(nb),gc=af.tb+hi+Jg;0!==af.Ya&&(gc+=af.ZE());for(var bd=0;bd<yd;bd++)if(ld[nb][bd]){var Jf=this.Yd(bd),Nd=Jf.tb+Ib+zh;0!==Jf.Ya&&(Nd+=Jf.ZE());for(var Kf=ld[nb][bd],Ah=Kf.length,Ik=0;Ik<Ah;Ik++){var Ab=Kf[Ik],de=Ab.Fa,md=Ab instanceof x?Ab:null;if(null===md||md.type!==Ro&&md.type!==So){ue.n(0,0);for(var Ke=
1;Ke<Ab.rowSpan&&!(nb+Ke>=this.Kq);Ke++){var ii=this.Zd(nb+Ke);ue.height+=ii.total}for(Ke=1;Ke<Ab.fI&&!(bd+Ke>=this.cq);Ke++){var ji=this.Yd(bd+Ke);ue.width+=ji.total}var vj=Jf.Ya+ue.width,wj=af.Ya+ue.height;h.x=Nd;h.y=gc;h.width=vj;h.height=wj;var Jb=Nd,Bh=gc,Ch=vj,ka=wj;Nd+vj>e.width&&(Ch=Math.max(e.width-Nd,0));gc+wj>e.height&&(ka=Math.max(e.height-gc,0));var ca=Ab.alignment,ic=0,kg=0,ki=0,Kb=0;if(ca.kd()){ca=this.nm;ca.Vc()||(ca=mc);var ic=ca.x,kg=ca.y,ki=ca.offsetX,Kb=ca.offsetY,li=Jf.alignment,
Xb=af.alignment;li.Vc()&&(ic=li.x,ki=li.offsetX);Xb.Vc()&&(kg=Xb.y,Kb=Xb.offsetY)}else ic=ca.x,kg=ca.y,ki=ca.offsetX,Kb=ca.offsetY;if(isNaN(ic)||isNaN(kg))kg=ic=.5,Kb=ki=0;var bf=de.width,Le=de.height,cf=Ab.margin,ve=cf.left+cf.right,we=cf.top+cf.bottom,cd=$o(Ab,af,Jf,!1);!isNaN(Ab.Ea.width)||cd!==rf&&cd!==yo||(bf=Math.max(vj-ve,0));!isNaN(Ab.Ea.height)||cd!==rf&&cd!==xo||(Le=Math.max(wj-we,0));var Od=Ab.of,Dh=Ab.bh,bf=Math.min(Od.width,bf),Le=Math.min(Od.height,Le),bf=Math.max(Dh.width,bf),Le=Math.max(Dh.height,
Le),Z=Le+we;h.x+=h.width*ic-(bf+ve)*ic+ki+cf.left;h.y+=h.height*kg-Z*kg+Kb+cf.top;Ab.visible&&(Vb(Jb,Bh,Ch,ka,h.x,h.y,de.width,de.height)?Ab.rc(h.x,h.y,bf,Le):Ab.rc(h.x,h.y,bf,Le,new C(Jb,Bh,Ch,ka)))}else{Ab.aj();Ab.cc.Xa();var nd=Ab.cc,lg=D.vg(nd.x,nd.y,nd.width,nd.height);nd.x=md.type===Ro?zh:Nd;nd.y=md.type===So?Jg:gc;nd.width=de.width;nd.height=de.height;Ab.cc.freeze();No(Ab,!1);if(!Db(lg,nd)){var mi=Ab.Y;null!==mi&&(mi.Yk(),Ab.Qs(mi))}D.Kb(lg)}}}}D.Uk(ue);for(nb=0;nb<ce;nb++)Ab=g[nb],md=Ab instanceof
x?Ab:null,null===md||md.type!==Ro&&md.type!==So||(nd=Ab.cc,Ab.ed.Xa(),Ab.ed.n(0,0,nd.width,nd.height),Ab.ed.freeze());break;case "Viewbox":var Eh=g[0],rc=Eh.Fa,Fh=Eh.margin,rb=Fh.top+Fh.bottom,sb=Math.max(rc.width+(Fh.right+Fh.left),0),lm=Math.max(rc.height+rb,0),df=Eh.alignment;df.kd()&&(df=this.nm);df.Vc()||(df=mc);h.x=e.width*df.x-sb*df.x+df.offsetX;h.y=e.height*df.y-lm*df.y+df.offsetY;h.width=rc.width;h.height=rc.height;Eh.rc(h.x,h.y,h.width,h.height);break;case "Link":var Jk=g.length;if(this instanceof
da||this instanceof J){var ni=null;this instanceof J&&(ni=this);this instanceof da&&(ni=this.Hf);var Cc=ni,Lf=this instanceof da?null:Cc.path;if(null!==this.rh){var mg=this.rh.o,Tb=0;if(null!==Lf&&Tb<this.rh.count){var ef=mg[Tb];Tb++;Lf.rc(ef.x-this.vj.x,ef.y-this.vj.y,ef.width,ef.height)}for(var Kk=0;Kk<Jk;Kk++){var mm=g[Kk];mm!==Lf&&Tb<this.rh.count&&(ef=mg[Tb],Tb++,mm.rc(ef.x-this.vj.x,ef.y-this.vj.y,ef.width,ef.height))}}var Mf=Cc.points,ff=Mf.count;if(2<=ff&&this instanceof J)for(var gf=this.Af;gf.next();){var sc=
gf.value,ng=ff,Gc=Mf,Fb=sc.We,oi=sc.JB,Kg=sc.Ih,xj=sc.Lq,tp=sc.KB,Lg=0,Nf=0,Mg=0;if(Fb<-ng||Fb>=ng){var Ng=this.oG,tb=this.nG;xj!==Mj&&(Mg=this.computeAngle(sc,xj,tb),sc.angle=Mg);Lg=Ng.x;Nf=Ng.y}else{var ac=void 0,Hc=void 0;if(0<=Fb)ac=Gc.o[Fb],Hc=Fb<ng-1?Gc.o[Fb+1]:ac;else var hf=ng+Fb,ac=Gc.o[hf],Hc=0<hf?Gc.o[hf-1]:ac;if(ac.Uc(Hc)){var Pd=void 0,zd=void 0;0<=Fb?(Pd=0<Fb?Gc.o[Fb-1]:ac,zd=Fb<ng-2?Gc.o[Fb+2]:Hc):(Pd=hf<ng-1?Gc.o[hf+1]:ac,zd=1<hf?Gc.o[hf-2]:Hc);var jf=Pd.Jf(ac),eb=Hc.Jf(zd),tb=jf>
eb+10?0<=Fb?Pd.Xb(ac):ac.Xb(Pd):eb>jf+10?0<=Fb?Hc.Xb(zd):zd.Xb(Hc):0<=Fb?Pd.Xb(zd):zd.Xb(Pd)}else tb=0<=Fb?ac.Xb(Hc):Hc.Xb(ac);xj!==Mj&&(Mg=this.computeAngle(sc,xj,tb),sc.angle=Mg);Lg=ac.x+(Hc.x-ac.x)*oi;Nf=ac.y+(Hc.y-ac.y)*oi}if(Kg.O(dc))sc.location=new N(Lg,Nf);else{Kg.ce()&&(Kg=mc);var kf=D.Rf();kf.reset();kf.scale(sc.scale,sc.scale);kf.rotate(sc.angle,0,0);var od=sc.Ga,Og=D.vg(0,0,od.width,od.height),lf=D.P();lf.uo(Og,Kg);kf.xb(lf);var Ic=-lf.x,Of=-lf.y,Me=tp.copy();isNaN(Me.x)&&(Me.x=0<=Fb?lf.x+
3:-(lf.x+3));isNaN(Me.y)&&(Me.y=-(lf.y+3));Me.rotate(tb);Lg+=Me.x;Nf+=Me.y;kf.nH(Og);var Ic=Ic+Og.x,Of=Of+Og.y,Pg=D.Fb(Lg+Ic,Nf+Of);sc.move(Pg);D.A(Pg);D.A(lf);D.Kb(Og);D.Je(kf)}}this.Mu(!1)}break;case "Grid":break;case "Graduated":if(null!==this.Tm){var Qg=this.Xd(),yj=this.Vm,Lk=this.Tm,xe=0,dd=Lk[xe];xe++;Qg.rc(dd.x-e.x,dd.y-e.y,dd.width,dd.height);for(var Mk=g.length,Gh=0;Gh<Mk;Gh++){var pd=g[Gh],zj=yj[Gh];pd.visible&&pd!==Qg&&0!==zj.length&&(dd=Lk[xe],xe++,pd.rc(dd.x-e.x,dd.y-e.y,dd.width,dd.height))}this.Tm=
null}break;case "TableRow":case "TableColumn":D.k(this.toString()+" is not an element of a Table Panel.TableRow and TableColumn panels can only be elements of a Table Panel.");break;default:D.k("Unknown panel type: "+u)}D.Kb(h)}};x.prototype.Ok=function(a){var b=this.Ga,c=Rp(this);if(Vb(0,0,b.width,b.height,a.x,a.y)){for(var b=this.xa.o,d=b.length,e=D.Fb(0,0);d--;){var g=b[d];if(g.visible||g===c)if(kb(e.set(a),g.transform),g.Pa(e))return D.A(e),!0}D.A(e);return null===this.Lb&&null===this.kc?!1:!0}return!1};
x.prototype.Lx=function(a){if(this.Jo===a)return this;for(var b=this.xa.o,c=b.length,d=0;d<c;d++){var e=b[d].Lx(a);if(null!==e)return e}return null};function gp(a,b,c){c(a,b);if(b instanceof x){b=b.xa.o;for(var d=b.length,e=0;e<d;e++)gp(a,b[e],c)}}function Wm(a,b){cq(a,a,b)}function cq(a,b,c){c(b);b=b.xa.o;for(var d=b.length,e=0;e<d;e++){var g=b[e];g instanceof x&&cq(a,g,c)}}x.prototype.walkVisualTree=x.prototype.FK=function(a){dq(this,this,a)};
function dq(a,b,c){c(b);if(b instanceof x){b=b.xa.o;for(var d=b.length,e=0;e<d;e++)dq(a,b[e],c)}}x.prototype.findInVisualTree=x.prototype.iu=function(a){return eq(this,this,a)};function eq(a,b,c){if(c(b))return b;if(b instanceof x){b=b.xa.o;for(var d=b.length,e=0;e<d;e++){var g=eq(a,b[e],c);if(null!==g)return g}}return null}
x.prototype.findObject=x.prototype.Hd=function(a){if(this.name===a)return this;for(var b=this.xa.o,c=b.length,d=0;d<c;d++){var e=b[d];if(e.name===a)return e;if(e instanceof x)if(null===e.cj&&null===e.Jg){if(e=e.Hd(a),null!==e)return e}else if(un(e)&&(e=e.xa.first(),null!==e&&(e=e.Hd(a),null!==e)))return e}return null};
function fq(a){a=a.xa.o;for(var b=a.length,c=0,d=0;d<b;d++){var e=a[d];if(e instanceof x)c=Math.max(c,fq(e));else if(e instanceof z){a:{if(null!==!e.bg)switch(e.$o){case "None":case "Square":case "Ellipse":case "Circle":case "LineH":case "LineV":case "FramedRectangle":case "RoundedRectangle":case "Line1":case "Line2":case "Border":case "Cube1":case "Cube2":case "Junction":case "Cylinder1":case "Cylinder2":case "Cylinder3":case "Cylinder4":case "PlusLine":case "XLine":case "ThinCross":case "ThickCross":e=0;
break a}e=e.Sg/2*e.yn*e.Fj()}c=Math.max(c,e)}}return c}f=x.prototype;f.nh=function(){return!(this.type===Ro||this.type===So)};
f.Ie=function(a,b,c){if(!1===this.tg)return null;void 0===b&&(b=null);void 0===c&&(c=null);if(Nm(this))return null;var d=this.Ga,e=1/this.Fj(),g=this.nh(),h=g?a:kb(D.Fb(a.x,a.y),this.transform),k=this.g,l=10,m=5;null!==k&&(l=k.tu("extraTouchArea"),m=l/2);if(Vb(-(m*e),-(m*e),d.width+l*e,d.height+l*e,h.x,h.y)){if(!this.Zn){var e=this.xa.o,n=e.length,k=D.P(),m=(l=this.vq)?this.Xd():null;if(l&&(m.nh()?kb(k.set(a),m.transform):k.set(a),!m.Pa(k)))return D.A(k),g||D.A(h),null;for(var p=Rp(this);n--;){var q=
e[n];if(q.visible||q===p)if(q.nh()?kb(k.set(a),q.transform):k.set(a),!l||q!==m){var r=null;q instanceof x?r=q.Ie(k,b,c):!0===q.tg&&q.Pa(k)&&(r=q);if(null!==r&&(null!==b&&(r=b(r)),null!==r&&(null===c||c(r))))return D.A(k),g||D.A(h),r}}D.A(k)}if(null===this.background&&null===this.fm)return g||D.A(h),null;a=Vb(0,0,d.width,d.height,h.x,h.y)?this:null;g||D.A(h);return a}g||D.A(h);return null};
f.ju=function(a,b,c,d){if(!1===this.tg)return!1;void 0===b&&(b=null);void 0===c&&(c=null);d instanceof K||d instanceof L||(d=new K(P));var e=this.Ga,g=this.nh(),h=g?a:kb(D.Fb(a.x,a.y),this.transform);if(Vb(0,0,e.width,e.height,h.x,h.y)){if(!this.Zn){for(var e=this.xa.o,k=e.length,l=D.P(),m=Rp(this);k--;){var n=e[k];if(n.visible||n===m){n.nh()?kb(l.set(a),n.transform):l.set(a);var p=n,n=n instanceof x?n:null;(null!==n?n.ju(l,b,c,d):p.Pa(l))&&!1!==p.tg&&(null!==b&&(p=b(p)),null===p||null!==c&&!c(p)||
d.add(p))}}D.A(l)}g||D.A(h);return null!==this.background||null!==this.fm}g||D.A(h);return!1};
f.Sk=function(a,b,c,d,e,g){if(!1===this.tg)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var h=g;void 0===g&&(h=D.Rf(),h.reset());h.multiply(this.transform);if(this.Jn(a,h))return gq(this,b,c,e),void 0===g&&D.Je(h),!0;if(this.jg(a,h)){if(!this.Zn)for(var k=Rp(this),l=this.xa.o,m=l.length;m--;){var n=l[m];if(n.visible||n===k){var p=n.Z,q=this.Ga;if(!(p.x>q.width||p.y>q.height||0>p.x+p.width||0>p.y+p.height)){p=n;n=n instanceof x?n:null;q=D.Rf();q.set(h);if(null!==n?n.Sk(a,b,c,d,e,q):Lo(p,a,d,
q))null!==b&&(p=b(p)),null===p||null!==c&&!c(p)||e.add(p);D.Je(q)}}}void 0===g&&D.Je(h);return d}void 0===g&&D.Je(h);return!1};function gq(a,b,c,d){for(var e=a.xa.o,g=e.length;g--;){var h=e[g];if(h.visible){var k=h.Z,l=a.Ga;k.x>l.width||k.y>l.height||0>k.x+k.width||0>k.y+k.height||(h instanceof x&&gq(h,b,c,d),null!==b&&(h=b(h)),null===h||null!==c&&!c(h)||d.add(h))}}}
f.Qn=function(a,b,c,d,e,g){if(!1===this.tg)return!1;void 0===c&&(c=null);void 0===d&&(d=null);var h=this.Ga,k=this.nh(),l=k?a:kb(D.Fb(a.x,a.y),this.transform),m=k?b:kb(D.Fb(b.x,b.y),this.transform),n=l.Jf(m),p=0<l.x&&l.x<h.width&&0<l.y&&l.y<h.height||lb(l.x,l.y,0,0,0,h.height)<n||lb(l.x,l.y,0,h.height,h.width,h.height)<n||lb(l.x,l.y,h.width,h.height,h.width,0)<n||lb(l.x,l.y,h.width,0,0,0)<n,h=0<l.x&&l.x<h.width&&0<l.y&&l.y<h.height&&lb(l.x,l.y,0,0,0,h.height)<n&&lb(l.x,l.y,0,h.height,h.width,h.height)<
n&&lb(l.x,l.y,h.width,h.height,h.width,0)<n&&lb(l.x,l.y,h.width,0,0,0)<n;k||(D.A(l),D.A(m));if(p){if(!this.Zn){for(var k=D.P(),l=D.P(),m=Rp(this),n=this.xa.o,q=n.length;q--;){var r=n[q];if(r.visible||r===m){var s=r.Z,u=this.Ga;s.x>u.width||s.y>u.height||0>s.x+s.width||0>s.y+s.height||(r.nh()?(s=r.transform,kb(k.set(a),s),kb(l.set(b),s)):(k.set(a),l.set(b)),s=r,r=r instanceof x?r:null,null!==r?!r.Qn(k,l,c,d,e,g):!s.yF(k,l,e))||(null!==c&&(s=c(s)),null===s||null!==d&&!d(s)||g.add(s))}}D.A(k);D.A(l)}return e?
p:h}return!1};function Sp(a){var b=null;a instanceof z&&(b=a.C,b===Vc&&(b=null),a=a.uf,null!==a&&null===b&&(b=a.C));null===b&&(b=ec);return b}function Tp(a){var b=null;a instanceof z&&(b=a.D,b===Vc&&(b=null),a=a.uf,null!==a&&null===b&&(b=a.D));null===b&&(b=vc);return b}x.prototype.add=x.prototype.add=function(a){D.l(a,P,x,"add:element");this.ae(this.xa.count,a)};x.prototype.elt=x.prototype.ea=function(a){return this.xa.ea(a)};
x.prototype.insertAt=x.prototype.ae=function(a,b){b instanceof F&&D.k("Cannot add a Part to a Panel: "+b+"; use a Panel instead");if(this===b||this.ym(b))this===b&&D.k("Cannot make a Panel contain itself: "+this.toString()),D.k("Cannot make a Panel indirectly contain itself: "+this.toString()+" already contains "+b.toString());var c=b.R;null!==c&&c!==this&&D.k("Cannot add a GraphObject that already belongs to another Panel to this Panel: "+b.toString()+", already contained by "+c.toString()+", cannot be shared by this Panel: "+
this.toString());this.ca!==Rl||b instanceof z||D.k("Can only add Shapes to a Grid Panel, not: "+b);this.ca!==pp||b instanceof z||b instanceof pa||D.k("Can only add Shapes or TextBlocks to a Graduated Panel, not: "+b);b.Cm(this);b.nn=null;if(null!==this.al){var d=b.data;null!==d&&"object"===typeof d&&(null===this.Ig&&(this.Ig=new oa(Object,x)),this.Ig.add(d,b))}var e=this.xa,d=-1;if(c===this){for(var g=-1,h=this.xa.o,k=h.length,l=0;l<k;l++)if(h[l]===b){g=l;break}if(-1!==g){if(g===a||g+1>=e.count&&
a>=e.count)return;e.od(g);d=g}else D.k("element "+b.toString()+" has panel "+c.toString()+" but is not contained by it.")}if(0>a||a>e.count)a=e.count;e.ae(a,b);if(0===a||b.Ue)this.Wl=null;this.K();b.K();null!==b.wd?this.Xk=!0:b instanceof x&&!0===b.Xk&&(this.Xk=!0);this.nk=null;c=this.Y;null!==c&&(c.Rl=null,c.vk=NaN,this.Xk&&c instanceof G&&(c.Xk=!0),c.Xk&&c instanceof G&&(c.ze=null),e=this.g,null!==e&&e.la.jb||(-1!==d&&c.nd(sg,"elements",this,b,null,d,null),c.nd(rg,"elements",this,null,b,null,a),
this.Au()||hq(this,b,!1)))};D.defineProperty(x,{Xk:null},function(){return 0!==(this.T&8388608)},function(a){0!==(this.T&8388608)!==a&&(this.T^=8388608)});function iq(a,b){a.T=b?a.T|16777216:a.T&-16777217}x.prototype.remove=x.prototype.remove=function(a){D.l(a,P,x,"remove:element");for(var b=this.xa.o,c=b.length,d=-1,e=0;e<c;e++)if(b[e]===a){d=e;break}-1!==d&&this.xf(d,!0)};x.prototype.removeAt=x.prototype.od=function(a){v&&D.p(a,x,"removeAt:idx");0<=a&&this.xf(a,!0)};
x.prototype.xf=function(a,b){var c=this.xa,d=c.ea(a);d.nn=null;d.Cm(null);if(null!==this.Ig){var e=d.data;"object"===typeof e&&this.Ig.remove(e)}c.od(a);jm(this,!1);this.K();this.Wl===d&&(this.Wl=null);this.nk=null;var g=this.Y;null!==g&&(g.Rl=null,g.vk=NaN,g.oe(),g instanceof G&&(d instanceof x?gp(d,d,function(a,c){dp(g,c,b)}):dp(g,d,b)),c=this.g,null!==c&&c.la.jb||g.nd(sg,"elements",this,d,null,a,null))};D.w(x,{Kq:"rowCount"},function(){return void 0===this.ke?0:this.ke.length});
x.prototype.getRowDefinition=x.prototype.Zd=function(a){v&&D.p(a,x,"getRowDefinition:idx");0>a&&D.ua(a,">= 0",x,"getRowDefinition:idx");a=Math.round(a);var b=this.ke;if(void 0===b[a]){var c=new jh;c.Cm(this);c.Ke=!0;c.index=a;b[a]=c}return b[a]};x.prototype.removeRowDefinition=x.prototype.FG=function(a){v&&D.p(a,x,"removeRowDefinition:idx");0>a&&D.ua(a,">= 0",x,"removeRowDefinition:idx");a=Math.round(a);var b=this.ke;this.nd(sg,"coldefs",this,b[a],null,a,null);b[a]&&delete b[a];this.K()};
D.w(x,{cq:"columnCount"},function(){return void 0===this.he?0:this.he.length});x.prototype.getColumnDefinition=x.prototype.Yd=function(a){v&&D.p(a,x,"getColumnDefinition:idx");0>a&&D.ua(a,">= 0",x,"getColumnDefinition:idx");a=Math.round(a);var b=this.he;if(void 0===b[a]){var c=new jh;c.Cm(this);c.Ke=!1;c.index=a;b[a]=c}return b[a]};
x.prototype.removeColumnDefinition=x.prototype.DG=function(a){v&&D.p(a,x,"removeColumnDefinition:idx");0>a&&D.ua(a,">= 0",x,"removeColumnDefinition:idx");a=Math.round(a);var b=this.he;this.nd(sg,"coldefs",this,b[a],null,a,null);b[a]&&delete b[a];this.K()};
D.defineProperty(x,{hK:"rowSizing"},function(){return void 0===this.qn?op:this.qn},function(a){if(void 0!==this.qn){var b=this.qn;b!==a&&(a!==op&&a!==Vp&&D.k("Panel.rowSizing must be RowColumnDefinition.ProportionalExtra or RowColumnDefinition.None, not: "+a),this.qn=a,this.K(),this.i("rowSizing",b,a))}});
D.defineProperty(x,{eI:"columnSizing"},function(){return void 0===this.Pm?op:this.Pm},function(a){if(void 0!==this.Pm){var b=this.Pm;b!==a&&(a!==op&&a!==Vp&&D.k("Panel.columnSizing must be RowColumnDefinition.ProportionalExtra or RowColumnDefinition.None, not: "+a),this.Pm=a,this.K(),this.i("columnSizing",b,a))}});
D.defineProperty(x,{kH:"topIndex"},function(){return void 0===this.uj?0:this.uj},function(a){if(void 0!==this.uj){var b=this.uj;b!==a&&((!isFinite(a)||0>a)&&D.k("Panel.topIndex must be greater than zero and a real number, not: "+a),this.uj=a,this.K(),this.i("topIndex",b,a))}});
D.defineProperty(x,{jG:"leftIndex"},function(){return void 0===this.fj?0:this.fj},function(a){if(void 0!==this.fj){var b=this.fj;b!==a&&((!isFinite(a)||0>a)&&D.k("Panel.leftIndex must be greater than zero and a real number, not: "+a),this.fj=a,this.K(),this.i("leftIndex",b,a))}});x.prototype.findRowForLocalY=function(a){if(0>a||this.type!==fa)return-1;for(var b=0,c=this.ke,d=c.length,e=this.uj;e<d;e++){var g=c[e];if(void 0!==g&&(b+=g.total,a<b))break}return e};
x.prototype.findColumnForLocalX=function(a){if(0>a||this.type!==fa)return-1;for(var b=0,c=this.he,d=c.length,e=this.fj;e<d;e++){var g=c[e];if(void 0!==g&&(b+=g.total,a<b))break}return e};x.prototype.graduatedPointForValue=function(a,b){void 0===b&&(b=new N(NaN,NaN));if(this.type!==pp)return b.n(NaN,NaN),b;a=Math.min(Math.max(a,this.Wk),this.WA);var c=(a-this.Wk)/this.uu,d=this.Xd();d.uf.SI(c,b);c=D.Rf();c.set(d.transform);b.transform(c);D.Je(c);return b};
x.prototype.graduatedValueForPoint=function(a){if(this.type!==pp)return NaN;var b=this.Xd(),c=b.uf,d=D.Rf();d.set(b.transform);d.Ux();a.transform(d);D.Je(d);return c.NI(a)*this.uu+this.Wk};
D.defineProperty(x,{data:"data"},function(){return this.Pd},function(a){var b=this.Pd;if(b!==a){var c=this instanceof F&&!(this instanceof da);c&&D.h(a,"object",x,"data");Sh(this);this.Pd=a;var d=this.g;null!==d&&(c?this instanceof J?(null!==b&&d.dk.remove(b),null!==a&&d.dk.add(a,this)):this instanceof F&&(null!==b&&d.Vi.remove(b),null!==a&&d.Vi.add(a,this)):(c=this.R,null!==c&&null!==c.Ig&&(null!==b&&c.Ig.remove(b),null!==a&&c.Ig.add(a,this))));this.i("data",b,a);null!==d&&d.la.jb||null!==a&&this.Ob()}});
D.defineProperty(x,{Gu:"itemIndex"},function(){return this.os},function(a){var b=this.os;b!==a&&(this.os=a,this.i("itemIndex",b,a))});function hp(a){a=a.vl;return null!==a&&a.J}
function Sh(a){var b=a.vl;if(null===b)null!==a.data&&D.k("Template cannot have .data be non-null: "+a),a.vl=b=new K(ph);else if(b.J)return;var c=new K(P);iq(a,!1);gp(a,a,function(a,d){var e=d.Dc;if(null!==e)for(cp(d,!1),e=e.j;e.next();){var g=e.value;g.mode===rh&&cp(d,!0);var h=g.Sq;null!==h&&("/"===h&&iq(a,!0),h=sh(g,a,d),null!==h&&(c.add(h),null===h.Kp&&(h.Kp=new K(ph)),h.Kp.add(g)));b.add(g)}if(d instanceof x&&d.type===fa){if(0<d.ke.length)for(e=d.ke,g=e.length,h=0;h<g;h++){var k=e[h];if(void 0!==
k&&null!==k.Dc)for(var l=k.Dc.j;l.next();){var t=l.value;t.Tg=k;t.Gt=2;t.Rp=k.index;b.add(t)}}if(0<d.he.length)for(e=d.he,g=e.length,h=0;h<g;h++)if(k=e[h],void 0!==k&&null!==k.Dc)for(l=k.Dc.j;l.next();)t=l.value,t.Tg=k,t.Gt=1,t.Rp=k.index,b.add(t)}});for(var d=c.j;d.next();){var e=d.value;if(null!==e.Kp){cp(e,!0);for(var g=e.Kp.j;g.next();){var h=g.value;null===e.Dc&&(e.Dc=new K(ph));e.Dc.add(h)}}e.Kp=null}for(d=b.j;d.next();)if(e=d.value,g=e.Tg,null!==g){e.Tg=null;var k=e.jv,l=k.indexOf(".");0<l&&
g instanceof x&&(h=k.substring(0,l),k=k.substr(l+1),l=g.Hd(h),null!==l?(g=l,e.jv=k):D.trace('Warning: unable to find GraphObject named "'+h+'" for Binding: '+e.toString()));g instanceof jh?(e.Fm=D.Jd(g.R),g.R.Jo=e.Fm):(e.Fm=D.Jd(g),g.Jo=e.Fm)}b.freeze();a instanceof F&&(a.pe()&&a.Re(),v&&!jq&&gp(a,a,function(a,c){if(c instanceof x&&(c.type===Ol||c.type===Wj||c.type===pp)&&1>=c.elements.count&&!(c instanceof F)){if(1===c.elements.count){var d=null!==c.al;if(!d)for(var e=b.j;e.next();)if("itemArray"===
e.value.jv){d=!0;break}}d||(D.trace("Auto, Spot, or Graduated Panel should not have zero or one elements: "+c.toString()+" in "+a.toString()),jq=!0)}}))}var jq=!1;x.prototype.copyTemplate=function(){var a=this.copy();a.FK(function(a){a instanceof x&&(a.vl=null,a.Pd=null);var c=a.Dc;null!==c&&(a.Dc=null,c.each(function(c){a.bind(c.copy())}))});return a};
x.prototype.updateTargetBindings=x.prototype.Ob=function(a){var b=this.vl;if(null!==b)for(void 0===a&&(a=""),b=b.j;b.next();){var c=b.value,d=c.aH;if(""===a||""===d||d===a)if(d=c.jv,null!==c.kI||""!==d){var d=this.data,e=c.Sq;if(null!==e)d=""===e?this:"/"===e?this:"."===e?this:".."===e?this:this.Hd(e);else{var g=this.g;null!==g&&c.ay&&(d=g.da.bl)}if(null===d)v&&D.trace("Binding error: missing GraphObject named "+e+" in "+this.toString());else{var g=this,h=c.Fm;if(-1!==h){if(g=this.Lx(h),null===g)continue}else null!==
c.Tg&&(g=c.Tg);"/"===e?d=g.Y:"."===e?d=g:".."===e&&(d=g.R);e=c.Gt;if(0!==e){if(!(g instanceof x))continue;h=g;1===e?g=h.Yd(c.Rp):2===e&&(g=h.Zd(c.Rp))}void 0!==g&&c.qH(g,d)}}}};
D.defineProperty(x,{al:"itemArray"},function(){return this.cj},function(a){var b=this.cj;if(b!==a){v&&null!==a&&!D.isArray(a)&&D.k("Panel.itemArray must be an Array-like object or null, not: "+a);var c=this.g;null!==c&&null!==b&&bn(c,this);this.cj=a;null!==c&&null!==a&&Ym(c,this);this.i("itemArray",b,a);null!==c&&c.la.jb||this.CB()}});function un(a){return a.type===Wj||a.type===Ol||a.type===rj||a.type===fa&&0<a.xa.length&&(a=a.xa.ea(0),a.Ue&&a instanceof x&&(a.type===Ro||a.type===So))?!0:!1}
x.prototype.rebuildItemElements=x.prototype.CB=function(){var a=0;for(un(this)&&(a=1);this.xa.length>a;)this.xf(this.xa.length-1,!1);a=this.al;if(null!==a)for(var b=D.cb(a),c=0;c<b;c++)tn(this,D.La(a,c),c)};x.prototype.findItemPanelForData=x.prototype.HI=function(a){if(void 0===a||null===a||null===this.Ig)return null;D.h(a,"object",x,"findItemPanelForData");return this.Ig.oa(a)};
function tn(a,b,c){if(!(void 0===b||null===b||0>c)){var d;d=kq(a,b);var e=a.sJ,g=null;null!==e&&(g=e.oa(d));null===g&&(lq||(lq=!0,D.trace('No item template Panel found for category "'+d+'" on '+a),D.trace("  Using default item template."),d=new x,e=new pa,e.bind(new ph("text","",ia)),d.add(e),mq=d),g=mq);d=g;null!==d&&(Sh(d),d=d.copy(),0!==(d.T&16777216)&&(e=a.rm(),null!==e&&iq(e,!0)),"object"===typeof b&&(null===a.Ig&&(a.Ig=new oa(Object,x)),a.Ig.add(b,d)),e=c,un(a)&&e++,a.ae(e,d),d.Pd=b,vn(a,e,
c),d.Pd=null,d.data=b)}}function vn(a,b,c){for(a=a.xa;b<a.length;){var d=a.ea(b);if(d instanceof x){var e=b,g=c;d.type===Ro?d.Sb=e:d.type===So&&(d.column=e);d.Gu=g}b++;c++}}
D.defineProperty(x,{UL:"itemTemplate"},function(){return null===this.Jg?null:this.Jg.oa("")},function(a){if(null===this.Jg){if(null===a)return;this.Jg=new oa("string",x)}var b=this.Jg.oa("");b!==a&&(D.l(a,x,x,"itemTemplate"),(a instanceof F||a.Ue)&&D.k("Panel.itemTemplate must not be a Part or be Panel.isPanelMain: "+a),this.Jg.add("",a),this.i("itemTemplate",b,a),a=this.g,null!==a&&a.la.jb||this.CB())});
D.defineProperty(x,{sJ:"itemTemplateMap"},function(){return this.Jg},function(a){var b=this.Jg;if(b!==a){D.l(a,oa,x,"itemTemplateMap");for(var c=a.j;c.next();){var d=c.value;(d instanceof F||d.Ue)&&D.k("Template in Panel.itemTemplateMap must not be a Part or be Panel.isPanelMain: "+d)}this.Jg=a;this.i("itemTemplateMap",b,a);a=this.g;null!==a&&a.la.jb||this.CB()}});
D.defineProperty(x,{fG:"itemCategoryProperty"},function(){return this.op},function(a){var b=this.op;b!==a&&("string"!==typeof a&&"function"!==typeof a&&D.jc(a,"string or function",x,"itemCategoryProperty"),this.op=a,this.i("itemCategoryProperty",b,a))});
function kq(a,b){if(null===b)return"";var c=a.op,d="";if("function"===typeof c)d=c(b);else if("string"===typeof c&&"object"===typeof b){if(""===c)return"";d=D.wb(b,c)}else return"";if(void 0===d)return"";if("string"===typeof d)return d;D.k("Panel.getCategoryForItemData found a non-string category for "+b+": "+d);return""}var lq=!1,mq=null;
D.defineProperty(x,{Zn:"isAtomic"},function(){return 0!==(this.T&1048576)},function(a){var b=0!==(this.T&1048576);b!==a&&(D.h(a,"boolean",x,"isAtomic"),this.T^=1048576,this.i("isAtomic",b,a))});D.defineProperty(x,{vq:"isClipping"},function(){return 0!==(this.T&2097152)},function(a){var b=0!==(this.T&2097152);b!==a&&(D.h(a,"boolean",x,"isClipping"),this.T^=2097152,this.K(),this.i("isClipping",b,a))});
D.defineProperty(x,{cG:"isOpposite"},function(){return 0!==(this.T&33554432)},function(a){var b=0!==(this.T&33554432);b!==a&&(D.h(a,"boolean",x,"isOpposite"),this.T^=33554432,this.K(),this.i("isOpposite",b,a))});D.defineProperty(x,{isEnabled:"isEnabled"},function(){return 0!==(this.T&4194304)},function(a){var b=0!==(this.T&4194304);if(b!==a){D.h(a,"boolean",x,"isEnabled");var c=null===this.R||this.R.Au();this.T^=4194304;this.i("isEnabled",b,a);b=this.g;null!==b&&b.la.jb||c&&hq(this,this,a)}});
function hq(a,b,c){var d=b.rF;null!==d&&d(b,c);if(b instanceof x){b=b.xa.o;for(var d=b.length,e=0;e<d;e++){var g=b[e];c&&g instanceof x&&!g.isEnabled||hq(a,g,c)}}}D.defineProperty(x,{LK:"alignmentFocusName"},function(){return this.ak},function(a){var b=this.ak;b!==a&&(v&&D.h(a,"string",x,"alignmentFocusName"),this.ak=a,this.K(),this.i("alignmentFocusName",b,a))});
function jh(){D.vc(this);this.Dh=null;this.jw=!0;this.Ec=0;this.tf=NaN;this.xk=0;this.wk=Infinity;this.te=Vc;this.tb=this.Ya=0;this.Dc=null;this.yt=nq;this.Rg=wo;this.tt=this.Bk=null;this.ut=NaN;this.Lb=this.qi=null;this.wr=!1}D.ka("RowColumnDefinition",jh);
jh.prototype.copy=function(){var a=new jh;a.jw=this.jw;a.Ec=this.Ec;a.tf=this.tf;a.xk=this.xk;a.wk=this.wk;a.te=this.te;a.Ya=this.Ya;a.tb=this.tb;a.Rg=this.Rg;a.yt=this.yt;a.Bk=null===this.Bk?null:this.Bk.V();a.tt=this.tt;a.ut=this.ut;a.qi=null;null!==this.qi&&(a.qi=D.jm(this.qi));a.Lb=this.Lb;a.wr=this.wr;a.Dc=this.Dc;return a};
jh.prototype.dq=function(a){D.l(a,jh,jh,"copyFrom:pd");a.Ke?this.height=a.height:this.width=a.width;this.Th=a.Th;this.re=a.re;this.alignment=a.alignment;this.stretch=a.stretch;this.gv=a.gv;this.Bk=null===a.Bk?null:a.Bk.V();this.Mq=a.Mq;this.Nq=a.Nq;this.qi=null;a.qi&&(this.qi=D.jm(a.qi));this.background=a.background;this.GA=a.GA;this.Dc=a.Dc};jh.prototype.qc=function(a){a.Oe===jh?this.gv=a:D.Yj(this,a)};
jh.prototype.toString=function(){return"RowColumnDefinition "+(this.Ke?"(Row ":"(Column ")+this.index+") #"+D.Jd(this)};var nq;jh.Default=nq=D.s(jh,"Default",0);var Vp;jh.None=Vp=D.s(jh,"None",1);var op;jh.ProportionalExtra=op=D.s(jh,"ProportionalExtra",2);jh.prototype.Cm=function(a){this.Dh=a};
jh.prototype.computeEffectiveSpacingTop=jh.prototype.ZE=function(){var a=0;if(0!==this.index){var b=this.Dh,c=this.Mq;null===c&&null!==b&&(c=this.Ke?b.Wi:b.ci);null!==c&&(a=this.Nq,isNaN(a)&&(a=null!==b?this.Ke?b.ei:b.di:0))}b=this.VG;if(null===b)if(b=this.Dh,null!==b)b=b.ek;else return a;return a+(this.Ke?b.top:b.left)};
jh.prototype.computeEffectiveSpacing=jh.prototype.kf=function(){var a=0;if(0!==this.index){var b=this.Dh,c=this.Mq;null===c&&null!==b&&(c=this.Ke?b.Wi:b.ci);null!==c&&(a=this.Nq,isNaN(a)&&(a=null!==b?this.Ke?b.ei:b.di:0))}b=this.VG;if(null===b)if(b=this.Dh,null!==b)b=b.ek;else return a;return a+(this.Ke?b.top+b.bottom:b.left+b.right)};
jh.prototype.ud=function(a,b,c,d,e){var g=this.Dh;if(null!==g&&(g.nd(fg,a,this,b,c,d,e),null!==this.Dc&&(b=g.g,null!==b&&!b.Xe&&(g=g.rm(),null!==g&&(b=g.data,null!==b)))))for(c=this.Dc.j;c.next();)c.value.Oy(this,b,a,g)};D.w(jh,{R:"panel"},function(){return this.Dh});D.defineProperty(jh,{Ke:"isRow"},function(){return this.jw},function(a){this.jw=a});D.defineProperty(jh,{index:"index"},function(){return this.Ec},function(a){this.Ec=a});
D.defineProperty(jh,{height:"height"},function(){return this.tf},function(a){var b=this.tf;b!==a&&(v&&D.h(a,"number",jh,"height"),0>a&&D.ua(a,">= 0",jh,"height"),this.tf=a,this.gb=this.Ya,null!==this.R&&this.R.K(),this.ud("height",b,a))});D.defineProperty(jh,{width:"width"},function(){return this.tf},function(a){var b=this.tf;b!==a&&(v&&D.h(a,"number",jh,"width"),0>a&&D.ua(a,">= 0",jh,"width"),this.tf=a,this.gb=this.Ya,null!==this.R&&this.R.K(),this.ud("width",b,a))});
D.defineProperty(jh,{Th:"minimum"},function(){return this.xk},function(a){var b=this.xk;b!==a&&(v&&D.h(a,"number",jh,"minimum"),(0>a||!isFinite(a))&&D.ua(a,">= 0",jh,"minimum"),this.xk=a,this.gb=this.Ya,null!==this.R&&this.R.K(),this.ud("minimum",b,a))});D.defineProperty(jh,{re:"maximum"},function(){return this.wk},function(a){var b=this.wk;b!==a&&(v&&D.h(a,"number",jh,"maximum"),0>a&&D.ua(a,">= 0",jh,"maximum"),this.wk=a,this.gb=this.Ya,null!==this.R&&this.R.K(),this.ud("maximum",b,a))});
D.defineProperty(jh,{alignment:"alignment"},function(){return this.te},function(a){var b=this.te;b.O(a)||(v&&D.l(a,S,jh,"alignment"),this.te=a.V(),null!==this.R&&this.R.K(),this.ud("alignment",b,a))});D.defineProperty(jh,{stretch:"stretch"},function(){return this.Rg},function(a){var b=this.Rg;b!==a&&(v&&D.Da(a,P,jh,"stretch"),this.Rg=a,null!==this.R&&this.R.K(),this.ud("stretch",b,a))});
D.defineProperty(jh,{VG:"separatorPadding"},function(){return this.Bk},function(a){"number"===typeof a?a=new Lb(a):null!==a&&v&&D.l(a,Lb,jh,"separatorPadding");var b=this.Bk;null!==a&&null!==b&&b.O(a)||(null!==a&&(a=a.V()),this.Bk=a,null!==this.R&&this.R.K(),this.ud("separatorPadding",b,a))});
D.defineProperty(jh,{Mq:"separatorStroke"},function(){return this.tt},function(a){var b=this.tt;b!==a&&(null===a||"string"===typeof a||a instanceof Ba)&&(a instanceof Ba&&a.freeze(),this.tt=a,null!==this.R&&this.R.K(),this.ud("separatorStroke",b,a))});D.defineProperty(jh,{Nq:"separatorStrokeWidth"},function(){return this.ut},function(a){var b=this.ut;b!==a&&(this.ut=a,null!==this.R&&this.R.K(),this.ud("separatorStrokeWidth",b,a))});
D.defineProperty(jh,{zM:"separatorDashArray"},function(){return this.qi},function(a){var b=this.qi;if(b!==a){null===a||Array.isArray(a)||D.jc(a,"Array",jh,"separatorDashArray:value");if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var g=a[e];"number"===typeof g&&0<=g&&isFinite(g)||D.k("separatorDashArray value "+g+" at index "+e+" must be a positive number or zero.");d+=g}if(0===d){if(null===b)return;a=null}}this.qi=a;null!==this.R&&this.R.ra();this.ud("separatorDashArray",b,a)}});
D.defineProperty(jh,{background:"background"},function(){return this.Lb},function(a){var b=this.Lb;b!==a&&(null===a||"string"===typeof a||a instanceof Ba)&&(a instanceof Ba&&a.freeze(),this.Lb=a,null!==this.R&&this.R.ra(),this.ud("background",b,a))});D.defineProperty(jh,{GA:"coversSeparators"},function(){return this.wr},function(a){var b=this.wr;b!==a&&(D.h(a,"boolean",jh,"coversSeparators"),this.wr=a,null!==this.R&&this.R.ra(),this.ud("coversSeparators",b,a))});
D.defineProperty(jh,{gv:"sizing"},function(){return this.yt},function(a){var b=this.yt;b!==a&&(v&&D.Da(a,jh,jh,"sizing"),this.yt=a,null!==this.R&&this.R.K(),this.ud("sizing",b,a))});function Up(a){if(a.gv===nq){var b=a.Dh;return a.Ke?b.hK:b.eI}return a.gv}D.defineProperty(jh,{gb:"actual"},function(){return this.Ya},function(a){this.Ya=isNaN(this.tf)?Math.max(Math.min(this.wk,a),this.xk):Math.max(Math.min(this.wk,this.tf),this.xk)});
D.defineProperty(jh,{total:"total"},function(){return this.Ya+this.kf()},function(a){this.Ya=isNaN(this.tf)?Math.max(Math.min(this.wk,a),this.xk):Math.max(Math.min(this.wk,this.tf),this.xk);this.Ya=Math.max(0,this.Ya-this.kf())});D.defineProperty(jh,{position:"position"},function(){return this.tb},function(a){this.tb=a});
jh.prototype.bind=jh.prototype.bind=function(a){a.Tg=this;var b=this.R;if(null!==b){var c=b.rm();null!==c&&hp(c)&&D.k("Cannot add a Binding to a RowColumnDefinition that is already frozen: "+a+" on "+b)}null===this.Dc&&(this.Dc=new K(ph));this.Dc.add(a)};
function z(){P.call(this);this.bg=this.ab=null;this.$o="None";this.Vr=wo;this.zc=this.Rd="black";this.Sg=1;this.Np="butt";this.Pp="miter";this.yn=10;this.Op=null;this.Gf=0;this.yi=this.xi=Vc;this.Ws=this.Vs=NaN;this.ds=!1;this.Ys=null;this.cp=this.Tp="None";this.Gg=1;this.Fg=0;this.Eg=1}D.Sa(z,P);D.ka("Shape",z);
z.prototype.cloneProtected=function(a){P.prototype.cloneProtected.call(this,a);a.ab=this.ab;a.$o=this.$o;a.Vr=this.Vr;a.bg=this.bg;a.Rd=this.Rd;a.zc=this.zc;a.Sg=this.Sg;a.Np=this.Np;a.Pp=this.Pp;a.yn=this.yn;null!==this.Op&&(a.Op=D.jm(this.Op));a.Gf=this.Gf;a.xi=this.xi.V();a.yi=this.yi.V();a.Vs=this.Vs;a.Ws=this.Ws;a.ds=this.ds;a.Ys=this.Ys;a.Tp=this.Tp;a.cp=this.cp;a.Gg=this.Gg;a.Fg=this.Fg;a.Eg=this.Eg};
z.prototype.qc=function(a){a===Yj||a===ak||a===bk||a===wo?this.TA=a:P.prototype.qc.call(this,a)};z.prototype.toString=function(){return"Shape("+("None"!==this.Jb?this.Jb:"None"!==this.Tq?this.Tq:this.RA)+")#"+D.Jd(this)};
function oq(a,b,c,d){var e=c.length;if(!(4>e)){for(var g=d.Fa,h=Math.max(1,g.width),g=g.height,k=c[0],l=c[1],m=0,n=0,p=0,q=0,r=0,s=0,u=q=0,t=D.nb(),y=2;y<e;y+=2)m=c[y],n=c[y+1],p=m-k,q=n-l,0===p&&(p=.001),r=q/p,s=Math.atan2(q,p),q=Math.sqrt(p*p+q*q),t.push([p,s,r,q]),u+=q,k=m,l=n;k=c[0];l=c[1];p=d.Fa.width;d instanceof z&&(p-=d.mb);1>p&&(p=1);for(var e=c=p,m=h/2,n=0===m?!1:!0,y=0,q=t[y],p=q[0],s=q[1],r=q[2],q=q[3],w=0;.1<=u;){0===w&&(n?(e=c,e-=m,u-=m,n=!1):e=c,0===e&&(e=1));if(e>u){D.ya(t);return}e>
q?(w=e-q,e=q):w=0;var B=Math.sqrt(e*e/(1+r*r));0>p&&(B=-B);k+=B;l+=r*B;a.translate(k,l);a.rotate(s);a.translate(-(h/2),-(g/2));0===w&&d.Rk(a,b);a.translate(h/2,g/2);a.rotate(-s);a.translate(-k,-l);u-=e;q-=e;if(0!==w){y++;if(y===t.length){D.ya(t);return}q=t[y];p=q[0];s=q[1];r=q[2];q=q[3];e=w}}D.ya(t)}}
z.prototype.Rk=function(a,b){if(null!==this.zc||null!==this.Rd){null!==this.Rd&&Vo(this,a,this.Rd,!0,!1);null!==this.zc&&Vo(this,a,this.zc,!1,!1);var c=this.Sg;if(0===c){var d=this.Y;d instanceof da&&d.type===rj&&"Selection"===d.Gd&&d.Bb instanceof z&&d.Hf.Xd()===d.Bb&&(c=d.Bb.mb)}a.lineWidth=c;a.lineJoin=this.Pp;a.lineCap=this.Np;a.miterLimit=this.yn;var e=!1;this.Y&&b.Gj("drawShadows")&&(e=this.Y.$k);var g=!0;null!==this.zc&&null===this.Rd&&(g=!1);var d=!1,h=!0,k=this.dH;null!==k&&(d=!0,h=a.NA(k,
this.Gf));var l=this.ab;if(null!==l){if(l.ca===sf)a.beginPath(),d&&!h?Go(a,l.yc,l.Ic,l.Cb,l.Ib,k,this.Gf):(a.moveTo(l.yc,l.Ic),a.lineTo(l.Cb,l.Ib)),null!==this.Rd&&a.Yg(this.Rd),0!==c&&null!==this.zc&&a.Vj();else if(l.ca===tf){var m=l.yc,n=l.Ic,p=l.Cb,q=l.Ib,l=Math.min(m,p),r=Math.min(n,q),m=Math.abs(p-m),n=Math.abs(q-n);null!==this.Rd&&(a.beginPath(),a.rect(l,r,m,n),a.Yg(this.Rd));if(null!==this.zc){var s=p=0,u=0;g&&e&&(p=a.shadowOffsetX,s=a.shadowOffsetY,u=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=
0,a.shadowBlur=0);d&&!h?(h=D.nb(),h.push(l),h.push(r),h.push(l+m),h.push(r),h.push(l+m),h.push(r+n),h.push(l),h.push(r+n),h.push(l),h.push(r),a.beginPath(),pq(a,h,k,this.Gf),a.Vj(),D.ya(h)):0!==c&&(a.beginPath(),a.rect(l,r,m,n),a.Vj());g&&e&&(a.shadowOffsetX=p,a.shadowOffsetY=s,a.shadowBlur=u)}}else if(l.ca===uf)m=l.yc,n=l.Ic,p=l.Cb,q=l.Ib,l=Math.abs(p-m)/2,r=Math.abs(q-n)/2,m=Math.min(m,p)+l,n=Math.min(n,q)+r,a.beginPath(),a.moveTo(m,n-r),a.bezierCurveTo(m+Jd*l,n-r,m+l,n-Jd*r,m+l,n),a.bezierCurveTo(m+
l,n+Jd*r,m+Jd*l,n+r,m,n+r),a.bezierCurveTo(m-Jd*l,n+r,m-l,n+Jd*r,m-l,n),a.bezierCurveTo(m-l,n-Jd*r,m-Jd*l,n-r,m,n-r),a.closePath(),null!==this.Rd&&a.Yg(this.Rd),d&&!h&&(h=D.nb(),Be(m,n-r,m+Jd*l,n-r,m+l,n-Jd*r,m+l,n,.5,h),Be(m+l,n,m+l,n+Jd*r,m+Jd*l,n+r,m,n+r,.5,h),Be(m,n+r,m-Jd*l,n+r,m-l,n+Jd*r,m-l,n,.5,h),Be(m-l,n,m-l,n-Jd*r,m-Jd*l,n-r,m,n-r,.5,h),a.beginPath(),pq(a,h,k,this.Gf),D.ya(h)),0!==c&&null!==this.zc&&(g&&e?(p=a.shadowOffsetX,s=a.shadowOffsetY,u=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=
0,a.shadowBlur=0,a.Vj(),a.shadowOffsetX=p,a.shadowOffsetY=s,a.shadowBlur=u):a.Vj());else if(l.ca===We){r=l.hk;n=r.length;for(q=0;q<n;q++){m=r.o[q];a.beginPath();a.moveTo(m.ma,m.ja);for(var p=m.Eb.o,s=p.length,t=null,u=0;u<s;u++){var y=p[u];switch(y.ca){case Ef:a.moveTo(y.G,y.H);break;case wf:a.lineTo(y.G,y.H);break;case Ff:a.bezierCurveTo(y.Sd,y.hf,y.Eh,y.Pg,y.Cb,y.Ib);break;case Sf:a.quadraticCurveTo(y.Sd,y.hf,y.Cb,y.Ib);break;case Tf:if(y.radiusX===y.radiusY)t=Math.PI/180,a.arc(y.Sd,y.hf,y.radiusX,
y.Me*t,(y.Me+y.Df)*t,0>y.Df);else{var t=Vf(y,m),w=t.length;if(0===w){a.lineTo(y.pa,y.va);break}for(var B=0;B<w;B++){var A=t[B];0===B&&a.lineTo(A[0],A[1]);a.bezierCurveTo(A[2],A[3],A[4],A[5],A[6],A[7])}}break;case Uf:B=w=0;if(null!==t&&t.type===Tf){t=Vf(t,m);A=t.length;if(0===A){a.lineTo(y.pa,y.va);break}t=t[A-1]||null;null!==t&&(w=t[6],B=t[7])}else w=null!==t?t.G:m.ma,B=null!==t?t.H:m.ja;t=Wf(y,m,w,B);w=t.length;if(0===w){a.lineTo(y.pa,y.va);break}for(B=0;B<w;B++)A=t[B],a.bezierCurveTo(A[2],A[3],
A[4],A[5],A[6],A[7]);break;default:D.k("Segment not of valid type: "+y.ca)}y.hi&&a.closePath();t=y}e?(u=s=p=0,m.mp?(!0===m.Xm&&null!==this.Rd?(a.Yg(this.Rd),g=!0):g=!1,0!==c&&null!==this.zc&&(g&&(p=a.shadowOffsetX,s=a.shadowOffsetY,u=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),d&&!h||a.Vj(),g&&(a.shadowOffsetX=p,a.shadowOffsetY=s,a.shadowBlur=u))):(g&&(p=a.shadowOffsetX,s=a.shadowOffsetY,u=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),!0===m.Xm&&null!==this.Rd&&
a.Yg(this.Rd),0!==c&&null!==this.zc&&(d&&!h||a.Vj()),g&&(a.shadowOffsetX=p,a.shadowOffsetY=s,a.shadowBlur=u))):(!0===m.Xm&&null!==this.Rd&&a.Yg(this.Rd),0===c||null===this.zc||d&&!h||a.Vj())}if(d&&!h)for(c=g,g=l.hk,h=g.length,l=0;l<h;l++){r=g.o[l];a.beginPath();n=D.nb();n.push(r.ma);n.push(r.ja);q=r.ma;m=r.ja;p=q;s=m;u=r.Eb.o;y=u.length;for(t=0;t<y;t++){w=u[t];switch(w.ca){case Ef:pq(a,n,k,this.Gf);n.length=0;n.push(w.G);n.push(w.H);q=w.G;m=w.H;p=q;s=m;break;case wf:n.push(w.G);n.push(w.H);q=w.G;
m=w.H;break;case Ff:Be(q,m,w.Sd,w.hf,w.Eh,w.Pg,w.Cb,w.Ib,.5,n);q=w.G;m=w.H;break;case Sf:Fe(q,m,w.Sd,w.hf,w.Cb,w.Ib,.5,n);q=w.G;m=w.H;break;case Tf:B=Vf(w,r);A=B.length;if(0===A){n.push(w.pa);n.push(w.va);q=w.pa;m=w.va;break}for(var O=0;O<A;O++){var H=B[O];Be(q,m,H[2],H[3],H[4],H[5],H[6],H[7],.5,n);q=H[6];m=H[7]}break;case Uf:B=Wf(w,r,q,m);A=B.length;if(0===A){n.push(w.pa);n.push(w.va);q=w.pa;m=w.va;break}for(O=0;O<A;O++)H=B[O],Be(q,m,H[2],H[3],H[4],H[5],H[6],H[7],.5,n),q=H[6],m=H[7];break;default:D.k("Segment not of valid type: "+
w.ca)}w.hi&&(n.push(p),n.push(s),pq(a,n,k,this.Gf))}pq(a,n,k,this.Gf);D.ya(n);null!==this.zc&&(q=n=r=0,c&&e&&(r=a.shadowOffsetX,n=a.shadowOffsetY,q=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),a.Vj(),c&&e&&(a.shadowOffsetX=r,a.shadowOffsetY=n,a.shadowBlur=q))}}d&&a.IA();if(null!==this.ty){d=this.ty;Gk(d,Infinity,Infinity);k=d.Fa;d.rc(0,0,k.width,k.height);c=this.uf;a.save();a.beginPath();k=D.nb();if(c.type===sf)k.push(c.ma),k.push(c.ja),k.push(c.G),k.push(c.H),oq(a,b,k,d);else if(c.type===
We)for(h=c.nc.j;h.next();){l=h.value;k.length=0;k.push(l.ma);k.push(l.ja);g=l.ma;r=l.ja;n=g;q=r;m=l.Eb.o;p=m.length;for(s=0;s<p;s++){u=m[s];switch(u.ca){case Ef:oq(a,b,k,d);k.length=0;k.push(u.G);k.push(u.H);g=u.G;r=u.H;n=g;q=r;break;case wf:k.push(u.G);k.push(u.H);g=u.G;r=u.H;break;case Ff:Be(g,r,u.Sd,u.hf,u.Eh,u.Pg,u.Cb,u.Ib,.5,k);g=u.G;r=u.H;break;case Sf:Fe(g,r,u.Sd,u.hf,u.Cb,u.Ib,.5,k);g=u.G;r=u.H;break;case Tf:c=Vf(u,l);e=c.length;if(0===e){k.push(u.pa);k.push(u.va);g=u.pa;r=u.va;break}for(y=
0;y<e;y++)t=c[y],Be(g,r,t[2],t[3],t[4],t[5],t[6],t[7],.5,k),g=t[6],r=t[7];break;case Uf:c=Wf(u,l,g,r);e=c.length;if(0===e){k.push(u.pa);k.push(u.va);g=u.pa;r=u.va;break}for(y=0;y<e;y++)t=c[y],Be(g,r,t[2],t[3],t[4],t[5],t[6],t[7],.5,k),g=t[6],r=t[7];break;default:D.k("Segment not of valid type: "+u.ca)}u.hi&&(k.push(n),k.push(q),oq(a,b,k,d))}oq(a,b,k,d)}else if(c.type===tf)k.push(c.ma),k.push(c.ja),k.push(c.G),k.push(c.ja),k.push(c.G),k.push(c.H),k.push(c.ma),k.push(c.H),k.push(c.ma),k.push(c.ja),
oq(a,b,k,d);else if(c.type===uf){h=new Xe;h.ma=c.G;h.ja=(c.ja+c.H)/2;g=new $f(Tf);g.Me=0;g.Df=360;g.pa=(c.ma+c.G)/2;g.va=(c.ja+c.H)/2;g.radiusX=Math.abs(c.ma-c.G)/2;g.radiusY=Math.abs(c.ja-c.H)/2;h.add(g);c=Vf(g,h);e=c.length;if(0===e)k.push(g.pa),k.push(g.va);else for(g=h.ma,r=h.ja,y=0;y<e;y++)t=c[y],Be(g,r,t[2],t[3],t[4],t[5],t[6],t[7],.5,k),g=t[6],r=t[7];oq(a,b,k,d)}D.ya(k);a.restore();a.De(!1)}}}};
function pq(a,b,c,d){var e=b.length;if(!(4>e)){var g=.001,h=c.length,k=b[0],l=b[1];if(4===e)Go(a,k,l,b[2],b[3],c,d);else{a.moveTo(k,l);for(var m=g=0,n=0,p=0,q=0,r=p=0,s=D.nb(),u=2;u<e;u+=2)g=b[u],m=b[u+1],n=g-k,p=m-l,0===n&&(n=.001),q=p/n,p=Math.sqrt(n*n+p*p),s.push([n,q,p]),r+=p,k=g,l=m;k=b[0];l=b[1];b=0;for(var e=!0,g=c[b%h],m=0!==d,u=0,p=s[u],n=p[0],q=p[1],p=p[2],t=0;.1<=r;){0===t&&(g=c[b%h],b++,m&&(d%=g,g-=d,m=!1));g>r&&(g=r);g>p?(t=g-p,g=p):t=0;var y=Math.sqrt(g*g/(1+q*q));0>n&&(y=-y);k+=y;l+=
q*y;e?a.lineTo(k,l):a.moveTo(k,l);r-=g;p-=g;if(0!==t){u++;if(u===s.length){D.ya(s);return}p=s[u];n=p[0];q=p[1];p=p[2];g=t}else e=!e}D.ya(s)}}}z.prototype.getDocumentPoint=z.prototype.eb=function(a,b){void 0===b&&(b=new N);if(a instanceof S){a.ce()&&D.k("getDocumentPoint Spot must be a real, specific Spot, not: "+a.toString());var c=this.Ga,d=this.mb;b.n(a.x*(c.width+d)-d/2+c.x+a.offsetX,a.y*(c.height+d)-d/2+c.y+a.offsetY)}else b.set(a);this.Jh.xb(b);return b};
z.prototype.Ok=function(a,b){var c=this.uf;if(null===c||null===this.fill&&null===this.stroke)return!1;var d=c.qb,e=this.mb/2;c.type!==sf||b||(e+=2);var g=D.Qf();g.assign(d);g.ig(e+2,e+2);if(!g.Pa(a))return D.Kb(g),!1;d=e+1E-4;if(c.type===sf){if(null===this.stroke)return!1;d=(c.G-c.ma)*(a.x-c.ma)+(c.H-c.ja)*(a.y-c.ja);if(0>(c.ma-c.G)*(a.x-c.G)+(c.ja-c.H)*(a.y-c.H)||0>d)return!1;D.Kb(g);return qe(c.ma,c.ja,c.G,c.H,e,a.x,a.y)}if(c.type===tf){var h=c.ma,k=c.ja,l=c.G,m=c.H;g.x=Math.min(h,l);g.y=Math.min(k,
m);g.width=Math.abs(l-h);g.height=Math.abs(m-k);if(null===this.fill){g.ig(-d,-d);if(g.Pa(a))return D.Kb(g),!1;g.ig(d,d)}null!==this.stroke&&g.ig(e,e);e=g.Pa(a);D.Kb(g);return e}if(c.type===uf){var h=c.ma,k=c.ja,l=c.G,m=c.H,c=Math.min(h,l),n=Math.min(k,m),h=Math.abs(l-h)/2,k=Math.abs(m-k)/2,c=a.x-(c+h),n=a.y-(n+k);if(null===this.fill){h-=d;k-=d;if(0>=h||0>=k||1>=c*c/(h*h)+n*n/(k*k))return D.Kb(g),!1;h+=d;k+=d}null!==this.stroke&&(h+=e,k+=e);D.Kb(g);return 0>=h||0>=k?!1:1>=c*c/(h*h)+n*n/(k*k)}if(c.type===
We)return D.Kb(g),null===this.fill?Yf(c,a.x,a.y,e):c.Pa(a,e,1<this.mb,b);D.k("Unknown Geometry type: "+c.type);return!1};
z.prototype.io=function(a,b,c,d){var e=this.Ea,g=this.Sg;a=Math.max(a,0);b=Math.max(b,0);var h;if(null!==this.bg)h=this.uf.qb;else{var k=this.Jb,l=me[k];if(void 0===l){var m=qq[k];"string"===typeof m&&(m=qq[m]);"function"===typeof m?(l=m(null,100,100),me[k]=l):D.k("Unsupported Figure: "+k)}h=l.qb}var k=h.width,l=h.height,m=h.width,n=h.height;switch(Mo(this,!0)){case Yj:d=c=0;break;case rf:m=Math.max(a-g,0);n=Math.max(b-g,0);break;case yo:m=Math.max(a-g,0);d=0;break;case xo:c=0,n=Math.max(b-g,0)}isFinite(e.width)&&
(m=e.width);isFinite(e.height)&&(n=e.height);e=this.of;h=this.bh;c=Math.max(c,h.width)-g;d=Math.max(d,h.height)-g;m=Math.min(e.width,m);n=Math.min(e.height,n);m=isFinite(m)?Math.max(c,m):Math.max(k,c);n=isFinite(n)?Math.max(d,n):Math.max(l,d);c=Zj(this);switch(c){case Yj:break;case rf:k=m;l=n;break;case ak:c=Math.min(m/k,n/l);isFinite(c)||(c=1);k*=c;l*=c;break;default:D.k(c+" is not a valid geometryStretch.")}null!==this.bg?(k=Math.max(k,.01),l=Math.max(l,.01),h=null!==this.bg?this.bg:this.ab,e=k,
d=l,c=h.copy(),h=h.qb,e/=h.width,d/=h.height,isFinite(e)||(e=1),isFinite(d)||(d=1),1===e&&1===d||c.scale(e,d),v&&c.freeze(),this.ab=c):null!==this.ab&&Eb(this.ab.lp,a-g)&&Eb(this.ab.jp,b-g)||(this.ab=z.makeGeometry(this,k,l));h=this.ab.qb;Infinity===a||Infinity===b?Jo(this,h.x-g/2,h.y-g/2,0===a&&0===k?0:h.width+g,0===b&&0===l?0:h.height+g):Jo(this,-(g/2),-(g/2),m+g,n+g)};
function Wp(a,b,c,d){if(!1!==Mm(a)){a.Dd.Xa();var e=a.Sg;0===e&&d instanceof da&&d.type===rj&&d.Bb instanceof z&&(e=d.Bb.mb);e*=a.Db;d instanceof J&&null!==d.ab?(b=d.ab.qb,Jo(a,b.x-e/2,b.y-e/2,b.width+e,b.height+e)):Jo(a,-(e/2),-(e/2),b+e,c+e);a.Dd.freeze();a.Dd.F()||D.k("Non-real measuredBounds has been set. Object "+a+", measuredBounds: "+a.Dd.toString());jm(a,!1)}}function Zj(a){var b=a.TA;return null!==a.bg?b===wo?rf:b:b===wo?me[a.Jb].me:b}z.prototype.yj=function(a,b,c,d){Oo(this,a,b,c,d)};
z.prototype.getNearestIntersectionPoint=z.prototype.FF=function(a,b,c){return this.Un(a.x,a.y,b.x,b.y,c)};
z.prototype.Un=function(a,b,c,d,e){var g=this.transform,h=1/(g.m11*g.m22-g.m12*g.m21),k=g.m22*h,l=-g.m12*h,m=-g.m21*h,n=g.m11*h,p=h*(g.m21*g.dy-g.m22*g.dx),q=h*(g.m12*g.dx-g.m11*g.dy),g=a*k+b*m+p,h=a*l+b*n+q,k=c*k+d*m+p,l=c*l+d*n+q,m=this.mb/2,p=this.ab;null===p&&(Gk(this,Infinity,Infinity),p=this.ab);q=p.qb;n=!1;if(p.type===sf)if(1.5>=this.mb)n=Ie(p.yc,p.Ic,p.Cb,p.Ib,g,h,k,l,e);else{var r=0,s=0;p.yc===p.Cb?(r=m,s=0):(b=(p.Ib-p.Ic)/(p.Cb-p.yc),s=m/Math.sqrt(1+b*b),r=s*b);d=D.nb();b=new N;Ie(p.yc+
r,p.Ic+s,p.Cb+r,p.Ib+s,g,h,k,l,b)&&d.push(b);b=new N;Ie(p.yc-r,p.Ic-s,p.Cb-r,p.Ib-s,g,h,k,l,b)&&d.push(b);b=new N;Ie(p.yc+r,p.Ic+s,p.yc-r,p.Ic-s,g,h,k,l,b)&&d.push(b);b=new N;Ie(p.Cb+r,p.Ib+s,p.Cb-r,p.Ib-s,g,h,k,l,b)&&d.push(b);b=d.length;if(0===b)return D.ya(d),!1;n=!0;s=Infinity;for(r=0;r<b;r++){var k=d[r],u=(k.x-g)*(k.x-g)+(k.y-h)*(k.y-h);u<s&&(s=u,e.x=k.x,e.y=k.y)}D.ya(d)}else if(p.type===tf)b=q.x-m,n=Je(b,q.y-m,q.x+q.width+m,q.y+q.height+m,g,h,k,l,e);else if(p.type===uf)a:if(b=q.copy().ig(m,
m),0===b.width)n=Ie(b.x,b.y,b.x,b.y+b.height,g,h,k,l,e);else if(0===b.height)n=Ie(b.x,b.y,b.x+b.width,b.y,g,h,k,l,e);else{a=b.width/2;var t=b.height/2;d=b.x+a;b=b.y+t;c=9999;g!==k&&(c=(h-l)/(g-k));if(9999>Math.abs(c)){n=h-b-c*(g-d);if(0>a*a*c*c+t*t-n*n){e.x=NaN;e.y=NaN;n=!1;break a}m=Math.sqrt(a*a*c*c+t*t-n*n);k=(-(a*a*c*n)+a*t*m)/(t*t+a*a*c*c)+d;a=(-(a*a*c*n)-a*t*m)/(t*t+a*a*c*c)+d;l=c*(k-d)+n+b;b=c*(a-d)+n+b;d=Math.abs((g-k)*(g-k))+Math.abs((h-l)*(h-l));h=Math.abs((g-a)*(g-a))+Math.abs((h-b)*(h-
b));d<h?(e.x=k,e.y=l):(e.x=a,e.y=b)}else{k=t*t;l=g-d;k-=k/(a*a)*l*l;if(0>k){e.x=NaN;e.y=NaN;n=!1;break a}m=Math.sqrt(k);l=b+m;b-=m;d=Math.abs(l-h);h=Math.abs(b-h);d<h?(e.x=g,e.y=l):(e.x=g,e.y=b)}n=!0}else if(p.type===We){var y=0,w=0,B=u=0,q=D.P(),r=k-g,s=l-h,s=r*r+s*s;e.x=k;e.y=l;for(r=0;r<p.nc.count;r++)for(var A=p.nc.o[r],O=A.Eb,y=A.ma,w=A.ja,H=y,R=w,aa=0;aa<O.count;aa++){var V=O.o[aa],ea=V.type,u=V.G,B=V.H,la=!1;switch(ea){case Ef:H=u;R=B;break;case wf:la=rq(y,w,u,B,g,h,k,l,q);break;case Ff:var la=
V.Cc,ea=V.Zc,Da=V.Uh,La=V.Vh,la=Ge(y,w,la,ea,Da,La,u,B,g,h,k,l,.5,q);break;case Sf:la=(y+2*V.Cc)/3;ea=(w+2*V.Zc)/3;Da=(2*V.Cc+u)/3;La=(2*V.Cc+u)/3;la=Ge(y,w,la,ea,Da,La,u,B,g,h,k,l,.5,q);break;case Tf:case Uf:ea=V.type===Tf?Vf(V,A):Wf(V,A,y,w);Da=ea.length;if(0===Da){la=rq(y,w,V.pa,V.va,g,h,k,l,q);break}for(B=0;B<Da;B++)t=ea[B],0===B&&rq(y,w,t[0],t[1],g,h,k,l,q)&&(u=sq(g,h,q,s,e),u<s&&(s=u,n=!0)),Ge(t[0],t[1],t[2],t[3],t[4],t[5],t[6],t[7],g,h,k,l,.5,q)&&(u=sq(g,h,q,s,e),u<s&&(s=u,n=!0));u=t[6];B=
t[7];break;default:D.k("Unknown Segment type: "+ea)}y=u;w=B;la&&(u=sq(g,h,q,s,e),u<s&&(s=u,n=!0));V.Vx&&(u=H,B=R,rq(y,w,u,B,g,h,k,l,q)&&(u=sq(g,h,q,s,e),u<s&&(s=u,n=!0)))}g=c-a;h=d-b;b=Math.sqrt(g*g+h*h);0!==b&&(g/=b,h/=b);e.x-=g*m;e.y-=h*m;D.A(q)}else D.k("Unknown Geometry type: "+p.type);if(!n)return!1;this.transform.xb(e);return!0};function sq(a,b,c,d,e){a=c.x-a;b=c.y-b;b=a*a+b*b;return b<d?(e.x=c.x,e.y=c.y,b):d}
function rq(a,b,c,d,e,g,h,k,l){var m=!1,n=(e-h)*(b-d)-(g-k)*(a-c);if(0===n)return!1;l.x=((e*k-g*h)*(a-c)-(e-h)*(a*d-b*c))/n;l.y=((e*k-g*h)*(b-d)-(g-k)*(a*d-b*c))/n;(a>c?a-c:c-a)<(b>d?b-d:d-b)?(e=b<d?b:d,a=b<d?d:b,(l.y>e||Eb(l.y,e))&&(l.y<a||Eb(l.y,a))&&(m=!0)):(e=a<c?a:c,a=a<c?c:a,(l.x>e||Eb(l.x,e))&&(l.x<a||Eb(l.x,a))&&(m=!0));return m}
z.prototype.containedInRect=z.prototype.Jn=function(a,b){if(void 0===b)return a.Pk(this.Z);var c=this.ab;null===c&&(Gk(this,Infinity,Infinity),c=this.ab);var c=c.qb,d=this.mb/2,e=!1,g=D.P();g.n(c.x-d,c.y-d);a.Pa(b.xb(g))&&(g.n(c.x-d,c.bottom+d),a.Pa(b.xb(g))&&(g.n(c.right+d,c.bottom+d),a.Pa(b.xb(g))&&(g.n(c.right+d,c.y-d),a.Pa(b.xb(g))&&(e=!0))));D.A(g);return e};
z.prototype.intersectsRect=z.prototype.jg=function(a,b){if(this.Jn(a,b)||void 0===b&&(b=this.transform,a.Pk(this.Z)))return!0;var c=D.Rf();c.set(b);c.Ux();var d=a.left,e=a.right,g=a.top,h=a.bottom,k=D.P();k.n(d,g);c.xb(k);if(this.Ok(k,!0))return D.A(k),!0;k.n(e,g);c.xb(k);if(this.Ok(k,!0))return D.A(k),!0;k.n(d,h);c.xb(k);if(this.Ok(k,!0))return D.A(k),!0;k.n(e,h);c.xb(k);if(this.Ok(k,!0))return D.A(k),!0;var l=D.P(),m=D.P();c.set(b);c.tB(this.transform);c.Ux();l.x=e;l.y=g;l.transform(c);k.x=d;k.y=
g;k.transform(c);var n=!1;tq(this,k,l,m)?n=!0:(k.x=e,k.y=h,k.transform(c),tq(this,k,l,m)?n=!0:(l.x=d,l.y=h,l.transform(c),tq(this,k,l,m)?n=!0:(k.x=d,k.y=g,k.transform(c),tq(this,k,l,m)&&(n=!0))));D.A(k);D.Je(c);D.A(l);D.A(m);return n};function tq(a,b,c,d){if(!a.FF(b,c,d))return!1;a=b.x;b=b.y;var e=c.x;c=c.y;var g=d.x;d=d.y;if(a===e){var h=0;a=0;b<c?(h=b,a=c):(h=c,a=b);return d>=h&&d<=a}a<e?(h=a,a=e):h=e;return g>=h&&g<=a}
z.prototype.yF=function(a,b,c){function d(a,b){for(var c=a.length,d=0;d<c;d+=2)if(b.jq(a[d],a[d+1])>e)return!0;return!1}if(c&&null!==this.fill&&this.Ok(a,!0))return!0;var e=a.Jf(b);b=e;1.5<this.mb&&(e=this.mb/2+Math.sqrt(e),e*=e);var g=this.ab;if(null===g&&(Gk(this,Infinity,Infinity),g=this.ab,null===g))return!1;if(!c){var h=g.qb,k=h.x,l=h.y,m=h.x+h.width,h=h.y+h.height;if(ob(a.x,a.y,k,l)<=e&&ob(a.x,a.y,m,l)<=e&&ob(a.x,a.y,k,h)<=e&&ob(a.x,a.y,m,h)<=e)return!0}k=g.yc;l=g.Ic;m=g.Cb;h=g.Ib;if(g.type===
sf){if(c=lb(a.x,a.y,k,l,m,h),g=(k-m)*(a.x-m)+(l-h)*(a.y-h),c<=(0<=(m-k)*(a.x-k)+(h-l)*(a.y-l)&&0<=g?e:b))return!0}else{if(g.type===tf)return b=!1,c&&(b=lb(a.x,a.y,k,l,k,h)<=e||lb(a.x,a.y,k,l,m,l)<=e||lb(a.x,a.y,m,l,m,h)<=e||lb(a.x,a.y,k,h,m,h)<=e),b;if(g.type===uf){b=a.x-(k+m)/2;var g=a.y-(l+h)/2,n=Math.abs(m-k)/2,p=Math.abs(h-l)/2;if(0===n||0===p)return c=lb(a.x,a.y,k,l,m,h),c<=e?!0:!1;if(c){if(a=Ue(n,p,b,g),a*a<=e)return!0}else return ob(b,g,-n,0)>=e||ob(b,g,0,-p)>=e||ob(b,g,0,p)>=e||ob(b,g,n,0)>=
e?!1:!0}else if(g.type===We){h=g.qb;k=h.x;l=h.y;m=h.x+h.width;h=h.y+h.height;if(a.x>m&&a.x<k&&a.y>h&&a.y<l&&lb(a.x,a.y,k,l,k,h)>e&&lb(a.x,a.y,k,l,m,l)>e&&lb(a.x,a.y,m,h,k,h)>e&&lb(a.x,a.y,m,h,m,l)>e)return!1;b=Math.sqrt(e);if(c){if(null===this.fill?Yf(g,a.x,a.y,b):g.Pa(a,b,!0))return!0}else{c=g.nc;for(b=0;b<c.count;b++){k=c.o[b];n=k.ma;p=k.ja;if(a.jq(n,p)>e)return!1;l=k.Eb.o;m=l.length;for(h=0;h<m;h++){var q=l[h];switch(q.type){case Ef:case wf:n=q.G;p=q.H;if(a.jq(n,p)>e)return!1;break;case Ff:g=D.nb();
Be(n,p,q.Cc,q.Zc,q.Uh,q.Vh,q.G,q.H,.8,g);n=d(g,a);D.ya(g);if(n)return!1;n=q.G;p=q.H;if(a.jq(n,p)>e)return!1;break;case Sf:g=D.nb();Fe(n,p,q.Cc,q.Zc,q.G,q.H,.8,g);n=d(g,a);D.ya(g);if(n)return!1;n=q.G;p=q.H;if(a.jq(n,p)>e)return!1;break;case Tf:case Uf:var r=q.type===Tf?Vf(q,k):Wf(q,k,n,p),s=r.length;if(0===s){n=q.pa;p=q.va;if(a.jq(n,p)>e)return!1;break}q=null;g=D.nb();for(b=0;b<s;b++)if(q=r[b],g.length=0,Be(q[0],q[1],q[2],q[3],q[4],q[5],q[6],q[7],.8,g),d(g,a))return D.ya(g),!1;D.ya(g);null!==q&&(n=
q[6],p=q[7]);break;default:D.k("Unknown Segment type: "+q.type)}}}return!0}}}return!1};D.defineProperty(z,{uf:"geometry"},function(){return null!==this.ab?this.ab:this.bg},function(a){var b=this.ab;if(b!==a){null!==a?(v&&D.l(a,Ve,z,"geometry"),this.bg=this.ab=a.freeze()):this.bg=this.ab=null;var c=this.Y;null!==c&&(c.vk=NaN);this.K();this.i("geometry",b,a);Ho(this)&&(a=this.Y,null!==a&&Io(this,a,"geometryString"))}});
D.defineProperty(z,{MI:"geometryString"},function(){return null===this.uf?"":this.uf.toString()},function(a){a=yf(a);var b=a.normalize();this.uf=a;this.position=a=D.Fb(-b.x,-b.y);D.A(a)});D.defineProperty(z,{XF:"isGeometryPositioned"},function(){return this.ds},function(a){v&&D.h(a,"boolean",z,"isGeometryPositioned");var b=this.ds;b!==a&&(this.ds=a,this.K(),this.i("isGeometryPositioned",b,a))});z.prototype.Te=function(){this.ab=null};
D.defineProperty(z,{fill:"fill"},function(){return this.Rd},function(a){var b=this.Rd;b!==a&&(v&&null!==a&&D.$t(a,"Shape.fill"),a instanceof Ba&&a.freeze(),this.Rd=a,this.ra(),this.i("fill",b,a))});D.defineProperty(z,{stroke:"stroke"},function(){return this.zc},function(a){var b=this.zc;b!==a&&(v&&null!==a&&D.$t(a,"Shape.stroke"),a instanceof Ba&&a.freeze(),this.zc=a,this.ra(),this.i("stroke",b,a))});
D.defineProperty(z,{mb:"strokeWidth"},function(){return this.Sg},function(a){var b=this.Sg;if(b!==a)if(v&&D.p(a,z,"strokeWidth"),0<=a){this.Sg=a;this.K();var c=this.Y;null!==c&&(c.vk=NaN);this.i("strokeWidth",b,a)}else D.ua(a,"value >= 0",z,"strokeWidth:value")});
D.defineProperty(z,{EM:"strokeCap"},function(){return this.Np},function(a){var b=this.Np;b!==a&&("string"!==typeof a||"butt"!==a&&"round"!==a&&"square"!==a?D.ua(a,'"butt", "round", or "square"',z,"strokeCap"):(this.Np=a,this.ra(),this.i("strokeCap",b,a)))});
D.defineProperty(z,{GM:"strokeJoin"},function(){return this.Pp},function(a){var b=this.Pp;b!==a&&("string"!==typeof a||"miter"!==a&&"bevel"!==a&&"round"!==a?D.ua(a,'"miter", "bevel", or "round"',z,"strokeJoin"):(this.Pp=a,this.ra(),this.i("strokeJoin",b,a)))});
D.defineProperty(z,{HM:"strokeMiterLimit"},function(){return this.yn},function(a){var b=this.yn;if(b!==a)if(v&&D.p(a,z,"strokeMiterLimit"),1<=a){this.yn=a;this.ra();var c=this.Y;null!==c&&(c.vk=NaN);this.i("strokeMiterLimit",b,a)}else D.ua(a,"value >= 1",z,"strokeWidth:value")});
D.defineProperty(z,{dH:"strokeDashArray"},function(){return this.Op},function(a){var b=this.Op;if(b!==a){null===a||Array.isArray(a)||D.jc(a,"Array",z,"strokeDashArray:value");if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var g=a[e];"number"===typeof g&&0<=g&&isFinite(g)||D.k("strokeDashArray:value "+g+" at index "+e+" must be a positive number or zero.");d+=g}if(0===d){if(null===b)return;a=null}}this.Op=a;this.ra();this.i("strokeDashArray",b,a)}});
D.defineProperty(z,{FM:"strokeDashOffset"},function(){return this.Gf},function(a){var b=this.Gf;b!==a&&(v&&D.p(a,z,"strokeDashOffset"),0<=a&&(this.Gf=a,this.ra(),this.i("strokeDashOffset",b,a)))});
D.defineProperty(z,{Jb:"figure"},function(){return this.$o},function(a){var b=this.$o;if(b!==a){v&&D.h(a,"string",z,"figure");var c=qq[a];"function"===typeof c?c=a:(c=qq[a.toLowerCase()])||D.k("Unknown Shape.figure: "+a);b!==c&&(a=this.Y,null!==a&&(a.vk=NaN),this.$o=c,this.bg=null,this.Te(),this.K(),this.i("figure",b,c))}});
D.defineProperty(z,{Tq:"toArrow"},function(){return this.Tp},function(a){var b=this.Tp;!0===a?a="Standard":!1===a&&(a="");if(b!==a){v&&D.h(a,"string",z,"toArrow");var c=uq(a);null===c?D.k("Unknown Shape.toArrow: "+a):b!==c&&(this.Tp=c,this.bg=null,this.Te(),this.K(),vq(this),this.i("toArrow",b,c))}});
D.defineProperty(z,{RA:"fromArrow"},function(){return this.cp},function(a){var b=this.cp;!0===a?a="Standard":!1===a&&(a="");if(b!==a){v&&D.h(a,"string",z,"fromArrow");var c=uq(a);null===c?D.k("Unknown Shape.fromArrow: "+a):b!==c&&(this.cp=c,this.bg=null,this.Te(),this.K(),vq(this),this.i("fromArrow",b,c))}});function vq(a){var b=a.g;null!==b&&b.la.jb||(a.Lq=wq,"None"!==a.Tp?(a.We=-1,a.Ih=fd):"None"!==a.cp&&(a.We=0,a.Ih=new S(1-fd.x,fd.y)))}
D.defineProperty(z,{C:"spot1"},function(){return this.xi},function(a){D.l(a,S,z,"spot1");var b=this.xi;b.O(a)||(this.xi=a=a.V(),this.K(),this.i("spot1",b,a))});D.defineProperty(z,{D:"spot2"},function(){return this.yi},function(a){D.l(a,S,z,"spot2");var b=this.yi;b.O(a)||(this.yi=a=a.V(),this.K(),this.i("spot2",b,a))});D.defineProperty(z,{Qc:"parameter1"},function(){return this.Vs},function(a){var b=this.Vs;b!==a&&(this.Vs=a,this.Te(),this.K(),this.i("parameter1",b,a))});
D.defineProperty(z,{Vu:"parameter2"},function(){return this.Ws},function(a){var b=this.Ws;b!==a&&(this.Ws=a,this.Te(),this.K(),this.i("parameter2",b,a))});D.w(z,{Ga:"naturalBounds"},function(){if(null!==this.ab)return this.ed.assign(this.ab.qb),this.ed;var a=this.Ea;return new C(0,0,a.width,a.height)});D.defineProperty(z,{ty:"pathPattern"},function(){return this.Ys},function(a){var b=this.Ys;b!==a&&(v&&D.l(a,P,z,"pathPattern"),this.Ys=a,this.ra(),this.i("pathPattern",b,a))});
D.defineProperty(z,{tM:"pathObject"},function(){return this.ty},function(a){this.ty=a});D.defineProperty(z,{TA:"geometryStretch"},function(){return this.Vr},function(a){var b=this.Vr;b!==a&&(D.Da(a,P,z,"geometryStretch"),this.Vr=a,this.i("geometryStretch",b,a))});
D.defineProperty(z,{interval:"interval"},function(){return this.Gg},function(a){var b=this.Gg;v&&D.p(a,z,"interval");a=Math.floor(a);if(b!==a&&0<=a){this.Gg=a;var c=this.g;null!==c&&this.R===c.Wn&&km(c);this.K();c=this.R;null!==c&&c.type===pp&&(c.nk=null);this.i("interval",b,a)}});D.defineProperty(z,{JF:"graduatedStart"},function(){return this.Fg},function(a){var b=this.Fg;v&&D.p(a,z,"graduatedStart");b!==a&&(0>a?a=0:1<a&&(a=1),this.Fg=a,this.K(),this.i("graduatedStart",b,a))});
D.defineProperty(z,{HF:"graduatedEnd"},function(){return this.Eg},function(a){var b=this.Eg;v&&D.p(a,z,"graduatedEnd");b!==a&&(0>a?a=0:1<a&&(a=1),this.Eg=a,this.K(),this.i("graduatedEnd",b,a))});z.makeGeometry=function(a,b,c){var d=null;if("None"!==a.Tq)d=ne[a.Tq];else if("None"!==a.RA)d=ne[a.RA];else{var e=qq[a.Jb];"string"===typeof e&&(e=qq[e]);void 0===e&&D.k("Unknown Shape.figure: "+a.Jb);d=e(a,b,c);d.lp=b;d.jp=c}null===d&&(e=qq.Rectangle,"function"===typeof e&&(d=e(a,b,c)));return d};
z.getFigureGenerators=function(){var a=new oa("string","function"),b;for(b in qq)if(b!==b.toLowerCase()){var c=qq[b];"function"===typeof c&&a.add(b,c)}a.freeze();return a};
z.defineFigureGenerator=function(a,b){D.h(a,"string",z,"defineFigureGenerator:name");"string"===typeof b?""!==b&&qq[b]||D.k("Shape.defineFigureGenerator synonym must not be empty or None or not a defined figure name: "+b):D.h(b,"function",z,"defineFigureGenerator:func");var c=a.toLowerCase();""!==a&&"none"!==c&&a!==c||D.k("Shape.defineFigureGenerator name must not be empty or None or all-lower-case: "+a);qq[a]=b;qq[c]=a};
z.getArrowheadGeometries=function(){var a=new oa("string",Ve),b;for(b in xq)if(void 0===ne[b]){var c=yf(xq[b],!1);ne[b]=c;c=b.toLowerCase();c!==b&&(ne[c]=b)}for(b in ne)b!==b.toLowerCase()&&(c=ne[b],c instanceof Ve&&a.add(b,c));a.freeze();return a};
z.defineArrowheadGeometry=function(a,b){D.h(a,"string",z,"defineArrowheadGeometry:name");var c=null;"string"===typeof b?(D.h(b,"string",z,"defineArrowheadGeometry:pathstr"),c=yf(b,!1)):(D.l(b,Ve,z,"defineArrowheadGeometry:pathstr"),c=b);var d=a.toLowerCase();""!==a&&"none"!==d&&a!==d||D.k("Shape.defineArrowheadGeometry name must not be empty or None or all-lower-case: "+a);ne[a]=c;ne[d]=a};
function pa(){P.call(this);this.Vd="";this.zc="black";this.Cg="13px sans-serif";this.Xl="start";this.Bg=Yj;this.am=Yc;this.Ym=!0;this.rk=this.sk=!1;this.nj=yq;this.Kk=zq;this.sw=this.we=0;this.Tz=this.Uz=null;this.rf=new bq;this.Nr=!1;this.cf=this.mr=this.Ht=this.Yl=this.It=null;this.wi=this.vi=0;this.xh=Infinity;this.rp=0;this.Gg=1;this.Fg=0;this.Eg=1;this.Um=null}D.Sa(pa,P);D.ka("TextBlock",pa);var Aq=/[ \u200b\u00ad]/;
pa.prototype.cloneProtected=function(a){P.prototype.cloneProtected.call(this,a);a.Vd=this.Vd;a.zc=this.zc;a.Cg=this.Cg;a.Xl=this.Xl;a.Bg=this.Bg;a.am=this.am;a.Ym=this.Ym;a.sk=this.sk;a.rk=this.rk;a.nj=this.nj;a.Kk=this.Kk;a.we=this.we;a.sw=this.sw;a.Uz=this.Uz;a.Tz=this.Tz;a.rf.dq(this.rf);a.Nr=this.Nr;a.It=this.It;a.Yl=this.Yl;a.Ht=this.Ht;a.mr=this.mr;a.cf=this.cf;a.vi=this.vi;a.wi=this.wi;a.xh=this.xh;a.rp=this.rp;a.Gg=this.Gg;a.Fg=this.Fg;a.Eg=this.Eg;a.Um=this.Um};
pa.prototype.qc=function(a){a.Oe===pa?this.sH=a:P.prototype.qc.call(this,a)};pa.prototype.toString=function(){return 22<this.Vd.length?'TextBlock("'+this.Vd.substring(0,20)+'"...)':'TextBlock("'+this.Vd+'")'};var Bq=new ma,Cq=0,Dq=new ma,Eq=0,Fq="...",Gq="",Hq=(new ja(null)).Qk;pa.getEllipsis=function(){return Fq};pa.setEllipsis=function(a){Fq=a;Dq=new ma;Eq=0};var Iq;pa.None=Iq=D.s(pa,"None",0);var Jq;pa.WrapFit=Jq=D.s(pa,"WrapFit",1);var zq;pa.WrapDesiredSize=zq=D.s(pa,"WrapDesiredSize",2);var yq;
pa.OverflowClip=yq=D.s(pa,"OverflowClip",0);var Kq;pa.OverflowEllipsis=Kq=D.s(pa,"OverflowEllipsis",1);pa.prototype.K=function(){P.prototype.K.call(this);this.Tz=this.Uz=null};D.defineProperty(pa,{font:"font"},function(){return this.Cg},function(a){var b=this.Cg;b!==a&&(v&&(D.h(a,"string",pa,"font"),Lq(a)||D.k('Not a valid font: "'+a+'"')),this.Cg=a,this.rf.ik=null,this.K(),this.i("font",b,a))});var Lq;
pa.isValidFont=Lq=function(a){var b=Hq.font;if(a===b||"10px sans-serif"===a)return!0;Hq.font="10px sans-serif";var c;Hq.font=a;var d=Hq.font;if("10px sans-serif"!==d)return Hq.font=b,!0;Hq.font="19px serif";c=Hq.font;Hq.font=a;d=Hq.font;Hq.font=b;return d!==c};D.defineProperty(pa,{text:"text"},function(){return this.Vd},function(a){var b=this.Vd;a=null!==a&&void 0!==a?a.toString():"";b!==a&&(this.Vd=a,this.K(),this.i("text",b,a))});
D.defineProperty(pa,{textAlign:"textAlign"},function(){return this.Xl},function(a){var b=this.Xl;b!==a&&(v&&D.h(a,"string",pa,"textAlign"),"start"===a||"end"===a||"left"===a||"right"===a||"center"===a?(this.Xl=a,this.ra(),this.i("textAlign",b,a)):D.ua(a,'"start", "end", "left", "right", or "center"',pa,"textAlign"))});D.defineProperty(pa,{ou:"flip"},function(){return this.Bg},function(a){var b=this.Bg;b!==a&&(D.Da(a,P,pa,"flip"),this.Bg=a,this.ra(),this.i("flip",b,a))});
D.defineProperty(pa,{MM:"verticalAlignment"},function(){return this.am},function(a){var b=this.am;b.O(a)||(v&&D.l(a,S,pa,"verticalAlignment"),a.ce()&&D.k("TextBlock.verticalAlignment for "+this+" must be a real Spot, not:"+a),this.am=a=a.V(),Zo(this),this.i("verticalAlignment",b,a))});D.w(pa,{Ga:"naturalBounds"},function(){if(!this.ed.F()){var a=Mq(this,this.Vd,this.rf,999999).width,b=Nq(this,a,this.rf),c=this.Ea;isNaN(c.width)||(a=c.width);isNaN(c.height)||(b=c.height);Cb(this.ed,a,b)}return this.ed});
D.defineProperty(pa,{$x:"isMultiline"},function(){return this.Ym},function(a){var b=this.Ym;b!==a&&(v&&D.h(a,"boolean",pa,"isMultiline"),this.Ym=a,this.K(),this.i("isMultiline",b,a))});D.defineProperty(pa,{TL:"isUnderline"},function(){return this.sk},function(a){var b=this.sk;b!==a&&(v&&D.h(a,"boolean",pa,"isUnderline"),this.sk=a,this.ra(),this.i("isUnderline",b,a))});
D.defineProperty(pa,{SL:"isStrikethrough"},function(){return this.rk},function(a){var b=this.rk;b!==a&&(v&&D.h(a,"boolean",pa,"isStrikethrough"),this.rk=a,this.ra(),this.i("isStrikethrough",b,a))});D.defineProperty(pa,{sH:"wrap"},function(){return this.Kk},function(a){var b=this.Kk;b!==a&&(v&&D.Da(a,pa,pa,"wrap"),this.Kk=a,this.K(),this.i("wrap",b,a))});
D.defineProperty(pa,{overflow:"overflow"},function(){return this.nj},function(a){var b=this.nj;b!==a&&(v&&D.Da(a,pa,pa,"overflow"),this.nj=a,this.K(),this.i("overflow",b,a))});D.defineProperty(pa,{stroke:"stroke"},function(){return this.zc},function(a){var b=this.zc;b!==a&&(v&&null!==a&&D.$t(a,"TextBlock.stroke"),a instanceof Ba&&a.freeze(),this.zc=a,this.ra(),this.i("stroke",b,a))});D.w(pa,{jB:"lineCount"},function(){return this.we});
D.defineProperty(pa,{MA:"editable"},function(){return this.Nr},function(a){var b=this.Nr;b!==a&&(v&&D.h(a,"boolean",pa,"editable"),this.Nr=a,this.i("editable",b,a))});D.defineProperty(pa,{gH:"textEditor"},function(){return this.It},function(a){var b=this.It;b!==a&&(!v||a instanceof HTMLElement||a instanceof gk||D.k("TextEditingTool.defaultTextEditor must be an HTMLElement or HTMLInfo."),this.It=a,this.i("textEditor",b,a))});
D.defineProperty(pa,{OA:"errorFunction"},function(){return this.cf},function(a){var b=this.cf;b!==a&&(null!==a&&D.h(a,"function",pa,"errorFunction"),this.cf=a,this.i("errorFunction",b,a))});D.defineProperty(pa,{interval:"interval"},function(){return this.Gg},function(a){var b=this.Gg;v&&D.p(a,pa,"interval");a=Math.floor(a);if(b!==a&&0<=a){this.Gg=a;this.K();var c=this.R;null!==c&&c.type===pp&&(c.nk=null);this.i("interval",b,a)}});
D.defineProperty(pa,{JF:"graduatedStart"},function(){return this.Fg},function(a){var b=this.Fg;v&&D.p(a,pa,"graduatedStart");b!==a&&(0>a?a=0:1<a&&(a=1),this.Fg=a,this.K(),this.i("graduatedStart",b,a))});D.defineProperty(pa,{HF:"graduatedEnd"},function(){return this.Eg},function(a){var b=this.Eg;v&&D.p(a,pa,"graduatedEnd");b!==a&&(0>a?a=0:1<a&&(a=1),this.Eg=a,this.K(),this.i("graduatedEnd",b,a))});
D.defineProperty(pa,{IF:"graduatedFunction"},function(){return this.Um},function(a){var b=this.Um;b!==a&&(null!==a&&D.h(a,"function",pa,"graduatedFunction"),this.Um=a,this.K(),this.i("graduatedFunction",b,a))});
pa.prototype.Rk=function(a,b){if(null!==this.zc&&0!==this.Vd.length&&null!==this.Cg){var c=this.Ga.width,d=this.Ga.height,e=Oq(this),g=a.textAlign=this.Xl,h=b.hs;"start"===g&&(g=h?"right":"left");"end"===g&&(g=h?"left":"right");Vo(this,a,this.zc,!0,!1);(this.sk||this.rk)&&Vo(this,a,this.zc,!1,!1);var k=0,h=!1,l=D.Fb(0,0);this.Jh.xb(l);var m=D.Fb(0,e);this.Jh.xb(m);var n=l.Jf(m);D.A(l);D.A(m);l=b.scale;8>n*l*l&&(h=!0);b.cd!==a&&(h=!1);!1===b.Gj("textGreeking")&&(h=!1);n=this.vi;l=this.wi;switch(this.ou){case Ao:a.translate(c,
0);a.scale(-1,1);break;case zo:a.translate(0,d);a.scale(1,-1);break;case Bo:a.translate(c,d),a.scale(-1,-1)}var m=this.we,p=(n+e+l)*m;d>p&&(k=this.am,k=k.y*d-k.y*p+k.offsetY);for(var p=this.rf,q=0;q<m;q++){var r=p.Ef[q],s=p.$e[q];r>c&&(r=c);var k=k+n,u=s,s=a,t=k,y=c,w=e,B=g,A=0;h?("left"===B?A=0:"right"===B?A=y-r:"center"===B&&(A=(y-r)/2),s.fillRect(0+A,t+.25*w,r,1)):("left"===B?A=0:"right"===B?A=y:"center"===B&&(A=y/2),s.fillText(u,0+A,t+w-.25*w),u=w/20|0,0===u&&(u=1),"right"===B?A-=r:"center"===
B&&(A-=r/2),this.sk&&(s.beginPath(),s.lineWidth=u,s.moveTo(0+A,t+w-.2*w),s.lineTo(0+A+r,t+w-.2*w),s.stroke()),this.rk&&(s.beginPath(),s.lineWidth=u,t=t+w-w/2.2|0,0!==u%2&&(t+=.5),s.moveTo(0+A,t),s.lineTo(0+A+r,t),s.stroke()));k+=e+l}switch(this.ou){case Ao:a.scale(-1,1);a.translate(-c,0);break;case zo:a.scale(1,-1);a.translate(0,-d);break;case Bo:a.scale(-1,-1),a.translate(-c,-d)}}};
pa.prototype.io=function(a,b,c,d){this.rp=a;var e=this.rf;e.reset();var g=0,h=0;if(isNaN(this.Ea.width)){g=this.Vd.replace(/\r\n/g,"\n").replace(/\r/g,"\n");if(0===g.length)g=0;else if(this.$x){for(var k=h=0,l=!1;!l;){var m=g.indexOf("\n",k);-1===m&&(m=g.length,l=!0);k=Pq(g.substr(k,m-k).replace(/^\s+|\s+$/g,""),this.Cg);k>h&&(h=k);k=m+1}g=h}else h=g.indexOf("\n",0),0<=h&&(g=g.substr(0,h)),g=k=Pq(g,this.Cg);g=Math.min(g,a/this.scale);g=Math.max(8,g)}else g=this.Ea.width;null!==this.R&&(g=Math.min(g,
this.R.of.width));h=Nq(this,g,e);m=h=isNaN(this.Ea.height)?Math.min(h,b/this.scale):this.Ea.height;if(0!==e.ff&&1!==e.$e.length&&this.nj===Kq&&(b=this.Cg,l=this.nj===Kq?Qq(b):0,k=this.vi+this.wi,k=Math.max(0,Oq(this)+k),m=Math.min(this.EJ-1,Math.max(Math.floor(m/k+.01)-1,0)),!(m+1>=e.$e.length))){k=e.$e[m];for(a=Math.max(1,a-l);Pq(k,b)>a&&1<k.length;)k=k.substr(0,k.length-1);k+=Fq;a=Pq(k,b);e.$e[m]=k;e.$e=e.$e.slice(0,m+1);e.Ef[m]=a;e.Ef=e.Ef.slice(0,m+1);e.wj=e.$e.length;e.ff=Math.max(e.ff,a);this.we=
e.wj}if(this.sH===Jq||isNaN(this.Ea.width))g=e.ff,isNaN(this.Ea.width)&&(g=Math.max(8,g));g=Math.max(c,g);h=Math.max(d,h);Cb(this.ed,g,h);Jo(this,0,0,g,h)};pa.prototype.yj=function(a,b,c,d){Oo(this,a,b,c,d)};
function Mq(a,b,c,d){b=b.replace(/^\s+|\s+$/g,"");var e=0,g=0,h=0,k=a.Cg,g=a.vi+a.wi,l=Math.max(0,Oq(a)+g),h=a.nj===Kq?Qq(k):0;if(a.we>=a.xh)return new Ca(0,l);if(a.Kk===Iq){c.wj=1;g=Pq(b,k);if(0===h||g<=d)return c.ff=Math.max(c.ff,g),c.Ef.push(c.ff),c.$e.push(b),new Ca(g,l);var m=Rq(b);b=b.substr(m.length);for(var n=Rq(b),g=Pq(m+n,k);0<n.length&&g<=d;)m+=n,b=b.substr(n.length),n=Rq(b),g=Pq((m+n).replace(/^\s+|\s+$/g,""),k);m+=n.replace(/^\s+|\s+$/g,"");for(d=Math.max(1,d-h);Pq(m,k)>d&&1<m.length;)m=
m.substr(0,m.length-1);m+=Fq;h=Pq(m,k);c.Ef.push(h);c.ff=h;c.$e.push(m);return new Ca(h,l)}var p=0;0===b.length&&(p=1,c.Ef.push(0),c.$e.push(b));for(;0<b.length;){m=Rq(b);for(b=b.substr(m.length);Pq(m,k)>d;){n=1;g=Pq(m.substr(0,n),k);for(h=0;g<=d;)n++,h=g,g=Pq(m.substr(0,n),k);1===n?(c.Ef[a.we+p]=g,e=Math.max(e,g)):(c.Ef[a.we+p]=h,e=Math.max(e,h));n--;1>n&&(n=1);c.$e[a.we+p]=m.substr(0,n);p++;m=m.substr(n);if(a.we+p>a.xh)break}n=Rq(b);for(g=Pq(m+n,k);0<n.length&&g<=d;)m+=n,b=b.substr(n.length),n=
Rq(b),g=Pq((m+n).replace(/^\s+|\s+$/g,""),k);m=m.replace(/^\s+|\s+$/g,"");if(""!==m&&("\u00ad"===m[m.length-1]&&(m=m.substring(0,m.length-1)+"\u2010"),0===n.length?(c.Ef.push(g),e=Math.max(e,g)):(h=Pq(m,k),c.Ef.push(h),e=Math.max(e,h)),c.$e.push(m),p++,a.we+p>a.xh))break}c.wj=Math.min(a.xh,p);c.ff=Math.max(c.ff,e);return new Ca(c.ff,l*c.wj)}function Rq(a){for(var b=a.length,c=0;c<b&&!Aq.test(a.charAt(c));)c++;for(;c<b&&Aq.test(a.charAt(c));)c++;return c>=b?a:a.substr(0,c)}
function Pq(a,b){Gq!==b&&(Gq=Hq.font=b);return Hq.measureText(a).width}function Oq(a){if(null!==a.rf.ik)return a.rf.ik;var b=a.Cg;Gq!==b&&(Gq=Hq.font=b);var c=0;void 0!==Bq[b]&&5E3>Cq?c=Bq[b]:(c=1.3*Hq.measureText("M").width,Bq[b]=c,Cq++);return a.rf.ik=c}function Qq(a){Gq!==a&&(Gq=Hq.font=a);var b=0;void 0!==Dq[a]&&5E3>Eq?b=Dq[a]:(b=Hq.measureText(Fq).width,Dq[a]=b,Eq++);return b}
function Nq(a,b,c){var d=a.Vd.replace(/\r\n/g,"\n").replace(/\r/g,"\n"),e=a.vi+a.wi,e=Math.max(0,Oq(a)+e);if(0===d.length)return c.ff=0,a.we=1,e;if(!a.$x){var g=d.indexOf("\n",0);0<=g&&(d=d.substr(0,g))}for(var g=0,h=a.we=0,k=-1,l=!1;!l;)k=d.indexOf("\n",h),-1===k&&(k=d.length,l=!0),h<=k&&(h=d.substr(h,k-h),a.Kk!==Iq?(c.wj=0,h=Mq(a,h,c,b),g+=h.height,a.we+=c.wj):(Mq(a,h,c,b),g+=e,a.we++),a.we===a.xh&&(l=!0)),h=k+1;return a.sw=g}
D.defineProperty(pa,{XB:"textValidation"},function(){return this.Yl},function(a){var b=this.Yl;b!==a&&(null!==a&&D.h(a,"function",pa,"textValidation"),this.Yl=a,this.i("textValidation",b,a))});D.defineProperty(pa,{fH:"textEdited"},function(){return this.Ht},function(a){var b=this.Ht;b!==a&&(null!==a&&D.h(a,"function",pa,"textEdited"),this.Ht=a,this.i("textEdited",b,a))});
D.defineProperty(pa,{BM:"spacingAbove"},function(){return this.vi},function(a){var b=this.vi;b!==a&&(v&&D.h(a,"number",pa,"spacingAbove"),this.vi=a,this.i("spacingAbove",b,a))});D.defineProperty(pa,{CM:"spacingBelow"},function(){return this.wi},function(a){var b=this.wi;b!==a&&(v&&D.h(a,"number",pa,"spacingBelow"),this.wi=a,this.i("spacingBelow",b,a))});
D.defineProperty(pa,{EJ:"maxLines"},function(){return this.xh},function(a){var b=this.xh;b!==a&&(v&&D.h(a,"number",pa,"maxLines"),a=Math.floor(a),0>=a&&D.ua(a,"> 0",pa,"maxLines"),this.xh=a,this.i("maxLines",b,a),this.K())});D.w(pa,{cM:"metrics"},function(){return this.rf});D.defineProperty(pa,{jL:"choices"},function(){return this.mr},function(a){var b=this.mr;b!==a&&(null===a||Array.isArray(a)||D.jc(a,"Array",pa,"choices:value"),this.mr=a,this.i("choices",b,a))});
function bq(){this.ff=this.wj=0;this.Ef=[];this.$e=[];this.ik=null}bq.prototype.reset=function(){this.ff=this.wj=0;this.ik=null;this.Ef=[];this.$e=[]};bq.prototype.dq=function(a){this.wj=a.wj;this.ik=a.ik;this.ff=a.ff;this.Ef=D.jm(a.Ef);this.$e=D.jm(a.$e)};D.w(bq,{gL:"arrSize"},function(){return this.Ef});D.w(bq,{hL:"arrText"},function(){return this.$e});D.w(bq,{bM:"maxLineWidth"},function(){return this.ff});D.w(bq,{LL:"fontHeight"},function(){return this.ik});
function Gl(){P.call(this);this.bf=null;this.At="";this.Ck=je;this.gp=rf;this.zi=this.cf=null;this.fp=mc;this.Bg=Yj;this.Qp=null;this.Oz=!1;this.ap=!0;this.qw=!1;this.Ip=null}D.Sa(Gl,P);D.ka("Picture",Gl);Gl.prototype.cloneProtected=function(a){P.prototype.cloneProtected.call(this,a);a.element=this.bf;a.At=this.At;a.Ck=this.Ck.V();a.gp=this.gp;a.Bg=this.Bg;a.cf=this.cf;a.zi=this.zi;a.fp=this.fp.V();a.ap=this.ap;a.Ip=this.Ip};
Gl.prototype.qc=function(a){a===Yj||a===ak||a===bk?this.bJ=a:P.prototype.qc.call(this,a)};Gl.prototype.toString=function(){return"Picture("+this.source+")#"+D.Jd(this)};var Sq=new ma,Tq=0,Al=[];function Uq(){var a=Al;if(0===a.length)for(var b=window.document.getElementsByTagName("canvas"),c=b.length,d=0;d<c;d++){var e=b[d];e.parentElement&&e.parentElement.ia&&a.push(e.parentElement.ia)}return a}var Vq;
Gl.clearCache=Vq=function(a){void 0===a&&(a="");D.h(a,"string",Gl,"clearCache:url");""!==a?Sq[a]&&(delete Sq[a],Tq--):(Sq=new ma,Tq=0)};
D.defineProperty(Gl,{element:"element"},function(){return this.bf},function(a){var b=this.bf;if(b!==a){null===a||a instanceof HTMLImageElement||a instanceof HTMLVideoElement||a instanceof HTMLCanvasElement||D.k("Picture.element must be an instance of Image, Canvas, or Video, not: "+a);this.Oz=a instanceof HTMLCanvasElement;this.bf=a;if(null!==a)if(a instanceof HTMLCanvasElement||!0===a.complete)a.ep instanceof Event&&null!==this.cf&&this.cf(this,a.ep),!0===a.fs&&null!==this.zi&&this.zi(this,a.cA),
a.fs=!0,this.Ea.F()||(jm(this,!1),this.K());else{var c=this;a.kD||(a.addEventListener("load",function(b){Wq(a,b);c.Ea.F()||(jm(c,!1),c.K())}),a.addEventListener("error",function(b){Xq(a,b)}),a.kD=!0)}this.i("element",b,a);this.ra()}});
D.defineProperty(Gl,{source:"source"},function(){return this.At},function(a){var b=this.At;if(b!==a){D.h(a,"string",Gl,"source");this.At=a;var c=Sq,d=this.g;if(void 0!==c[a])var e=c[a].Xp[0].source;else{30<Tq&&(Vq(),c=Sq);var e=D.createElement("img"),g=this;e.addEventListener("load",function(a){Wq(e,a);g.Ea.F()||(jm(g,!1),g.K())});e.addEventListener("error",function(a){Xq(e,a)});e.kD=!0;var h=this.Ip;null!==h&&(e.crossOrigin=h(this));e.src=a;c[a]=new Yq(e);Tq++}null!==d&&xn(d,this);this.element=e;
null!==d&&wn(d,this);this.K();this.ra();this.i("source",b,a)}});function Wq(a,b){a.fs=!0;a.ep=!1;for(var c=null,d=Uq(),e=d.length,g=0;g<e;g++){var h=d[g],k=h.Cp.oa(a.src);if(null!==k)for(var l=k.length,m=0;m<l;m++)c=k[m],h.$z.add(c),h.Ve(),null===a.cA&&(a.cA=b,null!==c.zi&&c.zi(c,b))}}function Xq(a,b){a.ep=b;for(var c=null,d=Uq(),e=d.length,g=0;g<e;g++)if(c=d[g].Cp.oa(a.src),null!==c){for(var h=c.length,k=D.nb(),l=0;l<h;l++)k.push(c[l]);for(l=0;l<h;l++)c=k[l],null!==c.cf&&c.cf(c,b);D.ya(k)}}
D.defineProperty(Gl,{AM:"sourceCrossOrigin"},function(){return this.Ip},function(a){if(this.Ip!==a&&(null!==a&&D.h(a,"function",Gl,"sourceCrossOrigin"),this.Ip=a,null!==this.element)){var b=this.element.src;null===a&&"string"===typeof b?this.element.crossOrigin=null:null!==a&&(this.element.crossOrigin=a(this));this.element.src=b}});
D.defineProperty(Gl,{Uj:"sourceRect"},function(){return this.Ck},function(a){var b=this.Ck;b.O(a)||(D.l(a,C,Gl,"sourceRect"),this.Ck=a=a.V(),this.ra(),this.i("sourceRect",b,a))});D.defineProperty(Gl,{bJ:"imageStretch"},function(){return this.gp},function(a){var b=this.gp;b!==a&&(D.Da(a,P,Gl,"imageStretch"),this.gp=a,this.ra(),this.i("imageStretch",b,a))});
D.defineProperty(Gl,{ou:"flip"},function(){return this.Bg},function(a){var b=this.Bg;b!==a&&(D.Da(a,P,Gl,"flip"),this.Bg=a,this.ra(),this.i("flip",b,a))});D.defineProperty(Gl,{NL:"imageAlignment"},function(){return this.fp},function(a){D.l(a,S,Gl,"imageAlignment");var b=this.fp;b.O(a)||(this.fp=a=a.V(),this.K(),this.i("imageAlignment",b,a))});
D.defineProperty(Gl,{OA:"errorFunction"},function(){return this.cf},function(a){var b=this.cf;b!==a&&(null!==a&&D.h(a,"function",Gl,"errorFunction"),this.cf=a,this.i("errorFunction",b,a))});D.defineProperty(Gl,{KM:"successFunction"},function(){return this.zi},function(a){var b=this.zi;b!==a&&(null!==a&&D.h(a,"function",Gl,"successFunction"),this.zi=a,this.i("successFunction",b,a))});
Gl.prototype.Rk=function(a,b){var c=this.bf;if(null!==c){var d=c.src;null!==d&&""!==d||D.k('Element has no source ("src") attribute: '+c);if(!(c.ep instanceof Event)&&!1!==c.fs){var d=this.Ga,e=0,g=0,h=this.Oz,k=h?+c.width:c.naturalWidth,h=h?+c.height:c.naturalHeight;void 0===k&&c.videoWidth&&(k=c.videoWidth);void 0===h&&c.videoHeight&&(h=c.videoHeight);k=k||d.width;h=h||d.height;if(0!==k&&0!==h){var l=k,m=h;this.Uj.F()&&(e=this.Ck.x,g=this.Ck.y,k=this.Ck.width,h=this.Ck.height);var n=k,p=h,q=this.gp,
r=this.fp;switch(q){case Yj:if(this.Uj.F())break;n>=d.width&&(e=e+r.offsetX+(n*r.x-d.width*r.x));p>=d.height&&(g=g+r.offsetY+(p*r.y-d.height*r.y));k=Math.min(d.width,n);h=Math.min(d.height,p);break;case rf:n=d.width;p=d.height;break;case ak:case bk:var s=0;q===ak?(s=Math.min(d.height/p,d.width/n),n*=s,p*=s):q===bk&&(s=Math.max(d.height/p,d.width/n),n*=s,p*=s,n>=d.width&&(e=(e+r.offsetX+(n*r.x-d.width*r.x)/n)*k),p>=d.height&&(g=(g+r.offsetY+(p*r.y-d.height*r.y)/p)*h),k*=1/(n/d.width),h*=1/(p/d.height),
n=d.width,p=d.height)}var q=this.Fj()*b.scale,u=k*h/(n*q*p*q),s=Sq[this.source],q=null;if(c.fs&&void 0!==s&&16<u){2>s.Xp.length&&(Zq(s,4,l,m),Zq(s,16,l,m));for(var l=s.Xp,m=l.length,q=l[0],t=0;t<m;t++)if(l[t].Fq*l[t].Fq<u)q=l[t];else break}if(!b.Kr){if(null===this.Qp)if(null===this.bf)this.Qp=!1;else{l=(new ja(null)).Qk;l.drawImage(this.bf,0,0);try{l.getImageData(0,0,1,1).data[3]&&(this.Qp=!1),this.Qp=!1}catch(y){this.Qp=!0}}if(this.Qp)return}l=0;n<d.width&&(l=r.offsetX+(d.width*r.x-n*r.x));m=0;p<
d.height&&(m=r.offsetY+(d.height*r.y-p*r.y));switch(this.ou){case Ao:a.translate(Math.min(d.width,n),0);a.scale(-1,1);break;case zo:a.translate(0,Math.min(d.height,p));a.scale(1,-1);break;case Bo:a.translate(Math.min(d.width,n),Math.min(d.height,p)),a.scale(-1,-1)}if(b.Gj("pictureRatioOptimization")&&!b.Pz&&void 0!==s&&null!==q&&1!==q.Fq){a.save();s=q.Fq;try{a.drawImage(q.source,e/s,g/s,Math.min(q.source.width,k/s),Math.min(q.source.height,h/s),l,m,Math.min(d.width,n),Math.min(d.height,p))}catch(w){v&&
this.ap&&D.trace(w.toString()),this.ap=!1}a.restore()}else try{a.drawImage(c,e,g,k,h,l,m,Math.min(d.width,n),Math.min(d.height,p))}catch(B){v&&this.ap&&D.trace(B.toString()),this.ap=!1}switch(this.ou){case Ao:a.scale(-1,1);a.translate(-Math.min(d.width,n),0);break;case zo:a.scale(1,-1);a.translate(0,-Math.min(d.height,p));break;case Bo:a.scale(-1,-1),a.translate(-Math.min(d.width,n),-Math.min(d.height,p))}}}}};D.w(Gl,{Ga:"naturalBounds"},function(){return this.ed});
Gl.prototype.io=function(a,b,c,d){var e=this.Ea,g=Mo(this,!0),h=this.bf,k=this.Oz;if(k||!this.qw&&h&&h.complete)this.qw=!0;null===h&&(isFinite(e.width)||(a=0),isFinite(e.height)||(b=0));isFinite(e.width)||g===rf||g===yo?(isFinite(a)||(a=this.Uj.F()?this.Uj.width:k?+h.width:h.naturalWidth),c=0):null!==h&&!1!==this.qw&&(a=this.Uj.F()?this.Uj.width:k?+h.width:h.naturalWidth);isFinite(e.height)||g===rf||g===xo?(isFinite(b)||(b=this.Uj.F()?this.Uj.height:k?+h.height:h.naturalHeight),d=0):null!==h&&!1!==
this.qw&&(b=this.Uj.F()?this.Uj.height:k?+h.height:h.naturalHeight);isFinite(e.width)&&(a=e.width);isFinite(e.height)&&(b=e.height);e=this.of;g=this.bh;c=Math.max(c,g.width);d=Math.max(d,g.height);a=Math.min(e.width,a);b=Math.min(e.height,b);a=Math.max(c,a);b=Math.max(d,b);null===h||h.complete||(isFinite(a)||(a=0),isFinite(b)||(b=0));Cb(this.ed,a,b);Jo(this,0,0,a,b)};Gl.prototype.yj=function(a,b,c,d){Oo(this,a,b,c,d)};function Yq(a){this.Xp=[new $q(a,1)]}
function Zq(a,b,c,d){var e=new ja(null),g=e.Qk,h=1/b;e.width=c/b;e.height=d/b;b=new $q(e.Wd,b);c=a.Xp[a.Xp.length-1];g.setTransform(h*c.Fq,0,0,h*c.Fq,0,0);g.drawImage(c.source,0,0);a.Xp.push(b)}function $q(a,b){this.source=a;this.Fq=b}function Fa(){this.q=new Ve;this.fc=null}f=Fa.prototype;f.reset=function(){this.q=new Ve;this.fc=null};
function T(a,b,c,d,e,g){null===a.q&&D.k("StreamGeometryContext has been closed");void 0!==e&&!0===e?(null===a.fc&&D.k("Need to call beginFigure first"),d=new $f(Ef),d.G=b,d.H=c,a.fc.Eb.add(d)):(a.fc=new Xe,a.fc.ma=b,a.fc.ja=c,a.fc.Bu=d,a.q.nc.add(a.fc));void 0!==g&&(a.fc.mp=g)}function X(a){null===a.q&&D.k("StreamGeometryContext has been closed");null===a.fc&&D.k("Need to call beginFigure first");var b=a.fc.Eb.length;0<b&&a.fc.Eb.ea(b-1).close()}
function Bf(a){null===a.q&&D.k("StreamGeometryContext has been closed");null===a.fc&&D.k("Need to call beginFigure first");0<a.fc.Eb.length&&(a.fc.Bu=!0)}f.kb=function(a){null===this.q&&D.k("StreamGeometryContext has been closed");null===this.fc&&D.k("Need to call beginFigure first");this.fc.$k=a};f.moveTo=function(a,b,c){void 0===c&&(c=!1);null===this.q&&D.k("StreamGeometryContext has been closed");null===this.fc&&D.k("Need to call beginFigure first");var d=new $f(Ef);d.G=a;d.H=b;c&&d.close();this.fc.Eb.add(d)};
f.lineTo=function(a,b,c){void 0===c&&(c=!1);null===this.q&&D.k("StreamGeometryContext has been closed");null===this.fc&&D.k("Need to call beginFigure first");var d=new $f(wf);d.G=a;d.H=b;c&&d.close();this.fc.Eb.add(d)};function U(a,b,c,d,e,g,h,k){void 0===k&&(k=!1);null===a.q&&D.k("StreamGeometryContext has been closed");null===a.fc&&D.k("Need to call beginFigure first");var l=new $f(Ff);l.Cc=b;l.Zc=c;l.Uh=d;l.Vh=e;l.G=g;l.H=h;k&&l.close();a.fc.Eb.add(l)}
function zf(a,b,c,d,e){var g;void 0===g&&(g=!1);null===a.q&&D.k("StreamGeometryContext has been closed");null===a.fc&&D.k("Need to call beginFigure first");var h=new $f(Sf);h.Cc=b;h.Zc=c;h.G=d;h.H=e;g&&h.close();a.fc.Eb.add(h)}f.arcTo=function(a,b,c,d,e,g,h){void 0===g&&(g=0);void 0===h&&(h=!1);null===this.q&&D.k("StreamGeometryContext has been closed");null===this.fc&&D.k("Need to call beginFigure first");var k=new $f(Tf);k.Me=a;k.Df=b;k.pa=c;k.va=d;k.radiusX=e;k.radiusY=0!==g?g:e;h&&k.close();this.fc.Eb.add(k)};
function Af(a,b,c,d,e,g,h,k){var l;void 0===l&&(l=!1);null===a.q&&D.k("StreamGeometryContext has been closed");null===a.fc&&D.k("Need to call beginFigure first");b=new $f(Uf,h,k,b,c,d,e,g);l&&b.close();a.fc.Eb.add(b)}function ar(a){a=br(a);var b=D.nb();b[0]=a[0];for(var c=1,d=1;d<a.length;)b[c]=a[d],b[c+1]=a[d],b[c+2]=a[d+1],d+=2,c+=3;D.ya(a);return b}
function br(a){var b=cr(a),c=D.nb(),d=Math.floor(b.length/2),e=b.length-1;a=0===a%2?2:1;for(var g=0;g<e;g++){var h=b[g],k=b[g+1],l=b[(d+g-1)%e],m=b[(d+g+a)%e];c[2*g]=h;c[2*g+1]=Ne(h.x,h.y,l.x,l.y,k.x,k.y,m.x,m.y,new N)}c[c.length]=c[0];D.ya(b);return c}function cr(a){for(var b=D.nb(),c=1.5*Math.PI,d=0,e=0;e<a;e++)d=2*Math.PI/a*e+c,b[e]=new N(.5+.5*Math.cos(d),.5+.5*Math.sin(d));b.push(b[0]);return b}
var qq={None:"Rectangle",Rectangle:function(a,b,c){a=new Ve;a.type=tf;a.ma=0;a.ja=0;a.G=b;a.H=c;return a},Square:function(a,b,c){a=new Ve;a.me=ak;a.type=tf;a.ma=0;a.ja=0;a.G=Math.min(b,c);a.H=Math.min(b,c);return a},Ellipse:function(a,b,c){a=new Ve;a.type=uf;a.ma=0;a.ja=0;a.G=b;a.H=c;a.C=ke;a.D=le;return a},Circle:function(a,b,c){a=new Ve;a.me=ak;a.type=uf;a.ma=0;a.ja=0;a.G=Math.min(b,c);a.H=Math.min(b,c);a.C=ke;a.D=le;return a},Connector:"Ellipse",TriangleRight:function(a,b,c){a=new Ve;var d=new Xe,
e=new $f;e.G=b;e.H=.5*c;d.Eb.add(e);b=new $f;b.G=0;b.H=c;d.Eb.add(b.close());a.nc.add(d);a.C=new S(0,.25);a.D=new S(.5,.75);return a},TriangleDown:function(a,b,c){a=new Ve;var d=new Xe,e=new $f;e.G=b;e.H=0;d.Eb.add(e);e=new $f;e.G=.5*b;e.H=c;d.Eb.add(e.close());a.nc.add(d);a.C=new S(.25,0);a.D=new S(.75,.5);return a},TriangleLeft:function(a,b,c){a=new Ve;var d=new Xe;d.ma=b;d.ja=c;var e=new $f;e.G=0;e.H=.5*c;d.Eb.add(e);c=new $f;c.G=b;c.H=0;d.Eb.add(c.close());a.nc.add(d);a.C=new S(.5,.25);a.D=new S(1,
.75);return a},TriangleUp:function(a,b,c){a=new Ve;var d=new Xe;d.ma=b;d.ja=c;var e=new $f;e.G=0;e.H=c;d.Eb.add(e);c=new $f;c.G=.5*b;c.H=0;d.Eb.add(c.close());a.nc.add(d);a.C=new S(.25,.5);a.D=new S(.75,1);return a},Line1:function(a,b,c){a=new Ve;a.type=sf;a.ma=0;a.ja=0;a.G=b;a.H=c;return a},Line2:function(a,b,c){a=new Ve;a.type=sf;a.ma=b;a.ja=0;a.G=0;a.H=c;return a},MinusLine:"LineH",LineH:function(a,b,c){a=new Ve;a.type=sf;a.ma=0;a.ja=c/2;a.G=b;a.H=c/2;return a},LineV:function(a,b,c){a=new Ve;a.type=
sf;a.ma=b/2;a.ja=0;a.G=b/2;a.H=c;return a},BarH:"Rectangle",BarV:"Rectangle",Curve1:function(a,b,c){a=D.v();T(a,0,0,!1);U(a,Jd*b,0,1*b,(1-Jd)*c,b,c);b=a.q;D.u(a);return b},Curve2:function(a,b,c){a=D.v();T(a,0,0,!1);U(a,0,Jd*c,(1-Jd)*b,c,b,c);b=a.q;D.u(a);return b},Curve3:function(a,b,c){a=D.v();T(a,1*b,0,!1);U(a,1*b,Jd*c,Jd*b,1*c,0,1*c);b=a.q;D.u(a);return b},Curve4:function(a,b,c){a=D.v();T(a,1*b,0,!1);U(a,(1-Jd)*b,0,0,(1-Jd)*c,0,1*c);b=a.q;D.u(a);return b},Alternative:"Triangle",Merge:"Triangle",
Triangle:function(a,b,c){a=D.v();T(a,.5*b,0*c,!0);a.lineTo(0*b,1*c);a.lineTo(1*b,1*c,!0);b=a.q;b.C=new S(.25,.5);b.D=new S(.75,1);D.u(a);return b},Decision:"Diamond",Diamond:function(a,b,c){a=D.v();T(a,.5*b,0,!0);a.lineTo(0,.5*c);a.lineTo(.5*b,1*c);a.lineTo(1*b,.5*c,!0);b=a.q;b.C=new S(.25,.25);b.D=new S(.75,.75);D.u(a);return b},Pentagon:function(a,b,c){var d=cr(5);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;5>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.2,.22);b.D=new S(.8,
.9);D.u(a);return b},DataTransmission:"Hexagon",Hexagon:function(a,b,c){var d=cr(6);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;6>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.07,.25);b.D=new S(.93,.75);D.u(a);return b},Heptagon:function(a,b,c){var d=cr(7);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;7>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.2,.15);b.D=new S(.8,.85);D.u(a);return b},Octagon:function(a,b,c){var d=cr(8);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);
for(var e=1;8>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.15,.15);b.D=new S(.85,.85);D.u(a);return b},Nonagon:function(a,b,c){var d=cr(9);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;9>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.17,.13);b.D=new S(.82,.82);D.u(a);return b},Decagon:function(a,b,c){var d=cr(10);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;10>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.16,.16);b.D=new S(.84,.84);D.u(a);return b},
Dodecagon:function(a,b,c){var d=cr(12);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;12>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.16,.16);b.D=new S(.84,.84);D.u(a);return b},FivePointedStar:function(a,b,c){var d=br(5);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;10>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.312,.383);b.D=new S(.693,.765);D.u(a);return b},SixPointedStar:function(a,b,c){var d=br(6);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;12>e;e++)a.lineTo(d[e].x*
b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.17,.251);b.D=new S(.833,.755);D.u(a);return b},SevenPointedStar:function(a,b,c){var d=br(7);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;14>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.363,.361);b.D=new S(.641,.709);D.u(a);return b},EightPointedStar:function(a,b,c){var d=br(8);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;16>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.252,.255);b.D=new S(.75,.75);D.u(a);return b},NinePointedStar:function(a,
b,c){var d=br(9);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;18>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.355,.361);b.D=new S(.645,.651);D.u(a);return b},TenPointedStar:function(a,b,c){var d=br(10);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;20>e;e++)a.lineTo(d[e].x*b,d[e].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.281,.261);b.D=new S(.723,.748);D.u(a);return b},FivePointedBurst:function(a,b,c){var d=ar(5);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)U(a,d[e].x*
b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.312,.383);b.D=new S(.693,.765);D.u(a);return b},SixPointedBurst:function(a,b,c){var d=ar(6);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)U(a,d[e].x*b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.17,.251);b.D=new S(.833,.755);D.u(a);return b},SevenPointedBurst:function(a,b,c){var d=ar(7);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)U(a,d[e].x*
b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.363,.361);b.D=new S(.641,.709);D.u(a);return b},EightPointedBurst:function(a,b,c){var d=ar(8);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)U(a,d[e].x*b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.252,.255);b.D=new S(.75,.75);D.u(a);return b},NinePointedBurst:function(a,b,c){var d=ar(9);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)U(a,d[e].x*
b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.355,.361);b.D=new S(.645,.651);D.u(a);return b},TenPointedBurst:function(a,b,c){var d=ar(10);a=D.v();T(a,d[0].x*b,d[0].y*c,!0);for(var e=1;e<d.length;e+=3)U(a,d[e].x*b,d[e].y*c,d[e+1].x*b,d[e+1].y*c,d[e+2].x*b,d[e+2].y*c);D.ya(d);X(a);b=a.q;b.C=new S(.281,.261);b.D=new S(.723,.748);D.u(a);return b},Cloud:function(a,b,c){a=D.v();T(a,.08034461*b,.1944299*c,!0);U(a,-.09239631*b,.07836421*c,.1406031*b,-.0542823*c,.2008615*
b,.05349299*c);U(a,.2450511*b,-.00697547*c,.3776197*b,-.01112067*c,.4338609*b,.074219*c);U(a,.4539471*b,0,.6066018*b,-.02526587*c,.6558228*b,.07004196*c);U(a,.6914277*b,-.01904177*c,.8921095*b,-.01220843*c,.8921095*b,.08370865*c);U(a,1.036446*b,.04105738*c,1.020377*b,.3022052*c,.9147671*b,.3194596*c);U(a,1.04448*b,.360238*c,.992256*b,.5219009*c,.9082935*b,.562044*c);U(a,1.032337*b,.5771781*c,1.018411*b,.8120651*c,.9212406*b,.8217117*c);U(a,1.028411*b,.9571472*c,.8556702*b,1.052487*c,.7592566*b,.9156953*
c);U(a,.7431877*b,1.009325*c,.5624123*b,1.021761*c,.5101666*b,.9310455*c);U(a,.4820677*b,1.031761*c,.3030112*b,1.002796*c,.2609328*b,.9344623*c);U(a,.2329994*b,1.01518*c,.03213784*b,1.01518*c,.08034461*b,.870098*c);U(a,-.02812061*b,.9032597*c,-.01205169*b,.6835638*c,.06829292*b,.6545475*c);U(a,-.01812061*b,.6089503*c,-.00606892*b,.4555777*c,.06427569*b,.4265613*c);U(a,-.01606892*b,.3892545*c,-.01205169*b,.1944299*c,.08034461*b,.1944299*c);X(a);b=a.q;b.C=new S(.1,.1);b.D=new S(.9,.9);D.u(a);return b},
Gate:"Crescent",Crescent:function(a,b,c){a=D.v();T(a,0,0,!0);U(a,1*b,0,1*b,1*c,0,1*c);U(a,.5*b,.75*c,.5*b,.25*c,0,0);X(a);b=a.q;b.C=new S(.511,.19);b.D=new S(.776,.76);D.u(a);return b},FramedRectangle:function(a,b,c){var d=D.v(),e=a?a.Qc:NaN;a=a?a.Vu:NaN;isNaN(e)&&(e=.1);isNaN(a)&&(a=.1);T(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c,!0);T(d,e*b,a*c,!1,!0);d.lineTo(e*b,(1-a)*c);d.lineTo((1-e)*b,(1-a)*c);d.lineTo((1-e)*b,a*c,!0);b=d.q;b.C=new S(e,a);b.D=new S(1-e,1-a);D.u(d);return b},
Delay:"HalfEllipse",HalfEllipse:function(a,b,c){a=D.v();T(a,0,0,!0);U(a,Jd*b,0,1*b,(.5-Jd/2)*c,1*b,.5*c);U(a,1*b,(.5+Jd/2)*c,Jd*b,1*c,0,1*c);X(a);b=a.q;b.C=new S(0,.2);b.D=new S(.75,.8);D.u(a);return b},Heart:function(a,b,c){a=D.v();T(a,.5*b,1*c,!0);U(a,.1*b,.8*c,0,.5*c,0*b,.3*c);U(a,0*b,0,.45*b,0,.5*b,.3*c);U(a,.55*b,0,1*b,0,1*b,.3*c);U(a,b,.5*c,.9*b,.8*c,.5*b,1*c);X(a);b=a.q;b.C=new S(.15,.29);b.D=new S(.86,.68);D.u(a);return b},Spade:function(a,b,c){a=D.v();T(a,.5*b,0,!0);a.lineTo(.51*b,.01*c);
U(a,.6*b,.2*c,b,.25*c,b,.5*c);U(a,b,.8*c,.6*b,.8*c,.55*b,.7*c);U(a,.5*b,.75*c,.55*b,.95*c,.75*b,c);a.lineTo(.25*b,c);U(a,.45*b,.95*c,.5*b,.75*c,.45*b,.7*c);U(a,.4*b,.8*c,0,.8*c,0,.5*c);U(a,0,.25*c,.4*b,.2*c,.49*b,.01*c);X(a);b=a.q;b.C=new S(.19,.26);b.D=new S(.8,.68);D.u(a);return b},Club:function(a,b,c){a=D.v();T(a,.4*b,.6*c,!0);U(a,.5*b,.75*c,.45*b,.95*c,.15*b,1*c);a.lineTo(.85*b,c);U(a,.55*b,.95*c,.5*b,.75*c,.6*b,.6*c);var d=.2,e=.3,g=0,h=4*(Math.SQRT2-1)/3*d;U(a,(.5-d+e)*b,(.5+h+g)*c,(.5-h+e)*
b,(.5+d+g)*c,(.5+e)*b,(.5+d+g)*c);U(a,(.5+h+e)*b,(.5+d+g)*c,(.5+d+e)*b,(.5+h+g)*c,(.5+d+e)*b,(.5+g)*c);U(a,(.5+d+e)*b,(.5-h+g)*c,(.5+h+e)*b,(.5-d+g)*c,(.5+e)*b,(.5-d+g)*c);U(a,(.5-h+e)*b,(.5-d+g)*c,(.5-d+e+.05)*b,(.5-h+g-.02)*c,.65*b,.36771243*c);d=.2;e=0;g=-.3;h=4*(Math.SQRT2-1)/3*d;U(a,(.5+h+e)*b,(.5+d+g)*c,(.5+d+e)*b,(.5+h+g)*c,(.5+d+e)*b,(.5+g)*c);U(a,(.5+d+e)*b,(.5-h+g)*c,(.5+h+e)*b,(.5-d+g)*c,(.5+e)*b,(.5-d+g)*c);U(a,(.5-h+e)*b,(.5-d+g)*c,(.5-d+e)*b,(.5-h+g)*c,(.5-d+e)*b,(.5+g)*c);U(a,(.5-d+
e)*b,(.5+h+g)*c,(.5-h+e)*b,(.5+d+g)*c,.35*b,.36771243*c);d=.2;e=-.3;g=0;h=4*(Math.SQRT2-1)/3*d;U(a,(.5+d+e-.05)*b,(.5-h+g-.02)*c,(.5+h+e)*b,(.5-d+g)*c,(.5+e)*b,(.5-d+g)*c);U(a,(.5-h+e)*b,(.5-d+g)*c,(.5-d+e)*b,(.5-h+g)*c,(.5-d+e)*b,(.5+g)*c);U(a,(.5-d+e)*b,(.5+h+g)*c,(.5-h+e)*b,(.5+d+g)*c,(.5+e)*b,(.5+d+g)*c);U(a,(.5+h+e)*b,(.5+d+g)*c,(.5+d+e)*b,(.5+h+g)*c,.4*b,.6*c);X(a);b=a.q;b.C=new S(.06,.39);b.D=new S(.93,.58);D.u(a);return b},Ring:function(a,b,c){a=D.v();var d=4*(Math.SQRT2-1)/3*.5;T(a,b,.5*
c,!0);U(a,b,(.5-d)*c,(.5+d)*b,0,.5*b,0);U(a,(.5-d)*b,0,0,(.5-d)*c,0,.5*c);U(a,0,(.5+d)*c,(.5-d)*b,c,.5*b,c);U(a,(.5+d)*b,c,b,(.5+d)*c,b,.5*c);d=4*(Math.SQRT2-1)/3*.4;T(a,.5*b,.1*c,!0,!0);U(a,(.5+d)*b,.1*c,.9*b,(.5-d)*c,.9*b,.5*c);U(a,.9*b,(.5+d)*c,(.5+d)*b,.9*c,.5*b,.9*c);U(a,(.5-d)*b,.9*c,.1*b,(.5+d)*c,.1*b,.5*c);U(a,.1*b,(.5-d)*c,(.5-d)*b,.1*c,.5*b,.1*c);b=a.q;b.C=new S(.146,.146);b.D=new S(.853,.853);b.me=ak;D.u(a);return b},YinYang:function(a,b,c){var d=.5;a=D.v();d=.5;T(a,.5*b,0,!0);a.arcTo(270,
180,.5*b,.5*b,.5*b);U(a,1*b,d*c,0,d*c,d*b,0,!0);var d=.1,e=.25;T(a,(.5+d)*b,e*c,!0,!0);a.arcTo(0,-360,.5*b,c*e,d*b);X(a);T(a,.5*b,0,!1);a.arcTo(270,-180,.5*b,.5*b,.5*b);a.kb(!1);e=.75;T(a,(.5+d)*b,e*c,!0);a.arcTo(0,360,.5*b,c*e,d*b);X(a);b=a.q;b.me=ak;D.u(a);return b},Peace:function(a,b,c){a=D.v();var d=4*(Math.SQRT2-1)/3*.5;T(a,b,.5*c,!0);U(a,b,(.5-d)*c,(.5+d)*b,0,.5*b,0);U(a,(.5-d)*b,0,0,(.5-d)*c,0,.5*c);U(a,0,(.5+d)*c,(.5-d)*b,c,.5*b,c);U(a,(.5+d)*b,c,b,(.5+d)*c,b,.5*c);d=4*(Math.SQRT2-1)/3*.4;
T(a,.5*b,.1*c,!0,!0);U(a,(.5+d)*b,.1*c,.9*b,(.5-d)*c,.9*b,.5*c);U(a,.9*b,(.5+d)*c,(.5+d)*b,.9*c,.5*b,.9*c);U(a,(.5-d)*b,.9*c,.1*b,(.5+d)*c,.1*b,.5*c);U(a,.1*b,(.5-d)*c,(.5-d)*b,.1*c,.5*b,.1*c);var d=.07,e=0,g=-.707*.11,h=4*(Math.SQRT2-1)/3*d;T(a,(.5+d+e)*b,(.5+g)*c,!0);U(a,(.5+d+e)*b,(.5-h+g)*c,(.5+h+e)*b,(.5-d+g)*c,(.5+e)*b,(.5-d+g)*c);U(a,(.5-h+e)*b,(.5-d+g)*c,(.5-d+e)*b,(.5-h+g)*c,(.5-d+e)*b,(.5+g)*c);U(a,(.5-d+e)*b,(.5+h+g)*c,(.5-h+e)*b,(.5+d+g)*c,(.5+e)*b,(.5+d+g)*c);U(a,(.5+h+e)*b,(.5+d+g)*
c,(.5+d+e)*b,(.5+h+g)*c,(.5+d+e)*b,(.5+g)*c);d=.07;e=-.707*.11;g=.707*.11;h=4*(Math.SQRT2-1)/3*d;T(a,(.5+d+e)*b,(.5+g)*c,!0);U(a,(.5+d+e)*b,(.5-h+g)*c,(.5+h+e)*b,(.5-d+g)*c,(.5+e)*b,(.5-d+g)*c);U(a,(.5-h+e)*b,(.5-d+g)*c,(.5-d+e)*b,(.5-h+g)*c,(.5-d+e)*b,(.5+g)*c);U(a,(.5-d+e)*b,(.5+h+g)*c,(.5-h+e)*b,(.5+d+g)*c,(.5+e)*b,(.5+d+g)*c);U(a,(.5+h+e)*b,(.5+d+g)*c,(.5+d+e)*b,(.5+h+g)*c,(.5+d+e)*b,(.5+g)*c);d=.07;e=.707*.11;g=.707*.11;h=4*(Math.SQRT2-1)/3*d;T(a,(.5+d+e)*b,(.5+g)*c,!0);U(a,(.5+d+e)*b,(.5-h+
g)*c,(.5+h+e)*b,(.5-d+g)*c,(.5+e)*b,(.5-d+g)*c);U(a,(.5-h+e)*b,(.5-d+g)*c,(.5-d+e)*b,(.5-h+g)*c,(.5-d+e)*b,(.5+g)*c);U(a,(.5-d+e)*b,(.5+h+g)*c,(.5-h+e)*b,(.5+d+g)*c,(.5+e)*b,(.5+d+g)*c);U(a,(.5+h+e)*b,(.5+d+g)*c,(.5+d+e)*b,(.5+h+g)*c,(.5+d+e)*b,(.5+g)*c);b=a.q;b.C=new S(.146,.146);b.D=new S(.853,.853);b.me=ak;D.u(a);return b},NotAllowed:function(a,b,c){var d=.5*Jd,e=.5;a=D.v();T(a,.5*b,(.5-e)*c,!0);U(a,(.5-d)*b,(.5-e)*c,(.5-e)*b,(.5-d)*c,(.5-e)*b,.5*c);U(a,(.5-e)*b,(.5+d)*c,(.5-d)*b,(.5+e)*c,.5*b,
(.5+e)*c);U(a,(.5+d)*b,(.5+e)*c,(.5+e)*b,(.5+d)*c,(.5+e)*b,.5*c);U(a,(.5+e)*b,(.5-d)*c,(.5+d)*b,(.5-e)*c,.5*b,(.5-e)*c);var e=.4,d=.4*Jd,g=D.P(),h=D.P(),k=D.P(),l=D.P();Ce(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.42,g,h,k,l,l);var m=D.P(),n=D.P(),p=D.P();Ce(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.58,l,l,p,m,n);var q=D.P(),r=D.P(),s=D.P();Ce(.5,.5+e,.5-d,.5+e,.5-e,.5+d,.5-e,.5,.42,q,r,s,l,l);var u=D.P(),t=D.P(),y=D.P();Ce(.5,.5+e,.5-d,.5+e,.5-e,.5+d,.5-e,.5,.58,l,l,y,u,t);T(a,y.x*b,y.y*c,!0,!0);U(a,u.x*b,
u.y*c,t.x*b,t.y*c,(.5-e)*b,.5*c);U(a,(.5-e)*b,(.5-d)*c,(.5-d)*b,(.5-e)*c,.5*b,(.5-e)*c);U(a,g.x*b,g.y*c,h.x*b,h.y*c,k.x*b,k.y*c);a.lineTo(y.x*b,y.y*c);X(a);T(a,s.x*b,s.y*c,!0,!0);a.lineTo(p.x*b,p.y*c);U(a,m.x*b,m.y*c,n.x*b,n.y*c,(.5+e)*b,.5*c);U(a,(.5+e)*b,(.5+d)*c,(.5+d)*b,(.5+e)*c,.5*b,(.5+e)*c);U(a,q.x*b,q.y*c,r.x*b,r.y*c,s.x*b,s.y*c);X(a);D.A(g);D.A(h);D.A(k);D.A(l);D.A(m);D.A(n);D.A(p);D.A(q);D.A(r);D.A(s);D.A(u);D.A(t);D.A(y);b=a.q;D.u(a);b.me=ak;return b},Fragile:function(a,b,c){a=D.v();T(a,
0,0,!0);a.lineTo(.25*b,0);a.lineTo(.2*b,.15*c);a.lineTo(.3*b,.25*c);a.lineTo(.29*b,.33*c);a.lineTo(.35*b,.25*c);a.lineTo(.3*b,.15*c);a.lineTo(.4*b,0);a.lineTo(1*b,0);U(a,1*b,.25*c,.75*b,.5*c,.55*b,.5*c);a.lineTo(.55*b,.9*c);a.lineTo(.7*b,.9*c);a.lineTo(.7*b,1*c);a.lineTo(.3*b,1*c);a.lineTo(.3*b,.9*c);a.lineTo(.45*b,.9*c);a.lineTo(.45*b,.5*c);U(a,.25*b,.5*c,0,.25*c,0,0);X(a);b=a.q;b.C=new S(.25,0);b.D=new S(.75,.4);D.u(a);return b},HourGlass:function(a,b,c){a=D.v();T(a,.65*b,.5*c,!0);a.lineTo(1*b,
1*c);a.lineTo(0,1*c);a.lineTo(.35*b,.5*c);a.lineTo(0,0);a.lineTo(1*b,0);X(a);b=a.q;D.u(a);return b},Lightning:function(a,b,c){a=D.v();T(a,0*b,.55*c,!0);a.lineTo(.75*b,0);a.lineTo(.25*b,.45*c);a.lineTo(.9*b,.48*c);a.lineTo(.4*b,1*c);a.lineTo(.65*b,.55*c);X(a);b=a.q;D.u(a);return b},Parallelogram1:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.1);var d=D.v();T(d,a*b,0,!0);d.lineTo(1*b,0);d.lineTo((1-a)*b,1*c);d.lineTo(0,1*c);X(d);b=d.q;b.C=new S(a,0);b.D=new S(1-a,1);D.u(d);return b},Input:"Output",Output:function(a,
b,c){a=D.v();T(a,0,1*c,!0);a.lineTo(.1*b,0);a.lineTo(1*b,0);a.lineTo(.9*b,1*c);X(a);b=a.q;b.C=new S(.1,0);b.D=new S(.9,1);D.u(a);return b},Parallelogram2:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.25);var d=D.v();T(d,a*b,0,!0);d.lineTo(1*b,0);d.lineTo((1-a)*b,1*c);d.lineTo(0,1*c);X(d);b=d.q;b.C=new S(a,0);b.D=new S(1-a,1);D.u(d);return b},ThickCross:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.25);var d=D.v();T(d,(.5-a/2)*b,0,!0);d.lineTo((.5+a/2)*b,0);d.lineTo((.5+a/2)*b,(.5-a/2)*c);d.lineTo(1*b,(.5-
a/2)*c);d.lineTo(1*b,(.5+a/2)*c);d.lineTo((.5+a/2)*b,(.5+a/2)*c);d.lineTo((.5+a/2)*b,1*c);d.lineTo((.5-a/2)*b,1*c);d.lineTo((.5-a/2)*b,(.5+a/2)*c);d.lineTo(0,(.5+a/2)*c);d.lineTo(0,(.5-a/2)*c);d.lineTo((.5-a/2)*b,(.5-a/2)*c);X(d);b=d.q;b.C=new S(.5-a/2,.5-a/2);b.D=new S(.5+a/2,.5+a/2);D.u(d);return b},ThickX:function(a,b,c){a=.25/Math.SQRT2;var d=D.v();T(d,.3*b,0,!0);d.lineTo(.5*b,.2*c);d.lineTo(.7*b,0);d.lineTo(1*b,.3*c);d.lineTo(.8*b,.5*c);d.lineTo(1*b,.7*c);d.lineTo(.7*b,1*c);d.lineTo(.5*b,.8*
c);d.lineTo(.3*b,1*c);d.lineTo(0,.7*c);d.lineTo(.2*b,.5*c);d.lineTo(0,.3*c);X(d);b=d.q;b.C=new S(.5-a,.5-a);b.D=new S(.5+a,.5+a);D.u(d);return b},ThinCross:function(a,b,c){var d=a?a.Qc:NaN;isNaN(d)&&(d=.1);a=D.v();T(a,(.5-d/2)*b,0,!0);a.lineTo((.5+d/2)*b,0);a.lineTo((.5+d/2)*b,(.5-d/2)*c);a.lineTo(1*b,(.5-d/2)*c);a.lineTo(1*b,(.5+d/2)*c);a.lineTo((.5+d/2)*b,(.5+d/2)*c);a.lineTo((.5+d/2)*b,1*c);a.lineTo((.5-d/2)*b,1*c);a.lineTo((.5-d/2)*b,(.5+d/2)*c);a.lineTo(0,(.5+d/2)*c);a.lineTo(0,(.5-d/2)*c);a.lineTo((.5-
d/2)*b,(.5-d/2)*c);X(a);b=a.q;D.u(a);return b},ThinX:function(a,b,c){a=D.v();T(a,.1*b,0,!0);a.lineTo(.5*b,.4*c);a.lineTo(.9*b,0);a.lineTo(1*b,.1*c);a.lineTo(.6*b,.5*c);a.lineTo(1*b,.9*c);a.lineTo(.9*b,1*c);a.lineTo(.5*b,.6*c);a.lineTo(.1*b,1*c);a.lineTo(0,.9*c);a.lineTo(.4*b,.5*c);a.lineTo(0,.1*c);X(a);b=a.q;D.u(a);return b},RightTriangle:function(a,b,c){a=D.v();T(a,0,0,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);X(a);b=a.q;b.C=new S(0,.5);b.D=new S(.5,1);D.u(a);return b},RoundedIBeam:function(a,b,c){a=
D.v();T(a,0,0,!0);a.lineTo(1*b,0);U(a,.5*b,.25*c,.5*b,.75*c,1*b,1*c);a.lineTo(0,1*c);U(a,.5*b,.75*c,.5*b,.25*c,0,0);X(a);b=a.q;D.u(a);return b},RoundedRectangle:function(a,b,c){var d=a?a.Qc:NaN;isNaN(d)&&(d=5);d=Math.min(d,b/3);d=Math.min(d,c/3);a=d*Jd;var e=D.v();T(e,d,0,!0);e.lineTo(b-d,0);U(e,b-a,0,b,a,b,d);e.lineTo(b,c-d);U(e,b,c-a,b-a,c,b-d,c);e.lineTo(d,c);U(e,a,c,0,c-a,0,c-d);e.lineTo(0,d);U(e,0,a,a,0,d,0);X(e);b=e.q;1<a?(b.C=new S(0,0,a,a),b.D=new S(1,1,-a,-a)):(b.C=ec,b.D=vc);D.u(e);return b},
Border:function(a,b,c){var d=a?a.Qc:NaN;isNaN(d)&&(d=5);d=Math.min(d,b/3);d=Math.min(d,c/3);a=D.v();T(a,d,0,!0);a.lineTo(b-d,0);U(a,b-0,0,b,0,b,d);a.lineTo(b,c-d);U(a,b,c-0,b-0,c,b-d,c);a.lineTo(d,c);U(a,0,c,0,c-0,0,c-d);a.lineTo(0,d);U(a,0,0,0,0,d,0);X(a);b=a.q;b.C=ec;b.D=vc;D.u(a);return b},SquareIBeam:function(a,b,c){var d=a?a.Qc:NaN;isNaN(d)&&(d=.2);a=D.v();T(a,0,0,!0);a.lineTo(1*b,0);a.lineTo(1*b,d*c);a.lineTo((.5+d/2)*b,d*c);a.lineTo((.5+d/2)*b,(1-d)*c);a.lineTo(1*b,(1-d)*c);a.lineTo(1*b,1*
c);a.lineTo(0,1*c);a.lineTo(0,(1-d)*c);a.lineTo((.5-d/2)*b,(1-d)*c);a.lineTo((.5-d/2)*b,d*c);a.lineTo(0,d*c);X(a);b=a.q;D.u(a);return b},Trapezoid:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.2);var d=D.v();T(d,a*b,0,!0);d.lineTo((1-a)*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);X(d);b=d.q;b.C=new S(a,0);b.D=new S(1-a,1);D.u(d);return b},ManualLoop:"ManualOperation",ManualOperation:function(a,b,c){var d=a?a.Qc:NaN;isNaN(d)&&(d=0);a=D.v();T(a,d,0,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(.9*b,1*c);a.lineTo(.1*
b,1*c);X(a);b=a.q;b.C=new S(.1,0);b.D=new S(.9,1);D.u(a);return b},GenderMale:function(a,b,c){a=D.v();var d=.4*Jd,e=.4,g=D.P(),h=D.P(),k=D.P(),l=D.P();T(a,(.5-e)*b,.5*c,!0);U(a,(.5-e)*b,(.5-d)*c,(.5-d)*b,(.5-e)*c,.5*b,(.5-e)*c);Ce(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.44,k,l,h,g,g);U(a,k.x*b,k.y*c,l.x*b,l.y*c,h.x*b,h.y*c);var m=D.Fb(h.x,h.y);Ce(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.56,g,g,h,k,l);var n=D.Fb(h.x,h.y);a.lineTo((.1*m.x+.855)*b,.1*m.y*c);a.lineTo(.85*b,.1*m.y*c);a.lineTo(.85*b,0);a.lineTo(1*
b,0);a.lineTo(1*b,.15*c);a.lineTo((.1*n.x+.9)*b,.15*c);a.lineTo((.1*n.x+.9)*b,(.1*n.y+.05*.9)*c);a.lineTo(n.x*b,n.y*c);U(a,k.x*b,k.y*c,l.x*b,l.y*c,(.5+e)*b,.5*c);U(a,(.5+e)*b,(.5+d)*c,(.5+d)*b,(.5+e)*c,.5*b,(.5+e)*c);U(a,(.5-d)*b,(.5+e)*c,(.5-e)*b,(.5+d)*c,(.5-e)*b,.5*c);e=.35;d=.35*Jd;T(a,.5*b,(.5-e)*c,!0,!0);U(a,(.5-d)*b,(.5-e)*c,(.5-e)*b,(.5-d)*c,(.5-e)*b,.5*c);U(a,(.5-e)*b,(.5+d)*c,(.5-d)*b,(.5+e)*c,.5*b,(.5+e)*c);U(a,(.5+d)*b,(.5+e)*c,(.5+e)*b,(.5+d)*c,(.5+e)*b,.5*c);U(a,(.5+e)*b,(.5-d)*c,(.5+
d)*b,(.5-e)*c,.5*b,(.5-e)*c);T(a,(.5-e)*b,.5*c,!0);D.A(g);D.A(h);D.A(k);D.A(l);D.A(m);D.A(n);b=a.q;b.C=new S(.202,.257);b.D=new S(.692,.839);b.me=ak;D.u(a);return b},GenderFemale:function(a,b,c){a=D.v();var d=.375,e=0,g=-.125,h=4*(Math.SQRT2-1)/3*d;T(a,(.525+e)*b,(.5+d+g)*c,!0);U(a,(.5+h+e)*b,(.5+d+g)*c,(.5+d+e)*b,(.5+h+g)*c,(.5+d+e)*b,(.5+g)*c);U(a,(.5+d+e)*b,(.5-h+g)*c,(.5+h+e)*b,(.5-d+g)*c,(.5+e)*b,(.5-d+g)*c);U(a,(.5-h+e)*b,(.5-d+g)*c,(.5-d+e)*b,(.5-h+g)*c,(.5-d+e)*b,(.5+g)*c);U(a,(.5-d+e)*b,
(.5+h+g)*c,(.5-h+e)*b,(.5+d+g)*c,(.475+e)*b,(.5+d+g)*c);a.lineTo(.475*b,.85*c);a.lineTo(.425*b,.85*c);a.lineTo(.425*b,.9*c);a.lineTo(.475*b,.9*c);a.lineTo(.475*b,1*c);a.lineTo(.525*b,1*c);a.lineTo(.525*b,.9*c);a.lineTo(.575*b,.9*c);a.lineTo(.575*b,.85*c);a.lineTo(.525*b,.85*c);X(a);d=.325;e=0;g=-.125;h=4*(Math.SQRT2-1)/3*d;T(a,(.5+d+e)*b,(.5+g)*c,!0,!0);U(a,(.5+d+e)*b,(.5+h+g)*c,(.5+h+e)*b,(.5+d+g)*c,(.5+e)*b,(.5+d+g)*c);U(a,(.5-h+e)*b,(.5+d+g)*c,(.5-d+e)*b,(.5+h+g)*c,(.5-d+e)*b,(.5+g)*c);U(a,(.5-
d+e)*b,(.5-h+g)*c,(.5-h+e)*b,(.5-d+g)*c,(.5+e)*b,(.5-d+g)*c);U(a,(.5+h+e)*b,(.5-d+g)*c,(.5+d+e)*b,(.5-h+g)*c,(.5+d+e)*b,(.5+g)*c);T(a,(.525+e)*b,(.5+d+g)*c,!0);b=a.q;b.C=new S(.232,.136);b.D=new S(.782,.611);b.me=ak;D.u(a);return b},PlusLine:function(a,b,c){a=D.v();T(a,0,.5*c,!1);a.lineTo(1*b,.5*c);a.moveTo(.5*b,0);a.lineTo(.5*b,1*c);b=a.q;D.u(a);return b},XLine:function(a,b,c){a=D.v();T(a,0,1*c,!1);a.lineTo(1*b,0);a.moveTo(0,0);a.lineTo(1*b,1*c);b=a.q;D.u(a);return b},AsteriskLine:function(a,b,c){a=
D.v();var d=.2/Math.SQRT2;T(a,d*b,(1-d)*c,!1);a.lineTo((1-d)*b,d*c);a.moveTo(d*b,d*c);a.lineTo((1-d)*b,(1-d)*c);a.moveTo(0*b,.5*c);a.lineTo(1*b,.5*c);a.moveTo(.5*b,0*c);a.lineTo(.5*b,1*c);b=a.q;D.u(a);return b},CircleLine:function(a,b,c){var d=.5*Jd;a=D.v();T(a,1*b,.5*c,!1);U(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);U(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);b=a.q;b.C=new S(.146,.146);b.D=new S(.853,.853);b.me=ak;D.u(a);return b},Pie:function(a,
b,c){a=D.v();var d=4*(Math.SQRT2-1)/3*.5;T(a,(.5*Math.SQRT2/2+.5)*b,(.5-.5*Math.SQRT2/2)*c,!0);U(a,.7*b,0*c,.5*b,0*c,.5*b,0*c);U(a,(.5-d+0)*b,0*c,0*b,(.5-d+0)*c,0*b,.5*c);U(a,0*b,(.5+d+0)*c,(.5-d+0)*b,1*c,.5*b,1*c);U(a,(.5+d+0)*b,1*c,1*b,(.5+d+0)*c,1*b,.5*c);a.lineTo(.5*b,.5*c);X(a);b=a.q;D.u(a);return b},PiePiece:function(a,b,c){var d=Jd/Math.SQRT2*.5,e=Math.SQRT2/2,g=1-Math.SQRT2/2;a=D.v();T(a,b,c,!0);U(a,b,(1-d)*c,(e+d)*b,(g+d)*c,e*b,g*c);a.lineTo(0,c);X(a);b=a.q;D.u(a);return b},StopSign:function(a,
b,c){a=1/(Math.SQRT2+2);var d=D.v();T(d,a*b,0,!0);d.lineTo((1-a)*b,0);d.lineTo(1*b,a*c);d.lineTo(1*b,(1-a)*c);d.lineTo((1-a)*b,1*c);d.lineTo(a*b,1*c);d.lineTo(0,(1-a)*c);d.lineTo(0,a*c);X(d);b=d.q;b.C=new S(a/2,a/2);b.D=new S(1-a/2,1-a/2);D.u(d);return b},LogicImplies:function(a,b,c){var d=a?a.Qc:NaN;isNaN(d)&&(d=.2);a=D.v();T(a,(1-d)*b,0*c,!1);a.lineTo(1*b,.5*c);a.lineTo((1-d)*b,c);a.moveTo(0,.5*c);a.lineTo(b,.5*c);b=a.q;b.C=ec;b.D=new S(.8,.5);D.u(a);return b},LogicIff:function(a,b,c){var d=a?a.Qc:
NaN;isNaN(d)&&(d=.2);a=D.v();T(a,(1-d)*b,0*c,!1);a.lineTo(1*b,.5*c);a.lineTo((1-d)*b,c);a.moveTo(0,.5*c);a.lineTo(b,.5*c);a.moveTo(d*b,0);a.lineTo(0,.5*c);a.lineTo(d*b,c);b=a.q;b.C=new S(.2,0);b.D=new S(.8,.5);D.u(a);return b},LogicNot:function(a,b,c){a=D.v();T(a,0,0,!1);a.lineTo(1*b,0);a.lineTo(1*b,1*c);b=a.q;D.u(a);return b},LogicAnd:function(a,b,c){a=D.v();T(a,0,1*c,!1);a.lineTo(.5*b,0);a.lineTo(1*b,1*c);b=a.q;b.C=new S(.25,.5);b.D=new S(.75,1);D.u(a);return b},LogicOr:function(a,b,c){a=D.v();
T(a,0,0,!1);a.lineTo(.5*b,1*c);a.lineTo(1*b,0);b=a.q;b.C=new S(.219,0);b.D=new S(.78,.409);D.u(a);return b},LogicXor:function(a,b,c){a=D.v();T(a,.5*b,0,!1);a.lineTo(.5*b,1*c);a.moveTo(0,.5*c);a.lineTo(1*b,.5*c);var d=.5*Jd;U(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);U(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);b=a.q;b.me=ak;D.u(a);return b},LogicTruth:function(a,b,c){a=D.v();T(a,0,0,!1);a.lineTo(1*b,0);a.moveTo(.5*b,0);a.lineTo(.5*b,1*c);
b=a.q;D.u(a);return b},LogicFalsity:function(a,b,c){a=D.v();T(a,0,1*c,!1);a.lineTo(1*b,1*c);a.moveTo(.5*b,1*c);a.lineTo(.5*b,0);b=a.q;D.u(a);return b},LogicThereExists:function(a,b,c){a=D.v();T(a,0,0,!1);a.lineTo(1*b,0);a.lineTo(1*b,.5*c);a.lineTo(0,.5*c);a.moveTo(1*b,.5*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);b=a.q;D.u(a);return b},LogicForAll:function(a,b,c){a=D.v();T(a,0,0,!1);a.lineTo(.5*b,1*c);a.lineTo(1*b,0);a.moveTo(.25*b,.5*c);a.lineTo(.75*b,.5*c);b=a.q;b.C=new S(.25,0);b.D=new S(.75,.5);D.u(a);
return b},LogicIsDefinedAs:function(a,b,c){a=D.v();T(a,0,0,!1);a.lineTo(b,0);a.moveTo(0,.5*c);a.lineTo(b,.5*c);a.moveTo(0,c);a.lineTo(b,c);b=a.q;b.C=new S(.01,.01);b.D=new S(.99,.49);D.u(a);return b},LogicIntersect:function(a,b,c){var d=.5*Jd;a=D.v();T(a,0,1*c,!1);a.lineTo(0,.5*c);U(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);a.lineTo(1*b,1*c);b=a.q;b.C=new S(0,.5);b.D=vc;D.u(a);return b},LogicUnion:function(a,b,c){var d=.5*Jd;a=D.v();T(a,1*b,0,!1);a.lineTo(1*b,.5*c);U(a,
1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);a.lineTo(0,0);b=a.q;b.C=ec;b.D=new S(1,.5);D.u(a);return b},Arrow:function(a,b,c){var d=a?a.Qc:NaN,e=a?a.Vu:NaN;isNaN(d)&&(d=.3);isNaN(e)&&(e=.3);a=D.v();T(a,0,(.5-e/2)*c,!0);a.lineTo((1-d)*b,(.5-e/2)*c);a.lineTo((1-d)*b,0);a.lineTo(1*b,.5*c);a.lineTo((1-d)*b,1*c);a.lineTo((1-d)*b,(.5+e/2)*c);a.lineTo(0,(.5+e/2)*c);X(a);b=a.q;b.C=new S(0,.5-e/2);d=Ne(0,.5+e/2,1,.5+e/2,1-d,1,1,.5,D.P());b.D=new S(d.x,d.y);D.A(d);D.u(a);return b},
ISOProcess:"Chevron",Chevron:function(a,b,c){a=D.v();T(a,0,0,!0);a.lineTo(.5*b,0);a.lineTo(1*b,.5*c);a.lineTo(.5*b,1*c);a.lineTo(0,1*c);a.lineTo(.5*b,.5*c);X(a);b=a.q;D.u(a);return b},DoubleArrow:function(a,b,c){a=D.v();T(a,0,0,!0);a.lineTo(.3*b,.214*c);a.lineTo(.3*b,0);a.lineTo(1*b,.5*c);a.lineTo(.3*b,1*c);a.lineTo(.3*b,.786*c);a.lineTo(0,1*c);X(a);T(a,.3*b,.214*c,!1);a.lineTo(.3*b,.786*c);a.kb(!1);b=a.q;D.u(a);return b},DoubleEndArrow:function(a,b,c){a=D.v();T(a,1*b,.5*c,!0);a.lineTo(.7*b,1*c);
a.lineTo(.7*b,.7*c);a.lineTo(.3*b,.7*c);a.lineTo(.3*b,1*c);a.lineTo(0,.5*c);a.lineTo(.3*b,0);a.lineTo(.3*b,.3*c);a.lineTo(.7*b,.3*c);a.lineTo(.7*b,0);X(a);b=a.q;c=Ne(0,.5,.3,0,0,.3,.3,.3,D.P());b.C=new S(c.x,c.y);c=Ne(.7,1,1,.5,.7,.7,1,.7,c);b.D=new S(c.x,c.y);D.A(c);D.u(a);return b},IBeamArrow:function(a,b,c){a=D.v();T(a,1*b,.5*c,!0);a.lineTo(.7*b,1*c);a.lineTo(.7*b,.7*c);a.lineTo(.2*b,.7*c);a.lineTo(.2*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(.2*b,0);a.lineTo(.2*b,.3*c);a.lineTo(.7*b,.3*c);
a.lineTo(.7*b,0);X(a);b=a.q;b.C=new S(0,.3);c=Ne(.7,1,1,.5,.7,.7,1,.7,D.P());b.D=new S(c.x,c.y);D.A(c);D.u(a);return b},Pointer:function(a,b,c){a=D.v();T(a,1*b,.5*c,!0);a.lineTo(0,1*c);a.lineTo(.2*b,.5*c);a.lineTo(0,0);X(a);b=a.q;b.C=new S(.2,.35);c=Ne(.2,.65,1,.65,0,1,1,.5,D.P());b.D=new S(c.x,c.y);D.A(c);D.u(a);return b},RoundedPointer:function(a,b,c){a=D.v();T(a,1*b,.5*c,!0);a.lineTo(0,1*c);U(a,.5*b,.75*c,.5*b,.25*c,0,0);X(a);b=a.q;b.C=new S(.4,.35);c=Ne(.2,.65,1,.65,0,1,1,.5,D.P());b.D=new S(c.x,
c.y);D.A(c);D.u(a);return b},SplitEndArrow:function(a,b,c){a=D.v();T(a,1*b,.5*c,!0);a.lineTo(.7*b,1*c);a.lineTo(.7*b,.7*c);a.lineTo(0,.7*c);a.lineTo(.2*b,.5*c);a.lineTo(0,.3*c);a.lineTo(.7*b,.3*c);a.lineTo(.7*b,0);X(a);b=a.q;b.C=new S(.2,.3);c=Ne(.7,1,1,.5,.7,.7,1,.7,D.P());b.D=new S(c.x,c.y);D.A(c);D.u(a);return b},MessageToUser:"SquareArrow",SquareArrow:function(a,b,c){a=D.v();T(a,1*b,.5*c,!0);a.lineTo(.7*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(.7*b,0);X(a);b=a.q;b.C=ec;b.D=new S(.7,1);D.u(a);
return b},Cone1:function(a,b,c){var d=.5*Jd,e=.1*Jd;a=D.v();T(a,0,.9*c,!0);a.lineTo(.5*b,0);a.lineTo(1*b,.9*c);U(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);X(a);b=a.q;b.C=new S(.25,.5);b.D=new S(.75,.97);D.u(a);return b},Cone2:function(a,b,c){a=D.v();T(a,0,.9*c,!0);U(a,(1-.85/.9)*b,1*c,.85/.9*b,1*c,1*b,.9*c);a.lineTo(.5*b,0);a.lineTo(0,.9*c);X(a);T(a,0,.9*c,!1);U(a,(1-.85/.9)*b,.8*c,.85/.9*b,.8*c,1*b,.9*c);a.kb(!1);b=a.q;b.C=new S(.25,.5);b.D=new S(.75,.82);D.u(a);return b},
Cube1:function(a,b,c){a=D.v();T(a,.5*b,1*c,!0);a.lineTo(1*b,.85*c);a.lineTo(1*b,.15*c);a.lineTo(.5*b,0*c);a.lineTo(0*b,.15*c);a.lineTo(0*b,.85*c);X(a);T(a,.5*b,1*c,!1);a.lineTo(.5*b,.3*c);a.lineTo(0,.15*c);a.moveTo(.5*b,.3*c);a.lineTo(1*b,.15*c);a.kb(!1);b=a.q;b.C=new S(0,.3);b.D=new S(.5,.85);D.u(a);return b},Cube2:function(a,b,c){a=D.v();T(a,0,.3*c,!0);a.lineTo(0*b,1*c);a.lineTo(.7*b,c);a.lineTo(1*b,.7*c);a.lineTo(1*b,0*c);a.lineTo(.3*b,0*c);X(a);T(a,0,.3*c,!1);a.lineTo(.7*b,.3*c);a.lineTo(1*b,
0*c);a.moveTo(.7*b,.3*c);a.lineTo(.7*b,1*c);a.kb(!1);b=a.q;b.C=new S(0,.3);b.D=new S(.7,1);D.u(a);return b},MagneticData:"Cylinder1",Cylinder1:function(a,b,c){var d=.5*Jd,e=.1*Jd;a=D.v();T(a,0,.1*c,!0);U(a,0,(.1-e)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.1-e)*c,1*b,.1*c);a.lineTo(b,.9*c);U(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);a.lineTo(0,.1*c);T(a,0,.1*c,!1);U(a,0,(.1+e)*c,(.5-d)*b,.2*c,.5*b,.2*c);U(a,(.5+d)*b,.2*c,1*b,(.1+e)*c,1*b,.1*c);a.kb(!1);b=a.q;b.C=new S(0,
.2);b.D=new S(1,.9);D.u(a);return b},Cylinder2:function(a,b,c){var d=.5*Jd,e=.1*Jd;a=D.v();T(a,0,.9*c,!0);a.lineTo(0,.1*c);U(a,0,(.1-e)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.1-e)*c,1*b,.1*c);a.lineTo(1*b,.9*c);U(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);T(a,0,.9*c,!1);U(a,0,(.9-e)*c,(.5-d)*b,.8*c,.5*b,.8*c);U(a,(.5+d)*b,.8*c,1*b,(.9-e)*c,1*b,.9*c);a.kb(!1);b=a.q;b.C=new S(0,.1);b.D=new S(1,.8);D.u(a);return b},Cylinder3:function(a,b,c){var d=.1*Jd,e=.5*Jd;a=D.v();
T(a,.1*b,0,!0);a.lineTo(.9*b,0);U(a,(.9+d)*b,0,1*b,(.5-e)*c,1*b,.5*c);U(a,1*b,(.5+e)*c,(.9+d)*b,1*c,.9*b,1*c);a.lineTo(.1*b,1*c);U(a,(.1-d)*b,1*c,0,(.5+e)*c,0,.5*c);U(a,0,(.5-e)*c,(.1-d)*b,0,.1*b,0);T(a,.1*b,0,!1);U(a,(.1+d)*b,0,.2*b,(.5-e)*c,.2*b,.5*c);U(a,.2*b,(.5+e)*c,(.1+d)*b,1*c,.1*b,1*c);a.kb(!1);b=a.q;b.C=new S(.2,0);b.D=new S(.9,1);D.u(a);return b},DirectData:"Cylinder4",Cylinder4:function(a,b,c){var d=.1*Jd,e=.5*Jd;a=D.v();T(a,.9*b,0,!0);U(a,(.9+d)*b,0,1*b,(.5-e)*c,1*b,.5*c);U(a,1*b,(.5+
e)*c,(.9+d)*b,1*c,.9*b,1*c);a.lineTo(.1*b,1*c);U(a,(.1-d)*b,1*c,0,(.5+e)*c,0,.5*c);U(a,0,(.5-e)*c,(.1-d)*b,0,.1*b,0);a.lineTo(.9*b,0);T(a,.9*b,0,!1);U(a,(.9-d)*b,0,.8*b,(.5-e)*c,.8*b,.5*c);U(a,.8*b,(.5+e)*c,(.9-d)*b,1*c,.9*b,1*c);a.kb(!1);b=a.q;b.C=new S(.1,0);b.D=new S(.8,1);D.u(a);return b},Prism1:function(a,b,c){a=D.v();T(a,.25*b,.25*c,!0);a.lineTo(.75*b,0);a.lineTo(b,.5*c);a.lineTo(.5*b,c);a.lineTo(0,c);X(a);T(a,.25*b,.25*c,!1);a.lineTo(.5*b,c);a.kb(!1);b=a.q;b.C=new S(.408,.172);b.D=new S(.833,
.662);D.u(a);return b},Prism2:function(a,b,c){a=D.v();T(a,0,.25*c,!0);a.lineTo(.75*b,0);a.lineTo(1*b,.25*c);a.lineTo(.75*b,.75*c);a.lineTo(0,1*c);X(a);T(a,0,c,!1);a.lineTo(.25*b,.5*c);a.lineTo(b,.25*c);a.moveTo(0,.25*c);a.lineTo(.25*b,.5*c);a.kb(!1);b=a.q;b.C=new S(.25,.5);b.D=new S(.75,.75);D.u(a);return b},Pyramid1:function(a,b,c){a=D.v();T(a,.5*b,0,!0);a.lineTo(b,.75*c);a.lineTo(.5*b,1*c);a.lineTo(0,.75*c);X(a);T(a,.5*b,0,!1);a.lineTo(.5*b,1*c);a.kb(!1);b=a.q;b.C=new S(.25,.367);b.D=new S(.75,
.875);D.u(a);return b},Pyramid2:function(a,b,c){a=D.v();T(a,.5*b,0,!0);a.lineTo(b,.85*c);a.lineTo(.5*b,1*c);a.lineTo(0,.85*c);X(a);T(a,.5*b,0,!1);a.lineTo(.5*b,.7*c);a.lineTo(0,.85*c);a.moveTo(.5*b,.7*c);a.lineTo(1*b,.85*c);a.kb(!1);b=a.q;b.C=new S(.25,.367);b.D=new S(.75,.875);D.u(a);return b},Actor:function(a,b,c){var d=.2*Jd,e=.1*Jd,g=.5,h=.1;a=D.v();T(a,g*b,(h+.1)*c,!0);U(a,(g-d)*b,(h+.1)*c,(g-.2)*b,(h+e)*c,(g-.2)*b,h*c);U(a,(g-.2)*b,(h-e)*c,(g-d)*b,(h-.1)*c,g*b,(h-.1)*c);U(a,(g+d)*b,(h-.1)*c,
(g+.2)*b,(h-e)*c,(g+.2)*b,h*c);U(a,(g+.2)*b,(h+e)*c,(g+d)*b,(h+.1)*c,g*b,(h+.1)*c);d=.05;e=Jd*d;T(a,.5*b,.2*c,!0);a.lineTo(.95*b,.2*c);g=.95;h=.25;U(a,(g+e)*b,(h-d)*c,(g+d)*b,(h-e)*c,(g+d)*b,h*c);a.lineTo(1*b,.6*c);a.lineTo(.85*b,.6*c);a.lineTo(.85*b,.35*c);d=.025;e=Jd*d;g=.825;h=.35;U(a,(g+d)*b,(h-e)*c,(g+e)*b,(h-d)*c,g*b,(h-d)*c);U(a,(g-e)*b,(h-d)*c,(g-d)*b,(h-e)*c,(g-d)*b,h*c);a.lineTo(.8*b,1*c);a.lineTo(.55*b,1*c);a.lineTo(.55*b,.7*c);d=.05;e=Jd*d;g=.5;h=.7;U(a,(g+d)*b,(h-e)*c,(g+e)*b,(h-d)*c,
g*b,(h-d)*c);U(a,(g-e)*b,(h-d)*c,(g-d)*b,(h-e)*c,(g-d)*b,h*c);a.lineTo(.45*b,1*c);a.lineTo(.2*b,1*c);a.lineTo(.2*b,.35*c);d=.025;e=Jd*d;g=.175;h=.35;U(a,(g+d)*b,(h-e)*c,(g+e)*b,(h-d)*c,g*b,(h-d)*c);U(a,(g-e)*b,(h-d)*c,(g-d)*b,(h-e)*c,(g-d)*b,h*c);a.lineTo(.15*b,.6*c);a.lineTo(0*b,.6*c);a.lineTo(0*b,.25*c);d=.05;e=Jd*d;g=.05;h=.25;U(a,(g-d)*b,(h-e)*c,(g-e)*b,(h-d)*c,g*b,(h-d)*c);a.lineTo(.5*b,.2*c);b=a.q;b.C=new S(.2,.2);b.D=new S(.8,.65);D.u(a);return b},Card:function(a,b,c){a=D.v();T(a,1*b,0*c,!0);
a.lineTo(1*b,1*c);a.lineTo(0*b,1*c);a.lineTo(0*b,.2*c);a.lineTo(.2*b,0*c);X(a);b=a.q;b.C=new S(0,.2);b.D=vc;D.u(a);return b},Collate:function(a,b,c){a=D.v();T(a,.5*b,.5*c,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(.5*b,.5*c);T(a,.5*b,.5*c,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(.5*b,.5*c);b=a.q;b.C=new S(.25,0);b.D=new S(.75,.25);D.u(a);return b},CreateRequest:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.1);var d=D.v();T(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);X(d);T(d,0,
a*c,!1);d.lineTo(1*b,a*c);d.moveTo(0,(1-a)*c);d.lineTo(1*b,(1-a)*c);d.kb(!1);b=d.q;b.C=new S(0,a);b.D=new S(1,1-a);D.u(d);return b},Database:function(a,b,c){a=D.v();var d=.5*Jd,e=.1*Jd;T(a,1*b,.1*c,!0);a.lineTo(1*b,.9*c);U(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);a.lineTo(0,.1*c);U(a,0,(.1-e)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.1-e)*c,1*b,.1*c);T(a,1*b,.1*c,!1);U(a,1*b,(.1+e)*c,(.5+d)*b,.2*c,.5*b,.2*c);U(a,(.5-d)*b,.2*c,0,(.1+e)*c,0,.1*c);a.moveTo(1*b,.2*c);
U(a,1*b,(.2+e)*c,(.5+d)*b,.3*c,.5*b,.3*c);U(a,(.5-d)*b,.3*c,0,(.2+e)*c,0,.2*c);a.moveTo(1*b,.3*c);U(a,1*b,(.3+e)*c,(.5+d)*b,.4*c,.5*b,.4*c);U(a,(.5-d)*b,.4*c,0,(.3+e)*c,0,.3*c);a.kb(!1);b=a.q;b.C=new S(0,.4);b.D=new S(1,.9);D.u(a);return b},StoredData:"DataStorage",DataStorage:function(a,b,c){a=D.v();T(a,0,0,!0);a.lineTo(.75*b,0);U(a,1*b,0,1*b,1*c,.75*b,1*c);a.lineTo(0,1*c);U(a,.25*b,.9*c,.25*b,.1*c,0,0);X(a);b=a.q;b.C=new S(.226,0);b.D=new S(.81,1);D.u(a);return b},DiskStorage:function(a,b,c){a=
D.v();var d=.5*Jd,e=.1*Jd;T(a,1*b,.1*c,!0);a.lineTo(1*b,.9*c);U(a,1*b,(.9+e)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.9+e)*c,0,.9*c);a.lineTo(0,.1*c);U(a,0,(.1-e)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.1-e)*c,1*b,.1*c);T(a,1*b,.1*c,!1);U(a,1*b,(.1+e)*c,(.5+d)*b,.2*c,.5*b,.2*c);U(a,(.5-d)*b,.2*c,0,(.1+e)*c,0,.1*c);a.moveTo(1*b,.2*c);U(a,1*b,(.2+e)*c,(.5+d)*b,.3*c,.5*b,.3*c);U(a,(.5-d)*b,.3*c,0,(.2+e)*c,0,.2*c);a.kb(!1);b=a.q;b.C=new S(0,.3);b.D=new S(1,.9);D.u(a);return b},Display:function(a,
b,c){a=D.v();T(a,.25*b,0,!0);a.lineTo(.75*b,0);U(a,1*b,0,1*b,1*c,.75*b,1*c);a.lineTo(.25*b,1*c);a.lineTo(0,.5*c);X(a);b=a.q;b.C=new S(.25,0);b.D=new S(.75,1);D.u(a);return b},DividedEvent:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)?a=.2:.15>a&&(a=.15);var d=D.v(),e=.2*Jd;T(d,0,.2*c,!0);U(d,0,(.2-e)*c,(.2-e)*b,0,.2*b,0);d.lineTo(.8*b,0);U(d,(.8+e)*b,0,1*b,(.2-e)*c,1*b,.2*c);d.lineTo(1*b,.8*c);U(d,1*b,(.8+e)*c,(.8+e)*b,1*c,.8*b,1*c);d.lineTo(.2*b,1*c);U(d,(.2-e)*b,1*c,0,(.8+e)*c,0,.8*c);d.lineTo(0,.2*c);
T(d,0,a*c,!1);d.lineTo(1*b,a*c);d.kb(!1);b=d.q;b.C=new S(0,a);b.D=new S(1,1-a);D.u(d);return b},DividedProcess:function(a,b,c){a=a?a.Qc:NaN;if(isNaN(a)||.1>a)a=.1;var d=D.v();T(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);X(d);T(d,0,a*c,!1);d.lineTo(1*b,a*c);d.kb(!1);b=d.q;b.C=new S(0,a);b.D=vc;D.u(d);return b},Document:function(a,b,c){c/=.8;a=D.v();T(a,0,.7*c,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(1*b,.7*c);U(a,.5*b,.4*c,.5*b,1*c,0,.7*c);X(a);b=a.q;b.C=ec;b.D=new S(1,.6);D.u(a);
return b},ExternalOrganization:function(a,b,c){a=a?a.Qc:NaN;if(isNaN(a)||.2>a)a=.2;var d=D.v();T(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);X(d);T(d,a*b,0,!1);d.lineTo(0,a*c);d.moveTo(1*b,a*c);d.lineTo((1-a)*b,0);d.moveTo(0,(1-a)*c);d.lineTo(a*b,1*c);d.moveTo((1-a)*b,1*c);d.lineTo(1*b,(1-a)*c);d.kb(!1);b=d.q;b.C=new S(a/2,a/2);b.D=new S(1-a/2,1-a/2);D.u(d);return b},ExternalProcess:function(a,b,c){a=D.v();T(a,.5*b,0,!0);a.lineTo(1*b,.5*c);a.lineTo(.5*b,1*c);a.lineTo(0,.5*c);X(a);
T(a,.1*b,.4*c,!1);a.lineTo(.1*b,.6*c);a.moveTo(.9*b,.6*c);a.lineTo(.9*b,.4*c);a.moveTo(.6*b,.1*c);a.lineTo(.4*b,.1*c);a.moveTo(.4*b,.9*c);a.lineTo(.6*b,.9*c);a.kb(!1);b=a.q;b.C=new S(.25,.25);b.D=new S(.75,.75);D.u(a);return b},File:function(a,b,c){a=D.v();T(a,0,0,!0);a.lineTo(.75*b,0);a.lineTo(1*b,.25*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);X(a);T(a,.75*b,0,!1);a.lineTo(.75*b,.25*c);a.lineTo(1*b,.25*c);a.kb(!1);b=a.q;b.C=new S(0,.25);b.D=vc;D.u(a);return b},Interrupt:function(a,b,c){a=D.v();T(a,1*b,
.5*c,!0);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(1*b,.5*c);T(a,1*b,.5*c,!1);a.lineTo(1*b,1*c);T(a,1*b,.5*c,!1);a.lineTo(1*b,0);b=a.q;b.C=new S(0,.25);b.D=new S(.5,.75);D.u(a);return b},InternalStorage:function(a,b,c){var d=a?a.Qc:NaN;a=a?a.Vu:NaN;isNaN(d)&&(d=.1);isNaN(a)&&(a=.1);var e=D.v();T(e,0,0,!0);e.lineTo(1*b,0);e.lineTo(1*b,1*c);e.lineTo(0,1*c);X(e);T(e,d*b,0,!1);e.lineTo(d*b,1*c);e.moveTo(0,a*c);e.lineTo(1*b,a*c);e.kb(!1);b=e.q;b.C=new S(d,a);b.D=vc;D.u(e);return b},Junction:function(a,b,
c){a=D.v();var d=1/Math.SQRT2,e=(1-1/Math.SQRT2)/2,g=.5*Jd;T(a,1*b,.5*c,!0);U(a,1*b,(.5+g)*c,(.5+g)*b,1*c,.5*b,1*c);U(a,(.5-g)*b,1*c,0,(.5+g)*c,0,.5*c);U(a,0,(.5-g)*c,(.5-g)*b,0,.5*b,0);U(a,(.5+g)*b,0,1*b,(.5-g)*c,1*b,.5*c);T(a,(e+d)*b,(e+d)*c,!1);a.lineTo(e*b,e*c);a.moveTo(e*b,(e+d)*c);a.lineTo((e+d)*b,e*c);a.kb(!1);b=a.q;b.me=ak;D.u(a);return b},LinedDocument:function(a,b,c){c/=.8;a=D.v();T(a,0,.7*c,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(1*b,.7*c);U(a,.5*b,.4*c,.5*b,1*c,0,.7*c);X(a);T(a,.1*
b,0,!1);a.lineTo(.1*b,.75*c);a.kb(!1);b=a.q;b.C=new S(.1,0);b.D=new S(1,.6);D.u(a);return b},LoopLimit:function(a,b,c){a=D.v();T(a,0,1*c,!0);a.lineTo(0,.25*c);a.lineTo(.25*b,0);a.lineTo(.75*b,0);a.lineTo(1*b,.25*c);a.lineTo(1*b,1*c);X(a);b=a.q;b.C=new S(0,.25);b.D=vc;D.u(a);return b},SequentialData:"MagneticTape",MagneticTape:function(a,b,c){a=D.v();var d=.5*Jd;T(a,.5*b,1*c,!0);U(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);U(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);U(a,1*b,(.5+
d)*c,(.5+d)*b,.9*c,.6*b,.9*c);a.lineTo(1*b,.9*c);a.lineTo(1*b,1*c);a.lineTo(.5*b,1*c);b=a.q;b.C=new S(.15,.15);b.D=new S(.85,.8);D.u(a);return b},ManualInput:function(a,b,c){a=D.v();T(a,1*b,0,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,.25*c);X(a);b=a.q;b.C=new S(0,.25);b.D=vc;D.u(a);return b},MessageFromUser:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.7);var d=D.v();T(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(a*b,.5*c);d.lineTo(1*b,1*c);d.lineTo(0,1*c);X(d);b=d.q;b.C=ec;b.D=new S(a,1);D.u(d);return b},
MicroformProcessing:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.25);var d=D.v();T(d,0,0,!0);d.lineTo(.5*b,a*c);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(.5*b,(1-a)*c);d.lineTo(0,1*c);X(d);b=d.q;b.C=new S(0,a);b.D=new S(1,1-a);D.u(d);return b},MicroformRecording:function(a,b,c){a=D.v();T(a,0,0,!0);a.lineTo(.75*b,.25*c);a.lineTo(1*b,.15*c);a.lineTo(1*b,.85*c);a.lineTo(.75*b,.75*c);a.lineTo(0,1*c);X(a);b=a.q;b.C=new S(0,.25);b.D=new S(1,.75);D.u(a);return b},MultiDocument:function(a,b,c){c/=.8;a=D.v();
T(a,b,0,!0);a.lineTo(b,.5*c);U(a,.96*b,.47*c,.93*b,.45*c,.9*b,.44*c);a.lineTo(.9*b,.6*c);U(a,.86*b,.57*c,.83*b,.55*c,.8*b,.54*c);a.lineTo(.8*b,.7*c);U(a,.4*b,.4*c,.4*b,1*c,0,.7*c);a.lineTo(0,.2*c);a.lineTo(.1*b,.2*c);a.lineTo(.1*b,.1*c);a.lineTo(.2*b,.1*c);a.lineTo(.2*b,0);X(a);T(a,.1*b,.2*c,!1);a.lineTo(.8*b,.2*c);a.lineTo(.8*b,.54*c);a.moveTo(.2*b,.1*c);a.lineTo(.9*b,.1*c);a.lineTo(.9*b,.44*c);a.kb(!1);b=a.q;b.C=new S(0,.25);b.D=new S(.8,.77);D.u(a);return b},MultiProcess:function(a,b,c){a=D.v();
T(a,.1*b,.1*c,!0);a.lineTo(.2*b,.1*c);a.lineTo(.2*b,0);a.lineTo(1*b,0);a.lineTo(1*b,.8*c);a.lineTo(.9*b,.8*c);a.lineTo(.9*b,.9*c);a.lineTo(.8*b,.9*c);a.lineTo(.8*b,1*c);a.lineTo(0,1*c);a.lineTo(0,.2*c);a.lineTo(.1*b,.2*c);X(a);T(a,.2*b,.1*c,!1);a.lineTo(.9*b,.1*c);a.lineTo(.9*b,.8*c);a.moveTo(.1*b,.2*c);a.lineTo(.8*b,.2*c);a.lineTo(.8*b,.9*c);a.kb(!1);b=a.q;b.C=new S(0,.2);b.D=new S(.8,1);D.u(a);return b},OfflineStorage:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.1);var d=1-a,e=D.v();T(e,0,0,!0);e.lineTo(1*
b,0);e.lineTo(.5*b,1*c);X(e);T(e,.5*a*b,a*c,!1);e.lineTo((1-.5*a)*b,a*c);e.kb(!1);b=e.q;b.C=new S(d/4+.5*a,a);b.D=new S(3*d/4+.5*a,a+.5*d);D.u(e);return b},OffPageConnector:function(a,b,c){a=D.v();T(a,0,0,!0);a.lineTo(.75*b,0);a.lineTo(1*b,.5*c);a.lineTo(.75*b,1*c);a.lineTo(0,1*c);X(a);b=a.q;b.C=ec;b.D=new S(.75,1);D.u(a);return b},Or:function(a,b,c){a=D.v();var d=.5*Jd;T(a,1*b,.5*c,!0);U(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);U(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);U(a,
(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);T(a,1*b,.5*c,!1);a.lineTo(0,.5*c);a.moveTo(.5*b,1*c);a.lineTo(.5*b,0);a.kb(!1);b=a.q;b.me=ak;D.u(a);return b},PaperTape:function(a,b,c){c/=.8;a=D.v();T(a,0,.7*c,!0);a.lineTo(0,.3*c);U(a,.5*b,.6*c,.5*b,0,1*b,.3*c);a.lineTo(1*b,.7*c);U(a,.5*b,.4*c,.5*b,1*c,0,.7*c);X(a);b=a.q;b.C=new S(0,.49);b.D=new S(1,.75);D.u(a);return b},PrimitiveFromCall:function(a,b,c){var d=a?a.Qc:NaN;a=a?a.Vu:NaN;isNaN(d)&&(d=.1);isNaN(a)&&(a=.3);var e=D.v();T(e,0,0,!0);e.lineTo(1*b,0);e.lineTo((1-
a)*b,.5*c);e.lineTo(1*b,1*c);e.lineTo(0,1*c);X(e);b=e.q;b.C=new S(d,0);b.D=new S(1-a,1);D.u(e);return b},PrimitiveToCall:function(a,b,c){var d=a?a.Qc:NaN;a=a?a.Vu:NaN;isNaN(d)&&(d=.1);isNaN(a)&&(a=.3);var e=D.v();T(e,0,0,!0);e.lineTo((1-a)*b,0);e.lineTo(1*b,.5*c);e.lineTo((1-a)*b,1*c);e.lineTo(0,1*c);X(e);b=e.q;b.C=new S(d,0);b.D=new S(1-a,1);D.u(e);return b},Subroutine:"Procedure",Procedure:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.1);var d=D.v();T(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,
1*c);X(d);T(d,(1-a)*b,0,!1);d.lineTo((1-a)*b,1*c);d.moveTo(a*b,0);d.lineTo(a*b,1*c);d.kb(!1);b=d.q;b.C=new S(a,0);b.D=new S(1-a,1);D.u(d);return b},Process:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.1);var d=D.v();T(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);X(d);T(d,a*b,0,!1);d.lineTo(a*b,1*c);d.kb(!1);b=d.q;b.C=new S(a,0);b.D=vc;D.u(d);return b},Sort:function(a,b,c){a=D.v();T(a,.5*b,0,!0);a.lineTo(1*b,.5*c);a.lineTo(.5*b,1*c);a.lineTo(0,.5*c);X(a);T(a,0,.5*c,!1);a.lineTo(1*b,.5*
c);a.kb(!1);b=a.q;b.C=new S(.25,.25);b.D=new S(.75,.5);D.u(a);return b},Start:function(a,b,c){a=D.v();T(a,.25*b,0,!0);T(a,.25*b,0,!0);a.arcTo(270,180,.75*b,.5*c,.25*b,.5*c);a.arcTo(90,180,.25*b,.5*c,.25*b,.5*c);T(a,.25*b,0,!1);a.lineTo(.25*b,1*c);a.moveTo(.75*b,0);a.lineTo(.75*b,1*c);a.kb(!1);b=a.q;b.C=new S(.25,0);b.D=new S(.75,1);D.u(a);return b},Terminator:function(a,b,c){a=D.v();T(a,.25*b,0,!0);a.arcTo(270,180,.75*b,.5*c,.25*b,.5*c);a.arcTo(90,180,.25*b,.5*c,.25*b,.5*c);b=a.q;b.C=new S(.23,0);
b.D=new S(.77,1);D.u(a);return b},TransmittalTape:function(a,b,c){a=a?a.Qc:NaN;isNaN(a)&&(a=.1);var d=D.v();T(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(.75*b,(1-a)*c);d.lineTo(0,(1-a)*c);X(d);b=d.q;b.C=ec;b.D=new S(1,1-a);D.u(d);return b},AndGate:function(a,b,c){a=D.v();var d=.5*Jd;T(a,0,0,!0);a.lineTo(.5*b,0);U(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);U(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);a.lineTo(0,1*c);X(a);b=a.q;b.C=ec;b.D=new S(.55,1);D.u(a);return b},Buffer:function(a,b,c){a=D.v();T(a,
0,0,!0);a.lineTo(1*b,.5*c);a.lineTo(0,1*c);X(a);b=a.q;b.C=new S(0,.25);b.D=new S(.5,.75);D.u(a);return b},Clock:function(a,b,c){a=D.v();var d=.5*Jd;T(a,1*b,.5*c,!0);U(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);U(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);T(a,1*b,.5*c,!1);a.lineTo(1*b,.5*c);T(a,.8*b,.75*c,!1);a.lineTo(.8*b,.25*c);a.lineTo(.6*b,.25*c);a.lineTo(.6*b,.75*c);a.lineTo(.4*b,.75*c);a.lineTo(.4*b,.25*c);a.lineTo(.2*b,.25*c);a.lineTo(.2*
b,.75*c);a.kb(!1);b=a.q;b.me=ak;D.u(a);return b},Ground:function(a,b,c){a=D.v();T(a,.5*b,0,!1);a.lineTo(.5*b,.4*c);a.moveTo(.2*b,.6*c);a.lineTo(.8*b,.6*c);a.moveTo(.3*b,.8*c);a.lineTo(.7*b,.8*c);a.moveTo(.4*b,1*c);a.lineTo(.6*b,1*c);b=a.q;D.u(a);return b},Inverter:function(a,b,c){a=D.v();var d=.1*Jd;T(a,.8*b,.5*c,!0);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(.8*b,.5*c);T(a,1*b,.5*c,!0);U(a,1*b,(.5+d)*c,(.9+d)*b,.6*c,.9*b,.6*c);U(a,(.9-d)*b,.6*c,.8*b,(.5+d)*c,.8*b,.5*c);U(a,.8*b,(.5-d)*c,(.9-d)*b,.4*
c,.9*b,.4*c);U(a,(.9+d)*b,.4*c,1*b,(.5-d)*c,1*b,.5*c);b=a.q;b.C=new S(0,.25);b.D=new S(.4,.75);D.u(a);return b},NandGate:function(a,b,c){a=D.v();var d=.5*Jd,e=.4*Jd,g=.1*Jd;T(a,.8*b,.5*c,!0);U(a,.8*b,(.5+e)*c,(.4+d)*b,1*c,.4*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(.4*b,0);U(a,(.4+d)*b,0,.8*b,(.5-e)*c,.8*b,.5*c);T(a,1*b,.5*c,!0);U(a,1*b,(.5+g)*c,(.9+g)*b,.6*c,.9*b,.6*c);U(a,(.9-g)*b,.6*c,.8*b,(.5+g)*c,.8*b,.5*c);U(a,.8*b,(.5-g)*c,(.9-g)*b,.4*c,.9*b,.4*c);U(a,(.9+g)*b,.4*c,1*b,(.5-g)*c,1*b,.5*
c);b=a.q;b.C=new S(0,.05);b.D=new S(.55,.95);D.u(a);return b},NorGate:function(a,b,c){a=D.v();var d=.5,e=Jd*d,g=0,h=.5;T(a,.8*b,.5*c,!0);U(a,.7*b,(h+e)*c,(g+e)*b,(h+d)*c,0,1*c);U(a,.25*b,.75*c,.25*b,.25*c,0,0);U(a,(g+e)*b,(h-d)*c,.7*b,(h-e)*c,.8*b,.5*c);d=.1;e=.1*Jd;g=.9;h=.5;T(a,(g-d)*b,h*c,!0);U(a,(g-d)*b,(h-e)*c,(g-e)*b,(h-d)*c,g*b,(h-d)*c);U(a,(g+e)*b,(h-d)*c,(g+d)*b,(h-e)*c,(g+d)*b,h*c);U(a,(g+d)*b,(h+e)*c,(g+e)*b,(h+d)*c,g*b,(h+d)*c);U(a,(g-e)*b,(h+d)*c,(g-d)*b,(h+e)*c,(g-d)*b,h*c);b=a.q;b.C=
new S(.2,.25);b.D=new S(.6,.75);D.u(a);return b},OrGate:function(a,b,c){a=D.v();var d=.5*Jd;T(a,0,0,!0);U(a,(0+d+d)*b,0*c,.8*b,(.5-d)*c,1*b,.5*c);U(a,.8*b,(.5+d)*c,(0+d+d)*b,1*c,0,1*c);U(a,.25*b,.75*c,.25*b,.25*c,0,0);X(a);b=a.q;b.C=new S(.2,.25);b.D=new S(.75,.75);D.u(a);return b},XnorGate:function(a,b,c){a=D.v();var d=.5,e=Jd*d,g=.2,h=.5;T(a,.1*b,0,!1);U(a,.35*b,.25*c,.35*b,.75*c,.1*b,1*c);T(a,.8*b,.5*c,!0);U(a,.7*b,(h+e)*c,(g+e)*b,(h+d)*c,.2*b,1*c);U(a,.45*b,.75*c,.45*b,.25*c,.2*b,0);U(a,(g+e)*
b,(h-d)*c,.7*b,(h-e)*c,.8*b,.5*c);d=.1;e=.1*Jd;g=.9;h=.5;T(a,(g-d)*b,h*c,!0);U(a,(g-d)*b,(h-e)*c,(g-e)*b,(h-d)*c,g*b,(h-d)*c);U(a,(g+e)*b,(h-d)*c,(g+d)*b,(h-e)*c,(g+d)*b,h*c);U(a,(g+d)*b,(h+e)*c,(g+e)*b,(h+d)*c,g*b,(h+d)*c);U(a,(g-e)*b,(h+d)*c,(g-d)*b,(h+e)*c,(g-d)*b,h*c);b=a.q;b.C=new S(.4,.25);b.D=new S(.65,.75);D.u(a);return b},XorGate:function(a,b,c){a=D.v();var d=.5*Jd;T(a,.1*b,0,!1);U(a,.35*b,.25*c,.35*b,.75*c,.1*b,1*c);T(a,.2*b,0,!0);U(a,(.2+d)*b,0*c,.9*b,(.5-d)*c,1*b,.5*c);U(a,.9*b,(.5+d)*
c,(.2+d)*b,1*c,.2*b,1*c);U(a,.45*b,.75*c,.45*b,.25*c,.2*b,0);X(a);b=a.q;b.C=new S(.4,.25);b.D=new S(.8,.75);D.u(a);return b},Capacitor:function(a,b,c){a=D.v();T(a,0,0,!1);a.lineTo(0,1*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.q;D.u(a);return b},Resistor:function(a,b,c){a=D.v();T(a,0,.5*c,!1);a.lineTo(.1*b,0);a.lineTo(.2*b,1*c);a.lineTo(.3*b,0);a.lineTo(.4*b,1*c);a.lineTo(.5*b,0);a.lineTo(.6*b,1*c);a.lineTo(.7*b,.5*c);b=a.q;D.u(a);return b},Inductor:function(a,b,c){a=D.v();var d=.1*Jd,e=.1;T(a,(e-.5*
d)*b,c,!1);U(a,(e-d)*b,c,(e-.1)*b,0,(e+.1)*b,0);e=.3;U(a,(e+.1)*b,0,(e+d)*b,c,e*b,c);U(a,(e-d)*b,c,(e-.1)*b,0,(e+.1)*b,0);e=.5;U(a,(e+.1)*b,0,(e+d)*b,c,e*b,c);U(a,(e-d)*b,c,(e-.1)*b,0,(e+.1)*b,0);e=.7;U(a,(e+.1)*b,0,(e+d)*b,c,e*b,c);U(a,(e-d)*b,c,(e-.1)*b,0,(e+.1)*b,0);e=.9;U(a,(e+.1)*b,0,(e+d)*b,c,(e+.5*d)*b,c);b=a.q;D.u(a);return b},ACvoltageSource:function(a,b,c){a=D.v();var d=.5*Jd;T(a,0*b,.5*c,!1);U(a,0*b,(.5-d)*c,(.5-d)*b,0*c,.5*b,0*c);U(a,(.5+d)*b,0*c,1*b,(.5-d)*c,1*b,.5*c);U(a,1*b,(.5+d)*
c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0*b,(.5+d)*c,0*b,.5*c);a.moveTo(.1*b,.5*c);U(a,.5*b,0*c,.5*b,1*c,.9*b,.5*c);b=a.q;b.me=ak;D.u(a);return b},DCvoltageSource:function(a,b,c){a=D.v();T(a,0,.75*c,!1);a.lineTo(0,.25*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.q;D.u(a);return b},Diode:function(a,b,c){a=D.v();T(a,1*b,0,!1);a.lineTo(1*b,.5*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(1*b,.5*c);a.lineTo(1*b,1*c);b=a.q;b.C=new S(0,.25);b.D=new S(.5,.75);D.u(a);return b},Wifi:function(a,b,c){var d=b,e=c;
b*=.38;c*=.6;a=D.v();var g=.8*Jd,h=.8,k=0,l=.5,d=(d-b)/2,e=(e-c)/2;T(a,k*b+d,(l+h)*c+e,!0);U(a,(k-g)*b+d,(l+h)*c+e,(k-h)*b+d,(l+g)*c+e,(k-h)*b+d,l*c+e);U(a,(k-h)*b+d,(l-g)*c+e,(k-g)*b+d,(l-h)*c+e,k*b+d,(l-h)*c+e);U(a,k*b+d,(l-h)*c+e,(k-h+.5*g)*b+d,(l-g)*c+e,(k-h+.5*g)*b+d,l*c+e);U(a,(k-h+.5*g)*b+d,(l+g)*c+e,k*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);X(a);g=.4*Jd;h=.4;k=.2;l=.5;T(a,k*b+d,(l+h)*c+e,!0);U(a,(k-g)*b+d,(l+h)*c+e,(k-h)*b+d,(l+g)*c+e,(k-h)*b+d,l*c+e);U(a,(k-h)*b+d,(l-g)*c+e,(k-g)*b+d,(l-h)*c+e,k*
b+d,(l-h)*c+e);U(a,k*b+d,(l-h)*c+e,(k-h+.5*g)*b+d,(l-g)*c+e,(k-h+.5*g)*b+d,l*c+e);U(a,(k-h+.5*g)*b+d,(l+g)*c+e,k*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);X(a);g=.2*Jd;h=.2;l=k=.5;T(a,(k-h)*b+d,l*c+e,!0);U(a,(k-h)*b+d,(l-g)*c+e,(k-g)*b+d,(l-h)*c+e,k*b+d,(l-h)*c+e);U(a,(k+g)*b+d,(l-h)*c+e,(k+h)*b+d,(l-g)*c+e,(k+h)*b+d,l*c+e);U(a,(k+h)*b+d,(l+g)*c+e,(k+g)*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);U(a,(k-g)*b+d,(l+h)*c+e,(k-h)*b+d,(l+g)*c+e,(k-h)*b+d,l*c+e);g=.4*Jd;h=.4;k=.8;l=.5;T(a,k*b+d,(l-h)*c+e,!0);U(a,(k+g)*b+d,(l-
h)*c+e,(k+h)*b+d,(l-g)*c+e,(k+h)*b+d,l*c+e);U(a,(k+h)*b+d,(l+g)*c+e,(k+g)*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);U(a,k*b+d,(l+h)*c+e,(k+h-.5*g)*b+d,(l+g)*c+e,(k+h-.5*g)*b+d,l*c+e);U(a,(k+h-.5*g)*b+d,(l-g)*c+e,k*b+d,(l-h)*c+e,k*b+d,(l-h)*c+e);X(a);g=.8*Jd;h=.8;k=1;l=.5;T(a,k*b+d,(l-h)*c+e,!0);U(a,(k+g)*b+d,(l-h)*c+e,(k+h)*b+d,(l-g)*c+e,(k+h)*b+d,l*c+e);U(a,(k+h)*b+d,(l+g)*c+e,(k+g)*b+d,(l+h)*c+e,k*b+d,(l+h)*c+e);U(a,k*b+d,(l+h)*c+e,(k+h-.5*g)*b+d,(l+g)*c+e,(k+h-.5*g)*b+d,l*c+e);U(a,(k+h-.5*g)*b+d,(l-g)*c+
e,k*b+d,(l-h)*c+e,k*b+d,(l-h)*c+e);X(a);b=a.q;D.u(a);return b},Email:function(a,b,c){a=D.v();T(a,0,0,!0);a.lineTo(1*b,0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);X(a);T(a,0,0,!1);a.lineTo(.5*b,.6*c);a.lineTo(1*b,0);a.moveTo(0,1*c);a.lineTo(.45*b,.54*c);a.moveTo(1*b,1*c);a.lineTo(.55*b,.54*c);a.kb(!1);b=a.q;D.u(a);return b},Ethernet:function(a,b,c){a=D.v();T(a,.35*b,0,!0);a.lineTo(.65*b,0);a.lineTo(.65*b,.4*c);a.lineTo(.35*b,.4*c);a.lineTo(.35*b,0);X(a);T(a,.1*b,1*c,!0,!0);a.lineTo(.4*b,1*c);
a.lineTo(.4*b,.6*c);a.lineTo(.1*b,.6*c);a.lineTo(.1*b,1*c);X(a);T(a,.6*b,1*c,!0,!0);a.lineTo(.9*b,1*c);a.lineTo(.9*b,.6*c);a.lineTo(.6*b,.6*c);a.lineTo(.6*b,1*c);X(a);T(a,0,.5*c,!1);a.lineTo(1*b,.5*c);a.moveTo(.5*b,.5*c);a.lineTo(.5*b,.4*c);a.moveTo(.75*b,.5*c);a.lineTo(.75*b,.6*c);a.moveTo(.25*b,.5*c);a.lineTo(.25*b,.6*c);a.kb(!1);b=a.q;D.u(a);return b},Power:function(a,b,c){a=D.v();var d=.4*Jd,e=.4,g=D.P(),h=D.P(),k=D.P(),l=D.P();Ce(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.5,g,g,h,k,l);var m=D.Fb(h.x,
h.y);T(a,h.x*b,h.y*c,!0);U(a,k.x*b,k.y*c,l.x*b,l.y*c,(.5+e)*b,.5*c);U(a,(.5+e)*b,(.5+d)*c,(.5+d)*b,(.5+e)*c,.5*b,(.5+e)*c);U(a,(.5-d)*b,(.5+e)*c,(.5-e)*b,(.5+d)*c,(.5-e)*b,.5*c);Ce(.5-e,.5,.5-e,.5-d,.5-d,.5-e,.5,.5-e,.5,k,l,h,g,g);U(a,k.x*b,k.y*c,l.x*b,l.y*c,h.x*b,h.y*c);d=.3*Jd;e=.3;Ce(.5-e,.5,.5-e,.5-d,.5-d,.5-e,.5,.5-e,.5,k,l,h,g,g);a.lineTo(h.x*b,h.y*c);U(a,l.x*b,l.y*c,k.x*b,k.y*c,(.5-e)*b,.5*c);U(a,(.5-e)*b,(.5+d)*c,(.5-d)*b,(.5+e)*c,.5*b,(.5+e)*c);U(a,(.5+d)*b,(.5+e)*c,(.5+e)*b,(.5+d)*c,(.5+
e)*b,.5*c);Ce(.5,.5-e,.5+d,.5-e,.5+e,.5-d,.5+e,.5,.5,g,g,h,k,l);U(a,l.x*b,l.y*c,k.x*b,k.y*c,h.x*b,h.y*c);X(a);T(a,.45*b,0,!0);a.lineTo(.45*b,.5*c);a.lineTo(.55*b,.5*c);a.lineTo(.55*b,0);X(a);D.A(g);D.A(h);D.A(k);D.A(l);D.A(m);b=a.q;b.C=new S(.25,.55);b.D=new S(.75,.8);D.u(a);return b},Fallout:function(a,b,c){a=D.v();var d=.5*Jd;T(a,0*b,.5*c,!0);U(a,0*b,(.5-d)*c,(.5-d)*b,0*c,.5*b,0*c);U(a,(.5+d)*b,0*c,1*b,(.5-d)*c,1*b,.5*c);U(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0*b,(.5+d)*c,0*b,
.5*c);var e=d=0;T(a,(.3+d)*b,(.8+e)*c,!0,!0);a.lineTo((.5+d)*b,(.5+e)*c);a.lineTo((.1+d)*b,(.5+e)*c);a.lineTo((.3+d)*b,(.8+e)*c);d=.4;e=0;X(a);T(a,(.3+d)*b,(.8+e)*c,!0,!0);a.lineTo((.5+d)*b,(.5+e)*c);a.lineTo((.1+d)*b,(.5+e)*c);a.lineTo((.3+d)*b,(.8+e)*c);d=.2;e=-.3;X(a);T(a,(.3+d)*b,(.8+e)*c,!0,!0);a.lineTo((.5+d)*b,(.5+e)*c);a.lineTo((.1+d)*b,(.5+e)*c);a.lineTo((.3+d)*b,(.8+e)*c);X(a);b=a.q;b.me=ak;D.u(a);return b},IrritationHazard:function(a,b,c){a=D.v();T(a,.2*b,0*c,!0);a.lineTo(.5*b,.3*c);a.lineTo(.8*
b,0*c);a.lineTo(1*b,.2*c);a.lineTo(.7*b,.5*c);a.lineTo(1*b,.8*c);a.lineTo(.8*b,1*c);a.lineTo(.5*b,.7*c);a.lineTo(.2*b,1*c);a.lineTo(0*b,.8*c);a.lineTo(.3*b,.5*c);a.lineTo(0*b,.2*c);X(a);b=a.q;b.C=new S(.3,.3);b.D=new S(.7,.7);D.u(a);return b},ElectricalHazard:function(a,b,c){a=D.v();T(a,.37*b,0*c,!0);a.lineTo(.5*b,.11*c);a.lineTo(.77*b,.04*c);a.lineTo(.33*b,.49*c);a.lineTo(1*b,.37*c);a.lineTo(.63*b,.86*c);a.lineTo(.77*b,.91*c);a.lineTo(.34*b,1*c);a.lineTo(.34*b,.78*c);a.lineTo(.44*b,.8*c);a.lineTo(.65*
b,.56*c);a.lineTo(0*b,.68*c);X(a);b=a.q;D.u(a);return b},FireHazard:function(a,b,c){a=D.v();T(a,.1*b,1*c,!0);U(a,-.25*b,.63*c,.45*b,.44*c,.29*b,0*c);U(a,.48*b,.17*c,.54*b,.35*c,.51*b,.42*c);U(a,.59*b,.29*c,.58*b,.28*c,.59*b,.18*c);U(a,.8*b,.34*c,.88*b,.43*c,.75*b,.6*c);U(a,.87*b,.48*c,.88*b,.43*c,.88*b,.31*c);U(a,1.17*b,.76*c,.82*b,.8*c,.9*b,1*c);X(a);b=a.q;b.C=new S(.05,.645);b.D=new S(.884,.908);D.u(a);return b},BpmnActivityLoop:function(a,b,c){a=D.v();var d=4*(Math.SQRT2-1)/3*.5;T(a,.65*b,1*c,
!1);U(a,(1-d+0)*b,1*c,1*b,(.5+d+0)*c,1*b,.5*c);U(a,1*b,(.5-d+0)*c,(.5+d+0)*b,0*c,.5*b,0*c);U(a,(.5-d+0)*b,0*c,0*b,(.5-d+0)*c,0*b,.5*c);U(a,0*b,(.5+d+0)*c,(.5-d+0)*b,1*c,.35*b,.98*c);a.moveTo(.25*b,.8*c);a.lineTo(.35*b,1*c);a.lineTo(.1*b,1*c);b=a.q;D.u(a);return b},BpmnActivityParallel:function(a,b,c){a=D.v();T(a,0,0,!1);a.lineTo(0,1*c);a.moveTo(.5*b,0);a.lineTo(.5*b,1*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.q;D.u(a);return b},BpmnActivitySequential:function(a,b,c){a=D.v();T(a,0,0,!1);a.lineTo(1*
b,0);a.moveTo(0,.5*c);a.lineTo(1*b,.5*c);a.moveTo(0,1*c);a.lineTo(1*b,1*c);b=a.q;D.u(a);return b},BpmnActivityAdHoc:function(a,b,c){a=D.v();T(a,0,0,!1);T(a,1*b,1*c,!1);T(a,0,.5*c,!1);U(a,.2*b,.35*c,.3*b,.35*c,.5*b,.5*c);U(a,.7*b,.65*c,.8*b,.65*c,1*b,.5*c);b=a.q;D.u(a);return b},BpmnActivityCompensation:function(a,b,c){a=D.v();T(a,0,.5*c,!0);a.lineTo(.5*b,0);a.lineTo(.5*b,.5*c);a.lineTo(1*b,1*c);a.lineTo(1*b,0);a.lineTo(.5*b,.5*c);a.lineTo(.5*b,1*c);X(a);b=a.q;D.u(a);return b},BpmnTaskMessage:function(a,
b,c){a=D.v();T(a,0,.2*c,!0);a.lineTo(1*b,.2*c);a.lineTo(1*b,.8*c);a.lineTo(0,.8*c);a.lineTo(0,.8*c);X(a);T(a,0,.2*c,!1);a.lineTo(.5*b,.5*c);a.lineTo(1*b,.2*c);a.kb(!1);b=a.q;D.u(a);return b},BpmnTaskScript:function(a,b,c){a=D.v();T(a,.7*b,1*c,!0);a.lineTo(.3*b,1*c);U(a,.6*b,.5*c,0,.5*c,.3*b,0);a.lineTo(.7*b,0);U(a,.4*b,.5*c,1*b,.5*c,.7*b,1*c);X(a);T(a,.45*b,.73*c,!1);a.lineTo(.7*b,.73*c);a.moveTo(.38*b,.5*c);a.lineTo(.63*b,.5*c);a.moveTo(.31*b,.27*c);a.lineTo(.56*b,.27*c);a.kb(!1);b=a.q;D.u(a);return b},
BpmnTaskUser:function(a,b,c){a=D.v();T(a,0,0,!1);T(a,.335*b,(1-.555)*c,!0);a.lineTo(.335*b,.595*c);a.lineTo(.665*b,.595*c);a.lineTo(.665*b,(1-.555)*c);U(a,.88*b,.46*c,.98*b,.54*c,1*b,.68*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,.68*c);U(a,.02*b,.54*c,.12*b,.46*c,.335*b,(1-.555)*c);a.lineTo(.365*b,.405*c);var d=.5-.285,e=Math.PI/4,g=4*(1-Math.cos(e))/(3*Math.sin(e)),e=g*d,g=g*d;U(a,(.5-(e+d)/2)*b,(d+(d+g)/2)*c,(.5-d)*b,(d+g)*c,(.5-d)*b,d*c);U(a,(.5-d)*b,(d-g)*c,(.5-e)*b,(d-d)*c,.5*b,(d-d)*c);
U(a,(.5+e)*b,(d-d)*c,(.5+d)*b,(d-g)*c,(.5+d)*b,d*c);U(a,(.5+d)*b,(d+g)*c,(.5+(e+d)/2)*b,(d+(d+g)/2)*c,.635*b,.405*c);a.lineTo(.635*b,.405*c);a.lineTo(.665*b,(1-.555)*c);a.lineTo(.665*b,.595*c);a.lineTo(.335*b,.595*c);T(a,.2*b,1*c,!1);a.lineTo(.2*b,.8*c);T(a,.8*b,1*c,!1);a.lineTo(.8*b,.8*c);b=a.q;D.u(a);return b},BpmnEventConditional:function(a,b,c){a=D.v();T(a,.1*b,0,!0);a.lineTo(.9*b,0);a.lineTo(.9*b,1*c);a.lineTo(.1*b,1*c);X(a);T(a,.2*b,.2*c,!1);a.lineTo(.8*b,.2*c);a.moveTo(.2*b,.4*c);a.lineTo(.8*
b,.4*c);a.moveTo(.2*b,.6*c);a.lineTo(.8*b,.6*c);a.moveTo(.2*b,.8*c);a.lineTo(.8*b,.8*c);a.kb(!1);b=a.q;D.u(a);return b},BpmnEventError:function(a,b,c){a=D.v();T(a,0,1*c,!0);a.lineTo(.33*b,0);a.lineTo(.66*b,.5*c);a.lineTo(1*b,0);a.lineTo(.66*b,1*c);a.lineTo(.33*b,.5*c);X(a);b=a.q;D.u(a);return b},BpmnEventEscalation:function(a,b,c){a=D.v();T(a,0,0,!1);T(a,1*b,1*c,!1);T(a,.1*b,1*c,!0);a.lineTo(.5*b,0);a.lineTo(.9*b,1*c);a.lineTo(.5*b,.5*c);X(a);b=a.q;D.u(a);return b},BpmnEventTimer:function(a,b,c){a=
D.v();var d=.5*Jd;T(a,1*b,.5*c,!0);U(a,1*b,(.5+d)*c,(.5+d)*b,1*c,.5*b,1*c);U(a,(.5-d)*b,1*c,0,(.5+d)*c,0,.5*c);U(a,0,(.5-d)*c,(.5-d)*b,0,.5*b,0);U(a,(.5+d)*b,0,1*b,(.5-d)*c,1*b,.5*c);T(a,.5*b,0,!1);a.lineTo(.5*b,.15*c);a.moveTo(.5*b,1*c);a.lineTo(.5*b,.85*c);a.moveTo(0,.5*c);a.lineTo(.15*b,.5*c);a.moveTo(1*b,.5*c);a.lineTo(.85*b,.5*c);a.moveTo(.5*b,.5*c);a.lineTo(.58*b,.1*c);a.moveTo(.5*b,.5*c);a.lineTo(.78*b,.54*c);a.kb(!1);b=a.q;b.me=ak;D.u(a);return b}},dr;for(dr in qq)qq[dr.toLowerCase()]=dr;
var xq={"":"",Standard:"F1 m 0,0 l 8,4 -8,4 2,-4 z",Backward:"F1 m 8,0 l -2,4 2,4 -8,-4 z",Triangle:"F1 m 0,0 l 8,4.62 -8,4.62 z",BackwardTriangle:"F1 m 8,4 l 0,4 -8,-4 8,-4 0,4 z",Boomerang:"F1 m 0,0 l 8,4 -8,4 4,-4 -4,-4 z",BackwardBoomerang:"F1 m 8,0 l -8,4 8,4 -4,-4 4,-4 z",SidewaysV:"m 0,0 l 8,4 -8,4 0,-1 6,-3 -6,-3 0,-1 z",BackwardV:"m 8,0 l -8,4 8,4 0,-1 -6,-3 6,-3 0,-1 z",OpenTriangle:"m 0,0 l 8,4 -8,4",BackwardOpenTriangle:"m 8,0 l -8,4 8,4",OpenTriangleLine:"m 0,0 l 8,4 -8,4 m 8.5,0 l 0,-8",
BackwardOpenTriangleLine:"m 8,0 l  -8,4 8,4 m -8.5,0 l 0,-8",OpenTriangleTop:"m 0,0 l 8,4 m 0,4",BackwardOpenTriangleTop:"m 8,0 l -8,4 m 0,4",OpenTriangleBottom:"m 0,8 l 8,-4",BackwardOpenTriangleBottom:"m 0,4 l 8,4",HalfTriangleTop:"F1 m 0,0 l 0,4 8,0 z m 0,8",BackwardHalfTriangleTop:"F1 m 8,0 l 0,4 -8,0 z m 0,8",HalfTriangleBottom:"F1 m 0,4 l 0,4 8,-4 z",BackwardHalfTriangleBottom:"F1 m 8,4 l 0,4 -8,-4 z",ForwardSemiCircle:"m 4,0 b 270 180 0 4 4",BackwardSemiCircle:"m 4,8 b 90 180 0 -4 4",Feather:"m 0,0 l 3,4 -3,4",
BackwardFeather:"m 3,0 l -3,4 3,4",DoubleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardDoubleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4",TripleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardTripleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4 m 3,-8 l -3,4 3,4",ForwardSlash:"m 0,8 l 5,-8",BackSlash:"m 0,0 l 5,8",DoubleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8",DoubleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8",TripleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8 m -2,8 l 4,-8",
TripleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8 m -2,-8 l 4,8",Fork:"m 0,4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4",LineFork:"m 0,0 l 0,8 m 0,-4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardLineFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4 m 8,-8 l 0,8",CircleFork:"F1 m 6,4 b 0 360 -3 0 3 z m 0,0 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",BackwardCircleFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 6,0 b 0 360 -3 0 3",CircleLineFork:"F1 m 6,4 b 0 360 -3 0 3 z m 1,-4 l 0,8 m 0,-4 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",
BackwardCircleLineFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 0,-4 l 0,8 m 7,-4 b 0 360 -3 0 3",Circle:"F1 m 8,4 b 0 360 -4 0 4 z",Block:"F1 m 0,0 l 0,8 8,0 0,-8 z",StretchedDiamond:"F1 m 0,3 l 5,-3 5,3 -5,3 -5,-3 z",Diamond:"F1 m 0,4 l 4,-4 4,4 -4,4 -4,-4 z",Chevron:"F1 m 0,0 l 5,0 3,4 -3,4 -5,0 3,-4 -3,-4 z",StretchedChevron:"F1 m 0,0 l 8,0 3,4 -3,4 -8,0 3,-4 -3,-4 z",NormalArrow:"F1 m 0,2 l 4,0 0,-2 4,4 -4,4 0,-2 -4,0 z",X:"m 0,0 l 8,8 m 0,-8 l -8,8",TailedNormalArrow:"F1 m 0,0 l 2,0 1,2 3,0 0,-2 2,4 -2,4 0,-2 -3,0 -1,2 -2,0 1,-4 -1,-4 z",
DoubleTriangle:"F1 m 0,0 l 4,4 -4,4 0,-8 z  m 4,0 l 4,4 -4,4 0,-8 z",BigEndArrow:"F1 m 0,0 l 5,2 0,-2 3,4 -3,4 0,-2 -5,2 0,-8 z",ConcaveTailArrow:"F1 m 0,2 h 4 v -2 l 4,4 -4,4 v -2 h -4 l 2,-2 -2,-2 z",RoundedTriangle:"F1 m 0,1 a 1,1 0 0 1 1,-1 l 7,3 a 0.5,1 0 0 1 0,2 l -7,3 a 1,1 0 0 1 -1,-1 l 0,-6 z",SimpleArrow:"F1 m 1,2 l -1,-2 2,0 1,2 -1,2 -2,0 1,-2 5,0 0,-2 2,2 -2,2 0,-2 z",AccelerationArrow:"F1 m 0,0 l 0,8 0.2,0 0,-8 -0.2,0 z m 2,0 l 0,8 1,0 0,-8 -1,0 z m 3,0 l 2,0 2,4 -2,4 -2,0 0,-8 z",BoxArrow:"F1 m 0,0 l 4,0 0,2 2,0 0,-2 2,4 -2,4 0,-2 -2,0 0,2 -4,0 0,-8 z",
TriangleLine:"F1 m 8,4 l -8,-4 0,8 8,-4 z m 0.5,4 l 0,-8",CircleEndedArrow:"F1 m 10,4 l -2,-3 0,2 -2,0 0,2 2,0 0,2 2,-3 z m -4,0 b 0 360 -3 0 3 z",DynamicWidthArrow:"F1 m 0,3 l 2,0 2,-1 2,-2 2,4 -2,4 -2,-2 -2,-1 -2,0 0,-2 z",EquilibriumArrow:"m 0,3 l 8,0 -3,-3 m 3,5 l -8,0 3,3",FastForward:"F1 m 0,0 l 3.5,4 0,-4 3.5,4 0,-4 1,0 0,8 -1,0 0,-4 -3.5,4 0,-4 -3.5,4 0,-8 z",Kite:"F1 m 0,4 l 2,-4 6,4 -6,4 -2,-4 z",HalfArrowTop:"F1 m 0,0 l 4,4 4,0 -8,-4 z m 0,8",HalfArrowBottom:"F1 m 0,8 l 4,-4 4,0 -8,4 z",
OpposingDirectionDoubleArrow:"F1 m 0,4 l 2,-4 0,2 4,0 0,-2 2,4 -2,4 0,-2 -4,0 0,2 -2,-4 z",PartialDoubleTriangle:"F1 m 0,0 4,3 0,-3 4,4 -4,4 0,-3 -4,3 0,-8 z",LineCircle:"F1 m 0,0 l 0,8 m 7 -4 b 0 360 -3 0 3 z",DoubleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",TripleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",CircleLine:"F1 m 6 4 b 0 360 -3 0 3 z m 1,-4 l 0,8",DiamondCircle:"F1 m 8,4 l -4,4 -4,-4 4,-4 4,4 m 8,0 b 0 360 -4 0 4 z",PlusCircle:"F1 m 8,4 b 0 360 -4 0 4 l -8 0 z m -4 -4 l 0 8",
OpenRightTriangleTop:"m 8,0 l 0,4 -8,0 m 0,4",OpenRightTriangleBottom:"m 8,8 l 0,-4 -8,0",Line:"m 0,0 l 0,8",DoubleLine:"m 0,0 l 0,8 m 2,0 l 0,-8",TripleLine:"m 0,0 l 0,8 m 2,0 l 0,-8 m 2,0 l 0,8",PentagonArrow:"F1 m 8,4 l -4,-4 -4,0 0,8 4,0 4,-4 z"};
function uq(a){var b=ne[a];if(void 0===b){var c=a.toLowerCase();if("none"===c)return"None";b=ne[c];if(void 0===b){var d=null,e;for(e in xq)if(e.toLowerCase()===c){d=e;break}if(null!==d)return a=yf(xq[d],!1),ne[d]=a,c!==d&&(ne[c]=d),d}}return"string"===typeof b?b:b instanceof Ve?a:null}
function F(a){x.call(this,a);this.S=2408959;this.Ml=this.Pi="";this.ht=this.et=this.qt=this.qs=null;this.st="";this.oh=this.bs=this.rt=this.un=null;this.gt="";this.Ep=null;this.ft=ie;this.it="";this.Fp=null;this.Vd="";this.nw=this.pr=this.wl=null;this.hj=(new N(NaN,NaN)).freeze();this.vs="";this.Pl=null;this.ws=ec;this.Es=Vd;this.xs=Wd;this.Jr=null;this.rs=er;this.wn=Ud;this.vn="gray";this.Qg=4;this.DD=-1;this.Qt=NaN;this.yH=new C;this.Rl=null;this.vk=NaN}D.Sa(F,x);D.ka("Part",F);
F.prototype.cloneProtected=function(a){x.prototype.cloneProtected.call(this,a);a.S=this.S&-4097|49152;a.Pi=this.Pi;a.Ml=this.Ml;a.qs=this.qs;a.qt=this.qt;a.et=this.et;a.ht=this.ht;a.st=this.st;a.rt=this.rt;a.bs=this.bs;a.oh=null;a.gt=this.gt;a.ft=this.ft.V();a.it=this.it;a.Vd=this.Vd;a.pr=this.pr;a.hj.assign(this.hj);a.vs=this.vs;a.ws=this.ws.V();a.Es=this.Es.V();a.xs=this.xs.V();a.Jr=this.Jr;a.rs=this.rs;a.wn=this.wn.V();a.vn=this.vn;a.Qg=this.Qg;a.Qt=this.Qt};
F.prototype.Ei=function(a){x.prototype.Ei.call(this,a);a.Yk();a.un=null;a.Ep=null;a.Fp=null;a.Pl=null;a.Rl=null};F.prototype.toString=function(){var a=D.vf(Object.getPrototypeOf(this))+"#"+D.Jd(this);null!==this.data&&(a+="("+ia(this.data)+")");return a};F.LayoutNone=0;var Vm;F.LayoutAdded=Vm=1;var dn;F.LayoutRemoved=dn=2;F.LayoutShown=4;F.LayoutHidden=8;F.LayoutNodeSized=16;var Kn;F.LayoutGroupLayout=Kn=32;F.LayoutNodeReplaced=64;var er;F.LayoutStandard=er=Vm|dn|28|Kn|64;F.LayoutAll=16777215;
F.prototype.qo=function(a,b,c,d,e,g,h){var k=this.g;null!==k&&(a===rg&&"elements"===b?e instanceof x?Wm(e,function(a){Ym(k,a);Xm(k,a)}):e instanceof Gl&&wn(k,e):a===sg&&"elements"===b&&(e instanceof x?Wm(e,function(a){bn(k,a);an(k,a)}):e instanceof Gl&&xn(k,e)),k.nd(a,b,c,d,e,g,h))};F.prototype.updateTargetBindings=F.prototype.Ob=function(a){x.prototype.Ob.call(this,a);if(null!==this.data){a=this.xa.o;for(var b=a.length,c=0;c<b;c++){var d=a[c];d instanceof x&&Wm(d,function(a){null!==a.data&&a.Ob()})}}};
F.prototype.updateRelationshipsFromData=function(){var a=this.data;if(null!==a){var b=this.g;if(null!==b){var c=b.da;c instanceof Y&&(a=c.Tn(a),b=b.II(a),null===b||b instanceof I)&&(this.Ka=b)}}};D.w(F,{key:"key"},function(){var a=this.g;return null===a?void 0:a.da.zb(this.data)},{configurable:!0});D.w(F,{ox:"adornments"},function(){return null===this.oh?Ka:this.oh.hG});
F.prototype.findAdornment=F.prototype.kq=function(a){v&&D.h(a,"string",F,"findAdornment:category");var b=this.oh;return null===b?null:b.oa(a)};F.prototype.addAdornment=F.prototype.bm=function(a,b){if(null!==b){v&&(D.h(a,"string",F,"addAdornment:category"),D.l(b,da,F,"addAdornment:ad"));var c=null,d=this.oh;null!==d&&(c=d.oa(a));if(c!==b){if(null!==c){var e=c.g;null!==e&&e.remove(c)}null===d&&(this.oh=d=new oa("string",da));b.Pi!==a&&(b.Gd=a);d.add(a,b);c=this.g;null!==c&&(c.add(b),b.data=this.data)}}};
F.prototype.removeAdornment=F.prototype.Tj=function(a){v&&D.h(a,"string",F,"removeAdornment:category");var b=this.oh;if(null!==b){var c=b.oa(a);if(null!==c){var d=c.g;null!==d&&d.remove(c)}b.remove(a);0===b.count&&(this.oh=null)}};F.prototype.clearAdornments=F.prototype.au=function(){var a=this.oh;if(null!==a){for(var b=D.nb(),a=a.j;a.next();)b.push(a.key);for(var a=b.length,c=0;c<a;c++)this.Tj(b[c]);D.ya(b)}};
F.prototype.updateAdornments=function(){var a=this.g;if(null!==a){a:{if(this.ib&&this.UG){var b=this.to;if(!(null!==b&&this.Z.F()&&this.isVisible()&&b.Oj()&&b.Z.F()))break a;var c=this.kq("Selection");if(null===c){c=this.jK;null===c&&(c=this instanceof J?a.AJ:this instanceof I?a.XI:a.RJ);if(!(c instanceof da))break a;Sh(c);c=c.copy();null!==c&&(c.Bb=b)}if(null!==c){var d=c.placeholder;if(null!==d){var e=b.Fj(),g=0;b instanceof z&&(g=b.mb);var h=D.Gm();h.n((b.Ga.width+g)*e,(b.Ga.height+g)*e);d.Ea=
h;D.Uk(h)}c.angle=b.tm();c.type!==rj&&(d=D.P(),c.location=b.eb(ec,d),D.A(d));this.bm("Selection",c);break a}}this.Tj("Selection")}fr(this,a);for(a=this.ox;a.next();)b=a.value,b.Ob(),b.K()}};function fr(a,b){b.ob.pf.each(function(b){b.isEnabled&&b.updateAdornments(a)});b.ob.updateAdornments(a)}D.w(F,{layer:"layer"},function(){return this.nw});D.w(F,{g:"diagram"},function(){var a=this.nw;return null!==a?a.g:null});
D.defineProperty(F,{Mf:"layerName"},function(){return this.Ml},function(a){var b=this.Ml;if(b!==a){D.h(a,"string",F,"layerName");var c=this.g;if(null===c||null!==c.qm(a)&&!c.$q)if(this.Ml=a,null!==c&&c.Nc(),this.i("layerName",b,a),b=this.layer,null!==b&&b.name!==a&&(c=b.g,null!==c&&(a=c.qm(a),null!==a&&a!==b))){var d=b.xf(-1,this,!0);0<=d&&c.nd(sg,"parts",b,this,null,d,!0);d=a.rq(99999999,this,!0);b.visible!==a.visible&&this.Ld(a.visible);0<=d&&c.nd(rg,"parts",a,null,this,!0,d);d=this.hy;if(null!==
d){var e=c.$a;c.$a=!0;d(this,b,a);c.$a=e}}}});D.defineProperty(F,{hy:"layerChanged"},function(){return this.qs},function(a){var b=this.qs;b!==a&&(null!==a&&D.h(a,"function",F,"layerChanged"),this.qs=a,this.i("layerChanged",b,a))});D.defineProperty(F,{Co:"zOrder"},function(){return this.Qt},function(a){var b=this.Qt;if(b!==a){D.h(a,"number",F,"zOrder");this.Qt=a;var c=this.layer;null!==c&&xl(c,-1,this);this.i("zOrder",b,a);a=this.g;null!==a&&a.ra()}});
F.prototype.invalidateAdornments=F.prototype.oe=function(){var a=this.g;null!==a&&(Vl(a),0!==(this.S&16384)!==!0&&(this.S|=16384,a.Ve()))};function tl(a){0!==(a.S&16384)!==!1&&(a.updateAdornments(),a.S&=-16385)}function gr(a){if(!1===Om(a)){var b=a.g;null!==b&&(b.Hg.add(a),b.Ve());hr(a,!0);a.aj()}}function ir(a){a.S|=2097152;if(!1!==Om(a)){var b=a.position,c=a.location;c.F()&&b.F()||jr(a,b,c);var c=a.cc,d=c.copy();c.Xa();c.x=b.x;c.y=b.y;c.freeze();a.qy(d,c);hr(a,!1)}}
D.w(F,{Bf:"locationObject"},function(){if(null===this.Pl){var a=this.ly;""!==a?(a=this.Hd(a),this.Pl=null!==a?a:this):this.Pl=this instanceof da?this.type!==rj&&null!==this.placeholder?this.placeholder:this:this}return this.Pl.visible?this.Pl:this});D.defineProperty(F,{LJ:"minLocation"},function(){return this.Es},function(a){var b=this.Es;b.O(a)||(v&&D.l(a,N,F,"minLocation"),this.Es=a=a.V(),this.i("minLocation",b,a))});
D.defineProperty(F,{FJ:"maxLocation"},function(){return this.xs},function(a){var b=this.xs;b.O(a)||(v&&D.l(a,N,F,"maxLocation"),this.xs=a=a.V(),this.i("maxLocation",b,a))});D.defineProperty(F,{ly:"locationObjectName"},function(){return this.vs},function(a){var b=this.vs;b!==a&&(v&&D.h(a,"string",F,"locationObjectName"),this.vs=a,this.Pl=null,this.K(),this.i("locationObjectName",b,a))});
D.defineProperty(F,{Nf:"locationSpot"},function(){return this.ws},function(a){var b=this.ws;b.O(a)||(v&&(D.l(a,S,F,"locationSpot"),a.Vc()||D.k("Part.locationSpot must be a specific Spot value, not: "+a)),this.ws=a=a.V(),this.K(),this.i("locationSpot",b,a))});F.prototype.move=function(a){this.position=a};F.prototype.moveTo=F.prototype.moveTo=function(a,b){var c=D.Fb(a,b);this.move(c);D.A(c)};
F.prototype.isVisible=function(){if(!this.visible)return!1;var a=this.layer;if(null!==a&&!a.visible)return!1;a=this.g;if(null!==a&&(a=a.Wa,a.yf&&(a=a.kn.oa(this),null!==a&&a.zy)))return!0;a=this.Ka;return null===a||a.ld&&a.isVisible()?!0:!1};F.prototype.Ld=function(a){var b=this.g;a?(this.N(4),this.oe(),null!==b&&b.Hg.add(this)):(this.N(8),this.au());this.Yk();null!==b&&(b.Nc(),b.ra())};
F.prototype.findObject=F.prototype.Hd=function(a){if(this.name===a)return this;var b=this.Rl;null===b&&(this.Rl=b=new ma);if(void 0!==b[a])return b[a];for(var c=this.xa.o,d=c.length,e=0;e<d;e++){var g=c[e];if(g.name===a)return b[a]=g;if(g instanceof x)if(null===g.cj&&null===g.Jg){if(g=g.Hd(a),null!==g)return b[a]=g}else if(un(g)&&(g=g.xa.first(),null!==g&&g.name===a))return b[a]=g}return b[a]=null};
function kr(a,b,c,d){void 0===d&&(d=new N);c=c.ce()?mc:c;var e=b.Ga;d.n(e.width*c.x+c.offsetX,e.height*c.y+c.offsetY);if(null===b||b===a)return d;b.transform.xb(d);for(b=b.R;null!==b&&b!==a;)b.transform.xb(d),b=b.R;a.gj.xb(d);d.offset(-a.Dd.x,-a.Dd.y);return d}F.prototype.ensureBounds=F.prototype.Re=function(){Gk(this,Infinity,Infinity);this.rc()};
function wl(a,b){var c=a.yH,d;isNaN(a.vk)&&(a.vk=fq(a));d=a.vk;var e=2*d;if(!a.$k)return c.n(b.x-1-d,b.y-1-d,b.width+2+e,b.height+2+e),c;d=b.x;var e=b.y,g=b.width,h=b.height,k=a.shadowBlur,l=a.nK,g=g+k,h=h+k;d-=k/2;e-=k/2;0<l.x?g+=l.x:(d+=l.x,g-=l.x);0<l.y?h+=l.y:(e+=l.y,h-=l.y);c.n(d-1,e-1,g+2,h+2);return c}
F.prototype.rc=function(){this.aj();if(!1===Nm(this))ir(this);else{var a=this.cc,b=D.Qf();b.assign(a);a.Xa();var c=sl(this);this.yj(0,0,this.Dd.width,this.Dd.height);var d=this.position;jr(this,d,this.location);a.x=d.x;a.y=d.y;a.freeze();this.qy(b,a);No(this,!1);b.O(a)?this.Of(c):!this.pe()||Q(b.width,a.width)&&Q(b.height,a.height)||0<=this.DD&&this.N(16);D.Kb(b);hr(this,!1)}};
F.prototype.qy=function(a,b){var c=this.g;if(null!==c){var d=!1;if(!1===c.pk&&a.F()){var e=c.jd,g=c.padding,h=e.x+g.left,k=e.y+g.top,l=e.width-2*g.right,e=e.height-2*g.bottom;a.x>h&&a.y>k&&a.right<l&&a.bottom<e&&b.x>h&&b.y>k&&b.right<l&&b.bottom<e&&(d=!0)}0!==(this.S&65536)!==!0&&a.O(b)||Zm(this,d,c);c.ra();Db(a,b)||(this instanceof G&&!c.la.jb&&this.kg(),this.Yk())}};
D.defineProperty(F,{location:"location"},function(){return this.hj},function(a){v&&D.l(a,N,F,"location");var b=a.x,c=a.y,d=this.hj,e=d.x,g=d.y;(e===b||isNaN(e)&&isNaN(b))&&(g===c||isNaN(g)&&isNaN(c))||(a=a.V(),b=a,this instanceof J?b=!1:(this.hj=b,this.S|=2097152,!1===Nm(this)&&(gr(this),c=this.tb,c.F()&&(e=c.copy(),c.n(c.x+(b.x-d.x),c.y+(b.y-d.y)),lr(this,this.g,c,e),this.i("position",e,c))),b=!0),b&&this.i("location",d,a))});f=F.prototype;
f.PB=function(a,b){if(this instanceof J||!a.F())return!1;var c=this.g;if(null!==c&&(lr(this,c,a,b),!0===c.la.jb))return!0;this.tb=a;this.S&=-2097153;c=this.hj;if(c.F()){var d=c.copy();c.n(c.x+(a.x-b.x),c.y+(a.y-b.y));this.i("location",d,c)}!1===Om(this)&&!1===Nm(this)&&(gr(this),ir(this));return!0};function lr(a,b,c,d){null===b||a instanceof da||(b=b.Wa,b.xc&&gl(b,a,"position",d.copy(),c.copy(),!1))}
f.My=function(a,b){var c=this.hj,d=this.tb;Om(this)||Nm(this)?c.n(NaN,NaN):c.n(c.x+a-d.x,c.y+b-d.y);d.n(a,b);gr(this)};f.QB=function(){this.S&=-2097153;gr(this)};
function jr(a,b,c){var d=D.P(),e=a.Nf,g=a.Bf;e.ce()&&D.k("determineOffset: Part's locationSpot must be real: "+e.toString());var h=g.Ga,k=g instanceof z?g.mb:0;d.fv(0,0,h.width+k,h.height+k,e);if(g!==a)for(d.offset(-k/2,-k/2),g.transform.xb(d),e=g.R;null!==e&&e!==a;)e.transform.xb(d),e=e.R;a.gj.xb(d);d.offset(-a.Dd.x,-a.Dd.y);e=a.g;g=c.F();h=b.F();g&&h?0!==(a.S&2097152)?mr(a,b,c,e,d):nr(a,b,c,e,d):g?mr(a,b,c,e,d):h&&nr(a,b,c,e,d);a.S|=2097152;D.A(d);a.aj()}
function mr(a,b,c,d,e){var g=b.x,h=b.y;b.n(c.x-e.x,c.y-e.y);null!==d&&(c=d.Wa,(e=c.qk)||!c.xc||a instanceof da||gl(c,a,"position",new N(g,h),b,!1),e||b.x===g&&b.y===h||(c=d.lb,d.lb=!0,a.i("position",new N(g,h),b),d.lb=c))}function nr(a,b,c,d,e){var g=c.copy();c.n(b.x+e.x,b.y+e.y);c.O(g)||null===d||(b=d.lb,d.lb=!0,a.i("location",g,c),d.lb=b)}
function Zm(a,b,c){Po(a,!1);a instanceof G&&c.dB(a);a.layer.Wc||b||c.Nc();b=a.cc;var d=c.vb;d.F()?(sl(a)?Ob(b,d)||a.Of(!1):b.jg(d)&&a.Of(!0),a.updateAdornments()):c.Fl=!0}f.zm=function(){return!0};
function ul(a,b){var c=a.cc;if(0!==c.width&&0!==c.height&&!isNaN(c.x)&&!isNaN(c.y)&&a.isVisible()){var d=a.transform;null!==a.kc&&(Vo(a,b,a.kc,!0,!0),b.fillRect(c.x,c.y,c.width,c.height));null===a.kc&&null===a.Lb&&(Vo(a,b,"rgba(0,0,0,0.4)",!0,!1),b.fillRect(c.x,c.y,c.width,c.height));null!==a.Lb&&(d.Du()||b.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy),c=a.Ga,Vo(a,b,a.Lb,!0,!1),b.fillRect(0,0,c.width,c.height),d.Du()||(c=1/(d.m11*d.m22-d.m12*d.m21),b.transform(d.m22*c,-d.m12*c,-d.m21*c,d.m11*c,c*(d.m21*
d.dy-d.m22*d.dx),c*(d.m12*d.dx-d.m11*d.dy))))}}f.pe=function(){return!0};f.Kj=function(){return!0};
D.defineProperty(F,{Gd:"category"},function(){return this.Pi},function(a){var b=this.Pi;if(b!==a){D.h(a,"string",F,"category");var c=this.g,d=this.data,e=null;if(null!==c&&null!==d&&!(this instanceof da)){var g=c.da.la;g.isEnabled&&!g.jb&&(e=this.clone(),e.xa.Tc(this.xa))}this.Pi=a;this.i("category",b,a);null===c||null===d||this instanceof da?(e=this.Hf,null!==e&&(a=e.oh,null!==a&&a.remove(b),e.bm(this.Gd,this))):(g=c.da,g.la.jb||(this instanceof J?(g instanceof Y?g.WG(d,a):g instanceof Bg&&g.lK(d,
a),c=En(c,a),null!==c&&(Sh(c),c=c.copy(),null!==c&&or(this,c,b,a))):(null!==g&&g.Jy(d,a),c=Bn(c,d,a),null!==c&&(Sh(c),c=c.copy(),null===c||c instanceof J||(c.location=this.location,or(this,c,b,a)))),null!==e&&(b=this.clone(),b.xa.Tc(this.xa),this.i("self",e,b))))}});D.defineProperty(F,{self:"self"},function(){return this},function(a){or(this,a,this.Gd,a.Gd)});var pr=!1;
function or(a,b,c,d){b.constructor===a.constructor||pr||(pr=!0,D.trace('Should not change the class of the Part when changing category from "'+c+'" to "'+d+'"'),D.trace("  Old class: "+D.vf(a)+", new class: "+D.vf(b)+", part: "+a.toString()));a.au();var e=a.data;c=a.Mf;var g=a.ib,h=a.$g,k=!0,l=!0,m=!1;if(a instanceof G)var n=a,k=n.Nj,l=n.Bc,m=n.Uq;b.Ei(a);b.cloneProtected(a);a.Pi=d;a.K();a.ra();b=a.g;d=!0;null!==b&&(d=b.lb,b.lb=!0);a.Pd=e;null!==e&&a.Ob();null!==b&&(b.lb=d);e=a.Mf;e!==c&&(a.Ml=c,
a.Mf=e);a instanceof G&&(n=a,n.Nj=k,n.Bc=l,n.Uq=m,n.pe()&&n.N(64));a.ib=g;a.$g=h}F.prototype.canCopy=function(){if(!this.cF)return!1;var a=this.layer;if(null===a)return!0;if(!a.Mk)return!1;a=a.g;return null===a?!0:a.Mk?!0:!1};F.prototype.canDelete=function(){if(!this.gF)return!1;var a=this.layer;if(null===a)return!0;if(!a.Dn)return!1;a=a.g;return null===a?!0:a.Dn?!0:!1};
F.prototype.canEdit=function(){if(!this.eH)return!1;var a=this.layer;if(null===a)return!0;if(!a.tx)return!1;a=a.g;return null===a?!0:a.tx?!0:!1};F.prototype.canGroup=function(){if(!this.NF)return!1;var a=this.layer;if(null===a)return!0;if(!a.px)return!1;a=a.g;return null===a?!0:a.px?!0:!1};F.prototype.canMove=function(){if(!this.qG)return!1;var a=this.layer;if(null===a)return!0;if(!a.em)return!1;a=a.g;return null===a?!0:a.em?!0:!1};
F.prototype.canReshape=function(){if(!this.IG)return!1;var a=this.layer;if(null===a)return!0;if(!a.qx)return!1;a=a.g;return null===a?!0:a.qx?!0:!1};F.prototype.canResize=function(){if(!this.JG)return!1;var a=this.layer;if(null===a)return!0;if(!a.Yt)return!1;a=a.g;return null===a?!0:a.Yt?!0:!1};F.prototype.canRotate=function(){if(!this.OG)return!1;var a=this.layer;if(null===a)return!0;if(!a.sx)return!1;a=a.g;return null===a?!0:a.sx?!0:!1};
F.prototype.canSelect=function(){if(!this.el)return!1;var a=this.layer;if(null===a)return!0;if(!a.If)return!1;a=a.g;return null===a?!0:a.If?!0:!1};D.defineProperty(F,{cF:"copyable"},function(){return 0!==(this.S&1)},function(a){var b=0!==(this.S&1);b!==a&&(v&&D.h(a,"boolean",F,"copyable"),this.S^=1,this.i("copyable",b,a))});
D.defineProperty(F,{gF:"deletable"},function(){return 0!==(this.S&2)},function(a){var b=0!==(this.S&2);b!==a&&(v&&D.h(a,"boolean",F,"deletable"),this.S^=2,this.i("deletable",b,a))});D.defineProperty(F,{eH:"textEditable"},function(){return 0!==(this.S&4)},function(a){var b=0!==(this.S&4);b!==a&&(v&&D.h(a,"boolean",F,"textEditable"),this.S^=4,this.i("textEditable",b,a),this.oe())});
D.defineProperty(F,{NF:"groupable"},function(){return 0!==(this.S&8)},function(a){var b=0!==(this.S&8);b!==a&&(v&&D.h(a,"boolean",F,"groupable"),this.S^=8,this.i("groupable",b,a))});D.defineProperty(F,{qG:"movable"},function(){return 0!==(this.S&16)},function(a){var b=0!==(this.S&16);b!==a&&(v&&D.h(a,"boolean",F,"movable"),this.S^=16,this.i("movable",b,a))});
D.defineProperty(F,{UG:"selectionAdorned"},function(){return 0!==(this.S&32)},function(a){var b=0!==(this.S&32);b!==a&&(v&&D.h(a,"boolean",F,"selectionAdorned"),this.S^=32,this.i("selectionAdorned",b,a),this.oe())});D.defineProperty(F,{Xx:"isInDocumentBounds"},function(){return 0!==(this.S&64)},function(a){var b=0!==(this.S&64);if(b!==a){v&&D.h(a,"boolean",F,"isInDocumentBounds");this.S^=64;var c=this.g;null!==c&&c.Nc();this.i("isInDocumentBounds",b,a)}});
D.defineProperty(F,{gB:"isLayoutPositioned"},function(){return 0!==(this.S&128)},function(a){var b=0!==(this.S&128);b!==a&&(v&&D.h(a,"boolean",F,"isLayoutPositioned"),this.S^=128,this.i("isLayoutPositioned",b,a),this.N(a?4:8))});D.defineProperty(F,{el:"selectable"},function(){return 0!==(this.S&256)},function(a){var b=0!==(this.S&256);b!==a&&(v&&D.h(a,"boolean",F,"selectable"),this.S^=256,this.i("selectable",b,a),this.oe())});
D.defineProperty(F,{IG:"reshapable"},function(){return 0!==(this.S&512)},function(a){var b=0!==(this.S&512);b!==a&&(v&&D.h(a,"boolean",F,"reshapable"),this.S^=512,this.i("reshapable",b,a),this.oe())});D.defineProperty(F,{JG:"resizable"},function(){return 0!==(this.S&1024)},function(a){var b=0!==(this.S&1024);b!==a&&(v&&D.h(a,"boolean",F,"resizable"),this.S^=1024,this.i("resizable",b,a),this.oe())});
D.defineProperty(F,{OG:"rotatable"},function(){return 0!==(this.S&2048)},function(a){var b=0!==(this.S&2048);b!==a&&(v&&D.h(a,"boolean",F,"rotatable"),this.S^=2048,this.i("rotatable",b,a),this.oe())});
D.defineProperty(F,{ib:"isSelected"},function(){return 0!==(this.S&4096)},function(a){var b=0!==(this.S&4096);if(b!==a){v&&D.h(a,"boolean",F,"isSelected");var c=this.g;if(!a||this.canSelect()&&!(null!==c&&c.selection.count>=c.GJ)){this.S^=4096;var d=!1;if(null!==c){d=c.lb;c.lb=!0;var e=c.selection;e.Xa();a?e.add(this):e.remove(this);e.freeze()}this.i("isSelected",b,a);this.oe();a=this.kK;null!==a&&a(this);null!==c&&(c.Ve(),c.lb=d)}}});
D.defineProperty(F,{$g:"isHighlighted"},function(){return 0!==(this.S&524288)},function(a){var b=0!==(this.S&524288);if(b!==a){v&&D.h(a,"boolean",F,"isHighlighted");this.S^=524288;var c=this.g;null!==c&&(c=c.wm,c.Xa(),a?c.add(this):c.remove(this),c.freeze());this.i("isHighlighted",b,a);this.ra();a=this.$I;null!==a&&a(this)}});
D.defineProperty(F,{$k:"isShadowed"},function(){return 0!==(this.S&8192)},function(a){var b=0!==(this.S&8192);b!==a&&(v&&D.h(a,"boolean",F,"isShadowed"),this.S^=8192,this.i("isShadowed",b,a),this.ra())});function Om(a){return 0!==(a.S&32768)}function hr(a,b){a.S=b?a.S|32768:a.S&-32769}function Po(a,b){a.S=b?a.S|65536:a.S&-65537}function sl(a){return 0!==(a.S&131072)}F.prototype.Of=function(a){this.S=a?this.S|131072:this.S&-131073};function qr(a,b){a.S=b?a.S|1048576:a.S&-1048577}
D.defineProperty(F,{UF:"isAnimated"},function(){return 0!==(this.S&262144)},function(a){var b=0!==(this.S&262144);b!==a&&(v&&D.h(a,"boolean",F,"isAnimated"),this.S^=262144,this.i("isAnimated",b,a))});D.defineProperty(F,{$I:"highlightedChanged"},function(){return this.bs},function(a){var b=this.bs;b!==a&&(null!==a&&D.h(a,"function",F,"highlightedChanged"),this.bs=a,this.i("highlightedChanged",b,a))});
D.defineProperty(F,{Hy:"selectionObjectName"},function(){return this.st},function(a){var b=this.st;b!==a&&(v&&D.h(a,"string",F,"selectionObjectName"),this.st=a,this.un=null,this.i("selectionObjectName",b,a))});D.defineProperty(F,{jK:"selectionAdornmentTemplate"},function(){return this.qt},function(a){var b=this.qt;b!==a&&(v&&D.l(a,da,F,"selectionAdornmentTemplate"),this instanceof J&&(a.type=rj),this.qt=a,this.i("selectionAdornmentTemplate",b,a))});
D.w(F,{to:"selectionObject"},function(){if(null===this.un){var a=this.Hy;null!==a&&""!==a?(a=this.Hd(a),this.un=null!==a?a:this):this instanceof J?(a=this.path,this.un=null!==a?a:this):this.un=this}return this.un});D.defineProperty(F,{kK:"selectionChanged"},function(){return this.rt},function(a){var b=this.rt;b!==a&&(null!==a&&D.h(a,"function",F,"selectionChanged"),this.rt=a,this.i("selectionChanged",b,a))});
D.defineProperty(F,{KG:"resizeAdornmentTemplate"},function(){return this.et},function(a){var b=this.et;b!==a&&(v&&D.l(a,da,F,"resizeAdornmentTemplate"),this.et=a,this.i("resizeAdornmentTemplate",b,a))});D.defineProperty(F,{MG:"resizeObjectName"},function(){return this.gt},function(a){var b=this.gt;b!==a&&(v&&D.h(a,"string",F,"resizeObjectName"),this.gt=a,this.Ep=null,this.i("resizeObjectName",b,a))});
D.w(F,{LG:"resizeObject"},function(){if(null===this.Ep){var a=this.MG;null!==a&&""!==a?(a=this.Hd(a),this.Ep=null!==a?a:this):this.Ep=this}return this.Ep});D.defineProperty(F,{bK:"resizeCellSize"},function(){return this.ft},function(a){var b=this.ft;b.O(a)||(v&&D.l(a,Ca,F,"resizeCellSize"),this.ft=a=a.V(),this.i("resizeCellSize",b,a))});
D.defineProperty(F,{dK:"rotateAdornmentTemplate"},function(){return this.ht},function(a){var b=this.ht;b!==a&&(v&&D.l(a,da,F,"rotateAdornmentTemplate"),this.ht=a,this.i("rotateAdornmentTemplate",b,a))});D.defineProperty(F,{eK:"rotateObjectName"},function(){return this.it},function(a){var b=this.it;b!==a&&(v&&D.h(a,"string",F,"rotateObjectName"),this.it=a,this.Fp=null,this.i("rotateObjectName",b,a))});
D.w(F,{GB:"rotateObject"},function(){if(null===this.Fp){var a=this.eK;null!==a&&""!==a?(a=this.Hd(a),this.Fp=null!==a?a:this):this.Fp=this}return this.Fp});D.defineProperty(F,{text:"text"},function(){return this.Vd},function(a){var b=this.Vd;b!==a&&(v&&D.h(a,"string",F,"text"),this.Vd=a,this.i("text",b,a))});
D.defineProperty(F,{Ka:"containingGroup"},function(){return this.wl},function(a){if(this.pe()){var b=this.wl;if(b!==a){v&&null!==a&&D.l(a,I,F,"containingGroup");null===a||this!==a&&!a.Gi(this)||(this===a&&D.k("Cannot make a Group a member of itself: "+this.toString()),D.k("Cannot make a Group indirectly contain itself: "+this.toString()+" already contains "+a.toString()));this.N(dn);var c=this.g;null!==b?rr(b,this):this instanceof I&&null!==c&&c.Zl.remove(this);this.wl=a;null!==a?sr(a,this):this instanceof
I&&null!==c&&c.Zl.add(this);this.N(Vm);if(null!==c){var d=this.data,e=c.da;null!==d&&e instanceof Y&&e.NB(d,e.zb(null!==a?a.data:null))}d=this.$E;null!==d&&(e=!0,null!==c&&(e=c.$a,c.$a=!0),d(this,b,a),null!==c&&(c.$a=e));if(this instanceof I)for(c=new L(F),Qh(c,this,!0,0,!0),c=c.j;c.next();)if(d=c.value,d instanceof G)for(d=d.Kd;d.next();)zn(d.value);if(this instanceof G){for(d=this.Kd;d.next();)zn(d.value);c=this.Yb;null!==c&&zn(c)}this.i("containingGroup",b,a);null!==a&&(b=a.layer,null!==b&&xl(b,
-1,a))}}else D.k("cannot set the Part.containingGroup of a Link or Adornment")});f=F.prototype;f.Yk=function(){var a=this.Ka;null!==a&&(a.K(),null!==a.Rb&&a.Rb.K(),a.kg())};f.ra=function(){var a=this.g;null!==a&&!Nm(this)&&!Om(this)&&this.isVisible()&&this.cc.F()&&a.ra(wl(this,this.cc))};f.K=function(){x.prototype.K.call(this);var a=this.g;null!==a&&(a.Hg.add(this),this instanceof G&&null!==this.Yb&&Zo(this.Yb),a.Ve(!0))};f.wu=function(a){a||(a=this.wl,null!==a&&sr(a,this))};
f.xu=function(a){a||(a=this.wl,null!==a&&rr(a,this))};f.On=function(){var a=this.data;if(null!==a){var b=this.g;null!==b&&(b=b.da,null!==b&&b.Cy(a))}};D.defineProperty(F,{$E:"containingGroupChanged"},function(){return this.pr},function(a){var b=this.pr;b!==a&&(null!==a&&D.h(a,"function",F,"containingGroupChanged"),this.pr=a,this.i("containingGroupChanged",b,a))});F.prototype.findSubGraphLevel=function(){return tr(this,this)};
function tr(a,b){var c=b.Ka;return null!==c?1+tr(a,c):b instanceof G&&(c=b.Yb,null!==c)?tr(a,c):0}F.prototype.findTopLevelPart=function(){return ur(this,this)};function ur(a,b){var c=b.Ka;return null!==c?ur(a,c):b instanceof G&&(c=b.Yb,null!==c)?ur(a,c):b}D.w(F,{wq:"isTopLevel"},function(){return null!==this.Ka||this instanceof G&&null!==this.Yb?!1:!0});F.prototype.isMemberOf=F.prototype.Gi=function(a){return a instanceof I?vr(this,this,a):!1};
function vr(a,b,c){if(b===c||null===c)return!1;var d=b.Ka;return null===d||d!==c&&!vr(a,d,c)?b instanceof G&&(b=b.Yb,null!==b)?vr(a,b,c):!1:!0}
F.prototype.findCommonContainingGroup=F.prototype.GI=function(a){if(null===a)return null;v&&D.l(a,F,F,"findCommonContainingGroup:other");if(this===a)return this.Ka;for(var b=this;null!==b;){b instanceof I&&qr(b,!0);if(b instanceof G){var c=b.Yb;null!==c&&(b=c)}b=b.Ka}for(var d=null,b=a;null!==b;){if(0!==(b.S&1048576)){d=b;break}b instanceof G&&(c=b.Yb,null!==c&&(b=c));b=b.Ka}for(b=this;null!==b;)b instanceof I&&qr(b,!1),b instanceof G&&(c=b.Yb,null!==c&&(b=c)),b=b.Ka;return d};
D.defineProperty(F,{wJ:"layoutConditions"},function(){return this.rs},function(a){var b=this.rs;b!==a&&(v&&D.h(a,"number",F,"layoutConditions"),this.rs=a,this.i("layoutConditions",b,a))});F.prototype.canLayout=function(){if(!this.gB||!this.isVisible())return!1;var a=this.layer;return null!==a&&a.Wc||this instanceof G&&this.Kf?!1:!0};
F.prototype.invalidateLayout=F.prototype.N=function(a){void 0===a&&(a=16777215);var b;this.gB&&0!==(a&this.wJ)?(b=this.layer,null!==b&&b.Wc||this instanceof G&&this.Kf?b=!1:(b=this.g,b=null!==b&&b.la.jb?!1:!0)):b=!1;if(b)if(b=this.wl,null!==b){var c=b.Zb;null!==c?c.N():b.N(a)}else a=this.g,null!==a&&(c=a.Zb,null!==c&&c.N())};function $m(a){if(!a.isVisible())return!1;a=a.layer;return null!==a&&a.Wc?!1:!0}
D.defineProperty(F,{lF:"dragComputation"},function(){return this.Jr},function(a){var b=this.Jr;b!==a&&(null!==a&&D.h(a,"function",F,"dragComputation"),this.Jr=a,this.i("dragComputation",b,a))});D.defineProperty(F,{nK:"shadowOffset"},function(){return this.wn},function(a){var b=this.wn;b.O(a)||(v&&D.l(a,N,F,"shadowOffset"),this.wn=a=a.V(),this.ra(),this.i("shadowOffset",b,a))});
D.defineProperty(F,{shadowColor:"shadowColor"},function(){return this.vn},function(a){var b=this.vn;b!==a&&(v&&D.h(a,"string",F,"shadowColor"),this.vn=a,this.ra(),this.i("shadowColor",b,a))});D.defineProperty(F,{shadowBlur:"shadowBlur"},function(){return this.Qg},function(a){var b=this.Qg;b!==a&&(v&&D.h(a,"number",F,"shadowBlur"),this.Qg=a,this.ra(),this.i("shadowBlur",b,a))});
function da(a){0===arguments.length?F.call(this,dk):F.call(this,a);this.S&=-257;this.Ml="Adornment";this.Rc=null;this.OD=0;this.EE=!1;this.Rb=this.rh=null}D.Sa(da,F);D.ka("Adornment",da);da.prototype.toString=function(){var a=this.Hf;return"Adornment("+this.Gd+")"+(null!==a?a.toString():"")};da.prototype.updateRelationshipsFromData=function(){};
da.prototype.Mu=function(a){var b=this.Bb.Y,c=this.Bb;if(b instanceof J&&c instanceof z){var d=b.path,c=d.uf;b.Mu(a);c=d.uf;a=this.xa.o;b=a.length;for(d=0;d<b;d++){var e=a[d];e.Ue&&e instanceof z&&(e.ab=c)}}};D.w(da,{placeholder:"placeholder"},function(){return this.Rb});
D.defineProperty(da,{Bb:"adornedObject"},function(){return this.Rc},function(a){v&&null!==a&&D.l(a,P,F,"adornedObject:value");var b=this.Hf,c=null;null!==a&&(c=a.Y);null===b||null!==a&&b===c||b.Tj(this.Gd);this.Rc=a;null!==c&&c.bm(this.Gd,this)});D.w(da,{Hf:"adornedPart"},function(){var a=this.Rc;return null!==a?a.Y:null});da.prototype.zm=function(){var a=this.Rc;if(null===a)return!0;a=a.Y;return null===a||!Nm(a)};da.prototype.pe=function(){return!1};D.w(da,{Ka:"containingGroup"},function(){return null});
da.prototype.qo=function(a,b,c,d,e,g,h){if(a===rg&&"elements"===b)if(e instanceof Xj){var k=e;null===this.Rb?this.Rb=k:this.Rb!==k&&D.k("Cannot insert a second Placeholder into the visual tree of an Adornment.")}else e instanceof x&&(k=e.iu(function(a){return a instanceof Xj}),k instanceof Xj&&(null===this.Rb?this.Rb=k:this.Rb!==k&&D.k("Cannot insert a second Placeholder into the visual tree of an Adornment.")));else a===sg&&"elements"===b&&null!==this.Rb&&(d===this.Rb?this.Rb=null:d instanceof x&&
this.Rb.ym(d)&&(this.Rb=null));F.prototype.qo.call(this,a,b,c,d,e,g,h)};da.prototype.updateAdornments=function(){};da.prototype.On=function(){};function G(a){F.call(this,a);this.Ca=13;this.wc=new K(J);this.Mt=this.pp=this.Ol=this.ts=this.ss=null;this.ir=Td;this.ze=this.$h=null;this.at=wr;this.Jk=!1}D.Sa(G,F);D.ka("Node",G);
G.prototype.cloneProtected=function(a){F.prototype.cloneProtected.call(this,a);a.Ca=this.Ca;a.Ca=this.Ca&-17;a.ss=this.ss;a.ts=this.ts;a.Ol=this.Ol;a.Mt=this.Mt;a.ir=this.ir.V();a.at=this.at};G.prototype.Ei=function(a){F.prototype.Ei.call(this,a);a.kg();a.$h=this.$h;a.ze=null};var xr;G.DirectionDefault=xr=D.s(G,"DirectionDefault",0);G.DirectionAbsolute=D.s(G,"DirectionAbsolute",1);var yr;G.DirectionRotatedNode=yr=D.s(G,"DirectionRotatedNode",2);var Fo;
G.DirectionRotatedNodeOrthogonal=Fo=D.s(G,"DirectionRotatedNodeOrthogonal",3);G.SpreadingNone=D.s(G,"SpreadingNone",10);var wr;G.SpreadingEvenly=wr=D.s(G,"SpreadingEvenly",11);var zr;G.SpreadingPacked=zr=D.s(G,"SpreadingPacked",12);function Ar(a,b){null!==b&&(null===a.$h&&(a.$h=new L(Hl)),a.$h.add(b))}
function Br(a,b,c,d){if(null===b||null===a.$h)return null;for(var e=a.$h.j;e.next();){var g=e.value;if(g.Cq===a&&g.Ru===b&&g.uy===c&&g.vy===d||g.Cq===b&&g.Ru===a&&g.uy===d&&g.vy===c)return g}return null}G.prototype.invalidateLinkBundle=function(a,b,c){if(void 0===b||null===b)b="";if(void 0===c||null===c)c="";a=Br(this,a,b,c);null!==a&&a.tq()};G.prototype.qo=function(a,b,c,d,e,g,h){a===rg&&"elements"===b?this.ze=null:a===sg&&"elements"===b&&(this.ze=null);F.prototype.qo.call(this,a,b,c,d,e,g,h)};
G.prototype.invalidateConnectedLinks=G.prototype.kg=function(a){void 0===a&&(a=null);for(var b=this.Kd;b.next();){var c=b.value;null!==a&&a.contains(c)||(Cr(this,c.Ac),Cr(this,c.ad),c.ec())}};function Qo(a,b){for(var c=a.Kd;c.next();){var d=c.value;if(d.Ac===b||d.ad===b)Cr(a,d.Ac),Cr(a,d.ad),d.ec()}}function Cr(a,b){if(null!==b){var c=b.$s;null!==c&&c.tq();c=a.Ka;null===c||c.ld||Cr(c,c.port)}}G.prototype.zm=function(){return!0};
D.defineProperty(G,{VJ:"portSpreading"},function(){return this.at},function(a){var b=this.at;b!==a&&(v&&D.Da(a,G,G,"portSpreading"),this.at=a,this.i("portSpreading",b,a),a=this.g,null!==a&&a.la.jb||this.kg())});D.defineProperty(G,{wA:"avoidable"},function(){return 0!==(this.Ca&8)},function(a){var b=0!==(this.Ca&8);if(b!==a){v&&D.h(a,"boolean",G,"avoidable");this.Ca^=8;var c=this.g;null!==c&&c.dB(this);this.i("avoidable",b,a)}});
D.defineProperty(G,{ZH:"avoidableMargin"},function(){return this.ir},function(a){"number"===typeof a?a=new Lb(a):D.l(a,Lb,G,"avoidableMargin");var b=this.ir;if(!b.O(a)){this.ir=a=a.V();var c=this.g;null!==c&&c.dB(this);this.i("avoidableMargin",b,a)}});G.prototype.getAvoidableRect=function(a){a.set(this.Z);a.nx(this.ZH);return a};G.prototype.findVisibleNode=function(){for(var a=this;null!==a&&!a.isVisible();)a=a.Ka;return a};
G.prototype.isVisible=function(){if(!F.prototype.isVisible.call(this))return!1;var a=!0,b=Dl,c=this.g;if(null!==c){a=c.Wa;if(a.yf&&(a=a.kn.oa(this),null!==a&&a.zy))return!0;a=c.de;b=c.eC}if(b===Dl){if(c=this.Tk(),null!==c&&!c.Bc)return!1}else if(b===hn){if(c=a?this.wF():this.xF(),0<c.count&&c.all(function(a){return!a.Bc}))return!1}else if(b===jn&&(c=a?this.wF():this.xF(),0<c.count&&c.any(function(a){return!a.Bc})))return!1;c=this.Yb;return null!==c?c.isVisible():!0};
G.prototype.Ld=function(a){F.prototype.Ld.call(this,a);for(var b=this.Kd;b.next();)b.value.Ld(a)};D.w(G,{Kd:"linksConnected"},function(){return this.wc.j});G.prototype.findLinksConnected=G.prototype.uF=function(a){void 0===a&&(a=null);if(null===a)return this.wc.j;v&&D.h(a,"string",G,"findLinksConnected:pid");var b=new Qa(this.wc),c=this;b.Eq=function(b){return b.aa===c&&b.hg===a||b.ba===c&&b.mh===a};return b};
G.prototype.findLinksOutOf=G.prototype.Mx=function(a){void 0===a&&(a=null);v&&null!==a&&D.h(a,"string",G,"findLinksOutOf:pid");var b=new Qa(this.wc),c=this;b.Eq=function(b){return b.aa!==c?!1:null===a?!0:b.hg===a};return b};G.prototype.findLinksInto=G.prototype.Zg=function(a){void 0===a&&(a=null);v&&null!==a&&D.h(a,"string",G,"findLinksInto:pid");var b=new Qa(this.wc),c=this;b.Eq=function(b){return b.ba!==c?!1:null===a?!0:b.mh===a};return b};
G.prototype.findNodesConnected=G.prototype.vF=function(a){void 0===a&&(a=null);v&&null!==a&&D.h(a,"string",G,"findNodesConnected:pid");for(var b=null,c=null,d=this.wc.j;d.next();){var e=d.value;if(e.aa===this){if(null===a||e.hg===a)e=e.ba,null!==b?b.add(e):null!==c&&c!==e?(b=new L(G),b.add(c),b.add(e)):c=e}else e.ba!==this||null!==a&&e.mh!==a||(e=e.aa,null!==b?b.add(e):null!==c&&c!==e?(b=new L(G),b.add(c),b.add(e)):c=e)}return null!==b?b.j:null!==c?new Ma(c):Ka};
G.prototype.findNodesOutOf=G.prototype.xF=function(a){void 0===a&&(a=null);v&&null!==a&&D.h(a,"string",G,"findNodesOutOf:pid");for(var b=null,c=null,d=this.wc.j;d.next();){var e=d.value;e.aa!==this||null!==a&&e.hg!==a||(e=e.ba,null!==b?b.add(e):null!==c&&c!==e?(b=new L(G),b.add(c),b.add(e)):c=e)}return null!==b?b.j:null!==c?new Ma(c):Ka};
G.prototype.findNodesInto=G.prototype.wF=function(a){void 0===a&&(a=null);v&&null!==a&&D.h(a,"string",G,"findNodesInto:pid");for(var b=null,c=null,d=this.wc.j;d.next();){var e=d.value;e.ba!==this||null!==a&&e.mh!==a||(e=e.aa,null!==b?b.add(e):null!==c&&c!==e?(b=new L(G),b.add(c),b.add(e)):c=e)}return null!==b?b.j:null!==c?new Ma(c):Ka};
G.prototype.findLinksBetween=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);v&&(D.l(a,G,G,"findLinksBetween:othernode"),null!==b&&D.h(b,"string",G,"findLinksBetween:pid"),null!==c&&D.h(c,"string",G,"findLinksBetween:otherpid"));var d=new Qa(this.wc),e=this;d.Eq=function(d){return(d.aa!==e||d.ba!==a||null!==b&&d.hg!==b||null!==c&&d.mh!==c)&&(d.aa!==a||d.ba!==e||null!==c&&d.hg!==c||null!==b&&d.mh!==b)?!1:!0};return d};
G.prototype.findLinksTo=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);v&&(D.l(a,G,G,"findLinksTo:othernode"),null!==b&&D.h(b,"string",G,"findLinksTo:pid"),null!==c&&D.h(c,"string",G,"findLinksTo:otherpid"));var d=new Qa(this.wc),e=this;d.Eq=function(d){return d.aa!==e||d.ba!==a||null!==b&&d.hg!==b||null!==c&&d.mh===c?!1:!0};return d};
D.defineProperty(G,{xJ:"linkConnected"},function(){return this.ss},function(a){var b=this.ss;b!==a&&(null!==a&&D.h(a,"function",G,"linkConnected"),this.ss=a,this.i("linkConnected",b,a))});D.defineProperty(G,{yJ:"linkDisconnected"},function(){return this.ts},function(a){var b=this.ts;b!==a&&(null!==a&&D.h(a,"function",G,"linkDisconnected"),this.ts=a,this.i("linkDisconnected",b,a))});
D.defineProperty(G,{jy:"linkValidation"},function(){return this.Ol},function(a){var b=this.Ol;b!==a&&(null!==a&&D.h(a,"function",G,"linkValidation"),this.Ol=a,this.i("linkValidation",b,a))});
function Dr(a,b,c){Cr(a,c);var d=a.wc.contains(b);d||a.wc.add(b);if(!d||b.aa===b.ba){var e=a.xJ;if(null!==e){var g=!0,h=a.g;null!==h&&(g=h.$a,h.$a=!0);e(a,b,c);null!==h&&(h.$a=g)}}!d&&b.Xc&&(c=b.aa,b=b.ba,null!==c&&null!==b&&c!==b&&(d=!0,h=a.g,null!==h&&(d=h.de),a=d?b:c,e=d?c:b,a.Jk||(a.Jk=e),!e.Nj||null!==h&&h.la.jb||(d?c===e&&(e.Nj=!1):b===e&&(e.Nj=!1))))}
function Er(a,b,c){Cr(a,c);var d=a.wc.remove(b);if(d||b.ba===b.aa){var e=a.yJ,g=a.g;if(null!==e){var h=!0;null!==g&&(h=g.$a,g.$a=!0);e(a,b,c);null!==g&&(g.$a=h)}}d&&b.Xc&&(c=!0,null!==g&&(c=g.de),a=c?b.ba:b.aa,b=c?b.aa:b.ba,null!==a&&(a.Jk=!1),null===b||b.Nj||(0===b.wc.count?(b.Jk=null,null!==g&&g.la.jb||(b.Nj=!0)):Ln(b)))}
function Ln(a){a.Jk=!1;if(0!==a.wc.count){var b=!0,c=a.g;if(null===c||!c.la.jb){null!==c&&(b=c.de);for(c=a.wc.j;c.next();){var d=c.value;if(d.Xc)if(b){if(d.aa===a){a.Nj=!1;return}}else if(d.ba===a){a.Nj=!1;return}}a.Nj=!0}}}
G.prototype.updateRelationshipsFromData=function(){F.prototype.updateRelationshipsFromData.call(this);var a=this.data;if(null!==a){var b=this.g;if(null!==b){var c=b.da;c instanceof Bg&&(a=c.Vn(a),a=b.Se(a),c=this.Tk(),a!==c&&(c=this.Rn(),null!==a?null!==c?b.de?c.aa=a:c.ba=a:on(b,a,this):null!==c&&cn(b,c,!1)))}}};G.prototype.wu=function(a){F.prototype.wu.call(this,a);a||(Ln(this),a=this.pp,null!==a&&Fr(a,this))};
G.prototype.xu=function(a){F.prototype.xu.call(this,a);a||(a=this.pp,null!==a&&null!==a.Ff&&(a.Ff.remove(this),a.K()))};G.prototype.On=function(){if(0<this.wc.count){var a=this.g;if(null!==a)for(var b=a.yb.kF,c=this.wc.copy().j;c.next();){var d=c.value;b?a.remove(d):(d.aa===this&&(d.aa=null),d.ba===this&&(d.ba=null))}}this.Yb=null;F.prototype.On.call(this)};D.w(G,{Kf:"isLinkLabel"},function(){return null!==this.pp});
D.defineProperty(G,{Yb:"labeledLink"},function(){return this.pp},function(a){var b=this.pp;if(b!==a){v&&null!==a&&D.l(a,J,G,"labeledLink");var c=this.g,d=this.data;if(null!==b){null!==b.Ff&&(b.Ff.remove(this),b.K());if(null!==c&&null!==d&&!c.la.jb){var e=b.data,g=c.da;if(null!==e&&g instanceof Y){var h=g.zb(d);void 0!==h&&g.$J(e,h)}}this.Ka=null}this.pp=a;null!==a&&(Fr(a,this),null===c||null===d||c.la.jb||(e=a.data,g=c.da,null!==e&&g instanceof Y&&(h=g.zb(d),void 0!==h&&g.LE(e,h))),this.Ka=a.Ka);
Zo(this);this.i("labeledLink",b,a)}});G.prototype.findPort=G.prototype.QA=function(a){v&&D.h(a,"string",G,"findPort:pid");if(null===this.ze){if(""===a&&!1===this.Xk)return this;ep(this)}var b=this.ze.oa(a);return null!==b||""!==a&&(b=this.ze.oa(""),null!==b)?b:this};D.w(G,{port:"port"},function(){return this.QA("")});D.w(G,{ports:"ports"},function(){null===this.ze&&ep(this);return this.ze.hG});
function ep(a){null===a.ze?a.ze=new oa("string",P):a.ze.clear();gp(a,a,function(a,c){var d=c.wd;null!==d&&a.ze.add(d,c)});0===a.ze.count&&a.ze.add("",a)}function dp(a,b,c){var d=b.wd;if(null!==d&&(null!==a.ze&&a.ze.remove(d),b=a.g,null!==b&&c)){c=null;for(d=a.uF(d);d.next();)a=d.value,null===c&&(c=D.nb()),c.push(a);if(null!==c){for(d=0;d<c.length;d++)a=c[d],b.remove(a);D.ya(c)}}}
G.prototype.isInTreeOf=function(a){if(null===a||a===this)return!1;var b=!0,c=this.g;null!==c&&(b=c.de);c=this;if(b)for(;c!==a;){for(var b=null,d=c.wc.j;d.next();){var e=d.value;if(e.Xc&&(b=e.aa,b!==c&&b!==this))break}if(b===this||null===b||b===c)return!1;c=b}else for(;c!==a;){b=null;for(d=c.wc.j;d.next()&&(e=d.value,!e.Xc||(b=e.ba,b===c||b===this)););if(b===this||null===b||b===c)return!1;c=b}return!0};
G.prototype.findTreeRoot=function(){var a=!0,b=this.g;null!==b&&(a=b.de);b=this;if(a)for(;;){for(var a=null,c=b.wc.j;c.next();){var d=c.value;if(d.Xc&&(a=d.aa,a!==b&&a!==this))break}if(a===this)return this;if(null===a||a===b)return b;b=a}else for(;;){a=null;for(c=b.wc.j;c.next()&&(d=c.value,!d.Xc||(a=d.ba,a===b||a===this)););if(a===this)return this;if(null===a||a===b)return b;b=a}};
G.prototype.findCommonTreeParent=function(a){if(null===a)return null;v&&D.l(a,G,G,"findCommonTreeParent:other");if(this===a)return this;for(var b=this;null!==b;)qr(b,!0),b=b.Tk();for(var c=null,b=a;null!==b;){if(0!==(b.S&1048576)){c=b;break}b=b.Tk()}for(b=this;null!==b;)qr(b,!1),b=b.Tk();return c};
G.prototype.findTreeParentLink=G.prototype.Rn=function(){var a=!0,b=this.g;null!==b&&(a=b.de);b=this.wc.j;if(a)for(;b.next();){if(a=b.value,a.Xc&&a.aa!==this)return a}else for(;b.next();)if(a=b.value,a.Xc&&a.ba!==this)return a;return null};
G.prototype.findTreeParentNode=G.prototype.Tk=function(){var a=this.Jk;if(null===a)return null;if(a instanceof G)return a;var b=!0,a=this.g;null!==a&&(b=a.de);a=this.wc.j;if(b)for(;a.next();){if(b=a.value,b.Xc&&(b=b.aa,b!==this))return this.Jk=b}else for(;a.next();)if(b=a.value,b.Xc&&(b=b.ba,b!==this))return this.Jk=b;return this.Jk=null};G.prototype.findTreeParentChain=function(){function a(b,d){if(null!==b){d.add(b);var e=b.Rn();null!==e&&(d.add(e),a(b.Tk(),d))}}var b=new L(F);a(this,b);return b};
G.prototype.findTreeLevel=function(){return Gr(this,this)};function Gr(a,b){var c=b.Tk();return null===c?0:1+Gr(a,c)}G.prototype.findTreeChildrenLinks=G.prototype.Ox=function(){var a=!0,b=this.g;null!==b&&(a=b.de);var b=new Qa(this.wc),c=this;b.Eq=a?function(a){return a.Xc&&a.aa===c?!0:!1}:function(a){return a.Xc&&a.ba===c?!0:!1};return b};
G.prototype.findTreeChildrenNodes=G.prototype.zF=function(){var a=!0,b=this.g;null!==b&&(a=b.de);var c=b=null,d=this.wc.j;if(a)for(;d.next();)a=d.value,a.Xc&&a.aa===this&&(a=a.ba,null!==b?b.add(a):null!==c&&c!==a?(b=new K(G),b.add(c),b.add(a)):c=a);else for(;d.next();)a=d.value,a.Xc&&a.ba===this&&(a=a.aa,null!==b?b.add(a):null!==c&&c!==a?(b=new K(G),b.add(c),b.add(a)):c=a);return null!==b?b.j:null!==c?new Ma(c):Ka};
G.prototype.findTreeParts=function(a){void 0===a&&(a=Infinity);D.h(a,"number",G,"findTreeParts:level");var b=new L(F);Qh(b,this,!1,a,!0);return b};G.prototype.collapseTree=G.prototype.collapseTree=function(a){void 0===a&&(a=1);D.p(a,G,"collapseTree:level");1>a&&(a=1);var b=this.g;if(null!==b&&!b.Qh){b.Qh=!0;var c=new L(G);c.add(this);Hr(this,c,b.de,a,b.Wa,this,b.eC===Dl);b.Qh=!1}};
function Hr(a,b,c,d,e,g,h){if(1<d)for(var k=c?a.Mx():a.Zg();k.next();){var l=k.value;l.Xc&&(l=l.VA(a),null===l||l===a||b.contains(l)||(b.add(l),Hr(l,b,c,d-1,e,g,h)))}else Ir(a,b,c,e,g,h)}function Ir(a,b,c,d,e,g){for(var h=e===a?!0:a.Bc,k=c?a.Mx():a.Zg();k.next();){var l=k.value;l.Xc&&(l=l.VA(a),null===l||l===a||b.contains(l)||(b.add(l),h&&(g&&pl(d,l,e),l.Yk(),l.Ld(!1)),l.Bc&&(l.Uq=l.Bc,Ir(l,b,c,d,e,g))))}a.Bc=!1}
G.prototype.expandTree=G.prototype.expandTree=function(a){void 0===a&&(a=2);D.p(a,G,"expandTree:level");2>a&&(a=2);var b=this.g;if(null!==b&&!b.Qh){b.Qh=!0;var c=new L(G);c.add(this);Jr(this,c,b.de,a,b.Wa,this,b.eC===Dl);b.Qh=!1}};function Jr(a,b,c,d,e,g,h){for(var k=g===a?!1:a.Bc,l=c?a.Mx():a.Zg();l.next();){var m=l.value;m.Xc&&(k||m.Tf||m.ec(),m=m.VA(a),null!==m&&m!==a&&!b.contains(m)&&(b.add(m),k||(m.Ld(!0),m.Yk(),h&&ol(e,m,g)),2<d||m.Uq))&&(m.Uq=!1,Jr(m,b,c,d-1,e,g,h))}a.Bc=!0}
D.defineProperty(G,{Bc:"isTreeExpanded"},function(){return 0!==(this.Ca&1)},function(a){var b=0!==(this.Ca&1);if(b!==a){v&&D.h(a,"boolean",G,"isTreeExpanded");this.Ca^=1;var c=this.g;this.i("isTreeExpanded",b,a);b=this.AK;if(null!==b){var d=!0;null!==c&&(d=c.$a,c.$a=!0);b(this);null!==c&&(c.$a=d)}null!==c&&c.la.jb?this.Ld(a):a?this.expandTree():this.collapseTree()}});
D.defineProperty(G,{Uq:"wasTreeExpanded"},function(){return 0!==(this.Ca&2)},function(a){var b=0!==(this.Ca&2);b!==a&&(v&&D.h(a,"boolean",G,"wasTreeExpanded"),this.Ca^=2,this.i("wasTreeExpanded",b,a))});D.defineProperty(G,{AK:"treeExpandedChanged"},function(){return this.Mt},function(a){var b=this.Mt;b!==a&&(null!==a&&D.h(a,"function",G,"treeExpandedChanged"),this.Mt=a,this.i("treeExpandedChanged",b,a))});
D.defineProperty(G,{Nj:"isTreeLeaf"},function(){return 0!==(this.Ca&4)},function(a){var b=0!==(this.Ca&4);b!==a&&(v&&D.h(a,"boolean",G,"isTreeLeaf"),this.Ca^=4,this.i("isTreeLeaf",b,a))});
function J(){F.call(this,rj);this.gc=8;this.Dg=null;this.fi="";this.Ug=this.Ur=null;this.Ai="";this.Lt=null;this.ar=Mj;this.vr=0;this.yr=Mj;this.zr=NaN;this.pn=Kr;this.zt=.5;this.Ff=null;this.fd=(new K(N)).freeze();this.rh=this.XC=this.RC=this.Oi=this.Zi=this.ab=this.Mw=this.Gp=this.gf=null;this.kA=new N;this.$=this.pE=this.oE=null}D.Sa(J,F);D.ka("Link",J);
J.prototype.cloneProtected=function(a){F.prototype.cloneProtected.call(this,a);a.gc=this.gc&-113;a.fi=this.fi;a.Ur=this.Ur;a.Ai=this.Ai;a.Lt=this.Lt;a.ar=this.ar;a.vr=this.vr;a.yr=this.yr;a.zr=this.zr;a.pn=this.pn;a.zt=this.zt;null!==this.$&&(a.$=this.$.copy())};J.prototype.Ei=function(a){F.prototype.Ei.call(this,a);this.fi=a.fi;this.Ai=a.Ai;a.gf=null;a.ec();a.Oi=this.Oi};
J.prototype.qc=function(a){a.Oe===J?2===(a.value&2)?this.Ey=a:a===Tj||a===tj||a===sj?this.lf=a:a===Lr||a===Mr||a===Nr?this.Ut=a:a!==Kr&&a!==Mj&&D.k("Unknown Link enum value for a Link property: "+a):F.prototype.qc.call(this,a)};var Kr;J.Normal=Kr=D.s(J,"Normal",1);J.Orthogonal=D.s(J,"Orthogonal",2);J.AvoidsNodes=D.s(J,"AvoidsNodes",6);var Or;J.AvoidsNodesStraight=Or=D.s(J,"AvoidsNodesStraight",7);var Mj;J.None=Mj=D.s(J,"None",0);var Tj;J.Bezier=Tj=D.s(J,"Bezier",9);var tj;
J.JumpGap=tj=D.s(J,"JumpGap",10);var sj;J.JumpOver=sj=D.s(J,"JumpOver",11);var Lr;J.End=Lr=D.s(J,"End",17);var Mr;J.Scale=Mr=D.s(J,"Scale",18);var Nr;J.Stretch=Nr=D.s(J,"Stretch",19);var wq;J.OrientAlong=wq=D.s(J,"OrientAlong",21);var Xp;J.OrientPlus90=Xp=D.s(J,"OrientPlus90",22);var Zp;J.OrientMinus90=Zp=D.s(J,"OrientMinus90",23);var Pr;J.OrientOpposite=Pr=D.s(J,"OrientOpposite",24);var Qr;J.OrientUpright=Qr=D.s(J,"OrientUpright",25);var Yp;J.OrientPlus90Upright=Yp=D.s(J,"OrientPlus90Upright",26);
var $p;J.OrientMinus90Upright=$p=D.s(J,"OrientMinus90Upright",27);var aq;J.OrientUpright45=aq=D.s(J,"OrientUpright45",28);f=J.prototype;f.$d=function(){null===this.$&&(this.$=new Eo)};f.zm=function(){var a=this.aa;if(null!==a){var b=a.findVisibleNode();null!==b&&(a=b);if(Nm(a)||Om(a))return!1}a=this.ba;return null!==a&&(b=a.findVisibleNode(),null!==b&&(a=b),Nm(a)||Om(a))?!1:!0};f.PB=function(){return!1};f.QB=function(){};f.pe=function(){return!1};
J.prototype.computeAngle=function(a,b,c){return J.computeAngle(b,c)};J.computeAngle=function(a,b){var c=0;switch(a){default:case Mj:c=0;break;case wq:c=b;break;case Xp:c=b+90;break;case Zp:c=b-90;break;case Pr:c=b+180;break;case Qr:c=Re(b);90<c&&270>c&&(c-=180);break;case Yp:c=Re(b+90);90<c&&270>c&&(c-=180);break;case $p:c=Re(b-90);90<c&&270>c&&(c-=180);break;case aq:c=Re(b);if(45<c&&135>c||225<c&&315>c)return 0;90<c&&270>c&&(c-=180)}return Re(c)};
D.defineProperty(J,{aa:"fromNode"},function(){return this.Dg},function(a){var b=this.Dg;if(b!==a){v&&null!==a&&D.l(a,G,J,"fromNode");var c=this.Ac;null!==b&&(this.Ug!==b&&Er(b,this,c),Rr(this),this.N(dn));this.Dg=a;null!==a&&this.Ld(a.isVisible());this.Zi=null;this.ec();var d=this.g;if(null!==d){var e=this.data,g=d.da;if(null!==e)if(g instanceof Y){var h=null!==a?a.data:null;g.LB(e,g.zb(h))}else g instanceof Bg&&(h=null!==a?a.data:null,d.de?g.Ji(e,g.zb(h)):(null!==b&&g.Ji(b.data,void 0),g.Ji(h,g.zb(null!==
this.Ug?this.Ug.data:null))))}e=this.Ac;g=this.CF;null!==g&&(h=!0,null!==d&&(h=d.$a,d.$a=!0),g(this,c,e),null!==d&&(d.$a=h));null!==a&&(this.Ug!==a&&Dr(a,this,e),Sr(this),this.N(Vm));this.i("fromNode",b,a);zn(this)}});
D.defineProperty(J,{hg:"fromPortId"},function(){return this.fi},function(a){var b=this.fi;if(b!==a){v&&D.h(a,"string",J,"fromPortId");var c=this.aa,d=this.Ac;null!==d&&Cr(c,d);Rr(this);this.fi=a;var e=this.Ac;null!==e&&Cr(c,e);c=this.g;if(null!==c){var g=this.data,h=c.da;null!==g&&h instanceof Y&&h.MB(g,a)}d!==e&&(this.Zi=null,this.ec(),g=this.CF,null!==g&&(h=!0,null!==c&&(h=c.$a,c.$a=!0),g(this,d,e),null!==c&&(c.$a=h)));Sr(this);this.i("fromPortId",b,a)}});
D.w(J,{Ac:"fromPort"},function(){var a=this.Dg;return null===a?null:a.QA(this.fi)});D.defineProperty(J,{CF:"fromPortChanged"},function(){return this.Ur},function(a){var b=this.Ur;b!==a&&(null!==a&&D.h(a,"function",J,"fromPortChanged"),this.Ur=a,this.i("fromPortChanged",b,a))});
D.defineProperty(J,{ba:"toNode"},function(){return this.Ug},function(a){var b=this.Ug;if(b!==a){v&&null!==a&&D.l(a,G,J,"toNode");var c=this.ad;null!==b&&(this.Dg!==b&&Er(b,this,c),Rr(this),this.N(dn));this.Ug=a;null!==a&&this.Ld(a.isVisible());this.Zi=null;this.ec();var d=this.g;if(null!==d){var e=this.data,g=d.da;if(null!==e)if(g instanceof Y){var h=null!==a?a.data:null;g.RB(e,g.zb(h))}else g instanceof Bg&&(h=null!==a?a.data:null,d.de?(null!==b&&g.Ji(b.data,void 0),g.Ji(h,g.zb(null!==this.Dg?this.Dg.data:
null))):g.Ji(e,g.zb(h)))}e=this.ad;g=this.iH;null!==g&&(h=!0,null!==d&&(h=d.$a,d.$a=!0),g(this,c,e),null!==d&&(d.$a=h));null!==a&&(this.Dg!==a&&Dr(a,this,e),Sr(this),this.N(Vm));this.i("toNode",b,a);zn(this)}});
D.defineProperty(J,{mh:"toPortId"},function(){return this.Ai},function(a){var b=this.Ai;if(b!==a){v&&D.h(a,"string",J,"toPortId");var c=this.ba,d=this.ad;null!==d&&Cr(c,d);Rr(this);this.Ai=a;var e=this.ad;null!==e&&Cr(c,e);c=this.g;if(null!==c){var g=this.data,h=c.da;null!==g&&h instanceof Y&&h.SB(g,a)}d!==e&&(this.Zi=null,this.ec(),g=this.iH,null!==g&&(h=!0,null!==c&&(h=c.$a,c.$a=!0),g(this,d,e),null!==c&&(c.$a=h)));Sr(this);this.i("toPortId",b,a)}});
D.w(J,{ad:"toPort"},function(){var a=this.Ug;return null===a?null:a.QA(this.Ai)});D.defineProperty(J,{iH:"toPortChanged"},function(){return this.Lt},function(a){var b=this.Lt;b!==a&&(null!==a&&D.h(a,"function",J,"toPortChanged"),this.Lt=a,this.i("toPortChanged",b,a))});D.defineProperty(J,{Gb:"fromSpot"},function(){return null!==this.$?this.$.mk:Vc},function(a){this.$d();var b=this.$.mk;b.O(a)||(v&&D.l(a,S,J,"fromSpot"),a=a.V(),this.$.mk=a,this.i("fromSpot",b,a),this.ec())});
D.defineProperty(J,{sm:"fromEndSegmentLength"},function(){return null!==this.$?this.$.kk:NaN},function(a){this.$d();var b=this.$.kk;b!==a&&(v&&D.h(a,"number",J,"fromEndSegmentLength"),0>a&&D.ua(a,">= 0",J,"fromEndSegmentLength"),this.$.kk=a,this.i("fromEndSegmentLength",b,a),this.ec())});
D.defineProperty(J,{ru:"fromEndSegmentDirection"},function(){return null!==this.$?this.$.jk:xr},function(a){this.$d();var b=this.$.jk;b!==a&&(D.Pn("Link.fromEndSegmentDirection","2.0"),v&&D.Da(a,G,J,"fromEndSegmentDirection"),this.$.jk=a,this.i("fromEndSegmentDirection",b,a),this.ec())});
D.defineProperty(J,{su:"fromShortLength"},function(){return null!==this.$?this.$.lk:NaN},function(a){this.$d();var b=this.$.lk;b!==a&&(v&&D.h(a,"number",J,"fromShortLength"),this.$.lk=a,this.i("fromShortLength",b,a),this.ec())});D.defineProperty(J,{Hb:"toSpot"},function(){return null!==this.$?this.$.Ik:Vc},function(a){this.$d();var b=this.$.Ik;b.O(a)||(v&&D.l(a,S,J,"toSpot"),a=a.V(),this.$.Ik=a,this.i("toSpot",b,a),this.ec())});
D.defineProperty(J,{Hm:"toEndSegmentLength"},function(){return null!==this.$?this.$.Gk:NaN},function(a){this.$d();var b=this.$.Gk;b!==a&&(v&&D.h(a,"number",J,"toEndSegmentLength"),0>a&&D.ua(a,">= 0",J,"toEndSegmentLength"),this.$.Gk=a,this.i("toEndSegmentLength",b,a),this.ec())});
D.defineProperty(J,{kv:"toEndSegmentDirection"},function(){return null!==this.$?this.$.Fk:xr},function(a){this.$d();var b=this.$.Fk;b!==a&&(D.Pn("Link.toEndSegmentDirection","2.0"),v&&D.Da(a,G,J,"toEndSegmentDirection"),this.$.Fk=a,this.i("toEndSegmentDirection",b,a),this.ec())});D.defineProperty(J,{lv:"toShortLength"},function(){return null!==this.$?this.$.Hk:NaN},function(a){this.$d();var b=this.$.Hk;b!==a&&(v&&D.h(a,"number",J,"toShortLength"),this.$.Hk=a,this.i("toShortLength",b,a),this.ec())});
function zn(a){var b=a.aa,c=a.ba,d=null,b=d=null!==b?null!==c?b.GI(c):b.Ka:null!==c?c.Ka:null,c=a.wl;if(c!==b){null!==c&&rr(c,a);a.wl=b;null!==b&&sr(b,a);var e=a.$E;if(null!==e){var g=!0,h=a.g;null!==h&&(g=h.$a,h.$a=!0);e(a,c,b);null!==h&&(h.$a=g)}!a.Tf||a.oE!==c&&a.pE!==c||a.ec()}if(a.lJ)for(a=a.Af;a.next();)a.value.Ka=d}J.prototype.Yk=function(){var a=this.Ka;null!==a&&this.aa!==a&&this.ba!==a&&a.zx&&F.prototype.Yk.call(this)};
J.prototype.getOtherNode=J.prototype.VA=function(a){v&&D.l(a,G,J,"getOtherNode:node");var b=this.aa;return a===b?this.ba:b};J.prototype.getOtherPort=function(a){v&&D.l(a,P,J,"getOtherPort:port");var b=this.Ac;return a===b?this.ad:b};D.w(J,{lJ:"isLabeledLink"},function(){return null===this.Ff?!1:0<this.Ff.count});D.w(J,{Af:"labelNodes"},function(){return null===this.Ff?Ka:this.Ff.j});function Fr(a,b){null===a.Ff&&(a.Ff=new L(G));a.Ff.add(b);a.K()}
J.prototype.wu=function(a){F.prototype.wu.call(this,a);Tr(this)&&Nj(this,this.Z);if(!a){a=this.Dg;var b=null;null!==a&&(b=this.Ac,Dr(a,this,b));var c=this.Ug,d=null;null!==c&&(d=this.ad,c===a&&d===b||Dr(c,this,d));Sr(this)}};J.prototype.xu=function(a){F.prototype.xu.call(this,a);Tr(this)&&Nj(this,this.Z);if(!a){a=this.Dg;var b=null;null!==a&&(b=this.Ac,Er(a,this,b));var c=this.Ug,d=null;null!==c&&(d=this.ad,c===a&&d===b||Er(c,this,d));Rr(this)}};
J.prototype.On=function(){this.Tf=!0;if(null!==this.Ff){var a=this.g;if(null!==a)for(var b=this.Ff.copy().j;b.next();)a.remove(b.value)}b=this.data;null!==b&&(a=this.g,null!==a&&(a=a.da,a instanceof Y?a.By(b):a instanceof Bg&&a.Ji(b,void 0)))};
J.prototype.updateRelationshipsFromData=function(){var a=this.data;if(null!==a){var b=this.g;if(null!==b){var c=b.da;if(c instanceof Y){var d=c.um(a);this.aa=d=b.Se(d);d=c.vm(a);this.ba=d=b.Se(d);a=c.Vk(a);if(0<a.length||0<this.Af.count){if(1===a.length&&1===this.Af.count){var d=a[0],e=this.Af.first();if(c.zb(e.data)===d)return}var d=(new L).Tc(a),g=new L;this.Af.each(function(a){null!==a.data&&(a=c.zb(a.data),void 0!==a&&g.add(a))});a=g.copy();a.Ay(d);d=d.copy();d.Ay(g);if(0<a.count||0<d.count){var h=
this;a.each(function(a){a=b.Se(a);null!==a&&a.Yb===h&&(a.Yb=null)});d.each(function(a){a=b.Se(a);null!==a&&a.Yb!==h&&(a.Yb=h)})}}}}}};J.prototype.move=function(a){var b=this.position,c=b.x;isNaN(c)&&(c=0);b=b.y;isNaN(b)&&(b=0);c=a.x-c;b=a.y-b;F.prototype.move.call(this,a);this.cl(c,b);for(a=this.Af;a.next();){var d=a.value,e=d.position;d.moveTo(e.x+c,e.y+b)}};
D.defineProperty(J,{YJ:"relinkableFrom"},function(){return 0!==(this.gc&1)},function(a){var b=0!==(this.gc&1);b!==a&&(v&&D.h(a,"boolean",J,"relinkableFrom"),this.gc^=1,this.i("relinkableFrom",b,a),this.oe())});D.defineProperty(J,{ZJ:"relinkableTo"},function(){return 0!==(this.gc&2)},function(a){var b=0!==(this.gc&2);b!==a&&(v&&D.h(a,"boolean",J,"relinkableTo"),this.gc^=2,this.i("relinkableTo",b,a),this.oe())});
J.prototype.canRelinkFrom=function(){if(!this.YJ)return!1;var a=this.layer;if(null===a)return!0;if(!a.En)return!1;a=a.g;return null===a||a.En?!0:!1};J.prototype.canRelinkTo=function(){if(!this.ZJ)return!1;var a=this.layer;if(null===a)return!0;if(!a.En)return!1;a=a.g;return null===a||a.En?!0:!1};D.defineProperty(J,{$u:"resegmentable"},function(){return 0!==(this.gc&4)},function(a){var b=0!==(this.gc&4);b!==a&&(v&&D.h(a,"boolean",J,"resegmentable"),this.gc^=4,this.i("resegmentable",b,a),this.oe())});
D.defineProperty(J,{Xc:"isTreeLink"},function(){return 0!==(this.gc&8)},function(a){var b=0!==(this.gc&8);b!==a&&(v&&D.h(a,"boolean",J,"isTreeLink"),this.gc^=8,this.i("isTreeLink",b,a),null!==this.aa&&Ln(this.aa),null!==this.ba&&Ln(this.ba))});D.w(J,{path:"path"},function(){var a=this.Xd();return a instanceof z?a:null});
D.w(J,{so:"routeBounds"},function(){this.zo();var a=new C;var b=Infinity,c=Infinity,d=this.ta;if(0===d)a.n(NaN,NaN,0,0);else{if(1===d)d=this.m(0),b=Math.min(d.x,b),c=Math.min(d.y,c),a.n(d.x,d.y,0,0);else if(2===d){var e=this.m(0),g=this.m(1),b=Math.min(e.x,g.x),c=Math.min(e.y,g.y);a.n(e.x,e.y,0,0);a.Mi(g)}else if(this.computeCurve()===Tj&&3<=d&&!this.ic)if(e=this.m(0),b=e.x,c=e.y,a.n(b,c,0,0),3===d)d=this.m(1),b=Math.min(d.x,b),c=Math.min(d.y,c),g=this.m(2),b=Math.min(g.x,b),c=Math.min(g.y,c),Ae(e.x,
e.y,d.x,d.y,d.x,d.y,g.x,g.y,.5,a);else for(var h=3;h<d;h+=3){var k=this.m(h-2);h+3>=d&&(h=d-1);var l=this.m(h-1),g=this.m(h);Ae(e.x,e.y,k.x,k.y,l.x,l.y,g.x,g.y,.5,a);b=Math.min(g.x,b);c=Math.min(g.y,c);e=g}else for(e=this.m(0),g=this.m(1),b=Math.min(e.x,g.x),c=Math.min(e.y,g.y),a.n(e.x,e.y,0,0),a.Mi(g),h=2;h<d;h++)e=this.m(h),b=Math.min(e.x,b),c=Math.min(e.y,c),a.Mi(e);this.kA.n(b-a.x,c-a.y)}return this.Mw=a});D.w(J,{oG:"midPoint"},function(){this.zo();return this.computeMidPoint(new N)});
J.prototype.computeMidPoint=function(a){var b=this.ta;if(0===b)return a.assign(he),a;if(1===b)return a.assign(this.m(0)),a;if(2===b){var c=this.m(0),d=this.m(1);a.n((c.x+d.x)/2,(c.y+d.y)/2);return a}if(this.computeCurve()===Tj&&3<=b&&!this.ic){if(3===b)return this.m(1);var c=(b-1)/3|0,e=3*(c/2|0);if(1===c%2){var c=this.m(e),d=this.m(e+1),g=this.m(e+2),e=this.m(e+3),b=d.x,h=d.y,d=g.x,k=g.y,g=(b+d)/2,l=(h+k)/2,h=((c.y+h)/2+l)/2,k=(l+(k+e.y)/2)/2;a.x=(((c.x+b)/2+g)/2+(g+(d+e.x)/2)/2)/2;a.y=(h+k)/2}else a.assign(this.m(e));
return a}e=0;g=D.nb();for(h=0;h<b-1;h++)c=0,c=this.m(h),d=this.m(h+1),Eb(c.x,d.x)?(c=d.y-c.y,0>c&&(c=-c)):Eb(c.y,d.y)?(c=d.x-c.x,0>c&&(c=-c)):c=Math.sqrt(c.Jf(d)),g.push(c),e+=c;for(d=h=c=0;c<e/2&&h<b;){d=g[h];if(c+d>e/2)break;c+=d;h++}D.ya(g);b=this.m(h);g=this.m(h+1);b.x===g.x?b.y>g.y?a.n(b.x,b.y-(e/2-c)):a.n(b.x,b.y+(e/2-c)):b.y===g.y?b.x>g.x?a.n(b.x-(e/2-c),b.y):a.n(b.x+(e/2-c),b.y):(c=(e/2-c)/d,a.n(b.x+c*(g.x-b.x),b.y+c*(g.y-b.y)));return a};D.w(J,{nG:"midAngle"},function(){this.zo();return this.computeMidAngle()});
J.prototype.computeMidAngle=function(){var a=this.ta;if(2>a)return NaN;if(this.computeCurve()===Tj&&4<=a&&!this.ic){var b=(a-1)/3|0,c=3*(b/2|0);if(1===b%2){var c=Math.floor(c),b=this.m(c),d=this.m(c+1),a=this.m(c+2),c=this.m(c+3),e=d.x,d=d.y,g=a.x,a=a.y,h=(e+g)/2,k=(d+a)/2;return Bb(((b.x+e)/2+h)/2,((b.y+d)/2+k)/2,(h+(g+c.x)/2)/2,(k+(a+c.y)/2)/2)}if(0<c&&c+1<a)return b=this.m(c-1),d=this.m(c+1),b.Xb(d)}c=a/2|0;if(0===a%2)return b=this.m(c-1),d=this.m(c),4<=a&&b.Uc(d)?(b=this.m(c-2),a=this.m(c+1),
c=b.Jf(d),e=d.Jf(a),c>e+10?b.Xb(d):e>c+10?d.Xb(a):b.Xb(a)):null===this.ab||this.ic?b.Xb(d):this.ab.DF(.5);if(null!==this.ab&&!this.ic)return this.ab.DF(.5);b=this.m(c-1);d=this.m(c);a=this.m(c+1);c=b.Jf(d);e=d.Jf(a);return c>e+10?b.Xb(d):e>c+10?d.Xb(a):b.Xb(a)};
D.defineProperty(J,{points:"points"},function(){return this.fd},function(a){var b=this.fd;if(b!==a){var c=null;if(Array.isArray(a)){var d=0===a.length%2;if(d)for(var e=0;e<a.length;e++)if("number"!==typeof a[e]||isNaN(a[e])){d=!1;break}if(d)for(c=new K(N),d=0;d<a.length/2;d++)e=(new N(a[2*d],a[2*d+1])).freeze(),c.add(e);else{e=!0;for(d=0;d<a.length;d++){var g=a[d];if(!D.Qa(g)||"number"!==typeof g.x||isNaN(g.x)||"number"!==typeof g.y||isNaN(g.y)){e=!1;break}}if(e)for(c=new K(N),d=0;d<a.length;d++)e=
a[d],c.add((new N(e.x,e.y)).freeze());else D.k("Link.points array must contain only an even number of numbers or objects with x and y properties, not: "+a)}}else if(a instanceof K)for(c=a.copy(),a=c.j;a.next();)a.value.freeze();else D.k("Link.points value is not an instance of List or Array: "+a);c.freeze();this.fd=c;this.Te();this.K();Ur(this);a=this.g;null!==a&&(a.$n||a.la.jb||a.ky.add(this),a.Wa.xc&&(this.Gp=c));this.i("points",b,c)}});D.w(J,{ta:"pointsCount"},function(){return this.fd.count});
J.prototype.getPoint=J.prototype.m=function(a){return this.fd.o[a]};J.prototype.setPoint=J.prototype.ih=function(a,b){v&&(D.l(b,N,J,"setPoint"),b.F()||D.k("Link.setPoint called with a Point that does not have real numbers: "+b.toString()));null===this.gf&&D.k("Call Link.startRoute before modifying the points of the route.");this.fd.ug(a,b)};
J.prototype.setPointAt=J.prototype.ha=function(a,b,c){v&&(D.p(b,J,"setPointAt:x"),D.p(c,J,"setPointAt:y"));null===this.gf&&D.k("Call Link.startRoute before modifying the points of the route.");this.fd.ug(a,new N(b,c))};J.prototype.insertPoint=function(a,b){v&&(D.l(b,N,J,"insertPoint"),b.F()||D.k("Link.insertPoint called with a Point that does not have real numbers: "+b.toString()));null===this.gf&&D.k("Call Link.startRoute before modifying the points of the route.");this.fd.ae(a,b)};
J.prototype.insertPointAt=J.prototype.B=function(a,b,c){v&&(D.p(b,J,"insertPointAt:x"),D.p(c,J,"insertPointAt:y"));null===this.gf&&D.k("Call Link.startRoute before modifying the points of the route.");this.fd.ae(a,new N(b,c))};J.prototype.addPoint=J.prototype.Ci=function(a){v&&(D.l(a,N,J,"addPoint"),a.F()||D.k("Link.addPoint called with a Point that does not have real numbers: "+a.toString()));null===this.gf&&D.k("Call Link.startRoute before modifying the points of the route.");this.fd.add(a)};
J.prototype.addPointAt=J.prototype.xj=function(a,b){v&&(D.p(a,J,"insertPointAt:x"),D.p(b,J,"insertPointAt:y"));null===this.gf&&D.k("Call Link.startRoute before modifying the points of the route.");this.fd.add(new N(a,b))};J.prototype.removePoint=J.prototype.EG=function(a){null===this.gf&&D.k("Call Link.startRoute before modifying the points of the route.");this.fd.od(a)};
J.prototype.clearPoints=J.prototype.aq=function(){null===this.gf&&D.k("Call Link.startRoute before modifying the points of the route.");this.fd.clear()};J.prototype.movePoints=J.prototype.cl=function(a,b){if(0!==a||0!==b){for(var c=this.Tf,d=new K(N),e=this.fd.j;e.next();){var g=e.value;d.add((new N(g.x+a,g.y+b)).freeze())}d.freeze();e=this.fd;this.fd=d;this.K();c&&Ur(this);c=this.g;null!==c&&c.Wa.xc&&(this.Gp=d);this.i("points",e,d)}};
J.prototype.startRoute=J.prototype.Dm=function(){null===this.gf&&(this.gf=this.fd,this.fd=this.fd.copy())};
J.prototype.commitRoute=J.prototype.Aj=function(){if(null!==this.gf){for(var a=this.gf,b=this.fd,c=Infinity,d=Infinity,e=a.o,g=e.length,h=0;h<g;h++)var k=e[h],c=Math.min(k.x,c),d=Math.min(k.y,d);for(var l=Infinity,m=Infinity,n=b.o,p=n.length,h=0;h<p;h++)k=n[h],l=Math.min(k.x,l),m=Math.min(k.y,m),k.freeze();b.freeze();if(p===g)for(h=0;h<p;h++){if(g=e[h],k=n[h],g.x-c!==k.x-l||g.y-d!==k.y-m){this.K();this.Te();break}}else this.K(),this.Te();this.gf=null;c=this.g;null!==c&&c.Wa.xc&&(this.Gp=b);Ur(this);
this.i("points",a,b)}};J.prototype.rollbackRoute=J.prototype.cK=function(){null!==this.gf&&(this.fd=this.gf,this.gf=null)};function Ur(a){0===a.fd.count?a.Tf=!1:(a.Tf=!0,a.fq=a.m(0),a.gq=a.m(a.ta-1),Vr(a,!1))}J.prototype.invalidateRoute=J.prototype.ec=function(){if(!this.Xj){var a=this.g;a&&(a.ky.contains(this)||a.la.jb||a.Wa.rJ&&!a.Wa.yf)||(a=this.path,null!==a&&(this.Tf=!1,this.K(),a.K()))}};
D.defineProperty(J,{Tf:null},function(){return 0!==(this.gc&16)},function(a){0!==(this.gc&16)!==a&&(this.gc^=16)});D.defineProperty(J,{Xj:"suspendsRouting"},function(){return 0!==(this.gc&32)},function(a){0!==(this.gc&32)!==a&&(this.gc^=32)});D.defineProperty(J,{zA:null},function(){return 0!==(this.gc&64)},function(a){0!==(this.gc&64)!==a&&(this.gc^=64)});D.defineProperty(J,{fq:"defaultFromPoint"},function(){return this.RC},function(a){this.RC=a.copy()});
D.defineProperty(J,{gq:"defaultToPoint"},function(){return this.XC},function(a){this.XC=a.copy()});J.prototype.updateRoute=J.prototype.zo=function(){if(!this.Tf&&!this.zA){var a=!0;try{this.zA=!0,this.Dm(),a=this.computePoints()}finally{this.zA=!1,a?this.Aj():this.cK()}}};
J.prototype.computePoints=function(){var a=this.g;if(null===a)return!1;var b=this.aa,c=null;null===b?(a.Ul||(a.jt=new z,a.jt.Ea=Xd,a.jt.mb=0,a.Ul=new G,a.Ul.add(a.jt),a.Ul.Re()),this.fq&&(a.Ul.position=a.Ul.location=this.fq,a.Ul.Re(),b=a.Ul,c=a.jt)):c=this.Ac;if(null!==c&&!b.isVisible()){var d=b.findVisibleNode();null!==d&&d!==b?(b=d,c=d.port):b=d}this.oE=b;if(null===b||!b.location.F())return!1;for(;!(null===c||c.Z.F()&&c.Oj());)c=c.R;if(null===c)return!1;var e=this.ba,g=null;null===e?(a.Vl||(a.kt=
new z,a.kt.Ea=Xd,a.kt.mb=0,a.Vl=new G,a.Vl.add(a.kt),a.Vl.Re()),this.gq&&(a.Vl.position=a.Vl.location=this.gq,a.Vl.Re(),e=a.Vl,g=a.kt)):g=this.ad;null===g||e.isVisible()||(a=e.findVisibleNode(),null!==a&&a!==e?(e=a,g=a.port):e=a);this.pE=e;if(null===e||!e.location.F())return!1;for(;!(null===g||g.Z.F()&&g.Oj());)g=g.R;if(null===g)return!1;var h=this.ta,d=this.computeSpot(!0,c),a=this.computeSpot(!1,g),k=d===dc,l=a===dc,m=c===g&&null!==c,n=this.ic,p=this.lf===Tj;this.Zi=m&&!n?p=!0:!1;var q=this.Ut===
Mj||m;if(!n&&!m&&k&&l){if(k=!1,!q&&3<=h&&(q=this.getLinkPoint(b,c,d,!0,!1,e,g),l=this.getLinkPoint(e,g,a,!1,!1,b,c),k=this.adjustPoints(0,q,h-1,l))&&(q=this.getLinkPoint(b,c,d,!0,!1,e,g),l=this.getLinkPoint(e,g,a,!1,!1,b,c),this.adjustPoints(0,q,h-1,l)),!k)if(this.aq(),p){var h=this.getLinkPoint(b,c,d,!0,!1,e,g),q=this.getLinkPoint(e,g,a,!1,!1,b,c),k=q.x-h.x,l=q.y-h.y,m=this.computeCurviness(),p=n=0,r=h.x+k/3,s=h.y+l/3,u=r,t=s;Q(l,0)?t=0<k?t-m:t+m:(n=-k/l,p=Math.sqrt(m*m/(n*n+1)),0>m&&(p=-p),u=(0>
l?-1:1)*p+r,t=n*(u-r)+s);var r=h.x+2*k/3,s=h.y+2*l/3,y=r,w=s;Q(l,0)?w=0<k?w-m:w+m:(y=(0>l?-1:1)*p+r,w=n*(y-r)+s);this.aq();this.Ci(h);this.xj(u,t);this.xj(y,w);this.Ci(q);this.ih(0,this.getLinkPoint(b,c,d,!0,!1,e,g));this.ih(3,this.getLinkPoint(e,g,a,!1,!1,b,c))}else d=this.getLinkPoint(b,c,d,!0,!1,e,g),a=this.getLinkPoint(e,g,a,!1,!1,b,c),this.hasCurviness()?(q=a.x-d.x,e=a.y-d.y,g=this.computeCurviness(),b=d.x+q/2,c=d.y+e/2,h=b,k=c,Q(e,0)?k=0<q?k-g:k+g:(q=-q/e,h=Math.sqrt(g*g/(q*q+1)),0>g&&(h=-h),
h=(0>e?-1:1)*h+b,k=q*(h-b)+c),this.Ci(d),this.xj(h,k)):this.Ci(d),this.Ci(a)}else{p=this.Jj;q&&(n&&p||m)&&this.aq();var B=m?this.computeCurviness():0,p=this.getLinkPoint(b,c,d,!0,n,e,g),r=u=s=0;if(n||!k||m)if(t=this.computeEndSegmentLength(b,c,d,!0),r=this.getLinkDirection(b,c,p,d,!0,n,e,g),m&&(k||d.O(a)||!n&&1===d.x+a.x&&1===d.y+a.y)&&(r-=n?90:30,0>B&&(r-=180)),0>r?r+=360:360<=r&&(r-=360),m&&(t+=Math.abs(B)*(n?1:2)),0===r?s=t:90===r?u=t:180===r?s=-t:270===r?u=-t:(s=t*Math.cos(r*Math.PI/180),u=t*
Math.sin(r*Math.PI/180)),d.ce()&&m){var A=c.eb(mc,D.P()),O=D.Fb(A.x+1E3*s,A.y+1E3*u);this.getLinkPointFromPoint(b,c,A,O,!0,p);D.A(A);D.A(O)}var t=this.getLinkPoint(e,g,a,!1,n,b,c),H=w=y=0;if(n||!l||m)A=this.computeEndSegmentLength(e,g,a,!1),H=this.getLinkDirection(e,g,t,a,!1,n,b,c),m&&(l||d.O(a)||!n&&1===d.x+a.x&&1===d.y+a.y)&&(H+=n?0:30,0>B&&(H+=180)),0>H?H+=360:360<=H&&(H-=360),m&&(A+=Math.abs(B)*(n?1:2)),0===H?y=A:90===H?w=A:180===H?y=-A:270===H?w=-A:(y=A*Math.cos(H*Math.PI/180),w=A*Math.sin(H*
Math.PI/180)),a.ce()&&m&&(A=g.eb(mc,D.P()),O=D.Fb(A.x+1E3*y,A.y+1E3*w),this.getLinkPointFromPoint(e,g,A,O,!1,t),D.A(A),D.A(O));a=p;if(n||!k||m)a=new N(p.x+s,p.y+u);d=t;if(n||!l||m)d=new N(t.x+y,t.y+w);!q&&!n&&k&&3<h&&this.adjustPoints(0,p,h-2,d)?this.ih(h-1,t):!q&&!n&&l&&3<h&&this.adjustPoints(1,a,h-1,t)?this.ih(0,p):!q&&(n?6<=h:4<h)&&this.adjustPoints(1,a,h-2,d)?(this.ih(0,p),this.ih(h-1,t)):(this.aq(),this.Ci(p),(n||!k||m)&&this.Ci(a),n&&this.addOrthoPoints(a,r,d,H,b,e),(n||!l||m)&&this.Ci(d),this.Ci(t))}return!0};
function Wr(a,b){Math.abs(b.x-a.x)>Math.abs(b.y-a.y)?(b.x=b.x>=a.x?a.x+9E9:a.x-9E9,b.y=a.y):(b.y=b.y>=a.y?a.y+9E9:a.y-9E9,b.x=a.x);return b}
J.prototype.getLinkPointFromPoint=function(a,b,c,d,e,g){void 0===g&&(g=new N);if(null===a||null===b)return g.assign(c),g;a.isVisible()||(e=a.findVisibleNode(),null!==e&&e!==a&&(b=e.port));var h=e=0,k=0,l=0;a=null;e=b.R;null===e||e.nh()||(e=e.R);if(null===e)e=d.x,h=d.y,k=c.x,l=c.y;else{a=e.Jh;e=1/(a.m11*a.m22-a.m12*a.m21);var k=a.m22*e,l=-a.m12*e,m=-a.m21*e,n=a.m11*e,p=e*(a.m21*a.dy-a.m22*a.dx),q=e*(a.m12*a.dx-a.m11*a.dy);e=d.x*k+d.y*m+p;h=d.x*l+d.y*n+q;k=c.x*k+c.y*m+p;l=c.x*l+c.y*n+q}b.Un(e,h,k,l,
g);null!==a&&g.transform(a);return g};function Xr(a,b){var c=b.$s;null===c&&(c=new Yr,c.port=b,c.Yc=b.Y,b.$s=c);return Zr(c,a)}
J.prototype.getLinkPoint=function(a,b,c,d,e,g,h,k){void 0===k&&(k=new N);if(c.Vc())return b.eb(c,k),k;if(c.Lj()){var l=Xr(this,b);if(null!==l){k.assign(l.zq);if(e&&this.Ey===Or){var m=Xr(this,h);if(null!==m&&l.Mn<m.Mn){var l=D.P(),m=D.P(),n=new C(b.eb(ec,l),b.eb(vc,m)),p=this.computeSpot(!d,h);a=this.getLinkPoint(g,h,p,!d,e,a,b,m);(c.Ij(xc)||c.Ij(yc))&&a.y>=n.y&&a.y<=n.y+n.height?k.y=a.y:(c.Ij(wc)||c.Ij(Dc))&&a.x>=n.x&&a.x<=n.x+n.width&&(k.x=a.x);D.A(l);D.A(m)}}return k}}c=b.eb(mc,D.P());l=g=null;
this.ta>(e?6:2)?(l=d?this.m(1):this.m(this.ta-2),e&&(l=Wr(c,l.copy()))):(g=D.P(),l=h.eb(mc,g),e&&(l=Wr(c,l)),D.A(g));this.getLinkPointFromPoint(a,b,c,l,d,k);D.A(c);return k};
J.prototype.getLinkDirection=function(a,b,c,d,e,g,h,k){a:if(d.Vc())c=d.x>d.y?d.x>1-d.y?0:d.x<1-d.y?270:315:d.x<d.y?d.x>1-d.y?90:d.x<1-d.y?180:135:.5>d.x?225:.5<d.x?45:0;else{if(d.Lj()){var l=Xr(this,b);if(null!==l)switch(l.Le){case D.yd:c=270;break a;case D.bd:c=180;break a;default:case D.qd:c=0;break a;case D.pd:c=90;break a}}var l=b.eb(mc,D.P()),m=null,n=null;this.ta>(g?6:2)?(n=e?this.m(1):this.m(this.ta-2),n=g?Wr(l,n.copy()):c):(m=D.P(),n=k.eb(mc,m),D.A(m));c=0;c=Math.abs(n.x-l.x)>Math.abs(n.y-
l.y)?n.x>=l.x?0:180:n.y>=l.y?90:270;D.A(l)}d.ce()&&h.Gi(a)&&(c+=180,360<=c&&(c-=360));a=xr;a=e?this.ru:this.kv;a===xr&&(a=e?b.ru:b.kv);switch(a){case yr:b=b.tm();c+=b;360<=c&&(c-=360);break;case xr:case Fo:if(d.oJ())break;b=b.tm();if(0===b)break;45<=b&&135>b?c+=90:135<=b&&225>b?c+=180:225<=b&&315>b&&(c+=270);360<=c&&(c-=360)}return c};
J.prototype.computeEndSegmentLength=function(a,b,c,d){if(null!==b&&c.Lj()&&(a=Xr(this,b),null!==a))return a.Ix;a=NaN;a=d?this.sm:this.Hm;null!==b&&isNaN(a)&&(a=d?b.sm:b.Hm);isNaN(a)&&(a=10);return a};J.prototype.computeSpot=function(a,b){var c;if(a)if(c=b?b:this.Ac,null===c)c=mc;else{var d=this.Gb;d.kd()&&null!==c&&(d=c.Gb);c=d===Vc?dc:d}else c=b?b:this.ad,null===c?c=mc:(d=this.Hb,d.kd()&&null!==c&&(d=c.Hb),c=d===Vc?dc:d);return c};
J.prototype.computeOtherPoint=function(a,b){var c=b.eb(mc),d;d=b.$s;d=null!==d?Zr(d,this):null;null!==d&&(c=d.zq);return c};J.prototype.computeShortLength=function(a){if(a){if(a=this.su,isNaN(a)){var b=this.Ac;null!==b&&(a=b.su)}}else a=this.lv,isNaN(a)&&(b=this.ad,null!==b&&(a=b.lv));return isNaN(a)?0:a};
J.prototype.Sk=function(a,b,c,d,e,g){if(!1===this.tg)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var h=g;void 0===g&&(h=D.Rf(),h.reset());h.multiply(this.transform);if(this.Jn(a,h))return gq(this,b,c,e),void 0===g&&D.Je(h),!0;if(this.jg(a,h)){var k=!1;if(!this.Zn)for(var l=this.xa.o,m=l.length;m--;){var n=l[m];if(n.visible||n===this.Bf){var p=n.Z,q=this.Ga;if(!(p.x>q.width||p.y>q.height||0>p.x+p.width||0>p.y+p.height)){p=D.Rf();p.set(h);if(n instanceof x)k=n.Sk(a,b,c,d,e,p);else if(this.path===
n){if(n instanceof z){var k=n,r=a,s=d,q=p;if(!1===k.tg)k=!1;else if(q.multiply(k.transform),s)b:{var u=r,t=q;if(k.Jn(u,t))k=!0;else{if(void 0===t&&(t=k.transform,u.Pk(k.Z))){k=!0;break b}var q=u.left,r=u.right,s=u.top,u=u.bottom,y=D.P(),w=D.P(),B=D.P(),A=D.Rf();A.set(t);A.tB(k.transform);A.Ux();w.x=r;w.y=s;w.transform(A);y.x=q;y.y=s;y.transform(A);t=!1;tq(k,y,w,B)?t=!0:(y.x=r,y.y=u,y.transform(A),tq(k,y,w,B)?t=!0:(w.x=q,w.y=u,w.transform(A),tq(k,y,w,B)?t=!0:(y.x=q,y.y=s,y.transform(A),tq(k,y,w,B)&&
(t=!0))));D.Je(A);D.A(y);D.A(w);D.A(B);k=t}}else k=k.Jn(r,q)}}else k=Lo(n,a,d,p);k&&(null!==b&&(n=b(n)),n&&(null===c||c(n))&&(e instanceof L&&e.add(n),e instanceof K&&e.add(n)));D.Je(p)}}}void 0===g&&D.Je(h);return k||null!==this.background||null!==this.fm}void 0===g&&D.Je(h);return!1};D.w(J,{ic:"isOrthogonal"},function(){return 2===(this.pn.value&2)});D.w(J,{Jj:"isAvoiding"},function(){return 4===(this.pn.value&4)});
J.prototype.computeCurve=function(){if(null===this.Zi){var a=this.Ac,b=this.ic;this.Zi=null!==a&&a===this.ad&&!b}return this.Zi?Tj:this.lf};J.prototype.computeCorner=function(){if(this.lf===Tj)return 0;var a=this.FA;if(isNaN(a)||0>a)a=10;return a};J.prototype.findMidLabel=function(){for(var a=this.path,b=this.xa.o,c=b.length,d=0;d<c;d++){var e=b[d];if(e!==a&&!e.Ue&&(-Infinity===e.We||isNaN(e.We)))return e}for(a=this.Af;a.next();)if(b=a.value,-Infinity===b.We||isNaN(b.We))return b;return null};
J.prototype.computeSpacing=function(){if(!this.isVisible())return 0;var a;a=Math.max(14,this.computeThickness());var b=this.Ac,c=this.ad;if(null!==b&&null!==c){var d=this.findMidLabel();if(null!==d){var e=d.Ga,g=d.margin,h=isNaN(e.width)?30:e.width*d.scale+g.left+g.right,e=isNaN(e.height)?14:e.height*d.scale+g.top+g.bottom,d=d.Lq;d===wq||d===Qr||d===Pr?a=Math.max(a,e):d===Zp||d===$p||d===Xp||d===Yp?a=Math.max(a,h):(b=b.eb(mc).Xb(c.eb(mc))/180*Math.PI,a=Math.max(a,Math.abs(Math.sin(b)*h)+Math.abs(Math.cos(b)*
e)+1));this.lf===Tj&&(a*=1.333)}}return a};J.prototype.arrangeBundledLinks=function(a,b){if(b)for(var c=0;c<a.length;c++)a[c].ec()};J.prototype.computeCurviness=function(){var a=this.Dx;if(isNaN(a)){var a=16,b=this.Oi;if(null!==b){for(var c=D.nb(),d=0,e=b.links,g=0;g<e.length;g++){var h=e[g],h=h.computeSpacing();c.push(h);d+=h}d=-d/2;for(g=0;g<e.length;g++){h=e[g];if(h===this){a=d+c[g]/2;break}d+=c[g]}b.Cq===this.aa&&(a=-a);D.ya(c)}}return a};
J.prototype.computeThickness=function(){if(!this.isVisible())return 0;var a=this.path;return null!==a?Math.max(a.mb,1):1};J.prototype.hasCurviness=function(){return!isNaN(this.Dx)||null!==this.Oi};
J.prototype.adjustPoints=function(a,b,c,d){var e=this.Ut;if(this.ic){if(e===Mr)return!1;e===Nr&&(e=Lr)}switch(e){case Mr:var g=this.m(a),h=this.m(c);if(!g.Uc(b)||!h.Uc(d)){var e=g.x,g=g.y,k=h.x-e,l=h.y-g,m=Math.sqrt(k*k+l*l);if(!Eb(m,0)){var n=0;Eb(k,0)?n=0>l?-Math.PI/2:Math.PI/2:(n=Math.atan(l/Math.abs(k)),0>k&&(n=Math.PI-n));var h=b.x,p=b.y,l=d.x-h,q=d.y-p,r=Math.sqrt(l*l+q*q),k=0;Eb(l,0)?k=0>q?-Math.PI/2:Math.PI/2:(k=Math.atan(q/Math.abs(l)),0>l&&(k=Math.PI-k));m=r/m;n=k-n;this.ih(a,b);for(a+=
1;a<c;a++)b=this.m(a),k=b.x-e,l=b.y-g,b=Math.sqrt(k*k+l*l),Eb(b,0)||(q=0,Eb(k,0)?q=0>l?-Math.PI/2:Math.PI/2:(q=Math.atan(l/Math.abs(k)),0>k&&(q=Math.PI-q)),k=q+n,b*=m,this.ha(a,h+b*Math.cos(k),p+b*Math.sin(k)));this.ih(c,d)}}return!0;case Nr:g=this.m(a);p=this.m(c);if(!g.Uc(b)||!p.Uc(d)){var e=g.x,g=g.y,h=p.x,p=p.y,m=(h-e)*(h-e)+(p-g)*(p-g),k=b.x,n=b.y,l=d.x,q=d.y,r=0,s=1;0!==l-k?(r=(q-n)/(l-k),s=Math.sqrt(1+1/(r*r))):r=9E9;this.ih(a,b);for(a+=1;a<c;a++){b=this.m(a);var u=b.x,t=b.y,y=.5;0!==m&&(y=
((e-u)*(e-h)+(g-t)*(g-p))/m);var w=e+y*(h-e),B=g+y*(p-g);b=Math.sqrt((u-w)*(u-w)+(t-B)*(t-B));t<r*(u-w)+B&&(b=-b);0<r&&(b=-b);u=k+y*(l-k);y=n+y*(q-n);0!==r?(b=u+b/s,this.ha(a,b,y-(b-u)/r)):this.ha(a,u,y+b)}this.ih(c,d)}return!0;case Lr:a:{if(this.ic&&(e=this.m(a),g=this.m(a+1),h=this.m(a+2),k=g.x,n=g.y,p=k,m=n,Q(e.y,g.y)?Q(g.x,h.x)?n=b.y:Q(g.y,h.y)&&(k=b.x):Q(e.x,g.x)&&(Q(g.y,h.y)?k=b.x:Q(g.x,h.x)&&(n=b.y)),this.ha(a+1,k,n),e=this.m(c),g=this.m(c-1),h=this.m(c-2),k=g.x,n=g.y,l=k,q=n,Q(e.y,g.y)?Q(g.x,
h.x)?n=d.y:Q(g.y,h.y)&&(k=d.x):Q(e.x,g.x)&&(Q(g.y,h.y)?k=d.x:Q(g.x,h.x)&&(n=d.y)),this.ha(c-1,k,n),Mi(this))){this.ha(a+1,p,m);this.ha(c-1,l,q);c=!1;break a}this.ih(a,b);this.ih(c,d);c=!0}return c;default:return!1}};
J.prototype.addOrthoPoints=function(a,b,c,d,e,g){b=-45<=b&&45>b?0:45<=b&&135>b?90:135<=b&&225>b?180:270;d=-45<=d&&45>d?0:45<=d&&135>d?90:135<=d&&225>d?180:270;var h=e.Z.copy(),k=g.Z.copy();if(h.F()&&k.F()){h.ig(8,8);k.ig(8,8);h.Mi(a);k.Mi(c);var l,m;if(0===b)if(c.x>a.x||270===d&&c.y<a.y&&k.right>a.x||90===d&&c.y>a.y&&k.right>a.x)l=new N(c.x,a.y),m=new N(c.x,(a.y+c.y)/2),180===d?(l.x=this.computeMidOrthoPosition(a.x,c.x,!1),m.x=l.x,m.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(l.x=a.x<k.left?this.computeMidOrthoPosition(a.x,
k.left,!1):a.x<k.right&&(270===d&&a.y<k.top||90===d&&a.y>k.bottom)?this.computeMidOrthoPosition(a.x,c.x,!1):k.right,m.x=l.x,m.y=c.y):0===d&&a.x<k.left&&a.y>k.top&&a.y<k.bottom&&(l.x=a.x,l.y=a.y<c.y?Math.min(c.y,k.top):Math.max(c.y,k.bottom),m.y=l.y);else{l=new N(a.x,c.y);m=new N((a.x+c.x)/2,c.y);if(180===d||90===d&&c.y<h.top||270===d&&c.y>h.bottom)180===d&&(k.Pa(a)||h.Pa(c))?l.y=this.computeMidOrthoPosition(a.y,c.y,!0):c.y<a.y&&(180===d||90===d)?l.y=this.computeMidOrthoPosition(h.top,Math.max(c.y,
k.bottom),!0):c.y>a.y&&(180===d||270===d)&&(l.y=this.computeMidOrthoPosition(h.bottom,Math.min(c.y,k.top),!0)),m.x=c.x,m.y=l.y;if(l.y>h.top&&l.y<h.bottom)if(c.x>=h.left&&c.x<=a.x||a.x<=k.right&&a.x>=c.x){if(90===d||270===d)l=new N(Math.max((a.x+c.x)/2,a.x),a.y),m=new N(l.x,c.y)}else l.y=270===d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,0===d?h.top:Math.min(h.top,k.top)):Math.max(c.y,0===d?h.bottom:Math.max(h.bottom,k.bottom)),m.x=c.x,m.y=l.y}else if(180===b)if(c.x<a.x||270===d&&c.y<a.y&&k.left<a.x||
90===d&&c.y>a.y&&k.left<a.x)l=new N(c.x,a.y),m=new N(c.x,(a.y+c.y)/2),0===d?(l.x=this.computeMidOrthoPosition(a.x,c.x,!1),m.x=l.x,m.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(l.x=a.x>k.right?this.computeMidOrthoPosition(a.x,k.right,!1):a.x>k.left&&(270===d&&a.y<k.top||90===d&&a.y>k.bottom)?this.computeMidOrthoPosition(a.x,c.x,!1):k.left,m.x=l.x,m.y=c.y):180===d&&a.x>k.right&&a.y>k.top&&a.y<k.bottom&&(l.x=a.x,l.y=a.y<c.y?Math.min(c.y,k.top):Math.max(c.y,k.bottom),m.y=l.y);else{l=new N(a.x,c.y);m=new N((a.x+
c.x)/2,c.y);if(0===d||90===d&&c.y<h.top||270===d&&c.y>h.bottom)0===d&&(k.Pa(a)||h.Pa(c))?l.y=this.computeMidOrthoPosition(a.y,c.y,!0):c.y<a.y&&(0===d||90===d)?l.y=this.computeMidOrthoPosition(h.top,Math.max(c.y,k.bottom),!0):c.y>a.y&&(0===d||270===d)&&(l.y=this.computeMidOrthoPosition(h.bottom,Math.min(c.y,k.top),!0)),m.x=c.x,m.y=l.y;if(l.y>h.top&&l.y<h.bottom)if(c.x<=h.right&&c.x>=a.x||a.x>=k.left&&a.x<=c.x){if(90===d||270===d)l=new N(Math.min((a.x+c.x)/2,a.x),a.y),m=new N(l.x,c.y)}else l.y=270===
d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,180===d?h.top:Math.min(h.top,k.top)):Math.max(c.y,180===d?h.bottom:Math.max(h.bottom,k.bottom)),m.x=c.x,m.y=l.y}else if(90===b)if(c.y>a.y||180===d&&c.x<a.x&&k.bottom>a.y||0===d&&c.x>a.x&&k.bottom>a.y)l=new N(a.x,c.y),m=new N((a.x+c.x)/2,c.y),270===d?(l.y=this.computeMidOrthoPosition(a.y,c.y,!0),m.x=c.x,m.y=l.y):180===d&&c.x<a.x||0===d&&c.x>a.x?(l.y=a.y<k.top?this.computeMidOrthoPosition(a.y,k.top,!0):a.y<k.bottom&&(180===d&&a.x<k.left||0===d&&a.x>k.right)?
this.computeMidOrthoPosition(a.y,c.y,!0):k.bottom,m.x=c.x,m.y=l.y):90===d&&a.y<k.top&&a.x>k.left&&a.x<k.right&&(l.x=a.x<c.x?Math.min(c.x,k.left):Math.max(c.x,k.right),l.y=a.y,m.x=l.x);else{l=new N(c.x,a.y);m=new N(c.x,(a.y+c.y)/2);if(270===d||0===d&&c.x<h.left||180===d&&c.x>h.right)270===d&&(k.Pa(a)||h.Pa(c))?l.x=this.computeMidOrthoPosition(a.x,c.x,!1):c.x<a.x&&(270===d||0===d)?l.x=this.computeMidOrthoPosition(h.left,Math.max(c.x,k.right),!1):c.x>a.x&&(270===d||180===d)&&(l.x=this.computeMidOrthoPosition(h.right,
Math.min(c.x,k.left),!1)),m.x=l.x,m.y=c.y;if(l.x>h.left&&l.x<h.right)if(c.y>=h.top&&c.y<=a.y||a.y<=k.bottom&&a.y>=c.y){if(0===d||180===d)l=new N(a.x,Math.max((a.y+c.y)/2,a.y)),m=new N(c.x,l.y)}else l.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,90===d?h.left:Math.min(h.left,k.left)):Math.max(c.x,90===d?h.right:Math.max(h.right,k.right)),m.x=l.x,m.y=c.y}else if(c.y<a.y||180===d&&c.x<a.x&&k.top<a.y||0===d&&c.x>a.x&&k.top<a.y)l=new N(a.x,c.y),m=new N((a.x+c.x)/2,c.y),90===d?(l.y=this.computeMidOrthoPosition(a.y,
c.y,!0),m.x=c.x,m.y=l.y):180===d&&c.x<a.x||0===d&&c.x>=a.x?(l.y=a.y>k.bottom?this.computeMidOrthoPosition(a.y,k.bottom,!0):a.y>k.top&&(180===d&&a.x<k.left||0===d&&a.x>k.right)?this.computeMidOrthoPosition(a.y,c.y,!0):k.top,m.x=c.x,m.y=l.y):270===d&&a.y>k.bottom&&a.x>k.left&&a.x<k.right&&(l.x=a.x<c.x?Math.min(c.x,k.left):Math.max(c.x,k.right),l.y=a.y,m.x=l.x);else{l=new N(c.x,a.y);m=new N(c.x,(a.y+c.y)/2);if(90===d||0===d&&c.x<h.left||180===d&&c.x>h.right)90===d&&(k.Pa(a)||h.Pa(c))?l.x=this.computeMidOrthoPosition(a.x,
c.x,!1):c.x<a.x&&(90===d||0===d)?l.x=this.computeMidOrthoPosition(h.left,Math.max(c.x,k.right),!1):c.x>a.x&&(90===d||180===d)&&(l.x=this.computeMidOrthoPosition(h.right,Math.min(c.x,k.left),!1)),m.x=l.x,m.y=c.y;if(l.x>h.left&&l.x<h.right)if(c.y<=h.bottom&&c.y>=a.y||a.y>=k.top&&a.y<=c.y){if(0===d||180===d)l=new N(a.x,Math.min((a.y+c.y)/2,a.y)),m=new N(c.x,l.y)}else l.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,270===d?h.left:Math.min(h.left,k.left)):Math.max(c.x,270===d?h.right:Math.max(h.right,
k.right)),m.x=l.x,m.y=c.y}var n=l,p=m;if(this.Jj){var q=this.g,r;(r=null===q)||(q.Wa.yf?r=!1:(r=q.hb,r=r instanceof Uh?!r.ax||r.hJ:!0),r=!r);if(r||h.Pa(c)&&!g.Gi(e)||k.Pa(a)&&!e.Gi(g)||e===g||this.layer.Wc)b=!1;else{var s=ha(q,!0,this.Ka,null);if(s.yq(Math.min(a.x,n.x),Math.min(a.y,n.y),Math.abs(a.x-n.x),Math.abs(a.y-n.y))&&s.yq(Math.min(n.x,p.x),Math.min(n.y,p.y),Math.abs(n.x-p.x),Math.abs(n.y-p.y))&&s.yq(Math.min(p.x,c.x),Math.min(p.y,c.y),Math.abs(p.x-c.x),Math.abs(p.y-c.y)))b=!1;else{e=a;g=c;
var u=r=null;if(q.hB){q=s.qb.copy();q.ig(-s.$p,-s.Yp);var t=D.P();$r(s,a.x,a.y)||(Je(q.x,q.y,q.x+q.width,q.y+q.height,a.x,a.y,n.x,n.y,t)?(r=a=t.copy(),b=t.Xb(n)):Je(q.x,q.y,q.x+q.width,q.y+q.height,n.x,n.y,p.x,p.y,t)?(r=a=t.copy(),b=t.Xb(p)):Je(q.x,q.y,q.x+q.width,q.y+q.height,p.x,p.y,c.x,c.y,t)&&(r=a=t.copy(),b=t.Xb(c)));$r(s,c.x,c.y)||(Je(q.x,q.y,q.x+q.width,q.y+q.height,c.x,c.y,p.x,p.y,t)?(u=c=t.copy(),d=p.Xb(t)):Je(q.x,q.y,q.x+q.width,q.y+q.height,p.x,p.y,n.x,n.y,t)?(u=c=t.copy(),d=n.Xb(t)):Je(q.x,
q.y,q.x+q.width,q.y+q.height,n.x,n.y,a.x,a.y,t)&&(u=c=t.copy(),d=a.Xb(t)));D.A(t)}h=h.copy().Zh(k);k=s.$G;h.ig(s.$p*k,s.Yp*k);as(s,a,b,c,d,h);k=bs(s,c.x,c.y);!s.abort&&999999<=k&&(Pn(s),k=s.iG,h.ig(s.$p*k,s.Yp*k),as(s,a,b,c,d,h),k=bs(s,c.x,c.y));!s.abort&&999999<=k&&s.rH&&(Pn(s),as(s,a,b,c,d,s.qb),k=bs(s,c.x,c.y));if(!s.abort&&999999>k&&0!==bs(s,c.x,c.y)){cs(this,s,c.x,c.y,d,!0);h=this.m(2);if(4>this.ta)0===b||180===b?(h.x=a.x,h.y=c.y):(h.x=c.x,h.y=a.y),this.ha(2,h.x,h.y),this.B(3,h.x,h.y);else if(c=
this.m(3),0===b||180===b)Q(h.x,c.x)?(h=0===b?Math.max(h.x,a.x):Math.min(h.x,a.x),this.ha(2,h,a.y),this.ha(3,h,c.y)):Q(h.y,c.y)?(Math.abs(a.y-h.y)<=s.Yp/2&&(this.ha(2,h.x,a.y),this.ha(3,c.x,a.y)),this.B(2,h.x,a.y)):this.ha(2,a.x,h.y);else if(90===b||270===b)Q(h.y,c.y)?(h=90===b?Math.max(h.y,a.y):Math.min(h.y,a.y),this.ha(2,a.x,h),this.ha(3,c.x,h)):Q(h.x,c.x)?(Math.abs(a.x-h.x)<=s.$p/2&&(this.ha(2,a.x,h.y),this.ha(3,a.x,c.y)),this.B(2,a.x,h.y)):this.ha(2,h.x,a.y);null!==r&&(a=this.m(1),c=this.m(2),
a.x!==c.x&&a.y!==c.y?0===b||180===b?this.B(2,a.x,c.y):this.B(2,c.x,a.y):0===b||180===b?this.B(2,e.x,r.y):this.B(2,r.x,e.y));null!==u&&(0===d||180===d?this.xj(g.x,u.y):this.xj(u.x,g.y));b=!0}else b=!1}}}else b=!1;b||(this.Ci(l),this.Ci(m))}};J.prototype.computeMidOrthoPosition=function(a,b){if(this.hasCurviness()){var c=this.computeCurviness();return(a+b)/2+c}return(a+b)/2};
function Mi(a){if(null===a.g||!a.Jj)return!1;var b=a.points.o,c=b.length;if(4>c)return!1;a=ha(a.g,!0,a.Ka,null);for(var d=1;d<c-2;d++){var e=b[d],g=b[d+1];if(!a.yq(Math.min(e.x,g.x),Math.min(e.y,g.y),Math.abs(e.x-g.x),Math.abs(e.y-g.y)))return!0}return!1}
function cs(a,b,c,d,e,g){var h=b.$p,k=b.Yp,l=bs(b,c,d),m=c,n=d;for(0===e?m+=h:90===e?n+=k:180===e?m-=h:n-=k;1<l&&bs(b,m,n)===l-1;)c=m,d=n,0===e?m+=h:90===e?n+=k:180===e?m-=h:n-=k,l-=1;if(g){if(1<l)if(180===e||0===e)c=Math.floor(c/h)*h+h/2;else if(90===e||270===e)d=Math.floor(d/k)*k+k/2}else c=Math.floor(c/h)*h+h/2,d=Math.floor(d/k)*k+k/2;1<l&&(g=e,m=c,n=d,0===e?(g=90,n+=k):90===e?(g=180,m-=h):180===e?(g=270,n-=k):270===e&&(g=0,m+=h),bs(b,m,n)===l-1?cs(a,b,m,n,g,!1):(m=c,n=d,0===e?(g=270,n-=k):90===
e?(g=0,m+=h):180===e?(g=90,n+=k):270===e&&(g=180,m-=h),bs(b,m,n)===l-1&&cs(a,b,m,n,g,!1)));a.xj(c,d)}J.prototype.findClosestSegment=function(a){v&&D.l(a,N,J,"findClosestSegment:p");var b=a.x;a=a.y;for(var c=this.m(0),d=this.m(1),e=lb(b,a,c.x,c.y,d.x,d.y),g=0,h=1;h<this.ta-1;h++){var c=this.m(h+1),k=lb(b,a,d.x,d.y,c.x,c.y),d=c;k<e&&(g=h,e=k)}return g};J.prototype.Te=function(){this.ab=null};D.w(J,{uf:"geometry"},function(){null===this.ab&&(this.zo(),this.ab=this.makeGeometry());return this.ab});
J.prototype.Mu=function(a){if(!a){if(!1===this.Tf)return;a=this.Xd();if(null!==this.ab&&(null===a||null!==a.uf))return}this.ab=this.makeGeometry();a=this.path;if(null!==a){a.ab=this.ab;for(var b=this.xa.o,c=b.length,d=0;d<c;d++){var e=b[d];e!==a&&e.Ue&&e instanceof z&&(e.ab=this.ab)}}};
J.prototype.makeGeometry=function(){var a=this.ta;if(2>a)return new Ve(sf);var b=!1,c=this.g;null!==c&&Tr(this)&&c.yl.contains(this)&&null!==this.Mw&&(b=!0);var d=c=0,e=this.m(0).copy(),g=e.copy(),c=this.fd.o,h=this.computeCurve();if(h===Tj&&3<=a&&!Eb(this.Qq,0))if(3===a)var k=this.m(1),c=Math.min(e.x,k.x),d=Math.min(e.y,k.y),k=this.m(2),c=Math.min(c,k.x),d=Math.min(d,k.y);else{if(this.ic)for(k=0;k<a;k++)d=c[k],g.x=Math.min(d.x,g.x),g.y=Math.min(d.y,g.y);else for(k=3;k<a;k+=3)k+3>=a&&(k=a-1),c=this.m(k),
g.x=Math.min(c.x,g.x),g.y=Math.min(c.y,g.y);c=g.x;d=g.y}else{for(k=0;k<a;k++)d=c[k],g.x=Math.min(d.x,g.x),g.y=Math.min(d.y,g.y);c=g.x;d=g.y}c-=this.kA.x;d-=this.kA.y;e.x-=c;e.y-=d;if(2!==a||Tr(this)){var l=D.v();0!==this.computeShortLength(!0)&&(e=ds(this,e,!0,g));T(l,e.x,e.y,!1,!1);if(h===Tj&&3<=a&&!Eb(this.Qq,0))if(3===a)k=this.m(1),a=k.x-c,b=k.y-d,k=this.m(2).copy(),k.x-=c,k.y-=d,0!==this.computeShortLength(!1)&&(k=ds(this,k,!1,g)),U(l,a,b,a,b,k.x,k.y);else if(this.ic){for(var g=new N(c,d),e=this.m(1).copy(),
h=new N(c,d),a=new N(c,d),b=this.m(0),m=null,n=this.Qq/3,k=1;k<this.ta-1;k++){var m=this.m(k),p=b,q=m,r=this.m(es(this,m,k,!1));if(!Eb(p.x,q.x)||!Eb(q.x,r.x))if(!Eb(p.y,q.y)||!Eb(q.y,r.y)){var s=n,u=h,t=a;isNaN(s)&&(s=this.Qq/3);var y=p.x,p=p.y,w=q.x,q=q.y,B=r.x,r=r.y,A=s*fs(y,p,w,q),s=s*fs(w,q,B,r);Eb(p,q)&&Eb(w,B)&&(w>y?r>q?(u.x=w-A,u.y=q-A,t.x=w+s,t.y=q+s):(u.x=w-A,u.y=q+A,t.x=w+s,t.y=q-s):r>q?(u.x=w+A,u.y=q-A,t.x=w-s,t.y=q+s):(u.x=w+A,u.y=q+A,t.x=w-s,t.y=q-s));Eb(y,w)&&Eb(q,r)&&(q>p?(B>w?(u.x=
w-A,u.y=q-A,t.x=w+s):(u.x=w+A,u.y=q-A,t.x=w-s),t.y=q+s):(B>w?(u.x=w-A,u.y=q+A,t.x=w+s):(u.x=w+A,u.y=q+A,t.x=w-s),t.y=q-s));if(Eb(y,w)&&Eb(w,B)||Eb(p,q)&&Eb(q,r))y=.5*(y+B),p=.5*(p+r),u.x=y,u.y=p,t.x=y,t.y=p;1===k?(e.x=.5*(b.x+m.x),e.y=.5*(b.y+m.y)):2===k&&Eb(b.x,this.m(0).x)&&Eb(b.y,this.m(0).y)&&(e.x=.5*(b.x+m.x),e.y=.5*(b.y+m.y));U(l,e.x-c,e.y-d,h.x-c,h.y-d,m.x-c,m.y-d);g.set(h);e.set(a);b=m}}k=b.x;b=b.y;g=this.m(this.ta-1);0!==this.computeShortLength(!1)&&(g=ds(this,g.copy(),!1,Kd));k=.5*(k+g.x);
b=.5*(b+g.y);U(l,a.x-c,a.y-d,k-c,b-d,g.x-c,g.y-d)}else for(k=3;k<a;k+=3)b=this.m(k-2),k+3>=a&&(k=a-1),g=this.m(k-1),e=this.m(k),k===a-1&&0!==this.computeShortLength(!1)&&(e=ds(this,e.copy(),!1,Kd)),U(l,b.x-c,b.y-d,g.x-c,g.y-d,e.x-c,e.y-d);else{g=D.P();g.assign(this.m(0));k=1;for(e=0;k<a;){k=es(this,g,k,1<k);u=this.m(k);if(k>=a-1){if(!g.O(u))0!==this.computeShortLength(!1)&&(u=ds(this,u.copy(),!1,Kd)),gs(this,l,-c,-d,g,u,b);else if(0===e)for(k=1;k<a;)u=this.m(k++),gs(this,l,-c,-d,g,u,b),g.assign(u);
break}e=es(this,u,k+1,k<a-3);k=l;h=-c;m=-d;n=g;t=this.m(e);y=g;p=b;Q(n.y,u.y)&&Q(u.x,t.x)?(s=this.computeCorner(),s=Math.min(s,Math.abs(u.x-n.x)/2),s=w=Math.min(s,Math.abs(t.y-u.y)/2),Q(s,0)?(gs(this,k,h,m,n,u,p),y.assign(u)):(q=u.x,B=u.y,r=q,A=B,q=u.x>n.x?u.x-s:u.x+s,A=t.y>u.y?u.y+w:u.y-w,gs(this,k,h,m,n,new N(q,B),p),zf(k,u.x+h,u.y+m,r+h,A+m),y.n(r,A))):Q(n.x,u.x)&&Q(u.y,t.y)?(s=this.computeCorner(),w=Math.min(s,Math.abs(u.y-n.y)/2),w=s=Math.min(w,Math.abs(t.x-u.x)/2),Q(s,0)?(gs(this,k,h,m,n,u,
p),y.assign(u)):(q=u.x,A=B=u.y,B=u.y>n.y?u.y-w:u.y+w,r=t.x>u.x?u.x+s:u.x-s,gs(this,k,h,m,n,new N(q,B),p),zf(k,u.x+h,u.y+m,r+h,A+m),y.n(r,A))):(gs(this,k,h,m,n,u,p),y.assign(u));k=e}D.A(g)}c=l.q;D.u(l)}else l=this.m(1).copy(),l.x-=c,l.y-=d,0!==this.computeShortLength(!0)&&(e=ds(this,e,!0,g)),0!==this.computeShortLength(!1)&&(l=ds(this,l,!1,g)),c=new Ve(sf),c.ma=e.x,c.ja=e.y,c.G=l.x,c.H=l.y;return c};
function fs(a,b,c,d){a=c-a;if(isNaN(a)||Infinity===a||-Infinity===a)return NaN;0>a&&(a=-a);b=d-b;if(isNaN(b)||Infinity===b||-Infinity===b)return NaN;0>b&&(b=-b);return Eb(a,0)?b:Eb(b,0)?a:Math.sqrt(a*a+b*b)}
function ds(a,b,c,d){var e=a.ta;if(2>e)return b;if(c){var g=a.m(1);c=g.x-d.x;d=g.y-d.y;g=fs(b.x,b.y,c,d);if(0===g)return b;e=2===e?.5*g:g;a=a.computeShortLength(!0);a>e&&(a=e);c=a*(c-b.x)/g;a=a*(d-b.y)/g;b.x+=c;b.y+=a}else{g=a.m(e-2);c=g.x-d.x;d=g.y-d.y;g=fs(b.x,b.y,c,d);if(0===g)return b;e=2===e?.5*g:g;a=a.computeShortLength(!1);a>e&&(a=e);c=a*(b.x-c)/g;a=a*(b.y-d)/g;b.x-=c;b.y-=a}return b}
function es(a,b,c,d){for(var e=a.ta,g=b;Eb(b.x,g.x)&&Eb(b.y,g.y);){if(c>=e)return e-1;g=a.m(c++)}if(!Eb(b.x,g.x)&&!Eb(b.y,g.y))return c-1;for(var h=g;Eb(b.x,g.x)&&Eb(g.x,h.x)&&(!d||(b.y>=g.y?g.y>=h.y:g.y<=h.y))||Eb(b.y,g.y)&&Eb(g.y,h.y)&&(!d||(b.x>=g.x?g.x>=h.x:g.x<=h.x));){if(c>=e)return e-1;h=a.m(c++)}return c-2}
function gs(a,b,c,d,e,g,h){if(!h&&Tr(a)){h=[];var k=0;a.isVisible()&&(k=hs(a,e,g,h));var l=e.x,l=e.y;if(0<k)if(Q(e.y,g.y))if(e.x<g.x)for(var m=0;m<k;){var n=Math.max(e.x,Math.min(h[m++]-5,g.x-10));b.lineTo(n+c,g.y+d);for(var l=n+c,p=Math.min(n+10,g.x);m<k;){var q=h[m];if(q<p+10)m++,p=Math.min(q+5,g.x);else break}q=(n+p)/2+c;q=g.y-10+d;n=p+c;p=g.y+d;a.lf===tj?T(b,n,p,!1,!1):U(b,l,q,n,q,n,p)}else for(m=k-1;0<=m;){n=Math.min(e.x,Math.max(h[m--]+5,g.x+10));b.lineTo(n+c,g.y+d);l=n+c;for(p=Math.max(n-10,
g.x);0<=m;)if(q=h[m],q>p-10)m--,p=Math.max(q-5,g.x);else break;q=g.y-10+d;n=p+c;p=g.y+d;a.lf===tj?T(b,n,p,!1,!1):U(b,l,q,n,q,n,p)}else if(Q(e.x,g.x))if(e.y<g.y)for(m=0;m<k;){n=Math.max(e.y,Math.min(h[m++]-5,g.y-10));b.lineTo(g.x+c,n+d);l=n+d;for(p=Math.min(n+10,g.y);m<k;)if(q=h[m],q<p+10)m++,p=Math.min(q+5,g.y);else break;q=g.x-10+c;n=g.x+c;p+=d;a.lf===tj?T(b,n,p,!1,!1):U(b,q,l,q,p,n,p)}else for(m=k-1;0<=m;){n=Math.min(e.y,Math.max(h[m--]+5,g.y+10));b.lineTo(g.x+c,n+d);l=n+d;for(p=Math.max(n-10,g.y);0<=
m;)if(q=h[m],q>p-10)m--,p=Math.max(q-5,g.y);else break;q=g.x-10+c;n=g.x+c;p+=d;a.lf===tj?T(b,n,p,!1,!1):U(b,q,l,q,p,n,p)}}b.lineTo(g.x+c,g.y+d)}
function hs(a,b,c,d){var e=a.g;if(null===e||b.O(c))return 0;for(e=e.bo;e.next();){var g=e.value;if(null!==g&&g.visible)for(var g=g.sb.o,h=g.length,k=0;k<h;k++){var l=g[k];if(l instanceof J){if(l===a)return 0<d.length&&d.sort(function(a,b){return a-b}),d.length;if(l.isVisible()&&Tr(l)){var m=l.so;m.F()&&a.so.jg(m)&&!a.usesSamePort(l)&&(m=l.path,null!==m&&m.Oj()&&is(b,c,d,l))}}}}0<d.length&&d.sort(function(a,b){return a-b});return d.length}
function is(a,b,c,d){for(var e=Q(a.y,b.y),g=d.ta,h=d.m(0),k=D.P(),l=1;l<g;l++){var m=d.m(l);if(l<g-1){var n=d.m(l+1);if(h.y===m.y&&m.y===n.y){if(m.x>h.x&&n.x>m.x||m.x<h.x&&n.x<m.x)m=n,l++}else h.x===m.x&&m.x===n.x&&(m.y>h.y&&n.y>m.y||m.y<h.y&&n.y<m.y)&&(m=n,l++)}a:{var n=k,p=a.x,q=a.y,r=b.x,s=b.y,u=h.x,h=h.y,t=m.x,y=m.y;if(!Q(p,r)){if(Q(q,s)&&Q(u,t)&&Math.min(p,r)<u&&Math.max(p,r)>u&&Math.min(h,y)<q&&Math.max(h,y)>q&&!Q(h,y)){n.x=u;n.y=q;n=!0;break a}}else if(!Q(q,s)&&Q(h,y)&&Math.min(q,s)<h&&Math.max(q,
s)>h&&Math.min(u,t)<p&&Math.max(u,t)>p&&!Q(u,t)){n.x=p;n.y=h;n=!0;break a}n.x=0;n.y=0;n=!1}n&&(e?c.push(k.x):c.push(k.y));h=m}D.A(k)}D.w(J,{lu:"firstPickIndex"},function(){var a;2>=this.ta?a=0:((a=this.ic)||(a=this.computeSpot(!0)!==dc),a=a?1:0);return a});D.w(J,{gy:"lastPickIndex"},function(){var a=this.ta;if(0===a)a=0;else if(2>=a)a-=1;else{var b;(b=this.ic)||(b=this.computeSpot(!1)!==dc);a=b?a-2:a-1}return a});function Tr(a){a=a.lf;return a===sj||a===tj}
function Vr(a,b){if(b||Tr(a)){var c=a.g;null===c||c.yl.contains(a)||null===a.Mw||c.yl.add(a,a.Mw)}}function Nj(a,b){var c=a.layer;if(null!==c&&c.visible&&!c.Wc){var d=c.g;if(null!==d)for(var e=!1,d=d.bo;d.next();){var g=d.value;if(g.visible)if(g===c)for(var e=!0,h=!1,g=g.sb.o,k=g.length,l=0;l<k;l++){var m=g[l];m instanceof J&&(m===a?h=!0:h&&js(a,m,b))}else if(e)for(g=g.sb.o,k=g.length,l=0;l<k;l++)m=g[l],m instanceof J&&js(a,m,b)}}}
function js(a,b,c){if(null!==b&&null!==b.ab&&Tr(b)){var d=b.so;d.F()&&(a.so.jg(d)||c.jg(d))&&(a.usesSamePort(b)||b.Te())}}J.prototype.usesSamePort=function(a){var b=this.ta,c=a.ta;if(0<b&&0<c){var d=this.m(0),e=a.m(0);if(d.Uc(e))return!0;b=this.m(b-1);a=a.m(c-1);if(b.Uc(a)||d.Uc(a)||b.Uc(e))return!0}else if(this.aa===a.aa||this.ba===a.ba||this.aa===a.ba||this.ba===a.aa)return!0;return!1};
J.prototype.isVisible=function(){if(!F.prototype.isVisible.call(this))return!1;var a=this.Ka,b=!0,c=this.g;null!==c&&(b=c.de);var d=this.aa;if(null!==d){if(this.Xc&&b&&!d.Bc)return!1;if(d===a)return!0;for(c=d;null!==c;){if(c.Yb===this)return!0;c=c.Ka}c=d.findVisibleNode();if(null===c||c===a)return!1}d=this.ba;if(null!==d){if(this.Xc&&!b&&!d.Bc)return!1;if(d===a)return!0;for(c=d;null!==c;){if(c.Yb===this)return!0;c=c.Ka}b=d.findVisibleNode();if(null===b||b===a)return!1}return!0};
J.prototype.Ld=function(a){F.prototype.Ld.call(this,a);null!==this.Oi&&this.Oi.tq();if(null!==this.Ff)for(var b=this.Ff.j;b.next();)b.value.Ld(a)};D.defineProperty(J,{Ut:"adjusting"},function(){return this.ar},function(a){var b=this.ar;b!==a&&(v&&D.Da(a,J,J,"adjusting"),this.ar=a,this.i("adjusting",b,a))});D.defineProperty(J,{FA:"corner"},function(){return this.vr},function(a){var b=this.vr;b!==a&&(v&&D.h(a,"number",J,"corner"),this.vr=a,this.Te(),this.i("corner",b,a))});
D.defineProperty(J,{lf:"curve"},function(){return this.yr},function(a){var b=this.yr;b!==a&&(v&&D.Da(a,J,J,"curve"),this.yr=a,this.ec(),this.Te(),Vr(this,b===tj||b===sj||a===tj||a===sj),this.i("curve",b,a))});D.defineProperty(J,{Dx:"curviness"},function(){return this.zr},function(a){var b=this.zr;b!==a&&(v&&D.h(a,"number",J,"curviness"),this.zr=a,this.ec(),this.Te(),this.i("curviness",b,a))});
D.defineProperty(J,{Ey:"routing"},function(){return this.pn},function(a){var b=this.pn;b!==a&&(v&&D.Da(a,J,J,"routing"),this.pn=a,this.Zi=null,this.ec(),Vr(this,2===(b.value&2)||2===(a.value&2)),this.i("routing",b,a))});D.defineProperty(J,{Qq:"smoothness"},function(){return this.zt},function(a){var b=this.zt;b!==a&&(v&&D.h(a,"number",J,"smoothness"),this.zt=a,this.Te(),this.i("smoothness",b,a))});
function Sr(a){var b=a.Dg;if(null!==b){var c=a.Ug;if(null!==c){var d=a.fi;a=a.Ai;for(var e=null,g=null,h=b.wc.o,k=h.length,l=0;l<k;l++){var m=h[l];if(m.Dg===b&&m.fi===d&&m.Ug===c&&m.Ai===a||m.Dg===c&&m.fi===a&&m.Ug===b&&m.Ai===d)null===g?g=m:(null===e&&(e=[],e.push(g)),e.push(m))}if(null!==e){g=Br(b,c,d,a);null===g&&(g=new Hl,g.Cq=b,g.uy=d,g.Ru=c,g.vy=a,Ar(b,g),Ar(c,g));g.links=e;for(l=0;l<e.length;l++)m=e[l],m.Oi=g;g.tq()}}}}
function Rr(a){var b=a.Oi;null!==b&&(a.Oi=null,a=b.links.indexOf(a),0<=a&&(D.Wg(b.links,a),b.tq()))}D.w(J,{key:"key"},function(){var a=this.g;return null!==a&&a.da instanceof Y?a.da.mf(this.data):void 0},{configurable:!0});function Hl(){D.vc(this);this.Wf=this.gw=!1;this.vy=this.Ru=this.uy=this.Cq=null;this.links=[]}Hl.prototype.tq=function(){if(!this.gw){var a=this.links;0<a.length&&(a=a[0].g,null!==a&&(a.nD.add(this),this.Wf=a.la.jb))}this.gw=!0};
Hl.prototype.gC=function(){if(this.gw){this.gw=!1;var a=this.links;if(0<a.length){var b=a[0],c=b.g,c=null===c||c.$n&&!this.Wf;this.Wf=!1;b.arrangeBundledLinks(a,c);1===a.length&&(b.Oi=null,a.length=0)}0===a.length&&(a=this.Cq,null!==this&&null!==a.$h&&a.$h.remove(this),a=this.Ru,null!==this&&null!==a.$h&&a.$h.remove(this))}};D.ne(Hl,{Cq:!0,uy:!0,Ru:!0,vy:!0,links:!0,spacing:!0});
function Nn(){D.vc(this);this.UB=this.group=null;this.sq=!0;this.abort=!1;this.ag=this.$f=1;this.zs=this.ys=-1;this.Od=this.ge=8;this.rd=null;this.rH=!1;this.$G=22;this.iG=111}D.ne(Nn,{group:!0,UB:!0,sq:!0,abort:!0,rH:!0,$G:!0,iG:!0});
Nn.prototype.initialize=function(a){if(!(0>=a.width||0>=a.height)){var b=a.y,c=a.x+a.width,d=a.y+a.height;this.$f=Math.floor((a.x-this.ge)/this.ge)*this.ge;this.ag=Math.floor((b-this.Od)/this.Od)*this.Od;this.ys=Math.ceil((c+2*this.ge)/this.ge)*this.ge;this.zs=Math.ceil((d+2*this.Od)/this.Od)*this.Od;a=1+(Math.ceil((this.ys-this.$f)/this.ge)|0);b=1+(Math.ceil((this.zs-this.ag)/this.Od)|0);if(null===this.rd||this.An<a-1||this.Bn<b-1){c=[];for(d=0;d<=a;d++)c[d]=[];this.rd=c;this.An=a-1;this.Bn=b-1}if(null!==
this.rd)for(a=0;a<=this.An;a++)for(b=0;b<=this.Bn;b++)this.rd[a][b]=1E6}};D.w(Nn,{qb:null},function(){return new C(this.$f,this.ag,this.ys-this.$f,this.zs-this.ag)});D.defineProperty(Nn,{$p:null},function(){return this.ge},function(a){0<a&&a!==this.ge&&(this.ge=a,this.initialize(this.qb))});D.defineProperty(Nn,{Yp:null},function(){return this.Od},function(a){0<a&&a!==this.Od&&(this.Od=a,this.initialize(this.qb))});function $r(a,b,c){return a.$f<=b&&b<=a.ys&&a.ag<=c&&c<=a.zs}
function bs(a,b,c){if(!$r(a,b,c))return 1E6;b-=a.$f;b/=a.ge;c-=a.ag;c/=a.Od;return a.rd[b|0][c|0]}function Qn(a,b,c){$r(a,b,c)&&(b-=a.$f,b/=a.ge,c-=a.ag,c/=a.Od,a.rd[b|0][c|0]=0)}function Pn(a){if(null!==a.rd)for(var b=0;b<=a.An;b++)for(var c=0;c<=a.Bn;c++)1<=a.rd[b][c]&&(a.rd[b][c]=1E6)}
Nn.prototype.yq=function(a,b,c,d){if(a>this.ys||a+c<this.$f||b>this.zs||b+d<this.ag)return!0;a=(a-this.$f)/this.ge|0;b=(b-this.ag)/this.Od|0;c=Math.max(0,c)/this.ge+1|0;var e=Math.max(0,d)/this.Od+1|0;0>a&&(c+=a,a=0);0>b&&(e+=b,b=0);if(0>c||0>e)return!0;d=Math.min(a+c-1,this.An)|0;for(c=Math.min(b+e-1,this.Bn)|0;a<=d;a++)for(e=b;e<=c;e++)if(0===this.rd[a][e])return!1;return!0};
function ks(a,b,c,d,e,g,h,k,l){if(!(b<g||b>h||c<k||c>l)){var m,n;m=b|0;n=c|0;var p=a.rd[m][n];if(1<=p&&999999>p)for(e?n+=d:m+=d,p+=1;g<=m&&m<=h&&k<=n&&n<=l&&!(p>=a.rd[m][n]);)a.rd[m][n]=p,p+=1,e?n+=d:m+=d;m=e?n:m;if(e)if(0<d)for(c+=d;c<m;c+=d)ks(a,b,c,1,!e,g,h,k,l),ks(a,b,c,-1,!e,g,h,k,l);else for(c+=d;c>m;c+=d)ks(a,b,c,1,!e,g,h,k,l),ks(a,b,c,-1,!e,g,h,k,l);else if(0<d)for(b+=d;b<m;b+=d)ks(a,b,c,1,!e,g,h,k,l),ks(a,b,c,-1,!e,g,h,k,l);else for(b+=d;b>m;b+=d)ks(a,b,c,1,!e,g,h,k,l),ks(a,b,c,-1,!e,g,h,
k,l)}}function ls(a,b,c,d,e,g,h,k,l){b|=0;c|=0;var m=0,n=1;for(a.rd[b][c]=n;0===m&&b>g&&b<h&&c>k&&c<l;)n+=1,a.rd[b][c]=n,e?c+=d:b+=d,m=a.rd[b][c]}function ms(a,b,c,d,e,g,h,k,l){b|=0;c|=0;var m=0;for(a.rd[b][c]=999999;0===m&&b>g&&b<h&&c>k&&c<l;)a.rd[b][c]=999999,e?c+=d:b+=d,m=a.rd[b][c]}
function as(a,b,c,d,e,g){if(null!==a.rd){a.abort=!1;var h=b.x,k=b.y;if($r(a,h,k)&&(h-=a.$f,h/=a.ge,k-=a.ag,k/=a.Od,b=d.x,d=d.y,$r(a,b,d)))if(b-=a.$f,b/=a.ge,d-=a.ag,d/=a.Od,1>=Math.abs(h-b)&&1>=Math.abs(k-d))a.abort=!0;else{var l=g.x,m=g.y,n=g.x+g.width,p=g.y+g.height,l=l-a.$f,l=l/a.ge,m=m-a.ag,m=m/a.Od,n=n-a.$f,n=n/a.ge,p=p-a.ag,p=p/a.Od;g=Math.max(0,Math.min(a.An,l|0));n=Math.min(a.An,Math.max(0,n|0));m=Math.max(0,Math.min(a.Bn,m|0));p=Math.min(a.Bn,Math.max(0,p|0));h|=0;k|=0;b|=0;d|=0;l=0===c||
90===c?1:-1;c=90===c||270===c;0===a.rd[h][k]?(ls(a,h,k,l,c,g,n,m,p),ls(a,h,k,1,!c,g,n,m,p),ls(a,h,k,-1,!c,g,n,m,p)):ls(a,h,k,l,c,h,k,h,k);0===a.rd[b][d]?(ms(a,b,d,0===e||90===e?1:-1,90===e||270===e,g,n,m,p),ms(a,b,d,1,!(90===e||270===e),g,n,m,p),ms(a,b,d,-1,!(90===e||270===e),g,n,m,p)):ms(a,b,d,l,c,b,d,b,d);a.abort||(ks(a,h,k,1,!1,g,n,m,p),ks(a,h,k,-1,!1,g,n,m,p),ks(a,h,k,1,!0,g,n,m,p),ks(a,h,k,-1,!0,g,n,m,p))}}}function Yr(){D.vc(this);this.port=this.Yc=null;this.og=[];this.Bq=!1}
D.ne(Yr,{Yc:!0,port:!0,og:!0,Bq:!0});Yr.prototype.toString=function(){for(var a=this.og,b=this.Yc.toString()+" "+a.length.toString()+":",c=0;c<a.length;c++){var d=a[c];null!==d&&(b+="\n  "+d.toString())}return b};
function ns(a,b,c,d){b=b.offsetY;switch(b){case D.pd:return 90;case D.bd:return 180;case D.yd:return 270;case D.qd:return 0}switch(b){case D.pd|D.yd:return 180<c?270:90;case D.bd|D.qd:return 90<c&&270>=c?180:0}a=180*Math.atan2(a.height,a.width)/Math.PI;switch(b){case D.bd|D.yd:return c>a&&c<=180+a?180:270;case D.yd|D.qd:return c>180-a&&c<=360-a?270:0;case D.qd|D.pd:return c>a&&c<=180+a?90:0;case D.pd|D.bd:return c>180-a&&c<=360-a?180:90;case D.bd|D.yd|D.qd:return 90<c&&c<=180+a?180:c>180+a&&c<=360-
a?270:0;case D.yd|D.qd|D.pd:return 180<c&&c<=360-a?270:c>a&&180>=c?90:0;case D.qd|D.pd|D.bd:return c>a&&c<=180-a?90:c>180-a&&270>=c?180:0;case D.pd|D.bd|D.yd:return c>180-a&&c<=180+a?180:c>180+a?270:90}d&&b!==(D.bd|D.yd|D.qd|D.pd)&&(c-=15,0>c&&(c+=360));return c>a&&c<180-a?90:c>=180-a&&c<=180+a?180:c>180+a&&c<360-a?270:0}Yr.prototype.tq=function(){this.og.length=0};
function Zr(a,b){var c=a.og;if(0===c.length){a:if(!a.Bq){c=a.Bq;a.Bq=!0;var d,e=null,g=a.Yc,g=g instanceof I?g:null;if(null===g||g.ld)d=a.Yc.uF(a.port.wd);else{if(!g.Z.F()){a.Bq=c;break a}e=g;d=e.tF()}var h=a.og.length=0,k=a.port.eb(ec,D.P()),l=a.port.eb(vc,D.P()),g=D.vg(k.x,k.y,0,0);g.Mi(l);D.A(k);D.A(l);k=D.Fb(g.x+g.width/2,g.y+g.height/2);l=a.port.tm();for(d=d.j;d.next();){var m=d.value;if(m.isVisible()&&m.Ac!==m.ad){var n=m.Ac===a.port||m.aa.Gi(e),p=m.computeSpot(n,a.port);if(p.Lj()&&(n=n?m.ad:
m.Ac,null!==n)){var q=n.Y;if(null!==q){var r=q.findVisibleNode();null!==r&&r!==q&&(q=r,n=q.port);n=m.computeOtherPoint(q,n);q=k.Xb(n);q-=l;0>q&&(q+=360);p=ns(g,p,q,m.ic);r=0;0===p?(r=D.qd,180<q&&(q-=360)):r=90===p?D.pd:180===p?D.bd:D.yd;p=a.og[h];void 0===p?(p=new os(m,q,r),a.og[h]=p):(p.link=m,p.angle=q,p.Le=r);p.sy.set(n);h++}}}}D.A(k);a.og.sort(Yr.prototype.QJ);e=a.og.length;k=-1;for(h=l=0;h<e;h++)p=a.og[h],void 0!==p&&(p.Le!==k&&(k=p.Le,l=0),p.qq=l,l++);k=-1;l=0;for(h=e-1;0<=h;h--)p=a.og[h],void 0!==
p&&(p.Le!==k&&(k=p.Le,l=p.qq+1),p.Mn=l);h=a.og;p=a.port;e=a.Yc.VJ;k=D.P();l=D.P();d=D.P();m=D.P();p.eb(ec,k);p.eb(kc,l);p.eb(vc,d);p.eb(tc,m);r=q=n=p=0;if(e===zr)for(var s=0;s<h.length;s++){var u=h[s];if(null!==u){var t=u.link.computeThickness();switch(u.Le){case D.pd:q+=t;break;case D.bd:r+=t;break;case D.yd:p+=t;break;default:case D.qd:n+=t}}}for(var y=0,w=0,B=1,s=0;s<h.length;s++)if(u=h[s],null!==u){var A,O;if(y!==u.Le){y=u.Le;switch(y){case D.pd:A=d;O=m;break;case D.bd:A=m;O=k;break;case D.yd:A=
k;O=l;break;default:case D.qd:A=l,O=d}var H=O.x-A.x;O=O.y-A.y;switch(y){case D.pd:q>Math.abs(H)?(B=Math.abs(H)/q,q=Math.abs(H)):B=1;break;case D.bd:r>Math.abs(O)?(B=Math.abs(O)/r,r=Math.abs(O)):B=1;break;case D.yd:p>Math.abs(H)?(B=Math.abs(H)/p,p=Math.abs(H)):B=1;break;default:case D.qd:n>Math.abs(O)?(B=Math.abs(O)/n,n=Math.abs(O)):B=1}w=0}var R=u.zq;if(e===zr){t=u.link.computeThickness();t*=B;R.set(A);switch(y){case D.pd:R.x=A.x+H/2+q/2-w-t/2;break;case D.bd:R.y=A.y+O/2+r/2-w-t/2;break;case D.yd:R.x=
A.x+H/2-p/2+w+t/2;break;default:case D.qd:R.y=A.y+O/2-n/2+w+t/2}w+=t}else t=.5,e===wr&&(t=(u.qq+1)/(u.Mn+1)),R.x=A.x+H*t,R.y=A.y+O*t}D.A(k);D.A(l);D.A(d);D.A(m);A=a.og;for(H=0;H<A.length;H++)O=A[H],null!==O&&(O.Ix=a.computeEndSegmentLength(O));a.Bq=c;D.Kb(g)}c=a.og}for(g=0;g<c.length;g++)if(A=c[g],null!==A&&A.link===b)return A;return null}Yr.prototype.QJ=function(a,b){return a===b?0:null===a?-1:null===b?1:a.Le<b.Le?-1:a.Le>b.Le?1:a.angle<b.angle?-1:a.angle>b.angle?1:0};
Yr.prototype.computeEndSegmentLength=function(a){var b=a.link,c=b.computeEndSegmentLength(this.Yc,this.port,dc,b.Ac===this.port),d=a.qq;if(0>d)return c;var e=a.Mn;if(1>=e||!b.ic)return c;var b=a.sy,g=a.zq;if(a.Le===D.bd||a.Le===D.pd)d=e-1-d;return((a=a.Le===D.bd||a.Le===D.qd)?b.y<g.y:b.x<g.x)?c+8*d:(a?b.y===g.y:b.x===g.x)?c:c+8*(e-1-d)};function os(a,b,c){this.link=a;this.angle=b;this.Le=c;this.sy=new N;this.Mn=this.qq=0;this.zq=new N;this.Ix=0}
D.ne(os,{link:!0,angle:!0,Le:!0,sy:!0,qq:!0,Mn:!0,zq:!0,Ix:!0});os.prototype.toString=function(){return this.link.toString()+" "+this.angle.toString()+" "+this.Le.toString()+":"+this.qq.toString()+"/"+this.Mn.toString()+" "+this.zq.toString()+" "+this.Ix.toString()+" "+this.sy.toString()};function Eo(){this.Ik=this.mk=Vc;this.Gk=this.kk=NaN;this.Fk=this.jk=xr;this.Hk=this.lk=NaN;this.Jt=this.Sr=null;this.Kt=this.Tr=Infinity}
Eo.prototype.copy=function(){var a=new Eo;a.mk=this.mk.V();a.Ik=this.Ik.V();a.kk=this.kk;a.Gk=this.Gk;a.jk=this.jk;a.Fk=this.Fk;a.lk=this.lk;a.Hk=this.Hk;a.Sr=this.Sr;a.Jt=this.Jt;a.Tr=this.Tr;a.Kt=this.Kt;return a};function I(a){G.call(this,a);this.Ca|=4608;this.Bs=new L(F);this.hn=new L(I);this.Rb=this.Dt=this.Ql=this.Cs=this.As=null;this.ve=new $g;this.ve.group=this}D.Sa(I,G);D.ka("Group",I);
I.prototype.cloneProtected=function(a){G.prototype.cloneProtected.call(this,a);this.Ca&=-32769;a.As=this.As;a.Cs=this.Cs;a.Ql=this.Ql;a.Dt=this.Dt;var b=a.iu(function(a){return a instanceof Xj});a.Rb=b instanceof Xj?b:null;null!==this.ve?(a.ve=this.ve.copy(),a.ve.group=a):(null!==a.ve&&(a.ve.group=null),a.ve=null)};I.prototype.Ei=function(a){G.prototype.Ei.call(this,a);var b=a.mq();for(a=a.Oc;a.next();){var c=a.value;c.K();c.N(8);c.au();if(c instanceof G)c.kg(b);else if(c instanceof J)for(c=c.Af;c.next();)c.value.kg(b)}};
I.prototype.qo=function(a,b,c,d,e,g,h){if(a===rg&&"elements"===b)if(e instanceof Xj){var k=e;null===this.Rb?this.Rb=k:this.Rb!==k&&D.k("Cannot insert a second Placeholder into the visual tree of a Group.")}else e instanceof x&&(k=e.iu(function(a){return a instanceof Xj}),k instanceof Xj&&(null===this.Rb?this.Rb=k:this.Rb!==k&&D.k("Cannot insert a second Placeholder into the visual tree of a Group.")));else a===sg&&"elements"===b&&null!==this.Rb&&(d===this.Rb?this.Rb=null:d instanceof x&&this.Rb.ym(d)&&
(this.Rb=null));G.prototype.qo.call(this,a,b,c,d,e,g,h)};I.prototype.yj=function(a,b,c,d){this.Pl=this.Rb;x.prototype.yj.call(this,a,b,c,d)};I.prototype.zm=function(){if(!G.prototype.zm.call(this))return!1;for(var a=this.Oc;a.next();){var b=a.value;if(b instanceof G){if(b.isVisible()&&Nm(b))return!1}else if(b instanceof J&&b.isVisible()&&Nm(b)&&b.aa!==this&&b.ba!==this)return!1}return!0};D.w(I,{placeholder:"placeholder"},function(){return this.Rb});
D.defineProperty(I,{iI:"computesBoundsAfterDrag"},function(){return 0!==(this.Ca&2048)},function(a){var b=0!==(this.Ca&2048);b!==a&&(D.h(a,"boolean",I,"computesBoundsAfterDrag"),this.Ca^=2048,this.i("computesBoundsAfterDrag",b,a))});D.defineProperty(I,{zx:"computesBoundsIncludingLinks"},function(){return 0!==(this.Ca&4096)},function(a){D.h(a,"boolean",I,"computesBoundsIncludingLinks");var b=0!==(this.Ca&4096);b!==a&&(this.Ca^=4096,this.i("computesBoundsIncludingLinks",b,a))});
D.defineProperty(I,{jI:"computesBoundsIncludingLocation"},function(){return 0!==(this.Ca&8192)},function(a){D.h(a,"boolean",I,"computesBoundsIncludingLocation");var b=0!==(this.Ca&8192);b!==a&&(this.Ca^=8192,this.i("computesBoundsIncludingLocation",b,a))});D.defineProperty(I,{YI:"handlesDragDropForMembers"},function(){return 0!==(this.Ca&16384)},function(a){D.h(a,"boolean",I,"handlesDragDropForMembers");var b=0!==(this.Ca&16384);b!==a&&(this.Ca^=16384,this.i("handlesDragDropForMembers",b,a))});
D.w(I,{Oc:"memberParts"},function(){return this.Bs.j});function sr(a,b){if(a.Bs.add(b)){b instanceof I&&a.hn.add(b);var c=a.IJ;if(null!==c){var d=!0,e=a.g;null!==e&&(d=e.$a,e.$a=!0);c(a,b);null!==e&&(e.$a=d)}a.isVisible()&&a.ld||b.Ld(!1)}b instanceof J&&!a.zx||(c=a.Rb,null===c&&(c=a),c.K())}
function rr(a,b){if(a.Bs.remove(b)){b instanceof I&&a.hn.remove(b);var c=a.JJ;if(null!==c){var d=!0,e=a.g;null!==e&&(d=e.$a,e.$a=!0);c(a,b);null!==e&&(e.$a=d)}a.isVisible()&&a.ld||b.Ld(!0)}b instanceof J&&!a.zx||(c=a.Rb,null===c&&(c=a),c.K())}I.prototype.On=function(){if(0<this.Bs.count){var a=this.g;if(null!==a)for(var b=this.Bs.copy().j;b.next();)a.remove(b.value)}G.prototype.On.call(this)};
D.defineProperty(I,{Zb:"layout"},function(){return this.ve},function(a){var b=this.ve;b!==a&&(null!==a&&D.l(a,$g,I,"layout"),null!==b&&(b.g=null,b.group=null),this.ve=a,null!==a&&(a.g=this.g,a.group=this),this.i("layout",b,a))});D.defineProperty(I,{IJ:"memberAdded"},function(){return this.As},function(a){var b=this.As;b!==a&&(null!==a&&D.h(a,"function",I,"memberAdded"),this.As=a,this.i("memberAdded",b,a))});
D.defineProperty(I,{JJ:"memberRemoved"},function(){return this.Cs},function(a){var b=this.Cs;b!==a&&(null!==a&&D.h(a,"function",I,"memberRemoved"),this.Cs=a,this.i("memberRemoved",b,a))});D.defineProperty(I,{oB:"memberValidation"},function(){return this.Ql},function(a){var b=this.Ql;b!==a&&(null!==a&&D.h(a,"function",I,"memberValidation"),this.Ql=a,this.i("memberValidation",b,a))});
I.prototype.canAddMembers=function(a){var b=this.g;if(null===b)return!1;b=b.yb;for(a=Xh(a).j;a.next();)if(!b.isValidMember(this,a.value))return!1;return!0};I.prototype.addMembers=function(a,b){var c=this.g;if(null===c)return!1;for(var c=c.yb,d=!0,e=Xh(a).j;e.next();){var g=e.value;!b||c.isValidMember(this,g)?g.Ka=this:d=!1}return d};
D.defineProperty(I,{CK:"ungroupable"},function(){return 0!==(this.Ca&256)},function(a){var b=0!==(this.Ca&256);b!==a&&(D.h(a,"boolean",I,"ungroupable"),this.Ca^=256,this.i("ungroupable",b,a))});I.prototype.canUngroup=function(){if(!this.CK)return!1;var a=this.layer;if(null!==a&&!a.ux)return!1;a=a.g;return null===a||a.ux?!0:!1};
I.prototype.kg=function(a){void 0===a&&(a=null);var b=0!==(this.Ca&65536);G.prototype.kg.call(this,a);if(!b)for(0!==(this.Ca&65536)!==!0&&(this.Ca^=65536),b=this.tF();b.next();){var c=b.value;if(null===a||!a.contains(c)){var d=c.aa;null!==d&&d!==this&&d.Gi(this)&&!d.isVisible()?c.ec():(d=c.ba,null!==d&&d!==this&&d.Gi(this)&&!d.isVisible()&&c.ec())}}};
I.prototype.findExternalLinksConnected=I.prototype.tF=function(){var a=this.mq();a.add(this);for(var b=new L(J),c=a.j;c.next();){var d=c.value;if(d instanceof G)for(d=d.Kd;d.next();){var e=d.value;a.contains(e)||b.add(e)}}return b.j};I.prototype.findExternalNodesConnected=function(){var a=this.mq();a.add(this);for(var b=new L(G),c=a.j;c.next();){var d=c.value;if(d instanceof G)for(d=d.Kd;d.next();){var e=d.value,g=e.aa;a.contains(g)&&g!==this||b.add(g);e=e.ba;a.contains(e)&&e!==this||b.add(e)}}return b.j};
I.prototype.findContainingGroupChain=function(){function a(b,d){null!==b&&(d.add(b),a(b.Ka,d))}var b=new L(I);a(this,b);return b};I.prototype.findSubGraphParts=I.prototype.mq=function(){var a=new L(F);Qh(a,this,!0,0,!0);a.remove(this);return a};I.prototype.Ld=function(a){G.prototype.Ld.call(this,a);for(var b=this.Oc;b.next();)b.value.Ld(a)};I.prototype.collapseSubGraph=I.prototype.collapseSubGraph=function(){var a=this.g;if(null!==a&&!a.Qh){a.Qh=!0;var b=this.mq();ps(this,b,a.Wa,this);a.Qh=!1}};
function ps(a,b,c,d){for(var e=a.Oc;e.next();){var g=e.value;g.Ld(!1);if(g instanceof I){var h=g;h.ld&&(h.Ry=h.ld,ps(h,b,c,d))}if(g instanceof G)g.kg(b),pl(c,g,d);else if(g instanceof J)for(g=g.Af;g.next();)g.value.kg(b)}a.ld=!1}I.prototype.expandSubGraph=I.prototype.expandSubGraph=function(){var a=this.g;if(null!==a&&!a.Qh){a.Qh=!0;var b=this.mq();qs(this,b,a.Wa,this);a.Qh=!1}};
function qs(a,b,c,d){for(var e=a.Oc;e.next();){var g=e.value;g.Ld(!0);if(g instanceof I){var h=g;h.Ry&&(h.Ry=!1,qs(h,b,c,d))}if(g instanceof G)g.kg(b),ol(c,g,d);else if(g instanceof J)for(g=g.Af;g.next();)g.value.kg(b)}a.ld=!0}
D.defineProperty(I,{ld:"isSubGraphExpanded"},function(){return 0!==(this.Ca&512)},function(a){var b=0!==(this.Ca&512);if(b!==a){D.h(a,"boolean",I,"isSubGraphExpanded");this.Ca^=512;var c=this.g;this.i("isSubGraphExpanded",b,a);b=this.sK;if(null!==b){var d=!0;null!==c&&(d=c.$a,c.$a=!0);b(this);null!==c&&(c.$a=d)}null!==c&&c.la.jb?null!==this.Rb&&this.Rb.K():a?this.expandSubGraph():this.collapseSubGraph()}});
D.defineProperty(I,{Ry:"wasSubGraphExpanded"},function(){return 0!==(this.Ca&1024)},function(a){var b=0!==(this.Ca&1024);b!==a&&(D.h(a,"boolean",I,"wasSubGraphExpanded"),this.Ca^=1024,this.i("wasSubGraphExpanded",b,a))});D.defineProperty(I,{sK:"subGraphExpandedChanged"},function(){return this.Dt},function(a){var b=this.Dt;b!==a&&(null!==a&&D.h(a,"function",I,"subGraphExpandedChanged"),this.Dt=a,this.i("subGraphExpandedChanged",b,a))});
I.prototype.move=function(a){var b=this.position,c=b.x;isNaN(c)&&(c=0);b=b.y;isNaN(b)&&(b=0);var c=a.x-c,b=a.y-b,d=D.Fb(c,b);G.prototype.move.call(this,a);for(a=this.mq().j;a.next();){var e=a.value;e instanceof J&&(e.Xj=!0)}for(a.reset();a.next();)if(e=a.value,!(e instanceof J||e instanceof G&&e.Kf)){var g=e.position,h=e.location;g.F()?(d.x=g.x+c,d.y=g.y+b,e.position=d):h.F()&&(d.x=h.x+c,d.y=h.y+b,e.location=d)}for(a.reset();a.next();)e=a.value,e instanceof J&&(e.Tf||e.aa!==this&&e.ba!==this)&&(g=
e.position,d.x=g.x+c,d.y=g.y+b,e.move(d),e.Xj=!1,Mi(e)&&e.ec());D.A(d)};D.defineProperty(I,{no:null},function(){return 0!==(this.Ca&32768)},function(a){0!==(this.Ca&32768)!==a&&(this.Ca^=32768)});function Xj(){P.call(this);this.sf=Sd;this.nt=new C(NaN,NaN,NaN,NaN)}D.Sa(Xj,P);D.ka("Placeholder",Xj);Xj.prototype.cloneProtected=function(a){P.prototype.cloneProtected.call(this,a);a.sf=this.sf.V();a.nt=this.nt.copy()};
Xj.prototype.Ok=function(a){if(null===this.background&&null===this.fm)return!1;var b=this.Ga;return Vb(0,0,b.width,b.height,a.x,a.y)};
Xj.prototype.io=function(){var a=this.Y;null!==a&&(a instanceof I||a instanceof da)||D.k("Placeholder is not inside a Group or Adornment.");if(a instanceof I){var b=this.computeBorder(this.nt),c=this.ed;Cb(c,b.width||0,b.height||0);Jo(this,0,0,c.width,c.height);for(var c=a.Oc,d=!1;c.next();)if(c.value.isVisible()){d=!0;break}!d||isNaN(b.x)||isNaN(b.y)||(c=new N,c.uo(b,a.Nf),a.location=new N(c.x,c.y))}else{var b=this.Ea,c=this.ed,d=this.padding,e=d.left+d.right,g=d.top+d.bottom;if(b.F())Cb(c,b.width+
e||0,b.height+g||0),Jo(this,-d.left,-d.top,c.width,c.height);else{var h=a.Bb,k=h.eb(ec,D.P()),b=D.vg(k.x,k.y,0,0);b.Mi(h.eb(vc,k));b.Mi(h.eb(kc,k));b.Mi(h.eb(tc,k));a.hj.n(b.x,b.y);Cb(c,b.width+e||0,b.height+g||0);Jo(this,-d.left,-d.top,c.width,c.height);D.A(k);D.Kb(b)}}};Xj.prototype.yj=function(a,b,c,d){var e=this.Z;e.x=a;e.y=b;e.width=c;e.height=d};
Xj.prototype.computeBorder=function(a){var b=this.Y;if(b instanceof I){var c=b;if(c.iI&&this.nt.F()){var d=c.g;if(null!==d&&(d=d.hb,d instanceof Uh&&!d.Lr&&null!==d.hc&&!d.hc.contains(c)))return a.assign(this.nt),a}}var c=D.Qf(),d=this.computeMemberBounds(c),e=this.padding;a.n(d.x-e.left,d.y-e.top,Math.max(d.width+e.left+e.right,0),Math.max(d.height+e.top+e.bottom,0));D.Kb(c);b instanceof I&&(c=b,c.jI&&c.location.F()&&a.Mi(c.location));return a};
Xj.prototype.computeMemberBounds=function(a){if(!(this.Y instanceof I))return a.n(0,0,0,0),a;for(var b=this.Y,c=Infinity,d=Infinity,e=-Infinity,g=-Infinity,h=b.Oc;h.next();){var k=h.value;if(k.isVisible()){if(k instanceof J){var l=k;if(!b.zx)continue;if(Mm(l))continue;if(l.aa===b||l.ba===b)continue}k=k.Z;k.left<c&&(c=k.left);k.top<d&&(d=k.top);k.right>e&&(e=k.right);k.bottom>g&&(g=k.bottom)}}isFinite(c)&&isFinite(d)?a.n(c,d,e-c,g-d):(b=b.location,c=this.padding,a.n(b.x+c.left,b.y+c.top,0,0));return a};
D.defineProperty(Xj,{padding:"padding"},function(){return this.sf},function(a){"number"===typeof a?a=new Lb(a):D.l(a,Lb,Xj,"padding");var b=this.sf;b.O(a)||(this.sf=a=a.V(),this.i("padding",b,a))});function $g(){0<arguments.length&&D.xd($g);D.vc(this);this.Lz=this.ia=null;this.Gl=this.gs=!0;this.ns=!1;this.er=(new N(0,0)).freeze();this.js=this.ks=!0;this.$C="";this.ms=!1;this.Yz=null}D.ka("Layout",$g);
$g.prototype.cloneProtected=function(a){a.gs=this.gs;a.Gl=this.Gl;a.ns=this.ns;a.er.assign(this.er);a.ks=this.ks;a.js=this.js;a.$C=this.$C;a.ms=!0};$g.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};$g.prototype.qc=function(a){D.Yj(this,a)};$g.prototype.toString=function(){var a=D.vf(Object.getPrototypeOf(this)),a=a+"(";null!==this.group&&(a+=" in "+this.group);null!==this.g&&(a+=" for "+this.g);return a+")"};
D.defineProperty($g,{g:"diagram"},function(){return this.ia},function(a){null!==a&&D.l(a,E,$g,"diagram");this.ia=a});D.defineProperty($g,{group:"group"},function(){return this.Lz},function(a){this.Lz!==a&&(null!==a&&D.l(a,I,$g,"group"),this.Lz=a,null!==a&&(this.ia=a.g))});D.defineProperty($g,{pJ:"isOngoing"},function(){return this.gs},function(a){this.gs!==a&&(D.h(a,"boolean",$g,"isOngoing"),this.gs=a)});
D.defineProperty($g,{bG:"isInitial"},function(){return this.Gl},function(a){D.h(a,"boolean",$g,"isInitial");this.Gl=a;a||(this.ms=!0)});D.defineProperty($g,{by:"isViewportSized"},function(){return this.ns},function(a){this.ns!==a&&(D.h(a,"boolean",$g,"isViewportSized"),(this.ns=a)&&this.N())});D.defineProperty($g,{Fu:"isRouting"},function(){return this.ks},function(a){this.ks!==a&&(D.h(a,"boolean",$g,"isRouting"),this.ks=a)});
D.defineProperty($g,{dG:"isRealtime"},function(){return this.js},function(a){this.js!==a&&(D.h(a,"boolean",$g,"isRealtime"),this.js=a)});D.defineProperty($g,{zf:"isValidLayout"},function(){return this.ms},function(a){this.ms!==a&&(D.h(a,"boolean",$g,"isValidLayout"),this.ms=a,a||(a=this.g,null!==a&&(a.tl=!0)))});$g.prototype.invalidateLayout=$g.prototype.N=function(){if(this.zf){var a=this.g;if(null!==a&&!a.la.jb){var b=a.Wa;!b.np&&(b.yf&&b.Yh(),this.pJ&&a.$n||this.bG&&!a.$n)&&(this.zf=!1,a.Ve())}}};
D.defineProperty($g,{network:"network"},function(){return this.Yz},function(a){var b=this.Yz;b!==a&&(null!==a&&D.l(a,ua,$g,"network"),null!==b&&(b.Zb=null),this.Yz=a,null!==a&&(a.Zb=this))});$g.prototype.createNetwork=function(){return new ua};$g.prototype.makeNetwork=function(a){var b=this.createNetwork();b.Zb=this;a instanceof E?(b.Lk(a.rg,!0),b.Lk(a.links,!0)):a instanceof I?b.Lk(a.Oc):b.Lk(a.j);return b};
$g.prototype.updateParts=function(){var a=this.g;if(null===a&&null!==this.network)for(var b=this.network.vertexes.j;b.next();){var c=b.value.Yc;if(null!==c&&(a=c.g,null!==a))break}this.zf=!0;try{null!==a&&a.Nb("Layout"),this.commitLayout()}finally{null!==a&&a.hd("Layout")}};$g.prototype.commitLayout=function(){for(var a=this.network.vertexes.j;a.next();)a.value.commit();if(this.Fu)for(a=this.network.edges.j;a.next();)a.value.commit()};
$g.prototype.doLayout=function(a){null===a&&D.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");var b=new L(F);a instanceof E?(rs(this,b,a.rg,!0,this.no,!0,!1,!0),rs(this,b,a.Ii,!0,this.no,!0,!1,!0)):a instanceof I?rs(this,b,a.Oc,!1,this.no,!0,!1,!0):b.Tc(a.j);var c=b.count;if(0<c){a=this.g;null!==a&&a.Nb("Layout");for(var c=Math.ceil(Math.sqrt(c)),d=this.Ce.x,e=d,g=this.Ce.y,h=0,k=0,b=b.j;b.next();){var l=b.value;l.Re();var m=l.Fa,n=m.width,
m=m.height;l.moveTo(e,g);l instanceof I&&(l.no=!1);e+=Math.max(n,50)+20;k=Math.max(k,Math.max(m,50));h>=c-1?(h=0,e=d,g+=k+20,k=0):h++}null!==a&&a.hd("Layout")}this.zf=!0};$g.prototype.no=function(a){return!a.location.F()||a instanceof I&&a.no?!0:!1};
function rs(a,b,c,d,e,g,h,k){for(c=c.j;c.next();){var l=c.value;d&&!l.wq||null!==e&&!e(l)||!l.canLayout()||(g&&l instanceof G?l.Kf||(l instanceof I?null===l.Zb?rs(a,b,l.Oc,!1,e,g,h,k):(ss(l),b.add(l)):(ss(l),b.add(l))):h&&l instanceof J?b.add(l):!k||!l.pe()||l instanceof G||(ss(l),b.add(l)))}}function ss(a){var b=a.Z;(0===b.width||0===b.height||isNaN(b.width)||isNaN(b.height))&&a.Re()}
$g.prototype.collectParts=$g.prototype.dI=function(a){var b=new L(F);a instanceof E?(rs(this,b,a.rg,!0,null,!0,!0,!0),rs(this,b,a.links,!0,null,!0,!0,!0),rs(this,b,a.Ii,!0,null,!0,!0,!0)):a instanceof I?rs(this,b,a.Oc,!1,null,!0,!0,!0):rs(this,b,a.j,!1,null,!0,!0,!0);return b};D.defineProperty($g,{Ce:"arrangementOrigin"},function(){return this.er},function(a){D.l(a,N,$g,"arrangementOrigin");this.er.O(a)||(this.er.assign(a),this.N())});
$g.prototype.initialOrigin=function(a){var b=this.group;if(null!==b){var c=b.position.copy();(isNaN(c.x)||isNaN(c.y))&&c.set(a);b=b.placeholder;null!==b&&(c=b.eb(ec),(isNaN(c.x)||isNaN(c.y))&&c.set(a),c.x+=b.padding.left,c.y+=b.padding.top);return c}return a};
function ua(){D.vc(this);this.ve=null;if(this.vertexes)for(var a=this.vertexes.j;a.next();){var b=a.value;b.clear();b.network=null}if(this.edges)for(a=this.edges.j;a.next();)b=a.value,b.clear(),b.network=null;this.vertexes=new L(va);this.edges=new L(wa);this.oy=new oa(G,va);this.iy=new oa(J,wa)}D.ka("LayoutNetwork",ua);
ua.prototype.clear=function(){if(this.vertexes)for(var a=this.vertexes.j;a.next();){var b=a.value;b.clear();b.network=null}if(this.edges)for(a=this.edges.j;a.next();)b=a.value,b.clear(),b.network=null;this.vertexes=new L(va);this.edges=new L(wa);this.oy=new oa(G,va);this.iy=new oa(J,wa)};
ua.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutNetwork"+(null!==this.Zb?"("+this.Zb.toString()+")":"");if(0>=a)return b;b+=" vertexes: "+this.vertexes.count+" edges: "+this.edges.count;if(1<a){for(var c=this.vertexes.j;c.next();)b+="\n    "+c.value.toString(a-1);for(c=this.edges.j;c.next();)b+="\n    "+c.value.toString(a-1)}return b};D.defineProperty(ua,{Zb:"layout"},function(){return this.ve},function(a){v&&null!==a&&D.l(a,$g,ua,"layout");this.ve=a});ua.prototype.createVertex=function(){return new va};
ua.prototype.createEdge=function(){return new wa};
ua.prototype.addParts=ua.prototype.Lk=function(a,b,c){if(null!==a){void 0===b&&(b=!1);D.h(b,"boolean",ua,"addParts:toplevelonly");void 0===c&&(c=null);null===c&&(c=function(a){if(a instanceof G)return!a.Kf;if(a instanceof J){var b=a.aa;if(null===b||b.Kf)return!1;a=a.ba;return null===a||a.Kf?!1:!0}return!1});for(a=a.j;a.next();){var d=a.value;if(d instanceof G&&(!b||d.wq)&&d.canLayout()&&c(d))if(d instanceof I&&null===d.Zb)this.Lk(d.Oc,!1);else if(null===this.Sn(d)){var e=this.createVertex();e.Yc=
d;this.dm(e)}}for(a.reset();a.next();)if(d=a.value,d instanceof J&&(!b||d.wq)&&d.canLayout()&&c(d)&&null===this.Kx(d)){var g=d.aa,e=d.ba;null!==g&&null!==e&&g!==e&&(g=this.findGroupVertex(g),e=this.findGroupVertex(e),null!==g&&null!==e&&this.Aq(g,e,d))}}};ua.prototype.findGroupVertex=function(a){if(null===a)return null;var b=a.findVisibleNode();if(null===b)return null;a=this.Sn(b);if(null!==a)return a;for(b=b.Ka;null!==b;){a=this.Sn(b);if(null!==a)return a;b=b.Ka}return null};
ua.prototype.addVertex=ua.prototype.dm=function(a){if(null!==a){v&&D.l(a,va,ua,"addVertex:vertex");this.vertexes.add(a);var b=a.Yc;null!==b&&this.oy.add(b,a);a.network=this}};ua.prototype.addNode=ua.prototype.Tt=function(a){if(null===a)return null;v&&D.l(a,G,ua,"addNode:node");var b=this.Sn(a);null===b&&(b=this.createVertex(),b.Yc=a,this.dm(b));return b};
ua.prototype.deleteVertex=ua.prototype.jF=function(a){if(null!==a&&(v&&D.l(a,va,ua,"deleteVertex:vertex"),ts(this,a))){for(var b=a.Ye,c=b.count-1;0<=c;c--){var d=b.ea(c);this.hq(d)}b=a.Qe;for(c=b.count-1;0<=c;c--)d=b.ea(c),this.hq(d)}};function ts(a,b){if(null===b)return!1;var c=a.vertexes.remove(b);c&&(a.oy.remove(b.Yc),b.network=null);return c}ua.prototype.deleteNode=function(a){null!==a&&(v&&D.l(a,G,ua,"deleteNode:node"),a=this.Sn(a),null!==a&&this.jF(a))};
ua.prototype.findVertex=ua.prototype.Sn=function(a){if(null===a)return null;v&&D.l(a,G,ua,"findVertex:node");return this.oy.oa(a)};ua.prototype.addEdge=ua.prototype.Vp=function(a){if(null!==a){v&&D.l(a,wa,ua,"addEdge:edge");this.edges.add(a);var b=a.link;null!==b&&null===this.Kx(b)&&this.iy.add(b,a);b=a.toVertex;null!==b&&b.ME(a);b=a.fromVertex;null!==b&&b.KE(a);a.network=this}};
ua.prototype.addLink=function(a){if(null===a)return null;v&&D.l(a,J,ua,"addLink:link");var b=a.aa,c=a.ba,d=this.Kx(a);null===d?(d=this.createEdge(),d.link=a,null!==b&&(d.fromVertex=this.Tt(b)),null!==c&&(d.toVertex=this.Tt(c)),this.Vp(d)):(d.fromVertex=null!==b?this.Tt(b):null,d.toVertex=null!==c?this.Tt(c):null);return d};ua.prototype.deleteEdge=ua.prototype.hq=function(a){if(null!==a){v&&D.l(a,wa,ua,"deleteEdge:edge");var b=a.toVertex;null!==b&&b.iF(a);b=a.fromVertex;null!==b&&b.hF(a);us(this,a)}};
function us(a,b){null!==b&&a.edges.remove(b)&&(a.iy.remove(b.link),b.network=null)}ua.prototype.deleteLink=function(a){null!==a&&(v&&D.l(a,J,ua,"deleteLink:link"),a=this.Kx(a),null!==a&&this.hq(a))};ua.prototype.findEdge=ua.prototype.Kx=function(a){if(null===a)return null;v&&D.l(a,J,ua,"findEdge:link");return this.iy.oa(a)};
ua.prototype.linkVertexes=ua.prototype.Aq=function(a,b,c){if(null===a||null===b)return null;v&&(D.l(a,va,ua,"linkVertexes:fromVertex"),D.l(b,va,ua,"linkVertexes:toVertex"),null!==c&&D.l(c,J,ua,"linkVertexes:link"));if(a.network===this&&b.network===this){var d=this.createEdge();d.link=c;d.fromVertex=a;d.toVertex=b;this.Vp(d);return d}return null};
ua.prototype.reverseEdge=ua.prototype.Dy=function(a){if(null!==a){v&&D.l(a,wa,ua,"reverseEdge:edge");var b=a.fromVertex,c=a.toVertex;null!==b&&null!==c&&(b.hF(a),c.iF(a),a.Dy(),b.ME(a),c.KE(a))}};ua.prototype.deleteSelfEdges=ua.prototype.Fx=function(){for(var a=D.nb(),b=this.edges.j;b.next();){var c=b.value;c.fromVertex===c.toVertex&&a.push(c)}b=a.length;for(c=0;c<b;c++)this.hq(a[c]);D.ya(a)};
ua.prototype.deleteArtificialVertexes=function(){for(var a=D.nb(),b=this.vertexes.j;b.next();){var c=b.value;null===c.Yc&&a.push(c)}for(var c=a.length,d=0;d<c;d++)this.jF(a[d]);b=D.nb();for(c=this.edges.j;c.next();)d=c.value,null===d.link&&b.push(d);c=b.length;for(d=0;d<c;d++)this.hq(b[d]);D.ya(a);D.ya(b)};function vs(a){for(var b=D.nb(),c=a.edges.j;c.next();){var d=c.value;null!==d.fromVertex&&null!==d.toVertex||b.push(d)}c=b.length;for(d=0;d<c;d++)a.hq(b[d]);D.ya(b)}
ua.prototype.splitIntoSubNetworks=ua.prototype.rK=function(){this.deleteArtificialVertexes();vs(this);this.Fx();for(var a=new K(ua),b=!0;b;)for(var b=!1,c=this.vertexes.j;c.next();){var d=c.value;if(0<d.Ye.count||0<d.Qe.count){b=this.Zb.createNetwork();a.add(b);ws(this,b,d);b=!0;break}}a.sort(function(a,b){return null===a||null===b||a===b?0:b.vertexes.count-a.vertexes.count});return a};
function ws(a,b,c){if(null!==c&&c.network!==b){ts(a,c);b.dm(c);for(var d=c.uc;d.next();){var e=d.value;e.network!==b&&(us(a,e),b.Vp(e),ws(a,b,e.fromVertex))}for(d=c.sc;d.next();)c=d.value,c.network!==b&&(us(a,c),b.Vp(c),ws(a,b,c.toVertex))}}ua.prototype.findAllParts=function(){for(var a=new L(F),b=this.vertexes.j;b.next();)a.add(b.value.Yc);for(b=this.edges.j;b.next();)a.add(b.value.link);return a};
function va(){D.vc(this);this.network=null;this.Q=(new C(0,0,10,10)).freeze();this.W=(new N(5,5)).freeze();this.Ed=this.Pd=null;this.Ye=new K(wa);this.Qe=new K(wa)}D.ka("LayoutVertex",va);va.prototype.clear=function(){this.Ed=this.Pd=null;this.Ye=new K(wa);this.Qe=new K(wa)};
va.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutVertex#"+D.Jd(this);if(0<a&&(b+=null!==this.Yc?"("+this.Yc.toString()+")":"",1<a)){a="";for(var c=!0,d=this.Ye.j;d.next();){var e=d.value;c?c=!1:a+=",";a+=e.toString(0)}e="";c=!0;for(d=this.Qe.j;d.next();){var g=d.value;c?c=!1:e+=",";e+=g.toString(0)}b+=" sources: "+a+" destinations: "+e}return b};
D.defineProperty(va,{data:"data"},function(){return this.Pd},function(a){this.Pd=a;if(null!==a){var b=a.bounds;a=b.x;var c=b.y,d=b.width,b=b.height;this.W.n(d/2,b/2);this.Q.n(a,c,d,b)}});
D.defineProperty(va,{Yc:"node"},function(){return this.Ed},function(a){if(this.Ed!==a){v&&null!==a&&D.l(a,G,va,"node");this.Ed=a;a.Re();var b=a.Z,c=b.x,d=b.y,e=b.width,b=b.height;isNaN(c)&&(c=0);isNaN(d)&&(d=0);this.Q.n(c,d,e,b);if(!(a instanceof I)&&(a=a.Bf.eb(mc),a.F())){this.W.n(a.x-c,a.y-d);return}this.W.n(e/2,b/2)}});D.defineProperty(va,{qb:"bounds"},function(){return this.Q},function(a){this.Q.O(a)||(v&&D.l(a,C,va,"bounds"),this.Q.assign(a))});
D.defineProperty(va,{focus:"focus"},function(){return this.W},function(a){this.W.O(a)||(v&&D.l(a,N,va,"focus"),this.W.assign(a))});D.defineProperty(va,{pa:"centerX"},function(){return this.Q.x+this.W.x},function(a){var b=this.Q;b.x+this.W.x!==a&&(v&&D.p(a,va,"centerX"),b.Xa(),b.x=a-this.W.x,b.freeze())});D.defineProperty(va,{va:"centerY"},function(){return this.Q.y+this.W.y},function(a){var b=this.Q;b.y+this.W.y!==a&&(v&&D.p(a,va,"centerY"),b.Xa(),b.y=a-this.W.y,b.freeze())});
D.defineProperty(va,{pu:"focusX"},function(){return this.W.x},function(a){var b=this.W;b.x!==a&&(b.Xa(),b.x=a,b.freeze())});D.defineProperty(va,{qu:"focusY"},function(){return this.W.y},function(a){var b=this.W;b.y!==a&&(b.Xa(),b.y=a,b.freeze())});D.defineProperty(va,{x:"x"},function(){return this.Q.x},function(a){var b=this.Q;b.x!==a&&(b.Xa(),b.x=a,b.freeze())});D.defineProperty(va,{y:"y"},function(){return this.Q.y},function(a){var b=this.Q;b.y!==a&&(b.Xa(),b.y=a,b.freeze())});
D.defineProperty(va,{width:"width"},function(){return this.Q.width},function(a){var b=this.Q;b.width!==a&&(b.Xa(),b.width=a,b.freeze())});D.defineProperty(va,{height:"height"},function(){return this.Q.height},function(a){var b=this.Q;b.height!==a&&(b.Xa(),b.height=a,b.freeze())});
va.prototype.commit=function(){var a=this.Pd;if(null!==a){var b=this.qb,c=a.bounds;D.Qa(c)?(c.x=b.x,c.y=b.y,c.width=b.width,c.height=b.height):a.bounds=b.copy()}else if(a=this.Yc,null!==a){b=this.qb;if(!(a instanceof I)){var c=a.Z,d=a.Bf.eb(mc);if(c.F()&&d.F()){a.moveTo(b.x+this.pu-(d.x-c.x),b.y+this.qu-(d.y-c.y));return}}a.moveTo(b.x,b.y)}};va.prototype.addSourceEdge=va.prototype.ME=function(a){null!==a&&(v&&D.l(a,wa,va,"addSourceEdge:edge"),this.Ye.contains(a)||this.Ye.add(a))};
va.prototype.deleteSourceEdge=va.prototype.iF=function(a){null!==a&&(v&&D.l(a,wa,va,"deleteSourceEdge:edge"),this.Ye.remove(a))};va.prototype.addDestinationEdge=va.prototype.KE=function(a){null!==a&&(v&&D.l(a,wa,va,"addDestinationEdge:edge"),this.Qe.contains(a)||this.Qe.add(a))};va.prototype.deleteDestinationEdge=va.prototype.hF=function(a){null!==a&&(v&&D.l(a,wa,va,"deleteDestinationEdge:edge"),this.Qe.remove(a))};
D.w(va,{qK:"sourceVertexes"},function(){for(var a=new L(va),b=this.uc;b.next();)a.add(b.value.fromVertex);return a.j});D.w(va,{uI:"destinationVertexes"},function(){for(var a=new L(va),b=this.sc;b.next();)a.add(b.value.toVertex);return a.j});D.w(va,{vertexes:"vertexes"},function(){for(var a=new L(va),b=this.uc;b.next();)a.add(b.value.fromVertex);for(b=this.sc;b.next();)a.add(b.value.toVertex);return a.j});D.w(va,{uc:"sourceEdges"},function(){return this.Ye.j});D.w(va,{sc:"destinationEdges"},function(){return this.Qe.j});
D.w(va,{edges:"edges"},function(){for(var a=new K(wa),b=this.uc;b.next();)a.add(b.value);for(b=this.sc;b.next();)a.add(b.value);return a.j});D.w(va,{FI:"edgesCount"},function(){return this.Ye.count+this.Qe.count});var xs;va.standardComparer=xs=function(a,b){v&&D.l(a,va,va,"standardComparer:m");v&&D.l(b,va,va,"standardComparer:n");var c=a.Ed,d=b.Ed;return c?d?(c=c.text,d=d.text,c<d?-1:c>d?1:0):1:null!==d?-1:0};
va.smartComparer=function(a,b){v&&D.l(a,va,va,"smartComparer:m");v&&D.l(b,va,va,"smartComparer:n");if(null!==a){if(null!==b){var c=a.Ed,d=b.Ed;if(null!==c){if(null!==d){for(var c=c.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),d=d.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),e=0;e<c.length;e++)if(""!==d[e]&&void 0!==d[e]){var g=parseFloat(c[e]),h=parseFloat(d[e]);if(isNaN(g)){if(!isNaN(h))return 1;if(0!==c[e].localeCompare(d[e]))return c[e].localeCompare(d[e])}else{if(isNaN(h))return-1;
if(0!==g-h)return g-h}}else if(""!==c[e])return 1;return""!==d[e]&&void 0!==d[e]?-1:0}return 1}return null!==d?-1:0}return 1}return null!==b?-1:0};function wa(){D.vc(this);this.toVertex=this.fromVertex=this.link=this.data=this.network=null}D.ka("LayoutEdge",wa);wa.prototype.clear=function(){this.toVertex=this.fromVertex=this.link=this.data=null};
wa.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutEdge#"+D.Jd(this);0<a&&(b+=null!==this.link?"("+this.link.toString()+")":"",1<a&&(b+=" "+(this.fromVertex?this.fromVertex.toString():"null")+" --\x3e "+(this.toVertex?this.toVertex.toString():"null")));return b};wa.prototype.Dy=function(){var a=this.fromVertex;this.fromVertex=this.toVertex;this.toVertex=a};wa.prototype.commit=function(){};
wa.prototype.getOtherVertex=wa.prototype.QI=function(a){v&&D.l(a,va,wa,"getOtherVertex:v");return this.toVertex===a?this.fromVertex:this.fromVertex===a?this.toVertex:null};function Wn(){0<arguments.length&&D.xd(Wn);$g.call(this);this.by=!0;this.Ot=this.Pt=NaN;this.ck=(new Ca(NaN,NaN)).freeze();this.ui=(new Ca(10,10)).freeze();this.te=ys;this.zd=zs;this.ti=As;this.bi=Bs}D.Sa(Wn,$g);D.ka("GridLayout",Wn);
Wn.prototype.cloneProtected=function(a){$g.prototype.cloneProtected.call(this,a);a.Pt=this.Pt;a.Ot=this.Ot;a.ck.assign(this.ck);a.ui.assign(this.ui);a.te=this.te;a.zd=this.zd;a.ti=this.ti;a.bi=this.bi};Wn.prototype.qc=function(a){a.Oe===Wn?a===As||a===Cs||a===Ds||a===Es?this.sorting=a:a===zs||a===Fs?this.eg=a:a===ys||a===Gs?this.alignment=a:D.k("Unknown enum value: "+a):$g.prototype.qc.call(this,a)};
Wn.prototype.doLayout=function(a){null===a&&D.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");this.Ce=this.initialOrigin(this.Ce);var b=this.g;a=this.dI(a);for(var c=a.copy().j;c.next();){var d=c.value;if(d instanceof J){var e=d;if(null!==e.aa||null!==e.ba){a.remove(e);continue}}d.Re();if(d instanceof I)for(d=d.Oc;d.next();)a.remove(d.value)}e=a.oc();if(0!==e.length){switch(this.sorting){case Es:e.reverse();break;case As:e.sort(this.comparer);
break;case Cs:e.sort(this.comparer),e.reverse()}var g=this.GK;isNaN(g)&&(g=0);var h=this.tH,h=isNaN(h)&&null!==b?Math.max(b.vb.width-b.padding.left-b.padding.right,0):Math.max(this.tH,0);0>=g&&0>=h&&(g=1);a=this.spacing.width;isFinite(a)||(a=0);c=this.spacing.height;isFinite(c)||(c=0);null!==b&&b.Nb("Layout");d=[];switch(this.alignment){case Gs:var k=a,l=c,m=Math.max(this.Zp.width,1);if(!isFinite(m))for(var n=m=0;n<e.length;n++)var p=e[n],q=p.Fa,m=Math.max(m,q.width);var m=Math.max(m+k,1),r=Math.max(this.Zp.height,
1);if(!isFinite(r))for(n=r=0;n<e.length;n++)p=e[n],q=p.Fa,r=Math.max(r,q.height);for(var r=Math.max(r+l,1),s=this.eg,u=this.Ce.x,t=u,y=this.Ce.y,w=0,B=0,n=0;n<e.length;n++){var p=e[n],q=p.Fa,A=Math.ceil((q.width+k)/m)*m,O=Math.ceil((q.height+l)/r)*r,H=0;switch(s){case Fs:H=Math.abs(t-q.width);break;default:H=t+q.width}if(0<g&&w>g-1||0<h&&0<w&&H>h)d.push(new C(0,y,h+k,B)),w=0,t=u,y+=B,B=0;B=Math.max(B,O);O=0;switch(s){case Fs:O=-q.width;break;default:O=0}p.moveTo(t+O,y);switch(s){case Fs:t-=A;break;
default:t+=A}w++}d.push(new C(0,y,h+k,B));break;case ys:k=g;l=a;m=c;n=Math.max(this.Zp.width,1);p=y=A=0;q=D.P();for(g=0;g<e.length;g++)r=e[g],s=r.Fa,u=kr(r,r.Bf,r.Nf,q),A=Math.max(A,u.x),y=Math.max(y,s.width-u.x),p=Math.max(p,u.y);t=this.eg;switch(t){case Fs:A+=l;break;default:y+=l}var n=isFinite(n)?Math.max(n+l,1):Math.max(A+y,1),R=y=this.Ce.x,w=this.Ce.y,B=0;h>=A&&(h-=A);for(var A=O=0,H=Math.max(this.Zp.height,1),aa=p=0,V=!0,ea=D.P(),g=0;g<e.length;g++){r=e[g];s=r.Fa;u=kr(r,r.Bf,r.Nf,q);if(0<B)switch(t){case Fs:R=
(R-y-(s.width-u.x))/n;R=Eb(Math.round(R),R)?Math.round(R):Math.floor(R);R=R*n+y;break;default:R=(R-y+u.x)/n,R=Eb(Math.round(R),R)?Math.round(R):Math.ceil(R),R=R*n+y}else switch(t){case Fs:O=R+u.x+s.width;break;default:O=R-u.x}var la=0;switch(t){case Fs:la=-(R+u.x)+O;break;default:la=R+s.width-u.x-O}if(0<k&&B>k-1||0<h&&0<B&&la>h){d.push(new C(0,V?w-p:w,h+l,aa+p+m));for(R=0;R<B&&g!==B;R++){var la=e[g-B+R],Da=kr(la,la.Bf,la.Nf,ea);la.moveTo(la.position.x,la.position.y+p-Da.y)}aa+=m;w=V?w+aa:w+(aa+p);
B=aa=p=0;R=y;V=!1}R===y&&(A=t===Fs?Math.max(A,s.width-u.x):Math.min(A,-u.x));p=Math.max(p,u.y);aa=Math.max(aa,s.height-u.y);isFinite(H)&&(aa=Math.max(aa,Math.max(s.height,H)-u.y));V?r.moveTo(R-u.x,w-u.y):r.moveTo(R-u.x,w);switch(t){case Fs:R-=u.x+l;break;default:R+=s.width-u.x+l}B++}d.push(new C(0,w,h+l,(V?aa:aa+p)+m));for(R=0;R<B&&g!==B;R++)la=e[g-B+R],Da=kr(la,la.Bf,la.Nf,q),la.moveTo(la.position.x,la.position.y+p-Da.y);D.A(q);D.A(ea);if(t===Fs)for(g=0;g<d.length;g++)e=d[g],e.width+=A,e.x-=A;else for(g=
0;g<d.length;g++)e=d[g],e.x>A&&(e.width+=e.x-A,e.x=A)}for(k=g=h=e=0;k<d.length;k++)l=d[k],e=Math.min(e,l.x),h=Math.min(h,l.y),g=Math.max(g,l.x+l.width);this.eg===Fs?this.commitLayers(d,new N(e+a/2-(g+e),h-c/2)):this.commitLayers(d,new N(e-a/2,h-c/2));null!==b&&b.hd("Layout");this.zf=!0}};Wn.prototype.commitLayers=function(){};
D.defineProperty(Wn,{tH:"wrappingWidth"},function(){return this.Pt},function(a){this.Pt!==a&&(D.h(a,"number",Wn,"wrappingWidth"),0<a||isNaN(a))&&(this.Pt=a,this.by=isNaN(a),this.N())});D.defineProperty(Wn,{GK:"wrappingColumn"},function(){return this.Ot},function(a){this.Ot!==a&&(D.h(a,"number",Wn,"wrappingColumn"),0<a||isNaN(a))&&(this.Ot=a,this.N())});D.defineProperty(Wn,{Zp:"cellSize"},function(){return this.ck},function(a){D.l(a,Ca,Wn,"cellSize");this.ck.O(a)||(this.ck.assign(a),this.N())});
D.defineProperty(Wn,{spacing:"spacing"},function(){return this.ui},function(a){D.l(a,Ca,Wn,"spacing");this.ui.O(a)||(this.ui.assign(a),this.N())});D.defineProperty(Wn,{alignment:"alignment"},function(){return this.te},function(a){this.te!==a&&(D.Da(a,Wn,Wn,"alignment"),a===ys||a===Gs)&&(this.te=a,this.N())});D.defineProperty(Wn,{eg:"arrangement"},function(){return this.zd},function(a){this.zd!==a&&(D.Da(a,Wn,Wn,"arrangement"),a===zs||a===Fs)&&(this.zd=a,this.N())});
D.defineProperty(Wn,{sorting:"sorting"},function(){return this.ti},function(a){this.ti!==a&&(D.Da(a,Wn,Wn,"sorting"),a===Ds||a===Es||a===As||a===Cs)&&(this.ti=a,this.N())});D.defineProperty(Wn,{comparer:"comparer"},function(){return this.bi},function(a){this.bi!==a&&(D.h(a,"function",Wn,"comparer"),this.bi=a,this.N())});var Bs;Wn.standardComparer=Bs=function(a,b){v&&D.l(a,F,Wn,"standardComparer:a");v&&D.l(b,F,Wn,"standardComparer:b");var c=a.text,d=b.text;return c<d?-1:c>d?1:0};
Wn.smartComparer=function(a,b){v&&D.l(a,F,Wn,"standardComparer:a");v&&D.l(b,F,Wn,"standardComparer:b");if(null!==a){if(null!==b){for(var c=a.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),d=b.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),e=0;e<c.length;e++)if(""!==d[e]&&void 0!==d[e]){var g=parseFloat(c[e]),h=parseFloat(d[e]);if(isNaN(g)){if(!isNaN(h))return 1;if(0!==c[e].localeCompare(d[e]))return c[e].localeCompare(d[e])}else{if(isNaN(h))return-1;
if(0!==g-h)return g-h}}else if(""!==c[e])return 1;return""!==d[e]&&void 0!==d[e]?-1:0}return 1}return null!==b?-1:0};var Gs;Wn.Position=Gs=D.s(Wn,"Position",0);var ys;Wn.Location=ys=D.s(Wn,"Location",1);var zs;Wn.LeftToRight=zs=D.s(Wn,"LeftToRight",2);var Fs;Wn.RightToLeft=Fs=D.s(Wn,"RightToLeft",3);var Ds;Wn.Forward=Ds=D.s(Wn,"Forward",4);var Es;Wn.Reverse=Es=D.s(Wn,"Reverse",5);var As;Wn.Ascending=As=D.s(Wn,"Ascending",6);var Cs;Wn.Descending=Cs=D.s(Wn,"Descending",7);
function Hs(){0<arguments.length&&D.xd(Hs);$g.call(this);this.Cz=this.Yo=this.Qd=0;this.Mr=360;this.Bz=Is;this.zl=0;this.uC=new N;this.gD=Is;this.Gv=this.dg=this.xE=0;this.jx=new Js;this.Hv=this.ln=0;this.GH=600;this.bt=NaN;this.gr=1;this.Ct=0;this.Et=360;this.zd=Is;this.fa=Ks;this.ti=Ls;this.bi=xs;this.ui=6;this.Ps=Ms}D.Sa(Hs,$g);D.ka("CircularLayout",Hs);
Hs.prototype.cloneProtected=function(a){$g.prototype.cloneProtected.call(this,a);a.bt=this.bt;a.gr=this.gr;a.Ct=this.Ct;a.Et=this.Et;a.zd=this.zd;a.fa=this.fa;a.ti=this.ti;a.bi=this.bi;a.ui=this.ui;a.Ps=this.Ps};Hs.prototype.qc=function(a){if(a.Oe===Hs)if(a===Ns||a===Os||a===Ps||a===Qs||a===Ls)this.sorting=a;else if(a===Rs||a===Ss||a===Ks||a===Ts)this.direction=a;else if(a===Us||a===Vs||a===Is||a===Ws)this.eg=a;else{if(a===Xs||a===Ms)this.ny=a}else $g.prototype.qc.call(this,a)};
Hs.prototype.createNetwork=function(){return new Ys};
Hs.prototype.doLayout=function(a){null===a&&D.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));this.Ce=this.initialOrigin(this.Ce);a=this.network.vertexes;if(1>=a.count)1===a.count&&(a=a.first(),a.pa=0,a.va=0);else{var b=new K(Zs);b.Tc(a.j);a=new K(Zs);var c=new K(Zs),d;d=this.sort(b);var e=this.Bz,g=this.gD,h=this.Qd,k=this.Yo,l=this.Cz,m=this.Mr,b=this.zl,n=this.xE,p=this.dg,q=this.Gv,
e=this.eg,g=this.ny,h=this.WJ;if(!isFinite(h)||0>=h)h=NaN;k=this.YH;if(!isFinite(k)||0>=k)k=1;l=this.Me;isFinite(l)||(l=0);m=this.Df;if(!isFinite(m)||360<m||1>m)m=360;b=this.spacing;isFinite(b)||(b=NaN);e===Ws&&g===Xs?e=Is:e===Ws&&g!==Xs&&(g=Xs,e=this.eg);if((this.direction===Rs||this.direction===Ss)&&this.sorting!==Ls){for(var r=0;!(r>=d.length);r+=2){a.add(d.ea(r));if(r+1>=d.length)break;c.add(d.ea(r+1))}this.direction===Rs?(this.eg===Ws&&a.reverse(),d=new K(Zs),d.Tc(a),d.Tc(c)):(this.eg===Ws&&
c.reverse(),d=new K(Zs),d.Tc(c),d.Tc(a))}for(var s=d.length,u=n=0,r=0;r<d.length;r++){var p=l+m*u*(this.direction===Ks?1:-1)/s,t=d.ea(r).diameter;isNaN(t)&&(t=$s(d.ea(r),p));360>m&&(0===r||r===d.length-1)&&(t/=2);n+=t;u++}if(isNaN(h)||e===Ws){isNaN(b)&&(b=6);if(e!==Is&&e!==Ws){t=-Infinity;for(r=0;r<s;r++){var q=d.ea(r),y=d.ea(r===s-1?0:r+1);isNaN(q.diameter)&&$s(q,0);isNaN(y.diameter)&&$s(y,0);t=Math.max(t,(q.diameter+y.diameter)/2)}q=t+b;e===Us?(p=2*Math.PI/s,h=(t+b)/p):h=at(this,q*(360<=m?s:s-1),
k,l*Math.PI/180,m*Math.PI/180)}else h=at(this,n+(360<=m?s:s-1)*(e!==Ws?b:1.6*b),k,l*Math.PI/180,m*Math.PI/180);p=h*k}else if(p=h*k,u=bt(this,h,p,l*Math.PI/180,m*Math.PI/180),isNaN(b)){if(e===Is||e===Ws)b=(u-n)/(360<=m?s:s-1)}else if(e===Is||e===Ws)r=(u-n)/(360<=m?s:s-1),r<b?(h=at(this,n+b*(360<=m?s:s-1),k,l*Math.PI/180,m*Math.PI/180),p=h*k):b=r;else{t=-Infinity;for(r=0;r<s;r++)q=d.ea(r),y=d.ea(r===s-1?0:r+1),isNaN(q.diameter)&&$s(q,0),isNaN(y.diameter)&&$s(y,0),t=Math.max(t,(q.diameter+y.diameter)/
2);q=t+b;r=at(this,q*(360<=m?s:s-1),k,l*Math.PI/180,m*Math.PI/180);r>h?(h=r,p=h*k):q=u/(360<=m?s:s-1)}this.Bz=e;this.gD=g;this.Qd=h;this.Yo=k;this.Cz=l;this.Mr=m;this.zl=b;this.xE=n;this.dg=p;this.Gv=q;b=d;d=this.Bz;e=this.Qd;g=this.Cz;k=this.Mr;l=this.zl;m=this.dg;n=this.Gv;if(this.direction!==Rs&&this.direction!==Ss||d!==Ws)if(this.direction===Rs||this.direction===Ss){h=0;switch(d){case Vs:h=180*ct(this,e,m,g,n)/Math.PI;break;case Is:n=b=0;h=a.first();null!==h&&(b=$s(h,Math.PI/2));h=c.first();null!==
h&&(n=$s(h,Math.PI/2));h=180*ct(this,e,m,g,l+(b+n)/2)/Math.PI;break;case Us:h=k/b.length}if(this.direction===Rs){switch(d){case Vs:dt(this,a,g,Ts);break;case Is:et(this,a,g,Ts);break;case Us:ft(this,a,k/2,g,Ts)}switch(d){case Vs:dt(this,c,g+h,Ks);break;case Is:et(this,c,g+h,Ks);break;case Us:ft(this,c,k/2,g+h,Ks)}}else{switch(d){case Vs:dt(this,c,g,Ts);break;case Is:et(this,c,g,Ts);break;case Us:ft(this,c,k/2,g,Ts)}switch(d){case Vs:dt(this,a,g+h,Ks);break;case Is:et(this,a,g+h,Ks);break;case Us:ft(this,
a,k/2,g+h,Ks)}}}else switch(d){case Vs:dt(this,b,g,this.direction);break;case Is:et(this,b,g,this.direction);break;case Us:ft(this,b,k,g,this.direction);break;case Ws:gt(this,b,k,g,this.direction)}else gt(this,b,k,g-k/2,Ks)}this.updateParts();this.network=null;this.zf=!0};
function ft(a,b,c,d,e){var g=a.Mr,h=a.Qd;a=a.dg;d=d*Math.PI/180;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=d+(e===Ks?l*c/(360<=g?k:k-1):-(l*c)/k),n=b.ea(l),p=h*Math.tan(m)/a,p=Math.sqrt((h*h+a*a*p*p)/(1+p*p));n.pa=p*Math.cos(m);n.va=p*Math.sin(m);n.actualAngle=180*m/Math.PI}}
function et(a,b,c,d){var e=a.Qd,g=a.dg,h=a.zl;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=b.ea(l),n=b.ea(l===k-1?0:l+1),p=g*Math.sin(c);m.pa=e*Math.cos(c);m.va=p;m.actualAngle=180*c/Math.PI;isNaN(m.diameter)&&$s(m,0);isNaN(n.diameter)&&$s(n,0);m=ct(a,e,g,d===Ks?c:-c,(m.diameter+n.diameter)/2+h);c+=d===Ks?m:-m}}
function dt(a,b,c,d){var e=a.Qd,g=a.dg,h=a.Gv;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=b.ea(l);m.pa=e*Math.cos(c);m.va=g*Math.sin(c);m.actualAngle=180*c/Math.PI;m=ct(a,e,g,d===Ks?c:-c,h);c+=d===Ks?m:-m}}function gt(a,b,c,d,e){var g=a.Hv,g=a.Mr;a.ln=0;a.jx=new Js;if(360>c){for(g=d+(e===Ks?g:-g);0>g;)g+=360;g%=360;180<g&&(g-=360);g*=Math.PI/180;a.Hv=g;ht(a,b,c,d,e)}else it(a,b,c,d,e);a.jx.commit(b)}
function it(a,b,c,d,e){var g=a.Qd,h=a.zl,k=a.Yo,l=g*Math.cos(d*Math.PI/180),m=a.dg*Math.sin(d*Math.PI/180),n=b.oc();if(3===n.length)n[0].pa=g,n[0].va=0,n[1].pa=n[0].pa-n[0].width/2-n[1].width/2-h,n[1].y=n[0].y,n[2].pa=(n[0].pa+n[1].pa)/2,n[2].y=n[0].y-n[2].height-h;else if(4===n.length)n[0].pa=g,n[0].va=0,n[2].pa=-n[0].pa,n[2].va=n[0].va,n[1].pa=0,n[1].y=Math.min(n[0].y,n[2].y)-n[1].height-h,n[3].pa=0,n[3].y=Math.max(n[0].y+n[0].height+h,n[2].y+n[2].height+h);else{for(var g=D.P(),p=0;p<n.length;p++){n[p].pa=
l;n[p].va=m;if(p>=n.length-1)break;jt(a,l,m,n,p,e,g)||kt(a,l,m,n,p,e,g);l=g.x;m=g.y}D.A(g);a.ln++;if(!(23<a.ln)){var l=n[0].pa,m=n[0].va,g=n[n.length-1].pa,p=n[n.length-1].va,q=Math.abs(l-g)-((n[0].width+n[n.length-1].width)/2+h),r=Math.abs(m-p)-((n[0].height+n[n.length-1].height)/2+h),h=0;1>Math.abs(r)?Math.abs(l-g)<(n[0].width+n[n.length-1].width)/2&&(h=0):h=0<r?r:1>Math.abs(q)?0:q;q=!1;q=Math.abs(g)>Math.abs(p)?0<g!==m>p:0<p!==l<g;if(q=e===Ks?q:!q)h=-Math.abs(h),h=Math.min(h,-n[n.length-1].width),
h=Math.min(h,-n[n.length-1].height);a.jx.compare(h,n);1<Math.abs(h)&&(a.Qd=8>a.ln?a.Qd-h/(2*Math.PI):5>n.length&&10<h?a.Qd/2:a.Qd-(0<h?1.7:-2.3),a.dg=a.Qd*k,it(a,b,c,d,e))}}}
function ht(a,b,c,d,e){for(var g=a.Qd,h=a.dg,k=a.Yo,l=g*Math.cos(d*Math.PI/180),m=h*Math.sin(d*Math.PI/180),n=D.P(),p=b.oc(),q=0;q<p.length;q++){p[q].pa=l;p[q].va=m;if(q>=p.length-1)break;jt(a,l,m,p,q,e,n)||kt(a,l,m,p,q,e,n);l=n.x;m=n.y}D.A(n);a.ln++;if(!(23<a.ln)){l=Math.atan2(m,l);l=e===Ks?a.Hv-l:l-a.Hv;l=Math.abs(l)<Math.abs(l-2*Math.PI)?l:l-2*Math.PI;g=l*(g+h)/2;h=a.jx;if(Math.abs(g)<Math.abs(h.nq))for(h.nq=g,h.Bo=[],h.Vq=[],l=0;l<p.length;l++)h.Bo[l]=p[l].qb.x,h.Vq[l]=p[l].qb.y;1<Math.abs(g)&&
(a.Qd=8>a.ln?a.Qd-g/(2*Math.PI):a.Qd-(0<g?1.7:-2.3),a.dg=a.Qd*k,ht(a,b,c,d,e))}}function jt(a,b,c,d,e,g,h){var k=a.Qd,l=a.dg,m=0,n=0;a=(d[e].width+d[e+1].width)/2+a.zl;var p=!1;if(0<=c!==(g===Ks)){if(m=b+a,m>k){m=b-a;if(m<-k)return h.x=m,h.y=n,!1;p=!0}}else if(m=b-a,m<-k){m=b+a;if(m>k)return h.x=m,h.y=n,!1;p=!0}n=Math.sqrt(1-Math.min(1,m*m/(k*k)))*l;0>c!==p&&(n=-n);if(Math.abs(c-n)>(d[e].height+d[e+1].height)/2)return h.x=m,h.y=n,!1;h.x=m;h.y=n;return!0}
function kt(a,b,c,d,e,g,h){var k=a.Qd,l=a.dg,m=0,n=0;a=(d[e].height+d[e+1].height)/2+a.zl;d=!1;if(0<=b!==(g===Ks)){if(n=c-a,n<-l){n=c+a;if(n>l){h.x=m;h.y=n;return}d=!0}}else if(n=c+a,n>l){n=c-a;if(n<-l){h.x=m;h.y=n;return}d=!0}m=Math.sqrt(1-Math.min(1,n*n/(l*l)))*k;0>b!==d&&(m=-m);h.x=m;h.y=n}Hs.prototype.commitLayout=function(){this.commitNodes();this.Fu&&this.commitLinks()};
Hs.prototype.commitNodes=function(){var a=this.PH,b=null!==this.group&&null!==this.group.placeholder&&this.group.ld,c=b?this.group.location.copy():null;b?a=new N(0,0):(a.x=this.Ce.x+this.Qd,a.y=this.Ce.y+this.dg);for(var d=this.network.vertexes.j;d.next();){var e=d.value;e.x+=a.x;e.y+=a.y;e.commit()}b&&(this.group.Re(),a=this.group.position.copy(),b=this.group.location.copy(),c=c.Wj(b.Wj(a)),this.group.move(c),this.uC=c.Wj(a))};Hs.prototype.commitLinks=function(){for(var a=this.network.edges.j;a.next();)a.value.commit()};
function bt(a,b,c,d,e){var g=a.GH;if(.001>Math.abs(a.Yo-1))return void 0!==d&&void 0!==e?e*b:2*Math.PI*b;a=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c;for(var h=0,k=0,k=void 0!==d&&void 0!==e?e/(g+1):Math.PI/(2*(g+1)),l=0,m=0;m<=g;m++)l=void 0!==d&&void 0!==e?d+m*e/g:m*Math.PI/(2*g),l=Math.sin(l),h+=Math.sqrt(1-a*a*l*l)*k;return void 0!==d&&void 0!==e?(b>c?b:c)*h:4*(b>c?b:c)*h}function at(a,b,c,d,e){var g=0,g=void 0!==d&&void 0!==e?bt(a,1,c,d,e):bt(a,1,c);return b/g}
function ct(a,b,c,d,e){if(.001>Math.abs(a.Yo-1))return e/b;var g=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c,h=0;a=2*Math.PI/(700*a.network.vertexes.count);b>c&&(d+=Math.PI/2);for(var k=0;;k++){var l=Math.sin(d+k*a),h=h+(b>c?b:c)*Math.sqrt(1-g*g*l*l)*a;if(h>=e)return k*a}}
Hs.prototype.sort=function(a){switch(this.sorting){case Ps:break;case Qs:a.reverse();break;case Ns:a.sort(this.comparer);break;case Os:a.sort(this.comparer);a.reverse();break;case Ls:for(var b=[],c=0;c<a.length;c++)b.push(0);for(var d=new K(Zs),c=0;c<a.length;c++){var e=-1,g=-1;if(0===c)for(var h=0;h<a.length;h++){var k=a.ea(h).FI;k>e&&(e=k,g=h)}else for(h=0;h<a.length;h++)k=b[h],k>e&&(e=k,g=h);d.add(a.ea(g));b[g]=-1;g=a.ea(g);e=0;for(h=g.uc;h.next();)e=a.indexOf(h.value.fromVertex),0>e||0<=b[e]&&
b[e]++;for(g=g.sc;g.next();)e=a.indexOf(g.value.toVertex),0>e||0<=b[e]&&b[e]++}a=[];for(g=0;g<d.length;g++){b=d.ea(g);a[g]=[];for(var l=0,c=b.sc;c.next();)l=d.indexOf(c.value.toVertex),l!==g&&0>a[g].indexOf(l)&&a[g].push(l);for(b=b.uc;b.next();)l=d.indexOf(b.value.fromVertex),l!==g&&0>a[g].indexOf(l)&&a[g].push(l)}h=[];for(g=0;g<a.length;g++)h[g]=0;for(var b=[],k=[],m=[],c=[],e=new K(Zs),n=0,g=0;g<a.length;g++){var p=a[g].length;if(1===p)c.push(g);else if(0===p)e.add(d.ea(g));else{if(0===n)b.push(g);
else{for(var q=Infinity,r=Infinity,s=-1,u=[],p=0;p<b.length;p++)0>a[b[p]].indexOf(b[p===b.length-1?0:p+1])&&u.push(p===b.length-1?0:p+1);if(0===u.length)for(p=0;p<b.length;p++)u.push(p);for(p=0;p<u.length;p++){var t=u[p],y,l=a[g];y=k;for(var w=m,B=h,A=t,O=b,H=0,R=0;R<y.length;R++){var aa=B[y[R]],V=B[w[R]],ea=0,la=0;aa<V?(ea=aa,la=V):(ea=V,la=aa);if(ea<A&&A<=la)for(aa=0;aa<l.length;aa++)V=l[aa],0>O.indexOf(V)||ea<B[V]&&B[V]<la||ea===B[V]||la===B[V]||H++;else for(aa=0;aa<l.length;aa++)V=l[aa],0>O.indexOf(V)||
ea<B[V]&&B[V]<la&&ea!==B[V]&&la!==B[V]&&H++}y=H;for(B=w=0;B<a[g].length;B++)l=b.indexOf(a[g][B]),0<=l&&(l=Math.abs(t-(l>=t?l+1:l)),w+=l<b.length+1-l?l:b.length+1-l);for(B=0;B<k.length;B++)l=h[k[B]],A=h[m[B]],l>=t&&l++,A>=t&&A++,l>A&&(O=A,A=l,l=O),A-l<(b.length+2)/2===(l<t&&t<=A)&&w++;if(y<q||y===q&&w<r)q=y,r=w,s=t}b.splice(s,0,g);for(p=0;p<b.length;p++)h[b[p]]=p;for(p=0;p<a[g].length;p++)q=a[g][p],0<=b.indexOf(q)&&(k.push(g),m.push(q))}n++}}g=!1;for(h=b.length;;){g=!0;for(k=0;k<c.length;k++)if(m=
c[k],n=a[m][0],l=b.indexOf(n),0<=l){for(r=p=0;r<a[n].length;r++)q=a[n][r],q=b.indexOf(q),0>q||q===l||(s=q>l?q-l:l-q,p+=q<l!==s>h-s?1:-1);b.splice(0>p?l:l+1,0,m);c.splice(k,1);k--}else g=!1;if(g)break;else b.push(c[0]),c.splice(0,1)}for(g=0;g<b.length;g++)e.add(d.ea(b[g]));return e;default:D.k("Invalid sorting type.")}return a};D.defineProperty(Hs,{WJ:"radius"},function(){return this.bt},function(a){this.bt!==a&&(D.h(a,"number",Hs,"radius"),0<a||isNaN(a))&&(this.bt=a,this.N())});
D.defineProperty(Hs,{YH:"aspectRatio"},function(){return this.gr},function(a){this.gr!==a&&(D.h(a,"number",Hs,"aspectRatio"),0<a&&(this.gr=a,this.N()))});D.defineProperty(Hs,{Me:"startAngle"},function(){return this.Ct},function(a){this.Ct!==a&&(D.h(a,"number",Hs,"startAngle"),this.Ct=a,this.N())});D.defineProperty(Hs,{Df:"sweepAngle"},function(){return this.Et},function(a){this.Et!==a&&(D.h(a,"number",Hs,"sweepAngle"),this.Et=0<a&&360>=a?a:360,this.N())});
D.defineProperty(Hs,{eg:"arrangement"},function(){return this.zd},function(a){this.zd!==a&&(D.Da(a,Hs,Hs,"arrangement"),a===Ws||a===Is||a===Vs||a===Us)&&(this.zd=a,this.N())});D.defineProperty(Hs,{direction:"direction"},function(){return this.fa},function(a){this.fa!==a&&(D.Da(a,Hs,Hs,"direction"),a===Ks||a===Ts||a===Rs||a===Ss)&&(this.fa=a,this.N())});
D.defineProperty(Hs,{sorting:"sorting"},function(){return this.ti},function(a){this.ti!==a&&(D.Da(a,Hs,Hs,"sorting"),a===Ps||a===Qs||a===Ns||Os||a===Ls)&&(this.ti=a,this.N())});D.defineProperty(Hs,{comparer:"comparer"},function(){return this.bi},function(a){this.bi!==a&&(D.h(a,"function",Hs,"comparer"),this.bi=a,this.N())});D.defineProperty(Hs,{spacing:"spacing"},function(){return this.ui},function(a){this.ui!==a&&(D.h(a,"number",Hs,"spacing"),this.ui=a,this.N())});
D.defineProperty(Hs,{ny:"nodeDiameterFormula"},function(){return this.Ps},function(a){this.Ps!==a&&(D.Da(a,Hs,Hs,"nodeDiameterFormula"),a===Ms||a===Xs)&&(this.Ps=a,this.N())});D.w(Hs,{JK:"actualXRadius"},function(){return this.Qd});D.w(Hs,{KK:"actualYRadius"},function(){return this.dg});D.w(Hs,{IK:"actualSpacing"},function(){return this.zl});D.w(Hs,{PH:"actualCenter"},function(){return this.uC});var Is;Hs.ConstantSpacing=Is=D.s(Hs,"ConstantSpacing",0);var Vs;
Hs.ConstantDistance=Vs=D.s(Hs,"ConstantDistance",1);var Us;Hs.ConstantAngle=Us=D.s(Hs,"ConstantAngle",2);var Ws;Hs.Packed=Ws=D.s(Hs,"Packed",3);var Ks;Hs.Clockwise=Ks=D.s(Hs,"Clockwise",4);var Ts;Hs.Counterclockwise=Ts=D.s(Hs,"Counterclockwise",5);var Rs;Hs.BidirectionalLeft=Rs=D.s(Hs,"BidirectionalLeft",6);var Ss;Hs.BidirectionalRight=Ss=D.s(Hs,"BidirectionalRight",7);var Ps;Hs.Forwards=Ps=D.s(Hs,"Forwards",8);var Qs;Hs.Reverse=Qs=D.s(Hs,"Reverse",9);var Ns;Hs.Ascending=Ns=D.s(Hs,"Ascending",10);
var Os;Hs.Descending=Os=D.s(Hs,"Descending",11);var Ls;Hs.Optimized=Ls=D.s(Hs,"Optimized",12);var Ms;Hs.Pythagorean=Ms=D.s(Hs,"Pythagorean",13);var Xs;Hs.Circular=Xs=D.s(Hs,"Circular",14);function Js(){this.nq=-Infinity;this.Vq=this.Bo=null}Js.prototype.compare=function(a,b){if(0<a&&0>this.nq||Math.abs(a)<Math.abs(this.nq)&&!(0>a&&0<this.nq)){this.nq=a;this.Bo=[];this.Vq=[];for(var c=0;c<b.length;c++)this.Bo[c]=b[c].qb.x,this.Vq[c]=b[c].qb.y}};
Js.prototype.commit=function(a){if(null!==this.Bo&&null!==this.Vq)for(var b=0;b<this.Bo.length;b++){var c=a.ea(b);c.x=this.Bo[b];c.y=this.Vq[b]}};function Ys(){ua.call(this)}D.Sa(Ys,ua);D.ka("CircularNetwork",Ys);Ys.prototype.createVertex=function(){return new Zs};Ys.prototype.createEdge=function(){return new lt};function Zs(){va.call(this);this.actualAngle=this.diameter=NaN}D.Sa(Zs,va);D.ka("CircularVertex",Zs);
function $s(a,b){var c=a.network;if(null===c)return NaN;c=c.Zb;if(null===c)return NaN;if(c.eg===Ws)if(c.ny===Xs)a.diameter=Math.max(a.width,a.height);else{var c=Math.abs(Math.sin(b)),d=Math.abs(Math.cos(b));if(0===c)return a.width;if(0===d)return a.height;a.diameter=Math.min(a.height/c,a.width/d)}else a.diameter=c.ny===Xs?Math.max(a.width,a.height):Math.sqrt(a.width*a.width+a.height*a.height);return a.diameter}function lt(){wa.call(this)}D.Sa(lt,wa);D.ka("CircularEdge",lt);
function mt(){0<arguments.length&&D.xd(mt);$g.call(this);this.Vg=null;this.ps=0;this.xg=(new Ca(100,100)).freeze();this.fr=!1;this.si=!0;this.ai=!1;this.xp=100;this.Qr=1;this.gi=1E3;this.ct=Math;this.Vo=.05;this.Uo=50;this.So=150;this.To=0;this.Dr=10;this.Cr=5}D.Sa(mt,$g);D.ka("ForceDirectedLayout",mt);
mt.prototype.cloneProtected=function(a){$g.prototype.cloneProtected.call(this,a);a.xg.assign(this.xg);a.fr=this.fr;a.si=this.si;a.ai=this.ai;a.xp=this.xp;a.Qr=this.Qr;a.gi=this.gi;a.ct=this.ct;a.Vo=this.Vo;a.Uo=this.Uo;a.So=this.So;a.To=this.To;a.Dr=this.Dr;a.Cr=this.Cr};mt.prototype.createNetwork=function(){return new nt};
mt.prototype.doLayout=function(a){null===a&&D.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));a=this.nB;if(0<this.network.vertexes.count){this.network.Fx();for(var b=this.network.vertexes.j;b.next();){var c=b.value;c.charge=this.electricalCharge(c);c.mass=this.gravitationalMass(c)}for(b=this.network.edges.j;b.next();)c=b.value,c.stiffness=this.springStiffness(c),c.length=this.springLength(c);
this.uA();this.ps=0;if(this.needsClusterLayout()){b=this.network;for(c=b.rK().j;c.next();){this.network=c.value;for(var d=this.network.vertexes.j;d.next();){var e=d.value;e.sg=e.vertexes.count;e.dl=1;e.In=null;e.Xh=null}ot(this,0,a)}this.network=b;c.reset();v&&D.l(b,nt,mt,"arrangeConnectedGraphs:singletons");for(var d=this.RE,g=c.count,h=!0,k=e=0,l=D.nb(),m=0;m<g+b.vertexes.count+2;m++)l[m]=null;g=0;c.reset();for(var n=D.Qf();c.next();)if(m=c.value,this.Mh(m,n),h)h=!1,e=n.x+n.width/2,k=n.y+n.height/
2,l[0]=new N(n.x+n.width+d.width,n.y),l[1]=new N(n.x,n.y+n.height+d.height),g=2;else{var p=pt(l,g,e,k,n.width,n.height,d),q=l[p],r=new N(q.x+n.width+d.width,q.y),s=new N(q.x,q.y+n.height+d.height);p+1<g&&l.splice(p+1,0,null);l[p]=r;l[p+1]=s;g++;p=q.x-n.x;q=q.y-n.y;for(m=m.vertexes.j;m.next();)r=m.value,r.pa+=p,r.va+=q}D.Kb(n);for(m=b.vertexes.j;m.next();)h=m.value,n=h.qb,2>g?(e=n.x+n.width/2,k=n.y+n.height/2,l[0]=new N(n.x+n.width+d.width,n.y),l[1]=new N(n.x,n.y+n.height+d.height),g=2):(p=pt(l,g,
e,k,n.width,n.height,d),q=l[p],r=new N(q.x+n.width+d.width,q.y),s=new N(q.x,q.y+n.height+d.height),p+1<g&&l.splice(p+1,0,null),l[p]=r,l[p+1]=s,g++,h.pa=q.x+h.width/2,h.va=q.y+h.height/2);D.ya(l);for(c.reset();c.next();){d=c.value;for(e=d.vertexes.j;e.next();)b.dm(e.value);for(d=d.edges.j;d.next();)b.Vp(d.value)}}qt(this,a);this.updateParts()}this.xp=a;this.network=null;this.zf=!0};
mt.prototype.needsClusterLayout=function(){if(3>this.network.vertexes.count)return!1;for(var a=0,b=0,c=this.network.vertexes.first().qb,d=this.network.vertexes.j;d.next();){if(d.value.qb.jg(c)&&(a++,2<a))return!0;if(10<b)break;b++}return!1};mt.prototype.Mh=function(a,b){for(var c=!0,d=a.vertexes.j;d.next();){var e=d.value;c?(c=!1,b.set(e.qb)):b.Zh(e.qb)}return b};
function At(a,b,c){v&&(D.p(b,mt,"computeClusterLayoutIterations:level"),D.p(c,mt,"computeClusterLayoutIterations:maxiter"));return Math.max(Math.min(a.network.vertexes.count,c*(b+1)/11),10)}
function ot(a,b,c){v&&(D.p(b,mt,"layoutClusters:level"),D.p(c,mt,"layoutClusters:maxiter"));if(hu(a,b)){var d=a.gi;a.gi*=1+1/(b+1);var e=iu(a,b),g=Math.max(0,At(a,b,c));a.nB+=g;ot(a,b+1,c);qt(a,g);ju(a,e,b);c=a.Vg;null===c?c=new K(ku):c.clear();c.Tc(e.vertexes);c.sort(function(a,b){return null===a||null===b||a===b?0:b.sg-a.sg});for(e=c.j;e.next();)lu(a,e.value,b);a.gi=d}}
function hu(a,b){v&&D.p(b,mt,"hasClusters:level");if(10<b||3>a.network.vertexes.count)return!1;null===a.Vg?a.Vg=new K(ku):a.Vg.clear();a.Vg.Tc(a.network.vertexes);var c=a.Vg;c.sort(function(a,b){return null===a||null===b||a===b?0:b.sg-a.sg});for(var d=c.count-1;0<=d&&1>=c.ea(d).sg;)d--;return 1<c.count-d}
function iu(a,b){v&&D.p(b,mt,"pushSubNetwork:level");for(var c=a.network,d=new nt,e=a.Vg.j;e.next();){var g=e.value;if(1<g.sg){d.dm(g);var h=new mu;h.Vy=g.sg;h.Wy=g.width;h.Uy=g.height;h.mC=g.W.x;h.nC=g.W.y;null===g.Xh&&(g.Xh=new K(mu));g.Xh.add(h);g.HB=g.Xh.count-1}else break}for(var k=c.edges.j;k.next();)if(h=k.value,e=h.fromVertex,g=h.toVertex,e.network===d&&g.network===d)d.Vp(h);else if(e.network===d){var l=e.In;null===l&&(l=new K(ku),e.In=l);l.add(g);e.sg--;e.dl+=g.dl}else g.network===d&&(l=
g.In,null===l&&(l=new K(ku),g.In=l),l.add(e),g.sg--,g.dl+=e.dl);for(e=d.edges.j;e.next();)g=e.value,g.length*=Math.max(1,pe((g.fromVertex.dl+g.toVertex.dl)/(4*b+1)));for(e=d.vertexes.j;e.next();)if(g=e.value,l=g.In,null!==l&&0<l.count&&(h=g.Xh.ea(g.Xh.count-1),h=h.Vy-g.sg,!(0>=h))){for(var m=0,n=0,p=l.count-h;p<l.count;p++){for(var q=l.ea(p),r=null,k=q.edges.j;k.next();){var s=k.value;if(s.QI(q)===g){r=s;break}}null!==r&&(n+=r.length,m+=q.width*q.height)}k=g.pa;l=g.va;p=g.width;q=g.height;r=g.W;s=
p*q;1>s&&(s=1);m=pe((m+s+n*n*4/(h*h))/s);h=(m-1)*p/2;m=(m-1)*q/2;g.qb=new C(k-r.x-h,l-r.y-m,p+2*h,q+2*m);g.focus=new N(r.x+h,r.y+m)}a.network=d;return c}function ju(a,b,c){v&&(D.l(b,nt,mt,"popNetwork:oldnet"),D.p(c,mt,"popNetwork:level"));for(c=a.network.vertexes.j;c.next();){var d=c.value;d.network=b;if(null!==d.Xh){var e=d.Xh.ea(d.HB);d.sg=e.Vy;var g=e.mC,h=e.nC;d.qb=new C(d.pa-g,d.va-h,e.Wy,e.Uy);d.focus=new N(g,h);d.HB--}}for(c=a.network.edges.j;c.next();)c.value.network=b;a.network=b}
function lu(a,b,c){v&&(D.l(b,ku,mt,"surroundNode:oldnet"),D.p(c,mt,"surroundNode:level"));var d=b.In;if(null!==d&&0!==d.count){c=b.pa;var e=b.va,g=b.width,h=b.height;null!==b.Xh&&0<b.Xh.count&&(h=b.Xh.ea(0),g=h.Wy,h=h.Uy);for(var g=pe(g*g+h*h)/2,k=!1,l=h=0,m=0,n=b.vertexes.j;n.next();){var p=n.value;1>=p.sg?l++:(k=!0,m++,h+=Math.atan2(b.va-p.va,b.pa-p.pa))}if(0!==l)for(0<m&&(h/=m),m=b=0,b=k?2*Math.PI/(l+1):2*Math.PI/l,0===l%2&&(m=b/2),1<d.count&&d.sort(function(a,b){return null===a||null===b||a===
b?0:b.width*b.height-a.width*a.height}),k=0===l%2?0:1,d=d.j;d.next();)if(l=d.value,!(1<l.sg||a.isFixed(l))){n=null;for(p=l.edges.j;p.next();){n=p.value;break}var p=l.width,q=l.height,p=pe(p*p+q*q)/2,n=g+n.length+p,p=h+(b*(k/2>>1)+m)*(0===k%2?1:-1);l.pa=c+n*Math.cos(p);l.va=e+n*Math.sin(p);k++}}}
function pt(a,b,c,d,e,g,h){var k=9E19,l=-1,m=0;a:for(;m<b;m++){var n=a[m],p=n.x-c,q=n.y-d,p=p*p+q*q;if(p<k){for(q=m-1;0<=q;q--)if(a[q].y>n.y&&a[q].x-n.x<e+h.width)continue a;for(q=m+1;q<b;q++)if(a[q].x>n.x&&a[q].y-n.y<g+h.height)continue a;l=m;k=p}}return l}mt.prototype.uA=function(){if(this.comments)for(var a=this.network.vertexes.j;a.next();)this.addComments(a.value)};
mt.prototype.addComments=function(a){var b=a.Yc;if(null!==b)for(b=b.vF();b.next();){var c=b.value;if("Comment"===c.Gd&&c.isVisible()){var d=this.network.Sn(c);null===d&&(d=this.network.Tt(c));d.charge=this.qI;for(var c=null,e=d.sc;e.next();){var g=e.value;if(g.toVertex===a){c=g;break}}if(null===c)for(e=d.uc;e.next();)if(g=e.value,g.fromVertex===a){c=g;break}null===c&&(c=this.network.Aq(a,d,null));c.length=this.rI}}};
function nu(a,b){v&&(D.l(a,ku,mt,"getNodeDistance:vertexA"),D.l(b,ku,mt,"getNodeDistance:vertexB"));var c=a.Q,d=c.x,e=c.y,g=c.width,c=c.height,h=b.Q,k=h.x,l=h.y,m=h.width,h=h.height;return d+g<k?e>l+h?(d=d+g-k,e=e-l-h,pe(d*d+e*e)):e+c<l?(d=d+g-k,e=e+c-l,pe(d*d+e*e)):k-(d+g):d>k+m?e>l+h?(d=d-k-m,e=e-l-h,pe(d*d+e*e)):e+c<l?(d=d-k-m,e=e+c-l,pe(d*d+e*e)):d-(k+m):e>l+h?e-(l+h):e+c<l?l-(e+c):.1}
function qt(a,b){v&&D.p(b,mt,"performIterations:num");a.Vg=null;for(var c=a.ps+b;a.ps<c&&(a.ps++,ou(a)););a.Vg=null}
function ou(a){null===a.Vg&&(a.Vg=new K(ku),a.Vg.Tc(a.network.vertexes));var b=a.Vg.o;if(0>=b.length)return!1;var c=b[0];c.forceX=0;c.forceY=0;for(var d=c.pa,e=d,g=c.va,h=g,c=1;c<b.length;c++){var k=b[c];k.forceX=0;k.forceY=0;var l=k.pa,k=k.va,d=Math.min(d,l),e=Math.max(e,l),g=Math.min(g,k),h=Math.max(h,k)}(g=e-d>h-g)?b.sort(function(a,b){return null===a||null===b||a===b?0:a.pa-b.pa}):b.sort(function(a,b){return null===a||null===b||a===b?0:a.va-b.va});for(var h=a.gi,m=0,n=0,p=0,c=0;c<b.length;c++){var k=
b[c],l=k.Q,q=k.W,d=l.x+q.x,l=l.y+q.y,n=k.charge*a.electricalFieldX(d,l),p=k.charge*a.electricalFieldY(d,l),n=n+k.mass*a.gravitationalFieldX(d,l),p=p+k.mass*a.gravitationalFieldY(d,l);k.forceX+=n;k.forceY+=p;for(q=c+1;q<b.length;q++)if(e=b[q],e!==k){var r=e.Q,n=e.W,p=r.x+n.x,r=r.y+n.y;if(d-p>h||p-d>h){if(g)break}else if(l-r>h||r-l>h){if(!g)break}else{var s=nu(k,e);1>s?(n=a.xy,null===n&&(a.xy=n=new Ia(0)),m=n.random(),s=n.random(),d>p?(n=Math.abs(e.Q.right-k.Q.x),n=(1+n)*m):d<p?(n=Math.abs(e.Q.x-k.Q.right),
n=-(1+n)*m):(n=Math.max(e.width,k.width),n=(1+n)*m-n/2),l>r?(p=Math.abs(e.Q.bottom-k.Q.y),p=(1+p)*s):d<p?(p=Math.abs(e.Q.y-k.Q.bottom),p=-(1+p)*s):(p=Math.max(e.height,k.height),p=(1+p)*s-p/2)):(m=-(k.charge*e.charge)/(s*s),n=(p-d)/s*m,p=(r-l)/s*m);k.forceX+=n;k.forceY+=p;e.forceX-=n;e.forceY-=p}}}for(c=a.network.edges.j;c.next();)g=c.value,k=g.fromVertex,e=g.toVertex,l=k.Q,q=k.W,d=l.x+q.x,l=l.y+q.y,r=e.Q,n=e.W,p=r.x+n.x,r=r.y+n.y,s=nu(k,e),1>s?(n=a.xy,null===n&&(a.xy=n=new Ia(0)),m=n.random(),s=
n.random(),n=(d>p?1:-1)*(1+(e.width>k.width?e.width:k.width))*m,p=(l>r?1:-1)*(1+(e.height>k.height?e.height:k.height))*s):(m=g.stiffness*(s-g.length),n=(p-d)/s*m,p=(r-l)/s*m),k.forceX+=n,k.forceY+=p,e.forceX-=n,e.forceY-=p;c=0;d=Math.max(a.gi/20,50);for(e=0;e<b.length;e++)k=b[e],a.isFixed(k)?a.moveFixedVertex(k):(g=k.forceX,h=k.forceY,g<-d?g=-d:g>d&&(g=d),h<-d?h=-d:h>d&&(h=d),k.pa+=g,k.va+=h,c=Math.max(c,g*g+h*h));return c>a.sF*a.sF}mt.prototype.moveFixedVertex=function(){};
mt.prototype.commitLayout=function(){this.OB();this.commitNodes();this.Fu&&this.commitLinks()};mt.prototype.OB=function(){if(this.Oq)for(var a=this.network.edges.j;a.next();){var b=a.value.link;null!==b&&(b.Gb=Vc,b.Hb=Vc)}};mt.prototype.commitNodes=function(){var a=0,b=0;if(this.XH){var c=D.Qf();this.Mh(this.network,c);b=this.Ce;a=b.x-c.x;b=b.y-c.y;D.Kb(c)}for(var c=D.Qf(),d=this.network.vertexes.j;d.next();){var e=d.value;if(0!==a||0!==b)c.assign(e.qb),c.x+=a,c.y+=b,e.qb=c;e.commit()}D.Kb(c)};
mt.prototype.commitLinks=function(){for(var a=this.network.edges.j;a.next();)a.value.commit()};mt.prototype.springStiffness=function(a){a=a.stiffness;return isNaN(a)?this.Vo:a};mt.prototype.springLength=function(a){a=a.length;return isNaN(a)?this.Uo:a};mt.prototype.electricalCharge=function(a){a=a.charge;return isNaN(a)?this.So:a};mt.prototype.electricalFieldX=function(){return 0};mt.prototype.electricalFieldY=function(){return 0};
mt.prototype.gravitationalMass=function(a){a=a.mass;return isNaN(a)?this.To:a};mt.prototype.gravitationalFieldX=function(){return 0};mt.prototype.gravitationalFieldY=function(){return 0};mt.prototype.isFixed=function(a){return a.isFixed};D.w(mt,{pL:"currentIteration"},function(){return this.ps});D.defineProperty(mt,{RE:"arrangementSpacing"},function(){return this.xg},function(a){D.l(a,Ca,mt,"arrangementSpacing");this.xg.O(a)||(this.xg.assign(a),this.N())});
D.defineProperty(mt,{XH:"arrangesToOrigin"},function(){return this.fr},function(a){this.fr!==a&&(D.h(a,"boolean",mt,"arrangesToOrigin"),this.fr=a,this.N())});D.defineProperty(mt,{Oq:"setsPortSpots"},function(){return this.si},function(a){this.si!==a&&(D.h(a,"boolean",mt,"setsPortSpots"),this.si=a,this.N())});D.defineProperty(mt,{comments:"comments"},function(){return this.ai},function(a){this.ai!==a&&(D.h(a,"boolean",mt,"comments"),this.ai=a,this.N())});
D.defineProperty(mt,{nB:"maxIterations"},function(){return this.xp},function(a){this.xp!==a&&(D.h(a,"number",mt,"maxIterations"),0<=a&&(this.xp=a,this.N()))});D.defineProperty(mt,{sF:"epsilonDistance"},function(){return this.Qr},function(a){this.Qr!==a&&(D.h(a,"number",mt,"epsilonDistance"),0<a&&(this.Qr=a,this.N()))});D.defineProperty(mt,{OL:"infinityDistance"},function(){return this.gi},function(a){this.gi!==a&&(D.h(a,"number",mt,"infinityDistance"),1<a&&(this.gi=a,this.N()))});
D.defineProperty(mt,{xy:"randomNumberGenerator"},function(){return this.ct},function(a){this.ct!==a&&(null!==a&&"function"!==typeof a.random&&D.k('ForceDirectedLayout.randomNumberGenerator must have a "random()" function on it: '+a),this.ct=a)});D.defineProperty(mt,{BL:"defaultSpringStiffness"},function(){return this.Vo},function(a){this.Vo!==a&&(D.h(a,"number",mt,"defaultSpringStiffness"),this.Vo=a,this.N())});
D.defineProperty(mt,{AL:"defaultSpringLength"},function(){return this.Uo},function(a){this.Uo!==a&&(D.h(a,"number",mt,"defaultSpringLength"),this.Uo=a,this.N())});D.defineProperty(mt,{uL:"defaultElectricalCharge"},function(){return this.So},function(a){this.So!==a&&(D.h(a,"number",mt,"defaultElectricalCharge"),this.So=a,this.N())});D.defineProperty(mt,{vL:"defaultGravitationalMass"},function(){return this.To},function(a){this.To!==a&&(D.h(a,"number",mt,"defaultGravitationalMass"),this.To=a,this.N())});
D.defineProperty(mt,{rI:"defaultCommentSpringLength"},function(){return this.Dr},function(a){this.Dr!==a&&(D.h(a,"number",mt,"defaultCommentSpringLength"),this.Dr=a,this.N())});D.defineProperty(mt,{qI:"defaultCommentElectricalCharge"},function(){return this.Cr},function(a){this.Cr!==a&&(D.h(a,"number",mt,"defaultCommentElectricalCharge"),this.Cr=a,this.N())});function mu(){this.nC=this.mC=this.Uy=this.Wy=this.Vy=0}function nt(){ua.call(this)}D.Sa(nt,ua);D.ka("ForceDirectedNetwork",nt);
nt.prototype.createVertex=function(){return new ku};nt.prototype.createEdge=function(){return new pu};function ku(){va.call(this);this.isFixed=!1;this.mass=this.charge=NaN;this.dl=this.sg=this.forceY=this.forceX=0;this.Xh=this.In=null;this.HB=0}D.Sa(ku,va);D.ka("ForceDirectedVertex",ku);function pu(){wa.call(this);this.length=this.stiffness=NaN}D.Sa(pu,wa);D.ka("ForceDirectedEdge",pu);
function qu(){0<arguments.length&&D.xd(qu);$g.call(this);this.ie=this.bn=25;this.fa=0;this.Ro=ru;this.sp=su;this.ip=tu;this.$m=4;this.Fo=uu;this.oj=vu;this.si=!0;this.us=4;this.Qb=this.rw=this.Ab=-1;this.Zf=this.Ds=0;this.Wb=this.Xf=this.Yf=this.Kg=this.ue=null;this.Ls=0;this.Ks=this.gn=null;this.Mg=0;this.Ms=null;this.xC=new N;this.zh=[];this.zh.length=100}D.Sa(qu,$g);D.ka("LayeredDigraphLayout",qu);
qu.prototype.cloneProtected=function(a){$g.prototype.cloneProtected.call(this,a);a.bn=this.bn;a.ie=this.ie;a.fa=this.fa;a.Ro=this.Ro;a.sp=this.sp;a.ip=this.ip;a.$m=this.$m;a.Fo=this.Fo;a.oj=this.oj;a.si=this.si;a.us=this.us};qu.prototype.qc=function(a){a.Oe===qu?0===a.name.indexOf("Aggressive")?this.VH=a:0===a.name.indexOf("Cycle")?this.pI=a:0===a.name.indexOf("Init")?this.gJ=a:0===a.name.indexOf("Layer")?this.vJ=a:D.k("Unknown enum value: "+a):$g.prototype.qc.call(this,a)};
qu.prototype.createNetwork=function(){return new wu};
qu.prototype.doLayout=function(a){null===a&&D.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));this.Ce=this.initialOrigin(this.Ce);this.rw=-1;this.Zf=this.Ds=0;this.Ms=this.Ks=this.gn=null;for(a=0;a<this.zh.length;a++)this.zh[a]=null;if(0<this.network.vertexes.count){this.network.Fx();for(a=this.network.edges.j;a.next();)a.value.rev=!1;switch(this.Ro){default:case xu:a=this.network;var b=
0,c=a.vertexes.count-1,d=[];d.length=c+1;for(var e=a.vertexes.j;e.next();)e.value.valid=!0;for(;null!==yu(a);){for(e=zu(a);null!==e;)d[c]=e,c--,e.valid=!1,e=zu(a);for(e=Au(a);null!==e;)d[b]=e,b++,e.valid=!1,e=Au(a);for(var e=null,g=0,h=this.network.vertexes.j;h.next();){var k=h.value;if(k.valid){for(var l=0,m=k.sc;m.next();)m.value.toVertex.valid&&l++;for(var m=0,n=k.uc;n.next();)n.value.fromVertex.valid&&m++;if(null===e||g<l-m)e=k,g=l-m}}null!==e&&(d[b]=e,b++,e.valid=!1)}for(b=0;b<a.vertexes.count;b++)d[b].index=
b;for(d=a.edges.j;d.next();)b=d.value,b.fromVertex.index>b.toVertex.index&&(a.Dy(b),b.rev=!0);break;case ru:for(d=this.network.vertexes.j;d.next();)a=d.value,a.iq=-1,a.finish=-1;for(a=this.network.edges.j;a.next();)a.value.forest=!1;this.Ls=0;for(d.reset();d.next();)b=d.value,0===b.uc.count&&Bu(this,b);for(d.reset();d.next();)b=d.value,-1===b.iq&&Bu(this,b);for(a.reset();a.next();)d=a.value,d.forest||(b=d.fromVertex,c=b.finish,e=d.toVertex,g=e.finish,e.iq<b.iq&&c<g&&(this.network.Dy(d),d.rev=!0))}for(a=
this.network.vertexes.j;a.next();)a.value.layer=-1;this.Ab=-1;this.assignLayers();for(a.reset();a.next();)this.Ab=Math.max(this.Ab,a.value.layer);a=this.network;d=[];for(c=a.edges.j;c.next();)b=c.value,b.valid=!1,d.push(b);for(c=0;c<d.length;c++)if(b=d[c],g=b.fromVertex,e=b.toVertex,!b.valid&&(null!==g.Ed&&null!==e.Ed||g.layer!==e.layer)){m=k=l=h=0;if(null!==b.link){l=b.link;if(null===l)continue;var p=g.Ed,h=e.Ed;if(null===p||null===h)continue;var q=l.aa,k=l.ba,r=l.Ac,m=l.ad;b.rev&&(l=q,n=r,q=k,r=
m,k=l,m=n);var s=g.W,l=e.W,u=b.rev?e.Q:g.Q,n=D.P();u.F()?(kr(q,r,mc,n),n.F()||n.assign(s)):n.assign(s);p!==q&&u.F()&&q.isVisible()&&(p=g.Q,p.F()&&(n.x+=u.x-p.x,n.y+=u.y-p.y));q=b.rev?g.Q:e.Q;p=D.P();q.F()?(kr(k,m,mc,p),p.F()||p.assign(l)):p.assign(l);h!==k&&q.F()&&k.isVisible()&&(h=e.Q,h.F()&&(p.x+=q.x-h.x,p.y+=q.y-h.y));90===this.fa||270===this.fa?(h=Math.round((n.x-s.x)/this.ie),k=n.x,l=Math.round((p.x-l.x)/this.ie),m=p.x):(h=Math.round((n.y-s.y)/this.ie),k=n.y,l=Math.round((p.y-l.y)/this.ie),m=
p.y);D.A(n);D.A(p);b.portFromColOffset=h;b.portFromPos=k;b.portToColOffset=l;b.portToPos=m}else b.portFromColOffset=0,b.portFromPos=0,b.portToColOffset=0,b.portToPos=0;n=g.layer;s=e.layer;a:if(p=b,q=0,u=p.link,null!==u){var t=u.Ac,y=u.ad;if(null!==t&&null!==y){var w=u.aa,r=u.ba;if(null!==w&&null!==r){var B=t.Gb,A=y.Hb;this.Oq||(u.Gb.kd()||(B=u.Gb),u.Hb.kd()||(A=u.Hb));var O=u.ic,H=Cu(this,!0);if(B.kd()||B===dc)B=H;var R=Cu(this,!1);if(A.kd()||A===dc)A=R;if(B.Lj()&&B.Ij(R)&&A.Lj()&&A.Ij(H)){q=0;break a}H=
u.getLinkPoint(w,t,B,!0,O,r,y,D.P());B=u.getLinkDirection(w,t,H,B,!0,O,r,y);D.A(H);B===Du(this,p,!0)?q+=1:this.Oq&&null!==w&&1===w.ports.count&&p.rev&&(q+=1);B=u.getLinkPoint(r,y,A,!1,O,w,t,D.P());u=u.getLinkDirection(r,y,B,A,!1,O,w,t);D.A(B);u===Du(this,p,!1)?q+=2:this.Oq&&null!==r&&1===r.ports.count&&p.rev&&(q+=2)}}}p=1===q||3===q?!0:!1;if(q=2===q||3===q?!0:!1)r=a.createVertex(),r.Ed=null,r.Fn=1,r.layer=n,r.near=g,a.dm(r),g=a.Aq(g,r,b.link),g.valid=!1,g.rev=b.rev,g.portFromColOffset=h,g.portToColOffset=
0,g.portFromPos=k,g.portToPos=0,g=r;u=1;p&&u--;if(n-s>u&&0<n){b.valid=!1;r=a.createVertex();r.Ed=null;r.Fn=2;r.layer=n-1;a.dm(r);g=a.Aq(g,r,b.link);g.valid=!0;g.rev=b.rev;g.portFromColOffset=q?0:h;g.portToColOffset=0;g.portFromPos=q?0:k;g.portToPos=0;g=r;for(n--;n-s>u&&0<n;)r=a.createVertex(),r.Ed=null,r.Fn=3,r.layer=n-1,a.dm(r),g=a.Aq(g,r,b.link),g.valid=!0,g.rev=b.rev,g.portFromColOffset=0,g.portToColOffset=0,g.portFromPos=0,g.portToPos=0,g=r,n--;g=a.Aq(r,e,b.link);g.valid=!p;p&&(r.near=e);g.rev=
b.rev;g.portFromColOffset=0;g.portToColOffset=l;g.portFromPos=0;g.portToPos=m}else b.valid=!0}d=this.ue=[];for(b=0;b<=this.Ab;b++)d[b]=0;for(a=this.network.vertexes.j;a.next();)a.value.index=-1;this.initializeIndices();this.rw=-1;for(b=this.Zf=this.Ds=0;b<=this.Ab;b++)d[b]>d[this.Zf]&&(this.rw=d[b]-1,this.Zf=b),d[b]<d[this.Ds]&&(this.Ds=b);this.Ms=[];for(b=0;b<d.length;b++)this.Ms[b]=[];for(a.reset();a.next();)d=a.value,this.Ms[d.layer][d.index]=d;this.Qb=-1;for(a=0;a<=this.Ab;a++){d=Eu(this,a);b=
0;c=this.ue[a];for(e=0;e<c;e++)g=d[e],b+=this.nodeMinColumnSpace(g,!0),g.column=b,b+=1,b+=this.nodeMinColumnSpace(g,!1);this.Qb=Math.max(this.Qb,b-1);Fu(this,a,d)}this.reduceCrossings();this.straightenAndPack();this.updateParts()}this.network=null;this.zf=!0};qu.prototype.linkMinLength=function(){return 1};function Gu(a){var b=a.fromVertex.Ed;a=a.toVertex.Ed;return null===b&&null===a?8:null===b||null===a?4:1}
qu.prototype.nodeMinLayerSpace=function(a,b){return null===a.Ed?0:90===this.fa||270===this.fa?b?a.W.y+10:a.Q.height-a.W.y+10:b?a.W.x+10:a.Q.width-a.W.x+10};qu.prototype.nodeMinColumnSpace=function(a,b){if(null===a.Ed)return 0;var c=b?a.vB:a.uB;if(null!==c)return c;c=this.fa;return 90===c||270===c?b?a.vB=a.W.x/this.ie+1|0:a.uB=(a.Q.width-a.W.x)/this.ie+1|0:b?a.vB=a.W.y/this.ie+1|0:a.uB=(a.Q.height-a.W.y)/this.ie+1|0};
function Hu(a){null===a.gn&&(a.gn=[]);for(var b=0,c=a.network.vertexes.j;c.next();){var d=c.value;a.gn[b]=d.layer;b++;a.gn[b]=d.column;b++;a.gn[b]=d.index;b++}return a.gn}function Iu(a,b){for(var c=0,d=a.network.vertexes.j;d.next();){var e=d.value;e.layer=b[c];c++;e.column=b[c];c++;e.index=b[c];c++}}
function Ju(a,b,c){v&&(D.p(b,qu,"crossingMatrix:unfixedLayer"),D.p(c,qu,"crossingMatrix:direction"));var d=Eu(a,b),e=a.ue[b];if(null===a.Ks||a.Ks.length<e*e)a.Ks=[];for(var g=a.Ks,h=0;h<e;h++){var k=0,l=d[h],m=l.near,n=0;if(null!==m&&m.layer===l.layer)if(n=m.index,n>h)for(var p=h+1;p<n;p++)l=d[p],l.near===m&&l.Fn===m.Fn||k++;else for(p=h-1;p>n;p--)l=d[p],l.near===m&&l.Fn===m.Fn||k++;var m=0,q,r=q=p=l=0,s,u=0,t=0;s=0;var y;if(0<=c)for(n=d[h].Ye,m=0;m<n.count;m++)if(q=n.o[m],q.valid&&q.fromVertex.layer!==
b)for(l=q.fromVertex.index,p=q.portToPos,q=q.portFromPos,r=m+1;r<n.count;r++)s=n.o[r],s.valid&&s.fromVertex.layer!==b&&(u=s.fromVertex.index,t=s.portToPos,s=s.portFromPos,p<t&&(l>u||l===u&&q>s)&&k++,t<p&&(u>l||u===l&&s>q)&&k++);if(0>=c)for(n=d[h].Qe,m=0;m<n.count;m++)if(q=n.o[m],q.valid&&q.toVertex.layer!==b)for(l=q.toVertex.index,p=q.portToPos,q=q.portFromPos,r=m+1;r<n.count;r++)s=n.o[r],s.valid&&s.toVertex.layer!==b&&(u=s.toVertex.index,t=s.portToPos,s=s.portFromPos,q<s&&(l>u||l===u&&p>t)&&k++,
s<q&&(u>l||u===l&&t>p)&&k++);g[h*e+h]=k;for(n=h+1;n<e;n++){var w=0,B=0;if(0<=c)for(k=d[h].Ye,y=d[n].Ye,m=0;m<k.count;m++)if(q=k.o[m],q.valid&&q.fromVertex.layer!==b)for(l=q.fromVertex.index,q=q.portFromPos,r=0;r<y.count;r++)s=y.o[r],s.valid&&s.fromVertex.layer!==b&&(u=s.fromVertex.index,s=s.portFromPos,(l<u||l===u&&q<s)&&B++,(u<l||u===l&&s<q)&&w++);if(0>=c)for(k=d[h].Qe,y=d[n].Qe,m=0;m<k.count;m++)if(q=k.o[m],q.valid&&q.toVertex.layer!==b)for(l=q.toVertex.index,p=q.portToPos,r=0;r<y.count;r++)s=y.o[r],
s.valid&&s.toVertex.layer!==b&&(u=s.toVertex.index,t=s.portToPos,(l<u||l===u&&p<t)&&B++,(u<l||u===l&&t<p)&&w++);g[h*e+n]=w;g[n*e+h]=B}}Fu(a,b,d);return g}qu.prototype.countCrossings=function(){for(var a=0,b=0;b<=this.Ab;b++)for(var c=Ju(this,b,1),d=this.ue[b],e=0;e<d;e++)for(var g=e;g<d;g++)a+=c[e*d+g];return a};
function Ku(a){for(var b=0,c=0;c<=a.Ab;c++){for(var d=a,e=c,g=Eu(d,e),h=d.ue[e],k=0,l=0;l<h;l++){var m=null,m=g[l].Qe,n,p=0,q=0;if(null!==m)for(var r=0;r<m.count;r++)n=m.o[r],n.valid&&n.toVertex.layer!==e&&(p=n.fromVertex.column+n.portFromColOffset,q=n.toVertex.column+n.portToColOffset,k+=(Math.abs(p-q)+1)*Gu(n))}Fu(d,e,g);b+=k}return b}
qu.prototype.normalize=function(){var a=Infinity;this.Qb=-1;for(var b=this.network.vertexes.j;b.next();){var c=b.value,a=Math.min(a,c.column-this.nodeMinColumnSpace(c,!0));this.Qb=Math.max(this.Qb,c.column+this.nodeMinColumnSpace(c,!1))}for(b.reset();b.next();)b.value.column-=a;this.Qb-=a};
function Lu(a,b,c){v&&(D.p(b,qu,"barycenters:unfixedLayer"),D.p(c,qu,"barycenters:direction"));for(var d=Eu(a,b),e=a.ue[b],g=[],h=0;h<e;h++){var k=d[h],l=null;0>=c&&(l=k.Ye);var m=null;0<=c&&(m=k.Qe);var n=0,p=0,q=k.near;null!==q&&q.layer===k.layer&&(n+=q.column-1,p++);if(null!==l)for(q=0;q<l.count;q++){var k=l.o[q],r=k.fromVertex;k.valid&&!k.rev&&r.layer!==b&&(n+=r.column,p++)}if(null!==m)for(l=0;l<m.count;l++)k=m.o[l],q=k.toVertex,k.valid&&!k.rev&&q.layer!==b&&(n+=q.column,p++);g[h]=0===p?-1:n/
p}Fu(a,b,d);return g}
function Mu(a,b,c){v&&(D.p(b,qu,"medians:unfixedLayer"),D.p(c,qu,"medians:direction"));for(var d=Eu(a,b),e=a.ue[b],g=[],h=0;h<e;h++){var k=d[h],l=null;0>=c&&(l=k.Ye);var m=null;0<=c&&(m=k.Qe);var n=0,p=[],q=k.near;null!==q&&q.layer===k.layer&&(p[n]=q.column-1,n++);if(null!==l)for(q=0;q<l.count;q++){var k=l.o[q],r=k.fromVertex;k.valid&&!k.rev&&r.layer!==b&&(p[n]=r.column+k.portFromColOffset,n++)}if(null!==m)for(l=0;l<m.count;l++)k=m.o[l],q=k.toVertex,k.valid&&!k.rev&&q.layer!==b&&(p[n]=q.column+k.portToColOffset,
n++);0===n?g[h]=-1:(p.sort(function(a,b){return a-b}),m=n>>1,g[h]=0!==(n&1)?p[m]:p[m-1]+p[m]>>1)}Fu(a,b,d);return g}function Nu(a,b,c,d,e,g){if(b.component===d){b.component=c;var h=0,k=0;if(e)for(var l=b.sc;l.next();){var k=l.value,m=k.toVertex,h=b.layer-m.layer,k=a.linkMinLength(k);h===k&&Nu(a,m,c,d,e,g)}if(g)for(l=b.uc;l.next();)k=l.value,m=k.fromVertex,h=m.layer-b.layer,k=a.linkMinLength(k),h===k&&Nu(a,m,c,d,e,g)}}
function Ou(a,b,c,d,e,g){if(b.component===d){b.component=c;if(e)for(var h=b.sc;h.next();)Ou(a,h.value.toVertex,c,d,e,g);if(g)for(b=b.uc;b.next();)Ou(a,b.value.fromVertex,c,d,e,g)}}function yu(a){for(a=a.vertexes.j;a.next();){var b=a.value;if(b.valid)return b}return null}function zu(a){for(a=a.vertexes.j;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.sc;d.next();)if(d.value.toVertex.valid){c=!1;break}if(c)return b}}return null}
function Au(a){for(a=a.vertexes.j;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.uc;d.next();)if(d.value.fromVertex.valid){c=!1;break}if(c)return b}}return null}function Bu(a,b){b.iq=a.Ls;a.Ls++;for(var c=b.sc;c.next();){var d=c.value,e=d.toVertex;-1===e.iq&&(d.forest=!0,Bu(a,e))}b.finish=a.Ls;a.Ls++}
qu.prototype.assignLayers=function(){switch(this.sp){case Pu:Qu(this);break;case Ru:for(var a=0,b=this.network.vertexes.j;b.next();)a=Su(this,b.value),this.Ab=Math.max(a,this.Ab);for(b.reset();b.next();)a=b.value,a.layer=this.Ab-a.layer;break;default:case su:Qu(this);for(b=this.network.vertexes.j;b.next();)b.value.valid=!1;for(b.reset();b.next();)a=b.value,0===a.uc.count&&Tu(this,a);a=Infinity;for(b.reset();b.next();)a=Math.min(a,b.value.layer);this.Ab=-1;for(b.reset();b.next();){var c=b.value;c.layer-=
a;this.Ab=Math.max(this.Ab,c.layer)}}};function Qu(a){for(var b=a.network.vertexes.j;b.next();){var c=Uu(a,b.value);a.Ab=Math.max(c,a.Ab)}}function Uu(a,b){var c=0;if(-1===b.layer){for(var d=b.sc;d.next();)var e=d.value,g=e.toVertex,e=a.linkMinLength(e),c=Math.max(c,Uu(a,g)+e);b.layer=c}else c=b.layer;return c}function Su(a,b){var c=0;if(-1===b.layer){for(var d=b.uc;d.next();)var e=d.value,g=e.fromVertex,e=a.linkMinLength(e),c=Math.max(c,Su(a,g)+e);b.layer=c}else c=b.layer;return c}
function Tu(a,b){if(!b.valid){b.valid=!0;for(var c=b.sc;c.next();)Tu(a,c.value.toVertex);for(c=a.network.vertexes.j;c.next();)c.value.component=-1;for(var d=b.Ye.o,e=d.length,g=0;g<e;g++){var h=d[g],k=h.fromVertex,l=h.toVertex,h=a.linkMinLength(h);k.layer-l.layer>h&&Nu(a,k,0,-1,!0,!1)}for(Nu(a,b,1,-1,!0,!0);0!==b.component;){for(var k=0,d=Infinity,l=0,m=null,n=a.network.vertexes.j;n.next();){var p=n.value;if(1===p.component){for(var q=0,r=!1,s=p.Ye.o,e=s.length,g=0;g<e;g++){var h=s[g],u=h.fromVertex,
q=q+1;1!==u.component&&(k+=1,u=u.layer-p.layer,h=a.linkMinLength(h),d=Math.min(d,u-h))}h=p.Qe.o;e=h.length;for(g=0;g<e;g++)s=h[g].toVertex,q-=1,1!==s.component?k-=1:r=!0;(null===m||q<l)&&!r&&(m=p,l=q)}}if(0<k){for(c.reset();c.next();)e=c.value,1===e.component&&(e.layer+=d);b.component=0}else m.component=0}for(c=a.network.vertexes.j;c.next();)c.value.component=-1;for(Nu(a,b,1,-1,!0,!1);0!==b.component;){g=0;e=Infinity;d=0;h=null;for(k=a.network.vertexes.j;k.next();)if(l=k.value,1===l.component){m=
0;n=!1;r=l.Ye.o;p=r.length;for(q=0;q<p;q++)s=r[q].fromVertex,m+=1,1!==s.component?g+=1:n=!0;r=l.Qe.o;p=r.length;for(q=0;q<p;q++)s=r[q],u=s.toVertex,m-=1,1!==u.component&&(g-=1,u=l.layer-u.layer,s=a.linkMinLength(s),e=Math.min(e,u-s));(null===h||m>d)&&!n&&(h=l,d=m)}if(0>g){for(c.reset();c.next();)g=c.value,1===g.component&&(g.layer-=e);b.component=0}else h.component=0}}}
function Du(a,b,c){return 90===a.fa?c&&!b.rev||!c&&b.rev?270:90:180===a.fa?c&&!b.rev||!c&&b.rev?0:180:270===a.fa?c&&!b.rev||!c&&b.rev?90:270:c&&!b.rev||!c&&b.rev?180:0}
qu.prototype.initializeIndices=function(){switch(this.ip){default:case Vu:for(var a=this.network.vertexes.j;a.next();){var b=a.value,c=b.layer;b.index=this.ue[c];this.ue[c]++}break;case tu:a=this.network.vertexes.j;for(b=this.Ab;0<=b;b--)for(a.reset();a.next();)c=a.value,c.layer===b&&-1===c.index&&Wu(this,c);break;case Xu:for(a=this.network.vertexes.j,b=0;b<=this.Ab;b++)for(a.reset();a.next();)c=a.value,c.layer===b&&-1===c.index&&Yu(this,c)}};
function Wu(a,b){var c=b.layer;b.index=a.ue[c];a.ue[c]++;for(var c=b.Qe.oc(),d=!0;d;)for(var d=!1,e=0;e<c.length-1;e++){var g=c[e],h=c[e+1];g.portFromColOffset>h.portFromColOffset&&(d=!0,c[e]=h,c[e+1]=g)}for(e=0;e<c.length;e++)d=c[e],d.valid&&(d=d.toVertex,-1===d.index&&Wu(a,d))}
function Yu(a,b){var c=b.layer;b.index=a.ue[c];a.ue[c]++;for(var c=b.Ye.oc(),d=!0,e=0;d;)for(d=!1,e=0;e<c.length-1;e++){var g=c[e],h=c[e+1];g.portToColOffset>h.portToColOffset&&(d=!0,c[e]=h,c[e+1]=g)}for(e=0;e<c.length;e++)d=c[e],d.valid&&(d=d.fromVertex,-1===d.index&&Yu(a,d))}
qu.prototype.reduceCrossings=function(){for(var a=this.countCrossings(),b=Hu(this),c=0,d=0,e=0,c=0;c<this.$m;c++){for(d=0;d<=this.Ab;d++)Zu(this,d,1),$u(this,d,1);e=this.countCrossings();e<a&&(a=e,b=Hu(this));for(d=this.Ab;0<=d;d--)Zu(this,d,-1),$u(this,d,-1);e=this.countCrossings();e<a&&(a=e,b=Hu(this))}Iu(this,b);for(c=0;c<this.$m;c++){for(d=0;d<=this.Ab;d++)Zu(this,d,0),$u(this,d,0);e=this.countCrossings();e<a&&(a=e,b=Hu(this));for(d=this.Ab;0<=d;d--)Zu(this,d,0),$u(this,d,0);e=this.countCrossings();
e<a&&(a=e,b=Hu(this))}Iu(this,b);var g=!1,h=c=0,k=0,d=0;switch(this.Fo){case av:break;case bv:for(k=a+1;(d=this.countCrossings())<k;)for(k=d,c=this.Ab;0<=c;c--)for(h=0;h<=c;h++){for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=$u(this,d,-1)||g;e=this.countCrossings();e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=$u(this,d,1)||g;e=this.countCrossings();e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=$u(this,d,1)||g;e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,
d=h;d<=c;d++)g=$u(this,d,-1)||g;e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=$u(this,d,0)||g;e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=$u(this,d,0)||g;e>=a?Iu(this,b):(a=e,b=Hu(this))}break;default:case uu:for(c=this.Ab,h=0,k=a+1;(d=this.countCrossings())<k;){k=d;for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=$u(this,d,-1)||g;e=this.countCrossings();e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=$u(this,d,1)||g;e=this.countCrossings();
e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=$u(this,d,1)||g;e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=$u(this,d,-1)||g;e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=$u(this,d,0)||g;e>=a?Iu(this,b):(a=e,b=Hu(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=$u(this,d,0)||g;e>=a?Iu(this,b):(a=e,b=Hu(this))}}Iu(this,b)};
function Zu(a,b,c){v&&(D.p(b,qu,"medianBarycenterCrossingReduction:unfixedLayer"),D.p(c,qu,"medianBarycenterCrossingReduction:direction"));var d=0,e=Eu(a,b),g=a.ue[b],h=Mu(a,b,c);c=Lu(a,b,c);for(d=0;d<g;d++)-1===c[d]&&(c[d]=e[d].column),-1===h[d]&&(h[d]=e[d].column);for(var k=!0,l;k;)for(k=!1,d=0;d<g-1;d++)if(h[d+1]<h[d]||h[d+1]===h[d]&&c[d+1]<c[d])k=!0,l=h[d],h[d]=h[d+1],h[d+1]=l,l=c[d],c[d]=c[d+1],c[d+1]=l,l=e[d],e[d]=e[d+1],e[d+1]=l;for(d=h=0;d<g;d++)l=e[d],l.index=d,h+=a.nodeMinColumnSpace(l,
!0),l.column=h,h+=1,h+=a.nodeMinColumnSpace(l,!1);Fu(a,b,e)}
function $u(a,b,c){var d=Eu(a,b),e=a.ue[b];c=Ju(a,b,c);var g=0,h;h=[];for(g=0;g<e;g++)h[g]=-1;var k;k=[];for(g=0;g<e;g++)k[g]=-1;for(var l=!1,m=!0;m;)for(m=!1,g=0;g<e-1;g++){var n=c[d[g].index*e+d[g+1].index],p=c[d[g+1].index*e+d[g].index],q=0,r=0,s=d[g].column,u=d[g+1].column,t=a.nodeMinColumnSpace(d[g],!0),y=a.nodeMinColumnSpace(d[g],!1),w=a.nodeMinColumnSpace(d[g+1],!0),B=a.nodeMinColumnSpace(d[g+1],!1),t=s-t+w,y=u-y+B,w=w=0,A=d[g].uc.j;for(A.reset();A.next();)if(w=A.value,B=w.fromVertex,w.valid&&
B.layer===b){for(w=0;d[w]!==B;)w++;w<g&&(q+=2*(g-w),r+=2*(g+1-w));w===g+1&&(q+=1);w>g+1&&(q+=4*(w-g),r+=4*(w-(g+1)))}A=d[g].sc.j;for(A.reset();A.next();)if(w=A.value,B=w.toVertex,w.valid&&B.layer===b){for(w=0;d[w]!==B;)w++;w===g+1&&(r+=1)}A=d[g+1].uc.j;for(A.reset();A.next();)if(w=A.value,B=w.fromVertex,w.valid&&B.layer===b){for(w=0;d[w]!==B;)w++;w<g&&(q+=2*(g+1-w),r+=2*(g-w));w===g&&(r+=1);w>g+1&&(q+=4*(w-(g+1)),r+=4*(w-g))}A=d[g+1].sc.j;for(A.reset();A.next();)if(w=A.value,B=w.toVertex,w.valid&&
B.layer===b){for(w=0;d[w]!==B;)w++;w===g&&(q+=1)}var w=B=0,A=h[d[g].index],O=k[d[g].index],H=h[d[g+1].index],R=k[d[g+1].index];-1!==A&&(B+=Math.abs(A-s),w+=Math.abs(A-y));-1!==O&&(B+=Math.abs(O-s),w+=Math.abs(O-y));-1!==H&&(B+=Math.abs(H-u),w+=Math.abs(H-t));-1!==R&&(B+=Math.abs(R-u),w+=Math.abs(R-t));if(r<q-.5||r===q&&p<n-.5||r===q&&p===n&&w<B-.5)m=l=!0,d[g].column=y,d[g+1].column=t,n=d[g],d[g]=d[g+1],d[g+1]=n}for(g=0;g<e;g++)d[g].index=g;Fu(a,b,d);return l}
qu.prototype.straightenAndPack=function(){var a=0,b=!1,c=0!==(this.oj&cv),a=this.oj===vu;1E3<this.network.edges.count&&!a&&(c=!1);if(c){b=[];for(a=a=0;a<=this.Ab;a++)b[a]=0;for(var d=0,e=this.network.vertexes.j;e.next();){var g=e.value,a=g.layer,d=g.column,g=this.nodeMinColumnSpace(g,!1);b[a]=Math.max(b[a],d+g)}for(e.reset();e.next();)g=e.value,a=g.layer,d=g.column,g.column=(8*(this.Qb-b[a])>>1)+8*d;this.Qb*=8}if(0!==(this.oj&dv))for(b=!0;b;){b=!1;for(a=this.Zf+1;a<=this.Ab;a++)b=ev(this,a,1)||b;
for(a=this.Zf-1;0<=a;a--)b=ev(this,a,-1)||b;b=ev(this,this.Zf,0)||b}if(0!==(this.oj&fv)){for(a=this.Zf+1;a<=this.Ab;a++)gv(this,a,1);for(a=this.Zf-1;0<=a;a--)gv(this,a,-1);gv(this,this.Zf,0)}c&&(hv(this,-1),hv(this,1));if(0!==(this.oj&dv))for(b=!0;b;){b=!1;b=ev(this,this.Zf,0)||b;for(a=this.Zf+1;a<=this.Ab;a++)b=ev(this,a,0)||b;for(a=this.Zf-1;0<=a;a--)b=ev(this,a,0)||b}};
function ev(a,b,c){v&&(D.p(b,qu,"bendStraighten:unfixedLayer"),D.p(c,qu,"bendStraighten:direction"));for(var d=!1;iv(a,b,c);)d=!0;return d}
function iv(a,b,c){v&&(D.p(b,qu,"shiftbendStraighten:unfixedLayer"),D.p(c,qu,"shiftbendStraighten:direction"));var d=0,e=Eu(a,b),g=a.ue[b],h=Lu(a,b,-1);if(0<c)for(d=0;d<g;d++)h[d]=-1;var k=Lu(a,b,1);if(0>c)for(d=0;d<g;d++)k[d]=-1;for(var l=!1,m=!0;m;)for(m=!1,d=0;d<g;d++){var n=e[d].column,p=a.nodeMinColumnSpace(e[d],!0),q=a.nodeMinColumnSpace(e[d],!1),r=0,r=0>d-1||n-e[d-1].column-1>p+a.nodeMinColumnSpace(e[d-1],!1)?n-1:n,p=0,p=d+1>=g||e[d+1].column-n-1>q+a.nodeMinColumnSpace(e[d+1],!0)?n+1:n,s=q=
0,u=0,t=0,y=0,w=0;if(0>=c)for(var B=e[d].uc.j;B.next();){var w=B.value,A=w.fromVertex;w.valid&&A.layer!==b&&(t=Gu(w),y=w.portFromColOffset,w=w.portToColOffset,A=A.column,q+=(Math.abs(n+w-(A+y))+1)*t,s+=(Math.abs(r+w-(A+y))+1)*t,u+=(Math.abs(p+w-(A+y))+1)*t)}if(0<=c)for(B=e[d].sc.j;B.next();)w=B.value,A=w.toVertex,w.valid&&A.layer!==b&&(t=Gu(w),y=w.portFromColOffset,w=w.portToColOffset,A=A.column,q+=(Math.abs(n+y-(A+w))+1)*t,s+=(Math.abs(r+y-(A+w))+1)*t,u+=(Math.abs(p+y-(A+w))+1)*t);w=y=t=0;B=h[e[d].index];
A=k[e[d].index];-1!==B&&(t+=Math.abs(B-n),y+=Math.abs(B-r),w+=Math.abs(B-p));-1!==A&&(t+=Math.abs(A-n),y+=Math.abs(A-r),w+=Math.abs(A-p));if(s<q||s===q&&y<t)m=l=!0,e[d].column=r;else if(u<q||u===q&&w<t)m=l=!0,e[d].column=p}Fu(a,b,e);a.normalize();return l}
function gv(a,b,c){v&&(D.p(b,qu,"medianStraighten:unfixedLayer"),D.p(c,qu,"medianStraighten:direction"));var d=0,e=Eu(a,b),g=a.ue[b],h=Mu(a,b,c);c=[];for(d=0;d<g;d++)c[d]=h[d];for(h=!0;h;)for(h=!1,d=0;d<g;d++){var k=e[d].column,l=a.nodeMinColumnSpace(e[d],!0),m=a.nodeMinColumnSpace(e[d],!1),n=0,p=0,q=0,q=p=0;-1===c[d]?0===d&&d===g-1?n=k:0===d?(p=e[d+1].column,n=p-k===m+a.nodeMinColumnSpace(e[d+1],!0)?k-1:k):d===g-1?(q=e[d-1].column,n=k-q===l+a.nodeMinColumnSpace(e[d-1],!1)?k+1:k):(q=e[d-1].column,
q=q+a.nodeMinColumnSpace(e[d-1],!1)+l+1,p=e[d+1].column,p=p-a.nodeMinColumnSpace(e[d+1],!0)-m-1,n=(q+p)/2|0):0===d&&d===g-1?n=c[d]:0===d?(p=e[d+1].column,p=p-a.nodeMinColumnSpace(e[d+1],!0)-m-1,n=Math.min(c[d],p)):d===g-1?(q=e[d-1].column,q=q+a.nodeMinColumnSpace(e[d-1],!1)+l+1,n=Math.max(c[d],q)):(q=e[d-1].column,q=q+a.nodeMinColumnSpace(e[d-1],!1)+l+1,p=e[d+1].column,p=p-a.nodeMinColumnSpace(e[d+1],!0)-m-1,q<c[d]&&c[d]<p?n=c[d]:q>=c[d]?n=q:p<=c[d]&&(n=p));n!==k&&(h=!0,e[d].column=n)}Fu(a,b,e);a.normalize()}
function jv(a,b){v&&(D.p(b,qu,"packAux:column"),D.p(1,qu,"packAux:direction"));for(var c=!0,d=a.network.vertexes.j;d.next();){var e=d.value,g=a.nodeMinColumnSpace(e,!0),h=a.nodeMinColumnSpace(e,!1);if(e.column-g<=b&&e.column+h>=b){c=!1;break}}e=!1;if(c)for(d.reset();d.next();)c=d.value,c.column>b&&(c.column-=1,e=!0);return e}
function kv(a,b){v&&(D.p(b,qu,"tightPackAux:column"),D.p(1,qu,"tightPackAux:direction"));for(var c=b,c=b+1,d=0,e=[],g=[],d=0;d<=a.Ab;d++)e[d]=!1,g[d]=!1;for(var h=a.network.vertexes.j;h.next();){var d=h.value,k=d.column-a.nodeMinColumnSpace(d,!0),l=d.column+a.nodeMinColumnSpace(d,!1);k<=b&&l>=b&&(e[d.layer]=!0);k<=c&&l>=c&&(g[d.layer]=!0)}k=!0;c=!1;for(d=0;d<=a.Ab;d++)k=k&&!(e[d]&&g[d]);if(k)for(h.reset();h.next();)e=h.value,e.column>b&&(e.column-=1,c=!0);return c}
function hv(a,b){v&&D.p(b,qu,"componentPack:direction");for(var c=0;c<=a.Qb;c++)for(;jv(a,c););a.normalize();for(c=0;c<a.Qb;c++)for(;kv(a,c););a.normalize();var c=0,d,e=0,g=0,h=0;if(0<b)for(c=0;c<=a.Qb;c++)for(d=Hu(a),e=Ku(a),g=e+1;e<g;)g=e,lv(a,c,1),h=Ku(a),h>e?Iu(a,d):h<e&&(e=h,d=Hu(a));if(0>b)for(c=a.Qb;0<=c;c--)for(d=Hu(a),e=Ku(a),g=e+1;e<g;)g=e,lv(a,c,-1),h=Ku(a),h>e?Iu(a,d):h<e&&(e=h,d=Hu(a));a.normalize()}
function lv(a,b,c){a.Mg=0;for(var d=a.network.vertexes.j;d.next();)d.value.component=-1;if(0<c)for(d.reset();d.next();){var e=d.value;e.column-a.nodeMinColumnSpace(e,!0)<=b&&(e.component=a.Mg)}if(0>c)for(d.reset();d.next();)e=d.value,e.column+a.nodeMinColumnSpace(e,!1)>=b&&(e.component=a.Mg);a.Mg++;for(d.reset();d.next();)b=d.value,-1===b.component&&(Ou(a,b,a.Mg,-1,!0,!0),a.Mg++);var g=0;b=[];for(g=0;g<a.Mg*a.Mg;g++)b[g]=!1;e=[];for(g=0;g<(a.Ab+1)*(a.Qb+1);g++)e[g]=-1;for(d.reset();d.next();)for(var g=
d.value,h=g.layer,k=Math.max(0,g.column-a.nodeMinColumnSpace(g,!0)),l=Math.min(a.Qb,g.column+a.nodeMinColumnSpace(g,!1));k<=l;k++)e[h*(a.Qb+1)+k]=g.component;for(g=0;g<=a.Ab;g++){if(0<c)for(k=0;k<a.Qb;k++)-1!==e[g*(a.Qb+1)+k]&&-1!==e[g*(a.Qb+1)+k+1]&&e[g*(a.Qb+1)+k]!==e[g*(a.Qb+1)+k+1]&&(b[e[g*(a.Qb+1)+k]*a.Mg+e[g*(a.Qb+1)+k+1]]=!0);if(0>c)for(k=a.Qb;0<k;k--)-1!==e[g*(a.Qb+1)+k]&&-1!==e[g*(a.Qb+1)+k-1]&&e[g*(a.Qb+1)+k]!==e[g*(a.Qb+1)+k-1]&&(b[e[g*(a.Qb+1)+k]*a.Mg+e[g*(a.Qb+1)+k-1]]=!0)}e=[];for(g=
0;g<a.Mg;g++)e[g]=!0;h=new K("number");h.add(0);for(l=0;0!==h.count;)if(l=h.o[h.count-1],h.od(h.count-1),e[l])for(e[l]=!1,g=0;g<a.Mg;g++)b[l*a.Mg+g]&&h.ae(0,g);if(0<c)for(d.reset();d.next();)a=d.value,e[a.component]&&(a.column-=1);if(0>c)for(d.reset();d.next();)c=d.value,e[c.component]&&(c.column+=1)}
qu.prototype.commitLayout=function(){if(this.Oq)for(var a=Cu(this,!0),b=Cu(this,!1),c=this.network.edges.j;c.next();){var d=c.value.link;null!==d&&(d.Gb=a,d.Hb=b)}this.commitNodes();this.yA();this.Fu&&this.commitLinks()};function Cu(a,b){return 270===a.fa?b?$c:gd:90===a.fa?b?gd:$c:180===a.fa?b?ed:fd:b?fd:ed}
qu.prototype.commitNodes=function(){this.Kg=[];this.Yf=[];this.Xf=[];this.Wb=[];for(var a=0;a<=this.Ab;a++)this.Kg[a]=0,this.Yf[a]=0,this.Xf[a]=0,this.Wb[a]=0;for(a=this.network.vertexes.j;a.next();){var b=a.value,c=b.layer;this.Kg[c]=Math.max(this.Kg[c],this.nodeMinLayerSpace(b,!0));this.Yf[c]=Math.max(this.Yf[c],this.nodeMinLayerSpace(b,!1))}for(var b=0,d=this.bn,c=0;c<=this.Ab;c++){var e=d;0>=this.Kg[c]+this.Yf[c]&&(e=0);0<c&&(b+=e/2);90===this.fa||0===this.fa?(b+=this.Yf[c],this.Xf[c]=b,b+=this.Kg[c]):
(b+=this.Kg[c],this.Xf[c]=b,b+=this.Yf[c]);c<this.Ab&&(b+=e/2);this.Wb[c]=b}d=b;b=this.Ce;for(c=0;c<=this.Ab;c++)270===this.fa?this.Xf[c]=b.y+this.Xf[c]:90===this.fa?(this.Xf[c]=b.y+d-this.Xf[c],this.Wb[c]=d-this.Wb[c]):180===this.fa?this.Xf[c]=b.x+this.Xf[c]:(this.Xf[c]=b.x+d-this.Xf[c],this.Wb[c]=d-this.Wb[c]);a.reset();for(d=e=Infinity;a.next();){var c=a.value,g=c.layer,h=c.column|0,k=0,l=0;270===this.fa||90===this.fa?(k=b.x+this.ie*h,l=this.Xf[g]):(k=this.Xf[g],l=b.y+this.ie*h);c.pa=k;c.va=l;
e=Math.min(c.x,e);d=Math.min(c.y,d)}e=b.x-e;b=b.y-d;this.xC=new N(e,b);for(a.reset();a.next();)c=a.value,c.x+=e,c.y+=b,c.commit()};
qu.prototype.yA=function(){for(var a=0,b=this.bn,c=0;c<=this.Ab;c++)a+=this.Kg[c],a+=this.Yf[c];for(var a=a+this.Ab*b,b=[],c=this.ie*this.Qb,d=this.DJ;0<=d;d--)270===this.fa?0===d?b.push(new C(0,0,c,Math.abs(this.Wb[0]))):b.push(new C(0,this.Wb[d-1],c,Math.abs(this.Wb[d-1]-this.Wb[d]))):90===this.fa?0===d?b.push(new C(0,this.Wb[0],c,Math.abs(this.Wb[0]-a))):b.push(new C(0,this.Wb[d],c,Math.abs(this.Wb[d-1]-this.Wb[d]))):180===this.fa?0===d?b.push(new C(0,0,Math.abs(this.Wb[0]),c)):b.push(new C(this.Wb[d-
1],0,Math.abs(this.Wb[d-1]-this.Wb[d]),c)):0===d?b.push(new C(this.Wb[0],0,Math.abs(this.Wb[0]-a),c)):b.push(new C(this.Wb[d],0,Math.abs(this.Wb[d-1]-this.Wb[d]),c));this.commitLayers(b,this.xC)};qu.prototype.commitLayers=function(){};
qu.prototype.commitLinks=function(){for(var a=this.network.edges.j,b;a.next();)b=a.value.link,null!==b&&(b.Dm(),b.aq(),b.Aj());for(a.reset();a.next();)b=a.value.link,null!==b&&b.zo();for(a.reset();a.next();){var c=a.value;b=c.link;if(null!==b){b.Dm();var d=b,e=d.aa,g=d.ba,h=d.Ac,k=d.ad;if(null!==e){var l=e.findVisibleNode();null!==l&&l!==e&&(e=l,h=l.port)}if(null!==g){var m=g.findVisibleNode();null!==m&&m!==g&&(g=m,k=m.port)}var n=b.computeSpot(!0,h),p=b.computeSpot(!1,k),q=c.fromVertex,r=c.toVertex;
if(c.valid){if(b.lf===Tj&&4===b.ta){if(c.rev)var s=e,e=g,g=s,u=h,h=k,k=u;if(q.column===r.column){var t=b.getLinkPoint(e,h,n,!0,!1,g,k),y=b.getLinkPoint(g,k,p,!1,!1,e,h);t.F()||t.set(e.Z.im);y.F()||y.set(g.Z.im);b.aq();b.xj(t.x,t.y);b.xj((2*t.x+y.x)/3,(2*t.y+y.y)/3);b.xj((t.x+2*y.x)/3,(t.y+2*y.y)/3);b.xj(y.x,y.y)}else{var w=!1,B=!1;null!==h&&n===dc&&(w=!0);null!==k&&p===dc&&(B=!0);if(w||B){var A=b.m(0).x,O=b.m(0).y,H=b.m(1).x,R=b.m(1).y,aa=b.m(2).x,V=b.m(2).y,ea=b.m(3).x,la=b.m(3).y;if(w){90===this.fa||
270===this.fa?(H=A,R=(O+la)/2):(H=(A+ea)/2,R=O);b.ha(1,H,R);var Da=b.getLinkPoint(e,h,n,!0,!1,g,k);Da.F()||Da.set(e.Z.im);b.ha(0,Da.x,Da.y)}B&&(90===this.fa||270===this.fa?(aa=ea,V=(O+la)/2):(aa=(A+ea)/2,V=la),b.ha(2,aa,V),Da=b.getLinkPoint(g,k,p,!1,!1,e,h),Da.F()||Da.set(g.Z.im),b.ha(3,Da.x,Da.y))}}}b.Aj()}else if(q.layer===r.layer)b.Aj();else{var La=!1,gb=!1,za=0,W=b.lu+1;if(b.ic)gb=!0,za=b.ta,4<za&&b.points.removeRange(2,za-3);else if(b.lf===Tj)La=!0,za=b.ta,4<za&&b.points.removeRange(2,za-3),
W=2;else{var za=b.ta,wb=n===dc,Pb=p===dc;2<za&&wb&&Pb?b.points.removeRange(1,za-2):3<za&&wb&&!Pb?b.points.removeRange(1,za-3):3<za&&!wb&&Pb?b.points.removeRange(2,za-2):4<za&&!wb&&!Pb&&b.points.removeRange(2,za-3)}var Sa,Qc;if(c.rev){for(var Ra=0;null!==r&&q!==r;){Qc=Sa=null;for(var hg=r.uc.j;hg.next();){var ig=hg.value;if(ig.link===c.link&&(Sa=ig.fromVertex,Qc=ig.toVertex,null===Sa.Ed))break}if(Sa!==q)if(hb=b.m(W-1).x,ub=b.m(W-1).y,sa=Sa.pa,na=Sa.va,gb)180===this.fa||0===this.fa?2===W?(b.B(W++,hb,
ub),b.B(W++,hb,na)):(Md=null!==Qc?Qc.va:ub,Md!==na&&(ab=this.Wb[Sa.layer-1],b.B(W++,ab,ub),b.B(W++,ab,na))):2===W?(b.B(W++,hb,ub),b.B(W++,sa,ub)):(Gf=null!==Qc?Qc.pa:hb,Gf!==sa&&(ab=this.Wb[Sa.layer-1],b.B(W++,hb,ab),b.B(W++,sa,ab)));else if(2===W)if(Oa=Math.max(10,this.Kg[r.layer]),pb=Math.max(10,this.Yf[r.layer]),La)180===this.fa?sa<=r.Q.x?(Ra=r.Q.x,b.B(W++,Ra-Oa,na),b.B(W++,Ra,na),b.B(W++,Ra+pb,na)):(b.B(W++,sa-Oa,na),b.B(W++,sa,na),b.B(W++,sa+pb,na)):90===this.fa?na>=r.Q.bottom?(Ra=r.Q.y+r.Q.height,
b.B(W++,sa,Ra+pb),b.B(W++,sa,Ra),b.B(W++,sa,Ra-Oa)):(b.B(W++,sa,na+pb),b.B(W++,sa,na),b.B(W++,sa,na-Oa)):270===this.fa?na<=r.Q.y?(Ra=r.Q.y,b.B(W++,sa,Ra-Oa),b.B(W++,sa,Ra),b.B(W++,sa,Ra+pb)):(b.B(W++,sa,na-Oa),b.B(W++,sa,na),b.B(W++,sa,na+pb)):0===this.fa&&(sa>=r.Q.right?(Ra=r.Q.x+r.Q.width,b.B(W++,Ra+pb,na),b.B(W++,Ra,na),b.B(W++,Ra-Oa,na)):(b.B(W++,sa+pb,na),b.B(W++,sa,na),b.B(W++,sa-Oa,na)));else{b.B(W++,hb,ub);var ud=0;if(180===this.fa||0===this.fa){if(180===this.fa?sa>=r.Q.right:sa<=r.Q.x)ud=
(0===this.fa?-Oa:pb)/2;b.B(W++,hb+ud,na)}else{if(270===this.fa?na>=r.Q.bottom:na<=r.Q.y)ud=(90===this.fa?-Oa:pb)/2;b.B(W++,sa,ub+ud)}b.B(W++,sa,na)}else Oa=Math.max(10,this.Kg[Sa.layer]),pb=Math.max(10,this.Yf[Sa.layer]),180===this.fa?(La&&b.B(W++,sa-Oa,na),b.B(W++,sa,na),La&&b.B(W++,sa+pb,na)):90===this.fa?(La&&b.B(W++,sa,na+pb),b.B(W++,sa,na),La&&b.B(W++,sa,na-Oa)):270===this.fa?(La&&b.B(W++,sa,na-Oa),b.B(W++,sa,na),La&&b.B(W++,sa,na+pb)):(La&&b.B(W++,sa+pb,na),b.B(W++,sa,na),La&&b.B(W++,sa-Oa,
na));r=Sa}if(null===k||n!==dc||gb)if(hb=b.m(W-1).x,ub=b.m(W-1).y,sa=b.m(W).x,na=b.m(W).y,gb){var vd=this.Yf[q.layer],Rc=0;180===this.fa||0===this.fa?(Rc=ub,Rc>=q.Q.y&&Rc<=q.Q.bottom&&(180===this.fa?sa>=q.Q.x:sa<=q.Q.right)&&(Ra=q.pa+(180===this.fa?-vd:vd),Rc=Rc<q.Q.y+q.Q.height/2?q.Q.y-this.ie/2:q.Q.bottom+this.ie/2,b.B(W++,Ra,ub),b.B(W++,Ra,Rc)),b.B(W++,sa,Rc)):(Rc=hb,Rc>=q.Q.x&&Rc<=q.Q.right&&(270===this.fa?na>=q.Q.y:na<=q.Q.bottom)&&(Ra=q.va+(270===this.fa?-vd:vd),Rc=Rc<q.Q.x+q.Q.width/2?q.Q.x-
this.ie/2:q.Q.right+this.ie/2,b.B(W++,hb,Ra),b.B(W++,Rc,Ra)),b.B(W++,Rc,na));b.B(W++,sa,na)}else if(La)Oa=Math.max(10,this.Kg[q.layer]),pb=Math.max(10,this.Yf[q.layer]),180===this.fa&&sa>=q.Q.x?(Ra=q.Q.x+q.Q.width,b.ha(W-2,Ra,ub),b.ha(W-1,Ra+pb,ub)):90===this.fa&&na<=q.Q.bottom?(Ra=q.Q.y,b.ha(W-2,hb,Ra),b.ha(W-1,hb,Ra-Oa)):270===this.fa&&na>=q.Q.y?(Ra=q.Q.y+q.Q.height,b.ha(W-2,hb,Ra),b.ha(W-1,hb,Ra+pb)):0===this.fa&&sa<=q.Q.right&&(Ra=q.Q.x,b.ha(W-2,Ra,ub),b.ha(W-1,Ra-Oa,ub));else{Oa=Math.max(10,
this.Kg[q.layer]);pb=Math.max(10,this.Yf[q.layer]);ud=0;if(180===this.fa||0===this.fa){if(180===this.fa?sa<=q.Q.x:sa>=q.Q.right)ud=(0===this.fa?pb:-Oa)/2;b.B(W++,sa+ud,ub)}else{if(270===this.fa?na<=q.Q.y:na>=q.Q.bottom)ud=(90===this.fa?pb:-Oa)/2;b.B(W++,hb,na+ud)}b.B(W++,sa,na)}}else{for(;null!==q&&q!==r;){Qc=Sa=null;for(var uh=q.sc.j;uh.next();){var Ye=uh.value;if(Ye.link===c.link&&(Sa=Ye.toVertex,Qc=Ye.fromVertex,null!==Qc.Ed&&(Qc=null),null===Sa.Ed))break}var hb=0,ub=0,sa=0,na=0,ab=0,Oa=0,pb=0;
if(Sa!==r)if(hb=b.m(W-1).x,ub=b.m(W-1).y,sa=Sa.pa,na=Sa.va,gb)if(180===this.fa||0===this.fa){var Md=null!==Qc?Qc.va:ub;Md!==na&&(ab=this.Wb[Sa.layer],2===W&&(ab=0===this.fa?Math.max(ab,hb):Math.min(ab,hb)),b.B(W++,ab,ub),b.B(W++,ab,na))}else{var Gf=null!==Qc?Qc.pa:hb;Gf!==sa&&(ab=this.Wb[Sa.layer],2===W&&(ab=90===this.fa?Math.max(ab,ub):Math.min(ab,ub)),b.B(W++,hb,ab),b.B(W++,sa,ab))}else Oa=Math.max(10,this.Kg[Sa.layer]),pb=Math.max(10,this.Yf[Sa.layer]),180===this.fa?(b.B(W++,sa+pb,na),La&&b.B(W++,
sa,na),b.B(W++,sa-Oa,na)):90===this.fa?(b.B(W++,sa,na-Oa),La&&b.B(W++,sa,na),b.B(W++,sa,na+pb)):270===this.fa?(b.B(W++,sa,na+pb),La&&b.B(W++,sa,na),b.B(W++,sa,na-Oa)):(b.B(W++,sa-Oa,na),La&&b.B(W++,sa,na),b.B(W++,sa+pb,na));q=Sa}gb&&(hb=b.m(W-1).x,ub=b.m(W-1).y,sa=b.m(W).x,na=b.m(W).y,180===this.fa||0===this.fa?ub!==na&&(ab=0===this.fa?Math.min(Math.max((sa+hb)/2,this.Wb[r.layer]),sa):Math.max(Math.min((sa+hb)/2,this.Wb[r.layer]),sa),b.B(W++,ab,ub),b.B(W++,ab,na)):hb!==sa&&(ab=90===this.fa?Math.min(Math.max((na+
ub)/2,this.Wb[r.layer]),na):Math.max(Math.min((na+ub)/2,this.Wb[r.layer]),na),b.B(W++,hb,ab),b.B(W++,sa,ab)))}if(null!==d&&La){if(null!==h){if(n===dc){var re=b.m(0),be=b.m(2);re.O(be)||b.ha(1,(re.x+be.x)/2,(re.y+be.y)/2)}Da=b.getLinkPoint(e,h,dc,!0,!1,g,k);Da.F()||Da.set(e.Z.im);b.ha(0,Da.x,Da.y)}null!==k&&(p===dc&&(re=b.m(b.ta-1),be=b.m(b.ta-3),re.O(be)||b.ha(b.ta-2,(re.x+be.x)/2,(re.y+be.y)/2)),Da=b.getLinkPoint(g,k,dc,!1,!1,e,h),Da.F()||Da.set(g.Z.im),b.ha(b.ta-1,Da.x,Da.y))}b.Aj();c.commit()}}}for(var jd=
new K(J),vh=this.network.edges.j;vh.next();){var Hf=vh.value.link;null!==Hf&&Hf.ic&&!jd.contains(Hf)&&jd.add(Hf)}if(0<jd.count)if(90===this.fa||270===this.fa){for(var Hg=0,Hb=new K(mv),oc,wd,wh=jd.j;wh.next();){var qb=wh.value;if(null!==qb&&qb.ic)for(var Pa=2;Pa<qb.ta-3;Pa++)if(oc=qb.m(Pa),wd=qb.m(Pa+1),nv(oc.y,wd.y)&&!nv(oc.x,wd.x)){var Qb=new mv;Qb.layer=Math.floor(oc.y/2);var If=qb.m(0),Ig=qb.m(qb.ta-1);Qb.first=If.x*If.x+If.y;Qb.qe=Ig.x*Ig.x+Ig.y;Qb.jf=Math.min(oc.x,wd.x);Qb.Ee=Math.max(oc.x,
wd.x);Qb.index=Pa;Qb.link=qb;if(Pa+2<qb.ta){var xh=qb.m(Pa-1),ib=qb.m(Pa+2),zc=0;xh.y<oc.y?zc=ib.y<oc.y?3:oc.x<wd.x?2:1:xh.y>oc.y&&(zc=ib.y>oc.y?0:wd.x<oc.x?2:1);Qb.Li=zc}Hb.add(Qb)}}if(1<Hb.count){Hb.sort(this.SG);for(var $b=0;$b<Hb.count;){for(var se=Hb.o[$b].layer,Ac=$b+1;Ac<Hb.count&&Hb.o[Ac].layer===se;)Ac++;if(1<Ac-$b)for(var Bc=$b;Bc<Ac;){for(var vb=Hb.o[Bc].Ee,pc=$b+1;pc<Ac&&Hb.o[pc].jf<vb;)vb=Math.max(vb,Hb.o[pc].Ee),pc++;var xb=pc-Bc;if(1<xb){Hb.Rq(this.Gy,Bc,Bc+xb);for(var Fc=1,db=Hb.o[Bc].qe,
Pa=Bc;Pa<pc;Pa++){var Ta=Hb.o[Pa];Ta.qe!==db&&(Fc++,db=Ta.qe)}Hb.Rq(this.RG,Bc,Bc+xb);for(var Mb=1,db=Hb.o[Bc].first,Pa=Bc;Pa<pc;Pa++)Ta=Hb.o[Pa],Ta.first!==db&&(Mb++,db=Ta.first);var xd=!0,jg=Mb;Fc<Mb?(xd=!1,jg=Fc,db=Hb.o[Bc].qe,Hb.Rq(this.Gy,Bc,Bc+xb)):db=Hb.o[Bc].first;for(var Ze=0,Pa=Bc;Pa<pc;Pa++){Ta=Hb.o[Pa];(xd?Ta.first:Ta.qe)!==db&&(Ze++,db=xd?Ta.first:Ta.qe);qb=Ta.link;oc=qb.m(Ta.index);wd=qb.m(Ta.index+1);var $e=this.kG*(Ze-(jg-1)/2);if(!qb.Jj||ov(oc.x,oc.y+$e,wd.x,wd.y+$e))Hg++,qb.Dm(),
qb.ha(Ta.index,oc.x,oc.y+$e),qb.ha(Ta.index+1,wd.x,wd.y+$e),qb.Aj()}}Bc=pc}$b=Ac}}}else{for(var uj=0,Rb=new K(mv),qc,fc,kd=jd.j;kd.next();){var mb=kd.value;if(null!==mb&&mb.ic)for(var Sb=2;Sb<mb.ta-3;Sb++)if(qc=mb.m(Sb),fc=mb.m(Sb+1),nv(qc.x,fc.x)&&!nv(qc.y,fc.y)){var te=new mv;te.layer=Math.floor(qc.x/2);var yb=mb.m(0),ce=mb.m(mb.ta-1);te.first=yb.x+yb.y*yb.y;te.qe=ce.x+ce.y*ce.y;te.jf=Math.min(qc.y,fc.y);te.Ee=Math.max(qc.y,fc.y);te.index=Sb;te.link=mb;if(Sb+2<mb.ta){var yh=mb.m(Sb-1),zh=mb.m(Sb+
2),Jg=0;yh.x<qc.x?Jg=zh.x<qc.x?3:qc.y<fc.y?2:1:yh.x>qc.x&&(Jg=zh.x>qc.x?0:fc.y<qc.y?2:1);te.Li=Jg}Rb.add(te)}}if(1<Rb.count){Rb.sort(this.SG);for(var ld=0;ld<Rb.count;){for(var Nd=Rb.o[ld].layer,gc=ld+1;gc<Rb.count&&Rb.o[gc].layer===Nd;)gc++;if(1<gc-ld)for(var zb=ld;zb<gc;){for(var yd=Rb.o[zb].Ee,nb=ld+1;nb<gc&&Rb.o[nb].jf<yd;)yd=Math.max(yd,Rb.o[nb].Ee),nb++;var hc=nb-zb;if(1<hc){Rb.Rq(this.Gy,zb,zb+hc);for(var hi=1,ad=Rb.o[zb].qe,Sb=zb;Sb<nb;Sb++){var Ib=Rb.o[Sb];Ib.qe!==ad&&(hi++,ad=Ib.qe)}Rb.Rq(this.RG,
zb,zb+hc);for(var ue=1,ad=Rb.o[zb].first,Sb=zb;Sb<nb;Sb++)Ib=Rb.o[Sb],Ib.first!==ad&&(ue++,ad=Ib.first);var af=!0,bd=ue;hi<ue?(af=!1,bd=hi,ad=Rb.o[zb].qe,Rb.Rq(this.Gy,zb,zb+hc)):ad=Rb.o[zb].first;for(var Jf=0,Sb=zb;Sb<nb;Sb++){Ib=Rb.o[Sb];(af?Ib.first:Ib.qe)!==ad&&(Jf++,ad=af?Ib.first:Ib.qe);mb=Ib.link;qc=mb.m(Ib.index);fc=mb.m(Ib.index+1);var Kf=this.kG*(Jf-(bd-1)/2);if(!mb.Jj||ov(qc.x+Kf,qc.y,fc.x+Kf,fc.y))uj++,mb.Dm(),mb.ha(Ib.index,qc.x+Kf,qc.y),mb.ha(Ib.index+1,fc.x+Kf,fc.y),mb.Aj()}}zb=nb}ld=
gc}}}};qu.prototype.SG=function(a,b){return a instanceof mv&&b instanceof mv&&a!==b?a.layer<b.layer?-1:a.layer>b.layer?1:a.jf<b.jf?-1:a.jf>b.jf?1:a.Ee<b.Ee?-1:a.Ee>b.Ee?1:0:0};qu.prototype.RG=function(a,b){return a instanceof mv&&b instanceof mv&&a!==b?a.first<b.first?-1:a.first>b.first||a.Li<b.Li?1:a.Li>b.Li||a.jf<b.jf?-1:a.jf>b.jf?1:a.Ee<b.Ee?-1:a.Ee>b.Ee?1:0:0};
qu.prototype.Gy=function(a,b){return a instanceof mv&&b instanceof mv&&a!==b?a.qe<b.qe?-1:a.qe>b.qe||a.Li<b.Li?1:a.Li>b.Li||a.jf<b.jf?-1:a.jf>b.jf?1:a.Ee<b.Ee?-1:a.Ee>b.Ee?1:0:0};function nv(a,b){v&&(D.p(a,qu,"isApprox:a"),D.p(b,qu,"isApprox:b"));var c=a-b;return-1<c&&1>c}function ov(a,b,c,d){v&&(D.p(a,qu,"isUnoccupied2:px"),D.p(b,qu,"isUnoccupied2:py"),D.p(c,qu,"isUnoccupied2:qx"),D.p(d,qu,"isUnoccupied2:qy"));return!0}
function Eu(a,b){var c,d=a.ue[b];if(d>=a.zh.length){c=[];for(var e=0;e<a.zh.length;e++)c[e]=a.zh[e];a.zh=c}void 0===a.zh[d]||null===a.zh[d]?c=[]:(c=a.zh[d],a.zh[d]=null);d=a.Ms[b];for(e=0;e<d.length;e++){var g=d[e];c[g.index]=g}return c}function Fu(a,b,c){a.zh[a.ue[b]]=c}D.defineProperty(qu,{layerSpacing:"layerSpacing"},function(){return this.bn},function(a){this.bn!==a&&(D.h(a,"number",qu,"layerSpacing"),0<=a&&(this.bn=a,this.N()))});
D.defineProperty(qu,{mL:"columnSpacing"},function(){return this.ie},function(a){this.ie!==a&&(D.h(a,"number",qu,"columnSpacing"),0<a&&(this.ie=a,this.N()))});D.defineProperty(qu,{direction:"direction"},function(){return this.fa},function(a){this.fa!==a&&(D.h(a,"number",qu,"direction"),0===a||90===a||180===a||270===a?(this.fa=a,this.N()):D.k("LayeredDigraphLayout.direction must be 0, 90, 180, or 270"))});
D.defineProperty(qu,{angle:"angle"},function(){return this.direction},function(a){this.direction=a});D.defineProperty(qu,{pI:"cycleRemoveOption"},function(){return this.Ro},function(a){this.Ro!==a&&(D.Da(a,qu,qu,"cycleRemoveOption"),a===xu||a===ru)&&(this.Ro=a,this.N())});D.defineProperty(qu,{vJ:"layeringOption"},function(){return this.sp},function(a){this.sp!==a&&(D.Da(a,qu,qu,"layeringOption"),a===su||a===Pu||a===Ru)&&(this.sp=a,this.N())});
D.defineProperty(qu,{gJ:"initializeOption"},function(){return this.ip},function(a){this.ip!==a&&(D.Da(a,qu,qu,"initializeOption"),a===tu||a===Xu||a===Vu)&&(this.ip=a,this.N())});D.defineProperty(qu,{VL:"iterations"},function(){return this.$m},function(a){this.$m!==a&&(D.p(a,wu,"iterations"),0<=a&&(this.$m=a,this.N()))});D.defineProperty(qu,{VH:"aggressiveOption"},function(){return this.Fo},function(a){this.Fo!==a&&(D.Da(a,qu,qu,"aggressiveOption"),a===av||a===uu||a===bv)&&(this.Fo=a,this.N())});
D.defineProperty(qu,{oM:"packOption"},function(){return this.oj},function(a){this.oj!==a&&(D.h(a,"number",qu,"packOption"),0<=a&&8>a&&(this.oj=a,this.N()))});D.defineProperty(qu,{Oq:"setsPortSpots"},function(){return this.si},function(a){this.si!==a&&(D.h(a,"boolean",qu,"setsPortSpots"),this.si=a,this.N())});D.defineProperty(qu,{kG:"linkSpacing"},function(){return this.us},function(a){this.us!==a&&(D.h(a,"number",qu,"linkSpacing"),0<=a&&(this.us=a,this.N()))});D.w(qu,{DJ:"maxLayer"},function(){return this.Ab});
D.w(qu,{$L:"maxIndex"},function(){return this.rw});D.w(qu,{ZL:"maxColumn"},function(){return this.Qb});D.w(qu,{eM:"minIndexLayer"},function(){return this.Ds});D.w(qu,{aM:"maxIndexLayer"},function(){return this.Zf});var ru;qu.CycleDepthFirst=ru=D.s(qu,"CycleDepthFirst",0);var xu;qu.CycleGreedy=xu=D.s(qu,"CycleGreedy",1);var su;qu.LayerOptimalLinkLength=su=D.s(qu,"LayerOptimalLinkLength",0);var Pu;qu.LayerLongestPathSink=Pu=D.s(qu,"LayerLongestPathSink",1);var Ru;
qu.LayerLongestPathSource=Ru=D.s(qu,"LayerLongestPathSource",2);var tu;qu.InitDepthFirstOut=tu=D.s(qu,"InitDepthFirstOut",0);var Xu;qu.InitDepthFirstIn=Xu=D.s(qu,"InitDepthFirstIn",1);var Vu;qu.InitNaive=Vu=D.s(qu,"InitNaive",2);var av;qu.AggressiveNone=av=D.s(qu,"AggressiveNone",0);var uu;qu.AggressiveLess=uu=D.s(qu,"AggressiveLess",1);var bv;qu.AggressiveMore=bv=D.s(qu,"AggressiveMore",2);qu.PackNone=0;var cv;qu.PackExpand=cv=1;var dv;qu.PackStraighten=dv=2;var fv;qu.PackMedian=fv=4;var vu;
qu.PackAll=vu=7;function mv(){this.index=this.Ee=this.jf=this.qe=this.first=this.layer=0;this.link=null;this.Li=0}D.ne(mv,{layer:!0,first:!0,qe:!0,jf:!0,Ee:!0,index:!0,link:!0,Li:!0});function wu(){ua.call(this)}D.Sa(wu,ua);D.ka("LayeredDigraphNetwork",wu);wu.prototype.createVertex=function(){return new pv};wu.prototype.createEdge=function(){return new qv};
function pv(){va.call(this);this.index=this.column=this.layer=-1;this.component=NaN;this.near=null;this.valid=!1;this.finish=this.iq=NaN;this.Fn=0;this.uB=this.vB=null}D.Sa(pv,va);D.ka("LayeredDigraphVertex",pv);function qv(){wa.call(this);this.forest=this.rev=this.valid=!1;this.portToPos=this.portFromPos=NaN;this.portToColOffset=this.portFromColOffset=0}D.Sa(qv,wa);D.ka("LayeredDigraphEdge",qv);
function $(){0<arguments.length&&D.xd($);$g.call(this);this.Fd=new L(Object);this.Xs=rv;this.Vf=sv;this.Nt=tv;this.ow=uv;this.vC=null;this.ai=!0;this.zd=vv;this.xg=(new Ca(10,10)).freeze();this.Aa=new wv;this.Ba=new wv;this.iA=[]}D.Sa($,$g);D.ka("TreeLayout",$);$.prototype.cloneProtected=function(a){$g.prototype.cloneProtected.call(this,a);a.Xs=this.Xs;a.Nt=this.Nt;a.ow=this.ow;a.ai=this.ai;a.zd=this.zd;a.xg.assign(this.xg);a.Aa.copyInheritedPropertiesFrom(this.Aa);a.Ba.copyInheritedPropertiesFrom(this.Ba)};
$.prototype.qc=function(a){a.Oe===$?0===a.name.indexOf("Alignment")?this.alignment=a:0===a.name.indexOf("Arrangement")?this.eg=a:0===a.name.indexOf("Compaction")?this.compaction=a:0===a.name.indexOf("Path")?this.path=a:0===a.name.indexOf("Sorting")?this.sorting=a:0===a.name.indexOf("Style")?this.BK=a:D.k("Unknown enum value: "+a):$g.prototype.qc.call(this,a)};$.prototype.createNetwork=function(){return new xv};
$.prototype.makeNetwork=function(a){function b(a){if(a instanceof G)return!a.Kf&&"Comment"!==a.Gd;if(a instanceof J){var b=a.aa;if(null===b||b.Kf||"Comment"===b.Gd)return!1;a=a.ba;return null===a||a.Kf||"Comment"===a.Gd?!1:!0}return!1}var c=this.createNetwork();c.Zb=this;a instanceof E?(c.Lk(a.rg,!0,b),c.Lk(a.links,!0,b)):a instanceof I?c.Lk(a.Oc,!1,b):c.Lk(a.j,!1,b);return c};
$.prototype.doLayout=function(a){null===a&&D.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));this.eg!==yv&&(this.Ce=this.initialOrigin(this.Ce));var b=this.g;null===b&&a instanceof E&&(b=a);this.Vf=this.path===rv&&null!==b?b.de?sv:zv:this.path===rv?sv:this.path;if(0<this.network.vertexes.count){this.network.Fx();for(a=this.network.vertexes.j;a.next();)b=a.value,b.initialized=!1,b.level=
0,b.parent=null,b.children=[];if(0<this.Fd.count){a=new L(wv);for(b=this.Fd.j;b.next();){var c=b.value;c instanceof G?(c=this.network.Sn(c),null!==c&&a.add(c)):c instanceof wv&&a.add(c)}this.Fd=a}0===this.Fd.count&&this.findRoots();for(a=this.Fd.copy().j;a.next();)b=a.value,b.initialized||(b.initialized=!0,Av(this,b));b=this.network.vertexes;for(a=null;a=Bv(b),0<a.count;)b=Cv(this,a),null!==b&&this.Fd.add(b),b.initialized=!0,Av(this,b),b=a;for(a=this.Fd.j;a.next();)b=a.value,b instanceof wv&&Dv(this,
b);for(a=this.Fd.j;a.next();)b=a.value,b instanceof wv&&Ev(this,b);for(a=this.Fd.j;a.next();)b=a.value,b instanceof wv&&Fv(this,b);this.uA();if(this.iB===Gv){c=[];for(a=this.network.vertexes.j;a.next();){var d=a.value,b=d.parent;null===b&&(b=d);var b=0===b.angle||180===b.angle,e=c[d.level];void 0===e&&(e=0);c[d.level]=Math.max(e,b?d.width:d.height)}for(d=0;d<c.length;d++)void 0===c[d]&&(c[d]=0);this.vC=c;for(a=this.network.vertexes.j;a.next();)d=a.value,b=d.parent,null===b&&(b=d),0===b.angle||180===
b.angle?(180===b.angle&&(d.pu+=c[d.level]-d.width),d.width=c[d.level]):(270===b.angle&&(d.qu+=c[d.level]-d.height),d.height=c[d.level])}else if(this.iB===Hv)for(a=this.network.vertexes.j;a.next();){c=a.value;b=0===c.angle||180===c.angle;e=-1;for(d=0;d<c.children.length;d++)var g=c.children[d],e=Math.max(e,b?g.width:g.height);if(0<=e)for(d=0;d<c.children.length;d++)g=c.children[d],b?(180===c.angle&&(g.pu+=e-g.width),g.width=e):(270===c.angle&&(g.qu+=e-g.height),g.height=e)}for(a=this.Fd.j;a.next();)b=
a.value,b instanceof wv&&this.layoutTree(b);this.arrangeTrees();this.updateParts()}this.network=null;this.Fd=new L(Object);this.zf=!0};function Bv(a){var b=new L(wv);for(a=a.j;a.next();){var c=a.value;c.initialized||b.add(c)}return b}
$.prototype.findRoots=function(){for(var a=this.network.vertexes,b=a.j;b.next();){var c=b.value;switch(this.Vf){case sv:0===c.uc.count&&this.Fd.add(c);break;case zv:0===c.sc.count&&this.Fd.add(c);break;default:D.k("Unhandled path value "+this.Vf.toString())}}0===this.Fd.count&&(a=Cv(this,a),null!==a&&this.Fd.add(a))};
function Cv(a,b){for(var c=999999,d=null,e=b.j;e.next();){var g=e.value;switch(a.Vf){case sv:g.uc.count<c&&(c=g.uc.count,d=g);break;case zv:g.sc.count<c&&(c=g.sc.count,d=g);break;default:D.k("Unhandled path value "+a.Vf.toString())}}return d}
function Av(a,b){if(null!==b){v&&D.l(b,wv,$,"walkTree:v");switch(a.Vf){case sv:if(0<b.sc.count){for(var c=new K(wv),d=b.uI;d.next();){var e=d.value;Iv(a,b,e)&&c.add(e)}0<c.count&&(b.children=c.oc())}break;case zv:if(0<b.uc.count){c=new K(wv);for(d=b.qK;d.next();)e=d.value,Iv(a,b,e)&&c.add(e);0<c.count&&(b.children=c.oc())}break;default:D.k("Unhandled path value"+a.Vf.toString())}c=b.children;d=c.length;for(e=0;e<d;e++){var g=c[e];g.initialized=!0;g.level=b.level+1;g.parent=b;a.Fd.remove(g)}for(e=
0;e<d;e++)g=c[e],Av(a,g)}}function Iv(a,b,c){v&&D.l(b,wv,$,"walkOK:v");v&&D.l(c,wv,$,"walkOK:c");if(c.initialized){var d;if(null===b)d=!1;else{v&&D.l(c,wv,$,"isAncestor:a");v&&D.l(b,wv,$,"isAncestor:b");for(d=b.parent;null!==d&&d!==c;)d=d.parent;d=d===c}if(d||c.level>b.level)return!1;a.removeChild(c.parent,c)}return!0}
$.prototype.removeChild=function(a,b){if(null!==a&&null!==b){v&&D.l(a,wv,$,"removeChild:p");v&&D.l(b,wv,$,"removeChild:c");for(var c=a.children,d=0,e=0;e<c.length;e++)c[e]===b&&d++;if(0<d){for(var d=Array(c.length-d),g=0,e=0;e<c.length;e++)c[e]!==b&&(d[g++]=c[e]);a.children=d}}};
function Dv(a,b){if(null!==b){v&&D.l(b,wv,$,"initializeTree:v");a.initializeTreeVertexValues(b);b.alignment===Jv&&a.sortTreeVertexChildren(b);for(var c=0,d=b.Hn,e=0,g=b.children,h=g.length,k=0;k<h;k++){var l=g[k];Dv(a,l);c+=l.descendantCount+1;d=Math.max(d,l.maxChildrenCount);e=Math.max(e,l.maxGenerationCount)}b.descendantCount=c;b.maxChildrenCount=d;b.maxGenerationCount=0<d?e+1:0}}
function Kv(a,b){v&&D.l(b,wv,$,"mom:v");switch(a.Nt){default:case tv:return null!==b.parent?b.parent:a.Aa;case Lv:return null===b.parent?a.Aa:null===b.parent.parent?a.Ba:b.parent;case Mv:return null!==b.parent?null!==b.parent.parent?b.parent.parent:a.Ba:a.Aa;case Nv:var c=!0;if(0===b.Hn)c=!1;else for(var d=b.children,e=d.length,g=0;g<e;g++)if(0<d[g].Hn){c=!1;break}return c&&null!==b.parent?a.Ba:null!==b.parent?b.parent:a.Aa}}
$.prototype.initializeTreeVertexValues=function(a){v&&D.l(a,wv,$,"initializeTreeVertexValues:v");var b=Kv(this,a);a.copyInheritedPropertiesFrom(b);if(null!==a.parent&&a.parent.alignment===Jv){for(var b=a.angle,c=a.parent.children,d=0;d<c.length&&a!==c[d];)d++;0===d%2?d!==c.length-1&&(b=90===b?180:180===b?270:270===b?180:270):b=90===b?0:180===b?90:270===b?0:90;a.angle=b}a.initialized=!0};
function Ev(a,b){if(null!==b){v&&D.l(b,wv,$,"assignTree:v");a.assignTreeVertexValues(b);for(var c=b.children,d=c.length,e=0;e<d;e++)Ev(a,c[e])}}$.prototype.assignTreeVertexValues=function(){};function Fv(a,b){if(null!==b){v&&D.l(b,wv,$,"sortTree:v");b.alignment!==Jv&&a.sortTreeVertexChildren(b);for(var c=b.children,d=c.length,e=0;e<d;e++)Fv(a,c[e])}}
$.prototype.sortTreeVertexChildren=function(a){v&&D.l(a,wv,$,"sortTreeVertexChildren:v");switch(a.sorting){case Ov:break;case Pv:a.children.reverse();break;case Qv:a.children.sort(a.comparer);break;case Rv:a.children.sort(a.comparer);a.children.reverse();break;default:D.k("Unhandled sorting value "+a.sorting.toString())}};$.prototype.uA=function(){if(this.comments)for(var a=this.network.vertexes.j;a.next();)this.addComments(a.value)};
$.prototype.addComments=function(a){v&&D.l(a,wv,$,"addComments:v");var b=a.angle,c=a.parent,d=0,e=Sv,e=!1;null!==c&&(d=c.angle,e=c.alignment,e=Tv(e));var b=90===b||270===b,d=90===d||270===d,c=0===a.Hn,g=0,h=0,k=0,l=a.commentSpacing;if(null!==a.Yc)for(var m=a.Yc.vF();m.next();){var n=m.value;"Comment"===n.Gd&&n.canLayout()&&(null===a.comments&&(a.comments=[]),a.comments.push(n),n.Re(),n=n.Fa,b&&!c||!e&&!d&&c||e&&d&&c?(g=Math.max(g,n.width),h+=n.height+Math.abs(k)):(g+=n.width+Math.abs(k),h=Math.max(h,
n.height)),k=l)}null!==a.comments&&(b&&!c||!e&&!d&&c||e&&d&&c?(g+=Math.abs(a.commentMargin),h=Math.max(0,h-a.height)):(h+=Math.abs(a.commentMargin),g=Math.max(0,g-a.width)),e=D.vg(0,0,a.Q.width+g,a.Q.height+h),a.qb=e,D.Kb(e))};function Tv(a){return a===Uv||a===Jv||a===Vv||a===Wv}function Xv(a){return a===Uv||a===Jv}
function Yv(a){v&&D.l(a,wv,$,"isLeftSideBus:v");var b=a.parent;if(null!==b){var c=b.alignment;if(Tv(c)){if(Xv(c)){b=b.children;for(c=0;c<b.length&&a!==b[c];)c++;return 0===c%2}if(c===Vv)return!0}}return!1}
$.prototype.layoutComments=function(a){v&&D.l(a,wv,$,"layoutComments:v");if(null!==a.comments){var b=a.Yc.Fa,c=a.parent,d=a.angle,e=0,g=Sv,g=!1;null!==c&&(e=c.angle,g=c.alignment,g=Tv(g));for(var c=90===d||270===d,d=90===e||270===e,h=0===a.Hn,k=Yv(a),l=0,m=a.comments,n=m.length,p=D.P(),q=0;q<n;q++){var r=m[q],s=r.Fa;if(c&&!h||!g&&!d&&h||g&&d&&h){if(135<e&&!g||d&&k)if(0<=a.commentMargin)for(p.n(a.Q.x-a.commentMargin-s.width,a.Q.y+l),r.move(p),r=r.Zg();r.next();){var u=r.value;u.Gb=ed;u.Hb=fd}else for(p.n(a.Q.x+
2*a.W.x-a.commentMargin,a.Q.y+l),r.move(p),r=r.Zg();r.next();)u=r.value,u.Gb=fd,u.Hb=ed;else if(0<=a.commentMargin)for(p.n(a.Q.x+2*a.W.x+a.commentMargin,a.Q.y+l),r.move(p),r=r.Zg();r.next();)u=r.value,u.Gb=fd,u.Hb=ed;else for(p.n(a.Q.x+a.commentMargin-s.width,a.Q.y+l),r.move(p),r=r.Zg();r.next();)u=r.value,u.Gb=ed,u.Hb=fd;l=0<=a.commentSpacing?l+(s.height+a.commentSpacing):l+(a.commentSpacing-s.height)}else{if(135<e&&!g||!d&&k)if(0<=a.commentMargin)for(p.n(a.Q.x+l,a.Q.y-a.commentMargin-s.height),
r.move(p),r=r.Zg();r.next();)u=r.value,u.Gb=$c,u.Hb=gd;else for(p.n(a.Q.x+l,a.Q.y+2*a.W.y-a.commentMargin),r.move(p),r=r.Zg();r.next();)u=r.value,u.Gb=gd,u.Hb=$c;else if(0<=a.commentMargin)for(p.n(a.Q.x+l,a.Q.y+2*a.W.y+a.commentMargin),r.move(p),r=r.Zg();r.next();)u=r.value,u.Gb=gd,u.Hb=$c;else for(p.n(a.Q.x+l,a.Q.y+a.commentMargin-s.height),r.move(p),r=r.Zg();r.next();)u=r.value,u.Gb=$c,u.Hb=gd;l=0<=a.commentSpacing?l+(s.width+a.commentSpacing):l+(a.commentSpacing-s.width)}}D.A(p);b=l-a.commentSpacing-
(c?b.height:b.width);if(this.Vf===sv)for(e=a.sc;e.next();)a=e.value.link,null===a||a.Jj||(a.sm=0<b?b:NaN);else for(e=a.uc;e.next();)a=e.value.link,null===a||a.Jj||(a.Hm=0<b?b:NaN)}};
$.prototype.layoutTree=function(a){if(null!==a){v&&D.l(a,wv,$,"layoutTree:v");for(var b=a.children,c=b.length,d=0;d<c;d++)this.layoutTree(b[d]);switch(a.compaction){case Zv:$v(this,a);break;case aw:if(a.alignment===Jv)$v(this,a);else if(v&&D.l(a,wv,$,"layoutTreeBlock:v"),0===a.Hn){var d=a.parent,b=!1,c=0,e=Sv;null!==d&&(c=d.angle,e=d.alignment,b=Tv(e));d=Yv(a);a.sa.n(0,0);a.ub.n(a.width,a.height);null===a.parent||null===a.comments||(180!==c&&270!==c||b)&&!d?a.Va.n(0,0):180===c&&!b||(90===c||270===
c)&&d?a.Va.n(a.width-2*a.W.x,0):a.Va.n(0,a.height-2*a.W.y);a.Hu=null;a.av=null}else{for(var g=bw(a),b=90===g||270===g,h=0,k=a.children,l=k.length,m=0;m<l;m++)var n=k[m],h=Math.max(h,b?n.ub.width:n.ub.height);var p=a.alignment,d=p===cw,q=p===dw,r=Tv(p),s=Math.max(0,a.breadthLimit),c=ew(a),u=a.nodeSpacing,t=fw(a),y=a.rowSpacing,w=0;if(d||q||a.Iq||a.Jq&&1===a.maxGenerationCount)w=Math.max(0,a.rowIndent);var d=a.width,e=a.height,B=0,A=0,O=0,H=null,R=null,aa=0,V=0,ea=0,la=0,Da=0,La=0,gb=0,za=0,n=0;r&&
!Xv(p)&&135<g&&k.reverse();if(Xv(p))if(1<l)for(m=0;m<l;m++)0===m%2&&m!==l-1?za=Math.max(za,b?k[m].ub.width:k[m].ub.height):0!==m%2&&(n=Math.max(n,b?k[m].ub.width:k[m].ub.height));else 1===l&&(za=b?k[0].ub.width:k[0].ub.height);if(r){switch(p){case Uv:A=135>g?gw(a,k,za,B,A):hw(a,k,za,B,A);za=A.x;B=A.width;A=A.height;break;case Vv:for(m=0;m<l;m++){var n=k[m],W=n.ub,H=0===La?0:y;b?(n.sa.n(h-W.width,la+H),B=Math.max(B,W.width),A=Math.max(A,la+H+W.height),la+=H+W.height):(n.sa.n(ea+H,h-W.height),B=Math.max(B,
ea+H+W.width),A=Math.max(A,W.height),ea+=H+W.width);La++}break;case Wv:for(m=0;m<l;m++)n=k[m],W=n.ub,H=0===La?0:y,b?(n.sa.n(u/2+a.W.x,la+H),B=Math.max(B,W.width),A=Math.max(A,la+H+W.height),la+=H+W.height):(n.sa.n(ea+H,u/2+a.W.y),B=Math.max(B,ea+H+W.width),A=Math.max(A,W.height),ea+=H+W.width),La++}H=Nw(this,2);R=Nw(this,2);b?(H[0].n(0,0),H[1].n(0,A),R[0].n(B,0)):(H[0].n(0,0),H[1].n(B,0),R[0].n(0,A));R[1].n(B,A)}else for(m=0;m<l;m++){n=k[m];W=n.ub;if(b){0<s&&0<La&&ea+u+W.width>s&&(ea<h&&Ow(a,p,h-
ea,0,gb,m-1),Da++,La=0,gb=m,O=A,ea=0,la=135<g?-A-y:A+y);Pw(this,n,0,la);var wb=0;if(0===La){if(H=n.Hu,R=n.av,aa=W.width,V=W.height,null===H||null===R||g!==bw(n))H=Nw(this,2),R=Nw(this,2),H[0].n(0,0),H[1].n(0,V),R[0].n(aa,0),R[1].n(aa,V)}else{var Pb=D.nb(),V=Qw(this,a,n,H,R,aa,V,Pb),wb=V.x,H=Pb[0],R=Pb[1],aa=V.width,V=V.height;D.ya(Pb);ea<W.width&&0>wb&&(Rw(a,-wb,0,gb,m-1),Sw(H,-wb,0),Sw(R,-wb,0),wb=0)}n.sa.n(wb,la);B=Math.max(B,aa);A=Math.max(A,O+(0===Da?0:y)+W.height);ea=aa}else{0<s&&0<La&&la+u+
W.height>s&&(la<h&&Ow(a,p,0,h-la,gb,m-1),Da++,La=0,gb=m,O=B,la=0,ea=135<g?-B-y:B+y);Pw(this,n,ea,0);wb=0;if(0===La){if(H=n.Hu,R=n.av,aa=W.width,V=W.height,null===H||null===R||g!==bw(n))H=Nw(this,2),R=Nw(this,2),H[0].n(0,0),H[1].n(aa,0),R[0].n(0,V),R[1].n(aa,V)}else Pb=D.nb(),V=Qw(this,a,n,H,R,aa,V,Pb),wb=V.x,H=Pb[0],R=Pb[1],aa=V.width,V=V.height,D.ya(Pb),la<W.height&&0>wb&&(Rw(a,0,-wb,gb,m-1),Sw(H,0,-wb),Sw(R,0,-wb),wb=0);n.sa.n(ea,wb);A=Math.max(A,V);B=Math.max(B,O+(0===Da?0:y)+W.width);la=V}La++}0<
Da&&(b?(A+=Math.max(0,c),ea<B&&Ow(a,p,B-ea,0,gb,l-1),0<w&&(q||Rw(a,w,0,0,l-1),B+=w)):(B+=Math.max(0,c),la<A&&Ow(a,p,0,A-la,gb,l-1),0<w&&(q||Rw(a,0,w,0,l-1),A+=w)));w=q=0;switch(p){case Tw:b?q+=B/2-a.W.x-t/2:w+=A/2-a.W.y-t/2;break;case Sv:0<Da?b?q+=B/2-a.W.x-t/2:w+=A/2-a.W.y-t/2:b?(za=k[0].sa.x+k[0].Va.x,m=k[l-1].sa.x+k[l-1].Va.x+2*k[l-1].W.x,q+=za+(m-za)/2-a.W.x-t/2):(za=k[0].sa.y+k[0].Va.y,m=k[l-1].sa.y+k[l-1].Va.y+2*k[l-1].W.y,w+=za+(m-za)/2-a.W.y-t/2);break;case cw:b?(q-=t,B+=t):(w-=t,A+=t);break;
case dw:b?(q+=B-a.width+t,B+=t):(w+=A-a.height+t,A+=t);break;case Uv:b?q=1<l?q+(za+u/2-a.W.x):q+(k[0].W.x-a.W.x+k[0].Va.x):w=1<l?w+(za+u/2-a.W.y):w+(k[0].W.y-a.W.y+k[0].Va.y);break;case Vv:b?q+=B+u/2-a.W.x:w+=A+u/2-a.W.y;break;case Wv:break;default:D.k("Unhandled alignment value "+p.toString())}for(m=0;m<l;m++)n=k[m],b?n.sa.n(n.sa.x+n.Va.x-q,n.sa.y+(135<g?(r?-A:-n.ub.height)+n.Va.y-c:e+c+n.Va.y)):n.sa.n(n.sa.x+(135<g?(r?-B:-n.ub.width)+n.Va.x-c:d+c+n.Va.x),n.sa.y+n.Va.y-w);l=k=0;r?b?(B=Uw(a,B,q),
0>q&&(q=0),135<g&&(w+=A+c),A+=e+c,p===Wv&&(k+=u/2+a.W.x),l+=e+c):(135<g&&(q+=B+c),B+=d+c,A=Vw(a,A,w),0>w&&(w=0),p===Wv&&(l+=u/2+a.W.y),k+=d+c):b?(null===a.comments?d>B&&(p=Ww(p,d-B,0),k=p.x,l=p.y,B=d,q=0):B=Uw(a,B,q),0>q&&(k-=q,q=0),135<g&&(w+=A+c),A=Math.max(Math.max(A,e),A+e+c),l+=e+c):(135<g&&(q+=B+c),B=Math.max(Math.max(B,d),B+d+c),null===a.comments?e>A&&(p=Ww(p,0,e-A),k=p.x,l=p.y,A=e,w=0):A=Vw(a,A,w),0>w&&(l-=w,w=0),k+=d+c);if(0<Da)g=Nw(this,4),p=Nw(this,4),b?(g[2].n(0,e+c),g[3].n(g[2].x,A),
p[2].n(B,g[2].y),p[3].n(p[2].x,g[3].y)):(g[2].n(d+c,0),g[3].n(B,g[2].y),p[2].n(g[2].x,A),p[3].n(g[3].x,p[2].y));else{g=Nw(this,H.length+2);p=Nw(this,R.length+2);for(m=0;m<H.length;m++)r=H[m],g[m+2].n(r.x+k,r.y+l);for(m=0;m<R.length;m++)r=R[m],p[m+2].n(r.x+k,r.y+l)}b?(g[0].n(q,0),g[1].n(g[0].x,e),g[2].y<g[1].y&&(g[2].x>g[0].x?g[2].assign(g[1]):g[1].assign(g[2])),g[3].y<g[2].y&&(g[3].x>g[0].x?g[3].assign(g[2]):g[2].assign(g[3])),p[0].n(q+d,0),p[1].n(p[0].x,e),p[2].y<p[1].y&&(p[2].x<p[0].x?p[2].assign(p[1]):
p[1].assign(p[2])),p[3].y<p[2].y&&(p[3].x<p[0].x?p[3].assign(p[2]):p[2].assign(p[3])),g[2].y-=c/2,p[2].y-=c/2):(g[0].n(0,w),g[1].n(d,g[0].y),g[2].x<g[1].x&&(g[2].y>g[0].y?g[2].assign(g[1]):g[1].assign(g[2])),g[3].x<g[2].x&&(g[3].y>g[0].y?g[3].assign(g[2]):g[2].assign(g[3])),p[0].n(0,w+e),p[1].n(d,p[0].y),p[2].x<p[1].x&&(p[2].y<p[0].y?p[2].assign(p[1]):p[1].assign(p[2])),p[3].x<p[2].x&&(p[3].y<p[0].y?p[3].assign(p[2]):p[2].assign(p[3])),g[2].x-=c/2,p[2].x-=c/2);Xw(this,H);Xw(this,R);a.Hu=g;a.av=p;
a.Va.n(q,w);a.ub.n(B,A)}break;default:D.k("Unhandled compaction value "+a.compaction.toString())}}};
function $v(a,b){v&&D.l(b,wv,$,"layoutTreeNone:v");if(0===b.Hn){var c=!1,d=0,e=Sv;null!==b.parent&&(d=b.parent.angle,e=b.parent.alignment,c=Tv(e));e=Yv(b);b.sa.n(0,0);b.ub.n(b.width,b.height);null===b.parent||null===b.comments||(180!==d&&270!==d||c)&&!e?b.Va.n(0,0):180===d&&!c||(90===d||270===d)&&e?b.Va.n(b.width-2*b.W.x,0):b.Va.n(0,b.height-2*b.W.y)}else{for(var c=bw(b),d=90===c||270===c,g=0,e=b.children,h=e.length,k=0;k<h;k++)var l=e[k],g=Math.max(g,d?l.ub.width:l.ub.height);var m=b.alignment,n=
m===cw,p=m===dw,q=Tv(m),r=Math.max(0,b.breadthLimit),s=ew(b),u=b.nodeSpacing,t=fw(b),y=n||p?0:t/2,w=b.rowSpacing,B=0;if(n||p||b.Iq||b.Jq&&1===b.maxGenerationCount)B=Math.max(0,b.rowIndent);var n=b.width,A=b.height,O=0,H=0,R=0,aa=0,V=0,ea=0,la=0,Da=0,La=0,gb=0;q&&!Xv(m)&&135<c&&e.reverse();if(Xv(m))if(1<h)for(k=0;k<h;k++){var l=e[k],za=l.ub;0===k%2&&k!==h-1?La=Math.max(La,(d?za.width:za.height)+Yw(l)-u):0!==k%2&&(gb=Math.max(gb,(d?za.width:za.height)+Yw(l)-u))}else 1===h&&(La=d?e[0].ub.width:e[0].ub.height);
if(q)switch(m){case Uv:case Jv:H=135>c?gw(b,e,La,O,H):hw(b,e,La,O,H);La=H.x;O=H.width;H=H.height;break;case Vv:for(k=0;k<h;k++)l=e[k],za=l.ub,r=0===la?0:w,d?(l.sa.n(g-za.width,V+r),O=Math.max(O,za.width),H=Math.max(H,V+r+za.height),V+=r+za.height):(l.sa.n(aa+r,g-za.height),O=Math.max(O,aa+r+za.width),H=Math.max(H,za.height),aa+=r+za.width),la++;break;case Wv:for(g=0;g<h;g++)l=e[g],za=l.ub,r=0===la?0:w,d?(l.sa.n(u/2+b.W.x,V+r),O=Math.max(O,za.width),H=Math.max(H,V+r+za.height),V+=r+za.height):(l.sa.n(aa+
r,u/2+b.W.y),O=Math.max(O,aa+r+za.width),H=Math.max(H,za.height),aa+=r+za.width),la++}else for(k=0;k<h;k++)l=e[k],za=l.ub,d?(0<r&&0<la&&aa+u+za.width>r&&(aa<g&&Ow(b,m,g-aa,0,Da,k-1),ea++,la=0,Da=k,R=H,aa=0,V=135<c?-H-w:H+w),gb=0===la?y:u,Pw(a,l,0,V),l.sa.n(aa+gb,V),O=Math.max(O,aa+gb+za.width),H=Math.max(H,R+(0===ea?0:w)+za.height),aa+=gb+za.width):(0<r&&0<la&&V+u+za.height>r&&(V<g&&Ow(b,m,0,g-V,Da,k-1),ea++,la=0,Da=k,R=O,V=0,aa=135<c?-O-w:O+w),gb=0===la?y:u,Pw(a,l,aa,0),l.sa.n(aa,V+gb),H=Math.max(H,
V+gb+za.height),O=Math.max(O,R+(0===ea?0:w)+za.width),V+=gb+za.height),la++;0<ea&&(d?(H+=Math.max(0,s),aa<O&&Ow(b,m,O-aa,0,Da,h-1),0<B&&(p||Rw(b,B,0,0,h-1),O+=B)):(O+=Math.max(0,s),V<H&&Ow(b,m,0,H-V,Da,h-1),0<B&&(p||Rw(b,0,B,0,h-1),H+=B)));B=p=0;switch(m){case Tw:d?p+=O/2-b.W.x-t/2:B+=H/2-b.W.y-t/2;break;case Sv:0<ea?d?p+=O/2-b.W.x-t/2:B+=H/2-b.W.y-t/2:d?(m=e[0].sa.x+e[0].Va.x,u=e[h-1].sa.x+e[h-1].Va.x+2*e[h-1].W.x,p+=m+(u-m)/2-b.W.x-t/2):(m=e[0].sa.y+e[0].Va.y,u=e[h-1].sa.y+e[h-1].Va.y+2*e[h-1].W.y,
B+=m+(u-m)/2-b.W.y-t/2);break;case cw:d?(p-=t,O+=t):(B-=t,H+=t);break;case dw:d?(p+=O-b.width+t,O+=t):(B+=H-b.height+t,H+=t);break;case Uv:case Jv:d?p=1<h?p+(La+u/2-b.W.x):p+(e[0].W.x-b.W.x+e[0].Va.x):B=1<h?B+(La+u/2-b.W.y):B+(e[0].W.y-b.W.y+e[0].Va.y);break;case Vv:d?p+=O+u/2-b.W.x:B+=H+u/2-b.W.y;break;case Wv:break;default:D.k("Unhandled alignment value "+m.toString())}for(k=0;k<h;k++)l=e[k],d?l.sa.n(l.sa.x+l.Va.x-p,l.sa.y+(135<c?(q?-H:-l.ub.height)+l.Va.y-s:A+s+l.Va.y)):l.sa.n(l.sa.x+(135<c?(q?
-O:-l.ub.width)+l.Va.x-s:n+s+l.Va.x),l.sa.y+l.Va.y-B);d?(O=Uw(b,O,p),0>p&&(p=0),135<c&&(B+=H+s),H+=A+s):(135<c&&(p+=O+s),O+=n+s,H=Vw(b,H,B),0>B&&(B=0));b.Va.n(p,B);b.ub.n(O,H)}}
function gw(a,b,c,d,e){v&&D.l(a,wv,$,"layoutBusChildrenPosDir:v");var g=b.length;if(0===g)return new C(c,0,d,e);if(1===g){var h=b[0];d=h.ub.width;e=h.ub.height;return new C(c,0,d,e)}for(var k=a.nodeSpacing,l=a.rowSpacing,m=90===bw(a),n=0,p=0,q=0,r=0;r<g;r++)if(!(0!==r%2||1<g&&r===g-1)){var h=b[r],s=h.ub,u=0===n?0:l;if(m){var t=Yw(h)-k;h.sa.n(c-(s.width+t),q+u);d=Math.max(d,s.width+t);e=Math.max(e,q+u+s.height);q+=u+s.height}else t=Yw(h)-k,h.sa.n(p+u,c-(s.height+t)),e=Math.max(e,s.height+t),d=Math.max(d,
p+u+s.width),p+=u+s.width;n++}var n=0,y=p,w=q;m?(p=c+k,q=0):(p=0,q=c+k);for(r=0;r<g;r++)0!==r%2&&(h=b[r],s=h.ub,u=0===n?0:l,m?(t=Yw(h)-k,h.sa.n(p+t,q+u),d=Math.max(d,p+s.width+t),e=Math.max(e,q+u+s.height),q+=u+s.height):(t=Yw(h)-k,h.sa.n(p+u,q+t),d=Math.max(d,p+u+s.width),e=Math.max(e,q+s.height+t),p+=u+s.width),n++);1<g&&1===g%2&&(h=b[g-1],s=h.ub,b=Zw(h,m?Math.max(Math.abs(w),Math.abs(q)):Math.max(Math.abs(y),Math.abs(p))),m?(h.sa.n(c+k/2-h.W.x-h.Va.x,e+b),m=c+k/2-h.W.x-h.Va.x,d=Math.max(d,m+s.width),
0>m&&(d-=m),e=Math.max(e,Math.max(w,q)+b+s.height),0>h.sa.x&&(c=$w(a,h.sa.x,!1,c,k))):(h.sa.n(d+b,c+k/2-h.W.y-h.Va.y),d=Math.max(d,Math.max(y,p)+b+s.width),m=c+k/2-h.W.y-h.Va.y,e=Math.max(e,m+s.height),0>m&&(e-=m),0>h.sa.y&&(c=$w(a,h.sa.y,!0,c,k))));return new C(c,0,d,e)}
function hw(a,b,c,d,e){v&&D.l(a,wv,$,"layoutBusChildrenNegDir:v");var g=b.length;if(0===g)return new C(c,0,d,e);if(1===g){var h=b[0];d=h.ub.width;e=h.ub.height;return new C(c,0,d,e)}for(var k=a.nodeSpacing,l=a.rowSpacing,m=270===bw(a),n=0,p=0,q=0,r=0;r<g;r++)if(!(0!==r%2||1<g&&r===g-1)){var h=b[r],s=h.ub,u=0===n?0:l;if(m){var t=Yw(h)-k,q=q-(u+s.height);h.sa.n(c-(s.width+t),q);d=Math.max(d,s.width+t);e=Math.max(e,Math.abs(q))}else t=Yw(h)-k,p-=u+s.width,h.sa.n(p,c-(s.height+t)),e=Math.max(e,s.height+
t),d=Math.max(d,Math.abs(p));n++}var n=0,y=p,w=q;m?(p=c+k,q=0):(p=0,q=c+k);for(r=0;r<g;r++)0!==r%2&&(h=b[r],s=h.ub,u=0===n?0:l,m?(t=Yw(h)-k,q-=u+s.height,h.sa.n(p+t,q),d=Math.max(d,p+s.width+t),e=Math.max(e,Math.abs(q))):(t=Yw(h)-k,p-=u+s.width,h.sa.n(p,q+t),e=Math.max(e,q+s.height+t),d=Math.max(d,Math.abs(p))),n++);1<g&&1===g%2&&(h=b[g-1],s=h.ub,l=Zw(h,m?Math.max(Math.abs(w),Math.abs(q)):Math.max(Math.abs(y),Math.abs(p))),m?(h.sa.n(c+k/2-h.W.x-h.Va.x,-e-s.height-l),p=c+k/2-h.W.x-h.Va.x,d=Math.max(d,
p+s.width),0>p&&(d-=p),e=Math.max(e,Math.abs(Math.min(w,q))+l+s.height),0>h.sa.x&&(c=$w(a,h.sa.x,!1,c,k))):(h.sa.n(-d-s.width-l,c+k/2-h.W.y-h.Va.y),d=Math.max(d,Math.abs(Math.min(y,p))+l+s.width),p=c+k/2-h.W.y-h.Va.y,e=Math.max(e,p+s.height),0>p&&(e-=p),0>h.sa.y&&(c=$w(a,h.sa.y,!0,c,k))));for(r=0;r<g;r++)h=b[r],m?h.sa.n(h.sa.x,h.sa.y+e):h.sa.n(h.sa.x+d,h.sa.y);return new C(c,0,d,e)}function Yw(a){v&&D.l(a,wv,$,"fixRelativePostions:child");return null===a.parent?0:a.parent.nodeSpacing}
function Zw(a){v&&D.l(a,wv,$,"fixRelativePostions:lastchild");return null===a.parent?0:a.parent.rowSpacing}function $w(a,b,c,d,e){v&&D.l(a,wv,$,"fixRelativePostions:v");a=a.children;for(var g=a.length,h=0;h<g;h++)c?a[h].sa.n(a[h].sa.x,a[h].sa.y-b):a[h].sa.n(a[h].sa.x-b,a[h].sa.y);b=a[g-1];return Math.max(d,c?b.Va.y+b.W.y-e/2:b.Va.x+b.W.x-e/2)}
function Uw(a,b,c){v&&D.l(a,wv,$,"calculateSubwidth:v");switch(a.alignment){case Sv:case Tw:var d=b;c+a.width>d&&(d=c+a.width);0>c&&(d-=c);return d;case cw:return a.width>b?a.width:b;case dw:return 2*a.W.x>b?a.width:b+a.width-2*a.W.x;case Uv:case Jv:return d=Math.min(0,c),c=Math.max(b,c+a.width),Math.max(a.width,c-d);case Vv:return a.width-a.W.x+a.nodeSpacing/2+b;case Wv:return Math.max(a.width,a.W.x+a.nodeSpacing/2+b);default:return b}}
function Vw(a,b,c){v&&D.l(a,wv,$,"calculateSubheight:v");switch(a.alignment){case Sv:case Tw:var d=b;c+a.height>d&&(d=c+a.height);0>c&&(d-=c);return d;case cw:return a.height>b?a.height:b;case dw:return 2*a.W.y>b?a.height:b+a.height-2*a.W.y;case Uv:case Jv:return d=Math.min(0,c),c=Math.max(b,c+a.height),Math.max(a.height,c-d);case Vv:return a.height-a.W.y+a.nodeSpacing/2+b;case Wv:return Math.max(a.height,a.W.y+a.nodeSpacing/2+b);default:return b}}
function Ww(a,b,c){v&&D.l(a,ya,$,"alignOffset:align");switch(a){case Tw:b/=2;c/=2;break;case Sv:b/=2;c/=2;break;case cw:c=b=0;break;case dw:break;default:D.k("Unhandled alignment value "+a.toString())}return new N(b,c)}function Ow(a,b,c,d,e,g){v&&D.l(a,wv,$,"shiftRelPosAlign:v");v&&D.l(b,ya,$,"shiftRelPosAlign:align");b=Ww(b,c,d);Rw(a,b.x,b.y,e,g)}function Rw(a,b,c,d,e){v&&D.l(a,wv,$,"shiftRelPos:v");if(0!==b||0!==c)for(a=a.children;d<=e;d++){var g=a[d].sa;g.x+=b;g.y+=c}}
function Pw(a,b,c,d){v&&(D.l(b,wv,$,"recordMidPoints:v"),D.h(c,"number",$,"recordMidPoints:x"),D.h(d,"number",$,"recordMidPoints:y"));var e=b.parent;switch(a.Vf){case sv:for(a=b.uc;a.next();)b=a.value,b.fromVertex===e&&b.dt.n(c,d);break;case zv:for(a=b.sc;a.next();)b=a.value,b.toVertex===e&&b.dt.n(c,d);break;default:D.k("Unhandled path value "+a.Vf.toString())}}function Sw(a,b,c){for(var d=0;d<a.length;d++){var e=a[d];e.x+=b;e.y+=c}}
function Qw(a,b,c,d,e,g,h,k){v&&D.l(b,wv,$,"mergeFringes:parent");v&&D.l(c,wv,$,"mergeFringes:child");var l=bw(b),m=90===l||270===l,n=b.nodeSpacing;b=d;var p=e;d=g;e=h;var q=c.Hu,r=c.av;h=c.ub;var s=m?Math.max(e,h.height):Math.max(d,h.width);if(null===q||l!==bw(c))q=Nw(a,2),r=Nw(a,2),m?(q[0].n(0,0),q[1].n(0,h.height),r[0].n(h.width,0),r[1].n(r[0].x,q[1].y)):(q[0].n(0,0),q[1].n(h.width,0),r[0].n(0,h.height),r[1].n(q[1].x,r[0].y));if(m){c=d;d=9999999;if(!(null===p||2>p.length||null===q||2>q.length))for(m=
l=0;l<p.length&&m<q.length;){e=p[l];var u=q[m];g=u.x;var t=u.y;g+=c;var y=e;l+1<p.length&&(y=p[l+1]);var w=u,u=w.x,w=w.y;m+1<q.length&&(w=q[m+1],u=w.x,w=w.y,u+=c);var B=d;e.y===t?B=g-e.x:e.y>t&&e.y<w?B=g+(e.y-t)/(w-t)*(u-g)-e.x:t>e.y&&t<y.y&&(B=g-(e.x+(t-e.y)/(y.y-e.y)*(y.x-e.x)));B<d&&(d=B);y.y<=e.y?l++:w<=t?m++:(y.y<=w&&l++,w<=y.y&&m++)}c-=d;c+=n;l=q;m=c;if(null===b||2>b.length||null===l||2>l.length)d=null;else{n=Nw(a,b.length+l.length);for(d=g=e=0;g<l.length&&l[g].y<b[0].y;)t=l[g++],n[d++].n(t.x+
m,t.y);for(;e<b.length;)t=b[e++],n[d++].n(t.x,t.y);for(e=b[b.length-1].y;g<l.length&&l[g].y<=e;)g++;for(;g<l.length&&l[g].y>e;)t=l[g++],n[d++].n(t.x+m,t.y);l=Nw(a,d);for(e=0;e<d;e++)l[e].assign(n[e]);Xw(a,n);d=l}g=r;t=c;if(null===p||2>p.length||null===g||2>g.length)e=null;else{n=Nw(a,p.length+g.length);for(m=y=l=0;l<p.length&&p[l].y<g[0].y;)e=p[l++],n[m++].n(e.x,e.y);for(;y<g.length;)e=g[y++],n[m++].n(e.x+t,e.y);for(g=g[g.length-1].y;l<p.length&&p[l].y<=g;)l++;for(;l<p.length&&p[l].y>g;)e=p[l++],
n[m++].n(e.x,e.y);e=Nw(a,m);for(l=0;l<m;l++)e[l].assign(n[l]);Xw(a,n)}g=Math.max(0,c)+h.width;h=s}else{c=e;d=9999999;if(!(null===p||2>p.length||null===q||2>q.length))for(m=l=0;l<p.length&&m<q.length;)e=p[l],u=q[m],g=u.x,t=u.y,t+=c,y=e,l+1<p.length&&(y=p[l+1]),w=u,u=w.x,w=w.y,m+1<q.length&&(w=q[m+1],u=w.x,w=w.y,w+=c),B=d,e.x===g?B=t-e.y:e.x>g&&e.x<u?B=t+(e.x-g)/(u-g)*(w-t)-e.y:g>e.x&&g<y.x&&(B=t-(e.y+(g-e.x)/(y.x-e.x)*(y.y-e.y))),B<d&&(d=B),y.x<=e.x?l++:u<=g?m++:(y.x<=u&&l++,u<=y.x&&m++);c-=d;c+=n;
l=q;m=c;if(null===b||2>b.length||null===l||2>l.length)d=null;else{n=Nw(a,b.length+l.length);for(d=g=e=0;g<l.length&&l[g].x<b[0].x;)t=l[g++],n[d++].n(t.x,t.y+m);for(;e<b.length;)t=b[e++],n[d++].n(t.x,t.y);for(e=b[b.length-1].x;g<l.length&&l[g].x<=e;)g++;for(;g<l.length&&l[g].x>e;)t=l[g++],n[d++].n(t.x,t.y+m);l=Nw(a,d);for(e=0;e<d;e++)l[e].assign(n[e]);Xw(a,n);d=l}g=r;t=c;if(null===p||2>p.length||null===g||2>g.length)e=null;else{n=Nw(a,p.length+g.length);for(m=y=l=0;l<p.length&&p[l].x<g[0].x;)e=p[l++],
n[m++].n(e.x,e.y);for(;y<g.length;)e=g[y++],n[m++].n(e.x,e.y+t);for(g=g[g.length-1].x;l<p.length&&p[l].x<=g;)l++;for(;l<p.length&&p[l].x>g;)e=p[l++],n[m++].n(e.x,e.y);e=Nw(a,m);for(l=0;l<m;l++)e[l].assign(n[l]);Xw(a,n)}g=s;h=Math.max(0,c)+h.height}Xw(a,b);Xw(a,q);Xw(a,p);Xw(a,r);k[0]=d;k[1]=e;return new C(c,0,g,h)}function Nw(a,b){var c=a.iA[b];if(void 0!==c&&(c=c.pop(),void 0!==c))return c;for(var c=[],d=0;d<b;d++)c[d]=new N;return c}
function Xw(a,b){var c=b.length,d=a.iA[c];void 0===d&&(d=[],a.iA[c]=d);d.push(b)}
$.prototype.arrangeTrees=function(){if(this.zd===yv)for(var a=this.Fd.j;a.next();){var b=a.value;if(b instanceof wv){var c=b.Yc;if(null!==c){var d=c.position,c=d.x,d=d.y;isFinite(c)||(c=0);isFinite(d)||(d=0);ax(this,b,c,d)}}}else{c=[];for(a=this.Fd.j;a.next();)b=a.value,b instanceof wv&&c.push(b);switch(this.sorting){case Ov:break;case Pv:c.reverse();break;case Qv:c.sort(this.comparer);break;case Rv:c.sort(this.comparer);c.reverse();break;default:D.k("Unhandled sorting value "+this.sorting.toString())}b=
this.Ce;a=b.x;b=b.y;for(d=0;d<c.length;d++){var e=c[d];ax(this,e,a+e.Va.x,b+e.Va.y);switch(this.zd){case vv:b+=e.ub.height+this.xg.height;break;case bx:a+=e.ub.width+this.xg.width;break;default:D.k("Unhandled arrangement value "+this.zd.toString())}}}};function ax(a,b,c,d){if(null!==b){v&&D.l(b,wv,$,"assignAbsolutePositions:v");b.x=c;b.y=d;b=b.children;for(var e=b.length,g=0;g<e;g++){var h=b[g];ax(a,h,c+h.sa.x,d+h.sa.y)}}}
$.prototype.commitLayout=function(){this.OB();this.commitNodes();this.yA();this.Fu&&this.commitLinks()};$.prototype.commitNodes=function(){for(var a=this.network.vertexes.j;a.next();)a.value.commit();for(a.reset();a.next();)this.layoutComments(a.value)};
$.prototype.yA=function(){if(this.iB===Gv){for(var a=this.vC,b=[],c=null,d=this.network.vertexes.j;d.next();){var e=d.value;null===c?c=e.qb.copy():c.Zh(e.qb);var g=b[e.level],g=void 0===g?ew(e):Math.max(g,ew(e));b[e.level]=g}for(d=0;d<b.length;d++)void 0===b[d]&&(b[d]=0);90===this.angle||270===this.angle?(c.ig(this.nodeSpacing/2,this.layerSpacing),e=new N(-this.nodeSpacing/2,-this.layerSpacing/2)):(c.ig(this.layerSpacing,this.nodeSpacing/2),e=new N(-this.layerSpacing/2,-this.nodeSpacing/2));var g=
[],c=90===this.angle||270===this.angle?c.width:c.height,h=0;if(180===this.angle||270===this.angle)for(d=0;d<a.length;d++)h+=a[d]+b[d];for(d=0;d<a.length;d++){var k=a[d]+b[d];270===this.angle?(h-=k,g.push(new C(0,h,c,k))):90===this.angle?(g.push(new C(0,h,c,k)),h+=k):180===this.angle?(h-=k,g.push(new C(h,0,k,c))):(g.push(new C(h,0,k,c)),h+=k)}this.commitLayers(g,e)}};$.prototype.commitLayers=function(){};$.prototype.commitLinks=function(){for(var a=this.network.edges.j;a.next();)a.value.commit()};
$.prototype.OB=function(){for(var a=this.Fd.j;a.next();){var b=a.value;b instanceof wv&&cx(this,b)}};function cx(a,b){if(null!==b){v&&D.l(b,wv,$,"setPortSpotsTree:v");a.setPortSpots(b);for(var c=b.children,d=c.length,e=0;e<d;e++)cx(a,c[e])}}
$.prototype.setPortSpots=function(a){v&&D.l(a,wv,$,"setPortSpots:v");var b=a.alignment;if(Tv(b)){v&&D.l(a,wv,$,"setPortSpotsBus:v");v&&D.l(b,ya,$,"setPortSpots:align");var c=this.Vf===sv,d=bw(a),e;switch(d){case 0:e=fd;break;case 90:e=gd;break;case 180:e=ed;break;default:e=$c}var g=a.children,h=g.length;switch(b){case Uv:case Jv:for(b=0;b<h;b++){var k=g[b],k=(c?k.uc:k.sc).first();if(null!==k&&(k=k.link,null!==k)){var l=90===d||270===d?ed:$c;if(1===h||b===h-1&&1===h%2)switch(d){case 0:l=ed;break;case 90:l=
$c;break;case 180:l=fd;break;default:l=gd}else 0===b%2&&(l=90===d||270===d?fd:gd);c?(a.setsPortSpot&&(k.Gb=e),a.setsChildPortSpot&&(k.Hb=l)):(a.setsPortSpot&&(k.Gb=l),a.setsChildPortSpot&&(k.Hb=e))}}break;case Vv:l=90===d||270===d?fd:gd;for(d=c?a.sc:a.uc;d.next();)k=d.value.link,null!==k&&(c?(a.setsPortSpot&&(k.Gb=e),a.setsChildPortSpot&&(k.Hb=l)):(a.setsPortSpot&&(k.Gb=l),a.setsChildPortSpot&&(k.Hb=e)));break;case Wv:for(l=90===d||270===d?ed:$c,d=c?a.sc:a.uc;d.next();)k=d.value.link,null!==k&&(c?
(a.setsPortSpot&&(k.Gb=e),a.setsChildPortSpot&&(k.Hb=l)):(a.setsPortSpot&&(k.Gb=l),a.setsChildPortSpot&&(k.Hb=e)))}}else if(c=bw(a),this.Vf===sv)for(e=a.sc;e.next();){if(d=e.value.link,null!==d){if(a.setsPortSpot)if(a.portSpot.kd())switch(c){case 0:d.Gb=fd;break;case 90:d.Gb=gd;break;case 180:d.Gb=ed;break;default:d.Gb=$c}else d.Gb=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.kd())switch(c){case 0:d.Hb=ed;break;case 90:d.Hb=$c;break;case 180:d.Hb=fd;break;default:d.Hb=gd}else d.Hb=a.childPortSpot}}else for(e=
a.uc;e.next();)if(d=e.value.link,null!==d){if(a.setsPortSpot)if(a.portSpot.kd())switch(c){case 0:d.Hb=fd;break;case 90:d.Hb=gd;break;case 180:d.Hb=ed;break;default:d.Hb=$c}else d.Hb=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.kd())switch(c){case 0:d.Gb=ed;break;case 90:d.Gb=$c;break;case 180:d.Gb=fd;break;default:d.Gb=gd}else d.Gb=a.childPortSpot}};function bw(a){a=a.angle;return 45>=a?0:135>=a?90:225>=a?180:315>=a?270:0}
function ew(a){v&&D.l(a,wv,$,"computeLayerSpacing:v");var b=bw(a),b=90===b||270===b,c=a.layerSpacing;if(0<a.layerSpacingParentOverlap)var d=Math.min(1,a.layerSpacingParentOverlap),c=c-(b?a.height*d:a.width*d);c<(b?-a.height:-a.width)&&(c=b?-a.height:-a.width);return c}function fw(a){v&&D.l(a,wv,$,"computeNodeIndent:v");var b=bw(a),b=90===b||270===b,c=a.nodeIndent;if(0<a.nodeIndentPastParent)var d=Math.min(1,a.nodeIndentPastParent),c=c+(b?a.width*d:a.height*d);return c=Math.max(0,c)}
D.defineProperty($,{yM:"roots"},function(){return this.Fd},function(a){this.Fd!==a&&(D.l(a,L,$,"roots"),this.Fd=a,this.N())});D.defineProperty($,{path:"path"},function(){return this.Xs},function(a){this.Xs!==a&&(D.Da(a,$,$,"path"),this.Xs=a,this.N())});D.defineProperty($,{BK:"treeStyle"},function(){return this.Nt},function(a){this.zd!==a&&(D.Da(a,$,$,"treeStyle"),a===tv||a===Mv||a===Nv||a===Lv)&&(this.Nt=a,this.N())});
D.defineProperty($,{iB:"layerStyle"},function(){return this.ow},function(a){this.zd!==a&&(D.Da(a,$,$,"layerStyle"),a===uv||a===Hv||a===Gv)&&(this.ow=a,this.N())});D.defineProperty($,{comments:"comments"},function(){return this.ai},function(a){this.ai!==a&&(D.h(a,"boolean",$,"comments"),this.ai=a,this.N())});D.defineProperty($,{eg:"arrangement"},function(){return this.zd},function(a){this.zd!==a&&(D.Da(a,$,$,"arrangement"),a===vv||a===bx||a===yv)&&(this.zd=a,this.N())});
D.defineProperty($,{RE:"arrangementSpacing"},function(){return this.xg},function(a){D.l(a,Ca,$,"arrangementSpacing");this.xg.O(a)||(this.xg.assign(a),this.N())});D.defineProperty($,{xM:"rootDefaults"},function(){return this.Aa},function(a){this.Aa!==a&&(D.l(a,wv,$,"rootDefaults"),this.Aa=a,this.N())});D.defineProperty($,{UK:"alternateDefaults"},function(){return this.Ba},function(a){this.Ba!==a&&(D.l(a,wv,$,"alternateDefaults"),this.Ba=a,this.N())});
D.defineProperty($,{sorting:"sorting"},function(){return this.Aa.sorting},function(a){this.Aa.sorting!==a&&(D.Da(a,$,$,"sorting"),a===Ov||a===Pv||a===Qv||Rv)&&(this.Aa.sorting=a,this.N())});D.defineProperty($,{comparer:"comparer"},function(){return this.Aa.comparer},function(a){this.Aa.comparer!==a&&(D.h(a,"function",$,"comparer"),this.Aa.comparer=a,this.N())});
D.defineProperty($,{angle:"angle"},function(){return this.Aa.angle},function(a){this.Aa.angle!==a&&(D.h(a,"number",$,"angle"),0===a||90===a||180===a||270===a?(this.Aa.angle=a,this.N()):D.k("TreeLayout.angle must be 0, 90, 180, or 270"))});D.defineProperty($,{alignment:"alignment"},function(){return this.Aa.alignment},function(a){this.Aa.alignment!==a&&(D.Da(a,$,$,"alignment"),this.Aa.alignment=a,this.N())});
D.defineProperty($,{nodeIndent:"nodeIndent"},function(){return this.Aa.nodeIndent},function(a){this.Aa.nodeIndent!==a&&(D.h(a,"number",$,"nodeIndent"),0<=a&&(this.Aa.nodeIndent=a,this.N()))});D.defineProperty($,{nodeIndentPastParent:"nodeIndentPastParent"},function(){return this.Aa.nodeIndentPastParent},function(a){this.Aa.nodeIndentPastParent!==a&&(D.h(a,"number",$,"nodeIndentPastParent"),0<=a&&1>=a&&(this.Aa.nodeIndentPastParent=a,this.N()))});
D.defineProperty($,{nodeSpacing:"nodeSpacing"},function(){return this.Aa.nodeSpacing},function(a){this.Aa.nodeSpacing!==a&&(D.h(a,"number",$,"nodeSpacing"),this.Aa.nodeSpacing=a,this.N())});D.defineProperty($,{layerSpacing:"layerSpacing"},function(){return this.Aa.layerSpacing},function(a){this.Aa.layerSpacing!==a&&(D.h(a,"number",$,"layerSpacing"),this.Aa.layerSpacing=a,this.N())});
D.defineProperty($,{layerSpacingParentOverlap:"layerSpacingParentOverlap"},function(){return this.Aa.layerSpacingParentOverlap},function(a){this.Aa.layerSpacingParentOverlap!==a&&(D.h(a,"number",$,"layerSpacingParentOverlap"),0<=a&&1>=a&&(this.Aa.layerSpacingParentOverlap=a,this.N()))});D.defineProperty($,{compaction:"compaction"},function(){return this.Aa.compaction},function(a){this.Aa.compaction!==a&&(D.Da(a,$,$,"compaction"),a===Zv||a===aw)&&(this.Aa.compaction=a,this.N())});
D.defineProperty($,{breadthLimit:"breadthLimit"},function(){return this.Aa.breadthLimit},function(a){this.Aa.breadthLimit!==a&&(D.h(a,"number",$,"breadthLimit"),0<=a&&(this.Aa.breadthLimit=a,this.N()))});D.defineProperty($,{rowSpacing:"rowSpacing"},function(){return this.Aa.rowSpacing},function(a){this.Aa.rowSpacing!==a&&(D.h(a,"number",$,"rowSpacing"),this.Aa.rowSpacing=a,this.N())});
D.defineProperty($,{rowIndent:"rowIndent"},function(){return this.Aa.rowIndent},function(a){this.Aa.rowIndent!==a&&(D.h(a,"number",$,"rowIndent"),0<=a&&(this.Aa.rowIndent=a,this.N()))});D.defineProperty($,{commentSpacing:"commentSpacing"},function(){return this.Aa.commentSpacing},function(a){this.Aa.commentSpacing!==a&&(D.h(a,"number",$,"commentSpacing"),this.Aa.commentSpacing=a,this.N())});
D.defineProperty($,{commentMargin:"commentMargin"},function(){return this.Aa.commentMargin},function(a){this.Aa.commentMargin!==a&&(D.h(a,"number",$,"commentMargin"),this.Aa.commentMargin=a,this.N())});D.defineProperty($,{setsPortSpot:"setsPortSpot"},function(){return this.Aa.setsPortSpot},function(a){this.Aa.setsPortSpot!==a&&(D.h(a,"boolean",$,"setsPortSpot"),this.Aa.setsPortSpot=a,this.N())});
D.defineProperty($,{portSpot:"portSpot"},function(){return this.Aa.portSpot},function(a){D.l(a,S,$,"portSpot");this.Aa.portSpot.O(a)||(this.Aa.portSpot=a,this.N())});D.defineProperty($,{setsChildPortSpot:"setsChildPortSpot"},function(){return this.Aa.setsChildPortSpot},function(a){this.Aa.setsChildPortSpot!==a&&(D.h(a,"boolean",$,"setsChildPortSpot"),this.Aa.setsChildPortSpot=a,this.N())});
D.defineProperty($,{childPortSpot:"childPortSpot"},function(){return this.Aa.childPortSpot},function(a){D.l(a,S,$,"childPortSpot");this.Aa.childPortSpot.O(a)||(this.Aa.childPortSpot=a,this.N())});D.defineProperty($,{eL:"alternateSorting"},function(){return this.Ba.sorting},function(a){this.Ba.sorting!==a&&(D.Da(a,$,$,"alternateSorting"),a===Ov||a===Pv||a===Qv||Rv)&&(this.Ba.sorting=a,this.N())});
D.defineProperty($,{TK:"alternateComparer"},function(){return this.Ba.comparer},function(a){this.Ba.comparer!==a&&(D.h(a,"function",$,"alternateComparer"),this.Ba.comparer=a,this.N())});D.defineProperty($,{NK:"alternateAngle"},function(){return this.Ba.angle},function(a){this.Ba.angle!==a&&(D.h(a,"number",$,"alternateAngle"),0===a||90===a||180===a||270===a)&&(this.Ba.angle=a,this.N())});
D.defineProperty($,{MK:"alternateAlignment"},function(){return this.Ba.alignment},function(a){this.Ba.alignment!==a&&(D.Da(a,$,$,"alternateAlignment"),this.Ba.alignment=a,this.N())});D.defineProperty($,{XK:"alternateNodeIndent"},function(){return this.Ba.nodeIndent},function(a){this.Ba.nodeIndent!==a&&(D.h(a,"number",$,"alternateNodeIndent"),0<=a&&(this.Ba.nodeIndent=a,this.N()))});
D.defineProperty($,{YK:"alternateNodeIndentPastParent"},function(){return this.Ba.nodeIndentPastParent},function(a){this.Ba.nodeIndentPastParent!==a&&(D.h(a,"number",$,"alternateNodeIndentPastParent"),0<=a&&1>=a&&(this.Ba.nodeIndentPastParent=a,this.N()))});D.defineProperty($,{ZK:"alternateNodeSpacing"},function(){return this.Ba.nodeSpacing},function(a){this.Ba.nodeSpacing!==a&&(D.h(a,"number",$,"alternateNodeSpacing"),this.Ba.nodeSpacing=a,this.N())});
D.defineProperty($,{VK:"alternateLayerSpacing"},function(){return this.Ba.layerSpacing},function(a){this.Ba.layerSpacing!==a&&(D.h(a,"number",$,"alternateLayerSpacing"),this.Ba.layerSpacing=a,this.N())});D.defineProperty($,{WK:"alternateLayerSpacingParentOverlap"},function(){return this.Ba.layerSpacingParentOverlap},function(a){this.Ba.layerSpacingParentOverlap!==a&&(D.h(a,"number",$,"alternateLayerSpacingParentOverlap"),0<=a&&1>=a&&(this.Ba.layerSpacingParentOverlap=a,this.N()))});
D.defineProperty($,{SK:"alternateCompaction"},function(){return this.Ba.compaction},function(a){this.Ba.compaction!==a&&(D.Da(a,$,$,"alternateCompaction"),a===Zv||a===aw)&&(this.Ba.compaction=a,this.N())});D.defineProperty($,{OK:"alternateBreadthLimit"},function(){return this.Ba.breadthLimit},function(a){this.Ba.breadthLimit!==a&&(D.h(a,"number",$,"alternateBreadthLimit"),0<=a&&(this.Ba.breadthLimit=a,this.N()))});
D.defineProperty($,{bL:"alternateRowSpacing"},function(){return this.Ba.rowSpacing},function(a){this.Ba.rowSpacing!==a&&(D.h(a,"number",$,"alternateRowSpacing"),this.Ba.rowSpacing=a,this.N())});D.defineProperty($,{aL:"alternateRowIndent"},function(){return this.Ba.rowIndent},function(a){this.Ba.rowIndent!==a&&(D.h(a,"number",$,"alternateRowIndent"),0<=a&&(this.Ba.rowIndent=a,this.N()))});
D.defineProperty($,{RK:"alternateCommentSpacing"},function(){return this.Ba.commentSpacing},function(a){this.Ba.commentSpacing!==a&&(D.h(a,"number",$,"alternateCommentSpacing"),this.Ba.commentSpacing=a,this.N())});D.defineProperty($,{QK:"alternateCommentMargin"},function(){return this.Ba.commentMargin},function(a){this.Ba.commentMargin!==a&&(D.h(a,"number",$,"alternateCommentMargin"),this.Ba.commentMargin=a,this.N())});
D.defineProperty($,{dL:"alternateSetsPortSpot"},function(){return this.Ba.setsPortSpot},function(a){this.Ba.setsPortSpot!==a&&(D.h(a,"boolean",$,"alternateSetsPortSpot"),this.Ba.setsPortSpot=a,this.N())});D.defineProperty($,{$K:"alternatePortSpot"},function(){return this.Ba.portSpot},function(a){D.l(a,S,$,"alternatePortSpot");this.Ba.portSpot.O(a)||(this.Ba.portSpot=a,this.N())});
D.defineProperty($,{cL:"alternateSetsChildPortSpot"},function(){return this.Ba.setsChildPortSpot},function(a){this.Ba.setsChildPortSpot!==a&&(D.h(a,"boolean",$,"alternateSetsChildPortSpot"),this.Ba.setsChildPortSpot=a,this.N())});D.defineProperty($,{PK:"alternateChildPortSpot"},function(){return this.Ba.childPortSpot},function(a){D.l(a,S,$,"alternateChildPortSpot");this.Ba.childPortSpot.O(a)||(this.Ba.childPortSpot=a,this.N())});var rv;$.PathDefault=rv=D.s($,"PathDefault",-1);var sv;
$.PathDestination=sv=D.s($,"PathDestination",0);var zv;$.PathSource=zv=D.s($,"PathSource",1);var Ov;$.SortingForwards=Ov=D.s($,"SortingForwards",10);var Pv;$.SortingReverse=Pv=D.s($,"SortingReverse",11);var Qv;$.SortingAscending=Qv=D.s($,"SortingAscending",12);var Rv;$.SortingDescending=Rv=D.s($,"SortingDescending",13);var Tw;$.AlignmentCenterSubtrees=Tw=D.s($,"AlignmentCenterSubtrees",20);var Sv;$.AlignmentCenterChildren=Sv=D.s($,"AlignmentCenterChildren",21);var cw;
$.AlignmentStart=cw=D.s($,"AlignmentStart",22);var dw;$.AlignmentEnd=dw=D.s($,"AlignmentEnd",23);var Uv;$.AlignmentBus=Uv=D.s($,"AlignmentBus",24);var Jv;$.AlignmentBusBranching=Jv=D.s($,"AlignmentBusBranching",25);var Vv;$.AlignmentTopLeftBus=Vv=D.s($,"AlignmentTopLeftBus",26);var Wv;$.AlignmentBottomRightBus=Wv=D.s($,"AlignmentBottomRightBus",27);var Zv;$.CompactionNone=Zv=D.s($,"CompactionNone",30);var aw;$.CompactionBlock=aw=D.s($,"CompactionBlock",31);var tv;
$.StyleLayered=tv=D.s($,"StyleLayered",40);var Nv;$.StyleLastParents=Nv=D.s($,"StyleLastParents",41);var Mv;$.StyleAlternating=Mv=D.s($,"StyleAlternating",42);var Lv;$.StyleRootOnly=Lv=D.s($,"StyleRootOnly",43);var vv;$.ArrangementVertical=vv=D.s($,"ArrangementVertical",50);var bx;$.ArrangementHorizontal=bx=D.s($,"ArrangementHorizontal",51);var yv;$.ArrangementFixedRoots=yv=D.s($,"ArrangementFixedRoots",52);var uv;$.LayerIndividual=uv=D.s($,"LayerIndividual",60);var Hv;
$.LayerSiblings=Hv=D.s($,"LayerSiblings",61);var Gv;$.LayerUniform=Gv=D.s($,"LayerUniform",62);function xv(){ua.call(this)}D.Sa(xv,ua);D.ka("TreeNetwork",xv);xv.prototype.createVertex=function(){return new wv};xv.prototype.createEdge=function(){return new dx};
function wv(){va.call(this);this.initialized=!1;this.parent=null;this.children=[];this.maxGenerationCount=this.maxChildrenCount=this.descendantCount=this.level=0;this.comments=null;this.sa=new N(0,0);this.ub=new Ca(0,0);this.Va=new N(0,0);this.Jq=this.Iq=this.gK=!1;this.av=this.Hu=null;this.sorting=Ov;this.comparer=xs;this.angle=0;this.alignment=Sv;this.nodeIndentPastParent=this.nodeIndent=0;this.nodeSpacing=20;this.layerSpacing=50;this.layerSpacingParentOverlap=0;this.compaction=aw;this.breadthLimit=
0;this.rowSpacing=25;this.commentSpacing=this.rowIndent=10;this.commentMargin=20;this.setsPortSpot=!0;this.portSpot=Vc;this.setsChildPortSpot=!0;this.childPortSpot=Vc}D.Sa(wv,va);D.ka("TreeVertex",wv);
wv.prototype.copyInheritedPropertiesFrom=function(a){null!==a&&(this.sorting=a.sorting,this.comparer=a.comparer,this.angle=a.angle,this.alignment=a.alignment,this.nodeIndent=a.nodeIndent,this.nodeIndentPastParent=a.nodeIndentPastParent,this.nodeSpacing=a.nodeSpacing,this.layerSpacing=a.layerSpacing,this.layerSpacingParentOverlap=a.layerSpacingParentOverlap,this.compaction=a.compaction,this.breadthLimit=a.breadthLimit,this.rowSpacing=a.rowSpacing,this.rowIndent=a.rowIndent,this.commentSpacing=a.commentSpacing,
this.commentMargin=a.commentMargin,this.setsPortSpot=a.setsPortSpot,this.portSpot=a.portSpot,this.setsChildPortSpot=a.setsChildPortSpot,this.childPortSpot=a.childPortSpot)};D.w(wv,{Hn:"childrenCount"},function(){return this.children.length});D.defineProperty(wv,{wM:"relativePosition"},function(){return this.sa},function(a){this.sa.set(a)});D.defineProperty(wv,{JM:"subtreeSize"},function(){return this.ub},function(a){this.ub.set(a)});
D.defineProperty(wv,{IM:"subtreeOffset"},function(){return this.Va},function(a){this.Va.set(a)});function dx(){wa.call(this);this.dt=new N(0,0)}D.Sa(dx,wa);D.ka("TreeEdge",dx);
dx.prototype.commit=function(){var a=this.link;if(null!==a&&!a.Jj){var b=this.network.Zb,c=null,d=null;switch(b.Vf){case sv:c=this.fromVertex;d=this.toVertex;break;case zv:c=this.toVertex;d=this.fromVertex;break;default:D.k("Unhandled path value "+b.Vf.toString())}if(null!==c&&null!==d)if(b=this.dt,0!==b.x||0!==b.y||c.gK){var d=c.qb,e=bw(c),g=ew(c),h=c.rowSpacing;a.zo();var k=a.lf===Tj,l=a.ic,m=0,n,p;a.Dm();if(l||k){for(m=2;4<a.ta;)a.EG(2);n=a.m(1);p=a.m(2)}else{for(m=1;3<a.ta;)a.EG(1);n=a.m(0);p=
a.m(a.ta-1)}var q=a.m(a.ta-1),r=0;0===e?(c.alignment===dw?(r=d.bottom+b.y,0===b.y&&n.y>q.y+c.rowIndent&&(r=Math.min(r,Math.max(n.y,r-fw(c))))):c.alignment===cw?(r=d.top+b.y,0===b.y&&n.y<q.y-c.rowIndent&&(r=Math.max(r,Math.min(n.y,r+fw(c))))):r=c.Iq||c.Jq&&1===c.maxGenerationCount?d.top-c.Va.y+b.y:d.y+d.height/2+b.y,k?(a.B(m,n.x,r),m++,a.B(m,d.right+g,r),m++,a.B(m,d.right+g+(b.x-h)/3,r),m++,a.B(m,d.right+g+2*(b.x-h)/3,r),m++,a.B(m,d.right+g+(b.x-h),r),m++,a.B(m,p.x,r)):(l&&(a.B(m,d.right+g/2,n.y),
m++),a.B(m,d.right+g/2,r),m++,a.B(m,d.right+g+b.x-(l?h/2:h),r),m++,l&&a.B(m,a.m(m-1).x,p.y))):90===e?(c.alignment===dw?(r=d.right+b.x,0===b.x&&n.x>q.x+c.rowIndent&&(r=Math.min(r,Math.max(n.x,r-fw(c))))):c.alignment===cw?(r=d.left+b.x,0===b.x&&n.x<q.x-c.rowIndent&&(r=Math.max(r,Math.min(n.x,r+fw(c))))):r=c.Iq||c.Jq&&1===c.maxGenerationCount?d.left-c.Va.x+b.x:d.x+d.width/2+b.x,k?(a.B(m,r,n.y),m++,a.B(m,r,d.bottom+g),m++,a.B(m,r,d.bottom+g+(b.y-h)/3),m++,a.B(m,r,d.bottom+g+2*(b.y-h)/3),m++,a.B(m,r,d.bottom+
g+(b.y-h)),m++,a.B(m,r,p.y)):(l&&(a.B(m,n.x,d.bottom+g/2),m++),a.B(m,r,d.bottom+g/2),m++,a.B(m,r,d.bottom+g+b.y-(l?h/2:h)),m++,l&&a.B(m,p.x,a.m(m-1).y))):180===e?(c.alignment===dw?(r=d.bottom+b.y,0===b.y&&n.y>q.y+c.rowIndent&&(r=Math.min(r,Math.max(n.y,r-fw(c))))):c.alignment===cw?(r=d.top+b.y,0===b.y&&n.y<q.y-c.rowIndent&&(r=Math.max(r,Math.min(n.y,r+fw(c))))):r=c.Iq||c.Jq&&1===c.maxGenerationCount?d.top-c.Va.y+b.y:d.y+d.height/2+b.y,k?(a.B(m,n.x,r),m++,a.B(m,d.left-g,r),m++,a.B(m,d.left-g+(b.x+
h)/3,r),m++,a.B(m,d.left-g+2*(b.x+h)/3,r),m++,a.B(m,d.left-g+(b.x+h),r),m++,a.B(m,p.x,r)):(l&&(a.B(m,d.left-g/2,n.y),m++),a.B(m,d.left-g/2,r),m++,a.B(m,d.left-g+b.x+(l?h/2:h),r),m++,l&&a.B(m,a.m(m-1).x,p.y))):270===e?(c.alignment===dw?(r=d.right+b.x,0===b.x&&n.x>q.x+c.rowIndent&&(r=Math.min(r,Math.max(n.x,r-fw(c))))):c.alignment===cw?(r=d.left+b.x,0===b.x&&n.x<q.x-c.rowIndent&&(r=Math.max(r,Math.min(n.x,r+fw(c))))):r=c.Iq||c.Jq&&1===c.maxGenerationCount?d.left-c.Va.x+b.x:d.x+d.width/2+b.x,k?(a.B(m,
r,n.y),m++,a.B(m,r,d.top-g),m++,a.B(m,r,d.top-g+(b.y+h)/3),m++,a.B(m,r,d.top-g+2*(b.y+h)/3),m++,a.B(m,r,d.top-g+(b.y+h)),m++,a.B(m,r,p.y)):(l&&(a.B(m,n.x,d.top-g/2),m++),a.B(m,r,d.top-g/2),m++,a.B(m,r,d.top-g+b.y+(l?h/2:h)),m++,l&&a.B(m,p.x,a.m(m-1).y))):D.k("Invalid angle "+e);a.Aj()}else e=c,g=d,v&&D.l(e,wv,dx,"adjustRouteForAngleChange:parent"),v&&D.l(g,wv,dx,"adjustRouteForAngleChange:child"),a=this.link,c=bw(e),c!==bw(g)&&(b=ew(e),d=e.qb,e=g.qb,0===c&&e.left-d.right<b+1||90===c&&e.top-d.bottom<
b+1||180===c&&d.left-e.right<b+1||270===c&&d.top-e.bottom<b+1||(a.zo(),e=a.lf===Tj,g=a.ic,h=Tv(this.fromVertex.alignment),a.Dm(),0===c?(c=d.right+b/2,e?4===a.ta&&(b=a.m(3).y,a.ha(1,c-20,a.m(1).y),a.B(2,c-20,b),a.B(3,c,b),a.B(4,c+20,b),a.ha(5,a.m(5).x,b)):g?h?a.ha(3,a.m(2).x,a.m(4).y):6===a.ta&&(a.ha(2,c,a.m(2).y),a.ha(3,c,a.m(3).y)):4===a.ta?a.B(2,c,a.m(2).y):3===a.ta?a.ha(1,c,a.m(2).y):2===a.ta&&a.B(1,c,a.m(1).y)):90===c?(b=d.bottom+b/2,e?4===a.ta&&(c=a.m(3).x,a.ha(1,a.m(1).x,b-20),a.B(2,c,b-20),
a.B(3,c,b),a.B(4,c,b+20),a.ha(5,c,a.m(5).y)):g?h?a.ha(3,a.m(2).x,a.m(4).y):6===a.ta&&(a.ha(2,a.m(2).x,b),a.ha(3,a.m(3).x,b)):4===a.ta?a.B(2,a.m(2).x,b):3===a.ta?a.ha(1,a.m(2).x,b):2===a.ta&&a.B(1,a.m(1).x,b)):180===c?(c=d.left-b/2,e?4===a.ta&&(b=a.m(3).y,a.ha(1,c+20,a.m(1).y),a.B(2,c+20,b),a.B(3,c,b),a.B(4,c-20,b),a.ha(5,a.m(5).x,b)):g?h?a.ha(3,a.m(2).x,a.m(4).y):6===a.ta&&(a.ha(2,c,a.m(2).y),a.ha(3,c,a.m(3).y)):4===a.ta?a.B(2,c,a.m(2).y):3===a.ta?a.ha(1,c,a.m(2).y):2===a.ta&&a.B(1,c,a.m(1).y)):270===
c&&(b=d.top-b/2,e?4===a.ta&&(c=a.m(3).x,a.ha(1,a.m(1).x,b+20),a.B(2,c,b+20),a.B(3,c,b),a.B(4,c,b-20),a.ha(5,c,a.m(5).y)):g?h?a.ha(3,a.m(2).x,a.m(4).y):6===a.ta&&(a.ha(2,a.m(2).x,b),a.ha(3,a.m(3).x,b)):4===a.ta?a.B(2,a.m(2).x,b):3===a.ta?a.ha(1,a.m(2).x,b):2===a.ta&&a.B(1,a.m(1).x,b)),a.Aj()))}};D.defineProperty(dx,{vM:"relativePoint"},function(){return this.dt},function(a){this.dt.set(a)});function ex(){P.call(this);this.bf=null}D.Sa(ex,P);
ex.prototype.cloneProtected=function(a){P.prototype.cloneProtected.call(this,a);a.element=this.bf.cloneNode(!0)};ex.prototype.toString=function(){return"HTMLHost("+this.bf.toString()+")#"+D.Jd(this)};
ex.prototype.Rk=function(a,b){var c=this.bf;if(null!==c){var d=this.eb(mc);d.x-=this.Z.width/2;d.y-=this.Z.height/2;d.x-=this.Z.x;d.y-=this.Z.y;var d=b.cC(d),e=b.Dj;null===e||e.contains(c)||e.appendChild(c);e=this.transform;c.style.transform="matrix("+e.m11+","+e.m12+","+e.m21+","+e.m22+","+e.dx+","+e.dy+")";c.style.transformOrigin="0 0";e=d.y;c.style.left=d.x+"px";c.style.top=e+"px"}};
ex.prototype.io=function(a,b,c,d){var e=this.Ea;isFinite(e.width)&&(a=e.width);isFinite(e.height)&&(b=e.height);var e=this.of,g=this.bh;c=Math.max(c,g.width);d=Math.max(d,g.height);a=Math.min(e.width,a);b=Math.min(e.height,b);a=Math.max(c,a);b=Math.max(d,b);c=this.bf;null!==c&&(b=c.getBoundingClientRect(),a=b.width,b=b.height);Cb(this.ed,a,b);Jo(this,0,0,a,b)};ex.prototype.yj=function(a,b,c,d){Oo(this,a,b,c,d)};D.w(ex,{Ga:"naturalBounds"},function(){return this.ed});
D.defineProperty(ex,{element:"element"},function(){return this.bf},function(a){var b=this.bf;b!==a&&(a instanceof HTMLElement||D.k("HTMLHost.element must be an instance of Element."),this.bf=a,a.className="HTMLHost",this.i("element",b,a),this.ra())});ba.version="1.8.2";
window&&(window.module&&"object"===typeof window.module&&"object"===typeof window.module.exports?window.module.exports=ba:window.define&&"function"===typeof window.define&&window.define.amd?(window.go=ba,window.define(ba)):window.go=ba);"undefined"!==typeof module&&"object"===typeof module.exports&&(module.exports=ba); })(window);
