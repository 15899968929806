import $$ from "dom7";
import inspectionDetail from "../pages/inspectionDetail.html";
import Template7 from "template7";

export default {
  name: "inspectionDetailForm",
  path: "/inspectionDetailForm/",
  component: {
    template: inspectionDetail,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = []
          args = [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.work.order.interface",
              method,
              obj
            ]
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args
          }
        };
      },
      //获取工程师列表的函数
      getEngineerList(page){
        let that = this
        let promise = new Promise((resolve, reject)=>{
            var vuserid = page.app.data.session.userid;
            var dingding_code = page.app.data.dingding_code;
            var feishu_userid = page.app.data.feishu_userid;
            var repairId = page.app.data.inspection_data.work_order_id
            if (page.app.data.session.source == 'dingding') {
                var paramrs = this.getParams("select_engineer", {
                    "dingding_code": dingding_code,
                    "work_order_id": repairId
                });
            } else if (page.app.data.session.source == 'feishu') {
                var paramrs = this.getParams("select_engineer", {
                    "feishu_userid": feishu_userid,
                    "work_order_id": repairId
                });
            } else {
                var paramrs = this.getParams("select_engineer", {
                    "wechat_name": vuserid,
                    "work_order_id": repairId
                });
            }
            page.app.request.postJSON(page.app.data.url, paramrs ,function (result) {
                if(result.result){
                    that.$setState({
                        engineerList: result.result.data.engineer
                    })
                    $('#engineer').val(page.app.data.inspection_data.default_engineer_id)
                    $('.tigger .item-after').text(page.app.data.inspection_data.default_engineer_name)
                    resolve(result)
                }else{
                    page.app.dialog.alert('系统错误！','系统提示')
                }          
            });
        })
        return promise
      },
      confirm(){
          let engineer = $('.tigger .item-after').text()
          if(engineer === '请选择' || engineer == ''){
            app.dialog.alert('请先选择工程师！',"温馨提示")
          }else{
            var vuserid = app.data.session.userid;
            var dingding_code = app.data.dingding_code;
            var feishu_userid = app.data.feishu_userid;
            var repairId = app.data.inspection_data.work_order_id
            if(app.data.inspection_data.button_assign){  // 判断是分配还是重新分配
                var assign = 'confirm_assign'
              }else{
                var assign = 'confirm_reassign'
              }
              if (app.data.session.source == 'dingding') {
                var paramrs = this.getParams(assign, {
                    "dingding_code": dingding_code,
                    "work_order_id": repairId,
                    "engineer_id": $('#engineer').val()?$('#engineer').val():app.data.inspection_data.default_engineer_id
                });
              } else if (app.data.session.source == 'feishu') {
                var paramrs = this.getParams(assign, {
                    "feishu_userid": feishu_userid,
                    "work_order_id": repairId,
                    "engineer_id": $('#engineer').val()?$('#engineer').val():app.data.inspection_data.default_engineer_id
                });
            } else {
                var paramrs = this.getParams(assign, {
                    "wechat_name": vuserid,
                    "work_order_id": repairId,
                    "engineer_id": $('#engineer').val()?$('#engineer').val():app.data.inspection_data.default_engineer_id
                });
            }
            app.request.postJSON(app.data.url, paramrs ,function (result) {
                if(result.result){
                    app.popup.get('.popup-about').close(false)
                    let toast = app.toast.create({
                        text: '分配成功！',
                        position: "center",
                        closeTimeout: 2000
                      });
                      toast.open();
                    app.router.back()
                  }else{
                    app.dialog.alert('分配失败！','系统提示')
                  }
            })
          }
      },
    },
    on: {
      pageInit: function (e, page) {
        if(this.info.button_reassign || this.info.button_assign)this.getEngineerList(page)
      },
      pageMounted: function (e, page) {
        console.log("pageMounted")
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        let smartSelect = page.app.smartSelect.get('.smart-select');
        $('.tigger').on('click', function (page) {
            smartSelect.open()
        });
        smartSelect.on('close', function () { 
            if(smartSelect.$valueEl.text()){
                $('.tigger .item-after').text(smartSelect.$valueEl.text())
            }
         })
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};