import $$ from 'dom7'
import accumulationFundForm from '../pages/accumulationFundForm.html'
import Template7 from 'template7'
import 'weui'
import weui from 'weui.js'

export default {
  name: 'accumulationFundForm',
  path: '/accumulationFundForm/',
  component: {
    template: accumulationFundForm,
    data: () => {
      return {
        isFabBtn: false
      }
    },
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.chanyi.pro.fund.purchase.application.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      //下载文件
      downloadFile(e) {
        window.open(e.currentTarget.dataset.url);
      },
      //文件上传后的处理函数
      filechange() {
        Array.from(this.$$("#file-attach")[0].files).forEach((ele) => {
          if (ele.type.indexOf("image") > -1) {
            this.fileList.images.push(ele);
          } else {
            this.fileList.files.push(ele);
          }
        });
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files,
        });
        //处理图片上传后事件冲突的问题
        $(".pb-standalone-dark").off("click");
        console.log(app.data);
        $(".pb-standalone-dark").on("click", function (event) {
          app.data.asset_data.myPhotoBrowserDark.open(
            Number(event.currentTarget.dataset.index)
          );
        });
        $(".addimg").off("click");
        $(".addimg").on("click", function (event) {
          $("#file-attach").trigger("click");
        });
        app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images;
        // 读取文件的函数
        function readAndPreview(file) {
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              file.url = this.result;
              file.caption = file.name;
            },
            false
          );
          if (!file.url) reader.readAsDataURL(file);
        }
        [].forEach.call(this.fileList.images, readAndPreview);
        setTimeout(() => {
          this.fileList.images.forEach((ele, index) => {
            $("img")[index].src = ele.url;
          });
        }, 100);
        console.log(this.fileList);
      },
      // 删除上传的文件
      delFile(val, attachment_id) {
        let e = window.event;
        e.stopPropagation();
        // if (!this.delete_attachment_ids) this.delete_attachment_ids = [];
        if (e.currentTarget.dataset.id)
          this.delete_attachment_ids.push(Number(e.currentTarget.dataset.id));
        if (val == "img") {
          // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            images: this.fileList.images,
          });

          //处理图片删除后事件冲突的问题
          $(".addimg").off("click");
          $(".addimg").on("click", function (event) {
            $("#file-attach").trigger("click");
          });

          app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images;
          this.fileList.images.forEach((ele, index) => {
            ele.caption = ele.name;
            $("img")[index].src = ele.url;
          });
        } else {
          // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            files: this.fileList.files,
          });
        }
      },
      // 上传文件到服务器的函数
      submitFiles(val) {
        let pro = new Promise((resolve, reject) => {
          var formData = new FormData()
          let post_url = this.$app.data.base_url + '/web/map_upload_attachment'
          formData.append('record_id', val.record_id)
          formData.append('model_name', val.record_model)
          formData.append('db', this.$app.data.dbname)
          formData.append('id', this.$app.data.userid)
          formData.append('pwd', this.$app.data.password)
          for (var i = 0; i < this.fileList.images.length; i++) {
            var file = this.fileList.images[i];
            if (!file.attachment_id) formData.append("file", file)
          }
          for (var i = 0; i < this.fileList.files.length; i++) {
            var file = this.fileList.files[i];
            if (!file.attachment_id) formData.append("file", file)
          }
          if (this.$app.data.session.source === 'dingding') {
            formData.append('dingding_code', this.$app.data.dingding_code)
          } else if (this.$app.data.session.source === 'feishu') {
            formData.append('feishu_userid', this.$app.data.feishu_userid)
          } else {
            formData.append('wechat_name', this.$app.data.session.userid)
          }
          this.$app.request.post(post_url, formData, (result) => {
            if (JSON.parse(result).state === 'ok') {
              var toast = this.$app.toast.create({
                text: '恭喜您,操作成功',
                position: 'center',
                closeTimeout: 2000
              })
              toast.open()
              // self.$app.router.navigate('/workOrderCompleted/', {})
            } else {
              var toast = this.$app.toast.create({
                text: '很遗憾,操作失败',
                position: 'center',
                closeTimeout: 2000
              })
              toast.open()
            }
            resolve()
          })
        })
        return pro
      },
      save() {
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="accumulationFund-form"]')[0].f7Page
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        let formData = app.form.convertToData("#accumulationFund_editForm");

        if (formData.provident_fund_unit_part == '') {
          app.dialog.alert("请输入单位月缴存额！", "系统提示");
          return;
        } else if (formData.provident_fund_personal_part == '') {
          app.dialog.alert("请输入个人月缴存额！", "系统提示");
          return;
        } else if (formData.starting_time_id == '') {
          app.dialog.alert("请选择起始年月！", "系统提示");
          return;
        }

        if (app.data.session.source == "dingding") {
          //保存的参数
          var saveParams = this.getParams("create_fund_purchase_application", {
            dingding_code: dingding_code,
            applicant_id: this.info.applicant_id,
            department_id: this.info.department_id,
            provident_fund_unit_part: formData.provident_fund_unit_part * 1,
            provident_fund_personal_part: formData.provident_fund_personal_part * 1,
            starting_time_id: formData.starting_time_id.slice(0, 7),
            note: formData.note
          });
        } else if(app.data.session.source == "feishu") {
          //保存的参数
          var saveParams = this.getParams("create_fund_purchase_application", {
            feishu_userid: feishu_userid,
            applicant_id: this.info.applicant_id,
            department_id: this.info.department_id,
            provident_fund_unit_part: formData.provident_fund_unit_part * 1,
            provident_fund_personal_part: formData.provident_fund_personal_part * 1,
            starting_time_id: formData.starting_time_id.slice(0, 7),
            note: formData.note
          });
        } else {
          var saveParams = this.getParams("create_fund_purchase_application", {
            wechat_name: vuserid,
            applicant_id: this.info.applicant_id,
            department_id: this.info.department_id,
            provident_fund_unit_part: formData.provident_fund_unit_part * 1,
            provident_fund_personal_part: formData.provident_fund_personal_part * 1,
            starting_time_id: formData.starting_time_id.slice(0, 7),
            note: formData.note
          });
        }

        console.log('提交保存', saveParams, self.fileList);
        app.preloader.show();
        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result.state == 'success') {
            console.log(result.result, "保存工作对象成功");
            console.log('456456', self.fileList)
            if (self.fileList && (!self.fileList.images.every(ele => ele.attachment_id) || !self.fileList.files.every(ele => ele.attachment_id))) {
              console.log('123123')
              self.submitFiles({
                record_id: result.result.purchase_application_id,
                record_model: result.result.model
              }).then(() => {
                app.preloader.hide();
                app.router.back('/accumulationFundIndex/', {
                  force: true
                })

              })
            } else {
              app.preloader.hide();
              app.router.back('/accumulationFundIndex/', {
                force: true
              })
            }
          } else if (result.result.state == 'err') {
            app.preloader.hide();
            app.dialog.alert(result.result.err, "系统提示");
          } else {
            app.preloader.hide();
            app.dialog.alert('获取信息错误！', "系统提示");
          }
        });
      },

      //文件上传后的处理函数
      filechange() {
        Array.from(this.$$('#file-attach')[0].files).forEach(ele => {
          if (ele.type.indexOf('image') > -1) {
            this.fileList.images.push(ele)
          } else {
            this.fileList.files.push(ele)
          }
        })
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files
        })
        //处理图片上传后事件冲突的问题
        $('.pb-standalone-dark').off('click')
        $('.pb-standalone-dark').on('click', function (event) {
          app.data.asset_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        $('.addimg').off('click')
        $('.addimg').on('click', function (event) {
          $('#file-attach').trigger('click')
        })
        app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images
        // 读取文件的函数
        let self = this

        function readAndPreview(file, index) {
          let reader = new FileReader();
          reader.addEventListener("load", function () {
            file.url = this.result
            file.caption = file.name
            self.fileList.images.forEach((ele) => {
              $('img')[index].src = this.result
            })
          }, false);
          if (!file.url) reader.readAsDataURL(file)
        }
        [].forEach.call(this.fileList.images, readAndPreview)
        // setTimeout(()=>{
        //   this.fileList.images.forEach((ele,index) => {
        //     $('img')[index].src=ele.url
        //   }) 
        // },200)
        console.log(this.fileList)
      },
      // 删除上传的文件
      delFile(val) {
        let e = window.event
        e.stopPropagation()
        if (val == 'img') { // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index), 1)
          this.$setState({
            images: this.fileList.images
          })

          //处理图片删除后事件冲突的问题
          $('.addimg').off('click')
          $('.addimg').on('click', function (event) {
            $('#file-attach').trigger('click')
          })


          app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images
          this.fileList.images.forEach((ele, index) => {
            ele.caption = ele.name
            $('img')[index].src = ele.url
          })
        } else { // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index), 1)
          this.$setState({
            files: this.fileList.files
          })
        }
      },
    },
    on: {
      pageInit: function (e, page) {

        this.$setState({
          images: [],
          files: [],
          fileList: {
            images: [],
            files: [],
          }
        })
        // 照片预览器
        page.app.data.asset_data.myPhotoBrowserDark = app.photoBrowser.create({
          photos: this.fileList.images,
          theme: 'dark'
        });
        $('.pb-standalone-dark').on('click', function (event) {
          page.app.data.asset_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        // 触发上传文件的事件
        $('.addimg').on('click', function (event) {
          console.log('触发上传文件的事件')
          $('#file-attach').trigger('click')
        })
        if (this.info.default_starting_time_id) {
          $('#starting_time_id').val(this.info.default_starting_time_id)
        }
      },
      pageMounted: function (e, page) {
        console.log('pageMounted')
      },
      pageReinit: function (e, page) {
        console.log('pageReinit')
      },
      pageBeforeIn: function (e, page) {
        console.log('pageBeforeIn')
      },
      pageAfterIn: function (e, page) {
        console.log('pageAfterIn')
        page.app.smartSelect.get('.smart-select').on('closed', function () {
          var asset_id = page.app.smartSelect.get('.smart-select').selectEl.value;
          var assetList = page.app.data.asset_data.asset
          if (!assetList) {
            return
          }
          for (var i = 0; i < assetList.length; i++) {
            if (asset_id == assetList[i].id) {
              $$('.place').html(assetList[i].location_id);
            }
          }
        });
      },
      pageBeforeInOut: function (e, page) {
        console.log('pageBeforeInOut')
      },
      pageAfterOut: function (e, page) {
        console.log('pageAfterOut')
      },
      pageBeforeRemove: function (e, page) {
        console.log('pageBeforeRemove')
      }
    }
  }
}