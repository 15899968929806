import $ from "dom7";
import assetListView from "../pages/assetListView.html";
import style from "../styles/asset.less";

export default {
  name: "assetListView",
  path: "/assetListView/",
  component: {
    template: assetListView,
    style: style,
    data: () => {
      return {
        page_index: 1,
        page_count: 6,
        search_value: '',
        allowInfinite: true
      };
    },
    methods: {
      getAssetMaintenanceParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.repair.requisition.interface",
              method,
              obj
            ]
          }
        };
      },
      toDetailAssetForm: function (e) {
        const {
          $$
        } = this;
        var page = $$('.page[data-name="assetListView"]')[0].f7Page;
        var assetInfo = {}
        assetInfo.name = e.currentTarget.dataset.name
        assetInfo.code = e.currentTarget.dataset.code
        assetInfo.id = e.currentTarget.dataset.id
        page.app.router.back('/assetform/',{
          force: true,
          context: {
            assetInfo: assetInfo,
            report_locations: self.report_locations,
            report_location_id: self.report_location_id
          }
        })
        // page.router.navigate("/assetform/", {
        //   history: false,
        //   reloadCurrent: false,
        //   ignoreCache: true,
        //   context: {
        //     assetInfo: assetInfo
        //   }
        // });

      },
      getAssetData: function() {
        var self = this
        const {
          $$
        } = this;
        var page = $$('.page[data-name="assetListView"]')[0].f7Page;
        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;
        return new Promise(function (resolve, reject) {
          if (page.app.data.session.source == 'dingding') {
            var post_list_asset_maintenance_paramrs = self.getAssetMaintenanceParams("get_user_phone_and_assets", {
              "dingding_code": dingding_code,
              page_count:self.page_count,
              page_index:self.page_index,
              search_value:self.search_value
            });
          } else if (page.app.data.session.source == 'feishu') {
            var post_list_asset_maintenance_paramrs = self.getAssetMaintenanceParams("get_user_phone_and_assets", {
              "feishu_userid": feishu_userid,
              page_count:self.page_count,
              page_index:self.page_index,
              search_value:self.search_value
            });
          } else {
            var post_list_asset_maintenance_paramrs = self.getAssetMaintenanceParams("get_user_phone_and_assets", {
              "wechat_name": vuserid,
              page_count:self.page_count,
              page_index:self.page_index,
              search_value:self.search_value
            });
          }
          page.app.preloader.show();
          page.app.request.postJSON(
            page.app.data.url,
            post_list_asset_maintenance_paramrs,
            function (result_list) {
              // console.log(result_list);
              self.page_index ++
              page.app.preloader.hide();
              resolve(result_list)
            }
          );
        })
      },
      doSearch(){
        var self = this
        const {
          $$
        } = this;
        this.page_index = 1,
        this.search_value =  $('#asset-search').val(),
        this.allowInfinite = true;
        var page = $$('.page[data-name="assetListView"]')[0].f7Page;
        page.app.infiniteScroll.create('.infinite-scroll-content');
        this.getAssetData().then(function(res){
          self.$setState({
            assetListViewData: []
          });
          self.$setState({
            assetListViewData: res.result.asset
          });
        })
      }
    },
    on: {
      pageInit: function (e, page) {

        var self = this
        this.getAssetData().then(function(res){
          self.$setState({
            assetListViewData: res.result.asset
          });
        })
        page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
          $(e.currentTarget)
            .parent()
            .parent()
            .prev()
            .find(".searchbar input")
            .blur();
        });


        //未处理 搜索框实例
        var sbtodo = page.app.searchbar.create({
          el: '[data-name="assetListView"] .searchbar-to-do',
          searchContainer: '[data-name="assetListView"] .list-to-do',
          searchIn: '[data-name="assetListView"] .item-text-to-do',
          notFoudEl: '[data-name="assetListView"] .searchbar-not-found-to-do',
          disableButton:true,
        });
        var sbdone = page.app.searchbar.create({
          el: ".searchbar-done",
          searchContainer: ".list-done",
          searchIn: ".item-text-done",
          notFoudEl: ".searchbar-not-found-done",
          disableButton:true,
        });
        $('[data-name="assetListView"] .searchbar-done').hide();

        $('[data-name="assetListView"] .to-do-btn').on("click", function () {
          sbdone.disable();
          $('[data-name="assetListView"] .searchbar-to-do,[data-name="assetListView"] .search-icon-1').show();
        });
        $('[data-name="assetListView"] .done-btn').on("click", function () {
          sbtodo.disable();
          $('[data-name="assetListView"] .searchbar-to-do,[data-name="assetListView"] .search-icon-1').hide();
        });
        sbtodo.disable();
        sbdone.disable();
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
        var self = this

        // Attach 'infinite' event handler
        $('.infinite-scroll-content').on('infinite', function () {

          // Exit, if loading in progress
          if (!self.allowInfinite) return;

          // Set loading flag
          self.allowInfinite = false;

          self.getAssetData().then(function(res){
            if(res.result.asset.length > 0){
              var newArr = self.assetListViewData.concat(res.result.asset)
              self.$setState({
                assetListViewData: newArr
              });
              self.allowInfinite = true;
            }else{
              // Nothing more to load, detach infinite scroll events to prevent unnecessary loadings
              page.app.infiniteScroll.destroy('.infinite-scroll-content');
              // Remove preloader
              $('.infinite-scroll-preloader').remove();
            }
          })

        });
        $('.ptr-content').on('ptr:refresh', function (e) {
          self.page_index = 1,
          self.search_value =  '',
          self.allowInfinite = true;
          page.app.infiniteScroll.create('.infinite-scroll-content');
          self.getAssetData().then(function(res){
            self.$setState({
              assetListViewData: res.result.asset
            });
            app.ptr.done()
          })
        });
      },
      pageReinit: function (e, page) {
        //初始化置空 元数据中的form_data
        if ("form_data" in page.app.data.expense_data) {
          delete page.app.data.expense_data["form_data"];
        }
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        // console.log("pageAfterOut");
        // page.app.toolbar.hide(".expense-toolbar", false);
        // $(".expense-toolbar").css("opacity", 0);
        // $('.expense-checkbox input[type="checkbox"]').prop("checked", false);
        // $(".expense-list-checkbox-icon").css("margin-left", "-80px");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }

};