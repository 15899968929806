import $$ from "dom7";
import maintainDetailView from "../pages/maintainDetailView.html";
import Template7 from "template7";
import app from "../../app";
import { retry } from "async";

// import 'weui';


export default {
  name: "maintainDetailView",
  path: "/maintainDetailView/",
  component: {
    template: maintainDetailView,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = []
          args = [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.work.order.interface",
              method,
              obj
            ]
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args
          }
        };
      },
      pending(type){
        if(type == 0){
          const {
            $$
          } = this;       
          var page = $$('.page[data-name="maintainDetailView"]')[0].f7Page;
          page.router.navigate("/pending/", {
            reloadCurrent: false,
            ignoreCache: true,
            context:{
              info: this.info
            }
          });
        }else{
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          var work_order_id = this.info.work_order_id
          
          if (app.data.session.source == 'dingding') {
              var createParams = this.getParams("action_release", {
                "dingding_code": dingding_code,
                "work_order_id": work_order_id
              });
            } else if (app.data.session.source == 'feishu') {
              var createParams = this.getParams("action_release", {
                "feishu_userid": feishu_userid,
                "work_order_id": work_order_id
              });
            } else {
              var createParams = this.getParams("action_release", {
                "wechat_name": vuserid,
                "work_order_id": work_order_id
              });
  
            }
            app.preloader.show()
            app.request.postJSON(app.data.url, createParams ,(result) => {
              if(result.result&&result.result.state == "success"){ 
                app.preloader.hide()

                app.router.back('/workOrderCompleted/',{
                  force: true
                })
              }else{

                app.preloader.hide()
                app.dialog.alert(result.result.message,'系统提示')
              }          
            });
        }

      },
      //下载文件
      downloadFile(e){
        window.open(e.currentTarget.dataset.url)
      },
      //文件上传后的处理函数
      filechange(){ 
        Array.from(this.$$('#file-attach')[0].files).forEach(ele => {
          if(ele.type.indexOf('image')>-1){
            this.fileList.images.push(ele)
          }else{
            this.fileList.files.push(ele)
          }
        })
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files
        })
        //处理图片上传后事件冲突的问题
        $('.pb-standalone-dark').off('click')
       $('.pb-standalone-dark').on('click', function (event) {
          app.data.maintainDetailView_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        $('.addimg').off('click')
        $('.addimg').on('click',function (event) { 
          $('#file-attach').trigger('click')
       })
        app.data.maintainDetailView_data.myPhotoBrowserDark.params.photos = this.fileList.images
        // 读取文件的函数
        function readAndPreview(file){
          let reader = new FileReader();
          reader.addEventListener("load", function () {
              file.url = this.result
              file.caption = file.name         
          }, false);
          if(!file.url)reader.readAsDataURL(file)    
        }
        [].forEach.call(this.fileList.images, readAndPreview)
        setTimeout(()=>{
          this.fileList.images.forEach((ele,index) => {
            $('img')[index].src=ele.url
          })
        },100)
        console.log(this.fileList)
      },
      // 删除上传的文件
      delFile(val){
        let e = window.event
        e.stopPropagation()
        if(!this.delete_attachment_ids)this.delete_attachment_ids = []
        if(e.currentTarget.dataset.id)this.delete_attachment_ids.push(Number(e.currentTarget.dataset.id))
        if(val == 'img'){ // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index),1)
          this.$setState({
            images: this.fileList.images
          })

          //处理图片删除后事件冲突的问题
          $('.addimg').off('click')
          $('.addimg').on('click',function (event) { 
            $('#file-attach').trigger('click')
         })


          app.data.maintainDetailView_data.myPhotoBrowserDark.params.photos = this.fileList.images
          this.fileList.images.forEach((ele,index) => {
            ele.caption = ele.name
            $('img')[index].src=ele.url
        })
        }else{  // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index),1)
          this.$setState({
            files: this.fileList.files
          })
        }    
      },
    //跳转到添加工作信息的界面
    add(){
      const {
        $$
      } = this;       
      var page = $$('.page[data-name="maintainDetailView"]')[0].f7Page;
      page.router.navigate("/addWorkInfo/", {
        reloadCurrent: false,
        ignoreCache: true,
        context:{
          info: this.lists,
          ismaintain: true   // 标识完工的保养工单
        }
    });
    },
    editline(e){
        // 有权限才能编辑
        if(this.info.complete || this.info.save){
            let index = Number(e.currentTarget.dataset.id)
            let item = app.data.maintainDetailView_data.work_order_complete_info_line_ids[index]
            app.router.navigate("/addWorkInfo/",{
                reloadCurrent: false,
                ignoreCache: true,
                context:{
                info: this.lists,
                isEdit: item,
                lineindex: index,
                ismaintain: true
                }
            })
        }      
    },
    submit(val){
      const self = this
      let repairFees = $('.repairFees').val()
      let work_order_complete_info = this.work_order_complete_info_line_ids
      if(repairFees == ''){
        app.dialog.alert('请输入维修费用！','系统提示')
        return
      }
      var vuserid = app.data.session.userid;
      var dingding_code = app.data.dingding_code;
      var feishu_userid = app.data.feishu_userid;
      var work_order_id = app.data.maintainDetailView_data.work_order_id
      if (app.data.session.source == 'dingding') {
        //完工的参数
          var createParams = this.getParams("action_complete", {
            "work_order_id": work_order_id,
            "dingding_code": dingding_code,
            "maintenance_expense": repairFees,
            "delete_attachment_ids": this.delete_attachment_ids,
            "work_order_complete_info_line_ids": work_order_complete_info
          });
          //保存的参数
          var saveParams = this.getParams("write_complete_info", {
            "work_order_id": work_order_id,
            "dingding_code": dingding_code,
            "maintenance_expense": repairFees,
            "delete_attachment_ids": this.delete_attachment_ids,
            "work_order_complete_info_line_ids": work_order_complete_info
          });
        } else if (app.data.session.source == 'feishu') {
        //完工的参数
          var createParams = this.getParams("action_complete", {
            "work_order_id": work_order_id,
            "feishu_userid": feishu_userid,
            "maintenance_expense": repairFees,
            "delete_attachment_ids": this.delete_attachment_ids,
            "work_order_complete_info_line_ids": work_order_complete_info
          });
          //保存的参数
          var saveParams = this.getParams("write_complete_info", {
            "work_order_id": work_order_id,
            "feishu_userid": feishu_userid,
            "maintenance_expense": repairFees,
            "delete_attachment_ids": this.delete_attachment_ids,
            "work_order_complete_info_line_ids": work_order_complete_info
          });
        } else {
          var createParams = this.getParams("action_complete", {
            "work_order_id": work_order_id,
            "wechat_name": vuserid,
            "maintenance_expense": repairFees,
            "delete_attachment_ids": this.delete_attachment_ids,
            "work_order_complete_info_line_ids": work_order_complete_info
          });
          var saveParams = this.getParams("write_complete_info", {
            "work_order_id": work_order_id,
            "wechat_name": dingding_code,
            "maintenance_expense": repairFees,
            "delete_attachment_ids": this.delete_attachment_ids,
            "work_order_complete_info_line_ids": work_order_complete_info
          });
        }
        if(val == 1){ //1 === 保存的逻辑 
          app.preloader.show()
          app.request.postJSON(app.data.url, saveParams ,(result) => {
            if(result.result&&result.result.state == "success"){ 
              app.preloader.hide()
              if(self.fileList && (!self.fileList.images.every(ele=>ele.attachment_id) || !self.fileList.files.every(ele=>ele.attachment_id))){
                self.submitFiles({record_id: result.result.record_id,record_model:result.result.record_model}).then(()=>{
                  app.preloader.hide()
                  app.router.back('/workOrderCompleted/',{
                    force: true
                  })
                })
              }else{
                var toast = app.toast.create({
                  text: "保存成功！",
                  position: "center",
                  closeTimeout: 2000
                });
                toast.open();
                app.router.back('/workOrderCompleted/',{
                  force: true
                })
                self.delete_attachment_ids = []
              }
            }else{
              app.preloader.hide()
              app.dialog.alert('系统错误！','系统提示')
            }          
          });
        }else{   // 2===完工的逻辑 
          if(work_order_complete_info.length == 0){
            app.dialog.alert('请填写完工工作信息！','系统提示')
          }else{
            app.preloader.show()
            app.dialog.confirm('您确认完成该工作单吗？', function () {
              app.request.postJSON(app.data.url, createParams ,(result) => {
                if(result.result&&result.result.state == 'completed'){ 
                  if(self.fileList && (!self.fileList.images.every(ele=>ele.attachment_id) || !self.fileList.files.every(ele=>ele.attachment_id))){
                    self.submitFiles({record_id: result.result.record_id,record_model:result.result.record_model}).then(()=>{
                      app.preloader.hide()
                      app.router.back('/workOrderCompleted/',{
                        force: true
                      })
                    })
                  }else{
                    app.preloader.hide()
                    var toast = app.toast.create({
                      text: "恭喜您,操作成功！",
                      position: "center",
                      closeTimeout: 2000
                    });
                    toast.open();
                    app.router.back('/workOrderCompleted/',{
                      force: true
                    })
                  }
                }else{
                    app.preloader.hide()
                    app.dialog.alert('系统错误！','系统提示')
                }          
              });
            },function(){
              app.preloader.hide()
            })
          }
        }        
    },
    // 上传文件到服务器的函数
    submitFiles(val){
      let pro = new Promise((resolve,reject) => {
      var formData = new FormData()
      let post_url = this.$app.data.base_url + '/web/map_upload_attachment'
      formData.append('record_id', val.record_id)
      formData.append('model_name', val.record_model)
      formData.append('db', this.$app.data.dbname)
      formData.append('id', this.$app.data.userid)
      formData.append('pwd', this.$app.data.password)
      for (var i = 0; i < this.fileList.images.length; i++) {
        var file = this.fileList.images[i];
        if(!file.attachment_id)formData.append("file", file)
      }
      for (var i = 0; i < this.fileList.files.length; i++) {
        var file = this.fileList.files[i];
        if(!file.attachment_id)formData.append("file", file)
      }
      if (this.$app.data.session.source === 'dingding') {
        formData.append('dingding_code', this.$app.data.dingding_code)
      }else if (this.$app.data.session.source === 'feishu') {
        formData.append('feishu_userid', this.$app.data.feishu_userid)
      }else{
        formData.append('wechat_name', this.$app.data.session.userid)
      }
      this.$app.request.post(post_url, formData,(result) => {
        if (JSON.parse(result).state === 'ok') {
          var toast = this.$app.toast.create({
            text: '恭喜您,操作成功',
            position: 'center',
            closeTimeout: 2000
          })
          toast.open()
          // self.$app.router.navigate('/workOrderCompleted/', {})
        } else {
          var toast = this.$app.toast.create({
            text: '很遗憾,操作失败',
            position: 'center',
            closeTimeout: 2000
          })
          toast.open()
        }
        resolve()
      })
    })
    return pro
    },
    },
    on: {
      pageInit: function (e, page) {
        const self = this
        const vuserid = page.app.data.session.userid;
        const dingding_code = page.app.data.dingding_code;
        const feishu_userid = page.app.data.feishu_userid;
        const id = this.id;
        if (page.app.data.session.source == "dingding") {
          var maintainDetailParams = this.getParams(
            "get_maintain_details",
            {
              work_order_id: id,
              dingding_code: dingding_code,
              identifying_code2: "preventive",
              identifying_code1: "complete",
            }
          );
        } else if (page.app.data.session.source == "feishu") {
          var maintainDetailParams = this.getParams(
            "get_maintain_details",
            {
              work_order_id: id,
              feishu_userid: feishu_userid,
              identifying_code2: "preventive",
              identifying_code1: "complete",
            }
          );
        } else {
          var maintainDetailParams = this.getParams(
            "get_maintain_details",
            {
              work_order_id: id,
              wechat_name: vuserid,
              identifying_code2: "preventive",
              identifying_code1: "complete",
            }
          );
        }
        page.app.request.postJSON(
          page.app.data.url,
          maintainDetailParams,
          function (result) {
            if (result.result) {
              console.log(result.result, "完工保养");
              page.app.data.maintainDetailView_data = result.result.data;
              result.result.data.save =
                result.result.data.extra_state.indexOf("edit") > -1;
              result.result.data.complete =
                result.result.data.extra_state.indexOf("complete") > -1;
              self.$setState({
                info:result.result.data,
                work_order_complete_info_line_ids: result.result.data.work_order_complete_info_line_ids,
                files: result.result.data.attachment_ids.files,
                images: result.result.data.attachment_ids.images
              });
              self.fileList = result.result.data.attachment_ids
              $('.repairFees').val(result.result.data.maintenance_expense)
                self.fileList.images.forEach((ele,index) => {
                  ele.caption = ele.name
                  $('img')[index].src=ele.url
              })
              // 照片预览器
              page.app.data.maintainDetailView_data.myPhotoBrowserDark = app.photoBrowser.create({
                photos : self.fileList.images,
                theme: 'dark'
              });
              $('.pb-standalone-dark').on('click', function (event) {
                page.app.data.maintainDetailView_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
              });
              // 触发上传文件的事件
              $('.addimg').on('click',function (event) { 
                  $('#file-attach').trigger('click')
               })
            } else {
              page.app.dialog.alert("获取信息错误！", "系统提示");
            }
          }
        );

      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {   
        //删掉多余的路由历史
        // let route = page.router.history[page.router.history.length-1]
        // if(route === '/editForm/')page.router.history.splice(page.router.history.length-1,1)
        let arr = page.app.data.maintainDetailView_data.work_order_complete_info_line_ids
        if(arr && arr.length>0){
          this.$setState({
            work_order_complete_info_line_ids: arr 
          })
        }else{
          this.$setState({
            work_order_complete_info_line_ids: []
          })
        }
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};