import expenseAppViewFormNewLine from '../pages/expenseAppViewFormNewLine.html'

export default {
  name: 'expenseappviewformnewline',
  path: '/expenseappviewformnewline/',
  component: {
    template: expenseAppViewFormNewLine,
    methods: {
      dateDiff: function (firstDate, secondDate) {
        var firstDate = new Date(firstDate)
        var secondDate = new Date(secondDate)
        var diff = Math.abs(firstDate.getTime() - secondDate.getTime())
        var result = parseInt(diff / (1000 * 60 * 60 * 24))
        return result
      },
      submit: function (e) {
        let self = this
        const {
          $$
        } = this
        let page = $$('.page[data-name="expenseapp-form-add-line"]')[0].f7Page
        let formData = app.form.convertToData('#expenseapp-form-add-line')
        if (!formData.expense_requisition_item_id) {
          return self.$app.dialog.alert('费用申请项必须有值!', "KTHRP");
        }
        if (!formData.actual_amount) {
          return self.$app.dialog.alert('金额必须有值!', "KTHRP");
        }
        if (!formData.date_start) {
          return self.$app.dialog.alert('开始日期必须有值!', "KTHRP");
        }
        if (!formData.date_stop) {
          return self.$app.dialog.alert('结束日期必须有值!', "KTHRP");
        }
        if (!formData.single_or_return) {
          return self.$app.dialog.alert('单程往返必须有值!', "KTHRP");
        }
        if (!formData.place) {
          return self.$app.dialog.alert('出发地必须有值!', "KTHRP");
        }
        if (!formData.origin) {
          return self.$app.dialog.alert('目的地必须有值!', "KTHRP");
        }
        if (app.data.expenseapp_index_flag) {
          formData.duration_of_business_trip_days = $$('#duration_of_business_trip_days').html()
          app.data.expenseapp_data[app.data.expenseapp_index] = formData
          app.data.expenseapp_display[app.data.expenseapp_index] = {
            expense_requisition_item_id: $$('.expense_requisition_item_id .item-after').html(),
            date_start: $$('#date_start').val(),
            date_stop: $$('#date_stop').val(),
            duration_of_business_trip_days: $$('#duration_of_business_trip_days').html(),
          }
        } else {
          app.data.expenseapp_display.push({
            expense_requisition_item_id: $$('.expense_requisition_item_id .item-after').html(),
            date_start: $$('#date_start').val(),
            date_stop: $$('#date_stop').val(),
            duration_of_business_trip_days: $$('#duration_of_business_trip_days').html(),
          })
          formData.duration_of_business_trip_days = $$('#duration_of_business_trip_days').html()
          app.data.expenseapp_data.push(formData)
        }
        self.$app.router.back()
      },
      delete: function (e) {
        let self = this
        app.dialog.confirm('确定要删除此条费用行吗?', function () {
          app.data.expenseapp_data.splice(app.data.expenseapp_index, 1)
          app.data.expenseapp_display.splice(app.data.expenseapp_index, 1)
          self.$app.router.back()
        });
      }
    },
    on: {
      pageInit: function (e, page) {
        console.log('pageInit')
        let self = this
        const {
          $$
        } = this
        page.app.calendar.create({
          inputEl: '#date_start',
          dateFormat: 'yyyy-mm-dd',
          openIn: 'customModal',
          header: true,
          footer: true,
          on: {
            closed: function (e) {
              var date_start = e.inputEl.value
              let curList = e.$inputEl.parent().parent().parent().parent()
              let date_stop = curList.find('#date_stop').val()
              if (date_stop && date_stop >= date_start) {
                let days = self.dateDiff(date_start, date_stop)
                $$('#duration_of_business_trip_days').html(days)
              } else if (date_stop && date_stop <= date_start) {
                self.$app.dialog.alert('结束日期必须大于等于开始日期!', "KTHRP");
              }
            }
          }
        })
        page.app.calendar.create({
          inputEl: '#date_stop',
          dateFormat: 'yyyy-mm-dd',
          openIn: 'customModal',
          header: true,
          footer: true,
          on: {
            closed: function (e) {
              var date_stop = e.inputEl.value
              let curList = e.$inputEl.parent().parent().parent().parent()
              let date_start = curList.find('#date_start').val()
              if (date_start && date_stop >= date_start) {
                let days = self.dateDiff(date_start, date_stop)
                $$('#duration_of_business_trip_days').html(days)
              } else if (date_start && date_stop <= date_start) {
                self.$app.dialog.alert('结束日期必须大于等于开始日期!', "KTHRP");
              }
            }
          }
        })
        if (app.data.expenseapp_index_flag) {
          page.app.form.fillFromData('#expenseapp-form-add-line', app.data.expenseapp_data[app.data.expenseapp_index])
          $$('#duration_of_business_trip_days').html(app.data.expenseapp_data[app.data.expenseapp_index].duration_of_business_trip_days)
        } else {
          page.app.form.fillFromData('#expenseapp-form-add-line', {
            expense_requisition_item_id: '',
            single_or_return: '',
            place: '',
            origin: '',
            date_start: '',
            date_stop: '',
            note: '',
            vehicle:''
          })
        }
      },
      pageMounted: function (e, page) {
        console.log('pageMounted')
      },
      pageReinit: function (e, page) {
        console.log('pageReinit')
      },
      pageBeforeIn: function (e, page) {
        console.log('pageBeforeIn')
      },
      pageAfterIn: function (e, page) {
        console.log('pageAfterIn')
      },
      pageBeforeInOut: function (e, page) {
        console.log('pageBeforeInOut')
      },
      pageAfterOut: function (e, page) {
        console.log('pageAfterOut')
      },
      pageBeforeRemove: function (e, page) {
        console.log('pageBeforeRemove')
      }
    }
  }
}