import $$ from "dom7";
import expenseClaimLineForm from "../pages/expenseClaimLineForm.html";
import Template7 from "template7";

export default {
  name: "expenseClaimLineForm",
  path: "/expenseClaimLineForm/",
  component: {
    template: expenseClaimLineForm,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.expense.report.requisition.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      currency_change(){
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-line-form"]')[0].f7Page
        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;
        let formData = page.app.form.convertToData("#expenseEditForm");

        if (app.data.session.source == "dingding") {
          var saveParams = this.getParams("get_currency_rate", {
            dingding_code: dingding_code,
            currency_id:formData.currency_id
          });
        } else if (app.data.session.source == "feishu") {
          var saveParams = this.getParams("get_currency_rate", {
            feishu_userid: feishu_userid,
            currency_id:formData.currency_id
          });
        } else {
          var saveParams = this.getParams("get_currency_rate", {
            wechat_name: vuserid,
            currency_id:formData.currency_id
          });
        }
        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result.data) {
            console.log('get_currency_rate',result.result)
            page.app.form.fillFromData('#expenseEditForm', {
              rate: result.result.data.rate
            });            
            if(formData.amount && formData.expense_standard){
              var reimbursable_amount;
              if(Number(formData.expense_standard) > formData.amount * result.result.data.rate){
                reimbursable_amount = formData.amount * result.result.data.rate
              }else{
                reimbursable_amount = formData.expense_standard
              }
              if(formData.expense_standard == 0 || formData.expense_standard == 0.00){
                reimbursable_amount = formData.amount * result.result.data.rate
              }
              page.app.form.fillFromData('#expenseEditForm', {
                reimbursable_amount
              });
            }

            // $('#reimbursable_amount').val(reimbursable_amount)
          } else if(result.result.state == 'rate_err'){
            page.app.form.fillFromData('#expenseEditForm', {
              rate: ''
            });
            app.dialog.alert(result.result.act_err, "系统提示");
          }else{
            app.dialog.alert("获取信息错误！", "系统提示");
          }
        }); 
      },
      line_amount_change(){
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-line-form"]')[0].f7Page
        let formData = page.app.form.convertToData("#expenseEditForm");
        if(formData.price > 0){
          formData.amount = formData.quantity * formData.price
          $('#line-amount').val(formData.amount)

          this.get_expense_standard()
        }
      },
      expense_item_change(){
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-line-form"]')[0].f7Page
        let formData = page.app.form.convertToData("#expenseEditForm");
        const current_expense_item_id = formData.expense_item_id.split(',')[1]
        const current_expense_item = this.expense_item_list.find(function (e) {
          return e.expense_item_id == current_expense_item_id
        });
        this.expense_category = current_expense_item.expense_category_name + ',' + current_expense_item.expense_category_code
        $("#expense_category").val(current_expense_item.expense_category_name);
        console.log(formData)
        this.get_expense_standard()
      },
      expense_dept_change(){
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-line-form"]')[0].f7Page
        let formData = page.app.form.convertToData("#expenseEditForm");
        const current_department_id = formData.department_id
        const current_department = this.info.expense_dept_list.find(function (e) {
          return e.expense_dept_id == current_department_id
        });
        this.cost_center_id = current_department.cost_center_name + ',' +current_department.cost_center_id
        $("#cost_center_id").val(current_department.cost_center_name);
        console.log(formData)
        this.get_expense_standard()
      },
      place_change(){
        this.get_expense_standard()
      },
      get_expense_standard(){
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-line-form"]')[0].f7Page
        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;
        let formData = page.app.form.convertToData("#expenseEditForm");

        if(!formData.expense_item_id.split(',')[1] || !formData.department_id){
          return false
        }

        if (app.data.session.source == "dingding") {
          //保存的参数
          var saveParams = this.getParams("get_expense_standard", {
            dingding_code: dingding_code,
            expense_item_id: formData.expense_item_id.split(',')[1],
            department_id : formData.department_id,
            report_place : formData.place || ' ',
            reimbursement_employee_id:self.reimbursement_employee_id
          });
        } else if (app.data.session.source == "feishu") {
          //保存的参数
          var saveParams = this.getParams("get_expense_standard", {
            feishu_userid: feishu_userid,
            expense_item_id: formData.expense_item_id.split(',')[1],
            department_id : formData.department_id,
            report_place : formData.place || ' ',
            reimbursement_employee_id:self.reimbursement_employee_id
          });
        } else {
          var saveParams = this.getParams("get_expense_standard", {
            wechat_name: vuserid,
            expense_item_id: formData.expense_item_id.split(',')[1],
            department_id : formData.department_id,
            report_place : formData.place || ' ',
            reimbursement_employee_id:self.reimbursement_employee_id
          });
        }

        app.request.postJSON(app.data.url, saveParams, function (result) {
          if (result.result) {
            console.log('get_expense_standard',result.result)
            formData.expense_standard = result.result.data.expense_standard
            $('#expense_standard').val(result.result.data.expense_standard)
            var reimbursable_amount_fc,reimbursable_amount
            if(Number(formData.expense_standard) > formData.amount * formData.rate){
              // reimbursable_amount_fc = formData.amount
              reimbursable_amount = formData.amount * formData.rate
            }else{
              // reimbursable_amount_fc = formData.expense_standard
              reimbursable_amount = formData.expense_standard
            }
            
            

            if(formData.expense_standard == 0 || formData.expense_standard == 0.00){
              reimbursable_amount = formData.amount * formData.rate
            }

            // formData.reimbursable_amount_fc = reimbursable_amount_fc
            formData.reimbursable_amount = reimbursable_amount
            // $('#reimbursable_amount_fc').val(reimbursable_amount_fc)
            $('#reimbursable_amount').val(reimbursable_amount)
          } else {
            app.dialog.alert("获取费用标准信息错误！", "系统提示");
          }
        }); 
      },
      date_change(e){
        let val=e||window.event;
        if(!val)return
        if(val.currentTarget&&val.currentTarget.id.indexOf('date')>-1){
          let end = $("input[name='date_stop']").val()
          let start = $("input[name='date_start']").val()
          if(start){
            if(end && new Date(start.replace(/-/g, "/")) - new Date(end.replace(/-/g, "/")) > 0){
              app.dialog.alert("结束时间必须大于开始时间！", "系统提示");
              $("input[name='date_stop']").val('')
            }
          }else{
            app.dialog.alert("请选择开始时间！", "系统提示");
          }
        }
      },
      // 删除行记录
      del(){
        if(!isNaN(this.lineindex)){
          if(this.ismaintain){
            app.data.maintainDetailView_data.work_order_complete_info_line_ids.splice(this.lineindex,1)
          }else if(this.isInspection){

          }else{
            app.data.workOrder_data.work_order_complete_info_line_ids.splice(this.lineindex,1)
          }
        }
        app.router.back()
    },
      save() {
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="expenseClaim-line-form"]')[0].f7Page
        let formData = page.app.form.convertToData("#expenseEditForm");
        formData.expense_category = this.expense_category
        formData.cost_center_id = this.cost_center_id
        console.log(formData);
        if (!formData.expense_item_id) {
          app.dialog.alert("请选择费用项！", "系统提示");
          return;
        } else if (!formData.quantity) {
          app.dialog.alert("请填写数量！", "系统提示");
          return;
        }else if (!formData.price) {
          app.dialog.alert("请填写单价！", "系统提示");
          return;
        }else if (!formData.department_id) {
          app.dialog.alert("请选择部门！", "系统提示");
          return;
        }else if (!formData.currency_id) {
          app.dialog.alert("请选择币种！", "系统提示");
          return;
        }else if (!formData.rate) {
          app.dialog.alert("请选择已维护汇率的币种！", "系统提示");
          return;
        }else if (!formData.date_start) {
          app.dialog.alert("请选择开始时间！", "系统提示");
          return;
        }else if (!formData.date_stop) {
          app.dialog.alert("请选择结束时间！", "系统提示");
          return;
        }
        if(!this.detail){
          app.data.expense_data.expense_line_ids.push(formData)
        }else{
          app.data.expense_data.expense_line_ids[self.lineId] = formData
        }
        
        $$(".back").trigger("click"); 
        
      },
    },
    on: {
      pageInit: function (e, page) { 
        const self = this 

        if(this.detail){
          page.app.form.fillFromData('#expenseEditForm', {
            amount: self.detail.amount,
            // cost_center_id: self.detail.cost_center_id,
            currency_id: self.detail.currency_id,
            date_start: self.detail.date_start,
            date_stop: self.detail.date_stop,
            department_id: self.detail.department_id,
            // expense_category: self.detail.expense_category.split(',')[0],
            expense_item_id: self.detail.expense_item_id,//保洁,290"
            expense_standard: self.detail.expense_standard,
            note: self.detail.note,
            place:self.detail.place,
            price:self.detail.price,
            quantity:self.detail.quantity,
            reimbursable_amount:self.detail.reimbursable_amount,
          });
          let formData = page.app.form.convertToData("#expenseEditForm");
          formData.expense_item_id = this.detail.expense_item_id
          formData.cost_center_id = this.detail.cost_center_id
        }
        const defaultCurrencyItem =  this.info.currency_list.filter(function(e){
          return e.default == 'True'
        });
        if(defaultCurrencyItem.length && !self.detail){
          $("#currency_id").val(defaultCurrencyItem[0].currency_id)
        }  

        this.currency_change()
        //注册时间选择控件
        let date_start = new lCalendar();
        date_start.init({
          trigger: "#date_start",
          type: "date",
        });
        let date_stop = new lCalendar();
        date_stop.init({
          trigger: "#date_stop",
          type: "date",
        });
        $("input[type='text']").bind('input propertychange',self.date_change)
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      },
    },
  },
};