import $ from "dom7";
import editForm from "./editForm";
import detailForm from "./detail";
import addWorkInfo from "./addWorkInfo";
import addInspectionWorkInfo from "./addInspectionWorkInfo";
import maintainDetailView from "./maintainDetailView";
import inspectionDetail from "./inspectionDetail";
import pending from "./pending";
import html from "../pages/index.html";
import style from "../styles/index.less";
import { reject } from "lodash";

export default [
  {
    name: "/workOrderCompleted/",
    path: "/workOrderCompleted/",
    component: {
      template: html,
      style: style,
      data: () => {
        return {
          work_index:1,
          page_index:1,
          page_count: 6,
          search_value:'',
          allowInfinite_1: true,
          allowInfinite_2: true,
          allowInfinite_3: true,
          request_type: 'processing_work_order'
        };
      },
      methods: {
        //待分配列表接口的参数
        getRepairRequisitionInfo: function (method, obj) {
          var vuserid = this.$app.data.session.userid;
          var dbname = this.$app.data.dbname;
          var userid = this.$app.data.userid;
          var password = this.$app.data.password;
          var args = [];
          if (obj.repair) {
            args = [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.repair.requisition.interface",
              method,
              obj,
            ];
          } else {
            args = [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.work.order.interface",
              method,
              obj,
            ];
          }
          return {
            jsonrpc: "2.0",
            method: "call",
            params: {
              service: "object",
              method: "execute",
              args: args,
            },
          };
        },
        doSearch(){
          this.inintPageIndex()
          this.search_value = $('.work-search').val(),
          console.log(this.search_value)
          if(this.work_index == 1){
            this.getRepairList().then(res=>{
              this.$setState({
                tabs1:res.result.result_data,
                tabs1Count:res.result.result_data.length
              })
              
            })
          }else{
            this.get_maintain_kanban().then(res=>{
              if(this.work_index == 2){
                this.$setState({
                  tabs4:res.result.result_data,
                  tabs4Count:res.result.result_data.length
                })
              }else{
                this.$setState({
                  tabs7:res.result.result_data,
                  tabs7Count:res.result.result_data.length
                })
              }
            
            })
          }
        
        },
        inintPageIndex(){
          // window.scrollTo(0,0)
          this.page_index = 1
          this.search_value = ''
          this[`allowInfinite_${this.work_index}`] = true
          this.$setState({
            tabs1:[],
            tabs4:[],
            tabs7:[],
            tabs1Count:0,
            tabs4Count:0,
            tabs7Count:0,
          });
          app.infiniteScroll.create(`#workOrder-tab${this.work_index}`);
        },
        //tab改变的函数
        changeTab(index) {
          this.work_index = index
          this.inintPageIndex()
          $('.child-tab').eq(0).addClass('tab-link-active').siblings().removeClass('tab-link-active')  
          
          this.changeChildTab(1)

          app.data.index = index;
          this.$setState({
            index: index,
          });
          // app.data[`index${index}`]
          //   ? this.changeSearchBar(index, app.data[`index${index}`])
          //   : this.changeSearchBar(index, 3);
          // let timeId = setTimeout(() => {
          //   if (app.data[`index${index}`]) {
          //     $($(".child-tab")[app.data[`index${index}`] - 1])
          //       .addClass("tab-link-active")
          //       .siblings()
          //       .removeClass("tab-link-active");
          //   } else {
          //     $($(".child-tab")[2])
          //       .addClass("tab-link-active")
          //       .siblings()
          //       .removeClass("tab-link-active");
          //   }
          //   clearTimeout(timeId);
          // }, 0);
        },
        // 子tab改变的函数
        changeChildTab(index) {
          this.inintPageIndex()
          $('.child-tab').eq(index - 1).addClass('tab-link-active').siblings().removeClass('tab-link-active')  
          if(this.work_index == 1){
            switch (index){
              case 1:
                this.request_type = 'repair_all_work_order'
                break;
              case 2:
                this.request_type = 'completed_work_order'
                break;
              case 3:
                this.request_type = 'processing_work_order'
                break;
            }
            
            this.getRepairList().then(res=>{
              this.$setState({
                tabs1:res.result.result_data,
                tabs1Count:res.result.result_data.length
              })
              
            })
          }else{
            switch (index){
              case 1:
                this.request_type = 'all'
                break;
              case 2:
                this.request_type = 'completed'
                break;
              case 3:
                this.request_type = 'processing'
                break;
            }
            this.get_maintain_kanban().then(res=>{
              if(this.work_index == 2){
                this.$setState({
                  tabs4:res.result.result_data,
                  tabs4Count:res.result.result_data.length
                })
              }else{
                this.$setState({
                  tabs7:res.result.result_data,
                  tabs7Count:res.result.result_data.length
                })
              }
            
            })
          }
          app.data[`index${parent}`] = index;
          // this.changeSearchBar(parent, index);
        },
        // 处理顶部搜索框的函数
        changeSearchBar(parent_index, child_index) {
          //parent_index 代表在哪个类型的工单下 child_index代表在哪个状态的工单下
          let searchbar_list = [];
          let searchIcon_list = [];
          searchbar_list.push(app.searchbar.get(".searchbar-all"));
          searchbar_list.push(app.searchbar.get(".searchbar-done"));
          searchbar_list.push(app.searchbar.get(".searchbar-to-do"));
          searchbar_list.push(app.searchbar.get(".searchbar-all-maintain"));
          searchbar_list.push(app.searchbar.get(".searchbar-done-maintain"));
          searchbar_list.push(app.searchbar.get(".searchbar-to-do-maintain"));
          searchbar_list.push(app.searchbar.get(".searchbar-all-inspection"));
          searchbar_list.push(app.searchbar.get(".searchbar-done-inspection"));
          searchbar_list.push(app.searchbar.get(".searchbar-to-do-inspection"));
          searchIcon_list.push($(".searchbar-all, .search-icon-1"));
          searchIcon_list.push($(".searchbar-done,.search-icon-2"));
          searchIcon_list.push($(".searchbar-to-do,.search-icon-3"));
          searchIcon_list.push($(".searchbar-all-maintain,.search-icon-4"));
          searchIcon_list.push($(".searchbar-done-maintain,.search-icon-5"));
          searchIcon_list.push($(".searchbar-to-do-maintain,.search-icon-6"));
          searchIcon_list.push($(".searchbar-all-inspection,.search-icon-7"));
          searchIcon_list.push($(".searchbar-done-inspection,.search-icon-8"));
          searchIcon_list.push($(".searchbar-to-do-inspection,.search-icon-9"));
          switch (parent_index) {
            case 1:
              for (let i = 1; i < 10; i++) {
                if (i < 4) {
                  if (i == child_index) {
                    searchIcon_list[i - 1].show();
                  } else {
                    searchIcon_list[i - 1].hide();
                  }
                } else {
                  searchIcon_list[i - 1].hide();
                }
              }
              break;
            case 2:
              for (let i = 1; i < 10; i++) {
                if (i >= 4 && i < 7) {
                  if (i == child_index + 3) {
                    searchIcon_list[i - 1].show();
                  } else {
                    searchIcon_list[i - 1].hide();
                  }
                } else {
                  searchIcon_list[i - 1].hide();
                }
              }
              break;
            case 3:
              for (let i = 1; i < 10; i++) {
                if (i >= 7) {
                  if (i == child_index + 6) {
                    searchIcon_list[i - 1].show();
                  } else {
                    searchIcon_list[i - 1].hide();
                  }
                } else {
                  searchIcon_list[i - 1].hide();
                }
              }
              break;
            default:
              break;
          }
        },
        toDetailWorkForm: function (e) {
          const { $$ } = this;
          var page = $$('.page[data-name="workOrderCompleted-index"]')[0]
            .f7Page;
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          var line_id = parseInt(e.currentTarget.dataset.id);
          var state = e.currentTarget.dataset.state;
          if (app.data.index == 1) {
            // 当前在哪个工作单下  1. 故障修  2. 保养工单  3. 巡检工单
            if (page.app.data.session.source == "dingding") {
              var assetMainDetailParams = this.getRepairRequisitionInfo(
                "get_work_order_info_for_id",
                {
                  work_order_id: line_id,
                  dingding_code: dingding_code,
                }
              );
            } else if (page.app.data.session.source == "feishu") {
              var assetMainDetailParams = this.getRepairRequisitionInfo(
                "get_work_order_info_for_id",
                {
                  work_order_id: line_id,
                  feishu_userid: feishu_userid,
                }
              );
            } else {
              var assetMainDetailParams = this.getRepairRequisitionInfo(
                "get_work_order_info_for_id",
                {
                  work_order_id: line_id,
                  wechat_name: vuserid,
                }
              );
            }
            page.app.request.postJSON(
              page.app.data.url,
              assetMainDetailParams,
              function (result) {
                if (result.result) {
                  page.app.data.workOrder_data = result.result;
                  if (
                    page.app.data.workOrder_data
                      .work_order_complete_info_line_ids.length > 0
                  ) {
                    page.app.data.workOrder_data.work_order_complete_info_line_ids.forEach(
                      (ele) => {
                        ele.actual_complete_date = ele.actual_complete_date
                          ? ele.actual_complete_date.slice(
                              0,
                              ele.actual_complete_date.length - 3
                            )
                          : "";
                        ele.actual_start_date = ele.actual_start_date
                          ? ele.actual_start_date.slice(
                              0,
                              ele.actual_start_date.length - 3
                            )
                          : "";
                        ele.halt_end_time = ele.halt_end_time
                          ? ele.halt_end_time.slice(
                              0,
                              ele.halt_end_time.length - 3
                            )
                          : "";
                        ele.halt_start_time = ele.halt_start_time
                          ? ele.halt_start_time.slice(
                              0,
                              ele.halt_start_time.length - 3
                            )
                          : "";
                      }
                    );
                  }
                  result.result.save =
                    result.result.extra_state.indexOf("edit") > -1;
                  result.result.complete =
                    result.result.extra_state.indexOf("complete") > -1;
                  if (
                    state == "处理中" &&
                    (result.result.save || result.result.complete)
                  ) {
                    page.router.navigate("/editForm/", {
                      reloadCurrent: false,
                      ignoreCache: true,
                      context: {
                        info: result.result,
                      },
                    });
                  } else {
                    page.router.navigate("/detailForm/", {
                      reloadCurrent: false,
                      ignoreCache: true,
                      context: {
                        id: line_id,
                      },
                    });
                  }
                } else {
                  page.app.dialog.alert("获取信息错误！", "系统提示");
                }
              }
            );
          } else if (app.data.index == 2) {
            if (page.app.data.session.source == "dingding") {
              var maintainDetailParams = this.getRepairRequisitionInfo(
                "get_maintain_details",
                {
                  work_order_id: line_id,
                  dingding_code: dingding_code,
                  identifying_code2: "preventive",
                  identifying_code1: "complete",
                }
              );
            } else if (page.app.data.session.source == "feishu") {
              var maintainDetailParams = this.getRepairRequisitionInfo(
                "get_maintain_details",
                {
                  work_order_id: line_id,
                  feishu_userid: feishu_userid,
                  identifying_code2: "preventive",
                  identifying_code1: "complete",
                }
              );
            } else {
              var maintainDetailParams = this.getRepairRequisitionInfo(
                "get_maintain_details",
                {
                  work_order_id: line_id,
                  wechat_name: vuserid,
                  identifying_code2: "preventive",
                  identifying_code1: "complete",
                }
              );
            }
            page.app.request.postJSON(
              page.app.data.url,
              maintainDetailParams,
              function (result) {
                if (result.result) {
                  console.log(result.result, "完工保养");
                  page.app.data.maintainDetailView_data = result.result.data;
                  result.result.data.save =
                    result.result.data.extra_state.indexOf("edit") > -1;
                  result.result.data.complete =
                    result.result.data.extra_state.indexOf("complete") > -1;
                  page.router.navigate("/maintainDetailView/", {
                    reloadCurrent: false,
                    ignoreCache: true,
                    context: {
                      id: line_id,
                    },
                  });
                } else {
                  page.app.dialog.alert("获取信息错误！", "系统提示");
                }
              }
            );
          } else if (app.data.index == 3) {
            if (page.app.data.session.source == "dingding") {
              var inspectionDetailParams = this.getRepairRequisitionInfo(
                "get_maintain_details",
                {
                  work_order_id: line_id,
                  dingding_code: dingding_code,
                  identifying_code2: "routing",
                  identifying_code1: "complete",
                }
              );
            } else if (page.app.data.session.source == "feishu") {
              var inspectionDetailParams = this.getRepairRequisitionInfo(
                "get_maintain_details",
                {
                  work_order_id: line_id,
                  feishu_userid: feishu_userid,
                  identifying_code2: "routing",
                  identifying_code1: "complete",
                }
              );
            } else {
              var inspectionDetailParams = this.getRepairRequisitionInfo(
                "get_maintain_details",
                {
                  work_order_id: line_id,
                  wechat_name: vuserid,
                  identifying_code2: "routing",
                  identifying_code1: "complete",
                }
              );
            }
            page.app.request.postJSON(
              page.app.data.url,
              inspectionDetailParams,
              function (result) {
                if (result.result) {
                  console.log(result.result, "巡检详情");
                  page.app.data.inspectionDetailView_data = result.result.data;
                  result.result.data.save =
                    result.result.data.extra_state.indexOf("edit") > -1;
                  result.result.data.complete =
                    result.result.data.extra_state.indexOf("complete") > -1;
                  page.router.navigate("/completeInspectionDetail/", {
                    reloadCurrent: false,
                    ignoreCache: true,
                    context: {
                      info: result.result.data,
                      id: line_id,
                    },
                  });
                } else {
                  page.app.dialog.alert("获取信息错误！", "系统提示");
                }
              }
            );
          }
        },
        //获取全部列表  故障修
        getRepairList() {
          const self = this
          const vuserid = app.data.session.userid;
          const dingding_code = app.data.dingding_code;
          const feishu_userid = app.data.feishu_userid;
          if (app.data.session.source == "dingding") {
            var params = this.getRepairRequisitionInfo(
              "get_work_order_info",
              {
                dingding_code: dingding_code,
                page_index: this.page_index,
                page_count: this.page_count,
                search_value: this.search_value,
                request_type: this.request_type
              }
            );
          } else if (app.data.session.source == "feishu") {
            var params = this.getRepairRequisitionInfo(
              "get_work_order_info",
              {
                feishu_userid: feishu_userid,
                page_index: this.page_index,
                page_count: this.page_count,
                search_value: this.search_value,
                request_type: this.request_type
              }
            );
          } else {
            var params = this.getRepairRequisitionInfo(
              "get_work_order_info",
              {
                wechat_name: vuserid,
                page_index: this.page_index,
                page_count: this.page_count,
                search_value: this.search_value,
                request_type: this.request_type
              }
            );
          }
          app.preloader.show();
          return new Promise((resolve,reject)=>{
          app.request.postJSON(app.data.url, params, (res) => {
            app.preloader.hide();
            self.page_index += 1
            resolve(res)
            
          })
        })
        },
        get_maintain_kanban(){
          const self = this
          const vuserid = app.data.session.userid;
          const dingding_code = app.data.dingding_code;
          const feishu_userid = app.data.feishu_userid;
          const page_index = this.page_index
          const page_count = this.page_count
          const search_value = this.search_value
          const identifying_code1 = 'complete'
          const identifying_code2 = this.work_index == 2 ? 'preventive' : 'routing'
          const identifying_code3 = this.request_type
          if (app.data.session.source == "dingding") {
            var params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                dingding_code: dingding_code,
                page_index,
                page_count,
                search_value,
                identifying_code1,
                identifying_code2,
                identifying_code3
              }
            );
          } else if (app.data.session.source == "feishu") {
            var params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                feishu_userid: feishu_userid,
                page_index,
                page_count,
                search_value,
                identifying_code1,
                identifying_code2,
                identifying_code3
              }
            );
          } else {
            var params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                wechat_name: vuserid,
                page_index,
                page_count,
                search_value,
                identifying_code1,
                identifying_code2,
                identifying_code3
              }
            );
          }
          app.preloader.show();
          return new Promise((resolve,reject)=>{
            app.request.postJSON(app.data.url, params, (res) =>{
             
              app.preloader.hide();
              self.page_index += 1
              resolve(res)
            })
          })
        },
        //获取故障修的全部列表
        getAll(page, params) {
          // this.$setState({
          //   index:1
          // });
          let pro = new Promise((resolve, reject) => {
            page.app.request.postJSON(page.app.data.url, params, (result) => {
              this.$setState({
                index: 1,
                tabs1: result.result.repair_all_work_order.sort((a, b) => {
                  let bb = b.doc_number.slice(
                    b.doc_number.length - 9,
                    b.doc_number.length
                  );
                  let aa = a.doc_number.slice(
                    a.doc_number.length - 9,
                    a.doc_number.length
                  );
                  if (bb.length > 8 && aa.length > 8) {
                    return bb - aa;
                  } else {
                    return a;
                  }
                }),
                tabs1Count: result.result.repair_all_work_order.length,
                tabs2: result.result.completed_work_order.sort((a, b) => {
                  let bb = b.doc_number.slice(
                    b.doc_number.length - 9,
                    b.doc_number.length
                  );
                  let aa = a.doc_number.slice(
                    a.doc_number.length - 9,
                    a.doc_number.length
                  );
                  if (bb.length > 8 && aa.length > 8) {
                    return bb - aa;
                  } else {
                    return a;
                  }
                }),
                tabs2Count: result.result.completed_work_order.length,
                tabs3: result.result.processing_work_order.sort((a, b) => {
                  let bb = b.doc_number.slice(
                    b.doc_number.length - 9,
                    b.doc_number.length
                  );
                  let aa = a.doc_number.slice(
                    a.doc_number.length - 9,
                    a.doc_number.length
                  );
                  if (bb.length > 8 && aa.length > 8) {
                    return bb - aa;
                  } else {
                    return a;
                  }
                }),
                tabs3Count: result.result.processing_work_order.length,
              });
              resolve(result);
            });
          });
          return pro;
        },
        // 获取保养工单的全部列表
        getMaintainList(page, params) {
          let pro = new Promise((resolve, reject) => {
            page.app.request.postJSON(page.app.data.url, params, (result) => {
              console.log(result, "保养");
              this.$setState({
                tabs4: result.result.all_asset_maintenance.sort((a, b) => {
                  let bb = b.doc_number.slice(
                    b.doc_number.length - 9,
                    b.doc_number.length
                  );
                  let aa = a.doc_number.slice(
                    a.doc_number.length - 9,
                    a.doc_number.length
                  );
                  if (bb.length > 8 && aa.length > 8) {
                    return bb - aa;
                  } else {
                    return a;
                  }
                }),
                tabs4Count: result.result.all_asset_maintenance.length,
                tabs5: result.result.completed_asset_maintenance.sort(
                  (a, b) => {
                    let bb = b.doc_number.slice(
                      b.doc_number.length - 9,
                      b.doc_number.length
                    );
                    let aa = a.doc_number.slice(
                      a.doc_number.length - 9,
                      a.doc_number.length
                    );
                    if (bb.length > 8 && aa.length > 8) {
                      return bb - aa;
                    } else {
                      return a;
                    }
                  }
                ),
                tabs5Count: result.result.completed_asset_maintenance.length,
                tabs6: result.result.processing_asset_maintenance.sort(
                  (a, b) => {
                    let bb = b.doc_number.slice(
                      b.doc_number.length - 9,
                      b.doc_number.length
                    );
                    let aa = a.doc_number.slice(
                      a.doc_number.length - 9,
                      a.doc_number.length
                    );
                    if (bb.length > 8 && aa.length > 8) {
                      return bb - aa;
                    } else {
                      return a;
                    }
                  }
                ),
                tabs6Count: result.result.processing_asset_maintenance.length,
              });
              resolve(result);
            });
          });
          return pro;
        },
        // 获取巡检工单所有的列表
        getInspectionList(page, params) {
          let pro = new Promise((resolve, reject) => {
            page.app.request.postJSON(page.app.data.url, params, (res) => {
              console.log(res, "工单处理-巡检列表");
              this.$setState({
                tabs7: res.result.all_asset_maintenance,
                tabs7Count: res.result.all_asset_maintenance.length,
                tabs8: res.result.completed_asset_maintenance,
                tabs8Count: res.result.completed_asset_maintenance.length,
                tabs9: res.result.processing_asset_maintenance,
                tabs9Count: res.result.processing_asset_maintenance.length,
              });
              resolve();
            });
          });
          return pro;
        },
      },
      on: {
        pageInit: function (e, page) {
          var self = this;
          // 隐藏无用的搜索框
          // $('[data-name="workOrderCompleted-index"] .searchbar-done').hide();
          // $('[data-name="workOrderCompleted-index"] .search-icon-2').hide();
          // $('[data-name="workOrderCompleted-index"] .searchbar-all').hide();
          // $('[data-name="workOrderCompleted-index"] .search-icon-1').hide();
          // $('[data-name="workOrderCompleted-index"] .search-icon-6').hide();
          // $('[data-name="workOrderCompleted-index"] .search-icon-5').hide();
          // $('[data-name="workOrderCompleted-index"] .search-icon-4').hide();
          // $('[data-name="workOrderCompleted-index"] .search-icon-7').hide();
          // $('[data-name="workOrderCompleted-index"] .search-icon-8').hide();
          // $('[data-name="workOrderCompleted-index"] .search-icon-9').hide();
          page.$pageEl
            .find("#tab-all,#tab-doing")
            .on("touchmove", function (e) {
              $(e.currentTarget)
                .parent()
                .parent()
                .prev()
                .find(".searchbar input")
                .blur();
            });

          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          page.app.data.index = 1;

          this.getRepairList().then(res=>{
            this.$setState({
              tabs1:res.result.result_data,
              tabs1Count:res.result.result_data.length,
              index: 1
            })
            this.asset_maintenance_assign =
                  res.result.asset_maintenance_assign;
                this.asset_maintenance_engineer =
                  res.result.asset_maintenance_engineer;
            // page.app.searchbar.create({
            //   el: ".searchbar-done",
            //   searchContainer: ".list-done",
            //   searchIn: ".item-text-done",
            //   notFoudEl: ".searchbar-not-found-done",
            // });
            page.app.searchbar.create({
              el: ".searchbar-all",
              searchContainer: ".list-all",
              searchIn: ".item-text-all",
              notFoudEl: ".searchbar-not-found-all",
            });

            
          })
          return
          if (page.app.data.session.source == "dingding") {
            //获取故障修全部工单和重新分配的参数
            var all_list_worders_paramrs = this.getRepairRequisitionInfo(
              "get_work_order_info",
              {
                dingding_code: dingding_code,
              }
            );
            //获取保养工单列表的参数
            var maintain_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                dingding_code: dingding_code,
                identifying_code2: "preventive",
                identifying_code1: "complete",
              }
            );
            // 获取巡检工单列表的参数
            var inspection_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                dingding_code: dingding_code,
                identifying_code2: "routing",
                identifying_code1: "complete",
              }
            );
          } else {
            var all_list_worders_paramrs = this.getRepairRequisitionInfo(
              "get_work_order_info",
              {
                wechat_name: vuserid,
              }
            );
            var maintain_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                wechat_name: vuserid,
                identifying_code2: "preventive",
                identifying_code1: "complete",
              }
            );
            // 获取巡检工单列表的参数
            var inspection_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                wechat_name: vuserid,
                identifying_code2: "routing",
                identifying_code1: "complete",
              }
            );
          }
          //调用列表接口
          this.getAll(page, all_list_worders_paramrs).then((res) => {
            //未处理 搜索框实例
            var sbtodo = page.app.searchbar.create({
              el: '[data-name="workOrderCompleted-index"] .searchbar-to-do',
              searchContainer:
                '[data-name="workOrderCompleted-index"] .list-to-do',
              searchIn:
                '[data-name="workOrderCompleted-index"] .item-text-to-do',
              notFoudEl:
                '[data-name="workOrderCompleted-index"] .searchbar-not-found-to-do',
            });
            var sbdone = page.app.searchbar.create({
              el: ".searchbar-done",
              searchContainer: ".list-done",
              searchIn: ".item-text-done",
              notFoudEl: ".searchbar-not-found-done",
            });
            var sball = page.app.searchbar.create({
              el: ".searchbar-all",
              searchContainer: ".list-all",
              searchIn: ".item-text-all",
              notFoudEl: ".searchbar-not-found-all",
            });
          });
          this.getMaintainList(page, maintain_params).then((res) => {
            $("a[href='#workOrder-tab2']").once("click", function () {
              // 创建保养工单的搜索框实例
              page.app.searchbar.create({
                el:
                  '[data-name="workOrderCompleted-index"] .searchbar-to-do-maintain',
                searchContainer:
                  '[data-name="workOrderCompleted-index"] .list-to-do-maintain',
                searchIn:
                  '[data-name="workOrderCompleted-index"] .item-text-to-do-maintain',
                notFoudEl:
                  '[data-name="workOrderCompleted-index"] .searchbar-not-found-to-do-maintain',
              });
              page.app.searchbar.create({
                el: ".searchbar-done-maintain",
                searchContainer: ".list-done-maintain",
                searchIn: ".item-text-done-maintain",
                notFoudEl: ".searchbar-not-found-done-maintain",
              });
              page.app.searchbar.create({
                el: ".searchbar-all-maintain",
                searchContainer: ".list-all-maintain",
                searchIn: ".item-text-all-maintain",
                notFoudEl: ".searchbar-not-found-all-maintain",
              });
            });
          });
          // 获取巡检列表
          this.getInspectionList(page, inspection_params).then(() => {
            page.app.searchbar.create({
              el: ".searchbar-all-inspection",
              searchContainer: ".list-all-inspection",
              searchIn: ".item-text-all-inspection",
              notFoudEl: ".searchbar-not-found-all-inspection",
            });
            page.app.searchbar.create({
              el: ".searchbar-done-inspection",
              searchContainer: ".list-done-inspection",
              searchIn: ".item-text-done-inspection",
              notFoudEl: ".searchbar-not-found-done-inspection",
            });
            page.app.searchbar.create({
              el: ".searchbar-to-do-inspection",
              searchContainer: ".list-to-do-inspection",
              searchIn: ".item-text-to-do-inspection",
              notFoudEl: ".searchbar-not-found-to-do-inspection",
            });
          });
        },
        pageMounted: function (e, page) {
          const self = this

          $('#workOrder-tab1').on('infinite', function () {

            // Exit, if loading in progress
            if (!self.allowInfinite_1) return;
            // Set loading flag
            self.allowInfinite_1 = false;
            self.getRepairList().then(function(res){
              if(res.result.result_data.length > 0){
                const newArr = self.tabs1.concat(res.result.result_data)
                self.$setState({
                  tabs1: newArr
                });
                self.allowInfinite_1 = true;

              }else{
                // Nothing more to load, detach infinite scroll events to prevent unnecessary loadings
                page.app.infiniteScroll.destroy('#workOrder-tab1');
                // Remove preloader
                $('#workOrder-tab1 .infinite-scroll-preloader').remove();
              }
            })
          });
          
          $('#workOrder-tab2').on('infinite', function () {

            // Exit, if loading in progress
            if (!self.allowInfinite_2) return;
            // Set loading flag
            self.allowInfinite_2 = false;
            self.get_maintain_kanban().then(function(res){
              if(res.result.result_data.length > 0){
                const newArr = self.tabs4.concat(res.result.result_data)
                self.$setState({
                  tabs4: newArr
                });
                self.allowInfinite_2 = true;

              }else{
                // Nothing more to load, detach infinite scroll events to prevent unnecessary loadings
                page.app.infiniteScroll.destroy('#workOrder-tab2');
                // Remove preloader
                $('#workOrder-tab2 .infinite-scroll-preloader').remove();
              }
            })
          });

          $('#workOrder-tab3').on('infinite', function () {

            // Exit, if loading in progress
            if (!self.allowInfinite_3) return;
            // Set loading flag
            self.allowInfinite_3 = false;
            self.get_maintain_kanban().then(function(res){
              if(res.result.result_data.length > 0){
                const newArr = self.tabs7.concat(res.result.result_data)
                self.$setState({
                  tabs7: newArr
                });
                self.allowInfinite_3 = true;
              }else{
                // Nothing more to load, detach infinite scroll events to prevent unnecessary loadings
                page.app.infiniteScroll.destroy('#workOrder-tab3');
                // Remove preloader
                $('#workOrder-tab3 .infinite-scroll-preloader').remove();
              }
            })
          });
  
        },
        pageReinit: function (e, page) {
          //初始化置空 元数据中的form_data
          if ("form_data" in page.app.data.expense_data) {
            delete page.app.data.expense_data["form_data"];
          }
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == "dingding") {
            //获取全部工单和重新分配的参数
            var all_list_worders_paramrs = this.getRepairRequisitionInfo(
              "get_work_order_info",
              {
                dingding_code: dingding_code,
              }
            );
            //获取保养工单列表的参数
            var maintain_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                dingding_code: dingding_code,
                identifying_code2: "preventive",
                identifying_code1: "complete",
              }
            );
            // 获取巡检工单列表的参数
            var inspection_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                dingding_code: dingding_code,
                identifying_code2: "routing",
                identifying_code1: "complete",
              }
            );
          } else if (page.app.data.session.source == "feishu") {
            //获取全部工单和重新分配的参数
            var all_list_worders_paramrs = this.getRepairRequisitionInfo(
              "get_work_order_info",
              {
                feishu_userid: feishu_userid,
              }
            );
            //获取保养工单列表的参数
            var maintain_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                feishu_userid: feishu_userid,
                identifying_code2: "preventive",
                identifying_code1: "complete",
              }
            );
            // 获取巡检工单列表的参数
            var inspection_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                feishu_userid: feishu_userid,
                identifying_code2: "routing",
                identifying_code1: "complete",
              }
            );
          } else {
            var all_list_worders_paramrs = this.getRepairRequisitionInfo(
              "get_work_order_info",
              {
                wechat_name: vuserid,
              }
            );
            //获取保养工单列表的参数
            var maintain_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                wechat_name: vuserid,
                identifying_code2: "preventive",
                identifying_code1: "complete",
              }
            );
            // 获取巡检工单列表的参数
            var inspection_params = this.getRepairRequisitionInfo(
              "get_maintain_kanban",
              {
                wechat_name: vuserid,
                identifying_code2: "routing",
                identifying_code1: "complete",
              }
            );
          }
          //调用列表接口
          // this.getAll(page, all_list_worders_paramrs).then(() => {
          //   this.changeTab(app.data.index); // 处理从详情界面返回时界面的tab显示不正确的问题
          // });
          // this.getMaintainList(page, maintain_params);
          // this.getInspectionList(page, inspection_params);
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
          page.app.toolbar.hide(".expense-toolbar", false);
          $(".expense-toolbar").css("opacity", 0);
          $('.expense-checkbox input[type="checkbox"]').prop("checked", false);
          $(".expense-list-checkbox-icon").css("margin-left", "-80px");
        },
        pageBeforeRemove: function (e, page) {
          // 重置下方子tab的显示
          app.data.index1 = "";
          app.data.index2 = "";
          app.data.index3 = "";
          console.log("pageBeforeRemove");
        },
      },
    },
  },
  editForm,
  detailForm,
  addWorkInfo,
  maintainDetailView,
  inspectionDetail,
  addInspectionWorkInfo,
  pending
];
