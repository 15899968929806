import $$ from "dom7";
import detailForm from "../pages/detail.html";
import Template7 from "template7";
import * as dd from 'dingtalk-jsapi'


export default {
  name: "assetsInventoryDetail",
  path: "/assetsInventoryDetail/",
  component: {
    template: detailForm,
    methods: {

    },
    on: {
      pageInit: function (e, page) {
        
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};