import $$ from "dom7";
import inspectionDetail from "../pages/inspectionDetail.html";
import Template7 from "template7";

export default {
  name: "completeInspectionDetail",
  path: "/completeInspectionDetail/",
  component: {
    template: inspectionDetail,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.asset.maintenance.work.order.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      pending(type){
        if(type == 0){
          const {
            $$
          } = this;       
          var page = $$('.page[data-name="complete-inspectionDetail-view-form"]')[0].f7Page;
          page.router.navigate("/pending/", {
            reloadCurrent: false,
            ignoreCache: true,
            context:{
              info: this.info
            }
          });
        }else{
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          var work_order_id = this.info.work_order_id
          
          if (app.data.session.source == 'dingding') {
              var createParams = this.getParams("action_release", {
                "dingding_code": dingding_code,
                "work_order_id": work_order_id
              });
          } else if (app.data.session.source == 'feishu') {
              var createParams = this.getParams("action_release", {
                "feishu_userid": feishu_userid,
                "work_order_id": work_order_id
              });
            } else {
              var createParams = this.getParams("action_release", {
                "wechat_name": vuserid,
                "work_order_id": work_order_id
              });
  
            }
            app.preloader.show()
            app.request.postJSON(app.data.url, createParams ,(result) => {
              if(result.result&&result.result.state == "success"){ 
                app.preloader.hide()

                app.router.back('/workOrderCompleted/',{
                  force: true
                })
              }else{

                app.preloader.hide()
                app.dialog.alert(result.result.message,'系统提示')
              }          
            });
        }

      },
      //跳转到工作信息的界面
      toLineDetail: function (e) {
        if(!this.info.complete){
          return false;
        }
        let self = this;
        var lineId = parseInt(e.currentTarget.dataset.lineId)

        self.$app.router.navigate("/addInspectionWorkInfo/", {
          reloadCurrent: false,
          ignoreCache: true,
          context: {
            info: this.info,
            lineId
          },
        });
 
      },

      editline(e) {
        // 有权限才能编辑
        if (this.info.complete || this.info.save) {
          let index = Number(e.currentTarget.dataset.id);
          let item =
            app.data.inspectionDetailView_data.work_order_complete_info_line_ids[
              index
            ];
          app.router.navigate("/addInspectionWorkInfo/", {
            reloadCurrent: false,
            ignoreCache: true,
            context: {
              info: this.lists,
              isEdit: item,
              lineindex: index,
              isInspection: true,
            },
          });
        }
      },
      //下载文件
      downloadFile(e) {
        window.open(e.currentTarget.dataset.url);
      },
      //文件上传后的处理函数
      filechange() {
        Array.from(this.$$("#file-attach")[0].files).forEach((ele) => {
          if (ele.type.indexOf("image") > -1) {
            this.fileList.images.push(ele);
          } else {
            this.fileList.files.push(ele);
          }
        });
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files,
        });
        //处理图片上传后事件冲突的问题
        $(".pb-standalone-dark").off("click");
        console.log(app.data)
        $(".pb-standalone-dark").on("click", function (event) {
          app.data.inspectionDetailView_data.myPhotoBrowserDark.open(
            Number(event.currentTarget.dataset.index)
          );
        });
        $(".addimg").off("click");
        $(".addimg").on("click", function (event) {
          $("#file-attach").trigger("click");
        });
        app.data.inspectionDetailView_data.myPhotoBrowserDark.params.photos = this.fileList.images;
        // 读取文件的函数
        function readAndPreview(file) {
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              file.url = this.result;
              file.caption = file.name;
            },
            false
          );
          if (!file.url) reader.readAsDataURL(file);
        }
        [].forEach.call(this.fileList.images, readAndPreview);
        setTimeout(() => {
          this.fileList.images.forEach((ele, index) => {
            $("img")[index].src = ele.url;
          });
        }, 100);
        console.log(this.fileList);
      },
      // 删除上传的文件
      delFile(val) {
        let e = window.event;
        e.stopPropagation();
        if (!this.delete_attachment_ids) this.delete_attachment_ids = [];
        if (e.currentTarget.dataset.id)
          this.delete_attachment_ids.push(Number(e.currentTarget.dataset.id));
        if (val == "img") {
          // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            images: this.fileList.images,
          });

          //处理图片删除后事件冲突的问题
          $(".addimg").off("click");
          $(".addimg").on("click", function (event) {
            $("#file-attach").trigger("click");
          });

          app.data.inspectionDetailView_data.myPhotoBrowserDark.params.photos = this.fileList.images;
          this.fileList.images.forEach((ele, index) => {
            ele.caption = ele.name;
            $("img")[index].src = ele.url;
          });
        } else {
          // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index), 1);
          this.$setState({
            files: this.fileList.files,
          });
        }
      },
      //获取工程师列表的函数
      getEngineerList(page) {
        let that = this;
        let promise = new Promise((resolve, reject) => {
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          var repairId = page.app.data.inspection_data.work_order_id;
          if (page.app.data.session.source == "dingding") {
            var paramrs = this.getParams("select_engineer", {
              dingding_code: dingding_code,
              work_order_id: repairId,
            });
          } else if (page.app.data.session.source == "feishu") {
            var paramrs = this.getParams("select_engineer", {
              feishu_userid: feishu_userid,
              work_order_id: repairId,
            });
          } else {
            var paramrs = this.getParams("select_engineer", {
              wechat_name: vuserid,
              work_order_id: repairId,
            });
          }
          page.app.request.postJSON(
            page.app.data.url,
            paramrs,
            function (result) {
              if (result.result) {
                that.$setState({
                  engineerList: result.result.data.engineer,
                });
                $("#engineer").val(
                  page.app.data.inspection_data.default_engineer_id
                );
                $(".tigger .item-after").text(
                  page.app.data.inspection_data.default_engineer_name
                );
                resolve(result);
              } else {
                page.app.dialog.alert("系统错误！", "系统提示");
              }
            }
          );
        });
        return promise;
      },
      confirm() {
        let engineer = $(".tigger .item-after").text();
        if (engineer === "请选择" || engineer == "") {
          app.dialog.alert("请先选择工程师！", "温馨提示");
        } else {
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          var repairId = app.data.inspection_data.work_order_id;
          if (app.data.inspection_data.button_assign) {
            // 判断是分配还是重新分配
            var assign = "confirm_assign";
          } else {
            var assign = "confirm_reassign";
          }
          if (app.data.session.source == "dingding") {
            var paramrs = this.getParams(assign, {
              dingding_code: dingding_code,
              work_order_id: repairId,
              engineer_id: $("#engineer").val()
                ? $("#engineer").val()
                : app.data.inspection_data.default_engineer_id,
            });
          } else if (app.data.session.source == "feishu") {
            var paramrs = this.getParams(assign, {
              feishu_userid: feishu_userid,
              work_order_id: repairId,
              engineer_id: $("#engineer").val()
                ? $("#engineer").val()
                : app.data.inspection_data.default_engineer_id,
            });
          } else {
            var paramrs = this.getParams(assign, {
              wechat_name: vuserid,
              work_order_id: repairId,
              engineer_id: $("#engineer").val()
                ? $("#engineer").val()
                : app.data.inspection_data.default_engineer_id,
            });
          }
          app.request.postJSON(app.data.url, paramrs, function (result) {
            if (result.result) {
              app.popup.get(".popup-about").close(false);
              let toast = app.toast.create({
                text: "分配成功！",
                position: "center",
                closeTimeout: 2000,
              });
              toast.open();
              app.router.back();
            } else {
              app.dialog.alert("分配失败！", "系统提示");
            }
          });
        }
      },
      submit(val) {
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        var work_order_id = app.data.inspectionDetailView_data.work_order_id;
        if (app.data.session.source == "dingding") {
          //完工的参数
          var createParams = this.getParams("object_action_complete", {
            work_order_id: work_order_id,
            dingding_code: dingding_code
          });

          var createParams_before = this.getParams("validate_routing_before_complete", {
            work_order_id: work_order_id,
            dingding_code: dingding_code
          });
        } else if (app.data.session.source == "feishu") {
          //完工的参数
          var createParams = this.getParams("object_action_complete", {
            work_order_id: work_order_id,
            feishu_userid: feishu_userid
          });

          var createParams_before = this.getParams("validate_routing_before_complete", {
            work_order_id: work_order_id,
            feishu_userid: feishu_userid
          });
        } else {
          var createParams = this.getParams("object_action_complete", {
            work_order_id: work_order_id,
            wechat_name: vuserid
          });
          
          var createParams_before = this.getParams("validate_routing_before_complete", {
            work_order_id: work_order_id,
            wechat_name: vuserid
          });
        }


        app.preloader.show();
        app.request.postJSON(app.data.url, createParams_before, (result) => {
          if (result.result) {
            
            if(result.result.state == "ok"){
              app.preloader.hide();

              app.preloader.show();
              app.dialog.confirm(
                "您确认完成该工作单吗？",
                function () {
                  app.request.postJSON(app.data.url, createParams, (result) => {
                    if (result.result) {
                      
                      if(result.result.state == "completed"){
                        app.preloader.hide()
                        var toast = app.toast.create({
                          text: "恭喜您,操作成功！",
                          position: "center",
                          closeTimeout: 2000
                        });
                        toast.open();
                        app.router.back('/workOrderCompleted/',{
                          force: true
                        })
                      }else{
                        app.preloader.hide();
                        app.dialog.alert("系统错误！", "系统提示");
                      }
                    } else {
                      app.preloader.hide();
                      app.dialog.alert("系统错误！", "系统提示");
                    }
                  });
                },
                function () {
                  app.preloader.hide();
                }
              );

            }else{
              app.preloader.hide();
              app.dialog.alert(result.result.err_str, "系统提示");
            }
          } else {
            app.preloader.hide();
            app.dialog.alert("系统错误！", "系统提示");
          }
        });


      },
      // 上传文件到服务器的函数
      submitFiles(val) {
        let pro = new Promise((resolve, reject) => {
          var formData = new FormData();
          let post_url = this.$app.data.base_url + "/web/map_upload_attachment";
          formData.append("record_id", val.record_id);
          formData.append("model_name", val.record_model);
          formData.append("db", this.$app.data.dbname);
          formData.append("id", this.$app.data.userid);
          formData.append("pwd", this.$app.data.password);
          for (var i = 0; i < this.fileList.images.length; i++) {
            var file = this.fileList.images[i];
            if (!file.attachment_id) formData.append("file", file);
          }
          for (var i = 0; i < this.fileList.files.length; i++) {
            var file = this.fileList.files[i];
            if (!file.attachment_id) formData.append("file", file);
          }
          if (this.$app.data.session.source === "dingding") {
            formData.append("dingding_code", this.$app.data.dingding_code);
          } else if (this.$app.data.session.source === "feishu") {
            formData.append("feishu_userid", this.$app.data.feishu_userid);
          } else {
            formData.append("wechat_name", this.$app.data.session.userid);
          }
          this.$app.request.post(post_url, formData, (result) => {
            if (JSON.parse(result).state === "ok") {
              var toast = this.$app.toast.create({
                text: "恭喜您,操作成功",
                position: "center",
                closeTimeout: 2000,
              });
              toast.open();
              // self.$app.router.navigate('/workOrderCompleted/', {})
            } else {
              var toast = this.$app.toast.create({
                text: "很遗憾,操作失败",
                position: "center",
                closeTimeout: 2000,
              });
              toast.open();
            }
            resolve();
          });
        });
        return pro;
      },
    },
    on: {
      pageInit: function (e, page) {
        console.log('pageInitpageInit',this.info)


      },
      pageMounted: function (e, page) {
        console.log("InspectionpageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("InspectionpageAfterIn",this.id);
        // let smartSelect = page.app.smartSelect.get(".smart-select");
        // $(".tigger").on("click", function (page) {
        //   smartSelect.open();
        // });
        // smartSelect.on("close", function () {
        //   if (smartSelect.$valueEl.text()) {
        //     $(".tigger .item-after").text(smartSelect.$valueEl.text());
        //   }
        // });

        let vuserid = page.app.data.session.userid;
        let dingding_code = page.app.data.dingding_code;
        let feishu_userid = page.app.data.feishu_userid;
        const self = this
        if (page.app.data.session.source == "dingding") {
          var inspectionDetailParams = this.getParams(
            "get_maintain_details",
            {
              work_order_id: this.id,
              dingding_code: dingding_code,
              identifying_code2: "routing",
              identifying_code1: "complete",
            }
          );
        } else if (page.app.data.session.source == "feishu") {
          var inspectionDetailParams = this.getParams(
            "get_maintain_details",
            {
              work_order_id: this.id,
              feishu_userid: feishu_userid,
              identifying_code2: "routing",
              identifying_code1: "complete",
            }
          );
        } else {
          var inspectionDetailParams = this.getParams(
            "get_maintain_details",
            {
              work_order_id: this.id,
              wechat_name: vuserid,
              identifying_code2: "routing",
              identifying_code1: "complete",
            }
          );
        }
        page.app.request.postJSON(
          page.app.data.url,
          inspectionDetailParams,
          function (result) {
            if (result.result) {
              console.log(result.result, "巡检详情");
              page.app.data.inspectionDetailView_data = result.result.data;
              result.result.data.save =
                result.result.data.extra_state.indexOf("edit") > -1;
              result.result.data.complete =
                result.result.data.extra_state.indexOf("complete") > -1;

                self.$setState({
                  info:result.result.data
                })

                if (result.result.data.button_reassign || result.result.data.button_assign){
                  this.getEngineerList(page);
                }
            } else {
              page.app.dialog.alert("获取信息错误！", "系统提示");
            }
          }
        );
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("InspectionpageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      },
    },
  },
};
