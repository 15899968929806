/*
 * @Author: liubaozhen
 * @Date: 2023-07-04 20:50:43
 * @LastEditTime: 2024-04-18 13:25:33
 * @LastEditors: liubaozhen
 * @Description: 
 * @FilePath: \odoo\kthrp_map\src\offsetleaveapp\routes\offsetForm.js
 */
import $$ from "dom7";
import offsetForm from "../pages/offsetForm.html";
import Template7 from "template7";

export default {
  name: "offsetForm",
  path: "/offsetForm/",
  component: {
    template: offsetForm,
    data: () => {
      return {
        isFabBtn: false,
        countLine: 1,
        resumption_leave_type:'full_resumption',
        minimum_leave_unit:''
      };
    },
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        return {
            jsonrpc: "2.0",
            method: "call",
            params: {
            service: "object",
            method: "execute",
            args: [
                dbname,
                userid,
                password,
                "kthrp.hr.resumption.leave.interface",
                method,
                obj
            ]
            }
        };
      },
      leaveTypeChange(){
        let self = this
        this.resumption_leave_type = $('#resumption_leave_type').val()
        if(this.resumption_leave_type == 'full_resumption'){
            $('.stop_click').show()
            $('.actual-start-date').val('')
            $('.actual-end-date').val('')
            $('.actual-start-date').attr('disabled','disabled')
            $('.actual-end-date').attr('disabled','disabled')
            $('.actual-start-date').attr('placeholder','')
            $('.actual-end-date').attr('placeholder','')
            $('.line_actual_leave_hours').html('0')
            $('.line_actual_leave_days').html('0')
        }else{
            
            let obj = this.getUserObj()
            obj.leave_id = parseInt($('#leave_id').val())
            self.$setState({
              line_ids: []
            });
            app.preloader.show();
            this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.resumption.leave.interface', 'document_default_get', obj), function (res) {
                console.log(res);
                app.preloader.hide();
                $('.stop_click').hide()
                $('#leave_type').html(res.result.data.leave_type_id)
                
                self.$setState({
                    line_ids: res.result.data.line_ids,
                    minimum_leave_unit: res.result.data.minimum_leave_unit 
                }); 
                self.initDate() 
                $('.actual-start-date').removeAttr('disabled')
                $('.actual-end-date').removeAttr('disabled')

            });

        }
      },
      leaveChange(){
        let self = this
        let obj = this.getUserObj()
        obj.leave_id = parseInt($('#leave_id').val())
        this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.resumption.leave.interface', 'document_default_get', obj), function (res) {
            console.log(res,res.result.data.minimum_leave_unit);
            $('#leave_type').html(res.result.data.leave_type_id)
            // self.minimum_leave_unit = res.result.data.minimum_leave_unit 
            self.$setState({
                line_ids: res.result.data.line_ids,
                minimum_leave_unit: res.result.data.minimum_leave_unit 
                // line_ids_length: res.result.data.line_ids.length
            });  
            // self.minimum_leave_unit = res.result.data.line_ids   
            self.leaveTypeChange()
        });
      },
      // 格式化时间的函数，当少0的时候自动补0
      initTime: function(time){
        let timeArr = time.split(':')
        return timeArr.map(ele => {
          if(ele.length<2){
            ele = '0'+ele
          }
          return ele
        }).join(':')
      },
      submit: function (e) {
        let self = this
        let page = $$('.page[data-name="offsetleaveapplication-form"]')[0].f7Page
        let leave_id = $('#leave_id').val()
        let resumption_leave_type = $('#resumption_leave_type').val()
        let note = $('#offsetReason').val()
        let record_lines = []
        if (leave_id === '') {
          return this.$app.dialog.alert('休假申请必须有值!', "KTHRP");
        }
        if (resumption_leave_type === '') {
          return this.$app.dialog.alert('销假类型必须有值!', "KTHRP");
        }
        if (note === '') {
          return this.$app.dialog.alert('事由必须有值!', "KTHRP");
        }

        var lines = $$('.list.offsetleaveapplication-form-line')
        
        if(resumption_leave_type == 'part_resumption'){
          for (let i = 0; i < lines.length; i++) {
            var lineData = {}

            var start =  $$(lines[i]).find('.actual-start-date').val().trim().replace(/-/g, "/")
            var end =  $$(lines[i]).find('.actual-end-date').val().trim().replace(/-/g, "/")
            if(!start){
              return self.$app.dialog.alert('销假明细第'+(i+1)+'行实际开始时间必须有值！', "KTHRP")
            }
            if(!end){
              return self.$app.dialog.alert('销假明细第'+(i+1)+'行实际结束时间必须有值！', "KTHRP")
            }
            if(self.minimum_leave_unit=='hour'){
              if(start && end && new Date(start)-new Date(end)>=0){
                return self.$app.dialog.alert('实际结束时间必须大于实际开始时间！', "KTHRP")
              }
            }else if(self.minimum_leave_unit=='day'){
              if(start && end && new Date(start)-new Date(end)>0){
                return self.$app.dialog.alert('实际结束时间必须大于实际开始时间！', "KTHRP")
              }
            }else{
              start = start.replace('上午','am').replace('下午','pm')
              end = end.replace('上午','am').replace('下午','pm')
              if(start && end && new Date(start.substring(0,10))-new Date(end.substring(0,10))>0){
                return self.$app.dialog.alert('实际结束时间必须大于实际开始时间！', "KTHRP")
              }
              if(start && end && new Date(start.substring(0,10))-new Date(end.substring(0,10))==0 && start.indexOf('pm') >-1 && end.indexOf('am')){
                return self.$app.dialog.alert('实际结束时间必须大于实际开始时间！', "KTHRP")
              }
            }

            lineData.actual_start_date = self.minimum_leave_unit=='hour'? $$(lines[i]).find('.actual-start-date').val().trim()+':00' : $$(lines[i]).find('.actual-start-date').val().trim().replace('上午','am').replace('下午','pm')
            lineData.actual_end_date = self.minimum_leave_unit=='hour'? $$(lines[i]).find('.actual-end-date').val().trim()+':00' : $$(lines[i]).find('.actual-end-date').val().trim().replace('上午','am').replace('下午','pm')
            lineData.line_no = self.line_ids[i].line_no
            lineData.line_id = self.line_ids[i].line_id
            // lineData.actual_leave_days = $$(lines[i]).find('.line_actual_leave_days').html()
            // lineData.actual_leave_hours = $$(lines[i]).find('.line_actual_leave_hours').html()
            record_lines.push(lineData)
          }
  
        }else{
          for (let i = 0; i < lines.length; i++) {
            var lineData = {}
            lineData.actual_start_date = ''
            lineData.actual_end_date = ''
            // lineData.actual_leave_days = 0
            // lineData.actual_leave_hours = 0
            lineData.line_no = self.line_ids[i].line_no
            lineData.line_id = self.line_ids[i].line_id
            record_lines.push(lineData)
          }
        }
        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;
        if (page.app.data.session.source == 'dingding') {
          var create_resumption_leave = this.getParams("create_resumption_leave", {
            "dingding_code": dingding_code,
            leave_id:leave_id * 1,
            resumption_leave_type,
            note,
            record_lines
          });

        } else if (page.app.data.session.source == 'feishu') {
          var create_resumption_leave = this.getParams("create_resumption_leave", {
            "feishu_userid": feishu_userid,
            leave_id:leave_id * 1,
            resumption_leave_type,
            note,
            record_lines
          });

        } else {
          var create_resumption_leave = this.getParams("create_resumption_leave", {
            "wechat_name": vuserid,
            leave_id:leave_id * 1,
            resumption_leave_type,
            note,
            record_lines
          });
        }
        console.log('创建：',create_resumption_leave)
        app.preloader.show()
        page.app.request.postJSON(
          page.app.data.url,
          create_resumption_leave,
          function (res) {
            console.log('create_resumption_leave:',res)
            if(res.result.state == 'ok'){
                
                let files = self.$$('#file-attach')[0].files
                if (files.length === 0) {
                  app.preloader.hide()
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  app.router.back('/offsetleaveapplicationlist/',{
                    force: true
                  }) 
                  
                  return 
                }
                let post_file_url = self.$app.data.base_url + '/web/map_upload_attachment'
                let formData = new FormData()
                formData.append('db', self.$app.data.dbname)
                formData.append('id', self.$app.data.userid)
                formData.append('pwd', self.$app.data.password)
                formData.append('model_name', res.result.data.model_name)
                formData.append('record_id', res.result.data.record_id)
                if (self.$app.data.session.source == 'dingding') {
                  formData.append('dingding_code', self.$app.data.dingding_code)
                } else if (self.$app.data.session.source == 'feishu') {
                  formData.append('feishu_userid', self.$app.data.feishu_userid)
                } else {
                  formData.append('wechat_name', self.$app.data.session.userid)
                }
                for (let i = 0; i < files.length; i++) {
                  let file = files[i];
                  formData.append("file", file)
                }
                self.$app.request.post(post_file_url, formData, function (result) {
                  console.log(result)
                  if (JSON.parse(result).state === 'ok') {
                    app.preloader.hide()
                    var toast = app.toast.create({
                      text: '恭喜您,操作成功',
                      position: 'top',
                      closeTimeout: 2000
                    })
                    toast.open()
                    app.router.back('/offsetleaveapplicationlist/',{
                      force: true
                    }) 
                  } else {
                    app.preloader.hide()
                    var toast = app.toast.create({
                      text: '很遗憾,操作失败',
                      position: 'top',
                      closeTimeout: 2000
                    })
                    toast.open()
                    app.router.back('/offsetleaveapplicationlist/',{
                      force: true
                    }) 
                  }
                });
            }else{
              app.preloader.hide()
              app.dialog.alert(res.result.err, "系统提示");
            }

          }
        );
      },
      getDate: function (date) {
        return date.substring(0, date.indexOf(' '))
      },
      getTime: function (date) {
        return date.substring(date.indexOf(' ') + 1, date.length)
      },
      addLine: function () {
        const {
          $$
        } = this;
        var self = this
        this.countLine++
        var page = $$('.page[data-name="offsetleaveapplication-form"]')[0].f7Page;
        var compiledExpenseLineDistributionTemplate = Template7.compile(
          offsetFormNewLineTemplate
        );
        var html = compiledExpenseLineDistributionTemplate({
          date_start: self.getDate(self.$route.context.info.start_date),
          time_start: self.getTime(self.$route.context.info.start_date),
          date_end: self.getDate(self.$route.context.info.end_date),
          time_end: self.getTime(self.$route.context.info.end_date),
          line: 'line' + this.countLine
        });

        $$('.page[data-name="offsetleaveapplication-form"] .offsetleaveapplication-form-list').append(html);
        $$(".delete-line-btn").on("click", function (e) {
          console.log("删除行");
          console.log($$(e.currentTarget).parent().parent());
          $$(e.currentTarget).parent().parent().remove();
        });

        this.initDate()



      },
      getSum: function (arr) {
        var len = arr.length;
        var sum = 0;
        for (var i = 0; i < len; i++) {
          sum += parseFloat(arr[i].amount);
        }
        return sum;
      },
      sysDate(){
        var myDate = new Date();
        var year = myDate.getFullYear();
        var month = myDate.getMonth()+1;
        var date = myDate.getDate();
        if (month < 10) {
            month = "0" + month
        }
        if (date < 10) {
            date = "0" + date
        }
        //当前系统时间
        return year+"-"+month+"-"+date;
      },
      calcTime(picker,el){
        let self = this
        let $el = $$(picker.params.input)
        let curList = $el.parent().parent().parent().parent()
        if (curList.find(el).val() != ''  && curList.find(el).val() != '' ) {

          let obj = self.getUserObj()
          obj.leave_id = parseInt($('#leave_id').val())

          obj.record_line = {
            actual_start_date:self.minimum_leave_unit=='hour'? curList.find('.actual-start-date').val().trim()+':00' : curList.find('.actual-start-date').val().trim().replace('上午','am').replace('下午','pm'),
            actual_end_date: self.minimum_leave_unit=='hour'? curList.find('.actual-end-date').val().trim()+':00' : curList.find('.actual-end-date').val().trim().replace('上午','am').replace('下午','pm')
          }

          self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.resumption.leave.interface', 'update_line', obj), function (res) {
            console.log(res);
            if (res.result.state === 'ok') {
                if(self.minimum_leave_unit == 'hour'){
                    curList.find('.line_actual_leave_hours').html(res.result.data.leave_hours)
                }else{
                    curList.find('.line_actual_leave_days').html(res.result.data.leave_days)
                }
            }
          });
        }
      },
      initDate(){
        let self = this
        if(this.minimum_leave_unit=='day'){
          $('.actual-start-date').datetimePicker({
            title: "",
            // years:[self.sysDate().substring(0,4)],
            times: function () {
                return [];
            },
            // value: self.sysDate() + ' ',
            onChange: function (picker, values, displayValues) {
              console.log('onChange');
            },
            onClose: function (picker) {
              console.log('onClose',picker);
              self.calcTime(picker,'.actual-start-date')
            },
            onShow: function (picker) {
              console.log('onShow');
            },
  
        });
          $('.actual-end-date').datetimePicker({
            title: "",
            // years:[self.sysDate().substring(0,4)],
            times: function () {
                return [];
            },
            // value: self.sysDate() + ' ',
            onChange: function (picker, values, displayValues) {
              console.log('onChange');
            },
            onClose: function (picker) {
              console.log('onClose');
              self.calcTime(picker,'.actual-end-date')
            },
            onShow: function (picker) {
              console.log('onShow');
            },
  
        })

        }else if(this.minimum_leave_unit=='half_day'){
    
            $('.actual-start-date').datetimePicker({
              title: "",
              // years:[self.sysDate().substring(0,4)],
              times: function () {
                  return [{
                      values: ['上午', '下午']
                  }];
              },
            //   value: self.sysDate() + ' 上午',
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.actual-start-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
          });
            $('.actual-end-date').datetimePicker({
              title: "",
              // years:[self.sysDate().substring(0,4)],
              times: function () {
                  return [{
                      values: ['上午', '下午']
                  }];
              },
            //   value: self.sysDate() + ' 下午',
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.actual-end-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
          })
        }else{
            $('.actual-start-date').datetimePicker({
              title: "",
              // years:[self.sysDate().substring(0,4)],
            //   value: self.info.start_date.substring(0,16),
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.actual-start-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
          });
            $('.actual-end-date').datetimePicker({
              title: "",
              // years:[self.sysDate().substring(0,4)],
            //   value: self.info.end_date.substring(0,16),
              onChange: function (picker, values, displayValues) {
              },
              onClose: function (picker) {
                console.log('onClose',picker);
                self.calcTime(picker,'.actual-end-date')
              },
              onShow: function (picker) {
                console.log('onShow');
              },
    
          })
        }
      }

    },
    on: {
      pageInit: function (e, page) {
        let self = this;
        // $$('input').on('blur', function () {
        //   window.scroll(0, 0);
        // });
        // this.initDate()

      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};