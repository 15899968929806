import $ from "dom7";
import assetsInventoryDetail from "./detail";
import assetsInventoryCreate from "./create";
import assetsInventoryList from "./list";
import html from "../pages/index.html";
import style from "../styles/index.less";
import app from "../../app";

export default [{
    name: "/assetsInventory/",
    path: "/assetsInventory/",
    // url: "./expense/pages/index.html"
    component: {
      template: html,
      style: style,
      methods: {
        toDetailAssetForm: function (e) {
            app.router.navigate("/assetsInventoryDetail/", {
                reloadCurrent: false,
                ignoreCache: true
              });
        }
      },
      on: {
        pageInit: function (e, page) {
          var self = this;
          let allList = [{
            id: '001',
            order_name: '申领的物资名称1',
            order_code: 202010201604001,
            order_type: "申领总仓物资",
            tel: '13564736282',
            level:'一般',
            company: '康博嘉北京医院',
            state: '盘点中',
            applyer: '刘大伟',
            fromDate: '2020年10月20日',
            toDate: '2020年10月28日',
            count: '10',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '儿科',
            description: '盘点资产并上传资产照片'
          },{
            id: '002',
            order_name: '申领的物资名称2',
            order_code: 2020101501604002,
            order_type: "申领总仓物资",
            tel: '15467856432',
            fromDate: '2020年10月20日',
            toDate: '2020年10月28日',
            count: '10',
            level:'一般',
            company: '康博嘉武汉医院',
            state: '盘点中',
            applyer: '曾小贤',
            apply_date: '2020年10月15日 16时23分60秒',
            apply_dept: '妇产科',
            description: '盘点资产并上传资产照片'
          },{
            id: '003',
            order_name: '申领的物资名称3',
            order_code: 202010220923003,
            order_type: "申领总仓物资",
            tel: '13564736282',
            fromDate: '2020年10月20日',
            toDate: '2020年10月28日',
            count: '10',
            level:'一般',
            company: '康博嘉北京医院',
            state: '未开始',
            applyer: '刘大伟',
            apply_date: '2020年10月22日 09时23分20秒',
            apply_dept: '牙科',
            description: '盘点资产并上传资产照片'
          },{
            id: '004',
            order_name: '申领的物资名称4',
            order_code: 202010201604004,
            order_type: "申领总仓物资",
            tel: '13564736282',
            fromDate: '2020年10月20日',
            toDate: '2020年10月28日',
            count: '10',
            level:'一般',
            company: '康博嘉北京医院',
            state: '盘点中',
            applyer: '刘大伟',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '精神科',
            description: '盘点资产并上传资产照片'
          },{
            id: '005',
            order_name: '申领的物资名称5',
            order_code: 202010201604005,
            order_type: "申领总仓物资",
            tel: '13564736282',
            fromDate: '2020年10月20日',
            toDate: '2020年10月28日',
            count: '10',
            level:'一般',
            company: '康博嘉北京医院',
            state: '未开始',
            applyer: '李冰',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '内科',
            description: '急需用品，请尽快安排到位'
          },{
            id: '006',
            order_name: '申领的物资名称6',
            order_code: 202010201604001,
            order_type: "申领总仓物资",
            tel: '13564736282',
            fromDate: '2020年10月20日',
            toDate: '2020年10月28日',
            count: '10',
            level:'一般',
            company: '康博嘉北京医院',
            state: '未开始',
            applyer: '王倩',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '神经外科',
            description: '盘点资产并上传资产照片'
          }]
          this.$setState({
            allList: allList,
            allListCount: allList.length
          })
          page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
            $(e.currentTarget)
              .parent()
              .parent()
              .prev()
              .find(".searchbar input")
              .blur();
          });
           //未处理 搜索框实例
           var sbtodo = page.app.searchbar.create({
            el: '[data-name="assetsInventory-index"] .searchbar-to-do',
            searchContainer: '[data-name="assetsInventory-index"] .list-to-do',
            searchIn: '[data-name="assetsInventory-index"] .item-text-to-do',
            notFoudEl: '[data-name="assetsInventory-index"] .searchbar-not-found-to-do',
          });
          var sbdone = page.app.searchbar.create({
            el: ".searchbar-done",
            searchContainer: ".list-done",
            searchIn: ".item-text-done",
            notFoudEl: ".searchbar-not-found-done",
          });
          $('[data-name="assetsInventory-index"] .searchbar-done').hide();
          $('[data-name="assetsInventory-index"] .search-icon-2').hide();

          $('[data-name="assetsInventory-index"] .to-do-btn').on("click", function () {
            sbdone.disable();
            $('[data-name="assetsInventory-index"] .searchbar-done,[data-name="assetsInventory-index"] .search-icon-2').hide();
            $('[data-name="assetsInventory-index"] .searchbar-to-do,[data-name="assetsInventory-index"] .search-icon-1').show();
          });
          $('[data-name="assetsInventory-index"] .done-btn').on("click", function () {
            sbtodo.disable();
            $('[data-name="assetsInventory-index"] .searchbar-done,[data-name="assetsInventory-index"] .search-icon-2').show();
            $('[data-name="assetsInventory-index"] .searchbar-to-do,[data-name="assetsInventory-index"] .search-icon-1').hide();
          });

        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  assetsInventoryDetail,
  assetsInventoryCreate,
  assetsInventoryList
];