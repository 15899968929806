import $$ from "dom7";
import assetsInventoryCreate from "../pages/create.html";
import Template7 from "template7";
import * as dd from 'dingtalk-jsapi'


export default {
  name: "assetsInventoryCreate",
  path: "/assetsInventoryCreate/",
  component: {
    template: assetsInventoryCreate,
    methods: {
        submit(){
            app.router.back('/assetsInventory/',{
                force: true
              })
        }
    },
    on: {
      pageInit: function (e, page) {
        var myPhotoBrowserStandalone = app.photoBrowser.create({
            photos : [
                '../img/11.jpg'
            ],
            theme: 'dark',
            backLinkText: '关闭'
        });
        //   $('.imageBox').on('click',()=>{
        //     myPhotoBrowserStandalone.open();
        //   })
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};