/*
 * @Author: liubaozhen
 * @Date: 2023-07-04 20:50:43
 * @LastEditTime: 2024-04-18 13:21:48
 * @LastEditors: liubaozhen
 * @Description: 
 * @FilePath: \odoo\kthrp_map\src\receptionApp\routes\receptionAppForm.js
 */
import $$ from "dom7";
import receptionAppForm from "../pages/receptionAppForm.html";
import Template7 from "template7";
import receptionAppFormNewLine from "../pages/receptionAppFormNewLine.html";


export default {
  name: "receptionappform",
  path: "/receptionappform/",
  component: {
    template: receptionAppForm,
    data: () => {
      return {
        isFabBtn: false,
        countLine: 1,
      };
    },
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      submit: function (e) {
        let self = this
        var page = $$('.page[data-name="receptionapp-form"]')[0].f7Page
        let receptionappFormData = self.$app.form.convertToData("#receptionapp-form");
        let post_file_url = this.$app.data.base_url + '/web/map_upload_attachment'
        var formData = new FormData()
        let files = this.$$('#file-attach')[0].files
        if (!receptionappFormData.note) {
          return self.$app.dialog.alert('应聘人员名单及拟聘职务必须有值!', "KTHRP");
        }
        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        if (self.$app.data.session.source == 'dingding') {
          formData.append('dingding_code', self.$app.data.dingding_code)
        } else if (self.$app.data.session.source == 'feishu') {
          formData.append('feishu_userid', self.$app.data.feishu_userid)
        } else {
          formData.append('wechat_name', self.$app.data.session.userid)
        }
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          formData.append("file", file)
        }
        receptionappFormData = {
          ...receptionappFormData,
          ...self.getUserObj(),
          ...{
            reception_requisition_line_ids: []
          }
        }
        var lines = $$('.list.receptionapp-form-new-line')
        for (var i = 0; i < lines.length; i++) {
          if (!self.$app.form.convertToData(lines[i]).expense_note) {
            return self.$app.dialog.alert('费用说明必须有值!', 'KTHRP')
          }
          receptionappFormData.reception_requisition_line_ids.push(self.$app.form.convertToData(lines[i]))
        }
        console.log(receptionappFormData)
        app.preloader.show();
        self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.reception.application.interface', 'create_reception_requisition', receptionappFormData), function (res) {
          console.log(res);
          if (res.result.state === 'ok') {
            if (files.length === 0) {
              var toast = app.toast.create({
                text: '恭喜您,操作成功',
                position: 'top',
                closeTimeout: 2000
              })
              app.preloader.hide()
              toast.open()
              page.router.navigate('/receptionapplist/', {
                // reloadCurrent: true,
                // ignoreCache: true
              })
            } else {
              formData.append('model_name', res.result.data.model_name)
              formData.append('record_id', res.result.data.record_id)
              self.$app.request.post(post_file_url, formData, function (result) {
                console.log(result)
                if (JSON.parse(result).state === 'ok') {
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide()
                  toast.open()
                  page.router.navigate('/receptionapplist/', {
                    // reloadCurrent: true,
                    // ignoreCache: true
                  })
                } else {
                  var toast = app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide()
                  toast.open()
                  page.router.navigate('/receptionapplist/', {
                    // reloadCurrent: true,
                    // ignoreCache: true
                  })
                }
              });
            }
          } else if (res.result.state === 'warn') {
            app.preloader.hide()
            self.$app.dialog.alert(res.result.err, "KTHRP");
          } else {
            app.preloader.hide()
            self.$app.dialog.alert(res.result.err, "KTHRP");
          }
        });
      },
      getDate: function (date) {
        return date.substring(0, date.indexOf(' '))
      },
      getTime: function (date) {
        return date.substring(date.indexOf(' ') + 1, date.length)
      },
      addLine: function () {
        const {
          $$
        } = this;
        var self = this
        this.countLine++
        var page = $$('.page[data-name="receptionapp-form"]')[0].f7Page;
        var receptionAppFormNewLineTemplate = Template7.compile(
          receptionAppFormNewLine
        );
        var html = receptionAppFormNewLineTemplate({});

        $$('.page[data-name="receptionapp-form"] .receptionapp-form-list').append(html);
        $$('.money_input').change(function () {
          let type = $$(this).attr('id')
          self.padding(type, self.getKindSum(type))
        })
        $$(".delete-line-btn").on("click", function (e) {
          $$(e.currentTarget).parent().parent().remove()
          self.padding('transportation', self.getKindSum('transportation'))
          self.padding('accommodation', self.getKindSum('accommodation'))
          self.padding('eatery', self.getKindSum('eatery'))
          self.padding('other', self.getKindSum('other'))
        });
      },
      getKindSum: function (type) {
        const {
          $$
        } = this
        let self = this
        let sum = 0
        $$('.single_' + type).each(function () {
          if ($$(this).val()) {
            sum = sum + parseFloat($$(this).val())
          }
        })
        return sum
      },
      padding: function (type, val) {
        const {
          $$
        } = this
        let self = this
        if ($$('.' + type).length > 0) {
          $$('.' + type).html(val)
        }
      },
    },
    on: {
      pageInit: function (e, page) {
        let self = this;
        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });
        page.app.form.fillFromData("#receptionapp-form", {
          origin: "",
          destination: "",
        });
        $$('.money_input').change(function () {
          let type = $$(this).attr('id')
          self.padding(type, self.getKindSum(type))
        })
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};