import $ from "dom7";
import assetForm from "./assetForm";
import assetViewForm from "./assetViewForm";
import assetListView from "./assetListView";
import score from "./score";
import html from "../pages/index.html";
import style from "../styles/asset.less";

export default [{
    name: "/asset/",
    path: "/asset/",
    // url: "./expense/pages/index.html"
    component: {
      template: html,
      style: style,
      methods: {
        getAssetMaintenanceParams: function (method, obj) {
          var vuserid = this.$app.data.session.userid;
          var dbname = this.$app.data.dbname;
          var userid = this.$app.data.userid;
          var password = this.$app.data.password;
          return {
            jsonrpc: "2.0",
            method: "call",
            params: {
              service: "object",
              method: "execute",
              args: [
                dbname,
                userid,
                password,
                "kthrp.asset.maintenance.repair.requisition.interface",
                method,
                obj
              ]
            }
          };
        },

        toDetailAssetForm: function (e) {
          const {
            $$
          } = this;
          var page = $$('.page[data-name="asset-index"]')[0].f7Page;
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          var asset_maintenance_line_id = parseInt(e.currentTarget.dataset.id);
          // var model = e.currentTarget.dataset.model;
          if (page.app.data.session.source == 'dingding') {
            var assetMainDetailParams = this.getAssetMaintenanceParams("get_repair_requisition_detail", {
              "asset_maintenance_line_id": asset_maintenance_line_id,
              "dingding_code": dingding_code,
            });
          } else if (page.app.data.session.source == 'feishu') {
            var assetMainDetailParams = this.getAssetMaintenanceParams("get_repair_requisition_detail", {
              "asset_maintenance_line_id": asset_maintenance_line_id,
              "feishu_userid": feishu_userid,
            });
          } else {
            var assetMainDetailParams = this.getAssetMaintenanceParams("get_repair_requisition_detail", {
              "asset_maintenance_line_id": asset_maintenance_line_id,
              "wechat_name": vuserid,
            });
          }

          page.app.request.postJSON(page.app.data.url, assetMainDetailParams, function (asset_main_detail_result) {
            console.log(asset_main_detail_result.result);
            page.app.data.asset_data.form_data = asset_main_detail_result.result.show_form_data;

            page.router.navigate("/assetviewform/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                info: asset_main_detail_result.result.show_form_data
              }
            });
          });
        }
      },
      on: {
        pageInit: function (e, page) {
          var self = this;

          page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
            $(e.currentTarget)
              .parent()
              .parent()
              .prev()
              .find(".searchbar input")
              .blur();
          });

          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var post_list_asset_maintenance_paramrs = this.getAssetMaintenanceParams("get_repair_requisition", {
              "dingding_code": dingding_code
            });
            var post_list_phone_and_assets_paramrs = this.getAssetMaintenanceParams("get_user_phone_and_assets", {
              "dingding_code": dingding_code
            });
          } else if (page.app.data.session.source == 'feishu') {
            var post_list_asset_maintenance_paramrs = this.getAssetMaintenanceParams("get_repair_requisition", {
              "feishu_userid": feishu_userid
            });
            var post_list_phone_and_assets_paramrs = this.getAssetMaintenanceParams("get_user_phone_and_assets", {
              "feishu_userid": feishu_userid
            });
          } else {
            var post_list_asset_maintenance_paramrs = this.getAssetMaintenanceParams("get_repair_requisition", {
              "wechat_name": vuserid,
            });
            var post_list_phone_and_assets_paramrs = this.getAssetMaintenanceParams("get_user_phone_and_assets", {
              "wechat_name": vuserid,
            });
          }

          page.app.request.postJSON(
            page.app.data.url,
            post_list_asset_maintenance_paramrs,
            function (result_list) {
              console.log(result_list);
              self.$setState({
                allAssetMaintenance: result_list.result.all_asset_maintenance,
                processingAssetMaintenance: result_list.result.processing_asset_maintenance,
                processingAssetMaintenanceCount: result_list.result.processing_asset_maintenance.length,
                allAssetMaintenanceCount: result_list.result.all_asset_maintenance.length
              });
              page.app.data.asset_data.telephone = result_list.result.telephone;
              //未处理 搜索框实例
              var sbtodo = page.app.searchbar.create({
                el: '[data-name="asset-index"] .searchbar-to-do',
                searchContainer: '[data-name="asset-index"] .list-to-do',
                searchIn: '[data-name="asset-index"] .item-text-to-do',
                notFoudEl: '[data-name="asset-index"] .searchbar-not-found-to-do',
              });
              var sbdone = page.app.searchbar.create({
                el: ".searchbar-done",
                searchContainer: ".list-done",
                searchIn: ".item-text-done",
                notFoudEl: ".searchbar-not-found-done",
              });
              $('[data-name="asset-index"] .searchbar-done').hide();
              $('[data-name="asset-index"] .search-icon-2').hide();

              $('[data-name="asset-index"] .to-do-btn').on("click", function () {
                sbdone.disable();
                $('[data-name="asset-index"] .searchbar-done,[data-name="asset-index"] .search-icon-2').hide();
                $('[data-name="asset-index"] .searchbar-to-do,[data-name="asset-index"] .search-icon-1').show();
              });
              $('[data-name="asset-index"] .done-btn').on("click", function () {
                sbtodo.disable();
                $('[data-name="asset-index"] .searchbar-done,[data-name="asset-index"] .search-icon-2').show();
                $('[data-name="asset-index"] .searchbar-to-do,[data-name="asset-index"] .search-icon-1').hide();
              });

            }
          );

          function get_phone_and_asset() {
            var promisePhoneAndAsset = new Promise(function (resolve, reject) {
              page.app.request.postJSON(
                page.app.data.url,
                post_list_phone_and_assets_paramrs,
                function (result_phone_and_asset) {
                  console.log("promisePhoneAndAsset");
                  resolve(result_phone_and_asset);
                }
              );
            });
            return promisePhoneAndAsset;
          }

          // get_phone_and_asset().then(function (result) {
          //   console.log(result)
          //   self.$setState({
          //     asset: result.result.asset,
          //     telephone: result.result.telephone,
          //   });
          //   page.app.data.asset_data.asset =
          //     result.result.asset;
          //   page.app.data.asset_data.telephone = result.result.telephone;
          // });

        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          //初始化置空 元数据中的form_data
          if ("form_data" in page.app.data.expense_data) {
            delete page.app.data.expense_data["form_data"];
          }
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
          page.app.toolbar.hide(".expense-toolbar", false);
          $(".expense-toolbar").css("opacity", 0);
          $('.expense-checkbox input[type="checkbox"]').prop("checked", false);
          $(".expense-list-checkbox-icon").css("margin-left", "-80px");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  assetForm,
  assetViewForm,
  assetListView,
  score
];