import $ from "dom7";
import loanForm from "./loanForm";
import loanFormView from "./loanFormView";
import html from "../pages/index.html";
import style from "../styles/index.less";
import app from "../../app";

export default [{
    name: "/loanIndex/",
    path: "/loanIndex/",
    component: {
      template: html,
      style: style,
      methods: {
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            return {
                jsonrpc: "2.0",
                method: "call",
                params: {
                service: "object",
                method: "execute",
                args: [
                    dbname,
                    userid,
                    password,
                    "kthrp.expense.borrowing.requisition.interface",
                    method,
                    obj
                ]
                }
            };
            },
        toDetailLoanForm: function (e) {
          const id = e.currentTarget.dataset.id
            app.router.navigate("/loanFormView/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  id
                }
              });
        },
        create:function(){
          const self = this
          app.router.navigate("/loanForm/", {
            reloadCurrent: false,
            ignoreCache: true,
            context: {
              info:self.requisition_fields
            }
          });
        }
      },
      on: {
        pageInit: function (e, page) {
          var self = this;
        
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var post_list_loan_maintenance_paramrs = this.getParams("get_user_borrowing_requisition", {
              "dingding_code": dingding_code
            });
            var get_borrowing_requisition_fields = this.getParams("get_borrowing_requisition_fields", {
              "dingding_code": dingding_code
            });

          } else if (page.app.data.session.source == 'feishu') {
            var post_list_loan_maintenance_paramrs = this.getParams("get_user_borrowing_requisition", {
              "feishu_userid": feishu_userid
            });
            var get_borrowing_requisition_fields = this.getParams("get_borrowing_requisition_fields", {
              "feishu_userid": feishu_userid
            });

          } else {
            var post_list_loan_maintenance_paramrs = this.getParams("get_user_borrowing_requisition", {
              "wechat_name": vuserid,
            });
            var get_borrowing_requisition_fields = this.getParams("get_borrowing_requisition_fields", {
              "wechat_name": vuserid,
            });
          }

          page.app.request.postJSON(
            page.app.data.url,
            post_list_loan_maintenance_paramrs,
            function (result_list) {
              console.log(result_list);
              self.$setState({
                loanList:result_list.result.all_borrowing_requisition,
                loanListCount:result_list.result.all_borrowing_requisition.length,
                loanList1:result_list.result.processing_borrowing_requisition,
                loanListCount1:result_list.result.processing_borrowing_requisition.length
              });

              page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
                $(e.currentTarget)
                  .parent()
                  .parent()
                  .prev()
                  .find(".searchbar input")
                  .blur();
              });
                //未处理 搜索框实例
              var sbtodo = page.app.searchbar.create({
                el: '[data-name="loan-index"] .searchbar-to-do',
                searchContainer: '[data-name="loan-index"] .list-to-do',
                searchIn: '[data-name="loan-index"] .item-text-to-do',
                notFoudEl: '[data-name="loan-index"] .searchbar-not-found-to-do',
                });
                var sbdone = page.app.searchbar.create({
                  el: ".searchbar-done",
                  searchContainer: ".list-done",
                  searchIn: ".item-text-done",
                  notFoudEl: ".searchbar-not-found-done",
                });
                $('[data-name="loan-index"] .searchbar-done').hide();
                $('[data-name="loan-index"] .search-icon-2').hide();

                $('[data-name="loan-index"] .to-do-btn').on("click", function () {
                  sbdone.disable();
                  $('[data-name="loan-index"] .searchbar-done,[data-name="loan-index"] .search-icon-2').hide();
                  $('[data-name="loan-index"] .searchbar-to-do,[data-name="loan-index"] .search-icon-1').show();
                });
                $('[data-name="loan-index"] .done-btn').on("click", function () {
                  sbtodo.disable();
                  $('[data-name="loan-index"] .searchbar-done,[data-name="loan-index"] .search-icon-2').show();
                  $('[data-name="loan-index"] .searchbar-to-do,[data-name="loan-index"] .search-icon-1').hide();
              });
            }
          );

          page.app.request.postJSON(
            page.app.data.url,
            get_borrowing_requisition_fields,
            function (result_list) {
              console.log(result_list);
              self.$setState({
                requisition_fields:result_list.result
              });
            }
          );
          

        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  loanForm,
  loanFormView,
//   loanCreate,
//   loanList
];