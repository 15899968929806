import main from './main';
import $ from 'dom7';

const indexRoute = {
  path: '/',
  url: './index.html',
  on: {
    pageBeforeRemove: function(e, page) {
      // TODO
      // console.log('pageBeforeRemove...');
    },
    pageInit: function(e, page) {
      // TODO
      // console.log('pageInit...');
      // $('#funclinks li a').on('click', function(e) {
      //   const viewId = $(e.target).attr('data-view');
      //   const router = $(e.target).attr('href');
      //   console.log(viewId, router);
      //   const mainview = page.app.views.get(viewId);
      //   mainview.router.navigate(router, {});
      // });
    }
  }
};

export default [
  indexRoute,
  main
];
