import $$ from "dom7";
import teamBuildingAppForm from "../pages/teamBuildingAppForm.html";
import Template7 from "template7";

export default {
  name: "teambuildingappform",
  path: "/teambuildingappform/",
  component: {
    template: teamBuildingAppForm,
    data: () => {
      return {};
    },
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      submit: function (e) {
        let self = this
        let page = $$('.page[data-name="teambuildingapp-form"]')[0].f7Page
        let teambuildingappFormData = app.form.convertToData("#teambuildingapp-form");

        if (!teambuildingappFormData.team_building_charge_description) {
          return self.$app.dialog.alert('团建经费说明必须有值!', "KTHRP");
        }
        if (!teambuildingappFormData.start_date) {
          return self.$app.dialog.alert('开始时间必须有值!', "KTHRP");
        }
        if (!teambuildingappFormData.end_date) {
          return self.$app.dialog.alert('结束时间必须有值!', "KTHRP");
        }


        let post_file_url = this.$app.data.base_url + '/web/map_upload_attachment'
        let formData = new FormData()
        let files = this.$$('#file-attach')[0].files

        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        if (self.$app.data.session.source == 'dingding') {
          formData.append('dingding_code', self.$app.data.dingding_code)
        } else if (self.$app.data.session.source == 'feishu') {
          formData.append('feishu_userid', self.$app.data.feishu_userid)
        } else {
          formData.append('wechat_name', self.$app.data.session.userid)
        }
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          formData.append("file", file)
        }

        let obj = self.getUserObj()

        obj.team_building_charge_description = teambuildingappFormData.team_building_charge_description
        obj.start_date = teambuildingappFormData.start_date
        obj.end_date = teambuildingappFormData.end_date
        if (new Date(obj.start_date.replace(/-/g, "/")) > new Date(obj.end_date.replace(/-/g, "/"))) {
          return self.$app.dialog.alert('结束时间必须大于开始时间！', "KTHRP");
        }
        console.log(obj)
        self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.team.building.application.interface', 'create_team_building_application', obj), function (res) {
          console.log(res);
          if (res.result.state === 'ok') {
            if (files.length === 0) {
              var toast = app.toast.create({
                text: '恭喜您,操作成功',
                position: 'top',
                closeTimeout: 2000
              })
              toast.open()
              page.router.navigate('/teambuildingapplist/', {
                // reloadCurrent: true,
                // ignoreCache: true
              })
            } else {
              formData.append('model_name', res.result.data.model_name)
              formData.append('record_id', res.result.data.record_id)
              self.$app.request.post(post_file_url, formData, function (result) {
                console.log(result)
                if (JSON.parse(result).state === 'ok') {
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  page.router.navigate('/teambuildingapplist/', {
                    // reloadCurrent: true,
                    // ignoreCache: true
                  })
                } else {
                  var toast = app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  page.router.navigate('/teambuildingapplist/', {
                    // reloadCurrent: true,
                    // ignoreCache: true
                  })
                }
              });
            }
          } else if (res.result.state === 'warn') {
            self.$app.dialog.alert(res.result.err, "KTHRP");
          } else {
            self.$app.dialog.alert(res.result.err, "KTHRP");
          }
        });
      },
      getDate: function (date) {
        return date.substring(0, date.indexOf(' '))
      },
      getTime: function (date) {
        return date.substring(date.indexOf(' ') + 1, date.length)
      },

    },
    on: {
      pageInit: function (e, page) {
        let self = this;
        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });
        let startDate = page.app.calendar.create({
          inputEl: "#start_date",
          dateFormat: "yyyy-mm",
          openIn: 'customModal',
          header: true,
          footer: true,
          on: {}
        });

        let endDate = page.app.calendar.create({
          inputEl: "#end_date",
          dateFormat: "yyyy-mm",
          openIn: 'customModal',
          header: true,
          footer: true,
          on: {}
        });
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};