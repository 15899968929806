import $$ from "dom7";
import goOutAppForm from "../pages/goOutAppForm.html";
import Template7 from "template7";


export default {
  name: "goOutappform",
  path: "/goOutappform/",
  component: {
    template: goOutAppForm,
    data: () => {
      return {};
    },
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.hr.outwork.application.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      date_change(e){
        let val=e||window.event;
        if(!val)return
        if(val.currentTarget&&val.currentTarget.id.indexOf('time')>-1){
          this.computeTime()
        }
      },
      computeTime(){
        const self = this
        const {
          $$
        } = this
        let end = $("input[name='expected_return_time']").val()
        let start = $("input[name='outwork_time']").val()
        if(start){
          if(end && new Date(start) - new Date(end) >= 0){
            app.dialog.alert("预计返回时间必须大于外出时间！", "系统提示");
            $("input[name='expected_return_time']").val('')
          }else{
            var page = $$('.page[data-name="goOutapp-form"]')[0].f7Page
            var vuserid = page.app.data.session.userid;
            var dingding_code = page.app.data.dingding_code;
            var feishu_userid = page.app.data.feishu_userid;
            if (page.app.data.session.source == 'dingding') {
              var getFields = self.getParams("compute_outwork_time", {
                "dingding_code": dingding_code,
                "outwork_time":start,
                "expected_return_time":end
              });
            } else if (page.app.data.session.source == 'feishu') {
              var getFields = self.getParams("compute_outwork_time", {
                "feishu_userid": feishu_userid,
                "outwork_time":start,
                "expected_return_time":end
              });
            } else {
              var getFields = self.getParams("compute_outwork_time", {
                "wechat_name": vuserid,
                "outwork_time":start,
                "expected_return_time":end
              });
            }

            page.app.request.postJSON(
              page.app.data.url,
              getFields,
              function (result) {
              if(result.result){
                $('.duration').html(result.result.outwork_hours)
              }
            })
          }
           
        }else{
          app.dialog.alert("请选择外出时间！", "系统提示");
        }
      },
      submit: function (e) {
        let self = this
        let page = $$('.page[data-name="goOutapp-form"]')[0].f7Page
        let goOutappFormData = app.form.convertToData("#goOutapp-form");

        if (!goOutappFormData.phone) {
          return self.$app.dialog.alert('联系电话必须有值!', "KTHRP");
        }
        if (!goOutappFormData.outwork_time) {
          return self.$app.dialog.alert('外出时间必须有值!', "KTHRP");
        }
        if (!goOutappFormData.expected_return_time) {
          return self.$app.dialog.alert('预计返回时间必须有值!', "KTHRP");
        }
        if (!goOutappFormData.outwork_reason) {
          return self.$app.dialog.alert('外出原因必须有值!', "KTHRP");
        }


        let post_file_url = this.$app.data.base_url + '/web/map_upload_attachment'
        let formData = new FormData()
        let files = this.$$('#file-attach')[0].files

        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        if (self.$app.data.session.source == 'dingding') {
          formData.append('dingding_code', self.$app.data.dingding_code)
        } else if (self.$app.data.session.source == 'feishu') {
          formData.append('feishu_userid', self.$app.data.feishu_userid)
        } else {
          formData.append('wechat_name', self.$app.data.session.userid)
        }
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          formData.append("file", file)
        }

        let obj = self.getUserObj()
        obj.phone = goOutappFormData.phone
        obj.outwork_time = goOutappFormData.outwork_time
        obj.expected_return_time = goOutappFormData.expected_return_time
        obj.outwork_reason = goOutappFormData.outwork_reason

        // obj.team_building_charge_description = goOutappFormData.team_building_charge_description
        // obj.start_date = goOutappFormData.start_date + " " + goOutappFormData.start_time
        // obj.end_date = goOutappFormData.end_date + " " + goOutappFormData.end_time
        // if (obj.start_date >= obj.end_date) {
        //   return self.$app.dialog.alert('结束时间必须大于开始时间！', "KTHRP");
        // }
        console.log(obj)

        // var vuserid = page.app.data.session.userid;
        // var dingding_code = page.app.data.dingding_code;
        // if (page.app.data.session.source == 'dingding') {
        //   var getCreateParams = self.getParams("compute_outwork_time", {
        //     "dingding_code": dingding_code,
        //     "phone": phone,
        //     "outwork_time":start,
        //     "expected_return_time":end,
        //     "outwork_reason":outwork_reason
        //   });
        // } else {
        //   var getCreateParams = self.getParams("compute_outwork_time", {
        //     "wechat_name": vuserid,
        //     "outwork_time":start,
        //     "expected_return_time":end
        //   });
        // }
        
        app.preloader.show()
        self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.outwork.application.interface', 'create_outwork', obj), function (res) {
          console.log(res);
          if (res.result.state === 'success') {
            if (files.length === 0) {
              app.preloader.hide()
              var toast = app.toast.create({
                text: '恭喜您,操作成功',
                position: 'top',
                closeTimeout: 2000
              })
              toast.open()
              app.router.back('/goOutapplist/',{
                force: true
              })    
              // page.router.navigate('/goOutapplist/', {
              //   // reloadCurrent: true,
              //   // ignoreCache: true
              // })
            } else {
              formData.append('model_name', res.result.model)
              formData.append('record_id', res.result.outwork_id)
              self.$app.request.post(post_file_url, formData, function (result) {
                console.log(result)
                if (JSON.parse(result).state === 'ok') {
                  app.preloader.hide()
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  app.router.back('/goOutapplist/',{
                    force: true
                  }) 
                } else {
                  app.preloader.hide()
                  var toast = app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  app.router.back('/goOutapplist/',{
                    force: true
                  }) 
                }
              });
            }
          } else if (res.result.state === 'warn') {
            app.preloader.hide()
            self.$app.dialog.alert(res.result.err, "KTHRP");
          } else {
            app.preloader.hide()
            self.$app.dialog.alert(res.result.err, "KTHRP");
          }
        });
      },
      getDate: function (date) {
        return date.substring(0, date.indexOf(' '))
      },
      getTime: function (date) {
        return date.substring(date.indexOf(' ') + 1, date.length)
      },

    },
    on: {
      pageInit: function (e, page) {
        let self = this;
        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });
        //注册时间选择控件
        let outwork_time = new lCalendar();
        outwork_time.init({
          trigger: "#outwork_time",
          type: "datetime",
        });
        let expected_return_time = new lCalendar();
        expected_return_time.init({
          trigger: "#expected_return_time",
          type: "datetime",
        });
        $("input[type='text']").bind('input propertychange',this.date_change)
        this.computeTime()

      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};