import $$ from "dom7";
import createForm from "../pages/create_form.html";
import Template7 from "template7";


export default {
  name: "materialCreateForm",
  path: "/materialCreateForm/",
  component: {
    template: createForm,
    methods: {
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            var args = []
              args = [
                  dbname,
                  userid,
                  password,
                  "kthrp.asset.maintenance.work.order.interface",
                  method,
                  obj
                ]
            return {
              jsonrpc: "2.0",
              method: "call",
              params: {
                service: "object",
                method: "execute",
                args: args
              }
            };
          },
        add(val){
            const {
              $$
            } = this;       
            var page = $$('.page[data-name="assets-create-form"]')[0].f7Page;
            page.router.navigate("/infoPage/", {
              reloadCurrent: false,
              ignoreCache: true,
              context:{
                info: {
                    multiple:val==22?true:false,
                    productList: this.productList
                }
              }
          });
          },
          edit(index){
            app.router.navigate("/infoPage/", {
                reloadCurrent: false,
                ignoreCache: true,
                context:{
                    info: {
                        edit:true,
                        index: index,
                        productList: this.productList
                    }
                }
            });
          },
          submit(){
            let formData = app.form.convertToData('#createForm');
            if(formData.order_type == ''){
                app.dialog.alert('单据类型必须有值！','系统提示')
                return
            }else if(formData.apply_dept == ''){
                app.dialog.alert('申请部门必须有值！','系统提示')
                return
            }else if(!this.infoList || this.infoList.length<1){
                app.dialog.alert('请填写申领明细信息！','系统提示')
                return
            }
            formData.order_name = "申领总仓物资"
            formData.applyer = "刘青霞"
            formData.order_code = '20201021604001'
            formData.apply_date = this.applyDate
            formData.state = '已创建'
            formData.company = '康博嘉北京医院'
            formData.infoList = this.infoList
            formData.description = formData.wo_overview
            console.log(formData)
            app.data.list = []
            app.data.list.push(formData)
            var toast = app.toast.create({
                text: "创建成功！",
                position: "center",
                closeTimeout: 2000
            });
            toast.open();
            app.router.back('/materialClaim/',{
                force: true
            })       
          }
    },
    on: {
      pageInit: function (e, page) {
        page.app.data.asset_data.supplies = []
          let apply_dept_list = [{
              id: '儿科',
              name: '儿科'
          },{
            id: '妇产科',
            name: '妇产科'
        },{
            id: '神经外科',
            name: '神经外科'
        },{
            id: '牙科',
            name: '牙科'
        },{
            id: '精神科',
            name: '精神科'
        }]
        this.$setState({
            apply_dept_list: apply_dept_list,
            applyDate: new Date().toLocaleDateString()
        })
        var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
              //获取产品列表的参数
              var productParams = this.getParams("get_product_info", {
                "repair_requisition_id": 151,
                "dingding_code": dingding_code
              });
            } else if (page.app.data.session.source == 'feishu') {
              //获取产品列表的参数
              var productParams = this.getParams("get_product_info", {
                "repair_requisition_id": 151,
                "feishu_userid": feishu_userid
              });
            } else {
              var productParams = this.getParams("get_product_info", {
                "repair_requisition_id": 151,
                "wechat_name": vuserid
              });
            }
            //获取产品列表的接口
            page.app.request.postJSON(page.app.data.url, productParams, (res) => {
              if(res.result){
                console.log(res)
                this.productList = res.result.fields_value.product.values.splice(0,100)
              }else{
                  page.app.dialog.alert('获取信息错误!','系统提示')
              }  
            })
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        let arr = page.app.data.asset_data.supplies
        if(arr && arr.length>0){
          this.$setState({
            infoList: arr.map((ele,index)=>{
                ele.index = index+1
                return ele
            })    
          })
        }
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};