import $$ from "dom7";
import infoPage from "../pages/infoPage.html";
import Template7 from "template7";
import * as dd from 'dingtalk-jsapi'
import app from "../../app";


export default {
  name: "infoPage",
  path: "/infoPage/",
  component: {
    template: infoPage,
    methods: {
        del(){
            if(this.info.index)app.data.asset_data.supplies.splice(this.info.index-1,1)
            app.router.back()
        },
        save(){
            const {
                $$
              } = this;
              var page = $$('.page[data-name="info-form"]')[0].f7Page;  
            let formData = app.form.convertToData('#infoForm');
            if(formData.product == ''){
                app.dialog.alert('产品必须有值！','系统提示')
                return
            }else if(formData.unit == ''){
                app.dialog.alert('单位必须有值！','系统提示')
                return
            }else if(formData.consumption == ''){
                app.dialog.alert('申请数量必须有值！','系统提示')
                return
            }else if(formData.fromLocation == ''){
                app.dialog.alert('来源库位必须有值！','系统提示')
                return
            }else if(formData.toLocation == ''){
                app.dialog.alert('目的库位必须有值！','系统提示')
                return
            }
            let obj = {}
            if(this.info.multiple){
                this.product_name.forEach((ele,index)=>{
                    obj = {}
                    obj.product_name = ele
                    obj.number = formData.consumption
                    obj.product_id = formData.product[index+1]
                    obj.guige = $$('.specification').text()
                    obj.shengchan = $$('.specification').text()
                    obj.danwei = formData.unit
                    obj.from = formData.fromLocation
                    obj.to = formData.toLocation
                    page.app.data.asset_data.supplies.push(obj)
                })
            }else{
                console.log(this.info)
                obj.product_name = this.product_name || this.item.product_name
                obj.number = formData.consumption
                obj.product_id = formData.product
                obj.guige = $$('.specification').text()
                obj.shengchan = $$('.specification').text()
                obj.danwei = formData.unit
                obj.from = formData.fromLocation
                obj.to = formData.toLocation
                if(this.info.edit){
                    page.app.data.asset_data.supplies.splice(this.info.index-1,1,obj)
                }else{
                    page.app.data.asset_data.supplies.push(obj)
                }               
            }
            console.log(formData)
            app.router.back()
        }
    },
    on: {
      pageInit: function (e, page) {
        console.log(this.info)
        if(this.info.edit){
            let item = page.app.data.asset_data.supplies.find(ele => ele.index == this.info.index)
            this.item = item
            console.log(item)
            page.app.form.fillFromData("#infoForm", {
                product: item.product_id,
                unit: item.danwei,
                consumption: item.number,
                fromLocation: item.from,
                toLocation: item.to
              });
               $$('.specification').text(item.guige)
               $$('.specification').text(item.shengchan)
        }else{
            page.app.form.fillFromData("#infoForm", {
                product: "",
                unit: "",
                consumption: ""
              });   
        }     
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        let product = page.app.smartSelect.get('.smart-select.product');
        product.on('close',()=>{
            let formData = app.form.convertToData('#infoForm');
            console.log(formData.product)
            if(this.info.multiple){
                var obj = this.info.productList.find(ele=>ele.product_id == formData.product[1])
                this.product_name = product.$valueEl.text().split(',').slice(1)
                console.log(this.product_name,product.$valueEl.text().split(','))
            }else{
                var obj = this.info.productList.find(ele=>ele.product_id == formData.product)
                this.product_name = product.$valueEl.text()
            }
            console.log(obj)
            if(obj){
                $$('.specification').text(obj.specification)
                $$('.manufacture').text(obj.manufacture)
            }
        })
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};