import $$ from "dom7";
import expenseAppViewForm from "../pages/expenseAppViewForm.html";

export default {
  name: "expenseappviewform",
  path: "/expenseappviewform/",
  component: {
    template: expenseAppViewForm,
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      toLineDetail: function (e) {
        let self = this;
        var id = parseInt(e.currentTarget.dataset.lineId)
        let obj = this.getUserObj()
        obj.expense_requisition_line_id = parseInt(id)
        this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.expense.expense.requisition.interface', 'get_user_expense_requisition_line_details', obj), function (res) {
          console.log(res)
          self.$app.router.navigate('/expenseappformlinedetail/', {
            reloadCurrent: false,
            ignoreCache: true,
            context: {
              info: res.result.data
            }
          })
        })
      },
    },
    on: {
      pageInit: function (e, page) {},
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};