import $$ from "dom7";
import workOrderViewForm from "../pages/workOrderViewForm.html";
import Template7 from "template7";

// import 'weui';


export default {
  name: "workOrderViewForm",
  path: "/workOrderViewForm/",
  component: {
    template: workOrderViewForm,
    methods: {
        //处理参数的函数
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            var args = []
              args = [
                  dbname,
                  userid,
                  password,
                  "kthrp.asset.maintenance.work.order.interface",
                  method,
                  obj
                ]
            return {
              jsonrpc: "2.0",
              method: "call",
              params: {
                service: "object",
                method: "execute",
                args: args
              }
            };
          },
        //无需处理的操作函数
        nohandle: function (e) {
        const {
          $$
        } = this;       
        var page = $$('.page[data-name="work-order-view-form"]')[0].f7Page;
        var vuserid = page.app.data.session.userid;
        var dingding_code = page.app.data.dingding_code;
        var feishu_userid = page.app.data.feishu_userid;
        var repairId = page.app.data.asset_data.form_data.id
        if (page.app.data.session.source == 'dingding') {
            var nohandleParams = this.getParams("action_no_need", {
              "repair_requisition_id": repairId,
              "dingding_code": dingding_code
            });
          } else if (page.app.data.session.source == 'feishu') {
            var nohandleParams = this.getParams("action_no_need", {
              "repair_requisition_id": repairId,
              "feishu_userid": feishu_userid
            });
          } else {
            var nohandleParams = this.getParams("action_no_need", {
              "repair_requisition_id": repairId,
              "wechat_name": vuserid
            });
          }
        page.app.dialog.confirm("该操作无法撤销，点击后故障报修将不会再被处理，您确认吗？",'温馨提示',function(){
            page.app.request.postJSON(page.app.data.url, nohandleParams ,function (result) {
                if(result.result.state == 'success'){
                    page.router.back('/workOrder/',{
                        ignoreCache:true
                    })
                }else{
                    page.app.dialog.alert('系统错误！','系统提示')
                }          
              });
        })
      },
      // 派单的操作函数,点击确定后跳转到创建工作单界面
      confirm(){
        const {
            $$
          } = this;       
          var page = $$('.page[data-name="work-order-view-form"]')[0].f7Page;
          let engineer = $('.tigger .item-after').text()
          if(engineer === '请选择' || engineer == ''){
            page.app.dialog.alert('请先选择工程师！',"温馨提示")
          }else{
            page.app.data.asset_data.form_data.engineer = this.engineerList.find(ele=>engineer.indexOf(ele.employee_name)>-1)
            app.popup.get('.popup-about').close(false)
            page.router.navigate("/workOrderForm/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                    info: page.app.data.asset_data.form_data
                }
            });
          }
      },
      //获取工程师列表的函数
      getEngineerList(page,current){
        let that = this
        let promise = new Promise((resolve, reject)=>{
            var vuserid = page.app.data.session.userid;
            var dingding_code = page.app.data.dingding_code;
            var feishu_userid = page.app.data.feishu_userid;
            var repairId = page.app.data.asset_data.form_data.id
            if (page.app.data.session.source == 'dingding') {
                var paramrs = this.getParams("get_engineer_info", {
                    "dingding_code": dingding_code,
                    "only_current_user": current,
                    "repair_requisition_id": repairId
                });
            } else  if (page.app.data.session.source == 'feishu') {
                var paramrs = this.getParams("get_engineer_info", {
                    "feishu_userid": feishu_userid,
                    "only_current_user": current,
                    "repair_requisition_id": repairId
                });
            } else {
                var paramrs = this.getParams("get_engineer_info", {
                    "wechat_name": vuserid,
                    "only_current_user": current,
                    "repair_requisition_id": repairId
                });
            }
            page.app.request.postJSON(page.app.data.url, paramrs ,function (result) {
                if(result.result){
                    if(current)page.app.data.asset_data.form_data.engineer = result.result.fields_value.engineer.values[0]
                    that.$setState({
                        engineerList: result.result.fields_value.engineer.values
                    })
                    resolve(result)
                }else{
                    page.app.dialog.alert('系统错误！','系统提示')
                }          
            });
        })
        return promise
      },
      //抢单的操作函数
      grabOrder(){
        const {
            $$
          } = this;       
          var page = $$('.page[data-name="work-order-view-form"]')[0].f7Page;
        this.getEngineerList(page,true).then(res=>{
            page.router.navigate("/workOrderForm/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                    info: page.app.data.asset_data.form_data
                }
            });
        })
      }
    },
    on: {
      pageInit: function (e, page) {
        var myPhotoBrowserPopup = page.app.photoBrowser.create({
            photos : app.data.asset_data.form_data.images,
            type: 'standalone'
        });
        this.$setState({
            showButton: app.data.asset_data.form_data.showButton,
            isEngineer: app.data.asset_data.form_data.asset_maintenance_engineer,
            isAssign: app.data.asset_data.form_data.asset_maintenance_assign
        })
        console.log(app.data.asset_data.form_data)
        $('.images').on('click', function () {
            myPhotoBrowserPopup.open();
        });
        this.getEngineerList(page,false)
      },
      pageMounted: function (e, page) {
        console.log("pageMounted")
        page.app.data.asset_data.form_data.images.forEach((ele,index) => {
            ele.caption = ele.name
            $('img')[index].src=ele.url
        })
      },
      pageReinit: function (e, page) {
        this.$setState({
            showButton: app.data.asset_data.form_data.showButton
        })
        // let route = page.router.history[page.router.history.length-1]
        // if(route === '/workOrderViewForm/')page.router.history.push('/workOrderForm/')
        console.log(page.router.history)
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        let smartSelect = page.app.smartSelect.get('.smart-select');
        $('.tigger').on('click', function (page) {
            smartSelect.open()
        });
        smartSelect.on('close', function () { 
            $('.tigger .item-after').text(smartSelect.$valueEl.text())
         })
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};