import $ from "dom7";
import createForm from "./create_form";
import viewForm from "./view_form";
import infoPage from "./infoPage";
import html from "../pages/index.html";
import style from "../styles/index.less";

export default [{
    name: "/materialClaim/",
    path: "/materialClaim/",
    // url: "./expense/pages/index.html"
    component: {
      template: html,
      style: style,
      methods: {
        toViewForm: function (e) {
            const {
                $$
              } = this;
              var page = $$('.page[data-name="materialClaim-index"]')[0].f7Page;  
                let id = "004";
                let obj = this.allList.find(ele=>ele.id===id)
                let state = e.currentTarget.dataset.state
                obj.state = state
                page.app.data.material_data = obj
                page.router.navigate("/materialViewForm/", {
                  reloadCurrent: false,
                  ignoreCache: true,
                  context: {
                    info: obj
                  }
                });
        }
      },
      on: {
        pageInit: function (e, page) {
          var self = this;
          page.app.data.material_data = {}
          page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
            $(e.currentTarget)
              .parent()
              .parent()
              .prev()
              .find(".searchbar input")
              .blur();
          });
          let allList = [{
            id: '001',
            order_name: '申领总仓物资',
            order_code: 202010201604001,
            order_type: "申领总仓物资",
            tel: '13564736282',
            level:'一般',
            company: '康博嘉北京医院',
            state: '已创建',
            applyer: '刘大伟',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '儿科',
            description: '急需用品，请尽快安排到位'
          },{
            id: '002',
            order_name: '申领总仓物资',
            order_code: 2020101501604002,
            order_type: "申领总仓物资",
            tel: '15467856432',
            level:'一般',
            company: '康博嘉武汉医院',
            state: '已完成',
            applyer: '曾小贤',
            apply_date: '2020年10月15日 16时23分60秒',
            apply_dept: '妇产科',
            description: '急需用品，请尽快安排到位'
          },{
            id: '003',
            order_name: '申领总仓物资',
            order_code: 202010220923003,
            order_type: "申领总仓物资",
            tel: '13564736282',
            level:'一般',
            company: '康博嘉北京医院',
            state: '审批中',
            applyer: '刘大伟',
            apply_date: '2020年10月22日 09时23分20秒',
            apply_dept: '牙科',
            description: '这里是牙科需要用的用品，急需用品，请尽快安排到位'
          },{
            id: '004',
            order_name: '申领总仓物资',
            order_code: 202010201604004,
            order_type: "申领总仓物资",
            tel: '13564736282',
            level:'一般',
            company: '康博嘉北京医院',
            state: '审批中',
            applyer: '刘大伟',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '精神科',
            description: '急需用品，请尽快安排到位'
          },{
            id: '005',
            order_name: '申领总仓物资',
            order_code: 202010201604005,
            order_type: "申领总仓物资",
            tel: '13564736282',
            level:'一般',
            company: '康博嘉北京医院',
            state: '已创建',
            applyer: '李冰',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '内科',
            description: '急需用品，请尽快安排到位'
          },{
            id: '006',
            order_name: '申领总仓物资',
            order_code: 202010201604001,
            order_type: "申领总仓物资",
            tel: '13564736282',
            level:'一般',
            company: '康博嘉北京医院',
            state: '已完成',
            applyer: '王倩',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '神经外科',
            description: '急需用品，请尽快安排到位'
          }]
          let processingList = [{
            id: '003',
            order_name: '申领总仓物资',
            order_code: 202010220923003,
            order_type: "申领总仓物资",
            tel: '13564736282',
            level:'一般',
            company: '康博嘉北京医院',
            state: '审批中',
            applyer: '刘大伟',
            apply_date: '2020年10月22日 09时23分20秒',
            apply_dept: '牙科',
            description: '这里是牙科需要用的用品，急需用品，请尽快安排到位'
          },{
            id: '004',
            order_name: '申领总仓物资',
            order_code: 202010201604004,
            order_type: "申领总仓物资",
            tel: '13564736282',
            level:'一般',
            company: '康博嘉北京医院',
            state: '审批中',
            applyer: '刘大伟',
            apply_date: '2020年10月20日 16时04分20秒',
            apply_dept: '精神科',
            description: '急需用品，请尽快安排到位'
          }]
          this.$setState({
            allList: allList,
            allListCount: allList.length,
            processingList: processingList,
            processingListCount: processingList.length
          })
          //未处理 搜索框实例
          var sbtodo = page.app.searchbar.create({
            el: '[data-name="materialClaim-index"] .searchbar-to-do',
            searchContainer: '[data-name="materialClaim-index"] .list-to-do',
            searchIn: '[data-name="materialClaim-index"] .item-text-to-do',
            notFoudEl: '[data-name="materialClaim-index"] .searchbar-not-found-to-do',
          });
          var sbdone = page.app.searchbar.create({
            el: ".searchbar-done",
            searchContainer: ".list-done",
            searchIn: ".item-text-done",
            notFoudEl: ".searchbar-not-found-done",
          });
          $('[data-name="materialClaim-index"] .searchbar-done').hide();
          $('[data-name="materialClaim-index"] .search-icon-2').hide();

          $('[data-name="materialClaim-index"] .to-do-btn').on("click", function () {
            sbdone.disable();
            $('[data-name="materialClaim-index"] .searchbar-done,[data-name="materialClaim-index"] .search-icon-2').hide();
            $('[data-name="materialClaim-index"] .searchbar-to-do,[data-name="materialClaim-index"] .search-icon-1').show();
          });
          $('[data-name="materialClaim-index"] .done-btn').on("click", function () {
            sbtodo.disable();
            $('[data-name="materialClaim-index"] .searchbar-done,[data-name="materialClaim-index"] .search-icon-2').show();
            $('[data-name="materialClaim-index"] .searchbar-to-do,[data-name="materialClaim-index"] .search-icon-1').hide();
          });
        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
            if(page.app.data.list && page.app.data.list.length>0){
                this.allList.unshift(page.app.data.list[0])
                this.$setState({
                    allList: this.allList,
                    allListCount: this.allList.length
                  })
                  page.app.data.list = []
              }              
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
          page.app.toolbar.hide(".expense-toolbar", false);
          $(".expense-toolbar").css("opacity", 0);
          $('.expense-checkbox input[type="checkbox"]').prop("checked", false);
          $(".expense-list-checkbox-icon").css("margin-left", "-80px");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    },
  },
  createForm,
  viewForm,
  infoPage
];