/*
 * @Author: liubaozhen
 * @Date: 2023-07-04 20:50:43
 * @LastEditTime: 2024-04-18 13:42:32
 * @LastEditors: liubaozhen
 * @Description: 
 * @FilePath: \odoo\kthrp_map\src\expenseApp\routes\expenseAppForm.js
 */
import $$ from "dom7";
import expenseAppForm from "../pages/expenseAppForm.html";
import expenseAppFormTableLineTemplate from "../pages/expenseAppFormTableLine.html";

import Template7 from "template7";


export default {
  name: "expenseappform",
  path: "/expenseappform/",
  component: {
    template: expenseAppForm,
    data: () => {
      return {
        isFabBtn: false,
        countLine: 1,
      };
    },
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      submit: function (e) {
        let self = this
        let page = $$('.page[data-name="expenseapp-form"]')[0].f7Page
        let expenseappFormData = app.form.convertToData('#expenseapp-form')

        if (!expenseappFormData.document_type) {
          return self.$app.dialog.alert('单据类型必须有值!', "KTHRP");
        }
        if (!expenseappFormData.expense_dept) {
          return self.$app.dialog.alert('费用部门必须有值!', "KTHRP");
        }
        if (!expenseappFormData.companion) {
          return self.$app.dialog.alert('同行人必须有值!', "KTHRP");
        }
        if (!expenseappFormData.note) {
          return self.$app.dialog.alert('事由必须有值!', "KTHRP");
        }
        if (app.data.expenseapp_data.length === 0) {
          return self.$app.dialog.alert('费用明细行至少填写一行!', "KTHRP");
        }

        let post_file_url = this.$app.data.base_url + '/web/map_upload_attachment'
        var formData = new FormData()
        let files = this.$$('#file-attach')[0].files

        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        if (self.$app.data.session.source == 'dingding') {
          formData.append('dingding_code', self.$app.data.dingding_code)
        } else if (self.$app.data.session.source == 'feishu') {
          formData.append('feishu_userid', self.$app.data.feishu_userid)
        } else {
          formData.append('wechat_name', self.$app.data.session.userid)
        }
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          formData.append("file", file)
        }
        let neWexpenseappFormData = {}
        neWexpenseappFormData.document_type_id = parseInt(expenseappFormData.document_type)
        neWexpenseappFormData.expense_dept_id = parseInt(expenseappFormData.expense_dept)
        neWexpenseappFormData.companion_id = parseInt(expenseappFormData.companion)
        neWexpenseappFormData.note = expenseappFormData.note
        neWexpenseappFormData.use_car  = expenseappFormData.use_car 
        neWexpenseappFormData.budget_source  = expenseappFormData.budget_source 
        neWexpenseappFormData.project_id  = expenseappFormData.project_id 



        for (let i = 0; i < app.data.expenseapp_data.length; i++) {
          app.data.expenseapp_data[i].line_number = i + 1
          app.data.expenseapp_data[i].expense_requisition_item_id = parseInt(app.data.expenseapp_data[i].expense_requisition_item_id)
          app.data.expenseapp_data[i].actual_amount = parseFloat(app.data.expenseapp_data[i].actual_amount)
        }
        neWexpenseappFormData.expense_requisition_line_ids = app.data.expenseapp_data
        let userObj = self.getUserObj()
        neWexpenseappFormData = {
          ...userObj,
          ...neWexpenseappFormData
        }
        console.log(neWexpenseappFormData)
        app.preloader.show();
        self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.expense.expense.requisition.interface', 'create_expense_requisition', neWexpenseappFormData), function (res) {
          console.log(res);
          if (res.result.state === 'ok') {
            if (files.length === 0) {
              var toast = app.toast.create({
                text: '恭喜您,操作成功',
                position: 'top',
                closeTimeout: 2000
              })
              app.preloader.hide()
              toast.open()
              page.router.back('/expenseapplist/', {
                force: true
              })
            } else {
              formData.append('model_name', res.result.data.model_name)
              formData.append('record_id', res.result.data.record_id)
              self.$app.request.post(post_file_url, formData, function (result) {
                console.log(result)
                if (JSON.parse(result).state === 'ok') {
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide()
                  toast.open()
                  page.router.back('/expenseapplist/', {
                    force: true
                  })
                } else {
                  var toast = app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  app.preloader.hide()
                  toast.open()
                  page.router.back('/expenseapplist/', {
                    force: true
                  })
                }
              });
            }
          } else if (res.result.state === 'warn') {
            app.preloader.hide()
            self.$app.dialog.alert(res.result.err, "KTHRP");
          } else {
            app.preloader.hide()
            self.$app.dialog.alert(res.result.err, "KTHRP");
          }
        });
      },
      addLine: function (e) {
        const {
          $$
        } = this
        let self = this
        let page = $$('.page[data-name="expenseapp-form"]')[0].f7Page
        let expenseappFormData = app.form.convertToData("#expenseapp-form")
        if (!expenseappFormData.document_type) {
          return self.$app.dialog.alert('请选择单据类型！', "KTHRP")
        }
        let has_class = $$(e.currentTarget).hasClass('add')
        let obj = this.getUserObj()
        obj.document_type_id = parseInt(expenseappFormData.document_type)
        this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.expense.expense.requisition.interface', 'get_expense_requisition_fields', obj), function (res) {
          console.log(res)
          if (res) {
            if (has_class) {
              app.data.expenseapp_index_flag = false
            }
            self.$app.router.navigate("/expenseappviewformnewline/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                info: res.result.data,
                expenseapp_index_flag: app.data.expenseapp_index_flag
              }
            })
          }
        })
        // $$('.page[data-name="leaveapplication-form"] .leaveapplication-form-list').append(html);
        // $$(".delete-line-btn").on("click", function (e) {
        //   console.log("删除行");
        //   console.log($$(e.currentTarget).parent().parent());
        //   $$(e.currentTarget).parent().parent().remove();
        // });

        // page.app.calendar.create({
        //   inputEl: '#line' + self.countLine + ' #calendar-leaveapplication-start-date',
        //   dateFormat: "yyyy-mm-dd ",
        //   openIn: 'customModal',
        //   header: true,
        //   footer: true,
        //   on: {
        //     closed: function (e) {

        //     }
        //   }
        // });

        // page.app.calendar.create({
        //   inputEl: '#line' + self.countLine + ' #calendar-leaveapplication-end-date',
        //   dateFormat: "yyyy-mm-dd ",
        //   openIn: 'customModal',
        //   header: true,
        //   footer: true,
        //   on: {
        //     closed: function (e) {

        //     }
        //   }
        // });


      },
      toLineDetail: function (e) {
        let self = this;
        let index = parseInt(e.currentTarget.dataset.index);
        self.$app.router.navigate("/expenseappviewformnewline/", {
          reloadCurrent: false,
          ignoreCache: true,
          context: {
            info: app.data.expenseapp_data[index]
          }
        });
      },
    },
    on: {
      pageInit: function (e, page) {
        let self = this;
        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });
        app.data.expenseapp_display = []
        app.data.expenseapp_data = []
        app.data.expenseapp_index = false
        page.app.form.fillFromData("#expenseapp-form", {
          document_type: "",
          expense_dept: "",
          companion: "",
          use_car:'',
          budget_source:''
        });
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
        let self = this;
        let expenseapp_display = app.data.expenseapp_display
        var template = Template7.compile(
          expenseAppFormTableLineTemplate
        )
        var html = template({
          expenseapp_display: expenseapp_display,
        })
        $$('table.detail tbody').remove()
        $$('table.detail').append($$(html))
        $$('table.detail tbody tr').click(function (e) {
          let index = parseInt(e.currentTarget.dataset.index)
          app.data.expenseapp_index = index
          app.data.expenseapp_index_flag = true
          self.addLine(e)
        });
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};