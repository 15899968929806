import $$ from "dom7";
import addWorkInfo from "../pages/addWorkInfo.html";
import Template7 from "template7";

export default {
  name: "addWorkInfo",
  path: "/addWorkInfo/",
  component: {
    template: addWorkInfo,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.asset.maintenance.work.order.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      date_change(e){
        let val=e||window.event;
        if(!val)return
        if(val.currentTarget&&val.currentTarget.id.indexOf('halt')>-1){
          let end = $("input[name='halt_end_time']").val().replace(/-/g, "/")
          let start = $("input[name='halt_start_time']").val().replace(/-/g, "/")
          if(start){
            if(end && new Date(start) - new Date(end) > 0){
              app.dialog.alert("停机结束时间必须大于停机开始时间！", "系统提示");
              $("input[name='halt_end_time']").val('')
            }else{
              if(end)this.halfTime = (new Date(end)-new Date(start))/60000    //停机时长
            }
          }else{
            app.dialog.alert("请选择停机开始时间！", "系统提示");
          }
        }else if(val.currentTarget&&val.currentTarget.id.indexOf('actual')>-1){
          let end = $("input[name='actual_complete_date']").val().replace(/-/g, "/")
          let start = $("input[name='actual_start_date']").val().replace(/-/g, "/")
          if(start){
            if(new Date(start) - new Date(end) > 0){
              app.dialog.alert("实际完成时间必须大于实际开始时间！", "系统提示");
              $("input[name='actual_complete_date']").val('')
              $('.time_actual').text('')
            }else{
              if(end)$('.time_actual').text((new Date(end)-new Date(start))/60000)              
            }
          }else{
            app.dialog.alert("请选择实际开始时间！", "系统提示");
          }
        }
      },
      // 删除行记录
      del(){
        if(!isNaN(this.lineindex)){
          if(this.ismaintain){
            app.data.maintainDetailView_data.work_order_complete_info_line_ids.splice(this.lineindex,1)
          }else if(this.isInspection){

          }else{
            app.data.workOrder_data.work_order_complete_info_line_ids.splice(this.lineindex,1)
          }
        }
        app.router.back()
    },
      haltChange(e){
        let formData = app.form.convertToData("#editForm");
        if(formData.halt && formData.halt == '是'){
          $('.showHalt').show()
        }else{
          $('.showHalt').hide()
        }
      },
      save() {
        let formData = app.form.convertToData("#editForm");
        console.log(formData);
        if (formData.actual_start_date == "") {
          app.dialog.alert("请选择实际开始时间！", "系统提示");
          return;
        } else if (formData.actual_complete_date == "") {
          app.dialog.alert("请选择实际完成时间！", "系统提示");
          return;
        } else if ($('.time_actual').text() == "") {
          app.dialog.alert("实际完成时间必须大于实际开始时间！", "系统提示");
          return;
        }else if (formData.execute_engineer_id == "") {
          app.dialog.alert("请选择执行工程师！", "系统提示");
          return;
        }
        if(formData.halt =='是'){
          if (formData.halt_start_time == ""){
            app.dialog.alert("请选择停机开始时间！", "系统提示");
            return;
          }else if (formData.halt_end_time == "") {
            app.dialog.alert("请选择停机结束时间！", "系统提示");
            return;
          }else if(new Date(formData.halt_start_time.replace(/-/g, "/")) - new Date(formData.halt_end_time.replace(/-/g, "/")) > 0){
            app.dialog.alert("停机结束时间必须大于停机开始时间！", "系统提示");
            $("input[name='halt_end_time']").text('')
            return
          }
        }else{
          delete formData.halt_end_time
          delete formData.halt_start_time
          delete this.halfTime
        }
        formData.time_actual = $('.time_actual').text()
        formData.execute_engineer_id = Number(formData.execute_engineer_id)
        if(this.halfTime && this.halfTime!='')formData.halt_time_actual = this.halfTime
        console.log('666666666666666666',app.data)
        if(this.isEdit){
          
          if(this.ismaintain){
            app.data.maintainDetailView_data.work_order_complete_info_line_ids.splice(this.lineindex,1,formData)
          }else if(this.isInspection){

          }else{
            app.data.workOrder_data.work_order_complete_info_line_ids.splice(this.lineindex,1,formData)
          }
        }else{
          if(this.ismaintain){
            app.data.maintainDetailView_data.work_order_complete_info_line_ids.push(formData)
          }else if(this.isInspection){

          }else{
            app.data.workOrder_data.work_order_complete_info_line_ids.push(formData)
          }
        }    
        $$(".back").trigger("click");
      },
    },
    on: {
      pageInit: function (e, page) { 
        console.log('pageInit',app.data)       
        let vuserid = page.app.data.session.userid;
        let dingding_code = page.app.data.dingding_code;
        let feishu_userid = page.app.data.feishu_userid;
        if(this.ismaintain){
          var work_order_id = app.data.maintainDetailView_data.work_order_id
        }else if(this.isInspection){

        }else{
          var work_order_id = app.data.workOrder_data.work_order_id
        }
        if (page.app.data.session.source == "dingding") {
          var engineerParams = this.getParams("get_execute_engineer", {
            work_order_id: work_order_id,
            dingding_code: dingding_code,
          });
        } else if (page.app.data.session.source == "feishu") {
          var engineerParams = this.getParams("get_execute_engineer", {
            work_order_id: work_order_id,
            feishu_userid: feishu_userid,
          });
        } else {
          var engineerParams = this.getParams("get_execute_engineer", {
            work_order_id: work_order_id,
            wechat_name: vuserid,
          });
        }
        //获取责任工程师列表
        page.app.request.postJSON(page.app.data.url, engineerParams, (res) => {
          if (res.result) {
            console.log(res);
            this.$setState({
              engineerList: res.result.data.execute_engineer
            });
          } else {
            page.app.dialog.alert("获取信息错误!", "系统提示");
          }
        });
        //注册时间选择控件
        let actual_start_date = new lCalendar();
        actual_start_date.init({
          'trigger': '#actual_start_date',
          'type': 'datetime'
        });
        let actual_complete_date = new lCalendar();
        actual_complete_date.init({
          'trigger': '#actual_complete_date',
          'type': 'datetime'
        });
        let halt_start_time = new lCalendar();
        halt_start_time.init({
          'trigger': '#halt_start_time',
          'type': 'datetime'
        });
        let halt_end_time = new lCalendar();
        halt_end_time.init({
          'trigger': '#halt_end_time',
          'type': 'datetime'
        });
        $("input[type='text']").bind('input propertychange',this.date_change)
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        if(this.isEdit){
          let item = this.isEdit
          if(item.halt == '是'){
            page.app.form.fillFromData('#editForm',{
              halt_start_time: item.halt_start_time,
              halt_end_time: item.halt_end_time,
              halt: item.halt
            })
            this.halfTime = item.halt_time_actual
          }else{
            this.haltChange()
          }
          page.app.form.fillFromData('#editForm',{
            actual_start_date: item.actual_start_date,
            actual_complete_date: item.actual_complete_date,
            note: item.note,
            content_or_steps: item.content_or_steps,
            execute_engineer_id: item.execute_engineer_id
          })
          $('.time_actual').text(item.time_actual)
        }else{
          let _execute_engineer_id,_halt;
          if(this.ismaintain){
            _execute_engineer_id = page.app.data.maintainDetailView_data.default_engineer_id
            if(page.app.data.maintainDetailView_data.work_order_halt){
              _halt = page.app.data.maintainDetailView_data.work_order_halt
            }else{
              _halt = '否'
            }
          }else if(this.isInspection){

          }else{
            _execute_engineer_id = page.app.data.workOrder_data.default_engineer_id
            if(app.data.workOrder_data.work_order_halt){
              _halt = app.data.workOrder_data.work_order_halt
            }else{
              _halt = '否'
            }
          }
          page.app.form.fillFromData('#editForm',{
            execute_engineer_id:_execute_engineer_id,
            halt:_halt
          })
          // page.app.form.fillFromData('#editForm',{
          //   execute_engineer_id: this.ismaintain ?  page.app.data.maintainDetailView_data.default_engineer_id : page.app.data.workOrder_data.default_engineer_id,
          //   halt: this.ismaintain ? page.app.data.maintainDetailView_data.work_order_halt?page.app.data.maintainDetailView_data.work_order_halt:'否' : app.data.workOrder_data.work_order_halt?app.data.workOrder_data.work_order_halt:'否'
          // })
        } 
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      },
    },
  },
};
