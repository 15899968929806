import $$ from "dom7";
import detail from "../pages/detail.html";
import Template7 from "template7";


export default {
  name: "detailForm",
  path: "/detailForm/",
  component: {
    template: detail,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = []
          args = [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.work.order.interface",
              method,
              obj
            ]
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args
          }
        };
      },
       //下载文件
       downloadFile(e){
        console.log(e.currentTarget.dataset.url)
        if(e.currentTarget.dataset.url){
          window.open(e.currentTarget.dataset.url)
        }
      },
      receive(){
        const vuserid = app.data.session.userid;
        const dingding_code = app.data.dingding_code;
        const feishu_userid = app.data.feishu_userid;
        const work_order_id = this.info.work_order_id
        if (app.data.session.source == 'dingding') {
            var commitParams = this.getParams("action_commit", {
              "dingding_code": dingding_code,
              "work_order_id": work_order_id
            });
        } else if (app.data.session.source == 'feishu') {
            var commitParams = this.getParams("action_commit", {
              "feishu_userid": feishu_userid,
              "work_order_id": work_order_id
            });
        } else {
          var commitParams = this.getParams("action_commit", {
            "wechat_name": vuserid,
            "work_order_id": work_order_id
          });
        }
        app.preloader.show()
        app.request.postJSON(app.data.url, commitParams ,(result) => {
          if(result.result&&result.result.state == "success"){ 
            app.preloader.hide()
            app.router.back('/workOrderCompleted/',{
              force: true
            })
          }else{
            app.preloader.hide()
            app.dialog.alert('操作失败','系统提示')
          }          
        });
      },
      pending(type){
        if(type == 0){
          const {
            $$
          } = this;       
          var page = $$('.page[data-name="work-order-editform"]')[0].f7Page;
          page.router.navigate("/pending/", {
            reloadCurrent: false,
            ignoreCache: true,
            context:{
              info: this.info
            }
          });
        }else{
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          var work_order_id = this.info.work_order_id
          
          if (app.data.session.source == 'dingding') {
              var createParams = this.getParams("action_release", {
                "dingding_code": dingding_code,
                "work_order_id": work_order_id
              });
            } else if (app.data.session.source == 'feishu') {
              var createParams = this.getParams("action_release", {
                "feishu_userid": feishu_userid,
                "work_order_id": work_order_id
              });
            } else {
              var createParams = this.getParams("action_release", {
                "wechat_name": vuserid,
                "work_order_id": work_order_id
              });
  
            }
            app.preloader.show()
            app.request.postJSON(app.data.url, createParams ,(result) => {
              if(result.result&&result.result.state == "success"){ 
                app.preloader.hide()

                app.router.back('/workOrderCompleted/',{
                  force: true
                })
              }else{

                app.preloader.hide()
                app.dialog.alert(result.result.message,'系统提示')
              }          
            });
        }

      },
    },
    on: {
      pageInit: function (e, page) {
        const self = this
        const vuserid = page.app.data.session.userid;
        const dingding_code = page.app.data.dingding_code;
        const feishu_userid = page.app.data.feishu_userid;
        const id = this.id;
        if (page.app.data.session.source == "dingding") {
          var assetMainDetailParams = this.getParams(
            "get_work_order_info_for_id",
            {
              work_order_id: id,
              dingding_code: dingding_code,
            }
          );
        } else if (page.app.data.session.source == "feishu") {
          var assetMainDetailParams = this.getParams(
            "get_work_order_info_for_id",
            {
              work_order_id: id,
              feishu_userid: feishu_userid,
            }
          );
        } else {
          var assetMainDetailParams = this.getParams(
            "get_work_order_info_for_id",
            {
              work_order_id: id,
              wechat_name: vuserid,
            }
          );
        }
        page.app.request.postJSON(
          page.app.data.url,
          assetMainDetailParams,
          function (result) {
            if (result.result) {
              page.app.data.workOrder_data = result.result;
              if (
                page.app.data.workOrder_data
                  .work_order_complete_info_line_ids.length > 0
              ) {
                page.app.data.workOrder_data.work_order_complete_info_line_ids.forEach(
                  (ele) => {
                    ele.actual_complete_date = ele.actual_complete_date
                      ? ele.actual_complete_date.slice(
                          0,
                          ele.actual_complete_date.length - 3
                        )
                      : "";
                    ele.actual_start_date = ele.actual_start_date
                      ? ele.actual_start_date.slice(
                          0,
                          ele.actual_start_date.length - 3
                        )
                      : "";
                    ele.halt_end_time = ele.halt_end_time
                      ? ele.halt_end_time.slice(
                          0,
                          ele.halt_end_time.length - 3
                        )
                      : "";
                    ele.halt_start_time = ele.halt_start_time
                      ? ele.halt_start_time.slice(
                          0,
                          ele.halt_start_time.length - 3
                        )
                      : "";
                  }
                );
              }
              result.result.save =
                result.result.extra_state.indexOf("edit") > -1;
              result.result.complete =
                result.result.extra_state.indexOf("complete") > -1;
              self.$setState({
                info:result.result,
                files: result.result.attachment_ids.files,
                images: result.result.attachment_ids.images
              });
              self.fileList = result.result.attachment_ids
              self.fileList.images.forEach((ele,index) => {
                ele.caption = ele.name
                $('img')[index].src=ele.url
              })
              // 照片预览器
              let myPhotoBrowserDark = app.photoBrowser.create({
                photos : self.fileList.images,
                theme: 'dark'
              });
              $('.pb-standalone-dark').on('click', function (event) {
                myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
              });
            } else {
              page.app.dialog.alert("获取信息错误！", "系统提示");
            }
          }
        );

      },
      pageMounted: function (e, page) {
        console.log("pageMounted")
        // page.app.data.asset_data.form_data.images.forEach((ele,index) => {
        //     ele.caption = ele.name
        //     $('img')[index].src=ele.url
        // })
      },
      pageReinit: function (e, page) {
        // let route = page.router.history[page.router.history.length-1]
        // if(route === '/workOrderViewForm/')page.router.history.push('/workOrderForm/')
        console.log(page.router.history)
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};