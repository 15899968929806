import $$ from "dom7";
import offsetViewForm from "../pages/offsetViewForm.html";

export default {
  name: "offsetViewForm",
  path: "/offsetViewForm/",
  component: {
    template: offsetViewForm,
    methods: {
      // previewImage: function (e) {
      //   const {
      //     $$
      //   } = this;
      //   var page = $$('.page[data-name="asset-view-form"]')[0].f7Page;
      //   var nowurl = e.currentTarget.dataset.src;
      //   if (page.app.data.session.source == 'dingding') {
      //     dd.ready(function () {
      //       dd.biz.util.previewImage({
      //         urls: page.app.data.asset_data.form_data.images_list, //图片地址列表
      //         current: nowurl, //当前显示的图片链接
      //         onSuccess: function (result) {
      //           console.log(result)
      //         },
      //         onFail: function (err) {
      //           console.log(err)
      //         }
      //       })
      //     });
      //   } else {
      //     wx.previewImage({
      //       current: nowurl,
      //       urls: page.app.data.asset_data.form_data.images_list
      //     });
      //   }
      // }
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },

      toLineDetail: function (e) {
        let self = this;
        let id = parseInt(e.currentTarget.dataset.lineId)

        const lineData = this.info.line_ids.filter(function(j){
          return j.line_id == id
        })
        this.$app.router.navigate('/viewLineForm/', {
          reloadCurrent: false,
          ignoreCache: true,
          context: {
            info:lineData[0],
            minimum_leave_unit:self.minimum_leave_unit
          }
        })

      },
    },
    on: {
      pageInit: function (e, page) {},
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};