import _ from 'lodash'
import mainRoutes from './main/routes'
// import demoRoutes from './demo/routes';
import homeRoutes from './home/routes'
// import catalogRoutes from './catalog/routes';
// import userRoutes from './user/routes';
// import antvRoutes from './antv/routes';
// import expenseRoutes from './expense/routes';
import detailRoutes from './detail/routes'
import expense from './expense/routes'
import assetsMaintenance from './assetsMaintenance/routes'
import approvedDocument from './approvedDocument/routes'
import myRequestsDocument from './MyRequestsDocument/routes'
import setting from './setting/routes'
import fileUpload from './fileUpload/routes'
import leaveApplication from './leaveApplication/routes'
import offsetleaveApplication from './offsetleaveapp/routes'
import regularizationApp from './regularizationApp/routes'
import contractRenewalApp from './contractRenewalApp/routes'
import expenseApp from './expenseApp/routes'
import receptionApp from './receptionApp/routes'
import teamBuildingApp from './teamBuildingApp/routes'
import goOutApp from './goOutApp/routes'
import publicApp from './publicApplication/routes'
import workOrder from './workOrderDispatch/routes'
import workOrderCompleted from './workOrderCompleted/routes'
import materialClaim from './materialClaim/routes'
import assetsInventory from './assetsInventory/routes'
import loanRoutes from './loan/routes'
import expenseClaimRoutes from './expenseClaim/routes'
import salaryQuery from './salaryQuery/routes/preIndex'
import personalInfo from './personalInfo/routes'
import accumulationFund from './accumulationFund/routes'





// Default route (404 page). MUST BE THE LAST
const errorRoute = {
  path: '(.*)',
  url: './main/pages/404.html'
}

let routes = _.concat(
  mainRoutes,
  homeRoutes,
  detailRoutes,
  expense,
  assetsMaintenance,
  approvedDocument,
  myRequestsDocument,
  setting,
  fileUpload,
  workOrder,
  workOrderCompleted,
  leaveApplication,
  offsetleaveApplication,
  regularizationApp,
  contractRenewalApp,
  assetsInventory,
  materialClaim,
  expenseApp,
  receptionApp,
  teamBuildingApp,
  goOutApp,
  publicApp,
  loanRoutes,
  expenseClaimRoutes,
  salaryQuery,
  personalInfo,
  accumulationFund,
  errorRoute
)
// const routes = _.concat(mainRoutes, homeRoutes, catalogRoutes, userRoutes);

export default routes