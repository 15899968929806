import $ from "dom7";
import accumulationFundFormView from "../pages/accumulationFundFormView.html";

export default {
    name: "/accumulationFundFormView/",
    path: "/accumulationFundFormView/",
    component: {
      template: accumulationFundFormView,
      methods: {
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            return {
                jsonrpc: "2.0",
                method: "call",
                params: {
                service: "object",
                method: "execute",
                args: [
                    dbname,
                    userid,
                    password,
                    "kthrp.chanyi.pro.fund.purchase.application.interface",
                    method,
                    obj
                ]
                }
            };
        },
        // 下载文件
        downloadFile(e) {
          window.open(e.currentTarget.dataset.url);
        },
      },

      on: {
        pageInit: function (e, page) {
          var self = this;
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var post_list_asset_maintenance_paramrs = this.getParams("get_purchase_application_record_details", {
              "dingding_code": dingding_code,
              purchase_application_id:Number(self.id)
            });

          } else if (page.app.data.session.source == 'feishu') {
            var post_list_asset_maintenance_paramrs = this.getParams("get_purchase_application_record_details", {
              "feishu_userid": feishu_userid,
              purchase_application_id:Number(self.id)
            });
          } else {
            var post_list_asset_maintenance_paramrs = this.getParams("get_purchase_application_record_details", {
              "wechat_name": vuserid,
              purchase_application_id:Number(self.id)
            });
          }
          page.app.request.postJSON(
            page.app.data.url,
            post_list_asset_maintenance_paramrs,
            function (result_list) {
              console.log(result_list);
              self.$setState({
                info:result_list.result.data,
                fileList:result_list.result.data.attachment_ids,
                files: result_list.result.data.attachment_ids.files,
                images: result_list.result.data.attachment_ids.images
              });

              self.fileList.images.forEach((ele,index) => {
                ele.caption = ele.name
                  $('img')[index].src=ele.url
              })
              // 照片预览器
              var myPhotoBrowserDark = app.photoBrowser.create({
                photos : self.fileList.images,
                theme: 'dark'
              });
              $('.pb-standalone-dark').on('click', function (event) {
                myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
              });

            }
          );


        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  }