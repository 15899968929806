import $$ from "dom7";
import addBankLine from "../pages/addBankLine.html";
import Template7 from "template7";
import * as dd from "dingtalk-jsapi";

export default {
  name: "addBankLine",
  path: "/addBankLine/",
  component: {
    template: addBankLine,
    methods: {
      bank_account_change(){
        const {
            $$
          } = this
          var page = $$('.page[data-name="addBankLine"]')[0].f7Page
          let formData = page.app.form.convertToData("#bankEditForm");
          const bank_id = formData.bank_account.split(',')[1]
          this.bank_id = bank_id
          const current_bank = this.bank_info_list.find(function (e) {
            return e.bank_id == bank_id
          });
          this.type = current_bank.account_type_name + ',' + current_bank.account_type 
          formData.account_name = current_bank.account_name
          $("#account_type").val(current_bank.account_type_name);
          $("#bank_account_name").val(current_bank.account_name);
          console.log(formData)
      },
      save() {
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="addBankLine"]')[0].f7Page
        let formData = page.app.form.convertToData("#bankEditForm");
        formData.type = this.type
        formData.bank_id = this.bank_id
        console.log(formData);
        if (!formData.bank_account) {
          app.dialog.alert("请选择账户！", "系统提示");
          return;
        }
        if (formData.amount == '' || Number(formData.amount) < 0) {
          app.dialog.alert("转账信息中金额应大于等于0！", "系统提示");
          return;
        }
        if(!this.detail){
          app.data.expense_data.bank_line_ids.push(formData)
        }else{
          app.data.expense_data.bank_line_ids[self.lineId] = formData
        }
        $$(".back").trigger("click");
   
        
      },
    },
    on: {
      pageInit: function (e, page) { 
        const self = this
        if(this.detail){
          page.app.form.fillFromData('#bankEditForm', {
            // account_name: self.detail.account_name,
            amount: self.detail.amount,
            bank_account: self.detail.bank_account,
            // bank_id: self.detail.bank_id,
            // type: self.detail.type
          });
          let formData = page.app.form.convertToData("#bankEditForm");
          formData.bank_account = this.detail.bank_account
        }
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      },
    },
  },
};
