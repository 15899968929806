/*
 * @Author: liubaozhen
 * @Date: 2023-07-04 20:50:43
 * @LastEditTime: 2024-04-18 13:20:35
 * @LastEditors: liubaozhen
 * @Description: 
 * @FilePath: \odoo\kthrp_map\src\regularizationApp\routes\regularizationAppForm.js
 */
import $$ from "dom7";
import regularizationAppForm from "../pages/regularizationAppForm.html";
import Template7 from "template7";



export default {
  name: "regularizationappform",
  path: "/regularizationappform/",
  component: {
    template: regularizationAppForm,
    data: () => {
      return {};
    },
    methods: {
      getUserObj: function () {
        if (this.$app.data.session.source == 'dingding') {
          return {
            dingding_code: this.$app.data.dingding_code
          }
        } else if (this.$app.data.session.source == 'feishu') {
          return {
            feishu_userid: this.$app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: this.$app.data.session.userid
          }
        }
      },
      getFileFormData: function (files) {
        let self = this
        let formData = new FormData()
        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        if (self.$app.data.session.source == 'dingding') {
          formData.append('dingding_code', self.$app.data.dingding_code)
        } else if (self.$app.data.session.source == 'feishu') {
          formData.append('feishu_userid', self.$app.data.feishu_userid)
        } else {
          formData.append('wechat_name', self.$app.data.session.userid)
        }
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          formData.append("file", file)
        }
        return formData
      },
      submit: function (e) {
        let self = this
        let page = $$('.page[data-name="regularization-form"]')[0].f7Page
        let regularizationFormData = self.$app.form.convertToData("#regularization-form");
        let userObj = self.getUserObj()
        let post_file_url = this.$app.data.base_url + '/web/map_upload_attachment'
        let files = this.$$('#file-attach')[0].files
        if (regularizationFormData.employee === '') {
          return self.$app.dialog.alert('员工必须有值!', "KTHRP");
        }
        if (regularizationFormData.regularization_date === '') {
          return self.$app.dialog.alert('生效日期必须有值!', "KTHRP");
        }
        regularizationFormData.employee_id = parseInt(regularizationFormData.employee_id)
        regularizationFormData.changed_department_id = parseInt(regularizationFormData.changed_department_id)
        regularizationFormData.changed_job_id = parseInt(regularizationFormData.changed_job_id)
        regularizationFormData.changed_job_grade_id = parseInt(regularizationFormData.changed_job_grade_id)
        regularizationFormData.changed_position_id = parseInt(regularizationFormData.changed_position_id)
        regularizationFormData.changed_organization_id = parseInt(regularizationFormData.changed_organization_id)
        regularizationFormData.effective_date = regularizationFormData.regularization_date
        let obj = {
          ...regularizationFormData,
          ...userObj,
          context_action_type:self.context_action_type.split(',')[0]
        }
        console.log(obj)
        app.preloader.show();
        self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.employee.change.apply.interface', 'create_change_apply', obj), function (res) {
          console.log(res);
          if (res.result.state === 'ok') {
            if (files.length === 0) {
              app.preloader.hide();
              var toast = app.toast.create({
                text: '恭喜您,操作成功',
                position: 'top',
                closeTimeout: 2000
              })
              toast.open()
              // page.router.navigate('/regularizationapplist/', {})
              app.router.back('/regularizationapplist/',{
                force: true
              })    
            } else {
              let formData = self.getFileFormData(self.$$('#file-attach')[0].files)
              formData.append('model_name', res.result.data.model_name)
              formData.append('record_id', res.result.data.record_id)
              self.$app.request.post(post_file_url, formData, function (result) {
                console.log(result)
                if (JSON.parse(result).state === 'ok') {
                  app.preloader.hide();
                  var toast = app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  // page.router.navigate('/regularizationapplist/', {})
                  app.router.back('/regularizationapplist/',{
                    force: true
                  })   
                } else {
                  app.preloader.hide();
                  var toast = app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  // page.router.navigate('/regularizationapplist/', {})
                  app.router.back('/regularizationapplist/',{
                    force: true
                  })   
                }
              });
            }
          } else {
            app.preloader.hide();
            self.$app.dialog.alert(res.result.act_err, "KTHRP");
          }
        });
      },
      employee_change(){
        let self = this
        const {
          $$
        } = this
        let page = $$('.page[data-name="regularization-form"]')[0].f7Page
        let regularizationFormData = page.app.form.convertToData("#regularization-form");

        var id = parseInt(regularizationFormData.employee_id)
        let obj = self.getUserObj()
        obj.employee_id = id
        self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.employee.change.apply.interface', 'get_default_with_employee', obj), function (res) {
          console.log(res)
          $$('.department').html(res.result.department.show_name)
          $$('.job').html(res.result.job.show_name)
          $$('.job_grade').html(res.result.job_grade.show_name)
          $$('.effect_date_from').html(res.result.effect_date_from.show_name)
        });
      }
    },
    on: {
      pageInit: function (e, page) {
        let self = this;
        $$('input').on('blur', function () {
          window.scroll(0, 0);
        });


        page.app.calendar.create({
          inputEl: '#regularization_date',
          dateFormat: "yyyy-mm-dd",
          openIn: 'customModal',
          header: true,
          footer: true,
          on: {}
        });
        page.app.form.fillFromData("#regularization-form", {
          employee_id: "",
          changed_department_id: "",
          changed_job_id: "",
          changed_job_grade_id: "",
          changed_position_id: ""
        });
        this.$setState({
          customer_code: app.data.kthrp_customer_code
        })
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
        // page.app.smartSelect.get('.smart-select.employee').on('closed', function () {
        //   var id = parseInt(page.app.smartSelect.get('.smart-select').selectEl.value)
        //   let obj = self.getUserObj()
        //   obj.employee_id = id
        //   self.$app.request.postJSON(self.$app.data.url, self.$app.methods.getJsonrpcParams('kthrp.hr.employee.change.apply.interface', 'get_default_with_employee', obj), function (res) {
        //     console.log(res)
        //     $$('.department').html(res.result.department.show_name)
        //     $$('.job').html(res.result.job.show_name)
        //     $$('.job_grade').html(res.result.job_grade.show_name)
        //     $$('.effect_date_from').html(res.result.effect_date_from.show_name)
        //   });
        // });
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};