import $ from "dom7";
import accumulationFundForm from "./accumulationFundForm";
import accumulationFundFormView from "./accumulationFundFormView";
import html from "../pages/index.html";
import style from "../styles/index.less";
import app from "../../app";

export default [{
    name: "/accumulationFundIndex/",
    path: "/accumulationFundIndex/",
    component: {
      template: html,
      style: style,
      methods: {
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            return {
                jsonrpc: "2.0",
                method: "call",
                params: {
                service: "object",
                method: "execute",
                args: [
                    dbname,
                    userid,
                    password,
                    "kthrp.chanyi.pro.fund.purchase.application.interface",
                    method,
                    obj
                ]
                }
            };
            },
        toDetailaccumulationFundForm: function (e) {
          const id = e.currentTarget.dataset.id
            app.router.navigate("/accumulationFundFormView/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  id
                }
              });
        },
        create:function(){
          var self = this;
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          if (app.data.session.source == 'dingding') {
            var get_borrowing_requisition_fields = this.getParams("get_default_fund_purchase_application", {
              "dingding_code": dingding_code
            });
          } else if (app.data.session.source == 'feishu') {
            var get_borrowing_requisition_fields = this.getParams("get_default_fund_purchase_application", {
              "feishu_userid": feishu_userid
            });
          } else {
            var get_borrowing_requisition_fields = this.getParams("get_default_fund_purchase_application", {
              "wechat_name": vuserid,
            });
          }
          app.request.postJSON(
            app.data.url,
            get_borrowing_requisition_fields,
            function (result_list) {
              console.log(result_list);
              app.router.navigate("/accumulationFundForm/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  info:result_list.result
                }
              });
            }
          );

        }
      },
      on: {
        pageInit: function (e, page) {
          var self = this;
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var post_list_accumulationFund_maintenance_paramrs = this.getParams("get_fund_purchase_application_kanban", {
              "dingding_code": dingding_code
            });
          } else if (page.app.data.session.source == 'feishu') {
            var post_list_accumulationFund_maintenance_paramrs = this.getParams("get_fund_purchase_application_kanban", {
              "feishu_userid": feishu_userid
            });
          } else {
            var post_list_accumulationFund_maintenance_paramrs = this.getParams("get_fund_purchase_application_kanban", {
              "wechat_name": vuserid,
            });
          }

          page.app.request.postJSON(
            page.app.data.url,
            post_list_accumulationFund_maintenance_paramrs,
            function (result_list) {
              console.log(result_list);
              self.$setState({
                accumulationFundList:result_list.result.all_application_kanban,
                accumulationFundListCount:result_list.result.all_application_kanban.length,
                accumulationFundList1:result_list.result.processing_application_kanban,
                accumulationFundListCount1:result_list.result.processing_application_kanban.length
              });

              page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
                $(e.currentTarget)
                  .parent()
                  .parent()
                  .prev()
                  .find(".searchbar input")
                  .blur();
              });
                //未处理 搜索框实例
              var sbtodo = page.app.searchbar.create({
                el: '[data-name="accumulationFund-index"] .searchbar-to-do',
                searchContainer: '[data-name="accumulationFund-index"] .list-to-do',
                searchIn: '[data-name="accumulationFund-index"] .item-text-to-do',
                notFoudEl: '[data-name="accumulationFund-index"] .searchbar-not-found-to-do',
                });
                var sbdone = page.app.searchbar.create({
                  el: ".searchbar-done",
                  searchContainer: ".list-done",
                  searchIn: ".item-text-done",
                  notFoudEl: ".searchbar-not-found-done",
                });
                $('[data-name="accumulationFund-index"] .searchbar-done').hide();
                $('[data-name="accumulationFund-index"] .search-icon-2').hide();

                $('[data-name="accumulationFund-index"] .to-do-btn').on("click", function () {
                  sbdone.disable();
                  $('[data-name="accumulationFund-index"] .searchbar-done,[data-name="accumulationFund-index"] .search-icon-2').hide();
                  $('[data-name="accumulationFund-index"] .searchbar-to-do,[data-name="accumulationFund-index"] .search-icon-1').show();
                });
                $('[data-name="accumulationFund-index"] .done-btn').on("click", function () {
                  sbtodo.disable();
                  $('[data-name="accumulationFund-index"] .searchbar-done,[data-name="accumulationFund-index"] .search-icon-2').show();
                  $('[data-name="accumulationFund-index"] .searchbar-to-do,[data-name="accumulationFund-index"] .search-icon-1').hide();
              });
            }
          );
          

        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  accumulationFundForm,
  accumulationFundFormView
];