import $$ from 'dom7'
import assetFormHtml from '../pages/assetForm.html'
import Template7 from 'template7'
import 'weui'
import weui from 'weui.js'
import * as dd from 'dingtalk-jsapi'

export default {
  name: 'assetform',
  path: '/assetform/',
  component: {
    template: assetFormHtml,
    data: () => {
      return {
        isFabBtn: false
      }
    },
    methods: {
      getCreateAssetMaintenanceParams: function (assetFormData) {
        var vuserid = this.$app.data.session.userid
        var dbname = this.$app.data.dbname
        var userid = this.$app.data.userid
        var password = this.$app.data.password
        return {
          jsonrpc: '2.0',
          method: 'call',
          params: {
            service: 'object',
            method: 'execute',
            args: [
              dbname,
              userid,
              password,
              'kthrp.asset.maintenance.repair.requisition.interface',
              'create_repair_requisition',
              assetFormData
            ]
          }
        }
      },
      get_asset_code_return_info_Params: function (obj) {
        var vuserid = this.$app.data.session.userid
        var dbname = this.$app.data.dbname
        var userid = this.$app.data.userid
        var password = this.$app.data.password
        return {
          jsonrpc: '2.0',
          method: 'call',
          params: {
            service: 'object',
            method: 'execute',
            args: [
              dbname,
              userid,
              password,
              'kthrp.asset.maintenance.repair.requisition.interface',
              'get_asset_code_return_info',
              obj
            ]
          }
        }
      },
      afterScanAsset(obj){
        return new Promise((resolve,reject) => {
          var page = $$('.page[data-name="asset-form"]')[0].f7Page
          var get_asset_code_return_info_Params = this.get_asset_code_return_info_Params(obj)
          console.log(get_asset_code_return_info_Params)
          page.app.request.postJSON(page.app.data.url, get_asset_code_return_info_Params, function (res) {
            console.log(res)
            if(res.result.state == 'success'){
              resolve(res.result)
            }else{
              reject()
            }

          })
        })

      },
      toAssetListView(){
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="asset-form"]')[0].f7Page
        page.router.navigate("/assetListView/", {
          reloadCurrent: false,
          ignoreCache: true,
          // context: {
          //   assetListViewData: self.asset
          // }
        });
      },
      submitAssetMan: function (e) {
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="asset-form"]')[0].f7Page
        let post_file_url = this.$app.data.base_url + '/web/map_upload_attachment'
        var formData = new FormData()
        // let files = this.$$('#file-attach')[0].files
        formData.append('model_name', 'kthrp.asset.maintenance.repair.requisition')
        formData.append('db', this.$app.data.dbname)
        formData.append('id', this.$app.data.userid)
        formData.append('pwd', this.$app.data.password)
        for (var i = 0; i < this.fileList.images.length; i++) {
          var file = this.fileList.images[i];
          formData.append("file", file)
        }
        for (var i = 0; i < this.fileList.files.length; i++) {
          var file = this.fileList.files[i];
          formData.append("file", file)
        }
        if(!self.assetInfo){
          page.app.dialog.alert('请先选择资产', 'KTHRP')
          return false
        }

        var assetFormData = page.app.form.convertToData('#asset-form')
        assetFormData.asset_id = parseInt(self.assetInfo.id)
        if (assetFormData.description == '') {
          page.app.dialog.alert('请填写故障描述', 'KTHRP')
        } else {
          $$(e.currentTarget).css('pointer-events', 'none')
          var vuserid = page.app.data.session.userid
          // assetFormData.asset_id = parseInt(assetFormData.asset_id)
          assetFormData.media_id = this.imagesData.serverId

          if (page.app.data.session.source == 'dingding') {
            assetFormData.dingding_code = page.app.data.dingding_code
            formData.append('dingding_code', page.app.data.dingding_code)
          } else if (page.app.data.session.source == 'feishu') {
            assetFormData.feishu_userid = page.app.data.feishu_userid
            formData.append('feishu_userid', page.app.data.feishu_userid)
          } else {
            assetFormData.wechat_name = page.app.data.session.userid
            formData.append('wechat_name', page.app.data.session.userid)
          }
          var postCreateAssetMantenanceParams = this.getCreateAssetMaintenanceParams(assetFormData)
          console.log(postCreateAssetMantenanceParams)
          app.preloader.show();
          page.app.request.postJSON(page.app.data.url, postCreateAssetMantenanceParams, function (asset_result) {
            console.log(asset_result)
            if (asset_result.error) {
              var toast = app.toast.create({
                text: '很遗憾,操作失败',
                position: 'top',
                closeTimeout: 2000
              })
              app.preloader.hide();
              toast.open()
              page.router.navigate('/asset/', {
                // reloadCurrent: true,
                // ignoreCache: true
              })
            } else {
              formData.append('record_id', asset_result.result.record_id)
              if(self.fileList.images.length>0 || self.fileList.files.length>0){
                page.app.request.post(post_file_url, formData, function (result) {
                  console.log(result)
                  if (JSON.parse(result).state === 'ok') {
                    var toast = app.toast.create({
                      text: '恭喜您,操作成功',
                      position: 'top',
                      closeTimeout: 2000
                    })
                    app.preloader.hide();
                    toast.open()
                    page.router.navigate('/asset/', {
                      // reloadCurrent: true,
                      // ignoreCache: true
                    })
                  } else {
                    var toast = app.toast.create({
                      text: '很遗憾,操作失败',
                      position: 'top',
                      closeTimeout: 2000
                    })
                    app.preloader.hide();
                    toast.open()
                    page.router.navigate('/asset/', {
                      // reloadCurrent: true,
                      // ignoreCache: true
                    })
                  }
                });
              }else{
                var toast = app.toast.create({
                  text: '恭喜您,操作成功',
                  position: 'top',
                  closeTimeout: 2000
                })
                app.preloader.hide();
                toast.open()
                page.router.navigate('/asset/', {
                  // reloadCurrent: true,
                  // ignoreCache: true
                })
              }
            }
          })
        }
      },
      //文件上传后的处理函数
      filechange(){ 
        Array.from(this.$$('#file-attach')[0].files).forEach(ele => {
          if(ele.type.indexOf('image')>-1){
            this.fileList.images.push(ele)
          }else{
            this.fileList.files.push(ele)
          }
        })
        this.$setState({
          images: this.fileList.images,
          files: this.fileList.files
        })
        //处理图片上传后事件冲突的问题
        $('.pb-standalone-dark').off('click')
       $('.pb-standalone-dark').on('click', function (event) {
        app.data.asset_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        $('.addimg').off('click')
        $('.addimg').on('click',function (event) { 
          $('#file-attach').trigger('click')
       })
       app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images
        // 读取文件的函数
        let self = this
        function readAndPreview(file,index){
          let reader = new FileReader();
          reader.addEventListener("load", function () {
              file.url = this.result
              file.caption = file.name  
              self.fileList.images.forEach((ele) => {
                $('img')[index].src=this.result
              })       
          }, false);
          if(!file.url)reader.readAsDataURL(file)    
        }
        [].forEach.call(this.fileList.images, readAndPreview)
        // setTimeout(()=>{
        //   this.fileList.images.forEach((ele,index) => {
        //     $('img')[index].src=ele.url
        //   }) 
        // },200)
        console.log(this.fileList)
      },
      // 删除上传的文件
      delFile(val){
        let e = window.event
        e.stopPropagation()
        if(val == 'img'){ // 删除图片
          this.fileList.images.splice(Number(e.currentTarget.dataset.index),1)
          this.$setState({
            images: this.fileList.images
          })

          //处理图片删除后事件冲突的问题
          $('.addimg').off('click')
          $('.addimg').on('click',function (event) { 
            $('#file-attach').trigger('click')
         })


         app.data.asset_data.myPhotoBrowserDark.params.photos = this.fileList.images
          this.fileList.images.forEach((ele,index) => {
            ele.caption = ele.name
            $('img')[index].src=ele.url
        })
        }else{  // 删除其他文件
          this.fileList.files.splice(Number(e.currentTarget.dataset.index),1)
          this.$setState({
            files: this.fileList.files
          })
        }    
      },
    },
    on: {
      pageInit: function (e, page) {
        var self = this
        var post_params = {
          jsonrpc: '2.0',
          method: 'call',
          params: {
            service: 'object',
            method: 'execute',
            args: [
              'wechat',
              623,
              'MAPp@ssw0rd',
              'kthrp.wechat.map.interface',
              'mobile_workflow_info_list',
              {
                auth_code: page.app.data.session.code,
                userid: 'BeiMingYouYu',
                status: 'to_be_approved',
                url: location.href.split('#')[0]
              }
            ]
          }
        }
        if(!this.fileList)this.fileList={}
        if(!this.fileList.images)this.fileList.images=[]
        if(!this.fileList.files)this.fileList.files=[]
        this.$setState({
          images: [],
          files: []
        })
        // 照片预览器
        page.app.data.asset_data.myPhotoBrowserDark = app.photoBrowser.create({
          photos : this.fileList.images,
          theme: 'dark'
        });
        $('.pb-standalone-dark').on('click', function (event) {
          page.app.data.asset_data.myPhotoBrowserDark.open(Number(event.currentTarget.dataset.index));
        });
        // 触发上传文件的事件
        $('.addimg').on('click',function (event) { 
            $('#file-attach').trigger('click')
         })
        if (page.app.data.session.source === 'dingding') {
          dd.ready(function () {
            $$('#asset_scan').on('click', function () {
              dd.biz.util.scan({
                type: 'all', // type 为 all、qrCode、barCode，默认是all。
                onSuccess: function (res) {
                  //onSuccess将在扫码成功之后回调
                  /* data结构
                    { 'text': String}
                  */
                  console.log(res)
                  if(page.app.data.kthrp_customer_code  == 'jiahui'){
                    if(res.text.indexOf('资产编号') > -1){
                      var asset_code = res.text.match(/资产编号：(\S*)\n/)[1]
                    }else{
                      var asset_code = res.text
                    }
                  }else{
                    if(res.text.indexOf('资产编号') > -1){
                      var asset_code = res.text.match(/资产编号：(\S*)\n/)[1]
                    }else{
                      var asset_code = res.text.split(' ')[0]
                    }
                  }
                  if(asset_code){
                    self.afterScanAsset({asset_code}).then(result => {
                      if(result.state == 'success'){
                        self.$setState({
                          assetInfo:{
                            name : result.asset.name,
                            code : result.asset.code,
                            id : result.asset.id,
                            place : result.asset.location_id
                          }
                        })
                      }

                    })
                  }
                  // var assetList = page.app.data.asset_data.asset
                  // for (var i = 0; i < assetList.length; i++) {
                  //   if (res.text.indexOf(assetList[i].code) != -1) {
                  //     var asset_id = assetList[i].id
                  //     page.app.form.fillFromData('#asset-form', {
                  //       asset_id: asset_id
                  //     })
                  //     $$('.place').html(assetList[i].location_id);
                  //   }
                  // }
                },
                onFail: function (err) {
                  console.log(err)
                }
              })
            })
          })
          self.imagesData = {
            localId: [],
            serverId: []
          }
          this.$setState({
            asset: page.app.data.asset_data.asset,
            telephone: page.app.data.asset_data.telephone
          })

          var uploadCount = 0
          var tmplIos = '<li class="weui-uploader__file" style="background-image:url(#url#)"></li>',
            tmplAndroid = '<li class="weui-uploader__file"><img width="79" height="79" src="#url#"/></li>',
            $$gallery = $$('#gallery'),
            $$galleryImg = $$('#galleryImg'),
            $$uploaderInput = $$('#assetUploaderInput'),
            $$uploaderFiles = $$('#assetUploaderFiles')

          dd.ready(function () {
            $$uploaderInput.on('click', function (e) {
              console.log('upload')
              dd.biz.util.uploadImage({
                stickers: {
                  time: '',
                  dateWeather: '',
                  username: '',
                  address: ''
                },
                multiple: true,
                max: 2,
                onSuccess: function (res) {
                  console.log(res)
                  for (var i = 0; i < res.length; i++) {
                    if (page.app.device.ios) {
                      console.log('ios')
                      $$uploaderFiles.append($$(tmplIos.replace('#url#', res[i])))
                      self.imagesData.serverId.push(res[i]) // 返回图片的服务器端ID
                    } else if (page.app.device.android) {
                      console.log('android')
                      $$uploaderFiles.append($$(tmplAndroid.replace('#url#', res[i])))
                      self.imagesData.serverId.push(res[i]) // 返回图片的服务器端ID
                    }
                  }
                },
                onFail: function (err) {}
              })
            })
          })

          var liIndex
          $$uploaderFiles.on('click', 'li', function () {
            liIndex = $$(this).index()
            console.log(liIndex)
            $$galleryImg.attr('style', this.getAttribute('style'))
            $$gallery.show(100)
          })
          $$gallery.on('click', function () {
            $$gallery.hide(100)
          })
          $$('.weui-gallery__del').on('click', function () {
            console.log(liIndex)
            self.imagesData.serverId.splice(liIndex, 1)
            $$uploaderFiles
              .find('li')
              .eq(liIndex)
              .remove()
            console.log(self.imagesData.serverId)
          })
          
        // } else if (page.app.data.session.source === 'feishu') {
        //   window.tt.ready(function () {
        //     $$('#asset_scan').on('click', function () {
        //       window.tt.scanCode({
        //         scanType: [
        //           "barCode",
        //           "qrCode",
        //           "datamatrix",
        //           "pdf417"
        //         ], // type 为 all、qrCode、barCode，默认是all。
        //         barCodeInput: true,
        //         success: function (res) {
        //           //onSuccess将在扫码成功之后回调
        //           /* data结构
        //             { 'text': String}
        //           */
        //           console.log(res)
        //           if(page.app.data.kthrp_customer_code  == 'jiahui'){
        //             if(res.result.indexOf('资产编号') > -1){
        //               var asset_code = res.result.match(/资产编号：(\S*)\n/)[1]
        //             }else{
        //               var asset_code = res.result
        //             }
        //           }else{
        //             if(res.result.indexOf('资产编号') > -1){
        //               var asset_code = res.result.match(/资产编号：(\S*)\n/)[1]
        //             }else{
        //               var asset_code = res.result.split(' ')[0]
        //             }
        //           }
        //           if(asset_code){
        //             self.afterScanAsset({asset_code}).then(result => {
        //               if(result.state == 'success'){
        //                 self.$setState({
        //                   assetInfo:{
        //                     name : result.asset.name,
        //                     code : result.asset.code,
        //                     id : result.asset.id,
        //                     place : result.asset.location_id
        //                   }
        //                 })
        //               }

        //             })
        //           }
        //           // var assetList = page.app.data.asset_data.asset
        //           // for (var i = 0; i < assetList.length; i++) {
        //           //   if (res.text.indexOf(assetList[i].code) != -1) {
        //           //     var asset_id = assetList[i].id
        //           //     page.app.form.fillFromData('#asset-form', {
        //           //       asset_id: asset_id
        //           //     })
        //           //     $$('.place').html(assetList[i].location_id);
        //           //   }
        //           // }
        //         },
        //         fail: function (err) {
        //           console.log(err)
        //         }
        //       })
        //     })
        //   })
        //   self.imagesData = {
        //     localId: [],
        //     serverId: []
        //   }
        //   this.$setState({
        //     asset: page.app.data.asset_data.asset,
        //     telephone: page.app.data.asset_data.telephone
        //   })

        //   var uploadCount = 0
        //   var tmplIos = '<li class="weui-uploader__file" style="background-image:url(#url#)"></li>',
        //     tmplAndroid = '<li class="weui-uploader__file"><img width="79" height="79" src="#url#"/></li>',
        //     $$gallery = $$('#gallery'),
        //     $$galleryImg = $$('#galleryImg'),
        //     $$uploaderInput = $$('#assetUploaderInput'),
        //     $$uploaderFiles = $$('#assetUploaderFiles')

        //   window.tt.ready(function () {
        //     $$uploaderInput.on('click', function (e) {
        //       console.log('upload')
        //       window.tt.chooseImage({
        //         count: 1,
        //         sizeType: ["original"],
        //         sourceType: ["album", "camera"],
        //         success: function (res) {
        //           console.log(res)
        //           for (var i = 0; i < res.length; i++) {
        //             if (page.app.device.ios) {
        //               console.log('ios')
        //               $$uploaderFiles.append($$(tmplIos.replace('#url#', res[i])))
        //               self.imagesData.serverId.push(res[i]) // 返回图片的服务器端ID
        //             } else if (page.app.device.android) {
        //               console.log('android')
        //               $$uploaderFiles.append($$(tmplAndroid.replace('#url#', res[i])))
        //               self.imagesData.serverId.push(res[i]) // 返回图片的服务器端ID
        //             }
        //           }
        //         },
        //         fail: function (err) {}
        //       })
        //     })
        //   })

        //   var liIndex
        //   $$uploaderFiles.on('click', 'li', function () {
        //     liIndex = $$(this).index()
        //     console.log(liIndex)
        //     $$galleryImg.attr('style', this.getAttribute('style'))
        //     $$gallery.show(100)
        //   })
        //   $$gallery.on('click', function () {
        //     $$gallery.hide(100)
        //   })
        //   $$('.weui-gallery__del').on('click', function () {
        //     console.log(liIndex)
        //     self.imagesData.serverId.splice(liIndex, 1)
        //     $$uploaderFiles
        //       .find('li')
        //       .eq(liIndex)
        //       .remove()
        //     console.log(self.imagesData.serverId)
        //   })
        } else {
          wx.ready(function () {
            $$('#asset_scan').on('click', function () {
              wx.scanQRCode({
                desc: 'scanQRCode desc',
                needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
                scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {
                  if(page.app.data.kthrp_customer_code  == 'jiahui'){
                    if(res.resultStr.indexOf('资产编号') > -1){
                      var asset_code = res.resultStr.match(/资产编号：(\S*)\n/)[1]
                    }else{
                      var asset_code = res.resultStr
                    }
                  }else{
                    if(res.resultStr.indexOf('资产编号') > -1){
                      var asset_code = res.resultStr.match(/资产编号：(\S*)\n/)[1]
                    }else{
                      var asset_code = res.resultStr.split(' ')[0]
                    }
                  }
                  if(asset_code){
                    self.afterScanAsset({asset_code}).then(result => {
                      if(result.state == 'success'){
                        self.$setState({
                          assetInfo:{
                            name : result.asset.name,
                            code : result.asset.code,
                            id : result.asset.id,
                            place : result.asset.location_id
                          }
                        })
                      }
                    })
                  }

                  // var assetList = page.app.data.asset_data.asset
                  // for (var i = 0; i < assetList.length; i++) {
                  //   if (res.resultStr.indexOf(assetList[i].code) != -1) {
                  //     var asset_id = assetList[i].id
                  //     page.app.form.fillFromData('#asset-form', {
                  //       asset_id: asset_id
                  //     })
                  //     $$('.place').html(assetList[i].location_id);
                  //   }
                  // }
                },
                error: function (res) {
                  if (res.errMsg.indexOf('function_not_exist') > 0) {
                    console.log('版本过低请升级')
                    alert(res)
                  }
                }
              })
            });
            $$('#asset_place_scan').on('click', function () {
              wx.scanQRCode({
                desc: 'scanQRCode desc',
                needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
                scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {

                  console.log('asset_place_scan:',res)
                  self.report_locations.filter(function(e){
                    if(res.resultStr && self.assetInfo && self.assetInfo.name){
                      if(res.resultStr == e.id){
                        $('#report_location_id').val(e.show_name)
                      }
                    }else{
                      $('#report_location_id').val(self.assetInfo.name)
                    }
                  })
                  // var assetList = page.app.data.asset_data.asset
                  // for (var i = 0; i < assetList.length; i++) {
                  //   if (res.resultStr.indexOf(assetList[i].code) != -1) {
                  //     var asset_id = assetList[i].id
                  //     page.app.form.fillFromData('#asset-form', {
                  //       asset_id: asset_id
                  //     })
                  //     $$('.place').html(assetList[i].location_id);
                  //   }
                  // }
                },
                error: function (res) {
                  if (res.errMsg.indexOf('function_not_exist') > 0) {
                    console.log('版本过低请升级')
                    alert(res)
                  }
                }
              })
            })
          })
          self.imagesData = {
            localId: [],
            serverId: []
          }
          this.$setState({
            asset: page.app.data.asset_data.asset,
            telephone: page.app.data.asset_data.telephone
          })

          var uploadCount = 0
          var tmplIos = '<li class="weui-uploader__file" style="background-image:url(#url#)"></li>',
            tmplAndroid = '<li class="weui-uploader__file"><img width="79" height="79" src="#url#"/></li>',
            $$gallery = $$('#gallery'),
            $$galleryImg = $$('#galleryImg'),
            $$uploaderInput = $$('#assetUploaderInput'),
            $$uploaderFiles = $$('#assetUploaderFiles')

          wx.ready(function () {
            $$uploaderInput.on('click', function (e) {
              console.log('upload')
              wx.chooseImage({
                count: 1,
                sizeType: ['original'],
                sourceType: ['album', 'camera'],
                success: function (res) {
                  console.log(res)
                  // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                  // localId 可以用于微信手机版图片显示（目前PC版不可用）
                  var localIds = res.localIds
                  for (var i = 0; i < localIds.length; i++) {
                    var localId = localIds[i]
                    if (page.app.device.ios) {
                      wx.getLocalImgData({
                        localId: localId, // 图片的localID
                        success: function (getLocalImgData_res) {
                          console.log(getLocalImgData_res)
                          $$uploaderFiles.append($$(tmplIos.replace('#url#', getLocalImgData_res.localData)))
                          wx.uploadImage({
                            localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (uploadImage_res) {
                              self.imagesData.serverId.push(uploadImage_res.serverId) // 返回图片的服务器端ID
                            }
                          })
                        }
                      })
                    } else if (page.app.device.android) {
                      console.log('android')
                      $$uploaderFiles.append($$(tmplAndroid.replace('#url#', localId)))
                      wx.uploadImage({
                        localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: function (uploadImage_res) {
                          self.imagesData.serverId.push(uploadImage_res.serverId) // 返回图片的服务器端ID
                        }
                      })
                    }
                  }
                }
              })
            })
          })

          var liIndex
          $$uploaderFiles.on('click', 'li', function () {
            liIndex = $$(this).index()
            console.log(liIndex)
            $$galleryImg.attr('style', this.getAttribute('style'))
            $$gallery.show(100)
          })
          $$gallery.on('click', function () {
            $$gallery.hide(100)
          })
          $$('.weui-gallery__del').on('click', function () {
            console.log(liIndex)
            self.imagesData.serverId.splice(liIndex, 1)
            $$uploaderFiles
              .find('li')
              .eq(liIndex)
              .remove()
            console.log(self.imagesData.serverId)
          })
        }
      },
      pageMounted: function (e, page) {
       console.log(app.data.kthrp_customer_code) 
        console.log('pageMounted')
      },
      pageReinit: function (e, page) {
        console.log('pageReinit')
      },
      pageBeforeIn: function (e, page) {
        console.log('pageBeforeIn')
      },
      pageAfterIn: function (e, page) {
        console.log('pageAfterIn')
        page.app.smartSelect.get('.smart-select').on('closed', function () {
          var asset_id = page.app.smartSelect.get('.smart-select').selectEl.value;
          var assetList = page.app.data.asset_data.asset
          if (!assetList) {
            return
          }
          for (var i = 0; i < assetList.length; i++) {
            if (asset_id == assetList[i].id) {
              $$('.place').html(assetList[i].location_id);
            }
          }
        });
      },
      pageBeforeInOut: function (e, page) {
        console.log('pageBeforeInOut')
      },
      pageAfterOut: function (e, page) {
        console.log('pageAfterOut')
      },
      pageBeforeRemove: function (e, page) {
        console.log('pageBeforeRemove')
      }
    }
  }
}