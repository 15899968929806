import $$ from "dom7";
import pending from "../pages/pending.html";
import Template7 from "template7";
import app from "../../app";
import { retry } from "async";


export default {
  name: "pending",
  path: "/pending/",
  component: {
    template: pending,
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = []
          args = [
              dbname,
              userid,
              password,
              "kthrp.asset.maintenance.work.order.interface",
              method,
              obj
            ]
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args
          }
        };
      },
      submit(){
        let formData = app.form.convertToData("#editForm");
        console.log(formData);
        if (formData.pending_note == "") {
            app.dialog.alert("请填写暂挂原因！", "系统提示");
          return;
        }else if(formData.plan_end_date == ''){
            app.dialog.alert("请选择预计解除时间", "系统提示");
          return;
        }
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        var work_order_id = this.info.work_order_id
        if (app.data.session.source == 'dingding') {
            var createParams = this.getParams("action_pending", {
                "dingding_code": dingding_code,
                "work_order_id": work_order_id,
                "expected_release_time":formData.plan_end_date,
                "pending_reason":formData.pending_note
            });
          } else if (app.data.session.source == 'feishu') {
            var createParams = this.getParams("action_pending", {
                "feishu_userid": feishu_userid,
                "work_order_id": work_order_id,
                "expected_release_time":formData.plan_end_date,
                "pending_reason":formData.pending_note
            });
            } else {
            var createParams = this.getParams("action_pending", {
                "wechat_name": vuserid,
                "work_order_id": work_order_id,
                "expected_release_time":formData.plan_end_date,
                "pending_reason":formData.pending_note
            });

            }
            app.preloader.show()
            app.request.postJSON(app.data.url, createParams ,(result) => {
            if(result.result&&result.result.state == "success"){ 
                app.preloader.hide()

                app.router.back('/workOrderCompleted/',{
                    force: true
                })
            }else{
                app.preloader.hide()
                app.dialog.alert('系统错误！','系统提示')
            }          
            });
      },

    },
    on: {
      pageInit: function (e, page) {
        //注册时间选择控件
        let plan_end_date = new lCalendar();
        plan_end_date.init({
          'trigger': '#plan_end_date',
          'type': 'datetime'
        });

      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {   

        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};