import $ from 'dom7'
import about from './about'
import html from '../pages/index.html'
import style from '../styles/index.less'
export default [{
    name: '/file/',
    path: '/file/',
    component: {
      template: html,
      style: style,
      methods: {
        getDocumentTypeParmas: function () {
          if (this.$app.data.session.source === 'dingding') {
            return {
              dingding_code: this.$app.data.dingding_code
            }
          } else if (this.$app.data.session.source === 'feishu') {
            return {
              feishu_userid: this.$app.data.feishu_userid
            }
          } else {
            return {
              wechat_name: this.$app.data.session.userid
            }
          }
        },
        getDocumentDataParmas: function (modelName, displayName) {
          if (this.$app.data.session.source === 'dingding') {
            return {
              dingding_code: this.$app.data.dingding_code,
              model_name: modelName,
              display_name: displayName
            }
          } else if (this.$app.data.session.source === 'feishu') {
            return {
              feishu_userid: this.$app.data.feishu_userid,
              model_name: modelName,
              display_name: displayName
            }
          } else {
            return {
              wechat_name: this.$app.data.session.userid,
              model_name: modelName,
              display_name: displayName
            }
          }
        },
        jsonRpc: function (func, method, params) {
          let self = this
          this.$app.request.postJSON(
            this.$app.data.url, {
              jsonrpc: '2.0',
              method: '2.0',
              params: {
                service: 'object',
                method: 'execute',
                args: [
                  this.$app.data.dbname,
                  this.$app.data.userid,
                  this.$app.data.password,
                  'kthrp.base.map.interface',
                  method,
                  params
                ]
              }
            },
            function (result) {
              console.log(result)
              func(result)
            }
          )
        },
        uploadFile: function () {
          var self = this
          var formData = new FormData()
          let files = this.$$('input')[0].files
          let headData = this.$app.form.convertToData('#fileupload-form')
          let post_url = this.$app.data.base_url + '/web/map_upload_attachment'
          // formData.append('file', file)
          formData.append('record_id', headData.record_id)
          formData.append('model_name', headData.model_name)
          formData.append('db', this.$app.data.dbname)
          formData.append('id', this.$app.data.userid)
          formData.append('pwd', this.$app.data.password)
          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            formData.append("file", file)
          }
          if (!headData.model_name) {
            this.$app.dialog.alert('请选择单据类型！', 'KTHRP')
          } else if (!headData.record_id) {
            this.$app.dialog.alert('请选择单据编号！', 'KTHRP')
          } else if (files.length === 0) {
            this.$app.dialog.alert('请上传附件或图片！', 'KTHRP')
          } else {
            if (this.$app.data.session.source === 'dingding') {
              formData.append('dingding_code', this.$app.data.dingding_code)
              self.$app.request.post(post_url, formData, function (result) {
                if (JSON.parse(result).state === 'ok') {
                  var toast = self.$app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  self.$app.router.navigate('/home/', {})
                } else {
                  var toast = self.$app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  self.$app.router.navigate('/home/', {})
                }
              })
            } else if (this.$app.data.session.source === 'feishu') {
              formData.append('feishu_userid', this.$app.data.feishu_userid)
              self.$app.request.post(post_url, formData, function (result) {
                if (JSON.parse(result).state === 'ok') {
                  var toast = self.$app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  self.$app.router.navigate('/home/', {})
                } else {
                  var toast = self.$app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  self.$app.router.navigate('/home/', {})
                }
              })
            } else {
              formData.append('wechat_name', this.$app.data.session.userid)
              self.$app.request.post(post_url, formData, function (result) {
                if (JSON.parse(result).state === 'ok') {
                  var toast = self.$app.toast.create({
                    text: '恭喜您,操作成功',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  self.$app.router.navigate('/home/', {})
                } else {
                  var toast = self.$app.toast.create({
                    text: '很遗憾,操作失败',
                    position: 'top',
                    closeTimeout: 2000
                  })
                  toast.open()
                  self.$app.router.navigate('/home/', {})
                }
              })
              // for (let i = 0; i < files.length; i++) {
              //   formData.append('file', files[i])
              //   this.$app.request.post('http://kthrp.karrytech.com:1270/web/map_upload_attachment', formData, function(
              //     result
              //   ) {
              //     if (JSON.parse(result).state === 'ok' && i === files.length - 1) {
              //       var toast = self.$app.toast.create({
              //         text: '恭喜您,操作成功',
              //         position: 'top',
              //         closeTimeout: 2000
              //       })
              //       toast.open()
              //       self.$app.router.navigate('/home/', {
              //         // reloadCurrent: true,
              //         // ignoreCache: true
              //       })
              //     }
              //   })
              // }
            }
          }
        }
      },
      on: {
        pageInit: function (e, page) {
          console.log('pageInit')
          let self = this
          //获取单据类型数据并填充
          self.jsonRpc(
            function (result) {
              self.$setState({
                documentTypeList: result.result.data
              })
            },
            'get_document_type',
            self.getDocumentTypeParmas()
          )
        },
        pageMounted: function (e, page) {
          console.log('pageMounted')
        },
        pageReinit: function (e, page) {
          console.log('pageReinit')
        },
        pageBeforeIn: function (e, page) {
          console.log('pageBeforeIn')
        },
        pageAfterIn: function (e, page) {
          console.log('pageAfterIn')
          let self = this
          let documentType, documentTypeDisplayName
          let documentTypeSelect = page.app.smartSelect.get('.document-type-smart-select').on('close', function () {
            documentType = $('#document-type')
              .find('option:checked')
              .val()
            documentTypeDisplayName = $('#document-type')
              .find('option:checked')
              .text()
            //获取单据类型对应单据编号并填充
            self.jsonRpc(
              function (result) {
                self.$setState({
                  documentDataList: result.result.data
                })
                page.app.form.fillFromData('#fileupload-form', {
                  record_id: ''
                })
              },
              'get_document_data',
              self.getDocumentDataParmas(documentType, documentTypeDisplayName)
            )
          })
          page.app.form.fillFromData('#fileupload-form', {
            model_name: ''
          })
        },
        pageBeforeInOut: function (e, page) {
          console.log('pageBeforeInOut')
        },
        pageAfterOut: function (e, page) {
          console.log('pageAfterOut')
        },
        pageBeforeRemove: function (e, page) {
          console.log('pageBeforeRemove')
        }
      }
    }
  },
  about
]