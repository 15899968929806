import $ from "dom7";
import leaveApplicationForm from "./leaveApplicationForm";
import afterTakeBackForm from "./afterTakeBackForm";
import leaveApplicationViewForm from "./leaveApplicationViewForm";
import leaveApplicationFormLineDetail from "./leaveApplicationFormLineDetail";

import preIndex from "./preIndex";
import about from "./about";
import html from "../pages/index.html";
import style from "../styles/index.less";
export default [{
    name: "/leaveapplicationlist/",
    path: "/leaveapplicationlist/",
    // url: "./expense/pages/index.html"
    component: {
      template: html,
      style: style,
      methods: {
        getUserObj: function () {
          if (this.$app.data.session.source == 'dingding') {
            return {
              dingding_code: this.$app.data.dingding_code
            }
          } else if (this.$app.data.session.source == 'feishu') {
            return {
              feishu_userid: this.$app.data.feishu_userid
            }
          } else {
            return {
              wechat_name: this.$app.data.session.userid
            }
          }
        },
        toDetail: function (e) {
          let self = this;
          let id = parseInt(e.currentTarget.dataset.id);
          let state = e.currentTarget.dataset.state
          let obj = this.getUserObj()
          obj.record_id = parseInt(id)
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'get_user_leave_record_details', obj), function (res) {
            console.log(res);
            if(state == '已创建'){
              const r = res.result.data
              r.leave_type_id = r.leave_type
              
              r.line_ids.map(function(item){
                if(r.minimum_leave_unit == 'day'){
                  item.start_date_str = item.start_day + ' '
                  item.end_date_str = item.end_day + ' '
                }else if(r.minimum_leave_unit == 'half_day'){
                  item.start_date_str = item.start_day + ' ' + item.start_time_period
                  item.end_date_str = item.end_day + ' ' + item.end_time_period
                }else{
                  item.start_date_str = item.start_date
                  item.end_date_str = item.end_date
                }
                 
              });
              app.router.navigate("/afterTakeBackForm/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  attachments: true,
                  info: r,
                  isTakeBack:true,
                  minimum_leave_unit:r.minimum_leave_unit
                }
              });
            }else{
              app.router.navigate("/leaveapplicationviewform/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  attachments: true,
                  info: res.result.data
                }
              });
            }
          });
        }
      },
      on: {
        pageInit: function (e, page) {
          let self = this
          const {
            $$
          } = this
          this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'get_user_leave_record', this.getUserObj()), function (res) {
            console.log(res)
            self.$setState({
              all_leave_record: res.result.data.all_leave_record,
              all_leave_record_count: res.result.data.all_leave_record_count,
              processing_leave_record: res.result.data.processing_leave_record,
              processing_leave_record_count: res.result.data.processing_leave_record_count,
            });
            //未处理 搜索框实例
            var sbtodo = page.app.searchbar.create({
              el: '[data-name="leaveapplication-index"] .searchbar-to-do',
              searchContainer: '[data-name="leaveapplication-index"] .list-to-do',
              searchIn: '[data-name="leaveapplication-index"] .item-text-to-do',
              notFoudEl: '[data-name="leaveapplication-index"] .searchbar-not-found-to-do',
            });
            var sbdone = page.app.searchbar.create({
              el: ".searchbar-done",
              searchContainer: ".list-done",
              searchIn: ".item-text-done",
              notFoudEl: ".searchbar-not-found-done",
            });
            $('[data-name="leaveapplication-index"] .searchbar-done').hide();
            $('[data-name="leaveapplication-index"] .search-icon-2').hide();

            $('[data-name="leaveapplication-index"] .to-do-btn').on("click", function () {
              sbdone.disable();
              $('[data-name="leaveapplication-index"] .searchbar-done,[data-name="leaveapplication-index"] .search-icon-2').hide();
              $('[data-name="leaveapplication-index"] .searchbar-to-do,[data-name="leaveapplication-index"] .search-icon-1').show();
            });
            $('[data-name="leaveapplication-index"] .done-btn').on("click", function () {
              sbtodo.disable();
              $('[data-name="leaveapplication-index"] .searchbar-done,[data-name="leaveapplication-index"] .search-icon-2').show();
              $('[data-name="leaveapplication-index"] .searchbar-to-do,[data-name="leaveapplication-index"] .search-icon-1').hide();
            });
          })
        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  preIndex,
  leaveApplicationForm,
  leaveApplicationViewForm,
  leaveApplicationFormLineDetail,
  afterTakeBackForm,
  about
];