import $ from "dom7";
import expenseClaimForm from "./expenseClaimForm";
import expenseClaimLineForm from "./expenseClaimLineForm";
import expenseClaimFormView from "./expenseClaimFormView";
import addLoanLine from "./addLoanLine";
import addBankLine from "./addBankLine";
import html from "../pages/index.html";
import style from "../styles/index.less";
import app from "../../app";

export default [{
    name: "/expenseClaimIndex/",
    path: "/expenseClaimIndex/",
    component: {
      template: html,
      style: style,
      methods: {
        getParams: function (method, obj) {
            var vuserid = this.$app.data.session.userid;
            var dbname = this.$app.data.dbname;
            var userid = this.$app.data.userid;
            var password = this.$app.data.password;
            return {
                jsonrpc: "2.0",
                method: "call",
                params: {
                service: "object",
                method: "execute",
                args: [
                    dbname,
                    userid,
                    password,
                    "kthrp.expense.report.requisition.interface",
                    method,
                    obj
                ]
                }
            };
            },
        toDetailexpenseClaimForm: function (e) {
          const id = e.currentTarget.dataset.id
            app.router.navigate("/expenseClaimFormView/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  id
                }
              });
        },
        create:function(){
          app.preloader.show();
          const self = this
          const {
            $$
          } = this
          var page = $$('.page[data-name="expenseClaim-index"]')[0].f7Page
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var getFields = this.getParams("get_report_requisition_fields", {
              "dingding_code": dingding_code
            });
          } else if (page.app.data.session.source == 'feishu') {
            var getFields = this.getParams("get_report_requisition_fields", {
              "feishu_userid": feishu_userid
            });
          } else {
            var getFields = this.getParams("get_report_requisition_fields", {
              "wechat_name": vuserid,
            });
          }

          page.app.request.postJSON(
            page.app.data.url,
            getFields,
            function (result_list) {
              app.preloader.hide();
              app.router.navigate("/expenseClaimForm/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  info:result_list.result,
                  borrowing_list_count: 0,
                  expense_requisition_list_count: 0
                }
              });
              // console.log(result_list);
              // self.$setState({
              //   requisition_fields:result_list.result
              // });
            }
          );

        }
      },
      on: {
        pageInit: function (e, page) {
          var self = this;
        
          var vuserid = page.app.data.session.userid;
          var dingding_code = page.app.data.dingding_code;
          var feishu_userid = page.app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var getList = this.getParams("get_user_report_requisition", {
              "dingding_code": dingding_code
            });
            var getFields = this.getParams("get_report_requisition_fields", {
              "dingding_code": dingding_code
            });

          } else if (page.app.data.session.source == 'feishu') {
            var getList = this.getParams("get_user_report_requisition", {
              "feishu_userid": feishu_userid
            });
            var getFields = this.getParams("get_report_requisition_fields", {
              "feishu_userid": feishu_userid
            });

          } else {
            var getList = this.getParams("get_user_report_requisition", {
              "wechat_name": vuserid,
            });
            var getFields = this.getParams("get_report_requisition_fields", {
              "wechat_name": vuserid,
            });
          }

          page.app.request.postJSON(
            page.app.data.url,
            getList,
            function (result_list) {
              console.log(result_list);
              self.$setState({
                expenseClaimList:result_list.result.all_report_requisition,
                expenseClaimListCount:result_list.result.all_report_requisition.length,
                expenseClaimList1:result_list.result.processing_report_requisition,
                expenseClaimListCount1:result_list.result.processing_report_requisition.length
              });

              page.$pageEl.find("#tab-all,#tab-doing").on("touchmove", function (e) {
                $(e.currentTarget)
                  .parent()
                  .parent()
                  .prev()
                  .find(".searchbar input")
                  .blur();
              });
                //未处理 搜索框实例
              var sbtodo = page.app.searchbar.create({
                el: '[data-name="expenseClaim-index"] .searchbar-to-do',
                searchContainer: '[data-name="expenseClaim-index"] .list-to-do',
                searchIn: '[data-name="expenseClaim-index"] .item-text-to-do',
                notFoudEl: '[data-name="expenseClaim-index"] .searchbar-not-found-to-do',
                });
                var sbdone = page.app.searchbar.create({
                  el: ".searchbar-done",
                  searchContainer: ".list-done",
                  searchIn: ".item-text-done",
                  notFoudEl: ".searchbar-not-found-done",
                });
                $('[data-name="expenseClaim-index"] .searchbar-done').hide();
                $('[data-name="expenseClaim-index"] .search-icon-2').hide();

                $('[data-name="expenseClaim-index"] .to-do-btn').on("click", function () {
                  sbdone.disable();
                  $('[data-name="expenseClaim-index"] .searchbar-done,[data-name="expenseClaim-index"] .search-icon-2').hide();
                  $('[data-name="expenseClaim-index"] .searchbar-to-do,[data-name="expenseClaim-index"] .search-icon-1').show();
                });
                $('[data-name="expenseClaim-index"] .done-btn').on("click", function () {
                  sbtodo.disable();
                  $('[data-name="expenseClaim-index"] .searchbar-done,[data-name="expenseClaim-index"] .search-icon-2').show();
                  $('[data-name="expenseClaim-index"] .searchbar-to-do,[data-name="expenseClaim-index"] .search-icon-1').hide();
              });
            }
          );

          // page.app.request.postJSON(
          //   page.app.data.url,
          //   getFields,
          //   function (result_list) {
          //     console.log(result_list);
          //     self.$setState({
          //       requisition_fields:result_list.result
          //     });
          //   }
          // );
          

        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  expenseClaimForm,
  expenseClaimFormView,
  expenseClaimLineForm,
  addLoanLine,
  addBankLine
];