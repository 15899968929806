import $$ from "dom7";
import teamBuildingAppViewForm from "../pages/teamBuildingAppViewForm.html";
import Template7 from "template7";
import * as dd from 'dingtalk-jsapi'

// import 'weui';


export default {
  name: "teambuildingappviewform",
  path: "/teambuildingappviewform/",
  component: {
    template: teamBuildingAppViewForm,
    methods: {
      // getUserObj: function () {
      //   if (this.$app.data.session.source == 'dingding') {
      //     return {
      //       dingding_code: this.$app.data.dingding_code
      //     }
      //   } else {
      //     return {
      //       wechat_name: this.$app.data.session.userid
      //     }
      //   }
      // },
      // toLineDetail: function (e) {
      //   let self = this;
      //   var id = parseInt(e.currentTarget.dataset.lineId)
      //   let obj = this.getUserObj()
      //   obj.line_id = parseInt(id)
      //   this.$app.request.postJSON(this.$app.data.url, this.$app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'get_user_leave_record_line_details', obj), function (res) {
      //     console.log(res)
      //     self.$app.router.navigate('/teamBuildingappformlinedetail/', {
      //       reloadCurrent: false,
      //       ignoreCache: true,
      //       context: {
      //         info: res.result.data
      //       }
      //     })
      //   })
      // },
    },
    on: {
      pageInit: function (e, page) {},
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};