import $$ from "dom7";
import preIndexHtml from "../pages/preIndex.html";
import Template7 from "template7";

export default {
  name: "publicapplicationpreindex",
  path: "/publicapplicationpreindex/",
  component: {
    template: preIndexHtml,
    data: () => {
      return {};
    },
    methods: {
      getUserObj: function () {
        if (app.data.session.source == 'dingding') {
          return {
            dingding_code: app.data.dingding_code
          }
        } else if (app.data.session.source == 'feishu') {
          return {
            feishu_userid: app.data.feishu_userid
          }
        } else {
          return {
            wechat_name: app.data.session.userid
          }
        }
      },
      next: function (e) {
        const self = this
        const publicapplicationTypeFormData = app.form.convertToData('#publicapplication-type-form'); //leaveType 休假类型
        const outwork_type_id = publicapplicationTypeFormData.leaveType
        if(!outwork_type_id){
          return app.dialog.alert('公出申请类型必须有值!', "KTHRP");
        }
        const obj = this.getUserObj()
        obj.outwork_type_id = parseInt(outwork_type_id)
        app.preloader.show();
        app.request.postJSON(app.data.url, app.methods.getJsonrpcParams('kthrp.public.outwork.interface', 'get_return_type', obj), function (res) {
          console.log(res);
          app.preloader.hide();
          // const {user_name,dept_name,outwork_type,is_show,outwork_traffic,need_fiel,usable_days} = res.result
          if(res.result){
            app.router.navigate("/publicapplicationform/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                info:res.result,
                outwork_type_id:obj.outwork_type_id
              }
            });
          }
        });
      }
    },
    on: {
      pageInit: function (e, page) {
        // let self = this
        // const {
        //   $$
        // } = this
        // app.request.postJSON(app.data.url, app.methods.getJsonrpcParams('kthrp.hr.leave.record.interface', 'get_document_type', this.getUserObj()), function (res) {
        //   console.log(res)
        //   self.$setState({
        //     leaveType: res.result.data,
        //   });
        // })
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};