import $$ from "dom7";
import about from "./about";
import html from "../pages/index.html";
import style from "../styles/setting.less";
export default [{
    name: "/setting/",
    path: "/setting/",
    // url: "./expense/pages/index.html"
    component: {
      template: html,
      style: style,
      methods: {},
      on: {
        pageInit: function (e, page) {
          var self = this;
          var page = $$('.page[data-name="setting-index"]')[0].f7Page;
          this.$setState({
            lang: page.app.data.session.lang,
          });

          app.form.fillFromData("#setting-form", {
            lang: page.app.data.session.lang,
          });

          var smartSelect = page.app.smartSelect.get("#smart-select");

          smartSelect.on("closed", function (e) {
            console.log(e);
            var lang = app.form.convertToData("#setting-form").lang;
            page.app.data.session.lang = lang;
            //本地缓存语言环境
            localStorage.setItem("lang", lang);
            self.$setState({
              lang: page.app.data.session.lang,
            });
          });

        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  about
];