/*
 * @Author: liubaozhen
 * @Date: 2023-07-04 20:50:43
 * @LastEditTime: 2024-04-18 13:23:29
 * @LastEditors: liubaozhen
 * @Description: 
 * @FilePath: \odoo\kthrp_map\src\publicApplication\routes\index.js
 */
import $ from "dom7";
import publicapplicationForm from "./publicapplicationForm";
import publicapplicationViewForm from "./publicapplicationViewForm";

import preIndex from "./preIndex";
import html from "../pages/index.html";
import style from "../styles/index.less";
export default [{
    name: "/publicApplication/",
    path: "/publicApplication/",
    // url: "./expense/pages/index.html"
    component: {
      template: html,
      style: style,
      methods: {
        getUserObj: function () {
          if (app.data.session.source == 'dingding') {
            return {
              dingding_code: app.data.dingding_code
            }
          } else if (app.data.session.source == 'feishu') {
            return {
              feishu_userid: app.data.feishu_userid
            }
          } else {
            return {
              wechat_name: app.data.session.userid
            }
          }
        },
        create(){
          const self = this;
          const obj = this.getUserObj()
          app.preloader.show();
          app.request.postJSON(app.data.url, app.methods.getJsonrpcParams('kthrp.public.outwork.interface', 'get_outwork_apply_type', obj), function (res) {
            console.log(res);
            app.preloader.hide();
            app.router.navigate("/publicapplicationpreindex/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                attachments: true,
                info: res.result.data
              }
            });
          });
        },
        toDetail: function (e) {
          let self = this;
          let id = parseInt(e.currentTarget.dataset.id);
          let obj = this.getUserObj()
          obj.outwork_id = parseInt(id)
          app.preloader.show();
          app.request.postJSON(app.data.url, app.methods.getJsonrpcParams('kthrp.public.outwork.interface', 'get_user_public_outwork_details', obj), function (res) {
            console.log(res);
            app.preloader.hide();
            app.router.navigate("/publicapplicationviewform/", {
              reloadCurrent: false,
              ignoreCache: true,
              context: {
                attachments: true,
                info: res.result.data
              }
            });
          });
        }
      },
      on: {
        pageInit: function (e, page) {
          let self = this
          const {
            $$
          } = this
          app.preloader.show();
          app.request.postJSON(app.data.url, app.methods.getJsonrpcParams('kthrp.public.outwork.interface', 'get_user_public_outwork', this.getUserObj()), function (res) {
            console.log(res)
            app.preloader.hide();
            if(res.result){
              self.$setState({
                all_leave_record: res.result.data.all_outwork_result,
                all_leave_record_count: res.result.data.all_public_outwork_count,
                processing_leave_record: res.result.data.processing_public_outwork,
                processing_leave_record_count: res.result.data.processing_public_outwork_count,
              });
            }else{
              self.$setState({
                processing_leave_record_count: 0,
              });
            }
            //未处理 搜索框实例
            var sbtodo = page.app.searchbar.create({
              el: '[data-name="publicapplication-index"] .searchbar-to-do',
              searchContainer: '[data-name="publicapplication-index"] .list-to-do',
              searchIn: '[data-name="publicapplication-index"] .item-text-to-do',
              notFoudEl: '[data-name="publicapplication-index"] .searchbar-not-found-to-do',
            });
            var sbdone = page.app.searchbar.create({
              el: ".searchbar-done",
              searchContainer: ".list-done",
              searchIn: ".item-text-done",
              notFoudEl: ".searchbar-not-found-done",
            });
            $('[data-name="publicapplication-index"] .searchbar-done').hide();
            $('[data-name="publicapplication-index"] .search-icon-2').hide();

            $('[data-name="publicapplication-index"] .to-do-btn').on("click", function () {
              sbdone.disable();
              $('[data-name="publicapplication-index"] .searchbar-done,[data-name="publicapplication-index"] .search-icon-2').hide();
              $('[data-name="publicapplication-index"] .searchbar-to-do,[data-name="publicapplication-index"] .search-icon-1').show();
            });
            $('[data-name="publicapplication-index"] .done-btn').on("click", function () {
              sbtodo.disable();
              $('[data-name="publicapplication-index"] .searchbar-done,[data-name="publicapplication-index"] .search-icon-2').show();
              $('[data-name="publicapplication-index"] .searchbar-to-do,[data-name="publicapplication-index"] .search-icon-1').hide();
            });
          })

        },
        pageMounted: function (e, page) {
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  preIndex,
  publicapplicationForm,
  publicapplicationViewForm,
];