import $$ from "dom7";
import html from "../pages/index.html";
import style from "../styles/index.less";
import bankNewLine from "./bankNewLine";
import  '../jSignature/jSignature.js';
export default [{
    name: "/personalInfo/",
    path: "/personalInfo/",
    component: {
      template: html,
      style: style,
      data: () => {
        return {
          isFabBtn: false,
          countLine: 1,
        };
      },
      methods: {
        getParams: function (method, obj) {
          var vuserid = this.$app.data.session.userid;
          var dbname = this.$app.data.dbname;
          var userid = this.$app.data.userid;
          var password = this.$app.data.password;
          var args = [];
          args = [
            dbname,
            userid,
            password,
            "kthrp.hr.mobile.employee.interface",
            method,
            obj,
          ];
          return {
            jsonrpc: "2.0",
            method: "call",
            params: {
              service: "object",
              method: "execute",
              args: args,
            },
          };
        },
        to_bank_line(e){
          if(!this.isEdit) return
          let self = this
          let lineId = parseInt(e.currentTarget.dataset.id)
          const {
              $$
          } = this;       
          var page = $$('.page[data-name="personalInfo"]')[0].f7Page;
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var getFields = self.getParams("employee_bank_info_query", {
              "dingding_code": dingding_code,
              
            });
          } else if (page.app.data.session.source == 'feishu') {
            var getFields = self.getParams("employee_bank_info_query", {
              "feishu_userid": feishu_userid,
              
            });
          } else {
            var getFields = self.getParams("employee_bank_info_query", {
              "wechat_name": vuserid
            });
          }  
          page.app.request.postJSON(
            page.app.data.url,
            getFields,
            function (result) {
            if(result.result){
              page.router.navigate("/bankNewLine/", {
                reloadCurrent: false,
                ignoreCache: true,
                context:{
                  bank_info_list: this.bank_info_list,
                  lineId:lineId,
                  detail: app.data.bank_info_ids[lineId],
                  info: result.result
                }
              });
            }
          })
        },
        addBank(){
          if(!this.isEdit) return
          const self = this
          const {
              $$
          } = this;       
          var page = $$('.page[data-name="personalInfo"]')[0].f7Page;
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var getFields = self.getParams("employee_bank_info_query", {
              "dingding_code": dingding_code,
              
            });
          } else if (page.app.data.session.source == 'feishu') {
            var getFields = self.getParams("employee_bank_info_query", {
              "feishu_userid": feishu_userid,
              
            });
          } else {
            var getFields = self.getParams("employee_bank_info_query", {
              "wechat_name": vuserid
            });
          }  
          page.app.request.postJSON(
            page.app.data.url,
            getFields,
            function (result) {
            if(result.result){
              page.router.navigate("/bankNewLine/", {
                reloadCurrent: false,
                ignoreCache: true,
                context:{
                  info: result.result
                }
              });

            }
          })
        },
        delete_line(e){
          e.preventDefault();
          e.stopPropagation()
          if(!this.isEdit) return
          const lineId = parseInt(e.currentTarget.dataset.id)
          app.data.bank_info_ids.splice(lineId,1)
          this.$setState({
            bank_info_ids:app.data.bank_info_ids
          })
        },

        chooseImg(){
          $('#fileIpt1').trigger('click')
        },
        //文件上传后的处理函数
        filechange() {
          var file = $('#fileIpt1')[0].files[0];
          // 读取文件URL
          var reader = new FileReader();
          reader.readAsDataURL(file);
          // 阅读文件完成后触发的事件
          reader.onload = function () {
              // 读取的URL结果
              $("#headImage").attr('src',this.result) 
          }
        },
        submit(){
          const self = this
          var page = $$('.page[data-name="personalInfo"]')[0].f7Page
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          var signEditForm = this.$app.form.convertToData('#signEditForm');
          if(!this.isEdit){
            $('input').removeAttr('disabled')
            $('select').removeAttr('disabled')
            self.chooseSignatureType()
            this.$setState({
              isEdit: !this.isEdit
            })

          }else{
            if(!signEditForm.mobile_phone){
              app.dialog.alert("手机必须有值！", "系统提示");
              return
            }
            if(!signEditForm.emergency_contact){
              app.dialog.alert("紧急联系人必须有值！", "系统提示");
              return
            }
            if(!signEditForm.emergency_telephone){
              app.dialog.alert("联系人电话必须有值！", "系统提示");
              return
            }

            if (page.app.data.session.source == 'dingding') {
              var getFields = self.getParams("write_employee_info", {
                "dingding_code": dingding_code,
                'bank_info_ids':app.data.bank_info_ids,
                ...signEditForm
              });
            } else if (page.app.data.session.source == 'feishu') {
              var getFields = self.getParams("write_employee_info", {
                "feishu_userid": feishu_userid,
                'bank_info_ids':app.data.bank_info_ids,
                ...signEditForm
              });
            } else {
              var getFields = self.getParams("write_employee_info", {
                "wechat_name": vuserid,
                'bank_info_ids':app.data.bank_info_ids,
                ...signEditForm
              });
            }  
            app.preloader.show();
            page.app.request.postJSON(
              page.app.data.url,
              getFields,
              function (result) {
              if(result.result.state == 'ok'){

                // var toast = app.toast.create({
                //   text: "保存成功！",
                //   position: "center",
                //   closeTimeout: 2000
                // });
                // toast.open();
                self.submitFiles({record_id: result.result.data.record_id,record_model:result.result.data.model_name}).then(()=>{
                  self.getData()
                  app.preloader.hide();
                  $('input').attr('disabled','disabled')
                  $('select').attr('disabled','disabled')
                    self.$setState({
                    isEdit: !self.isEdit
                  });
                  self.close()
                  // app.router.back('/home/',{
                  //   force: true
                  // }) 
                })
              }else if(result.result.state == 'err'){
                app.preloader.hide();
                app.dialog.alert(result.result.err, "系统提示");
              }else{
                app.preloader.hide();
                app.dialog.alert('操作失败', "系统提示");
              }
            })

          }

        },
        chooseSignatureType(){
          let signEditForm = this.$app.form.convertToData('#signEditForm');
          if(signEditForm.signatureType == '1'){
            $('#signContainer').show()
            $('#type2').hide()
            this.reWrite()
          }else if(signEditForm.signatureType == '2'){
            $('#signContainer').hide()
            $('#type2').show()
          }else{
            $('#signContainer').hide()
            $('#type2').hide()
          }
        },
        close(){
          app.form.fillFromData("#signEditForm", {
            signatureType: 0
          });
        },
        reWrite(){
          $('#signature').html('');
          var params = {
            width: '100%',
            height: '200px',
            // lineWidth: '5'
          };

          $('#signature').jSignature(params);
        },
        submitFiles(val){
          let self = this
          let signEditForm = this.$app.form.convertToData('#signEditForm');
          let pro = new Promise((resolve,reject) => {
          var formData = new FormData()
          let post_url = this.$app.data.base_url + '/web/map_upload_attachment'
          formData.append('record_id', val.record_id)
          formData.append('model_name', val.record_model)
          formData.append('update_file_fields', 1)
          formData.append('db', this.$app.data.dbname)
          formData.append('id', this.$app.data.userid)
          formData.append('pwd', this.$app.data.password)
          
          var file1 = $('#fileIpt1')[0].files[0];
          if(file1){
            formData.append('file', file1, 'image@' + file1.name);
          }
          
          if(signEditForm.signatureType == '1'){
            var datapair = $("#signature").jSignature("getData", "image");
            var src = "data:" + datapair[0] + "," + datapair[1];
            var bytes = window.atob(src.split(',')[1]);
            var array = [];
            for(var i = 0; i < bytes.length; i++){
                array.push(bytes.charCodeAt(i));
            }
            var blob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
            formData.append('file',blob, 'signature_image@' + Date.now() + '.jpg');
          }else if(signEditForm.signatureType == '2'){
            var file2 = $('#fileIpt2')[0].files[0];
            if(file2){
              formData.append('file', file2, 'signature_image@' + file2.name);
            }
          }

          if(!file1 && ((!file2 && signEditForm.signatureType == '2') || signEditForm.signatureType == '0')){
            var toast = app.toast.create({
                text: "保存成功！",
                position: "center",
                closeTimeout: 2000
              });
            toast.open();
            app.preloader.hide();
            $('input').attr('disabled','disabled')
            $('select').attr('disabled','disabled')
            self.$setState({
              isEdit: !self.isEdit
            });
            self.close()
            // app.router.back('/home/',{
            //   force: true
            // }) 
            return
          }

          if (this.$app.data.session.source === 'dingding') {
            formData.append('dingding_code', this.$app.data.dingding_code)
          }else if (this.$app.data.session.source === 'feishu') {
            formData.append('feishu_userid', this.$app.data.feishu_userid)
          }else{
            formData.append('wechat_name', this.$app.data.session.userid)
          }
          this.$app.request.post(post_url, formData,(result) => {
            if (JSON.parse(result).state === 'ok') {
              var toast = this.$app.toast.create({
                text: '恭喜您,操作成功',
                position: 'center',
                closeTimeout: 2000
              })
              toast.open()
              // self.$app.router.navigate('/workOrderCompleted/', {})
            } else {
              var toast = this.$app.toast.create({
                text: '很遗憾,操作失败',
                position: 'center',
                closeTimeout: 2000
              })
              toast.open()
            }
            resolve()
          })
        })
        return pro
        },
        getData(scene){
          let self = this
          const {
            $$
          } = this
          var page = $$('.page[data-name="personalInfo"]')[0].f7Page
          var vuserid = app.data.session.userid;
          var dingding_code = app.data.dingding_code;
          var feishu_userid = app.data.feishu_userid;
          if (page.app.data.session.source == 'dingding') {
            var getFields = self.getParams("employee_info_query", {
              "dingding_code": dingding_code,
              
            });
          } else if (page.app.data.session.source == 'feishu') {
            var getFields = self.getParams("employee_info_query", {
              "feishu_userid": feishu_userid,
              
            });
          } else {
            var getFields = self.getParams("employee_info_query", {
              "wechat_name": vuserid
            });
          }  
          page.app.request.postJSON(
            page.app.data.url,
            getFields,
            function (result) {
            if(result.result){
              self.$setState({
                info:result.result
              });

              $("#headImage").attr('src',result.result.image + '&time-stamp=' + Date.now())
              if(result.result.signature_image){
                $("#signature_image").attr('src',result.result.signature_image + '&time-stamp=' + Date.now()) 
              }else{
                $("#signature_image").hide()
              }
              
              if(scene == 'init'){
                app.form.fillFromData("#signEditForm", {
                  mobile_phone: result.result.mobile_phone,
                  emergency_contact: result.result.emergency_contact,
                  emergency_telephone: result.result.emergency_telephone,
                  home_address: result.result.home_address,
                  personal_email: result.result.personal_email
                });
  
                var params = {
                  width: '100%',
                  height: '200px',
                  // lineWidth: '5'
                };
  
                $('#signature').jSignature(params);
                $('#signContainer').hide()
                self.$setState({
                  bank_info_ids:result.result.bank_info_ids
                })
                app.data.bank_info_ids = result.result.bank_info_ids
              }else{
                
              }

            }
          })
        }
      },
      on: {
        pageInit: function (e, page) {
          
          this.$setState({
            isEdit: false
          })
          
          this.getData('init')
        },
        pageMounted: function (e, page) {
          
          console.log("pageMounted");
        },
        pageReinit: function (e, page) {
          console.log("pageReinit");
        },
        pageBeforeIn: function (e, page) {
          console.log("pageBeforeIn");
        },
        pageAfterIn: function (e, page) {
          console.log("pageAfterIn");
          this.$setState({
            bank_info_ids:app.data.bank_info_ids
          })
        },
        pageBeforeInOut: function (e, page) {
          console.log("pageBeforeInOut");
        },
        pageAfterOut: function (e, page) {
          console.log("pageAfterOut");
        },
        pageBeforeRemove: function (e, page) {
          console.log("pageBeforeRemove");
        }
      }
    }
  },
  bankNewLine
];