import regularizationAppFormLineDetail from "../pages/regularizationAppFormLineDetail.html";

export default {
  name: "regularizationappformlinedetail",
  path: "/regularizationappformlinedetail/",
  component: {
    template: regularizationAppFormLineDetail,
    methods: {},
    on: {
      pageInit: function (e, page) {
        console.log("pageInit");
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
};