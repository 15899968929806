import $$ from "dom7";
import bankNewLine from "../pages/bankNewLine.html";
import Template7 from "template7";

export default {
  name: "bankNewLine",
  path: "/bankNewLine/",
  component: {
    template: bankNewLine,
    data: () => {
      return {
        isInit: true
      }
    },
    methods: {
      getParams: function (method, obj) {
        var vuserid = this.$app.data.session.userid;
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var args = [];
        args = [
          dbname,
          userid,
          password,
          "kthrp.hr.mobile.employee.interface",
          method,
          obj,
        ];
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: args,
          },
        };
      },
      bank_id_change(){
        const self = this
        var vuserid = app.data.session.userid;
        var dingding_code = app.data.dingding_code;
        var feishu_userid = app.data.feishu_userid;
        const bank_id_key = $('#bank_id').val().split(',')[0]
        if (app.data.session.source == 'dingding') {
          var getFields = self.getParams("employee_branch_bank_query", {
            "dingding_code": dingding_code,
            bank_id_key
            
          });
        } else if (app.data.session.source == 'feishu') {
          var getFields = self.getParams("employee_branch_bank_query", {
            "feishu_userid": feishu_userid,
            bank_id_key
            
          });
        } else {
          var getFields = self.getParams("employee_branch_bank_query", {
            "wechat_name": vuserid,
            bank_id_key
          });
        }
        app.preloader.show();
        app.request.postJSON(
          app.data.url,
          getFields,
          function (result) {
          app.preloader.hide();
          if(result.result){
            self.$setState({
              bank_branch:result.result.bank_branch
            });
            app.form.fillFromData('#bankNewLineForm', {
              external_branch:self.isInit && self.detail ? self.detail.external_branch_key + ',' + self.detail.external_branch : ''
            })
          }
        })
      },
      save() {
        var self = this
        const {
          $$
        } = this
        var page = $$('.page[data-name="bankNewLine"]')[0].f7Page
        let formData = page.app.form.convertToData("#bankNewLineForm");
        console.log(formData);
        if (!formData.account_type) {
          return app.dialog.alert("账户类型必须有值！", "系统提示");
        }
        if (!formData.bank_id) {
          return app.dialog.alert("银行必须有值！", "系统提示");
        }
        if (!formData.bank_account) {
          return app.dialog.alert("银行账户必须有值！", "系统提示");
        }
        if (this.info.branch_bank_required && !formData.external_branch) {
          return app.dialog.alert("银行分行必须有值！", "系统提示");
        }
        // if (this.info.branch_num_required && !formData.branch_num) {
        //   return app.dialog.alert("分行号必须有值！", "系统提示");
        // }
        formData.bank_id_key = parseInt(formData.bank_id.split(',')[0])
        formData.bank_id = formData.bank_id.split(',')[1]
        formData.external_branch_key = parseInt(formData.external_branch.split(',')[0])
        formData.external_branch = formData.external_branch.split(',')[1]
        formData.account_type_value = formData.account_type.split(',')[1]
        formData.account_type = formData.account_type.split(',')[0]
        if(!this.detail){
          app.data.bank_info_ids.push(formData)
        }else{
          app.data.bank_info_ids[self.lineId] = formData
        }
        $$(".back").trigger("click");
        
      },
    },
    on: {
      pageInit: function (e, page) { 
        const self = this
        if(this.detail){
          page.app.form.fillFromData('#bankNewLineForm', {
            account_type: self.detail.account_type + ',' + self.detail.account_type_value,
            bank_account: self.detail.bank_account,
            bank_id: self.detail.bank_id_key + ',' + self.detail.bank_id,
            // branch_num: self.detail.branch_num,
            // external_branch: self.detail.external_branch_key + ',' + self.detail.external_branch,
            note: self.detail.note
          });
        }
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      },
    },
  },
};
